import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  RootState,
  VodAsset,
  ApiAuthResponse,
  VodConfig,
  VodConfigData,
  VodBatchListResponse,
  GenreType,
  Genre,
  SearchCategories,
  PromotionActionType,
  VodConfigDataType,
  Filter,
  FilterKeys,
  GenreTypes,
  SearchConfig,
  HeaderDisplayType,
  HeaderInteractionType,
  AltiboxAsset,
  AltiboxAssetDisplayTypes,
  VodAssetDisplayMode,
  VodSection,
  VodConfigSectionType,
  GAaction,
} from '../../../interfaces';
import Header from '../../app/Header';
import { getGenres } from '../../tv/actions';
import { fetchMyContent, fetchFrontPageVodLists, setSelectedVodGenre } from '../actions';
import { routes } from '../../../config';
import {
  prepareMyContent,
  getCurrentLangTitle,
  addBadgeOnOwnedSeriesAssets,
  getVodAsset,
} from '../../../utils/vodUtils';
import { getContentDetailFromAibId } from '../../../api/vod';
import VodPromotionBanner from '../../../components/VodPromotionBanner';
import CategoryHelmet from '../../../components/Helmets/CategoryHelmet';
import VodInlinePromotion from '../../../components/VodInlinePromotion';
import Footer from '../../../components/Footer/index';
import { clearOriginLocation } from '../../../views/app/actions';
import AltiboxAssetViewList from '../../../components/UI/AltiboxAssetViewList';
import StickyHeader from '../../../views/app/StickyHeader';
import Spinner from '../../../components/Spinner';
import i18n from '../../../i18n';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import AltiboxGenreBanner from '../../../components/AltiboxGenreBanner';

interface Props extends RouteComponentProps<{}, {}, { toTop?: boolean }> {
  auth: ApiAuthResponse | undefined;
  lists: VodBatchListResponse[] | undefined;
  genres: Genre[];
  isGuest: boolean;
  loggedInWithCredentials: boolean;
  vodConfig: VodConfig | undefined;
  myContent: VodAsset[] | undefined;
  mySeries: VodAsset[] | undefined;
  mySeasons: VodAsset[] | undefined;
  searchConfig?: SearchConfig;
  selectedGenre: GenreTypes | undefined;
  defaultImagePath: string;
  clearOriginLocation: () => { type: symbol; originLocation: string };
  getMyContent: (count: number) => Promise<void>;
  setSelectedGenre: (genreType: GenreTypes) => Promise<void>;
  getGenres: () => Promise<Genre[]>;
  getLists: (config: VodConfigData[], genre: Genre[], stripLength: number) => Function;
  searchCategories: SearchCategories | undefined;
}
interface State {
  stripLength: number;
  redirectUrl: string;
  categories: VodAsset[];
}

class FrontPage extends Component<Props, State> {
  state: State = {
    stripLength: 18,
    categories: [],
    redirectUrl: '',
  };

  tracking = AnalyticsTracking.getInstance();

  componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state.toTop) {
      window.scrollTo(0, 0);
    }
    this.props.clearOriginLocation();

    if (!this.props.isGuest) {
      this.props.getMyContent(-1);
    }

    if (!this.props.lists) {
      this.props.getGenres().then(() => {
        this.props.getLists(this.props.vodConfig!.data, this.props.genres, this.state.stripLength);
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.isGuest && !nextProps.isGuest) {
      this.props.getMyContent(this.state.stripLength);
    }
  }

  getMovieGenres = () => {
    return this.props.vodConfig!.data.find((x) => x.type === VodConfigDataType.MOVIE_GENRE);
  };

  getSeriesGenres = () => {
    return this.props.vodConfig!.data.find((x) => x.type === VodConfigDataType.SERIES_GENRE);
  };

  getFrontPageViewOrder = () => {
    return this.props.vodConfig!.data.find((x) => x.type === VodConfigDataType.GRID_WITH_BANNERS);
  };

  getSingleRow(assets: VodBatchListResponse, index: number, waitForMyContent: boolean) {
    const { myContent, mySeries, mySeasons } = this.props;
    if (waitForMyContent && !myContent) {
      return null;
    }
    let link = `${routes.vod.base}${routes.vod.category}/${assets.section.categoryId}/${assets.section.sorting}`;
    if (assets.section.filter) {
      assets.section.filter.forEach((filter: Filter) => {
        if (filter.key === FilterKeys.GENRE_IDS) {
          link += '/' + filter.value.replace(/\s/g, '');
        }
      });
    }
    const content = prepareMyContent(myContent!, mySeries!, mySeasons!);
    let preparedContent = addBadgeOnOwnedSeriesAssets(assets.msg.vodlist, content);
    let preparedSeries = addBadgeOnOwnedSeriesAssets(preparedContent, mySeries!);
    const list = preparedSeries.map((asset) => {
      return getVodAsset(asset);
    });

    return (
      <AltiboxAssetViewList
        key={index + '-vodlist'}
        assets={list}
        title={getCurrentLangTitle(assets.section.title)}
        displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
        vodDisplayMode={VodAssetDisplayMode.VOD}
        titleLink={link}
        loggedInWithCredentials={this.props.loggedInWithCredentials}
        myContent={this.props.myContent}
      />
    );
  }

  bannerClicked = (index: number) => {
    if (this.props.vodConfig && !isEmpty(this.props.vodConfig.promotions) && this.props.vodConfig.promotions[index]) {
      const promo = this.props.vodConfig.promotions[index];

      this.tracking.trackCurrentService(GAaction.bannerInteraction, promo.actionType + ' - ' + promo.platformId);

      if (promo.actionType === PromotionActionType.MOVIE_DETAILS) {
        getContentDetailFromAibId(promo.platformId)
          .then((assets) => {
            this.setState({
              redirectUrl: routes.vod.base + routes.vod.details + '/' + (assets as VodAsset).externalContentCode,
            });
          })
          .catch(() => {
            console.error('Asset not found');
          });
      } else if (promo.actionType === PromotionActionType.CATEGORY) {
        this.setState({
          redirectUrl: routes.vod.base + routes.vod.promo + '/' + promo.platformId,
        });
      }
    }
  };

  onGenreSelected = (genreSelected: GenreTypes) => {
    this.props.setSelectedGenre(genreSelected);
  };

  render() {
    if (this.state.redirectUrl) {
      this.props.history.push(this.props.location.pathname);
      return <Redirect to={this.state.redirectUrl} />;
    }

    let myContent: AltiboxAsset[] = [];
    if (this.props.myContent) {
      const content = prepareMyContent(this.props.myContent, this.props.mySeries!, this.props.mySeasons!);
      myContent = content.map((asset) => {
        return getVodAsset(asset);
      });
    }

    const viewOrder = this.getFrontPageViewOrder();
    let configBasedJSXlist;
    if (this.props.lists && viewOrder && viewOrder.sections && viewOrder.sections.length > 0) {
      configBasedJSXlist = viewOrder.sections.map((section: VodSection, index: number) => {
        if (section.type === VodConfigSectionType.CATEGORY) {
          const list = this.props.lists!.find(
            (x) => x.section.categoryId === section.categoryId && x.section.sorting === section.sorting,
          );
          if (list && list.msg.counttotal !== '0') {
            return this.getSingleRow(list, index, !this.props.isGuest);
          } else {
            return null;
          }
        } else if (section.type === VodConfigSectionType.PROMOTION) {
          return (
            <VodInlinePromotion
              key={'VodInlinePromotion-' + index}
              promotions={section.data}
              imagePath={this.props.defaultImagePath}
            />
          );
        } else if (section.type === VodConfigSectionType.GENRES) {
          return (
            <AltiboxGenreBanner
              key={'VodGenreBanner-' + index}
              seriesGenres={this.getSeriesGenres()}
              moviesGenres={this.getMovieGenres()}
              baseUrl={routes.vod.base + routes.vod.genre}
              onGenreSelected={this.onGenreSelected}
              selectedTab={this.props.selectedGenre}
            />
          );
        } else {
          return null;
        }
      });
    }

    if (configBasedJSXlist && !this.props.isGuest && myContent.length > 0) {
      configBasedJSXlist.splice(
        2,
        0,
        <AltiboxAssetViewList
          key={'VodFrontpageMyAssets'}
          assets={myContent.splice(0, 10)}
          title={i18n.t<string>('your bought and rented')}
          displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
          vodDisplayMode={VodAssetDisplayMode.VOD}
          titleLink={routes.vod.base + routes.vod.myContent}
          loggedInWithCredentials={this.props.loggedInWithCredentials}
          myContent={this.props.myContent}
        />,
      );
    }

    return (
      <div className="main-frame">
        <CategoryHelmet categoryTitle={'Forside'} title={i18n.t<string>('movies and series')} />
        <StickyHeader searchConfig={this.props.searchConfig} />
        <Header
          title={i18n.t<string>('movies and series')}
          displayType={HeaderDisplayType.Gradient}
          interactionType={HeaderInteractionType.Full}
          hideTitle={true}
        />
        <VodPromotionBanner
          bannerClicked={this.bannerClicked}
          promotions={this.props.vodConfig!.promotions}
          imagePath={this.props.defaultImagePath}
          randomPlay={true}
        />
        <div className="vod-content vod-frontpage">
          {configBasedJSXlist ? configBasedJSXlist : <Spinner wrapInContainer={true} />}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      defaultImagePath: state.app.defaultImagePath,
      isGuest: state.authReducer.isGuest,
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
      auth: state.authReducer.auth,
      selectedGenre: state.vodReducer.selectedGenreType,
      lists: state.vodReducer.frontPageVodLists,
      vodConfig: state.vodReducer.vodConfig,
      myContent: state.vodReducer.myContent,
      mySeries: state.vodReducer.mySeries,
      mySeasons: state.vodReducer.mySeasons,
      genres: state.channelsReducer.genres,
      searchCategories: state.searchReducer.searchCategories,
    }),
    (dispatch) => ({
      clearOriginLocation: () => dispatch(clearOriginLocation()),
      getMyContent: (count: number) => dispatch(fetchMyContent(count)),
      setSelectedGenre: (genreType: GenreTypes) => dispatch(setSelectedVodGenre(genreType)),
      getLists: (config: VodConfigData[], genre: Genre[], stripLength: number) =>
        dispatch(fetchFrontPageVodLists(config, genre, stripLength)),
      getGenres: () => dispatch(getGenres(GenreType.MovieAndTV)),
    }),
  )(FrontPage),
);
