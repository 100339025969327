import {
  SET_FRONTPAGE_CONFIG,
  SET_PORTAL_MENU,
  ADD_AUTH_STATUS,
  SHOW_LOGIN_MODAL,
  HIDE_LOGIN_MODAL,
  SET_LOGIN_ERROR,
  SET_SHOULD_FADE,
  SHOW_DEVICE_SWITCH_MODAL,
  HIDE_DEVICE_SWITCH_MODAL,
  SHOW_LOCATION_SWITCH_MODAL,
  HIDE_LOCATION_SWITCH_MODAL,
  SHOW_ALERT_MODAL,
  HIDE_ALERT_MODAL,
  SET_PVR_STATUS,
  SET_PRODUCT_LIST,
  SET_SVOD_KIOSKS,
  SET_ALL_BOOKMARKS,
  SET_CONTINUE_WATCHING_ASSETS,
  IS_LOADING_CONTINUE_WATCHING_ASSETS,
  SET_DEFAULT_IMAGE_PATH,
  SET_ORIGIN_LOCATION,
  SET_NIELSEN_CHANNELS,
} from '../actions';
import { AnyAction } from 'redux';
import { AlertType, AppReducerState, CountryType } from '../../../interfaces';
import { getCountryBasedOnBossId } from '../../../utils/appUtils';
import { goToLogin } from '../../../api/auth';

export const authReducer = (
  state = {
    auth: {},
    country: CountryType.NO,
    loggedIn: false,
    isGuest: false,
    isHome: false,
    isInternetOnly: false,
    isInternetOnlyAuthByIp: false,
    isInternetOnlyAuthByCreds: false,
    loggedInWithCredentials: false,
    loggedInWithIp: false,
    loginModalVisible: false,
    devicesToSwitch: null,
    deviceReplaceTimes: null,
    deviceSwitchModalVisible: false,
    locationSelectModalVisible: false,
    accountSelectModalVisible: false,
    loginError: null,
    subscribers: [],
    userFullName: null,
    productList: [],
    hasPvr: false,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case SHOW_DEVICE_SWITCH_MODAL:
      return {
        ...state,
        deviceSwitchModalVisible: true,
        devicesToSwitch: action.devicesForSwitch,
        deviceReplaceTimes: action.deviceReplaceTimes,
      };
    case HIDE_DEVICE_SWITCH_MODAL:
      return {
        ...state,
        deviceSwitchModalVisible: false,
        devicesToSwitch: null,
        deviceReplaceTimes: null,
      };
    case ADD_AUTH_STATUS:
      const loggedIn = action.auth.retcode === '0';
      const isHome = action.auth.isInside === '1';
      const isInternetOnly = action.auth.isInternetOnly === true;
      const isInternetOnlyAuthByIp = action.auth.isInternetOnlyAuthByIp === true;
      const isInternetOnlyAuthByCreds = action.auth.isInternetOnlyAuthByCreds === true;
      const currentUser = localStorage.getItem('currentuser') && localStorage.getItem('analytics-token') ? true : false;

      const loggedInWithCredentials = loggedIn && currentUser;
      const isGuest = action.auth && action.auth.isInside === undefined;
      let country = getCountryBasedOnBossId(action.auth.bossID);
      return {
        ...state,
        auth: action.auth,
        country,
        loggedIn,
        isInternetOnly,
        isInternetOnlyAuthByCreds,
        isInternetOnlyAuthByIp,
        isGuest,
        isHome,
        loggedInWithCredentials,
        loggedInWithIp: isHome && !loggedInWithCredentials,
        currentUser,
        userFullName: localStorage.getItem('customername'),
      };
    case SHOW_LOCATION_SWITCH_MODAL:
      return {
        ...state,
        accounts: action.accounts,
        locationSelectModalVisible: true,
      };
    case HIDE_LOCATION_SWITCH_MODAL:
      return {
        ...state,
        locationSelectModalVisible: false,
        loginError: null,
      };
    case SHOW_LOGIN_MODAL:
      goToLogin();
      return {
        ...state,
      };
    case HIDE_LOGIN_MODAL:
      return {
        ...state,
        loginModalVisible: false,
        loginError: null,
      };
    case SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: action.error,
      };
    case SET_PVR_STATUS:
      return {
        ...state,
        hasPvr: action.status,
      };
    case SET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.productList,
      };
    default:
      return state;
  }
};

export function appReducer(
  state: AppReducerState = {
    shouldFadeOut: false,
    originLocation: '',
    alertVisible: false,
    bookmarks: [],
    continueWatchingAssetsIsLoading: true,
    continueWatchingAssets: [],
    alert: {
      title: '',
      text: [],
      type: AlertType.INFO,
    },
    portalMenu: [],
    svodKiosks: [],
    frontPageConfig: {
      clients: [],
      availableComponents: [],
    },
    defaultImagePath: '',
    nielsenChannelMapping: undefined,
  },
  action: AnyAction,
) {
  switch (action.type) {
    case SET_ORIGIN_LOCATION:
      return {
        ...state,
        originLocation: action.originLocation,
      };
    case SET_DEFAULT_IMAGE_PATH:
      return {
        ...state,
        defaultImagePath: action.defaultImagePath,
      };
    case IS_LOADING_CONTINUE_WATCHING_ASSETS:
      return {
        ...state,
        continueWatchingAssetsIsLoading: action.continueWatchingAssetsIsLoading,
      };
    case SET_CONTINUE_WATCHING_ASSETS:
      return {
        ...state,
        continueWatchingAssets: action.continueWatchingAssets,
      };
    case SET_ALL_BOOKMARKS:
      return {
        ...state,
        bookmarks: action.bookmarks,
      };
    case SET_SVOD_KIOSKS:
      return {
        ...state,
        svodKiosks: [...action.svodKiosks],
      };
    case SET_FRONTPAGE_CONFIG:
      return {
        ...state,
        frontPageConfig: action.frontPageConfig,
      };
    case SET_PORTAL_MENU:
      return {
        ...state,
        portalMenu: action.portalMenu,
      };
    case SET_SHOULD_FADE:
      return {
        ...state,
        shouldFadeOut: action.shouldFadeOut,
      };
    case SHOW_ALERT_MODAL:
      return {
        ...state,
        alert: action.alert,
        alertVisible: action.alertVisible,
      };
    case HIDE_ALERT_MODAL:
      return {
        ...state,
        alertVisible: action.alertVisible,
      };
    case SET_NIELSEN_CHANNELS:
      return {
        ...state,
        nielsenChannelMapping: action.nielsenChannelMapping,
      };
    default:
      return state;
  }
}
