import { useEffect, useState, useRef } from 'react';
import { useConfirmContext } from '../context/ConfirmContext';

interface IsConfirmedArguments {
  title?: string;
  prompt: string;
}

export function useConfirm() {
  const { confirm, setConfirm } = useConfirmContext();
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const test = useRef(false);

  function isConfirmed({ title, prompt }: IsConfirmedArguments) {
    setNeedsCleanup(true);

    const promise = new Promise((resolve, reject) => {
      setConfirm({
        title,
        prompt,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      });
    });

    return promise.then(
      () => {
        setConfirm((prev) => ({ ...prev, isOpen: false }));
        test.current = true;
        return true;
      },
      () => {
        setConfirm((prev) => ({ ...prev, isOpen: false }));
        test.current = false;
        return false;
      },
    );
  }

  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  const { current } = test;

  return {
    ...confirm,
    isConfirmed,
    current,
  };
}
