import React from 'react';
import { ScriptService } from '../../../../../controllers/ScriptService';
import i18n from '../../../../../i18n';
import { Program } from '../../../../../interfaces';
import { getProgramData, getSeriesId, getChannelid } from '../../../../../utils/altiboxassetUtils';
import PvrRecordingButton from '../../../../../views/pvr/PvrRecordingButton';
import PlayerControlButton from '../PlayerControlButton';
import './index.scss';

type Props = {
  isPlaying: boolean;
  isFullscreen: boolean;
  isWatchingLive: boolean;
  playPause: () => void;
  fastForward: () => void;
  rewind: () => void;
  fullscreen?: () => void;
  airplay?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  volumeControl: JSX.Element;
  playNextEpisode?: () => void;
  audioAndSubtitlesMenu?: JSX.Element;
  streamQualityMenu?: JSX.Element;
  togglePictureInPicture?: () => void;
  currentProgram?: Program;
};

class PlayerControlRow extends React.Component<Props> {
  get pictureInPicture() {
    if (this.props.togglePictureInPicture && ScriptService.isPiPSupported()) {
      return (
        <PlayerControlButton
          tooltip={i18n.t<string>('picture in picture')}
          icon="Ï"
          className="pic-in-pic"
          onClickCallback={this.props.togglePictureInPicture}
        />
      );
    }
    return null;
  }

  get chromeCast() {
    if (ScriptService.canCast()) {
      return <google-cast-launcher />;
    }
    return null;
  }

  get airPlay() {
    if (ScriptService._isSafari() && this.props.airplay) {
      return <PlayerControlButton icon="G" className="airplay" onClickCallback={this.props.airplay} />;
    }
    return null;
  }

  get playNextEpisode() {
    if (this.props.playNextEpisode) {
      return <PlayerControlButton icon={'Ą'} className="nextEpisode" onClickCallback={this.props.playNextEpisode} />;
    }
    return null;
  }

  handleOnMouseEnter = () => {
    this.props.onMouseEnter();
  };

  handleOnMouseLeave = () => {
    this.props.onMouseLeave();
  };

  render() {
    const {
      volumeControl,
      streamQualityMenu,
      audioAndSubtitlesMenu,
      rewind,
      playPause,
      fastForward,
      fullscreen,
      isPlaying,
      isFullscreen,
      isWatchingLive,
      currentProgram,
    } = this.props;

    let playPauseIcon = isPlaying ? '=' : 'Ă';
    let fullscreenIcon = isFullscreen ? 'f' : 'F';
    let skipForwardClass = isWatchingLive ? ' live' : '';

    return (
      <div className={'player-controls-container'}>
        <div className="player-controls">
          <div className="left-content">
            <PlayerControlButton icon="«" className="rewind" onClickCallback={rewind} />
            <PlayerControlButton icon={playPauseIcon} className="play" onClickCallback={playPause} />
            <PlayerControlButton
              icon="»"
              className={'fast-forward' + skipForwardClass}
              onClickCallback={fastForward}
              disabled={isWatchingLive}
            />

            {currentProgram && (
              <PlayerControlButton
                icon={
                  <PvrRecordingButton
                    modalHeroBackground={currentProgram.picture.poster}
                    programData={getProgramData(currentProgram)}
                    seriesId={getSeriesId(currentProgram)}
                    channelId={getChannelid(currentProgram)}
                    hideButtonText
                  />
                }
                className="record"
              />
            )}

            {this.playNextEpisode}
          </div>
          <div className="right-content">
            {this.airPlay}
            {this.chromeCast}
            <div className="hover-menus" onMouseEnter={this.handleOnMouseEnter} onMouseLeave={this.handleOnMouseLeave}>
              {volumeControl}
              {audioAndSubtitlesMenu}
              {streamQualityMenu}
              {this.pictureInPicture}
            </div>
            <PlayerControlButton icon={fullscreenIcon} className={'fullscreen'} onClickCallback={fullscreen} />
          </div>
        </div>
      </div>
    );
  }
}
export default PlayerControlRow;
