import { broadcastingNow } from '../../../utils/huaweiUtils';
import {
  ChannelsReducerState,
  Program,
  Genre,
  TVGuideChannelFilter,
  MiniEpgPlaybills,
  Channel,
} from '../../../interfaces';
import { isEmpty } from 'lodash';

import { TypeKeys, ActionTypes } from '../actions';
import { getChannelPermission } from '../../../utils/tvUtils';

export function shouldPlaybillHide(showingDetails: boolean) {
  return document.body.clientWidth < 900 && showingDetails;
}

export function channelsReducer(
  state: ChannelsReducerState = {
    channels: [],
    sortedChannels: [],
    popularChannels: [],
    favoriteChannels: [],
    favoritePrograms: {},
    playbills: new Map<string, Program[]>(),
    cachedPlaybills: new Map<string, Map<string, Program[]>>(),
    tvGuidePlaybills: new Map<string, Program[]>(),
    currentChannel: undefined,
    currentProgram: undefined,
    startingCatchup: false,
    focusedChannel: undefined,
    focusedChannelTopOffset: 0,
    channelListVisible: false,
    programDetails: undefined,
    playbillShouldHide: false,
    seriesRecordings: [],
    singleRecordings: [],
    recordingActionInProgress: false,
    channelNotFound: false,
    catchupNotFound: false,
    catchupDataLoading: false,
    genres: [],
    liveFromStart: false,
    tvGuideLoading: false,
    tvGuideFilter: TVGuideChannelFilter.ALL,
    miniEpgPlaybills: {} as MiniEpgPlaybills,
    tvGuideGenreFilterList: [],
    currentDate: new Date(),
  },
  action: ActionTypes,
): ChannelsReducerState {
  switch (action.type) {
    case TypeKeys.SET_TVGUIDE_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.date,
      };
    case TypeKeys.SET_TVGUIDE_GENRE_FILTER_LIST:
      return {
        ...state,
        tvGuideGenreFilterList: action.tvGuideGenreFilterList,
      };
    case TypeKeys.SET_CATCHUP_DATA_LOADING:
      return {
        ...state,
        catchupDataLoading: action.catchupDataLoading,
      };
    case TypeKeys.SET_CURRENT_LIVE_PROGRAM:
      return {
        ...state,
        currentProgram: action.currentProgram,
      };
    case TypeKeys.SET_CHANNELS:
      let channelsInSubscription: Channel[] = [];
      let insideChannels: Channel[] = [];
      let decoderOnlyChannels: Channel[] = [];
      let remainingChannels: Channel[] = [];

      action.channels.forEach((channel) => {
        const permission = getChannelPermission(channel);
        if (!permission.notInSubscription) {
          channelsInSubscription.push(channel);
          return;
        }
        if (permission.onlyInsideforLiveTV) {
          insideChannels.push(channel);
          return;
        }
        if (permission.onlyIptvChannel) {
          decoderOnlyChannels.push(channel);
          return;
        }
        remainingChannels.push(channel);
      });

      return {
        ...state,
        channels: action.channels,
        sortedChannels: [...channelsInSubscription, ...insideChannels, ...decoderOnlyChannels, ...remainingChannels],
        currentChannel: state.currentChannel
          ? action.channels.find((x) => x.contentId === state.currentChannel!.contentId)
          : undefined,
      };
    case TypeKeys.SET_POPULAR_CHANNELS:
      return {
        ...state,
        popularChannels: [...action.popularChannels],
      };
    case TypeKeys.SET_FAVORITE_PROGRAMS:
      return {
        ...state,
        favoritePrograms: action.favorites,
      };
    case TypeKeys.SET_CHANNEL_FOCUSED:
      return {
        ...state,
        focusedChannel: state.channels.find((channel) => channel.contentId === action.channelId),
      };
    case TypeKeys.SET_CHANNEL_PLAYING:
      const playbill = (state.playbills[action.channelId] || []) as Program[];
      const theCurrentChannel = state.channels.find((channel) => channel.contentId === action.channelId)!;
      const theCurrentProgram = playbill.find(broadcastingNow);

      if (theCurrentChannel === undefined) {
        return { ...state, channelNotFound: true };
      }
      return {
        ...state,
        channelNotFound: false,
        currentChannel: theCurrentChannel,
        currentProgram: theCurrentProgram,
        startingCatchup: false,
        liveFromStart: action.liveFromStart,
      };
    case TypeKeys.SET_CATCHUP_PLAYING:
      const program = action.program;
      if (!program) {
        return { ...state, catchupNotFound: true };
      }

      const currentChannel = state.channels.find((channel) => channel.contentId === program.channelid)!;

      return {
        ...state,
        channelNotFound: false,
        catchupNotFound: false,
        currentChannel,
        currentProgram: program,
        startingCatchup: true,
      };
    case TypeKeys.SHOW_CHANNEL_LIST:
      return {
        ...state,
        channelListVisible: true,
      };
    case TypeKeys.HIDE_CHANNEL_LIST:
      return {
        ...state,
        channelListVisible: false,
        programDetails: undefined,
      };
    case TypeKeys.REPLACE_TVGUIDE_PLAYBILL_PROGRAMS:
      const tvguideNewPrograms = action.programs;
      if (tvguideNewPrograms && tvguideNewPrograms.length > 0) {
        const newTvGuide = new Map<string, Program[]>();
        tvguideNewPrograms.forEach((newProgram: Program) => {
          if (newProgram) {
            let programContainer = newTvGuide.get(newProgram.channelid);
            if (programContainer) {
              programContainer.push(newProgram);
            } else {
              newTvGuide.set(newProgram.channelid, [newProgram]);
            }
          }
        });
        return {
          ...state,
          tvGuidePlaybills: newTvGuide,
        };
      } else {
        return {
          ...state,
          tvGuidePlaybills: new Map(),
        };
      }
    case TypeKeys.APPEND_TVGUIDE_PLAYBILL_PROGRAMS:
      const tvguideNewProgramsToAppend = action.programs;
      if (tvguideNewProgramsToAppend && !isEmpty(tvguideNewProgramsToAppend)) {
        const tvguideToAppend = new Map<string, Program[]>();
        tvguideNewProgramsToAppend.forEach((newProgram: Program) => {
          if (newProgram) {
            let programContainer = tvguideToAppend.get(newProgram.channelid);
            if (programContainer) {
              programContainer.push(newProgram);
            } else {
              tvguideToAppend.set(newProgram.channelid, [newProgram]);
            }
          }
        });
        return {
          ...state,
          tvGuidePlaybills: new Map<string, Program[]>([...state.tvGuidePlaybills, ...tvguideToAppend]),
        };
      } else {
        return {
          ...state,
        };
      }

    case TypeKeys.REPLACE_TVGUIDE_SINGLE_CHANNEL_PLAYBILL:
      if (action.programs && action.programs.length > 0) {
        action.programs = action.programs.filter((x) => x);
        let changingChannelId = action.programs[0].channelid;
        state.tvGuidePlaybills.set(changingChannelId, action.programs);
        return {
          ...state,
          tvGuidePlaybills: new Map(state.tvGuidePlaybills),
        };
      }
      return {
        ...state,
      };
    case TypeKeys.ADD_PLAYBILL_PROGRAMS:
      // Only add the programs that aren't in the playbill yet
      const programs = Object.keys(state.playbills)
        .map((x) => state.playbills[x] as Program[])
        .reduce((result, next) => [...result, ...next], [])
        .filter((x) => x !== undefined);
      const programIds = new Set(programs.map((x) => x.id));
      const newPrograms = action.programs.filter((x) => x && !programIds.has(x.id));
      return {
        ...state,
        playbills: programs.concat(newPrograms).reduce((result, next) => {
          result[next.channelid] = result[next.channelid] || [];
          result[next.channelid].push(next);
          return result;
        }, new Map<string, Program[]>()),
      };
    case TypeKeys.SHOW_PROGRAM_DETAILS:
      // TODO: calculate if playbill should be hidden based on screen width.
      // Reuse function in index.tsx
      return {
        ...state,
        programDetails: { program: action.program, displayType: action.displayType, svodKiosk: action.svodKiosk },
        playbillShouldHide: shouldPlaybillHide(true),
      };
    case TypeKeys.HIDE_PROGRAM_DETAILS:
      return { ...state, programDetails: undefined, playbillShouldHide: false };
    case TypeKeys.SET_PLAYBILL_SHOULD_HIDE:
      return { ...state, playbillShouldHide: action.value };
    case TypeKeys.SET_SERIES_RECORDINGS:
      return { ...state, seriesRecordings: action.recordings };
    case TypeKeys.SET_SINGLE_RECORDINGS:
      return { ...state, singleRecordings: action.recordings };
    case TypeKeys.RECORDING_ACTION_IN_PROGRESS:
      return { ...state, recordingActionInProgress: true };
    case TypeKeys.RECORDING_ACTION_DONE:
      return { ...state, recordingActionInProgress: false };
    case TypeKeys.SET_GENRES:
      // we have programs genres and vod genres, combine them
      const cloneStateGenres = [...state.genres];
      action.genres.forEach((newGenre: Genre) => {
        if (!state.genres.some((x) => x.genreId === newGenre.genreId)) {
          cloneStateGenres.push(newGenre);
        }
      });
      return { ...state, genres: cloneStateGenres };
    case TypeKeys.RESET_CHANNEL_GUARDS:
      return {
        ...state,
        channelNotFound: false,
        catchupNotFound: false,
      };
    case TypeKeys.SET_CHANNEL_LIST_FILTER:
      return {
        ...state,
        tvGuideFilter: action.tvGuideFilter,
      };
    case TypeKeys.SET_MINI_EPG_PROGRAMS:
      return {
        ...state,
        miniEpgPlaybills: action.epg,
      };
    case TypeKeys.CACHE_TVGUIDE:
      if (!state.cachedPlaybills.get(action.currentDate.toDateString())) {
        return {
          ...state,
          cachedPlaybills: new Map(state.cachedPlaybills).set(
            action.currentDate.toDateString(),
            state.tvGuidePlaybills,
          ),
        };
      }
      return {
        ...state,
      };
    default:
      return state;
  }
}
