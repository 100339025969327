import { isEmpty } from 'lodash';
import { getBaseUrl, getSessionTicket } from '../../config';
import { fetchError, getDataFromResponse } from '../../controllers/Fetch';
import { Pvr, Api, Bookmark, HuaweiBatchResponse } from '../../interfaces';
import { Recording } from './types';

export async function getRecordingEpisodes() {
  try {
    const pvrStatus = `${Pvr.Status.RecordedSuccessfully},${Pvr.Status.BeingRecorded}`;
    const res = await fetch(getBaseUrl() + '/EPG/JSON/QueryPVR', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        status: pvrStatus,
        count: -1,
        offset: 0,
        pvrType: Pvr.Type.NPVR,
        isFilter: 0,
        expandSubTask: 1,
        orderType: Pvr.APIOrderType.StartTimeDesc,
        isReturnSeriesTask: 0,
      }),
    });

    return (await getDataFromResponse<Api.PvrResponse<Recording.Query.Union[]>>(res)).pvrlist;
  } catch (error) {
    return fetchError(error as string);
  }
}

export async function deleteRecordings(pvrIds: string[]) {
  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/DeletePVR', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        pvrId: pvrIds.join(),
      }),
    });

    return await getDataFromResponse<Api.RetResponse>(res);
  } catch (error) {
    return fetchError(error as string);
  }
}

export async function deleteRecording(pvrId: string) {
  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/DeletePVR', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        pvrId: pvrId,
      }),
    });

    return await getDataFromResponse<Api.RetResponse>(res);
  } catch (error) {
    return fetchError(error as string);
  }
}

export async function getRecordingSpace() {
  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        requestList: [
          {
            name: 'QueryPVRSpace',
            param: {
              type: 1,
            },
          },
          {
            name: 'QueryPVRSpace',
            param: {
              type: 2,
            },
          },
        ],
      }),
    });

    const data = await getDataFromResponse<Api.AllPvrSpaceResponse>(res);
    const usedSpace = data.responseList[0].msg;
    const spaceLeft = data.responseList[1].msg;

    return {
      used: usedSpace.space,
      left: spaceLeft.space,
    };
  } catch (error) {
    return fetchError(error as string);
  }
}

export async function getContinueWatchingRecordings(
  bookmarks: Bookmark[] | undefined,
): Promise<Recording.Query.Union[]> {
  if (!bookmarks || isEmpty(bookmarks)) {
    return Promise.resolve([]);
  }

  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        requestList: bookmarks.map((bookmark) => {
          return {
            name: 'QueryPVRById',
            param: {
              pvrIds: bookmark.contentId,
            },
          };
        }),
      }),
    });

    const data = await getDataFromResponse<
      HuaweiBatchResponse<'QueryPVRById', Api.PvrResponse<Recording.Query.Union[]>>
    >(res);

    return data.responseList
      .map((r) => r.msg)
      .filter((r) => r.pvrlist && r.pvrlist.length > 0)
      .map((r) => r.pvrlist[0])
      .map((pvr, i) => {
        return { ...pvr, bookmarkTime: bookmarks[i].rangeTime, updateTime: bookmarks[i].updateTime };
      });
  } catch (error) {
    return fetchError(error as string);
  }
}
