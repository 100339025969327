import { status, json } from '../controllers/Fetch';
import { getBaseUrl, getSessionTicket } from '../config';
import {
  ContentType,
  SearchType,
  ExtensionInfoResponse,
  SearchCategories,
  VodAsset,
  ApiBatchSearchResponse,
  AltiboxAssetSearchResponse,
  Program,
  PVRSearchResponse,
  GetStateType,
  AltiboxAssetType,
  StreamType,
  AltiboxAsset,
} from '../interfaces';
import flatten from 'lodash/flatten';
import { getContentDetailSearch, getSeriesFromEpisodes } from './vod';
import { getSearchCatchupAsset } from '../utils/programArchiveUtils';
import { getPVRAssetForSearch } from '../utils/pvrUtils';
import { getVodAltiboxAssetForSearch } from '../views/app/actions';
import { getProgramDetail } from './programarchive';
import { isStringEncoded, decodeURIComponentSafe } from '../utils/searchUtils';
import isEmpty from 'lodash/isEmpty';

interface SearchQuery {
  categoryid?: string;
  key: string;
  contenttype: ContentType;
  type: SearchType | number;
  count: number;
  offset: 0;
}

interface PVRSearchQuery {
  searchKey: string;
  status: string;
  offset: number;
  count: number;
  expandSubTask: number;
  orderType: number;
  searchScopes: string[];
}

export async function search(
  searchString: string,
  state: GetStateType,
  categories: SearchCategories,
  maxResults: number,
  standardType: SearchType,
): Promise<AltiboxAssetSearchResponse[]> {
  const queryList = buildSearchQuery(searchString, state, categories, maxResults, standardType);
  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: queryList.map((queryObject) => {
        return {
          name: queryObject.hasOwnProperty('searchKey') ? 'QueryPVR' : 'Search',
          param: queryObject,
        };
      }),
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(async (response: ApiBatchSearchResponse) => {
      let convertedAndEnrichedAssets = await enrichSearchAssets(response, state);
      return convertedAndEnrichedAssets;
    });
}

async function enrichSearchAssets(apiResponse: ApiBatchSearchResponse, state: GetStateType) {
  const channels = state().channelsReducer.channels;

  let vodContentDetailIds: string[][] = [];
  let programContentDetailIds: string[][] = [];
  let output: AltiboxAssetSearchResponse[] = [];
  apiResponse.responseList.forEach((searchResult, idx) => {
    let result = searchResult.msg;
    if (Number(result.counttotal) > 0 && !isEmpty(result.contentlist)) {
      if (result.contentlist && result.contentlist[0].type === ContentType.VIDEO_VOD) {
        vodContentDetailIds.push(result.contentlist.map((x) => x.id));
      } else if (result.contentlist && result.contentlist[0].type === ContentType.PROGRAM) {
        programContentDetailIds.push(result.contentlist.map((x) => x.id));
      }
    }
  });

  let vodContentIdsToQuery = Array.from(new Set(flatten(vodContentDetailIds))) || [];
  let programContentIdsToQuery = Array.from(new Set(flatten(programContentDetailIds))) || [];

  const enrichedVods =
    vodContentIdsToQuery.length > 0 ? await getContentDetailSearch(vodContentIdsToQuery.join(',')) : undefined;
  const enrichedPrograms =
    programContentIdsToQuery.length > 0
      ? ((await getProgramDetail(programContentIdsToQuery.join(','), false)) as Program[])
      : undefined;

  if (enrichedVods) {
    const episodes = enrichedVods.filter((x) => x.fathervodlist && x.fathervodlist[0]);
    const series = episodes ? await getSeriesFromEpisodes(episodes.map((x) => x.fathervodlist[0].vodid)) : [];

    if (series) {
      series.forEach((asset, idx) => {
        episodes[idx].seriesAsset = asset;
      });
    }
  }

  apiResponse.responseList.forEach((searchResult, idx) => {
    let result = searchResult.msg;
    if (Number(result.counttotal) > 0 && !isEmpty(result.contentlist)) {
      if (enrichedVods && result.contentlist && result.contentlist[0].type === ContentType.VIDEO_VOD) {
        result.contentlist = result.contentlist.map((inner) => {
          return enrichedVods.find((x: VodAsset) => x.id === inner.id) as VodAsset;
        });
      } else if (enrichedPrograms && result.contentlist && result.contentlist[0].type === ContentType.PROGRAM) {
        result.contentlist = result.contentlist.map((inner) => {
          return enrichedPrograms.find((x: Program) => x.id === inner.id) as {} as VodAsset;
        });
      }
    }

    switch (idx) {
      case 0:
        output.push({
          contentlist: result.contentlist
            ? result.contentlist.map((x) => {
                const vodAsset = getVodAltiboxAssetForSearch(x, state, true) as AltiboxAsset;
                vodAsset.doNotMarkAsSvod = true; // because of shared assets
                return vodAsset;
              })
            : [],
          counttotal: result.counttotal ? result.counttotal : '0',
        } as AltiboxAssetSearchResponse);

        break;
      case 1:
        output.push({
          contentlist: result.contentlist
            ? result.contentlist.map((x) => {
                return getVodAltiboxAssetForSearch(x, state);
              })
            : [],
          counttotal: result.counttotal ? result.counttotal : '0',
        } as AltiboxAssetSearchResponse);
        break;
      case 2:
        output.push({
          contentlist: result.contentlist
            ? result.contentlist.map((x) => getSearchCatchupAsset(x as {} as Program, channels))
            : [],
          counttotal: result.counttotal ? result.counttotal : '0',
        } as AltiboxAssetSearchResponse);
        break;
      case 3:
        output.push({
          contentlist: result.contentlist
            ? result.contentlist.map((x) =>
                getSearchCatchupAsset(x as {} as Program, channels, AltiboxAssetType.TVGUIDE),
              )
            : [],
          counttotal: result.counttotal ? result.counttotal : '0',
        } as AltiboxAssetSearchResponse);
        break;
      case 4:
        let pvrlist = (result as {} as PVRSearchResponse).pvrlist;
        output.push({
          contentlist: pvrlist ? pvrlist.map((x) => getPVRAssetForSearch(x)) : [],
          counttotal: result.counttotal ? result.counttotal : '0',
        } as AltiboxAssetSearchResponse);
    }
  });

  if (output.length > 0) {
    output[1].contentlist.forEach((x) => {
      x.type = AltiboxAssetType.SVOD as {} as StreamType;
    });
  }
  return output;
}

export function buildSearchQuery(
  searchString: string,
  state: GetStateType,
  categories: SearchCategories,
  count: number,
  standardType: SearchType,
): Array<SearchQuery | PVRSearchQuery> {
  const { VOD, SVOD } = categories;
  const channels = state().channelsReducer.channels;

  if (isStringEncoded(searchString)) {
    searchString = decodeURIComponentSafe(encodeURIComponent(searchString));
  }

  const baseStructure: SearchQuery = {
    key: searchString,
    contenttype: ContentType.VOD,
    offset: 0,
    count: count,
    type: standardType,
  };

  const pvrSearch: PVRSearchQuery = {
    searchKey: searchString,
    status: '0,1', // recorded, recording
    offset: 0,
    count: 50,
    expandSubTask: 1, // query series as well
    orderType: 1, // start time desc.
    searchScopes: ['PVRNAME', 'ACTOR', 'DIRECTOR', 'OTHERCAST'],
  };
  if (standardType === SearchType.STAR_NAME) {
    pvrSearch.searchScopes = ['ACTOR'];
  } else if (standardType === SearchType.DIRECTORS_AND_OTHER_CAST) {
    pvrSearch.searchScopes = ['DIRECTOR', 'OTHERCAST'];
  }

  const vodSearch = Object.assign(
    {},
    { ...baseStructure },
    {
      categoryid: VOD,
      order: '8,10,1',
    },
  );
  const svodSearch = Object.assign(
    {},
    { ...baseStructure },
    {
      categoryid: SVOD,
      order: '8,10,1',
    },
  );
  const catchupSearch = Object.assign(
    {},
    { ...baseStructure },
    {
      contenttype: ContentType.TVOD,
      order: '4,1',
      filterlist: [
        { key: 'ChannelScope', value: '3' },
        { key: 'SubscriptionType', value: '0' },
        { key: 'ChannelId', value: channels.map((x) => x.contentId).join(',') },
      ],
    },
  );
  const tvguideSearch = Object.assign(
    {},
    { ...baseStructure },
    {
      contenttype: ContentType.PROGRAM,
      order: '3,1',
      filterlist: [{ key: 'SubscriptionType', value: '0' }],
    },
  );
  return [
    vodSearch,
    svodSearch,
    catchupSearch,
    tvguideSearch,
    state().authReducer.isGuest ? undefined : pvrSearch,
  ].filter((x) => x) as Array<SearchQuery | PVRSearchQuery>;
}

export function getCategories(): Promise<ExtensionInfoResponse> {
  return fetch(getBaseUrl() + '/EPG/JSON/GetCustomizeConfig', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      queryType: 0,
    }),
  })
    .then(status)
    .then((r) => json(r as Response));
}

export function getCastById(id: string) {
  return fetch(getBaseUrl() + '/EPG/JSON/GetCastDetail', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      castIds: [id],
    }),
  })
    .then(status)
    .then((r) => json(r as Response));
}

export async function getCastByName(castName: string) {
  return fetch(getBaseUrl() + '/EPG/JSON/Search', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      key: castName,
      contenttype: ContentType.VOD,
      type: 1016,
      count: -1,
      offset: 0,
    }),
  })
    .then(status)
    .then((r) => json(r as Response));
}
