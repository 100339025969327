import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SearchConfig,
  RootState,
  VodAsset,
  StreamTypeObject,
  AuthReducerState,
  HeaderDisplayType,
  HeaderInteractionType,
  GAaction,
  GAlabel,
  GAcategory,
  PortalMenu,
  AltiboxAssetDetailsType,
  DetailsPanelType,
} from '../../../interfaces';
import { withRouter, RouteComponentProps } from 'react-router';
import { clearVodAutoplay, clearVodStreamConfig, loadTrailerAsset } from '../actions';
import { Helmet } from 'react-helmet-async';
import Player from '../../../components/Player';
import { searchConfig, routes, imageScaleValues } from '../../../config';
import StickyHeader from '../../app/StickyHeader';
import Header from '../../app/Header';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import { getSvodPicture, getSvodUrl } from '../../../utils/svodUtil';
import { getImageUrlDimensions, wrapAssetAsAltiboxAsset } from '../../../utils/altiboxassetUtils';
import PlayerAssetCard from '../../../components/Player/UI/PlayerAssetCard';
import { showDetailsPanel as showDetailsPanelAction } from '../../details/actions';
import { SlideInDetails } from '../../details';

interface State {
  timeLeft: number;
}

interface RouteParams {
  vodId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  searchConfig: SearchConfig;
  currentVod: VodAsset;
  streamConfig: StreamTypeObject;
  authStatus: AuthReducerState;
  shouldHideControls: boolean;
  svodKiosk: PortalMenu | undefined;
  detailsPanel: DetailsPanelType | undefined;
  loadTrailerAsset: (_1: string) => void;
  clearVodAutoplay: () => void;
  clearVodStreamConfig: () => void;
  showDetailsPanel: (vodAsset: VodAsset, displayType: AltiboxAssetDetailsType) => void;
}

class TrailerPlayer extends Component<Props, State> {
  tracking = AnalyticsTracking.getInstance();

  get title() {
    const { currentVod } = this.props;
    return 'Trailer - ' + (currentVod?.name || '');
  }

  get imageUrl() {
    const { currentVod } = this.props;

    return currentVod
      ? getImageUrlDimensions(getSvodPicture(this.props.currentVod), imageScaleValues.altiboxAssetView)
      : '';
  }
  get detailsURL() {
    const { currentVod, svodKiosk } = this.props;
    let service = routes.vod.base;
    if (svodKiosk) {
      service = routes.svod.base + '/' + getSvodUrl(svodKiosk);
    }
    return service + routes.vod.details + '/' + currentVod.externalContentCode;
  }

  get assetCardAndSlideInDetails() {
    return (
      <>
        {this.props.detailsPanel ? (
          <SlideInDetails
            key={
              this.props.detailsPanel.routeProps.parentId ??
              '' + this.props.detailsPanel.routeProps.childId ??
              '' + this.props.detailsPanel.displayType
            }
            {...this.props.detailsPanel}
          />
        ) : null}
        {this.props.shouldHideControls ? null : (
          <PlayerAssetCard
            currentTitle={this.title}
            image={this.imageUrl}
            showDetailsPanel={() => this.handleOpenDetails()}
          />
        )}
      </>
    );
  }

  componentDidMount() {
    this.prepareAsset(this.props.match.params.vodId);
  }

  componentWillUnmount() {
    this.onExit();
  }

  onExit = () => {
    this.props.clearVodStreamConfig();
    this.props.clearVodAutoplay();
  };

  prepareAsset(externalContentCode: string) {
    this.props.loadTrailerAsset(externalContentCode);
  }

  onConcurrencyError = () => {
    this.tracking.trackCurrentService(
      GAaction.notification,
      (this.props.authStatus.isHome ? 'inside ' : 'outside ') + GAlabel.maximumNoOfStreamReached,
    );
  };

  onPause = () => {
    const id = this.props.match.params.vodId;
    if (this.props.currentVod) {
      this.tracking.trackCurrentService(GAaction.playback_pause, this.props.currentVod.name + ' - ' + id);
    }
  };

  onPlay = () => {
    const id = this.props.match.params.vodId;
    if (this.props.currentVod) {
      this.tracking.trackCurrentService(GAaction.playback_play, this.props.currentVod.name + ' - ' + id);
    }
  };

  onPlayerError = (error: object) => {
    this.tracking.trackEvent(GAcategory.playerError, 'vod or svod' as GAaction, JSON.stringify(error));
  };

  goBackToDetails = () => {
    if (!this.props.currentVod) {
      this.props.history.goBack();
    } else {
      this.props.history.replace(this.detailsURL);
    }
  };

  onProgramEnded = () => {
    this.goBackToDetails();
  };

  alert = () => {
    return null;
  };

  handleOpenDetails = () => {
    const { currentVod, svodKiosk } = this.props;
    if (currentVod) {
      let displayType = AltiboxAssetDetailsType.VOD;
      if (svodKiosk) {
        displayType = AltiboxAssetDetailsType.SVOD;
      }

      this.props.showDetailsPanel(currentVod, displayType);
    }
  };

  renderPlayer() {
    return (
      <Player
        stream={this.props.streamConfig}
        onPlay={this.onPlay}
        onAlert={this.alert}
        onPause={this.onPause}
        hideControls={this.props.shouldHideControls}
        onPlayerError={this.onPlayerError}
        onConcurrencyError={this.onConcurrencyError}
        onProgramEnded={this.onProgramEnded}
        authStatus={this.props.authStatus}
        currentAsset={wrapAssetAsAltiboxAsset(this.props.currentVod)}
        includeInFullscreen={() => this.assetCardAndSlideInDetails}
      />
    );
  }

  render() {
    let playerSearchConfig = this.props.searchConfig ? this.props.searchConfig : searchConfig.global;
    playerSearchConfig.noFocus = true;
    return (
      <div className="main-frame">
        <div className="tv-container vod-player">
          <Helmet async={true}>
            <title>{this.title}</title>
          </Helmet>
          <StickyHeader overPlayer={true} searchConfig={playerSearchConfig} />
          {this.props.detailsPanel ? (
            <SlideInDetails
              key={
                this.props.detailsPanel.routeProps.parentId ??
                '' + this.props.detailsPanel.routeProps.childId ??
                '' + this.props.detailsPanel.displayType
              }
              {...this.props.detailsPanel}
            />
          ) : null}

          <Header
            displayType={HeaderDisplayType.Player}
            interactionType={HeaderInteractionType.Back}
            closeCallback={this.goBackToDetails}
          />
          {this.renderPlayer()}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      currentVod: state.vodReducer.currentVod as VodAsset,
      streamConfig: state.vodReducer.streamConfig as StreamTypeObject,
      authStatus: state.authReducer,
      shouldHideControls: state.app.shouldFadeOut,
      svodKiosk: state.svodReducer.svodKiosk,
      detailsPanel: state.detailsReducer.detailsPanel,
    }),
    (dispatch) => ({
      showDetailsPanel: (vodAsset: VodAsset, displayType: AltiboxAssetDetailsType) =>
        dispatch(
          showDetailsPanelAction({
            routeProps: { childId: vodAsset.externalContentCode, parentId: vodAsset.foreignsn },
            displayType: displayType,
          }),
        ),
      loadTrailerAsset: (_1: string) => dispatch(loadTrailerAsset(_1)),
      clearVodAutoplay: () => dispatch(clearVodAutoplay()),
      clearVodStreamConfig: () => dispatch(clearVodStreamConfig()),
    }),
  )(TrailerPlayer),
);
