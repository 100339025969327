import { Bookmark } from '../../interfaces';

export const recordingKeys = {
  all: ['recordings'] as const,
  episodes: () => [...recordingKeys.all, 'episodes'] as const,
  scheduled: () => [...recordingKeys.all, 'scheduled'] as const,
  space: () => [...recordingKeys.all, 'space'] as const,
  continueWatching: (bookmarks?: Bookmark[]) => {
    if (!bookmarks) {
      return [...recordingKeys.all, 'continueWatching'] as const;
    }

    const bookmarkIds = bookmarks?.map((bookmark) => `${bookmark.contentId}:${bookmark.updateTime}`);
    return [...recordingKeys.all, 'continueWatching', bookmarkIds] as const;
  },
};
