import {
  AlertData,
  AlertType,
  AltiboxAsset,
  AltiboxAssetContextType,
  AltiboxAssetDetailsType,
  AltiboxURLs,
  AuthReducerState,
  Bookmark,
  Channel,
  ContentType,
  DeleteType,
  DispatchType,
  Favourite,
  GAaction,
  Genre,
  HeaderDisplayType,
  HeaderInteractionType,
  PortalMenu,
  Program,
  PvrRecording,
  RootState,
  SharedAsset,
  SharedAssets,
  UnionAssetTypes,
  VodAsset,
  VodContentProduct,
} from '../../interfaces';
import {
  MarkRecordingForDeletion,
  MarkSeasonForDeletion,
  MarkSeriesForDeletion,
  SetCurrentPvrSeries,
  ShowAggregatedDeleteModal,
  fetchAllRecordings,
  markRecordingForDeletion,
  markSeasonForDeletion,
  markSeriesForDeletion,
  setCurrentPvrSeries,
  showAggregatedDeleteModal,
} from '../pvr/actions';
import { Movie, TVEpisode } from 'schema-dts';
import React, { Component, KeyboardEventHandler, MouseEventHandler, useRef } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { every, isEmpty, isEqual } from 'lodash';
import { fetchAllFavoriteIds, getChannelsIncludingIPTV } from '../tv/actions';
import {
  findEpisodeBasedOnVodId,
  getAltiboxAssetChannel,
  getAltiboxAssetCover,
  getAltiboxAssetId,
  getAltiboxAssetPromotedAsset,
  getAltiboxAssetSeriesTitle,
  getAltiboxAssetTitle,
  getAltiboxAssetTitleSuffix,
  getChannelid,
  wrapAssetAsAltiboxAsset,
} from '../../utils/altiboxassetUtils';
import {
  findEpisodeToPromote,
  findPvrToPromote,
  getSharedAssets,
  hideDetailsPanel,
  loadAsset,
  setSlideOutStatus,
  toggleAddRemoveFavourite,
  unloadAsset,
  updateContentPricing,
} from './actions';
import { generateEpisodeJsonLD, generateSingleMovieJsonLD } from '../../utils/jsonLD';
import { loadAllUsersBookmarks, showAlert, showLoginModal } from '../app/actions';
import { routes, searchConfig } from '../../config';

import { Action } from 'redux';
import AltiboxAssetContext from './AltiboxAssetContext';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';
import Details from '../../components/UI/Details';
import Footer from '../../components/Footer';
import Header from '../app/Header';
import { Helmet } from 'react-helmet-async';
import { Key } from 'ts-key-enum';
import PurchaseModal from '../vod/PurchaseModal';
import { PvrModals } from '../../components/PvrModals';
import { ScriptService } from '../../controllers/ScriptService';
import Spinner from '../../components/Spinner';
import StickyHeader from '../app/StickyHeader';
import { assetIsProgram } from '../../typeGuards';
import channelPermissions from '../../utils/channelPermissions';
import { fetchMyContent } from '../vod/actions';
import { getCurrentLangTitle } from '../../utils/vodUtils';
import { getFooterUrl } from '../../utils/appUtils';
import { getSvodUrlBasedByProvidername } from '../../utils/svodUtil';
import { helmetJsonLdProp } from 'react-schemaorg';
import i18n from '../../i18n';
import styles from './details.module.scss';

export type DetailsRouteProps = {
  parentId?: string; // series/film
  childId?: string; // episode/program/pvrid
  channelId?: string;
  svodKiosk?: string;
};

type Props = RouteComponentProps<DetailsRouteProps> & {
  // passed in
  overrideRouteProps?: DetailsRouteProps;
  overrideSvodKiosk?: PortalMenu;
  displayType: AltiboxAssetDetailsType;
  // reducer
  currentAsset: AltiboxAsset;
  loading: boolean;
  bookmarks: Bookmark[];
  channels: Channel[];
  authState: AuthReducerState;
  myContent: VodAsset[] | undefined;
  recordings: PvrRecording[] | undefined;
  singleRecordings: PvrRecording[];
  seriesRecordings: PvrRecording[];
  svodKiosk: PortalMenu | undefined;
  svodKiosks: PortalMenu[] | undefined;
  programGenres: Genre[];
  userLocation: string;
  defaultImagePath: string;
  clickedId?: string;
  recordingDeleted: PvrRecording | PvrRecording[] | undefined;
  // reducer functions
  loadBookmarks: () => Promise<Bookmark[]>;
  loadAsset: (
    routeProps: DetailsRouteProps,
    type: AltiboxAssetDetailsType,
    svodKiosk?: PortalMenu,
  ) => Promise<AltiboxAsset>;
  unloadAsset: () => Function;
  getChannels: () => Promise<void>;
  fetchMyContent: () => DispatchType;
  updateContentPricing: (_1: AltiboxAsset, _2: string) => DispatchType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSharedAssets: (_1: AltiboxAsset | UnionAssetTypes, _2: AltiboxAssetDetailsType, _3: boolean) => Action<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showAlert: (_1: AlertData) => any;
  showLoginModal: () => void;
  fetchAllRecordings: () => Promise<PvrRecording[]>;
  markRecordingForDeletion: (_: PvrRecording) => MarkRecordingForDeletion;
  showDeleteSeasonModal: () => MarkSeasonForDeletion;
  showDeleteSeriesModal: () => MarkSeriesForDeletion;
  showDeleteRecordingsModal: () => ShowAggregatedDeleteModal;
  setSelectedSeries: (_: PvrRecording) => SetCurrentPvrSeries;
  hideDetailsPanel: () => void;
  fetchAllFavoriteIds: () => Promise<void>;
};

type State = {
  isPurchasing: boolean;
  purchaseAsset: VodAsset;
  previousAsset: UnionAssetTypes | undefined;
  sharedAssets: SharedAssets | undefined;
  routeProps: DetailsRouteProps;
};

let isMounted: boolean = false;
class DetailsView extends Component<Props, State> {
  state: State = {
    isPurchasing: false,
    purchaseAsset: {} as VodAsset,
    previousAsset: undefined,
    sharedAssets: undefined,
    routeProps: this.props.overrideRouteProps ?? this.props.match.params,
  };

  tracking = AnalyticsTracking.getInstance();

  get isVod() {
    return (
      this.props.displayType === AltiboxAssetDetailsType.VOD ||
      this.props.displayType === AltiboxAssetDetailsType.VIDEO_VOD
    );
  }

  get isSvod() {
    return this.props.displayType === AltiboxAssetDetailsType.SVOD;
  }

  get isVodOrSvod() {
    return this.isVod || this.isSvod;
  }

  get isPvr() {
    return this.props.displayType === AltiboxAssetDetailsType.PVR;
  }

  get isCatchup() {
    return (
      this.props.displayType === AltiboxAssetDetailsType.CATCHUP ||
      this.props.displayType === AltiboxAssetDetailsType.PROGRAM
    );
  }

  get isCatchupOrPvr() {
    return this.isCatchup || this.isPvr;
  }

  get svodKiosk() {
    const kiosk = this.props.overrideSvodKiosk ?? this.props.svodKiosk;
    return kiosk;
  }

  get header() {
    const { displayType } = this.props;
    const svodKiosk = this.svodKiosk;
    switch (displayType) {
      case AltiboxAssetDetailsType.PVR:
        return i18n.t<string>('recordings');
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PROGRAM:
        return i18n.t<string>('programarchive');
      case AltiboxAssetDetailsType.VIDEO_VOD:
      case AltiboxAssetDetailsType.VOD:
        return i18n.t<string>('movies and series');
      case AltiboxAssetDetailsType.SVOD:
        if (svodKiosk) {
          const svodTitle = getCurrentLangTitle(svodKiosk.titles!);
          return svodTitle;
        }
        return '';
      default:
        return undefined;
    }
  }

  get isSlideIn() {
    return this.props.overrideRouteProps !== undefined;
  }

  get detailsContext() {
    const { props, state } = this;
    return {
      altiboxAsset: props.currentAsset,
      promotedAsset: this.promotedAsset,
      sharedAssets: state.sharedAssets,
      routeProps: this.routeProps,
      authState: props.authState,
      channels: props.channels,
      bookmarks: [...props.bookmarks],
      myContent: props.myContent,
      recordings: { all: props.recordings, single: props.singleRecordings, series: props.seriesRecordings },
      svodKiosk: this.svodKiosk,
      svodKiosks: props.svodKiosks,
      programGenres: props.programGenres,
      isGeoBlocked: this.isGeoBlocked,
      defaultImagePath: props.defaultImagePath,
      clickedId: props.clickedId,
      recordingDeleted: props.recordingDeleted,

      isVod: this.isVod,
      isSvod: this.isSvod,
      isVodOrSvod: this.isVodOrSvod,
      isPvr: this.isPvr,
      isCatchup: this.isCatchup,
      isCatchupOrPvr: this.isCatchupOrPvr,
      isSlideIn: this.isSlideIn,

      toggleFavourite: (_1: Favourite, _2: string) => this.toggleFavourite(_1, _2),
      purchaseAsset: (_1: VodAsset) => this.purchaseAsset(_1),
      getAccessToSvod: () => this.getAccessToSvod(),
      deleteRecordings: (_1: DeleteType) => this.deleteRecordings(_1),
      getTrackingLabel: () => this.getTrackingLabel(),
      cannotPurchaseAbroad: () => this.cannotPurchaseAbroad(),
      cannotPlaybackAbroad: () => this.cannotPlaybackAbroad(),
      setRouteProps: this.setRouteProps,
      hideDetailsPanel: () => props.hideDetailsPanel(),
    } as AltiboxAssetContextType;
  }

  get redirectToEpisode() {
    const { props } = this;

    if (!this.isSlideIn && props.currentAsset && props.currentAsset.meta && props.currentAsset.meta.isSeries) {
      if (this.isVodOrSvod) {
        const { parentId, childId } = this.routeProps;
        const isHuaweiIdOrForeignSn =
          props.currentAsset.asset.foreignsn! === parentId || props.currentAsset.asset.id! === parentId;
        const seriesId = isHuaweiIdOrForeignSn ? parentId : props.currentAsset.asset.foreignsn!;
        if (!childId) {
          let episodeToPromote = findEpisodeToPromote(props.currentAsset, props.bookmarks);
          if (!isHuaweiIdOrForeignSn) {
            let potentialEpisodeInsteadOfSeries = findEpisodeBasedOnVodId(props.currentAsset, parentId!);
            episodeToPromote = potentialEpisodeInsteadOfSeries ? potentialEpisodeInsteadOfSeries : episodeToPromote;
          }
          let location = props.location.pathname.split('/').filter((x) => x);
          location.pop();
          let newLocation = location.join('/') + '/' + seriesId;
          return <Redirect to={'/' + newLocation + '/' + episodeToPromote.foreignsn!} />;
        }
      }
      if (this.isPvr) {
        const { childId } = this.routeProps;
        if (!childId) {
          let pvrEpisodeToPromote = findPvrToPromote(props.currentAsset, props.bookmarks) as PvrRecording;
          let pvrLocation = props.location.pathname.split('/').filter((x) => x);
          let newPvrLocation = pvrLocation.join('/') + '/' + getAltiboxAssetId(pvrEpisodeToPromote);
          return <Redirect to={'/' + newPvrLocation} />;
        }
      }
    }
    return undefined;
  }

  get redirectIfAssetDoesNotExists() {
    const { props } = this;
    if (!this.isSlideIn && !props.loading && !this.currentAssetIsSet()) {
      if (this.isPvr) {
        return <Redirect to={routes.pvr.base} />;
      }

      if (this.isVod) {
        return <Redirect to={routes.vod.base} />;
      }

      if (this.isSvod) {
        return <Redirect to={routes.svod.base + getSvodUrlBasedByProvidername(this.svodKiosk!.providerName!)} />;
      }

      if (this.isCatchup) {
        return <Redirect to={routes.programarchive.base} />;
      }
    }
    return undefined;
  }

  get searchConfiguration() {
    const { displayType } = this.props;
    const svodKiosk = this.svodKiosk;
    let derivedSearchConfig = searchConfig.global;
    switch (displayType) {
      case AltiboxAssetDetailsType.CATCHUP:
        derivedSearchConfig = searchConfig.programarchive;
        break;
      case AltiboxAssetDetailsType.VOD:
        derivedSearchConfig = searchConfig.vod;
        break;
      case AltiboxAssetDetailsType.SVOD:
        derivedSearchConfig = searchConfig.svod;
        const kiosk = getSvodUrlBasedByProvidername(svodKiosk!.providerName!);
        const svodBase = routes.svod.base + '/' + kiosk;
        derivedSearchConfig.baseService = svodBase;
        derivedSearchConfig.service = kiosk;
        break;
      case AltiboxAssetDetailsType.PVR:
        derivedSearchConfig = searchConfig.pvr;
        break;
    }
    return derivedSearchConfig;
  }

  get promotedAsset() {
    const { currentAsset, bookmarks } = this.props;
    const { type, asset } = currentAsset;
    const { childId } = this.routeProps;

    switch (type) {
      case AltiboxAssetDetailsType.PVR:
        if (childId) {
          return getAltiboxAssetPromotedAsset(currentAsset, childId) as Program;
        }

        return findPvrToPromote(currentAsset, bookmarks) as PvrRecording;

      case AltiboxAssetDetailsType.CATCHUP:
        const idToPromote = currentAsset.promotedEpisodeId || childId!;
        return getAltiboxAssetPromotedAsset(currentAsset, idToPromote) as Program;

      case AltiboxAssetDetailsType.SVOD:
      case AltiboxAssetDetailsType.VOD:
        if (childId) {
          return getAltiboxAssetPromotedAsset(currentAsset, childId) as VodAsset;
        }

        if (currentAsset.meta!.isSeries) {
          return findEpisodeToPromote(currentAsset, bookmarks);
        }

        return asset as VodAsset;

      default:
        return asset as UnionAssetTypes;
    }
  }

  get routeProps() {
    return this.props.overrideRouteProps ? this.state.routeProps : this.props.match.params;
  }

  get isGeoBlocked() {
    const { props } = this;
    const { authState, channels, userLocation } = props;
    switch (props.displayType) {
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PROGRAM:
      case AltiboxAssetDetailsType.PVR:
        const asset = this.promotedAsset as Program | PvrRecording;
        let channelId = assetIsProgram(asset) ? asset.channelid : asset.channelId;
        const programChannel = getAltiboxAssetChannel(channelId, channels);
        const programPermissions = channelPermissions(programChannel, authState);
        return programPermissions ? programPermissions.geoLocked : false;
      case AltiboxAssetDetailsType.SVOD:
      case AltiboxAssetDetailsType.VOD:
        const vodAsset = this.promotedAsset as VodAsset;
        if (vodAsset.locationCopyrights) {
          return vodAsset.locationCopyrights.indexOf(userLocation!) === -1;
        }
        return true;
      default:
        return false;
    }
  }

  componentDidMount() {
    let { props } = this;
    if (!isMounted) {
      isMounted = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let mountConditions: Promise<any>[] = [];

      // resources needed if home or logged in
      if (props.authState.isHome || props.authState.loggedInWithCredentials) {
        mountConditions.push(props.loadBookmarks());
        if (!props.myContent) {
          props.fetchMyContent();
        }
        if (!props.recordings) {
          mountConditions.push(props.fetchAllRecordings());
        }
      }

      if (isEmpty(props.channels)) {
        mountConditions.push(props.getChannels());
      }

      Promise.all(mountConditions).then(() => {
        return props.loadAsset(this.routeProps, props.displayType, this.svodKiosk).then(() => {
          // TODO: Improve this as this might potentially crash
          setTimeout(() => this.getSharedAssets(), 1000);
        });
      });
    }
  }

  componentWillUnmount() {
    isMounted = false;
    this.props.unloadAsset();
  }

  componentDidUpdate(prevProps: Props) {
    const { props, routeProps } = this;

    if (this.isVodOrSvod) {
      if (routeProps.childId) {
        if (
          props.currentAsset &&
          this.promotedAsset &&
          (!this.state.previousAsset || this.state.previousAsset.foreignsn! !== this.promotedAsset.foreignsn)
        ) {
          props.updateContentPricing(props.currentAsset, this.promotedAsset.id!);
        }
      }
    }
    if ((prevProps.currentAsset || this.props.currentAsset) && this.promotedAsset) {
      let prev = this.state.previousAsset ? getAltiboxAssetId(this.state.previousAsset) : '0';
      let next = this.promotedAsset ? getAltiboxAssetId(this.promotedAsset) : '1';
      if (!isEqual(prev, next)) {
        let timeout = !this.state.previousAsset ? 1000 : 0;
        this.setState(
          {
            previousAsset: this.promotedAsset,
          },
          () => {
            setTimeout(() => {
              this.track();
              this.getSharedAssets();
            }, timeout);
          },
        );
      }
    }
  }

  getSharedAssets() {
    if (this.isVodOrSvod && this.promotedAsset) {
      const { props } = this;
      let assetToUse: AltiboxAsset | UnionAssetTypes = props.currentAsset;
      let isSeries = false;
      if (props.currentAsset && props.currentAsset.meta && props.currentAsset.meta.isSeries) {
        assetToUse = this.promotedAsset;
        isSeries = true;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (props.getSharedAssets(assetToUse, props.displayType, isSeries) as unknown as Promise<any>).then(
        (sharedAssets) => {
          if (!every(sharedAssets, isEmpty)) {
            this.setState({ sharedAssets: sharedAssets });
          } else {
            this.setState({ sharedAssets: [] as SharedAssets });
          }
        },
      );
    }
  }

  setRouteProps = (routeProps: DetailsRouteProps): void => {
    const { currentAsset } = this.props;
    if (currentAsset) {
      delete currentAsset.promotedEpisodeId;
    }

    this.setState((prevState) => ({ ...prevState, routeProps: { ...prevState.routeProps, ...routeProps } }));
  };

  toggleFavourite = (favourite: Favourite, toggleState: string) => {
    const { props, state, tracking } = this;
    const { asset, catalog } = favourite;
    const { id, type } = asset;
    if (!id) {
      return;
    }
    if (props.authState.loggedInWithCredentials) {
      tracking.trackEvent(
        tracking.getCurrentCategory(),
        toggleState === '1' ? GAaction.favoriteAdd : GAaction.favoriteRemove,
        this.getTrackingLabel(),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let toggleFavourites: Promise<any>[] = [];
      if (state.sharedAssets) {
        Object.keys(state.sharedAssets).forEach((keys) => {
          state.sharedAssets![keys].forEach((sharedAsset: SharedAsset) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let sharedAssetType = (sharedAsset as any).asset.type;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let sharedAssetKiosk = (sharedAsset as any).kiosk;
            let sharedAssetFavoCatalog = sharedAsset.favoCatalog;
            toggleFavourites.push(
              toggleAddRemoveFavourite(
                id,
                toggleState,
                sharedAssetType as ContentType,
                sharedAssetFavoCatalog,
                sharedAssetKiosk,
              ),
            );
          });
        });
      }
      toggleFavourites.push(toggleAddRemoveFavourite(id, toggleState, type as ContentType, catalog, this.svodKiosk));
      Promise.all(toggleFavourites).then(() =>
        props.fetchAllFavoriteIds().then(() => props.loadAsset(this.routeProps, props.displayType, this.svodKiosk)),
      );
    } else {
      this.props.showLoginModal();
    }
  };

  cannotPurchaseAbroad = () => {
    this.props.showAlert({
      title: i18n.t<string>('unavailable'),
      text: [i18n.t<string>('geolocked_purchase')],
      type: AlertType.INFO,
    });
    this.tracking.trackCurrentService(GAaction.rentOrBuyNotPossibleAbroad);
  };

  cannotPlaybackAbroad = () => {
    this.props.showAlert({
      title: i18n.t<string>('unavailable'),
      text: [i18n.t<string>('geolocked_playback')],
      type: AlertType.INFO,
    });
    this.tracking.trackCurrentService(GAaction.assetGeoblocked);
  };

  purchaseAsset = (assetToPurchase: VodAsset) => {
    const { authState } = this.props;
    if (authState.isGuest || !authState.loggedInWithCredentials) {
      this.props.showLoginModal();
    } else {
      this.setState({
        purchaseAsset: assetToPurchase,
        isPurchasing: true,
      });
      this.tracking.trackEvent(this.tracking.getCurrentCategory(), GAaction.aboutToSpend, this.getTrackingLabel());
    }
  };

  hasPurchased = () => {
    const { props } = this;
    (props.fetchMyContent() as unknown as Promise<void>).then(() => {
      props.loadAsset(this.routeProps, props.displayType, this.svodKiosk);
    });
  };

  closePurchaseModal = () => {
    this.setState({
      isPurchasing: false,
    });
  };

  getAccessToSvod = () => {
    const { authState } = this.props;
    if (authState.isGuest) {
      this.props.showLoginModal();
    } else {
      const text = ScriptService.isDKUser()
        ? i18n.t<string>('missing access dk message', {
            service: this.svodKiosk?.providerName ? this.svodKiosk.providerName : i18n.t<string>('programarchive'),
          })
        : i18n.t<string>('missing access message', {
            service: this.svodKiosk?.providerName ? this.svodKiosk.providerName : i18n.t<string>('programarchive'),
          });

      this.props.showAlert({
        title: i18n.t<string>('how to get access'),
        text: [text],
        type: ScriptService.isDKUser() ? AlertType.GET_ACCESS_DK : AlertType.GET_ACCESS,
        link: ScriptService.isDKUser() ? getFooterUrl(AltiboxURLs.CUSTOMER_SERVICE) : getFooterUrl(AltiboxURLs.MY_PAGE),
      });
    }
  };

  deleteRecordings = (type: DeleteType) => {
    let currentSeries = this.props.currentAsset.asset as PvrRecording;
    const recordingToDelete = this.promotedAsset as PvrRecording;
    switch (type) {
      case DeleteType.SINGLE:
        if (recordingToDelete) {
          this.props.markRecordingForDeletion(recordingToDelete);
        }
        break;
      case DeleteType.SEASONS:
        this.props.setSelectedSeries(currentSeries);
        this.props.showDeleteSeasonModal();
        break;
      case DeleteType.SERIES:
        this.props.setSelectedSeries(currentSeries);
        this.props.showDeleteSeriesModal();
        break;
      case DeleteType.ALL:
        if (recordingToDelete.isSingle) {
          this.deleteRecordings(DeleteType.SINGLE);
        } else {
          this.props.showDeleteRecordingsModal();
        }
    }
  };

  currentAssetIsSet() {
    return Boolean(this.props.currentAsset) && Boolean(this.promotedAsset);
  }

  shouldLoadDetailsPanel() {
    const { loggedInWithCredentials, isHome, isGuest } = this.props.authState;
    const { myContent } = this.props;
    return this.currentAssetIsSet() && (((loggedInWithCredentials || isHome) && myContent) || isGuest);
  }

  track() {
    if (this.currentAssetIsSet()) {
      this.tracking.trackEvent(this.tracking.getCurrentCategory(), GAaction.openDetailsPanel, this.getTrackingLabel());
    }
  }

  getTrackingLabel() {
    const { displayType, currentAsset } = this.props;
    const { meta } = currentAsset;
    const promotedAsset = this.state.previousAsset as UnionAssetTypes;
    switch (displayType) {
      case AltiboxAssetDetailsType.PVR:
      case AltiboxAssetDetailsType.PROGRAM:
      case AltiboxAssetDetailsType.CATCHUP:
        let output = '';
        if (this.isPvr) {
          output = (promotedAsset as PvrRecording).channelName + ' - ' + getAltiboxAssetTitle(currentAsset);
        } else {
          let channel = getAltiboxAssetChannel(getChannelid(promotedAsset), this.props.channels);
          let channelName = channel ? channel.name + ' - ' : '';
          output = channelName + getAltiboxAssetTitle(currentAsset);
        }
        if (meta && meta.isSeries) {
          output = output + ' - ' + getAltiboxAssetTitleSuffix(currentAsset, this.routeProps, promotedAsset);
        }
        return output;
      default:
        let asset = getAltiboxAssetTitle(currentAsset);
        if (meta && meta.isSeries) {
          asset = asset + ' ' + getAltiboxAssetTitleSuffix(currentAsset, this.routeProps, promotedAsset);
        }
        return asset + ' - ' + getAltiboxAssetId(promotedAsset);
    }
  }

  renderHelmet() {
    const { currentAsset, displayType } = this.props;
    const { meta } = currentAsset;
    const isSeries = Boolean(meta?.isSeries);
    const title = isSeries
      ? getAltiboxAssetSeriesTitle(currentAsset, this.promotedAsset)
      : getAltiboxAssetTitle(wrapAssetAsAltiboxAsset(this.promotedAsset)) ?? '';

    switch (displayType) {
      case AltiboxAssetDetailsType.PROGRAM:
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.SVOD:
      case AltiboxAssetDetailsType.VIDEO_VOD:
      case AltiboxAssetDetailsType.VOD:
        const jsonLD = isSeries
          ? helmetJsonLdProp<TVEpisode>(generateEpisodeJsonLD(currentAsset, this.promotedAsset))
          : helmetJsonLdProp<Movie>(generateSingleMovieJsonLD(currentAsset));
        return (
          <Helmet script={[jsonLD]}>
            <title>{title}</title>
          </Helmet>
        );
      default:
        return (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        );
    }
  }

  renderHeader() {
    return (
      <>
        <StickyHeader searchConfig={this.searchConfiguration} />
        <Header
          displayType={HeaderDisplayType.NoGradient}
          interactionType={HeaderInteractionType.Full}
          hideTitle={true}
        />
      </>
    );
  }

  render() {
    if (this.redirectToEpisode) {
      return this.redirectToEpisode;
    }

    if (this.redirectIfAssetDoesNotExists) {
      return this.redirectIfAssetDoesNotExists;
    }
    return (
      <>
        {this.shouldLoadDetailsPanel() ? (
          <>
            {!this.isSlideIn && this.currentAssetIsSet() ? (
              <>
                {this.renderHeader()}
                {this.renderHelmet()}
              </>
            ) : null}
            <AltiboxAssetContext.Provider value={this.detailsContext}>
              <PvrModals
                currentAsset={this.props.currentAsset}
                promotedAsset={this.promotedAsset}
                deleteTypeCallback={this.deleteRecordings}
              />
              <Details />
              {this.state.isPurchasing ? (
                <PurchaseModal
                  assetTitle={getAltiboxAssetTitle(this.props.currentAsset)}
                  modalHeroBackground={getAltiboxAssetCover(this.props.currentAsset)}
                  seasonAndEpisodeShorthand={getAltiboxAssetTitleSuffix(this.props.currentAsset, this.routeProps)}
                  purchaseOptions={this.props.currentAsset.meta!.purchaseOptions as VodContentProduct[]}
                  purchaseAsset={this.state.purchaseAsset}
                  onCloseModalCallback={this.closePurchaseModal}
                  onAssetIsBoughtCallback={this.hasPurchased}
                  isVisible={this.state.isPurchasing}
                  getTrackingLabel={this.getTrackingLabel}
                />
              ) : null}
            </AltiboxAssetContext.Provider>
          </>
        ) : (
          <div className="temporary-loading-container">
            <Spinner wrapInContainer={true} />
          </div>
        )}
        {!this.isSlideIn ? (
          <>
            <Footer />
          </>
        ) : null}
      </>
    );
  }
}

const ConnectedDetailsView = withRouter(
  connect(
    (state: RootState) => ({
      userLocation: state.authReducer.auth ? state.authReducer.auth.location : '',
      currentAsset: state.detailsReducer.currentAsset as AltiboxAsset,
      loading: state.detailsReducer.loading,
      isSlidingOut: state.detailsReducer.isSlidingOut,
      bookmarks: state.app.bookmarks,
      channels: state.channelsReducer.channels,
      authState: state.authReducer,
      myContent: state.vodReducer.myContent,
      recordings: state.pvrReducer.recordings,
      singleRecordings: state.channelsReducer.singleRecordings,
      seriesRecordings: state.channelsReducer.seriesRecordings,
      svodKiosk: state.svodReducer.svodKiosk,
      svodKiosks: state.app.svodKiosks,
      programGenres: state.channelsReducer.genres,
      defaultImagePath: state.app.defaultImagePath,
      clickedId: state.detailsReducer.detailsPanel?.clickedId,
      recordingDeleted: state.pvrReducer.recordingDeleted,
    }),
    (dispatch) => ({
      loadBookmarks: () => dispatch(loadAllUsersBookmarks()),
      hideDetailsPanel: () => {
        dispatch(setSlideOutStatus(true));
        setTimeout(() => {
          dispatch(hideDetailsPanel());
          dispatch(setSlideOutStatus(false));
        }, 200);
      },
      updateContentPricing: (_1: AltiboxAsset, _2: string) => dispatch(updateContentPricing(_1, _2)),
      loadAsset: (routeProps: DetailsRouteProps, type: AltiboxAssetDetailsType, svodKiosk?: PortalMenu) =>
        dispatch(loadAsset(routeProps, type, svodKiosk)),
      unloadAsset: () => dispatch(unloadAsset()),
      getChannels: () => dispatch(getChannelsIncludingIPTV()),
      fetchMyContent: () => dispatch(fetchMyContent(-1)),
      showAlert: (alert: AlertData) => dispatch(showAlert(alert)),
      showLoginModal: () => dispatch(showLoginModal()),
      fetchAllRecordings: () => dispatch(fetchAllRecordings()),
      markRecordingForDeletion: (recording: PvrRecording) => dispatch(markRecordingForDeletion(recording)),
      showDeleteSeasonModal: () => dispatch(markSeasonForDeletion(true)),
      showDeleteSeriesModal: () => dispatch(markSeriesForDeletion(true)),
      showDeleteRecordingsModal: () => dispatch(showAggregatedDeleteModal(true)),
      setSelectedSeries: (series: PvrRecording) => dispatch(setCurrentPvrSeries(series)),
      getSharedAssets: (_1: AltiboxAsset | UnionAssetTypes, _2: AltiboxAssetDetailsType, _3: boolean) =>
        dispatch(getSharedAssets(_1, _2, _3)),
      fetchAllFavoriteIds: () => dispatch(fetchAllFavoriteIds()),
    }),
  )(DetailsView),
);

export default ConnectedDetailsView;

export function SlideInDetails({
  routeProps,
  displayType,
  svodKiosk,
  isSlidingOut,
}: {
  routeProps: DetailsRouteProps;
  displayType: AltiboxAssetDetailsType;
  svodKiosk?: PortalMenu;
  isSlidingOut?: boolean;
}) {
  const dispatch = useDispatch();
  const slideInRef = useRef<HTMLDivElement>(null);

  const handleSlideOut = () => {
    dispatch(setSlideOutStatus(true));
    setTimeout(() => {
      dispatch(hideDetailsPanel());
      dispatch(setSlideOutStatus(false));
    }, 200);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (e.key === Key.Escape) {
      handleSlideOut();
    }

    const slideIn = slideInRef.current;

    if (e.key !== Key.Tab || !slideIn) {
      return;
    }

    const focusableElements = Array.from(
      slideIn.querySelectorAll<HTMLElement>('a[href], button:not([disabled]), [tabindex]'),
    ).filter((el) => el.offsetParent !== null);

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      return e.preventDefault();
    }

    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      e.preventDefault();
    }
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = () => handleSlideOut();

  return (
    <div onClick={handleClick} className={styles.overlay}>
      <div
        tabIndex={0}
        ref={slideInRef}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={handleKeyDown}
        className={isSlidingOut ? styles.slideOut : styles.slideIn}
      >
        <ConnectedDetailsView overrideRouteProps={routeProps} overrideSvodKiosk={svodKiosk} displayType={displayType} />
      </div>
    </div>
  );
}
