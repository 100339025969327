import {
  getEpgGenres,
  getProgramArchiveFrontPageGenres,
  getProgramsForGenre,
  getProgramDetail,
} from '../../../api/programarchive';
import {
  Program,
  Channel,
  EpgGenre,
  DispatchType,
  ProgramArchiveList,
  GetStateType,
  ProgramArchiveListResponse,
  AltiboxAsset,
  Genre,
  ChannelFilter,
  SeriesRelation,
  AuthReducerState,
  RootState,
  PopularContentChannelTimespan,
} from '../../../interfaces';
import { getCatchupAssetWithLock } from '../../../utils/programArchiveUtils';
import channelPermissions from '../../../utils/channelPermissions';
import { seriesRelations, getAllPlaybills } from '../../../api/tv';

import * as popularContentApi from '../../../api/popularContent';
import { isEmpty } from 'lodash';

export enum TypeKeys {
  SET_CURRENT_PROGRAM_ARCHIVE_ASSET = 'SET_CURRENT_PROGRAM_ARCHIVE_ASSET',
  SET_CURRENT_PROGRAM_ARCHIVE_SERIES = 'SET_CURRENT_PROGRAM_ARCHIVE_SERIES',
  SET_PROGRAM_ARCHIVE_GENRE_LIST = 'SET_PROGRAM_ARCHIVE_GENRE_LIST',
  SET_PROGRAM_ARCHIVE_FRONT_PAGE_ASSETS = 'SET_PROGRAM_ARCHIVE_FRONT_PAGE_ASSETS',
  SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST = 'SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST',
  SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST_LOADING = 'SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST_LOADING',
  SET_MOST_WATCHED_CATCHUP = 'SET_MOST_WATCHED_CATCHUP',
}

export type ActionTypes =
  | SetProgramArchiveGenresLists
  | SetProgramArchiveFrontPageLists
  | SetCurrentProgramArchiveGenreLists
  | SetCurrentProgramArchiveAsset
  | SetCurrentProgramArchiveGenreListsLoading
  | SetMostWatchedCatchup;

interface SetCurrentProgramArchiveAsset {
  type: TypeKeys.SET_CURRENT_PROGRAM_ARCHIVE_ASSET;
  currentProgramArchiveAsset: AltiboxAsset;
}

interface SetCurrentProgramArchiveGenreListsLoading {
  type: TypeKeys.SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST_LOADING;
  currentGenreListIsLoading: boolean;
}

function setCurrentProgramArchiveGenreListsLoading(isLoading: boolean) {
  return {
    type: TypeKeys.SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST_LOADING,
    currentGenreListIsLoading: isLoading,
  };
}

interface SetProgramArchiveGenresLists {
  type: TypeKeys.SET_PROGRAM_ARCHIVE_GENRE_LIST;
  epgGenres?: EpgGenre[];
}

function setProgramArchiveGenreLists(epgGenres: EpgGenre[]): SetProgramArchiveGenresLists {
  return {
    type: TypeKeys.SET_PROGRAM_ARCHIVE_GENRE_LIST,
    epgGenres,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadProgramArchiveGenreLists(areaId: string, bossId: string): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    return getEpgGenres(areaId, bossId).then((response: EpgGenre[]) => {
      dispatch(setProgramArchiveGenreLists(response));
    });
  };
}

interface SetProgramArchiveFrontPageLists {
  type: TypeKeys.SET_PROGRAM_ARCHIVE_FRONT_PAGE_ASSETS;
  epgGenresList: ProgramArchiveList[];
}

function setProgramArchiveLists(epgGenresList: ProgramArchiveList[]): SetProgramArchiveFrontPageLists {
  return {
    type: TypeKeys.SET_PROGRAM_ARCHIVE_FRONT_PAGE_ASSETS,
    epgGenresList,
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadProgramArchiveLists(filterGenres: Genre[], archiveGenres: EpgGenre[], channels: Channel[]): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    return getGenres(archiveGenres, filterGenres, channels, getState, dispatch);
  };
}
interface SetCurrentProgramArchiveGenreLists {
  type: TypeKeys.SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST;
  currentProgramArchiveGenrePrograms?: AltiboxAsset[];
}
function setCurrentProgramArchiveGenreList(
  currentProgramArchiveGenrePrograms: AltiboxAsset[] | undefined,
): SetCurrentProgramArchiveGenreLists {
  return {
    type: TypeKeys.SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST,
    currentProgramArchiveGenrePrograms,
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearCurrentProgramArchiveGenre(): any {
  return function (dispatch: DispatchType) {
    dispatch(setCurrentProgramArchiveGenreList(undefined));
  };
}

function filterOutChannelsNotInSubscription(channels: Channel[], authReducer: AuthReducerState) {
  let channelsToLoad: ChannelFilter[] = [];
  channels.forEach((channel: Channel) => {
    let permissions = channelPermissions(channel, authReducer);
    if (!permissions.notInSubscription) {
      channelsToLoad.push({
        channelId: channel.contentId,
        type: 'CHANNEL',
      });
    }
  });
  return channelsToLoad;
}

export function loadCurrentProgramArchiveGenre(
  epgGenres: string,
  orderType: string,
  filterGenres: Genre[],
  channels: Channel[],
  offset: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    let channelsToLoad = filterOutChannelsNotInSubscription(channels, getState().authReducer);
    dispatch(setCurrentProgramArchiveGenreListsLoading(true));
    return getProgramsForGenre(epgGenres, orderType, filterGenres, channelsToLoad, offset).then(
      (currentProgramArchiveGenrePrograms) => {
        let genreProgramList = (currentProgramArchiveGenrePrograms as Program[]).map((program: Program) => {
          return getCatchupAssetWithLock(program, channels, getState().authReducer);
        });
        dispatch(setCurrentProgramArchiveGenreList(genreProgramList));
        dispatch(setCurrentProgramArchiveGenreListsLoading(false));
      },
    );
  };
}

function getGenres(
  genres: EpgGenre[],
  filterGenres: Genre[],
  channels: Channel[],
  getState: GetStateType,
  dispatch: DispatchType,
) {
  let channelsToLoad = filterOutChannelsNotInSubscription(channels, getState().authReducer);
  return getProgramArchiveFrontPageGenres(genres, filterGenres, channelsToLoad).then((response) => {
    let theProgramLists: ProgramArchiveList[] = [];
    (response as ProgramArchiveListResponse[]).forEach((list: ProgramArchiveListResponse, index: number) => {
      if (list.msg && list.msg.playbillList) {
        let genreProgramList = list.msg.playbillList.map((program: Program) => {
          return getCatchupAssetWithLock(program, channels, getState().authReducer);
        });

        theProgramLists.push({
          programs: genreProgramList,
          genre: genres[index],
        });
      }
    });

    dispatch(setProgramArchiveLists(theProgramLists));
  });
}

export function getSeriesData(seriesId: string, channels: Channel[], programIdToPromote: string, state: RootState) {
  return seriesRelations([seriesId]).then((seriesRelation) => {
    const ids = (seriesRelation as SeriesRelation[]).map((program: SeriesRelation) => {
      return program.programId;
    });
    if (!ids) {
      return undefined;
    }
    let episodeToPromote: Program;
    return getProgramDetail(ids.join(','), false)
      .then((programs) => {
        programs = programs as Program[];
        if (!isEmpty(programs)) {
          const foundEpisode = programs.find((x) => x.id === programIdToPromote);
          if (foundEpisode) {
            episodeToPromote = foundEpisode;
          } else {
            return getAllPlaybills(Array.from(new Set(programs.map((p) => p.channelid)))).then(
              async (playbills: Record<string, Program[]>) => {
                let playBillForChannel = Object.keys(playbills)
                  .map((x) => playbills[x] as Program[])
                  .reduce((result, next) => [...result, ...next], []);

                const playbillEpisodeToPromote = playBillForChannel.find((x) => x.id === programIdToPromote);
                if (playbillEpisodeToPromote) {
                  episodeToPromote = playbillEpisodeToPromote;
                } else {
                  episodeToPromote = (programs as Program[])[0];
                }
                return organizeProgramsInSeasons(programs, channels, state.authReducer);
              },
            );
          }
        }
        return organizeProgramsInSeasons(programs, channels, state.authReducer);
      })
      .then((seasons) => {
        return createSeriesFromProgram(seasons as Program, episodeToPromote, channels, state.authReducer);
      });
  });
}

function sortEpisodesOf(season: AltiboxAsset[]) {
  return season.sort((a: AltiboxAsset, b: AltiboxAsset) => {
    const aAsset = a.asset as Program;
    const bAsset = b.asset as Program;
    return Number(bAsset.subNum) - Number(aAsset.subNum);
  });
}

function removeDuplicates(season: AltiboxAsset[]) {
  let newSeason: AltiboxAsset[] = [];
  season.forEach((assetToFind: AltiboxAsset) => {
    const programToFind = assetToFind.asset as Program;
    const found = newSeason.find((assetToCheckIfExists: AltiboxAsset) => {
      const programToCheckIfExists = assetToCheckIfExists.asset as Program;
      if (
        programToFind.chapterNO === '1' &&
        programToFind.seriesID === programToCheckIfExists.seriesID &&
        programToFind.subNum &&
        programToCheckIfExists.subNum &&
        programToFind.subNum === programToCheckIfExists.subNum &&
        programToFind.channelid === programToCheckIfExists.channelid
      ) {
        return true;
      }
      return false;
    });

    if (!found) {
      newSeason.push(assetToFind);
    }
  });
  return newSeason;
}

export function createSeriesFromProgram(
  seasons: Object,
  program: Program,
  channels: Channel[],
  authState: AuthReducerState,
) {
  if (seasons && program) {
    let series: AltiboxAsset = getCatchupAssetWithLock(program, channels, authState);
    series.seasons = [];
    series.title = program.name;
    series.id = program.seriesID;

    Object.keys(seasons).forEach((seasonNumber: string) => {
      let season = seasons[seasonNumber];
      if (season.length > 0 && series.seasons) {
        season = sortEpisodesOf(season);
        season = removeDuplicates(season);

        series.seasons.push({
          content: season,
          seasonNumber: seasonNumber,
        });
      }
    });

    return series;
  } else {
    return undefined;
  }
}

function organizeProgramsInSeasons(
  programs: Program[] | Program | undefined,
  channels: Channel[],
  authReducer: AuthReducerState,
) {
  // is an array of programs
  if (programs && 'length' in programs) {
    return groupBySeason(programs, channels, authReducer);
  }
  return programs;
}

function groupBySeason(programs: Program[], channels: Channel[], authReducer: AuthReducerState) {
  let property = 'seasonNum';
  return programs.reduce((prevProgram: Program, nextProgram: Program) => {
    if (!prevProgram[nextProgram[property]]) {
      prevProgram[nextProgram[property]] = [];
    }
    prevProgram[nextProgram[property]].push(getCatchupAssetWithLock(nextProgram, channels, authReducer));
    return prevProgram;
  }, {} as Program);
}

export function getProgramData(programId: string, channels: Channel[], authReducer: AuthReducerState) {
  return getProgramDetail(programId).then((program) => {
    if (program) {
      return getCatchupAssetWithLock(program as Program, channels, authReducer);
    } else {
      return undefined;
    }
  });
}
export interface SetMostWatchedCatchup {
  type: TypeKeys.SET_MOST_WATCHED_CATCHUP;
  mostWatchedCatchup: Program[];
}
export function setMostWatchedCatchup(mostWatchedCatchup: Program[]): SetMostWatchedCatchup {
  return {
    type: TypeKeys.SET_MOST_WATCHED_CATCHUP,
    mostWatchedCatchup,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMostWatchedCatchup(): any {
  return function (dispatch: DispatchType) {
    popularContentApi
      .fetchMostWatchedCatchup(PopularContentChannelTimespan.TWENTYFOUR_HOURS)
      .then((mostWatchedCatchup) => {
        dispatch(setMostWatchedCatchup(mostWatchedCatchup));
      });
  };
}
