import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, StreamingServicePortal } from '../interfaces';
import { getStreamingServices } from '../api/svod';

export default function useFetchStreamingServices() {
  const auth = useSelector((state: RootState) => state.authReducer.auth);
  const [streamingPortals, setStreamingPortals] = useState<StreamingServicePortal[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (auth) {
        const bossId = auth.bossID;
        const areaId = auth.areaid;

        await getStreamingServices(bossId, areaId).then((res) => {
          setStreamingPortals(res);
          setLoading(false);
        });
      }
    })();
  }, [auth]);

  return { streamingPortals, loading };
}
