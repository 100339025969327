import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import ChannelList from './channelList';
import MiniEpg from './miniEpg';
import SvodService from './svodService';

import sortChannels from '../../utils/sortChannels';
import { fetchSvodPromotedAssets as fetchSvodPromotedAssetsAction } from '../../views/svod/actions';
import {
  fetchAllFavoriteIds,
  fetchMiniEpgPlaybills,
  hideChannelList as hideChannelListAction,
} from '../../views/tv/actions';

import {
  FavoritePrograms,
  MiniEpgPlaybills,
  RootState,
  SvodChannel,
  AssetsBySvodKioskIdentifier,
  DetailsPanelType,
  ApiAuthResponse,
} from '../../interfaces';

import './style.scss';
import { hideDetailsPanel } from '../../views/details/actions';

interface Props {
  channelListVisible: boolean;
  channels: SvodChannel[];
  currentChannel?: SvodChannel;
  playbills: MiniEpgPlaybills;
  favoritePrograms: FavoritePrograms;
  promotedAssets?: AssetsBySvodKioskIdentifier;
  imageBase: string;
  detailsPanel?: DetailsPanelType;
  loadMiniEpgPlaybills: (auth: ApiAuthResponse | undefined, channelIds: string[]) => void;
  fetchFavoritePrograms: () => void;
  fetchSvodPromotedAssets: () => void;
  hideChannelList: () => void;
}

function PlayerEpg({
  channelListVisible,
  channels,
  currentChannel,
  playbills,
  favoritePrograms,
  promotedAssets,
  imageBase,
  detailsPanel,
  loadMiniEpgPlaybills,
  fetchFavoritePrograms,
  fetchSvodPromotedAssets,
  hideChannelList,
}: Props) {
  const auth = useSelector((state: RootState) => state.authReducer.auth);

  const [shouldShowEpg, setShouldShowEpg] = useState(true);
  const [focusedChannel, setFocusedChannel] = useState(currentChannel);
  const [panicking, setPanicking] = useState(false);

  const hideEpg = () => setShouldShowEpg(false);
  const showEpg = () => setShouldShowEpg(true);

  const panic = (() => {
    let timeoutId = 0;

    return () => {
      hideChannelList();
      setPanicking(true);

      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        setPanicking(false);
      }, 250);
    };
  })();

  useEffect(
    () => {
      window.addEventListener('resize', panic);
      return () => window.removeEventListener('resize', panic);
    },
    /* eslint-disable-next-line */
    [],
  );

  useEffect(() => {
    if (!channelListVisible && currentChannel) {
      setFocusedChannel(currentChannel);
    }
  }, [channelListVisible, currentChannel]);

  useEffect(
    () => {
      loadMiniEpgPlaybills(
        auth,
        channels.map((channel) => channel.contentId),
      );
      fetchFavoritePrograms();

      if (!promotedAssets) {
        fetchSvodPromotedAssets();
      }
    },
    /* eslint-disable-next-line */
    [],
  );

  if (!focusedChannel) {
    return null;
  }

  const svodPromotions =
    focusedChannel.svodKiosk && promotedAssets && promotedAssets[String(focusedChannel.svodKiosk.identifier)]
      ? promotedAssets[String(focusedChannel.svodKiosk.identifier)]
      : undefined;

  return (
    <div className={`playerEpg ${detailsPanel ? 'fade-to-transparent-4500ms' : ''}`}>
      {!panicking && (
        <ChannelList
          channels={channels}
          focusedChannel={focusedChannel}
          setFocusedChannel={setFocusedChannel}
          playbills={playbills}
          showEpg={showEpg}
          hideEpg={hideEpg}
          shouldShowEpg={shouldShowEpg}
          visible={channelListVisible}
          panic={panic}
        />
      )}
      {channelListVisible && shouldShowEpg && (
        <>
          <MiniEpg
            programs={playbills[focusedChannel.contentId] || []}
            channel={focusedChannel}
            favorites={favoritePrograms}
          />
          {focusedChannel.svodKiosk && (
            <SvodService imageBase={imageBase} kiosk={focusedChannel.svodKiosk} promotions={svodPromotions} />
          )}
        </>
      )}
    </div>
  );
}

export default connect(
  (state: RootState) => {
    const {
      channelsReducer: {
        channels,
        channelListVisible,
        currentChannel,
        currentProgram,
        miniEpgPlaybills,
        favoritePrograms,
      },
      detailsReducer: { detailsPanel },
      authReducer,
      app,
      svodReducer,
    } = state;

    const _channels = sortChannels(channels, authReducer)
      .reverse()
      .map((channel) => ({
        ...channel,
        svodKiosk: app.svodKiosks.find((kiosk) => kiosk.associatedChannelIds?.includes(String(channel.contentId))),
      }));

    return {
      channelListVisible,
      currentProgram,
      favoritePrograms,
      detailsPanel,
      imageBase: app.defaultImagePath,
      playbills: miniEpgPlaybills,
      channels: _channels,
      currentChannel: currentChannel
        ? _channels.find((channel) => channel.contentId === currentChannel.contentId)
        : undefined,
      promotedAssets: svodReducer.promotedAssets,
    };
  },
  (dispatch) => ({
    loadMiniEpgPlaybills: (auth: ApiAuthResponse | undefined, channelIds: string[]) =>
      dispatch(fetchMiniEpgPlaybills(auth, channelIds)),
    fetchFavoritePrograms: () => dispatch(fetchAllFavoriteIds()),
    fetchSvodPromotedAssets: () => dispatch(fetchSvodPromotedAssetsAction()),
    hideChannelList: () => {
      dispatch(hideChannelListAction());
      dispatch(hideDetailsPanel());
    },
  }),
)(PlayerEpg);
