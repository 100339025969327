import { FC } from 'react';
import { AltiboxAsset, UnionAssetTypes, DeleteType } from '../../interfaces';
import {
  getAltiboxAssetCover,
  getAltiboxAssetTitleSuffix,
  getAltiboxAssetTitle,
  isPvr,
} from '../../utils/altiboxassetUtils';
import { useAltiboxAsset } from '../../views/details/AltiboxAssetContext';
import DeleteModal from '../../views/pvr/DeleteModal';
import DeleteSeasonModal from '../../views/pvr/DeleteSeasonModal';
import DeleteSeriesModal from '../../views/pvr/DeleteSeriesModal';
import DeleteSingleRecordingModal from '../../views/pvr/DeleteSingleRecordingModal';

interface PvrModalsProps {
  currentAsset: AltiboxAsset;
  promotedAsset: UnionAssetTypes;
  deleteTypeCallback: (type: DeleteType) => void;
}

export const PvrModals: FC<PvrModalsProps> = ({ currentAsset, promotedAsset, deleteTypeCallback }) => {
  const { authState, routeProps } = useAltiboxAsset();

  const backgroudPicture = getAltiboxAssetCover(currentAsset);
  const titleSuffix = getAltiboxAssetTitleSuffix(currentAsset, routeProps);
  const titleSeasonEpisode = `${getAltiboxAssetTitle(currentAsset)} - ${titleSuffix}`;

  if (!authState.isGuest && isPvr(promotedAsset)) {
    return (
      <>
        <DeleteModal
          title={titleSeasonEpisode}
          modalHeroBackground={backgroudPicture}
          deleteTypeCallback={deleteTypeCallback}
        />
        <DeleteSingleRecordingModal modalHeroBackground={backgroudPicture} />
        <DeleteSeasonModal modalHeroBackground={backgroudPicture} />
        <DeleteSeriesModal modalHeroBackground={backgroudPicture} />
      </>
    );
  }
  return null;
};
