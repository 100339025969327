import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  RootState,
  VodAsset,
  VodListResponse,
  PortalMenu,
  ApiAuthResponse,
  VodAssetDisplayMode,
  ProgramContentDetailResponse,
  Channel,
  AltiboxAssetDisplayTypes,
  ContentDetailResponse,
  HeaderDisplayType,
  HeaderInteractionType,
  SearchConfig,
  AltiboxAsset,
  Program,
  AuthReducerState,
  DispatchResponse,
  DispatchType,
} from '../../interfaces';
import Header from '../app/Header';
import CategoryHelmet from '../../components/Helmets/CategoryHelmet';
import { getAllFavouriteAssets, getProgramArchiveFavourites, getEPGFavouriteId } from '../../api/favorites';
import Spinner from '../../components/Spinner';
import { getVodFromFavourites, getSvodFromFavourites } from '../../utils/favourites';
import { routes, swimlaneSettings } from '../../config';
import { getSvodAsset } from '../../utils/svodUtil';
import './style.scss';
import AltiboxAssetViewList from '../../components/UI/AltiboxAssetViewList';
import { getChannels, getFavoriteChannels } from '../tv/actions';
import { getSearchCatchupAsset as getFavCatchupAsset } from '../../utils/programArchiveUtils';
import Footer from '../../components/Footer';
import { addBadgeOnOwnedSeriesAssets, getVodAsset, prepareMyContent } from '../../utils/vodUtils';
import { fetchMyContent } from '../vod/actions';
import StickyHeader from '../app/StickyHeader';
import i18n from '../../i18n';
import ContinueWatching from '../app/ContinueWatching';
import { isEmpty } from 'lodash';
import ChannelsBanner from '../../components/ChannelsBanner';
import { showLoginModal } from '../app/actions';

interface Props extends RouteComponentProps<{}, {}, { toTop?: boolean }> {
  isGuest: boolean;
  isHome: boolean;
  svodKiosks: PortalMenu[];
  mySeries: VodAsset[] | undefined;
  mySeasons: VodAsset[] | undefined;
  myContent: VodAsset[] | undefined;
  defaultImagePath: string;
  auth: ApiAuthResponse | undefined;
  searchConfig: SearchConfig;
  loggedInWithCredentials: boolean;
  channels: Channel[];
  favoriteChannels: Channel[];
  authReducer: AuthReducerState;
  getChannels: () => Promise<void>;
  getFavoriteChannels: () => Promise<void>;
  loadContent: () => Promise<void>;
  showLoginModal: () => DispatchResponse;
}

interface State {
  favouriteAssets: VodAsset[];
  favouriteVodAssets: VodAsset[];
  favouriteProgramArchiveAssets: AltiboxAsset[];
  hasLoaded: boolean;
  fetchingAssets: boolean;
}

class MyContent extends Component<Props, State> {
  state: State = {
    favouriteAssets: [],
    favouriteVodAssets: [],
    favouriteProgramArchiveAssets: [] as AltiboxAsset[],
    hasLoaded: false,
    fetchingAssets: true,
  };

  get myAltiboxAssets() {
    let myAltiboxAssets: AltiboxAsset[] = [];
    if (this.props.myContent && this.props.mySeries && this.props.mySeasons) {
      const vods = prepareMyContent(this.props.myContent, this.props.mySeries, this.props.mySeasons);
      myAltiboxAssets = vods
        ? vods.map((asset) => {
            return getVodAsset(asset);
          })
        : [];
    }
    return myAltiboxAssets;
  }

  get svodFavourites() {
    if (this.props.svodKiosks) {
      const svods = getSvodFromFavourites(this.props.svodKiosks, this.state.favouriteAssets);
      return this.alterPotentialSharedAssetsFromSvod(svods).map((x) => {
        if (x.hasOwnProperty('isSharedAsset')) {
          return getVodAsset(x, true);
        } else {
          return getSvodAsset(x, this.props.svodKiosks, this.props.defaultImagePath);
        }
      });
    }
    return [];
  }

  get vodFavourites() {
    return this.prepareVods(this.state.favouriteVodAssets);
  }

  componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state.toTop) {
      window.scrollTo(0, 0);
    }
    this.props.loadContent();
    if (isEmpty(this.props.favoriteChannels)) {
      this.props.getFavoriteChannels();
    }
    if (isEmpty(this.props.channels)) {
      this.props.getChannels();
    } else {
      this.loadAllFavourites(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!this.state.hasLoaded) {
      this.loadAllFavourites(nextProps);
    }
  }

  loadAllFavourites = (props: Props) => {
    if (
      !props.isGuest &&
      props.loggedInWithCredentials &&
      props.svodKiosks.length > 0 &&
      props.channels.length > 0 &&
      this.state.fetchingAssets
    ) {
      this.setState(
        {
          fetchingAssets: false,
        },
        () => {
          getAllFavouriteAssets()
            .then((assetList) => {
              if ((assetList as VodListResponse).vodlist) {
                this.updateFavouriteAssets((assetList as VodListResponse).vodlist);
              } else {
                this.updateFavouriteAssets([]);
              }
              this.updateProgramArchiveAssets();
            })
            .catch(() => {
              this.updateFavouriteAssets([]);
              this.updateProgramArchiveAssets();
            });
        },
      );
    } else {
      if (props.svodKiosks.length !== 0) {
        setTimeout(() => {
          this.setState({ hasLoaded: true });
        }, 1000);
      }
    }
  };

  updateFavouriteAssets(favouriteAssets: VodAsset[]) {
    this.setState(
      {
        favouriteAssets: favouriteAssets,
      },
      () => {
        this.filterVodFavourites();
      },
    );
  }

  updateProgramArchiveAssets() {
    getProgramArchiveFavourites(this.props.channels)
      .then(this.programArchiveFavouritesToCorrectFormat)
      .then((assets) => {
        this.setState({
          favouriteProgramArchiveAssets: assets.filter((x) => (x.asset as Program).istvod === '1'),
          hasLoaded: true,
        });
      });
  }

  programArchiveFavouritesToCorrectFormat = (responsePlaybill: ProgramContentDetailResponse) => {
    return responsePlaybill.playbilllist.map((asset) => {
      if (asset.hasOwnProperty('seasonNum') && asset.hasOwnProperty('subNum')) {
        // @ts-ignore deletion of non-optional property
        delete asset.seasonNum;
        // @ts-ignore deletion of non-optional property
        delete asset.subNum;
      }
      return getFavCatchupAsset(asset, this.props.channels);
    });
  };

  filterVodFavourites() {
    const vods = getVodFromFavourites(this.props.svodKiosks, this.state.favouriteAssets);
    const vodsToCheck = vods.map((x) => x.foreignsn).join(',');
    getEPGFavouriteId(vodsToCheck).then((results) => {
      results = results as ContentDetailResponse;
      let withoutProgramArchive: VodAsset[] = [];
      if (results.vodlist.length === vods.length) {
        withoutProgramArchive = results.vodlist;
      } else {
        withoutProgramArchive = vods;
      }
      withoutProgramArchive = withoutProgramArchive.filter((x) => x.isPlayable === '1').filter((x) => x);
      this.setState({ favouriteVodAssets: withoutProgramArchive }, () => this.forceUpdate());
    });
  }

  prepareVods(vods: VodAsset[]) {
    let assets = [...vods];
    if (this.props.myContent) {
      assets = addBadgeOnOwnedSeriesAssets(assets, this.props.myContent);
    }
    if (this.props.mySeries) {
      assets = addBadgeOnOwnedSeriesAssets(assets, this.props.mySeries!);
    }
    return assets.map((asset) => {
      return getVodAsset(asset, true);
    });
  }

  sortFavourites(vods: AltiboxAsset[], svods: AltiboxAsset[]) {
    let sortedIndices = this.state.favouriteAssets.map((x) => x.id);
    let vodsAndSvods = [...vods, ...svods];
    let vodSvodIds = vodsAndSvods.map((x) => x.id);

    let intersectedIndices = vodSvodIds.filter((x) => sortedIndices.indexOf(x) !== -1);
    let sortedInitialFavourites = [...this.state.favouriteAssets].filter(
      (x) => intersectedIndices.indexOf(x.id) !== -1,
    );

    let sortedAltiboxAssetList: AltiboxAsset[] = [];
    sortedInitialFavourites.forEach((sortedFavourite) => {
      let foundFavourite = vodsAndSvods.filter((vodAndSvod) => vodAndSvod.id === sortedFavourite.id);
      if (!isEmpty(foundFavourite) && foundFavourite.length === 1) {
        sortedAltiboxAssetList.push(foundFavourite[0]);
      }
      if (!isEmpty(foundFavourite) && foundFavourite.length === 2) {
        if (sortedAltiboxAssetList.find((x) => x.id === sortedFavourite.id)) {
          sortedAltiboxAssetList.push(foundFavourite[1]);
        } else {
          sortedAltiboxAssetList.push(foundFavourite[0]);
        }
      }
    });
    return sortedAltiboxAssetList;
  }

  alterPotentialSharedAssetsFromSvod(svods: VodAsset[]) {
    let temp = [...svods];
    if (!isEmpty(temp)) {
      let arrayOfIds = temp.map((altiboxAsset) => {
        return altiboxAsset.id;
      });
      let duplicateIds = arrayOfIds.filter((e, i, a) => a.indexOf(e) !== i);
      duplicateIds.forEach((id) => {
        let duplicate = temp.findIndex((x) => x.id === id);
        if (duplicate !== -1) {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          temp[duplicate]['isSharedAsset'] = true;
        }
      });
      return temp;
    } else {
      return temp;
    }
  }

  renderFavoriteChannels(
    channels: Channel[],
    titleLink?: string,
    displayType?: VodAssetDisplayMode,
    isFavouriteView?: boolean,
  ) {
    if (isEmpty(channels) && this.state.hasLoaded) {
      return null;
    }
    return (
      <div className="favorite-channels-container vod-asset-full-view vod-content">
        <ChannelsBanner
          onlyInSubscription={true}
          channels={channels}
          favoriteChannels={this.props.favoriteChannels}
          isFavouriteView={isFavouriteView}
          authReducer={this.props.authReducer}
          openState={false}
          showLoginModal={this.props.showLoginModal}
        />
      </div>
    );
  }

  renderFavouriteAssets(
    assets: AltiboxAsset[],
    title: string,
    titleLink?: string,
    displayType?: VodAssetDisplayMode,
    isFavouriteView?: boolean,
  ) {
    if (isEmpty(assets) && this.state.hasLoaded) {
      return null;
    }
    return (
      <div className="vod-asset-full-view vod-content">
        {!isEmpty(assets) ? (
          <AltiboxAssetViewList
            assets={assets}
            title={i18n.t<string>(title)}
            displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
            titleLink={titleLink}
            vodDisplayMode={displayType}
            loggedInWithCredentials={this.props.loggedInWithCredentials}
            favouriteView={isFavouriteView}
            myContent={this.props.myContent}
          />
        ) : (
          <Spinner wrapInContainer={true} />
        )}
      </div>
    );
  }

  renderFavoriteView() {
    const svod = this.svodFavourites;
    const vod = this.vodFavourites;
    const sortedFavourites = this.sortFavourites(vod, svod);
    const programArchive = this.state.favouriteProgramArchiveAssets;
    if (this.props.isGuest || (this.props.isHome && !this.props.loggedInWithCredentials)) {
      return <h2 className="h2-login-needed">{i18n.t<string>('log in to see your content')}</h2>;
    } else if (
      isEmpty(sortedFavourites) &&
      isEmpty(programArchive) &&
      isEmpty(this.myAltiboxAssets) &&
      this.state.hasLoaded
    ) {
      return <h2 className="no-content">{i18n.t<string>('you have no content')}</h2>;
    } else if (!isEmpty(sortedFavourites) || !isEmpty(programArchive) || !isEmpty(this.myAltiboxAssets)) {
      return (
        <div className="my-content">
          {this.renderFavouriteAssets(
            sortedFavourites,
            'favourite movies and series',
            undefined,
            VodAssetDisplayMode.SVOD,
            true,
          )}
          {this.renderFavouriteAssets(
            this.myAltiboxAssets,
            'your bought and rented',
            routes.vod.base + routes.vod.myContent,
            VodAssetDisplayMode.VOD,
            false,
          )}
          {this.renderFavoriteChannels(this.props.favoriteChannels, undefined, VodAssetDisplayMode.SVOD, true)}
          {this.renderFavouriteAssets(programArchive, 'my tv favourites', undefined, VodAssetDisplayMode.SVOD, true)}
        </div>
      );
    } else {
      return <Spinner wrapInContainer={true} />;
    }
  }

  render() {
    return (
      <div className="main-frame my-content-list">
        <CategoryHelmet categoryTitle={'My Content'} title={i18n.t<string>('my content')} />
        <StickyHeader searchConfig={this.props.searchConfig} />
        <Header
          title={i18n.t<string>('my content')}
          displayType={HeaderDisplayType.Solid}
          interactionType={HeaderInteractionType.Full}
        />
        {this.state.hasLoaded && (
          <>
            {this.props.loggedInWithCredentials && (
              <div className="vod-asset-full-view vod-content cw-strip favorite-channels-container">
                <ContinueWatching numberOfAssetsToShow={swimlaneSettings.limit} />
              </div>
            )}

            {this.renderFavoriteView()}
            <Footer />
          </>
        )}

        {!this.state.hasLoaded && <Spinner wrapInContainer={true} />}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      mySeries: state.vodReducer.mySeries,
      myContent: state.vodReducer.myContent,
      mySeasons: state.vodReducer.mySeasons,
      defaultImagePath: state.app.defaultImagePath,
      isGuest: state.authReducer.isGuest,
      isHome: state.authReducer.isHome,
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
      svodKiosks: state.app.svodKiosks,
      auth: state.authReducer.auth,
      channels: state.channelsReducer.channels,
      favoriteChannels: state.channelsReducer.favoriteChannels,
      authReducer: state.authReducer,
    }),
    (dispatch: DispatchType) => ({
      getChannels: () => dispatch(getChannels()),
      getFavoriteChannels: () => dispatch(getFavoriteChannels()),
      loadContent: () => dispatch(fetchMyContent(-1)),
      showLoginModal: () => dispatch(showLoginModal()),
    }),
  )(MyContent),
);
