import { isEmpty } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import i18n from '../../i18n';
import { Channel, Program, RootState } from '../../interfaces';
import { broadcastingNow } from '../../utils/huaweiUtils';
import { getLockedMessage } from '../../utils/tvUtils';
import ChannelPlaybill from './ChannelPlaybill';
import './style.scss';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from '../../views/tvguide/TvGuide.module.scss';

interface Props {
  channelIsLocked: boolean;
  playbills: Program[];
  channel: Channel;
  categoryFilterActive: boolean;
  currentDate: Date;
}

export default function ChannelTvGuide({
  channel,
  playbills,
  currentDate,
  channelIsLocked,
  categoryFilterActive,
}: Props) {
  const { t } = useTranslation();
  const auth = useSelector((state: RootState) => state.authReducer);
  const [expanded, setExpanded] = useState(false);

  const currentProgramIndex = playbills.findIndex(broadcastingNow);

  function isToday() {
    return moment(currentDate).isSame(moment(), 'day');
  }

  function showMore() {
    setExpanded(true);
  }

  function showLess() {
    setExpanded(false);
  }

  if (isEmpty(playbills)) {
    return <p>{t('program schedule is not available yet')}</p>;
  }

  function getDefaultSplice() {
    if (!isToday()) {
      return [0, 7];
    }

    const middle = findMiddle(currentProgramIndex, 3);

    if (middle[0] < 0) {
      return [0, 7];
    }

    return middle;
  }

  const [start, end] = expanded ? [0, playbills.length] : getDefaultSplice();

  return (
    <>
      <ul className="playbill-for-channel">
        {channelIsLocked && (categoryFilterActive || !isToday()) && (
          <div className="locked-badge-wrapper">
            <div className={styles.tvGuideLockedInfoBadge}>
              {<span className={styles.icon}>p</span>}
              <div className={styles.badgeText}>{getLockedMessage(channel)}</div>
            </div>
          </div>
        )}

        {playbills.slice(start, end).map((program) => (
          <ChannelPlaybill
            key={program.id + program.starttime}
            channelIsLocked={channelIsLocked}
            isGuest={auth.isGuest}
            hasPvr={auth.hasPvr ?? false}
            loggedInWithCredentials={auth.loggedInWithCredentials}
            channel={channel}
            filterActive={categoryFilterActive}
            {...program}
          />
        ))}

        <ExpandButton shouldHide={categoryFilterActive} expanded={expanded} showMore={showMore} showLess={showLess} />
      </ul>
    </>
  );
}

interface ExpandButtonProps {
  shouldHide: boolean;
  expanded: boolean;
  showMore: () => void;
  showLess: () => void;
}

function ExpandButton({ shouldHide, expanded, showMore, showLess }: ExpandButtonProps) {
  if (shouldHide) {
    return null;
  }

  return (
    <li className="tvguide-playbill-loadmore">
      {expanded ? (
        <button className="toggle-channel-playbill-button" onClick={showLess}>
          {i18n.t<string>('show less tvguide')}
          <span className="aib-icon">u</span>
        </button>
      ) : (
        <button className="toggle-channel-playbill-button" onClick={showMore}>
          {i18n.t<string>('show more tvguide')}
          <span className="aib-icon">U</span>
        </button>
      )}
    </li>
  );
}

function findMiddle(index: number, padding: number) {
  return [index - padding, index + padding + 1];
}
