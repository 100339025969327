import {
  AltiboxAsset,
  AltiboxAssetDetailsType,
  AltiboxSeason,
  Bookmark,
  Channel,
  ContentType,
  DetailsMeta,
  DispatchType,
  FavoCatalogResponse,
  FavouriteResponse,
  GetStateType,
  PortalMenu,
  Program,
  PvrRecording,
  SharedAssets,
  SvodSharedAsset,
  UnionAssetTypes,
  VodAsset,
  VodContentProduct,
  VodSharedAsset,
} from '../../../interfaces';
import {
  addAssetToFavourites,
  createFavoCatalogs,
  getAllFavouriteAssets,
  getAllFavourites,
  getEPGFavouriteId,
  getFavoIds,
  getPlaybillFavourites,
  removeAssetFromFavourites,
} from '../../../api/favorites';
import { assetIsRented, isEpisode, isSeries } from '../../../utils/vodUtils';
import {
  checkAssetPlayability,
  getContentIdByExternalContentCode,
  getContentPricing,
  getPotentialSharedAssets,
  getSeriesByEpisode,
  getSingleContentDetail,
} from '../../../api/vod';
import { createSeries, getProgramAsset, groupBySeason } from '../../../utils/catchupUtils';
import { flatten, isEmpty, uniq } from 'lodash';
import { getAllPlaybills, seriesRelations } from '../../../api/tv';
import {
  addEpisodeSeasonTag,
  getAltiboxAssetId,
  getAltiboxAssetPercentWatched,
  sortByEpisodeAndSeasonTag,
  wrapAssetAsAltiboxAsset,
} from '../../../utils/altiboxassetUtils';
import { getNonCatchupProgramSeries, getProgramDetail } from '../../../api/programarchive';

import { DetailsRouteProps } from '..';
import { catchupAvailable } from '../../../utils/tvUtils';
import { getPercentWatched } from '../../../utils/pvrUtils';
import { getSharedAssetSvodKiosk } from '../../../utils/sharedAssetsUtils';
import { getSvodProducts } from '../../../utils/svodUtil';
import { getVodAltiboxAssetDetails } from '../../app/actions';
import { identifySvodIdentifierBasedOnFavoIdName } from '../../../utils/favourites';
import { loadAllSeasonsAndEpisodes } from '../../vod/actions';
import { waitForPrograms } from '../../tv/actions';
import { arrayToMap } from '../../../utils/commonUtils';
import { bookmarkKeys } from './../../../queries/bookmarks/keys';
import { queryClient } from '../../../queries/client';

export enum TypeKeys {
  SET_LOADING_STATUS = 'SET_LOADING_STATUS',
  SET_CURRENT_ASSET = 'SET_CURRENT_ASSET',
  SHOW_DETAILS_PANEL = 'SHOW_DETAILS_PANEL',
  HIDE_DETAILS_PANEL = 'HIDE_DETAILS_PANEL',
  SET_SLIDE_OUT_STATUS = 'SET_SLIDE_OUT_STAUTS',
}

export type ActionTypes = SetCurrentAsset | SetLoadingStatus | ShowDetailsPanel | HideDetailsPanel | SetSlideOutStatus;

type SetCurrentAsset = {
  type: TypeKeys.SET_CURRENT_ASSET;
  currentAsset: AltiboxAsset | undefined;
};

type SetLoadingStatus = {
  type: TypeKeys.SET_LOADING_STATUS;
  loading: boolean;
};

type SetSlideOutStatus = {
  type: TypeKeys.SET_SLIDE_OUT_STATUS;
  slidingOut: boolean;
};

export type ShowDetailsPanel = {
  type: TypeKeys.SHOW_DETAILS_PANEL;
  routeProps: DetailsRouteProps;
  displayType: AltiboxAssetDetailsType;
  svodKiosk?: PortalMenu;
  clickedId?: string;
};

type HideDetailsPanel = {
  type: TypeKeys.HIDE_DETAILS_PANEL;
};

function setCurrentAsset(currentAsset: AltiboxAsset | undefined): SetCurrentAsset {
  return {
    type: TypeKeys.SET_CURRENT_ASSET,
    currentAsset,
  };
}

function setLoadingStatus(isLoading: boolean): SetLoadingStatus {
  return {
    type: TypeKeys.SET_LOADING_STATUS,
    loading: isLoading,
  };
}

export function setSlideOutStatus(isSlidingOut: boolean): SetSlideOutStatus {
  return {
    type: TypeKeys.SET_SLIDE_OUT_STATUS,
    slidingOut: isSlidingOut,
  };
}

export function showDetailsPanel({
  routeProps,
  displayType,
  svodKiosk,
  clickedId,
}: {
  routeProps: DetailsRouteProps;
  displayType: AltiboxAssetDetailsType;
  svodKiosk?: PortalMenu;
  clickedId?: string;
}): ShowDetailsPanel {
  return {
    type: TypeKeys.SHOW_DETAILS_PANEL,
    routeProps,
    displayType,
    svodKiosk,
    clickedId,
  };
}

export function hideDetailsPanel(): HideDetailsPanel {
  return { type: TypeKeys.HIDE_DETAILS_PANEL };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadAsset(routeProps: DetailsRouteProps, type: AltiboxAssetDetailsType, svodKiosk?: PortalMenu): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    return createDetailsAltiboxAsset(routeProps, type, getState, svodKiosk).then((asset) => {
      dispatch(setCurrentAsset(asset));
      dispatch(setLoadingStatus(false));
      return asset;
    });
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function unloadAsset(): any {
  return function (dispatch: DispatchType) {
    dispatch(setCurrentAsset(undefined));
    dispatch(hideDetailsPanel());
    dispatch(setLoadingStatus(true));
  };
}

async function createDetailsAltiboxAsset(
  routeProps: DetailsRouteProps,
  type: AltiboxAssetDetailsType,
  getState: GetStateType,
  kiosk?: PortalMenu,
) {
  const channels = getState().channelsReducer.channels;
  const bookmarks = getState().app.bookmarks;
  const svodKiosk = kiosk ?? getState().svodReducer.svodKiosk;
  const defaultImagePath = getState().app.defaultImagePath;
  const isGuest = getState().authReducer.isGuest;
  let isAssetSeries = false;
  switch (type) {
    case AltiboxAssetDetailsType.CATCHUP: {
      const programArchiveAsset = await getProgramArchiveAsset({ routeProps, channels });

      if (!programArchiveAsset || !programArchiveAsset.meta?.isSeries || !programArchiveAsset.asset.seasons) {
        return programArchiveAsset;
      }

      const recordings = getPvrAsset({ routeProps, getState, bookmarks });

      if (!recordings || (recordings.asset && !recordings.asset.seasons)) {
        return programArchiveAsset;
      }

      (recordings.asset.seasons as AltiboxSeason[]).forEach((season) => {
        const paSeasons = programArchiveAsset.asset.seasons as AltiboxSeason[];
        const paSeason = paSeasons.find((_paSeason) => _paSeason.seasonNumber === season.seasonNumber);

        if (paSeason) {
          paSeason.content = [...paSeason.content, ...season.content];
        } else {
          paSeasons.push(season);
        }
      });

      (programArchiveAsset.asset.seasons as AltiboxSeason[]).sort(
        (a, b) => Number(a.seasonNumber) - Number(b.seasonNumber),
      );

      // making sure the recorded version of a program is promoted
      (programArchiveAsset.asset.seasons as AltiboxSeason[]).forEach((season) => {
        season.content.forEach((episode) => {
          const pvrEpisodeAsset = episode.asset as PvrRecording;
          const pvrAsset = programArchiveAsset.asset as PvrRecording;
          const lifetimeIdExists =
            pvrEpisodeAsset.hasOwnProperty('lifetimeId') && pvrAsset.hasOwnProperty('lifetimeId');
          if (lifetimeIdExists && pvrEpisodeAsset.lifetimeId === pvrAsset.lifetimeId) {
            programArchiveAsset.promotedEpisodeId = (episode.asset as PvrRecording).pvrId;
          }
        });
      });

      return programArchiveAsset;
    }
    case AltiboxAssetDetailsType.SVOD:
    case AltiboxAssetDetailsType.VOD: {
      const { parentId, childId } = routeProps;
      let loadedVodAsset = await fetchVod(parentId!, undefined);
      if (!loadedVodAsset) {
        return undefined;
      }
      isAssetSeries = isVodAssetSeries(loadedVodAsset);
      let pricingId = loadedVodAsset.asset.id!;
      if (isAssetSeries) {
        loadedVodAsset.meta!.isSeries = isAssetSeries;
        loadedVodAsset.asset = organizeVodSeries(loadedVodAsset.asset as VodAsset);
        const seriesAsset = { ...loadedVodAsset };
        // has episode id to promote in url
        if (childId) {
          loadedVodAsset.asset = findPromomotedVodAsset(seriesAsset, childId);
          loadedVodAsset.seasons = seriesAsset.seasons!;
        }
      }
      if (svodKiosk) {
        loadedVodAsset.meta!.kiosk = svodKiosk;
        loadedVodAsset.meta!.defaultImagePath = defaultImagePath;
        loadedVodAsset.meta!.favourite.catalog = svodKiosk.favoId!;
      } else {
        if (isAssetSeries) {
          const foundEpisode = findEpisodeToPromote(loadedVodAsset, bookmarks);
          pricingId = foundEpisode.id!;
        }
        loadedVodAsset.meta!.purchaseOptions = await getContentPricing(pricingId!);
      }
      if (!isGuest) {
        loadedVodAsset = await checkIfContentIsPlayable(loadedVodAsset);
      }

      // Makes sure the episodes for the asset have a valid category id otherwise don't show the episodes
      if (loadedVodAsset.meta?.kiosk) {
        const validCategoryIds = loadedVodAsset.meta.kiosk.allActiveCategoryIds;
        const asset = loadedVodAsset.asset as VodAsset;

        const seasons = asset.seasons?.map((season) => {
          const episodes = (season as unknown as AltiboxSeason).content.filter((episode) => {
            return (episode.asset as VodAsset).categoryIds.some((categoryId) => validCategoryIds.includes(categoryId));
          });

          return { ...season, content: episodes };
        });

        loadedVodAsset.asset.seasons = seasons?.filter((season) => season.content.length > 0);
      }

      return loadedVodAsset;
    }
    case AltiboxAssetDetailsType.PVR: {
      const pvrAsset = getPvrAsset({ routeProps, getState, bookmarks });

      if (!pvrAsset || !pvrAsset.meta?.isSeries || !pvrAsset.asset.seasons) {
        return pvrAsset;
      }
      const recordings = getState().pvrReducer.recordings;
      const programArchiveAsset = await getProgramArchiveAsset({ routeProps, channels, recordings });

      if (!programArchiveAsset || !programArchiveAsset?.asset || !programArchiveAsset?.asset?.seasons) {
        return pvrAsset;
      } else {
        (programArchiveAsset.asset.seasons as AltiboxSeason[]).forEach((season) => {
          const pvrSeasons = pvrAsset.asset.seasons as AltiboxSeason[];
          const pvrSeason = pvrSeasons.find((_paSeason) => _paSeason.seasonNumber === season.seasonNumber);

          if (pvrSeason) {
            pvrSeason.content = [...pvrSeason.content, ...season.content];
          } else {
            pvrSeasons.push(season);
          }
        });

        (pvrAsset.asset.seasons as AltiboxSeason[]).sort((a, b) => Number(a.seasonNumber) - Number(b.seasonNumber));
        pvrAsset.meta.favourite = programArchiveAsset.meta?.favourite ?? pvrAsset.meta.favourite;
        pvrAsset.meta.producedate = (programArchiveAsset.asset as Program).producedate;
        pvrAsset.meta.country = (programArchiveAsset.asset as Program).country;
        pvrAsset.meta.introduce = (programArchiveAsset.asset as Program).introduce;
        return pvrAsset;
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateContentPricing(loadedAsset: AltiboxAsset, episodeId: string): any {
  return function (dispatch: DispatchType) {
    let tempAsset = { ...loadedAsset };
    getContentPricing(episodeId).then((purchaseOptions) => {
      tempAsset.meta!.purchaseOptions = purchaseOptions;
      dispatch(setCurrentAsset(tempAsset));
    });
  };
}

/** **********************************/
/** ****  Program archive start ******/

async function getProgramArchiveAsset({
  routeProps,
  channels,
  recordings,
}: {
  routeProps: DetailsRouteProps;
  channels: Channel[];
  recordings?: PvrRecording[];
}): Promise<AltiboxAsset | undefined> {
  const { parentId, childId, channelId } = routeProps;

  let asset = parentId
    ? await loadProgramArchiveSeries(parentId, childId ?? '', channels)
    : await getProgramData(childId ?? '', channels);

  if (asset) {
    const assetIsSeries = asset.meta?.isSeries ?? Boolean(parentId);
    const favouriteAsset = await getFavouriteAssetForProgram(asset, assetIsSeries);
    asset.meta = await getProgramArchiveMeta(asset, assetIsSeries, favouriteAsset);
    return asset;
  }
  /**
   * Edge case for when loadProgramArchiveSeries and getProgramData is undefined.
   * Get playbills for channelId and find matching program in resulting playbills because QuerySeriesRelation and ContentDetails
   * return empty responses for IPTV only channels.
   */
  if (!asset && channelId) {
    const fallbackAsset = await getAllPlaybills([channelId], 5, 5);
    if (fallbackAsset[channelId]) {
      asset = wrapAssetAsAltiboxAsset(fallbackAsset[channelId].find((program) => program.id === childId)!);
      asset.type = AltiboxAssetDetailsType.PROGRAM;
      const assetIsSeries = false;
      asset.meta = await getProgramArchiveMeta(asset, assetIsSeries);
      return asset;
    }
  }
  /**
   * Edge case for when channel is retired (removed from allChannels response)
   */
  const subRecording = recordings?.find((recording) => recording.subRecordings?.find((sub) => sub.pvrId === childId));
  asset = wrapAssetAsAltiboxAsset(subRecording!);
  asset.type = AltiboxAssetDetailsType.PROGRAM;
  const assetIsSeries = false;
  asset.meta = await getProgramArchiveMeta(asset, assetIsSeries);
  return asset;
}

async function getProgramArchiveMeta(asset: AltiboxAsset, assetIsSeries: boolean, favouriteAsset?: Program | VodAsset) {
  return asset.meta
    ? {
        ...asset.meta,
        isSeries: assetIsSeries,
        favourite: {
          ...asset.meta.favourite,
          asset: favouriteAsset ? favouriteAsset : ({} as Program | VodAsset),
        },
      }
    : {
        isSeries: assetIsSeries,
        favourite: {
          asset: favouriteAsset ? favouriteAsset : ({} as Program | VodAsset),
          catalog: '',
        },
      };
}

/** *** Single program *****/

function getProgramData(programId: string, channels: Channel[]) {
  return getProgramDetail(programId).then((program) => {
    if (program) {
      return getProgramAsset(program as Program, channels);
    } else {
      return undefined;
    }
  });
}

/** *** Series  *** **/
function loadProgramArchiveSeries(
  seriesId: string,
  promoteId: string,
  channels: Channel[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AltiboxAsset | undefined> {
  return getSeriesData(seriesId, channels, promoteId).then((series) => {
    if (!series) {
      // If series relation is not ready in platform, return the single program to avoid "content not available"
      return getProgramData(promoteId, channels).then((asset) => {
        if (!asset) {
          return;
        }

        const meta = asset.meta ? { ...asset.meta, isSeries: false } : { isSeries: false };
        return { ...asset, meta } as AltiboxAsset;
      });
    }
    return series;
  });
}

async function getSeriesData(seriesId: string, channels: Channel[], promoteId: string) {
  const programIds = await seriesRelations([seriesId]).then((seriesRelation) =>
    uniq(
      seriesRelation
        .map((program) => program.programId)
        .concat(promoteId)
        .filter((id) => id !== undefined),
    ).join(','),
  );

  if (!programIds) {
    return;
  }

  let programs = await getProgramDetail(programIds, false);

  if (!Array.isArray(programs) || isEmpty(programs)) {
    return;
  }

  const channelIds = uniq(programs.filter((program) => catchupAvailable(program)).map((program) => program.channelid));

  // Get assets from TV-guide since Program archive has a slight delay that does not include all assets
  programs = [
    ...programs,
    ...(await waitForPrograms({ channelIds, preNumber: 50, nextNumber: 250 })).filter(
      (program) => program.seriesID === seriesId && catchupAvailable(program),
    ),
  ];

  let episodeToPromote = programs.find((program) => program.id === promoteId);

  if (!episodeToPromote) {
    const uniqueIds = uniq(programs.map((program) => program.channelid));
    const playbills = await getAllPlaybills(uniqueIds).then((_playbills) => flatten(Object.values(_playbills)));

    episodeToPromote = playbills.find((program) => program.id === promoteId) ?? programs[0];

    if (episodeToPromote !== programs[0]) {
      programs = [...programs, episodeToPromote];
    }
  }

  // Fetch and show non catchup programs if the there are no other programs than the promoted program itself
  if (programs.length <= 1) {
    programs = await getNonCatchupProgramSeries(seriesId);
    if (programs) {
      const seasons = await groupBySeason(programs as Program[], channels);
      return createSeries(seasons, episodeToPromote, channels);
    }
  } else {
    const seasons = await groupBySeason(programs, channels);
    return createSeries(seasons, episodeToPromote, channels);
  }
}

/** ****  Program archive end ******/
/** ********************************/

/** *** Favourites start *****/
// Programs
function getFavouriteAssetForProgram(asset: AltiboxAsset, isAssetSeries: boolean) {
  let programAsset = asset.asset as Program;
  let singleInSeries = !programAsset.seasons! && programAsset.seriesID;
  if (isAssetSeries || singleInSeries) {
    return getEPGFavouriteId(programAsset.seriesID).then((response) => {
      if (typeof response !== 'string' && response.vodlist.length > 0) {
        // const relatedVodAsset = response.vodlist[0];
        // relatedVodAsset.isfavorited = '1';
        return response.vodlist[0] as VodAsset;
      } else {
        programAsset.isfavorited = '0';
        return undefined;
      }
    });
  } else {
    const idToCheck = programAsset.seriesID ? programAsset.seriesID : programAsset.id;
    return checkForFakeSingleCatchupOrProgram(idToCheck)
      .then((fakeAsset) => {
        return getAllFavouriteAssets().then((favourites) => {
          const fakeFavourite = fakeAsset as VodAsset;
          if ((favourites as FavouriteResponse).favoritelist.find((x) => x.id === fakeFavourite.id)) {
            fakeFavourite.isfavorited = '1';
          } else {
            fakeFavourite.isfavorited = '0';
          }
          return fakeFavourite;
        });
      })
      .catch(() => {
        return getPlaybillFavourites([idToCheck]).then((result) => {
          if (result.playbilllist && result.playbilllist.length > 0) {
            let favouriteAsset = result.playbilllist[0] as Program;
            return getAllFavourites(ContentType.PROGRAM).then((favourites: FavouriteResponse) => {
              if (favourites.favoritelist && favourites.favoritelist.find((x) => x.id === favouriteAsset.id)) {
                favouriteAsset.isfavorited = '1';
              } else {
                favouriteAsset.isfavorited = '0';
              }
              return favouriteAsset;
            });
          } else {
            return programAsset;
          }
        });
      });
  }
}

async function checkForFakeSingleCatchupOrProgram(seriesID: string) {
  return new Promise((resolve, reject) => {
    return getEPGFavouriteId(seriesID).then((response) => {
      if (typeof response !== 'string' && response.vodlist.length > 0) {
        const asset = response.vodlist[0] as VodAsset;
        resolve(asset);
      }
      reject();
    });
  });
}

export function toggleAddRemoveFavourite(
  id: string,
  state: string,
  type: ContentType,
  catalog: string = '-2',
  kiosk?: PortalMenu,
) {
  if (kiosk && catalog === '-1') {
    return getFavoIds().then((catalogues: FavoCatalogResponse) => {
      const favoCatalogList = catalogues.favolist;
      if (!isEmpty(favoCatalogList)) {
        const identifier = kiosk.identifier!;
        const isInFavoCatalogue = favoCatalogList!.find((catalogue) => {
          return identifySvodIdentifierBasedOnFavoIdName(catalogue.name) === identifier;
        });
        if (!isInFavoCatalogue) {
          const newCatalogueName = 'svod-' + identifier;
          return createFavoCatalogs(newCatalogueName).then((catalogue) => {
            catalog = catalogue.favoId;
            if (state === '1') {
              return addAssetToFavourites(id, type, catalog).then((response) => {
                if (response.retcode !== '-1') {
                  return Promise.resolve();
                }
                return Promise.reject();
              });
            } else {
              return removeAssetFromFavourites(id, type, catalog).then((response) => {
                if (response.retcode !== '-1') {
                  return Promise.resolve();
                }
                return Promise.reject();
              });
            }
          });
        } else {
          return Promise.reject();
        }
      } else {
        return Promise.reject();
      }
    });
  } else {
    if (state === '1') {
      return addAssetToFavourites(id, type, catalog).then((response) => {
        if (response.retcode !== '-1') {
          return Promise.resolve();
        }
        return Promise.reject();
      });
    } else {
      return removeAssetFromFavourites(id, type, catalog).then((response) => {
        if (response.retcode !== '-1') {
          return Promise.resolve();
        }
        return Promise.reject();
      });
    }
  }
}

/** *** Favourites end *****/

/** *******  VOD/SVOD start ********/

/** ********* Single VOD ***********/
/** ********* Series VOD ***********/
function fetchVod(vodId: string, svodKiosk?: PortalMenu): Promise<AltiboxAsset | undefined> {
  return getContentIdByExternalContentCode(vodId)
    .then((internalId: string) => {
      return getSingleContentDetail(internalId).then((initialVodAsset: VodAsset | undefined) => {
        if (initialVodAsset) {
          return getVodOfType(initialVodAsset, svodKiosk).then((processedVodAsset: VodAsset) => {
            return getVodAltiboxAssetDetails(processedVodAsset, false);
          });
        }
        return undefined;
      });
    })
    .catch(() => {
      return getSingleContentDetail(vodId).then((initialVodAsset: VodAsset | undefined) => {
        if (initialVodAsset) {
          return getVodOfType(initialVodAsset, svodKiosk).then((processedVodAsset: VodAsset) => {
            return getVodAltiboxAssetDetails(processedVodAsset, false);
          });
        }
        return undefined;
      });
    });
}

function getVodOfType(vodAsset: VodAsset, svodKiosk?: PortalMenu) {
  if (isEpisode(vodAsset)) {
    return getSeriesByEpisode(vodAsset).then((theAsset: VodAsset) => {
      return loadAllSeasonsAndEpisodes(theAsset, svodKiosk);
    });
  } else if (isSeries(vodAsset)) {
    return loadAllSeasonsAndEpisodes(vodAsset, svodKiosk);
  } else {
    return Promise.resolve(vodAsset);
  }
}

function organizeVodSeries(vodAsset: VodAsset) {
  // TODO: MAKE THIS AltiboxSeason[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let tempSeasonStructure: any = [];
  let tempVodAsset = { ...vodAsset };
  tempVodAsset.seasons!.forEach((season) => {
    let sitcomnum = season.fathervodlist[0].sitcomnum ? season.fathervodlist[0].sitcomnum : '1';
    tempSeasonStructure.push({
      content: season.episodes.map((x: VodAsset) => getVodAltiboxAssetDetails(x)),
      seasonNumber: sitcomnum,
      description: season.introduce,
    });
  });
  tempVodAsset.seasons = tempSeasonStructure;
  return tempVodAsset;
}

function findPromomotedVodAsset(altiboxAsset: AltiboxAsset, promotedId: string) {
  let foundVodAsset = altiboxAsset.asset as VodAsset;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (altiboxAsset.asset as VodAsset).seasons!.forEach((season: any) => {
    season.content.forEach((episode: VodAsset) => {
      if (episode.id === promotedId) {
        foundVodAsset = episode;
      }
    });
  });
  return foundVodAsset;
}

function isVodAssetSeries(altiboxAsset: AltiboxAsset) {
  return (altiboxAsset.asset as VodAsset).hasOwnProperty('seasons');
}

// function isPVRAssetSeries(recording: PvrRecording) {
//   return recording.hasOwnProperty('subRecordings');
// }

export function findEpisodeToPromote(altiboxAsset: AltiboxAsset, bookmarks: Bookmark[]) {
  let episodeIds: string[] = [];
  let possibleBookmarks = [];
  let seasons = (altiboxAsset.asset as VodAsset).seasons! as [] as AltiboxSeason[];
  seasons.forEach((season) => {
    season.content.forEach((episodes) => {
      episodeIds.push(episodes.asset.id!);
    });
  });
  possibleBookmarks = bookmarks.filter((x) => episodeIds.indexOf(x.contentId) >= 0);
  if (possibleBookmarks.length === 0) {
    let firstSeason = seasons.sort((a: AltiboxSeason, b: AltiboxSeason) => {
      return Number(a.seasonNumber) - Number(b.seasonNumber);
    })[0];
    let firstEpisode = firstSeason.content.sort((a: AltiboxAsset, b: AltiboxAsset) => {
      let aAsset = a.asset as VodAsset;
      let bAsset = b.asset as VodAsset;
      return Number(aAsset.sitcomnum) - Number(bAsset.sitcomnum);
    })[0];
    return firstEpisode.asset as VodAsset;
  } else {
    let episodeToPromote = getEpisodeToPromoteBasedOnBookmarkOrSeasons(seasons, bookmarks);
    if (!episodeToPromote) {
      if (altiboxAsset.asset.seasons) {
        episodeToPromote = (altiboxAsset.asset.seasons![0] as AltiboxSeason).content[0].asset as VodAsset;
      } else {
        // actually a single / movie - should not happen, but is a fallback
        episodeToPromote = altiboxAsset.asset as VodAsset;
      }
    }
    return episodeToPromote;
  }
}

export function getEpisodeToPromoteBasedOnBookmarkOrSeasons(
  seasons: AltiboxSeason[] | undefined,
  bookmarks: Bookmark[] | undefined,
  clickedId?: string,
) {
  if (!seasons || !bookmarks || isEmpty(seasons) || isEmpty(bookmarks)) {
    return undefined;
  }

  const episodes = sortByEpisodeAndSeasonTag(
    seasons.reduce<AltiboxAsset[]>(
      (acc, season) => [...acc, ...season.content.map((ep) => addEpisodeSeasonTag(ep, season.seasonNumber))],
      [],
    ),
  );

  const firstEpisode = episodes[episodes.length - 1];

  if (isEmpty(episodes)) {
    return undefined;
  }

  if (clickedId) {
    const clickedEpisode = episodes.find((episode) => {
      return getAltiboxAssetId(episode.asset) === clickedId;
    });

    return clickedEpisode?.asset;
  }

  const bookmarksMap = arrayToMap(bookmarks, (bokmark) => bokmark.contentId);

  const episodesWithBookmark = episodes.map((episode) => ({
    episode,
    bookmark: bookmarksMap.get(getAltiboxAssetId(episode.asset)),
  }));

  const mostRecentEpisode = episodesWithBookmark
    .filter((ep) => Boolean(ep.bookmark))
    .sort((a, b) => Number(b.bookmark?.updateTime) - Number(a.bookmark?.updateTime))[0];

  if (!mostRecentEpisode) {
    return firstEpisode.asset;
  }

  const percentWatched = getAltiboxAssetPercentWatched(mostRecentEpisode.episode, mostRecentEpisode.bookmark);

  if (percentWatched >= 97) {
    const nextEpisodeIndex =
      episodesWithBookmark.findIndex(
        (ep) => getAltiboxAssetId(ep.episode.asset) === getAltiboxAssetId(mostRecentEpisode.episode.asset),
      ) - 1;

    const nextEpisode = episodesWithBookmark[nextEpisodeIndex];

    if (nextEpisode) {
      return nextEpisode.episode.asset;
    }
  }

  return mostRecentEpisode.episode.asset;
}

async function checkIfContentIsPlayable(altiboxAsset: AltiboxAsset) {
  let tempAsset = { ...altiboxAsset };
  let asset = tempAsset.asset as VodAsset;
  let assetIdsToCheck: string[] = [];
  if (altiboxAsset.meta!.isSeries) {
    let seasons = asset.seasons as unknown as AltiboxSeason[];
    seasons.forEach((season) => {
      season.content.forEach((episodes) => {
        assetIdsToCheck.push(episodes.asset.id!);
      });
    });
    let episodesThatCanBePlayed = await checkAssetPlayability(assetIdsToCheck);
    let counter = 0;
    seasons.forEach((season) => {
      season.content.forEach((episodes) => {
        if (Boolean(episodesThatCanBePlayed[counter])) {
          (episodes.asset as VodAsset).isPlayable = '1';
        }
        counter++;
      });
    });
  } else {
    assetIdsToCheck = [asset.id];
    let singleAssetCanBePlayed = await checkAssetPlayability(assetIdsToCheck);
    if (singleAssetCanBePlayed && Boolean(singleAssetCanBePlayed[0])) {
      asset.isPlayable = '1';
    }
  }
  return tempAsset;
}

/** ********  VOD/SVOD end *********/
/** ********************************/
/** **********  PVR Start **********/
/** ********************************/
function getPvrAsset({
  routeProps,
  getState,
  bookmarks,
}: {
  routeProps: DetailsRouteProps;
  getState: GetStateType;
  bookmarks: Bookmark[];
}): AltiboxAsset | undefined {
  const { parentId, childId } = routeProps;
  const recordings = getState().pvrReducer.recordings;

  if (!recordings) {
    return;
  }

  const series = parentId ? recordings.filter((recording) => recording.seriesId === parentId) : [];
  let seriesAsset = series.find((recording) => !isEmpty(recording.subRecordings));

  if (seriesAsset) {
    seriesAsset.subRecordings = flatten(
      series.filter((recording) => !isEmpty(recording.subRecordings)).map((x) => x.subRecordings),
    );
    seriesAsset.subRecordings.sort(
      (a, b) => Number(b.seasonNum) - Number(a.seasonNum) || Number(b.subNum) - Number(a.subNum),
    );
    return getPVRAltiboxAssetDetails(seriesAsset, true, bookmarks);
  }

  if (!isEmpty(series)) {
    const homemadeSeriesAsset = {
      ...series[0],
      subRecordings: series,
    };

    return getPVRAltiboxAssetDetails(homemadeSeriesAsset, true, bookmarks);
  }

  const single = childId ? recordings.find((recording) => recording.pvrId === childId) : undefined;

  return single ? getPVRAltiboxAssetDetails(single, false, bookmarks) : undefined;
}

function getPVRAltiboxAssetDetails(recording: PvrRecording, recordingIsSeries: boolean = false, bookmarks: Bookmark[]) {
  let altiboxAsset = createAssetFromPvr(recording);
  if (recordingIsSeries) {
    altiboxAsset.meta.isSeries = true;
    let seasons = Array.from(new Set(recording.subRecordings.map((x) => x.seasonNum)));
    let tempSeasons: AltiboxSeason[] = seasons.map((num) => {
      return {
        seasonNumber: num,
        content: [] as AltiboxAsset[],
      };
    });
    recording.subRecordings.forEach((episode) => {
      let findSeason = tempSeasons.find((season) => season.seasonNumber === episode.seasonNum);
      if (findSeason) {
        findSeason.content.push(createAssetFromPvr(episode));
      }
    });
    altiboxAsset.asset.seasons = cleanupPvrSeasons(tempSeasons, bookmarks);
  } else {
    altiboxAsset.meta.isSeries = false;
  }

  return altiboxAsset;
}
// TODO: Refactor to a generic createAltiboxAsset
function createAssetFromPvr(recording: PvrRecording) {
  return {
    progress: 0,
    id: '0',
    picture: '',
    title: '',
    playLink: '',
    detailsLink: '',
    weight: 0,
    type: AltiboxAssetDetailsType.PVR,
    asset: recording,
    meta: {
      favourite: {
        asset: {} as VodAsset,
        catalog: '-1',
      },
    } as DetailsMeta,
  };
}

function cleanupPvrSeasons(seasons: AltiboxSeason[], bookmarks: Bookmark[]) {
  let tempSeasons = [...seasons];
  let undefinedSeasons = tempSeasons.filter((x) => !Number(x.seasonNumber));
  if (!isEmpty(undefinedSeasons)) {
    let rest = tempSeasons.filter((x) => Number(x.seasonNumber));
    let concatenatedUndefineds: AltiboxAsset[] = [];
    undefinedSeasons.forEach((season) => {
      concatenatedUndefineds = concatenatedUndefineds.concat(season.content);
    });
    rest.push({
      seasonNumber: '0',
      content: concatenatedUndefineds,
    });
    tempSeasons = rest;
  }
  tempSeasons = removeDuplicatePVREntriesFromSeasons(tempSeasons, bookmarks);
  return tempSeasons.sort((a, b) => {
    return Number(a.seasonNumber) - Number(b.seasonNumber);
  });
}

function removeDuplicatePVREntriesFromSeasons(seasons: AltiboxSeason[], bookmarks: Bookmark[]) {
  let tempSeasons = [...seasons];
  tempSeasons.forEach((season) => {
    let episodes = season.content;
    episodes.forEach((episode) => {
      let pvrAsset = episode.asset as PvrRecording;
      let lifetimeId = pvrAsset.lifetimeId;
      let matchingLifetimeIds = episodes.filter((x) => {
        let temp = x.asset as PvrRecording;
        return temp.lifetimeId === lifetimeId;
      });
      if (matchingLifetimeIds.length > 1) {
        const duplicateIds = matchingLifetimeIds.map((x) => (x.asset as PvrRecording).pvrId);
        const hasBookmarks = duplicateIds.filter((id) => {
          return bookmarks.find((x) => x.contentId === id);
        });
        if (isEmpty(hasBookmarks)) {
          matchingLifetimeIds.sort((a: AltiboxAsset, b: AltiboxAsset) => {
            return Number((b.asset as PvrRecording).beginTime) - Number((a.asset as PvrRecording).beginTime);
          });
          matchingLifetimeIds.forEach((duplicate, idx) => {
            if (idx !== 0) {
              duplicate.meta!.hide = true;
            }
          });
        } else {
          matchingLifetimeIds.forEach((duplicate, idx) => {
            if (hasBookmarks[0] !== (duplicate.asset as PvrRecording).pvrId) {
              duplicate.meta!.hide = true;
            }
          });
        }
      }
    });
  });
  return tempSeasons;
}

function getNextSeasonIndex(seasons: AltiboxSeason[] | undefined, nodeSeason: string): number {
  if (seasons) {
    let index = seasons.findIndex((season) => {
      return season.seasonNumber === nodeSeason;
    });
    return seasons[index + 1] ? index + 1 : index;
  }
  return 0;
}

function findNextPvrToPromote(foundAsset: PvrRecording, seriesAsset: PvrRecording) {
  const currentSeasonNumber = foundAsset.seasonNum ?? '0';
  const assetSeasons = seriesAsset.seasons || [];
  const assetSeason = assetSeasons.find((season) => {
    return season.seasonNumber && season.seasonNumber === currentSeasonNumber;
  });

  if (!assetSeason) {
    return foundAsset;
  }

  const seasonEpisodes = assetSeason.content
    .map((episode) => {
      return episode.asset;
    })
    .filter((x) => x);
  const episodeIndex = seasonEpisodes.indexOf(foundAsset);
  if (seasonEpisodes[episodeIndex - 1]) {
    return seasonEpisodes[episodeIndex - 1] as PvrRecording;
  } else {
    let nextSeasonIndex = getNextSeasonIndex(assetSeasons, currentSeasonNumber);
    let nextSeason = assetSeasons![nextSeasonIndex].content;
    return nextSeasonIndex ? (nextSeason[nextSeason.length - 1].asset as PvrRecording) : foundAsset;
  }
}

export function findPvrToPromote(altiboxAsset: AltiboxAsset, bookmarks: Bookmark[]) {
  const seriesAsset = altiboxAsset.asset as PvrRecording;
  const queryBookmarks = queryClient.getQueryData<Bookmark[]>(bookmarkKeys.all) || [];

  const pvrBookmarks = [...queryBookmarks, ...bookmarks]
    .filter((x) => x.bookmarkType === '2')
    .sort((a, b) => Number(b.updateTime) - Number(a.updateTime));

  let foundAsset: PvrRecording | undefined = undefined;

  pvrBookmarks.forEach((x) => {
    if (!foundAsset) {
      foundAsset = seriesAsset.subRecordings.find((y) => y.pvrId === x.contentId);
    }
  });

  if (foundAsset) {
    const percentWatched = getPercentWatched(foundAsset);

    if (percentWatched >= 97) {
      return findNextPvrToPromote(foundAsset, seriesAsset);
    }

    return foundAsset;
  }

  return seriesAsset.subRecordings[seriesAsset.subRecordings.length - 1];
}
/** **********  PVR end ************/
/** ********************************/

/** ******* SHARED ASSETS **********/
export function getSharedAssets(
  promotedAsset: AltiboxAsset | UnionAssetTypes,
  type: AltiboxAssetDetailsType,
  _isSeries = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return async function (dispatch: DispatchType, getState: GetStateType) {
    let sharedAssets: SharedAssets | undefined = {} as SharedAssets;
    if (!_isSeries) {
      promotedAsset = (promotedAsset as AltiboxAsset).asset as UnionAssetTypes;
    }
    switch (type) {
      case AltiboxAssetDetailsType.SVOD:
        let svodAsset: VodAsset = promotedAsset as VodAsset;
        let vodProducts = (await getContentPricing(svodAsset.id)) as VodContentProduct[];
        let lowestPrice = 0;
        if (!isEmpty(vodProducts)) {
          const myContent = getState().vodReducer.myContent || [];
          const ownedAsset = myContent.find((owned) => owned.id === svodAsset.id);
          let isRented = false;
          let isBought = false;

          if (ownedAsset) {
            if (assetIsRented(ownedAsset)) {
              isRented = true;
            } else {
              isBought = true;
            }
          }

          lowestPrice = Math.min(...vodProducts.map((x: VodContentProduct) => Number(x.price)));
          const vodSharedAsset: VodSharedAsset = {
            asset: svodAsset,
            isBought,
            isRented,
            lowestPrice: lowestPrice,
            favoCatalog: '-1',
            pricing: vodProducts,
          };
          sharedAssets.vod = [vodSharedAsset];
          return Promise.resolve(sharedAssets);
        }
        break;
      case AltiboxAssetDetailsType.VOD:
      case AltiboxAssetDetailsType.VIDEO_VOD:
        let vodAsset: VodAsset = promotedAsset as VodAsset;
        let svodProducts = (await getPotentialSharedAssets(vodAsset.id)) as VodContentProduct[];
        svodProducts = getSvodProducts(svodProducts);
        if (!isEmpty(svodProducts)) {
          let svodSharedAssets: SvodSharedAsset[] = [];
          const kiosks = getState().app.svodKiosks;
          svodProducts.forEach((product: VodContentProduct) => {
            let identifier = product.introduce ? product.introduce : product.name ? product.name : '';
            let kiosk = getSharedAssetSvodKiosk(kiosks, identifier);
            if (kiosk) {
              svodSharedAssets.push({
                asset: vodAsset,
                kiosk: kiosk ? kiosk : undefined,
                favoCatalog: kiosk.favoId ? kiosk.favoId : '-1',
                pricing: svodProducts,
              });
            }
          });
          sharedAssets.svod = svodSharedAssets;
          if (isEmpty(svodSharedAssets)) {
            sharedAssets.svod = undefined;
          }
        }
        break;
      case AltiboxAssetDetailsType.PVR:
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PROGRAM:
      default:
        sharedAssets = undefined;
    }
    return Promise.resolve(sharedAssets);
  };
}
