import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { RootState, VodAssetDisplayMode, AltiboxAssetDetailsType } from '../../interfaces';
import { routes, searchConfig } from '../../config';
import FrontPage from './FrontPage';
import MyContent from './MyContent';
import './style.scss';
import VodPlayer from './VodPlayer';
import TrailerPlayer from './TrailerPlayer';
import Search from '../globalsearch';
import CategoryView from './CategoryView';
import Promo from './Promo';
import { clearSvodSelection } from '../svod/actions';
import DetailsView from '../details';

interface Props extends RouteComponentProps<{}> {
  isGuest: boolean;
  clearSvodSelection: () => Promise<void>;
}

class Vod extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.clearSvodSelection();
  }

  render() {
    return (
      <Switch>
        <Route
          path={routes.vod.base + routes.search.base + '*'}
          render={() => <Search searchConfig={searchConfig.vod} />}
        />

        <Route exact={true} path={routes.vod.base} render={() => <FrontPage searchConfig={searchConfig.vod} />} />

        <Route exact={true} path={routes.vod.base + routes.vod.myContent} render={() => <MyContent />} />

        <Route
          exact={true}
          path={routes.vod.base + routes.vod.playTrailer + '/:vodId'}
          render={() => <TrailerPlayer searchConfig={searchConfig.vod} />}
        />

        <Route
          exact={true}
          path={routes.vod.base + routes.vod.play + '/:vodId'}
          render={() => <VodPlayer searchConfig={searchConfig.vod} />}
        />

        <Route
          path={routes.vod.base + routes.vod.details + '/:parentId/:childId?'}
          render={() => <DetailsView displayType={AltiboxAssetDetailsType.VOD} />}
        />

        <Route
          path={routes.vod.base + routes.vod.category + '/:categoryId/:orderType?/:genreIds?'}
          render={() => <CategoryView viewType={VodAssetDisplayMode.VOD} searchConfig={searchConfig.vod} />}
        />

        <Route
          path={routes.vod.base + routes.vod.genre + '/:categoryId/:orderType?/:genreIds?'}
          render={() => <CategoryView viewType={VodAssetDisplayMode.VOD} searchConfig={searchConfig.vod} />}
        />

        <Route
          path={routes.vod.base + routes.vod.promo + '/:categoryId'}
          render={() => <Promo searchConfig={searchConfig.vod} />}
        />

        <Route path={routes.vod.base} render={() => <Redirect to={routes.vod.base} />} />
      </Switch>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      isGuest: state.authReducer.isGuest,
    }),
    (dispatch) => ({
      clearSvodSelection: () => dispatch(clearSvodSelection()),
    }),
  )(Vod),
);
