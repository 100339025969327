import { PortalMenu, BossType, CountryType, ApiAuthResponse, AltiboxURLs } from '../interfaces';
import { footerURLs } from '../config';
import { ScriptService } from '../controllers/ScriptService';

interface MemoizedAssets {
  ids: string[];
  portalMenus: PortalMenu[];
  assets: MemoizedAsset;
}
interface MemoizedAsset {
  [key: string]: number[];
}
export function memoizeAssets(ids: string[], svodKiosks: PortalMenu[]): MemoizedAssets {
  const tempMemoizedAssets: MemoizedAssets = {
    ids: Array.from(new Set(ids)),
    portalMenus: [],
    assets: {},
  };

  ids.forEach((id, idx) => {
    let exists = tempMemoizedAssets.assets[id];
    if (!exists) {
      tempMemoizedAssets.assets[id] = [idx];
    } else {
      tempMemoizedAssets.assets[id].unshift(idx);
    }
  });

  tempMemoizedAssets.ids.forEach((el, idx) => {
    let foundIndex = ids.findIndex((x) => x === el);
    if (foundIndex !== -1) {
      tempMemoizedAssets.portalMenus[idx] = svodKiosks[foundIndex];
    } else {
      tempMemoizedAssets.portalMenus[idx] = {} as PortalMenu;
    }
  });

  return tempMemoizedAssets;
}

export function goToPrevPath() {
  const redirectTo = localStorage.getItem('redirect-to');
  localStorage.removeItem('redirect-to');
  if (redirectTo) {
    window.location.href = redirectTo;
  } else {
    window.location.href = '/';
  }
}

export function setPrevPath(customPath?: string) {
  localStorage.setItem('redirect-to', customPath ? customPath : window.location.href);
}

export function getCountryBasedOnBossId(bossId: string) {
  return bossId === BossType.DK ? CountryType.DK : CountryType.NO;
}

export function isBossIdSameAsLocation(authResponse: ApiAuthResponse) {
  if (authResponse) {
    const countryByBossId = getCountryBasedOnBossId(authResponse.bossID);
    const authLocation = authResponse.location ? authResponse.location.toString() : undefined;
    return authLocation && countryByBossId ? authLocation.toLowerCase() === countryByBossId.toLowerCase() : false;
  }
  return false;
}

export const isGuestId = function (id: string) {
  return id.indexOf('guest') !== -1;
};

export function getFooterUrl(altiboxUrl: AltiboxURLs): string {
  const isDK = ScriptService.isDKUser();
  switch (altiboxUrl) {
    case AltiboxURLs.TOP_DOMAIN:
      return isDK ? footerURLs.dk.topDomain : footerURLs.no.topDomain;
    case AltiboxURLs.HOME:
      return isDK ? footerURLs.dk.home : footerURLs.no.home;
    case AltiboxURLs.GDPR:
      return isDK ? footerURLs.dk.gdprAndCookies : footerURLs.no.gdprAndCookies;
    case AltiboxURLs.MY_PAGE:
      return isDK ? footerURLs.dk.myPage : footerURLs.no.myPage;
    case AltiboxURLs.CUSTOMER_SERVICE:
      return isDK ? footerURLs.dk.customerService : footerURLs.no.customerService;
  }
}
