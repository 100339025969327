import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, GAaction, GAlabel, CountryType } from '../../../interfaces';
import getChannelPermissions from '../../../utils/channelPermissions';
import FullScreenMessage from '../../../components/FullscreenMessage';
import { Trans, useTranslation } from 'react-i18next';
import { useTracking } from '../../../hooks/useTracking';
import styles from './style.module.scss';

interface Props {
  canLogin: boolean;
  onMobile: boolean;
}

let HAS_TRACKED = false;

export default function ChannelLocked({ canLogin }: Props) {
  const currentProgram = useSelector((state: RootState) => state.channelsReducer.currentProgram);
  const currentChannel = useSelector((state: RootState) => state.channelsReducer.currentChannel);
  const auth = useSelector((state: RootState) => state.authReducer);

  const { t } = useTranslation();
  const { trackEvent } = useTracking();

  const currentProgramImage = currentProgram ? currentProgram.picture.poster : undefined;
  const channelPermissions = getChannelPermissions(currentChannel, auth);

  useEffect(() => {
    if (channelPermissions.geoLocked && !HAS_TRACKED) {
      HAS_TRACKED = true;
      trackEvent({
        action: GAaction.notification,
        label: `${GAlabel.channelGeoblocked} - ${currentChannel?.name} - ${currentChannel?.contentId}`,
      });
    } else if (channelPermissions.outsideHome && !HAS_TRACKED) {
      HAS_TRACKED = true;
      if (!auth.isGuest) {
        trackEvent({
          action: GAaction.notification,
          label: `${GAlabel.noChannelRightsOutsideHome} - ${currentChannel?.name} - ${currentChannel?.contentId}`,
        });
      }
    }
  }, [
    auth.isGuest,
    channelPermissions.geoLocked,
    channelPermissions.outsideHome,
    currentChannel?.contentId,
    currentChannel?.name,
    trackEvent,
  ]);

  function getMessage(): [boolean, JSX.Element | undefined] {
    if (channelPermissions.geoLocked) {
      return [false, <span key="dummy">{t('no rights for abroad')}</span>];
    } else if (auth.isGuest) {
      return [true, <span key="dummy">{t('log in to see this channel')}</span>];
    } else if (channelPermissions.outsideHome) {
      return [false, <span key="dummy">{t("can't show outside home")}</span>];
    } else if (channelPermissions.notInSubscription) {
      // todo translate
      if (auth.country === CountryType.DK) {
        return [false, <span key="dummy">Denne kanal findes ikke i din kanalpakke.</span>];
      } else {
        /* eslint-disable react/jsx-no-target-blank */
        return [
          false,
          <span key="dummy">
            <Trans
              t={t}
              i18nKey="missing channel access"
              values={{ channel: currentChannel?.name }}
              components={{
                a: (
                  <a href="https://altibox.no/" target="_blank">
                    altibox.no
                  </a>
                ),
              }}
            />
          </span>,
        ];
        /* eslint-enable react/jsx-no-target-blank */
      }
    }
    return [false, undefined];
  }

  if (!currentChannel) {
    return null;
  }

  const [showLogin, message] = getMessage();

  return (
    <FullScreenMessage backgroundImage={currentProgramImage} showLoginButton={showLogin && canLogin}>
      <img alt="channel-logo" src={currentChannel.pictures ? currentChannel.pictures[0].href : ''} />
      <p className={styles.message}>{message}</p>
    </FullScreenMessage>
  );
}
