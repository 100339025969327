import { Program as ProgramType, Channel } from '../../../interfaces';
import ProgramHeader from './PlaybillComponents/ProgramHeader';
import { useProgram } from '../../../hooks/useProgram';
import { ProgramMarkers } from './ProgramMarkers';

interface Props extends ProgramType {
  markers: JSX.Element;
  externalProgramButton: JSX.Element | null;
  channel: Channel;
  channelIsLocked: boolean;
  programSet: boolean;
  isGuest: boolean;
  hasPvr: boolean;
  loggedInWithCredentials: boolean;
  showDetailsPanel: (program: ProgramType) => void;
  trackPlay: (channel: string, seasonAndEpisode: string, programName: string) => void;
}

export default function FutureProgram(props: Props) {
  const program = useProgram(props);

  function showProgramDetails() {
    program.showDetailsPanel(props);
  }

  function trackProgram(channel: string, name: string) {
    const seasonAndEpisode = program.subNum && program.seasonNum ? ` S${program.seasonNum} E${program.subNum}` : '';
    program.trackPlay(channel, seasonAndEpisode, name);
  }

  return (
    <li className="tvGuideplaybillProgram future">
      <ProgramHeader
        showProgramDetailsCallback={showProgramDetails}
        startTime={program.startTime}
        name={program.name}
        episodeNumber={program.episodeNumber}
        markers={<ProgramMarkers {...program} trackProgram={trackProgram} />}
      />
    </li>
  );
}
