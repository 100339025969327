import React, { useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useQueryClient } from 'react-query';
import styles from './styles.module.scss';
import { useState } from 'react';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('react-query/devtools/development').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

const COPY_TEXT = 'Copy JSON';

export function ReactQueryCustomDevtools() {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [showProductionDevtools, setShowProductionDevtools] = useState(false);

  const [copyStatus, setCopyStatus] = useState(COPY_TEXT);

  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowProductionDevtools((prev) => !prev);
  }, []);

  async function copyJSON() {
    try {
      const queryHash = localStorage.getItem('reactQueryDevtoolsActiveQueryHash');
      const queryKey = JSON.parse(queryHash ? JSON.parse(queryHash) : '');
      const data = queryClient.getQueryData(queryKey);

      await navigator.clipboard.writeText(JSON.stringify(data, null, 2));

      setCopyStatus('Successfully copied JSON');
    } catch {
      setCopyStatus('Failed to copy');
    } finally {
      setTimeout(() => setCopyStatus(COPY_TEXT), 2500);
    }
  }

  return (
    <div className={styles.container}>
      {showProductionDevtools ? (
        <React.Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </React.Suspense>
      ) : null}

      <ReactQueryDevtools
        initialIsOpen={false}
        toggleButtonProps={{
          onClick: () => setIsOpen(true),
        }}
        closeButtonProps={{
          onClick: () => setIsOpen(false),
        }}
      />

      {isOpen && (
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={() => queryClient.clear()}>
            Clear Query Cache
          </button>
          <button className={`${styles.button} ${styles.copy}`} onClick={copyJSON}>
            {copyStatus}
          </button>
        </div>
      )}
    </div>
  );
}
