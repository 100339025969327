import { useEffect, useState } from 'react';
import { searchConfig } from '../../../config';
import ChannelsBannerListItem from '../../../components/ChannelsBanner/ChannelsBannerListItem/index';
import { Channel, RootState } from '../../../interfaces';
import StickyHeader from '../../app/StickyHeader';
import i18n from '../../../i18n';
import styles from './WatchTv.module.scss';
import { getOngoingProgramName, getSortedChannelsBySubscription } from '../../../utils/tvUtils';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { routes } from '../../../config';
import { isDisneyChannel } from '../../../utils/programArchiveUtils';
import Footer from '../../../components/Footer';
import { getFavoriteChannels } from '../actions';
import { isEmpty } from 'lodash';

function WatchTv() {
  const store = useSelector(
    (state: RootState) => ({
      channels: state.channelsReducer.channels,
      playbills: state.channelsReducer.playbills,
      favoriteChannels: state.channelsReducer.favoriteChannels,
    }),
    shallowEqual,
  );
  const auth = useSelector((state: RootState) => state.authReducer);
  const isGuest = useSelector((state: RootState) => state.authReducer.isGuest);
  const [channels, setChannels] = useState<Channel[]>([]);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const isProgramArchive = (): boolean => {
    let programArchiveUrl = `${routes.watchTv.base}${routes.watchTv.programarchive}`;
    return match.url === programArchiveUrl;
  };

  useEffect(() => {
    if (!isGuest && isEmpty(store.favoriteChannels)) {
      dispatch(getFavoriteChannels());
    }
  }, [store.favoriteChannels, isGuest, dispatch]);

  useEffect(() => {
    const isLoggedIn = auth.loggedInWithCredentials || Boolean(auth.loggedInWithIp);
    const channelsList = isLoggedIn ? getSortedChannelsBySubscription(store.channels) : store.channels;
    setChannels(channelsList);
  }, [auth, store.channels]);

  return (
    <>
      <StickyHeader searchConfig={searchConfig.global} />
      <h3 className={styles.watchTvHeaderWrapper}>
        {isProgramArchive() ? i18n.t<string>('programarchive') : i18n.t<string>('watch tv')}
      </h3>
      <div className={styles.watchTvChannelsContainer}>
        {channels.map((channel) => {
          if (
            isProgramArchive() &&
            channel.channelPermissions.hasEnabledCatchup === true &&
            channel.channelPermissions.notInSubscription === false &&
            !isDisneyChannel(channel)
          ) {
            return <ChannelsBannerListItem isProgramArchive={true} key={channel.name} channel={channel} />;
          }
          if (!isProgramArchive()) {
            return (
              <ChannelsBannerListItem
                isProgramArchive={false}
                key={channel.name}
                channel={channel}
                ongoingProgramName={store.playbills ? getOngoingProgramName(channel, store.playbills) : ''}
              />
            );
          }
          return null;
        })}
      </div>
      <Footer />
    </>
  );
}
export default WatchTv;
