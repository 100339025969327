import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { SvgPlayFilled } from '../../../icons';
import { PartialNever } from '../../../utils/typescriptUtils';
import Badge, { BadgeTypes } from '../../Badge';
import PvrPinIcon from '../../PvrPinIcon';
import styles from './HorizontalAsset.module.scss';
import { Image } from '../../Image';
import { useTranslation } from 'react-i18next';
import SeeAllWide from '../../../images/see-all-wide.svg';

enum BadgePositions {
  Top = 'top',
  TopLeft = 'top-left',
  TopRight = 'top-right',
  Bottom = 'bottom',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
}

interface BrandProps {
  src: string;
  alt: string;
}

interface ImageProps {
  src: string | undefined;
  alt: string;
  fallback?: string;
}

interface BadgeProps {
  text: string;
  type: BadgeTypes;
  position?: BadgePositions;
}

interface HorizontalAssetProps extends HTMLAttributes<HTMLElement> {
  // Default props
  image: ImageProps;
  titles: string[];
  subtitle?: string;
  link: string | string[];
  badge?: BadgeProps;
  brand?: BrandProps;
  progress?: number;
  glowOnHover?: boolean;
  playOnHover?: boolean;
  showPin?: boolean;

  // Skeleton props
  isSkeleton?: never;
  showSubtitle?: never;

  // See all props
  isSeeAll?: never;
}

interface HorizontalSkeletonAssetProps extends Omit<PartialNever<HorizontalAssetProps>, 'isSkeleton' | 'showSubtitle'> {
  isSkeleton: true;
  showSubtitle?: boolean;
}

interface HorizontalSeeAllAssetProps extends Omit<PartialNever<HorizontalAssetProps>, 'isSeeAll' | 'link'> {
  isSeeAll: true;
  link: string;
}

export function HorizontalAsset({
  image,
  titles,
  subtitle,
  link,
  badge,
  brand,
  progress,
  glowOnHover,
  playOnHover,
  showPin,
  showSubtitle,
  ...rest
}: HorizontalAssetProps | HorizontalSkeletonAssetProps | HorizontalSeeAllAssetProps) {
  const { t } = useTranslation();

  if ('isSkeleton' in rest && rest.isSkeleton) {
    return (
      <article className={styles.skeleton} aria-live="polite" aria-busy>
        <div className={styles.imageContainer}>
          <div className={styles.image} />
        </div>

        <div className={styles.body}>
          <div className={styles.title} />
          {showSubtitle && <div className={styles.subtitle} />}
        </div>
      </article>
    );
  }

  if ('isSeeAll' in rest && rest.isSeeAll) {
    return (
      <Link
        to={typeof link === 'string' ? link : ''}
        title="See all"
        className={`${styles.container} ${glowOnHover ? styles.glow : ''}`}
        draggable="false"
        onMouseDown={(e) => e.preventDefault}
      >
        <article>
          <div className={styles.imageContainer}>
            <p className={styles.seeAll}>{t('see all')}</p>
            <Image className={styles.image} src={SeeAllWide} alt="See all" preventDrag />
          </div>
        </article>
      </Link>
    );
  }

  if (typeof link === 'string') {
    return (
      <Link
        to={link ?? ''}
        title={titles?.join(' - ')}
        className={`${styles.container} ${glowOnHover ? styles.glow : ''}`}
        draggable="false"
        onMouseDown={(e) => e.preventDefault}
        {...rest}
      >
        <article>
          <div className={styles.imageContainer}>
            {playOnHover && (
              <div className={styles.overlay}>
                <SvgPlayFilled />
              </div>
            )}

            {showPin && <PvrPinIcon />}

            {badge && (
              <Badge className={`${styles.badge} ${badge.position ? styles[badge.position] : ''}`} {...badge} />
            )}

            {brand && (
              <img
                className={styles.brand}
                alt={brand.alt}
                src={brand.src}
                draggable="false"
                onMouseDown={(e) => e.preventDefault}
              />
            )}

            {Boolean(progress) && (
              <progress className={styles.progress} aria-label="Watched" value={progress} max={100} />
            )}

            <Image className={styles.image} src={image?.src} fallback={image?.fallback} alt={image?.alt} preventDrag />
          </div>
          <div className={styles.body}>
            <div className={styles.title}>
              {titles?.map((title, i) => (
                <span key={title}>
                  {titles.length > 1 && i !== 0 && ' - '}
                  {title}
                </span>
              ))}
            </div>
            {subtitle && (
              <div className={styles.subtitle} title={subtitle}>
                {subtitle}
              </div>
            )}
          </div>
        </article>
      </Link>
    );
  }

  return (
    <article
      className={`${styles.container} ${glowOnHover ? styles.glow : ''}`}
      draggable="false"
      onMouseDown={(e) => e.preventDefault()}
      {...rest}
    >
      <Link
        to={link?.[0] ?? ''}
        className={styles.imageContainer}
        draggable="false"
        onMouseDown={(e) => e.preventDefault()}
      >
        {playOnHover && (
          <div className={styles.overlay}>
            <SvgPlayFilled />
          </div>
        )}

        {showPin && <PvrPinIcon />}

        {badge && <Badge className={`${styles.badge} ${badge.position ? styles[badge.position] : ''}`} {...badge} />}

        {brand && (
          <img
            className={styles.brand}
            alt={brand.alt}
            src={brand.src}
            draggable="false"
            onMouseDown={(e) => e.preventDefault()}
          />
        )}

        {Boolean(progress) && <progress className={styles.progress} aria-label="Watched" value={progress} max={100} />}

        <Image className={styles.image} src={image?.src} fallback={image?.fallback} alt={image?.alt} preventDrag />
      </Link>
      <Link to={link?.[1] ?? ''} className={styles.body} draggable="false" onMouseDown={(e) => e.preventDefault()}>
        <div className={styles.title}>
          {titles?.map((title, i) => (
            <span key={title}>
              {titles.length > 1 && i !== 0 && ' - '}
              {title}
            </span>
          ))}
        </div>
        {subtitle && (
          <div className={styles.subtitle} title={subtitle}>
            {subtitle}
          </div>
        )}
      </Link>
    </article>
  );
}
