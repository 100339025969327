import { useQuery } from 'react-query';
import { getAllPlaybills as getAllPlaybillsOld } from '../../api/tv';
import { Program } from '../../interfaces';
import { reduceProgramMap } from '../../views/tv/actions';
import { getAllPlaybills, getPlaybillsForDay } from './api';
import { playbillKeys } from './keys';

interface UsePlaybillsArguments {
  channelIds: string[];
  date: Date;
  enabled?: boolean;
}

export function usePlaybills({ channelIds, date, enabled = true }: UsePlaybillsArguments) {
  return useQuery(
    playbillKeys.channels(channelIds, date),
    async () => {
      return getAllPlaybillsOld(channelIds, 3, 3, date).then(reduceProgramMap);
    },
    {
      staleTime: 1000 * 60 * 1,
      enabled,
    },
  );
}

interface UsePlaybillsForDayArguments<TData> {
  channelId: string;
  date: Date;
  enabled?: boolean;
  onSuccess?: (data: TData) => void;
}

export function usePlaybillsForDay<TData = Program[]>({
  channelId,
  date,
  enabled = true,
  onSuccess,
}: UsePlaybillsForDayArguments<TData>) {
  return useQuery(
    playbillKeys.channels([channelId], date),
    async () => {
      return getPlaybillsForDay(channelId, date);
    },
    {
      staleTime: 1000 * 60 * 60,
      enabled,
      onSuccess,
    },
  );
}

export function usePlaybillsMap({ channelIds, date, enabled = true }: UsePlaybillsArguments) {
  return useQuery(
    playbillKeys.channels(channelIds, date),
    async () => {
      return getAllPlaybills(channelIds, date).then(convertToMap);
    },
    {
      staleTime: 1000 * 60 * 1,
      enabled,
    },
  );
}

function convertToMap(programs: Program[]) {
  const tvguideToAppend = new Map<string, Program[]>();
  programs.forEach((program) => {
    if (program) {
      let programContainer = tvguideToAppend.get(program.channelid);

      if (programContainer) {
        programContainer.push(program);
      } else {
        tvguideToAppend.set(program.channelid, [program]);
      }
    }
  });
  return tvguideToAppend;
}
