import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../interfaces';
import { getAllGenres, getEpgGenres } from './api';
import { genreKeys } from './keys';
import { Genre } from './types';

type UseSelect<TReturn, TArg> = ((arg: TArg) => TReturn) | undefined;

const FIFTEEN_MIN = 1000 * 60 * 15;

/**
 *
 * @returns All genres
 */
export function useAllGenres<TReturn = Genre.All.Item[]>(transform?: UseSelect<TReturn, unknown>) {
  return useQuery(genreKeys.all, () => getAllGenres(), {
    select: transform,
    staleTime: FIFTEEN_MIN,
  });
}

/**
 *
 * @returns Translations for EPG genres
 */
export function useEpgGenres() {
  const areaId = useSelector((state: RootState) => state.authReducer.auth?.areaid);
  const bossId = useSelector((state: RootState) => state.authReducer.auth?.bossID);

  return useQuery(genreKeys.epg(), () => getEpgGenres(areaId!, bossId!), {
    staleTime: FIFTEEN_MIN,
  });
}
