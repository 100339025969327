import moment from 'moment';
import { huaweiDateTimeFormat } from '../../config';
import { Bookmark } from '../../interfaces';
import { useBookmarks } from '../bookmarks/queries';
import { useContinueWatchingRecordings } from '../recordings/queries';
import { useContinueWatchingVods } from '../vod/queries';
import { combineQueryResults } from '../../utils/queryUtils';
import { useContinueWatchingCatchup } from '../catchup/queries';
import { Vod } from '../vod/types';
import { Catchup } from '../catchup/types';
import { Recording } from '../recordings/types';

export enum StreamTypeNumber {
  CATCHUP = '3',
  VOD = '0',
  PVR = '2',
}

type CombinedQueryResults = [
  Vod.ConstructedAsset[] | undefined,
  Catchup.ConstructedAsset[] | undefined,
  Recording.Group.Union[] | undefined,
];

/**
 *
 * @returns Continue watching assets
 */
export function useContinueWatching() {
  const { data: bookmarks } = useBookmarks('all');
  const vodBookmarks = validBookmarksFor(bookmarks);
  const catchupBookmarks = validBookmarksFor(bookmarks, StreamTypeNumber.CATCHUP);

  const vods = useContinueWatchingVods(vodBookmarks);
  const catchup = useContinueWatchingCatchup(catchupBookmarks);
  const recordings = useContinueWatchingRecordings();

  return combineQueryResults<CombinedQueryResults>(vods, recordings, catchup);
}

function validBookmarksFor(bookmarks: Bookmark[] | undefined, bookmarkType?: StreamTypeNumber) {
  if (!bookmarks) {
    return [];
  }

  let bookmarksForType: Bookmark[];
  if (bookmarkType !== undefined) {
    bookmarksForType = bookmarks.filter((b) => b.bookmarkType === bookmarkType);
  } else {
    bookmarksForType = bookmarks
      .filter((b) => b.bookmarkType !== StreamTypeNumber.PVR)
      .filter((b) => b.bookmarkType !== StreamTypeNumber.CATCHUP);
  }

  return bookmarksForType.filter(bookmarkRangeTimeIsValid).filter(bookmarkIsRecentlyUpdated);
}

function bookmarkIsRecentlyUpdated(bookmark: Bookmark) {
  const recentlyBoundaryDate = moment().subtract(30, 'days');
  const lastWatchedDate = moment(bookmark.updateTime, huaweiDateTimeFormat);
  return lastWatchedDate.isAfter(recentlyBoundaryDate);
}

function bookmarkRangeTimeIsValid(bookmark: Bookmark) {
  const rangeTime = parseInt(bookmark.rangeTime, 10);
  return rangeTime > 0;
}
