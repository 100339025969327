import { flatten } from 'lodash';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SvgClose } from '../../icons';
import { useDeleteRecording } from '../../queries/recordings/mutations';
import { Recording } from '../../queries/recordings/types';
import { groupIsSeries } from '../../queries/recordings/utils';
import Modal from '../Modal';
import { ModalHeroBackground } from '../OptionsModal';
import { ButtonSecondary } from '../UI/Buttons';
import styles from './styles/DeleteModal.module.scss';

interface DeleteModalProps {
  isOpen: boolean;
  hideModal: () => void;
  idToDelete: string | string[];
  picture: string | undefined;
  name: string;
  shouldGoBackOnDelete?: boolean;
}

export function DeleteModal({ isOpen, hideModal, idToDelete, picture, name, shouldGoBackOnDelete }: DeleteModalProps) {
  const { t } = useTranslation();
  const { mutate, isLoading } = useDeleteRecording({
    mode: 'episode',
    shouldGoBackOnDelete,
  });

  function handleMutate() {
    const ids = Array.isArray(idToDelete) ? idToDelete : [idToDelete];
    mutate({ ids });
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      onRequestClose={hideModal}
      onAfterClose={hideModal}
      className={styles.modal}
    >
      <ModalHeroBackground imageUrl={picture} title={t('confirm deletion')} />

      <button className={styles.buttonClose} onClick={hideModal} children={<SvgClose />} />

      <main className={styles.mainContainer}>
        <h2>
          <Trans i18nKey="delete recording" ns="pvr" values={{ name }} />
        </h2>
        <ButtonSecondary onClick={handleMutate} isLoading={isLoading}>
          {t('confirm')}
        </ButtonSecondary>
      </main>
    </Modal>
  );
}

interface DeleteMultipleModalProps {
  isOpen: boolean;
  hideModal: () => void;
  selectedGroups: Recording.Group.Union[];
  isAllSelected: boolean;
  callback: () => void;
}

export function DeleteMultipleModal({
  isOpen,
  hideModal,
  selectedGroups,
  isAllSelected,
  callback,
}: DeleteMultipleModalProps) {
  const { t } = useTranslation();
  const { mutate, isLoading } = useDeleteRecording({
    callback: () => callback(),
    mode: 'series',
    shouldGoBackOnDelete: isAllSelected,
  });

  const episodesLength = useMemo(() => {
    return selectedGroups.reduce((acc, group) => {
      return acc + (groupIsSeries(group) ? group.episodes.length : 1);
    }, 0);
  }, [selectedGroups]);

  const ids = useMemo(() => {
    const arr = selectedGroups.map((group) => {
      if (groupIsSeries(group)) {
        return group.episodes.map((episode) => episode.pvrId);
      }

      return group.pvrId;
    });

    return flatten(arr);
  }, [selectedGroups]);

  function handleMutate() {
    mutate({ ids });
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      onRequestClose={hideModal}
      onAfterClose={hideModal}
      className={styles.modal}
    >
      <h1 className={styles.title}>{t('confirm deletion')}</h1>

      <button className={styles.buttonClose} onClick={hideModal} children={<SvgClose />} />

      <main className={styles.mainContainer}>
        <h2>
          <Trans i18nKey="delete multiple episodes" ns="pvr" values={{ episodes: episodesLength }} />
        </h2>
        <ButtonSecondary onClick={handleMutate} isLoading={isLoading}>
          {t('confirm')}
        </ButtonSecondary>
      </main>
    </Modal>
  );
}

interface DeleteMultipleSeasonsModalProps {
  isOpen: boolean;
  hideModal: () => void;
  selectedSeasons: Recording.Group.Season[];
  name: string | undefined;
  isAllSelected: boolean;
  callback: () => void;
}

export function DeleteMultipleSeasonsModal({
  isOpen,
  hideModal,
  selectedSeasons,
  name,
  isAllSelected,
  callback,
}: DeleteMultipleSeasonsModalProps) {
  const { t } = useTranslation();
  const { mutate, isLoading } = useDeleteRecording({
    callback: () => callback(),
    mode: 'season',
    shouldGoBackOnDelete: isAllSelected,
  });

  const seasonsString = useMemo(() => {
    const allSeasonNumbers = selectedSeasons.map((season) => season.seasonNum);

    if (allSeasonNumbers.length === 1) {
      return allSeasonNumbers[0];
    }

    const firstSeasonNumbers = allSeasonNumbers.slice(0, allSeasonNumbers.length - 1).join(', ');
    const lastSeasonNumber = allSeasonNumbers[allSeasonNumbers.length - 1];
    return `${firstSeasonNumbers} ${t('and')} ${lastSeasonNumber}`;
  }, [selectedSeasons, t]);

  const ids = useMemo(() => {
    const arr = selectedSeasons.map((season) => season.episodes.map((episode) => episode.pvrId));
    return flatten(arr);
  }, [selectedSeasons]);

  function handleMutate() {
    mutate({ ids });
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      onRequestClose={hideModal}
      onAfterClose={hideModal}
      className={styles.modal}
    >
      <h1 className={styles.title}>{t('confirm deletion')}</h1>

      <button className={styles.buttonClose} onClick={hideModal} children={<SvgClose />} />

      <main className={styles.mainContainer}>
        <h2>
          <Trans i18nKey="delete multiple seasons" ns="pvr" values={{ name, seasons: seasonsString }} />
        </h2>
        <ButtonSecondary onClick={handleMutate} isLoading={isLoading}>
          {t('confirm')}
        </ButtonSecondary>
      </main>
    </Modal>
  );
}

interface DeleteMultipleEpisodesModalProps {
  isOpen: boolean;
  hideModal: () => void;
  selectedEpisodes: Recording.Query.Series[];
  name: string | undefined;
  seasonNum: string;
  callback: () => void;
  isAllSelected: boolean;
}

export function DeleteMultipleEpisodesModal({
  isOpen,
  hideModal,
  selectedEpisodes,
  name,
  seasonNum,
  isAllSelected,
  callback,
}: DeleteMultipleEpisodesModalProps) {
  const { t } = useTranslation();
  const { mutate, isLoading } = useDeleteRecording({
    callback: () => callback(),
    mode: 'episode',
    shouldGoBackOnDelete: isAllSelected,
  });

  const ids = useMemo(() => {
    return selectedEpisodes.map((episode) => episode.pvrId);
  }, [selectedEpisodes]);

  function handleMutate() {
    mutate({ ids });
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      onRequestClose={hideModal}
      onAfterClose={hideModal}
      className={styles.modal}
    >
      <h1 className={styles.title}>{t('confirm deletion')}</h1>

      <button className={styles.buttonClose} onClick={hideModal} children={<SvgClose />} />

      <main className={styles.mainContainer}>
        <h2>
          {isAllSelected ? (
            <Trans i18nKey="delete multiple seasons" ns="pvr" values={{ name, seasons: seasonNum }} />
          ) : (
            <Trans i18nKey="delete multiple episodes" ns="pvr" values={{ episodes: selectedEpisodes.length }} />
          )}
        </h2>
        <ButtonSecondary onClick={handleMutate} isLoading={isLoading}>
          {t('confirm')}
        </ButtonSecondary>
      </main>
    </Modal>
  );
}
