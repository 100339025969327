import React, { Component, useRef, useState } from 'react';
import styles from './SortDropdown.module.scss';
import { SortCategories, VodListResponse, VodSvodSortingOption, PvrSorting, PvrSortingOption } from '../../interfaces';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import i18n from '../../i18n';
import { sortingIsNotPvr } from '../../typeGuards';
import { Key } from 'ts-key-enum';
import { useTranslation } from 'react-i18next';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';
import { SvgCheck, SvgRearrange } from '../../icons';
import FadeIn from '../UI/FadeIn';

interface Props extends RouteComponentProps {
  changeSortCallback: Function;
  initialSelectedSort: SortCategories | PvrSorting;
  currentVodCategory?: VodListResponse;
  sortingOptions?: (VodSvodSortingOption | PvrSortingOption)[];
}

interface State {
  selectedSort: SortCategories | PvrSorting;
  dropdownStatus: boolean;
}

class DefaultSortDropdown extends Component<Props, State> {
  state: State = {
    selectedSort: this.props.initialSelectedSort,
    dropdownStatus: false,
  };

  setNewSort = (newSort: VodSvodSortingOption | PvrSortingOption) => {
    this.setState({
      selectedSort: newSort.value,
    });
    this.props.changeSortCallback(newSort.value);
  };

  handleChangeSort = (newSort: VodSvodSortingOption | PvrSortingOption, isSelected: boolean) => {
    const { currentVodCategory, history } = this.props;
    if (isSelected) {
      return;
    }
    if (currentVodCategory && sortingIsNotPvr(newSort)) {
      if (parseInt(currentVodCategory.counttotal, 10) > 0) {
        history.replace(newSort.value);
        this.setNewSort(newSort);
      }
      return;
    }
    this.setNewSort(newSort);
  };

  handleDropdown = () => {
    this.setState({ dropdownStatus: !this.state.dropdownStatus });
  };

  render() {
    const { dropdownStatus, selectedSort } = this.state;
    const { sortingOptions } = this.props;
    if (!sortingOptions) {
      return null;
    }
    return (
      <>
        <div className={styles.sortDropdownContainer}>
          <button
            className={styles.dropdownButton}
            onMouseDown={(event) => {
              event.preventDefault();
              this.handleDropdown();
            }}
            onKeyDown={(event) => {
              if (event.key === Key.Enter) {
                this.handleDropdown();
              }
            }}
          >
            {i18n.t<string>('sort')}
            <span className={styles.aibIcon}>Ň</span>
          </button>
          {dropdownStatus && (
            <div tabIndex={-1} className={styles.sortDropdown}>
              <ul className={styles.sortingList}>
                {sortingOptions.map((option: VodSvodSortingOption | PvrSortingOption) => {
                  const isSelected = selectedSort === option.value;
                  return (
                    <button
                      className={isSelected ? styles.selectedSortingItem : styles.sortingItem}
                      key={option.i18nKey + option.value}
                      onMouseDown={(event) => {
                        event.preventDefault();
                        this.handleChangeSort(option, isSelected);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === Key.Enter) {
                          this.handleChangeSort(option, isSelected);
                        }
                      }}
                    >
                      {i18n.t<string>(option.i18nKey)}
                      {isSelected ? <span className={styles.aibIconSelected}>v</span> : null}
                    </button>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(DefaultSortDropdown);

interface SortDropdownProps<TValues> {
  options: SortDropdownOption<TValues>[];
  defaultValue?: TValues;
  onChange: (value: TValues) => void;
}

interface SortDropdownOption<TValues> {
  label: string;
  value: TValues;
}

/**
 * More generic sort dropdown
 * @param props
 */
export function SortDropdown<TValues>({ onChange, options, defaultValue }: SortDropdownProps<TValues>) {
  const { t } = useTranslation('sort');

  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);

  const [selectedValue, setSelectedValue] = useState(defaultValue ?? options[0].value);

  useOutsideAlerter(() => setIsOpen(false), [listRef]);

  function handleClick(value: TValues) {
    if (selectedValue !== value) {
      setSelectedValue(value);
      onChange(value);
    }
  }

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={() => setIsOpen(true)}>
        {t('sort')}
        <SvgRearrange />
      </button>
      {isOpen && (
        <FadeIn slideUp duration={250} className={styles.wrapper} childClassName={styles.dropdown}>
          <ul className={styles.list} ref={listRef}>
            {options.map(({ label, value }) => {
              return (
                <button
                  key={label}
                  onClick={() => handleClick(value)}
                  className={selectedValue === value ? styles.selected : styles.item}
                >
                  {t(label)}
                  {selectedValue === value && <SvgCheck />}
                </button>
              );
            })}
          </ul>
        </FadeIn>
      )}
    </div>
  );
}
