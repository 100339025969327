import React, { PureComponent, ChangeEvent, KeyboardEventHandler, MouseEventHandler } from 'react';
import moment from 'moment';
import Spinner from '../Spinner';
import shaka from 'shaka-player';
import { setupWidevineDrm } from './helpers/DashWidevinePlayerHelper';
import {
  shouldShowCastPlayer,
  getCastDeviceName,
  setNewCastStream,
  setNewCurrentOnPlayingCallback,
  getCastImage,
  getCurrentCastMediaStatus,
  getCastSession,
  getCastPlayerController,
  castStream,
  reloadAfterCastEnd,
} from './helpers/ChromeCast';
import { makeElementDraggable, DashPlayerUtil } from './helpers/PlayerHelper';
import {
  addBookmark,
  doPlayHeartBit,
  doPlayRecord,
  setDefaultAudioLanguage,
  setDefaultSubtitleLanguage,
  getDefaultAudioLanguage,
  getDefaultSubtitleLanguage,
  getFallbackAudioLanguage,
  getShortLanguage,
  getFallbackSubtitleLanguage,
} from '../../controllers/PlayerService';
import { ScriptService } from '../../controllers/ScriptService';
import OptionsModal from '../../components/OptionsModal';
import {
  OptionsModalContent,
  AuthReducerState,
  PlayerOptions,
  AltiboxAsset,
  ChromecastCustomData,
  NielsenChannelMapping,
  PortalMenu,
  Program,
  RootState,
} from '../../interfaces';
import AibTextDisplayer from './helpers/AibSimpleTextDisplayer';
import './styles/player-style.scss';
import i18n from '../../i18n';
import { StreamType, StreamTypeObject, StreamData } from '../../interfaces';
import { setupFairplayDrm } from './helpers/HLSFairplayHelper.js';
import { getBaseUrl, imageScaleValues, routes, shouldNotStretchVideo } from '../../config';
import isEmpty from 'lodash/isEmpty';
import { getTranslation } from '../../i18n/languageMap';
import PlayerControlRow from './UI/PlayerControls/PlayerControlsRow';
import VolumeControl from './UI/PlayerVolumeControl/index';
import HoverMenu from './UI/PlayerHoverMenu';
import { getImageUrlDimensions, getProgramCover } from '../../utils/altiboxassetUtils';
import { bookmarkKeys } from '../../queries/bookmarks/keys';
import { connect } from 'react-redux';
import { NielsenTracker } from '../../controllers/NielsenTracking';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { queryClient } from '../../queries/client';

enum AspectRatio {
  SD = 4 / 3,
  HD = 16 / 9,
}

export type ManifestStream = {
  bandwidth: number;
  id: number;
  audio?: {
    codecs?: string | undefined;
  };
  video: {
    bandwidth: number;
    width: number;
    height: number;
    codecs?: string | undefined;
  };
};

interface Subtitle {
  id: string;
  roles?: string[];
  key: number | string;
  language: string;
  accessibility?: boolean;
  active: boolean;
  hardOfHearing: boolean;
}
type Props = {
  unMountCallback?: Function;
  onConcurrencyError: Function;
  stream?: StreamTypeObject;
  hideControls: boolean;
  hideCursor?: boolean;
  liveFromStart?: boolean;
  onAlert: (title: string, message: string[]) => void;
  /* eslint-disable-next-line */
  onPlayerError: (_: { [key: string]: any }) => void;
  onProgramEnded: (_: StreamType) => void;
  onPlay: Function;
  onPause: Function;
  redirectSoon?: JSX.Element;
  onControlsClick?: Function;
  onScrollToLive?: Function;
  onPlayerClick?: Function;
  timeLeftCallback?: Function;
  authStatus: AuthReducerState;
  popupOverlay?: JSX.Element | null;
  includeInFullscreen?: () => JSX.Element;
  currentAsset?: AltiboxAsset;
  currentProgram?: Program;
  playNextEpisode?: () => void;
  svodKiosk?: PortalMenu;
  handleKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  seriesTitle?: string;
  nielsenChannelMapping?: NielsenChannelMapping[];
  isRecordingModalOpen: boolean;
} & RouteComponentProps<{}>;

interface State {
  playHeartbitIntervalTime: number;
  addBookmarkIntervalTime: number;
  liveDelaySeconds: number;
  playPausePulseIcon: string;
  showPlayPausePulse: boolean;
  videoCssClass: string;
  liveFromStart: boolean;
  position: number;
  rangePercent: number;
  seekbarWidth: number;
  windowWidth: number;
  isSeeking: boolean;
  isPlaying: boolean;
  showControls: boolean;
  showPlayerSeekbar: boolean;
  showPlayerSettings: boolean;
  autoBitrateIsSelected: boolean;
  isLive: boolean;
  liveEdgePercentOfScrollbar: number;
  showLanguageSelector: boolean;
  showSubtitleSelection: boolean;
  selectedTextTrackId: string;
  showAudioSelection: boolean;
  showCursor: boolean;
  isBuffering: boolean;
  currentStreamData: StreamData;
  capBandwidthAt: number;
  streamType: StreamType;
  contentId: string | undefined;
  chanNo: string | undefined;
  mediaId: string | undefined;
  pvrId: string | undefined;
  currentTimeText: string;
  endTimeText: string;
  isCasting: boolean;
  chromeCastIsLoading: boolean;
  castImage: string;
  range: {
    start: number;
    end: number;
  };
  customProgram: boolean;
  programEnd: string;
  programStart: string;
  startPlayerMuted: boolean;
  volume: number;
  showPlayerLog: boolean;
  showDebug: boolean;
  maxBandwithSelected: number;
  debug: {
    playertime: string;
    videobuffer: string;
    audiobuffer: string;
    duration: string;
    codec: string;
    frameRate: string;
    bitrateallowed: string;
    currentbitrate: string;
    heightWidth: string;
    droppedFrames: string;
  };
  isGuest: boolean;
  disconnected: boolean;
}

// DOC: https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html

// to add debug we need to include debug build in index.html:
// https://github.com/google/shaka-player/blob/master/docs/tutorials/debugging.md
// https://cdnjs.cloudflare.com/ajax/libs/shaka-player/2.5.5/shaka-player.compiled.debug.js
// shaka.log.setLevel(shaka.log.Level.DEBUG);

class Player extends PureComponent<Props, State> {
  // Strict Class Initialization ignored
  player!: shaka.Player;
  playerseekbar!: HTMLInputElement;
  volumeslider!: HTMLInputElement;
  tempScrubSubTimer!: NodeJS.Timer;
  seekThrottle!: NodeJS.Timer;
  addBookmarkInterval!: NodeJS.Timer;
  playHeartbitInterval!: NodeJS.Timer;
  videoWidthChangeTimeout!: NodeJS.Timer;
  checkTimeOfVideo!: NodeJS.Timer;
  liveAvailableFrom!: string;
  video!: HTMLVideoElement;
  videocontainer!: HTMLDivElement;
  videoOuterContainer!: HTMLDivElement;
  texttrack!: HTMLDivElement;
  textTrackInner!: HTMLDivElement;
  imageTrackInner!: HTMLDivElement;
  hasStartedFromLive = false;

  languageMap = {
    nosub: i18n.t<string>('nosub'),
    en: i18n.t<string>('english'),
    eng: i18n.t<string>('english'),
    no: i18n.t<string>('norwegian'),
    nb: i18n.t<string>('norwegian'),
    nor: i18n.t<string>('norwegian'),
    da: i18n.t<string>('danish'),
    dan: i18n.t<string>('danish'),
    sv: i18n.t<string>('swedish'),
    se: i18n.t<string>('sami'),
    smi: i18n.t<string>('sami'),
    swe: i18n.t<string>('swedish'),
    fi: i18n.t<string>('finnish'),
    fin: i18n.t<string>('finnish'),
    '': 'unknown',
  };

  NO_SUB = 'nosub';

  // local player state
  state = {
    playHeartbitIntervalTime: 300000, // 5 minutes
    addBookmarkIntervalTime: 30000, // 30 sec
    liveDelaySeconds: ScriptService._isSafari() ? 10 : 5, // TODO: Safari is having some issues so close to the live edge, fix when Huawei gets their sh*t in order.

    // the play pause icon in middle of player
    playPausePulseIcon: 'z', // "=" is pause
    showPlayPausePulse: false,

    videoCssClass: 'height-adjust',

    liveFromStart: false,

    position: 0,
    rangePercent: 0,
    seekbarWidth: 0,
    isSeeking: false,
    windowWidth: 0,
    isPlaying: false,
    showControls: false,
    showPlayerSeekbar: true,
    showPlayerSettings: false,
    autoBitrateIsSelected: true,

    isLive: false,
    liveEdgePercentOfScrollbar: 0,

    showLanguageSelector: false,
    showSubtitleSelection: false,
    selectedTextTrackId: '',
    showAudioSelection: false,

    showCursor: true,
    isBuffering: false,

    currentStreamData: {
      manifestUrl: '',
      licenseUrl: '',
      customData: '',
    },

    capBandwidthAt: -1,
    streamType: '' as StreamType,
    contentId: '',
    chanNo: '',
    mediaId: '',
    pvrId: '',

    currentTimeText: '',
    endTimeText: '',

    isCasting: false,
    chromeCastIsLoading: false,
    castImage: '',

    range: {
      start: 0,
      end: 0,
    },

    customProgram: false,
    programEnd: '0',
    programStart: '0',
    startPlayerMuted: localStorage.getItem('mute') === 'true' ? true : false,
    volume: 0,
    showPlayerLog: localStorage.getItem('playerdebug') === 'true' ? true : false,
    showDebug: localStorage.getItem('debug') === 'true' ? true : false,
    maxBandwithSelected: -1,

    debug: {
      playertime: '',
      videobuffer: '',
      audiobuffer: '',
      duration: '',
      codec: '',
      frameRate: '',
      bitrateallowed: '',
      heightWidth: '',
      currentbitrate: '',
      droppedFrames: '',
    },
    isGuest: true,
    disconnected: false,
  };

  isInLanguageMap(value: string) {
    const languageKeys = Object.keys(this.languageMap);
    return languageKeys.indexOf(value) !== -1;
  }

  getPreferredAudioLang() {
    const defaultAudioLanguage = getDefaultAudioLanguage();
    if (this.isInLanguageMap(defaultAudioLanguage)) {
      return defaultAudioLanguage;
    }
    return getFallbackAudioLanguage();
  }

  getPreferredSubtitleLang() {
    const defaultSubLanguage = getDefaultSubtitleLanguage();
    if (this.isInLanguageMap(defaultSubLanguage)) {
      return defaultSubLanguage;
    } else if (defaultSubLanguage === this.NO_SUB) {
      if (ScriptService._isSafari()) {
        return '';
      }
      return this.NO_SUB;
    }
    return getFallbackSubtitleLanguage();
  }

  componentDidMount() {
    queryClient.invalidateQueries(bookmarkKeys.all);

    this.setState({
      windowWidth: window.innerWidth,
    });
    const maxBitrate = localStorage.getItem('bitrate');
    if (maxBitrate) {
      this.setState({
        maxBandwithSelected: Number.isInteger(Number(maxBitrate)) ? Number(maxBitrate) : -1,
      });
    }

    if (shouldShowCastPlayer()) {
      this.registerChromecastOnPlayingCallback();
    } else {
      shaka.polyfill.installAll();
      if (shaka.Player.isBrowserSupported()) {
        var player = new shaka.Player(this.video);
        this.player = player;
        this.player.configure({
          streaming: {
            stallSkip: 1,
            stallThreshold: 4,
            stallEnabled: true,
            ignoreTextStreamFailures: true,
            bufferingGoal: 10,
            bufferBehind: 0,
            rebufferingGoal: 0.01,
            gapDetectionThreshold: 10,
            jumpLargeGaps: true,
            lowLatencyMode: true, // CMAF
            inaccurateManifestTolerance: 4,
            smallGapLimit: 9,
          },
          abr: {
            defaultBandwidthEstimate: 1000000,
            switchInterval: 6,
          },
          manifest: {
            defaultPresentationDelay: this.state.liveDelaySeconds,
            dash: {
              ignoreMinBufferTime: false,
            },
          },
          textDisplayFactory: () => {
            return new AibTextDisplayer(this.video, this.imageTrackInner, this.textTrackInner);
          },
        });
        this.setPreferredLanguages();
        this.player.setTextTrackVisibility(true);

        this.updateVolume();

        setInterval(this.checkCurrentTime, 500);
      } else {
        console.error('Browser not supported!');
      }

      // FOR DEBUGGING
      if (this.state.showDebug) {
        /* eslint-disable @typescript-eslint/dot-notation */
        window['video'] = this.video;
        window['player'] = this.player;
        makeElementDraggable('debugStatus');
        /* eslint-enable @typescript-eslint/dot-notation */
      }

      this.updateVideoStyle();
      this.startListeners();
      this.startReadyToCastListenersIfCanCast();
      this.video?.focus();
    }
  }

  initializeNielsenTracking = () => {
    if (this.player && ScriptService.isDKUser() && window && window.nielsen && !isEmpty(window.nielsen)) {
      const { currentAsset, seriesTitle, nielsenChannelMapping, svodKiosk } = this.props;

      if (NielsenTracker.isTracking) {
        NielsenTracker.stopTracking();
      }
      NielsenTracker.init(
        this.player,
        this.video,
        this.state.streamType,
        currentAsset?.asset,
        seriesTitle,
        nielsenChannelMapping,
        svodKiosk,
      );

      NielsenTracker.track();
    }
  };

  checkCurrentTime = () => {
    if (this.video && this.video.currentTime && this.props.timeLeftCallback) {
      let timeLeft = Math.ceil(Number(this.duration()) - Number(this.video.currentTime));
      this.props.timeLeftCallback(timeLeft);
    }
  };

  updateVolume = () => {
    const volume = localStorage.getItem('volume');
    let _volume = 0.5;

    if (volume) {
      _volume = !isNaN(Number(volume)) && Number(volume) <= 1 && Number(volume) >= 0 ? Number(volume) : 0.5;
    }

    this.setState(
      {
        volume: _volume,
      },
      () => {
        this.video.volume = _volume;
      },
    );
  };

  componentWillUnmount() {
    queryClient.invalidateQueries(bookmarkKeys.all);
    if (!shouldShowCastPlayer()) {
      this.removeListeners();
    }
    if (window.cast) {
      getCastPlayerController().removeEventListener(
        window.cast.framework.RemotePlayerEventType.ANY_CHANGE,
        reloadAfterCastEnd,
      );
      if (!ScriptService.isCasting()) {
        setNewCastStream(undefined, undefined);
      }
    }

    this.suspendPeriodicCalls();
    clearTimeout(this.tempScrubSubTimer);
    clearTimeout(this.checkTimeOfVideo);

    if (this.props.unMountCallback) {
      if (this.shouldAddBookmark() && this.state.streamType === StreamType.PVR) {
        let assetIdentifier = this.state.streamType === StreamType.PVR ? this.state.pvrId : this.state.contentId;
        let currentTime = this.video ? Math.floor(this.video.currentTime) : 0;
        let bookmarkTime = this.calculateCurrentBookmarkTime(currentTime);

        if (bookmarkTime >= 0) {
          this.props.unMountCallback(assetIdentifier, bookmarkTime);
        }
      } else {
        this.props.unMountCallback();
      }
    }

    /* eslint-disable @typescript-eslint/dot-notation */
    if (document.pictureInPictureElement && document.exitPictureInPicture) {
      document.exitPictureInPicture();
    }
    /* eslint-enable @typescript-eslint/dot-notation */

    if (this.player) {
      this.player.destroy();
    }
    if (NielsenTracker.shouldTrack()) {
      NielsenTracker.stopTracking();
    }
  }

  startReadyToCastListenersIfCanCast = () => {
    if (ScriptService.canCast()) {
      getCastPlayerController().addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
        (evt: cast.framework.RemotePlayerChangedEvent) => {
          if (evt.field === 'isConnected' && evt.value === true) {
            this.registerChromecastOnPlayingCallback();
            this.startChromecastLoading();
          }
        },
      );
    }
  };

  registerChromecastOnPlayingCallback = () => {
    setNewCurrentOnPlayingCallback(() => this.stopLoadingChromecastSpinner());
  };

  stopLoadingChromecastSpinner = () => {
    this.setState(
      {
        chromeCastIsLoading: false,
      },
      () => {
        this.forceUpdate();
      },
    );
  };

  startChromecastLoading = () => {
    const { currentAsset } = this.props;

    let secondsFromLive = 0;
    if (this.state.streamType === StreamType.LIVE) {
      const currentTime = this.video ? this.video.currentTime : 0;
      const liveEdge = this.getLiveEdgeInSeconds();
      secondsFromLive = currentTime - liveEdge;
    }

    if (currentAsset && !this.state.chromeCastIsLoading) {
      castStream(currentAsset, secondsFromLive);
    }
    this.setState(
      {
        chromeCastIsLoading: true,
      },
      () => {
        this.forceUpdate();
      },
    );
  };

  shouldChangeCastStream(stream: StreamTypeObject) {
    const castSession = getCastSession();
    if (!castSession) {
      // we are not connected, or we should not change stream
      return false;
    }
    const castMediaStatus = castSession.getMediaSession();
    if (!castMediaStatus) {
      // we are connected but have no active stream
      return true;
    }
    const customData = castMediaStatus.media.customData as ChromecastCustomData;
    // we are connected, but should we change the cast stream?
    if (customData) {
      switch (stream.streamType) {
        case StreamType.VOD:
        case StreamType.LIVE:
          return customData.contentId !== stream.contentId || customData.mediaId !== stream.mediaId;
        case StreamType.PVR:
          return customData.pvrId !== stream.pvrId;
        case StreamType.TRAILER:
          return customData.mediaId !== stream.mediaId;
        case StreamType.CATCHUP:
          return customData.mediaId !== stream.contentId;
        default:
          return false;
      }
    }
  }

  // Will fire when props are changed on Component from outside-component
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (ScriptService.isDKUser() && NielsenTracker.shouldTrack()) {
      // Loads new metadata if on same channel and program is changing and has reached the end of the program
      if (
        this.props.stream?.chanNo === nextProps.stream?.chanNo &&
        this.props.currentAsset?.asset.id !== NielsenTracker.assetTrackingId &&
        this.hasReachedTheEnd(this.video.currentTime)
      ) {
        const { currentAsset, seriesTitle, nielsenChannelMapping, svodKiosk } = this.props;
        NielsenTracker.loadNewMetadata(
          this.player,
          this.video,
          this.state.streamType,
          currentAsset?.asset,
          seriesTitle,
          nielsenChannelMapping,
          svodKiosk,
        );
      }
    }
    if (nextProps.stream) {
      // let CC know the current stream
      setNewCastStream({ ...nextProps.stream, startBookmark: this.video?.currentTime ?? '0' }, this.props.authStatus);
    }

    if (shouldShowCastPlayer()) {
      if (nextProps.stream) {
        if (this.shouldChangeCastStream(nextProps.stream)) {
          this.startChromecastLoading();
        }
      }
      const { currentAsset } = this.props;
      const programCover = currentAsset && getProgramCover(currentAsset);
      let castImage = getCastImage();
      if (!castImage && programCover) {
        castImage =
          currentAsset && getProgramCover(currentAsset)
            ? getImageUrlDimensions(programCover, imageScaleValues.vod)
            : '';
      }
      this.setState({
        castImage,
        isCasting: true,
      });
    } else {
      if (nextProps.stream) {
        if (nextProps.liveFromStart !== this.state.liveFromStart) {
          if (nextProps.liveFromStart) {
            this.hasStartedFromLive = false;
          }
          this.setState({ liveFromStart: nextProps.liveFromStart ? nextProps.liveFromStart : false });
        }

        let streamToStart = nextProps.stream;
        let streamType = streamToStart.streamType;

        var isChangingMpd =
          streamToStart.playData &&
          streamToStart.playData.manifestUrl &&
          this.state.currentStreamData &&
          this.state.currentStreamData.manifestUrl !== streamToStart.playData.manifestUrl;
        if (isChangingMpd) {
          this.setState({
            streamType: streamType,
            contentId: streamToStart.contentId,
            chanNo: streamToStart.chanNo,
            mediaId: streamToStart.mediaId,
            pvrId: streamToStart.pvrId,
            liveFromStart: nextProps.liveFromStart ? nextProps.liveFromStart : false,
            isBuffering: true,
          });

          this.hasStartedFromLive = false;

          let manifestUrl = streamToStart.playData.manifestUrl;
          if (ScriptService._isSafari()) {
            // must pop in safari(enables timeshift)
            let firstPartOfManifestUrl = manifestUrl.split('|').pop();
            if (firstPartOfManifestUrl) {
              manifestUrl = firstPartOfManifestUrl;
            }
            this.playFairplay(streamToStart);
          } else {
            // must shift in dash
            let lastPartOfManifestUrl = manifestUrl.split('|').shift();
            if (lastPartOfManifestUrl) {
              manifestUrl = lastPartOfManifestUrl;
            }
            let cappingLevel = streamToStart.playData.capBandwidthAt ? streamToStart.playData.capBandwidthAt : -1;
            this.playWidevine(streamToStart, cappingLevel);
          }

          let startTime = null;
          let bookmark = streamToStart.startBookmark;

          // start on initial bookmark
          if (streamToStart.streamType !== StreamType.LIVE) {
            startTime = bookmark && bookmark !== 0 ? bookmark : null;
          }
          this.playManifest(manifestUrl, startTime);
          if (this.video && this.video.currentTime) {
            this.setState({ currentTimeText: this.getCurrentLiveTimeText(this.video.currentTime) });
          }
        }

        // if no end or start is set, make set timeline 1 hour back 5 hours ahead
        this.setState({
          isGuest: streamToStart.isGuest,
          customProgram:
            streamToStart.programEnd ||
            streamToStart.streamType === StreamType.VOD ||
            streamToStart.streamType === StreamType.TRAILER
              ? false
              : true,
          programEnd: streamToStart.programEnd
            ? streamToStart.programEnd
            : moment().add(5, 'hours').format('YYYYMMDDHHmmss'),
          programStart: streamToStart.programStart
            ? streamToStart.programStart
            : moment().subtract(1, 'hours').format('YYYYMMDDHHmmss'),
        });

        if (streamType === StreamType.LIVE) {
          this.updateLiveRange();
        } else {
          this.setState({
            isLive: false,
          });
        }
      }
    }
    if (nextProps.hideControls) {
      this.showPlayerSeekbar();
    }
  }

  playManifest(manifestUrl: string, startTime: null | number) {
    // reset to a suitable resolution
    this.setBandwidthBasedOnVideoWidth();
    if (!this.player) {
      return;
    }
    this.player
      .load(manifestUrl, startTime)
      .then(() => {
        if (this.userHasSelectedBadwidth()) {
          this.setBandwidth(this.state.maxBandwithSelected);
          this.setState({
            autoBitrateIsSelected: false,
          });
        }

        if (this.state.streamType !== StreamType.LIVE) {
          let seekRange = this.player.seekRange();
          this.setState({
            // set the range we can scroll in
            range: {
              start: seekRange.start,
              end: seekRange.end,
            },
          });
          this.updateCurrentTime(startTime ? startTime : 0);
        }
        this.setPreferredLanguages();
      })
      .catch(this.onPlayerError);
  }

  // safari
  playFairplay(streamToStart: StreamTypeObject) {
    if (this.player && streamToStart.playData) {
      this.updateStreamAndDrm(streamToStart, () => {
        setupFairplayDrm(this.video, this.state.currentStreamData, getBaseUrl(), this.onAlert);
      });
    }
  }

  // firefox, chrome, opera
  playWidevine(streamToStart: StreamTypeObject, cappingLevel: number) {
    if (this.player && streamToStart.playData) {
      this.updateStreamAndDrm(streamToStart, () => {
        setupWidevineDrm(
          this.player,
          this.state.currentStreamData,
          streamToStart.streamType,
          this.state.programStart,
          cappingLevel,
        );
      });
    }
  }

  updateStreamAndDrm(streamToStart: StreamTypeObject, callbackDoneUpdatingStream: Function) {
    this.setState(
      {
        currentStreamData: {
          manifestUrl: streamToStart.playData.manifestUrl,
          licenseUrl: streamToStart.playData.licenseUrl ? streamToStart.playData.licenseUrl : '',
          customData: streamToStart.playData.customData,
        },
      },
      () => {
        callbackDoneUpdatingStream();
      },
    );
  }

  suspendPeriodicCalls() {
    if (!this.state.isGuest) {
      clearInterval(this.addBookmarkInterval);
      clearInterval(this.playHeartbitInterval);
      this.playRecord(false);
      this.addBookmark();
    }
  }

  startPeriodicCalls() {
    if (!this.state.isGuest) {
      clearInterval(this.addBookmarkInterval);
      this.addBookmarkInterval = setInterval(() => {
        this.addBookmark();
      }, this.state.addBookmarkIntervalTime);

      // PlayheartBit
      clearInterval(this.playHeartbitInterval);
      this.playHeartbitInterval = setInterval(() => {
        this.playHeartBit();
      }, this.state.playHeartbitIntervalTime);

      if (!this.state.isCasting) {
        this.playRecord(true);
      }
    }
  }

  playRecord(start: boolean) {
    const { isGuest, contentId, pvrId, chanNo, streamType, mediaId } = this.state;
    if (isGuest) {
      return;
    }

    switch (streamType) {
      case StreamType.PVR:
        doPlayRecord(pvrId, streamType, start);
        break;
      case StreamType.LIVE:
        doPlayRecord(chanNo, streamType, start);
        break;
      case StreamType.CATCHUP:
        doPlayRecord(contentId, streamType, start, mediaId);
        break;
      default:
        doPlayRecord(contentId, streamType, start);
        break;
    }

    if (start) {
      this.playHeartBit();
    }
  }

  playHeartBit() {
    if (!this.state.isGuest) {
      doPlayHeartBit(this.state.contentId, this.state.streamType, this.state.mediaId).catch((error) => {
        if (error.success === false) {
          this.suspendPeriodicCalls();
          this.video.pause();
          this.onAlert(i18n.t<string>('concurrency title'), [
            i18n.t<string>('concurrency message1'),
            i18n.t<string>('concurrency message2'),
          ]);
          this.props.onConcurrencyError();
        }
      });
    }
  }

  // add bookmark for all except live and trailer
  shouldAddBookmark() {
    return (
      this.state.streamType !== StreamType.LIVE &&
      this.state.streamType !== StreamType.TRAILER &&
      this.props.authStatus.loggedInWithCredentials
    );
  }

  calculateCurrentBookmarkTime(nowTime: number) {
    return moment('00:00:00', 'HH:mm:ss')
      .add(Math.ceil(nowTime), 'seconds')
      .diff(moment('00:00:00', 'HH:mm:ss'), 'seconds');
  }

  addBookmark() {
    if (!this.state.isGuest) {
      if (this.shouldAddBookmark()) {
        let assetIdentifier = this.state.streamType === StreamType.PVR ? this.state.pvrId : this.state.contentId;
        let currentTime = this.video ? Math.floor(this.video.currentTime) : 0;
        let bookmarkTime = 0;

        if (this.state.streamType === StreamType.PVR) {
          bookmarkTime = this.calculateCurrentBookmarkTime(currentTime);
        } else if (this.state.streamType === StreamType.CATCHUP) {
          bookmarkTime = this.calculateCurrentBookmarkTime(currentTime);
        } else {
          bookmarkTime = currentTime;
        }

        if (bookmarkTime >= 0) {
          addBookmark(
            assetIdentifier, // pvrId for PVR
            this.state.streamType,
            bookmarkTime,
          );
        }
      }
    }
  }

  startListeners = () => {
    this.player.addEventListener('buffering', this.onBuffering);
    this.player.addEventListener('error', this.onPlayerError);

    if (this.video) {
      this.video.addEventListener('playing', this.onPlaybackPlaying);
      this.video.addEventListener('timeupdate', this.onPlaybackTimeUpdated);
      this.video.addEventListener('ended', this.onPlaybackEnded);
      this.video.addEventListener('pause', this.onPlaybackPaused);
      this.video.addEventListener('loadeddata', this.onLoadeddata);
    }

    window.addEventListener('resize', this.onWindowResize);
    window.addEventListener('offline', this.onDisconnect);
    window.addEventListener('online', this.onConnect);
    if (ScriptService.canCast() && getCastPlayerController()) {
      getCastPlayerController().addEventListener(
        window.cast.framework.RemotePlayerEventType.ANY_CHANGE,
        reloadAfterCastEnd,
      );
    }
  };

  removeListeners() {
    if (this.player) {
      this.player.removeEventListener('buffering', this.onBuffering);
      this.player.removeEventListener('error', this.onPlayerError);
    }

    if (this.video) {
      this.video.removeEventListener('ended', this.onPlaybackEnded);
      this.video.removeEventListener('playing', this.onPlaybackPlaying);
      this.video.removeEventListener('timeupdate', this.onPlaybackTimeUpdated);
      this.video.removeEventListener('pause', this.onPlaybackPaused);
      this.video.removeEventListener('loadeddata', this.onLoadeddata);
    }

    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('offline', this.onDisconnect);
    window.removeEventListener('online', this.onConnect);
  }

  focusVideoContainer: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.screenX === 0 && event.screenY === 0) {
      // actaully keyboard event
      return;
    }
    if (!this.props.popupOverlay) {
      this.videoOuterContainer?.focus();
    }
  };

  onKeyPressed: KeyboardEventHandler<HTMLDivElement> = (event) => {
    switch (event.key) {
      case ' ': // space
        this.togglePlayPauseWithControls();
        break;
      case 'm':
        this.toggleMute();
        break;
      case 'f':
        this.toggleFullscreen();
        break;
      default:
        break;
    }

    if (this.props.handleKeyDown) {
      this.props.handleKeyDown(event);
    }
  };

  onConnect = () => {
    this.setState({
      disconnected: false,
    });
  };

  onDisconnect = () => {
    this.setState({
      disconnected: true,
    });
  };

  onWindowResize = (evt: Event) => {
    clearInterval(this.videoWidthChangeTimeout);
    if (this.state.autoBitrateIsSelected) {
      // only do the update 1 sec after last resize event
      this.videoWidthChangeTimeout = setTimeout(() => {
        this.setBandwidthBasedOnVideoWidth();
        this.setState({
          windowWidth: window.innerWidth,
        });
      }, 1000);
    }

    this.updateVideoStyle();
  };

  /**
   * Calculates the aspect ratio based on manifest data
   * reading the SAR value from MPD on one of the video streams
   * @returns calculated aspect ratio
   */
  getManifestAspectRatio = (manifestVideo: shaka.extern.Stream) => {
    const sar = manifestVideo.pixelAspectRatio!.split(':')!;
    const manifestHeight = manifestVideo.height;
    const manifestWidth = manifestVideo.width;
    return (Number(sar[0]) * manifestWidth!) / (Number(sar[1]) * manifestHeight!);
  };

  updateTexttrackStyle = () => {
    if (this.texttrack && !ScriptService._isSafari()) {
      this.texttrack.style.top = this.video.getBoundingClientRect().top + 'px';
      this.texttrack.style.width = '100%';
      this.texttrack.style.height = this.video.offsetHeight + 'px';
    }
  };

  updateVideoStyle = () => {
    if (!this.video) {
      return;
    }
    const { innerWidth, innerHeight } = window;
    const {
      documentElement: { clientHeight, clientWidth },
    } = document;
    const bodyElement = document.getElementsByTagName('body')[0];
    const width = innerWidth || clientWidth || bodyElement.clientWidth;
    const height = innerHeight || clientHeight || bodyElement.clientHeight;
    const variants = this.player?.getManifest()?.variants;

    if (
      (variants && variants[0].video && this.getManifestAspectRatio(variants[0].video) === AspectRatio.SD) ||
      (shouldNotStretchVideo(this.state.contentId) && this.state.streamType !== StreamType.VOD)
    ) {
      this.video.style.height = '100%';
      this.video.style.width = height * (4 / 3) + 'px';
      if (width / height > 4 / 3) {
        this.video.style.height = '100%';
        this.video.style.width = height * (4 / 3) + 'px';
      } else {
        this.video.style.height = width / (4 / 3) + 'px';
        this.video.style.width = '100%';
      }
    } else {
      if (width / height > 16 / 9) {
        this.video.style.height = '100%';
        this.video.style.width = height * (16 / 9) + 'px';
        this.setState({
          videoCssClass: 'width-adjust',
        });
      } else {
        this.video.style.height = width / (16 / 9) + 'px';
        this.video.style.width = '100%';
        this.setState({
          videoCssClass: 'height-adjust',
        });
      }
    }
    this.updateTexttrackStyle();
  };

  onLoadeddata = () => {
    if (this.video) {
      this.updateVideoStyle();

      this.setState({
        position: this.video.currentTime,
      });
      this.initializeNielsenTracking();
    }
  };

  onPlayerError = (evt: Event) => {
    if (evt.type === 'error') {
      /* eslint-disable-next-line */
      const errorEvent = evt as { [key: string]: any };
      if (
        errorEvent &&
        errorEvent.detail &&
        errorEvent.detail.data &&
        errorEvent.detail.data[0] &&
        errorEvent.detail.data[0].restrictedKeyStatuses &&
        errorEvent.detail.data[0].restrictedKeyStatuses[0] &&
        errorEvent.detail.data[0].restrictedKeyStatuses[0]
      ) {
        this.props.onAlert('HDCP-Error:', [i18n.t<string>('hdcp_error')]);
      } else {
        if (errorEvent.code && errorEvent.code === 4012) {
          console.error('DRM ERROR');
          this.showErrorModal('' + errorEvent.code);
        } else if (errorEvent.code && errorEvent.code !== 7000) {
          // 7000 is LOAD_INTERRUPTED
          this.props.onPlayerError(errorEvent);
          console.error('PLAYER ERROR');
          console.error(errorEvent);
        } else if (errorEvent.type && errorEvent.type === 'error') {
          console.error('PLAYER ERROR');
          console.error(errorEvent);
        }
      }
    }
  };

  onPlaybackEnded = (evt: Event) => {
    if (this.state.showDebug) {
      console.log('onPlaybackEnded');
      console.log(evt);
    }
    this.onProgramEnded();
  };

  onAlert = (title: string, message: string[]) => {
    this.props.onAlert(title, message);
  };

  onPlaybackPaused = () => {
    this.suspendPeriodicCalls();
    this.setState({
      isPlaying: false,
    });
  };

  clearSubtitleContainers = () => {
    if (this.textTrackInner) {
      this.textTrackInner.innerHTML = '';
    }
    if (this.imageTrackInner) {
      this.imageTrackInner.innerHTML = '';
    }
  };

  onPlaybackPlaying = () => {
    this.clearSubtitleContainers();

    this.setState({
      isPlaying: true,
      isBuffering: false,
    });

    this.startPeriodicCalls();

    if (this.state.streamType === StreamType.LIVE) {
      this.updateLiveRange();
    } else {
      let seekRange = this.player.seekRange();
      this.setState({
        // set the range we can scroll in
        range: {
          start: seekRange.start,
          end: seekRange.end,
        },
      });
    }
    this.updateVideoStyle();
    this.streamLoaded();
  };

  updateLiveRange = () => {
    if (this.player) {
      const startRange = this.getStartTimeForLiveRange();
      const endRange = startRange + this.getRangeDuration();
      if (!isNaN(startRange)) {
        this.setState(
          {
            range: {
              start: startRange,
              end: endRange,
            },
          },
          () => {
            if (this.state.liveFromStart === true && !this.hasStartedFromLive && this.video) {
              this.video.currentTime = this.getProgramStartTime();
              this.hasStartedFromLive = true;
            }
          },
        );
      }
    }
  };

  getProgramStartTime() {
    const startRange = this.getStartTimeForLiveRange();
    return this.player.seekRange().end - Math.abs(startRange);
  }

  getStartTimeForLiveRange() {
    var diff = moment(this.player.getPlayheadTimeAsDate(), 'X').diff(this.toMoment(this.state.programStart), 'seconds');
    return this.getCurrentTime() - diff;
  }

  getRangeDuration() {
    return this.toMoment(this.state.programEnd).diff(this.toMoment(this.state.programStart), 'seconds');
  }

  onBuffering = (evt: Event): void => {
    if (evt.type === 'buffering') {
      /* eslint-disable-next-line */
      const bufferEvent = evt as { [key: string]: any };
      this.setState({
        isBuffering: bufferEvent.buffering,
      });
    }
  };

  hasReachedTheEnd(time: number) {
    return time > this.state.range.end && this.state.range.end !== 0;
  }

  onPlaybackTimeUpdated = () => {
    if (this.state.showDebug) {
      this.updateDebug();
    }

    let currentTime = this.video ? this.video.currentTime : 0;
    if (!this.state.isSeeking) {
      if (this.state.streamType === StreamType.LIVE) {
        // on live check if current program is finished so we can update UI
        if (this.hasReachedTheEnd(currentTime)) {
          this.onProgramEnded();
        } else {
          let liveEdge = this.getLiveEdgeInSeconds();
          let rangeUnit = (this.state.range.end - this.state.range.start) / 100;
          let rangePosition = liveEdge - this.state.range.start;
          this.setState({
            liveEdgePercentOfScrollbar: rangePosition / rangeUnit / 100,
          });

          let liveEdgeDiff = currentTime - liveEdge;
          if (liveEdgeDiff <= 15 && liveEdgeDiff >= -15) {
            this.setState({
              position: currentTime,
              isLive: true,
              endTimeText: i18n.t<string>('live').toUpperCase(),
            });
          } else {
            this.setState({
              isLive: false,
            });
          }
          this.updateLiveCurrentTime(currentTime);
        }
      } else {
        this.updateCurrentTime(currentTime);
      }
    }
  };

  updateDebug() {
    if (this.state.showDebug) {
      let stats = this.player.getStats();
      let activeTrack = this.getActiveTrack();
      let videoBuffer, audioBuffer;
      if (ScriptService._isSafari()) {
        videoBuffer = '' + Math.ceil(this.getVideoBufferLengthInSeconds());
        audioBuffer = videoBuffer;
      } else {
        videoBuffer = '' + Math.ceil(this.getVideoBufferLengthInSeconds());
        audioBuffer = '' + Math.ceil(this.getAudioBufferLengthInSeconds());
      }

      this.setState({
        debug: {
          playertime: '' + Math.ceil(this.getCurrentTime()),
          videobuffer: videoBuffer,
          audiobuffer: audioBuffer,
          codec: DashPlayerUtil.getCurrentCodec(this.player),
          frameRate: activeTrack && activeTrack.frameRate ? activeTrack.frameRate.toString() : 'NA',
          duration: '' + this.duration(),
          bitrateallowed: this.getMaxedAllowedBitrate() + 'Mbps',
          currentbitrate: (stats.streamBandwidth / 1000 / 1000).toFixed(2) + 'Mbps',
          heightWidth: this.video ? this.video.videoHeight + '/' + this.video.videoWidth : 'NA',
          droppedFrames: this.player ? this.player.getStats().droppedFrames.toString() : 'NA',
        },
      });
    }
  }

  hasEmptyBuffer = () => {
    if (this.getVideoBufferLengthInSeconds() <= 1 || this.getAudioBufferLengthInSeconds() <= 1) {
      return true;
    } else {
      return false;
    }
  };

  getVideoBufferLengthInSeconds() {
    if (!this.player || !this.video) {
      return 0;
    }
    let bufferedInfo = this.player.getBufferedInfo();

    if (ScriptService._isSafari()) {
      return bufferedInfo && bufferedInfo.total && bufferedInfo.total[0]
        ? bufferedInfo.total[0].end - this.video.currentTime
        : 0;
    } else {
      return bufferedInfo && bufferedInfo.video && bufferedInfo.video[0]
        ? bufferedInfo.video[0].end - this.video.currentTime
        : 0;
    }
  }

  getAudioBufferLengthInSeconds() {
    if (!this.player || !this.video) {
      return 0;
    }
    let bufferedInfo = this.player.getBufferedInfo();
    if (bufferedInfo && bufferedInfo.audio && bufferedInfo.audio[0]) {
      return bufferedInfo.audio[0].end - this.video.currentTime;
    }
    return 0;
  }

  getCurrentBitrate = () => {
    if (this.player && this.player.getStats) {
      return this.player.getStats().streamBandwidth;
    }
  };

  userHasSelectedBadwidth = () => {
    return this.state.maxBandwithSelected !== -1;
  };

  onSetBandwidthButtonClicked = (stream: ManifestStream) => {
    // auto is selected
    if (stream.bandwidth === -1) {
      this.setState({
        autoBitrateIsSelected: true,
        maxBandwithSelected: -1,
      });
      localStorage.removeItem('bitrate');
      this.setBandwidthBasedOnVideoWidth();
    } else {
      this.setBandwidth(stream.bandwidth);
      localStorage.setItem('bitrate', '' + stream.bandwidth);
      this.setState({
        autoBitrateIsSelected: false,
        maxBandwithSelected: stream.bandwidth,
      });
    }
  };

  setBandwidth = (bandwidth: number) => {
    // Shaka v3.x-patch: We need to update the config ourselves by merging the old one with the new additions, since Shaka v3.0 broke it :(
    let newConfig = {
      abr: {
        restrictions: {
          maxBandwidth: bandwidth,
          maxWidth: Infinity,
        },
      },
    };
    this.player.configure(Object.assign({}, this.player.getConfiguration(), newConfig));
  };

  setBandwidthBasedOnVideoWidth = () => {
    if (this.player && this.video) {
      const width = this.video.offsetWidth;
      // Shaka v3.x-patch: We need to update the config ourselves by merging the old one with the new additions, since Shaka v3.0 broke it :(
      let newConfig = {
        abr: {
          restrictions: {
            maxWidth: width,
            maxBandwidth: Infinity,
          },
        },
      };
      this.player.configure(Object.assign({}, this.player.getConfiguration(), newConfig));
    }
  };

  streamLoaded = () => {
    // if we have subtitles available show the button
    if (this.player.getTextLanguages().length >= 1) {
      this.setState({
        showSubtitleSelection: true,
      });
    } else {
      this.setState({
        showSubtitleSelection: false,
      });
    }

    // if more than one audiotracks show button
    let tracks = this.player.getAudioLanguages();
    if (tracks.length > 1) {
      this.setState({
        showAudioSelection: true,
      });
    } else {
      this.setState({
        showAudioSelection: false,
      });
    }
  };

  setPreferredLanguages() {
    // set prefered audio and sub
    // Shaka v3.x-patch: We need to update the config ourselves by merging the old one with the new additions, since Shaka v3.0 broke it :(
    let newConfig = {
      preferredAudioLanguage: this.getPreferredAudioLang(),
      preferredTextLanguage: this.getPreferredSubtitleLang(),
    };
    this.player.configure(Object.assign({}, this.player.getConfiguration(), newConfig));

    // https://github.com/google/shaka-player/issues/2269
    // But seems to be the case as well for npm-installed versions of shaka.
    // Workaround for now:
    if (ScriptService._isSafari()) {
      this.player.selectAudioLanguage(this.getPreferredAudioLang());
      this.player.selectTextLanguage(this.getPreferredSubtitleLang());
    }

    // Check for no subtitles and fallback audio language
    this.setFallbackSubtitlesIfNeeded();
  }

  setSubtitle = (subtitle: Subtitle) => {
    const language = subtitle.language;
    if (subtitle.id === this.NO_SUB || language === this.NO_SUB) {
      this.player.setTextTrackVisibility(false);
      this.setState({
        selectedTextTrackId: this.NO_SUB,
      });
      setDefaultSubtitleLanguage(this.NO_SUB);
    } else {
      this.clearSubtitleContainers();
      this.setState({
        selectedTextTrackId: subtitle.id,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const foundTextTrack = this.player.getTextTracks().find((x: any) => x.id.toString() === subtitle.id);
      if (foundTextTrack) {
        this.player.selectTextTrack(foundTextTrack);
      }
      this.player.setTextTrackVisibility(true);
      setDefaultSubtitleLanguage(language);
    }
  };

  setAudioTrack(track: shaka.extern.LanguageRole) {
    const languageToSet = getShortLanguage(track.language);
    this.player.selectAudioLanguage(languageToSet);
    setDefaultAudioLanguage(languageToSet);
  }

  toggleMute = () => {
    if (this.video) {
      if (this.video.volume === 0.0 || this.video.muted === true) {
        this.video.muted = false;
      } else {
        this.video.muted = true;
      }

      localStorage.setItem('mute', '' + this.video.muted);
    }
  };

  volumeChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      volume: Number(evt.target.value),
    });

    this.video.volume = Number(evt.target.value);

    if (this.video.volume === 0.0) {
      this.video.muted = true;
    } else {
      this.video.muted = false;
    }

    localStorage.setItem('volume', evt.target.value);
    localStorage.setItem('mute', '' + this.video.muted);
  };

  isFullscreen() {
    return ScriptService.isFullscreen();
  }

  toggleFullscreen = () => {
    const videoContainer = this.videocontainer;
    if (this.isFullscreen()) {
      this.exitFullscreen();
    } else {
      this.requestFullscreen(videoContainer);
    }
  };

  requestFullscreen = (videoContainer: HTMLDivElement) => {
    /* eslint-disable @typescript-eslint/dot-notation */
    if (videoContainer['mozRequestFullScreen']) {
      videoContainer['mozRequestFullScreen'](); // Firefox
    } else if (videoContainer['requestFullscreen']) {
      videoContainer['requestFullscreen']();
    } else if (videoContainer['webkitRequestFullScreen']) {
      videoContainer['webkitRequestFullScreen']();
    } else if (videoContainer.requestFullscreen) {
      videoContainer.requestFullscreen(); // Edge
    }
    /* eslint-enable @typescript-eslint/dot-notation */
  };

  exitFullscreen = () => {
    /* eslint-disable @typescript-eslint/dot-notation */
    if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['webkitCancelFullScreen']) {
      document['webkitCancelFullScreen']();
    }
    /* eslint-enable @typescript-eslint/dot-notation */
  };

  togglePlayPauseWithControls = () => {
    if (!this.state.isCasting) {
      this.playPauseClick(true);
    }
  };

  togglePlayPause = () => {
    this.playPauseClick();
  };

  playPauseClick = (override: boolean = false) => {
    if (!this.video) {
      return;
    }
    var video = this.video;
    if (!this.props.hideControls || override) {
      if (video.paused) {
        video.play();
        this.props.onPlay();

        this.setState({
          isPlaying: true,
        });
      } else {
        video.pause();
        this.props.onPause();

        this.setState({
          isPlaying: false,
        });
      }
      this.playPausePulse();
    }
  };

  playPausePulse() {
    if (!this.video) {
      return;
    }
    var video = this.video;

    if (video.paused) {
      this.setState({
        playPausePulseIcon: '=',
      });
    } else {
      this.setState({
        playPausePulseIcon: 'z',
      });
    }

    this.setState({
      showPlayPausePulse: true,
    });

    setTimeout(() => {
      this.setState({
        showPlayPausePulse: false,
      });
    }, 800);
  }

  toMoment(timeString: string) {
    return moment(timeString, 'YYYYMMDDHHmmss');
  }

  // returns the video elements current time
  getCurrentTime = () => {
    if (this.video) {
      return Math.floor(this.video.currentTime);
    } else {
      return 0;
    }
  };

  duration() {
    let seekRange = this.player.seekRange();
    return seekRange.end - seekRange.start;
  }

  getCurrentLiveTimeText(time: number) {
    let currentTimeText = '';
    if (time) {
      if (this.state.range.start !== 0) {
        var seekFromStart = time - this.state.range.start;
        currentTimeText = this.toMoment(this.state.programStart).add(seekFromStart, 'seconds').format('HH:mm:ss');
      }
    }
    return currentTimeText;
  }

  // live
  updateLiveCurrentTime(time: number) {
    this.setState({
      position: time,
      currentTimeText: this.getCurrentLiveTimeText(time),
      endTimeText: i18n.t<string>('live').toUpperCase(),
    });
  }

  // pvr catchup vod svod
  updateCurrentTime(time: number) {
    this.setState({
      position: time,
      currentTimeText: this.getTimeStringBasedOnSeconds(Math.ceil(time)),
      endTimeText: this.getTimeStringBasedOnSeconds(Math.ceil(this.duration())),
    });
  }

  getTimeStringBasedOnSeconds(seconds: number) {
    return moment('00:00:00', 'HH:mm:ss').add(seconds, 'seconds').format('HH:mm:ss');
  }

  getProgramEndTime() {
    return this.toMoment(this.state.programEnd).format('HH:mm');
  }

  getLiveEdgeInSeconds() {
    // in case you are already casting and player is not mounted, you cannot get the starttime.
    if (!this.player) {
      return 0;
    }
    return (
      moment().diff(moment(this.player.getPresentationStartTimeAsDate(), 'X'), 'seconds') - this.state.liveDelaySeconds
    );
  }

  restrictBeforeSeek() {
    if (!ScriptService._isSafari()) {
      // Shaka v3.x-patch: We need to update the config ourselves by merging the old one with the new additions, since Shaka v3.0 broke it :(
      let newConfig = {
        restrictions: {
          maxWidth: DashPlayerUtil.getSkippingWidth(this.player),
        },
        streaming: {
          bufferingGoal: 2,
        },
      };

      this.player.configure(Object.assign({}, this.player.getConfiguration(), newConfig));
    }
  }

  restrictAfterSeek() {
    if (!ScriptService._isSafari()) {
      // Shaka v3.x-patch: We need to update the config ourselves by merging the old one with the new additions, since Shaka v3.0 broke it :(
      let newConfig = {
        restrictions: {
          maxWidth: undefined,
        },
      };
      this.player.configure(Object.assign({}, this.player.getConfiguration(), newConfig));

      setTimeout(() => {
        if (this.video && this.player) {
          let delayedUpdatedConfig = {
            streaming: {
              bufferingGoal: 10,
            },
          };
          this.player.configure(Object.assign({}, this.player.getConfiguration(), delayedUpdatedConfig));
        }
      }, 2000);
    }
  }

  positionRangeChange(evt: ChangeEvent<HTMLInputElement>) {
    this.clearSubtitleContainers();

    this.setState({
      isSeeking: true,
    });

    let value = Number(evt.target.value);

    // for some strange reason uneven numbers cannot be seeked to
    if (value % 2 === 1) {
      value++;
    }

    let keepUpdatingPosition = true;
    if (this.state.streamType === StreamType.LIVE) {
      let liveEdge = this.getLiveEdgeInSeconds();
      if (liveEdge <= value) {
        this.setState({
          position: liveEdge,
          isLive: true,
        });
        keepUpdatingPosition = false;
      } else {
        this.updateLiveCurrentTime(value);
        this.setState({
          isLive: false,
        });
      }
      if (keepUpdatingPosition) {
        this.updateLiveCurrentTime(value);
      }
    } else {
      this.updateCurrentTime(value);
    }

    // so we dont fire alot of seeks when dragging slider
    clearTimeout(this.seekThrottle);
    this.seekThrottle = setTimeout(() => {
      this.setState({
        isSeeking: false,
      });
      if (this.video) {
        this.restrictBeforeSeek();

        this.video.currentTime = this.state.position;

        this.restrictAfterSeek();

        if (this.state.streamType === StreamType.LIVE && this.state.isLive && this.props.onScrollToLive) {
          this.props.onScrollToLive();
        }
      }
    }, 500);
  }

  // notifies to outer component
  onPlayerClick = () => {
    this.togglePlayPause();

    if (this.props.onPlayerClick) {
      this.props.onPlayerClick();
    }
  };

  skipBackClick = () => {
    const newTime = this.video.currentTime - 30;

    if (this.state.streamType === StreamType.LIVE) {
      var diff = moment(this.player.getPlayheadTimeAsDate(), 'X').diff(
        this.toMoment(this.state.programStart),
        'seconds',
      );
      if (diff - 30 > 0) {
        this.setVideoCurrentTime(newTime);
      } else {
        // seek to start of current program
        this.setVideoCurrentTime(this.video.currentTime - diff);
      }
    } else {
      // NOT live
      if (newTime - this.state.range.start >= 0) {
        this.setVideoCurrentTime(newTime);
      } else {
        this.setVideoCurrentTime(this.state.range.start + 1);
      }
    }
  };

  goToLive() {
    this.video.currentTime = this.player.seekRange().end;

    // In AltiboxAssetButton (ref: playOrContinueWatchingButton) we check if the user is watching from the start by the start route
    const path = this.props.location.pathname;
    if (path.includes(routes.tv.start)) {
      this.props.history.replace(path.replace(routes.tv.start, ''));
    }
  }

  skipForwardClick = () => {
    const newTime = this.video.currentTime + 30;

    if (this.player.isLive() && newTime > this.player.seekRange().end) {
      this.goToLive();
    } else if (newTime > this.state.range.end) {
      this.onProgramEnded();
    } else {
      this.setVideoCurrentTime(newTime);
    }
  };

  setVideoCurrentTime(time: number) {
    this.restrictBeforeSeek();
    let value = Math.floor(time);
    // for some strange reason uneven numbers cannot be seeked to
    if (value % 2 === 1) {
      value++;
    }
    this.video.currentTime = value;
    this.restrictAfterSeek();
  }

  onProgramEnded = () => {
    if (!this.state.customProgram) {
      this.suspendPeriodicCalls();
      this.props.onProgramEnded(this.state.streamType);
    }

    if (this.state.streamType === StreamType.PVR || this.state.streamType === StreamType.CATCHUP) {
      if (this.video) {
        this.video.pause();
      }
    }
  };

  getLiveRangeCss = () => {
    return (
      <style type="text/css">
        {'input[type=range].player-seekbar::-webkit-slider-runnable-track { ' +
          'border-radius: 2px;' +
          'background-image: -webkit-gradient(linear, left top, right top, color-stop(' +
          (this.state.liveEdgePercentOfScrollbar >= 0.6
            ? this.state.liveEdgePercentOfScrollbar - 0.01
            : this.state.liveEdgePercentOfScrollbar) +
          ', #FFFFFF), color-stop(' +
          (this.state.liveEdgePercentOfScrollbar >= 0.6
            ? this.state.liveEdgePercentOfScrollbar - 0.01
            : this.state.liveEdgePercentOfScrollbar) +
          ', #5a5958)) !important; }' +
          'input[type=range]::-moz-range-track { border-radius: 2px; background: linear-gradient(to right, #FFFFFF, #FFFFFF ' +
          this.state.liveEdgePercentOfScrollbar * 100 +
          '%, #5a5958 ' +
          this.state.liveEdgePercentOfScrollbar * 100 +
          '%)!important; }'}
      </style>
    );
  };

  getRangeCss = () => {
    let position = this.state.position / (this.state.range.start - this.state.range.end);
    return (
      <style type="text/css">
        {'input[type=range].player-seekbar::-webkit-slider-runnable-track { ' +
          'border-radius: 2px;' +
          'background-image: -webkit-gradient(linear, left top, right top, color-stop(' +
          (1 - position - 1) +
          ', #FFFFFF), color-stop(' +
          (1 - position - 1) +
          ', #5a5958)) !important; }' +
          'input[type=range]::-moz-range-track { border-radius: 2px; background: linear-gradient(to right, #FFFFFF, #FFFFFF ' +
          (100 * (1 - position) - 100) +
          '%, #5a5958 ' +
          (100 * (1 - position) - 100) +
          '%)!important; }'}
      </style>
    );
  };
  updateRangeThumb = (element: HTMLInputElement) => {
    const { end } = this.state.range;
    const isLiveStream = this.state.streamType === StreamType.LIVE;
    let { width } = element.getBoundingClientRect();
    let { min, value } = element;
    let percent = ((Number(value) - Number(min)) / (Number(end) - Number(min))) * 100;
    const marginLeft = isLiveStream ? 25 : 25;
    let elementWidth = width - marginLeft;
    this.setState({ rangePercent: percent, seekbarWidth: elementWidth });
  };
  getActiveTrack = () => {
    let audioTracks: shaka.extern.TrackList = this.player.getVariantTracks();
    return audioTracks.find((x: shaka.extern.Track) => x.active === true);
  };

  getAvailableVideoStreams() {
    return DashPlayerUtil.getAvailableVideoStreams(this.player);
  }

  getMaxedAllowedBitrate() {
    return DashPlayerUtil.getMaxedAllowedBitrate(this.player).toFixed(2);
  }

  airplayButtonClick = () => {
    /* eslint-disable @typescript-eslint/dot-notation */
    if (this.video['webkitShowPlaybackTargetPicker']) {
      this.video['webkitShowPlaybackTargetPicker']();
    }
    /* eslint-enable @typescript-eslint/dot-notation */
  };

  languageButtonClick() {
    this.setState({
      showLanguageSelector: !this.state.showLanguageSelector,
      showPlayerSettings: false,
    });
  }

  languageSelectorHide() {
    this.setState({
      showLanguageSelector: false,
    });
  }

  getLiveTimer() {
    const isLiveStream = this.state.streamType === StreamType.LIVE;
    if (!isLiveStream) {
      return null;
    }
    return i18n.t<string>('live').toUpperCase();
  }

  getProgramTimer() {
    const isLiveStream = this.state.streamType === StreamType.LIVE;
    if (isLiveStream) {
      return null;
    }
    return (
      <>
        {this.state.currentTimeText} <span>/ {this.state.endTimeText}</span>
      </>
    );
  }

  // DOM Creation //
  controls() {
    const isLiveStream = this.state.streamType === StreamType.LIVE;
    let endTimeClass = 'player-control-item range-text end-time-text';
    if (isLiveStream) {
      endTimeClass += ' live-player';
      if (this.state.isLive) {
        endTimeClass += ' live';
      }
    }
    return (
      <div
        className="player-controls"
        onClick={() => {
          if (this.props.onControlsClick) {
            this.props.onControlsClick();
          }
        }}
      >
        {this.state.showPlayerSeekbar ? (
          <div className="player-control-row range-row">
            {isLiveStream ? this.getLiveRangeCss() : this.getRangeCss()}
            <input
              name="slider"
              className="player-control-item player-seekbar"
              ref={(element) => {
                if (element) {
                  this.playerseekbar = element;
                  this.updateRangeThumb(element);
                }
              }}
              type="range"
              step="1"
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                this.positionRangeChange(evt);
              }}
              value={this.state.position}
              max={this.state.range.end}
              min={this.state.range.start}
            />
            <div
              className="relative-wrapper"
              style={{
                width: this.state.seekbarWidth + 'px',
              }}
            >
              <output
                style={{
                  left: this.state.rangePercent + '%',
                }}
              >
                {isLiveStream || this.state.isSeeking ? this.state.currentTimeText : null}
              </output>
            </div>
            <div
              className={endTimeClass}
              onClick={() => {
                if (isLiveStream) {
                  this.goToLive();
                }
              }}
            >
              {this.getLiveTimer()}
              {this.getProgramTimer()}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  checked() {
    return <span className="selection-selected aib-icon">v</span>;
  }

  playerBitRates() {
    let videoStreams = this.getAvailableVideoStreams();

    videoStreams.reverse();

    // the Auto setting
    videoStreams.push({
      id: -1,
      bandwidth: -1,
      video: {
        bandwidth: -1,
        width: 0,
        height: 0,
      },
    });

    let currentSelectedBitrate = this.state.maxBandwithSelected ? this.state.maxBandwithSelected : false;
    let hasCurrentSelection = false;
    let bitrateJSX = videoStreams.map((stream: ManifestStream) => {
      let checkmark = null;
      let listClassName = '';
      let streamQuality =
        stream.bandwidth !== -1 ? `${stream.video.height}p (${(stream.bandwidth / 1000000).toFixed(2)}Mbps)` : 'Auto';
      /* eslint-disable-next-line */
      if ((this.state.autoBitrateIsSelected && stream.id === -1) || stream.bandwidth === currentSelectedBitrate) {
        hasCurrentSelection = true;
        listClassName = ' selected';
        checkmark = this.checked();
      }
      return (
        <li
          tabIndex={0}
          key={stream.id}
          onClick={() => {
            this.onSetBandwidthButtonClicked(stream);
          }}
          className={listClassName}
        >
          {streamQuality}
          {checkmark}
        </li>
      );
    });

    return {
      jsx: bitrateJSX,
      hasCurrentSelection: hasCurrentSelection,
    };
  }

  setFallbackSubtitlesIfNeeded() {
    let hasNoSub = getDefaultSubtitleLanguage() === this.NO_SUB;
    if (hasNoSub) {
      this.player.setTextTrackVisibility(false);
      this.setState({
        selectedTextTrackId: this.NO_SUB,
      });
    }
  }

  getFirstAvailableAudioTrack() {
    let audioAndRoles = this.player.getAudioLanguagesAndRoles();
    if (!isEmpty(audioAndRoles)) {
      return audioAndRoles[0];
    }
    return undefined;
  }

  audios() {
    if (!this.player) {
      return;
    }
    let audioAndRoles = this.player.getAudioLanguagesAndRoles();
    let selectedAudioTrack = this.getActiveTrack();
    return audioAndRoles.map((track: shaka.extern.LanguageRole, index: number) => {
      let isSelected = selectedAudioTrack && selectedAudioTrack.language === track.language;
      return (
        <li
          className={isSelected ? 'selected' : ''}
          tabIndex={0}
          key={`${track.language}${index}`}
          onClick={this.setAudioTrack.bind(this, track)}
        >
          {getTranslation(track.language)}
          {isSelected ? this.checked() : null}
        </li>
      );
    });
  }

  subtitles() {
    if (!this.player) {
      return;
    }
    let textTracks: Subtitle[] = [];
    let videoTextTracks = this.player.getTextTracks();
    let isNoSub = this.state.selectedTextTrackId === this.NO_SUB;

    function hasHardOfHearing(track: shaka.extern.Track): boolean {
      if (ScriptService._isSafari()) {
        return Boolean(track?.kind === 'captions');
      }
      return Boolean(track?.roles?.indexOf('HH') !== -1);
    }

    videoTextTracks.forEach((track: shaka.extern.Track, index: number) => {
      if (track.language !== '') {
        textTracks.push({
          language: track.language,
          key: index,
          id: track.id.toString(),
          hardOfHearing: hasHardOfHearing(track),
          active: !isNoSub && track.active,
        });
      }
    });
    if (ScriptService._isSafari()) {
      const HLSNoSub = videoTextTracks.find((x: shaka.extern.Track) => x.language === '');
      textTracks.unshift({
        language: 'nosub',
        key: 'nosub',
        id: (HLSNoSub ? HLSNoSub.id : this.NO_SUB).toString(),
        hardOfHearing: false,
        active: isNoSub ? true : false,
      });
    } else {
      textTracks.unshift({
        language: this.NO_SUB,
        key: this.NO_SUB,
        id: this.NO_SUB,
        hardOfHearing: false,
        active: isNoSub ? true : false,
      });
    }
    return textTracks.map((track) => (
      <li
        className={track.active ? 'selected' : ''}
        tabIndex={0}
        key={track.key}
        onClick={this.setSubtitle.bind(this, track)}
      >
        {getTranslation(track.language)}
        {track.hardOfHearing ? '(HOH)' : ''}
        {track.active ? this.checked() : null}
      </li>
    ));
  }

  hidePlayerSeekbar = () => {
    this.setState({
      showPlayerSeekbar: false,
    });
  };

  showPlayerSeekbar = () => {
    this.setState({
      showPlayerSeekbar: true,
    });
  };

  volumeControl = () => {
    return (
      <VolumeControl
        volume={this.state.volume}
        muted={this.video.muted}
        changeVolume={this.volumeChange}
        toggleMute={this.toggleMute}
      />
    );
  };

  streamQualityControl = () => {
    return (
      <HoverMenu
        className={'stream-quality'}
        icon={'Q'}
        optionsType={PlayerOptions.BitRates}
        playerBitRates={this.playerBitRates().jsx}
        currentBitRateStatus={this.getCurrentBitrate()}
        maxBitRateStatus={this.getMaxedAllowedBitrate()}
      />
    );
  };

  audioAndSubtitlesControl = () => {
    let safariClass = ScriptService._isSafari() ? ' safari' : '';
    let pipClass = ScriptService.isPiPSupported() ? ' pip' : '';
    return (
      <HoverMenu
        className={'audio-and-subtitles' + safariClass + pipClass}
        icon={'ë'}
        optionsType={PlayerOptions.AudioAndSubtitles}
        audios={this.audios()}
        subtitles={this.subtitles()}
      />
    );
  };

  togglePictureInPicture = () => {
    const {
      video,
      video: { readyState, HAVE_ENOUGH_DATA },
    } = this;
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
      return;
    }
    if (video && readyState === HAVE_ENOUGH_DATA && document.pictureInPictureEnabled) {
      /* eslint-disable @typescript-eslint/dot-notation */
      video['requestPictureInPicture']();
      /* eslint-enable @typescript-eslint/dot-notation */
    }
  };

  controlWrapper() {
    if (!this.video) {
      return null;
    }
    const isWatchingLive = this.state.streamType === StreamType.LIVE && this.state.isLive;

    return (
      <>
        <PlayerControlRow
          onMouseEnter={this.hidePlayerSeekbar}
          onMouseLeave={this.showPlayerSeekbar}
          volumeControl={this.volumeControl()}
          streamQualityMenu={this.streamQualityControl()}
          audioAndSubtitlesMenu={this.audioAndSubtitlesControl()}
          isPlaying={this.state.isPlaying}
          playPause={this.playPauseClick}
          isWatchingLive={isWatchingLive}
          fastForward={this.skipForwardClick}
          rewind={this.skipBackClick}
          isFullscreen={ScriptService.isFullscreen()}
          fullscreen={this.toggleFullscreen}
          airplay={this.airplayButtonClick}
          playNextEpisode={this.props.playNextEpisode}
          togglePictureInPicture={this.togglePictureInPicture}
          currentProgram={this.props.currentProgram}
        />
        <div className="player-controls-wrapper">{this.controls()}</div>
      </>
    );
  }

  // used for debugging stream
  streamStatus() {
    return (
      <div id="debugStatus" className="status">
        <p>Player time: {this.state.debug.playertime}</p>
        <p>Duration: {this.state.debug.duration}</p>
        <p>Video BufferLength: {this.state.debug.videobuffer}</p>
        <p>Audio BufferLength: {this.state.debug.audiobuffer}</p>
        <p>Codec: {this.state.debug.codec}</p>
        <p>Frame Rate: {this.state.debug.frameRate}</p>
        <p>Max allowed bitrate: {this.state.debug.bitrateallowed}</p>
        <p>Current bitrate: {this.state.debug.currentbitrate}</p>
        <p>Aspect: {this.state.debug.heightWidth}</p>
        <p>Dropped Frames: {this.state.debug.droppedFrames}</p>
      </div>
    );
  }

  showErrorModal = (code?: string) => {
    this.props.onAlert('Error', [i18n.t<string>('an_error_occured') + (code !== '' ? ': ' + code : '')]);
  };

  showDisconnectModal = () => {
    const content = {
      modalIsOpen: true,
      title: i18n.t<string>('network error'),
      onClose: () => window.location.reload(),
      closetext: i18n.t<string>('try again'),
      subtitle2: i18n.t<string>('no internet connection'),
      overrideButtonIcon: ';',
    } as OptionsModalContent;

    return <OptionsModal {...content} />;
  };

  spinnerWhenBuffering() {
    return (
      <div className="buffering-loader">
        <Spinner wrapInContainer={true} />
      </div>
    );
  }

  render() {
    if (shouldShowCastPlayer()) {
      let castSession = getCurrentCastMediaStatus();

      if (castSession) {
        return (
          <>
            {this.props.includeInFullscreen ? this.props.includeInFullscreen() : null}
            <div
              className="cast-banner"
              onClick={() => {
                this.onPlayerClick();
              }}
            >
              <div className="cast-banner-content">
                <img alt="Chromecast" src={this.state.castImage} />
                {castSession?.playerState === chrome.cast.media.PlayerState.IDLE ||
                castSession?.playerState === chrome.cast.media.PlayerState.BUFFERING ? (
                  <Spinner wrapInContainer={true} />
                ) : null}
                <h3>
                  <span className="player-control-item cast-button">
                    <google-cast-launcher />
                  </span>
                  {getCastDeviceName()}
                </h3>
              </div>
            </div>
          </>
        );
      }
      return null;
    }

    let containerClass = 'player-fullscreen-wrapper';
    const shouldHideCursor = this.props.hideCursor !== undefined ? this.props.hideCursor : this.props.hideControls;

    if (shouldHideCursor) {
      containerClass += ' no-cursor';
    }

    if (this.isFullscreen()) {
      containerClass += ' fullscreen';
    }

    containerClass += ' ' + this.state.streamType;
    containerClass += this.state.isLive ? ' is-live' : '';
    // containerClass += (shouldNotStretchVideo(this.state.contentId) && this.state.streamType !== StreamType.VOD) ? ' no-stretch' : '';

    // hasEmptyBuffer on the end so we dont do the check before going offline
    let showOfflineMessage = this.state.disconnected && this.hasEmptyBuffer();
    return (
      <div className="player-outer-container">
        <div
          onClick={this.focusVideoContainer}
          ref={(element) => (this.videocontainer = element!)}
          className={containerClass}
        >
          {this.props.includeInFullscreen ? this.props.includeInFullscreen() : null}
          {this.props.popupOverlay ? this.props.popupOverlay : null}
          {this.state.showDebug ? this.streamStatus() : null}
          {this.state.isBuffering ? this.spinnerWhenBuffering() : null}
          <div
            onKeyDown={(e) => this.onKeyPressed(e)}
            tabIndex={0}
            ref={(element) => (this.videoOuterContainer = element!)}
            className="outer-video-container"
          >
            {showOfflineMessage ? this.showDisconnectModal() : null}
            <div className="video-container">
              <video
                poster={this.props.stream?.picture?.poster}
                autoPlay={true}
                className={this.state.videoCssClass + ' player-video'}
                ref={(element) => (this.video = element!)}
                onDoubleClick={this.toggleFullscreen}
                onClick={this.onPlayerClick}
                muted={this.state.startPlayerMuted}
              />
              {this.props.redirectSoon ? this.props.redirectSoon : null}
              <div
                onClick={this.togglePlayPause}
                className={(this.state.showPlayPausePulse ? 'show-pulse' : 'hide-pulse') + ' playPausePulse noselect'}
              >
                <span className="aib-icon">{this.state.playPausePulseIcon}</span>
              </div>

              <div
                id="textTrackContainer"
                className={
                  this.state.videoCssClass +
                  ' textTrackContainer ' +
                  (this.state.selectedTextTrackId === this.NO_SUB ? 'hidden-subs' : '')
                }
                ref={(element) => (this.texttrack = element!)}
              >
                <div className="imageTrackContainerInner" ref={(element) => (this.imageTrackInner = element!)} />
                <div className="textTrackContainerInner" ref={(element) => (this.textTrackInner = element!)} />
              </div>
            </div>
          </div>
          {!this.props.hideControls || this.props.isRecordingModalOpen ? this.controlWrapper() : null}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect((state: RootState) => ({
    isRecordingModalOpen: state.pvrReducer.isRecordingModalOpen,
  }))(Player),
);
