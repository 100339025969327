import * as api from '../../../api/vod';
import {
  VodAsset,
  DispatchType,
  GetStateType,
  VodConfig,
  VodSection,
  VodConfigData,
  VodConfigDataType,
  VodBatchListResponse,
  VodListResponse,
  Genre,
  CategoryId,
  PortalMenu,
  GenreTypes,
  VodAssetSeason,
  Bookmarks,
  VodAutoplay,
  StreamTypeObject,
  TrailerStreamTypeObject,
  VodPlaybackResponse,
  AltiboxAsset,
  Bookmark,
  AlertType,
} from '../../../interfaces';
import {
  isSeries,
  isEpisode,
  appendBookmarksOn,
  getNextEpisode,
  getSeasonIndexFromSeries,
  getEpisodeFromSeries,
  generateVodStreamObject,
  hasParent,
  getEpisodeIndexFromSeries,
  getSeasonNumber,
} from '../../../utils/vodUtils';
import { fakeFavourite } from '../../../utils/favourites';
import {
  relatedSvodKioskIds,
  getVodSeasonsOrEpisodes,
  mergeEpisodesAndSeasonsWithAssets,
  populateSeriesWithEpisodes,
  showAlert,
  loadAllUsersBookmarks,
} from '../../app/actions';
import isEmpty from 'lodash/isEmpty';
import i18n from '../../../i18n';
import { getBaseUrl, getSessionTicket } from '../../../config';
import { getDataFromResponse } from '../../../controllers/Fetch';
import { MyContentResponse } from '../../../api/vod';
import { toMoment } from '../../../utils/huaweiUtils';
import moment from 'moment';
import { bookmarkKeys } from '../../../queries/bookmarks/keys';
import { queryClient } from '../../../queries/client';

export enum TypeKeys {
  SET_SELECTED_VOD_GENRE = 'SET_SELECTED_VOD_GENRE',
  SET_MY_CONTENT = 'SET_MY_CONTENT',
  SET_VOD_CATEGORY_IDS = 'SET_VOD_CATEGORY_IDS',
  SET_MY_SEASONS = 'SET_MY_SEASONS',
  SET_MY_SERIES = 'SET_MY_SERIES',
  SET_VOD_CONFIG = 'SET_VOD_CONFIG',
  SET_CURRENT_VOD = 'SET_CURRENT_VOD',
  SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY',
  CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY',
  SET_FRONT_PAGE_LISTS = 'SET_FRONT_PAGE_LISTS',
  SET_VOD_AUTOPLAY = 'SET_VOD_AUTOPLAY',
  CLEAR_VOD_AUTOPLAY = 'CLEAR_VOD_AUTOPLAY',
  SET_VOD_STREAM_CONFIG = 'SET_VOD_STREAM_CONFIG',
  CLEAR_VOD_STREAM_CONFIG = 'CLEAR_VOD_STREAM_CONFIG',
  TOGGLE_ISFAVOURITE_ON_VODLIST_ASSET = 'TOGGLE_ISFAVOURITE_ON_VODLIST_ASSET',
}

export type ActionTypes =
  | SetSelectedVodGenre
  | SetMyContent
  | SetMySeries
  | SetMySeasons
  | SetCurrentVod
  | SetCurrentCategory
  | ClearCurrentCategory
  | SetVodConfig
  | SetFrontPageLists
  | SetVodCategoryIds
  | SetVodAutoplay
  | ClearVodAutoplay
  | SetVodStreamConfig
  | ClearVodStreamConfig
  | ToggleIsFavouritedOnVodListAsset;

export interface ToggleIsFavouritedOnVodListAsset {
  type: TypeKeys.TOGGLE_ISFAVOURITE_ON_VODLIST_ASSET;
  currentVodCategory: VodListResponse;
  newVodlist: VodAsset[];
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toggleIsFavouritedOnVodListAsset(assetId: string): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    const state = getState().vodReducer;
    const currentVodCategory = state.currentVodCategory;
    const vodlist = currentVodCategory?.vodlist;

    if (!currentVodCategory || !vodlist) {
      return;
    }

    const newVodlist = generateNewVodlistWithFavouriteChange(assetId, vodlist);

    dispatch({
      type: TypeKeys.TOGGLE_ISFAVOURITE_ON_VODLIST_ASSET,
      currentVodCategory,
      newVodlist,
    });
  };
}

export function generateNewVodlistWithFavouriteChange(assetId: string, vodlist: VodAsset[]) {
  return vodlist.map((asset) => {
    if (asset.id === assetId) {
      return { ...asset, isfavorited: Number(asset.isfavorited) ? '0' : '1' };
    }

    return asset;
  });
}

export interface SetSelectedVodGenre {
  type: TypeKeys.SET_SELECTED_VOD_GENRE;
  selectedGenreType: GenreTypes;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setSelectedVodGenre(selectedGenreType: GenreTypes): any {
  return function (dispatch: DispatchType) {
    dispatch({
      type: TypeKeys.SET_SELECTED_VOD_GENRE,
      selectedGenreType,
    });
  };
}

export interface SetVodAutoplay {
  type: TypeKeys.SET_VOD_AUTOPLAY;
  autoplay: VodAutoplay;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setVodAutoplay(autoplay: VodAutoplay): any {
  return function (dispatch: DispatchType) {
    dispatch({
      type: TypeKeys.SET_VOD_AUTOPLAY,
      autoplay,
    });
  };
}

export interface ClearVodAutoplay {
  type: TypeKeys.CLEAR_VOD_AUTOPLAY;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearVodAutoplay(): any {
  return function (dispatch: DispatchType) {
    dispatch({
      type: TypeKeys.CLEAR_VOD_AUTOPLAY,
    });
  };
}

export interface SetFrontPageLists {
  type: TypeKeys.SET_FRONT_PAGE_LISTS;
  frontPageVodLists: VodBatchListResponse[];
}
export function setFrontPageLists(frontPageVodLists: VodBatchListResponse[]): SetFrontPageLists {
  return {
    type: TypeKeys.SET_FRONT_PAGE_LISTS,
    frontPageVodLists,
  };
}

export interface SetVodConfig {
  type: TypeKeys.SET_VOD_CONFIG;
  vodConfig: VodConfig;
}
export function setVodConfig(vodConfig: VodConfig): SetVodConfig {
  return {
    type: TypeKeys.SET_VOD_CONFIG,
    vodConfig,
  };
}

export interface SetVodCategoryIds {
  type: TypeKeys.SET_VOD_CATEGORY_IDS;
  categoryIds: CategoryId[];
}
export function setVodCategoryIds(categoryIds: CategoryId[]): SetVodCategoryIds {
  return {
    type: TypeKeys.SET_VOD_CATEGORY_IDS,
    categoryIds,
  };
}

export interface SetCurrentCategory {
  type: TypeKeys.SET_CURRENT_CATEGORY;
  currentVodCategory: VodListResponse;
  categoryId: string;
}

export function setCurrentCategory(currentVodCategory: VodListResponse, categoryId: string): SetCurrentCategory {
  return {
    type: TypeKeys.SET_CURRENT_CATEGORY,
    currentVodCategory,
    categoryId,
  };
}

export interface ClearCurrentCategory {
  type: TypeKeys.CLEAR_CURRENT_CATEGORY;
}

export function clearCurrentCategory(): ClearCurrentCategory {
  return {
    type: TypeKeys.CLEAR_CURRENT_CATEGORY,
  };
}

export interface SetCurrentVod {
  type: TypeKeys.SET_CURRENT_VOD;
  currentVod: VodAsset | undefined;
}
export function setCurrentVod(currentVod?: VodAsset): SetCurrentVod {
  return {
    type: TypeKeys.SET_CURRENT_VOD,
    currentVod,
  };
}

interface SetMySeasons {
  type: TypeKeys.SET_MY_SEASONS;
  mySeasons: VodAsset[];
}
export function setMySeasons(mySeasons: VodAsset[]): SetMySeasons {
  return {
    type: TypeKeys.SET_MY_SEASONS,
    mySeasons,
  };
}

export interface SetMySeries {
  type: TypeKeys.SET_MY_SERIES;
  mySeries: VodAsset[];
}
export function setMySeries(mySeries: VodAsset[]): SetMySeries {
  return {
    type: TypeKeys.SET_MY_SERIES,
    mySeries,
  };
}

export interface SetMyContent {
  type: TypeKeys.SET_MY_CONTENT;
  myContent: VodAsset[];
}
export function setMyContent(myContent: VodAsset[]): SetMyContent {
  return {
    type: TypeKeys.SET_MY_CONTENT,
    myContent,
  };
}

export interface SetVodStreamConfig {
  type: TypeKeys.SET_VOD_STREAM_CONFIG;
  streamConfig: StreamTypeObject | TrailerStreamTypeObject | undefined;
}

export function setVodStreamConfig(streamConfig: StreamTypeObject | TrailerStreamTypeObject): SetVodStreamConfig {
  return {
    type: TypeKeys.SET_VOD_STREAM_CONFIG,
    streamConfig,
  };
}

export interface ClearVodStreamConfig {
  type: TypeKeys.CLEAR_VOD_STREAM_CONFIG;
}

export function clearVodStreamConfig(): ClearVodStreamConfig {
  return {
    type: TypeKeys.CLEAR_VOD_STREAM_CONFIG,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateVodWithFavourite(vodId: string, favouriteValue: string): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    const state = getState().vodReducer;
    const vodState = state.currentVodCategory;
    if (vodState) {
      const vodCategoryId = state.currentVodCategoryId!;
      const fakeVodList = fakeFavourite(vodState, vodId, favouriteValue);
      dispatch(clearCategory());
      dispatch(setCurrentCategory(fakeVodList, vodCategoryId));
    } else if (state.frontPageVodLists) {
      const fakeFrontPageList = state.frontPageVodLists;
      fakeFrontPageList.forEach((list) => {
        return fakeFavourite(list.msg, vodId, favouriteValue);
      });
      dispatch(setFrontPageLists(fakeFrontPageList));
    }
  };
}

export function getUniqueFatherIds(content: VodAsset[]) {
  let foundIds: string[] = [];
  content.forEach(function (vod: VodAsset) {
    if (vod.fathervodlist && vod.fathervodlist[0] && vod.fathervodlist[0].vodid) {
      if (foundIds.indexOf(vod.fathervodlist[0].vodid) === -1) {
        foundIds.push(vod.fathervodlist[0].vodid);
      }
    }
  });
  return foundIds;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadVodConfig(areaId: string, bossId: string): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    return api
      .getVodConfig(areaId, bossId)
      .then((config) => {
        dispatch(setVodConfig(config as VodConfig));
      })
      .catch(() => {
        api.getVodConfig(areaId, bossId, true).then((config) => {
          dispatch(setVodConfig(config as VodConfig));
        });
      });
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearCategory(): any {
  return function (dispatch: DispatchType) {
    dispatch(clearCurrentCategory());
  };
}

export function loadVodCategory(
  categoryId: string,
  orderType: string,
  genres: string,
  availableGenres: Genre[],
  count: number,
  offset: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return function (dispatch: DispatchType) {
    return api.getVodCategory(categoryId, orderType, genres, availableGenres, count, offset).then((vodList) => {
      dispatch(setCurrentCategory(vodList as VodListResponse, categoryId + '/' + orderType + '/' + genres));
    });
  };
}
export function loadCategory(category: VodSection, genres: Genre[], count: number, offset: number) {
  return function (dispatch: DispatchType) {
    return api.getVodList(category, genres, count, offset).then((vodList) => {
      dispatch(setCurrentCategory(vodList as VodListResponse, category.customCategoryId));
    });
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadPromoCategory(categoryId: string): any {
  return function (dispatch: DispatchType) {
    return api.getPromoVodList(categoryId).then((vodList) => {
      dispatch(setCurrentCategory(vodList as VodListResponse, categoryId));
    });
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchFrontPageVodLists(vodConfigData: VodConfigData[], genre: Genre[], count: number): any {
  return function (dispatch: DispatchType) {
    let categoriesToFetch: VodSection[] = [];
    vodConfigData.forEach((data: VodConfigData) => {
      if (data.sections) {
        if (data.type === VodConfigDataType.GRID_WITH_BANNERS) {
          data.sections.forEach((section: VodSection) => {
            if (section.categoryId) {
              categoriesToFetch.push(section);
            }
          });
        }
      }
    });
    api.getBatchOfVodLists(categoriesToFetch, genre, count).then((result) => {
      dispatch(setFrontPageLists(result as VodBatchListResponse[]));
    });
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchVod(vodId: string, svodKiosk?: PortalMenu): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    return api
      .getContentDetailSingle(vodId)
      .then((vodAsset: VodAsset) => addMissingInfoBasedOnType(vodAsset, !getState().authReducer.isGuest, svodKiosk))
      .then((vodAsset) => {
        dispatch(setCurrentVod(vodAsset));
        return vodAsset;
      });
  };
}

function addMissingInfoBasedOnType(vodAsset: VodAsset, loadBookmarks: boolean, svodKiosk?: PortalMenu) {
  if (loadBookmarks) {
    return api.getVODBookmarks().then((bookmarks) => {
      return loadCurrentVodAsset(vodAsset, svodKiosk).then((loadedVodAsset: VodAsset) => {
        return appendBookmarksOn(loadedVodAsset, bookmarks as Bookmarks);
      });
    });
  } else {
    return loadCurrentVodAsset(vodAsset, undefined, false);
  }
}

function loadCurrentVodAsset(vodAsset: VodAsset, svodKiosk?: PortalMenu, loadBookmarks?: boolean) {
  if (isEpisode(vodAsset)) {
    return api.getSeriesByEpisode(vodAsset).then((theAsset: VodAsset) => {
      return loadAllSeasonsAndEpisodes(theAsset, svodKiosk, loadBookmarks);
    });
  } else if (isSeries(vodAsset)) {
    return loadAllSeasonsAndEpisodes(vodAsset, svodKiosk, loadBookmarks);
  } else {
    return Promise.resolve(vodAsset);
  }
}

export async function loadAllSeasonsAndEpisodes(vodAsset: VodAsset, svodKiosk?: PortalMenu, loadBookmarks?: boolean) {
  let myContent: Response | undefined = undefined;
  if (loadBookmarks) {
    myContent = await fetch(getBaseUrl() + '/EPG/JSON/QueryMyContent', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        contenttype: 'VOD',
        count: -1,
        offset: 0,
        order: '4',
        productType: 1,
      }),
    });
  }

  const { contentlist } = myContent
    ? await getDataFromResponse<MyContentResponse>(myContent)
    : { contentlist: undefined };

  return api
    .getSeasons(vodAsset.id, svodKiosk)
    .then((seasons) => {
      seasons = seasons as VodAssetSeason[];
      vodAsset.seasons = seasons;
      const seasonIds = seasons.map((season) => season.id);
      return seasonIds;
    })
    .then((seasonIds: string[]) => {
      return api.getEpisodes(seasonIds, svodKiosk);
    })
    .then((episodes) => {
      vodAsset.seasons = vodAsset.seasons!.map((season: VodAsset) => {
        const episodesForThisSeason = (episodes as VodAsset[]).filter((episode) => {
          if (!episode) {
            return false;
          }

          let episodeBelongsToSeason = episode.fathervodlist[0].vodid === season.id;
          if (episodeBelongsToSeason) {
            episode.seasonNumber = season.fathervodlist[0].sitcomnum;
          }

          return episodeBelongsToSeason;
        });

        const episodesWithAdjustedRentperiod = episodesForThisSeason.map((episode) => {
          const serendtime = contentlist?.find((c) => c.id === episode.id)?.serendtime;
          return {
            ...episode,
            rentperiod: serendtime ? String(toMoment(serendtime).diff(moment.now(), 'seconds')) : episode.rentperiod,
          };
        });

        return {
          ...season,
          episodes: episodesWithAdjustedRentperiod,
        };
      });
      // remove empty seasons
      vodAsset.seasons = vodAsset.seasons.filter((obj) => obj.episodes.length !== 0);
      return vodAsset;
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchMyContent(count: number): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    if (getState().authReducer.isGuest) {
      return Promise.resolve([]);
    }
    return api.getMyContent(count).then((myContent: VodAsset[]) => {
      if (myContent) {
        const foundSeasonIds = getUniqueFatherIds(myContent);
        return api.getContentDetail(foundSeasonIds.join(',')).then((seasons: VodAsset[]) => {
          if (seasons) {
            const foundSereiesIds = getUniqueFatherIds(seasons);
            return api.getContentDetail(foundSereiesIds.join(',')).then((series: VodAsset[]) => {
              dispatch(setMySeries(series));
              dispatch(setMySeasons(seasons));
              dispatch(setMyContent(myContent));
              return myContent;
            });
          } else {
            return dispatch(setMyContent(myContent));
          }
        });
      } else {
        return Promise.resolve(myContent);
      }
    });
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function generateVodAutoplayList(id: string): any {
  return async function (dispatch: DispatchType, getState: GetStateType) {
    let vodItems = await api.getContentDetail(id);
    if (!vodItems) {
      return undefined;
    }
    const svodKiosks = getState().app.svodKiosks;
    let svodKioskIds: PortalMenu[] = relatedSvodKioskIds(await vodItems, svodKiosks);

    let vodsThatAreSeries: VodAsset[] = [...vodItems].filter((asset) => asset.fathervodlist);
    if (vodsThatAreSeries.length > 0) {
      // get all relations to series
      let vodFatherIds = vodsThatAreSeries.map((x) => x.fathervodlist && x.fathervodlist[0].vodid).join(',');
      let vodFathers = (await api.getContentDetail(vodFatherIds)) as VodAssetSeason[];
      let vodGrandFathers = await api.getContentDetail(
        vodFathers.map((x) => x.fathervodlist && x.fathervodlist[0].vodid).join(','),
      );

      // get all episodes for the father asset
      let fatherEpisodes: VodBatchListResponse[] = await getVodSeasonsOrEpisodes(vodFathers, svodKioskIds);
      vodFathers = (await mergeEpisodesAndSeasonsWithAssets(vodFathers, fatherEpisodes, true)) as VodAssetSeason[];

      // get all seasons for the grandfather asset
      let grandFatherSeasons: VodBatchListResponse[] = await getVodSeasonsOrEpisodes(vodGrandFathers, svodKioskIds);

      vodGrandFathers = await mergeEpisodesAndSeasonsWithAssets(vodGrandFathers, grandFatherSeasons);
      // set seasons on episodes
      vodsThatAreSeries = await mergeEpisodesAndSeasonsWithAssets(vodsThatAreSeries, grandFatherSeasons);

      // populate each season within with episodes
      let populatedSeries = await populateSeriesWithEpisodes(vodsThatAreSeries, svodKiosks);

      Promise.all(populatedSeries).then((output) => {
        output.forEach((vod: VodAsset, idx: number) => (vod.fatherVod = vodFathers[idx]));
        vodFathers.forEach((vod, idx) => (vod.fatherVod = vodGrandFathers[idx]));
        let seriesAsset = output[0] as VodAsset;
        const seasonIndex = getSeasonIndexFromSeries(id, seriesAsset)!;
        const episodeIndex = getEpisodeIndexFromSeries(id, seriesAsset)!;
        if (svodKioskIds && !isEmpty(svodKioskIds) && !isEmpty(Object.keys(svodKioskIds[0]))) {
          seriesAsset.isSvod = true;
        }
        const nextEpisode = getNextEpisode(seasonIndex, episodeIndex, seriesAsset.seasons!)!;
        if (nextEpisode) {
          let nextSeasonIndex = getSeasonIndexFromSeries(nextEpisode!.id, seriesAsset)!;
          let nextEpisodeIndex = getEpisodeIndexFromSeries(nextEpisode!.id, seriesAsset)!;
          const currentVod = getState().vodReducer.currentVod;
          const doNotSetNextAutoplay =
            seasonIndex !== nextSeasonIndex || Number(nextEpisode.sitcomnum!) !== Number(currentVod!.sitcomnum) + 1;
          if (doNotSetNextAutoplay) {
            dispatch(clearVodAutoplay());
          } else {
            const autoplay: VodAutoplay = {
              seriesAsset: seriesAsset,
              nextAsset: nextEpisode,
              seasonIndex: nextSeasonIndex,
              episodeIndex: nextEpisodeIndex,
              iteration: 1,
            };
            dispatch(setVodAutoplay({ ...autoplay }));
          }
        } else {
          dispatch(clearVodAutoplay());
        }
      });
    }
    return undefined;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function autoplayNextEpisode(): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    let currentVod = Object.assign({}, getState().vodReducer.currentVod);
    dispatch(clearVodStreamConfig());
    dispatch(setCurrentVod(undefined));
    let { autoplay } = getState().vodReducer;
    let { nextAsset, seriesAsset, seasonIndex, episodeIndex, iteration } = autoplay!;
    api
      .getContentDetail(nextAsset!.id)
      .then((updatedAsset) => {
        let newAsset: VodAsset | undefined = updatedAsset[0];
        let nextEpisode = getNextEpisode(seasonIndex, episodeIndex, seriesAsset.seasons!)!;
        let nextSeasonIndex = -1;
        let nextEpisodeIndex = -1;

        if (nextEpisode) {
          nextSeasonIndex = getSeasonIndexFromSeries(nextEpisode.id, seriesAsset)!;
          nextEpisodeIndex = getEpisodeIndexFromSeries(nextEpisode.id, seriesAsset)!;
          let doNotSetNextAutoplay = false;
          if (seasonIndex !== nextSeasonIndex) {
            if (Number(nextEpisode.sitcomnum!) !== 1) {
              doNotSetNextAutoplay = true;
            }
          } else {
            if (Number(newAsset.sitcomnum!) - Number(currentVod.sitcomnum!) >= 2) {
              doNotSetNextAutoplay = true;
            }
          }
          if (doNotSetNextAutoplay) {
            dispatch(clearVodAutoplay());
            newAsset = undefined;
          } else {
            let nextAutoplay: VodAutoplay = {
              seriesAsset: seriesAsset,
              nextAsset: nextEpisode,
              seasonIndex: nextSeasonIndex,
              episodeIndex: nextEpisodeIndex,
              iteration: iteration++,
            };
            dispatch(setVodAutoplay({ ...nextAutoplay }));
          }
        } else {
          dispatch(clearVodAutoplay());
        }
        if (newAsset) {
          const seasonNumber = getSeasonNumber(seasonIndex, seriesAsset.seasons!);
          dispatch(setCurrentVod({ ...newAsset, seasonNumber }));
          api
            .getAssetStreamInfo(newAsset)
            .then((streamData) => {
              if (streamData) {
                streamData = streamData as StreamTypeObject;
                const streamObject = generateVodStreamObject(newAsset!, streamData);
                dispatch(setVodStreamConfig({ ...streamObject }));
                setTimeout(() => {
                  dispatch(setVodStreamConfig({ ...streamObject }));
                }, 800);
              }
            })
            .catch((error: VodPlaybackResponse) => {
              dispatch(setStreamError(error));
            });
        }
      })
      .catch(() => {
        dispatch(assetNotFound());
      });
  };
}

// TODO: Take a details panel structure instead of fetching it
export function useExistingAutoplayList(altiboxAsset: AltiboxAsset) {
  return 'hei';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadPlayAsset(externalContentCode: string, generateAutoplay: boolean): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    const isGuest = getState().authReducer.isGuest;
    const loggedInWithCredentials = getState().authReducer.loggedInWithCredentials;
    const myContent = getState().vodReducer.myContent;

    const userBookmarks = loggedInWithCredentials ? getState().app.bookmarks : [];
    const queryBookmarks = queryClient.getQueryData<Bookmark[]>(bookmarkKeys.all) || [];

    if (isGuest) {
      dispatch(loginToSeeContent());
      return;
    }

    let vod: VodAsset | undefined = getState().vodReducer.currentVod;
    const svodKiosk = getState().svodReducer.svodKiosk;
    if (!vod || vod.id !== externalContentCode) {
      api
        .getContentIdByExternalContentCode(externalContentCode)
        .then((vodIdToPlay: string) => {
          api
            .getContentDetailSingle(vodIdToPlay)
            .then((vodAsset: VodAsset) => addMissingInfoBasedOnType(vodAsset, isGuest, svodKiosk))
            .then((vodAsset) => {
              if (!loggedInWithCredentials) {
                return vodAsset;
              }

              const userBookmark = userBookmarks.find((bookmark) => bookmark.contentId === vodAsset.id);
              const queryBookmark = queryBookmarks.find((bookmark) => bookmark.contentId === vodAsset.id);

              const bookmark =
                Number(userBookmark?.updateTime) > Number(queryBookmark?.updateTime) ? userBookmark : queryBookmark;

              if (bookmark === undefined) {
                return dispatch(loadAllUsersBookmarks()).then((allBookmarks: Bookmark[]) => {
                  vodAsset = appendBookmarksOn(vodAsset, { bookmarkList: allBookmarks });
                  return vodAsset;
                });
              }

              vodAsset = appendBookmarksOn(vodAsset, { bookmarkList: [bookmark, ...queryBookmarks, ...userBookmarks] });
              return vodAsset;
            })
            .then((vodAsset) => {
              const myContentAsset = myContent?.find((c) => c.id === vodAsset.id);

              if (myContentAsset) {
                vodAsset.rentperiod = myContentAsset.rentperiod;
              }

              return vodAsset;
            })
            .then((vodAsset) => {
              if (isSeries(vodAsset)) {
                let foundEpisode = getEpisodeFromSeries(vodIdToPlay, vodAsset);
                vodAsset = foundEpisode ? foundEpisode : vodAsset;
              }
              dispatch(setCurrentVod(vodAsset));
              return vodAsset;
            })
            .then((vodAsset: VodAsset) => {
              if (hasParent(vodAsset) && generateAutoplay) {
                dispatch(generateVodAutoplayList(vodAsset.id));
              }
              api
                .getAssetStreamInfo(vodAsset)
                .then((streamData) => {
                  if (streamData) {
                    streamData = streamData as StreamTypeObject;
                    const streamObject = generateVodStreamObject(vodAsset!, streamData);
                    dispatch(setVodStreamConfig({ ...streamObject }));
                    setTimeout(() => {
                      dispatch(setVodStreamConfig({ ...streamObject }));
                    }, 1);
                  }
                })
                .catch((error: VodPlaybackResponse) => {
                  dispatch(setStreamError(error));
                });
            });
        })
        .catch(() => {
          dispatch(assetNotFound());
        });
    } else {
      api
        .getAssetStreamInfo(vod!)
        .then((streamData) => {
          if (streamData) {
            streamData = streamData as StreamTypeObject;
            const streamObject = generateVodStreamObject(vod!, streamData);
            dispatch(setVodStreamConfig({ ...streamObject }));
            setTimeout(() => {
              dispatch(setVodStreamConfig({ ...streamObject }));
            }, 1);
          }
        })
        .catch((error: VodPlaybackResponse) => {
          dispatch(setStreamError(error));
        });
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadTrailerAsset(externalContentCode: string): any {
  return function (dispatch: DispatchType) {
    api
      .getContentIdByExternalContentCode(externalContentCode)
      .then((vodIdToPlay: string) => {
        api.getContentDetailSingle(vodIdToPlay).then((asset: VodAsset) => {
          api.getTrailerData(asset).then((trailerData) => {
            dispatch(setCurrentVod(asset));
            dispatch(setVodStreamConfig({ ...(trailerData as TrailerStreamTypeObject) }));
            setTimeout(() => {
              dispatch(setVodStreamConfig({ ...(trailerData as TrailerStreamTypeObject) }));
            }, 1);
          });
        });
      })
      .catch(() => {
        dispatch(assetNotFound());
      });
  };
}

function customAlertMessage(title: string, body?: string) {
  return function (dispatch: DispatchType) {
    dispatch(
      showAlert({
        title: title,
        text: [body ? body : ''],
        type: AlertType.INFO,
      }),
    );
  };
}

function assetNotFound() {
  return function (dispatch: DispatchType) {
    dispatch(
      showAlert({
        title: i18n.t<string>('asset not found'),
        text: [''],
        type: AlertType.INFO,
      }),
    );
  };
}

function loginToSeeContent() {
  return function (dispatch: DispatchType) {
    dispatch(
      showAlert({
        title: i18n.t<string>('log on'),
        text: [i18n.t<string>('log in to see this content')],
        type: AlertType.INFO,
      }),
    );
  };
}

function setStreamError(error: VodPlaybackResponse) {
  return function (dispatch: DispatchType) {
    if (error.retcode === '85983417') {
      dispatch(customAlertMessage(i18n.t<string>('an_error_occured'), i18n.t<string>('concurrency message1')));
    } else if (error.retcode === '85983372') {
      dispatch(customAlertMessage(i18n.t<string>('an_error_occured'), i18n.t<string>('geolocked_playback')));
    } else {
      dispatch(assetNotFound());
    }
  };
}
