import * as auth from '../../../api/auth';
import { queryAllOrders } from '../../../api/user';
import HuaweiErrors from '../../../controllers/HuaweiErrors';

import i18n from '../../../i18n';
import {
  DispatchType,
  Product,
  ApiAuthResponse,
  AlertData,
  Device,
  PortalMenu,
  PortalMenuType,
  FrontPageConfig,
  CategoryListResponse,
  CategoryIds,
  CategoryList,
  VodListResponse,
  BatchBookmarksResponse,
  Bookmark,
  PvrRecording,
  AltiboxAsset,
  VodAsset,
  Program,
  Channel,
  GetStateType,
  StreamType,
  VodAssetSeason,
  QueryOrderResponse,
  FavoCatalogResponse,
  StreamTypeNumber,
  ContinueWatchingAsset,
  BatchVodListResponse,
  VodBatchListResponse,
  AltiboxAssetDetailsType,
  DetailsMeta,
  GAaction,
  AccountWithExtraData,
  ExtensionInfoResponse,
} from '../../../interfaces';
import {
  loadPortalMenuFromBackend,
  loadFrontPageConfigFromBackend,
  loadAllBookmarks,
  loadSvodConfigFromBackend,
  getContinueWatchingPadding,
} from '../../../api/app';
import { loadSvodKiosksBaseCategoryId, loadSvodCategoryIds } from '../../../api/svod';
import { getRecordingsForBookmarks } from '../../../api/recording';
import {
  getVodContentDetailFromBookmarks,
  batchGetSeasonsOrEpisodes,
  getContentDetail,
  loadAllVodCategoryIds,
} from '../../../api/vod';
import { getCatchupDetailsFromBookmarks } from '../../../api/tv';
import {
  routes,
  huaweiDateTimeFormat,
  nielsenChannelMapping,
  NielsenChannels,
  setSessionTicket,
} from '../../../config';
import { getSvodUrl, getSvodPicture, getSvodKiosk } from '../../../utils/svodUtil';
import { getPercentWatched } from '../../../utils/pvrUtils';
import {
  getPercentWatched as getVodPercentWatched,
  getEpisodeToPromoteBasedOnSeasons,
  isEpisode,
  doubleDigits,
  getGenre,
  assetIsOwned,
} from '../../../utils/vodUtils';
import { hasPicture, checkIfUserHasSvodProduct } from '../../../utils/altiboxassetUtils';
import { getPercentWatchedCatchup } from '../../../utils/catchupUtils';
import { appendBookmarksOn } from '../../../utils/vodUtils';
import { getFavoIds } from '../../../api/favorites';
import { identifySvodIdentifierBasedOnFavoIdName } from '../../../utils/favourites';
import { getCatchupAssetWithLock } from '../../../utils/programArchiveUtils';
import moment from 'moment';
import flatten from 'lodash/flatten';
import { memoizeAssets, goToPrevPath, isGuestId } from '../../../utils/appUtils';
import { toMomentReadableDateString } from '../../../utils/huaweiUtils';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';

export const ADD_AUTH_STATUS = Symbol('ADD_AUTH_STATUS');
export const LOGOUT = Symbol('LOGOUT');
export const SHOW_LOGIN_MODAL = Symbol('SHOW_LOGIN_MODAL');
export const HIDE_LOGIN_MODAL = Symbol('HIDE_LOGIN_MODAL');
export const SET_LOGIN_ERROR = Symbol('SET_LOGIN_ERROR');
export const SET_SHOULD_FADE = Symbol('SET_SHOULD_FADE');
export const SHOW_DEVICE_SWITCH_MODAL = Symbol('SHOW_DEVICE_SWITCH');
export const HIDE_DEVICE_SWITCH_MODAL = Symbol('HIDE_DEVICE_SWITCH_MODAL');
export const SHOW_LOCATION_SWITCH_MODAL = Symbol('SHOW_LOCATION_SWITCH');
export const HIDE_LOCATION_SWITCH_MODAL = Symbol('HIDE_LOCATION_SWITCH_MODAL');

export const SET_ORIGIN_LOCATION = Symbol('SET_ORIGIN_LOCATION');
export const SET_PVR_STATUS = Symbol('SET_PVR_STATUS');
export const SET_SVOD_KIOSKS = Symbol('SET_SVOD_KIOSKS');
export const GET_SVOD_KIOSKS = Symbol('GET_SVOD_KIOSKS');
export const SET_PORTAL_MENU = Symbol('SET_PORTAL_MENU');
export const SHOW_ALERT_MODAL = Symbol('SHOW_ALERT_MODAL');
export const HIDE_ALERT_MODAL = Symbol('HIDE_ALERT_MODAL');
export const SET_PRODUCT_LIST = Symbol('SET_PRODUCT_LIST');
export const SET_ALL_BOOKMARKS = Symbol('SET_ALL_BOOKMARKS');
export const SET_FRONTPAGE_CONFIG = Symbol('SET_FRONTPAGE_CONFIG');
export const SET_CONTINUE_WATCHING_ASSETS = Symbol('SET_CONTINUE_WATCHING_ASSETS');
export const IS_LOADING_CONTINUE_WATCHING_ASSETS = Symbol('IS_LOADING_CONTINUE_WATCHING_ASSETS');
export const SET_DEFAULT_IMAGE_PATH = Symbol('SET_DEFAULT_IMAGE_PATH');
export const SET_NIELSEN_CHANNELS = Symbol('SET_NIELSEN_CHANNELS');

export const addAuthStatus = (authResponse: ApiAuthResponse) => ({
  type: ADD_AUTH_STATUS,
  auth: authResponse,
});

export function setPvrStatus(status: boolean) {
  return {
    type: SET_PVR_STATUS,
    status: status,
  };
}

export function setProductList(productList: Product[]) {
  return {
    type: SET_PRODUCT_LIST,
    productList: productList,
  };
}

export function showAlert(alert: AlertData) {
  return {
    type: SHOW_ALERT_MODAL,
    alertVisible: true,
    alert,
  };
}

export function hideAlert() {
  return {
    type: HIDE_ALERT_MODAL,
    alertVisible: false,
  };
}

export function showLoginModal() {
  return {
    type: SHOW_LOGIN_MODAL,
  };
}

export function showDeviceSwitchModal(devices: auth.DeviceData) {
  let devicesForSwitch = devices.deviceList;
  let deviceReplaceTimes = devices.replaceTimes;
  return {
    type: SHOW_DEVICE_SWITCH_MODAL,
    devicesForSwitch,
    deviceReplaceTimes,
  };
}

export function showLocationSelectModal(accounts: AccountWithExtraData[]) {
  return {
    type: SHOW_LOCATION_SWITCH_MODAL,
    accounts,
  };
}

export function hideLocationSelectModal() {
  return {
    type: HIDE_LOCATION_SWITCH_MODAL,
  };
}

export function hideDeviceSwitchModal() {
  return {
    type: HIDE_DEVICE_SWITCH_MODAL,
  };
}

export function getDevicesForSwitch() {
  return function (dispatch: DispatchType) {
    return auth.getDeviceData().then((devices) => {
      return dispatch(showDeviceSwitchModal(devices as auth.DeviceData));
    });
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function doDeviceSwitch(deviceToRemove: Device): any {
  trackLogin('Device swapped');

  return function (dispatch: DispatchType) {
    dispatch(hideDeviceSwitchModal());
    auth.swapDevice(deviceToRemove);
  };
}

export function hideLoginModal() {
  return {
    type: HIDE_LOGIN_MODAL,
  };
}

export function setDefaultImagePath(path: string) {
  return {
    type: SET_DEFAULT_IMAGE_PATH,
    defaultImagePath: path,
  };
}

export function setLoginError(error: string) {
  return {
    type: SET_LOGIN_ERROR,
    error: error,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setProductListAndPvrStatus(): any {
  return function (dispatch: DispatchType) {
    queryAllOrders().then((result) => {
      result = result as QueryOrderResponse;
      dispatch(setProductList(result.productlist));
      dispatch(setPvrStatus(checkIfUserHasPvr(result.productlist)));
    });
  };
}

function checkIfUserHasPvr(productlist: Product[]) {
  if (!productlist) {
    return false;
  }
  return (
    productlist.find((product) => {
      return (product.hasOwnProperty('name') && product.name.indexOf('PVR') > -1) ||
        (product.hasOwnProperty('id') && product.id.indexOf('PVR') > -1)
        ? true
        : false;
    }) !== undefined
  );
}

export function handleLoginResponse(dispatch: DispatchType, result: ApiAuthResponse) {
  const { retcode, success, accounts } = result;
  if (retcode === '0') {
    trackLogin('success');
    trackLogin('User logged in with u/p');
    goToPrevPath();
  } else if (retcode === HuaweiErrors.INCORRECT_PASSWORD_OR_INVALID_USER) {
    trackLogin('Could not login on location');
    trackLogin('fail');
    dispatch(setLoginError(i18n.t<string>('could not login on location')));
  } else if (retcode === 'wrong_platform') {
    AnalyticsTracking.getInstance().trackCurrentService(GAaction.login, 'Wrong platform');
    dispatch(setLoginError(i18n.t<string>('wrong platform')));
  } else if (success === false) {
    trackLogin('Wrong username or password');
    trackLogin('fail');
    dispatch(setLoginError(i18n.t<string>('wrong user or password combination')));
  } else if (success === true && accounts) {
    dispatch(showLocationSelectModal(accounts));
  } else if (retcode === 'wrong_platform') {
    trackLogin('Wrong platform');
    trackLogin('fail');
    dispatch(setLoginError(i18n.t<string>('wrong platform')));
  } else if (retcode === HuaweiErrors.NO_FREE_EST_SLOT) {
    trackLogin('Full EST slots: ' + retcode);
    if (result.jSessionID) {
      setSessionTicket(result.jSessionID, result.cSessionID);
    }
    handleFullEstSlots(dispatch);
  } else if (retcode === HuaweiErrors.NO_FREE_NON_EST_SLOT || retcode === HuaweiErrors.NON_EST_DEVICE_IS_BLANK) {
    trackLogin('Full nonEST slots: ' + retcode);
    dispatch(setLoginError(i18n.t<string>('full nonest slots')));
    if (result.jSessionID) {
      setSessionTicket(result.jSessionID, result.cSessionID);
    }
    handleFullEstSlots(dispatch);
  } else if (retcode === 'CUSTOMER_ENTERPRISE') {
    trackLogin('Enterprise customer trying to login: ' + retcode);
    trackLogin('fail');
    dispatch(setLoginError(i18n.t<string>('enterprise customer login fail')));
  } else {
    trackLogin('General error: ' + retcode);
    trackLogin('fail');
    dispatch(setLoginError(i18n.t<string>('unknown error') + ' (' + retcode + ')'));
  }
}

function trackLogin(label: string) {
  AnalyticsTracking.getInstance().trackEvent(
    AnalyticsTracking.getInstance().getCurrentCategory(),
    GAaction.login,
    label,
  );
}

function handleFullEstSlots(dispatch: DispatchType) {
  trackLogin('success');
  trackLogin('Too many devices');
  dispatch(hideLoginModal());
  dispatch(getDevicesForSwitch());
}

export interface SetShouldFadeAction {
  type: Symbol;
  shouldFadeOut: boolean;
}

export function setShouldFade(shouldFadeOut: boolean): SetShouldFadeAction {
  return {
    type: SET_SHOULD_FADE,
    shouldFadeOut,
  };
}

export function setPortalMenu(portalMenu: PortalMenu[]) {
  return {
    type: SET_PORTAL_MENU,
    portalMenu,
  };
}
export function setSvodKiosks(svodKiosks: PortalMenu[]) {
  return {
    type: SET_SVOD_KIOSKS,
    svodKiosks,
  };
}

// filter out all svod kiosks
function getSvodKiosks(portalMenu: PortalMenu[]) {
  let svods: PortalMenu[] = [];
  portalMenu.forEach((element: PortalMenu) => {
    if (element.type === PortalMenuType.SVOD) {
      svods.push(element);
    } else if (element.promotions && element.promotions.kiosks) {
      element.promotions.kiosks.forEach((kiosk: PortalMenu) => {
        svods.push(kiosk);
      });
    }
  });
  return svods;
}

function loadSvodKioskIntoReducer(svods: PortalMenu[], dispatch: DispatchType) {
  return loadSvodKiosksBaseCategoryId().then((categoryList: CategoryListResponse) => {
    svods.forEach((kiosk: PortalMenu) => {
      appendAllActiveContentCategoryId(kiosk, svods, categoryList).then((svodsWithBaseCategory) => {
        dispatch(setSvodKiosks(svodsWithBaseCategory));
      });
    });
  });
}

export function appendAllFavoIdsToSvodKiosk(svods: PortalMenu[], favoIds: FavoCatalogResponse): PortalMenu[] {
  if (favoIds.favolist) {
    favoIds.favolist.sort((a, b) => {
      return Number(a.id) - Number(b.id);
    });
    svods.forEach((svod) => {
      favoIds.favolist!.forEach((favoId) => {
        if (!svod.favoId) {
          let tempName = identifySvodIdentifierBasedOnFavoIdName(favoId.name);
          if (tempName === svod.identifier) {
            svod.favoId = favoId.id;
          }
        }
      });
    });
  }
  return svods;
}

function appendAllActiveContentCategoryId(kiosk: PortalMenu, svods: PortalMenu[], categoryList: CategoryListResponse) {
  return loadSvodCategoryIds(categoryList, kiosk).then((finalCategoryList: CategoryListResponse) => {
    if (finalCategoryList.categorylist) {
      finalCategoryList.categorylist.forEach((item: CategoryList) => {
        if (item.name === CategoryIds.VOD) {
          kiosk.svodAllActiveContentId = item!.id;
        }
        kiosk.allActiveCategoryIds = finalCategoryList.categorylist.map((x) => x.id).concat(kiosk.allActiveCategoryIds);
      });
    }
    return svods;
  });
}

export function loadSvodNews(svodKiosks: PortalMenu[]) {
  return function (dispatch: DispatchType) {
    const ids = svodKiosks.map((kiosk) => kiosk.svodAllActiveContentId);
    loadAllVodCategoryIds(ids, '0', 18).then((listResponse: VodListResponse[]) => {
      listResponse.forEach((vodlist: VodListResponse, index: number) => {
        svodKiosks[index].newsAssets = (vodlist as VodListResponse).vodlist;
        dispatch(setSvodKiosks(svodKiosks));
      });
    });
  };
}

export function setFrontPageConfig(frontPageConfig: FrontPageConfig) {
  return {
    type: SET_FRONTPAGE_CONFIG,
    frontPageConfig,
  };
}

function loadPortalMenu(areaId: string, bossId: string, userId: string, loggedInWithCredentials: boolean) {
  return function (dispatch: DispatchType) {
    let svods = [] as PortalMenu[];
    return loadPortalMenuFromBackend(bossId, areaId)
      .then((result) => {
        loadSvodConfigFromBackend(bossId, areaId)
          .then((svodResult) => {
            svods = getSvodKiosks(svodResult as PortalMenu[]);
          })
          .then(() => {
            dispatch(setPortalMenu(result as PortalMenu[]));

            if (!isGuestId(userId) && loggedInWithCredentials) {
              return getFavoIds()
                .then((favoIds: FavoCatalogResponse) => {
                  svods = appendAllFavoIdsToSvodKiosk(svods, favoIds);
                  loadSvodKioskIntoReducer(svods, dispatch);
                })
                .catch(() => loadSvodKioskIntoReducer(svods, dispatch));
            } else {
              return loadSvodKioskIntoReducer(svods, dispatch);
            }
          });
      })
      .catch(() => {
        return loadPortalMenuFromBackend(bossId, areaId, true).then((result) => {
          loadSvodConfigFromBackend(bossId, areaId).then((svodResult) => {
            svods = getSvodKiosks(svodResult as PortalMenu[]);
            dispatch(setPortalMenu(result as PortalMenu[]));
            if (!isGuestId(userId) && loggedInWithCredentials) {
              getFavoIds()
                .then((favoIds: FavoCatalogResponse) => {
                  svods = appendAllFavoIdsToSvodKiosk(svods, favoIds);
                  loadSvodKioskIntoReducer(svods, dispatch);
                })
                .catch(() => loadSvodKioskIntoReducer(svods, dispatch));
            } else {
              loadSvodKioskIntoReducer(svods, dispatch);
            }
          });
        });
      });
  };
}

export function loadPortalMenuAndFrontPageConfig(
  areaId: string,
  bossId: string,
  userId: string,
  loggedInWithCredentials: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return function (dispatch: DispatchType) {
    dispatch(loadPortalMenu(areaId, bossId, userId, loggedInWithCredentials)).then(() => {
      loadFrontPageConfigFromBackend(bossId, areaId).then((result: FrontPageConfig) => {
        dispatch(setFrontPageConfig(result));
      });
    });
  };
}

export function setAllBookmarks(bookmarks: Bookmark[]) {
  return {
    type: SET_ALL_BOOKMARKS,
    bookmarks: bookmarks,
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadAllUsersBookmarks(): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    return loadAllBookmarks().then((bookmarks: BatchBookmarksResponse) => {
      let tempBookmarks: Bookmark[] = [];
      bookmarks.responseList.forEach((bookmarkMsg) => {
        if (bookmarkMsg.msg.bookmarkList) {
          tempBookmarks = tempBookmarks.concat(bookmarkMsg.msg.bookmarkList as Bookmark[]);
        }
      });

      tempBookmarks.sort((bookmarkA: Bookmark, bookmarkB: Bookmark) => {
        return parseInt(bookmarkB.updateTime, 10) - parseInt(bookmarkA.updateTime, 10);
      });

      // 18000 = 5 hours. if above it is a fake series bookmark, so remove it
      tempBookmarks = tempBookmarks.filter(
        (bookmark) =>
          parseInt(bookmark.rangeTime, 10) <= 18000 && parseInt(bookmark.rangeTime, 10) > 0 && !bookmark.subContentInfo,
      );

      let tempPvrBookmark = getState().pvrReducer.tempBookmark;
      if (tempPvrBookmark) {
        let foundBookmark = tempBookmarks.find((x: Bookmark) => x.contentId === tempPvrBookmark!.contentId);
        if (foundBookmark) {
          let foundIndex = tempBookmarks.findIndex((x: Bookmark) => x.contentId === tempPvrBookmark!.contentId);
          tempBookmarks[foundIndex].rangeTime = tempPvrBookmark.rangeTime.toString();
        } else {
          tempBookmarks.push(tempPvrBookmark);
        }
      }
      dispatch(setAllBookmarks(tempBookmarks));
      return tempBookmarks;
    });
  };
}

function setContinueWatchingAssetsLoading(isLoading: boolean) {
  return {
    type: IS_LOADING_CONTINUE_WATCHING_ASSETS,
    continueWatchingAssetsIsLoading: isLoading,
  };
}

export function setContinueWatchingAssets(assets: AltiboxAsset[] | undefined) {
  return {
    type: SET_CONTINUE_WATCHING_ASSETS,
    continueWatchingAssets: assets,
  };
}

export function loadContinueWatchingAssets(bookmarks: Bookmark[], amountToLoad: number) {
  return function (dispatch: DispatchType, getState: GetStateType) {
    dispatch(setContinueWatchingAssetsLoading(true));

    loadAssetsForAllBookmarks(bookmarks, dispatch, getState).then((assets: AltiboxAsset[]) => {
      const cwAssets = assets.filter((x) => x.progress !== 0).slice(0, amountToLoad);
      dispatch(setContinueWatchingAssets(cwAssets));
      dispatch(dispatch(setContinueWatchingAssetsLoading(false)));
    });
  };
}

function bookmarkIsRecentlyUpdated(bookmark: Bookmark) {
  const recentlyBoundaryDate = moment().subtract(30, 'days');
  const lastWatchedDate = moment(bookmark.updateTime, huaweiDateTimeFormat);
  return lastWatchedDate.isAfter(recentlyBoundaryDate);
}

function bookmarkRangeTimeIsValid(bookmark: Bookmark) {
  const rangeTime = parseInt(bookmark.rangeTime, 10);
  return rangeTime > 0;
}

async function loadAssetsForAllBookmarks(bookmarks: Bookmark[], dispatch: DispatchType, getState: GetStateType) {
  const vodAssets: ContinueWatchingAsset[] = await getContinueWatchingAssetsVOD(bookmarks, getState, true);
  const catchupAssets: ContinueWatchingAsset[] = await getContinueWatchingAssetsCatchup(bookmarks, getState, true);
  const pvrAssets: ContinueWatchingAsset[] = await getContinueWatchingAssetsPVR(bookmarks, dispatch, getState, true);
  const allAssets = catchupAssets
    .concat(vodAssets)
    .concat(pvrAssets)
    .sort(sortContinueWatchingAssetsByBookmarkUpdateTime)
    .map((asset) => asset.asset);

  return allAssets;
}

function validBookmarksFor(bookmarks: Bookmark[], bookmarkType?: StreamTypeNumber) {
  let bookmarksForType: Bookmark[];
  if (bookmarkType !== undefined) {
    bookmarksForType = bookmarks.filter((b) => b.bookmarkType === bookmarkType);
  } else {
    bookmarksForType = bookmarks
      .filter((b) => b.bookmarkType !== StreamTypeNumber.PVR)
      .filter((b) => b.bookmarkType !== StreamTypeNumber.CATCHUP);
  }
  const validBookmarks = bookmarksForType
    .filter(bookmarkRangeTimeIsValid) // 11. If bookmark is 0:00 don't show asset
    .filter(bookmarkIsRecentlyUpdated); // 10. If bookmark is older than 30 days -> dont' show in CW.

  return validBookmarks;
}

export async function getVodSeasonsOrEpisodes(assets: VodAsset[], svodKiosks: PortalMenu[]) {
  let assetIds = assets.map((x) => x.id);
  const reducedIds = memoizeAssets(assetIds, svodKiosks);
  const iterations = Math.ceil(reducedIds.ids.length / 10);

  let consolidatedList: BatchVodListResponse[] = [];

  if (iterations > 0) {
    for (let i = 0; i < iterations; i++) {
      const start = i * 10;
      const end = (i + 1) * 10;

      let tempAssetIds = reducedIds.ids.slice(start, end);
      let tempSvodKioskIds = reducedIds.portalMenus.slice(start, end);

      consolidatedList.push((await batchGetSeasonsOrEpisodes(tempAssetIds, tempSvodKioskIds)) as BatchVodListResponse);
    }
  } else {
    consolidatedList.push(
      (await batchGetSeasonsOrEpisodes(reducedIds.ids, reducedIds.portalMenus)) as BatchVodListResponse,
    );
  }

  return Promise.all([consolidatedList]).then((batchedLists: BatchVodListResponse[][]) => {
    let flattenedBatchedLists = flatten(batchedLists);
    let concatedLists = flattenedBatchedLists.map((batch) => batch.responseList);
    let flattenedConcatedLists = flatten(concatedLists) as VodBatchListResponse[];
    let tempConcatedLists: VodBatchListResponse[] = [];
    let index = 0;
    for (let id of reducedIds.ids) {
      if (reducedIds.assets[id]) {
        while (reducedIds.assets[id].length !== 0) {
          let assertIndex = reducedIds.assets[id].pop();
          if (assertIndex === 0) {
            tempConcatedLists[0] = flattenedConcatedLists[index];
          }
          if (assertIndex) {
            tempConcatedLists[Number(assertIndex)] = flattenedConcatedLists[index];
          }
        }
      }
      index++;
    }
    return tempConcatedLists;
  });
}

export async function mergeEpisodesAndSeasonsWithAssets(
  vodAssets: VodAsset[] | VodAssetSeason[],
  episodes: VodBatchListResponse[],
  assetSeason?: boolean,
): Promise<VodAsset[] | VodAssetSeason[]> {
  let index = 0;
  for (let asset of vodAssets) {
    if (assetSeason) {
      (asset as VodAssetSeason).episodes = (episodes[index].msg.vodlist as VodAssetSeason[]) || [];
    } else {
      asset.seasons = (episodes[index] && (episodes[index].msg.vodlist as VodAssetSeason[])) || [];
    }
    index++;
  }
  return assetSeason ? (vodAssets as VodAssetSeason[]) : (vodAssets as VodAsset[]);
}

export async function populateSeriesWithEpisodes(vodAssets: VodAsset[], svodKiosks: PortalMenu[]) {
  const allSeries: VodAssetSeason[][] = vodAssets.map((assets) => {
    return assets.seasons as VodAssetSeason[];
  });

  let segmentedIds: Array<string[]> = [];

  allSeries.forEach((series) => {
    segmentedIds.push(series.map((serie) => serie.id));
  });

  let allAssets = flatten(await allSeries);
  let svodKioskIds = relatedSvodKioskIds(allAssets as VodAsset[], svodKiosks);
  const relations = await getVodSeasonsOrEpisodes(allAssets, svodKioskIds);

  let position = 0;
  for (let chunk of allSeries) {
    for (let i = 0; i < chunk.length; i++) {
      chunk[i].episodes = relations[position].msg.vodlist;
      position++;
    }
  }

  return vodAssets;
}

function replaceGenreIdWithText(recording: PvrRecording, getState: GetStateType) {
  const genres = getState().channelsReducer.genres;
  const recordingGenreId = recording.genreIds ? recording.genreIds[0] : null;
  if (recordingGenreId) {
    const foundInReducer = genres.find((x) => x.genreId === recordingGenreId);
    if (foundInReducer) {
      recording.genreIds = [foundInReducer.genreName];
    }
  }
  return recording;
}

async function pvrEpisodesToPromote(
  allRecordings: PvrRecording[],
  previousEpisodes: PvrRecording[],
): Promise<PvrRecording[]> {
  let nextEpisodes: PvrRecording[] = [];
  let allRecordingsTemp = allRecordings.slice(0);
  allRecordingsTemp.forEach((x) => x.sortedSeasons.reverse());
  previousEpisodes.forEach((episode) => {
    let recordingCollection = allRecordingsTemp.find((x) => x.mediaId === episode.mediaId);
    if (recordingCollection) {
      let seasonIndex = -1;
      let episodeIndex = -1;
      recordingCollection.sortedSeasons.forEach((season, seasonIdx) => {
        let attempt = !season.episodes ? -1 : season.episodes.findIndex((x) => x.pvrId === episode.pvrId);
        if (attempt !== -1) {
          seasonIndex = seasonIdx;
          episodeIndex = attempt;
        }
      });

      if (episodeIndex > -1 && seasonIndex > -1) {
        if (
          recordingCollection.sortedSeasons[seasonIndex] &&
          recordingCollection.sortedSeasons[seasonIndex].episodes &&
          recordingCollection.sortedSeasons[seasonIndex].episodes[episodeIndex + 1]
        ) {
          nextEpisodes.push(recordingCollection.sortedSeasons[seasonIndex].episodes[episodeIndex + 1]);
        } else if (
          recordingCollection.sortedSeasons[seasonIndex + 1] &&
          recordingCollection.sortedSeasons[seasonIndex + 1].episodes &&
          recordingCollection.sortedSeasons[seasonIndex + 1].episodes[0]
        ) {
          nextEpisodes.push(recordingCollection.sortedSeasons[seasonIndex + 1].episodes[0]);
        }
      }
    }
  });
  return await nextEpisodes;
}

async function getContinueWatchingAssetsPVR(
  bookmarks: Bookmark[],
  dispatch: DispatchType,
  getState: GetStateType,
  continueWatching?: boolean,
) {
  let tempBookmarks = bookmarks.slice(0);
  const pvrBookmarks = validBookmarksFor(bookmarks, StreamTypeNumber.PVR);
  let pvrRecordingsWithBookmarks = (await getRecordingsForBookmarks({ bookmarkList: pvrBookmarks })) as PvrRecording[];
  const pvrState = getState().pvrReducer;
  const allPvrRecordings = pvrState.recordings;
  const pvrRecordingsOver97Percent = pvrRecordingsWithBookmarks.filter(
    (recording) => getPercentWatched(recording) > 97,
  );
  pvrRecordingsWithBookmarks = pvrRecordingsWithBookmarks.filter((recording) => getPercentWatched(recording) < 97);
  let pvrContinueWatchingList: PvrRecording[] = [];

  if (pvrRecordingsOver97Percent.length > 0 && allPvrRecordings) {
    let episodesToPromote = await pvrEpisodesToPromote(allPvrRecordings, pvrRecordingsOver97Percent);
    pvrRecordingsOver97Percent.forEach((r, idx) => {
      let found = tempBookmarks.find((b) => b.contentId === r.pvrId);
      if (found && episodesToPromote[idx]) {
        found.contentId = episodesToPromote[idx].pvrId;
      }
    });
    pvrRecordingsWithBookmarks = pvrRecordingsWithBookmarks.concat(episodesToPromote);
    pvrRecordingsWithBookmarks.forEach((recording) => {
      if (allPvrRecordings.map((x) => x.pvrId).indexOf(recording.pvrId) !== -1) {
        recording.isSingle = true;
      }
    });
    let singles = pvrRecordingsWithBookmarks.filter((recording) => recording && recording.isSingle);
    let series = pvrRecordingsWithBookmarks.filter((recording) => recording && !recording.isSingle);
    series = Array.from(new Set(series.map((a) => a.mediaId)))
      .map((id) => {
        return series.find((a) => a.mediaId === id);
      })
      .map((x) => x) as PvrRecording[];
    pvrContinueWatchingList = series.concat(singles);
  }
  const pvrAssetPromises = pvrContinueWatchingList.map((recording: PvrRecording) =>
    createAssetFromPvr(replaceGenreIdWithText(recording, getState), tempBookmarks, getState, continueWatching),
  );
  const pvrAssetsResolved = await Promise.all(pvrAssetPromises);
  const pvrAssets = pvrAssetsResolved.filter((a) => a) as ContinueWatchingAsset[];

  return pvrAssets;
}

export function relatedSvodKioskIds(vodItems: VodAsset[], svodKiosks: PortalMenu[]) {
  return vodItems.map((x: VodAsset) => {
    let found = svodKiosks.find((z) => z.providerName === x.companyName);
    return found ? found : ({} as PortalMenu);
  });
}

async function getContinueWatchingAssetsVOD(bookmarks: Bookmark[], getState: GetStateType, continueWatching?: boolean) {
  let vodIdsNotToAdd: string[] = [];
  const vodBookmarks = validBookmarksFor(bookmarks);
  let vodItems = await getVodContentDetailFromBookmarks({ bookmarkList: vodBookmarks });

  if (!vodItems) {
    return [] as ContinueWatchingAsset[];
  }
  let vodAssets = [] as ContinueWatchingAsset[];

  const svodKiosks = getState().app.svodKiosks;
  let svodKioskIds: PortalMenu[] = relatedSvodKioskIds(await vodItems, svodKiosks);

  let vodsThatAreSeries: VodAsset[] = vodItems.slice().filter((asset) => asset.fathervodlist);
  if (vodsThatAreSeries.length > 0) {
    // get all relations to series
    let vodFathers = (await getContentDetail(
      vodsThatAreSeries.map((x) => x.fathervodlist && x.fathervodlist[0].vodid).join(','),
    )) as VodAssetSeason[];
    let vodGrandFathers = await getContentDetail(
      vodFathers.map((x) => x.fathervodlist && x.fathervodlist[0].vodid).join(','),
    );

    // get all episodes for the father asset
    let fatherEpisodes: VodBatchListResponse[] = await getVodSeasonsOrEpisodes(vodFathers, svodKioskIds);
    vodFathers = (await mergeEpisodesAndSeasonsWithAssets(vodFathers, fatherEpisodes, true)) as VodAssetSeason[];

    // get all seasons for the grandfather asset
    let grandFatherSeasons: VodBatchListResponse[] = await getVodSeasonsOrEpisodes(vodGrandFathers, svodKioskIds);

    vodGrandFathers = await mergeEpisodesAndSeasonsWithAssets(vodGrandFathers, grandFatherSeasons);
    // set seasons on episodes
    vodsThatAreSeries = await mergeEpisodesAndSeasonsWithAssets(vodsThatAreSeries, grandFatherSeasons);

    // populate each season within with episodes
    let populatedSeries = await populateSeriesWithEpisodes(vodsThatAreSeries, svodKiosks);

    return Promise.all(populatedSeries).then((output) => {
      output.forEach((vod, idx) => (vod.fatherVod = vodFathers[idx]));
      vodFathers.forEach((vod, idx) => (vod.fatherVod = vodGrandFathers[idx]));
      let vodAssetList = vodItems.map((asset: VodAsset) => {
        return createAssetFromVod(asset, bookmarks, vodIdsNotToAdd, getState, continueWatching);
      });
      let vodAssetsPromises = Promise.all(vodAssetList);
      return vodAssetsPromises.then((resolved) => {
        vodAssets = resolved.filter((a) => a) as ContinueWatchingAsset[];
        return vodAssets;
      });
    });
  } else {
    let vodAssetList = vodItems.map((asset: VodAsset) => {
      return createAssetFromVod(asset, bookmarks, vodIdsNotToAdd, getState, continueWatching);
    });

    let vodAssetsPromises = Promise.all(vodAssetList);
    return vodAssetsPromises.then((resolved) => {
      vodAssets = resolved.filter((a) => a) as ContinueWatchingAsset[];
      return vodAssets;
    });
  }
}

async function getContinueWatchingAssetsCatchup(
  bookmarks: Bookmark[],
  getState: GetStateType,
  continueWatching?: boolean,
) {
  const catchupBookmarks = validBookmarksFor(bookmarks, StreamTypeNumber.CATCHUP);

  const [prePadding, postPadding] = await getContinueWatchingPadding();

  const catchupPrograms = (await getCatchupDetailsFromBookmarks({ bookmarkList: catchupBookmarks })) as Program[];
  let catchupAssets = catchupPrograms
    .filter((program) => program.recordedMediaIds)
    .map((program) => ({ ...program, beginOffset: prePadding, endOffset: postPadding }))
    .map((program) => createContinueWatchingAssetFromProgram(program, bookmarks, getState, continueWatching))
    .filter((a) => a) as ContinueWatchingAsset[];

  return catchupAssets;
}

function sortContinueWatchingAssetsByBookmarkUpdateTime(assetA: ContinueWatchingAsset, assetB: ContinueWatchingAsset) {
  let updateA = moment(assetA.bookmark.updateTime, huaweiDateTimeFormat);
  let updateB = moment(assetB.bookmark.updateTime, huaweiDateTimeFormat);
  if (updateA.isAfter(updateB)) {
    return -1;
  }
  return 1;
}

async function createAssetFromPvr(
  recording: PvrRecording,
  bookmarks: Bookmark[],
  getState: GetStateType,
  continueWatching?: boolean,
) {
  let bookmark = bookmarks.find((b) => b.contentId === recording.pvrId);
  if (!bookmark) {
    return undefined;
  }

  const channel = getState().channelsReducer.channels.find((x) => x.contentId === recording.channelId) as Channel;
  const channelLogo = channel && channel.pictures ? channel.pictures[0].href : '';

  const allRecordings = getState().pvrReducer.recordings;
  let isSeriesRecording = true;
  if (allRecordings && allRecordings.find((x) => x.pvrId === recording.pvrId && x.isSingle)) {
    isSeriesRecording = false;
  }

  const link = isSeriesRecording
    ? `${routes.pvr.base}${routes.pvr.series}${routes.pvr.play}/${recording.channelId}/${recording.seriesId}/${recording.pvrId}`
    : `${routes.pvr.base}${routes.pvr.single}${routes.pvr.play}/${recording.channelId}/${recording.pvrId}`;

  const detailsLink = isSeriesRecording
    ? `${routes.pvr.base}${routes.pvr.series}/${recording.channelId}/${recording.seriesId}/${recording.pvrId}`
    : `${routes.pvr.base}${routes.pvr.single}/${recording.pvrId}`;

  let title =
    recording.pvrName +
    (recording.seasonNum && recording.subNum
      ? ' S' + doubleDigits(recording.seasonNum) + ' E' + doubleDigits(recording.subNum)
      : '');
  let subtitle = 'TV PROGRAM';

  if (continueWatching) {
    title = recording.pvrName;
    let airtime = recording.beginTime ? `${toMomentReadableDateString(recording.beginTime)}` : null;
    subtitle =
      recording.seasonNum && recording.subNum
        ? ` S${doubleDigits(recording.seasonNum)} E${doubleDigits(recording.subNum)}`
        : '';
    if (airtime) {
      if (!recording.seasonNum) {
        if (recording.genreIds && !Number(recording.genreIds[0])) {
          subtitle = `${recording.genreIds[0]} - ${airtime}`;
        } else {
          subtitle = `${airtime}`;
        }
      } else {
        subtitle += ` - ${airtime}`;
      }
    }
  }

  let asset: AltiboxAsset = {
    id: recording.pvrId,
    progress: getPercentWatched(recording),
    type: StreamType.PVR,
    brandLogo: channelLogo,
    picture: recording.pictures ? recording.pictures[0].href : '',
    title: title,
    subtitle: subtitle,
    weight: 1,
    playLink: link,
    detailsLink: detailsLink,
    asset: recording,
  };
  let continueWatchingAsset: ContinueWatchingAsset = {
    asset: asset,
    bookmark: bookmark,
  };

  return continueWatchingAsset;
}

function createContinueWatchingAssetFromProgram(
  program: Program,
  bookmarks: Bookmark[],
  getState: GetStateType,
  continueWatching?: boolean,
) {
  if (!program) {
    return undefined;
  }
  const bookmark = bookmarks.find((b) => b.contentId === program.id);
  if (bookmark === undefined) {
    return undefined;
  }

  const percentWatched = getPercentWatchedCatchup(program, bookmark);
  if (percentWatched > 97) {
    return undefined;
  }

  let asset = getCatchupAssetWithLock(
    program,
    getState().channelsReducer.channels,
    getState().authReducer,
    bookmark,
    continueWatching,
  );

  if (asset.isGeoLockedAtUserLocation || asset.isLockedForOutsideHomePlayback) {
    asset.playLink = asset.detailsLink;
  }

  let continueWatchingAsset: ContinueWatchingAsset = {
    asset: asset,
    bookmark: bookmark,
  };

  return continueWatchingAsset;
}

export async function createAssetFromVod(
  vodAsset: VodAsset,
  bookmarks: Bookmark[],
  vodIdsNotToAdd: string[],
  getState: GetStateType,
  continueWatching?: boolean,
): Promise<ContinueWatchingAsset | undefined> {
  const bookmark = bookmarks.find((b) => b.contentId === vodAsset.id);
  if (bookmark === undefined) {
    return undefined;
  }
  vodAsset.bookmark = bookmark;

  const assetIsEpisode = isEpisode(vodAsset);
  const percentWatched = getVodPercentWatched(vodAsset);
  const myContent = getState().vodReducer.myContent;
  const authState = getState().authReducer;
  const svodKiosks = getState().app.svodKiosks;
  const svodKioskRelatedToAsset = getSvodKiosk(vodAsset, svodKiosks);

  if (svodKioskRelatedToAsset) {
    if (!checkIfUserHasSvodProduct(authState, svodKioskRelatedToAsset!)) {
      return undefined;
    }
  } else {
    if (!boughtOrRented(vodAsset, myContent)) {
      return undefined;
    }
  }

  if (assetIsEpisode) {
    if (vodAlreadyAdded(vodIdsNotToAdd, vodAsset.id)) {
      return undefined;
    }
    if (vodAsset && vodAsset.seasons) {
      addBookmarksToEpisodes(vodAsset, bookmarks);
      let promotedEpisode = getEpisodeToPromoteBasedOnSeasons(vodAsset.seasons, true);
      if (promotedEpisode && !vodAlreadyAdded(vodIdsNotToAdd, promotedEpisode.id)) {
        addEpisodesToExcludeList(vodAsset, vodIdsNotToAdd);
        promotedEpisode.previousAsset = vodAsset;
        const cwAsset: ContinueWatchingAsset = {
          asset: await getVodAltiboxAsset(
            promotedEpisode,
            getVodPercentWatched(promotedEpisode),
            vodIdsNotToAdd,
            getState,
            continueWatching,
          ),
          bookmark: bookmark,
        };
        return cwAsset;
      } else {
        return undefined;
      }
    }

    if (vodAsset && !vodAlreadyAdded(vodIdsNotToAdd, vodAsset.id) && percentWatched < 97) {
      addEpisodesToExcludeList(vodAsset, vodIdsNotToAdd);
      const cwAsset: ContinueWatchingAsset = {
        asset: await getVodAltiboxAsset(
          vodAsset,
          getVodPercentWatched(vodAsset),
          vodIdsNotToAdd,
          getState,
          continueWatching,
        ),
        bookmark: bookmark,
      };
      return cwAsset;
    }

    return undefined;
  }

  // if already added or is a finished movie
  if (vodAlreadyAdded(vodIdsNotToAdd, vodAsset.id) || (percentWatched > 97 && !assetIsEpisode)) {
    return undefined;
  }

  const continueWatchingAsset: ContinueWatchingAsset = {
    asset: await getVodAltiboxAsset(vodAsset, percentWatched, vodIdsNotToAdd, getState, continueWatching),
    bookmark: bookmark,
  };
  return continueWatchingAsset;
}

function vodAlreadyAdded(vodIdsNotToAdd: string[], vodId?: string) {
  return vodId && vodIdsNotToAdd.indexOf(vodId) > -1;
}

function boughtOrRented(asset: VodAsset, myContent?: VodAsset[]) {
  if (asset.hasOwnProperty('rentperiod') && asset.hasOwnProperty('issubscribed')) {
    return assetIsOwned(asset, myContent);
  } else {
    return false;
  }
}

function addBookmarksToEpisodes(seasonAsset: VodAsset, bookmarks: Bookmark[]) {
  if (seasonAsset && seasonAsset.seasons) {
    seasonAsset.seasons.forEach((season: VodAssetSeason) => {
      if (season.episodes) {
        season.episodes.forEach((episodeToAdd: VodAsset) => {
          episodeToAdd = appendBookmarksOn(episodeToAdd, { bookmarkList: bookmarks });
        });
      }
    });
  }
}

function addEpisodesToExcludeList(seasonAsset: VodAsset, vodIdsNotToAdd: string[]) {
  if (seasonAsset && seasonAsset.seasons) {
    seasonAsset.seasons.forEach((season: VodAssetSeason) => {
      vodIdsNotToAdd.push(season.id);
      if (season.episodes) {
        season.episodes.forEach((episodeToAdd: VodAsset) => {
          vodIdsNotToAdd.push(episodeToAdd.id);
        });
      }
    });
  }
}

export function getVodAltiboxAssetForSearch(vodAsset: VodAsset, getState: GetStateType, forceNotSvod: boolean = false) {
  let asset = getVodAltiboxAsset(vodAsset, 0, [], getState, false, forceNotSvod);
  return asset;
}

export function getVodAltiboxAsset(
  vodAsset: VodAsset,
  percentWatched: number,
  vodIdsNotToAdd: string[],
  getState: GetStateType,
  continueWatching?: boolean,
  forceNotSvod?: boolean,
) {
  vodIdsNotToAdd.push(vodAsset.id);
  let playLink = `${routes.vod.base}${routes.vod.play}/${vodAsset.externalContentCode}`;
  let detailsLink = `${routes.vod.base}${routes.vod.details}/${vodAsset.externalContentCode}`;
  let svodKiosk = getSvodKiosk(vodAsset, getState().app.svodKiosks);

  let fatherAsset: VodAsset;
  let title = vodAsset.name;
  let episodeNumber = -1;
  let sitcomnum = -1;
  let seasonNumber = -1;
  let returnId = vodAsset.id;
  let brandLogo = '';
  let croppedBrandLogo = '';
  let picture = '';
  let grandFatherAsset: VodAsset | undefined;
  let subtitle = getGenre(vodAsset.genres);
  const svodUrl = getSvodUrl(svodKiosk);

  if (vodAsset.seriesAsset) {
    detailsLink = `${routes.vod.base}${routes.vod.details}/${vodAsset.seriesAsset.foreignsn!}/${vodAsset.foreignsn!}`;
  }

  if (svodKiosk && !forceNotSvod) {
    playLink = `${routes.svod.base}/${svodUrl}${routes.vod.play}/${vodAsset.externalContentCode}`;
    detailsLink = `${routes.svod.base}/${svodUrl}${routes.svod.details}/${vodAsset.externalContentCode}`;
    if (vodAsset.seriesAsset) {
      detailsLink = `${routes.svod.base}/${svodUrl}${routes.svod.details}/${vodAsset.seriesAsset
        .foreignsn!}/${vodAsset.foreignsn!}`;
    }
    if (svodKiosk.kioskHeader) {
      let defaultImagePath = getState().app.defaultImagePath;
      if (svodKiosk.kioskHeader.phone) {
        brandLogo = `${defaultImagePath}/${svodKiosk.kioskHeader.phone}`;
      }
      if (svodKiosk.kioskHeader.cropped) {
        croppedBrandLogo = `${defaultImagePath}/${svodKiosk.kioskHeader.cropped}`;
      }
    }
    vodAsset.hideBoughtForCW = true;
    vodAsset.showRentedBadge = false;
  }

  if (continueWatching && (vodAsset.fatherVod || vodAsset.previousAsset)) {
    if (vodAsset.fatherVod) {
      fatherAsset = vodAsset.fatherVod;
      grandFatherAsset = vodAsset.fatherVod.fatherVod ? vodAsset.fatherVod.fatherVod : vodAsset.fatherVod;
      seasonNumber = vodAsset.seasons!.findIndex((x: VodAsset) => x.id === vodAsset.fatherVod!.id);
      episodeNumber = (vodAsset.seasons![seasonNumber] as VodAssetSeason).episodes.findIndex(
        (x: VodAsset) => x.id === vodAsset.id,
      );

      subtitle = getGenre(fatherAsset.genres);
      title = grandFatherAsset.name;
      if (fatherAsset && hasPicture(fatherAsset.picture)) {
        picture = getSvodPicture(fatherAsset);
      }
    } else if (vodAsset.previousAsset) {
      let oldAsset = vodAsset.previousAsset;
      if (oldAsset.seasons) {
        oldAsset.seasons.forEach((season, sIdx) => {
          if (season.episodes) {
            season.episodes.forEach((episode, eIdx) => {
              if (episode.id === vodAsset.id) {
                seasonNumber = sIdx;
                episodeNumber = eIdx;
                sitcomnum = episode.sitcomnum ? Number(episode.sitcomnum) : -1;
              }
            });
          }
        });
      }
      grandFatherAsset = oldAsset.fatherVod!.fatherVod!; // this is definitely here if we are at this

      // we assume that the other seasonNumber calculations are correct, but there are some who have irregular seasons
      if (grandFatherAsset.seasons) {
        let parentId = vodAsset.fathervodlist[0].vodid;
        let correctParent = grandFatherAsset.seasons.find((x) => x.id === parentId);
        if (correctParent) {
          seasonNumber = Number(correctParent.fathervodlist[0].sitcomnum) - 1;
        }
        if (svodKiosk) {
          detailsLink = `${routes.svod.base}/${svodUrl}${
            routes.svod.details
          }/${grandFatherAsset.foreignsn!}/${vodAsset.foreignsn!}`;
        } else {
          detailsLink = `${routes.vod.base}${routes.vod.details}/${grandFatherAsset.foreignsn!}/${vodAsset.foreignsn!}`;
        }
      }
      title = grandFatherAsset.name;
      if (oldAsset.fatherVod && hasPicture(oldAsset.fatherVod.picture)) {
        picture = getSvodPicture(oldAsset.fatherVod);
      }
    }

    subtitle = `${seasonNumber !== -1 ? 'S' + doubleDigits(seasonNumber + 1) : ''}`;

    if (sitcomnum !== -1) {
      subtitle += ' E' + doubleDigits(sitcomnum);
    } else {
      subtitle += `${episodeNumber !== -1 ? ' E' + doubleDigits(episodeNumber + 1) : ''}`;
    }

    return {
      id: returnId,
      progress: percentWatched,
      type: StreamType.VOD,
      brandLogo: brandLogo,
      croppedBrandLogo: croppedBrandLogo,
      picture: picture,
      title: title,
      subtitle: subtitle,
      genre: getGenre(vodAsset.genres),
      weight: 1,
      playLink: playLink,
      detailsLink: detailsLink,
      asset: vodAsset,
    };
  } else {
    if (hasPicture(vodAsset.picture)) {
      picture = getSvodPicture(vodAsset);
    }
    return {
      id: vodAsset.id,
      name: vodAsset.name,
      progress: percentWatched,
      type: StreamType.VOD,
      brandLogo: brandLogo,
      croppedBrandLogo: croppedBrandLogo,
      picture: picture,
      title: title,
      subtitle: subtitle,
      genre: subtitle,
      weight: 1,
      playLink: playLink,
      detailsLink: detailsLink,
      asset: vodAsset,
    };
  }
}

export function setOriginLocation(locationPath: string) {
  return {
    type: SET_ORIGIN_LOCATION,
    originLocation: locationPath,
  };
}

export function clearOriginLocation() {
  return setOriginLocation('');
}

export function setNielsenChannels(results: ExtensionInfoResponse) {
  const nielsen_keys = ['nielsen_channels', 'nielsen_channels2'];
  const channelLists = results.extensionInfo
    .filter((x) => nielsen_keys.includes(x.key))
    .reduce<NielsenChannels[]>((acc, obj) => [...acc, JSON.parse(obj.value)], [])
    .flat();

  if (channelLists) {
    try {
      const nielsenChannels = channelLists;
      return {
        type: SET_NIELSEN_CHANNELS,
        nielsenChannelMapping: nielsenChannels,
      };
    } catch (error) {
      return {
        type: SET_NIELSEN_CHANNELS,
        nielsenChannelMapping: nielsenChannelMapping,
      };
    }
  }
  return {
    type: SET_NIELSEN_CHANNELS,
    nielsenChannelMapping: nielsenChannelMapping,
  };
}

export function getVodAltiboxAssetDetails(vodAsset: VodAsset, nometa?: boolean): AltiboxAsset {
  return {
    progress: 0,
    id: '0',
    picture: '',
    title: '',
    playLink: '',
    detailsLink: '',
    weight: 0,
    type: AltiboxAssetDetailsType.VOD,
    asset: vodAsset,
    meta: nometa
      ? undefined
      : ({
          favourite: {
            asset: vodAsset,
            catalog: '-1',
          },
        } as DetailsMeta),
  };
}
