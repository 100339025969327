import * as searchApi from '../../../api/search';
import * as appApi from '../../../api/app';
import {
  DispatchType,
  SearchCategories,
  ExtensionInfoResponse,
  CategoryListResponse,
  GetStateType,
  AltiboxAssetSearchResponse,
  SearchType,
} from '../../../interfaces';
import { vodConfigPostfix } from '../../../config';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import { ScriptService } from '../../../controllers/ScriptService';
import { setNielsenChannels } from '../../app/actions';
import { setLoginMethod } from '../../../utils/authUtils';

export enum TypeKeys {
  SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS',
  SET_SEARCH_QUERY = 'SET_SEARCH_QUERY',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
  SET_SEARCH_CATEGORIES = 'SET_SEARCH_CATEGORIES',
}

export type ActionTypes = SetSearchResults | SetSearchQuery | ClearSearch | SetSearchCategories;

export interface SetSearchResults {
  type: TypeKeys.SET_SEARCH_RESULTS;
  searchResults: [AltiboxAssetSearchResponse[], AltiboxAssetSearchResponse[], AltiboxAssetSearchResponse[]];
  initialSearchQuery: string;
}

export function setSearchResults(
  query: string,
  results: [AltiboxAssetSearchResponse[], AltiboxAssetSearchResponse[], AltiboxAssetSearchResponse[]],
): SetSearchResults {
  return {
    type: TypeKeys.SET_SEARCH_RESULTS,
    searchResults: results,
    initialSearchQuery: query,
  };
}

export interface SetSearchQuery {
  type: TypeKeys.SET_SEARCH_QUERY;
  searchQuery: string;
}

export function setSearchQuery(query: string): SetSearchQuery {
  return {
    type: TypeKeys.SET_SEARCH_QUERY,
    searchQuery: query,
  };
}

export interface ClearSearch {
  type: TypeKeys.CLEAR_SEARCH;
}

export function clearSearch(): ClearSearch {
  return {
    type: TypeKeys.CLEAR_SEARCH,
  };
}

export interface SetSearchCategories {
  type: TypeKeys.SET_SEARCH_CATEGORIES;
  searchCategories: SearchCategories;
}

export function setSearchCategories(searchCategories: SearchCategories) {
  return {
    type: TypeKeys.SET_SEARCH_CATEGORIES,
    searchCategories: searchCategories,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getCustomizeConfig(location: string): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    return searchApi.getCategories().then((configResults: ExtensionInfoResponse) => {
      const configKey = location.toLowerCase() + vodConfigPostfix;
      if (configResults && configResults.extensionInfo) {
        setLoginMethod(configResults.extensionInfo.find((x) => x.key === 'login_method'));
        if (ScriptService.isDKUser()) {
          dispatch(setNielsenChannels(configResults));
        }
        let categories: SearchCategories = {} as SearchCategories;
        let vodCategories = configResults.extensionInfo.find((x) => x.key === configKey);
        if (vodCategories) {
          categories.VOD = vodCategories.value;
        }
        appApi.getContentCategory('-1').then((catResponse: CategoryListResponse) => {
          let svodCategories = catResponse.categorylist.find((x) => x.name === 'SVOD');
          if (svodCategories) {
            categories.SVOD = svodCategories.id;
          }
          dispatch(setSearchCategories(categories));
          let pricingCategories = catResponse.categorylist.find((x) => x.name === 'PRICING');
          if (pricingCategories) {
            appApi.getContentCategory(pricingCategories.id).then((pricing) => {
              let pricingTempPromises = [];
              let EST = pricing.categorylist.find((x) => x.name === 'EST');
              let TVOD = pricing.categorylist.find((x) => x.name === 'TVOD');
              if (EST) {
                pricingTempPromises.push(appApi.getContentCategory(EST.id));
              }
              if (TVOD) {
                pricingTempPromises.push(appApi.getContentCategory(TVOD.id));
              }
              Promise.all(pricingTempPromises).then((pricingResponse) => {
                const pricingCategoryList = flatten(pricingResponse.map((x) => x.categorylist));
                const pricingResolutions = pricingCategoryList.map((x) => x.id);
                let resolutionTemp: Promise<CategoryListResponse>[] = [];
                pricingResolutions.forEach((resolution) => {
                  resolutionTemp.push(appApi.getContentCategory(resolution));
                });
                Promise.all(resolutionTemp).then((prices) => {
                  const activeContent = flatten(prices.map((x) => x.categorylist));
                  const activeIds = activeContent.map((x) => x.id);
                  if (!isEmpty(activeIds)) {
                    const svodCategoryIds = flatten(getState().app.svodKiosks.map((x) => x.allActiveCategoryIds));
                    categories.ActiveContent = [categories.VOD]
                      .concat([categories.SVOD])
                      .concat(activeIds)
                      .concat(svodCategoryIds);
                  }
                  dispatch(setSearchCategories(categories));
                });
              });
            });
          }
        });
      }
    });
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchSearchResults(searchQuery: string): any {
  return async function (dispatch: DispatchType, getState: GetStateType) {
    dispatch(setSearchQuery(searchQuery));
    const state = getState();
    let searchCategories = state.searchReducer.searchCategories;
    if (!searchCategories) {
      dispatch(getCustomizeConfig(state.authReducer.country));
      setTimeout(() => {
        dispatch(fetchSearchResults(searchQuery));
      }, 1000);
    } else {
      const onlyTitles = searchApi.search(searchQuery, getState, searchCategories, 50, SearchType.CONTENT_NAME);
      const onlyActors = searchApi.search(searchQuery, getState, searchCategories, 50, SearchType.STAR_NAME);
      const directorsAndOtherCast = searchApi.search(
        searchQuery,
        getState,
        searchCategories,
        50,
        SearchType.DIRECTORS_AND_OTHER_CAST,
      );
      return Promise.all([onlyTitles, onlyActors, directorsAndOtherCast]).then((response) => {
        dispatch(setSearchResults(searchQuery, response));
      });
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function emptySearch(): any {
  return function (dispatch: DispatchType) {
    dispatch(clearSearch());
  };
}
