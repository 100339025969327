import { Button } from '../Buttons';
import { SvgCheck } from '../../../icons';

import styles from './optionBox.module.scss';
import { useRef } from 'react';
import { useOnOutsideClick } from '../../../hooks/useOnOutsideClick';
import { Option } from '../../../interfaces';

type Props<OptionType> = {
  className?: string;
  title: string;
  options: OptionType[];
  handleSelect: (option: OptionType) => void;
  onOutsideClick: (event: MouseEvent | TouchEvent | KeyboardEvent) => void;
};

export function OptionBox<OptionType extends Option>({
  options,
  className,
  title,
  handleSelect,
  onOutsideClick,
}: Props<OptionType>) {
  const ref = useRef<HTMLDivElement>(null);
  useOnOutsideClick(ref, onOutsideClick);

  return (
    <div ref={ref} className={`${styles.dropdownBox} ${className ?? ''}`}>
      <p className={styles.title}>{title}</p>
      <ul>
        {options.map((option) => {
          const text = option.count ? `${option.title} (${option.count})` : option.title;
          return (
            <li key={option.title}>
              <Button
                data-text={text}
                disabled={option.active ?? false}
                onClick={() => handleSelect(option)}
                className={`${styles.option} ${option.active ? styles.active : ''}`}
              >
                {text}
                {option.active ? <SvgCheck width="16px" height="12px" /> : null}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
