import React, { Component } from 'react';
import { getResolution } from '../../../utils/vodUtils';
import './style.scss';

import optionButtonStyles from '../../OptionsModal/styles.module.scss';
import { VodContentProduct } from '../../../interfaces';
import i18n from '../../../i18n';
import Badge from '../../Badge';

interface Props {
  options: VodContentProduct[];
  onCloseCallback: Function;
  onPurchaseCallback: Function;
}

class PurchaseOptionsModal extends Component<Props> {
  generateOptions = () => {
    this.props.options.sort((a: VodContentProduct, b: VodContentProduct) => {
      return parseInt(b.price, 10) - parseInt(a.price, 10);
    });
    let buy = this.props.options.filter((product) => {
      return product.isEST === '1';
    });

    let rent = this.props.options.filter((product) => {
      return product.isEST === '0';
    });

    let output: Array<JSX.Element> = [];
    if (buy.length > 0) {
      buy.map((option) => output.push(this.generateButton(option, `${i18n.t<string>('buy')} ${i18n.t<string>('in')}`)));
    }
    if (rent.length > 0) {
      rent.map((option) =>
        output.push(this.generateButton(option, `${i18n.t<string>('rent')} ${i18n.t<string>('in')}`)),
      );
    }
    return <>{output}</>;
  };

  generateButton = (option: VodContentProduct, type: string) => {
    const resolution = getResolution(option.restrictionList[0].v[0]);
    return (
      <button
        className={optionButtonStyles.optionButton}
        onClick={() => {
          this.props.onPurchaseCallback(option);
        }}
        key={option.id}
      >
        {type} {resolution} <Badge text={`${option.price},-`} className={'purchasing'} />
      </button>
    );
  };

  render() {
    return this.generateOptions();
  }
}
export default PurchaseOptionsModal;
