import { status, json, fetchError, getDataFromResponse } from '../controllers/Fetch';
import { getBaseUrl, getSessionTicket } from '../config';

import {
  ProgramArchiveBatchListResponse,
  EpgGenre,
  ChannelFilter,
  Genre,
  Program,
  ProgramContentDetailResponse,
  NonCatchupSeriesResponse,
  PlaybillDetailResponse,
} from '../interfaces';
import { nowHuaweiFormat } from '../utils/huaweiUtils';
import { isEmpty, uniq } from 'lodash';

// V6 API Start

export const queryProgramDetails = async <PlaybillDetailResponse>(playbillID: string) => {
  const requestBody: RequestInit = {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      playbillID,
    }),
  };

  const response = await fetch(`${getBaseUrl()}/VSP/V3/QueryPlaybill`, requestBody);
  const resultJson = await response.json();
  return resultJson as PlaybillDetailResponse;
};

// V6 API End

export async function getEpgGenres(areaId: string, bossId: string) {
  const configUrl = `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/epg-genres.json`;

  try {
    const res = await fetch(configUrl, {
      method: 'GET',
      headers: { SessionTicket: getSessionTicket() },
    });

    return await getDataFromResponse<EpgGenre[]>(res);
  } catch (error) {
    return fetchError(error as string);
  }
}

export const playBillInclude =
  'id,foreignsn,channelid,name,type,introduce,starttime,endtime,' +
  'ratingid,picture,istvod,isnpvr,contentId,contentType,cast,casts,subName,subNum,seasonNum,' +
  'genres,genreIds,producedate,extensionInfo,seriesID,externalContentCode,lifetimeId,originalTitle,' +
  'mainGenre,gapFiller,episodeInformation,isLive,externalDescription,pictures,seriesDescriptions,' +
  'recordedMediaIds,seasonId';

function createProgramArchiveGenreRequest(epgGenres: EpgGenre[], filterGenres: Genre[], channels: ChannelFilter[]) {
  return epgGenres.map((genre: EpgGenre) => {
    return {
      name: 'QueryPlaybillByFilter',
      param: {
        orderType: genre.orderType,
        type: 1,
        count: 6,
        offset: 0,
        filterlist: getFilterList(genre, filterGenres),
        begintime: '19700101010000',
        endtime: nowHuaweiFormat(),
        channelIDs: channels,
        properties: [
          {
            name: 'playbill',
            include: playBillInclude,
          },
        ],
      },
    };
  });
}

function createProgramArchiveSeriesRelationRequest(seriesID: string[]) {
  return seriesID.map((id: string) => {
    return {
      name: 'QuerySeriesRelation',
      param: {
        filterlist: [
          {
            key: 'SubscriptionType',
            value: 0,
          },
        ],
        seriesID: id,
      },
    };
  });
}

export async function getProgramDetail(
  programId: string,
  singleResponse: boolean = true,
): Promise<Program | Program[] | undefined> {
  const programDetailChapterNO = await Promise.all<PlaybillDetailResponse>(
    uniq(programId.split(','))
      // TODO: Find a better way to populate Programs chapter number, for series with large amount of episodes this function sends alot of request to huawei.
      .splice(0, 20) // Limiting to 20 requests.
      .map((value) => {
        return new Promise((resolve, reject) => {
          try {
            resolve(queryProgramDetails(value));
          } catch (error) {
            console.error(error);
            reject(error);
          }
        });
      }),
  );
  return fetch(getBaseUrl() + '/EPG/JSON/ContentDetail', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      playbill: programId,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: ProgramContentDetailResponse) => {
      const { playbilllist } = response;
      if (isEmpty(playbilllist)) {
        return;
      }
      if (singleResponse) {
        const singlePlaybill = playbilllist[0];
        const chapterInfo = programDetailChapterNO[0];
        if (chapterInfo) {
          if (chapterInfo.playbillDetail.chapterInfo) {
            return {
              ...singlePlaybill,
              chapterNO:
                chapterInfo.playbillDetail.chapterInfo.chapterNO &&
                chapterInfo.playbillDetail.chapterInfo.chapterNO !== '1'
                  ? chapterInfo.playbillDetail.chapterInfo.chapterNO
                  : '',
            } as Program;
          }
        }
        return singlePlaybill;
      }
      if (!isEmpty(programDetailChapterNO)) {
        return playbilllist.map((playbill, index) => {
          const foundPlaybill = programDetailChapterNO[index];
          if (!foundPlaybill) {
            return playbill;
          }
          return {
            ...playbill,
            chapterNO: foundPlaybill ? foundPlaybill.playbillDetail?.playbillSeries?.chapterNO : '',
          };
        });
      }
      return playbilllist;
    });
}

function createFilterList(genreIds: string, genres: Genre[]) {
  let mappedFilters: string[] = [];
  let filterIds = genreIds.replace(/\s/g, '').split(',');
  filterIds.forEach((id) => {
    let singleGenre = genres.find((_genre) => _genre.genreId === id.trim());
    if (singleGenre) {
      mappedFilters.push(singleGenre.genreName);
    }
  });
  return [{ key: 'Genre', value: mappedFilters.join(',') }];
}

function getFilterList(genre: EpgGenre, genres: Genre[]) {
  let filterList = undefined;
  if (genre.genreIds) {
    filterList = createFilterList(genre.genreIds, genres);
  }
  return filterList;
}

export function getProgramArchiveFrontPageGenres(
  epgGenres: EpgGenre[],
  filterGenres: Genre[],
  channels: ChannelFilter[],
) {
  if (!epgGenres) {
    return Promise.resolve([]);
  }

  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: createProgramArchiveGenreRequest(epgGenres, filterGenres, channels),
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (response: ProgramArchiveBatchListResponse) {
      return response.responseList ? response.responseList : [];
    })
    .catch(fetchError);
}

export function getProgramsForGenre(
  epgGenreIds: string,
  orderType: string,
  filterGenres: Genre[],
  channels: ChannelFilter[],
  offset: number,
) {
  const count = 50;
  const newOffset = offset === 0 ? offset : offset * count;
  return fetch(getBaseUrl() + '/EPG/JSON/QueryPlaybillByFilter', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      orderType: orderType,
      type: 1,
      count: count,
      offset: newOffset,
      filterlist: createFilterList(epgGenreIds, filterGenres),
      begintime: '19700101010000',
      endtime: nowHuaweiFormat(),
      channelIDs: channels,
      properties: [
        {
          name: 'playbill',
          include: playBillInclude,
        },
      ],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: ProgramContentDetailResponse) => {
      return response.playbillList ? response.playbillList : [];
    })
    .catch(fetchError);
}

export function getProgramSeries(seriesIDs: string[]) {
  if (isEmpty(seriesIDs)) {
    return Promise.resolve({ responseList: [] });
  }
  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: createProgramArchiveSeriesRelationRequest(seriesIDs),
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function getNonCatchupProgramSeries(seriesId: string): Promise<Program[]> {
  return fetch(getBaseUrl() + '/EPG/JSON/PlayBillList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      type: 2, // type: number, "all live TV programs, including expired programs"
      filterlist: [{ key: 'SeriesId', value: seriesId }],
    }),
  })
    .then((r) => json(r))
    .then((response: NonCatchupSeriesResponse) => {
      return response.playbilllist ? response.playbilllist : [];
    })
    .catch(fetchError);
}
