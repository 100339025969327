import { useQuery } from 'react-query';
import { Bookmark } from '../../interfaces';
import { constructContinueWatchingCatchupList, getCatchupDetails } from './api';
import { catchupKeys } from './keys';

export function useContinueWatchingCatchup(bookmarks: Bookmark[]) {
  return useQuery(
    catchupKeys.bookmarks(bookmarks),
    () => getCatchupDetails(bookmarks).then((data) => constructContinueWatchingCatchupList(data, bookmarks)),
    {
      enabled: Boolean(bookmarks),
      keepPreviousData: true,
    },
  );
}
