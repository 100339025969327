import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgClock } from '../../icons';
import { Pvr } from '../../interfaces';
import { useChannel } from '../../queries/channels/queries';
import { PvrUtils } from '../../utils/pvrUtils';
import Badge from '../Badge';
import { RecordingModal } from './RecordingModal';
import styles from './styles/ScheduledAsset.module.scss';
import { Image } from '../Image';

interface PvrScheduledAssetProps {
  asset: Pvr.Recording;
  useSkeleton?: boolean;
}

export function PvrScheduledAsset({ asset, useSkeleton }: PvrScheduledAssetProps) {
  const [showModal, setShowModal] = useState(false);

  const { data: channel } = useChannel(asset.channelId);

  const { t } = useTranslation();

  function getTitle() {
    return `${asset.pvrName ?? asset.periodPVRTaskName}`;
  }

  function getSeasonAndEpisodeLabel() {
    if (!asset.seasonNum || !asset.subNum) {
      return null;
    }

    return `- S${asset.seasonNum} E${asset.subNum}`;
  }

  const isRecording = PvrUtils.isCurrentlyRecording(asset);

  if (useSkeleton) {
    return <PvrScheduledAssetSkeleton />;
  }

  const title = getTitle();

  return (
    <>
      <button
        className={styles.container}
        title={title}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <div className={styles.left}>
          {!isRecording && (
            <div className={styles.overlay}>
              <SvgClock />
            </div>
          )}
          <Image src={asset.pictures?.[0].href} crop={[256, 144]} alt={title} loading="lazy" />
        </div>
        <div className={styles.text}>
          <h4 className={styles.title}>{getTitle()}</h4>

          <p className={`${styles.subtitle} ${isRecording ? styles.recording : ''}`}>
            <span>{isRecording ? t('airing now') : PvrUtils.getRecordingDate(asset)}</span>
            <span className={styles.episode}>{getSeasonAndEpisodeLabel()}</span>
          </p>
        </div>
        <div className={styles.right}>
          {isRecording ? (
            <Badge text={t('is recording')} className="recording" />
          ) : (
            <img src={PvrUtils.getChannelLogo(channel)} alt={title} />
          )}
        </div>
      </button>

      <RecordingModal isOpen={showModal} hideModal={() => setShowModal(false)} recording={asset} />
    </>
  );
}

export function PvrScheduledAssetSkeleton() {
  return (
    <article className={styles.skeleton}>
      <div className={styles.left}></div>
      <div className={styles.text}>
        <div></div>
        <div></div>
      </div>
      <div className={styles.right}>
        <div></div>
      </div>
    </article>
  );
}
