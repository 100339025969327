import { ProgramArchiveReducerState, Program } from '../../../interfaces';

import { TypeKeys, ActionTypes } from '../actions';

export function programArchiveReducer(
  state: ProgramArchiveReducerState = {
    epgGenres: undefined,
    epgGenresList: undefined,
    currentGenreListIsLoading: false,
    currentProgramArchiveGenrePrograms: undefined,
    currentProgramArchiveAsset: undefined,
    mostWatchedCatchup: [],
  },
  action: ActionTypes,
): ProgramArchiveReducerState {
  switch (action.type) {
    case TypeKeys.SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST:
      if (state.currentProgramArchiveGenrePrograms && action.currentProgramArchiveGenrePrograms) {
        action.currentProgramArchiveGenrePrograms = state.currentProgramArchiveGenrePrograms.concat(
          action.currentProgramArchiveGenrePrograms,
        );
      }
      return {
        ...state,
        currentProgramArchiveGenrePrograms: action.currentProgramArchiveGenrePrograms,
      };
    case TypeKeys.SET_PROGRAM_ARCHIVE_GENRE_LIST:
      return {
        ...state,
        epgGenres: action.epgGenres,
      };
    case TypeKeys.SET_CURRENT_PROGRAM_ARCHIVE_ASSET:
      if (!action.currentProgramArchiveAsset) {
        return {
          ...state,
          currentProgramArchiveAsset: undefined,
        };
      } else {
        return {
          ...state,
          currentProgramArchiveAsset: {
            ...action.currentProgramArchiveAsset,
            asset: action.currentProgramArchiveAsset.asset ? action.currentProgramArchiveAsset.asset : ({} as Program),
          },
        };
      }
    case TypeKeys.SET_CURRENT_PROGRAM_ARCHIVE_GENRE_LIST_LOADING:
      return {
        ...state,
        currentGenreListIsLoading: action.currentGenreListIsLoading,
      };
    case TypeKeys.SET_PROGRAM_ARCHIVE_FRONT_PAGE_ASSETS:
      return {
        ...state,
        epgGenresList: action.epgGenresList,
      };
    case TypeKeys.SET_MOST_WATCHED_CATCHUP:
      return {
        ...state,
        mostWatchedCatchup: action.mostWatchedCatchup,
      };
    default:
      return state;
  }
}
