import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PvrLayout } from '../../../components/Pvr/Layout';
import { PvrSwimlane, PvrSwimlaneSkeleton } from '../../../components/Pvr/Swimlane';
import { useRecordingsSwimlanes } from '../../../queries/recordings/queries';
import { Recording } from '../../../queries/recordings/types';
import styles from './style.module.scss';

const SKELETON_TITLES = [
  Recording.Swimlane.Titles.AllRecordings,
  Recording.Swimlane.Titles.DeletingSoon,
  Recording.Swimlane.Titles.ContinueWatching,
];

export default function PvrFrontPage() {
  const { data: swimlanes, isLoading, isSuccess } = useRecordingsSwimlanes();
  const { t } = useTranslation('pvr');

  const isAllEmpty = useMemo(() => swimlanes.every((swimlane) => isEmpty(swimlane.groups)), [swimlanes]);

  return (
    <PvrLayout>
      <div className={styles.container}>
        {isLoading
          ? Array.from({ length: 3 }).map((_, i) => <PvrSwimlaneSkeleton key={i} title={SKELETON_TITLES[i]} />)
          : swimlanes.map(
              ({ title, groups, slug }) =>
                shouldShow(groups) && <PvrSwimlane key={slug} title={title} slug={slug} groups={groups} />,
            )}

        {isAllEmpty && !isLoading && isSuccess && (
          <div className={styles.container}>
            <p className={styles.empty}>{t('your recordings will be available here')}</p>
          </div>
        )}
      </div>
    </PvrLayout>
  );
}

function shouldShow(groups: Recording.Group.Union[] | undefined) {
  return groups && !isEmpty(groups);
}
