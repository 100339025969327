import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeleteLeftActions } from '../../../components/Pvr/DeleteActions';
import { PvrDeleteAsset } from '../../../components/Pvr/DeleteAsset';
import { DeleteMultipleEpisodesModal } from '../../../components/Pvr/DeleteModal';
import { PvrLayout } from '../../../components/Pvr/Layout';
import { SortDropdown } from '../../../components/SortDropdown';
import Spinner from '../../../components/Spinner';
import { useBlockNavigation } from '../../../hooks/useBlockNavigation';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { Bookmark } from '../../../interfaces';
import { useBookmarks } from '../../../queries/bookmarks/queries';
import { useRecordingEpisodes } from '../../../queries/recordings/queries';
import { Recording } from '../../../queries/recordings/types';
import { isSameInArray } from '../../../utils/commonUtils';
import { addMinutes, subtractMinutes, toMoment } from '../../../utils/huaweiUtils';
import { FormWrapper } from '../FormWrapper';
import styles from '../PvrDeleteSeries/style.module.scss';

const SORT_OPTIONS = [
  { label: 'newest', value: Recording.Sort.Action.Newest },
  { label: 'oldest', value: Recording.Sort.Action.Oldest },
  { label: 'A - Å', value: Recording.Sort.Action.Alphabetically },
];

export default function PvrDeleteEpisodes() {
  const { id, seasonNum } = useParams<{ id: string; seasonNum: string }>();
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0].value);
  const { data: season, isLoading } = useRecordingEpisodes(id, seasonNum, sortBy);

  const { t } = useTranslation('pvr');
  const { data: bookmarks } = useBookmarks();

  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedEpisodes, setSelectedEpisode] = useState<Recording.Query.Series[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useScrollToTop();
  useBlockNavigation(!selectionMode, {
    prompt: t('are you sure you want to cancel'),
  });

  function handleChange(episode: Recording.Query.Series) {
    if (isSameInArray(selectedEpisodes, episode, 'pvrId')) {
      return setSelectedEpisode((prev) => prev.filter((selected) => selected.pvrId !== episode.pvrId));
    }

    return setSelectedEpisode((prev) => [...prev, episode]);
  }

  function markAll() {
    if (season) {
      setSelectedEpisode(season.episodes);
    }
  }

  function clearAll() {
    setSelectedEpisode([]);
  }

  function deleteCallback() {
    clearAll();
    setIsModalOpen(false);
    setSelectionMode(false);
  }

  const leftActions = (
    <DeleteLeftActions
      onSetSelectionMode={setSelectionMode}
      markAllSelections={markAll}
      clearAllSelections={clearAll}
      openModal={() => setIsModalOpen(true)}
      selectionMode={selectionMode}
      selectedRecordings={selectedEpisodes}
      isAllSelected={selectedEpisodes.length === season?.episodes.length}
    />
  );

  const rightActions = <SortDropdown onChange={setSortBy} options={SORT_OPTIONS} />;

  const title = `${season?.pvrName} - Season ${seasonNum}`;

  return (
    <PvrLayout title={title} leftActions={leftActions} rightActions={rightActions}>
      <DeleteMultipleEpisodesModal
        name={season?.pvrName}
        seasonNum={seasonNum}
        selectedEpisodes={selectedEpisodes}
        isOpen={isModalOpen}
        hideModal={() => setIsModalOpen(false)}
        isAllSelected={selectedEpisodes.length === season?.episodes.length}
        callback={deleteCallback}
      />

      <div className={styles.container}>
        <FormWrapper
          selectionMode={selectionMode}
          shouldOpenModal={Boolean(selectedEpisodes.length)}
          openModal={() => setIsModalOpen(true)}
          onCancel={() => {
            setSelectionMode(false);
            clearAll();
          }}
        >
          {isLoading && <Spinner wrapInContainer />}

          {(isEmpty(season?.episodes) || !season) && !isLoading && (
            <div className={styles.container}>
              <p className={styles.empty}>{t('you have no recordings')}</p>
            </div>
          )}

          <ul className={styles.grid}>
            {season?.episodes.map((episode) => (
              <li key={episode.pvrId}>
                <PvrDeleteAsset
                  episode={episode}
                  selectionMode={selectionMode}
                  onChange={handleChange}
                  isSelected={isSameInArray(selectedEpisodes, episode, 'pvrId')}
                  percentWatched={getProgressPercent(episode, bookmarks)}
                  shouldGoBackOnDelete={season.episodes.length === 1}
                  type="episode"
                />
              </li>
            ))}
          </ul>

          <button type="submit" className={styles.submit}>
            Submit
          </button>
        </FormWrapper>
      </div>
    </PvrLayout>
  );
}

function getProgressPercent(episode: Recording.Query.Series, bookmarks: Bookmark[] | undefined) {
  const bookmark = bookmarks?.find((mark) => mark.contentId === episode.pvrId);
  if (bookmark?.rangeTime) {
    const timeUnit = getTimeUnit(episode);
    return Number(bookmark.rangeTime) / timeUnit;
  }
  return 0;
}

function getTimeUnit(episode: Recording.Query.Series) {
  if (!episode.beginTime || !episode.endTime) {
    return 0;
  }

  let programStart = subtractMinutes(episode.beginTime, Number(episode.beginOffset));
  let programEnd = addMinutes(episode.endTime, Number(episode.endOffset));
  const durationInSeconds = toMoment(programEnd).diff(toMoment(programStart), 'seconds');
  return durationInSeconds / 100;
}
