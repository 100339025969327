import {
  EpgGenre,
  Title,
  Program,
  AltiboxAsset,
  Channel,
  AltiboxAssetType,
  Bookmark,
  AuthReducerState,
  ChannelLogoType,
} from '../interfaces';
import i18n from '../i18n';
import { disneyChannels, routes } from '../config';
import { toMomentReadableDateString } from './huaweiUtils';
import moment from 'moment';

import { toMoment } from './huaweiUtils';
import { getPercentWatchedCatchup } from './catchupUtils';
import channelPermissions from './channelPermissions';
import { catchupAvailable, getChannelLogoUrl } from './tvUtils';
import { ScriptService } from '../controllers/ScriptService';

export function isDisneyChannel(channel: Channel): boolean {
  return disneyChannels[ScriptService.getLocale()].some((disneyChannel) => disneyChannel === channel.contentId);
}

export function appendFavouriteFlags(assetList: AltiboxAsset[], favouriteIds: string[]) {
  if (assetList) {
    let assets = assetList.slice(0);
    if (favouriteIds.length > 0) {
      assets.forEach((altiboxAsset: AltiboxAsset) => {
        if (altiboxAsset.asset) {
          let tempAssetProgram = altiboxAsset.asset as Program;
          const foundInFavouritesIds = favouriteIds.indexOf(altiboxAsset.asset.id as string) !== -1;
          const foundinFavoureiteSeriesIds = tempAssetProgram.seriesID
            ? favouriteIds.indexOf(tempAssetProgram.seriesID) !== -1
            : false;
          altiboxAsset.asset.isfavorited = foundInFavouritesIds || foundinFavoureiteSeriesIds ? '1' : '0';
        }
      });
    }
    return assets;
  } else {
    return assetList;
  }
}

export function getProgramArchiveGenreTitle(genres: EpgGenre[], genreIds: string, orderType: string) {
  let filterdGenres = genres.filter((genre) => {
    let filterIds = genre.genreIds.replace(/\s/g, '');
    let filterOrderId = genre.orderType;

    if (filterIds === genreIds && filterOrderId === orderType) {
      return genre;
    }
    return undefined;
  });
  let title = undefined;
  if (filterdGenres.length > 0 && filterdGenres[0]) {
    title = getCurrentLangTitle(filterdGenres[0].title);
  }
  return title;
}

function getCurrentLangTitle(title: Title) {
  if (i18n && i18n.language && title[i18n.language.split('-')[0]]) {
    return title[i18n.language.split('-')[0]];
  }
  return title.nb || '';
}

export function addLockedSymbolOnInsideOnlyChannelPrograms(programs: AltiboxAsset[], isHome: boolean) {
  let alteredPrograms = [...programs];
  alteredPrograms = alteredPrograms.map((x) => {
    let asset = x.asset! as Program;
    if (asset.channel!.channelPermissions.onlyInsideForCatchup && !isHome) {
      x.isLockedForOutsideHomePlayback = true;
    }
    return x;
  });

  return alteredPrograms;
}

export function getSearchCatchupAsset(
  program: Program,
  channels: Channel[],
  type: AltiboxAssetType = AltiboxAssetType.CATCHUP,
): AltiboxAsset {
  let asset = getCatchupAsset(program, channels);
  // if the asset is NOT in catchup, we can link them to catchup SINGLE asset.
  if ((asset.asset as Program).istvod === '0') {
    asset.detailsLink =
      routes.programarchive.base + routes.programarchive.details + routes.programarchive.single + '/' + asset.asset.id;
  }
  if (type === AltiboxAssetType.CATCHUP) {
    asset.title = program.name ? program.name : program.subName ? program.subName : program.name;
    asset.subtitle = i18n.t<string>('watch in programarchive');
  } else if (type === AltiboxAssetType.TVGUIDE) {
    asset.subtitle = i18n.t<string>('watch on tv') + ' ' + asset.subtitle;
  }
  return asset;
}

export function getCatchupAssetWithLock(
  program: Program,
  channels: Channel[],
  authState: AuthReducerState,
  bookmark?: Bookmark,
  continueWatching?: boolean,
): AltiboxAsset {
  let asset = getCatchupAsset(program, channels, bookmark, continueWatching);
  const programAsset = asset.asset as Program;
  const channel = programAsset.channel;
  if (channel) {
    let extChannelPermissions = channelPermissions(channel, authState);
    asset.isLockedForOutsideHomePlayback = extChannelPermissions.outsideHome;
    asset.isGeoLockedAtUserLocation = extChannelPermissions.geoLocked;
  } else {
    asset.isLockedForOutsideHomePlayback = false;
    asset.isGeoLockedAtUserLocation = false;
  }
  return asset;
}

export function getCatchupAsset(
  program: Program,
  channels: Channel[],
  bookmark?: Bookmark,
  continueWatching?: boolean,
): AltiboxAsset {
  const channel = channels.find((x) => x.contentId === program.channelid) as Channel;
  const channelLogo = getChannelLogoUrl(channel, ChannelLogoType.DEFAULT);
  const croppedChannelLogo = getChannelLogoUrl(channel, ChannelLogoType.CROPPED);
  const link = program.seriesID
    ? `${routes.programarchive.base}${routes.programarchive.details}${routes.programarchive.series}/${program.seriesID}/${program.id}`
    : `${routes.programarchive.base}${routes.programarchive.details}${routes.programarchive.single}/${program.id}`;

  const playLink = program.id ? `${routes.programarchive.base}${routes.programarchive.play}/${program.id}` : '';
  const genre = program.genres ? program.genres.split(',')[0] : '';
  if (channel) {
    program.channel = channel;
  }
  let percentWatched = 0;
  if (bookmark) {
    percentWatched = getPercentWatchedCatchup(program, bookmark);
  }
  let episodeSeasonTag =
    (program.seasonNum ? ' S' + program.seasonNum : '') + (program.subNum ? ' E' + program.subNum : '');
  let title = program.name;
  let hasEpisodeSeasonTag = episodeSeasonTag.length > 0;
  let subtitle = toMomentReadableDateString(program.starttime);
  if (continueWatching) {
    if (program.seasonNum) {
      subtitle = `${hasEpisodeSeasonTag ? episodeSeasonTag + ` - ` : ''} ${subtitle}`;
    } else {
      subtitle = `${genre} - ${hasEpisodeSeasonTag ? episodeSeasonTag + ` - ` : ''} ${subtitle}`;
    }
  }

  return {
    id: program.id,
    progress: percentWatched,
    description: program.introduce,
    type: AltiboxAssetType.CATCHUP,
    brandLogo: channelLogo,
    croppedBrandLogo: croppedChannelLogo,
    picture: program.picture ? program.picture.deflate : '',
    coverImage: program.picture ? program.picture.poster : '',
    title: title,
    episodeTitle: program.subName,
    episodeSeasonTag: episodeSeasonTag,
    subtitle: subtitle,
    genre: genre,
    weight: 1,
    playLink: playLink,
    detailsLink: link,
    asset: program,
  };
}

export function getDuration(asset: AltiboxAsset | Program, isProgram?: boolean, asSeconds?: boolean) {
  const program = isProgram ? (asset as Program) : ((asset as AltiboxAsset).asset as Program);
  if (program.endtime && program.starttime) {
    return toMoment(program.endtime).diff(toMoment(program.starttime), asSeconds ? 'seconds' : 'minutes');
  } else {
    return 'NA';
  }
}

export function programExpiresIn(program: Program) {
  let language;

  if (i18n.language.includes('en')) {
    language = 'en-gb';
  } else if (i18n.language.includes('no') || i18n.language.includes('nb-NO')) {
    language = 'nb';
  } else if (i18n.language.includes('da') || i18n.language.includes('da-DK')) {
    language = 'da';
  } else {
    language = i18n.language;
  }

  const timeToLive = catchupAvailable(program)
    ? program.channel && program.channel.channelPermissions.catchupTimeToLive
      ? program.channel.channelPermissions.catchupTimeToLive
      : '604800' // 604800/60/60/24 = 7 days
    : '0';

  moment.locale(language);

  moment.relativeTimeRounding(Math.round);
  moment.relativeTimeThreshold('ss', 0);
  moment.relativeTimeThreshold('s', 0);
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 25);
  moment.relativeTimeThreshold('d', 8);
  moment.relativeTimeThreshold('w', 4);
  const endTime = toMoment(program.starttime).unix();

  let resultingTime = Number(endTime + Number(timeToLive));

  moment.updateLocale(language, {
    relativeTime: {
      m: `%d ${i18n.t<string>('minutes single')}`,
      mm: `%d ${i18n.t<string>('minutes no abrv')}`,
      h: `%d ${i18n.t<string>('hour')}`,
      d: `%d ${i18n.t<string>('day')}`,
    },
  });

  return moment.unix(resultingTime < 0 ? 0 : resultingTime).fromNow();
}

export function nonCULiveProgramExpiresIn(program: Program): number | string {
  const duration = getDuration(program, true);
  const elapsedTime = elapsedTimeFromStarttime(program);

  if (typeof duration === 'number') {
    return duration - elapsedTime;
  }
  return '';
}

/**
 * @param program
 * @returns elapsed time from start time in minutes
 */
export function elapsedTimeFromStarttime(program: Program) {
  const { starttime } = program;
  const startTimeMoment = toMoment(starttime);
  return moment.duration(moment().diff(startTimeMoment)).minutes();
}

/**
 * Checks if a program is valid for deep linking based on the `VALID_FROM` and `VALID_TO` extension info
 * @param program
 */
export function isDeepLinkValid(program: Pick<Program, 'extensionInfo'>) {
  const validFromUTC = program.extensionInfo.find((info) => info.key === 'VALID_FROM')?.value + '+00:00';
  const validToUTC = program.extensionInfo.find((info) => info.key === 'VALID_TO')?.value + '+00:00';

  const validFrom = moment(validFromUTC).local();
  const validTo = moment(validToUTC).local();

  const now = moment();

  return now.isAfter(validFrom) && now.isBefore(validTo);
}
