import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AltiboxAsset, AltiboxAssetType, VodAssetDisplayMode, VodAsset, ContentType } from '../../../interfaces';
import './style.scss';
import FavouriteIcon from '../../../components/FavouriteIcon';
import Badge from '../../../components/Badge';
import i18n from '../../../i18n';
import {
  assetIsBought,
  assetShouldShowBadge,
  Owned,
  isMovie,
  getRentBadgeTextWithInformation,
  getLowestRentedEpisodeCountdown,
  assetIsRented,
  isSeries,
} from '../../../utils/vodUtils';
import { isNull } from 'util';
import { getImageUrlDimensions, getPicture } from '../../../utils/altiboxassetUtils';
import { imageScaleValues } from '../../../config';
import { assetIsVod } from '../../../typeGuards';

import missingCoverUrl from './missing-cover.jpeg';
import missingVodUrl from '../../VodAsset/missing-vod-cover.png';

interface Props {
  asset: AltiboxAsset;
  showNumbering?: boolean;
  shouldNotGoToDetails?: boolean;
  loggedInWithCredentials?: boolean;
  favouriteView?: boolean;
  vodDisplayMode?: VodAssetDisplayMode;
  hidePurchasedBadge?: boolean;
  isCW?: boolean;
  gridView?: boolean;
  myContent?: VodAsset[];
}

interface State {
  posterURL: string;
  failedToLoadDoNotLoadAgain: boolean;
}

class AltiboxAssetView extends Component<Props, State> {
  state: State = {
    posterURL: missingCoverUrl,
    failedToLoadDoNotLoadAgain: false,
  };

  get placeholder() {
    switch (this.props.vodDisplayMode) {
      case VodAssetDisplayMode.SVOD:
        return missingCoverUrl;
      case VodAssetDisplayMode.VOD:
        return missingVodUrl;
      default:
        return missingCoverUrl;
    }
  }

  get posterURL() {
    const posterUrl = getPicture(this.props.asset.picture);
    if (posterUrl) {
      switch (this.props.vodDisplayMode) {
        case VodAssetDisplayMode.VOD:
          return getImageUrlDimensions(posterUrl, imageScaleValues.vod);
        case VodAssetDisplayMode.SVOD:
          return getImageUrlDimensions(posterUrl, imageScaleValues.altiboxAssetView);
        default:
          switch (this.props.asset.type) {
            case AltiboxAssetType.VOD:
              if (this.props.isCW || this.props.favouriteView || this.props.gridView) {
                return getImageUrlDimensions(posterUrl, imageScaleValues.altiboxAssetView);
              }
              return getImageUrlDimensions(posterUrl, imageScaleValues.vod);
            case AltiboxAssetType.SVOD:
            case AltiboxAssetType.CATCHUP:
            case AltiboxAssetType.PVR:
              return getImageUrlDimensions(posterUrl, imageScaleValues.altiboxAssetView);
          }
      }
    }
    return this.placeholder;
  }

  UNSAFE_componentWillMount() {
    this.setState({
      posterURL: this.placeholder,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.state.posterURL !== this.placeholder) {
      if (nextProps.asset.picture !== this.state.posterURL) {
        this.setState({ posterURL: this.posterURL });
      }
    }
  }

  assetHasProgress = (asset: AltiboxAsset): boolean => {
    return Boolean(asset.progress && asset.progress !== 0);
  };

  // after placeholder is loaded load the poster or ad img based on width of screen
  handlePlaceholderLoaded = () => {
    if (this.props.asset.picture === undefined) {
      this.setState({
        posterURL: this.placeholder,
      });
    } else if (!this.state.failedToLoadDoNotLoadAgain) {
      this.setState({ posterURL: this.posterURL });
    }
  };

  handleLoadingError = () => {
    if (!this.state.failedToLoadDoNotLoadAgain) {
      this.setState({
        failedToLoadDoNotLoadAgain: true,
        posterURL: this.placeholder,
      });
    }
  };

  wrapLink(content: JSX.Element, linkType: string, className: string) {
    const asset = this.props.asset;
    return (
      <Link
        draggable="false"
        className={className}
        to={{
          pathname: linkType === 'play' ? asset.playLink : asset.detailsLink,
          state: { prevPath: window.location.pathname },
        }}
      >
        {content}
      </Link>
    );
  }

  renderBrandLogo = (asset: AltiboxAsset) => {
    const { croppedBrandLogo, brandLogo, hideBrandLogo, title } = asset;
    switch (asset.type) {
      case AltiboxAssetType.CATCHUP:
      case AltiboxAssetType.PROGRAM:
        if (croppedBrandLogo) {
          return <img className="cropped-logo" src={croppedBrandLogo} alt={title} />;
        }
        return <img className="logo" src={brandLogo} alt={title} />;
      case AltiboxAssetType.PVR:
        return <div className="aib-icon asset-brand-icon">7</div>;
      case AltiboxAssetType.SVOD:
      case AltiboxAssetType.VOD:
        if (croppedBrandLogo) {
          return <img className="cropped-logo" src={croppedBrandLogo} alt={title} />;
        } else if (brandLogo) {
          return <img className="logo" src={brandLogo} alt={title} />;
        } else if (hideBrandLogo) {
          return null;
        } else {
          return <div className="aib-icon asset-brand-icon">N</div>;
        }
      default:
        return '';
    }
  };

  getBadge = (altiboxAsset: AltiboxAsset) => {
    const { asset } = altiboxAsset;
    const {
      isCW,
      asset: { type },
    } = this.props;
    const forceBought = this.forceDisplayBought(altiboxAsset);
    if (!isNull(forceBought)) {
      return forceBought;
    }
    if (asset && assetIsVod(asset)) {
      let badge = assetShouldShowBadge(asset);
      if (asset.hideBoughtForCW) {
        return null;
      }

      if (badge) {
        if (badge === Owned.RENTED) {
          if (asset.listOfRentedEpisodes && asset.listOfRentedEpisodes.length === 1) {
            const lowestRentPeriod = getLowestRentedEpisodeCountdown(asset.listOfRentedEpisodes);
            return <Badge className="rent" text={getRentBadgeTextWithInformation(lowestRentPeriod.rentPeriod)} />;
          } else if (isCW || isMovie(asset)) {
            return <Badge className="rent" text={getRentBadgeTextWithInformation(asset.rentperiod)} />;
          }
          return <Badge className="rent" text={i18n.t<string>('rented')} />;
        } else if (badge === Owned.BOUGHT) {
          return <Badge className="bought" text={i18n.t('bought')} />;
        } else if (type === AltiboxAssetType.VOD && Number(asset.price) >= 0) {
          return <Badge className="price" text={`${Math.floor(+asset.price)},-`} />;
        } else {
          return null;
        }
      }
    }
    return null;
  };

  forceDisplayBought = (altiboxAsset: AltiboxAsset) => {
    const { asset } = altiboxAsset;
    if (asset && assetIsVod(asset)) {
      return assetIsBought(asset, this.props.myContent) &&
        !this.props.hidePurchasedBadge &&
        !asset.hideBoughtForCW &&
        this.props.vodDisplayMode !== VodAssetDisplayMode.SVOD &&
        altiboxAsset.type !== (AltiboxAssetType.SVOD as {} as ContentType) ? (
        <Badge className="bought" text={i18n.t<string>('bought')} />
      ) : null;
    } else {
      return null;
    }
  };

  assetSubtitle() {
    const asset = this.props.asset;
    const vodAssetFavoriteView = asset.type === AltiboxAssetType.VOD && this.props.favouriteView;

    if (vodAssetFavoriteView && (assetIsRented(asset.asset as VodAsset) || assetIsBought(asset.asset as VodAsset))) {
      return '';
    }
    if (vodAssetFavoriteView && (isSeries(asset.asset as VodAsset) || isMovie(asset.asset as VodAsset))) {
      return asset.genre;
    }

    return asset.subtitle;
  }

  render() {
    const asset = this.props.asset;
    const title = asset.title + (this.props.showNumbering ? ' ' + asset.episodeSeasonTag : '');

    let showFavouriteIcon = null;

    if (this.props.loggedInWithCredentials) {
      if (this.props.favouriteView) {
        showFavouriteIcon = <FavouriteIcon isFavourite={true} />;
      } else {
        showFavouriteIcon = <FavouriteIcon isFavourite={Boolean(Number(asset.asset!.isfavorited))} />;
      }
    }

    let showPlayIcon = false;
    let showHoverGlow = true;

    if (this.props.shouldNotGoToDetails) {
      showPlayIcon = true;
      showHoverGlow = false;
      if (asset.isLockedForOutsideHomePlayback) {
        showPlayIcon = false;
        showHoverGlow = true;
      }
    }

    let altiboxAssetClassType = 'altibox-asset';
    if (this.props.vodDisplayMode) {
      altiboxAssetClassType += ' ' + this.props.vodDisplayMode;
    } else if (this.assetHasProgress(asset)) {
      altiboxAssetClassType += ' with-progress';
    }

    let displayModeVod = this.props.vodDisplayMode === VodAssetDisplayMode.VOD;
    let altiboxAssetTitleClassName = displayModeVod ? 'asset-title text-center' : 'asset-title';

    return (
      <div
        onMouseDown={(e) => {
          // Prevent image dragging for firefox.
          e.preventDefault();
        }}
        className={altiboxAssetClassType}
        title={title + (asset.subtitle ? ' - ' + asset.subtitle : '')}
      >
        {this.wrapLink(
          <>
            {this.getBadge(asset)}
            <div className={'asset-image-container' + (showHoverGlow ? ' aib-hover-glow' : '')}>
              {showFavouriteIcon}
              {showPlayIcon ? (
                <div className="hover-overlay">
                  <div className="aib-icon">z</div>
                </div>
              ) : null}
              {this.props.asset.isLockedForOutsideHomePlayback ? <span className="aib-icon lock">p</span> : null}
              <img
                draggable="false"
                className="asset-image fade-in"
                alt={title}
                src={this.state.posterURL}
                onError={this.handleLoadingError}
                onLoad={this.handlePlaceholderLoaded}
                loading="lazy"
              />
              <div className="logo-container">{this.renderBrandLogo(asset)}</div>
            </div>
            {this.assetHasProgress(asset) ? (
              <div className="progress-wrapper">
                <progress value={asset.progress / 100} />
              </div>
            ) : null}
          </>,
          this.props.shouldNotGoToDetails ? 'play' : 'details',
          'altibox-asset-image',
        )}
        <div className="asset-title-container">
          {this.wrapLink(
            <div className={altiboxAssetTitleClassName}>
              <div title={title} className={`asset-title-text`}>
                <div className={'title-name'}>{title}</div>
              </div>
              <div className="asset-title-genre">{this.assetSubtitle()}</div>
            </div>,
            'details',
            'altibox-asset-title',
          )}
        </div>
      </div>
    );
  }
}

export default AltiboxAssetView;
