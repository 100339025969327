import React, { Component } from 'react';
import './style.scss';
import MobileDetect from 'mobile-detect';
import i18n from '../../../i18n';
import { androidAppId, iosAppUrl } from '../../../config';

interface Props {
  deepLink: string;
  useApp?: boolean;
}

export default class MobileLock extends Component<Props> {
  get appstoreUrl() {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    switch (mobileDetect.os()) {
      case 'AndroidOS':
        return `market://search?q=pname:${androidAppId}`;
      case 'iOS':
        return iosAppUrl;
      default:
        return undefined;
    }
  }

  componentDidMount() {
    this.fakeDeepLinkClick();
  }

  // this function will click the watch in app.
  // if not installed, nothing happens
  fakeDeepLinkClick() {
    const clickTarget = document.getElementById('deeplink');
    const fakeMouseEvent = document.createEvent('MouseEvents');
    fakeMouseEvent.initMouseEvent('click', true, true, window, 0, 0, 0, 20, 10, false, false, false, false, 0, null);
    if (clickTarget) {
      clickTarget.dispatchEvent(fakeMouseEvent);
    }
  }

  openAppLink(title: string, icon: string) {
    return (
      <a href={this.props.deepLink} id="deeplink" className="button mobileButton">
        <span className="text">{title}</span>
        <span className="aib-icon">{icon}</span>
      </a>
    );
  }

  render() {
    return (
      <div id="mobileLock">
        <p style={{ maxWidth: '300px', marginLeft: '0' }}>{i18n.t<string>('service unavailable for mobile')}</p>
        {this.props.useApp
          ? this.openAppLink(i18n.t<string>('open app'), 'q')
          : this.openAppLink(i18n.t<string>('watch in app'), 'z')}
        <a href={this.appstoreUrl} className="button mobileButton">
          <span className="aib-icon">/</span>
          <span className="text">{i18n.t<string>('install app')}</span>
        </a>
      </div>
    );
  }
}
