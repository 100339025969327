import { Channel, AuthReducerState } from '../interfaces';
import { isFakeHome } from '../config';

export interface ChannelPermissionResults {
  locked: boolean;
  geoLocked: boolean;
  outsideHome: boolean;
  notInSubscription: boolean;
  onlyInsideForCatchup: boolean;
}

// Will return true if the channel is not allowed to be shown in the user's current location
function isGeoLocked(channel: Channel, userLocation: string | undefined) {
  const allowedLocations = channel.locationCopyrights;
  // If no allowed locations are defined the channel can be seen anywhere
  if (!allowedLocations) {
    return false;
  }

  if (!userLocation) {
    return true;
  }

  return allowedLocations.indexOf(userLocation) === -1;
}

// Will return true if the channel is only allowed to be shown inside the user's home AND the user is not home
function userOutsideHome(channel: Channel, userIsHome: boolean) {
  const { onlyInsideforLiveTV } = channel.channelPermissions;
  if (isFakeHome) {
    // just for testing
    return false;
  } else {
    return onlyInsideforLiveTV && !userIsHome;
  }
}

function userOutsideHomeForCatchup(channel: Channel, userIsHome: boolean) {
  const { onlyInsideForCatchup } = channel.channelPermissions;
  return onlyInsideForCatchup && !userIsHome;
}

// returns if the channel is locked and if so which rules were broken
export default function channelPermissions(
  channel: Channel | undefined,
  authState: AuthReducerState,
): ChannelPermissionResults {
  if (!channel) {
    return {
      locked: false,
      geoLocked: false,
      outsideHome: false,
      notInSubscription: false,
      onlyInsideForCatchup: false,
    };
  }

  const userLocation = authState.auth ? authState.auth.location : undefined;
  const geoLocked = isGeoLocked(channel, userLocation);
  const outsideHome = userOutsideHome(channel, authState.isHome);
  const notInSubscription = channel.channelPermissions.notInSubscription;
  const onlyIptvChannel = channel.channelPermissions.onlyIptvChannel;
  const onlyInsideForCatchup = userOutsideHomeForCatchup(channel, authState.isHome);
  return {
    locked: geoLocked || outsideHome || notInSubscription || onlyIptvChannel,
    geoLocked,
    outsideHome,
    notInSubscription,
    onlyInsideForCatchup,
  };
}
