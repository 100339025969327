/* eslint-disable */
import shaka from 'shaka-player';
import { unescape } from 'lodash';

var imageCaptionContainerInner, captionContainerInner, video;

shaka.text.AibSimpleTextDisplayer = function (_video, _imageTrackInner, _texttrackInner) {
  imageCaptionContainerInner = _imageTrackInner;
  imageCaptionContainerInner.innerHTML = '';
  captionContainerInner = _texttrackInner;
  captionContainerInner.innerHTML = '';
  video = _video;

  /** @private {TextTrack} */
  this.textTrack_ = null;
  // TODO: Test that in all cases, the built-in CC controls in the video element
  // are toggling our TextTrack.
  // If the video element has TextTracks, disable them.  If we see one that
  // was created by a previous instance of Shaka Player, reuse it.

  for (let i = 0; i < video.textTracks.length; ++i) {
    let track = video.textTracks[i];
    track.mode = 'disabled';
    if (track.label == shaka.text.AibSimpleTextDisplayer.TextTrackLabel_) {
      this.textTrack_ = track;
    }
  }

  if (!this.textTrack_) {
    // As far as I can tell, there is no observable difference between setting
    // kind to 'subtitles' or 'captions' when creating the TextTrack object.
    // The individual text tracks from the manifest will still have their own
    // kinds which can be displayed in the app's UI.
    this.textTrack_ = video.addTextTrack('subtitles', shaka.text.AibSimpleTextDisplayer.TextTrackLabel_);
  }
  this.textTrack_.mode = 'hidden';
};

/**
 * @override
 * @export
 */
shaka.text.AibSimpleTextDisplayer.prototype.remove = function (start, end) {
  // Check that the displayer hasn't been destroyed.
  if (!this.textTrack_) return false;
  let removeInRange = (cue) => {
    const inside = cue.startTime < end && cue.endTime > start;
    return inside;
  };
  shaka.text.AibSimpleTextDisplayer.removeWhere_(this.textTrack_, removeInRange);
  return true;
};

/**
 * @override
 * @export
 */
shaka.text.AibSimpleTextDisplayer.prototype.append = function (cues) {
  const convertToTextTrackCue = shaka.text.AibSimpleTextDisplayer.convertToTextTrackCue_;
  // Convert cues.
  let textTrackCues = [];
  for (let i = 0; i < cues.length; i++) {
    let cue = convertToTextTrackCue(cues[i]);
    if (cue) {
      textTrackCues.push(cue);
    }
  }
  // Sort the cues based on start/end times.  Make a copy of the array so
  // we can get the index in the original ordering.  Out of order cues are
  // rejected by IE/Edge.  See https://bit.ly/2K9VX3s
  let sortedCues = textTrackCues.slice().sort(function (a, b) {
    if (a.startTime != b.startTime) {
      return a.startTime - b.startTime;
    } else if (a.endTime != b.endTime) {
      return a.endTime - b.startTime;
    } else {
      // The browser will display cues with identical time ranges from the
      // bottom up.  Reversing the order of equal cues means the first one
      // parsed will be at the top, as you would expect.
      // See https://github.com/google/shaka-player/issues/848 for more info.
      return textTrackCues.indexOf(b) - textTrackCues.indexOf(a);
    }
  });
  sortedCues.forEach(
    function (cue) {
      this.textTrack_.addCue(cue);
    }.bind(this),
  );
};

/**
 * @override
 * @export
 */
shaka.text.AibSimpleTextDisplayer.prototype.destroy = function () {
  if (this.textTrack_) {
    let removeIt = (cue) => true;
    shaka.text.AibSimpleTextDisplayer.removeWhere_(this.textTrack_, removeIt);
  }
  this.textTrack_ = null;
  return Promise.resolve();
};

/**
 * @override
 * @export
 */
shaka.text.AibSimpleTextDisplayer.prototype.isTextVisible = function () {
  return this.textTrack_.mode == 'showing';
};

/**
 * @override
 * @export
 */
shaka.text.AibSimpleTextDisplayer.prototype.setTextVisibility = function (on) {
  this.textTrack_.mode = on ? 'showing' : 'hidden';
};

/**
 * @param {!shaka.extern.Cue} shakaCue
 * @return {TextTrackCue}
 * @private
 */
shaka.text.AibSimpleTextDisplayer.convertToTextTrackCue_ = (shakaCue) => {
  if (shakaCue.startTime >= shakaCue.endTime) {
    // IE/Edge will throw in this case.
    // See issue #501
    console.log('Invalid cue times: ' + shakaCue.startTime + ' - ' + shakaCue.endTime);
    return null;
  }

  const createShakaCuePayload = (nestedCues) => {
    return nestedCues
      .map((x) => x.payload)
      .filter((x) => x !== '' || x !== ' ')
      .join(' ');
  };

  const hasNestedCues = shakaCue.nestedCues && shakaCue.nestedCues.length > 0;
  if (shakaCue.payload === '' && hasNestedCues) {
    shakaCue.payload = createShakaCuePayload(shakaCue.nestedCues);
  }

  // TTML subtitles may have nested nested cues
  const hasNestedNestedCues =
    shakaCue.nestedCues[0] && shakaCue.nestedCues[0].nestedCues && shakaCue.nestedCues[0].nestedCues.length > 0;

  if (shakaCue.payload === '' && hasNestedNestedCues) {
    shakaCue.payload = createShakaCuePayload(shakaCue.nestedCues[0].nestedCues);
  }

  // Future-proofing in case of nested nested nested cues for TTML subtitles :)
  const hasNestedNestedNestedCues =
    shakaCue.nestedCues[0] &&
    shakaCue.nestedCues[0].nestedCues[0] &&
    shakaCue.nestedCues[0].nestedCues[0].nestedCues.length > 0;

  if (shakaCue.payload === '' && hasNestedNestedNestedCues) {
    shakaCue.payload = createShakaCuePayload(shakaCue.nestedCues[0].nestedCues[0].nestedCues);
  }

  let vttCue = new VTTCue(shakaCue.startTime, shakaCue.endTime, '');
  if (shakaCue.backgroundImage) {
    vttCue.onenter = () => {
      imageCaptionContainerInner.innerHTML = '<img src="' + shakaCue.backgroundImage + '" />';
    };
    vttCue.onexit = () => {
      imageCaptionContainerInner.innerHTML = '';
    };
  } else {
    vttCue.onenter = () => {
      if (captionContainerInner.innerHTML.indexOf(shakaCue.payload) === -1) {
        var containerNode = document.createElement('div');
        var spanNode = document.createElement('span');
        var textNode = document.createTextNode(unescape(shakaCue.payload));
        containerNode.appendChild(spanNode);
        spanNode.appendChild(textNode);
        captionContainerInner.prepend(containerNode);
      }
    };

    vttCue.onexit = () => {
      captionContainerInner.innerHTML = '';
    };
  }
  return vttCue;
};

/**
 * Iterate over all the cues in a text track and remove all those for which
 * |predicate(cue)| returns true.
 *
 * @param {!TextTrack} track
 * @param {function(!TextTrackCue):boolean} predicate
 * @private
 */
shaka.text.AibSimpleTextDisplayer.removeWhere_ = function (track, predicate) {
  // Since |track.cues| can be null if |track.mode| is "disabled", force it to
  // something other than "disabled".
  //
  // If the track is already showing, then we should keep it as showing. But if
  // it something else, we will use hidden so that we don't "flash" cues on the
  // screen.
  let oldState = track.mode;
  let tempState = oldState == 'showing' ? 'showing' : 'hidden';
  track.mode = tempState;

  // Go backward so that if a removal is done, it should not cause problems
  // with future indexing. In the case that the underlying implementation
  // returns a copy (and not a shared instance) cache a copy of the tracks.
  let cues = track.cues;
  for (let i = cues.length - 1; i >= 0; i--) {
    let cue = cues[i];
    if (cue && predicate(cue)) {
      track.removeCue(cue);
    }
  }
  track.mode = oldState;
};

/**
 * @const {string}
 * @private
 */
shaka.text.AibSimpleTextDisplayer.TextTrackLabel_ = 'Altibox';
export default shaka.text.AibSimpleTextDisplayer;

/* eslint-enable */
