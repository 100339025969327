import { Program as ProgramType, Channel } from '../../../interfaces';
import { Link } from 'react-router-dom';
import i18n from '../../../i18n';
import { getLockedMessage } from '../../../utils/tvUtils';
import { hasPicture, getImageUrlDimensions } from '../../../utils/altiboxassetUtils';
import { imageScaleValues } from '../../../config';
import imageMissing from './programbilde_mangler_v2.png';
import ProgramHeader from './PlaybillComponents/ProgramHeader';
import Badge from '../../Badge';
import { useMemo } from 'react';
import { useProgram } from '../../../hooks/useProgram';

interface Props extends ProgramType {
  isRecording: boolean;
  channel: Channel;
  channelIsLocked: boolean;
  isGuest: boolean;
  hasPvr: boolean;
  loggedInWithCredentials: boolean;
  programSet: boolean;
  showDetailsPanel: (program: ProgramType) => void;
  trackPlay: (channel: string, seasonAndEpisode: string, programName: string, fromStart?: boolean) => void;
  filterActive: boolean;
  markers: JSX.Element;
}

export default function LiveProgram(props: Props) {
  const program = useProgram(props);

  const liveUrl = `/tv/${program.channelid}`;

  function showProgramDetails() {
    program.showDetailsPanel(props);
  }

  function trackProgram(channel: string, name: string, fromStart?: boolean) {
    const seasonAndEpisode = program.subNum && program.seasonNum ? ` S${program.seasonNum} E${program.subNum}` : '';
    program.trackPlay(channel, seasonAndEpisode, name, fromStart);
  }

  let className = 'tvGuideplaybillProgram live' + (program.filterActive ? ' filter-active' : '');
  return (
    <li className={className}>
      {program.isRecording ? <Badge className="recording" text={i18n.t<string>('is recording')} /> : null}

      <ProgramCard {...program} liveUrl={liveUrl} progress={program.getProgress()} trackProgram={trackProgram} />
      <ProgramHeader
        className="live"
        showProgramDetailsCallback={showProgramDetails}
        startTime={program.startTime}
        name={program.name}
        episodeNumber={program.episodeNumber}
        markers={
          <PlayAndMarkers {...program} liveUrl={liveUrl} progress={program.getProgress()} trackProgram={trackProgram} />
        }
      />
    </li>
  );
}

interface ProgramCardProps extends Props {
  liveUrl: string;
  progress: number;
  trackProgram(channel: string, name: string, fromStart?: boolean | undefined): void;
}

function ProgramCard(props: ProgramCardProps) {
  if (props.channelIsLocked) {
    return (
      <div className="image">
        <LiveImage {...props} />
      </div>
    );
  }

  return (
    <Link
      className="image"
      to={{ pathname: props.liveUrl, state: { prevPath: window.location.pathname } }}
      onClick={() => props.trackProgram(props.channel.name, props.name)}
    >
      <LiveImage {...props} />
    </Link>
  );
}

function PlayAndMarkers({ channelIsLocked, isGuest, markers, liveUrl, channel, name, trackProgram }: ProgramCardProps) {
  if (channelIsLocked || isGuest) {
    return null;
  }

  return (
    <>
      {markers}
      <li className="from-start">
        <Link
          to={{ pathname: liveUrl, state: { prevPath: window.location.pathname, reloadPage: true } }}
          onClick={() => trackProgram(channel.name, name, true)}
        >
          z
        </Link>
      </li>
    </>
  );
}

function LiveImage(props: ProgramCardProps) {
  const liveProgramPictureUrl = useMemo(() => {
    return hasPicture(props.picture)
      ? getImageUrlDimensions(props.picture.deflate, imageScaleValues.tvGuide)
      : imageMissing;
  }, [props.picture]);

  return (
    <>
      <img
        className="noselect"
        alt={props.name}
        src={liveProgramPictureUrl}
        loading="lazy"
        onError={(e) => {
          e.currentTarget.src = imageMissing;
        }}
      />

      {props.channelIsLocked && !props.isGuest ? (
        <div className="noselect overlay lockedMessage">
          <span>
            <span className="aib-icon">p</span>
            <br />
            {getLockedMessage(props.channel)}
          </span>
        </div>
      ) : (
        <div className="noselect overlay playIcon">
          <span className="aib-icon">{props.isGuest ? '' : 'Ă'}</span>
        </div>
      )}
      <div className="noselect overlay gradient" />
      <div className="noselect progressWrapper">
        <progress value={props.progress} />
      </div>
    </>
  );
}
