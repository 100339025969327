import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style.scss';
import { VodPromotion } from '../../interfaces';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { getCurrentLangTitle } from '../../utils/vodUtils';
import Spinner from '../Spinner/index';

interface Props {
  promotions: VodPromotion[];
  imagePath: string;
  trailerUrl?: string;
  bannerClicked: Function;
  randomPlay?: boolean;
  centerMode?: boolean;
}

class VodPromotionBanner extends Component<
  Props,
  {
    startItemIndex: number;
  }
> {
  // Strict Class Initialization ignored
  carousel!: Carousel;

  componentDidMount() {
    this.setStartNumber();
  }

  setStartNumber() {
    let startItemIndex = 0;
    if (this.props.randomPlay && !isEmpty(this.props.promotions)) {
      let maxRandom = this.props.promotions.length - 1;
      startItemIndex = Math.floor(Math.random() * maxRandom);
    }
    this.setState({
      startItemIndex: startItemIndex,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.promotions !== this.props.promotions) {
      this.setStartNumber();
    }
  }

  trailerButton() {
    if (this.props.trailerUrl) {
      return (
        <Link
          className="playButton"
          to={{
            pathname: this.props.trailerUrl,
            state: { prevPath: window.location.pathname },
          }}
        >
          <button>
            {i18n.t<string>('play trailer')}
            <span className="aib-icon">z</span>
          </button>
        </Link>
      );
    } else {
      return null;
    }
  }

  promotionItem(item: VodPromotion) {
    return (
      <div key={item.image}>
        <img
          className="fade-in"
          alt={isEmpty(item.title) ? 'Promotion' : getCurrentLangTitle(item.title)}
          src={this.props.imagePath + '/' + item.image}
        />
        <div className="promotion-container noselect">
          <div className="promotion-subtitle vod-promotion-banner-subtitle noselect">
            <h2 className="text-shadow">{getCurrentLangTitle(item.subTitle)}</h2>
          </div>
          <div className="promotion-title vod-promotion-banner-title noselect">
            <h1 className="text-shadow">{getCurrentLangTitle(item.title)}</h1>
          </div>
        </div>
      </div>
    );
  }

  onClickItem = (index: number) => {
    this.props.bannerClicked(index);
  };

  render() {
    if (this.state && this.state.startItemIndex !== -1) {
      return (
        <div className="vod-promotion-banner-placeholder">
          <Carousel
            className="vod-promotion-banner noselect"
            showThumbs={false}
            selectedItem={this.state.startItemIndex}
            showStatus={false}
            centerMode={this.props.centerMode ? true : false}
            infiniteLoop={true}
            autoPlay={true}
            useKeyboardArrows={true}
            stopOnHover={false}
            interval={8000}
            onClickItem={this.onClickItem}
            renderIndicator={(handleClick, isSelected) => (
              <li>
                <button className={isSelected ? 'selected' : ''} onClick={handleClick} />
              </li>
            )}
          >
            {this.props.promotions.map((item) => {
              return this.promotionItem(item);
            })}
          </Carousel>
        </div>
      );
    } else {
      return (
        <div className="vod-promotion-banner-placeholder">
          <Spinner />
        </div>
      );
    }
  }
}
export default VodPromotionBanner;
