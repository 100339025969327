import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-dropdown/style.css';
import './style.scss';

import {
  RootState,
  HeaderDisplayType,
  HeaderInteractionType,
  VodAsset,
  AltiboxAsset,
  AltiboxAssetDisplayTypes,
  VodAssetDisplayMode,
  PortalMenu,
  AltiboxAssetType,
  SearchCategories,
} from '../../interfaces';
import StickyHeader from '../app/StickyHeader';
import Footer from '../../components/Footer';

import i18n from '../../i18n';

import { RouteComponentProps, withRouter } from 'react-router';
import Header from '../app/Header';
import Spinner from '../../components/Spinner';
import { searchConfig, routes } from '../../config';
import AltiboxAssetViewList from '../../components/UI/AltiboxAssetViewList';
import { getContentDetail } from '../../api/vod';
import { getCastByName, getCastById } from '../../api/search';
import { isEpisode, assetIsBought, assetIsRented, isVodActive } from '../../utils/vodUtils';
import { getSvodUrlBasedByProvidername, getSvodKiosk, getSvodAsset } from '../../utils/svodUtil';
import { isEmpty } from 'lodash';

interface RouteProps {
  castId: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  svodKiosks: PortalMenu[];
  defaultImagePath: string;
  myContent: VodAsset[] | undefined;
  mySeries: VodAsset[] | undefined;
  searchCategories: SearchCategories;
}
interface State {
  name: string;
  noresults: boolean;
  assets: AltiboxAsset[] | undefined;
}

class Cast extends Component<Props, State> {
  state: State = {
    name: i18n.t<string>('loading'),
    noresults: false,
    assets: undefined,
  };

  convertToSvodLinks(asset: AltiboxAsset[], ids: string[]) {
    ids.forEach((id) => {
      let found = asset.find((x) => x.id === id);
      if (found) {
        let svodAsset = found.asset! as VodAsset;
        let kiosk = this.getKiosk(svodAsset);
        let urlToCheck = kiosk
          ? kiosk.providerName
            ? kiosk.providerName
            : svodAsset.companyName
          : svodAsset.companyName;
        let svodUrl = getSvodUrlBasedByProvidername(urlToCheck);
        found.playLink = `${routes.svod.base}/${svodUrl}${routes.vod.play}/${svodAsset.externalContentCode}`;
        found.detailsLink = `${routes.svod.base}/${svodUrl}${routes.svod.details}/${svodAsset.externalContentCode}`;
        found.type = AltiboxAssetType.SVOD;
      }
    });
    return asset;
  }

  isInVodKiosk(asset: VodAsset): boolean {
    return this.getKiosk(asset) ? true : false;
  }

  getKiosk(asset: VodAsset) {
    return getSvodKiosk(asset, this.props.svodKiosks);
  }

  checkCastNames(asset: VodAsset, nameToCheck: string) {
    let allCasts = asset.casts
      ? asset.casts.filter((cast) => {
          return cast.roleType === '0' || cast.roleType === '1' || cast.roleType === '4';
        })
      : [];
    let allCastsNames = isEmpty(allCasts) ? [] : allCasts.map((x) => x.castName);
    let allNames: string[] = [];
    let actors: string[] = [];
    let directors: string[] = [];
    let producers: string[] = [];
    if (asset.cast) {
      actors = asset.cast.actor ? asset.cast.actor.split(',') : [];
      directors = asset.cast.director ? asset.cast.director.split(',') : [];
      producers = asset.cast.producer ? asset.cast.producer.split(',') : [];
    }
    allNames = allNames.concat(actors).concat(directors).concat(producers).concat(allCastsNames);
    let filteredNames = allNames.map((x) => x.indexOf(nameToCheck) !== -1).filter((x) => x);
    return !isEmpty(filteredNames);
  }

  addBadgeToCastResult(assets: VodAsset[]) {
    let allIds: string[] = [];
    let myContentIds = this.props.myContent
      ? this.props.myContent!.map((asset) => {
          return asset.id;
        })
      : [];
    let mySeriesIds = this.props.mySeries
      ? this.props.mySeries!.map((asset) => {
          return asset.id;
        })
      : [];
    allIds = allIds.concat(myContentIds).concat(mySeriesIds);
    return assets.map((asset) => {
      if (allIds.indexOf(asset.id) !== -1) {
        asset.showBoughtBadge = assetIsBought(asset);
        asset.showRentedBadge = assetIsRented(asset);
      }
      return asset;
    });
  }

  componentDidMount() {
    const { props } = this;
    window.scrollTo(0, 0);
    getCastById(props.match.params.castId).then((castResponse) => {
      if (castResponse.casts && castResponse.casts.length > 0) {
        let name = castResponse.casts[0].name;
        this.setState({
          name: name,
        });
        getCastByName(name).then((castAssets) => {
          if (castAssets.contentlist && castAssets.contentlist.length > 0) {
            getContentDetail(castAssets.contentlist.map((x: VodAsset) => x.id).join(',')).then((enrichedAssets) => {
              let nonEpisodes = enrichedAssets.filter((x) => !isEpisode(x));
              let vodAssets = nonEpisodes.filter((x) => x.categoryIds);
              if (props.searchCategories && props.svodKiosks) {
                let svodAllActiveContent = props.svodKiosks.map((x) => x.svodAllActiveContentId).filter((x) => x);
                let categoryIds = svodAllActiveContent.concat(
                  props.searchCategories.ActiveContent.concat(
                    props.searchCategories.VOD.concat(props.searchCategories.SVOD),
                  ),
                );
                vodAssets = vodAssets.filter((x) => isVodActive(x.categoryIds, categoryIds));
              }
              vodAssets = vodAssets.filter((asset) => this.checkCastNames(asset, name));
              vodAssets = this.addBadgeToCastResult(vodAssets);
              let altiboxAssets = vodAssets.map((x: VodAsset) =>
                getSvodAsset(x, this.props.svodKiosks, this.props.defaultImagePath, true),
              );
              let svodAssets = altiboxAssets.filter((x) => this.isInVodKiosk(x.asset! as VodAsset));
              let finalList = this.convertToSvodLinks(
                altiboxAssets,
                svodAssets.map((x) => x.id),
              );
              this.setState({
                assets: finalList,
              });
            });
          } else {
            this.setState({
              noresults: true,
              assets: undefined,
            });
          }
        });
      } else {
        this.setState({
          name: i18n.t<string>('cannot find cast'),
          noresults: true,
          assets: undefined,
        });
      }
    });
  }

  render() {
    return (
      <>
        <StickyHeader searchConfig={searchConfig.global} />
        <Header
          title={this.state.name}
          displayType={HeaderDisplayType.Solid}
          interactionType={HeaderInteractionType.Full}
        />
        <div className="vod-asset-full-view vod-content vod-content-cast">
          {this.state.assets && this.state.assets.length > 0 ? (
            <AltiboxAssetViewList
              assets={this.state.assets}
              hideTitleHeader={true}
              displayType={AltiboxAssetDisplayTypes.GridView}
              vodDisplayMode={VodAssetDisplayMode.SVOD}
              myContent={this.props.myContent}
            />
          ) : !this.state.assets && !this.state.noresults ? (
            <Spinner wrapInContainer={true} />
          ) : null}
          <Footer />
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      defaultImagePath: state.app.defaultImagePath,
      svodKiosks: state.app.svodKiosks,
      myContent: state.vodReducer.myContent,
      mySeries: state.vodReducer.mySeries,
      searchCategories: state.searchReducer.searchCategories as SearchCategories,
    }),
    (dispatch) => ({}),
  )(Cast),
);
