import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import {
  RootState,
  ApiAuthResponse,
  PortalMenu,
  GenreType,
  Genre,
  VodAssetDisplayMode,
  SearchCategories,
  SearchConfig,
  AltiboxAssetDetailsType,
} from '../../interfaces';
import { routes, searchConfig } from '../../config';
import FrontPage from './FrontPage';
import { loadSvodConfig, clearSvodSelection } from './actions';

import './style.scss';
import { getGenres } from '../tv/actions/index';
import { getSvodUrl } from '../../utils/svodUtil';
import CategoryView from '../vod/CategoryView';
import VodPlayer from '../vod/VodPlayer';
import Spinner from '../../components/Spinner/index';
import Search from '../globalsearch';
import DetailsView from '../details';
import { isEqual } from 'lodash';

interface RouteProps {
  svodKiosk: string;
}

interface Props extends RouteComponentProps<RouteProps> {
  isGuest: boolean;
  auth: ApiAuthResponse | undefined;
  svodKiosks: PortalMenu[];
  svodKiosk: PortalMenu;
  genres: Genre[];
  searchCategories: SearchCategories;
  getGenres: () => Promise<Genre[]>;
  clearSvodSelection: () => Promise<void>;
  loadConfig: (_: string, __: string, ___: PortalMenu, ____: Function) => Promise<void>;
  setSvodKiosk: (_: PortalMenu) => Promise<void>;
}

class Svod extends React.Component<Props, {}> {
  componentDidMount() {
    this.setUpSvod(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.svodKiosk && !isEqual(this.props.match.params.svodKiosk, nextProps.match.params.svodKiosk)) {
      this.props.clearSvodSelection();
      this.setUpSvod(nextProps);
    }
  }

  componentWillUnmount() {
    this.props.clearSvodSelection();
  }

  setUpSvod(props: Props) {
    let svodKioskParam = props.match.params.svodKiosk;
    let svodKiosk = props.svodKiosks.find((x) => getSvodUrl(x) === svodKioskParam);
    if (svodKiosk) {
      if (props.auth && svodKiosk) {
        if (props.genres.length <= 0) {
          props.getGenres();
        }
        props.loadConfig(props.auth.areaid, props.auth.bossID, svodKiosk, () => {
          this.forceUpdate();
        });
      }
    }
  }

  render() {
    if (this.props.svodKiosk && this.props.svodKiosks && this.props.svodKiosk.svodCategoryId) {
      let derivedSearchConfig = searchConfig.svod as SearchConfig;
      let svodKioskParam = this.props.match.params.svodKiosk;
      const svodBase = routes.svod.base + '/:svodKiosk';
      derivedSearchConfig.baseService = routes.svod.base + '/' + svodKioskParam;
      derivedSearchConfig.service = this.props.svodKiosk.providerName!;
      return (
        <Switch>
          <Route exact={true} path={svodBase} render={() => <FrontPage searchConfig={derivedSearchConfig} />} />

          <Route
            exact={true}
            path={svodBase + routes.svod.playTrailer + '/:vodId'}
            render={() => <VodPlayer searchConfig={derivedSearchConfig} />}
          />
          <Route
            exact={true}
            path={svodBase + routes.vod.play + '/:vodId'}
            render={() => <VodPlayer searchConfig={derivedSearchConfig} />}
          />

          <Route
            path={svodBase + routes.search.base + '*'}
            render={() => <Search searchConfig={derivedSearchConfig} />}
          />

          <Route
            path={svodBase + routes.svod.details + '/:parentId/:childId?'}
            render={() => {
              return <DetailsView displayType={AltiboxAssetDetailsType.SVOD} />;
            }}
          />

          <Route
            path={svodBase + routes.svod.category + '/:categoryId/:orderType?/:genreIds?'}
            render={(props) => {
              return <CategoryView viewType={VodAssetDisplayMode.SVOD} {...props} searchConfig={derivedSearchConfig} />;
            }}
          />

          <Route
            path={svodBase + routes.svod.genre + '/:categoryId/:orderType?/:genreIds?'}
            render={(props) => {
              return <CategoryView viewType={VodAssetDisplayMode.SVOD} {...props} searchConfig={derivedSearchConfig} />;
            }}
          />
        </Switch>
      );
    } else {
      return <Spinner />;
    }
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      isGuest: state.authReducer.isGuest,
      auth: state.authReducer.auth,
      genres: state.channelsReducer.genres,
      svodKiosk: state.svodReducer.svodKiosk as PortalMenu,
      svodKiosks: state.app.svodKiosks,
    }),
    (dispatch) => ({
      loadConfig: (areaId: string, bossID: string, svod: PortalMenu, callback: Function) =>
        dispatch(loadSvodConfig(areaId, bossID, svod, callback)),
      getGenres: () => dispatch(getGenres(GenreType.MovieAndTV)),
      clearSvodSelection: () => dispatch(clearSvodSelection()),
    }),
  )(Svod),
);
