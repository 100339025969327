import { CSSProperties, ImgHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string;
  transition?: number;
  crop?: [x: number, y: number];
  preventDrag?: boolean;
}

const imageLoadedStyle = {
  opacity: '1',
};

export function Image({
  src,
  fallback = '/images/horizontal-placeholder.jpeg',
  alt = '',
  className,
  style,
  transition = 400,
  crop,
  preventDrag = false,
  ...rest
}: ImageProps) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const componentMounted = useRef(false);

  const imageSrc = useMemo(() => {
    return crop ? cropImage(src, crop) : src;
  }, [crop, src]);

  useEffect(() => {
    if (imageSrc) {
      const image = new window.Image();
      image.src = imageSrc;
      image.onload = onImageLoad;
      componentMounted.current = true;
    }

    return () => {
      componentMounted.current = false;
    };
  }, [imageSrc]);

  function onImageLoad() {
    if (componentMounted.current) {
      setHasLoaded(true);
    }
  }

  let imageStyle: CSSProperties = {
    opacity: '0',
    transition: `opacity ${transition}ms ease-in 0s`,
  };

  return (
    <object
      className={className || ''}
      style={hasLoaded ? { ...style, ...imageStyle, ...imageLoadedStyle } : { ...style, ...imageStyle }}
      data={imageSrc}
      type="image/png"
      draggable={!preventDrag}
      onMouseDown={(e) => preventDrag && e.preventDefault()}
    >
      <img
        className={className || ''}
        style={hasLoaded ? { ...style, ...imageStyle, ...imageLoadedStyle } : { ...style, ...imageStyle }}
        src={fallback}
        alt={alt}
        draggable={!preventDrag}
        onMouseDown={(e) => preventDrag && e.preventDefault()}
        {...rest}
      />
    </object>
  );
}

function cropImage(src: string | undefined, [x, y]: [x: number, y: number]) {
  return `${src}?x=${x}&y=${y}&ar=keep`;
}
