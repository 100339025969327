import { RefObject, useEffect } from 'react';

/**
 * Run a function when you click outside of the specified ref object(s).
 *
 * @param {function} func
 * @param {RefObject<HTMLElement>[]} ref
 * @functions
 */
export function useOutsideAlerter<F extends Function, T extends RefObject<HTMLElement>[]>(func: F, ref: T) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (Array.isArray(ref)) {
        for (let i = 0; i < ref.length; i++) {
          const { current } = ref[i];
          if (current && current.contains(event.target as Node)) {
            return;
          }
        }

        return func();
      }

      throw new Error('useOutsideAlerter only accepts an array of ref objects');
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [func, ref]);
}
