import { Environments } from './Environments';

export const forgeRock = function () {
  const environments = Environments();
  const currentEnvironment = environments.getCurrentApiEnvironment();

  const client_ids = {
    at: '7f32d176-e0bc-4890-b494-1aca373e3088',
    st: '7f32d176-e0bc-4890-b494-1aca373e3088',
    production: '5d5c30f6-06a3-410e-aa48-b1de6207f340',
  };

  const login_url = {
    at: 'https://login-at.enid.no/',
    st: 'https://login-st.enid.no/',
    production: 'https://login.enid.no/',
  };
  const logout_endpoint = {
    at: 'https://login-at.enid.no/am/json/authenticate?authIndexType=service&authIndexValue=Logout',
    st: 'https://login-st.enid.no/am/XUI/?realm=alpha&authIndexType=service&authIndexValue=Logout',
    production: 'https://login.enid.no/am/json/authenticate?authIndexType=service&authIndexValue=Logout',
  };

  function getRedirectURL() {
    return (
      localStorage.getItem('call-me-back') ||
      window.location.protocol + '//' + document.location.host + '/auth/callback/'
    );
  }

  return {
    client_id: client_ids[currentEnvironment],
    login_url: login_url[currentEnvironment],
    redirect_uri: getRedirectURL(),
    logout_endpoint: logout_endpoint[currentEnvironment],
  };
};
