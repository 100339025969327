// channelNamespace = 0 = ott channels

import { getBaseUrl, getSessionTicket } from '../../config';
import { fetchError, getDataFromResponse } from '../../controllers/Fetch';
import { Api } from '../../interfaces';
import { constructChannel } from './utils';

// channelNamespace = 4000 = ott channels and iptv channels
export async function getChannels(channelNamespace = '0') {
  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/AllChannel', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        domain: 0,
        channelNamespace: channelNamespace,
        metaDataVer: 'Channel/1.1',
        filterlist: [{ key: 'IsHide', value: '-1' }],
        properties: [
          {
            include: [
              '/channellist/logicalChannel/contentId',
              '/channellist/logicalChannel/name',
              '/channellist/logicalChannel/chanNo',
              '/channellist/logicalChannel/mediaid',
              '/channellist/logicalChannel/locationCopyrights',
              '/channellist/logicalChannel/pictures/picture/href',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/extensionInfo',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/mediaId',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/btvBR/is',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/btvBR/va',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/videoCodec',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/cutvCR',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/npvrRecCR',
            ].join(),
          },
        ],
      }),
    });

    const data = await getDataFromResponse<Api.ChannelsResponse>(res);

    return data.channellist.map(constructChannel);
  } catch (error) {
    return fetchError(error as string);
  }
}
