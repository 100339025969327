import React, { Component } from 'react';
import './style.scss';
import { VodGenre, VodConfigData, GenreTypes, EpgGenre, GAaction } from '../../interfaces';
import { getCurrentLangTitle } from '../../utils/vodUtils';
import AltiboxGenreButton from '../UI/AltiboxGenreButton';
import GALink from '../GALink';
import { routes } from '../../config';
import i18n from '../../i18n';

interface Props {
  seriesGenres?: VodConfigData;
  moviesGenres?: VodConfigData;
  baseUrl?: string;
  onGenreSelected?: (selectedGenre: GenreTypes) => void;
  selectedTab?: GenreTypes;
  genres?: EpgGenre[];
}

interface State {
  selectedGenre: string;
}

class AltiboxGenreBanner extends Component<Props, State> {
  state: State = {
    selectedGenre: GenreTypes.MOVIES,
  };

  componentDidMount() {
    this.setState({
      selectedGenre: this.props.selectedTab ? this.props.selectedTab : GenreTypes.MOVIES,
    });
  }

  getProgramArchiveGenres() {
    if (this.props.genres) {
      return (
        <div className="genre-listing-wrapper">
          <div role="navigation" className="genre-listing-container">
            {this.props.genres.map((genre: EpgGenre) => {
              const link =
                `${routes.programarchive.base}${routes.programarchive.genre}/` +
                `${genre.genreIds.replace(/\s/g, '')}/${genre.orderType}`;
              return (
                <GALink
                  action={GAaction.listFilterBy}
                  label={genre.title.en + ' - ' + genre.genreIds}
                  key={genre.title.en}
                >
                  <AltiboxGenreButton title={getCurrentLangTitle(genre.title)} key={genre.title.en} path={link} />
                </GALink>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  }

  getVodSvodGenres(mainGenre: VodConfigData) {
    return (
      <div role="navigation" className="genre-listing-container">
        {mainGenre.genres.map((genre: VodGenre) => {
          return (
            <AltiboxGenreButton
              title={getCurrentLangTitle(genre.title)}
              key={genre.title.en}
              path={this.props.baseUrl + '/' + mainGenre.categoryId + '/2/' + genre.genreIds.replace(/\s/g, '')}
            />
          );
        })}
      </div>
    );
  }

  onGenreTitleClick = (type: GenreTypes) => {
    if (this.props.onGenreSelected) {
      this.props.onGenreSelected(type);
    }

    this.setState({
      selectedGenre: type,
    });
  };

  isSelected(type: string) {
    return this.state.selectedGenre === type ? 'selected' : '';
  }

  render() {
    if (this.props.seriesGenres && this.props.moviesGenres) {
      return (
        <div className="genre-list">
          <div className="genre-button-listing-title vod">
            <h3
              className={this.isSelected(GenreTypes.MOVIES)}
              onClick={this.onGenreTitleClick.bind(this, GenreTypes.MOVIES)}
            >
              {getCurrentLangTitle(this.props.moviesGenres.title)}
            </h3>
            <h3
              className={this.isSelected(GenreTypes.SERIES)}
              onClick={this.onGenreTitleClick.bind(this, GenreTypes.SERIES)}
            >
              {getCurrentLangTitle(this.props.seriesGenres.title)}
            </h3>
          </div>
          <div className="genre-listing-wrapper">
            {this.isSelected(GenreTypes.MOVIES) ? this.getVodSvodGenres(this.props.moviesGenres) : null}
            {this.isSelected(GenreTypes.SERIES) ? this.getVodSvodGenres(this.props.seriesGenres) : null}
          </div>
        </div>
      );
    } else if (this.props.genres) {
      return (
        <div className="genre-list">
          <div className="genre-button-listing-title">
            <h3 title={i18n.t<string>('genres')}>{i18n.t<string>('genres')}</h3>
          </div>
          {this.props.genres ? this.getProgramArchiveGenres() : null}
        </div>
      );
    } else {
      return null;
    }
  }
}
export default AltiboxGenreBanner;
