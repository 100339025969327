import styles from './style.module.scss';

export function CircleLoader() {
  return (
    <div className={styles.loader}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
