// libs
import 'react-app-polyfill/ie9';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import preval from 'preval.macro';
import { Provider } from 'react-redux';
import { RootState, ApiAuthResponse, GAaction, GAcategory, AuthReducerState, SubnetIds, AlertType } from './interfaces';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createStore, combineReducers, applyMiddleware, compose, Store, AnyAction } from 'redux';
import thunk from 'redux-thunk';
import { enableBatching } from 'redux-batched-actions';
import 'moment/locale/nb';
import { I18nextProvider } from 'react-i18next';
import { authenticate, clearOldNonEsts } from './api/auth';
import './utils/smartbanner';
import { createBrowserHistory } from 'history';

import Alert from './components/Alert';
import globalInteraction from './globalInteraction';
import i18n from './i18n';
import {
  routes,
  isDev,
  getImagePath,
  searchConfig,
  setWidevineLicenseServerUrl,
  exposeWindowObjects,
  setStoredGuestLocation,
  googleAnalytics,
  cookieCategory,
} from './config';
import { HelmetProvider } from 'react-helmet-async';

// views
import ErrorBoundary from './components/ErrorBoundary';
import Settings from './views/settings/Settings';
import Tv from './views/tv/Tv';
import Pvr from './views/newpvr';
import FrontPage from './views/app';
import Vod from './views/vod';
import Svod from './views/svod';
import Search from './views/globalsearch';
import MyContent from './views/mycontent';
import ProgramArchive from './views/programarchive';
import TvGuide from './views/tvguide';
import Cast from './views/cast';
import StreamingServices from './views/streamingServices';

// actions
import { version } from './utils/packageJson.js';
import {
  addAuthStatus,
  showAlert,
  setProductListAndPvrStatus,
  loadPortalMenuAndFrontPageConfig,
  setDefaultImagePath,
} from './views/app/actions';

// reducers
import { pvrReducer } from './views/pvr/reducers';
import { vodReducer } from './views/vod/reducers';
import { svodReducer } from './views/svod/reducers';
import { searchReducer } from './views/globalsearch/reducers';
import { channelsReducer } from './views/tv/reducers';
import { programArchiveReducer } from './views/programarchive/reducers';
import { authReducer, appReducer } from './views/app/reducers';
import { detailsReducer } from './views/details/reducers';

// common css
import './index.scss';
import { loadVodConfig } from './views/vod/actions';
import { getCustomizeConfig } from './views/globalsearch/actions';
import { ScriptService } from './controllers/ScriptService';
import { getChannels, fetchAllPlaybills, getPopularChannels } from './views/tv/actions';
import ChromeCastPlayer from './components/Player/ChromeCastPlayer';
import AnalyticsTracking from './controllers/AnalyticsTracking';
import TestPlayer from './components/Player/TestPlayer';
import Login from './views/app/Login';

import { isGuestId } from './utils/appUtils';
import { getCookieFailedCount } from './controllers/Fetch';
import linktvbox from './views/linktvbox';
import FakeLogin from './views/app/Login/FakeLogin';
import { setupIE11Polyfills } from './utils/ie11polyfills';
import APIEndpointTesting from './components/APIEndpointTesting';
import WatchTv from './views/tv/WatchTv';
import { ConfirmContextProvider } from './context/ConfirmContext';
import { ConfirmModal } from './components/Modal/ConfirmModal';
import { ReactQueryCustomDevtools } from './components/ReactQueryCustomDevtools';
import { fetchAllRecordings } from './views/pvr/actions';
import { queryClient, QueryClientProvider } from './queries/client';
import { thirdPartyCatchupKeys } from './queries/third-party-catchup/keys';
import { getThirdPartyCatchupData } from './api/thirdPartyCatchup';
import { configureLoginMethod } from './api/app';

let composeEnhancers = compose;

window.isLoaded = true;

if (isDev) {
  const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (reduxDevtools) {
    composeEnhancers = reduxDevtools({ serialize: true });
  }
} else {
  if (window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == 'function' ? () => undefined : null;
    }
  }
}

setupIE11Polyfills();
exposeWindowObjects();

const store = createStore(
  enableBatching(
    combineReducers({
      app: appReducer,
      authReducer,
      channelsReducer,
      pvrReducer,
      vodReducer,
      searchReducer,
      svodReducer,
      programArchiveReducer,
      detailsReducer,
    }),
  ),
  composeEnhancers(applyMiddleware(thunk)),
);

globalInteraction(store as {} as Store<RootState, AnyAction>);

const history = createBrowserHistory();
const tracking = AnalyticsTracking.getInstance();
let lastLocation = '';
history.listen((locationChange) => {
  if (locationChange.pathname !== lastLocation) {
    lastLocation = locationChange.pathname;
    setTimeout(() => {
      tracking.setCurrentService(locationChange.pathname, store.getState().svodReducer.svodKiosk);
      tracking.trackPage();
    }, 1000);
  }
});

const inDevEnvironment =
  window.location.href.indexOf('tvbeta') !== -1 ||
  window.location.href.indexOf('atvweb-st3') !== -1 ||
  window.location.href.indexOf('localhost') !== -1;

function render() {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <HelmetProvider>
          <Provider store={store}>
            <I18nextProvider i18n={i18n}>
              <Alert>
                <ConfirmContextProvider>
                  {ScriptService.canCast() ? <ChromeCastPlayer /> : null}
                  <Router history={history}>
                    <ConfirmModal />
                    <React.Fragment>
                      <Switch>
                        <Route exact={true} path="/se-tv/:programarkiv" component={WatchTv}></Route>
                        <Route
                          exact={true}
                          path={routes.authentication.fakecallback + '*'}
                          render={() => <FakeLogin location={window.location.search} />}
                        />
                        <Route
                          exact={true}
                          path={routes.authentication.callback + '*'}
                          render={() => <Login location={window.location.href} />}
                        />
                        <Route
                          path={routes.search.base + '*'}
                          render={() => <Search searchConfig={searchConfig.global} />}
                        />
                        <Route exact={true} path="/" component={FrontPage} />
                        <Route exact={true} path={routes.settings.base} component={Settings} />
                        <Route exact={true} path="/altiboxtestplayer" component={TestPlayer} />
                        <Route path="/tv/:channelId?/:catchupId?" component={Tv} />
                        <Route path="/cast/:castId" component={Cast} />

                        <Route
                          path={routes.pvr.base + routes.search.base + '*'}
                          render={() => <Search searchConfig={searchConfig.pvr} />}
                        />
                        <Route path={routes.pvr.base} render={() => <Pvr searchConfig={searchConfig.pvr} />} />

                        <Route
                          path={routes.mycontent.base + routes.search.base + '*'}
                          render={() => <Search searchConfig={searchConfig.mycontent} />}
                        />
                        <Route
                          path={routes.mycontent.base}
                          render={() => <MyContent searchConfig={searchConfig.mycontent} />}
                        />

                        <Route
                          path={routes.tvguide.base + routes.search.base + '*'}
                          render={() => <Search searchConfig={searchConfig.tvguide} />}
                        />

                        <Route path={routes.tvguide.base} component={TvGuide} />

                        <Route path={routes.programarchive.base} component={ProgramArchive} />
                        <Route path={routes.svod.base + '/:svodKiosk'} component={Svod} />
                        <Route path={routes.svod.base} component={StreamingServices} />
                        <Route path={routes.vod.base} component={Vod} />
                        <Route path="/link" component={linktvbox} />

                        <Route
                          exact={true}
                          path="/version"
                          render={() => (
                            <div className="light">
                              <p>
                                Version: {version} - {process.env.REACT_APP_VERSION}
                              </p>
                              <p>
                                Build Date:{' '}
                                {preval`module.exports = new Date().toLocaleString('nb-NO', { hour12: false });`}.
                              </p>
                            </div>
                          )}
                        />
                        <Route
                          path={routes.ios.base}
                          render={() => {
                            return (
                              <div className="light">
                                <a href="https://itunes.apple.com/no/app/altibox/id1164396975?mt=8">
                                  Last ned appen her
                                </a>
                              </div>
                            );
                          }}
                        />
                        <Route
                          path="/font"
                          render={() => (
                            <>
                              <h1>Altibox Regular</h1>
                              <div style={{ fontSize: '2em', fontFamily: 'Altibox-Regular' }}>
                                |1234567890+\qwertyuiopåäsdfghjkløæ'zxcvbnm,.- §!"#¤%/()=?WERTYUIOPÅÂSDFGHJKLØÆ*{'>'}
                                ZXCVBNM;:_" ¦¡@£$½¥[]±ł€®þ←↓→œπßðđŋħł½«»©“”nµ–ɎąĂëĎĄŇÏŔ
                              </div>
                              <h1>Altibox STB</h1>
                              <div style={{ fontSize: '2em', fontFamily: 'Altibox-STB' }}>
                                |1234567890+\qwertyuiopåäsdfghjkløæ'zxcvbnm,.- §!"#¤%/()=?WERTYUIOPÅÂSDFGHJKLØÆ*{'>'}
                                ZXCVBNM;:_" ¦¡@£$½¥[]±ł€®þ←↓→œπßðđŋħł½«»©“”nµ–✚⚊ɎąĂëĎ
                              </div>
                            </>
                          )}
                        />
                        {inDevEnvironment && <Route path="/api-test/:payload" component={APIEndpointTesting} />}
                        {inDevEnvironment && <Route path="/api-test" component={APIEndpointTesting} />}

                        <Route path="/:bogus" render={() => <Redirect to="/" />} />
                      </Switch>
                    </React.Fragment>
                  </Router>
                </ConfirmContextProvider>
              </Alert>
            </I18nextProvider>
          </Provider>
        </HelmetProvider>
      </ErrorBoundary>
      {inDevEnvironment && <ReactQueryCustomDevtools />}
    </QueryClientProvider>,
    document.getElementById('root'),
  );
  document.getElementById('root')!.className = ScriptService.getBrowserType();
}

window.showMessage = function (title: string, text: string[]) {
  store.dispatch(
    showAlert({
      title: title,
      text: text,
      type: AlertType.INFO,
    }),
  );
};

const callback = window.location.pathname.indexOf(routes.authentication.callback) !== -1;
const fakeCallback = window.location.pathname.indexOf(routes.authentication.fakecallback) !== -1;

// handle login redirect
if (callback || fakeCallback) {
  render();
} else {
  // First identify user
  authenticate()
    .then((authResponse: ApiAuthResponse) => {
      const { bossID, areaid } = authResponse;
      queryClient.prefetchQuery(
        thirdPartyCatchupKeys.auth(bossID, areaid),
        () => getThirdPartyCatchupData(bossID, areaid),
        { cacheTime: Infinity, staleTime: Infinity },
      );
      return store.dispatch(addAuthStatus(authResponse));
    })
    .then((authResponse) => {
      if (!isGuestId(authResponse.auth.userID)) {
        let subnetId = authResponse.auth.subnetId ?? (ScriptService.isDKUser() ? SubnetIds.DK : SubnetIds.NO);
        setStoredGuestLocation(subnetId.toString());
        if (
          !ScriptService._isSafari() &&
          authResponse.auth.ca &&
          authResponse.auth.ca.widevine &&
          authResponse.auth.ca.widevine.LA_Url
        ) {
          setWidevineLicenseServerUrl(authResponse.auth.ca.widevine.LA_Url);
        }

        if (!store.getState().searchReducer.searchCategories && store.getState().authReducer) {
          store.dispatch(getCustomizeConfig((store.getState().authReducer as AuthReducerState).country));
        }
      } else {
        configureLoginMethod();
      }

      if (
        (store.getState().authReducer as AuthReducerState).loggedInWithCredentials ||
        (store.getState().authReducer as AuthReducerState).loggedInWithIp
      ) {
        store.dispatch(setProductListAndPvrStatus());
      }

      if ((store.getState().authReducer as AuthReducerState).loggedInWithCredentials) {
        // after authenticated by username and password
        clearOldNonEsts();
      }

      store.dispatch(
        loadPortalMenuAndFrontPageConfig(
          authResponse.auth.areaid,
          authResponse.auth.bossID,
          authResponse.auth.userID,
          (store.getState().authReducer as AuthReducerState).loggedInWithCredentials,
        ),
      );
      store.dispatch(loadVodConfig(authResponse.auth.areaid, authResponse.auth.bossID));
      return store
        .dispatch(getChannels())
        .then(() => store.dispatch(fetchAllRecordings(true)))
        .then(() =>
          store.dispatch(getPopularChannels()).then(() =>
            store.dispatch(fetchAllPlaybills()).then(() => {
              store.dispatch(setDefaultImagePath(getImagePath(authResponse.auth.bossID, authResponse.auth.areaid)));
              setTimeout(() => {
                tracking.setCurrentService(window.location.pathname, store.getState().svodReducer.svodKiosk);
                tracking.trackPage(); // initial page track
              }, 1000);
            }),
          ),
        );
    })
    .then(() => {
      const statisticConsentGiven = Boolean(window.CookieInformation.getConsentGivenFor(cookieCategory.statistic));

      if (statisticConsentGiven) {
        googleAnalytics.enable();
      } else {
        googleAnalytics.disable();
      }

      if (ScriptService.isDKUser()) {
        // Documentation: https://engineeringportal.nielsen.com/docs/DCR_Denmark_Video_Browser_SDK
        window.initNielsen();

        // const nielsen = window.NOLBUNDLE?.nlsQ('P171E7F90-D20C-459D-9B4E-66E3E6CAEBB6', 'nlsnInstance', {
        //   nol_sdkDebug: 'debug',
        // });

        const nielsen = window.NOLBUNDLE?.nlsQ('P171E7F90-D20C-459D-9B4E-66E3E6CAEBB6', 'nlsnInstance', {
          enableFpid: statisticConsentGiven,
        });

        window.nielsen = nielsen;
      } else {
        const nielsenScript = document.querySelector('script[data-playbacktracking="nielsen"]');
        if (nielsenScript !== null && nielsenScript?.parentElement !== null) {
          nielsenScript?.parentElement?.removeChild(nielsenScript);
        }
      }
    })
    .then(render)
    .catch((error: Error) => {
      const isFailingCookies = Number(getCookieFailedCount()) >= 3;

      if (typeof error === 'string' && error === '-2') {
        if (!isFailingCookies) {
          tracking.trackEvent(GAcategory.login, GAaction.sessionExpired, error);
        } else {
          tracking.trackEvent(GAcategory.login, GAaction.sessionRedirected, error);
        }
      } else {
        tracking.trackEvent(
          GAcategory.webError,
          'ROOT FAIL' as GAaction,
          error.message
            ? '| <MESSAGE> |' + error.message + '| <NAME> |' + error.name + '| <STACK> |' + error.stack
            : (error as unknown as GAaction),
        );
      }

      if (typeof window.cacheBust === 'function') {
        window.cacheBust(true, 2, isFailingCookies);
      } else {
        setTimeout(() => {
          if (typeof window.cacheBust === 'function') {
            window.cacheBust(true, 2, isFailingCookies);
          }
        }, 1000);
      }
    });
}
