import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PvrLayout } from '../../../components/Pvr/Layout';
import { PvrScheduledAsset } from '../../../components/Pvr/ScheduledAsset';
import Spinner from '../../../components/Spinner';
import { useScheduledRecordings } from '../../../queries/recordings/queries';
import { recordingIsSingle } from '../../../typeGuards';
import styles from './style.module.scss';

export default function PvrPlanned() {
  const { data: scheduledRecordings, isLoading, isFetching } = useScheduledRecordings();

  const { t } = useTranslation('pvr');

  const singleRecordings = useMemo(
    () => scheduledRecordings?.filter((recording) => recordingIsSingle(recording)),
    [scheduledRecordings],
  );
  const seriesRecordings = useMemo(
    () => scheduledRecordings?.filter((recording) => !recordingIsSingle(recording)),
    [scheduledRecordings],
  );

  return (
    <PvrLayout>
      <div className={styles.container}>
        {isLoading && <Spinner wrapInContainer />}

        {isEmpty(singleRecordings) && isEmpty(seriesRecordings) && !isLoading && (
          <div className={styles.container}>
            <p className={styles.empty}>{t('you have no scheduled recordings')}</p>
          </div>
        )}

        {!isEmpty(singleRecordings) && (
          <section>
            <h2>{t('recordings next two weeks', { amount: singleRecordings?.length })}</h2>
            <ul className={styles.grid}>
              {singleRecordings?.map((asset) => (
                <li key={asset.pvrId ?? asset.seriesId}>
                  <PvrScheduledAsset asset={asset} useSkeleton={isFetching} />
                </li>
              ))}
            </ul>
          </section>
        )}

        {!isEmpty(seriesRecordings) && (
          <section>
            <h2>{t('series recordings the next two weeks', { amount: seriesRecordings?.length })}</h2>
            <ul className={styles.grid}>
              {seriesRecordings?.map((asset) => (
                <li key={asset.pvrId}>
                  <PvrScheduledAsset asset={asset} useSkeleton={isFetching} />
                </li>
              ))}
            </ul>
          </section>
        )}
      </div>
    </PvrLayout>
  );
}
