import React, { Component } from 'react';
import './style.scss';
import { addFiltersToLink, getCurrentLangImage, getCurrentLangTitle } from '../../utils/vodUtils';
import { InlineVodPromotion } from '../../interfaces';
import { Link } from 'react-router-dom';
import { routes } from '../../config';

interface Props {
  promotions?: InlineVodPromotion[];
  imagePath: string;
}

class VodInlinePromotion extends Component<Props> {
  getLink(promotion: InlineVodPromotion) {
    if (promotion.actionParams) {
      let sorting = promotion.actionParams.sorting ? promotion.actionParams.sorting : '2';
      let link = `${routes.vod.base}${routes.vod.category}/${promotion.actionParams.categoryId}/${sorting}`;

      link = addFiltersToLink(promotion.actionParams.filter, link);

      return link;
    } else {
      return routes.vod.base;
    }
  }

  render() {
    if (this.props.promotions) {
      return (
        <div className="vod-inline-promotions">
          {this.props.promotions.map((promotion, index) => {
            return (
              <div
                key={'inline-promotion-' + index}
                className={'inline-promotion-item inline-promotion-size-' + promotion.size}
              >
                <Link to={this.getLink(promotion)}>
                  <img
                    alt={getCurrentLangTitle(promotion.actionParams.title)}
                    title={getCurrentLangTitle(promotion.actionParams.title)}
                    className={'aib-hover-glow'}
                    src={this.props.imagePath + '/' + getCurrentLangImage(promotion.image)}
                  />
                </Link>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}
export default VodInlinePromotion;
