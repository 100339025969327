import React, { useState } from 'react';
import { ScriptService } from '../../../../controllers/ScriptService';
import i18n from '../../../../i18n';
import { PlayerOptions } from '../../../../interfaces';
import PlayerControlButton from '../PlayerControls/PlayerControlButton';
import './style.scss';

interface Props {
  className: string;
  icon: string;
  optionsType: PlayerOptions;
  playerBitRates?: JSX.Element[];
  currentBitRateStatus?: number;
  maxBitRateStatus?: string;
  audios?: JSX.Element[];
  subtitles?: JSX.Element[];
  selelectedOptionCallback?: () => void;
}

interface HoverListProps {
  listItems: JSX.Element[];
  title: string;
}

// TODO: [Universal Design] Make these options tabable.
const HoverListItems = ({ title, listItems }: HoverListProps) => {
  return (
    <div key={title}>
      <p>{i18n.t<string>(title)}</p>
      <ul>{listItems}</ul>
    </div>
  );
};

const BitRateStatus = ({ maxBitRateStatus, currentBitRateStatus }: Props) => {
  if (currentBitRateStatus && maxBitRateStatus) {
    let currentBitRateToShow = (currentBitRateStatus / 1000000).toFixed(2).toString();
    return (
      <div className={'bitRate-status'}>
        {`${i18n.t<string>('current')}: ${currentBitRateToShow} Mbps`}
        <br />
        {`${i18n.t<string>('max')}: ${maxBitRateStatus} Mbps`}
      </div>
    );
  }
  return null;
};

function HoverMenu(props: Props) {
  const [showHoverMenu, setShowHoverMenu] = useState(false);

  const getOnHoverIconClassName = () => {
    return showHoverMenu ? ' hover' : '';
  };

  const handleOnMouseEnter = () => {
    setShowHoverMenu(true);
  };

  const handleOnMouseLeave = () => {
    setShowHoverMenu(false);
  };

  const getOptions = (playerOptions: PlayerOptions) => {
    const { playerBitRates, audios, subtitles } = props;
    switch (playerOptions) {
      case PlayerOptions.BitRates:
        if (playerBitRates) {
          return (
            <>
              <HoverListItems listItems={playerBitRates} title={'video quality'} />
              <BitRateStatus {...props} />
            </>
          );
        }
        return null;
      case PlayerOptions.AudioAndSubtitles:
        if (audios && subtitles) {
          return (
            <>
              <HoverListItems listItems={audios} title={'audio languages'} />
              <HoverListItems listItems={subtitles} title={'subtitle languages'} />
            </>
          );
        }
        return null;
    }
  };

  return props.playerBitRates && ScriptService._isSafari() ? null : (
    <div className={'hover-menu'} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <PlayerControlButton
        className="noOutlineOnHover"
        hover={props.className + getOnHoverIconClassName()}
        icon={props.icon}
        onClickCallback={undefined}
      />
      {showHoverMenu ? (
        <div className={'options-container ' + props.className}>{getOptions(props.optionsType)}</div>
      ) : null}
    </div>
  );
}
export default HoverMenu;
