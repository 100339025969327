import MobileDetect from 'mobile-detect';
import { getCastSession } from '../components/Player/helpers/ChromeCast';
import { getSubnetId, isDevDK, isGuestDK } from '../config';
import i18n from '../i18n';
import { Domains, SubnetIds } from '../interfaces';

var ScriptService = {
  SAFARI_LOWEST_HEVC_MAJOR: 10,
  SAFARI_LOWEST_HEVC_MINOR: 12,
  ALLOW_SAFARI_PLAYBACK: true,

  isDKDomain() {
    return document && document.location && document.location.host === Domains.DK;
  },

  isNETDomain() {
    return (
      document &&
      document.location &&
      (document.location.host === Domains.NET || document.location.host === Domains.NETBETA)
    );
  },

  isNODomain() {
    return document && document.location && document.location.host === Domains.NO;
  },

  isDKLanguage() {
    return i18n.language === 'da';
  },

  isNOLanguage() {
    return i18n.language === 'no' || i18n.language === 'nb';
  },

  isENLanguage() {
    return i18n.language === 'en';
  },

  getLocale() {
    return this.isDKUser() ? 'DK' : 'NO';
  },

  isDKUser() {
    return (
      ScriptService.isDKDomain() ||
      isGuestDK() ||
      isDevDK() ||
      (ScriptService.isNETDomain() && isGuestDK()) ||
      getSubnetId() === SubnetIds.DK
    );
  },

  isPiPSupported() {
    return !this._isFirefox() && document.pictureInPictureEnabled;
  },

  isCurrentBrowserSupported() {
    let isSupported = false;

    switch (true) {
      case ScriptService._isEdgeChromium():
      case ScriptService._isChrome():
      case ScriptService._isFirefox():
      case ScriptService._isOpera():
        isSupported = true;
        break;
      case ScriptService._isSafari():
        isSupported = ScriptService.ALLOW_SAFARI_PLAYBACK;
        break;
    }
    return isSupported;
  },

  isOptimizedForWeb() {
    return ScriptService.onMobile() || ScriptService.isCurrentBrowserSupported();
  },

  supportsHEVC() {
    return ScriptService._isSafari() && ScriptService.safariHevcSupport();
  },

  // todo make test
  safariHevcSupport() {
    const osVersion = ScriptService.getMacOsVersion();
    const osVersionNumbers = osVersion.split('.');
    const major = Number(osVersionNumbers[0]);
    const minor = Number(osVersionNumbers[1]);

    if (major === ScriptService.SAFARI_LOWEST_HEVC_MAJOR) {
      if (minor >= ScriptService.SAFARI_LOWEST_HEVC_MINOR) {
        return true;
      }
    } else if (major > ScriptService.SAFARI_LOWEST_HEVC_MAJOR) {
      return true;
    }
    return false;
  },

  getMacOsVersion() {
    const reg = /\(Macintosh; Intel Mac OS X [0-9_]+\)/;
    return (reg.exec(navigator.userAgent || '') || [''])[0]
      .replace('(Macintosh; Intel Mac OS X ', '')
      .replace(')', '')
      .replace(/_/g, '.');
  },

  isCasting() {
    return Boolean(getCastSession());
  },

  canCast() {
    /* eslint-disable @typescript-eslint/dot-notation */
    return (
      ScriptService._isChrome() &&
      !ScriptService.onMobile() &&
      window['cast'] &&
      window['chrome'].cast &&
      window['chrome'].cast.AutoJoinPolicy
    );
    /* eslint-enable @typescript-eslint/dot-notation */
  },

  isFullscreen: function () {
    /* eslint-disable @typescript-eslint/dot-notation */
    return Boolean(
      document.fullscreen ||
        document['webkitIsFullScreen'] ||
        document['mozFullScreen'] ||
        document['msFullscreenElement'] ||
        document.fullscreenElement,
    );
    /* eslint-enable @typescript-eslint/dot-notation */
  },

  onMobile: function () {
    return new MobileDetect(window.navigator.userAgent).mobile() !== null;
  },

  onPhone: function () {
    return new MobileDetect(window.navigator.userAgent).phone() !== null;
  },

  getBrowserName: function () {
    switch (true) {
      case ScriptService._isMicrosoftEdge():
        return 'Microsoft Edge';
      case ScriptService._isEdgeChromium():
        return 'Edge Chromium';
      case ScriptService._isChrome():
        return 'Google Chrome';
      case ScriptService._isFirefox():
        return 'Mozilla Firefox';
      case ScriptService._isSafari():
        return 'Apple Safari';
      case ScriptService._isIE():
        return 'Microsoft IE';
      case ScriptService._isOpera():
        return 'Opera';
    }
    return 'Unknown';
  },

  getBrowserType: function () {
    switch (true) {
      case ScriptService._isMicrosoftEdge():
      case ScriptService._isEdgeChromium():
        return 'edge';
      case ScriptService._isChrome():
        return 'chrome';
      case ScriptService._isFirefox():
        return 'firefox';
      case ScriptService._isSafari():
        return 'safari';
      case ScriptService._isOpera():
        return 'opera';
      case ScriptService._isIE():
        return 'IE';
    }
    return 'unknown';
  },

  _isSafari: function () {
    if (navigator.userAgent.indexOf('Safari') > 0 && navigator.userAgent.indexOf('Chrome') < 0) {
      // Safari (because Chrome spoofs a Safari in their userAgent, we have to ensure that it isn't Chrome)
      return true;
    }
    return false;
  },

  _isIE: (userAgent?: string) => {
    const ua = userAgent || navigator.userAgent;
    // rv:11.0 = IE 11
    return ua.toLowerCase().indexOf('MSIE') > -1 || ua.toLowerCase().indexOf('rv:11.0') > -1;
  },

  _isFirefox: (userAgent?: string) => {
    const ua = userAgent || navigator.userAgent;
    return ua.toLowerCase().indexOf('firefox') > -1;
  },

  _isOpera: (userAgent?: string) => {
    const ua = userAgent || navigator.userAgent;
    return ua.indexOf('OPR') > -1;
  },

  _isMicrosoftEdge: function (useragent?: string) {
    const ua = useragent || navigator.userAgent;
    return ua.indexOf('Edge/') > -1;
  },

  _isEdgeChromium: function (useragent?: string) {
    const ua = useragent || navigator.userAgent;
    return ua.indexOf('Edg/') > -1;
  },

  _isChrome: (userAgent?: string) => {
    const ua = userAgent || navigator.userAgent;
    return (
      ua.toLowerCase().indexOf('chrome') > -1 &&
      ua.toLowerCase().indexOf('edge') === -1 && // not edge
      ua.toLowerCase().indexOf('opr') === -1
    ); // not opera
  },
};

export { ScriptService };
