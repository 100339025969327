import { channelKeys } from './keys';
import { useMutation, useQueryClient } from 'react-query';
import { addAssetToFavourites, removeAssetFromFavourites } from '../../api/favorites';
import { Channel, ContentType } from '../../interfaces';

interface UseToggleFavoriteChannelArguments {
  callback?: () => void;
}
/**
 * For Typescript to properly infer the types for all the callbacks -
 * we need to explicitly define the argument type of the callback `onMutate`
 */
interface UseToggleFavoriteChannelMutateArguments {
  channelId: string;
  isFavorited: boolean;
}

/**
 * Toggles the favorite status of a channel
 * @param Object containing the options for the mutation
 * @returns Mutation result
 */
export function useToggleFavoriteChannel({ callback }: UseToggleFavoriteChannelArguments = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ channelId, isFavorited }) => {
      if (typeof channelId !== 'string') {
        throw new Error('The useToggleFavorite mutation expects a channelId string.');
      }

      if (isFavorited) {
        return removeAssetFromFavourites(channelId, ContentType.CHANNEL);
      }

      return addAssetToFavourites(channelId, ContentType.CHANNEL);
    },
    {
      onMutate: ({ channelId, isFavorited }: UseToggleFavoriteChannelMutateArguments) => {
        const previousChannels = queryClient.getQueryData<Channel[]>(channelKeys.all);

        queryClient.setQueryData<Channel[] | undefined>(channelKeys.all, (old) => {
          const channelIndex = old?.findIndex((c) => c.contentId === channelId);

          if (channelIndex && old) {
            const channel = old[channelIndex];
            channel.isfavorited = isFavorited ? '0' : '1';
          }

          return old;
        });

        return { previousChannels };
      },
      onError: (_, __, ctx) => {
        if (ctx?.previousChannels) {
          queryClient.setQueryData<Channel[]>(channelKeys.all, ctx.previousChannels);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(channelKeys.all);
        if (callback) {
          callback();
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(channelKeys.all);
      },
    },
  );
}
