// Helpers for handling js fetch
import { reAuthenticate } from '../api/auth';
import { debounce } from 'debounce';
import { HeartBitResponse } from '../interfaces';

export function status(response: Response): Promise<Response | Object> {
  if (response.status === 204) {
    return Promise.resolve({ status: 'empty' });
  } else if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response.statusText);
  }
}

export function aib400Status(response: Response): Promise<Response | Object> {
  if (response.status === 400) {
    return Promise.resolve(response);
  } else {
    return status(response);
  }
}

export function json(response: Response) {
  if (!response.json) {
    return response;
  } else {
    return response.json().then((jsonResponse) => authCheck(jsonResponse));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function authCheck(jsonResponse: any) {
  if (jsonResponse.retcode && jsonResponse.retcode === '-2') {
    throw jsonResponse.retcode; // -2 expired session
  } else {
    return jsonResponse;
  }
}

const cookieCheckerName = 'third-party-cookie-checker';
export function thirdParyCookiesResetter(response: HeartBitResponse): Promise<HeartBitResponse> {
  // on heartbet success reset this value
  localStorage.setItem(cookieCheckerName, '0');
  return Promise.resolve(response);
}

export function getCookieFailedCount(): string {
  return localStorage.getItem(cookieCheckerName) || '0';
}

export function fetchError(errorString: string) {
  // session is expired
  if (errorString === '-2' || errorString === '-3') {
    let cookieFailedCount = Number(getCookieFailedCount());
    let currentLocation = window.location.href;
    cookieFailedCount++;
    localStorage.setItem(cookieCheckerName, cookieFailedCount.toString());
    if (cookieFailedCount >= 4) {
      if (currentLocation.indexOf('localhost') !== -1) {
        console.error('Session Error NO COOKIE support, will redirect in production!');
      } else if (currentLocation.indexOf('tv.altibox.no') !== -1) {
        window.location.href = 'https://tv.altibox.net' + window.location.pathname;
      } else if (currentLocation.indexOf('tv.altibox.dk') !== -1) {
        window.location.href = 'https://tv.altibox.net' + window.location.pathname;
      } else if (currentLocation.indexOf('tvbeta.altibox.no') !== -1) {
        window.location.href = 'https://tvbeta.altibox.net' + window.location.pathname;
      } else if (currentLocation.indexOf('tvbeta.altibox.dk') !== -1) {
        window.location.href = 'https://tvbeta.altibox.net' + window.location.pathname;
      }
    } else {
      console.error('Session Error:', errorString);
      debounce(reAuthenticate()(), 1);
    }
  } else {
    console.error('Request failed', errorString);
  }

  return Promise.reject(errorString);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getDataFromResponse<T = any>(response: Response) {
  const resStatus = (await status(response)) as Response;
  return (await json(resStatus)) as T;
}
