import { routes } from '../config';
import { GAcategory } from '../interfaces';

export function getGAcategoryBasedOnRoute(route: string): GAcategory {
  route = '/' + route;
  switch (route) {
    case routes.vod.base:
      return GAcategory.moviesandseries;
    case routes.programarchive.base:
      return GAcategory.programarchive;
    case routes.tv.base:
      return GAcategory.watchtv;
    case routes.pvr.base:
      return GAcategory.pvr;
    case routes.svod.base:
      return GAcategory.streamingServices;
    case routes.mycontent.base:
      return GAcategory.mycontent;
    case routes.tvguide.base:
      return GAcategory.tvguide;
    default:
      return GAcategory.frontpage;
  }
}
