/* eslint-disable */
var BASE64 = {};

var T = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

var encodeArray = function encodeArray(u) {
  var i = 0;
  var a = [];
  var n = 0 | (u.length / 3);
  while (0 < n--) {
    var v = (u[i] << 16) + (u[i + 1] << 8) + u[i + 2];
    i += 3;
    a.push(T.charAt(63 & (v >> 18)));
    a.push(T.charAt(63 & (v >> 12)));
    a.push(T.charAt(63 & (v >> 6)));
    a.push(T.charAt(63 & v));
  }
  if (2 == u.length - i) {
    var v = (u[i] << 16) + (u[i + 1] << 8);
    a.push(T.charAt(63 & (v >> 18)));
    a.push(T.charAt(63 & (v >> 12)));
    a.push(T.charAt(63 & (v >> 6)));
    a.push('=');
  } else if (1 == u.length - i) {
    var v = u[i] << 16;
    a.push(T.charAt(63 & (v >> 18)));
    a.push(T.charAt(63 & (v >> 12)));
    a.push('==');
  }
  return a.join('');
};
var R = (function () {
  var a = [];
  for (var i = 0; i < T.length; ++i) {
    a[T.charCodeAt(i)] = i;
  }
  a['='.charCodeAt(0)] = 0;
  return a;
})();
var decodeArray = function decodeArray(s) {
  var i = 0;
  var u = [];
  var n = 0 | (s.length / 4);
  while (0 < n--) {
    var v =
      (R[s.charCodeAt(i)] << 18) +
      (R[s.charCodeAt(i + 1)] << 12) +
      (R[s.charCodeAt(i + 2)] << 6) +
      R[s.charCodeAt(i + 3)];
    u.push(255 & (v >> 16));
    u.push(255 & (v >> 8));
    u.push(255 & v);
    i += 4;
  }
  if (u) {
    if ('=' == s.charAt(i - 2)) {
      u.pop();
      u.pop();
    } else if ('=' == s.charAt(i - 1)) {
      u.pop();
    }
  }
  return u;
};
var ASCII = {};
ASCII.encode = function (s) {
  var u = [];
  for (var i = 0; i < s.length; ++i) {
    u.push(s.charCodeAt(i));
  }
  return u;
};
ASCII.decode = function (u) {
  for (var i = 0; i < s.length; ++i) {
    a[i] = String.fromCharCode(a[i]);
  }
  return a.join('');
};
BASE64.decodeArray = function (s) {
  var u = decodeArray(s);
  return new Uint8Array(u);
};
BASE64.encodeArray = function (s) {
  return encodeArray(s);
};
BASE64.encodeASCII = function (s) {
  var u = ASCII.encode(s);
  return encodeArray(u);
};
BASE64.decodeASCII = function (s) {
  var a = decodeArray(s);
  return ASCII.decode(a);
};
BASE64.encode = function (s) {
  var u = UTF8.encode(s);
  return encodeArray(u);
};
BASE64.decode = function (s) {
  var u = decodeArray(s);
  return UTF8.decode(u);
};

export function ticklePSSHBox(pssh) {
  var decodedPSSH = BASE64.decodeArray(pssh);
  var oldBuffer = decodedPSSH.buffer;
  var oldDV = new DataView(oldBuffer);
  if (oldDV.getUint32(4) !== 0x70737368) {
    // create new pssh box
    var oldbox = new Uint8Array(oldBuffer);
    var oldsize = oldbox.length;
    var oldcursor = 0;
    var newsize = oldsize + 32; // new pssh box have more 32 byte data than old one.
    var newbuffer = new ArrayBuffer(newsize);
    var newdv = new DataView(newbuffer);
    var newbox = new Uint8Array(newbuffer);
    var vmxcursor = 0;
    var i = 0;

    // set new pssh size, 4 bytes
    newdv.setUint32(vmxcursor, newsize);
    vmxcursor += 4;

    // set pssh, 4 bytes
    newdv.setUint32(vmxcursor, 0x70737368);
    vmxcursor += 4;

    // set version, 4 bytes
    newdv.setUint32(vmxcursor, 0);
    vmxcursor += 4;

    // set widevine systemID: edef8ba9-79d6-4ace-a3c8-27dcd51d21ed, 16 bytes
    newdv.setUint32(vmxcursor, 0xedef8ba9);
    vmxcursor += 4;
    newdv.setUint32(vmxcursor, 0x79d64ace);
    vmxcursor += 4;
    newdv.setUint32(vmxcursor, 0xa3c827dc);
    vmxcursor += 4;
    newdv.setUint32(vmxcursor, 0xd51d21ed);
    vmxcursor += 4;

    // set old vmx box size, 4 bytes
    newdv.setUint32(vmxcursor, oldsize);
    vmxcursor += 4;

    // copy old vmx box content to new one
    for (i = 0; i < oldsize; i++) {
      newbox[vmxcursor++] = oldbox[oldcursor++];
    }

    var newbase64 = BASE64.encodeArray(newbox);
    return newbase64;
    /* tslint:enable */
  }
  return undefined;
}
/* eslint-enable */
