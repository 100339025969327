function ie11URL(url: string) {
  return {
    searchParams: {
      get: function (name: string) {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(url);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
      },
    },
  };
}
export function setupIE11Polyfills() {
  try {
    const isURL = new URL('https://tv.altibox.no');
    return Boolean(isURL);
  } catch (e) {
    Object.defineProperty(window, 'URL', {
      value: ie11URL,
    });
    return true;
  }
}
