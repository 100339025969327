import React, { Component, ChangeEvent } from 'react';
import './style.scss';
import { getBaseUrl, getSessionTicket, searchConfig } from '../../config';
import { Helmet } from 'react-helmet-async';
import StickyHeader from '../../views/app/StickyHeader';
import Header from '../../views/app/Header';
import { HeaderDisplayType, HeaderInteractionType } from '../../interfaces';
import { RouteComponentProps } from 'react-router';

interface RouteProps {
  payload: string;
}

type State = {
  version: string;
  endpoint: string;
  payload: string;
  response: string;
  link: string;
};

class APIEndpointTesting extends Component<RouteComponentProps<RouteProps>, State> {
  // Strict Class Initialization ignored
  versionRef!: HTMLInputElement | null;
  endpointRef!: HTMLInputElement | null;
  payloadRef!: HTMLTextAreaElement | null;

  state: State = {
    version: '',
    endpoint: '',
    payload: '',
    response: '',
    link: '',
  };

  componentDidMount() {
    let payloadFromLocalstorage = this.getLocalstoragePayload();
    if (this.props.match.params.payload) {
      try {
        let payload = atob(this.props.match.params.payload);
        if (btoa(payload) === this.props.match.params.payload) {
          let content = JSON.parse(payload);
          content.payload = JSON.parse(content.payload);
          this.changeVersion(content.version);
          this.changeEndpoint(content.endpoint);
          this.changePayload(content.payload);

          this.versionRef!.defaultValue = content.version;
          this.endpointRef!.defaultValue = content.endpoint;
          this.payloadRef!.defaultValue = content.payload;
          this.setState({ link: window.location.href + '/' + this.props.match.params.payload });
        } else {
          throw new Error('Could not parse the payload');
        }
      } catch (e) {
        alert(e);
      }
    } else if (payloadFromLocalstorage) {
      try {
        let payload = atob(payloadFromLocalstorage);
        if (btoa(payload) === payloadFromLocalstorage) {
          let content = JSON.parse(payload);
          content.payload = JSON.parse(content.payload);
          this.changeVersion(content.version);
          this.changeEndpoint(content.endpoint);
          this.changePayload(content.payload);

          this.versionRef!.defaultValue = content.version;
          this.endpointRef!.defaultValue = content.endpoint;
          this.payloadRef!.defaultValue = content.payload;
        }
      } catch (e) {
        // do nothing :))
      }
    }
  }

  changeVersion(value: string) {
    this.setState({ version: value }, () => this.updateLocalstoragePayload());
  }
  changeEndpoint(value: string) {
    this.setState({ endpoint: value }, () => this.updateLocalstoragePayload());
  }
  changePayload(value: string) {
    this.setState({ payload: value }, () => this.updateLocalstoragePayload());
  }

  updateLocalstoragePayload() {
    const { version, endpoint, payload } = this.state;
    const strippedPayload = payload
      .toString()
      .replace(/(\r\n|\n|\r)/gm, '')
      .replace(/\s\s+/g, ' ');
    const serializedPayload = {
      version: version,
      endpoint: endpoint,
      payload: JSON.stringify(strippedPayload),
    };
    localStorage.setItem('last_api_payload', btoa(JSON.stringify(serializedPayload)));
  }

  getLocalstoragePayload() {
    return localStorage.getItem('last_api_payload') || undefined;
  }

  send() {
    const { version, endpoint, payload } = this.state;
    if (payload.charAt(0) !== '{' || payload.charAt(payload.length - 1) !== '}') {
      alert('The payload needs to start with { and end with }');
    } else {
      const payloadObject = JSON.parse(payload);
      fetch(getBaseUrl() + '/' + version + '/' + endpoint, {
        method: 'POST',
        headers: { SessionTicket: getSessionTicket() },
        body: JSON.stringify(payloadObject),
      })
        .then((response) => response.json())
        .then((result) => this.setState({ response: result }))
        .catch(() => {
          alert('Something went wrong. Please check the input');
        });
      this.setState({ link: window.location.href + '/' + this.getLocalstoragePayload() });
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>API Endpoint Testing</title>
        </Helmet>
        <StickyHeader searchConfig={searchConfig.global} />
        <Header
          title={'API Endpoint Testing'}
          displayType={HeaderDisplayType.Solid}
          interactionType={HeaderInteractionType.Full}
        />
        <div className="endpoint-wrapper">
          <h2>V2:</h2>
          <p>EPG/JSON</p>
          <h2>V6:</h2>
          <p>VSP/V3</p>
          <h2>Test API endpoint:</h2>
          <br />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.send();
            }}
          >
            {getBaseUrl()}/
            <input
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.changeVersion(e.target.value)}
              ref={(ref) => {
                this.versionRef = ref;
              }}
            />
            /
            <input
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => this.changeEndpoint(e.target.value)}
              ref={(ref) => {
                this.endpointRef = ref;
              }}
            />
            <h2>Payload:</h2>
            <textarea
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => this.changePayload(e.target.value)}
              ref={(ref) => {
                this.payloadRef = ref;
              }}
            />
            <br />
            <button
              onClick={(e) => {
                e.preventDefault();
                this.send();
              }}
            >
              Submit
            </button>
          </form>
          <br />
          <br />
          Direct link to this query:
          <br />
          <div className="pretty-results">{this.state.link}</div>
          <br />
          <br />
          <br />
          <button
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(this.state.link);
            }}
          >
            Copy link
          </button>
          <h2>Results (RAW):</h2>
          <textarea
            value={JSON.stringify(this.state.response)}
            onChange={() => {
              return;
            }}
          />
          <br />
          <button
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(this.state.response));
            }}
          >
            Copy JSON
          </button>{' '}
          &nbsp; &nbsp;
          <button
            onClick={() => {
              this.setState({ response: '' });
            }}
          >
            Clear JSON
          </button>
          <br />
          <br />
          <h2>Results (Pretty)</h2>
          <br />
          <div className="pretty-results">{JSON.stringify(this.state.response, null, 4)}</div>
        </div>
      </>
    );
  }
}

export default APIEndpointTesting;
