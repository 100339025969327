import React, { useState, useEffect, RefCallback, useCallback, useRef } from 'react';
import { useGesture } from 'react-use-gesture';
import { Link, useLocation } from 'react-router-dom';
import { Key } from 'ts-key-enum';

import { broadcastingNow } from '../../../utils/huaweiUtils';
import { getChannelLogoUrl } from '../../../utils/tvUtils';
import { routes } from '../../../config';

import { SvodChannel as ChannelType, MiniEpgPlaybills, ChannelLogoType } from '../../../interfaces';

import './style.scss';

interface ChannelProps {
  name: string;
  logoUrl: string;
  focusedChannel: boolean;
  currentProgram: string;
  linkTo: string;
  hasSvodService: boolean;
  handleClick: () => void;
}

const Channel = React.forwardRef<HTMLAnchorElement, ChannelProps>(
  ({ name, logoUrl, focusedChannel, currentProgram, linkTo, hasSvodService, handleClick }, ref) => {
    const className = `${focusedChannel ? 'focusedChannel' : ''} ${hasSvodService ? 'hasSvodService' : ''}`;
    const newRef = focusedChannel ? ref : null;

    const location = useLocation<{ prevPath: string | undefined }>();

    const renderLogo = () => {
      return logoUrl ? <img alt={name} src={logoUrl} /> : name;
    };

    return (
      <li>
        <Link
          onClick={handleClick}
          tabIndex={-1} // tabbing through channels does not work well when hijacking scroll.
          ref={newRef}
          to={{
            pathname: linkTo,
            state: { prevPath: location.state?.prevPath ? location.state.prevPath : location.pathname },
          }}
          className={className}
        >
          {renderLogo()}
          <span className={'channelList_currentProgram'}>{currentProgram}</span>
        </Link>
      </li>
    );
  },
);

interface ChannelListProps {
  channels: ChannelType[];
  playbills: MiniEpgPlaybills;
  focusedChannel: ChannelType;
  setFocusedChannel: React.Dispatch<React.SetStateAction<ChannelType | undefined>>;
  visible: boolean;
  shouldShowEpg: boolean;
  showEpg: () => void;
  hideEpg: () => void;
  panic: () => void;
}

function padChannelsList(count: number, list: ChannelType[]): ChannelType[] {
  return [...list.slice(list.length - 1 - count), ...list];
}

const ChannelList = ({
  focusedChannel,
  setFocusedChannel,
  playbills,
  hideEpg,
  showEpg,
  shouldShowEpg,
  visible,
  panic,
  ...props
}: ChannelListProps) => {
  const [channels, setChannels] = useState({
    channels: props.channels,
    padded: false,
  });

  const [visibleChannelsCount, setVisibleChannelsCount] = useState(0);
  const [channelHeight, setChannelHeight] = useState(0);
  const [focusBoxPos, setFocusBoxPos] = useState(0);
  const [snapPoints, setSnapPoints] = useState([] as number[]);

  const [listEl, setListEl] = useState<HTMLUListElement | null>(null);
  const listRef: RefCallback<HTMLUListElement> = useCallback((element) => setListEl(element), []);
  const listNavButtonRef = useRef<HTMLButtonElement>(null);
  const focusBoxRef = useRef<HTMLDivElement>(null);
  const focusedChannelRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const focusedChannelEl = focusedChannelRef.current;
    const focusBoxEl = focusBoxRef.current;
    const listNavButtonEl = listNavButtonRef.current;

    if (listEl && focusedChannelEl) {
      const chanHeight = focusedChannelEl.offsetHeight;
      const listHeight = listEl.offsetHeight;
      const visibleChannels = listHeight / chanHeight;

      setChannelHeight(chanHeight);
      setVisibleChannelsCount(visibleChannels);
    }

    if (focusBoxEl && listNavButtonEl) {
      setFocusBoxPos(focusBoxEl.offsetTop - listNavButtonEl.offsetHeight);
    }
  }, [listEl, focusedChannelRef, setVisibleChannelsCount, setChannelHeight]);

  useEffect(() => {
    if (visibleChannelsCount) {
      setChannels((state) => ({
        channels: padChannelsList(visibleChannelsCount, state.channels),
        padded: true,
      }));
    }
  }, [visibleChannelsCount]);

  useEffect(() => {
    if (channels.padded && focusBoxPos) {
      setSnapPoints(channels.channels.map((_, i) => i * channelHeight - (focusBoxPos % channelHeight)));
    }
  }, [channels, focusBoxPos, channelHeight]);

  useEffect(() => {
    const focusedChannelEl = focusedChannelRef.current;

    if (!visible && listEl && focusedChannelEl && channels.padded) {
      const scrollPos = focusedChannelEl.offsetTop - focusBoxPos;
      const paddingHeight = visibleChannelsCount * channelHeight;
      const scrollHeight = listEl.scrollHeight;

      if (paddingHeight + scrollPos >= scrollHeight) {
        window.requestAnimationFrame(
          () => (listEl.scrollTop = paddingHeight + scrollPos + channelHeight + channelHeight / 2 - scrollHeight),
        );
      } else {
        window.requestAnimationFrame(() => (listEl.scrollTop = focusedChannelEl.offsetTop - focusBoxPos));
      }
    }
  }, [listEl, channels, focusedChannelRef, focusBoxPos, channelHeight, visibleChannelsCount, visible, focusedChannel]);

  const handleUp = () => {
    if (listEl) {
      window.requestAnimationFrame(() => listEl.scrollBy({ top: -channelHeight, behavior: 'smooth' }));
    }
  };

  const handleDown = () => {
    if (listEl) {
      window.requestAnimationFrame(() => listEl.scrollBy({ top: channelHeight, behavior: 'smooth' }));
    }
  };

  const bindGestures = useGesture({
    onScrollStart: () => hideEpg(),
    onScrollEnd: ({ offset: [, offset] }) => {
      if (!listEl) {
        return;
      }

      const snapTo = snapPoints.filter((point) => point - offset >= -channelHeight / 2).sort((a, b) => a - b)[0];

      if (snapTo - offset <= 1 && snapTo - offset >= -1) {
        const children = Array.from(listEl.children) as HTMLElement[];
        const currentEl = children.find((li) => li.offsetTop >= listEl.scrollTop + focusBoxPos);

        if (currentEl) {
          const idx = children.indexOf(currentEl);
          const channel = channels.channels[idx];

          if (!channel) {
            return panic();
          } else {
            setFocusedChannel(channel);
            showEpg();
          }
        }
      } else {
        window.requestAnimationFrame(() => listEl.scrollTo({ top: snapTo, behavior: 'smooth' }));
      }
    },
    onScroll: ({ offset: [, offset] }) => {
      window.requestAnimationFrame(loopList);

      function loopList() {
        if (!listEl) {
          return;
        }

        const scrollPos = Math.round(offset);
        const scrollHeight = listEl.scrollHeight;
        const listHeight = listEl.offsetHeight;
        const paddingHeight = visibleChannelsCount * channelHeight;

        if (paddingHeight + scrollPos >= scrollHeight) {
          listEl.scrollTop = listHeight - focusBoxPos - channelHeight + channelHeight / 2;
        } else if (scrollPos <= 0) {
          listEl.scrollTop =
            scrollHeight - (Math.floor(visibleChannelsCount) * channelHeight + (listHeight - focusBoxPos));
        }
      }
    },
    onKeyDown: (e) => {
      const { key } = e;

      if (key === Key.Enter) {
        const focusedChannelEl = focusedChannelRef.current;

        if (focusedChannelEl) {
          focusedChannelEl.click();
        }
      }
    },
  });

  const renderChannels = () => {
    return channels.channels.map((channel, idx) => {
      const playbill = playbills[channel.contentId] || [];
      const currentProgram = playbill.find(broadcastingNow);
      const currentProgramName = currentProgram ? currentProgram.name : '';
      const logoUrl = getChannelLogoUrl(channel, ChannelLogoType.CROPPED);
      const hasSvodService = channel.svodKiosk !== undefined;

      const handleClick = () => setFocusedChannel(channel);

      return (
        <Channel
          key={channel.name + idx}
          ref={focusedChannelRef}
          focusedChannel={channel.contentId === focusedChannel.contentId}
          name={channel.name}
          logoUrl={logoUrl}
          currentProgram={currentProgramName}
          linkTo={`${routes.tv.base}/${channel.contentId}`}
          hasSvodService={hasSvodService}
          handleClick={handleClick}
        />
      );
    });
  };

  const channelListClass = `playerEpg_channelList ${visible ? '' : 'transparent'} ${shouldShowEpg ? '' : 'scrolling'}`;
  return (
    <div className={channelListClass}>
      <div ref={focusBoxRef} className="focusBox" />
      <button className="navButton" ref={listNavButtonRef} onClick={handleUp}>
        <span>u</span>
      </button>
      <ul {...bindGestures()} ref={listRef} tabIndex={0}>
        {renderChannels()}
      </ul>
      <button className="navButton" onClick={handleDown}>
        <span>U</span>
      </button>
    </div>
  );
};

export default ChannelList;
