import { useTranslation } from 'react-i18next';
import Modal from '.';
import { useConfirm } from '../../hooks/useConfirm';
import { SvgClose } from '../../icons';
import { ButtonSecondary } from '../UI/Buttons';
import styles from './styles/ConfirmModal.module.scss';

export function ConfirmModal() {
  const { t } = useTranslation();
  const { title = t('confirm'), prompt = '', isOpen = false, proceed, cancel } = useConfirm();

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      onRequestClose={cancel}
      onAfterClose={cancel}
      className={styles.modal}
    >
      <h1 className={styles.title}>{title}</h1>

      <button className={styles.buttonClose} onClick={cancel} children={<SvgClose />} />

      <main className={styles.mainContainer}>
        <p>{prompt}</p>

        <ButtonSecondary onClick={proceed}>{t('confirm')}</ButtonSecondary>
      </main>
    </Modal>
  );
}
