import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes, swimlaneSettings } from '../../config';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';
import i18n from '../../i18n';
import see_all_image from '../../images/see-all-landscape.svg';
import { StreamingServicePortal, StreamingPortalsType, PortalMenu, GAaction } from '../../interfaces';
import {
  getSvodModalSetting,
  ThirdPartyPopUp,
  gaSvodTracking,
  getSvodLinkPath,
  getSvodImageLogo,
  skipModalByConditionType,
} from '../../utils/svodUtil';
import style from './StreamingPortalsItem.module.scss';

interface Props {
  portal: StreamingServicePortal;
  defaultImagePath: string;
  svodKiosks: PortalMenu[];
  swimlaneItemNumber?: number;
  handleExternalPortal?: (portal: StreamingServicePortal) => void;
}

const StreamingPortalsItem: React.FC<Props> = ({
  portal,
  defaultImagePath,
  svodKiosks,
  swimlaneItemNumber,
  handleExternalPortal,
}: Props) => {
  const [skipModal, setSkipModal] = useState<boolean>(false);
  const tracking = useRef<AnalyticsTracking | null>(null);
  const { deeplink, svodPageLink } = getSvodLinkPath(portal, svodKiosks);
  const hideModal = getSvodModalSetting() === ThirdPartyPopUp.HIDE;

  useEffect(() => {
    if (hideModal || (portal && skipModalByConditionType(portal))) {
      setSkipModal(true);
    }
  }, [hideModal, portal]);

  useEffect(() => {
    tracking.current = AnalyticsTracking.getInstance();
  }, []);

  const shouldSkipModal = (): boolean => {
    return Boolean(portal.type === StreamingPortalsType.EXTERNAL && skipModal);
  };

  const linkPath = shouldSkipModal() ? deeplink : svodPageLink;
  const svodImageLogo = getSvodImageLogo(portal, defaultImagePath);

  const handleLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const currentCategory = tracking.current?.getCurrentCategory();

    if (portal.type === StreamingPortalsType.EXTERNAL && !skipModal) {
      e.preventDefault();
      handleExternalPortal && handleExternalPortal(portal);
    }
    gaSvodTracking(
      currentCategory ?? AnalyticsTracking.getInstance().getCurrentCategory(),
      GAaction.openService,
      portal,
      shouldSkipModal(),
    );
  };

  if (swimlaneItemNumber === swimlaneSettings.limit) {
    return (
      <li className={style.see_all_container + ' aib-hover-glow'}>
        <Link to={routes.svod.base + '/'} onMouseDown={(e) => e.preventDefault()}>
          <img src={see_all_image} alt={i18n.t('see all')} loading="lazy" />
          <div className={style.see_all_text}>{i18n.t<string>('see all')}</div>
        </Link>
      </li>
    );
  }

  return (
    <li className={style.item_container}>
      <Link
        to={{
          pathname: linkPath,
          state: { prevPath: window.location.pathname },
        }}
        draggable={false}
        onMouseDown={(e) => e.preventDefault()}
        onClick={(e) => handleLink(e)}
        target={portal.type === StreamingPortalsType.EXTERNAL && skipModal ? '_blank' : ''}
        rel="noreferrer"
      >
        <div draggable={false} className="aib-hover-glow">
          <img draggable={false} alt={portal.titles?.en} src={svodImageLogo} loading="lazy" />
        </div>
      </Link>
    </li>
  );
};

export default StreamingPortalsItem;
