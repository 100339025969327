import React from 'react';
import debounce from 'debounce';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';

import MobileLock from '../app/MobileLock';

import channelPermissions from '../../utils/channelPermissions';
import sortChannels from '../../utils/sortChannels';

import PlayerStreamManager from './PlayerStreamManager';
import ActiveChannel from './../../components/ActiveChannel';
import PlayerEpg from '../../components/PlayerEpg';

import {
  getChannels,
  fetchAllPlaybills,
  getRecordings,
  playChannel,
  playCatchup,
  updateCurrentLiveProgram,
  SetCurrentLiveProgramAction,
  hideChannelList,
  resetChannelGuards,
  hideProgramDetails,
  unloadCurrentLiveProgram,
} from './actions';
import ChannelLocked from './ChannelLocked';
import {
  Program,
  RootState,
  Channel,
  ApiAuthResponse,
  BaseAction,
  KeyEvent,
  HeaderDisplayType,
  HeaderInteractionType,
  SearchConfig,
  GAaction,
  DetailsPanelType,
} from '../../interfaces';
import { showLoginModal } from '../app/actions';
import Header from '../app/Header';

import './style/tv.scss';
import { ScriptService } from './../../controllers/ScriptService';
import { Helmet } from 'react-helmet-async';
import StickyHeader from '../app/StickyHeader';
import { routes, searchConfig } from '../../config';
import i18n from '../../i18n';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';
import { showDetailsPanel, hideDetailsPanel, unloadAsset, ShowDetailsPanel } from '../details/actions';
import { SlideInDetails } from '../details';
import { getCastSession } from '../../components/Player/helpers/ChromeCast';

interface RouteProps {
  channelId: string;
  catchupId: string;
}

interface Props
  extends RouteComponentProps<
    RouteProps,
    {},
    { prevPath: string | undefined; showDetailsPanel?: Omit<ShowDetailsPanel, 'type'> }
  > {
  isGuest: boolean;
  auth: ApiAuthResponse | undefined;
  channels: Channel[];
  sortedChannels: Channel[];
  currentChannel: Channel | undefined;
  currentProgram: Program | undefined;
  playbillsLoaded: boolean;
  currentChannelIsLocked: boolean;
  shouldFadeOut: boolean;
  channelListVisible: boolean;
  loggedInWithCredentials: boolean;
  startingCatchup: boolean;
  channelNotFound: boolean;
  catchupDataIsLoading: boolean;
  catchupNotFound: boolean;
  hasPvr: boolean;
  liveFromStart: boolean;
  programSet: boolean;
  detailsPanel: DetailsPanelType | undefined;
  isSlidingOut: boolean | undefined;
  searchConfig: SearchConfig;

  showDetailsPanel: (showDetailsPanelProps: Omit<ShowDetailsPanel, 'type'>) => void;
  hideDetailsPanel: () => void;
  hideChannelList: () => void;
  updateCurrentLiveProgram: () => SetCurrentLiveProgramAction;
  showLoginModal: () => BaseAction;
  loadPrograms: () => Promise<void>;
  loadContent: (_: boolean) => Promise<void>;
  onProgramEnded: (_: string) => void;
  playChannel: (_1: string, _2: boolean) => Promise<void>;
  playCatchup: (_1: string, _2: string, _3?: boolean) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetChannelGuards: () => any;
}

interface State {
  renderPlayer: boolean;
  prevPath: string;
  currentChannelId?: string;
  currentProgram?: Program;
  redirectToUrl?: string;
}

class Tv extends React.Component<Props, State> {
  hasTracked = false;

  tracking = AnalyticsTracking.getInstance();

  state: State = {
    renderPlayer: true,
    prevPath: this.props.location.pathname,
    currentChannelId: undefined,
    currentProgram: undefined,
    redirectToUrl: undefined,
  };

  redirectToChannel: Function = debounce((channelId: string) => {
    if (ScriptService.isFullscreen()) {
      this.playAndTrackChannel(channelId, false);
    } else {
      this.setState({
        redirectToUrl: routes.tv.base + '/' + channelId,
      });
    }
  }, 100);

  get overlay() {
    const { loggedInWithCredentials } = this.props;
    if (this.props.currentChannelIsLocked && this.props.currentChannel) {
      return (
        <div className={'channellock-wrapper'} onClick={this.clickOutsideChannellistForLockedChannels}>
          <ChannelLocked onMobile={false} canLogin={!loggedInWithCredentials} />
        </div>
      );
    } else {
      return null;
    }
  }

  playAndTrackChannel(channelId: string, liveFromStart: boolean) {
    if (!this.props.currentChannelIsLocked && this.props.currentChannel) {
      if (liveFromStart) {
        this.tracking.trackEvent(
          this.tracking.getCurrentCategory(),
          GAaction.playback_jumpToStart,
          this.props.currentChannel.name + ' - ' + this.props.currentChannel.contentId,
          true,
        );
      } else {
        this.tracking.trackEvent(
          this.tracking.getCurrentCategory(),
          GAaction.playChannel,
          this.props.currentChannel.name + ' - ' + this.props.currentChannel.contentId,
          true,
        );
      }
    }
    this.props.playChannel(channelId, liveFromStart);
  }

  onFullScreenChange = () => {
    if (!ScriptService.isFullscreen() && !this.props.match.params.catchupId) {
      this.props.history.push(routes.tv.base + '/' + this.props.currentChannel!.contentId);
    }
  };

  playByRoute(props: Props) {
    const params = props.match.params;
    const isChangingPath = this.state.prevPath !== props.location.pathname;

    if (isChangingPath && this.props.location.pathname.includes(routes.tv.base)) {
      this.handlePlayerUnmount();
    }

    if (params.catchupId === 'start') {
      this.playAndTrackChannel(params.channelId, true);
    } else if (params.channelId && params.catchupId) {
      props.playCatchup(params.channelId, params.catchupId, this.props.currentChannelIsLocked);
    } else if (params.channelId) {
      this.playAndTrackChannel(params.channelId, false);
    }
  }

  componentDidMount() {
    if (this.props.channels.length > 0 && this.props.match.params.channelId) {
      this.setState({ renderPlayer: true });
      if (!this.props.match.params.catchupId) {
        this.playAndTrackChannel(this.props.match.params.channelId, false);
      } else if (this.props.match.params.catchupId && this.props.match.params.catchupId === 'start') {
        this.playAndTrackChannel(this.props.match.params.channelId, true);
      }
    }

    this.props.loadContent(this.props.isGuest).then(() => {
      if (!this.props.match.params.catchupId || this.props.match.params.catchupId === 'start') {
        this.props.updateCurrentLiveProgram();
      }
    });

    if (this.props.location && this.props.location.state && this.props.location.state.showDetailsPanel) {
      this.props.showDetailsPanel(this.props.location.state.showDetailsPanel);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.componentDidMount();
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('fullscreenchange', this.onFullScreenChange);
    window.addEventListener('webkitfullscreenchange', this.onFullScreenChange);
    window.addEventListener('mozfullscreenchange', this.onFullScreenChange);
  }

  componentWillUnmount() {
    this.props.resetChannelGuards();
    if (!getCastSession()) {
      this.props.hideDetailsPanel();
    }
    window.removeEventListener('fullscreenchange', this.onFullScreenChange);
    window.removeEventListener('webkitfullscreenchange', this.onFullScreenChange);
    window.removeEventListener('mozfullscreenchange', this.onFullScreenChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps !== this.props) {
      if (nextProps.channelNotFound && !this.hasTracked) {
        this.tracking.trackEvent(this.tracking.getCurrentCategory(), GAaction.errorMessage, 'channelNotFound');
        this.hasTracked = true;
      }
      if (nextProps.catchupNotFound && !this.hasTracked) {
        this.tracking.trackEvent(this.tracking.getCurrentCategory(), GAaction.errorMessage, 'catchupNotFound');
        this.hasTracked = true;
      }
    }

    // reset the redirect state so we dont redirect to where we are
    this.setState({
      redirectToUrl: undefined,
    });

    const params = this.props.match.params;
    const newParams = nextProps.match.params;

    if (
      this.props.catchupDataIsLoading ||
      nextProps.catchupDataIsLoading ||
      (nextProps.currentChannel !== undefined &&
        newParams.channelId === params.channelId &&
        newParams.catchupId === params.catchupId) ||
      this.props.channelNotFound ||
      this.props.catchupNotFound ||
      nextProps.channels.length === 0
    ) {
      return;
    } else {
      this.playByRoute(nextProps);
    }
  }

  handleKeyDown = (event: KeyEvent) => {
    switch (event.keyCode) {
      case 38: // up
        this.handleUpKey();
        break;
      case 40: // down
        this.handleDownKey();
        break;
      default:
        break;
    }
  };

  getCurrentChannelIndex() {
    return this.props.sortedChannels.findIndex((x) => x.contentId === this.props.currentChannel!.contentId);
  }

  handleUpKey() {
    let currentChannelIndex = this.getCurrentChannelIndex();
    let canChangeChannelUp =
      currentChannelIndex !== -1 && currentChannelIndex !== 0 && this.props.sortedChannels[currentChannelIndex - 1];

    if (canChangeChannelUp) {
      let theChannel = this.props.sortedChannels[currentChannelIndex - 1];
      this.redirectToChannel(theChannel.contentId);
      this.tracking.trackEvent(
        this.tracking.getCurrentCategory(),
        GAaction.playPreviousChannel,
        theChannel.name + ' - ' + theChannel.contentId,
      );
    }
  }

  handleDownKey() {
    let currentChannelIndex = this.getCurrentChannelIndex();
    let canChangeChannelDown =
      currentChannelIndex !== -1 &&
      currentChannelIndex - 1 <= this.props.sortedChannels.length &&
      this.props.sortedChannels[currentChannelIndex + 1];

    if (canChangeChannelDown) {
      let theChannel = this.props.sortedChannels[currentChannelIndex + 1];
      this.redirectToChannel(theChannel.contentId);
      this.tracking.trackEvent(
        this.tracking.getCurrentCategory(),
        GAaction.playNextChannel,
        theChannel.name + ' - ' + theChannel.contentId,
      );
    }
  }

  clickOutsideChannellistForLockedChannels = () => {
    if (this.props.channelListVisible) {
      this.props.hideChannelList();
    }
  };

  handlePlayerUnmount() {
    this.setState({ renderPlayer: false });
  }

  renderStream() {
    if (this.props.channelNotFound) {
      // if someone changed the local storage manually
      localStorage.removeItem('previous_channel');

      return (
        <div style={{ color: '#fff', paddingTop: '80px', paddingLeft: '20px' }}>
          {i18n.t<string>('channel not found')}
        </div>
      );
    }

    if (this.props.catchupNotFound) {
      return (
        <div style={{ color: '#fff', paddingTop: '80px', paddingLeft: '20px' }}>
          {i18n.t<string>('catchup not found')}
        </div>
      );
    }

    const includeInFullscreen = () => {
      return (
        <>
          <ActiveChannel />
          <div className="tv-navigation-outer-container">
            <PlayerEpg />
          </div>
          {this.props.detailsPanel ? (
            <SlideInDetails
              key={
                this.props.detailsPanel.routeProps.parentId ??
                '' + this.props.detailsPanel.routeProps.childId ??
                '' + this.props.detailsPanel.displayType
              }
              isSlidingOut={this.props.isSlidingOut}
              {...this.props.detailsPanel}
            />
          ) : null}
        </>
      );
    };

    return (
      <PlayerStreamManager
        handleKeyDown={this.handleKeyDown}
        overlay={this.overlay}
        includeInFullscreen={includeInFullscreen}
        unmountPlayer={this.handlePlayerUnmount}
      />
    );
  }

  onClose = () => {
    if (this.props.location && this.props.location.state && this.props.location.state.prevPath) {
      return this.props.history.push(this.props.location.state.prevPath);
    }

    this.props.history.push('/');
  };

  render() {
    let playerSearchConfig = this.props.searchConfig ? this.props.searchConfig : searchConfig.global;
    playerSearchConfig.noFocus = true;
    if (ScriptService.onMobile()) {
      return (
        <div className="main-frame">
          <Header displayType={HeaderDisplayType.Solid} interactionType={HeaderInteractionType.CloseOnly} />
          <MobileLock deepLink="altibox://tv" />
        </div>
      );
    } else if (this.state.redirectToUrl) {
      return <Redirect to={'' + this.state.redirectToUrl} />;
    } else if (!this.props.match.params.channelId && this.props.sortedChannels.length > 0) {
      let channelIdToPlay = this.props.sortedChannels[0].contentId;

      // user has a previous channel stored
      let previousChannelItem = localStorage.getItem('previous_channel');
      if (previousChannelItem && Number.isInteger(parseInt(previousChannelItem, 10))) {
        channelIdToPlay = previousChannelItem;
      }

      return <Redirect to={routes.tv.base + '/' + channelIdToPlay} />;
    }

    return (
      <div className="main-frame">
        <Helmet>
          <title>{this.props.currentChannel ? this.props.currentChannel.name : 'TV'}</title>
        </Helmet>
        {this.props.shouldFadeOut || this.props.detailsPanel !== undefined ? null : (
          <div onClick={() => this.props.hideChannelList()}>
            {!this.props.channelListVisible && (
              <>
                <StickyHeader searchConfig={playerSearchConfig} overPlayer={true} />
                <Header
                  title="TV"
                  hideTitle={true}
                  displayType={HeaderDisplayType.Player}
                  interactionType={HeaderInteractionType.CloseOnly}
                  closeCallback={this.onClose}
                />
              </>
            )}
          </div>
        )}

        <div className="tv-container tv-player">{this.state.renderPlayer ? this.renderStream() : null}</div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    // give the Component access to the store
    (state: RootState) => {
      const { channelsReducer, authReducer } = state;
      return {
        programSet: state.channelsReducer.programDetails !== undefined,
        detailsPanel: state.detailsReducer.detailsPanel,
        isSlidingOut: state.detailsReducer.isSlidingOut,
        isGuest: authReducer.isGuest,
        hasPvr: authReducer.hasPvr as boolean,
        loggedInWithCredentials: authReducer.loggedInWithCredentials,
        auth: authReducer.auth,
        channels: channelsReducer.channels,
        catchupDataIsLoading: channelsReducer.catchupDataLoading,
        playbillsLoaded: Object.keys(channelsReducer.playbills).length > 0,
        sortedChannels: sortChannels(channelsReducer.channels, authReducer),
        currentChannel: channelsReducer.currentChannel,
        currentProgram: channelsReducer.currentProgram,
        currentChannelIsLocked: channelPermissions(channelsReducer.currentChannel, authReducer).locked,
        shouldFadeOut: state.app.shouldFadeOut,
        channelListVisible: channelsReducer.channelListVisible,
        startingCatchup: channelsReducer.startingCatchup,
        channelNotFound: channelsReducer.channelNotFound,
        catchupNotFound: channelsReducer.catchupNotFound,
        liveFromStart: channelsReducer.liveFromStart,
      };
    },
    (dispatch) => ({
      updateCurrentLiveProgram: () => dispatch(updateCurrentLiveProgram()),
      showDetailsPanel: (showDetailsPanelProps: Omit<ShowDetailsPanel, 'type'>) => {
        dispatch(showDetailsPanel(showDetailsPanelProps));
      },
      hideDetailsPanel: () => {
        dispatch(unloadAsset());
        dispatch(unloadCurrentLiveProgram());
        dispatch(hideDetailsPanel());
      },
      hideChannelList: () => {
        dispatch(hideChannelList());
        dispatch(hideProgramDetails());
      },
      loadContent: (isGuest: boolean) => {
        if (!isGuest) {
          dispatch(getRecordings());
        }
        // Update the playbill data every 10 minute
        setInterval(() => dispatch(fetchAllPlaybills()), 600000);
        return dispatch(getChannels()).then(() => dispatch(fetchAllPlaybills()));
      },
      showLoginModal: () => dispatch(showLoginModal()),
      onProgramEnded: (channelId: string) => dispatch(playChannel(channelId, false)),
      playChannel: (channelId: string, liveFromStart: boolean) => dispatch(playChannel(channelId, liveFromStart)),
      playCatchup: (channelId: string, catchupId: string, isLocked?: boolean) =>
        dispatch(playCatchup(channelId, catchupId, isLocked)),
      resetChannelGuards: () => dispatch(resetChannelGuards()),
    }),
  )(Tv),
);
