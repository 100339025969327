import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/nb';
import 'moment/locale/da';
import Modal from 'react-modal';
import { setShouldFade } from './views/app/actions';
import { setPlaybillShouldHide } from './views/tv/actions';
import { shouldPlaybillHide } from './views/tv/reducers';
import { Store } from 'redux';
import { RootState } from './interfaces';
import { getCastSession } from './components/Player/helpers/ChromeCast';

function setShouldFadeOutListener(store: Store<RootState>) {
  // Keep track of when the user stops interacting
  let userStoppedInteractingAt: moment.Moment | undefined = undefined;
  function storeUserInteraction() {
    userStoppedInteractingAt = moment();
  }
  storeUserInteraction();
  window.onmousemove = storeUserInteraction;
  // For mobile devices where there's no mouse to move
  document.onclick = storeUserInteraction;
  document.addEventListener('keydown', storeUserInteraction);

  // Determine wether elements should fade out based on:
  // the mouse not moving any more or no keys pressed
  // todo - do not create a new function each time
  setInterval(() => {
    const shouldFadeOutAt = moment(userStoppedInteractingAt).add(4.5, 'seconds');
    const shouldFadeOut = moment().isAfter(shouldFadeOutAt);
    if (shouldFadeOut !== store.getState().app.shouldFadeOut) {
      store.dispatch(setShouldFade(shouldFadeOut && !getCastSession()));
    }
  }, 250);
}

function setPlaybillShouldHideListener(store: Store<RootState>) {
  // Determine wetherplaybill needs to be hidden
  // When program details are opened on small screen
  function updatePlaybillShouldHide() {
    const showingDetails = store.getState().channelsReducer.programDetails !== undefined;
    const playbillShouldHide = shouldPlaybillHide(showingDetails);

    if (store.getState().channelsReducer.playbillShouldHide !== playbillShouldHide) {
      store.dispatch(setPlaybillShouldHide(playbillShouldHide));
    }
  }
  updatePlaybillShouldHide();
  window.onresize = updatePlaybillShouldHide;
}

/* eslint-disable-next-line */
export default (store: Store<RootState>) => {
  // Set the locale for dates
  moment.locale('nb');

  // Set the aria-hidden context for the React Modal component
  if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#root');
  }

  setShouldFadeOutListener(store);
  setPlaybillShouldHideListener(store);
};
