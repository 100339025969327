import { ScriptService } from './controllers/ScriptService';
import { Environments } from './controllers/Environments';
import {
  SearchPriority,
  AltiboxAssetType,
  GlobalSearchConfig,
  Domains,
  SubnetIds,
  AspectRatioType,
  ImageScaleValue,
  SortCategories,
  PvrSorting,
} from './interfaces';
import { UbiSecure } from './controllers/UbiSecure';
import i18next from 'i18next';

const env = Environments();
type AppEnvTypes = 'production' | 'at' | 'st';
export const APP_ENV: AppEnvTypes = env.ENV_VARS[String(process.env.REACT_APP_ENV)]
  ? env.ENV_VARS[String(process.env.REACT_APP_ENV)]
  : env.ENV_VARS.production;

if (process.env.NODE_ENV === 'development') {
  console.debug('APP_ENV: ' + APP_ENV);
  let apiUrl = localStorage.getItem('aib_api_url');
  let huaweiUrl = localStorage.getItem('huawei_url');
  let amrUrl = localStorage.getItem('amr_url');

  if (apiUrl) {
    console.debug('FROM LOCALSTORAGE - ALTIBOX API URL:' + apiUrl);
  } else {
    console.debug('FROM SETTINGS - ALTIBOX API URL:' + env.AltiboxAPIUrls[APP_ENV]);
  }

  if (huaweiUrl) {
    console.debug('FROM LOCALSTORAGE - HUAWEI:' + huaweiUrl);
  } else {
    console.debug('FROM SETTINGS - HUAWEI:' + env.HuaweiAPIUrls[APP_ENV]);
  }

  if (amrUrl) {
    console.debug('FROM LOCALSTORAGE - AMR:' + amrUrl);
  } else {
    console.debug('FROM SETTINGS - AMR:' + env.AmrAPIUrls[APP_ENV]);
  }
}

export const getDeviceName = function () {
  return 'Web Client';
};

export const V6Url = 'https://tvotteds.envision.services.altibox.net';
const altiboxApiUrlPostfix = '/altibox/api/';

export const shouldNotStretchVideo = function (contentId: string) {
  const ids = ['726'];
  return ids.indexOf(contentId) !== -1;
};

export const setSessionTicket = function (jSession: string, cSession?: string) {
  const ticket = cSession ? `JSESSIONID=${jSession}; CSESSIONID=${cSession}` : `JSESSIONID=${jSession}`;
  sessionStorage.setItem('__t', `${btoa(ticket)}`);
};

export const getSessionTicket = function () {
  if (sessionStorage.getItem('__t') !== null) {
    return atob(sessionStorage.getItem('__t')!);
  }
  return '';
};

export const setStoredGuestLocation = function (subnetId: string) {
  sessionStorage.setItem('guestLocation', subnetId);
};

export const getStoredGuestLocation = function () {
  const storedGuestLocation = Number(sessionStorage.getItem('guestLocation'));
  if (storedGuestLocation === SubnetIds.DK || storedGuestLocation === SubnetIds.NO) {
    return storedGuestLocation;
  }
  return false;
};

export const isGuestDK = function () {
  const storedGuestLocation = getStoredGuestLocation();
  return storedGuestLocation === SubnetIds.DK;
};

export const isDevDK = function () {
  return localStorage.getItem('DEVDK');
};

export const getSubnetId = function () {
  let subnetId = SubnetIds.NO;
  const storedGuestLocation = getStoredGuestLocation();
  return storedGuestLocation ?? subnetId;
};

export const getDeviceDisplayName = function () {
  let environment = '';
  if (window.location.href.indexOf(Domains.PRODUCTION) === -1) {
    if (window.location.href.indexOf(Domains.BETA) !== -1) {
      environment = ' AT';
    } else {
      environment = ' ST';
    }
  }
  return getDeviceName() + ' ' + ScriptService.getBrowserName() + environment;
};

function getApiUrls(): {
  altibox: string;
  huawei: string;
  amr: string;
} {
  let altiboxApiUrl = env.AltiboxAPIUrls[APP_ENV];
  let huaweiApiUrl = env.HuaweiAPIUrls[APP_ENV];
  let amrApiUrl = env.AmrAPIUrls[APP_ENV];

  if (document && document.location && document.location.host !== Domains.PRODUCTION) {
    let apiUrl = localStorage.getItem('aib_api_url');
    let huaweiUrl = localStorage.getItem('huawei_url');
    let amrUrl = localStorage.getItem('amr_url');

    if (apiUrl && apiUrl.indexOf(altiboxApiUrlPostfix) > 0) {
      apiUrl = apiUrl.replace(altiboxApiUrlPostfix, '');
    }

    if (apiUrl && huaweiUrl) {
      altiboxApiUrl = apiUrl;
      huaweiApiUrl = huaweiUrl;
    }

    if (amrUrl) {
      amrApiUrl = amrUrl;
    }
  }

  return {
    altibox: altiboxApiUrl,
    huawei: huaweiApiUrl,
    amr: amrApiUrl,
  };
}

export let isDev: boolean = Boolean(process.env.NODE_ENV === 'development');

export let isDebug: boolean = Boolean(localStorage.getItem('debug'));

// used for testing channels
export let isFakeHome = Boolean(localStorage.getItem('take-me-home')) && isDebug;

export const getForgotPasswordLink = function () {
  return `https://www.altibox.no/glemt-passord/`;
};

export const androidAppId = env.AndroidAppIds[APP_ENV];
export const iosAppId = env.IosAppIds[APP_ENV];
export const iosAppUrl = env.IosAppUrls[APP_ENV];

let apiUrls = getApiUrls();
let baseUrl: string = apiUrls.huawei;

export const altiboxApiUrlBase: string = apiUrls.altibox;

export const getAltiboxApiUrl: () => string = function () {
  return altiboxApiUrlBase + altiboxApiUrlPostfix;
};

export const getMigrationUrl: () => string = function () {
  return apiUrls.huawei;
};

export const getBaseUrl: () => string = function () {
  return baseUrl;
};

export const getAmrUrl: () => string = function () {
  return apiUrls.amr;
};

export const getT1ProductName: () => string = function () {
  return 'T1_NH';
};

export const setBaseUrl = function (newBaseUrl: string) {
  baseUrl = newBaseUrl;
};

export let widevineLicenseServerUrl: string = '';

// is set when authinticated through auth reducer
export const setWidevineLicenseServerUrl = function (_widevineLicenseServerUrl: string) {
  widevineLicenseServerUrl = _widevineLicenseServerUrl;
};

export const getWidevineLicenseServerUrl: () => string = function () {
  return widevineLicenseServerUrl;
};

export const getImagePath = function (bossId: string, areaId: string) {
  return `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/images`;
};

export const numberOfLiveCollagePrograms = 5;

export const huaweiDateTimeFormat: string = 'YYYYMMDDHHmmss';
export const vodConfigPath: string = 'vod-config-v3.json';
export const vodConfigPostfix: string = '_movies_and_series_all_active_content_id';

export const footerURLs = {
  no: {
    topDomain: 'altibox.no',
    home: 'https://www.altibox.no',
    gdprAndCookies: 'https://www.altibox.no/privat/kundeservice/personvern',
    customerService: 'https://www.altibox.no/privat/kundeservice/',
    foc: 'https://www.altibox.no/mine-sider/atv/velg-ditt-innhold/',
    myPage: 'https://www.altibox.no/mine-sider',
  },
  dk: {
    topDomain: 'altibox.dk',
    home: 'https://www.altibox.dk',
    gdprAndCookies: 'https://www.altibox.dk/persondatapolitik-og-cookies',
    customerService: 'https://www.altibox.dk/kundeservice',
    foc: 'https://www.altibox.dk/kundeservice', // Altibox Denmark does not yet have FoC available for customers, danish customers need to contact customer service
    myPage: 'https://minesider.altibox.dk/mine-sider',
  },
};

export const routes = {
  authentication: {
    fakecallback: '/auth/call-me-back',
    callback: '/auth/callback',
  },
  programarchive: {
    base: '/programarkiv',
    play: '/play',
    genre: '/sjanger',
    channel: '/kanal',
    details: '/detaljer',
    series: '/serie',
    single: '/enkelt',
    mostWatched: '/mest-sett',
  },
  vod: {
    base: '/film-og-serier',
    myContent: '/mitt-innhold',
    category: '/kategori',
    promo: '/kampanje',
    genre: '/sjanger',
    details: '/detaljer',
    play: '/play',
    playTrailer: '/trailer',
    actor: '/skuespiller',
  },
  svod: {
    play: '/play',
    playTrailer: '/trailer',
    genre: '/sjanger',
    base: '/streaming',
    details: '/detaljer',
    category: '/kategori',
  },
  pvr: {
    base: '/opptak',
    play: '/play',
    details: '/detaljer',
    series: '/serie',
    single: '/enkelt',
    recordings: '/opptak',
    scheduled: '/planlagte',
    delete: '/slett',
  },
  search: {
    base: '/search',
  },
  tv: {
    base: '/tv',
    start: '/start',
  },
  watchTv: {
    base: '/se-tv',
    programarchive: '/programarkiv',
    live: '/direkte',
  },
  tvguide: {
    base: '/tv-guide',
  },
  mycontent: {
    base: '/mitt-innhold',
  },
  cast: {
    base: '/cast',
  },
  settings: {
    base: '/settings',
  },
  ios: {
    base: '/ios',
  },
};

export type NielsenChannels = {
  name: string;
  id: string;
  subBrand: string;
  stationId: string;
};

export const nielsenChannelMapping: NielsenChannels[] = [
  { name: 'DR1', id: '549', subBrand: 'c01', stationId: 'GN375TDG9A0KV3K' },
  { name: 'DR2', id: '453', subBrand: 'c02', stationId: 'GN5M9SFDP2AHBGK' },
  { name: 'DR Ramasjang', id: '488', subBrand: 'c03', stationId: 'GNENHCATM5FWF3H' },
  { name: 'TV 2 ZULU', id: '495', subBrand: 'c05', stationId: 'GN6FKWRTJKH2JES' },
  { name: 'TV 2 CHARLIE', id: '509', subBrand: 'c06', stationId: 'GN0TGVEJ5D98FYJ' },
  { name: 'TV 2 SPORT', id: '494', subBrand: 'c07', stationId: 'GN3E6P63RRA7T27' },
  { name: 'TV 2 NEWS', id: '483', subBrand: 'c08', stationId: 'GNDHF1XBP4BBTV1' },
  { name: 'TV 2 FRI', id: '496', subBrand: 'c09', stationId: 'GN65V7JB3RRKY0Z' },
  { name: 'TV 2 SPORT X', id: '742', subBrand: 'c10', stationId: 'GN1N0ZVP55JF0MV' },
  { name: 'TV3', id: '684', subBrand: 'c11', stationId: 'GN2MHMH3D1NHAX0' },
  { name: 'TV3+', id: '683', subBrand: 'c12', stationId: 'GNDBQ7VM29KVBK9' },
  { name: 'TV3 PULS', id: '682', subBrand: 'c13', stationId: 'GN7J65A7M0S9ZGW' },
  { name: 'TV3 SPORT', id: '680', subBrand: 'c14', stationId: 'GN33KBZQ35JY2CH' },
  { name: 'TV3 MAX', id: '681', subBrand: 'c15', stationId: 'GN3CN32B9X83HNP' },
  { name: 'TV MIDTVEST', id: '503', subBrand: 'c16', stationId: 'GN6AJ4SX2156D04' },
  { name: 'TV 2 NORD', id: '513', subBrand: 'c17', stationId: 'GN87D5XSQCR20KE' },
  { name: 'Disney Channel', id: '479', subBrand: 'c18', stationId: 'GN4RTR5E3CS7M1F' },
  { name: 'Disney Junior', id: '466', subBrand: 'c19', stationId: 'GN2ZD17M2M1NKQK' },
  { name: 'National Geographic', id: '525', subBrand: 'c20', stationId: 'GNCYFYAEZ6ER8EB' },
  { name: 'Cartoon Network', id: '463', subBrand: 'c21', stationId: '' },
  { name: 'DK4', id: '517', subBrand: 'c22', stationId: '' },
  { name: 'TV 2 ØSTJYLLAND', id: '511', subBrand: 'c23', stationId: 'GND2KQA9SGPGF8E' },
  { name: 'TV 2 LORRY', id: '498', subBrand: 'c24', stationId: 'GN4WN6V3K166ZMF' },
  { name: 'TV 2 ØST', id: '522', subBrand: 'c25', stationId: 'GN6GKV76AQ25ABE' },
  { name: 'TV 2 FYN', id: '516', subBrand: 'c26', stationId: 'GNB7R7TG2757YWD' },
  { name: 'TV SYD', id: '501', subBrand: 'c27', stationId: 'GN6RHD3F5WKPV6D' },
  { name: 'Viasat Film Premiere', id: '560', subBrand: 'c28', stationId: '' },
  { name: 'Viasat Series', id: '550', subBrand: 'c29', stationId: '' },
  { name: 'Viasat Film Hits', id: '639', subBrand: 'c31', stationId: '' },
  { name: 'Viasat Film Family', id: '602', subBrand: 'c32', stationId: '' },
  { name: 'V Sport Golf', id: '557', subBrand: 'c34', stationId: '' },
  { name: 'National Geographic Wild', id: '698', subBrand: 'c35', stationId: '' },
  { name: 'V Film Action', id: '563', subBrand: 'c38', stationId: '' },
  { name: 'Viasat Explore', id: '638', subBrand: 'c41', stationId: '' },
  { name: 'Viasat Nature', id: '555', subBrand: 'c42', stationId: '' },
  { name: 'C More Series', id: '545', subBrand: 'c43', stationId: '' },
  { name: 'C More Stars', id: '642', subBrand: 'c44', stationId: '' },
  { name: 'C More First', id: '547', subBrand: 'c45', stationId: '' },
  { name: 'C More Hits', id: '530', subBrand: 'c46', stationId: '' },
  { name: 'Folketinget', id: '527', subBrand: 'c47', stationId: '' },
  { name: 'DR2 Syn', id: '650', subBrand: 'c48', stationId: '' },
  { name: 'DR1 Syn', id: '649', subBrand: 'c49', stationId: '' },
];

export const searchConfig: GlobalSearchConfig = {
  global: {
    priority: [],
    type: undefined,
    baseService: '',
    returnPath: '/',
  },
  tvguide: {
    priority: [SearchPriority.TVGUIDE],
    type: AltiboxAssetType.PROGRAM,
    baseService: routes.tvguide.base,
    returnPath: routes.tvguide.base,
  },
  mycontent: {
    priority: [],
    type: undefined,
    baseService: routes.mycontent.base,
    returnPath: routes.mycontent.base,
  },
  vod: {
    priority: [SearchPriority.VOD],
    type: AltiboxAssetType.VOD,
    baseService: routes.vod.base,
    returnPath: routes.vod.base,
  },
  programarchive: {
    priority: [SearchPriority.CATCHUP],
    type: AltiboxAssetType.CATCHUP,
    baseService: routes.programarchive.base,
    returnPath: routes.programarchive.base,
  },
  pvr: {
    priority: [SearchPriority.RECORDINGS],
    type: AltiboxAssetType.PVR,
    baseService: routes.pvr.base,
    returnPath: routes.pvr.base,
  },
  svod: {
    priority: [SearchPriority.SVOD],
    type: AltiboxAssetType.SVOD,
    baseService: routes.svod.base,
    returnPath: routes.svod.base,
  },
};

export const imageScaleValues: ImageScaleValue = {
  vod: {
    width: 330,
    height: 440,
    aspectRatioType: AspectRatioType.KEEP,
  },
  altiboxAssetView: {
    width: 400,
    height: 225,
    aspectRatioType: AspectRatioType.KEEP,
  },
  pvr: {
    width: 512,
    height: 288,
    aspectRatioType: AspectRatioType.KEEP,
  },
  live: {
    width: 464,
    height: 261,
    aspectRatioType: AspectRatioType.KEEP,
  },
  tvGuide: {
    width: 368,
    height: 207,
    aspectRatioType: AspectRatioType.KEEP,
  },
  assetThumbnail: {
    width: 125,
    height: 70,
    aspectRatioType: AspectRatioType.KEEP,
  },
  plannedAsset: {
    width: 70,
    height: 70,
    aspectRatioType: AspectRatioType.KEEP,
  },
};

export const disneyChannels = {
  NO: [
    '466', // disney junior
    '569', // disney channel
    '533', // disney xd
    '581', // national geographic
    '698', // natgeo wild
  ],
  DK: [],
};

export const cookieCategory = {
  necessary: 'cookie_cat_necessary',
  functional: 'cookie_cat_functional',
  statistic: 'cookie_cat_statistic',
  marketing: 'cookie_cat_marketing',
  unclassified: 'cookie_cat_unclassified',
};

export const googleAnalytics = {
  webPropertyId: 'UA-56390192-8',
  disableString: 'ga-disable-UA-56390192-8',
  localStorageKey: 'disableTracking',
  isDisabled: () => window[googleAnalytics.disableString],
  enable: () => {
    localStorage.setItem(googleAnalytics.localStorageKey, 'false');
    window[googleAnalytics.disableString] = false;
  },
  disable: () => {
    localStorage.setItem(googleAnalytics.localStorageKey, 'true');
    window[googleAnalytics.disableString] = true;
  },
  toggle: () => {
    const isDisabled = window[googleAnalytics.disableString];
    localStorage.setItem(googleAnalytics.localStorageKey, `${!isDisabled}`);
    window[googleAnalytics.disableString] = !isDisabled;
  },
  removeLocalStorage: () => localStorage.removeItem(googleAnalytics.localStorageKey),
};

const shouldDisableAnalytics = localStorage.getItem(googleAnalytics.localStorageKey);

if (shouldDisableAnalytics === 'true') {
  googleAnalytics.disable();
}

export function exposeWindowObjects() {
  /* eslint-disable @typescript-eslint/dot-notation */
  window['enableDebug'] = function () {
    localStorage.setItem('debug', 'true');
    document.location.reload();
  };

  window['disableDebug'] = function () {
    localStorage.removeItem('debug');
    document.location.reload();
  };

  window['reset'] = function () {
    const keys = ['debug', 'call-me-back', 'huawei_url', 'aib_api_url', 'tim'];
    keys.forEach((key) => localStorage.removeItem(key));
    document.location.reload();
  };

  window['iAmTimApple'] = function () {
    localStorage.setItem('tim', 'apple');
    document.location.reload();
  };

  const environments = Environments();
  const ubi = UbiSecure();

  window['callMeBack'] = ubi.setUbiCallbackUrl;

  window['setEnvToAT'] = environments.setEnvToAT;
  window['setEnvToST'] = environments.setEnvToST;
  window['setEnvToProd'] = environments.setEnvToProd;

  window['setApiToST'] = environments.setApiToST;
  window['setApiToAT'] = environments.setApiToAT;
  window['setApiToProd'] = environments.setApiToProd;

  window['setAltiboxAPIToAT'] = environments.setAltiboxAPIToAT;
  window['setAltiboxApiToProd'] = environments.setAltiboxApiToProd;
  window['setAltiboxApiToST'] = environments.setAltiboxApiToST;

  window['setHuaweiToST'] = environments.setHuaweiToST;
  window['setHuaweiToAT'] = environments.setHuaweiToAT;
  window['setHuaweiToProd'] = environments.setHuaweiToProd;

  window['setAmrToST'] = environments.setAmrToST;
  window['setAmrToAT'] = environments.setAmrToAT;
  window['setAmrToProd'] = environments.setAmrToProd;

  window['setCCToProd'] = environments.setCCToProd;
  window['setCCToAT'] = environments.setCCToAT;
  window['setCCToST'] = environments.setCCToST;
  window['setCCId'] = environments.setCCId;

  window['setLang'] = function (lang: string) {
    i18next.changeLanguage(lang, (err, t) => {
      if (err) {
        console.error(err);
      }

      console.debug(`Current language set to: %c${i18next.resolvedLanguage.toUpperCase()}`, 'color:yellow');
    });
  };

  window['useForgerock'] = environments.useForgerock;
  window['useUbi'] = environments.useUbi;
  window['enableGA'] = googleAnalytics.enable;
  window['disableGA'] = googleAnalytics.disable;
  window['toggleGA'] = googleAnalytics.toggle;
  window['removeLocalGA'] = googleAnalytics.removeLocalStorage;
  /* eslint-enable @typescript-eslint/dot-notation */
}

export const vodSvodSortingOptions = [
  {
    i18nKey: 'newest',
    value: SortCategories.LATEST_ARRIVALS,
  },
  {
    i18nKey: 'most popular',
    value: SortCategories.MOST_POPULAR,
  },
  {
    i18nKey: 'alphabetical',
    value: SortCategories.A_TO_Z,
  },
];

export const pvrSortingOptions = [
  { i18nKey: 'newest', value: PvrSorting.NEW },
  { i18nKey: 'oldest', value: PvrSorting.OLD },
  { i18nKey: 'alphabetical', value: PvrSorting.A_TO_Z },
];

export const swimlaneSettings = {
  limit: 15,
};

export const pvrSettings = {
  /**
   * Days until the expiration badge should show
   */
  expiration: 14,
  swimlaneMinLength: 5,
  swimlaneMaxLength: 15,
  /**
   * How many days until the new badge wont show on PvrAssets -
   * no matter if it's been watched or not
   */
  newBadgeTimeLimit: 14,
};

export const continueWatchingSettings = {
  minProgress: 1,
  maxProgress: 97,
};
