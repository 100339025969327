import './style.scss';

export enum BadgeTypes {
  Bought = 'bought',
  Rented = 'rented',
  Price = 'price',
  Expires = 'expires',
  New = 'new',
}

interface Props {
  text: string;
  className?: string;
  type?: BadgeTypes;
}

export default function Badge({ text, className, type }: Props) {
  return <div className={`badge ${className ? className : ''} ${type ? type : ''}`}>{text}</div>;
}
