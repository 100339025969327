import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGesture } from 'react-use-gesture';
import { ScriptService } from '../../../controllers/ScriptService';
import { VodAssetDisplayMode } from '../../../interfaces';
import styles from './AltiboxAssetSlider.module.scss';
import { Key } from 'ts-key-enum';

enum SCROLL_POSITION {
  LEFT = -1,
  MIDDLE = 0,
  RIGHT = 1,
}

const dragThreshold = 5;
const vodHeightMultiplier = 0.275;
const svodHeightMultiplier = 0.2;
const simulatedDragDistance = 500; // Distance in pixels

interface Props {
  children: React.ReactNode;
  titleBar: JSX.Element | null;
  shouldNotResize?: boolean;
  displayType?: VodAssetDisplayMode;
  rememberPositionKey?: string;
}

interface ArrowButtonProps {
  scrollPosition: SCROLL_POSITION;
  simulateDrag: (direction: boolean) => void;
  direction: 'right' | 'left';
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ direction, scrollPosition, simulateDrag }) => {
  const [focus, setFocus] = useState(false);

  const getDisabledStyle = { color: 'rgba(255, 255, 255, 0.38)' };

  const isDisabled =
    direction === 'right' ? scrollPosition === SCROLL_POSITION.RIGHT : scrollPosition === SCROLL_POSITION.LEFT;

  return (
    <button
      className={!focus ? styles.arrowButton : styles.focusedArrowButton}
      onClick={() => {
        simulateDrag(direction === 'right' ?? true);
      }}
      style={isDisabled ? getDisabledStyle : {}}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onKeyDown={(e) => {
        if (e.key === Key.Enter) {
          setFocus(true);
          simulateDrag(direction === 'right' ?? true);
        }
      }}
    >
      {direction === 'right' ? '>' : '<'}
    </button>
  );
};

const CACHE: { [rememberPositionKey: string]: number } = {};

function AltiboxAssetSlider({ children, titleBar, shouldNotResize, displayType, rememberPositionKey }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isClicking, setIsClicking] = useState(false);
  const [xOffset, setXOffset] = useState(rememberPositionKey ? CACHE[rememberPositionKey] ?? 0 : 0);
  const [scrollPosition, setScrollPosition] = useState(SCROLL_POSITION.LEFT);
  const [width, setWidth] = useState(shouldNotResize ? 0 : window.innerWidth);
  const [swimlaneHeight, setSwimlaneHeight] = useState(0);

  const shouldRenderArrows = useCallback(() => {
    return ref.current && ref.current.scrollWidth > ref.current.offsetWidth;
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth), {
      passive: false,
    });
    if (!shouldNotResize) {
      // Calculate height for swimlane based on window.innerWidth
      let calculatedSwimlaneHeight =
        width * (displayType === VodAssetDisplayMode.VOD ? vodHeightMultiplier : svodHeightMultiplier);
      setSwimlaneHeight(calculatedSwimlaneHeight);
      return () => window.removeEventListener('resize', () => setWidth(window.innerWidth));
    }
  }, [width, shouldNotResize, displayType, shouldRenderArrows]);

  useEffect(() => {
    return () => {
      if (rememberPositionKey) {
        CACHE[rememberPositionKey] = xOffset;
      }
    };
  }, [rememberPositionKey, xOffset]);

  useEffect(() => {
    if (rememberPositionKey && ref.current) {
      ref.current.scrollLeft = CACHE[rememberPositionKey] ?? 0;
    }
  }, [rememberPositionKey]);

  const onDragCallback = (x: number, last: boolean, dragging: boolean) => {
    let newPos = xOffset + -x;
    let maxScrollWidth: number | undefined;
    let clientWidth: number | undefined;

    if (ref && ref.current) {
      clientWidth = ref.current.clientWidth;
      maxScrollWidth = ref.current.scrollWidth;

      // Scroll freely between the limits
      if (newPos > 0 && newPos < maxScrollWidth) {
        ref.current.scrollLeft = newPos;
      }

      if (newPos <= 0) {
        ref.current.scrollLeft = 0;
      }
    }

    // Keep track of dragging state to make sure that ´pointer-events: 'none'´ while dragging
    if (dragging) {
      setIsClicking(false);
      setIsDragging(true);
    }

    // Last event
    if (last) {
      // If the offset is negative scrollLeft should be set to 0 to avoid "negative" scrolling.
      if (newPos < 0) {
        setXOffset(0);
        setScrollPosition(SCROLL_POSITION.LEFT);
        if (ref && ref.current && isClicking) {
          ref.current.scrollLeft = 0;
        }
        // If the offset is has reached max scroll width "over-scrolling" should not happen
      } else if (maxScrollWidth && clientWidth && newPos > maxScrollWidth - clientWidth) {
        if (ref && ref.current) {
          setXOffset(maxScrollWidth - clientWidth);
          setScrollPosition(SCROLL_POSITION.RIGHT);
          if (isClicking) {
            ref.current.scrollLeft = maxScrollWidth - clientWidth;
          }
        }
      } else {
        setScrollPosition(SCROLL_POSITION.MIDDLE);
        setXOffset(newPos);
      }
      setIsDragging(false);
    }
  };

  const handleSimulateDrag = (direction: boolean) => {
    setIsClicking(true);
    if (ref && ref.current) {
      // Smooth scrolling for button scrolling.
      ref.current.style.scrollBehavior = 'smooth';
    }
    onDragCallback(simulatedDragDistance * (direction ? -1 : 1), true, false);
  };

  const bindGestures = useGesture(
    {
      onDrag: ({ movement: [x], last, dragging, event }) => {
        if (event) {
          switch (event.type) {
            case 'touchmove':
            case 'touchend':
              if (ref && ref.current) {
                setXOffset(ref.current.scrollLeft);
              }
              break;
            default:
              onDragCallback(x, last, dragging);
          }
        }
      },
      onWheel: () => {
        if (ref && ref.current) {
          setXOffset(ref.current.scrollLeft);
        }
      },

      // Mouse enter and leave to prevent back and forward navigation for trackpads.
      onMouseEnter: () => {
        document.body.style.overscrollBehaviorX = 'contain';
      },
      onMouseLeave: () => {
        document.body.style.overscrollBehaviorX = '';
      },
    },
    {
      // pixel threshold in order to destinguish between click and drag.
      drag: { threshold: dragThreshold },
      domTarget: ref,
      eventOptions: { passive: false },
    },
  );

  const getStyles = (): React.CSSProperties => {
    let minHeight = displayType === VodAssetDisplayMode.SVOD ? 180 : undefined;
    if (displayType === VodAssetDisplayMode.SVOD && width < 480) {
      minHeight = 148;
    }
    return {
      scrollBehavior: isClicking ? 'smooth' : 'auto',
      height: swimlaneHeight ? swimlaneHeight : undefined,
      minHeight: minHeight,
      maxHeight: displayType === VodAssetDisplayMode.SVOD ? 216 : undefined,
    };
  };

  return (
    <>
      <div className={styles.altiboxAssetSliderHeader}>
        {titleBar}
        {!ScriptService.onPhone() && shouldRenderArrows() ? (
          <div className={styles.buttonContainer}>
            <ArrowButton scrollPosition={scrollPosition} simulateDrag={handleSimulateDrag} direction="left" />
            <ArrowButton scrollPosition={scrollPosition} simulateDrag={handleSimulateDrag} direction="right" />
          </div>
        ) : null}
      </div>
      <div
        {...bindGestures()}
        className={isDragging ? styles.altiboxAssetSliderNoEvent : styles.altiboxAssetSlider}
        ref={ref}
        style={getStyles()}
      >
        {children}
      </div>
    </>
  );
}
export default AltiboxAssetSlider;
