import React from 'react';
import { Channel, Program, AuthReducerState, VodAssetDisplayMode } from '../../interfaces';
import './style.scss';
import { Link } from 'react-router-dom';
import channelPermissions from '../../utils/channelPermissions';
import i18n from '../../i18n';
import { isEmpty } from 'lodash';
import SvgSeeAll from './see-all-channels.svg';
import ChannelsBannerListItem from './ChannelsBannerListItem';
import { getOngoingProgramName } from '../../utils/tvUtils';
import AltiboxAssetSlider from '../UI/AltiboxAssetSlider';
import Spinner from '../Spinner';
import { routes } from '../../config';

interface Props {
  onlyInSubscription?: boolean;
  channels: Channel[];
  favoriteChannels: Channel[];
  isFavouriteView?: boolean;
  playbills?: Map<string, Program[]>;
  openState: boolean;
  isProgramArchive?: boolean;
  authReducer: AuthReducerState;
  showLoginModal(): void;
}
interface State {
  channels: JSX.Element[];
  favoriteChannels: Channel[];
  hideArrows: boolean;
}

const channelsLimit = 15;

class ChannelsBanner extends React.Component<Props, State> {
  state = {
    channels: [],
    favoriteChannels: [],
    hideArrows: false,
  };

  get headerText() {
    const { isFavouriteView, isProgramArchive } = this.props;
    if (isFavouriteView) {
      return i18n.t<string>('favorite channels list');
    } else if (isProgramArchive) {
      return i18n.t<string>('catchup tv channels');
    }

    return i18n.t<string>('watch tv');
  }

  get seeAllURI() {
    const { isProgramArchive } = this.props;
    const { base, programarchive, live } = routes.watchTv;
    return `${base}${isProgramArchive ? programarchive : live}`;
  }

  get seeAllChannelsCard() {
    return (
      <div key="see-all" className={'see-all-wrapper'}>
        <Link to={this.seeAllURI}>
          <img
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            className={'see-all'}
            src={SvgSeeAll}
            alt={i18n.t<string>('see all')}
            title={'see-all'}
          />
          <div className={'see-all-text'}>{i18n.t<string>('see all')}</div>
        </Link>
      </div>
    );
  }

  get defaultHeader() {
    return (
      <div className="watch-tv-wrapper">
        <Link to={this.seeAllURI}>
          <h3 title={this.headerText} className="watch-tv-header">
            {this.headerText}
          </h3>
          <span className="aib-icon">{`>`}</span>
        </Link>
      </div>
    );
  }

  get favoriteChannelHeader() {
    return (
      <div className="watch-tv-wrapper">
        <h3 title={this.headerText} className="watch-tv-header">
          {this.headerText}
        </h3>
      </div>
    );
  }

  get titleBar() {
    return this.props.isFavouriteView ? this.favoriteChannelHeader : this.defaultHeader;
  }

  getChannelList(channels: Channel[], playbills?: Map<string, Program[]>) {
    if (channels.length > channelsLimit && !this.props.isFavouriteView) {
      return [
        ...channels.slice(0, channelsLimit).map((channel) => {
          return this.renderChannelImage(channel, playbills);
        }),
        this.seeAllChannelsCard,
      ];
    }
    return [
      ...channels.map((channel) => {
        return this.renderChannelImage(channel, playbills);
      }),
    ];
  }

  UNSAFE_componentWillMount() {
    const { props } = this;
    let channels = props.channels;
    const isLoggedIn = props.authReducer.loggedInWithCredentials || Boolean(props.authReducer.loggedInWithIp);
    if (isLoggedIn && props.onlyInSubscription) {
      channels = channels.filter((x) => !x.channelPermissions.notInSubscription);
    }
    this.setState({
      channels: this.getChannelList(channels, this.props.playbills),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.playbills !== this.props.playbills) {
      this.setState(
        {
          channels: this.getChannelList(nextProps.channels, nextProps.playbills),
        },
        () => {
          this.forceUpdate();
        },
      );
    }
    if (this.props.isProgramArchive) {
      this.setState(
        {
          channels: [...this.getChannelList(nextProps.channels)],
        },
        () => {
          this.forceUpdate();
        },
      );
    }
    if (this.props.authReducer && this.props.authReducer.auth && !this.props.authReducer.isGuest) {
      this.setState({ favoriteChannels: nextProps.favoriteChannels });
    }
  }

  getOnGoingProgram = (channel: Channel, playbills?: Map<string, Program[]>) => {
    let playbillsToUse = playbills ? playbills : this.props.playbills;
    if (playbillsToUse) {
      return getOngoingProgramName(channel, playbillsToUse);
    }
    return '';
  };

  isLocked = (channel: Channel) => {
    const permission = channelPermissions(channel, this.props.authReducer);
    return permission.locked;
  };

  checkChannelPermissionsBeforeLink = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    if (this.props.authReducer.isGuest) {
      this.props.showLoginModal();
    }
  };

  renderChannelImage(channel: Channel, playbills?: Map<string, Program[]>) {
    return (
      <ChannelsBannerListItem
        key={channel.name}
        channel={channel}
        isProgramArchive={this.props.isProgramArchive}
        isFavoriteView={this.props.isFavouriteView}
        ongoingProgramName={this.getOnGoingProgram(channel, playbills)}
      />
    );
  }

  render() {
    const { channels } = this.state;
    if (!isEmpty(channels)) {
      return (
        <ol className={'channelsBanner'}>
          <AltiboxAssetSlider
            children={channels}
            key="channelsBanner"
            titleBar={this.titleBar}
            shouldNotResize={true}
            displayType={VodAssetDisplayMode.SVOD}
          ></AltiboxAssetSlider>
        </ol>
      );
    } else {
      return <Spinner wrapInContainer={true} />;
    }
  }
}

export default ChannelsBanner;
