import OptionsModal from '../../../components/OptionsModal';
import { OptionType, OptionsModalContent, DeleteType, AlertData, AlertType } from '../../../interfaces';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { doDeviceSwitch, hideDeviceSwitchModal, showAlert } from '../actions';
import { RootState, Device, DispatchType } from '../../../interfaces';
import i18n from '../../../i18n';
import { logout } from '../../../api/auth';

type Props = {
  switchDevice: (_: OptionType) => void;
  hideDeviceSwitch: () => void;
  deviceSwitchModalVisible: boolean;
  options: [Device];
  deviceReplaceTimes: number;
  showAlert: (alertData: AlertData) => void;
};

type State = OptionsModalContent;

class DeviceSwitchModal extends Component<Props, State> {
  selectedDevice: OptionType = {
    title: i18n.t<string>('ok'),
    value: DeleteType.SWITCH,
    style: 'checkmark',
    type: 'primary',
  };

  state: State = {
    modalIsOpen: false,
    error: false,
    title: i18n.t<string>('change_device'),
    subtitle: i18n.t<string>('change_device_message'),
    subtitle2: '',
    posttitle: '',
    closetext: i18n.t<string>('cancel'),
    options: [],
    onAction: this.onAction(),
    onClose: () => {
      this.props.hideDeviceSwitch();
      logout(true);
    },
  };

  onAction() {
    return (selectedAction: OptionType) => {
      this.props.switchDevice(selectedAction);
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // "this subscriberId doesn't match to the login one"
    if (
      nextProps.deviceReplaceTimes &&
      nextProps.deviceReplaceTimes.retcode &&
      nextProps.deviceReplaceTimes.retcode === '85983778'
    ) {
      this.setState({
        modalIsOpen: nextProps.deviceSwitchModalVisible,
        options: [],
        title: i18n.t<string>('an_error_occured'),
        subtitle: 'Error: 85983778',
        subtitle2: i18n.t<string>('please_try_again'),
        posttitle: '',
        closetext: i18n.t<string>('ok'),
      });
      return;
    }

    if (nextProps.deviceReplaceTimes && nextProps.deviceReplaceTimes.remainTimes !== '0') {
      this.setState({
        modalIsOpen: nextProps.deviceSwitchModalVisible,
        options: nextProps.options.map((option: OptionType) => {
          return { ...option, type: 'primary' };
        }),
        title: i18n.t<string>('change_device'),
      });
    } else if (nextProps.deviceReplaceTimes) {
      this.props.showAlert({
        title: i18n.t<string>('you_cannot_login'),
        text: [i18n.t<string>('you_have_changed_device_too_many_times')],
        type: AlertType.RETURN_TO_BASE,
        link: '/',
      });
    }

    if (nextProps.deviceSwitchModalVisible !== this.props.deviceSwitchModalVisible) {
      this.setState({
        modalIsOpen: nextProps.deviceSwitchModalVisible,
      });
    }
  }

  render() {
    return <OptionsModal customClass="device-switch-options" {...(this.state as OptionsModalContent)} />;
  }
}

export default connect(
  (state: RootState) => ({
    deviceSwitchModalVisible: state.authReducer.deviceSwitchModalVisible,
    options: state.authReducer.devicesToSwitch,
    deviceReplaceTimes: state.authReducer.deviceReplaceTimes,
  }),
  (dispatch: DispatchType) => ({
    switchDevice: (deviceToRemove: OptionType) => dispatch(doDeviceSwitch(deviceToRemove as {} as Device)),
    hideDeviceSwitch: () => dispatch(hideDeviceSwitchModal()),
    showAlert: (alertData: AlertData) => dispatch(showAlert(alertData)),
  }),
)(DeviceSwitchModal);
