import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { negate } from 'lodash';
import {
  RootState,
  ApiAuthResponse,
  ProgramArchiveList,
  Channel,
  Genre,
  GenreType,
  EpgGenre,
  AuthReducerState,
  ProgramContentDetailResponse,
  AltiboxAssetDisplayTypes,
  SearchConfig,
  AltiboxAsset,
  HeaderDisplayType,
  HeaderInteractionType,
  Program,
  VodAssetDisplayMode,
  PopularContentChannelTimespan,
} from '../../../interfaces';

import Header from '../../app/Header';
import i18n from '../../../i18n';
import { getMostWatchedCatchup, loadProgramArchiveLists } from '../actions';
import { routes } from '../../../config';
import { getChannels, getFavoriteChannels, getGenres } from '../../tv/actions';
import { loadProgramArchiveGenreLists } from '../actions';
import Footer from '../../../components/Footer';
import ChannelsBanner from '../../../components/ChannelsBanner';
import Spinner from '../../../components/Spinner/index';
import { getCurrentLangTitle } from '../../../utils/vodUtils';
import AltiboxAssetViewList from '../../../components/UI/AltiboxAssetViewList/index';
import { getProgramArchiveFavourites, getAllProgramArchiveFavouritesRelations } from '../../../api/favorites';
import {
  getSearchCatchupAsset as getFavCatchupAsset,
  appendFavouriteFlags,
  addLockedSymbolOnInsideOnlyChannelPrograms,
  isDisneyChannel,
  getCatchupAsset,
} from '../../../utils/programArchiveUtils';
import CategoryHelmet from '../../../components/Helmets/CategoryHelmet';
import StickyHeader from '../../../views/app/StickyHeader';
import { showLoginModal } from '../../../views/app/actions';
import { ScriptService } from '../../../controllers/ScriptService';
import AltiboxGenreBanner from '../../../components/AltiboxGenreBanner';
import isEmpty from 'lodash/isEmpty';
import { isDisneyProgramAsset } from '../../../utils/altiboxassetUtils';

interface Props extends RouteComponentProps<{}, {}, { toTop?: boolean }> {
  isGuest: boolean;
  isHome: boolean;
  loggedInWithCredentials: boolean;
  auth: ApiAuthResponse | undefined;
  authReducer: AuthReducerState;
  genreLists: ProgramArchiveList[];
  filterGenres: Genre[];
  searchConfig: SearchConfig;
  archiveGenres: EpgGenre[];
  channels: Channel[];
  favoriteChannels: Channel[];
  mostWatchedCatchup: Program[] | undefined;
  getChannels: () => Promise<void>;
  getFavoriteChannels: () => Promise<void>;
  loadGenreLists: (filterGenres: Genre[], archiveGenres: EpgGenre[], channels: Channel[]) => Promise<void>;
  loadAvailableGenres: () => Promise<Genre[]>;
  loadProgramArchiveGenreLists: (areaid: string, bossID: string) => Promise<void>;
  showLoginModal: () => void;
  getMostWatchedCatchup: () => void;
}

interface State {
  allChannelsOpen: boolean;
  favouriteIds: string[];
  favourites: AltiboxAsset[] | undefined;
  hideArrows: boolean;
}

var isMounted: boolean = false;
class FrontPage extends Component<Props, State> {
  state: State = {
    favouriteIds: [],
    allChannelsOpen: false,
    favourites: undefined,
    hideArrows: false,
  };

  componentDidMount() {
    isMounted = true;
    window.addEventListener('resize', this.onResize);
    this.onResize();

    if (this.props.location && this.props.location.state && this.props.location.state.toTop) {
      window.scrollTo(0, 0);
    }
    if (this.props.auth) {
      this.props.getMostWatchedCatchup();
      this.props
        .loadProgramArchiveGenreLists(this.props.auth.areaid, this.props.auth.bossID)
        .then(() => {
          if (isEmpty(this.props.channels)) {
            this.props.getChannels();
          }
          if (isEmpty(this.props.favoriteChannels)) {
            this.props.getFavoriteChannels();
          }
          return true;
        })
        .then(this.props.loadAvailableGenres)
        .then(() => {
          this.props.loadGenreLists(this.props.filterGenres, this.props.archiveGenres, this.props.channels);
        })
        .then(() => {
          if (!this.props.isGuest && this.props.loggedInWithCredentials) {
            getProgramArchiveFavourites(this.props.channels)
              .then((responsePlaybill: ProgramContentDetailResponse) => {
                const allIds = responsePlaybill.playbilllist.map((x) => x.id);
                const allSeriesIds = responsePlaybill.playbilllist.map((x) => x.seriesID).filter((x) => x);

                getAllProgramArchiveFavouritesRelations(allSeriesIds).then((possibleFavouritedProgramIds) => {
                  if (isMounted) {
                    let favouriteIds = possibleFavouritedProgramIds.concat(allIds).concat(allSeriesIds);
                    this.setState({
                      favouriteIds: favouriteIds,
                    });
                  }
                });
                return responsePlaybill;
              })
              .then(this.programArchiveFavouritesToCorrectFormat);
          }
        });
    }
  }

  componentWillUnmount() {
    isMounted = false;
    window.removeEventListener('resize', this.onResize);
  }

  filterOutNonWatchableFavourites(assets: AltiboxAsset[]) {
    return assets.filter((x) => (x.asset! as Program).recordedMediaIds);
  }

  onResize = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth < 600 || ScriptService.onMobile() || ScriptService.onPhone()) {
      if (!this.state.hideArrows) {
        this.setState({ hideArrows: true });
      }
    } else {
      if (this.state.hideArrows) {
        this.setState({
          hideArrows: false,
        });
      }
    }
  };

  addLockedAndFavoriteIcon(programs: AltiboxAsset[]) {
    programs = addLockedSymbolOnInsideOnlyChannelPrograms(programs, this.props.isHome);
    if (!isEmpty(this.state.favouriteIds)) {
      programs = appendFavouriteFlags(programs, this.state.favouriteIds);
    }
    return programs;
  }

  programArchiveFavouritesToCorrectFormat = (responsePlaybill: ProgramContentDetailResponse) => {
    let altiboxFavouriteAssets = responsePlaybill.playbilllist.map((asset) => {
      return getFavCatchupAsset(asset, this.props.channels);
    });
    if (isMounted) {
      altiboxFavouriteAssets = this.filterOutNonWatchableFavourites(altiboxFavouriteAssets);
      this.setState({
        favourites: altiboxFavouriteAssets,
      });
    }
  };

  renderAssets = () => {
    return this.props.genreLists.map((list: ProgramArchiveList, index: number) => {
      const title = getCurrentLangTitle(list.genre.title);
      const link =
        `${routes.programarchive.base}${routes.programarchive.genre}/` +
        `${list.genre.genreIds.replace(/\s/g, '')}/${list.genre.orderType}`;

      let programs = list.programs.filter(negate(isDisneyProgramAsset));

      programs = this.addLockedAndFavoriteIcon(programs);

      return (
        <AltiboxAssetViewList
          showNumbering={true}
          key={title + index}
          loggedInWithCredentials={this.props.loggedInWithCredentials}
          assets={programs}
          displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
          title={title}
          trackingTitle={list.genre.title.en}
          titleLink={link}
          genreIds={list.genre.genreIds}
          vodDisplayMode={VodAssetDisplayMode.SVOD}
        />
      );
    });
  };

  renderMostWatchedCatchup = () => {
    const { mostWatchedCatchup } = this.props;
    const { base, mostWatched } = routes.programarchive;

    if (mostWatchedCatchup && !isEmpty(mostWatchedCatchup)) {
      let programs = mostWatchedCatchup.map((program) => {
        return getCatchupAsset(program, this.props.channels);
      });

      programs = this.addLockedAndFavoriteIcon(programs);

      const title = 'people are watching';
      return (
        <AltiboxAssetViewList
          key={title}
          title={i18n.t<string>(title)}
          trackingTitle={title}
          titleLink={`${base}${mostWatched}/${PopularContentChannelTimespan.TWENTYFOUR_HOURS}`}
          assets={programs}
          displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
          loggedInWithCredentials={this.props.loggedInWithCredentials}
          vodDisplayMode={VodAssetDisplayMode.SVOD}
        />
      );
    }
  };

  renderFavourites = () => {
    if (
      (this.props.loggedInWithCredentials && this.state.favourites && this.state.favourites.length > 0) ||
      (this.props.loggedInWithCredentials && this.state.favourites === undefined)
    ) {
      const favourites = this.state.favourites?.filter(negate(isDisneyProgramAsset)) || [];

      const title = 'favourites';
      return (
        <AltiboxAssetViewList
          key={title}
          title={i18n.t<string>(title)}
          trackingTitle={title}
          titleLink={routes.mycontent.base}
          assets={favourites}
          favouriteView={true}
          displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
          loggedInWithCredentials={this.props.loggedInWithCredentials}
          vodDisplayMode={VodAssetDisplayMode.SVOD}
        />
      );
    } else {
      return null;
    }
  };

  toggleChannelBarOpenState = () => {
    this.setState({
      allChannelsOpen: !this.state.allChannelsOpen,
    });
  };

  getChannelsWithCatchupArchive = () => {
    return this.props.channels.filter(
      (channel) =>
        channel.channelPermissions.hasEnabledCatchup === true &&
        channel.channelPermissions.notInSubscription === false &&
        !isDisneyChannel(channel),
    );
  };

  showGuestMessage() {
    return (
      <div className="main-frame program-archive">
        <StickyHeader searchConfig={this.props.searchConfig} />
        <Header
          title={i18n.t<string>('programarchive')}
          displayType={HeaderDisplayType.Solid}
          interactionType={HeaderInteractionType.Full}
        />
        <div className="content-wrapper">
          <h2>{i18n.t<string>('log in to see program archive')}</h2>
        </div>
        <Footer />
      </div>
    );
  }

  render() {
    if (this.props.isGuest) {
      return this.showGuestMessage();
    }
    let hideArrowsClass = this.state.hideArrows ? ' no-arrows' : '';
    return (
      <div className="main-frame program-archive">
        <CategoryHelmet categoryTitle={'Program Archive'} title={i18n.t<string>('programarchive')} />
        <StickyHeader searchConfig={this.props.searchConfig} />
        <Header
          title={i18n.t<string>('programarchive')}
          displayType={HeaderDisplayType.Solid}
          interactionType={HeaderInteractionType.Full}
        />
        <div className="content-wrapper">
          {this.renderFavourites()}
          {this.props.archiveGenres ? <AltiboxGenreBanner genres={this.props.archiveGenres} /> : null}

          <div className={'channels-container' + hideArrowsClass}>
            <ChannelsBanner
              channels={this.getChannelsWithCatchupArchive()}
              favoriteChannels={this.props.favoriteChannels}
              authReducer={this.props.authReducer}
              isProgramArchive={true}
              openState={this.state.allChannelsOpen}
              showLoginModal={this.props.showLoginModal}
            />
          </div>
          {this.renderMostWatchedCatchup()}
          {this.props.genreLists ? this.renderAssets() : <Spinner wrapInContainer={true} />}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      isGuest: state.authReducer.isGuest,
      isHome: state.authReducer.isHome,
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
      authReducer: state.authReducer,
      auth: state.authReducer.auth,
      genreLists: state.programArchiveReducer.epgGenresList as ProgramArchiveList[],
      filterGenres: state.channelsReducer.genres,
      archiveGenres: state.programArchiveReducer.epgGenres as EpgGenre[],
      channels: state.channelsReducer.channels,
      favoriteChannels: state.channelsReducer.favoriteChannels,
      mostWatchedCatchup: state.programArchiveReducer.mostWatchedCatchup,
    }),
    (dispatch) => ({
      getMostWatchedCatchup: () => {
        dispatch(getMostWatchedCatchup());
      },
      getChannels: () => dispatch(getChannels()),
      getFavoriteChannels: () => dispatch(getFavoriteChannels()),
      loadAvailableGenres: () => dispatch(getGenres(GenreType.ChannelProgram)),
      showLoginModal: () => dispatch(showLoginModal()),
      loadGenreLists: (filterGenres: Genre[], archiveGenres: EpgGenre[], channels: Channel[]) =>
        dispatch(loadProgramArchiveLists(filterGenres, archiveGenres, channels)),
      loadProgramArchiveGenreLists: (areaid: string, bossID: string) =>
        dispatch(loadProgramArchiveGenreLists(areaid, bossID)),
    }),
  )(FrontPage),
);
