import { useEffect, useRef, useState } from 'react';
import {
  PortalMenu,
  StreamingServicePortal,
  VodAssetDisplayMode,
  StreamingPortalsType,
  GAaction,
  GAlabel,
} from '../../interfaces';
import { getSvodKiosksForWeb, getSvodModalSetting, ThirdPartyPopUp } from '../../utils/svodUtil';
import StreamingPortalsItem from '../../views/streamingServices/StreamingPortalsItem';
import DeeplinkModal from '../../views/streamingServices/DeeplinkModal';
import AltiboxAssetSlider from '../UI/AltiboxAssetSlider';
import style from './style.module.scss';
import { isEmpty } from 'lodash';
import { swimlaneSettings } from '../../config';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';
import useFetchStreamingServices from '../../hooks/useFetchStreamingServices';
import TitleHeader from './TitleHeader';
import Spinner from '../Spinner';

type Props = {
  defaultImagePath: string;
  svodKiosks: PortalMenu[];
};

const StreamingServicesBanner = ({ defaultImagePath, svodKiosks }: Props) => {
  const { streamingPortals: streamingServicePortals, loading } = useFetchStreamingServices();
  const [webSvodKiosks, setWebSvodKiosks] = useState<PortalMenu[]>([]);
  const [externalPortal, setExternalPortal] = useState<StreamingServicePortal>({} as StreamingServicePortal);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const tracking = useRef<AnalyticsTracking | null>(null);

  const handleExternalPortal = (portal: StreamingServicePortal) => {
    if (portal.type === StreamingPortalsType.EXTERNAL) {
      const category = tracking.current?.getCurrentCategory();
      setOpenModal(true);
      setExternalPortal(portal);
      tracking.current?.trackEvent(category!, GAaction.openModal, `${GAlabel.thirdParty}_${portal.id}`);
    }
  };

  const streamingPortals = () => {
    if (!isEmpty(webSvodKiosks) && !isEmpty(streamingServicePortals)) {
      const portalsForSwimlane = streamingServicePortals.slice(0, swimlaneSettings.limit);

      return portalsForSwimlane.map((portal, idx) => {
        return (
          <div key={`${portal.id}-${idx + 1}`}>
            <StreamingPortalsItem
              portal={portal}
              defaultImagePath={defaultImagePath}
              svodKiosks={webSvodKiosks}
              swimlaneItemNumber={idx + 1}
              handleExternalPortal={handleExternalPortal}
            />
          </div>
        );
      });
    }

    return null;
  };

  useEffect(() => {
    if (getSvodModalSetting() === ThirdPartyPopUp.HIDE) {
      setOpenModal(false);
    }
    tracking.current = AnalyticsTracking.getInstance();
  }, []);

  useEffect(() => {
    const webKiosks = getSvodKiosksForWeb(svodKiosks);
    setWebSvodKiosks(webKiosks);
  }, [svodKiosks]);

  if (isEmpty(svodKiosks) || isEmpty(streamingServicePortals)) {
    return null;
  }

  if (loading) {
    return <Spinner wrapInContainer={true} />;
  }

  return (
    <div className={style.container}>
      <AltiboxAssetSlider
        children={streamingPortals()}
        titleBar={TitleHeader()}
        shouldNotResize={false}
        displayType={VodAssetDisplayMode.SVOD}
        rememberPositionKey="streaming-services-banner"
      />
      <DeeplinkModal showModalState={openModal} setOpenModal={setOpenModal} externalService={externalPortal} />
    </div>
  );
};

export default StreamingServicesBanner;
