import { createContext, useContext } from 'react';
import { SearchConfig } from '../interfaces';

interface PvrContextValues {
  searchConfig: SearchConfig;
}

interface PvrContextProps {
  searchConfig: SearchConfig;
  children: React.ReactNode;
}

const PvrContext = createContext<PvrContextValues | null>(null);

export const usePvr = () => {
  return useContext(PvrContext) as PvrContextValues;
};

export function PvrProvider({ children, searchConfig }: PvrContextProps) {
  const value = {
    searchConfig,
  };

  return <PvrContext.Provider value={value}>{children}</PvrContext.Provider>;
}
