import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Program as ProgramType, Channel, AuthReducerState, Picture, ChannelLogoType } from '../../../interfaces';
import { Link } from 'react-router-dom';
import { routes, imageScaleValues } from '../../../config';
import channelPermissions from '../../../utils/channelPermissions';
import i18n from '../../../i18n';
import { getImageUrlDimensions } from '../../../utils/altiboxassetUtils';
import { getChannelLogoUrl } from '../../../utils/tvUtils';
import imageMissing from './tv-placeholder-landscape.png';
import { useProgram } from '../../../hooks/useProgram';

interface Props extends ProgramType {
  channel: Channel;
  authReducer: AuthReducerState;
  picture: Picture;
  showLoginModal: () => Function;
  programOrder: number;
}

export default function LiveCollageProgram(props: Props) {
  const { getProgress } = useProgram(props);

  function getProgramPicture(picture: Props['picture']) {
    return props.picture
      ? window.document.body.offsetWidth > 767
        ? props.picture.poster
        : props.picture.deflate
      : null;
  }

  const [posterUrl, setPosterUrl] = useState(imageMissing);
  const [failedToLoadDoNotLoadAgain, setFailedToLoadDoNotLoadAgain] = useState(false);
  const [progress, setProgress] = useState(getProgress());

  const { isGuest, isHome } = props.authReducer;

  const progressRefreshIntervalRef = useRef<NodeJS.Timer>();

  const isLocked = useMemo(() => {
    const permission = channelPermissions(props.channel, props.authReducer);
    return permission.locked;
  }, [props.authReducer, props.channel]);

  const liveUrl = useMemo(() => {
    return `${routes.tv.base}/${props.channelid ? props.channelid : ''}`;
  }, [props.channelid]);

  useEffect(() => {
    progressRefreshIntervalRef.current = setInterval(() => {
      setProgress(getProgress());
    }, 15 * 1000);

    return () => {
      clearInterval(progressRefreshIntervalRef.current);
    };
  }, [getProgress]);

  const checkChannelPermissions = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (isGuest || (props.channel.channelPermissions.onlyInsideforLiveTV && !isHome)) {
        event.preventDefault();
      }
      return true;
    },
    [isGuest, isHome, props.channel.channelPermissions.onlyInsideforLiveTV],
  );

  // after placeholder is loaded load the poster or ad img based on width of screen
  const handlePlaceholderLoaded = () => {
    const programPicture = getProgramPicture(props.picture);
    let posterURL = imageMissing;

    if (!failedToLoadDoNotLoadAgain && programPicture) {
      if (props.programOrder > 0) {
        posterURL = getImageUrlDimensions(programPicture, imageScaleValues.live);
      } else {
        posterURL = programPicture;
      }
    }

    setPosterUrl(posterURL);
  };

  const handleLoadingError = () => {
    if (!failedToLoadDoNotLoadAgain) {
      setFailedToLoadDoNotLoadAgain(true);
      setPosterUrl(imageMissing);
    }
  };

  if (!props.channel) {
    return null;
  }

  return (
    <div className="live-program-collage-item">
      {isLocked ? <div className="text-shadow aib-icon">p</div> : null}

      <Link to={liveUrl} onClick={(e) => checkChannelPermissions(e)} className="noselect">
        <img alt={props.name} src={posterUrl} onError={handleLoadingError} onLoad={handlePlaceholderLoaded} />
        <div className="shader" />

        <LiveCollageProgramOverlay {...props} />

        <div className="metadata">
          <div className="title-container">
            <h2 className="title text-shadow">{props.name}</h2>
            <div className="logo-container">
              <img
                className="channel-logo"
                alt={props.channel.name}
                src={getChannelLogoUrl(props.channel, ChannelLogoType.CROPPED)}
              />
            </div>
          </div>
          <div className="progress-wrapper">
            <progress value={progress} />
          </div>
        </div>
      </Link>
    </div>
  );
}

function LiveCollageProgramOverlay(props: Props) {
  const isGuest = props.authReducer.isGuest;
  const isHome = props.authReducer.isHome;
  const onlyInsideforLiveTV = props.channel.channelPermissions.onlyInsideforLiveTV;

  if (isGuest) {
    return (
      <div className="hover-overlay not-inside">
        <div className="disabled-content-container" onClick={props.showLoginModal}>
          {i18n.t<string>('log in to see this channel')}
          <button onClick={props.showLoginModal} className="login">
            <span className="aib-icon">q</span>
            {i18n.t<string>('log on')}
          </button>
        </div>
      </div>
    );
  }

  if (onlyInsideforLiveTV && !isHome) {
    return (
      <div className="hover-overlay not-inside">
        <div className="disabled-content-container not-home">{i18n.t<string>("can't show outside home")}</div>
      </div>
    );
  }

  return (
    <div className="hover-overlay">
      <div className="aib-icon">z</div>
    </div>
  );
}
