import { useRef } from 'react';
import AnalyticsTracking from '../controllers/AnalyticsTracking';
import { GAaction, GAcategory } from '../interfaces';

interface TrackFn {
  category?: GAcategory;
  action: GAaction;
  label?: string;
  nonInteraction?: boolean;
  value?: string;
}

export function useTracking() {
  const tracking = useRef(AnalyticsTracking.getInstance());

  function trackEvent({ category, action, label, nonInteraction, value }: TrackFn) {
    tracking.current.trackEvent(category ?? getCurrentCategory(), action, label, nonInteraction, value);
  }

  function getCurrentCategory() {
    return tracking.current.getCurrentCategory();
  }

  return { trackEvent, getCurrentCategory };
}
