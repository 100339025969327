import {
  AltiboxAsset,
  VodAsset,
  PvrRecording,
  AltiboxAssetType,
  AltiboxAssetSearchResponse,
  Cast,
  Program,
} from '../interfaces';
import { isEpisode, assetIsRented } from './vodUtils';
import { firstBy } from 'thenby';
import flatten from 'lodash/flatten';
import i18n from '../i18n';
import safeURIdecode from './safe-url-decode';

export function removeSearchFromBasePath(path: string) {
  let splitPath = path.split('/');
  let idx = splitPath.findIndex((x) => x.indexOf('search') !== -1);
  splitPath.length = idx;
  return splitPath.join('/');
}

export function decodeURIComponentSafe(s: string) {
  if (!s) {
    return s;
  }
  return safeURIdecode(s);
}

export function prettifySearchQueryURL(query: string) {
  return query.replace(/%20/g, '+').split(' ').join('+');
}

export function prettySearchQueryToHuawei(query: string) {
  return query.replace(/%20/g, ' ').replace(/\+/g, ' ');
}

export function prettySearchQuery(query: string) {
  return query.replace(/%20/g, ' ').replace(/\+/g, ' ');
}

export function isOnSearch(location: string) {
  return location.split('/').indexOf('search') !== -1;
}

export function prioritizePvr(assets: AltiboxAsset[]) {
  const pvr = assets.filter((x) => x.asset!.hasOwnProperty('pvrName'));
  const newAssets = assets.filter((x) => pvr.indexOf(x) === -1);

  pvr.forEach((asset) => {
    asset.subtitle = `${i18n.t<string>('recorded')}`;
  });

  return pvr.concat(newAssets);
}

function squashTVGuide(assets: AltiboxAsset[]) {
  const tvGuide = onlyUniqueTVGuideAssets(assets);
  return tvGuide;
}

export function isStringEncoded(str: string) {
  return /%/i.test(str);
}

export async function weighAndSort(assets: AltiboxAsset[] | undefined, query: string, myContent?: VodAsset[]) {
  if (assets && assets.length > 0) {
    let returnAsset = sort(assets.map((x) => weighAsset(x, query)));
    returnAsset = await generalizeVodAssets(returnAsset, myContent);
    return returnAsset;
  } else {
    return [];
  }
}

export function weighAndRetrieveCast(
  assets: AltiboxAssetSearchResponse[][] | undefined,
  query: string,
): [Cast[], Cast[]] {
  let cast: Cast[][] = [];
  if (assets) {
    assets.forEach((result) => {
      result.forEach((searchResult) => {
        searchResult.contentlist.forEach((innerAsset) => {
          cast.push((innerAsset.asset as VodAsset)!.casts);
        });
      });
    });
  }
  let flattenedCast = flatten(cast).filter((x) => x);
  flattenedCast.forEach((x) => (x.weight = x.castName ? weigh(x.castName, query) : 1));
  flattenedCast = flattenedCast.filter((x) => x.weight! > 1);

  let actors = onlyUniqueCasts(flattenedCast.filter((x) => x.roleType === '0')).sort((a, b) =>
    a.castName.localeCompare(b.castName, 'nb'),
  );
  let producers = onlyUniqueCasts(flattenedCast.filter((x) => x.roleType === '4' || x.roleType === '1')).sort((a, b) =>
    a.castName.localeCompare(b.castName, 'nb'),
  );

  return [actors, producers];
}

function weighAsset(asset: AltiboxAsset, searchQuery: string) {
  let target = asset.asset!.name ? asset.asset!.name : (asset.asset! as PvrRecording).pvrName;

  asset.weight = weigh(target, searchQuery);
  asset.isEpisode = checkIfIsEpisode(asset);
  return asset;
}

function weigh(target: string, searchQuery: string) {
  let weight = 1;
  let text = target ? target.trim() : '';
  searchQuery = searchQuery.toLowerCase();
  text = text.toLowerCase();
  let textArr = text.split(' ');

  // https://wiki.altibox.net/display/MTV/Client-side+logic
  if (textArr.indexOf(searchQuery) === 0) {
    // Whole word in the beginnig of the text. Highest weight.
    weight = 100;
  } else if (textArr.indexOf(searchQuery) === textArr.length - 1) {
    // Whole word in the end of the text.
    weight = 60;
  } else if (textArr.indexOf(searchQuery) >= 0) {
    // Whole word somehwere in the text.
    weight = 40;
  } else if (text.startsWith(searchQuery)) {
    // Text starts with the search term.
    weight = 30;
  } else if (textArr.some((inner) => inner.startsWith(searchQuery))) {
    // Some word starts with the text.
    weight = 25;
  } else if (textArr.some((inner) => inner.endsWith(searchQuery))) {
    // Some word ends with the text.
    weight = 20;
  } else if (text.indexOf(searchQuery) !== -1) {
    // Search text is present in the text.
    weight = 10;
  }
  return weight;
}

function sort(assets: AltiboxAsset[]) {
  let sortedAssets = assets.sort(
    firstBy(function (a: AltiboxAsset, b: AltiboxAsset) {
      return Number(a.isEpisode!) - Number(b.isEpisode!);
    })
      .thenBy(function (a: AltiboxAsset, b: AltiboxAsset) {
        return b.weight - a.weight;
      })
      .thenBy(function (a: AltiboxAsset, b: AltiboxAsset) {
        return Number(b.asset!.visittimes!) - Number(a.asset!.visittimes!);
      })
      .thenBy(function (a: AltiboxAsset, b: AltiboxAsset) {
        return a.title.localeCompare(b.title, 'nb');
      }),
  );
  return sortedAssets;
}

function checkIfIsEpisode(altiboxAsset: AltiboxAsset): boolean {
  if ((altiboxAsset.asset! as PvrRecording).pvrName || altiboxAsset.type === AltiboxAssetType.CATCHUP) {
    return isPvrOrProgramEpisode(altiboxAsset);
  } else {
    return isEpisode(altiboxAsset.asset! as VodAsset);
  }
}

function isPvrOrProgramEpisode(altiboxAsset: AltiboxAsset) {
  if (altiboxAsset.hasOwnProperty('episodeTitle')) {
    return altiboxAsset.episodeTitle ? true : false;
  } else {
    return altiboxAsset.episodeSeasonTag !== '';
  }
}

export function reduceProgramArchiveResults(searchAssets: AltiboxAssetSearchResponse) {
  searchAssets.contentlist = onlyUniqueProgramArchiveAssets(searchAssets.contentlist);
  return searchAssets;
}

export function reducePVRResults(searchAssets: AltiboxAssetSearchResponse) {
  searchAssets.contentlist = onlyUniquePvrAssets(searchAssets.contentlist);
  return searchAssets;
}

export function reduceTVGuideResults(searchAssets: AltiboxAssetSearchResponse) {
  searchAssets.contentlist = squashTVGuide(searchAssets.contentlist);
  return searchAssets;
}

function onlyUniqueProgramArchiveAssets(assets: AltiboxAsset[]) {
  return assets.reduce((unique: AltiboxAsset[], o: AltiboxAsset) => {
    if (!unique.some((obj) => (obj.asset! as Program).seriesID === (o.asset! as Program).seriesID)) {
      unique.push(o);
    }
    return unique;
  }, []);
}

function onlyUniqueTVGuideAssets(assets: AltiboxAsset[]) {
  return assets.reduce((unique: AltiboxAsset[], o: AltiboxAsset) => {
    if (
      !unique.some(
        (obj) =>
          (obj.asset! as Program).seriesID === (o.asset! as Program).seriesID &&
          (obj.asset! as Program).channelid === (o.asset! as Program).channelid,
      )
    ) {
      unique.push(o);
    }
    return unique;
  }, []);
}

function onlyUniquePvrAssets(assets: AltiboxAsset[]) {
  return assets.reduce((unique: AltiboxAsset[], o: AltiboxAsset) => {
    if (!unique.some((obj) => (obj.asset! as PvrRecording).seriesId === (o.asset! as PvrRecording).seriesId)) {
      unique.push(o);
    }
    return unique;
  }, []);
}

function onlyUniqueCasts(casts: Cast[]) {
  return casts.reduce((unique: Cast[], o: Cast) => {
    if (!unique.some((obj) => obj.castName === o.castName)) {
      unique.push(o);
    }
    return unique;
  }, []);
}

async function generalizeVodAssets(assets: AltiboxAsset[], myContent: VodAsset[] = []) {
  assets.forEach((altiboxAsset) => {
    altiboxAsset.asset = altiboxAsset.asset as VodAsset;
    if (altiboxAsset.type === AltiboxAssetType.SVOD || altiboxAsset.type === AltiboxAssetType.VOD) {
      let hasFather = altiboxAsset.asset.fathervodlist && altiboxAsset.asset.fathervodlist[0];
      let isOwned = false;
      let isRented = false;

      if (altiboxAsset.type === AltiboxAssetType.VOD) {
        const ownedAsset = myContent.find((owned) => owned.id === altiboxAsset.asset.id);

        if (ownedAsset) {
          isOwned = true;

          if (assetIsRented(ownedAsset)) {
            isRented = true;
          }
        }
      }

      if (altiboxAsset.isEpisode && hasFather) {
        altiboxAsset.subtitle = 'Episode' + (altiboxAsset.genre ? ' - ' + altiboxAsset.genre : '');
      } else {
        if (altiboxAsset.asset!.hasOwnProperty('seriesType')) {
          if (altiboxAsset.type === AltiboxAssetType.VOD && isOwned) {
            altiboxAsset.subtitle = i18n.t<string>('series') + ' - ' + i18n.t<string>(isRented ? 'rented' : 'bought');
          } else {
            altiboxAsset.subtitle = i18n.t<string>('series') + (altiboxAsset.genre ? ' - ' + altiboxAsset.genre : '');
          }
        } else {
          if (altiboxAsset.type === AltiboxAssetType.VOD && isOwned) {
            altiboxAsset.subtitle = i18n.t<string>('movie') + ' - ' + i18n.t<string>(isRented ? 'rented' : 'bought');
          } else {
            altiboxAsset.subtitle = i18n.t<string>('movie') + (altiboxAsset.genre ? ' - ' + altiboxAsset.genre : '');
          }
        }
      }
    }
  });
  return assets;
}
