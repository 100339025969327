import moment from 'moment';
import { huaweiDateTimeFormat } from '../../config';
import { Bookmark, StreamTypeNumber } from '../../interfaces';

export function validBookmarksFor(bookmarks: Bookmark[] | undefined, bookmarkType?: StreamTypeNumber) {
  if (!bookmarks) {
    return [];
  }

  let bookmarksForType: Bookmark[];
  if (bookmarkType !== undefined) {
    bookmarksForType = bookmarks.filter((b) => b.bookmarkType === bookmarkType);
  } else {
    bookmarksForType = bookmarks
      .filter((b) => b.bookmarkType !== StreamTypeNumber.PVR)
      .filter((b) => b.bookmarkType !== StreamTypeNumber.CATCHUP);
  }

  return bookmarksForType.filter(bookmarkRangeTimeIsValid).filter(bookmarkIsRecentlyUpdated);
}

function bookmarkIsRecentlyUpdated(bookmark: Bookmark) {
  const recentlyBoundaryDate = moment().subtract(30, 'days');
  const lastWatchedDate = moment(bookmark.updateTime, huaweiDateTimeFormat);
  return lastWatchedDate.isAfter(recentlyBoundaryDate);
}

function bookmarkRangeTimeIsValid(bookmark: Bookmark) {
  const rangeTime = parseInt(bookmark.rangeTime, 10);
  return rangeTime > 0;
}
