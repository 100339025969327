import OptionsModal from '../../components/OptionsModal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { markSeasonForDeletion, MarkSeasonForDeletion, deleteSeason, loadPVRSpace } from './actions';
import {
  RootState,
  PvrRecording,
  Season,
  GAaction,
  OptionsModalContent,
  OptionType,
  PvrReducerState,
} from '../../interfaces';
import { getSeasonAndEpisodeLabel } from '../../utils/pvrUtils';
import i18n from '../../i18n';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';

type Props = {
  hideModal: () => MarkSeasonForDeletion;
  showDeleteSeasonModal: boolean;
  deleteSeason: (_: PvrRecording[]) => void;
  loadPVRSpace: () => Promise<void>;
  modalHeroBackground?: string;
  recordingDeleted: PvrRecording | PvrRecording[] | undefined;
};

type State = OptionsModalContent;

class DeleteSeasonModal extends Component<Props, State> {
  tracking = AnalyticsTracking.getInstance();

  state: State = {
    modalIsOpen: false,
    title: i18n.t<string>('delete seasons'),
    subtitle: ' ',
    subtitle2: '',
    posttitle: '',
    closetext: i18n.t<string>('cancel'),
    options: [],
    onAction: this.onAction(),
    onClose: () => {
      this.props.hideModal();
    },
    error: false,
    deletedRecordings: [],
  };

  onAction() {
    return (selectedAction: OptionType) => {
      if (selectedAction && selectedAction.nextAction === 'confirm') {
        const season = selectedAction.season;
        this.setState({
          modalIsOpen: true,
          title: `${i18n.t<string>('confirm deletion')}`,
          subtitle: `${i18n.t<string>('are you sure to delete season')} ${
            season?.seasonNum
              ? `<b> ${selectedAction.series?.periodPVRTaskName} ${i18n.t<string>('season')} ${season?.seasonNum}</b>?`
              : `<b> ${selectedAction.series?.periodPVRTaskName}</b>?`
          }`,
          options: [
            {
              title: `${i18n.t<string>('confirm')}`,
              type: 'secondary',
              style: '',
              series: selectedAction.series,
              season: season,
              nextAction: 'delete',
            },
          ] as OptionType[],
        });
      } else if (selectedAction.nextAction === 'delete') {
        this.props.loadPVRSpace();
        this.tracking.trackEvent(
          this.tracking.getCurrentCategory(),
          GAaction.recordDeleteSeason,
          selectedAction.series?.channelName +
            ' - ' +
            selectedAction.series?.periodPVRTaskName +
            ' - ' +
            selectedAction && selectedAction.series
            ? getSeasonAndEpisodeLabel(selectedAction.series)
            : '',
        );
        if (selectedAction.season) {
          this.props.deleteSeason(selectedAction.season.episodes);
        }
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props & PvrReducerState) {
    this.handleDeletedRecordings(nextProps);
    const seasons = this.removeDeletedEpisodes(nextProps);
    const showDeleteSeasonModal = nextProps.showDeleteSeasonModal;

    if (showDeleteSeasonModal && seasons) {
      this.setState({
        modalIsOpen: showDeleteSeasonModal,
        options: seasons.map((season: Season) => {
          return {
            title: season.seasonNum
              ? `${i18n.t<string>('season')} ${season.seasonNum} `
              : nextProps.currentPvrSeries?.periodPVRTaskName,
            titleInfo: `${season.episodes.length}  ${
              season.episodes.length > 1
                ? i18n.t<string>('episodes').toLowerCase()
                : i18n.t<string>('episode').toLowerCase()
            }`,
            type: 'primary',
            style: '',
            series: nextProps.currentPvrSeries,
            season: season,
            nextAction: 'confirm',
          } as OptionType;
        }),
      });
    } else {
      this.setState({
        modalIsOpen: false,
      });
      this.props.hideModal();
    }
  }

  handleDeletedRecordings(nextProps: Props & PvrReducerState) {
    if (nextProps.recordingDeleted) {
      if (Array.isArray(nextProps.recordingDeleted)) {
        this.setState({
          deletedRecordings: this.state.deletedRecordings
            ? [...this.state.deletedRecordings, ...nextProps.recordingDeleted.map((recording) => recording.pvrId)]
            : nextProps.recordingDeleted.map((recording) => recording.pvrId),
        });
      } else {
        this.setState({
          deletedRecordings: this.state.deletedRecordings
            ? [...this.state.deletedRecordings, nextProps.recordingDeleted.pvrId]
            : [nextProps.recordingDeleted.pvrId],
        });
      }
    }
  }

  removeDeletedEpisodes(nextProps: Props & PvrReducerState) {
    const { deletedRecordings } = this.state;

    if (nextProps.currentPvrSeries && deletedRecordings) {
      const newSortedSeasons = nextProps.currentPvrSeries.sortedSeasons.map((season: Season) => {
        return {
          ...season,
          episodes: season.episodes.filter((episode) => !deletedRecordings.includes(episode.pvrId)),
        };
      });

      return newSortedSeasons?.filter((season) => season.episodes.length > 0);
    }

    return nextProps.currentPvrSeries?.sortedSeasons;
  }

  render() {
    return (
      <OptionsModal
        {...this.state}
        modalHeroBackground={this.props.modalHeroBackground}
        customClass={'options-modal'}
        hideModal={this.props.hideModal}
      />
    );
  }
}

export default connect(
  (state: RootState) => {
    return {
      showDeleteSeasonModal: state.pvrReducer.showDeleteSeasonModal,
      currentPvrSeries: state.pvrReducer.currentPvrSeries,
      recordingDeleted: state.pvrReducer.recordingDeleted,
    };
  },
  (dispatch) => ({
    deleteSeason: (season: PvrRecording[]) => dispatch(deleteSeason(season)),
    hideModal: () => dispatch(markSeasonForDeletion(false)),
    loadPVRSpace: () => dispatch(loadPVRSpace()),
  }),
)(DeleteSeasonModal);
