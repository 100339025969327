import { Movie, TVEpisode, WithContext } from 'schema-dts';
import { AltiboxAsset, Program, UnionAssetTypes, VodAsset } from '../interfaces';
import {
  getAltiboxAssetCast,
  getAltiboxAssetDistributor,
  getAltiboxAssetPoster,
  getAltiboxAssetThumbnail,
  getAltiboxAssetTitle,
  wrapAssetAsAltiboxAsset,
} from './altiboxassetUtils';
import { getDurationInSeconds } from './huaweiUtils';
import { getVodDuration } from './vodUtils';
import moment from 'moment';

/* eslint-disable @typescript-eslint/dot-notation */
const jsonLDBase = { '@context': 'https://schema.org' };
export function generateSingleMovieJsonLD(currentAsset: AltiboxAsset): WithContext<Movie> {
  const asset = currentAsset.asset as UnionAssetTypes;
  const producedate = generateProducedate(currentAsset.asset!['producedate']!);
  const cast = getAltiboxAssetCast(currentAsset);
  const [actors, producers] = cast;

  const subtitles = asset['subtitles'];
  const productionCompany = getAltiboxAssetDistributor(currentAsset);
  const movieJSON = {
    '@type': 'Movie',
    name: getAltiboxAssetTitle(currentAsset),
    dateCreated: producedate,
    director: producers ? generatePerson(producers[0].castName) : undefined,
    actors: actors ? actors.map((a) => generatePerson(a.castName)) : undefined,
    image: getAltiboxAssetPoster(currentAsset),
    duration: getDurationForAsset(asset),
    productionCompany: productionCompany,
    subtitleLanguage: subtitles,
  };
  const unusedProperties = Object.keys(movieJSON).filter(
    (i) => movieJSON[i] === 'N/A' || typeof movieJSON[i] === 'undefined',
  );
  unusedProperties.forEach((name) => delete movieJSON[name]);

  return { ...jsonLDBase, ...movieJSON } as WithContext<Movie>;
}

export function generateEpisodeJsonLD(
  currentAsset: AltiboxAsset,
  promotedAsset: UnionAssetTypes,
): WithContext<TVEpisode> {
  const asset = currentAsset.asset as UnionAssetTypes;
  const wrappedAsset = { ...wrapAssetAsAltiboxAsset(promotedAsset), type: promotedAsset.type! };
  const producedate = generateProducedate(currentAsset.asset!['producedate']!);
  const cast = getAltiboxAssetCast(wrappedAsset);
  const [actors, producers] = cast;
  const subtitles = asset['subtitles'];
  const productionCompany = getAltiboxAssetDistributor(currentAsset);
  const seasonNumber = promotedAsset['seasonNumber'] ?? promotedAsset['seasonNum'];
  const episodeNumber = promotedAsset['sitcomnum'] ?? promotedAsset['subNum'];

  let episodeJSON = {
    '@type': 'TVEpisode',
    name: promotedAsset.name,
    partOfTVSeries: {
      '@type': 'TVSeries',
      name: getAltiboxAssetTitle(currentAsset),
    },
    partOfSeason: {
      '@type': 'TVSeason',
      seasonNumber: seasonNumber,
    },
    duration: getDurationForAsset(asset),
    episodeNumber: episodeNumber,
    director: producers ? generatePerson(producers[0].castName) : undefined,
    actors: actors ? actors.map((a) => generatePerson(a.castName)) : undefined,
    image: getAltiboxAssetThumbnail(wrappedAsset, currentAsset),
    productionCompany: productionCompany,
    subtitleLanguage: subtitles,
    datePublished: producedate,
  };

  const unusedProperties = Object.keys(episodeJSON).filter(
    (i) => episodeJSON[i] === 'N/A' || typeof episodeJSON[i] === 'undefined',
  );
  unusedProperties.forEach((name) => delete episodeJSON[name]);
  return { ...jsonLDBase, ...episodeJSON } as WithContext<TVEpisode>;
}

function generatePerson(name: string) {
  return {
    '@type': 'Person',
    name: name,
  };
}

function generateProducedate(produceDate: string) {
  return moment(produceDate, 'YYYY-MM-DD').add(1, 'hour').utc().format();
}

function getDurationForAsset(currentAsset: UnionAssetTypes) {
  const start = (currentAsset as Program).starttime || undefined;
  const end = (currentAsset as Program).endtime || undefined;
  let seconds = 0;
  if (start && end) {
    seconds = getDurationInSeconds(start, end);
  } else {
    seconds = getVodDuration(currentAsset as VodAsset);
  }
  return moment.duration(seconds, 'seconds').toISOString();
}

/* eslint-enable @typescript-eslint/dot-notation */
