import React, { FC } from 'react';
import styles from './style.module.scss';
import { addFiltersToLink, getCurrentLangImage, getCurrentLangTitle } from '../../utils/vodUtils';
import { InlineVodPromotion } from '../../interfaces';
import { Link } from 'react-router-dom';
import { routes } from '../../config';

interface Props {
  imagePath: string;
  baseUrl: string;
  promotions?: InlineVodPromotion[];
}

const SvodInlinePromotion: FC<Props> = ({ promotions, imagePath, baseUrl }) => {
  const getLink = (promotion: InlineVodPromotion) => {
    if (!promotion.actionParams) {
      return routes.svod.base;
    }

    let sorting = promotion.actionParams.sorting ? promotion.actionParams.sorting : '2';
    let link = `${baseUrl}${routes.svod.category}/${promotion.actionParams.categoryId}/${sorting}`;

    link = addFiltersToLink(promotion.actionParams.filter, link);

    return link;
  };

  if (!promotions) {
    return null;
  }

  return (
    <div className={styles['svod-inline-promotions']}>
      {promotions.map((promotion, index) => {
        return (
          <div key={'inline-promotion-' + index} className={styles['inline-promotion-item']}>
            <Link to={getLink(promotion)}>
              <img
                alt={getCurrentLangTitle(promotion.actionParams.title)}
                title={getCurrentLangTitle(promotion.actionParams.title)}
                className={'aib-hover-glow'}
                src={imagePath + '/' + getCurrentLangImage(promotion.image)}
              />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default SvodInlinePromotion;
