import './style.scss';

import {
  AltiboxAssetDetailsType,
  BroadcastStatus,
  Channel,
  FavoritePrograms,
  Program as ProgramType,
  PvrRecording,
  RootState,
} from '../../../interfaces';
import React, { RefCallback, useCallback, useEffect, useState } from 'react';
import { broadcastStatus, broadcastingNow } from '../../../utils/huaweiUtils';
import {
  catchupAvailable,
  channelHasCatchupEnabled,
  formatSeasonAndEpisode,
  formatTimeSpan,
  isRecording,
} from '../../../utils/tvUtils';
import { isDisneyChannel } from '../../../utils/programArchiveUtils';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { routes } from '../../../config';
import { showDetailsPanel as showDetailsPanelAction } from '../../../views/details/actions';
import { useGesture } from 'react-use-gesture';
import { useThirdPartyCatchupProgram } from '../../../queries/third-party-catchup/queries';

interface ProgramProps {
  program: ProgramType;
  seriesRecordings: PvrRecording[];
  singleRecordings: PvrRecording[];
  focused: boolean;
  favorites: FavoritePrograms;
  showDetailsPanel: (program: ProgramType) => void;
}

const Program = React.forwardRef<HTMLButtonElement, ProgramProps>(
  ({ program, seriesRecordings, singleRecordings, focused, favorites, showDetailsPanel }, ref) => {
    const formattedTime = formatTimeSpan(program.starttime, program.endtime);
    const seasonAndEpisodeShorthand = formatSeasonAndEpisode(program.seasonNum, program.subNum);
    const recording = isRecording(program, singleRecordings, seriesRecordings);
    const status = broadcastStatus(program);
    const favourited = favorites[program.seriesID] || favorites[program.id];

    const { data: thirdPartyInfo } = useThirdPartyCatchupProgram(program);

    if (status === BroadcastStatus.Finished && thirdPartyInfo && thirdPartyInfo.isExternal && !thirdPartyInfo.isValid) {
      return null;
    }

    if (status === BroadcastStatus.Finished && !catchupAvailable(program)) {
      return null;
    }

    const buttonClass = `miniEpgProgram${status === BroadcastStatus.Live ? ' current' : ''}`;
    const newRef = focused ? ref : null;
    return (
      <li>
        <button className={buttonClass} onClick={() => showDetailsPanel(program)} ref={newRef}>
          <div className={'timeAndIcons'}>
            <div className={'time'}>
              {formattedTime}
              {status === BroadcastStatus.Live && <span className={'now'}>{i18n.t<string>('now')}</span>}
            </div>
            <div className={'icons'}>
              {recording && (
                <div className="iconSpacer">
                  <span className="icon red">7</span>
                </div>
              )}
              {favourited && (
                <div className="iconSpacer">
                  <span className="icon red">M</span>
                </div>
              )}
              {status === BroadcastStatus.Finished && (
                <div className="iconSpacer">
                  <span className="icon white">z</span>
                </div>
              )}
              {status === BroadcastStatus.Live && (
                <div className="iconSpacer">
                  <span className="icon">z</span>
                </div>
              )}
            </div>
          </div>
          <div className={'programNameContainer'}>
            <div className={'programName'}>{program.name}</div>
            {seasonAndEpisodeShorthand && <div className={'seasonAndEpisode'}>&nbsp;- {seasonAndEpisodeShorthand}</div>}
          </div>
        </button>
      </li>
    );
  },
);

enum ScrollPos {
  Top,
  Middle,
  Bottom,
}

interface Props {
  programs: ProgramType[];
  favorites: FavoritePrograms;
  showDetailsPanel: (program: ProgramType) => void;
  seriesRecordings: PvrRecording[];
  singleRecordings: PvrRecording[];
  channel: Channel;
}

const MiniEpg = ({ programs, favorites, showDetailsPanel, seriesRecordings, singleRecordings, channel }: Props) => {
  const [focusedProgram, setFocusedProgram] = useState(programs.indexOf(programs.find(broadcastingNow)!));
  const [focusedProgramEl, setfocusedProgramEl] = useState<HTMLButtonElement | HTMLAnchorElement | null>(null);
  const focusedProgramRef: RefCallback<HTMLButtonElement | HTMLAnchorElement> = useCallback(
    (element) => setfocusedProgramEl(element),
    [],
  );

  const [listEl, setListEl] = useState<HTMLUListElement | null>(null);
  const listRef: RefCallback<HTMLUListElement> = useCallback((element) => setListEl(element), []);

  const [scrollPos, setScrollPos] = useState(channelHasCatchupEnabled(channel) ? ScrollPos.Middle : ScrollPos.Top);

  const hasProgramArchive =
    channel.channelPermissions.hasEnabledCatchup &&
    !channel.channelPermissions.notInSubscription &&
    !isDisneyChannel(channel);

  const handleUp = () => {
    if (listEl && focusedProgramEl) {
      const height = focusedProgramEl.offsetHeight;
      window.requestAnimationFrame(() => listEl.scrollBy({ top: -height, behavior: 'smooth' }));
    }
  };

  const handleDown = () => {
    if (listEl && focusedProgramEl) {
      const height = focusedProgramEl.offsetHeight;
      window.requestAnimationFrame(() => listEl.scrollBy({ top: height, behavior: 'smooth' }));
    }
  };

  useEffect(() => {
    setFocusedProgram(programs.indexOf(programs.find(broadcastingNow)!));

    setInterval(() => {
      setFocusedProgram((prev) => {
        const next = programs.find(broadcastingNow);

        if (next) {
          return programs.indexOf(next);
        }

        return prev;
      });
    }, 1000 * 60 * 1);
  }, [programs]);

  useEffect(() => {
    if (listEl && focusedProgramEl) {
      const { offsetHeight, offsetTop } = focusedProgramEl;
      const scrollTo = offsetTop - (offsetHeight + offsetHeight / 4);

      window.requestAnimationFrame(() => (listEl.scrollTop = scrollTo));
    }
  }, [listEl, focusedProgramEl]);

  const bindGestures = useGesture({
    onScroll: () => {
      if (listEl) {
        if (listEl.scrollTop <= 5) {
          setScrollPos(ScrollPos.Top);
        } else if (listEl.scrollHeight - listEl.scrollTop === listEl.clientHeight) {
          setScrollPos(ScrollPos.Bottom);
        } else {
          setScrollPos(ScrollPos.Middle);
        }

        const visibleElementCount = listEl.clientHeight / listEl.children[0].clientHeight;

        // Make only visible elements in list tabable
        [...listEl.children].forEach((element) => {
          if (!(element instanceof HTMLElement)) {
            return;
          }

          if (
            element.offsetTop + element.clientHeight / 3 > listEl.scrollTop &&
            element.offsetTop < listEl.scrollTop + element.clientHeight * visibleElementCount
          ) {
            element.children[0]?.removeAttribute('tabindex');
          } else {
            element.children[0]?.setAttribute('tabindex', '-1');
          }
        });
      }
    },
  });

  const buttonUpClass = `miniEpgNavButton ${scrollPos === ScrollPos.Top ? 'transparent' : ''}`;
  const buttonDownClass = `miniEpgNavButton ${scrollPos === ScrollPos.Bottom ? 'transparent' : ''}`;
  const listClass = `${hasProgramArchive ? '' : 'shortScrollbar'}`;
  return (
    <div className="miniEpg">
      <button className={buttonUpClass} onClick={handleUp}>
        <span className={'icon'}>u</span>
      </button>
      <ul className={listClass} {...bindGestures()} ref={listRef}>
        {hasProgramArchive ? (
          <li>
            <Link
              className="miniEpgProgram link"
              to={`${routes.programarchive.base}${routes.programarchive.channel}/${channel.contentId}`}
            >
              <span>{i18n.t<string>('goto program archive')}</span>
              <span className="icon">V</span>
            </Link>
          </li>
        ) : (
          <li className={'listSpacer'} />
        )}
        {programs.map((program, idx) => (
          <Program
            key={program.starttime}
            program={program}
            showDetailsPanel={showDetailsPanel}
            seriesRecordings={seriesRecordings}
            singleRecordings={singleRecordings}
            focused={focusedProgram === idx}
            ref={focusedProgramRef}
            favorites={favorites}
          />
        ))}
        <li>
          <Link className="miniEpgProgram  link" to={routes.tvguide.base}>
            <span>{i18n.t<string>('goto tv-guide')}</span>
            <span className="icon">b</span>
          </Link>
        </li>
      </ul>
      <button className={buttonDownClass} onClick={handleDown}>
        <span className={'icon'}>U</span>
      </button>
    </div>
  );
};

export default connect(
  ({ channelsReducer: { seriesRecordings, singleRecordings } }: RootState) => ({
    seriesRecordings,
    singleRecordings,
  }),
  (dispatch) => ({
    showDetailsPanel: (program: ProgramType) => {
      return dispatch(
        showDetailsPanelAction({
          routeProps: { childId: program.id, parentId: program.seriesID },
          displayType: AltiboxAssetDetailsType.CATCHUP,
          clickedId: program.id,
        }),
      );
    },
  }),
)(MiniEpg);
