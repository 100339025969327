import React, { Component } from 'react';
import './style.scss';

type Props = {
  wrapInContainer?: boolean;
};

export default class Spinner extends Component<Props> {
  renderAnimation() {
    return (
      <div className="loading-animation">
        <div className="loader-container arc-rotate2">
          <div className="loader">
            <div className="arc" />
          </div>
        </div>
      </div>
    );
  }

  renderWrappedAnimation() {
    return <div className="loading-animation-container">{this.renderAnimation()}</div>;
  }

  render() {
    return this.props.wrapInContainer ? this.renderWrappedAnimation() : this.renderAnimation();
  }
}
