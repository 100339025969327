import { ScriptService } from '../../../controllers/ScriptService';
import {
  StreamType,
  ChromecastStreamTypeNumber,
  StreamTypeObject,
  AuthReducerState,
  AltiboxAsset,
  VodAsset,
  Program,
  PvrRecording,
  UnionAssetTypes,
} from '../../../interfaces';
import { getBaseUrl, altiboxApiUrlBase, isDebug } from '../../../config';
import { getDurationInSeconds } from '../../../utils/huaweiUtils';
import {
  getDefaultSubtitleLanguage,
  getDefaultAudioLanguage,
  addBookmark,
  doPlayRecord,
} from '../../../controllers/PlayerService';
import i18n from '../../../i18n';
import { getAltiboxAssetTitle } from '../../../utils/altiboxassetUtils';
import { isEmpty } from 'lodash';
import { formatSeasonAndEpisode } from '../../../utils/tvUtils';

let currentStream: StreamTypeObject | undefined = undefined;
let authState: AuthReducerState | undefined = undefined;
let playerController: cast.framework.RemotePlayerController | undefined = undefined;

export function getCastSession() {
  if (!window.cast) {
    return null;
  }
  return window.cast.framework.CastContext.getInstance().getCurrentSession();
}

export function setNewCastStream(stream: StreamTypeObject | undefined, authStatus: AuthReducerState | undefined) {
  currentStream = stream;
  authState = authStatus;
}

export function getCastImage() {
  let imageUrl = '';
  if (currentStream && currentStream.picture) {
    if (currentStream.picture.href) {
      imageUrl = currentStream.picture.href;
    } else if (currentStream.picture.other) {
      imageUrl = currentStream.picture.other;
    } else if (currentStream.picture.background) {
      imageUrl = currentStream.picture.background;
    } else if (currentStream.picture.poster) {
      imageUrl = currentStream.picture.poster;
    }
  }
  if (!imageUrl) {
    let currentMediaStatus = getCurrentCastMediaStatus();
    if (currentMediaStatus && hasImageSet(currentMediaStatus)) {
      imageUrl = currentMediaStatus.media.metadata.images[1].url;
    }
  }
  return imageUrl.split(',')[0];
}

function hasImageSet(currentMediaStatus: chrome.cast.media.Media) {
  if (currentMediaStatus?.media?.metadata) {
    const { images } = currentMediaStatus.media.metadata;
    return Boolean(images && images.length > 1 && images[1] && images[1].url);
  }
  return false;
}

export function shouldShowCastPlayer() {
  return Boolean(ScriptService.canCast() && getCastSession());
}

export function getCurrentCastMediaStatus() {
  let castSession = getCastSession();
  /* Custom message bus
  castSession.addMessageListener("urn:x-cast:custom.altibox.message", (namespace, message) => {
  });
  */
  if (castSession) {
    let castMediaStatus = castSession.getMediaSession();
    if (castMediaStatus) {
      if (isDebug) {
        console.log('GETTING STATUS');
        console.log(castMediaStatus);
      }
      return castMediaStatus;
    }
  }
  return undefined;
}

export function getCastPlayerController() {
  if (playerController) {
    return playerController;
  }
  let player = new window.cast.framework.RemotePlayer();
  return new window.cast.framework.RemotePlayerController(player);
}

export function getCastDeviceName() {
  const castSession = getCastSession();
  if (castSession && castSession.getCastDevice) {
    const device = castSession.getCastDevice();
    return device.friendlyName ? device.friendlyName : 'Unknown';
  }
  return 'Unknown';
}

export function listenForCastConnection(
  callback: (
    castStateEvent?: cast.framework.CastStateEventData,
    sessionStateEvent?: cast.framework.SessionStateEventData,
  ) => void,
) {
  const context = window.cast.framework.CastContext.getInstance();
  context.addEventListener(window.cast.framework.CastContextEventType.CAST_STATE_CHANGED, function (castStateEvent) {
    callback(castStateEvent, undefined);
  });
  context.addEventListener(
    window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
    function (sessionStateEvent) {
      const { sessionState, session } = sessionStateEvent;
      let mediaSession: chrome.cast.media.Media | null = null;
      if (session && session.getMediaSession && session.getMediaSession()) {
        mediaSession = session.getMediaSession();
      }
      if (
        sessionState &&
        sessionState === window.cast.framework.SessionState.SESSION_ENDED &&
        mediaSession &&
        !isEmpty(mediaSession) &&
        mediaSession.media &&
        mediaSession &&
        mediaSession.getEstimatedTime() &&
        authState &&
        authState.loggedInWithCredentials &&
        currentStream
      ) {
        const { pvrId, contentId } = currentStream;
        const currentTime = mediaSession.getEstimatedTime();
        const assetIdentifier = currentStream && pvrId ? pvrId : contentId;
        if (currentTime >= 0) {
          addBookmark(
            assetIdentifier, // pvrId for PVR
            currentStream.streamType,
            currentTime,
          ).then(() => {
            window.location.reload();
          });
        }
      }
      callback(undefined, undefined);
    },
  );
}

export let currentOnPlayingCallback: Function | undefined = undefined;
export const setNewCurrentOnPlayingCallback = (newCurrentOnPlayingCallback: Function | undefined) => {
  currentOnPlayingCallback = newCurrentOnPlayingCallback;
};

function getCastPlayType() {
  if (currentStream) {
    switch (currentStream.streamType) {
      case StreamType.VOD:
        return ChromecastStreamTypeNumber.VOD;
      case StreamType.CATCHUP:
        return ChromecastStreamTypeNumber.CATCHUP;
      case StreamType.PVR:
        return ChromecastStreamTypeNumber.PVR;
      case StreamType.TRAILER:
        return ChromecastStreamTypeNumber.TRAILER;
      case StreamType.LIVE:
        return ChromecastStreamTypeNumber.LIVE;
      default:
        return undefined;
    }
  }
  return undefined;
}

const castStreamImage = (activeStream: StreamTypeObject) => {
  let { streamType, picture } = activeStream;

  if (!picture) {
    return;
  }
  switch (streamType) {
    case StreamType.CATCHUP: {
      return [{ url: picture.deflate ?? '' }, { url: picture.poster ?? '' }];
    }
    case StreamType.LIVE: {
      const { poster, other, background } = picture;
      return [{ url: poster ?? '' }, { url: other ? other : background ?? '' }];
    }
    case StreamType.PVR: {
      return [{ url: getCastImage() }, { url: getCastImage() }];
    }
    case StreamType.VOD: {
      return [{ url: getCastImage() }, { url: getCastImage() }];
    }
  }
};

const debugChromecastRequest = (request: chrome.cast.media.LoadRequest) => {
  if (isDebug) {
    console.log('SENDING');
    console.log(request);
  }
};

const loadChromecastMedia = (
  request: chrome.cast.media.LoadRequest,
  castSession: cast.framework.CastSession | null,
) => {
  if (castSession && castSession.loadMedia) {
    castSession.loadMedia(request);
  }
};

const getSubtitle = (streamType: StreamType, asset: UnionAssetTypes) => {
  switch (streamType) {
    case StreamType.CATCHUP:
    case StreamType.PVR:
      let program = asset as Program | PvrRecording;
      return formatSeasonAndEpisode(program.seasonNum, program.subNum, false);
    case StreamType.VOD:
      let vod = asset as VodAsset;
      return formatSeasonAndEpisode(vod.seasonNumber, vod.sitcomnum!, false);
  }
};

const createMediaInfo = (
  mediaId: string | undefined,
  contentId: string,
  currentAsset: AltiboxAsset,
  images: { url: string }[] | undefined,
  secondsFromLive: number,
) => {
  let mediaInfo = {
    customData: {
      mediaId: mediaId,
      contentId: contentId,
      altiboxUrl: altiboxApiUrlBase,
      baseUrl: getBaseUrl(),
      playType: getCastPlayType(),
      pvrId: currentStream!.pvrId,
      currentSubtitle: getDefaultSubtitleLanguage(),
      currentAudio: getDefaultAudioLanguage(),
      systemLanguage: i18n.language.split('-')[0],
      country: ScriptService.isDKUser() ? 'da' : 'no',
      recordedMediaId: currentStream!.mediaId,
      loginToken: localStorage.getItem('cc'),
      isHome: (authState && authState.isHome) ?? false,
      secondsFromLive: Math.abs(secondsFromLive),
    },
  };
  if (currentStream && currentStream.streamType !== StreamType.LIVE) {
    return {
      ...mediaInfo,
      metadata: {
        subtitle: currentAsset ? getSubtitle(currentStream.streamType, currentAsset.asset) : '',
        title: currentAsset ? getAltiboxAssetTitle(currentAsset, true) : 'N/A',
        images: images ?? [{ url: (currentAsset.asset as VodAsset).picture.poster }],
      },
    };
  }
  return mediaInfo;
};

let castTimeout: NodeJS.Timeout | undefined = undefined;
export function castStream(currentAsset: AltiboxAsset, secondsFromLive?: number) {
  if (castTimeout) {
    clearInterval(castTimeout);
  }
  castTimeout = setTimeout(() => {
    if (!currentStream) {
      return;
    }
    let { mediaId, contentId, streamType, startBookmark, programStart, programEnd } = currentStream;
    let mediaInfo = new window.chrome.cast.media.MediaInfo('mpd-not-set', 'video/mp4');
    const images = castStreamImage(currentStream);
    const castSession = getCastSession();

    mediaInfo.customData = {} as StreamTypeObject;
    if (streamType === StreamType.CATCHUP && currentStream.channelId) {
      mediaId = currentStream.contentId;
      contentId = currentStream.channelId;
    }
    mediaInfo = createMediaInfo(
      mediaId,
      contentId,
      currentAsset,
      images,
      secondsFromLive ?? 0,
    ) as unknown as chrome.cast.media.MediaInfo;

    let request = new window.chrome.cast.media.LoadRequest(mediaInfo);

    if (streamType === StreamType.PVR && programStart && programEnd) {
      mediaInfo.duration = getDurationInSeconds(programStart, programEnd);
    }

    request.currentTime = startBookmark ?? 0;
    if (streamType !== StreamType.LIVE) {
      mediaInfo.metadata = { ...mediaInfo.metadata, metadataType: 1, duration: 0 };
      if ((currentAsset.asset as VodAsset).bookmark?.rangeTime) {
        request.currentTime = Number((currentAsset.asset as VodAsset).bookmark?.rangeTime);
      }
    }
    doPlayRecord(contentId, currentStream.streamType, false, mediaId);
    loadChromecastMedia(request, castSession);
    debugChromecastRequest(request);
  }, 500);
}
export function getActiveTracks() {
  let castSession = getCastSession();
  if (castSession) {
    let castMediaStatus = castSession.getMediaSession();
    if (castMediaStatus && castMediaStatus.activeTrackIds) {
      return castMediaStatus.activeTrackIds;
    } else {
      return [];
    }
  }
}

export function getCastTracks() {
  let castSession = getCastSession();
  if (castSession) {
    let castMediaStatus = castSession.getMediaSession();
    if (castMediaStatus && castMediaStatus.media && castMediaStatus.media.tracks) {
      return castMediaStatus.media.tracks;
    } else {
      return [];
    }
  }
}

export function updateCastStatus() {
  let castSession = getCastSession();
  if (castSession) {
    const media = castSession.getMediaSession();
    if (media && media.mediaSessionId) {
      castSession.sendMessage('urn:x-cast:com.google.cast.media', {
        type: 'GET_STATUS',
        requestId: getNewRequestId(),
        mediaSessionId: media.mediaSessionId,
      });
    }
  }
}

// ***************** //
//  player controls  //
// ***************** //
export function cast_playPause() {
  let castPlayerController = getCastPlayerController();
  castPlayerController.playOrPause();
}

export function cast_seek(seekTo: number) {
  let castSession = getCastSession();
  if (castSession) {
    const media = castSession.getMediaSession();
    if (media && media.mediaSessionId) {
      castSession.sendMessage('urn:x-cast:com.google.cast.media', {
        type: 'SEEK',
        requestId: getNewRequestId(),
        currentTime: seekTo,
        mediaSessionId: media.mediaSessionId,
      });
    }
  }
}

let lastUsedRequestId: number;
function getNewRequestId() {
  if (lastUsedRequestId) {
    lastUsedRequestId = lastUsedRequestId + 1;
  }
  return Math.floor(Math.random() * Math.floor(2222222));
}

export function cast_setVolume(volume: number) {
  let castSession = getCastSession();
  if (castSession) {
    castSession.setVolume(Number(volume));
  }
}

export function cast_getVolume() {
  let castSession = getCastSession();
  if (castSession) {
    return castSession.getVolume();
  }
  return 0;
}

export function cast_isMute() {
  let castSession = getCastSession();
  if (castSession) {
    return castSession.isMute();
  }
  return true;
}

export function cast_setMute(mute: boolean) {
  let castSession = getCastSession();
  if (castSession) {
    castSession.setMute(mute);
  }
}

export function cast_setActiveTracks(activeTrackIds: number[]) {
  let castSession = getCastSession();
  if (castSession) {
    const media = castSession.getMediaSession();
    if (media) {
      let tracksInfoRequest = new window.chrome.cast.media.EditTracksInfoRequest(activeTrackIds);
      media.editTracksInfo(
        tracksInfoRequest,
        function () {
          // success
        },
        function () {
          // fail
        },
      );
    }
  }
}

export const reloadAfterCastEnd = (event: cast.framework.RemotePlayerChangedEvent) => {
  if (event.field === 'isConnected' && !event.value) {
    const { location } = window;
    const hrefSplit = location.href.split('/');
    const shouldReload = hrefSplit.some((uri) => uri === 'tv');
    currentStream = undefined;
    if (shouldReload) {
      window.location.reload();
    }
  }
};
