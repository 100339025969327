import { Channel, IsSomething, NamedParameter, Pvr, Cast } from '../../interfaces';
import { Genre } from '../genres/types';

export namespace Recording {
  export enum Definition {
    SD,
    HD,
    FourK,
  }

  export enum DeleteMode {
    Auto = 0,
    Manual = 1,
  }

  export enum Dimension {
    '2D' = 2,
    '3D' = 3,
  }

  /**
   * These types apply when querying `QueryPVR` with the option `expandSubTask: 1`
   */
  export namespace Query {
    export interface Movie {
      beginOffset: string;
      beginTime: string;
      casts?: Cast[];
      channelCode: string;
      channelId: string;
      channelName: string;
      channelNo: string;
      country: string;
      definition: Definition;
      deleteMode: DeleteMode;
      deviceId: string;
      dimension: Dimension;
      endOffset: string;
      endTime: string;
      extensionInfo: NamedParameter;
      foreignsn: string;
      genreIds: string[] | undefined;
      introduce?: string;
      isConflict: IsSomething;
      isPltvtoPVR: IsSomething;
      isShare: IsSomething;
      isWatched: IsSomething;
      lifetimeId: string;
      mediaId: string;
      pictures?: Pvr.Picture[];
      playEnable: IsSomething;
      profileId: string;
      programCode: string;
      programId: string;
      pvrId: string;
      pvrName: string;
      ratingId: string;
      realRecordLength: string;
      status: string;
      type: '2';
      /**
       * Added after fetching
       */
      updateTime?: string;
      bookmarkTime?: string;
      progress?: number;
    }

    export interface Series extends Movie {
      periodPVRTaskId?: string;
      seasonNum?: string;
      seriesId: string;
      subName?: string;
      subNum?: string;
    }

    export type Union = Movie | Series;
  }

  export namespace Group {
    interface CommonProperties extends CommonCustomProperties {
      pvrName: string;
      pvrId: string;
      pictures?: Pvr.Picture[] | undefined;
      genreIds: string[] | undefined;
    }

    interface CommonCustomProperties {
      genreNames: string[] | undefined;
      channel: Channel | undefined;
      unwatchedCount: number;
      /**
       * In days
       */
      lowestExpirationTime: number | undefined;
      /**
       * Added to continue watching assets
       */
      updateTime?: string;
    }

    export interface Series extends CommonProperties {
      episodes: Query.Series[];
      seasons: Season[];
      seriesId: string;
      periodPVRTaskId: string | undefined;
      type: 'series';
    }

    export interface Movie extends CommonProperties {
      asset: Query.Movie;
      type: 'movie';
    }

    export type Union = Series | Movie;

    export interface List {
      [pvrName: string]: Union;
    }

    export interface Seasons {
      [seasonNum: string]: Query.Series[];
    }

    export interface Season {
      pvrName: string;
      pictures: Pvr.Picture[] | undefined;
      seasonNum: string;
      episodes: Query.Series[];
    }
  }

  export namespace Sort {
    export enum Action {
      Newest = 'newest',
      Oldest = 'oldest',
      Alphabetically = 'alphabetically',
      ContinueWatching = 'continueWatching',
      ExpirationTime = 'expirationTime',
    }
  }

  export namespace Swimlane {
    /**
     * Titles are used to identify swimlanes, and generate links.
     * If you add a new one remember to add a corresponding Slug for it.
     */
    export enum Titles {
      AllRecordings = 'all recordings',
      ContinueWatching = 'continue watching',
      DeletingSoon = 'expires soon',
      NewestRecordings = 'newest recordings',
      Series = 'series',
      Movies = 'movies',
    }

    /**
     * Slugs are used in conjunction with Titles, if you add a new one
     * remember to add a corresponding Key.
     */
    export enum Slugs {
      'all recordings' = 'alle-opptak',
      'continue watching' = 'fortsett-å-se',
      'expires soon' = 'slettes-snart',
      'newest recordings' = 'nyeste-opptak',
      'scheduled' = 'planlagte',
      'series' = 'serier',
      'movies' = 'filmer',
    }

    export interface Item {
      title: Titles | Genre.Epg.Translations | string;
      slug: Slugs | string;
      groups: Group.Union[] | undefined;
      order: number;
    }
  }
}
