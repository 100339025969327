import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SortState } from '../..';
import {
  AltiboxAsset,
  SortingValues,
  SortingTypes,
  AltiboxAssetDetailsType,
  Option,
} from '../../../../../../interfaces';
import { OptionButtonSort } from '../../../../Buttons';
import { OptionBox } from '../../../../OptionBox';

export interface SortingProps {
  altiboxAsset: AltiboxAsset;
  sort: SortState;
  setSort: React.Dispatch<React.SetStateAction<SortState>>;
  hasEpisodeNumbers: true | AltiboxAsset | undefined;
}

export function Sorting({ altiboxAsset, sort, setSort, hasEpisodeNumbers }: SortingProps) {
  const sortButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(['sort', 'translations']);

  const toggleSortOptionBox = () => {
    setSort((prev) => ({ ...prev, open: !prev.open }));
  };

  const handleSortBoxOutsideClick = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
    const button = sortButtonRef.current;

    if (!button || !(event.target instanceof Element)) {
      return;
    }

    if (!button.contains(event.target)) {
      toggleSortOptionBox();
    }
  };

  const handleSortSelect = (option: Option) => {
    if (!option.type || !option.value) {
      return;
    }
    setSort({
      open: false,
      type: option.type,
      value: option.value as SortingValues,
    });
  };

  function sortingOptions(): Option[] {
    const episodeOptions = [
      {
        title: `${t('episodes', { ns: 'translations' })} ${t('ascending')}`,
        active: sort.type === SortingTypes.ASC && sort.value === SortingValues.EPISODE,
        type: SortingTypes.ASC,
        value: SortingValues.EPISODE,
      },
      {
        title: `${t('episodes', { ns: 'translations' })} ${t('descending')}`,
        active: sort.type === SortingTypes.DESC && sort.value === SortingValues.EPISODE,
        type: SortingTypes.DESC,
        value: SortingValues.EPISODE,
      },
    ];

    const dateOptions = [
      {
        title: t('latest'),
        active: sort.type === SortingTypes.ASC && sort.value === SortingValues.DATE,
        type: SortingTypes.ASC,
        value: SortingValues.DATE,
      },
      {
        title: t('oldest'),
        active: sort.type === SortingTypes.DESC && sort.value === SortingValues.DATE,
        type: SortingTypes.DESC,
        value: SortingValues.DATE,
      },
    ];

    switch (altiboxAsset.type) {
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PROGRAM:
      case AltiboxAssetDetailsType.PVR:
        return hasEpisodeNumbers ? [...episodeOptions, ...dateOptions] : dateOptions;

      case AltiboxAssetDetailsType.VOD:
      case AltiboxAssetDetailsType.VIDEO_VOD:
      case AltiboxAssetDetailsType.SVOD:
        return episodeOptions;
      default:
        return [];
    }
  }

  switch (altiboxAsset.type) {
    case AltiboxAssetDetailsType.CATCHUP:
    case AltiboxAssetDetailsType.PROGRAM:
    case AltiboxAssetDetailsType.PVR:
    case AltiboxAssetDetailsType.SVOD:
    case AltiboxAssetDetailsType.VOD:
    case AltiboxAssetDetailsType.VIDEO_VOD:
      return (
        <div className="altibox-asset-sorting-container">
          <OptionButtonSort ref={sortButtonRef} onClick={() => toggleSortOptionBox()}>
            {t('sort')}
          </OptionButtonSort>
          {sort.open ? (
            <OptionBox
              className="altibox-asset-sorting-box"
              title={t('sort')}
              options={sortingOptions()}
              onOutsideClick={handleSortBoxOutsideClick}
              handleSelect={handleSortSelect}
            />
          ) : null}
        </div>
      );
    default:
      return null;
  }
}
