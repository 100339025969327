import { Component } from 'react';
import { connect } from 'react-redux';
import { negate } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SearchResults from '../../components/Search/SearchResults/index';
import Header from '../app/Header';
import i18n from '../../i18n';

import {
  RootState,
  DispatchResponse,
  ContentType,
  SearchConfig,
  PortalMenu,
  Channel,
  FavouriteResponse,
  HeaderDisplayType,
  HeaderInteractionType,
  VodAsset,
  SearchResultPriority,
  AltiboxAssetSearchResponse,
  AltiboxAssetType,
  SearchResultCast,
  SearchPriority,
  AltiboxAsset,
  AuthReducerState,
} from '../../interfaces';
import { emptySearch } from './actions';
import { Helmet } from 'react-helmet-async';
import { getChannelsIncludingIPTV } from '../tv/actions';
import {
  getAllFavourites,
  getProgramArchiveFavourites,
  getAllProgramArchiveFavouritesRelations,
} from '../../api/favorites';
import { fetchMyContent } from '../vod/actions';
import StickyHeader from '../app/StickyHeader';
import Footer from '../../components/Footer';
import {
  weighAndSort,
  weighAndRetrieveCast,
  reduceProgramArchiveResults,
  reducePVRResults,
  prioritizePvr,
  reduceTVGuideResults,
} from '../../utils/searchUtils';
import flatten from 'lodash/flatten';
import { getSvodKiosk } from '../../utils/svodUtil';
import isEmpty from 'lodash/isEmpty';
import { checkIfUserHasSvodProduct, isDisneyProgramAsset } from '../../utils/altiboxassetUtils';

export interface RouteProps {
  svodKiosk?: string;
}
interface Props extends RouteComponentProps<RouteProps> {
  searchQuery: string;
  searchResults: [AltiboxAssetSearchResponse[], AltiboxAssetSearchResponse[], AltiboxAssetSearchResponse[]];
  initialSearchQuery: string;
  searchConfig: SearchConfig;
  channels: Channel[];
  loggedInWithCredentials: boolean;
  authState: AuthReducerState;
  isGuest: boolean;
  mySeries: VodAsset[] | undefined;
  myContent: VodAsset[] | undefined;
  svodReducer: PortalMenu;
  svodKiosks: PortalMenu[];
  fetchMyContent: (count: number) => Promise<VodAsset[]>;
  getChannelsIncludingIPTV: () => Promise<void>;
  emptySearch: () => DispatchResponse;
}

interface State {
  vodFavourites?: FavouriteResponse;
  programFavourites?: string[];
  manualMyContentList: VodAsset[];
  searchResults: SearchResultPriority;
  castResults: SearchResultCast;
  loading: boolean;
  hasCanceled: boolean;
}

class Search extends Component<Props, State> {
  state: State = {
    vodFavourites: undefined,
    programFavourites: undefined,
    manualMyContentList: [],
    searchResults: this.emptySearchResults(),
    castResults: this.emptyCastResults(),
    loading: true,
    hasCanceled: false,
  };

  emptyCastResults() {
    return {
      actors: undefined,
      producers: undefined,
    };
  }

  emptySearchResults() {
    return {
      prioritized: undefined,
      rest: undefined,
      unfiltered: undefined,
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.channels)) {
      this.props.getChannelsIncludingIPTV();
    } else {
      if (!this.props.isGuest && this.props.loggedInWithCredentials) {
        this.setProgramArchiveFavourites(this.props);
      }
    }
    if (!this.props.mySeries && !this.props.isGuest && this.props.loggedInWithCredentials) {
      getAllFavourites(ContentType.VIDEO_VOD).then((result: FavouriteResponse) => {
        this.setState({
          vodFavourites: result,
        });
      });
      if (!this.props.myContent) {
        this.props.fetchMyContent(-1).then((myContent) => {
          this.setState({ manualMyContentList: myContent });
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.channels.length === 0 && nextProps.channels.length !== 0) {
      this.setProgramArchiveFavourites(nextProps);
    }
    if (nextProps.searchQuery !== this.props.searchQuery) {
      this.setState({ loading: true });
    }
    if (nextProps.searchQuery !== nextProps.initialSearchQuery) {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ hasCanceled: false });
      }, 600);
    }
    if (nextProps.searchResults) {
      this.prioritizeAndSortAssets(nextProps);
      this.extractCasts(nextProps);
    } else {
      this.setState({
        searchResults: this.emptySearchResults(),
        castResults: this.emptyCastResults(),
      });
    }
  }

  extractCasts(nextProps: Props) {
    const { searchQuery, searchResults } = nextProps;

    const resultToUse = [searchResults[1], searchResults[2]];
    if (searchQuery && resultToUse) {
      const [actors, producers] = weighAndRetrieveCast(resultToUse, searchQuery);
      this.setState({
        castResults: {
          actors: actors,
          producers: producers,
        },
      });
    }
  }

  componentWillUnmount() {
    this.emptyCastResults();
    this.emptySearchResults();
    this.props.emptySearch();
  }

  combineProgramArchiveAssets(assets: AltiboxAssetSearchResponse[]) {
    if (assets[SearchPriority.CATCHUP] && assets[SearchPriority.CATCHUP].contentlist.length > 0) {
      assets[SearchPriority.CATCHUP] = reduceProgramArchiveResults(assets[SearchPriority.CATCHUP]);
    }
    return assets;
  }

  removeDuplicateSharedAssets(assets: AltiboxAssetSearchResponse[]) {
    function deleteAndUpdate(searchResponse: AltiboxAsset[], index: number, counter: string) {
      delete searchResponse[index];
      counter = (Number(counter) - 1).toString();
    }
    // Check for duplicates in vod and svod
    const vods = assets[0].contentlist.filter((x) => x);
    const svods = assets[1].contentlist.filter((x) => x);

    let vodsCount = assets[0].counttotal;
    let svodsCount = assets[1].counttotal;

    /* Order of showing correct duplicate shared asset:

    1. Paid SVOD Services
    2. Recordings
    3. AD Free TV
    4. Commercial TV
    5. Unpaid VOD
    6. Unpaid SVOD

    */

    if (!isEmpty(vods) && !isEmpty(svods)) {
      vods.forEach((vod, index) => {
        const duplicate = svods.find((svod) => (svod ? svod.id : undefined) === (vod ? vod.id : null));
        if (duplicate) {
          const svodKiosk = getSvodKiosk(duplicate.asset as VodAsset, this.props.svodKiosks);
          if (svodKiosk) {
            const hasSvodKiosk = checkIfUserHasSvodProduct(this.props.authState, svodKiosk);
            if (hasSvodKiosk) {
              deleteAndUpdate(vods, index, vodsCount);
            } else {
              deleteAndUpdate(svods, index, svodsCount);
            }
          } else {
            deleteAndUpdate(vods, index, vodsCount);
          }
          duplicate.hideBrandLogo = true;
        }
      });
    }
    assets.forEach((x) => (x.contentlist = x.contentlist.filter((y) => y)));
    return assets;
  }

  combinePVRAssets(assets: AltiboxAssetSearchResponse[]) {
    if (assets[SearchPriority.RECORDINGS] && assets[SearchPriority.RECORDINGS].contentlist.length > 0) {
      assets[SearchPriority.RECORDINGS] = reducePVRResults(assets[SearchPriority.RECORDINGS]);
    }
    return assets;
  }

  combineTVGuideAssets(assets: AltiboxAssetSearchResponse[]) {
    if (assets[SearchPriority.TVGUIDE] && assets[SearchPriority.TVGUIDE].contentlist.length > 0) {
      assets[SearchPriority.TVGUIDE] = reduceTVGuideResults(assets[SearchPriority.TVGUIDE]);
    }
    return assets;
  }

  mapAssetsToSvodKiosk(assets: AltiboxAsset[], service: string): [AltiboxAsset[], AltiboxAsset[]] {
    const foundKiosk = this.props.svodKiosks.find((x) => x.providerName === service);

    if (foundKiosk) {
      let serviceFilteredKiosk = assets.filter(
        (x) => getSvodKiosk(x.asset! as VodAsset, this.props.svodKiosks) === foundKiosk,
      );
      let serviceUnfilteredKiosk = assets.filter(
        (x) => getSvodKiosk(x.asset! as VodAsset, this.props.svodKiosks) !== foundKiosk,
      );
      return [serviceFilteredKiosk, serviceUnfilteredKiosk];
    }

    let serviceFiltered = assets.filter((x) => (x.asset! as VodAsset).companyName === service);
    let serviceUnfiltered = assets.filter((x) => (x.asset! as VodAsset).companyName !== service);
    return [serviceFiltered, serviceUnfiltered];
  }

  async prioritizeAndSortAssets(nextProps: Props) {
    const { searchQuery, searchResults, searchConfig, initialSearchQuery, myContent } = nextProps;
    let resultsToUse = this.removeDuplicateSharedAssets(searchResults[0]);
    if (initialSearchQuery === searchQuery && !this.state.hasCanceled) {
      if (resultsToUse && searchConfig) {
        resultsToUse = this.combineProgramArchiveAssets(resultsToUse);
        resultsToUse = this.combinePVRAssets(resultsToUse);
        resultsToUse = this.combineTVGuideAssets(resultsToUse);
        if (searchConfig.priority || searchConfig.priority!.length === 0) {
          let assetsToPrioritize: SearchResultPriority = { prioritized: [], rest: [], unfiltered: [] };
          resultsToUse.forEach((results, idx) => {
            if (searchConfig.priority!.indexOf(idx) !== -1) {
              assetsToPrioritize.prioritized =
                assetsToPrioritize.prioritized && assetsToPrioritize.prioritized.length > 0
                  ? assetsToPrioritize.prioritized.concat(results.contentlist)
                  : results.contentlist;
            } else {
              assetsToPrioritize.rest =
                assetsToPrioritize.rest && assetsToPrioritize.rest.length > 0
                  ? assetsToPrioritize.rest!.concat(results.contentlist)
                  : results.contentlist;
            }
          });
          assetsToPrioritize.prioritized = await weighAndSort(assetsToPrioritize.prioritized, searchQuery, myContent);
          if (searchConfig.service && searchConfig.type && searchConfig.type === AltiboxAssetType.SVOD) {
            let assetsBasedOnService = this.mapAssetsToSvodKiosk(assetsToPrioritize.prioritized, searchConfig.service);
            let serviceFiltered = assetsBasedOnService[0];
            let serviceUnfiltered = assetsBasedOnService[1];
            serviceUnfiltered.reverse().forEach((x) => assetsToPrioritize.rest!.unshift(x));
            assetsToPrioritize.prioritized = serviceFiltered;
          }
          this.setState({
            loading: false,
            searchResults: {
              prioritized: assetsToPrioritize.prioritized,
              rest: prioritizePvr(await weighAndSort(assetsToPrioritize.rest, searchQuery, myContent)),
              unfiltered: prioritizePvr(
                await weighAndSort(flatten(resultsToUse.map((x) => x.contentlist)), searchQuery, myContent),
              ),
            },
          });
        } else {
          let allContent = flatten(resultsToUse.map((x) => x.contentlist));
          this.setState({
            loading: false,
            searchResults: {
              prioritized: this.state.searchResults.prioritized ? this.state.searchResults.prioritized : [],
              rest: prioritizePvr(await weighAndSort(allContent, searchQuery, myContent)),
              unfiltered: [],
            },
          });
        }
      }
    } else {
      this.emptyCastResults();
      this.emptySearchResults();
    }
  }

  setProgramArchiveFavourites(props: Props) {
    getProgramArchiveFavourites(props.channels).then((results) => {
      const allIds = results.playbilllist.map((x) => x.id);
      const seriesIdsToCheck = results.playbilllist.map((x) => x.seriesID).filter((x) => x);
      getAllProgramArchiveFavouritesRelations(seriesIdsToCheck).then((possibleFavouritedProgramIds) => {
        this.setState({
          programFavourites: possibleFavouritedProgramIds.concat(allIds),
        });
      });
    });
  }

  cancelPotentialSearch = () => {
    this.setState({ hasCanceled: true, loading: false });
  };

  filterExcludedResults(results: SearchResultPriority): SearchResultPriority {
    const isNotDisneyProgramAsset = negate(isDisneyProgramAsset);

    return {
      prioritized: results.prioritized?.filter(isNotDisneyProgramAsset),
      rest: results.rest?.filter(isNotDisneyProgramAsset),
      unfiltered: results.unfiltered?.filter(isNotDisneyProgramAsset),
    };
  }

  render() {
    return (
      <div className="search">
        <Helmet>
          <title>{i18n.t<string>('search')}</title>
        </Helmet>
        <StickyHeader
          focusSearch={true}
          searchConfig={this.props.searchConfig}
          isSearching={true}
          cancelPotentialSearch={this.cancelPotentialSearch}
        />
        <Header
          title={i18n.t<string>('search')}
          displayType={HeaderDisplayType.Solid}
          interactionType={HeaderInteractionType.CloseOnly}
        />
        {this.props.channels.length === 0 ? null : (
          <SearchResults
            searchQuery={this.props.searchQuery}
            results={this.filterExcludedResults(this.state.searchResults)}
            searchConfig={this.props.searchConfig}
            channels={this.props.channels}
            loggedInWithCredentials={this.props.loggedInWithCredentials}
            myContent={this.props.myContent ? this.props.myContent : this.state.manualMyContentList}
            {...this.state}
          />
        )}
        <Footer />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      authState: state.authReducer,
      isGuest: state.authReducer.isGuest,
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
      channels: state.channelsReducer.channels,
      searchQuery: state.searchReducer.searchQuery,
      searchResults: state.searchReducer.searchResults as [
        AltiboxAssetSearchResponse[],
        AltiboxAssetSearchResponse[],
        AltiboxAssetSearchResponse[],
      ],
      initialSearchQuery: state.searchReducer.initialSearchQuery,
      mySeries: state.vodReducer.mySeries,
      myContent: state.vodReducer.myContent,
      svodReducer: state.svodReducer.svodKiosk as PortalMenu,
      svodKiosks: state.app.svodKiosks,
    }),
    (dispatch) => ({
      fetchMyContent: (count: number) => dispatch(fetchMyContent(count)),
      emptySearch: () => dispatch(emptySearch()),
      getChannelsIncludingIPTV: () => dispatch(getChannelsIncludingIPTV()),
    }),
  )(Search),
);
