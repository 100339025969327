import React, { useContext } from 'react';
import { AltiboxAssetContextType } from '../../interfaces';

const AltiboxAssetContext: React.Context<AltiboxAssetContextType> = React.createContext({} as AltiboxAssetContextType);

export const useAltiboxAsset = () => {
  return useContext(AltiboxAssetContext) as AltiboxAssetContextType;
};

// export const AltiboxAssetProvider = AltiboxAssetContext.Provider;
// export const AltiboxAssetConsumer = AltiboxAssetContext.Consumer;
export default AltiboxAssetContext;
