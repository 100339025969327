import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import {
  RootState,
  ApiAuthResponse,
  StreamTypeObject,
  Program,
  AlertData,
  StreamType,
  Bookmark,
  Channel,
  StreamTypeNumber,
  HeaderDisplayType,
  HeaderInteractionType,
  SearchConfig,
  StreamingObject,
  GAaction,
  GAlabel,
  GAcategory,
  AuthReducerState,
  AltiboxAssetDetailsType,
  DetailsPanelType,
  NielsenChannelMapping,
  AlertType,
  AltiboxURLs,
} from '../../../interfaces';
import Header from '../../app/Header';
import Player from '../../../components/Player';
import { searchConfig, isFakeHome, imageScaleValues, routes } from '../../../config';
import MobileLock from '../../app/MobileLock';
import { ScriptService } from '../../../controllers/ScriptService';
import { showAlert, loadAllUsersBookmarks } from '../../app/actions';
import NoSupport from '../../../components/NoSupport';
import { Helmet } from 'react-helmet-async';

import { getProgramDetail } from '../../../api/programarchive';
import * as tvApi from '../../../api/tv';
import HuaweiErrors from '../../../controllers/HuaweiErrors';
import { getChannels } from '../../../views/tv/actions';
import StickyHeader from '../../app/StickyHeader';
import { getPercentWatchedCatchup } from '../../../utils/catchupUtils';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import isEmpty from 'lodash/isEmpty';
import {
  getImageUrlDimensions,
  getPicture,
  hasPicture,
  wrapAssetAsAltiboxAsset,
} from '../../../utils/altiboxassetUtils';
import PlayerAssetCard from '../../../components/Player/UI/PlayerAssetCard';
import { missingCoverUrlLandscape } from '../../../utils/svodUtil';
import { ShowDetailsPanel, showDetailsPanel as showDetailsPanelAction } from '../../details/actions';
import { SlideInDetails } from '../../details';
import { formatSeasonAndEpisode } from '../../../utils/tvUtils';
import { getFooterUrl } from '../../../utils/appUtils';
import { isBroadcastWithinFifteenMinutes } from '../../../utils/huaweiUtils';
import { StaticContext } from 'react-router';
import { getThirdPartyCatchupInformation } from '../../../utils/thirdPartyCatchupUtils';
import { getCurrentLangTitle } from '../../../utils/vodUtils';

interface Props {
  auth: ApiAuthResponse | undefined;
  isGuest: boolean;
  authStatus: AuthReducerState;
  isInside: boolean;
  bookmarks: Bookmark[];
  searchConfig: SearchConfig;
  shouldHideControls: boolean;
  detailsPanel: DetailsPanelType | undefined;
  getChannels: () => Promise<void>;
  loadBookmarks: () => Promise<Bookmark[]>;
  showDetailsPanel: (program: Program) => void;
  showAlert: (alert: AlertData) => {
    type: symbol;
    alertVisible: boolean;
    alert: { title: string; text: string[] };
  };
  channels: Channel[];
  nielsenChannelMapping?: NielsenChannelMapping[];
}

interface State {
  stream: StreamTypeObject | undefined;
  title: string;
  program?: Program;
}

interface RouteParams {
  programId: string;
}

class ProgramPlayer extends Component<
  Props &
    RouteComponentProps<RouteParams, {}, { prevPath?: string; showDetailsPanel?: Omit<ShowDetailsPanel, 'type'> }>,
  State
> {
  state: State = {
    stream: undefined,
    title: '',
    program: undefined,
  };

  tracking = AnalyticsTracking.getInstance();

  get imageUrl() {
    const { program } = this.state;
    if (program) {
      let programCover = hasPicture(program.picture) ? getPicture(program.picture.deflate) : '';

      return programCover
        ? getImageUrlDimensions(programCover, imageScaleValues.altiboxAssetView)
        : missingCoverUrlLandscape;
    }
    return missingCoverUrlLandscape;
  }

  get seasonAndEpisodePrefix() {
    const { program } = this.state;

    if (program) {
      return formatSeasonAndEpisode(program.seasonNum, program.subNum);
    }
    return '';
  }

  get fullscreenDetails() {
    const { detailsPanel, shouldHideControls } = this.props;
    const { program, title } = this.state;
    return (
      <>
        {detailsPanel ? (
          <SlideInDetails
            key={
              detailsPanel.routeProps.parentId ?? '' + detailsPanel.routeProps.childId ?? '' + detailsPanel.displayType
            }
            {...detailsPanel}
          />
        ) : null}
        {program && !shouldHideControls ? (
          <PlayerAssetCard
            currentTitle={title}
            image={this.imageUrl}
            currentSeason={program.seasonNum}
            currentEpisode={program.subNum}
            showDetailsPanel={() => this.handleOpenDetails()}
          />
        ) : null}
      </>
    );
  }

  getCatchupBookmark(contentId: string) {
    return this.props.bookmarks.find(
      (bookmark) => bookmark.bookmarkType === StreamTypeNumber.CATCHUP && bookmark.contentId === contentId,
    );
  }

  handleDeeplink = (program: Program) => {
    const { url, fallbackUrl, data } = getThirdPartyCatchupInformation(this.props.auth, program);
    const providerName = getCurrentLangTitle(data?.titles);
    const linkText = providerName ? `${providerName} - ${program.name}` : program.name;

    const getLink = () => {
      if (!url) {
        return '';
      }

      return `<br><a target="_blank" href="${fallbackUrl}">${linkText}</a>`;
    };

    const link = getLink();

    this.props.showAlert({
      title: 'Kan ikke spille av',
      text: [`Du kan se dette programmet i ${providerName}s programarkiv. ${link}`],
      buttonText: i18n.t<string>('watch external catchup', { title: providerName }),
      type: AlertType.DEEPLINK,
      link: url,
    });
  };

  componentDidMount() {
    if (this.props.isGuest) {
      this.setState({
        title: i18n.t<string>('log in to see this content'),
      });
      this.props.showAlert({
        title: i18n.t<string>('log on'),
        text: [i18n.t<string>('log in to see this content')],
        type: AlertType.INFO,
      });
    } else {
      this.props
        .loadBookmarks()
        .then(() => {
          if (!isEmpty(this.props.channels)) {
            return Promise.resolve();
          } else {
            return this.props.getChannels();
          }
        })
        .then(() => {
          getProgramDetail(this.props.match.params.programId).then((program) => {
            if (program) {
              program = program as Program;
              this.setState({
                title: program.name,
                program: program,
              });
              // has catchup but we do not allow playback
              if (!program.recordedMediaIds || (program.recordedMediaIds && program.recordedMediaIds.length === 0)) {
                this.props.showAlert({
                  title: i18n.t<string>('can not play'),
                  text: [i18n.t<string>('programarchive_empty')],
                  type: AlertType.INFO,
                });
                return;
              }

              const isExternalChannel = getThirdPartyCatchupInformation(this.props.auth, program).isExternal;
              const isWithinFifteenMinutes = isBroadcastWithinFifteenMinutes(program);

              if (isExternalChannel && !isWithinFifteenMinutes) {
                return this.handleDeeplink(program);
              }

              const channel = this.props.channels.find(
                (channelx) => channelx.contentId === (program as Program).channelid,
              );
              if (channel) {
                const onlyInsideForCatchup =
                  channel.channelPermissions.onlyInsideForCatchup &&
                  channel.channelPermissions.onlyInsideForCatchup !== this.props.isInside;

                if (channel.channelPermissions.notInSubscription) {
                  this.props.showAlert({
                    title: i18n.t<string>('missing access title'),
                    text: [i18n.t<string>('not in subscription')],
                    type: AlertType.GET_ACCESS,
                    link: getFooterUrl(AltiboxURLs.CUSTOMER_SERVICE),
                  });
                  return;
                } else if (onlyInsideForCatchup && !isFakeHome) {
                  this.props.showAlert({
                    title: i18n.t<string>('can not play'),
                    text: [i18n.t<string>("can't show outside home")],
                    type: AlertType.INFO,
                  });
                  return;
                }
              }

              let startBookmark = 0;
              let bookmark = this.getCatchupBookmark(program.id);
              if (bookmark) {
                if (getPercentWatchedCatchup(program, bookmark) < 97) {
                  startBookmark = Number(bookmark.rangeTime);
                }
              }
              const mediaId = channel ? channel.mediaId : undefined;
              tvApi
                .getProgramCatchupPlayData(program, mediaId)
                .then((playData: StreamingObject) => {
                  let licenseUrl = '';
                  if (ScriptService._isSafari()) {
                    licenseUrl = playData.licenseUrl ? playData.licenseUrl : '';
                  }
                  program = program as Program;
                  let stream = {
                    isGuest: this.props.isGuest,
                    title: program.name,
                    picture: program.picture,
                    streamType: StreamType.CATCHUP,
                    channelId: program.channelid,
                    programStart: program.starttime,
                    programEnd: program.endtime,
                    mediaId: program.recordedMediaIds ? program.recordedMediaIds[0] : undefined,
                    contentId: program.id,
                    startBookmark: startBookmark,
                    playData: {
                      manifestUrl: playData.manifestUrl,
                      licenseUrl: licenseUrl,
                      customData: playData.customData,
                    },
                  };

                  if (ScriptService.isCurrentBrowserSupported() && !ScriptService.onMobile()) {
                    this.setState({
                      stream: stream,
                    });
                    // React doesn't notice the changes in the nested objects in stream, so we have to tell it to rerender.
                    this.forceUpdate();
                  }
                })
                .catch((error) => {
                  if (error.retcode === HuaweiErrors.MAX_PLAYBACK_SESSIONS) {
                    this.notifyToManyStreams();
                  } else if (error.retcode === HuaweiErrors.NO_ACCESS) {
                    this.props.showAlert({
                      title: i18n.t<string>('how to get access'),
                      text: [i18n.t<string>('missing access message', { service: i18n.t<string>('programarchive') })],
                      type: AlertType.GET_ACCESS,
                      link: getFooterUrl(AltiboxURLs.CUSTOMER_SERVICE),
                    });
                  }
                });
            } else {
              this.setState({
                title: i18n.t<string>('oops catchup not found'),
              });
            }
          });
        });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props & RouteComponentProps<RouteParams, StaticContext, unknown>>) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.componentDidMount();
    }
  }

  alert = (title: string, message: string[]) => {
    this.props.showAlert({
      title: title,
      text: message,
      type: AlertType.INFO,
    });
  };

  notifyToManyStreams() {
    this.alert(i18n.t<string>('concurrency title'), [
      i18n.t<string>('concurrency message1'),
      i18n.t<string>('concurrency message2'),
    ]);
  }

  // called by player on error
  onPlayerError = (error: object) => {
    this.tracking.trackEvent(GAcategory.playerError, 'catchup' as GAaction, JSON.stringify(error));
  };

  onProgramEnded = (type: String) => {
    this.props.history.goBack();
  };

  onConcurrencyError = () => {
    this.tracking.trackCurrentService(
      GAaction.notification,
      (this.props.auth!.isInside === '1' ? 'inside ' : 'outside ') + GAlabel.maximumNoOfStreamReached,
    );
  };

  onPause = () => {
    // TODO: Track play/pause Program
    // this.tracking.trackCurrentService(GAaction.playback_pause, this.props.currentVod.name + ' - ' + this.props.currentVod.id);
  };

  onPlay = () => {
    // TODO: Track play/pause Program
    // this.tracking.trackCurrentService(GAaction.playback_play, this.props.currentVod.name + ' - ' + this.props.currentVod.id);
  };

  onClose = () => {
    const { base, details, series, single } = routes.programarchive;
    const { seriesID, id } = this.state.program!;

    if (this.props.location && this.props.location.state && this.props.location.state.prevPath) {
      return this.props.history.push(this.props.location.state.prevPath, {
        showDetailsPanel: {
          routeProps: { childId: id, parentId: seriesID },
          displayType: AltiboxAssetDetailsType.CATCHUP,
        },
      });
    }

    if (seriesID) {
      return this.props.history.push(`${base}${details}${series}/${seriesID}/${id}`);
    }

    return this.props.history.push(`${base}${details}${single}/${id}`);
  };

  handleOpenDetails = () => {
    if (this.state.program) {
      this.props.showDetailsPanel(this.state.program);
    }
  };

  render() {
    let playerSearchConfig = this.props.searchConfig ? this.props.searchConfig : searchConfig.global;
    playerSearchConfig.noFocus = true;
    playerSearchConfig.returnPath = this.props.location.pathname;
    var canPlay = true;
    if (ScriptService.onMobile()) {
      canPlay = false;
    } else {
      // on PC/MAC
      if (ScriptService.isCurrentBrowserSupported()) {
        canPlay = true;
      } else {
        canPlay = false;
      }
    }

    if (canPlay) {
      return (
        <div className="main-frame">
          <div className="tv-container vod-player">
            <Helmet>
              <title>{this.state.title}</title>
            </Helmet>
            {this.props.shouldHideControls ? null : (
              <>
                <StickyHeader searchConfig={playerSearchConfig} overPlayer={true} />
                <Header
                  title={this.state.title}
                  displayType={HeaderDisplayType.Player}
                  interactionType={HeaderInteractionType.CloseOnly}
                  closeCallback={this.onClose}
                />
              </>
            )}
            {this.state.stream && this.state.program ? (
              <Player
                stream={this.state.stream}
                onPlay={this.onPlay}
                onAlert={this.alert}
                onPause={this.onPause}
                hideControls={this.props.shouldHideControls}
                onPlayerError={this.onPlayerError}
                onConcurrencyError={this.onConcurrencyError}
                onProgramEnded={this.onProgramEnded}
                authStatus={this.props.authStatus}
                currentAsset={wrapAssetAsAltiboxAsset(this.state.program)}
                includeInFullscreen={() => this.fullscreenDetails}
                nielsenChannelMapping={this.props.nielsenChannelMapping}
              />
            ) : null}
          </div>
        </div>
      );
    } else {
      var deepLink = 'altibox://catchup';

      return (
        <div className="main-frame">
          <Header displayType={HeaderDisplayType.NoStickyGradient} interactionType={HeaderInteractionType.CloseOnly} />
          {ScriptService.onMobile() ? <MobileLock deepLink={deepLink} /> : <NoSupport />}
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      isGuest: state.authReducer.isGuest,
      isInside: state.authReducer.isHome,
      auth: state.authReducer.auth,
      authStatus: state.authReducer,
      channels: state.channelsReducer.channels,
      bookmarks: state.app.bookmarks,
      shouldHideControls: state.app.shouldFadeOut,
      detailsPanel: state.detailsReducer.detailsPanel,
      nielsenChannelMapping: state.app.nielsenChannelMapping,
    }),
    (dispatch) => ({
      showDetailsPanel: (program: Program) => {
        dispatch(
          showDetailsPanelAction({
            routeProps: { childId: program.id, parentId: program.seriesID },
            displayType: AltiboxAssetDetailsType.CATCHUP,
          }),
        );
      },
      getChannels: () => dispatch(getChannels()),
      loadBookmarks: () => dispatch(loadAllUsersBookmarks()),
      showAlert: (alert: AlertData) => dispatch(showAlert(alert)),
    }),
  )(ProgramPlayer),
);
