import React from 'react';
import './index.scss';

interface Props {
  icon: string | React.ReactNode;
  className?: string;
  hover?: string;
  tooltip?: string;
  onClickCallback?: () => void;
  hoverEnterCallback?: () => void;
  hoverLeaveCallback?: () => void;
  disabled?: boolean;
}

export default class PlayerControlButton extends React.Component<Props, {}> {
  render() {
    if (typeof this.props.icon === 'string') {
      return (
        <button
          title={this.props.tooltip ? this.props.tooltip : ''}
          tabIndex={1}
          className={'player-control-button ' + this.props.className}
          onClick={this.props.onClickCallback}
          onMouseEnter={this.props.hoverEnterCallback}
          onMouseLeave={this.props.hoverLeaveCallback}
          disabled={this.props.disabled}
        >
          <div className={'player-control-icon ' + this.props.hover}>
            <span className="aib-icon">{this.props.icon}</span>
          </div>
        </button>
      );
    }

    return (
      <div
        title={this.props.tooltip ? this.props.tooltip : ''}
        tabIndex={1}
        className={'player-control-button ' + this.props.className}
        onMouseEnter={this.props.hoverEnterCallback}
        onMouseLeave={this.props.hoverLeaveCallback}
      >
        <div className={'player-control-icon ' + this.props.hover}>
          <span className="aib-icon">{this.props.icon}</span>
        </div>
      </div>
    );
  }
}
