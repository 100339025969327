import React, { FC } from 'react';
import Modal, { Props as ModalProps } from 'react-modal';
import './style.scss';

const AtvModal: FC<ModalProps> = (props) => {
  return (
    <Modal
      {...props}
      closeTimeoutMS={300}
      parentSelector={() => document.querySelector('.player-fullscreen-wrapper') || document.querySelector('body')!}
    >
      {props.children}
    </Modal>
  );
};
export default AtvModal;
