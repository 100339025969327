import { status, json, fetchError } from '../controllers/Fetch';
import { QuerySubscriberResp, QueryOrderResponse } from '../interfaces';
import { getBaseUrl, getSessionTicket } from '../config';

export function userInfo(): Promise<string> {
  return fetch(getBaseUrl() + '/EPG/JSON/QuerySubscriber', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({}),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: QuerySubscriberResp) => {
      return response.subscriber.customer.customerName;
    })
    .catch(fetchError);
}

export function queryAllOrders(): Promise<string | QueryOrderResponse> {
  return fetch(getBaseUrl() + '/EPG/JSON/QueryOrder', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      profileId: -1, // inside login - must have -1 to get license urls
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (data: QueryOrderResponse) {
      return Promise.resolve(data);
    })
    .catch(fetchError);
}
