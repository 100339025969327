import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import { showLoginModal } from '../actions';
import { RootState, PortalMenu, HeaderDisplayType, HeaderInteractionType } from '../../../interfaces';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface State {
  showOptimizedMessage: boolean;
}
interface Props {
  title?: string | '';
  hideTitle?: boolean;
  hasPvr: boolean;
  goHomeOnBack?: boolean;
  svodKiosks: PortalMenu[];
  searchQuery?: string;
  displayType: HeaderDisplayType;
  interactionType: HeaderInteractionType;
  defaultImagePath: string;
  showLoginModal: () => void;
  closeCallback?: () => void;
  overwriteUrl?: string;
}

class Header extends Component<Props & RouteComponentProps<{}>, State> {
  get backIcon() {
    switch (this.props.interactionType) {
      case HeaderInteractionType.CloseOnly:
        return 'B';
      case HeaderInteractionType.Back:
        return 'B';
      default:
        return undefined;
    }
  }

  renderCenterContent() {
    const { hideTitle, title } = this.props;
    if (hideTitle) {
      return null;
    }
    return (
      <h1 title={title} className="header-center">
        {title}
      </h1>
    );
  }

  renderRightContent() {
    return <div className="header-right" />;
  }

  handleBackClick = () => {
    if (this.props.closeCallback) {
      return this.props.closeCallback();
    }

    if (this.props.goHomeOnBack) {
      return this.props.history.push('/');
    }

    if (this.props.overwriteUrl) {
      return this.props.history.push(this.props.overwriteUrl);
    }

    return this.props.history.goBack();
  };

  renderLeftContent() {
    switch (this.props.interactionType) {
      case HeaderInteractionType.Back:
      case HeaderInteractionType.CloseOnly:
        return (
          <div className="header-left">
            <div className="aib-icon backButton" onClick={this.handleBackClick}>
              {this.backIcon}
            </div>
          </div>
        );
      default:
        return <div className="header-left" />;
    }
  }

  render() {
    const headerClassName = 'header ' + this.props.displayType;
    return (
      <div className={headerClassName}>
        {this.renderLeftContent()}
        {this.renderCenterContent()}
        {this.renderRightContent()}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => {
      return {
        svodKiosks: state.app.svodKiosks,
        hasPvr: state.authReducer.hasPvr as boolean,
        defaultImagePath: state.app.defaultImagePath,
      };
    },
    (dispatch) => ({
      showLoginModal: () => dispatch(showLoginModal()),
    }),
  )(Header),
);
