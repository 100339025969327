import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: true,
      /* State will be considered fresh for at least 60 seconds */
      staleTime: 1000 * 60,
      /* Will only track (and cause a rerender) for variables that are used */
      notifyOnChangeProps: 'tracked',
    },
  },
});

export { QueryClientProvider, queryClient };
