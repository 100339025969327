import { status, json, fetchError, getDataFromResponse } from '../controllers/Fetch';
import {
  PortalMenu,
  FrontPageConfig,
  BatchBookmarksResponse,
  StreamTypeNumber,
  CategoryListResponse,
  ExtensionInfoResponse,
} from '../interfaces';
import { getBaseUrl, getSessionTicket } from '../config';
import { setLoginMethod } from '../utils/authUtils';

export function loadPortalMenuFromBackend(bossId: string, areaId: string, useDefaultConfig: boolean = false) {
  return fetch(
    useDefaultConfig ? '/portal-menu-stb.json' : `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/portal-menu-stb.json`,
  )
    .then(status)
    .then((r) => json(r as Response))
    .then((response: PortalMenu[]) => {
      return response;
    })
    .catch(fetchError);
}

export function loadSvodConfigFromBackend(bossId: string, areaId: string, useDefaultConfig: boolean = false) {
  return fetch(
    useDefaultConfig
      ? '/portal-menu-stb.json'
      : `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/svod-kiosk-mapping.json`,
  )
    .then(status)
    .then((r) => json(r as Response))
    .then((response: PortalMenu[]) => {
      return response.filter((x) => x);
    })
    .catch(fetchError);
}

export function loadFrontPageConfigFromBackend(bossId: string, areaId: string) {
  const frontPageConfigUrl = `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/front-page.json`;
  return fetch(frontPageConfigUrl)
    .then(status)
    .then((r) => json(r as Response))
    .then((response: FrontPageConfig) => {
      return response;
    })
    .catch(() => {
      return fetch('/front-page.json')
        .then(status)
        .then((r) => json(r as Response))
        .then((response: FrontPageConfig) => {
          return response;
        });
    });
}

export function loadAllBookmarks() {
  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: [
        {
          name: 'QueryBookmark',
          param: {
            bookmarkType: StreamTypeNumber.VOD,
          },
        },
        {
          name: 'QueryBookmark',
          param: {
            bookmarkType: StreamTypeNumber.PVR,
          },
        },
        {
          name: 'QueryBookmark',
          param: {
            bookmarkType: StreamTypeNumber.CATCHUP,
          },
        },
      ],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((bookmarksResponse: BatchBookmarksResponse) => {
      return bookmarksResponse;
    });
}

export function getContentCategory(id: string): Promise<CategoryListResponse> {
  return fetch(getBaseUrl() + '/EPG/JSON/CategoryList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      categoryid: id,
      type: 'VOD',
      count: '-1',
      offset: '0',
      properties: [
        {
          name: 'category',
          include: 'id,name',
        },
      ],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export async function getContinueWatchingPadding(): Promise<[prePadding: number, postPadding: number]> {
  const res = await fetch(getBaseUrl() + '/VSP/V3/QueryCustomizeConfig', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      key: 'CatchupPostPadding,CatchupPrePadding',
      queryType: '2',
    }),
  });

  const data = await getDataFromResponse(res);
  const [prePadding, postPadding] = data.configurations[0].values;

  return [prePadding.values[0], postPadding.values[0]];
}

export async function configureLoginMethod(): Promise<void> {
  const req = await fetch(getBaseUrl() + '/EPG/JSON/GetCustomizeConfig', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      queryType: 0,
    }),
  });

  try {
    const customizeConfig = (await req.json()) as ExtensionInfoResponse;
    setLoginMethod(customizeConfig.extensionInfo.find((x) => x.key === 'login_method'));
  } catch (e) {
    // fallback to ubi
    localStorage.setItem('login_method', 'ubi');
  }
}
