import React from 'react';
import {
  AltiboxAsset,
  Channel,
  FavouriteResponse,
  SearchResultPriority,
  VodAsset,
  AltiboxAssetType,
  AltiboxAssetDisplayTypes,
  SearchConfig,
  SearchResultCast,
} from '../../../interfaces';
import { addBadgeOnOwnedSeriesAssets } from '../../../utils/vodUtils';
import './style.scss';
import i18n from '../../../i18n';
import AltiboxCast from '../../UI/AltiboxCast';
import Spinner from '../../Spinner';
import AltiboxAssetViewList from '../../UI/AltiboxAssetViewList';
import { isStringEncoded, decodeURIComponentSafe } from '../../../utils/searchUtils';

interface Props {
  searchQuery: string;
  results: SearchResultPriority | undefined;
  castResults: SearchResultCast | undefined;
  searchConfig: SearchConfig;
  channels: Channel[];
  loggedInWithCredentials: boolean;
  vodFavourites?: FavouriteResponse;
  programFavourites?: string[];
  myContent?: VodAsset[];
  loading: boolean;
}
interface State {
  expand: [number, number];
}

class SearchResults extends React.Component<Props, State> {
  state: State = {
    expand: [10, 10],
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.searchQuery !== this.props.searchQuery) {
      this.resetExpansionBoxes();
    }
    if (!nextProps.results) {
      this.resetExpansionBoxes();
    }
  }

  componentWillUnmount() {
    this.resetExpansionBoxes();
  }

  resetExpansionBoxes() {
    this.setState({
      expand: [10, 10],
    });
  }

  expandResults(priority: number, other: number) {
    this.setState({
      expand: [this.state.expand[0] + priority, this.state.expand[1] + other],
    });
  }

  appendSearchResultFavouritesAndBoughtBadges(assets: AltiboxAsset[]) {
    if (!this.props.loggedInWithCredentials) {
      return assets;
    }
    let vodAssets = assets.filter((x) => x.type === AltiboxAssetType.VOD).map((x) => x.asset!) as VodAsset[];
    let boughtAndRentedAssets: VodAsset[] = [];
    boughtAndRentedAssets =
      this.props.myContent && vodAssets
        ? addBadgeOnOwnedSeriesAssets(vodAssets as VodAsset[], this.props.myContent).filter(
            (asset) => asset.showBoughtBadge || asset.showRentedBadge,
          )
        : [];
    assets.forEach((asset) => {
      if (asset.asset && boughtAndRentedAssets && boughtAndRentedAssets.length > 0) {
        let foundBoughtOrPurchased = boughtAndRentedAssets.find((x) => x.id === asset.asset!.id);
        if (foundBoughtOrPurchased && asset.type !== AltiboxAssetType.SVOD) {
          asset.asset = foundBoughtOrPurchased;
        }
      }
      const foundVod =
        this.props.vodFavourites && this.props.vodFavourites.favoritelist
          ? this.props.vodFavourites.favoritelist.find((x) => x.id === asset.id)
          : undefined;
      const foundProgram = this.props.programFavourites
        ? this.props.programFavourites.indexOf(asset.asset!.id as string) !== -1
        : undefined;
      asset.asset!.isfavorited = foundVod || foundProgram ? '1' : '0';
    });
    return assets;
  }

  renderAssets(assets: AltiboxAsset[], displayIndex: number) {
    let searchQuery = isStringEncoded(this.props.searchQuery)
      ? decodeURIComponentSafe(this.props.searchQuery)
      : this.props.searchQuery;

    if (assets.length > 0) {
      assets = this.appendSearchResultFavouritesAndBoughtBadges(assets);
      let assetsToDisplay = assets;
      assetsToDisplay = assets.slice(0, this.state.expand[displayIndex]);
      return (
        <AltiboxAssetViewList
          key={'searchresults-' + displayIndex}
          assets={assetsToDisplay}
          displayType={AltiboxAssetDisplayTypes.GridView}
          hideTitleHeader={true}
          loggedInWithCredentials={this.props.loggedInWithCredentials}
          myContent={this.props.myContent}
        />
      );
    } else {
      const { service, type } = this.props.searchConfig!;
      let superDangerousHTML = i18n.t<string>('no results', {
        query: `<strong>“${searchQuery}”</strong>`,
        service:
          displayIndex === 0 ? this.getTitle(service, type) : i18n.t<string>('other relevant results').toLowerCase(),
      });
      return <div className={'no-results'} dangerouslySetInnerHTML={{ __html: superDangerousHTML }} />;
    }
  }

  getTitle(service: string | undefined, type: AltiboxAssetType | undefined) {
    switch (type) {
      case AltiboxAssetType.SVOD:
        if (service) {
          return service;
        } else {
          return i18n.t<string>('streaming services');
        }
      case AltiboxAssetType.VOD:
        return i18n.t<string>('movies and series');
      case AltiboxAssetType.CATCHUP:
        return i18n.t<string>('programarchive');
      case AltiboxAssetType.PROGRAM:
        return i18n.t<string>('tv guide');
      case AltiboxAssetType.PVR: {
        return i18n.t<string>('recordings');
      }
      default:
        return i18n.t<string>('movies and series');
    }
  }

  renderCast(results: SearchResultCast | undefined) {
    if (results) {
      // const back = window.location.pathname + window.location.search;
      const actors = results.actors ? results.actors.map((x) => <AltiboxCast cast={x} key={x.castId} />) : null;
      const producers = results.producers
        ? results.producers.map((x) => <AltiboxCast cast={x} key={x.castId} />)
        : null;
      return (
        <>
          {actors && actors.length > 0 ? (
            <>
              <h3>
                {i18n.t<string>('actors')} ({actors.length})
              </h3>
              <div className={'cast-flex'} key={'actors'}>
                {actors}
              </div>
            </>
          ) : null}

          {producers && producers.length > 0 ? (
            <>
              <h3>
                {i18n.t<string>('directors and producers')} ({producers.length})
              </h3>
              <div className={'cast-flex'} key={'producers'}>
                {producers}
              </div>
            </>
          ) : null}
        </>
      );
    } else {
      return null;
    }
  }

  renderResults(results: SearchResultPriority | undefined) {
    if (this.props.searchConfig && results) {
      const { priority, service, type } = this.props.searchConfig;
      let searchView: JSX.Element[] = [];
      if (priority && priority.length > 0 && results.prioritized) {
        searchView.push(
          <React.Fragment key={'prioritized'}>
            <h3>
              {i18n.t<string>('results in')} {this.getTitle(service, type)} - {i18n.t<string>('titles')} (
              {results.prioritized.length})
            </h3>
            {this.renderAssets(results.prioritized, 0)}
            {results.prioritized.length > 10 && this.state.expand[0] < results.prioritized.length ? (
              <div className={'expand-results'}>
                <button onClick={() => this.expandResults(10, 0)}>{i18n.t<string>('show more search')}</button>
              </div>
            ) : null}
          </React.Fragment>,
        );
      }
      if (results.rest) {
        let title = `${i18n.t<string>('titles')} (${results.rest.length})`;
        if (type) {
          title = `${i18n.t<string>('other relevant results')} - ${title}`;
        }
        searchView.push(
          <React.Fragment key={'rest'}>
            <h3>{title}</h3>
            {this.renderAssets(results.rest, 1)}
            {results.rest.length > 10 && this.state.expand[1] < results.rest.length ? (
              <div className={'expand-results'}>
                <button onClick={() => this.expandResults(0, 10)}>{i18n.t<string>('show more search')}</button>
              </div>
            ) : null}
          </React.Fragment>,
        );
      }
      if (searchView.length > 0) {
        return searchView;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  resultsHaveContent(results: SearchResultPriority | undefined, castResults: SearchResultCast | undefined) {
    if (!results && !castResults) {
      return false;
    } else if (
      results &&
      results.prioritized &&
      results.prioritized.length === 0 &&
      results &&
      results.rest &&
      results.rest.length === 0 &&
      results &&
      results.unfiltered &&
      results.unfiltered.length === 0 &&
      castResults &&
      castResults.actors &&
      castResults.actors.length === 0 &&
      castResults &&
      castResults.producers &&
      castResults.producers.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { results, castResults } = this.props;

    let searchQuery = isStringEncoded(this.props.searchQuery)
      ? decodeURIComponentSafe(this.props.searchQuery)
      : this.props.searchQuery;
    if (!this.props.loading) {
      if (this.resultsHaveContent(results, castResults)) {
        return (
          <div className="searchResults" key={'results'}>
            {this.renderResults(results)}
            {this.renderCast(castResults)}
          </div>
        );
      } else {
        let superDangerousHTML = i18n.t<string>('no results general', {
          query: `<strong>“${searchQuery}”</strong>`,
        });
        return (
          <div className="searchResults" key={'no-results'}>
            <div className={'centered'}>
              <span className={'searchIcon'}>E</span>
              <div className={'no-results'} dangerouslySetInnerHTML={{ __html: superDangerousHTML }} />
            </div>
          </div>
        );
      }
    } else {
      if (this.props.searchQuery === '') {
        return (
          <div className="searchResults" key={'empty-search-term'}>
            <div className={'centered'}>
              <span className={'searchIcon'}>E</span>
              <div className={'no-results'}>{i18n.t<string>('write something in the search field')}</div>
            </div>
          </div>
        );
      } else {
        return <Spinner wrapInContainer={true} />;
      }
    }
  }
}

export default SearchResults;
