import { Link } from 'react-router-dom';
import { routes } from '../../config';
import i18n from '../../i18n';
import style from './style.module.scss';

const TitleHeader = () => {
  return (
    <div className="watch-tv-wrapper">
      <Link to={routes.svod.base}>
        <h3 title={i18n.t('streaming services')} className={style['title-header']}>
          {i18n.t<string>('streaming services')}
        </h3>
        <span className="aib-icon">{`>`}</span>
      </Link>
    </div>
  );
};

export default TitleHeader;
