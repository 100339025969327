import React, { Component, ChangeEvent } from 'react';
import { routes } from '../../../config';

interface Props {
  location: string;
}

interface State {
  pathToGoTo: string;
}

class FakeLogin extends Component<Props, State> {
  state: State = {
    pathToGoTo: 'atvweb-st3.services.snartibox.net',
  };

  handleChange = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    this.setState({
      pathToGoTo: event.target.value,
    });
  };

  handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    let path = this.state.pathToGoTo;
    if (path.indexOf('http://') !== -1) {
      path = path.substring(7, path.length);
    }
    if (path.indexOf('https://') !== -1) {
      path = path.substring(8, path.length);
    }
    if (path.charAt(path.length - 1).indexOf('/') !== -1) {
      path = path.slice(0, -1);
    }
    window.location.href = 'http://' + path + routes.authentication.callback + this.props.location;
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} style={{ paddingLeft: 32, paddingTop: 32 }}>
        <h2>Log into remote location</h2>
        <br />
        <h3>Choose from dropdown:</h3>
        <select
          name="location-dropdown"
          id="location-dropdown"
          onChange={(e) => this.handleChange(e)}
          style={{ width: 362, padding: 4 }}
        >
          <option value="atvweb-st3.services.snartibox.net">atvweb-st3</option>
          <option value="atvweb-int3.services.snartibox.net">atvweb-int3</option>
          <option value="localhost:3000">localhost</option>
        </select>
        <br />
        <br />
        <h3>Or enter manually:</h3>
        <input
          tabIndex={0}
          autoFocus={true}
          type="text"
          value={this.state.pathToGoTo}
          onChange={this.handleChange}
          style={{ width: 350, padding: 4 }}
        />
        <br />
        <br />
        <input type="submit" value="LOGIN" style={{ padding: 8 }} />
        <br />
        <br />
        You will be redirected to: <strong>{this.state.pathToGoTo}</strong>
      </form>
    );
  }
}
export default FakeLogin;
