import React, { FC, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useAltiboxAsset } from '../../../../../../views/details/AltiboxAssetContext';
import {
  AltiboxAsset,
  AltiboxAssetDetailsType,
  Program,
  VodAsset,
  AltiboxAssetButtonType,
  PvrRecording,
  BroadcastStatus,
  ThirdPartyCatchupData,
} from '../../../../../../interfaces';
import './style.scss';
import {
  getAltiboxAssetThumbnail,
  getAltiboxAssetEpisodeNumber,
  getAltiboxAssetAdditionalThumbnail,
  getAltiboxAssetTitle,
  getAltiboxAssetChannel,
  getAltiboxAssetPromotedId,
  isEpisodeActive,
  getPromotedAssetId,
  getAltiboxAssetDescription,
  getAltiboxAssetPlayLink,
  checkIfUserHasSvodProduct,
  getAltiboxAssetEpisodeTitle,
  getEpisodeProgressValue,
  getShouldShowEpisodeProgress,
  canAssetBePurchased,
  isSeriesRecording,
  isSingleRecording,
  getAdditionalInformation,
  getAltiboxAssetId,
} from '../../../../../../utils/altiboxassetUtils';
import i18n from '../../../../../../i18n';
import channelPermissions from '../../../../../../utils/channelPermissions';
import { assetIsBought, getCurrentLangTitle, getRentBadgeTextWithInformation } from '../../../../../../utils/vodUtils';
import Badge from '../../../../../Badge';
import AltiboxAssetButton from '../../../AltiboxAssetButtonRow/AltiboxAssetButton';
import AltiboxImage from '../../../../AltiboxImage';
import SharedAssets from '../../../SharedAssets';

import missingTvPlaceholder from '../../../AltiboxAssetHero/tv-placeholder.png';
import missingVODPlaceholder from '../../../AltiboxAssetHero/vod-placeholder.jpeg';
import { SvgCheck, SvgPlay, SvgTimer } from '../../../../../../icons';
import { ButtonExpandable } from '../../../../Buttons';
import {
  broadcastEnded,
  broadcastingFuture,
  broadcastingNow,
  broadcastStatus,
} from '../../../../../../utils/huaweiUtils';
import ExpandableText from '../../../../ExpandableText';
import { assetIsProgram, assetIsPvr } from '../../../../../../typeGuards';
import { catchupAvailable } from '../../../../../../utils/tvUtils';
import { Link } from 'react-router-dom';
import { DetailsRouteProps } from '../../../../../../views/details';
import { isArray } from 'lodash';
import { useThirdPartyCatchupProgram } from '../../../../../../queries/third-party-catchup/queries';

enum ThumbnailActions {
  PLAY,
  PURCHASE,
  EXTERNAL,
  GET_ACCESS,
}

const expandLimit = 8;

interface EpisodeProps {
  episode: AltiboxAsset;
  allEpisodesBelongToSameChannel: boolean;
  activeEpisodeId: string;
  setActiveEpisodeId: React.Dispatch<React.SetStateAction<string>>;
  isFilteringByPvr: boolean;
}

const Episode: FC<EpisodeProps> = ({
  episode,
  allEpisodesBelongToSameChannel,
  activeEpisodeId,
  setActiveEpisodeId,
  isFilteringByPvr,
}) => {
  const {
    altiboxAsset,
    promotedAsset,
    recordings,
    bookmarks,
    authState,
    isVodOrSvod,
    isSvod,
    svodKiosk,
    myContent,
    isGeoBlocked,
    channels,
    isCatchupOrPvr,
    isSlideIn,
    setRouteProps,
    getAccessToSvod,
    cannotPurchaseAbroad,
    cannotPlaybackAbroad,
    purchaseAsset,
  } = useAltiboxAsset();

  const match = useRouteMatch();
  const history = useHistory();

  // const [navigateToPlay, setNavigateToPlay] = useState<string | null>(null);
  const [shouldPurchase, setShouldPurchase] = useState(false);
  const [shouldCollapse, setShouldCollapse] = useState(false);

  const episodeIsActive = isEpisodeActive(episode, activeEpisodeId);

  const { data: externalCatchupData } = useThirdPartyCatchupProgram(episode.asset as Program);

  useEffect(() => {
    if (shouldPurchase) {
      purchaseAsset(promotedAsset as VodAsset);
      setShouldPurchase(false);
    }
  }, [shouldPurchase, purchaseAsset, promotedAsset]);

  function isOwned() {
    return myContent ? myContent.find((asset: VodAsset) => asset.id === episode.asset.id) : undefined;
  }

  function isLockedFromOutsideHome() {
    const asset = promotedAsset as Program;
    const channel = getAltiboxAssetChannel(asset.channelid, channels);
    const permissions = channelPermissions(channel, authState);
    return permissions ? permissions.onlyInsideForCatchup : false;
  }

  function isNotInSubscription() {
    const asset = episode.asset as Program;
    const channel = getAltiboxAssetChannel(asset.channelid, channels);
    return channel.channelPermissions.notInSubscription;
  }

  function isActiveRecording() {
    if (!broadcastingNow(episode.asset as Program)) {
      return false;
    }

    if (
      isSeriesRecording(recordings, episode.asset as Program) ||
      isSingleRecording(recordings, episode.asset as Program)
    ) {
      return true;
    }

    return false;
  }

  function getThumbnailOverlay() {
    const progress = getEpisodeProgressValue(episode, bookmarks);

    let overlayAction = ThumbnailActions.PLAY;
    let overlay: JSX.Element | null = <SvgPlay width="23px" height="27px" />;
    let noHover = false;

    if (canAssetBePurchased(altiboxAsset) && !isSvod && !isOwned()) {
      overlayAction = ThumbnailActions.PURCHASE;
      overlay = <PurchaseTextOverlay altiboxAsset={altiboxAsset} />;
    }

    if (svodKiosk && isSvod) {
      const hasProduct = checkIfUserHasSvodProduct(authState, svodKiosk);
      if (!hasProduct || authState.isGuest) {
        overlayAction = ThumbnailActions.GET_ACCESS;
        overlay = <AccessOverlay />;
      }
    }

    if (Boolean(isGeoBlocked || isLockedFromOutsideHome())) {
      overlayAction = 'DoNothing' as unknown as ThumbnailActions;
      overlay = null;
      noHover = true;
    }

    if (assetIsProgram(episode.asset)) {
      if (isNotInSubscription()) {
        overlayAction = ThumbnailActions.GET_ACCESS;
        overlay = <AccessOverlay />;
      }

      if (externalCatchupData && externalCatchupData.isExternal) {
        if (externalCatchupData.url) {
          overlayAction = ThumbnailActions.EXTERNAL;
          overlay = <ExternalOverlay externalData={externalCatchupData.data} />;
        } else if (!broadcastingFuture(episode.asset)) {
          return null;
        }
      }

      if (broadcastEnded(episode.asset) && !catchupAvailable(episode.asset)) {
        return null;
      }

      if (broadcastingFuture(episode.asset)) {
        overlay = <ComingSoonOverlay />;
        overlayAction = 'DoNothing' as unknown as ThumbnailActions;
        noHover = true;
      }
    }

    const noHoverClass = noHover ? ' disabled' : '';
    const overlayActionClass = overlayAction === ThumbnailActions.PLAY ? ' overlay-play' : '';
    const overlayClass = 'overlay' + noHoverClass + overlayActionClass;
    const playLink = getAltiboxAssetPlayLink(altiboxAsset, episode.asset);

    if (overlayAction === ThumbnailActions.PLAY) {
      return (
        <>
          <Link
            to={playLink}
            className={overlayClass}
            onClick={(e) => {
              if (isGeoBlocked) {
                e.preventDefault();
                cannotPlaybackAbroad();
              }

              if (playLink === window.location.pathname) {
                return;
              }

              if (playLink) {
                window.location.replace(playLink);
              }
            }}
          >
            {overlay}
          </Link>
          {progress > 97 ? (
            <div className="finished">
              <SvgCheck width="25px" height="20px" />
            </div>
          ) : null}
        </>
      );
    }

    return (
      <>
        <div className={overlayClass} onClick={() => onThumbnailOverlayClick(overlayAction)}>
          {overlay}
        </div>
        {progress > 97 ? (
          <div className="finished">
            <SvgCheck width="25px" height="20px" />
          </div>
        ) : null}
      </>
    );
  }

  function onThumbnailOverlayClick(action: ThumbnailActions) {
    switch (action) {
      case ThumbnailActions.PLAY:
        if (isGeoBlocked) {
          cannotPlaybackAbroad();
        }
        // else {
        // const playLink = getAltiboxAssetPlayLink(altiboxAsset, episode.asset);
        // setNavigateToPlay(playLink);
        // }
        break;
      case ThumbnailActions.PURCHASE:
        if (isGeoBlocked) {
          cannotPurchaseAbroad();
        } else {
          setShouldPurchase(true);
        }
        break;
      case ThumbnailActions.EXTERNAL:
        if (externalCatchupData?.url) {
          window.location.href = externalCatchupData.url;
        }
        break;
      case ThumbnailActions.GET_ACCESS:
        getAccessToSvod();
        break;
      default:
        break;
    }
  }

  function getThumbnail() {
    const progress = getEpisodeProgressValue(episode, bookmarks);
    const shouldShowProgress = getShouldShowEpisodeProgress(authState, episode, bookmarks);
    let placeholder = isVodOrSvod ? missingVODPlaceholder : missingTvPlaceholder;
    let thumbnail = getAltiboxAssetThumbnail(episode, altiboxAsset);
    thumbnail = thumbnail ? thumbnail : placeholder;

    return (
      <div className="thumbnail">
        {getThumbnailOverlay()}
        <AltiboxImage alt={getAltiboxAssetTitle(episode)} placeholder={placeholder} image={thumbnail} />
        {shouldShowProgress ? <progress value={progress} max="100" /> : null}
      </div>
    );
  }

  function getVodBadge() {
    if (svodKiosk) {
      return undefined;
    }

    if (!isOwned()) {
      return null;
    }

    if (assetIsBought(episode.asset as VodAsset)) {
      return <Badge className="bought" text={i18n.t<string>('bought')} />;
    } else {
      return <Badge className="rent" text={getRentBadgeTextWithInformation((episode.asset as VodAsset).rentperiod)} />;
    }
  }

  function getBadge() {
    const { type } = altiboxAsset;

    switch (type) {
      case AltiboxAssetDetailsType.VIDEO_VOD:
      case AltiboxAssetDetailsType.VOD:
        return getVodBadge();
      case AltiboxAssetDetailsType.PROGRAM:
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PVR:
        if (assetIsPvr(episode.asset)) {
          return broadcastStatus({ starttime: episode.asset.beginTime, endtime: episode.asset.endTime }) ===
            BroadcastStatus.Live ? (
            <Badge className="recording" text={i18n.t<string>('is recording')} />
          ) : (
            <Badge className="recorded" text={i18n.t<string>('recorded')} />
          );
        }

        return isActiveRecording() ? <Badge className="recording" text={i18n.t<string>('is recording')} /> : null;
      case AltiboxAssetDetailsType.SVOD:
      default:
        return null;
    }
  }

  function getAdditionalThumbnail() {
    const badge = getBadge();

    if (badge && !isFilteringByPvr) {
      return badge;
    }

    if (allEpisodesBelongToSameChannel) {
      return null;
    }

    const alteredEpisode = {
      ...episode,
      meta: altiboxAsset.meta,
    };

    const thumbnail = getAltiboxAssetAdditionalThumbnail(alteredEpisode, channels, svodKiosk);

    if (thumbnail) {
      return <img src={thumbnail} alt={`${i18n.t<string>('episode thumbnail')}: ${getAltiboxAssetTitle(episode)}`} />;
    } else {
      return null;
    }
  }

  const handleSetActiveEpisodeId = () => {
    const promotedAssetId = getAltiboxAssetId(episode.asset);

    if (activeEpisodeId === getAltiboxAssetPromotedId(episode.asset)) {
      return setShouldCollapse((prev) => !prev);
    }

    setRouteProps({ childId: promotedAssetId });

    setActiveEpisodeId(promotedAssetId);
    setShouldCollapse(false);

    if (!isSlideIn) {
      const params: DetailsRouteProps = match.params;
      const newPath = match.path
        .replace(':channelId', params.channelId ?? '')
        .replace(':svodKiosk', params.svodKiosk ?? '')
        .replace(':parentId', params.parentId ?? '')
        .replace(':childId', promotedAssetId);

      history.replace(newPath);
    }
  };

  // TODO: This made this component go haywire; was reloading the component infinitely.
  // Make the links change the asset in player without reloading.
  // if (isSlideIn && navigateToPlay) {
  //   history.push(navigateToPlay);
  // }

  const [additionalInfo, additionalInfoClassName] = getAdditionalInformation(episode, isCatchupOrPvr);

  return (
    <React.Fragment key={'fragment-' + getPromotedAssetId(episode.asset)}>
      <tr tabIndex={0} className={`episode-row ${episodeIsActive ? ' active' : ''}`} onClick={handleSetActiveEpisodeId}>
        <td>{getThumbnail()}</td>
        <td className={'episode-number'}>
          {getAltiboxAssetEpisodeNumber(episode) ? <div>{getAltiboxAssetEpisodeNumber(episode)}</div> : null}
        </td>
        <td className="episode-name">
          <div>
            <div>{getAltiboxAssetEpisodeTitle(episode)}</div>
          </div>
        </td>
        <td className={additionalInfoClassName ?? ''}>{additionalInfo}</td>
        <td className="episode-badge">{isCatchupOrPvr ? getAdditionalThumbnail() : getBadge()}</td>
      </tr>
      <tr>
        <ExpandedDetails
          episode={episode}
          active={episodeIsActive}
          shouldCollapse={shouldCollapse}
          additionalInfo={additionalInfo}
          additionalInfoClassName={additionalInfoClassName}
        />
      </tr>
    </React.Fragment>
  );
};

interface ExpandedDetailsProps {
  episode: AltiboxAsset;
  active: boolean;
  shouldCollapse: boolean;
  additionalInfo: string | null;
  additionalInfoClassName?: string;
}

function ExpandedDetails({
  active,
  shouldCollapse,
  episode,
  additionalInfo,
  additionalInfoClassName,
}: ExpandedDetailsProps) {
  const { altiboxAsset, routeProps } = useAltiboxAsset();
  const asset = episode.asset as Program;

  const activeAndShouldNotCollapse = active && !shouldCollapse ? ' active' : '';
  const activeAndShouldCollapse = active && shouldCollapse ? ' active-and-collapsed' : '';
  const expandedClass = activeAndShouldCollapse || activeAndShouldNotCollapse || '';

  return (
    <td colSpan={5} className={`expanded-episode-details ${expandedClass}`}>
      <div className="expanded-episode-content-container">
        <div className="expanded-episode-details-information">
          <div className={additionalInfoClassName ?? ''}>{additionalInfo}</div>
          <ExpandableText threshold={420}>{getAltiboxAssetDescription(altiboxAsset, routeProps)}</ExpandableText>
          <AltiboxAssetButton
            overwriteToLink={getAltiboxAssetPlayLink(altiboxAsset, asset)}
            buttonType={AltiboxAssetButtonType.PLAY}
            limited={true}
            isFutureProgram={!!asset.starttime && broadcastingFuture(asset)}
          />
        </div>

        <div className="expanded-episode-details-related-assets">
          <SharedAssets />
        </div>
      </div>
    </td>
  );
}

interface PurchaseTextOverlayProps {
  altiboxAsset: AltiboxAsset;
}

function PurchaseTextOverlay({ altiboxAsset }: PurchaseTextOverlayProps) {
  function getPurchaseText() {
    const { meta } = altiboxAsset;

    if (isArray(meta?.purchaseOptions)) {
      const priceArr = meta?.purchaseOptions.map((option) => Number(option.price));
      const lowestPrice = Math.min(...priceArr!);
      return `${i18n.t('watch')} ${i18n.t('from')} ${lowestPrice},-`;
    }

    return `${i18n.t('watch')} ${i18n.t('from')} ${meta?.purchaseOptions},-`;
  }

  return <div className="innertext">{getPurchaseText()}</div>;
}

function AccessOverlay() {
  return <div className="innertext">{i18n.t<string>('get access')}</div>;
}

function ExternalOverlay({ externalData }: { externalData: ThirdPartyCatchupData | undefined }) {
  return (
    <div className="innertext">
      {i18n.t<string>('watch external catchup', { title: getCurrentLangTitle(externalData?.titles) })}
    </div>
  );
}

function ComingSoonOverlay() {
  return <SvgTimer height="27px" width="27px" />;
}

interface ViewListProps {
  episodeList: AltiboxAsset[];
  isFilteringByPvr: boolean;
}

const ViewList: FC<ViewListProps> = ({ episodeList, isFilteringByPvr }) => {
  const { isCatchupOrPvr, promotedAsset } = useAltiboxAsset();

  const [episodes, setEpisodes] = useState<AltiboxAsset[]>([]);

  const promotedId = getAltiboxAssetPromotedId(promotedAsset);
  const [activeEpisodeId, setActiveEpisodeId] = useState(promotedId);

  const [isExpanded, setIsExpanded] = useState(false);
  const canExpand = episodes.length > expandLimit;

  useEffect(() => {
    setActiveEpisodeId(promotedId);
    if (promotedId && episodeList) {
      const index = episodeList.findIndex((episode) => {
        return getAltiboxAssetPromotedId(episode.asset) === promotedId;
      });
      if (index > expandLimit) {
        setIsExpanded(true);
      }
    }
  }, [promotedId, episodeList]);

  useEffect(() => {
    setEpisodes(episodeList);
  }, [episodeList]);

  const allEpisodesBelongToSameChannel = isCatchupOrPvr
    ? episodes
        .map((episode) => (episode.asset as Program).channelid ?? (episode.asset as PvrRecording).channelId)
        .some((id, _, array) => id !== array[0])
    : false;

  function expandEpisode() {
    setIsExpanded((prev) => !prev);
  }

  return (
    <>
      <table role={'presentation'} className={`altibox-asset-episodes-listview`}>
        <tbody>
          {episodes.slice(0, isExpanded ? episodes.length : expandLimit).map((episode) => {
            const { pvrId } = episode.asset as PvrRecording;
            return (
              <Episode
                episode={episode}
                allEpisodesBelongToSameChannel={allEpisodesBelongToSameChannel}
                activeEpisodeId={activeEpisodeId}
                setActiveEpisodeId={setActiveEpisodeId}
                isFilteringByPvr={isFilteringByPvr}
                key={pvrId || episode.asset.id}
              />
            );
          })}
        </tbody>
      </table>
      {canExpand && (
        <div className="expand-results">
          <ButtonExpandable expanded={isExpanded} onClick={expandEpisode}>
            {isExpanded ? (
              <span>{i18n.t<string>('show less episodes')}</span>
            ) : (
              <span>{i18n.t<string>('show all episodes')}</span>
            )}
          </ButtonExpandable>
        </div>
      )}
    </>
  );
};

export default ViewList;
