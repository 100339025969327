import moment from 'moment';
import { huaweiDateTimeFormat } from '../config';
import { BroadcastStatus, Program } from '../interfaces';
import i18n from 'i18next';

const TODAY = moment();
const YESTERDAY = moment().subtract(1, 'day');
const TOMORROW = moment().add(1, 'day');
const ONE_WEEK_FORWARD = moment().add(7, 'd');

export function toMoment(huaweiDateTime: string) {
  return moment(huaweiDateTime, huaweiDateTimeFormat);
}

export function fromMoment(time: moment.Moment) {
  return time.format(huaweiDateTimeFormat);
}

export function getDurationInSeconds(start: string, end: string) {
  return toMoment(end).diff(toMoment(start), 'seconds');
}

export function addMinutes(inputTime: string, minutes: number) {
  return fromMoment(toMoment(inputTime).add(minutes, 'minutes'));
}

export function subtractMinutes(inputTime: string, minutes: number) {
  return fromMoment(toMoment(inputTime).subtract(minutes, 'minutes'));
}

export function toMomentReadableDateString(huaweiDateTime: string, showWeekdayName?: boolean) {
  const momentDate = moment(huaweiDateTime, huaweiDateTimeFormat);
  if (momentDate.isSame(moment(), 'day')) {
    return momentDate.locale(i18n.language).calendar();
  }
  return momentDate.locale(i18n.language).format(i18n.t<string>('date format catchup'));
}

export function toDetailsReadableDateString({
  huaweiDateTime,
  ...options
}: {
  huaweiDateTime: string;
  longestFormat: string;
  longFormat: string;
  shortFormat: string;
  farInTheFutureFormat: string;
}) {
  const date = moment(huaweiDateTime, huaweiDateTimeFormat);
  const currentYear = date.isSame(TODAY, 'year');
  const moreThanOneWeekForward = date.isSameOrAfter(ONE_WEEK_FORWARD, 'day');

  const when = YESTERDAY.isSame(date, 'day')
    ? i18n.t<string>('yesterday')
    : TODAY.isSame(date, 'day')
    ? i18n.t<string>('today')
    : TOMORROW.isSame(date, 'day')
    ? i18n.t<string>('tomorrow')
    : moreThanOneWeekForward
    ? ''
    : '';

  return when
    ? `${when} ${date.format(options.shortFormat)}`
    : moreThanOneWeekForward
    ? date.format(options.farInTheFutureFormat)
    : currentYear
    ? date.format(options.longFormat)
    : date.format(options.longestFormat);
}

export function nowHuaweiFormat() {
  return moment().format(huaweiDateTimeFormat);
}

export function minusWeeksFromNow(weeks: number) {
  return moment().subtract(weeks, 'weeks').format(huaweiDateTimeFormat);
}

interface BroadcastingArgs {
  starttime: string;
  endtime: string;
}

export function broadcastingNow({ starttime, endtime }: BroadcastingArgs) {
  return broadcastStatus({ starttime, endtime }) === BroadcastStatus.Live;
}

export function broadcastEnded(program: Program): boolean {
  return broadcastStatus(program) === BroadcastStatus.Finished;
}

export function broadcastingFuture(program: Program): boolean {
  return broadcastStatus(program) === BroadcastStatus.Future;
}

export function broadcastStatus(
  { starttime, endtime }: BroadcastingArgs,
  now: moment.Moment = moment(),
): BroadcastStatus {
  const startTime = toMoment(starttime);

  if (startTime.isAfter(now)) {
    return BroadcastStatus.Future;
  }

  const endTime = toMoment(endtime);

  if (startTime <= now && now < endTime) {
    return BroadcastStatus.Live;
  }

  return BroadcastStatus.Finished;
}

export function isBroadcastWithinFifteenMinutes({ starttime, endtime }: BroadcastingArgs) {
  const now = moment();
  const endTimePlusFifteenMinutes = moment(toMoment(endtime)).add(15, 'minutes');

  return now.isBefore(endTimePlusFifteenMinutes) && now.isAfter(toMoment(starttime));
}

export function broadcastProgress({ starttime, endtime }: BroadcastingArgs): number {
  const now = moment().unix();
  const start = toMoment(starttime).unix();
  const end = toMoment(endtime).unix();
  const percent = (now - start) / (end - start);

  return percent <= 1 ? percent : 1;
}
