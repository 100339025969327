import moment from 'moment';
import { status, json, fetchError } from '../controllers/Fetch';
import {
  VodAsset,
  PlayResult,
  Authorization,
  VodConfig,
  VodSection,
  VodListResponse,
  Genre,
  VodBatch,
  BatchVodListResponse,
  AuthPromise,
  VodContentPriceResponse,
  PurchaseContentLocked,
  PurchaseAsset,
  PurchaseErrors,
  Bookmarks,
  StreamType,
  VodAssetSeason,
  ContentDetailResponse,
  PortalMenu,
  TrailerStreamTypeObject,
  ForeignCodeResponse,
  StreamTypeObject,
  MediaFile,
  CODEC,
  VodContentProduct,
  ContentType,
  Filter,
} from '../interfaces';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import HuaweiErrors from '../controllers/HuaweiErrors';

import { getBaseUrl, getSessionTicket, vodConfigPath } from '../config';
import { shouldCapBandwidthForThisStream } from '../utils/vodUtils';
import { ScriptService } from '../controllers/ScriptService';
import { toMoment } from '../utils/huaweiUtils';

var playUrl = '/EPG/JSON/Play';

export interface MyContentResponse {
  contentlist?: {
    id: string;
    type: ContentType;
    starttime: string;
    endtime: string;
    serstarttime: string;
    serendtime: string;
    buisnesstype: number;
    name: string;
  }[];
  count: number;
}

let properties =
  'id,name,type,introduce,picture,casts,cast,vodtype,seriesType,sitcomnum,' +
  'episodeTotalCount,price,genres,genreIds,mediafiles,clipfiles,rentperiod,ratingid,visittimes,' +
  'starttime,isfavorited,issubscribed,isRefresh,producedate,foreignsn,fathervodlist,country,' +
  'categoryIds,extensionInfo,externalContentCode,company,companyName,location,subtitles,languages,locationCopyrights';

const vodInclude = {
  name: 'vod',
  include: properties
    .split(',')
    .map((x) => 'vodlist/vod/' + x)
    .join(),
};

export function getVODBookmarks() {
  return fetch(getBaseUrl() + '/EPG/JSON/QueryBookmark', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      bookmarkType: 0, // VOD bookmark type
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((bookmarks) => {
      return bookmarks as Bookmarks;
    })
    .catch(fetchError);
}

export function getMyContent(count: number): Promise<VodAsset[]> {
  const myContentUrl = '/EPG/JSON/QueryMyContent';
  return new Promise((resolve) => {
    fetch(getBaseUrl() + myContentUrl, {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        contenttype: 'VOD',
        count: count,
        offset: 0,
        order: '4',
        productType: 1,
      }),
    })
      .then(status)
      .then((r) => json(r as Response))
      .then((myContentData: MyContentResponse) => {
        const contentlist = myContentData.contentlist;
        if (contentlist && contentlist.length > 0) {
          return resolve(
            getContentDetail(contentlist.map((x) => x.id).join(',')).then((vods) => {
              return vods.map((vod) => {
                const ownedVod = contentlist.find((owned) => owned.id === vod.id);
                const rentperiod = ownedVod
                  ? String(toMoment(ownedVod.serendtime).diff(moment.now(), 'seconds'))
                  : vod.rentperiod;

                return {
                  ...vod,
                  rentperiod,
                };
              });
            }),
          );
        } else {
          return resolve([] as VodAsset[]);
        }
      })
      .catch(fetchError);
  });
}

export function getContentIdByExternalContentCode(code: string) {
  const contentDetailUrl = '/EPG/JSON/GetContentByForeignSn';
  return fetch(getBaseUrl() + contentDetailUrl, {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      contenttype: 'VOD',
      foreignSn: code,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (data: ForeignCodeResponse) {
      if (data && data.content) {
        return data.content.id;
      }
      throw new Error('Not found');
    });
}

// Based on altibox id
export async function getContentDetail(seriesString: string): Promise<VodAsset[]> {
  if (isEmpty(seriesString)) {
    return Promise.resolve([] as VodAsset[]);
  }
  const contentDetailUrl = '/EPG/JSON/ContentDetail';
  return fetch(getBaseUrl() + contentDetailUrl, {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      metaDataVer: 'Channel/1.1',
      properties: [vodInclude],
      vod: seriesString,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((contentDetailData: ContentDetailResponse) => {
      return contentDetailData.vodlist as VodAsset[];
    });
}

export async function getVodContentDetailFromBookmarks(bookmarks: Bookmarks) {
  const contentIds = bookmarks.bookmarkList.map((x) => x.contentId).filter((x) => x);
  return (await getContentDetail(contentIds.join(','))) as VodAsset[];
}

export function getContentDetailFromAibId(id: string) {
  const contentDetailUrl = '/EPG/JSON/ContentDetail';
  return fetch(getBaseUrl() + contentDetailUrl, {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      metaDataVer: 'Channel/1.1',
      idType: 2,
      properties: [vodInclude],
      vod: id,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (contentDetailData: ContentDetailResponse) {
      if (contentDetailData.vodlist && contentDetailData.vodlist[0]) {
        return contentDetailData.vodlist[0];
      } else {
        throw new Error('not found');
      }
    })
    .catch(fetchError);
}

export function getContentDetailSingle(id: string) {
  return getContentDetail(id).then((vods) => {
    if (!vods || vods.length === 0) {
      throw new Error('not found');
    }

    return vods[0];
  });
}

export function getSingleContentDetail(id: string) {
  return getContentDetail(id).then((vods) => {
    if (!vods || vods.length === 0) {
      return undefined;
    }

    return vods[0];
  });
}

export function getSeriesByEpisodesList(vodAssets: VodAsset[]) {
  const seasonIds = vodAssets.map((x) => x.fathervodlist[0].vodid);
  return getContentDetail(seasonIds.join(','))
    .then((seasonVods: VodAsset[]) => {
      const innerSeasonIds = seasonVods.map((x) => x.fathervodlist[0].vodid);
      return innerSeasonIds;
    })
    .then((seriesIds) => getContentDetail(seriesIds.join(',')));
}

export function getSeriesByEpisode(vodAsset: VodAsset) {
  const seasonId = vodAsset.fathervodlist[0].vodid;
  return getContentDetailSingle(seasonId)
    .then((seasonVod: VodAsset) => seasonVod.fathervodlist[0].vodid)
    .then((seriesId) => getContentDetailSingle(seriesId));
}

export function getAssetStreamInfo(asset: VodAsset) {
  var authorizationUrl = '/EPG/JSON/Authorization';

  return (
    getMediaFileId(asset)
      .then((mediafileId: string) => {
        return fetch(getBaseUrl() + authorizationUrl, {
          method: 'POST',
          headers: { SessionTicket: getSessionTicket() },
          body: JSON.stringify({
            businesstype: 1, // 1 = VOD
            contentid: asset.id,
            contenttype: 'VOD',
            mediaId: mediafileId,
          }),
        })
          .then(status)
          .then((r) => json(r as Response))
          .then((authData: Authorization) => {
            if (authData.errorCode) {
              throw authData.desc;
            }
            // console.log(authorizationUrl, authData);
            return Promise.resolve({
              authData: authData,
              asset: asset,
              mediafileId: mediafileId,
            });
          })
          .catch(fetchError);
      })
      // GET PLAY URL
      .then((data) => {
        data = data as AuthPromise;
        return fetch(getBaseUrl() + playUrl, {
          method: 'POST',
          headers: { SessionTicket: getSessionTicket() },
          body: JSON.stringify({
            contentid: data.asset.id,
            playtype: 1,
            mediaid: data.mediafileId,
            subscriptionKey: data.authData.subscriptionKey,
          }),
        })
          .then(status)
          .then((r) => json(r as Response))
          .then((playData: PlayResult) => {
            if (playData.retcode === '85983417' || playData.retcode === '85983372') {
              throw playData;
            } else {
              // CAP bandwidth
              let capBandwidthAt = -1;
              if (shouldCapBandwidthForThisStream(asset.companyName)) {
                console.log('Capping: 576');
                capBandwidthAt = 575;
              }

              let licenseUrl = '';
              if (ScriptService._isSafari()) {
                licenseUrl =
                  playData.triggers && playData.triggers[0] ? playData.triggers[0].getLicenseTrigger.licenseURL : '';
              }

              data = data as AuthPromise;
              let streamTypeObject: StreamTypeObject = {
                isGuest: false,
                contentId: data.asset.id,
                streamType: StreamType.VOD,
                mediaId: data.mediafileId,
                playData: {
                  manifestUrl: playData.url,
                  capBandwidthAt: capBandwidthAt,
                  customData: playData.triggers ? playData.triggers[0].getLicenseTrigger.customData : '',
                  licenseUrl: licenseUrl,
                },
              };
              return streamTypeObject;
            }
          })
          .catch(fetchError);
      })
  );
}

export function getTrailerData(asset: VodAsset) {
  var mediaId = '';
  if (asset.clipfiles && asset.clipfiles[0]) {
    mediaId = asset.clipfiles[0].id;
  }
  return fetch(getBaseUrl() + playUrl, {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      contentid: asset.id,
      playtype: 5, // trailer type
      mediaid: mediaId,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((playData: PlayResult) => {
      return {
        contentId: asset.id,
        streamType: StreamType.TRAILER,
        isGuest: true,
        mediaId: mediaId,
        externalContentCode: asset.externalContentCode,
        playData: {
          capBandwidthAt: -1,
          manifestUrl: playData.url,
        },
        title: asset.name ? asset.name : '',
      } as TrailerStreamTypeObject;
    })
    .catch(fetchError);
}

interface AuthBatch {
  name: string;
  param: {
    businesstype: number;
    contentid: string;
    contenttype: string;
    mediaId: string;
  };
}

export interface AuthBatchListResponse {
  msg: Authorization;
  name: string;
}

export interface AuthBatchListResponseList {
  responseList: AuthBatchListResponse[];
}

// get correct mediafile id based on what we are entitled to play - sd or hd
function getMediaFileId(vodAsset: VodAsset) {
  let batch: AuthBatch[] = [];
  vodAsset.mediafiles.forEach((mediaFile: MediaFile) => {
    let batchObject = {
      name: 'Authorization',
      param: {
        businesstype: 1, // 1 = VOD
        contentid: vodAsset.id,
        contenttype: 'VOD',
        mediaId: mediaFile.id,
      },
    };
    batch.push(batchObject);
  });

  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: batch,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (response: AuthBatchListResponseList) {
      let validMediaFiles: MediaFile[] = [];
      response.responseList.forEach((authResp: AuthBatchListResponse, index: number) => {
        if (authResp.msg.isValid === '1') {
          validMediaFiles.push(vodAsset.mediafiles[index]);
        }
      });

      const mediaFile = getBestSuitedMediaFile(validMediaFiles);
      if (mediaFile) {
        return mediaFile.id;
      } else {
        return '';
      }
    })
    .catch(fetchError);
}

function getBestSuitedMediaFile(validMediaFiles: MediaFile[]) {
  let mediaFile = undefined;
  let codecSortedMediaFiles: MediaFile[] = [];

  if (ScriptService.supportsHEVC()) {
    codecSortedMediaFiles = validMediaFiles.filter((x) => x.videoCodec === CODEC.HEVC);

    // not all assets has HEVC
    if (codecSortedMediaFiles.length === 0) {
      codecSortedMediaFiles = validMediaFiles;
    }
  } else {
    codecSortedMediaFiles = validMediaFiles.filter((x) => x.videoCodec === CODEC.AVC);
  }

  // set best quality first
  codecSortedMediaFiles.sort((a: MediaFile, b: MediaFile) => Number(a.definition) - Number(b.definition));
  const bestResolutionMediaFile = codecSortedMediaFiles.shift();

  if (bestResolutionMediaFile) {
    mediaFile = bestResolutionMediaFile;
  }

  return mediaFile;
}

export function getVodConfig(areaId: string, bossId: string, useDefaultConfig: boolean = false) {
  const configUrl = useDefaultConfig
    ? '/vod-config-v3.json'
    : `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/${vodConfigPath}`;
  return fetch(configUrl)
    .then(status)
    .then((r) => json(r as Response))
    .then(function (config: VodConfig) {
      return config;
    })
    .catch(fetchError);
}

export function getBatchOfVodLists(vodLists: Partial<VodSection>[], genres: Genre[], count: number) {
  let vodsToFetch: VodBatch[] = [];

  // filter
  vodLists.forEach((vodSection) => {
    let batch: VodBatch = {
      name: 'VodList',
      param: {
        categoryid: vodSection.categoryId,
        count: count,
        offset: 0,
        orderType: vodSection.sorting,
        properties: [vodInclude],
      },
    };

    let filterList;

    if (vodSection.filter) {
      filterList = getFilterList(vodSection.filter, genres);
    }

    if (filterList) {
      batch.param.filterlist = filterList;
    }

    vodsToFetch.push(batch);
  });

  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: vodsToFetch,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (response: BatchVodListResponse) {
      response.responseList.forEach((category, index) => {
        category.section = vodLists[index];
      });
      return response.responseList;
    })
    .catch(fetchError);
}

function getFilterList(filters: Filter[], genres: Genre[]) {
  let filterList = undefined;
  if (!isEmpty(filters)) {
    let mappedFilters: string[] = [];
    let filterIds = filters[0].value.replace(/\s/g, '').split(',');

    filterIds.forEach((id) => {
      let singleGenre = genres.find((_genre) => _genre.genreId === id.trim());
      if (singleGenre) {
        mappedFilters.push(singleGenre.genreName);
      }
    });

    filterList = [{ key: 'Genre', value: mappedFilters.join(',') }];
  }
  return filterList;
}

export function getPromoVodList(categoryId: string) {
  return fetch(getBaseUrl() + '/EPG/JSON/VodList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      categoryid: categoryId,
      orderType: 2, // most pop
      count: 200, // will we ever need count?
      offset: 0,
      properties: [vodInclude],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (vodListResponse: VodListResponse) {
      return vodListResponse;
    })
    .catch(fetchError);
}

export function getVodCategory(
  categoryId: string,
  orderType: string,
  genres: string,
  availableGenres: Genre[],
  count: number,
  offset: number,
) {
  let filterList = getVodCategoryFilterList(genres, availableGenres);

  return fetch(getBaseUrl() + '/EPG/JSON/VodList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      categoryid: categoryId,
      count: count,
      offset: offset,
      orderType: orderType,
      properties: [vodInclude],
      filterlist: filterList ? filterList : '',
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (vodListResponse: VodListResponse) {
      return vodListResponse;
    })
    .catch(fetchError);
}
export function loadAllVodCategoryIds(categoryIds: string[], orderType: string, count: number) {
  let requestList = categoryIds.map((id: string, index: number) => {
    return {
      name: 'VodList',
      param: {
        categoryid: id,
        count: count,
        offset: 0,
        orderType: orderType,
        properties: [vodInclude],
      },
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let executeBatches: Promise<any>[] = [];
  let iterations = Math.ceil(requestList.length / 10);
  for (let i = 0; i < iterations; i++) {
    executeBatches.push(
      fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
        method: 'POST',
        headers: { SessionTicket: getSessionTicket() },
        body: JSON.stringify({
          requestList: requestList.slice(i * 10, (i + 1) * 10),
        }),
      })
        .then(status)
        .then((r) => json(r as Response)),
    );
  }
  return Promise.all(executeBatches)
    .then((res) => {
      let categoryResults: VodListResponse[] = [];
      flatten(res).forEach((content) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        content.responseList.forEach((response: any) => {
          if (response && response.msg && response.msg) {
            categoryResults.push(response.msg);
          }
        });
      });
      return categoryResults;
    })
    .catch(fetchError);
}

export function loadVodCategoryId(categoryId: string, orderType: string, count: number) {
  return fetch(getBaseUrl() + '/EPG/JSON/VodList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      categoryid: categoryId,
      count: count,
      offset: 0,
      orderType: orderType,
      properties: [vodInclude],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (vodListResponse: VodListResponse) {
      return vodListResponse;
    })
    .catch(fetchError);
}

function getVodCategoryFilterList(genres: string, availableGenres: Genre[]) {
  let filterList = undefined;
  if (genres) {
    let mappedFilters: string[] = [];
    let filterIds = genres.split(',');

    filterIds.forEach((id) => {
      let singleGenre = availableGenres.find((_genre) => _genre.genreId === id.trim());
      if (singleGenre) {
        mappedFilters.push(singleGenre.genreName);
      }
    });

    filterList = [{ key: 'Genre', value: mappedFilters.join(',') }];
  }
  return filterList;
}

export function getVodList(category: VodSection, genres: Genre[], count: number, offset: number) {
  let filterList = getFilterList(category.filter, genres);

  return fetch(getBaseUrl() + '/EPG/JSON/VodList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      categoryid: category.categoryId,
      count: count,
      offset: offset,
      orderType: category.sorting,
      properties: [vodInclude],
      filterlist: filterList ? filterList : '',
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (vodListResponse: VodListResponse) {
      return vodListResponse;
    })
    .catch(fetchError);
}

export function getSeasons(vodId: string, svodKiosk?: PortalMenu) {
  return fetch(getBaseUrl() + '/EPG/JSON/SitcomList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      vodid: vodId,
      count: -1,
      offset: 0,
      categoryid: svodKiosk ? svodKiosk.svodCategoryId : '',
      properties: [vodInclude],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (vodListResponse: VodListResponse) {
      return vodListResponse.vodlist as VodAssetSeason[];
    })
    .catch(fetchError);
}

export function batchGetSeasonsOrEpisodes(vodIds: string[], svodKiosks: PortalMenu[]) {
  let requestList: VodBatch[] = [];
  vodIds.forEach((id, idx) => {
    requestList.push({
      name: 'SitcomList',
      param: {
        vodid: id,
        count: -1,
        offset: 0,
        categoryid: svodKiosks && svodKiosks[idx] ? svodKiosks[idx].svodCategoryId : '',
        properties: [vodInclude],
      },
    });
  });

  return (
    requestList.length > 0 &&
    fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        requestList: requestList,
      }),
    })
      .then(status)
      .then((r) => json(r as Response))
      .then(function (batchVodListResponse: BatchVodListResponse) {
        return batchVodListResponse;
      })
  );
}

export function getEpisodes(seasonIds: string[], svodKiosk?: PortalMenu) {
  let requestList: VodBatch[] = [];
  seasonIds.forEach((id) => {
    requestList.push({
      name: 'SitcomList',
      param: {
        vodid: id,
        count: -1,
        offset: 0,
        categoryid: svodKiosk ? svodKiosk.svodCategoryId : '',
        properties: [vodInclude],
      },
    });
  });

  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: requestList,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (batchVodListResponse: BatchVodListResponse) {
      const responses = batchVodListResponse.responseList;

      const episodesBySeason = responses
        .filter((obj) => obj.msg.counttotal !== '0')
        .map((response) => response.msg.vodlist);
      return flatten(episodesBySeason);
    })
    .catch(fetchError);
}

export function getContentPricing(vodId: string) {
  return getProductsByContent(vodId).then((results) => {
    return Promise.resolve(
      results
        ? (results as VodContentProduct[])
            .filter((x) => x.restrictionList)
            .filter((x) => x.name.indexOf('_EST_') > -1 || x.name.indexOf('_TVOD_') > -1)
        : undefined,
    );
  });
}

export function getPotentialSharedAssets(vodId: string) {
  return getProductsByContent(vodId).then((results) => {
    return Promise.resolve(!isEmpty(results) ? results : undefined);
  });
}

export function getProductsByContent(vodId: string) {
  return fetch(getBaseUrl() + '/EPG/JSON/GetProductsByContent', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      contentID: vodId,
      contentType: 'VOD',
      productType: '-1',
      isEST: '-1',
      count: '-1',
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (vodContentPrices: VodContentPriceResponse) {
      return vodContentPrices.list;
    })
    .catch(fetchError);
}

export function queryContentLockedAsset(asset: PurchaseContentLocked) {
  return new Promise<void>((resolve, reject) => {
    return fetch(getBaseUrl() + '/EPG/JSON/ContentLocked', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify(asset),
    })
      .then(status)
      .then((r) => json(r as Response))
      .then((result) => {
        if (result.retcode === '1') {
          resolve();
        } else {
          reject(PurchaseErrors.Restrictions);
        }
      })
      .catch(fetchError);
  });
}

export function queryPurchaseAsset(asset: PurchaseAsset) {
  return new Promise<void>((resolve, reject) => {
    return fetch(getBaseUrl() + '/EPG/JSON/Subscribe', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify(asset),
    })
      .then(status)
      .then((r) => json(r as Response))
      .then((result) => {
        if (result.retcode === '0') {
          resolve();
        } else if (result.retcode === HuaweiErrors.INVALID_USER) {
          reject(PurchaseErrors.InvalidUser);
        } else if (result.retcode === HuaweiErrors.BLOCKED_FOR_PURCHASE) {
          reject(PurchaseErrors.BlockedPurchase);
        } else {
          reject(PurchaseErrors.NoFunds);
        }
      })
      .catch(fetchError);
  });
}

// Based on altibox id
export async function getContentDetailSearch(seriesString: string): Promise<VodAsset[]> {
  const contentDetailUrl = '/EPG/JSON/ContentDetail';
  return fetch(getBaseUrl() + contentDetailUrl, {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      filterType: '0',
      idType: '0',
      metaDataVer: 'Channel/1.1',
      properties: [vodInclude],
      vod: seriesString,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((contentDetailData: ContentDetailResponse) => {
      return contentDetailData.vodlist as VodAsset[];
    });
}
export async function getSeriesFromEpisodes(episodes: string[]): Promise<VodAsset[]> {
  const seasons = await getContentDetail(episodes.join(','));
  const seasonIds = seasons ? seasons.filter((x) => x.fathervodlist).map((x) => x.fathervodlist[0].vodid) : undefined;
  const series = seasons ? await getContentDetail(seasonIds!.join(',')) : seasons;
  return series;
}

export function checkAssetPlayability(ids: string[]) {
  let requestList = ids.map((id) => {
    return {
      name: 'GetProductsByContent',
      param: {
        contentID: id,
        contentType: 'VOD',
        productType: '-1',
        isEST: '-1',
        count: '-1',
      },
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let executeBatches: Promise<any>[] = [];
  let iterations = Math.ceil(requestList.length / 10);
  for (let i = 0; i < iterations; i++) {
    executeBatches.push(
      fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
        method: 'POST',
        headers: { SessionTicket: getSessionTicket() },
        body: JSON.stringify({
          requestList: requestList.slice(i * 10, (i + 1) * 10),
        }),
      })
        .then(status)
        .then((r) => json(r as Response)),
    );
  }
  return Promise.all(executeBatches)
    .then((vodContentPrices) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let contentPricingByProduct: any[] = [];
      flatten(vodContentPrices).forEach((content) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        content.responseList.forEach((response: any) => {
          if (response && response.msg && response.msg.list && response.msg.list[0]) {
            contentPricingByProduct.push(response.msg.list[0]);
          } else {
            contentPricingByProduct.push(undefined);
          }
        });
      });
      return contentPricingByProduct;
    })
    .catch(fetchError);
}
