import './style.scss';

import {
  AltiboxAsset,
  AltiboxAssetDetailsType,
  AltiboxSeason,
  Option,
  Program,
  PvrRecording,
  SortingTypes,
  SortingValues,
  UnionAssetTypes,
  VodAsset,
} from '../../../../interfaces';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { assetIsProgram, assetIsPvr } from '../../../../typeGuards';
import { flatten, uniqBy } from 'lodash';

import { SeasonSelector } from './SeasonSelector';
import ViewList from './AltiboxAssetEpisodes/ListView';
import i18n from '../../../../i18n';
import {
  getAltiboxAssetDetailsType,
  getAltiboxAssetId,
  getAltiboxAssetPromotedId,
  isPvr as isPvrUtil,
} from '../../../../utils/altiboxassetUtils';
import { useAltiboxAsset } from '../../../../views/details/AltiboxAssetContext';
import { useHistory } from 'react-router';
import { arrayToMap } from '../../../../utils/commonUtils';
import { markRecordingAsDeleted } from '../../../../views/pvr/actions';
import { useDispatch } from 'react-redux';

export enum Filter {
  All,
  Pvr,
  Catchup,
}

export interface FilterState {
  previousValue: Filter.All | Filter.Pvr | Filter.Catchup;
  value: Filter.All | Filter.Pvr | Filter.Catchup;
  open: boolean;
}

export interface SortState {
  value: SortingValues | number;
  type: SortingTypes;
  open: boolean;
}

interface HistoryState {
  overwriteEpisodeToPromoteId?: string;
  reloadRecordings?: boolean;
}

interface AltiboxAssetSeriesProps {
  recordingDeleted?: PvrRecording | PvrRecording[];
}

const AltiboxAssetSeries: FC<AltiboxAssetSeriesProps> = ({ recordingDeleted }) => {
  const { isVodOrSvod, isCatchupOrPvr, isPvr, altiboxAsset, promotedAsset } = useAltiboxAsset();

  const history = useHistory<HistoryState>();
  const dispatch = useDispatch();

  const type = getAltiboxAssetDetailsType(altiboxAsset);

  const altiboxAssetSeasons = (altiboxAsset?.asset?.seasons as AltiboxSeason[]) ?? ([] as AltiboxSeason[]);
  const seasonMap = arrayToMap(altiboxAssetSeasons, (season) => season.seasonNumber);

  const hasEpisodeNumbers =
    isVodOrSvod || altiboxAssetSeasons[0].content.find((x: AltiboxAsset) => x.asset.hasOwnProperty('subNum'));

  const [filter, setFilter] = useState<FilterState>(
    isCatchupOrPvr
      ? {
          previousValue: Filter.All,
          value: isPvr ? Filter.Pvr : Filter.All,
          open: false,
        }
      : {
          previousValue: Filter.All,
          value: Filter.All,
          open: false,
        },
  );

  const [sort, setSort] = useState<SortState>({
    value: hasEpisodeNumbers ? SortingValues.EPISODE : SortingValues.DATE,
    type: SortingTypes.ASC,
    open: false,
  });

  const [deletedRecordings, setDeletedRecordings] = useState<string[]>([]);

  const [currentSeason, setCurrentSeason] = useState(
    getPromotedAssetSeasonNum(promotedAsset, altiboxAssetSeasons) || [...seasonMap.values()][0].seasonNumber,
  );
  const episodes = useMemo(
    () => filterAndSort(seasonMap.get(currentSeason)?.content || [], type, filter.value, sort, deletedRecordings),
    [currentSeason, deletedRecordings, filter.value, seasonMap, sort, type],
  );

  // Finds index or deleted recording and either increments or decrements the active episode
  useEffect(() => {
    if (recordingDeleted && episodes) {
      if (Array.isArray(recordingDeleted)) {
        setDeletedRecordings((prev) => {
          return [...prev, ...recordingDeleted.map((recording) => recording.pvrId)];
        });
        dispatch(markRecordingAsDeleted());
      } else {
        setDeletedRecordings((prev) => {
          return [...prev, recordingDeleted.pvrId];
        });

        dispatch(markRecordingAsDeleted());
      }
    }
  }, [dispatch, episodes, history, recordingDeleted]);

  function getFilterOptions() {
    const allEpisodes = flatten(
      Object.values(altiboxAsset.asset.seasons as AltiboxSeason[]).map((season) => season.content),
    );

    const catchupCount = filterEpisodes(allEpisodes, Filter.Catchup, deletedRecordings).length;
    const unfilteredCount = filterEpisodes(allEpisodes, Filter.All, deletedRecordings).length;

    return [
      {
        count: unfilteredCount,
        title: i18n.t<string>('all'),
        value: Filter.All,
        active: filter?.value === Filter.All,
      },
      {
        count: allEpisodes.length - catchupCount,
        title: i18n.t<string>('my recordings'),
        value: Filter.Pvr,
        active: filter?.value === Filter.Pvr,
      },
      {
        count: catchupCount,
        title: i18n.t<string>('programarchive'),
        value: Filter.Catchup,
        active: filter?.value === Filter.Catchup,
      },
    ].filter((option) => option.count > 0);
  }

  const handleFilterSelect = (option: Option) => {
    setFilter(
      (prev) =>
        prev && {
          open: false,
          value: option.value as Filter,
          previousValue: prev.value,
        },
    );
  };

  return (
    <div className="altibox-asset-content-container">
      <div className="altibox-asset-season-and-options-container">
        <SeasonSelector
          altiboxAsset={altiboxAsset}
          filter={filter}
          setFilter={setFilter}
          handleFilterSelect={handleFilterSelect}
          getFilterOptions={getFilterOptions}
          sort={sort}
          setSort={setSort}
          hasEpisodeNumbers={hasEpisodeNumbers}
          seasons={[...seasonMap.values()]}
          currentSeason={currentSeason}
          setCurrentSeason={setCurrentSeason}
          deletedRecordings={deletedRecordings}
        />
      </div>
      <div className={`altibox-asset-episodes`}>
        <ViewList episodeList={episodes || []} isFilteringByPvr={filter?.value === Filter.Pvr} />
      </div>
    </div>
  );
};

function sortPrograms(sort: SortState, a: AltiboxAsset, b: AltiboxAsset) {
  let aStartTime = '';
  let bStartTime = '';

  const aProgramAsset = a.asset as Program;
  const bProgramAsset = b.asset as Program;

  switch (sort.value) {
    case SortingValues.EPISODE:
      switch (sort.type) {
        case SortingTypes.ASC:
          return Number(aProgramAsset.subNum) - Number(bProgramAsset.subNum);
        case SortingTypes.DESC:
          return Number(bProgramAsset.subNum) - Number(aProgramAsset.subNum);
        default:
          return Number(aProgramAsset.subNum) - Number(bProgramAsset.subNum);
      }
    case SortingValues.DATE:
      aStartTime = isPvrUtil(aProgramAsset) ? (aProgramAsset as {} as PvrRecording).beginTime : aProgramAsset.starttime;
      bStartTime = isPvrUtil(bProgramAsset) ? (bProgramAsset as {} as PvrRecording).beginTime : bProgramAsset.starttime;
      switch (sort.type) {
        case SortingTypes.ASC:
          return Number(bStartTime) - Number(aStartTime);
        case SortingTypes.DESC:
          return Number(aStartTime) - Number(bStartTime);
        default:
          return Number(bStartTime) - Number(aStartTime);
      }
    default:
      return Number(bProgramAsset.subNum) - Number(aProgramAsset.subNum);
  }
}

function sortVods(sort: SortState, a: AltiboxAsset, b: AltiboxAsset) {
  const aVodAsset = a.asset as VodAsset;
  const bVodAsset = b.asset as VodAsset;

  switch (sort.type) {
    case SortingTypes.ASC:
      return Number(aVodAsset.sitcomnum) - Number(bVodAsset.sitcomnum);
    case SortingTypes.DESC:
      return Number(bVodAsset.sitcomnum) - Number(aVodAsset.sitcomnum);
    default:
      return Number(aVodAsset.sitcomnum) - Number(bVodAsset.sitcomnum);
  }
}

function sortAltiboxAssets(altiboxAssetType: string, sort: SortState, a: AltiboxAsset, b: AltiboxAsset) {
  switch (altiboxAssetType) {
    case AltiboxAssetDetailsType.CATCHUP:
    case AltiboxAssetDetailsType.PROGRAM:
    case AltiboxAssetDetailsType.PVR:
      return sortPrograms(sort, a, b);
    case AltiboxAssetDetailsType.SVOD:
    case AltiboxAssetDetailsType.VOD:
    case AltiboxAssetDetailsType.VIDEO_VOD:
      return sortVods(sort, a, b);
    default:
      return 0;
  }
}

function filterAndSort(
  episodes: AltiboxAsset[],
  type: string,
  filter: Filter,
  sort: SortState,
  deletedRecordings: string[],
) {
  const filtered = removeDuplicates(filterEpisodes(episodes, filter, deletedRecordings));
  return sortEpisodes(filtered, type, sort);
}

function sortEpisodes(episodes: AltiboxAsset[], type: string, sort: SortState) {
  return [...episodes].sort((a, b) => {
    return sortAltiboxAssets(type, sort, a, b);
  });
}

export function filterEpisodes(episodes: AltiboxAsset[], filter: Filter, deletedRecordings: string[]) {
  switch (filter) {
    case Filter.Pvr:
      return episodes.filter((episode) => {
        const assetId = getAltiboxAssetId(episode.asset);
        return assetIsPvr(episode.asset) && !deletedRecordings.includes(assetId);
      });
    case Filter.Catchup:
      return episodes.filter((episode) => assetIsProgram(episode.asset));
    case Filter.All:
      return episodes.filter((episode, _, array) => {
        if (assetIsPvr(episode.asset)) {
          return true;
        }

        return (
          array.find(
            (ep) =>
              (ep.asset as Program | PvrRecording).lifetimeId ===
                (episode.asset as Program | PvrRecording).lifetimeId && assetIsPvr(ep.asset),
          ) === undefined
        );
      });
  }
}

function removeDuplicates(episodes: AltiboxAsset[]) {
  return uniqBy(episodes, ({ asset }) => getAltiboxAssetPromotedId(asset));
}

function getPromotedAssetSeasonNum(promotedAsset: UnionAssetTypes, altiboxSeasons: AltiboxSeason[]) {
  const season = altiboxSeasons.find((altiboxSeason) => {
    return altiboxSeason.content.some((episode) => {
      return getAltiboxAssetId(episode.asset) === getAltiboxAssetId(promotedAsset);
    });
  });
  return season?.seasonNumber;
}

export default AltiboxAssetSeries;
