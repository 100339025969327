import OptionsModal from '../../components/OptionsModal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  deleteSeries,
  markSeriesForDeletion,
  MarkSeriesForDeletion,
  loadPVRSpace,
  deleteSeriesAndStopRecording,
} from './actions';
import {
  RootState,
  GAaction,
  OptionsModalContent,
  OptionType,
  PvrReducerState,
  DeleteType,
  PvrRecording,
  RecordingDeleteMode,
} from '../../interfaces';
import { getSeasonAndEpisodeLabel } from '../../utils/pvrUtils';
import i18n from '../../i18n';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';
import { updateSeriesRecording } from '../tv/actions';
import { routes } from '../../config';
import { recordingKeys } from '../../queries/recordings/keys';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { queryClient } from '../../queries/client';

type Props = {
  deleteSeries: (_: string) => void;
  hideModal: () => MarkSeriesForDeletion;
  loadPVRSpace: () => Promise<void>;
  updateSeriesRecording: (_: PvrRecording, _2: boolean, _3: RecordingDeleteMode) => void;
  deleteSeriesAndStopRecording: (_: string, _2: PvrRecording, _3: boolean, _4: RecordingDeleteMode) => void;
  modalHeroBackground?: string;
} & RouteComponentProps;

type State = OptionsModalContent;

class DeleteSeriesModal extends Component<Props, State> {
  tracking = AnalyticsTracking.getInstance();

  state: State = {
    modalIsOpen: false,
    title: '',
    subtitle: '',
    subtitle2: '',
    posttitle: '',
    closetext: i18n.t<string>('cancel'),
    options: [],
    onAction: this.onAction(),
    onClose: () => {
      this.props.hideModal();
    },
    error: false,
  };

  onAction() {
    return (selectedAction: OptionType) => {
      if (selectedAction.nextAction === 'confirm') {
        this.openConfirmModal(selectedAction);
      }

      if (selectedAction.nextAction === 'delete') {
        this.onDeleteAction(selectedAction);
      }
    };
  }

  trackEvent(selectedAction: OptionType) {
    if (selectedAction && selectedAction.series) {
      this.tracking.trackEvent(
        this.tracking.getCurrentCategory(),
        GAaction.recordDeleteSeries,
        selectedAction.series.channelName +
          ' - ' +
          selectedAction.series.periodPVRTaskName +
          ' - ' +
          selectedAction.series.latestPVRTask
          ? getSeasonAndEpisodeLabel(selectedAction.series.latestPVRTask as PvrRecording)
          : '',
      );
    }
  }

  onDeleteAction(selectedAction: OptionType) {
    if (!selectedAction || !selectedAction.series) {
      return;
    }

    if (selectedAction.value === DeleteType.EPISODES_AND_STOP_RECORDING) {
      this.props.deleteSeriesAndStopRecording(
        selectedAction.series.periodPVRTaskId,
        { ...selectedAction.series, seasons: undefined },
        true,
        undefined as unknown as RecordingDeleteMode,
      );
    } else {
      this.props.deleteSeries(selectedAction.series.periodPVRTaskId);
    }

    this.trackEvent(selectedAction);
    this.props.loadPVRSpace();

    this.props.history.push(routes.pvr.base);
    queryClient.invalidateQueries(recordingKeys.all);
  }

  openConfirmModal(selectedAction: OptionType) {
    if (!selectedAction || !selectedAction.series) {
      return;
    }

    switch (selectedAction.value) {
      case DeleteType.EPISODES:
        this.setState({
          title: `${i18n.t<string>('confirm deletion')}`,
          subtitle:
            i18n.t<string>('are you sure to delete series') +
            ` <strong>${selectedAction.series?.periodPVRTaskName}</strong>?`,
          modalIsOpen: true,
          options: [
            {
              title: `${i18n.t<string>('confirm')}`,
              value: DeleteType.EPISODES,
              series: selectedAction.series,
              nextAction: 'delete',
              type: 'secondary',
              style: '',
            },
          ],
        });
        break;
      case DeleteType.EPISODES_AND_STOP_RECORDING:
        this.setState({
          title: `${i18n.t<string>('confirm deletion')}`,
          subtitle:
            i18n.t<string>('are you sure to delete series') +
            ` <strong>${selectedAction.series?.periodPVRTaskName}</strong> ` +
            i18n.t<string>('and') +
            ` ${i18n.t<string>('cancel series recording').toLocaleLowerCase()}?`,
          modalIsOpen: true,
          options: [
            {
              title: `${i18n.t<string>('confirm')}`,
              value: DeleteType.EPISODES_AND_STOP_RECORDING,
              series: selectedAction.series,
              nextAction: 'delete',
              type: 'secondary',
              style: '',
            },
          ],
        });
        break;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props & PvrReducerState) {
    const showDeleteSeriesModal = nextProps.showDeleteSeriesModal;
    if (showDeleteSeriesModal && nextProps && nextProps.currentPvrSeries) {
      this.setState({
        title: i18n.t<string>('delete series'),
        subtitle: '',
        modalIsOpen: showDeleteSeriesModal,
        options: [
          {
            title: i18n.t<string>('delete episodes'),
            value: DeleteType.EPISODES,
            series: nextProps.currentPvrSeries,
            nextAction: 'confirm',
            type: 'primary',
            style: '',
          },
          {
            title: i18n.t<string>('delete and stop recording'),
            value: DeleteType.EPISODES_AND_STOP_RECORDING,
            series: nextProps.currentPvrSeries,
            nextAction: 'confirm',
            type: 'primary',
            style: '',
          },
        ],
      });
    } else {
      this.setState({
        modalIsOpen: false,
      });
      this.props.hideModal();
    }
  }

  render() {
    return (
      <OptionsModal
        {...this.state}
        modalHeroBackground={this.props.modalHeroBackground}
        customClass={'options-modal'}
        hideModal={this.props.hideModal}
      />
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => {
      return {
        showDeleteSeriesModal: state.pvrReducer.showDeleteSeriesModal,
        currentPvrSeries: state.pvrReducer.currentPvrSeries,
      };
    },
    (dispatch) => ({
      deleteSeries: (seriesId: string) => dispatch(deleteSeries(seriesId)),
      hideModal: () => dispatch(markSeriesForDeletion(false)),
      loadPVRSpace: () => dispatch(loadPVRSpace()),
      updateSeriesRecording: (recording: PvrRecording, canceled: boolean, deleteMode: RecordingDeleteMode) =>
        dispatch(updateSeriesRecording(recording, canceled, deleteMode)),
      deleteSeriesAndStopRecording: (
        seriesId: string,
        recording: PvrRecording,
        canceled: boolean,
        deleteMode: RecordingDeleteMode,
      ) => dispatch(deleteSeriesAndStopRecording(seriesId, recording, canceled, deleteMode)),
    }),
  )(DeleteSeriesModal),
);
