import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from '../../components/UI/Buttons';
import { getSvodAltiboxServiceUrl } from '../../utils/svodUtil';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';
import { SvgAccess } from '../../icons';
import style from './GetAccessContainer.module.scss';
import { GAaction, GAcategory, GAlabel } from '../../interfaces';
import { ScriptService } from '../../controllers/ScriptService';

const GetAccessContainer: React.FC = () => {
  const tracking = useRef<AnalyticsTracking | null>(null);
  const { t } = useTranslation();

  const bodyText = ScriptService.isDKUser()
    ? t('order streaming services dk users')
    : t('select streaming services with your points');
  const buttonText = ScriptService.isDKUser() ? t('order') : t('go to mine sider');

  useEffect(() => {
    tracking.current = AnalyticsTracking.getInstance();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.title_wrapper}>
        <h3>{t('how to get access')}</h3>
        <SvgAccess />
      </div>
      <div>{bodyText}</div>
      <a
        className={style.link_button}
        target="_blank"
        onClick={() =>
          tracking.current?.trackEvent(GAcategory.streamingServices, GAaction.openService, GAlabel.mineSider)
        }
        href={getSvodAltiboxServiceUrl()}
        rel="noreferrer"
      >
        <ButtonPrimary>{buttonText}</ButtonPrimary>
      </a>
    </div>
  );
};

export default GetAccessContainer;
