import { useTranslation } from 'react-i18next';
import { pvrSettings, routes } from '../../config';
import { Recording } from '../../queries/recordings/types';
import { getPercentWatched, groupIsSeries } from '../../queries/recordings/utils';
import { BadgeTypes } from '../Badge';
import { isDeleteModeManual } from '../../utils/pvrUtils';
import { HorizontalAsset } from './HorizontalAsset';
import recordingBrand from '../../icons/recording.svg';

interface PvrAssetProps {
  group: Recording.Group.Union;
  showProgress?: boolean;
  playOnHover?: boolean;
  showChannel?: boolean;
}

export function PvrAsset({ group, showProgress, playOnHover, showChannel = true }: PvrAssetProps) {
  const { t } = useTranslation(['pvr', 'translations']);

  function getLink(type: 'details' | 'play') {
    if (playOnHover && type === 'play') {
      return groupIsSeries(group)
        ? `${routes.pvr.base}${routes.pvr.series}${routes.pvr.play}/${group.episodes[0].channelId}/${group.seriesId}/${group.episodes[0].pvrId}`
        : `${routes.pvr.base}${routes.pvr.single}${routes.pvr.play}/${group.channel?.contentId}/${group.pvrId}`;
    }

    return groupIsSeries(group)
      ? `${routes.pvr.base}${routes.pvr.series}/${group.episodes[0].channelId}/${group.seriesId}`
      : `${routes.pvr.base}${routes.pvr.single}/${group.pvrId}`;
  }

  function getTitle() {
    if (groupIsSeries(group)) {
      const episode = group.episodes[0];

      if (episode.seasonNum && episode.subNum) {
        return showProgress ? [`${group.pvrName}`, `S${episode.seasonNum} E${episode.subNum}`] : [group.pvrName];
      }

      return [group.pvrName];
    }

    return [group.pvrName];
  }

  function getGenre() {
    if (group.genreNames) {
      return group.genreNames[0];
    }

    return t('movie', { ns: 'translations' });
  }

  function getBadgeText() {
    const daysLeft = group.lowestExpirationTime;
    const limit = pvrSettings.expiration;

    if (group.unwatchedCount >= 1) {
      return t('new', { count: group.unwatchedCount });
    }

    if (!daysLeft || (daysLeft > limit && daysLeft >= 0)) {
      return undefined;
    }

    if (!daysLeft) {
      return '';
    }

    if (daysLeft <= 2) {
      return `${Math.floor(daysLeft * 24)} ${t('rent hours suffix', { ns: 'translations' })} ${t('time remaining', {
        ns: 'translations',
      })}`;
    }

    return `${Math.floor(daysLeft)} ${t('day', { count: 2, ns: 'translations' })} ${t('time remaining', {
      ns: 'translations',
    })}`;
  }

  function getBadge() {
    const badgeText = getBadgeText();

    if (!badgeText) {
      return;
    }

    if (group.unwatchedCount >= 1) {
      return {
        text: badgeText,
        type: BadgeTypes.New,
      };
    }

    return {
      text: badgeText,
      type: BadgeTypes.Expires,
    };
  }

  function getSubtitle() {
    return groupIsSeries(group) && !showProgress ? `${t('episodes', { count: group.episodes.length })}` : getGenre();
  }

  function getBrand() {
    if (!showChannel || !group.channel) {
      return {
        src: recordingBrand,
        alt: 'Recording',
      };
    }

    return {
      src: group.channel.pictures[1].href ?? group.channel.pictures[0].href,
      alt: group.channel?.name ?? '',
    };
  }

  const title = getTitle();

  const showPin = groupIsSeries(group)
    ? isDeleteModeManual(group.episodes[0].deleteMode)
    : isDeleteModeManual(group.asset.deleteMode);

  const progress = groupIsSeries(group) ? getPercentWatched(group.episodes[0]) : getPercentWatched(group.asset);

  if (progress > 97) {
    return null;
  }

  return (
    <HorizontalAsset
      titles={title}
      subtitle={getSubtitle()}
      link={[getLink('play'), getLink('details')]}
      progress={showProgress ? progress : undefined}
      image={{
        src: group.pictures?.[0].href,
        alt: title.join(' - '),
      }}
      badge={getBadge()}
      brand={getBrand()}
      playOnHover={playOnHover}
      showPin={showPin}
    />
  );
}

export function PvrAssetSeeAll({ slug }: { slug: string }) {
  return <HorizontalAsset isSeeAll link={`${routes.pvr.base}/${slug}`} />;
}

export function PvrAssetSkeleton() {
  return <HorizontalAsset isSkeleton showSubtitle />;
}
