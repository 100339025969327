import shaka from 'shaka-player';
import { StreamType, StreamData } from '../../../interfaces';
import { getSessionTicket, getWidevineLicenseServerUrl } from '../../../config';
import { DashPlayerUtil } from './PlayerHelper';

var showDebug = localStorage.getItem('debug') === 'true' ? true : false;

export function setupWidevineDrm(
  player: shaka.Player,
  streamData: StreamData,
  streamType: StreamType,
  programStart: string,
  cappingLevel: number,
) {
  let customData = streamData.customData;
  let licenseUrl = getWidevineLicenseServerUrl();

  // Shaka v3.x-patch: We need to update the config ourselves by merging the old one with the new additions, since Shaka v3.0 broke it :(
  let newConfig = {
    drm: {
      servers: {
        'com.widevine.alpha': licenseUrl,
      },
      advanced: {
        'com.widevine.alpha': {
          videoRobustness: 'SW_SECURE_CRYPTO',
          audioRobustness: 'SW_SECURE_CRYPTO',
        },
      },
    },
  };

  if (streamType === StreamType.LIVE) {
    /* !!!! IMPORTANT !!!!
      Shaka restricts timeshiftBufferDepth, so we have to override it.
      This generates a list of valid segments. Our live manifests have 
      2 second segments. The default shaka config is 1000, so we only get ~33,33 minutes timeshift
      Setting the config to 10800 seconds (3 hours * 60 mins * 60 seconds) which should generate a 
      segment list that goes 10800 * 2 seconds into the timeshift (which equals 6 hours).
      TODO: Adjust accordingly based on segment length and timeshift depth.
      The formula should be (in seconds):
      initialSegmentLimit: timeShiftDepth / segmentLength;
      */
    newConfig = Object.assign({}, newConfig, {
      manifest: {
        dash: {
          initialSegmentLimit: 10800,
        },
      },
    });
  }

  player.configure(Object.assign({}, player.getConfiguration(), newConfig));

  const shakaNetworkingEngine = player.getNetworkingEngine();
  if (shakaNetworkingEngine !== null) {
    shakaNetworkingEngine.clearAllRequestFilters();
    shakaNetworkingEngine.clearAllResponseFilters();

    // for widevine we need to push customData and Session Token to the header in the license call
    shakaNetworkingEngine.registerRequestFilter(
      (requestType: shaka.net.NetworkingEngine.RequestType, request: shaka.extern.Request) => {
        if (requestType === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          request.headers['AcquireLicense.CustomData'] = customData;
          request.headers.SessionTicket = getSessionTicket();
        }
      },
    );

    // manipultate the MPD so faulty streams play, also apply capping for given VOD streams
    shakaNetworkingEngine.registerResponseFilter(
      (requestType: shaka.net.NetworkingEngine.RequestType, response: shaka.extern.Response) => {
        let data;
        if (response.data) {
          data = new Uint8Array(response.data as ArrayBuffer);
        } else {
          return;
        }
        if (requestType === shaka.net.NetworkingEngine.RequestType.MANIFEST) {
          switch (streamType) {
            case StreamType.LIVE:
              response.data = DashPlayerUtil.applyLiveFilter(data);
              break;
            case StreamType.PVR:
            case StreamType.CATCHUP:
              response.data = DashPlayerUtil.applyProgramFilter(data, programStart);
              break;
            case StreamType.VOD:
              response.data = DashPlayerUtil.applyVODFilter(data, cappingLevel);
              break;
          }

          if (showDebug) {
            console.log('This manifest is sent to player:');
            console.log(new TextDecoder('utf-8').decode(response.data));
          }
        }
      },
    );
  }
}
