import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes } from '../../../config';
import { Program, RootState } from '../../../interfaces';

interface CatchupButtonsProps extends Pick<Program, 'id' | 'name' | 'channel'> {
  markers: JSX.Element;
  externalProgramButton: JSX.Element | null;
  channelIsLocked: boolean;
  canCatchup: boolean | undefined;
  trackProgram(channel: string, name: string): void;
}

/**
 * If we can catchup and the channel is not locked *and* the user is not a guest, show the catchup button and the external program button
 *
 * If we can't catchup but we have an external program button *and* we're not a guest, show the external program button
 *
 * If we can't catchup and we don't have an external program button, only show the markers
 */
export function ProgramMarkers({
  id,
  name,
  channel,
  channelIsLocked,
  markers,
  trackProgram,
  externalProgramButton,
  canCatchup,
}: CatchupButtonsProps) {
  const isGuest = useSelector((state: RootState) => state.authReducer.isGuest);
  const catchupURL = `${routes.programarchive.base}${routes.programarchive.play}/${id}`;

  if (!channel) {
    return null;
  }

  return (
    <>
      {markers}
      <div className="catchup-button">
        {canCatchup && !channelIsLocked && !isGuest ? (
          <>
            <li>
              <Link
                to={{ pathname: catchupURL, state: { prevPath: window.location.pathname } }}
                onClick={() => trackProgram(channel.name, name)}
              >
                z
              </Link>
            </li>
            {externalProgramButton ? <li>{externalProgramButton}</li> : null}
          </>
        ) : externalProgramButton && !isGuest ? (
          <li>{externalProgramButton}</li>
        ) : null}
      </div>
    </>
  );
}
