import { useSelector } from 'react-redux';
import { RootState, ProgramToRecord } from '../../../interfaces';
import './style.scss';
import i18n from '../../../i18n';
import ProgramRecordingModal from '../PvrEditRecordingModal';
import { IconButtonRecording } from '../../../components/UI/Buttons';
import { getRecording } from '../../../utils/pvrUtils';
import { useState } from 'react';

interface Props {
  programData: ProgramToRecord | undefined;
  seriesId: string;
  channelId: string;
  lock?: boolean;
  modalHeroBackground?: string;
  hideButtonText?: boolean;
}

export default function PvrRecordingButton({
  programData,
  seriesId,
  channelId,
  modalHeroBackground,
  hideButtonText,
}: Props) {
  const [isRecordingModalOpen, setIsRecordingModalOpen] = useState(false);

  const seriesRecordings = useSelector((state: RootState) => state.channelsReducer.seriesRecordings);
  const singleRecordings = useSelector((state: RootState) => state.channelsReducer.singleRecordings);

  const recording = getRecording(
    seriesRecordings,
    singleRecordings,
    seriesId,
    channelId,
    programData?.id,
    programData?.lifetimeId,
  );

  const hasStartTime = programData && programData.starttime;

  function getButtonText() {
    return !hasStartTime || recording !== undefined ? i18n.t<string>('change') : i18n.t<string>('record');
  }

  if (!programData) {
    return null;
  }

  return (
    <>
      <ProgramRecordingModal
        modalHeroBackground={modalHeroBackground}
        modalVisible={isRecordingModalOpen}
        hideModal={() => setIsRecordingModalOpen(false)}
        programData={programData}
        seriesId={seriesId}
        channelId={channelId}
      />
      <IconButtonRecording active={recording !== undefined} onClick={() => setIsRecordingModalOpen(true)}>
        {hideButtonText ? null : getButtonText()}
      </IconButtonRecording>
    </>
  );
}
