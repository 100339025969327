import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ChannelTvGuide from '..';
import { useInView } from '../../../hooks/useInView';
import { Channel, ChannelLogoType, Program, RootState, TVGuideChannelFilter } from '../../../interfaces';
import { useToggleFavoriteChannel } from '../../../queries/channels/mutations';
import { usePlaybillsMap } from '../../../queries/playbills/queries';
import channelPermissions from '../../../utils/channelPermissions';
import { filterPlaybills, getChannelLogoUrl } from '../../../utils/tvUtils';

interface ChannelTvGuideGroupProps {
  channels: Channel[];
  genreFilter: string[];
  timeFilter: number;
  channelsFilter: TVGuideChannelFilter;
  currentDate: Date;
}

export function ChannelTvGuideGroup({
  channels,
  genreFilter,
  timeFilter,
  channelsFilter,
  currentDate,
}: ChannelTvGuideGroupProps) {
  const auth = useSelector((state: RootState) => state.authReducer);

  const { ref, inView } = useInView<HTMLDivElement>({
    root: null,
    rootMargin: '0px',
    threshold: 0,
    // triggerOnce: true,
  });

  const channelIds = useMemo(() => channels.map((c) => c.contentId), [channels]);

  const {
    data: playbills,
    isLoading,
    isSuccess,
    isError,
  } = usePlaybillsMap({
    channelIds,
    date: currentDate,
    enabled: inView,
  });

  const { mutate: toggleFavoriteChannel } = useToggleFavoriteChannel();

  function getIsFilterActive() {
    const isTvGuideGenreFilterActive = !isEmpty(genreFilter);
    const isTimepickerFilterActive = timeFilter !== 0;

    return isTvGuideGenreFilterActive || isTimepickerFilterActive;
  }

  function getIsLocked(channel: Channel) {
    const permission = channelPermissions(channel, auth);
    return permission.locked;
  }

  function shouldShowFavorite(channel: Channel) {
    return !(channelsFilter === TVGuideChannelFilter.FAVORITE && channel.isfavorited !== '1');
  }

  function shouldRenderChannel(channel: Channel, playbillsToShow: Program[]) {
    if (isEmpty(playbills) && getIsFilterActive()) {
      return false;
    }

    switch (channelsFilter) {
      case TVGuideChannelFilter.ALL:
        return true;
      case TVGuideChannelFilter.MINE:
        if (!channel.channelPermissions.notInSubscription) {
          return true;
        }
        break;
      case TVGuideChannelFilter.FAVORITE:
        if (channel.isfavorited) {
          return true;
        }
        break;
      default:
        return false;
    }
  }

  function renderChannelHeader({ contentId, name, isfavorited, pictures }: Channel, isLocked: boolean) {
    const logo = getChannelLogoUrl({ pictures }, ChannelLogoType.CROPPED);

    return (
      <div className="logo-container">
        <Link to={`/tv/${contentId}`}>
          <img className="logo noselect" alt={name} src={logo} />
          <div className="channel-name">{name}</div>
        </Link>

        {isLocked ? <div className="aib-icon lock-icon noselect">p</div> : null}

        {auth.loggedInWithCredentials && (
          <span
            onClick={() => toggleFavoriteChannel({ channelId: contentId, isFavorited: isfavorited === '1' })}
            className={isfavorited === '1' ? 'aib-icon favorite' : 'aib-icon non-favorite'}
          >
            {isfavorited === '1' ? `M` : `Ɏ`}
          </span>
        )}
      </div>
    );
  }

  function isToday() {
    return moment(currentDate).isSame(moment(), 'day');
  }

  function renderGroup() {
    if ((isLoading || !isSuccess) && !isError) {
      return channels.map((channel) => (
        <div className="playbill-item-container" key={channel.chanNo + channel.name}>
          {renderChannelHeader(channel, getIsLocked(channel))}
          <div className="playbill-item-skeleton" style={{ height: isToday() ? '606px' : '400px' }}></div>
        </div>
      ));
    }

    return channels.map((channel) => {
      const playbillsForChannel = playbills?.get(channel.contentId);
      const playbillsToShow = filterPlaybills(playbillsForChannel, genreFilter, timeFilter, currentDate);

      if (!shouldRenderChannel(channel, playbillsToShow) || !shouldShowFavorite(channel)) {
        return null;
      }

      const isLocked = getIsLocked(channel);

      return (
        <div className="playbill-item-container" key={channel.chanNo + channel.name}>
          {renderChannelHeader(channel, isLocked)}
          <ChannelTvGuide
            channel={channel}
            currentDate={currentDate}
            channelIsLocked={isLocked}
            playbills={playbillsToShow ? playbillsToShow : []}
            categoryFilterActive={getIsFilterActive()}
          />
        </div>
      );
    });
  }

  return (
    <div className="channel-tv-guide-group" ref={ref}>
      {renderGroup()}
    </div>
  );
}
