import { flatten } from 'lodash';
import { getAltiboxApiUrl, getBaseUrl, getSessionTicket } from '../config';
import { fetchError, json, status } from '../controllers/Fetch';
import { forgeRock } from '../controllers/ForgeRock';
import {
  Account,
  AccountType,
  AltiboxSubscriber,
  ContentDetailResponse,
  ContentDetailsRequestBody,
  FavouriteResponse,
  GetFavoriteRequestBody,
  Partner,
  QueryProgramSeriesRequestBody,
  QueryProgramSeriesResponse,
  LoginAliasResponseData,
} from '../interfaces';
import { UbiSecure } from '../controllers/UbiSecure';

export function huaweiAPI<Res, Body = undefined>(path: string, body?: Body, options: RequestInit = {}): Promise<Res> {
  const defaults: RequestInit = {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify(body),
  };

  return fetch(getBaseUrl() + path, { ...defaults, ...options })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function getFavorites(body: GetFavoriteRequestBody): Promise<FavouriteResponse> {
  return huaweiAPI<FavouriteResponse, GetFavoriteRequestBody>('/EPG/JSON/GetFavorite', body);
}

export function getContentDetails(body: ContentDetailsRequestBody): Promise<ContentDetailResponse> {
  return huaweiAPI<ContentDetailResponse, ContentDetailsRequestBody>('/EPG/JSON/ContentDetail', body);
}

export function queryProgramSeries(body: QueryProgramSeriesRequestBody): Promise<QueryProgramSeriesResponse> {
  return huaweiAPI<QueryProgramSeriesResponse, QueryProgramSeriesRequestBody>('/EPG/JSON/QueryProgramSeries', body);
}

export function altiboxAPI<Res, Body = undefined>(path: string, body?: Body, options: RequestInit = {}): Promise<Res> {
  const defaults: RequestInit = body
    ? {
        body: typeof body === 'string' ? body : JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    : {
        method: 'GET',
      };

  const requestInit: RequestInit = {
    ...defaults,
    ...options,
    headers: {
      ...defaults.headers,
      ...options.headers,
    },
  };

  return fetch(getAltiboxApiUrl() + path, requestInit)
    .then((res) => res.json())
    .then((res) => {
      if (res.status && res.status !== 'success') {
        throw res;
      }

      if (res.data) {
        return res.data;
      }

      if (res.value) {
        return res.value;
      }

      return res;
    });
}

export function getAccounts(accessToken: string): Promise<Account[]> {
  return altiboxAPI<Account[], string>('authentication/account', `token=${accessToken}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getUserData(accessToken: string, id: string): Promise<LoginAliasResponseData> {
  return altiboxAPI<LoginAliasResponseData, string>('authentication/token', `id=${id}`, {
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  });
}

export function getAltiboxSubscribers(sessionTicket: string): Promise<AltiboxSubscriber[]> {
  return altiboxAPI<AltiboxSubscriber[]>('tv/subscribers', undefined, { headers: { sessionTicket } });
}

export function getAccountType(sessionTicket: string): Promise<AccountType> {
  return altiboxAPI<{ type: AccountType }>('customer/account/type', undefined, {
    headers: { sessionTicket },
  })
    .then((r) => r.type)
    .catch(() => 'NOT_AUTHORIZED');
}

export async function getPartners(): Promise<Partner[]> {
  const countryCodes = ['no', 'dk'];

  const partners = await Promise.all(
    countryCodes.map((code) => altiboxAPI<Partner[]>(`partner/partners?countryCode=${code}`)),
  );

  return flatten(partners);
}

export async function getAccessToken(code: string): Promise<string> {
  const loginMethod = localStorage.getItem('login_method');
  if (loginMethod === 'forgerock') {
    return forgerockToken(code);
  }
  if (loginMethod === 'ubi') {
    return ubiToken(code);
  }
  return '';
}

async function forgerockToken(code: string): Promise<string> {
  const { login_url, client_id, redirect_uri } = forgeRock();
  const codeVerifier = localStorage.getItem('codeVerifier');
  try {
    const res = await fetch(
      `${login_url}am/oauth2/alpha/access_token?client_id=${client_id}&grant_type=authorization_code&code=${code}&redirect_uri=${redirect_uri}&code_verifier=${codeVerifier}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    const data = await res.json();
    // Stored in local storage for use in log out function
    localStorage.setItem('id_token', data.id_token);
    return data.access_token;
  } catch (error) {
    console.log(error);
    return '';
  }
}

async function ubiToken(code: string): Promise<string> {
  const Ubi = UbiSecure();
  const redirectUrl = encodeURIComponent(Ubi.redirect_url);

  return fetch(Ubi.url + Ubi.token_url, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    body:
      `grant_type=authorization_code&` +
      `redirect_uri=${redirectUrl}&` +
      `code=${code}&` +
      `client_id=${Ubi.client_id}&` +
      `client_secret=${Ubi.client_secret}`,
  })
    .then((r) => r.json())
    .then((r) => r.access_token);
}
