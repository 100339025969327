import { useEffect, useRef } from 'react';
import { useConfirm } from '../../hooks/useConfirm';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface FormWrapperProps extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  children: React.ReactNode;
  selectionMode: boolean;
  shouldOpenModal: boolean;
  openModal: () => void;
  onCancel: () => void;
}

export function FormWrapper({
  children,
  selectionMode,
  shouldOpenModal,
  openModal,
  onCancel,
  ...rest
}: FormWrapperProps) {
  const { windowWidth } = useWindowDimensions();
  const { isConfirmed } = useConfirm();

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (!selectionMode) {
      formRef.current = null;
    }
  }, [selectionMode]);

  async function handleCancel() {
    const confirmed = await isConfirmed({
      prompt: 'Er du sikker på at du vil avbryte sletting av markerte opptak?',
    });

    if (confirmed) {
      onCancel();
    }
  }

  function setInitialFocus() {
    if (formRef.current) {
      (formRef.current[0] as HTMLFormElement).focus();
    }
  }

  return selectionMode ? (
    <form
      ref={(el) => {
        if (!formRef.current) {
          formRef.current = el;
          setInitialFocus();
        }
      }}
      onSubmit={(e) => {
        e.preventDefault();
        if (shouldOpenModal) {
          openModal();
        }
      }}
      onKeyDown={(e) => handleFormKeyDown(e, windowWidth, handleCancel)}
      {...rest}
    >
      {children}
    </form>
  ) : (
    <section onKeyDown={(e) => handleSectionKeyDown(e, windowWidth)}>{children}</section>
  );
}

function handleSectionKeyDown(event: React.KeyboardEvent<HTMLElement>, windowWidth: number) {
  const children = (event.target as HTMLElement).parentElement?.parentElement?.children;

  if (!children) {
    return;
  }

  const list = [...children].map((el) => el.firstChild) as HTMLElement[];
  const index = list.indexOf(event.target as HTMLElement);

  switch (event.key) {
    case 'ArrowRight':
      list[(index + 1) % list.length].focus();
      return event.preventDefault();
    case 'ArrowLeft':
      list[(index - 1 + list.length) % list.length].focus();
      return event.preventDefault();
    case 'ArrowUp':
      handleFocusUp(list, index, windowWidth);
      return event.preventDefault();
    case 'ArrowDown':
      handleFocusDown(list, index, windowWidth);
      return event.preventDefault();
    default:
      return;
  }
}

/**
 * Controls focus with arrow keys for a 3 grid form
 * @param {React.KeyboardEvent<HTMLFormElement>} event
 * @param {number} windowWidth
 */
export function handleFormKeyDown(
  event: React.KeyboardEvent<HTMLFormElement>,
  windowWidth: number,
  onCancel: () => void,
) {
  const form = (event.target as HTMLFormElement).form;
  const index = [...form].indexOf(event.target);

  switch (event.key) {
    case 'ArrowRight':
      form.elements[(index + 1) % form.elements.length].focus();
      return event.preventDefault();
    case 'ArrowLeft':
      form.elements[(index - 1 + form.elements.length) % form.elements.length].focus();
      return event.preventDefault();
    case 'ArrowUp':
      handleFocusUp(form.elements, index, windowWidth);
      return event.preventDefault();
    case 'ArrowDown':
      handleFocusDown(form.elements, index, windowWidth);
      return event.preventDefault();
    case 'Escape':
      onCancel();
      return event.preventDefault();
    default:
      return;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleFocusUp(elements: any, index: number, windowWidth: number) {
  if (windowWidth >= 1024) {
    return elements[(index - 3 + elements.length) % elements.length].focus();
  }

  if (windowWidth >= 768) {
    return elements[(index - 2 + elements.length) % elements.length].focus();
  }

  if (windowWidth > 0) {
    return elements[(index - 1 + elements.length) % elements.length].focus();
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleFocusDown(elements: any, index: number, windowWidth: number) {
  if (windowWidth >= 1024) {
    return elements[(index + 3) % elements.length].focus();
  }

  if (windowWidth >= 768) {
    return elements[(index + 2) % elements.length].focus();
  }

  if (windowWidth > 0) {
    return elements[(index + 1) % elements.length].focus();
  }
}
