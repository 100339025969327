import { status, fetchError, thirdParyCookiesResetter } from '../controllers/Fetch';
import { ScriptService } from '../controllers/ScriptService';
import {
  getAltiboxApiUrl,
  getDeviceName,
  getBaseUrl,
  setBaseUrl,
  getDeviceDisplayName,
  getMigrationUrl,
  getSubnetId,
  V6Url,
  setSessionTicket,
  getSessionTicket,
} from '../config';
import {
  ApiAuthResponse,
  HuaweiSubscriber,
  HeartBitResponse,
  LoginResponse,
  AltiboxNetworkResponse,
  AltiboxApiAuthenticateResponse,
  AltiboxApiSiteResponse,
  HuaweiAuthenticateResponse,
  Device,
  GAaction,
  GAcategory,
  DRM,
  AccountWithExtraData,
  AibHuaweiNonPaceSubscriber,
  Account,
} from '../interfaces';
import moment from 'moment';
import { Base64 } from 'js-base64';
import HuaweiErrors from '../controllers/HuaweiErrors';
import AnalyticsTracking from '../controllers/AnalyticsTracking';
import { setPrevPath, goToPrevPath } from '../utils/appUtils';
import { UbiSecure } from '../controllers/UbiSecure';
import {
  getAccessToken,
  getAccounts,
  getAccountType,
  getAltiboxSubscribers as getSubscribers,
  getUserData,
  getPartners,
} from '.';
import { isEmpty } from 'lodash';
import { subscriberIsNotPace } from '../typeGuards';
import { forgeRock } from '../controllers/ForgeRock';
import { generateCodeChallengeFromVerifier, generateCodeVerifier } from '../utils/authUtils';
import { getForgerockLanguage } from '../i18n/languageMap';

const IPCheckIntervalInSeconds = 60;
export const HUAWEI_SUCCESS_RETCODE = '0';

let IPCheckInterval: NodeJS.Timer;
var terminalid = 'WEBTV';
var authUrl = '/EPG/JSON/AltiboxAuthenticate';
var loginToken: string;
let aibSessionTicket: string;
let justLoggedInWithPasswordAndUsername: boolean = false;

const Ubi = UbiSecure();

const tracking = AnalyticsTracking.getInstance();

function getTerminalType() {
  if (ScriptService._isSafari()) {
    return 'iphone_fairplay';
  } else {
    return 'WEBTV';
  }
}

function getStreamVersion() {
  if (ScriptService._isMicrosoftEdge() || ScriptService._isIE()) {
    return DRM.PLAYREADY;
  } else if (ScriptService._isSafari()) {
    return DRM.FAIRPLAY;
  } else {
    return DRM.WIDEVINE;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function json(response: any) {
  if (!response.json) {
    return response;
  } else {
    return response.json();
  }
}

function reAuthenticate(): Function {
  return () => authenticate();
}

// will authenticate on startup
function authenticate() {
  return getNetworkStatus()
    .then(function (isInside) {
      if (hasRequiredLocalStorageData()) {
        return authenticateWithAuthToken().catch(() => {
          return fallbackLogin(isInside as boolean);
        });
      } else {
        return fallbackLogin(isInside as boolean);
      }
    })
    .then((authResponse: ApiAuthResponse) => {
      startHeartBeatInterval();
      return authResponse;
    });
}

async function fallbackLogin(isInside: boolean) {
  if (isInside) {
    return authenticateByIp(isInside).then(checkIPLoginResponse);
  } else if (hasAibSessionTicket()) {
    // if user has one, it has been authorized once before just let it in
    return loginAsInternetCustomer();
  } else {
    // Let the crawler crawl as guest
    if (navigator.userAgent.includes('Googlebot')) {
      return await authenticateAsGuest();
    }
    // huawei says no - try aib login or guest
    return await aibIPloginOrGuestLogin();
  }
}

function hasAibSessionTicket() {
  return localStorage.getItem('sessionticket');
}

async function aibIPloginOrGuestLogin() {
  return getIpUserInfoFromAibApi().then((auth: AltiboxApiSiteResponse) => {
    if (auth.status === 'failure') {
      return authenticateAsGuest();
    } else {
      return loginAsInternetCustomerByIp();
    }
  });
}

function loginAsInternetCustomerByIp() {
  return loginAsInternetCustomer(true);
}

function loginAsInternetCustomer(byIp: boolean = false, sessionTicket?: string) {
  const tmpCustomerName = localStorage.getItem('customername');
  const tmpSessionTicket = sessionTicket ?? localStorage.getItem('sessionticket');
  return authenticateAsGuest().then((huaweiAuth) => {
    if (tmpCustomerName) {
      localStorage.setItem('customername', tmpCustomerName);
      (huaweiAuth as ApiAuthResponse).isInternetOnly = true;

      if (byIp) {
        (huaweiAuth as ApiAuthResponse).isInternetOnlyAuthByIp = true;
      } else {
        (huaweiAuth as ApiAuthResponse).isInternetOnlyAuthByCreds = true;
        if (tmpSessionTicket) {
          localStorage.setItem('sessionticket', tmpSessionTicket);
        }
      }
    }
    return huaweiAuth as ApiAuthResponse;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function checkIPLoginResponse(response: any) {
  // there is no free NotEST device
  if (response.retcode === HuaweiErrors.NO_FREE_NON_EST_SLOT) {
    let errorMessage = 'ERROR: ' + response.retcode + ' Message: ' + response.retmsg;
    tracking.trackEvent(GAcategory.error, GAaction.noFreeNonESTSlot, errorMessage);
    console.error('NO_FREE_NON_EST_SLOT');
    return authenticateAsGuest();
  } else if (response.retcode !== '0') {
    let errorMessage = 'ERROR: ' + response.retcode + ' Message: ' + response.retmsg;
    console.error(response.retcode);
    tracking.trackEvent(GAcategory.error, GAaction.loginError, errorMessage);
    return authenticateAsGuest();
  }
  return response;
}

function setAltiboxUserData(firstName: string, lastName: string, alias: string) {
  localStorage.setItem('customername', firstName + ' ' + lastName);
  localStorage.setItem('currentuser', alias);
}

// Look for the TODO comments in this file
// This is an ugly hack to quickly add types without rewriting the entire login codebase.
// THIS SHOULD BE REWRITTEN.
export function createAuthResponse(fields: object): ApiAuthResponse {
  return {
    loginIP: '',
    location: '',
    country: '',
    isInside: '',
    retmsg: '',
    retcode: '',
    areaid: '',
    usergroup: '',
    epgurl: '',
    ca: {
      widevine: {
        LA_Url: '',
      },
    },
    sessionid: '',
    currenttime: '',
    parameters: {},
    users: {},
    userID: '',
    currentuser: '',
    errorCode: '',
    desc: '',
    deviceId: '',
    deviceName: '',
    success: true,
    accounts: undefined,
    message: undefined,
    bossID: '',
    ...fields,
  };
}

interface HuaweiAuthResponse {
  value: HuaweiSubscriber[];
}

// calls altibox API and get Huawei token
function generateHuaweiTokenSubscribers(aibSessionToken: string, siteId?: string) {
  let mac = siteId ? 'CHROMECAST' + siteId : 'WEBTV' + getMacFromLocalStorage();
  return fetch(getAltiboxApiUrl() + 'authentication/huawei', {
    method: 'POST',
    headers: {
      SessionTicket: aibSessionToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      encryptToken: loginToken,
      terminalIp: getTerminalType(),
      mac: mac,
    }),
  })
    .then(status)
    .then(json)
    .then(function (authData: HuaweiAuthResponse) {
      return authData.value;
    })
    .catch(fetchError);
}

async function logout(forceFrontpage?: boolean) {
  AnalyticsTracking.getInstance().trackEvent(
    AnalyticsTracking.getInstance().getCurrentCategory(),
    GAaction.login,
    'Logged out',
  );

  cleanUpLocalStorage();
  await fetch(getBaseUrl() + '/EPG/JSON/Logout', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      type: 1,
    }),
  });
  const redirect = forceFrontpage ? window.location.origin : window.location.href;

  if (localStorage.getItem('login_method') === 'forgerock') {
    const { logout_endpoint } = forgeRock();

    try {
      const fgToken = localStorage.getItem('fgAccessToken');
      const res = await fetch(logout_endpoint, {
        method: 'POST',
        headers: { token: fgToken! },
      });
      await res.json();
    } catch (e) {
      console.error(e);
    } finally {
      localStorage.removeItem('fgAccessToken');
      window.location.reload();
    }
  } else {
    window.location.href = Ubi.url + Ubi.logout_url + '?returnurl=' + redirect;
  }
}

interface ReplaceTimes {
  remainTimes: string;
  totalTimes: string;
}

function swapDevice(device: Device) {
  return fetch(getBaseUrl() + '/EPG/JSON/ReplaceDevice', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      userid: getItemFromLocalStorage('user'),
      orgDeviceId: device.value,
      destDeviceId: getDeviceName(),
      isEST: 1,
    }),
  })
    .then(status)
    .then(json)
    .then(() => {
      return authenticateWithAuthToken();
    })
    .then(function (data: ApiAuthResponse) {
      let deviceIdToChangeName: string;
      return (
        getDeviceData(data.userID)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((devices: any): any => {
            devices.deviceList.forEach(function (deviceItem: Device) {
              if (deviceItem.physicalDeviceId === getTerminalType() + getMacFromLocalStorage()) {
                deviceIdToChangeName = deviceItem.value;
              }
            });
            return deviceIdToChangeName;
          })
          .then(function (deviceId: string) {
            return fetch(getBaseUrl() + '/EPG/JSON/ModifyDeviceName', {
              method: 'POST',
              headers: { SessionTicket: getSessionTicket() },
              body: JSON.stringify({
                deviceid: deviceId,
                deviceName: getDeviceDisplayName() + ' ' + moment().format('L'),
              }),
            })
              .then(status)
              .then(json)
              .then(function () {
                goToPrevPath();
              });
          })
      );
    })
    .catch(fetchError);
}

interface DeviceListResponse {
  deviceList: Device[];
}
interface DeviceDataDevice {
  title: string;
  value: string;
  physicalDeviceId: string;
}
export interface DeviceData {
  deviceList: DeviceDataDevice[];
  replaceTimes: ReplaceTimes;
}

export function clearOldNonEsts() {
  getNonEst().then((data: DeviceListResponse) => {
    if (data.deviceList) {
      data.deviceList.forEach((device: Device) => {
        if (device.isonline && device.isonline === '0') {
          releaseSlot(device);
        }
      });
    }
  });
}

export function getNonEst() {
  return fetch(getBaseUrl() + '/EPG/JSON/GetDeviceList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      deviceType: 2, // OTT device
      isEST: 1, // only get NON-EST devices
      userid: getItemFromLocalStorage('user'),
    }),
  })
    .then(status)
    .then(json)
    .then(function (data: DeviceListResponse) {
      return data;
    });
}

export function getEst() {
  return fetch(getBaseUrl() + '/EPG/JSON/GetDeviceList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      deviceType: 2, // OTT device
      isEST: 0, // only get EST devices
      userid: getItemFromLocalStorage('user'),
    }),
  })
    .then(status)
    .then(json)
    .then(function (data: DeviceListResponse) {
      return data;
    });
}

export function releaseSlot(device: Device) {
  return fetch(getBaseUrl() + '/EPG/JSON/ReplaceDevice', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      userid: getItemFromLocalStorage('user'),
      orgDeviceId: device.deviceId,
      isEST: 0,
    }),
  });
}

export function releaseESTSlot(device: Device) {
  return fetch(getBaseUrl() + '/EPG/JSON/ReplaceDevice', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      userid: getItemFromLocalStorage('user'),
      orgDeviceId: device.value,
      isEST: 1,
    }),
  });
}

function getDeviceData(userID?: string) {
  return fetch(getBaseUrl() + '/EPG/JSON/GetDeviceList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      deviceType: 2, // OTT device
      isEST: 0, // only get EST devices
      userid: userID ? userID : getItemFromLocalStorage('user'),
    }),
  })
    .then(status)
    .then(json)
    .then(function (data: DeviceListResponse) {
      if (data.deviceList) {
        return data.deviceList.map((device: Device) => {
          return {
            title: device.deviceName ? device.deviceName : 'Unknown(' + device.physicalDeviceId + ')',
            value: device.deviceId,
            physicalDeviceId: device.physicalDeviceId,
          } as DeviceDataDevice;
        });
      } else {
        return [];
      }
    })
    .then((deviceList) => {
      return fetch(getBaseUrl() + '/EPG/JSON/GetReplaceTimes', {
        method: 'POST',
        headers: { SessionTicket: getSessionTicket() },
        body: JSON.stringify({
          subscriberId: getItemFromLocalStorage('user'),
        }),
      })
        .then(status)
        .then(json)
        .then(function (replaceTimes: ReplaceTimes) {
          return {
            deviceList: deviceList,
            replaceTimes: replaceTimes,
          } as DeviceData;
        });
    })
    .catch(fetchError);
}

async function loginToHuaweiWithCredentials(
  subscriberId: string,
  authToken: string,
  profilePin: string,
  platform: string,
): Promise<ApiAuthResponse> {
  localStorage.setItem('subscriberId', subscriberId);
  if (!platform || platform === 'PACE') {
    // TODO: This should be a reject so we can return a different type in the result
    // (so we can avoid having to use this ugly hack) #yolofix
    return Promise.resolve(
      createAuthResponse({
        retmsg: 'platform: PACE',
        retcode: 'wrong_platform',
        success: false,
      }),
    );
  } else {
    // save for later usage
    saveItemInLocalStorage('usercred', profilePin);
    saveItemInLocalStorage('authToken', authToken);
    saveItemInLocalStorage('user', subscriberId);
    await initSessionToHuawei();
    return setCCToken(subscriberId).then(() => {
      return authenticateWithAuthToken();
    });
  }
}

async function getLoginUrlBasedOnSubscriber(subscriberId: string | null) {
  const edsLoginUrl = `/EDS/jsp/AuthenticationURL?Action=Login&return_type=2&UserID=${subscriberId}`;
  let backendUrl = getMigrationUrl();

  // send .dk users directly to v6
  if (ScriptService.isDKUser()) {
    backendUrl = V6Url;
  }
  return await fetch(backendUrl + edsLoginUrl)
    .then(status)
    .then(json)
    .then(function (loginData: LoginResponse) {
      return loginData.epghttpsurl;
    })
    .catch(fetchError);
}

// call huawei platform for initial cookie setup
async function initSessionToHuawei() {
  const subscriberId = localStorage.getItem('subscriberId');
  let epgLogin = '/EPG/JSON/Login' + (subscriberId ? '?UserId=' + subscriberId : '');
  let migratedBaseUrl = await getLoginUrlBasedOnSubscriber(subscriberId);
  return doHuaweiLoginCall(migratedBaseUrl + epgLogin);
}

async function doHuaweiLoginCall(loginUrl: string) {
  return await fetch(loginUrl)
    .then(status)
    .then(json)
    .then((loginData: LoginResponse) => {
      setLoginToken(loginData.enctytoken);
      setBaseUrl(loginData.epghttpsurl);
      return Promise.resolve();
    })
    .catch(fetchError);
}

function setLoginToken(token: string) {
  loginToken = token;
}

// will check if we are inside or not
function getNetworkStatus() {
  return initSessionToHuawei().then(getNetWork);
}

function getNetWork() {
  return fetch(getBaseUrl() + '/EPG/JSON/GetAltiboxNetwork', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
  })
    .then(status)
    .then(json)
    .then(function (networkDetail: AltiboxNetworkResponse) {
      return networkDetail.isInside === '1';
    })
    .catch(fetchError);
}

function switchProfile(authDetail: ApiAuthResponse) {
  // if no user is present in localstorage
  // someone deleted localstorage or did not select
  // location when multiple
  if (!getItemFromLocalStorage('user')) {
    logout(); // logout will do a loction.reload
    return authDetail;
  } else {
    return fetch(getBaseUrl() + '/EPG/JSON/SwitchProfile', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        id: getItemFromLocalStorage('user'),
        password: getItemFromLocalStorage('usercred'),
      }),
    })
      .then(status)
      .then(json)
      .then((switchData) => {
        authDetail.currentuser = localStorage.getItem('currentuser') || '';

        if (authDetail.errorCode) {
          throw authDetail.desc;
        }
        return authDetail;
      });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function authenticateWithAuthToken(): any {
  clearIPCheckInterval();
  return huaweiAuthenticateWithToken()
    .then((authResponse: ApiAuthResponse) => {
      if (authResponse.retcode === HUAWEI_SUCCESS_RETCODE) {
        return authResponse;
      } else if (authResponse.retcode === HuaweiErrors.NO_FREE_EST_SLOT && !justLoggedInWithPasswordAndUsername) {
        logout();
      }
      return Promise.reject(authResponse);
    })
    .then(renameDeviceIfNeeded)
    .then((r) => switchProfile(r as ApiAuthResponse))
    .catch(fetchError);
}

function huaweiAuthenticateWithToken() {
  var terminalType = getTerminalType();
  return fetch(getBaseUrl() + authUrl, {
    method: 'POST',
    body: JSON.stringify({
      authType: '1', // third-party authentication system = 0, authtoken = 1
      authenticator: getItemFromLocalStorage('authToken'),
      mac: 'WEBTV' + getMacFromLocalStorage(),
      terminalid: terminalid,
      terminaltype: terminalType,
      userId: getItemFromLocalStorage('user'),
      userType: '1',
      caDeviceInfo: [
        {
          caDeviceType: getStreamVersion(),
          caDeviceId: terminalType,
        },
      ],
    }),
  })
    .then(status)
    .then(json)
    .then((authResponse: ApiAuthResponse) => {
      const { jSessionID, cSessionID } = authResponse;
      if (jSessionID && cSessionID) {
        setSessionTicket(jSessionID, cSessionID);
      }
      return authResponse;
    });
}

function renameDeviceIfNeeded(authDetail: ApiAuthResponse) {
  if (!authDetail.deviceName) {
    return fetch(getBaseUrl() + '/EPG/JSON/ModifyDeviceName', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        deviceid: authDetail.deviceId,
        deviceName: getDeviceDisplayName() + ' ' + moment().format('L'),
      }),
    })
      .then(status)
      .then(json)
      .then(() => {
        return authDetail;
      })
      .catch(fetchError);
  } else {
    return authDetail;
  }
}

// starts ip checker, will check if one leaves IP/home
function startIPchecker() {
  // checking IP every 10 minutes: 60ms * 10000ms = 600000ms = 600s = 10min
  IPCheckInterval = setInterval(checkIfOnIpElseLogout, IPCheckIntervalInSeconds * 10000);
}

// will be cleared on guest and u/p login
function clearIPCheckInterval() {
  if (IPCheckInterval) {
    clearInterval(IPCheckInterval);
  }
}

function checkIfOnIpElseLogout() {
  // Only check if browser tab is active to reduce amount of getAltiboxNetwork calls
  // Check for active tab.
  if (document && !document.hidden) {
    getNetWork().then(function (isInside) {
      if (!isInside) {
        logout();
      }
    });
  }
}

function getSiteId(aibData: AltiboxApiSiteResponse) {
  if (aibData && aibData.value[0]) {
    let site = aibData.value[0];
    // todo always get the one with the lowest siteId - do to non-est-slots hogging
    return site.siteId;
  } else {
    return '';
  }
}

function authenticateByIp(isInside: boolean) {
  return getIpUserInfoFromAibApi()
    .then((aibData: AltiboxApiSiteResponse) => {
      const shouldLoginByIp = isInside || (aibData.status === 'success' && aibData.value.length > 0);

      let theMac;
      if (isInside && aibData.status === 'failure') {
        theMac = getMacFromLocalStorage();
      } else {
        theMac = getMac(getSiteId(aibData));
      }

      if (shouldLoginByIp) {
        var terminalType = getTerminalType();
        return fetch(getBaseUrl() + authUrl, {
          method: 'POST',
          body: JSON.stringify({
            authType: 0, // third-party authentication system(by IP) = 0, authtoken = 1
            mac: theMac,
            terminaltype: terminalType,
            terminalid: terminalid,
            caDeviceInfo: [
              {
                caDeviceType: getStreamVersion(),
                caDeviceId: terminalType,
              },
            ],
          }),
        })
          .then(status)
          .then(json)
          .then((authResponse: ApiAuthResponse) => {
            clearIPCheckInterval();
            startIPchecker();
            const { jSessionID, cSessionID } = authResponse;
            if (jSessionID && cSessionID) {
              setSessionTicket(jSessionID, cSessionID);
            }
            return authResponse;
          })
          .catch(authenticateAsGuest)
          .catch(fetchError);
      } else {
        // cannot get aib data fail back to guest
        return authenticateAsGuest();
      }
    })
    .catch((enterpriseLoginByIpError) => {
      // Almost always an enterprise customer trying to log in by IP.
      tracking.trackEvent(GAcategory.error, enterpriseLoginByIpError);
      return authenticateAsGuest();
    });
}

// starts heartbeat, will call again based on backend call interval
function startHeartBeatInterval() {
  doHeartBeat().then(function (response) {
    response = response as HeartBitResponse;

    if (response.nextcallinterval) {
      let nextInterval = parseInt(response.nextcallinterval, 10) * 1000;

      setTimeout(function () {
        startHeartBeatInterval();
      }, nextInterval);
    }
  });
}

// will hold the session alive
function doHeartBeat() {
  return fetch(getBaseUrl() + '/EPG/JSON/HeartBit', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
  })
    .then(status)
    .then(json)
    .then(function (response: HeartBitResponse) {
      if (response.retcode && response.retcode !== HUAWEI_SUCCESS_RETCODE) {
        throw response.retcode;
      }
      return Promise.resolve(response);
    })
    .then(thirdParyCookiesResetter)
    .catch(fetchError);
}

function getServiceLocation(sessionTicket: string) {
  return fetch(getAltiboxApiUrl() + 'customer/servicelocations', {
    method: 'GET',
    headers: {
      SessionTicket: sessionTicket,
    },
  })
    .then(status)
    .then(json)
    .then((aibSiteResponse: AltiboxApiSiteResponse) => {
      return aibSiteResponse;
    });
}

function getIpUserInfoFromAibApi() {
  return (
    fetch(getAltiboxApiUrl() + 'authentication/authenticate?method=BY_IP_ADDRESS', {
      method: 'GET',
    })
      .then(status)
      .then(json)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then(function (authenticateData: AltiboxApiAuthenticateResponse): any {
        if (authenticateData.status === 'success') {
          aibSessionTicket = authenticateData.data.sessionTicket.identifier;
          return checkAliasProfile(aibSessionTicket)
            .then((response) => {
              if (response.type === 'CUSTOMER_PRIVATE') {
                if (authenticateData.data.user) {
                  let user = authenticateData.data.user;
                  localStorage.setItem('customername', user.firstName + ' ' + user.lastName);
                }
                return getServiceLocation(aibSessionTicket);
              } else {
                return Promise.resolve({ status: 'failure' } as AltiboxApiSiteResponse);
              }
            })
            .then((subscribersData: AltiboxApiSiteResponse) => {
              return Promise.resolve(subscribersData);
            })
            .catch(fetchError);
        } else {
          return authenticateData;
        }
      })
      .catch(fetchError)
  );
}

async function authenticateAsGuest() {
  clearIPCheckInterval();
  var authenticateUrl = '/EPG/JSON/Authenticate';
  cleanUpLocalStorage();

  return fetch(getBaseUrl() + authenticateUrl, {
    method: 'POST',
    body: JSON.stringify({
      authType: 0,
      userType: 3, // guest type
      terminaltype: getTerminalType(),
      subnetId: getSubnetId(),
    }),
  })
    .then(status)
    .then(json)
    .then(function (authDetail: HuaweiAuthenticateResponse) {
      if (authDetail.errorCode) {
        throw authDetail.desc;
      }

      const { jSessionID, cSessionID } = authDetail;
      if (jSessionID && cSessionID) {
        setSessionTicket(jSessionID, cSessionID);
      }

      return Promise.resolve(authDetail);
    })
    .catch(() => {
      return fetchError;
    });
}

function getMacFromLocalStorage() {
  let MAC = localStorage.getItem('MAC');
  if (!MAC) {
    MAC = '' + Date.now();
    localStorage.setItem('MAC', MAC);
  }
  return MAC;
}

function generateRandom(): string {
  let rand = Math.floor(Math.random() * 10 + 1);
  return rand === parseInt(localStorage.getItem('rand') || '1', 10) ? generateRandom() : `${rand}`;
}

// when logging in inside,
// just allow max 10 slots
// generate ipMac 1-10 random number
// users get new ipMac on every reload
// append siteId to make MAC unique
// possibly will logout other users on same IP,
// but this is the only "for now" solution, for not filling up slots
// offline slots are unbinded on u/p login
function getMac(siteId: string) {
  let rand = generateRandom();
  localStorage.setItem('rand', rand);
  return getDeviceName() + '-' + siteId + '-' + rand;
}

function hasRequiredLocalStorageData() {
  if (getItemFromLocalStorage('authToken')) {
    if (!localStorage.getItem('customername') || !localStorage.getItem('currentuser')) {
      cleanUpLocalStorage();
      return false;
    }
    return true;
  }
  return false;
}

function cleanUpLocalStorage() {
  localStorage.removeItem('customername');
  localStorage.removeItem('currentuser');
  localStorage.removeItem('analytics-token');
  localStorage.removeItem('pvrleadtime');
  localStorage.removeItem('pvrlagtime');
  localStorage.removeItem('sessionticket');
  localStorage.removeItem('cc');

  // remove old settings from earlier releases - not used
  localStorage.removeItem('ipMAC');
  localStorage.removeItem('web');
  localStorage.removeItem('customerid');
  localStorage.removeItem('subscriberId');
}

/**
 * just to obfuscate username / passowrd / authToken
 * TODO: if we were to rewrite login, use altibox session and make users login
 * when altibox session token expires. This way we do not store this in localstorage.
 */
function getLocalStorageJson() {
  let store = '{ "you":"HACKER!"}';

  try {
    if (localStorage.getItem('analytics-token')) {
      store = atob(atob(localStorage.getItem('analytics-token') || ''));
    }
  } catch (err) {
    cleanUpLocalStorage();
    window.location.reload();
  }
  return JSON.parse(store);
}

function saveItemInLocalStorage(key: string, value: string) {
  let _json = getLocalStorageJson();
  _json[key] = value;
  localStorage.setItem('analytics-token', btoa(btoa(JSON.stringify(_json))));
}

export function getItemFromLocalStorage(key: string) {
  let _json = getLocalStorageJson();
  return _json[key];
}

async function getAlias(_aibSessionTicket: string) {
  return await fetch(getAltiboxApiUrl() + 'authentication/alias', {
    headers: {
      SessionTicket: _aibSessionTicket,
      'Content-Type': 'application/json',
    },
  })
    .then(status)
    .then(json)
    .then((_response) => {
      if (_response.status === 'success') {
        return _response.value;
      } else {
        return 'Unknown';
      }
    });
}

export async function loginInternetCustomer(account: AccountWithExtraData): Promise<ApiAuthResponse> {
  tracking.trackCurrentService(GAaction.login, 'internet only login');

  const alias = await getAlias(account.sessionTicket);
  const firstName = account.user.firstName ?? '';
  const lastName = account.user.lastName ?? '';

  setAltiboxUserData(firstName, lastName, alias);
  return loginAsInternetCustomer(false, account.sessionTicket);
}

function setCCToken(subscriberId: string) {
  const sessionTicket = aibSessionTicket ? aibSessionTicket : localStorage.getItem('sessionticket') || '';
  // generate a chromecast huawei token to simulate login
  return getServiceLocation(sessionTicket)
    .then((aibData) => {
      return generateHuaweiTokenSubscribers(sessionTicket, getSiteId(aibData)).then(saveTokenToLocalStorage);
    })
    .catch(() => {
      // Retry without service location.
      // Users who are no longer customers have no service location, but should be able to log in and watch bought films.
      return generateHuaweiTokenSubscribers(sessionTicket).then(saveTokenToLocalStorage);
    });

  function saveTokenToLocalStorage(data: string | HuaweiSubscriber[]) {
    let location = (data as HuaweiSubscriber[]).find((x) => x.subscriberId === subscriberId);
    if (location) {
      localStorage.setItem('cc', Base64.encode(JSON.stringify(location)));
    }
  }
}

function getAibHeaders(sessionTicket: string) {
  return {
    SessionTicket: sessionTicket,
    // todo : at some point we need to use accessToken
    // 'Authorization': 'Bearer ' + accessToken,
    'Content-Type': 'application/json',
  };
}

function checkAliasProfile(sessionTicket: string) {
  return fetch(getAltiboxApiUrl() + 'customer/account/type', {
    headers: getAibHeaders(sessionTicket),
  })
    .then(status)
    .then(json);
}

async function goToLogin() {
  setPrevPath();
  const loginMethod = localStorage.getItem('login_method');
  if (!loginMethod) {
    window.location.href = ubiLoginUrl();
  }
  if (loginMethod === 'ubi') {
    window.location.href = ubiLoginUrl();
  }
  if (loginMethod === 'forgerock') {
    const loginUrl = await forgerockLoginUrl();
    window.location.href = loginUrl;
  }
}

function ubiLoginUrl() {
  const ubiRandom = '' + Math.floor(Math.random() * 100000000);
  localStorage.setItem('ubiRandom', ubiRandom);
  const isDK = ScriptService.isDKUser();

  const ubiUrl = new URL(Ubi.url);

  ubiUrl.pathname = 'uas/oauth2/authorization';

  ubiUrl.searchParams.append('response_type', 'code');
  ubiUrl.searchParams.append('client_id', Ubi.client_id);
  ubiUrl.searchParams.append('state', ubiRandom);
  ubiUrl.searchParams.append('redirect_uri', Ubi.redirect_url);
  ubiUrl.searchParams.append('scope', 'openid ' + Ubi.api_client_id);
  if (isDK) {
    ubiUrl.searchParams.append('locale', 'dk');
  }
  return ubiUrl.toString();
}

async function forgerockLoginUrl() {
  // Generate a random verifier
  const codeVerifier = generateCodeVerifier();

  // Generate codeChallenge with SHA-256 of verifier, then Base64 URL encode the result
  let codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);

  // Store global variables in localstorage
  localStorage.setItem('codeVerifier', codeVerifier);

  const { login_url, client_id, redirect_uri } = forgeRock();

  const foregrockUrl = new URL(login_url);

  foregrockUrl.pathname = 'am/oauth2/alpha/authorize';

  // Add query parameters
  foregrockUrl.searchParams.append('client_id', client_id);
  foregrockUrl.searchParams.append('response_type', 'code');
  foregrockUrl.searchParams.append('scope', 'openid profile email infonova');
  foregrockUrl.searchParams.append('redirect_uri', redirect_uri);
  foregrockUrl.searchParams.append('code_challenge', codeChallenge);
  foregrockUrl.searchParams.append('code_challenge_method', 'S256');
  foregrockUrl.searchParams.append('locale', getForgerockLanguage(navigator.language));
  foregrockUrl.searchParams.append('theme', 'Altibox');
  return foregrockUrl.toString();
}

async function handleAibLoginCallback(inputUrl: string): Promise<AccountWithExtraData[]> {
  const parameters = new URL(inputUrl).searchParams;
  const code = parameters.get('code');
  const state = parameters.get('state');
  const ubiRandom = localStorage.getItem('ubiRandom');

  // check if the state is the same as we started with
  if (state === ubiRandom && code !== null) {
    localStorage.removeItem('ubiRandom');
    try {
      await initSessionToHuawei();
      const accessToken = await getAccessToken(code!);
      localStorage.setItem('fgAccessToken', accessToken);
      const accounts = await getAccounts(accessToken);
      const partners = await getPartners().catch(() => {
        // Partnerinfo is not important in this case as it is only used to display partner names
        // if a user has accounts from different partners.
        return [];
      });

      let accountsWithExtraData = (await getExtraAccountData(accounts, accessToken)).map((account) => {
        const partner = partners.find((_partner) => _partner.partnerId === account.partnerId);
        return { ...account, partner };
      });

      if (isEmpty(accountsWithExtraData)) {
        return Promise.reject(createAuthResponse({ success: false, retcode: 'MISSING_ACCOUNT_DATA' }));
      }

      accountsWithExtraData = accountsWithExtraData.filter((account) => account.type === 'CUSTOMER_PRIVATE');

      if (isEmpty(accountsWithExtraData)) {
        return Promise.reject(createAuthResponse({ retcode: 'CUSTOMER_ENTERPRISE' }));
      }

      justLoggedInWithPasswordAndUsername = true;

      return accountsWithExtraData;
    } catch (error) {
      return Promise.reject(createAuthResponse({ success: false, message: error }));
    }
  } else {
    localStorage.removeItem('ubiRandom');
    return Promise.reject({ status: 'failure', message: 'Initial state not same as received' });
  }
}

async function getExtraAccountData(accounts: Account[], accessToken: string): Promise<AccountWithExtraData[]> {
  return (
    await Promise.all(
      accounts.map(async (account) => {
        const { sessionTicket, user } = await getUserData(accessToken, account.id);

        return Promise.all([
          generateHuaweiTokenSubscribers(sessionTicket.identifier).catch(() => {
            // This occasionally fails (in test-environments anyways) for some accounts that are not activated in Prov,
            // Ignore with empty array to keep things running
            return [];
          }),
          getSubscribers(sessionTicket.identifier),
          getAccountType(sessionTicket.identifier),
          Promise.resolve(sessionTicket.identifier),
          Promise.resolve(user),
          Promise.resolve(account),
        ]);
      }),
    )
  ).map((data) => {
    const [_huaweiSubscribers, _altiboxSubscribers, type, sessionTicket, user, account] = data;
    const subscribers = _altiboxSubscribers
      .filter(subscriberIsNotPace)
      .map((altiboxSubscriber) => {
        const huaweiSubscriber = _huaweiSubscribers.find(
          (_huaweiSubscriber) => _huaweiSubscriber.subscriberId === altiboxSubscriber.username,
        );

        if (!huaweiSubscriber) {
          return undefined;
        }

        return { ...altiboxSubscriber, ...huaweiSubscriber };
      })
      .filter((sub) => typeof sub !== 'undefined') as AibHuaweiNonPaceSubscriber[];

    return { type, user, sessionTicket, subscribers, ...account };
  });
}

async function loginToAccount(account: AccountWithExtraData, subscriber: AibHuaweiNonPaceSubscriber) {
  const alias = await getAlias(account.sessionTicket);
  const firstName = account.user.firstName ?? '';
  const lastName = account.user.lastName ?? '';

  setAltiboxUserData(firstName, lastName, alias);
  localStorage.setItem('sessionticket', account.sessionTicket);
  localStorage.setItem('subscriberId', subscriber.subscriberId);

  await initSessionToHuawei();

  return setCCToken(subscriber.subscriberId).then(() =>
    loginToHuaweiWithCredentials(
      subscriber.subscriberId,
      subscriber.authToken,
      subscriber.profilePin,
      subscriber.platform,
    ),
  );
}

export {
  handleAibLoginCallback,
  goToLogin,
  cleanUpLocalStorage,
  reAuthenticate,
  authenticate,
  logout,
  getDeviceData,
  swapDevice,
  loginToAccount,
};
