import React, { Component, ReactNode } from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  RootState,
  ApiAuthResponse,
  VodConfig,
  VodBatchListResponse,
  FilterKeys,
  Filter,
  PortalMenu,
  VodConfigData,
  Genre,
  VodAssetDisplayMode,
  PromotionActionType,
  VodAsset,
  SearchConfig,
  VodConfigDataType,
  GenreTypes,
  HeaderDisplayType,
  HeaderInteractionType,
  AltiboxAssetDisplayTypes,
  GAaction,
  VodSection,
} from '../../../interfaces';

import Header from '../../app/Header';
import VodPromotionBanner from '../../../components/VodPromotionBanner/index';
import { getCurrentLangTitle } from '../../../utils/vodUtils';
import { routes } from '../../../config';
import { fetchFrontPageVodLists, clearSvodSelection } from '../actions/index';
import { getSvodUrl, getSvodAsset } from '../../../utils/svodUtil';
import { getContentDetailFromAibId } from '../../../api/vod';
import CategoryHelmet from '../../../components/Helmets/CategoryHelmet';
import Footer from '../../../components/Footer/index';
import { setSelectedVodGenre } from '../../../views/vod/actions';
import { clearOriginLocation } from '../../../views/app/actions';
import AltiboxAssetViewList from '../../../components/UI/AltiboxAssetViewList';
import StickyHeader from '../../../views/app/StickyHeader';
import Spinner from '../../../components/Spinner';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import AltiboxGenreBanner from '../../../components/AltiboxGenreBanner/index';
import MiniSvodFrontPage from './MiniSvodFrontPage';
import SvodInlinePromotion from '../../../components/SvodInlinePromotion';

interface Props extends RouteComponentProps<{}, {}, { toTop?: boolean }> {
  auth: ApiAuthResponse | undefined;
  isGuest: boolean;
  lists: VodBatchListResponse[] | undefined;
  svodConfig: VodConfig | undefined;
  selectedGenre: GenreTypes | undefined;
  defaultImagePath: string;
  genres: Genre[];
  searchConfig: SearchConfig;
  svodKiosk: PortalMenu | undefined;
  svodKiosks: PortalMenu[];
  myContent?: VodAsset[];
  clearOriginLocation: () => { type: symbol; originLocation: string };
  setSelectedGenre: (genreType: GenreTypes) => Promise<void>;
  getLists: (config: VodConfigData[], genre: Genre[], stripLength: number) => Promise<void>;
  clearSvodSelection: () => Promise<void>;
  loggedInWithCredentials: boolean;
}

interface State {
  stripLength: number;
  redirectUrl: string;
  showLoading: boolean;
  startedLoading: boolean;
  promotions: VodSection[];
}

class FrontPage extends Component<Props, State> {
  state: State = {
    redirectUrl: '',
    stripLength: 15,
    showLoading: false,
    startedLoading: false,
    promotions: [],
  };

  // Strict Class Initialization ignored
  loadingTimer!: NodeJS.Timer;
  tracking = AnalyticsTracking.getInstance();

  get customSearchConfig() {
    let svodSearchConfig = this.props.searchConfig;
    svodSearchConfig.baseService = window.location.pathname;
    return svodSearchConfig;
  }

  componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state.toTop) {
      window.scrollTo(0, 0);
    }
    this.props.clearOriginLocation();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const svodData = nextProps.svodConfig?.data.find((el) => el.title.en === 'Frontpage');
    const promotions = svodData?.sections.filter((el) => el.type === 'promotion');

    if (promotions) {
      this.setState({ promotions });
    }

    if (nextProps.svodKiosk !== this.props.svodKiosk) {
      this.setState({
        startedLoading: false,
      });
    }

    if (nextProps.lists === undefined) {
      this.setState({
        showLoading: true,
      });
    } else {
      clearTimeout(this.loadingTimer);
      this.loadingTimer = setTimeout(() => {
        this.setState({
          showLoading: false,
        });
      }, 200);
    }

    if (!this.state.startedLoading && nextProps.svodConfig && nextProps.genres) {
      this.setState(
        {
          startedLoading: true,
        },
        () => {
          this.props.getLists(nextProps.svodConfig!.data, nextProps.genres, this.state.stripLength);
        },
      );
    }
  }

  bannerClicked = (index: number) => {
    const { svodConfig, svodKiosk } = this.props;
    if (svodConfig && !isEmpty(svodConfig.promotions) && svodConfig.promotions[index]) {
      const { actionType, platformId, link } = svodConfig.promotions[index];
      this.tracking.trackEvent(
        this.tracking.getCurrentCategory(),
        GAaction.bannerInteraction,
        actionType + ' - ' + platformId,
      );

      switch (actionType) {
        case PromotionActionType.MOVIE_DETAILS: {
          // If deeplink exists, navigate user to streaming service.
          if (link) {
            window.location.href = link;
          }
          getContentDetailFromAibId(platformId)
            .then((assets) => {
              this.setState({
                redirectUrl:
                  `${routes.svod.base}/${getSvodUrl(svodKiosk)}${routes.svod.details}` +
                  `/${(assets as VodAsset).externalContentCode}`,
              });
            })
            .catch(() => {
              console.error('Asset not found');
            });
          break;
        }
        case PromotionActionType.CATEGORY: {
          this.setState({
            redirectUrl: `${routes.svod.base}/${getSvodUrl(svodKiosk)}${routes.svod.category}/` + platformId,
          });
          break;
        }
      }
    }
  };

  getViewsBasedOnVodConfig(lists: VodBatchListResponse[]) {
    if (lists) {
      let vodAssetListToShow = lists.map((assets: VodBatchListResponse, index: number) => {
        if (index === 0) {
          return null;
        }
        return this.getSingleAssetList(assets, index);
      });
      return vodAssetListToShow;
    }
    return null;
  }

  getFirstRow(lists: VodBatchListResponse[]) {
    if (lists) {
      return this.getSingleAssetList(lists[0], 0);
    }
    return null;
  }

  getSingleAssetList(assets: VodBatchListResponse, index: number) {
    let link =
      `${routes.svod.base}/${getSvodUrl(this.props.svodKiosk!)}${routes.svod.category}` +
      `/${assets.section.categoryId}/${assets.section.sorting}`;

    if (assets.section.filter) {
      assets.section.filter.forEach((filter: Filter) => {
        if (filter.key === FilterKeys.GENRE_IDS) {
          link += '/' + filter.value.replace(/\s/g, '');
        }
      });
    }

    const list = assets.msg.vodlist
      ? assets.msg.vodlist.map((asset) => {
          return getSvodAsset(asset, this.props.svodKiosks, this.props.defaultImagePath, true);
        })
      : [];

    if (list.length === 0) {
      return null;
    }

    return (
      <AltiboxAssetViewList
        key={index + '-vodlist'}
        assets={list}
        title={getCurrentLangTitle(assets.section.title)}
        displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
        vodDisplayMode={VodAssetDisplayMode.SVOD}
        titleLink={link}
        loggedInWithCredentials={this.props.loggedInWithCredentials}
        myContent={this.props.myContent}
      />
    );
  }

  getMovieGenres = () => {
    return this.props.svodConfig!.data.find((x) => x.type === VodConfigDataType.MOVIE_GENRE);
  };

  getSeriesGenres = () => {
    return this.props.svodConfig!.data.find((x) => x.type === VodConfigDataType.SERIES_GENRE);
  };

  onGenreSelected = (genreSelected: GenreTypes) => {
    this.props.setSelectedGenre(genreSelected);
  };

  wrapContent(children: ReactNode) {
    if (!this.props.svodKiosk) {
      return null;
    }

    const title = getCurrentLangTitle(this.props.svodKiosk.titles);

    return (
      <div className="main-frame">
        <CategoryHelmet categoryTitle={'Forside'} title={this.props.svodKiosk.titles?.nb ?? ''} />
        <StickyHeader searchConfig={this.customSearchConfig} />
        <Header
          title={title}
          displayType={HeaderDisplayType.Gradient}
          interactionType={HeaderInteractionType.Full}
          hideTitle={true}
        />
        {children}
        <Footer />
      </div>
    );
  }

  render() {
    if (!this.props.svodKiosk) {
      return null;
    }

    if (this.state.redirectUrl) {
      this.props.history.push(this.props.location.pathname);
      return (
        <Redirect
          to={{
            pathname: this.state.redirectUrl,
            state: { prevPath: window.location.pathname },
          }}
        />
      );
    }

    return this.wrapContent(
      this.props.svodConfig && this.props.auth && isEmpty(this.props.svodConfig.promotions) ? (
        <MiniSvodFrontPage
          svodKiosk={this.props.svodKiosk}
          defaultImagePath={this.props.defaultImagePath}
          loggedInWithCredentials={this.props.loggedInWithCredentials}
        />
      ) : (
        <>
          {this.props.svodConfig && this.props.auth ? (
            <VodPromotionBanner
              bannerClicked={this.bannerClicked}
              promotions={this.props.svodConfig.promotions}
              imagePath={this.props.defaultImagePath}
              randomPlay={true}
              centerMode={false}
            />
          ) : null}

          {!this.state.showLoading ? (
            this.props.svodConfig && this.props.auth ? (
              <React.Fragment>
                <div className="svod-frontpage">
                  {this.props.lists ? this.getFirstRow(this.props.lists) : null}

                  <AltiboxGenreBanner
                    seriesGenres={this.getSeriesGenres()}
                    moviesGenres={this.getMovieGenres()}
                    baseUrl={routes.svod.base + '/' + getSvodUrl(this.props.svodKiosk) + routes.svod.genre}
                    onGenreSelected={this.onGenreSelected}
                    selectedTab={this.props.selectedGenre}
                  />

                  {this.props.lists ? this.getViewsBasedOnVodConfig(this.props.lists) : null}

                  {!isEmpty(this.state.promotions) &&
                    this.state.promotions.map((promotion) => (
                      <SvodInlinePromotion
                        key={promotion.data[0].actionParams.categoryId}
                        imagePath={this.props.defaultImagePath}
                        baseUrl={routes.svod.base + '/' + getSvodUrl(this.props.svodKiosk)}
                        promotions={promotion.data}
                      />
                    ))}
                </div>
              </React.Fragment>
            ) : null
          ) : (
            <div className="svod-frontpage-loader">
              <Spinner wrapInContainer={true} />
            </div>
          )}
        </>
      ),
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      defaultImagePath: state.app.defaultImagePath,
      auth: state.authReducer.auth,
      isGuest: state.authReducer.isGuest,
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
      selectedGenre: state.vodReducer.selectedGenreType,
      genres: state.channelsReducer.genres,
      lists: state.svodReducer.frontPageVodLists,
      svodKiosk: state.svodReducer.svodKiosk,
      svodKiosks: state.app.svodKiosks,
      svodConfig: state.svodReducer.svodConfig,
      myContent: state.vodReducer.myContent,
    }),
    (dispatch) => ({
      clearOriginLocation: () => dispatch(clearOriginLocation()),
      setSelectedGenre: (genreType: GenreTypes) => dispatch(setSelectedVodGenre(genreType)),
      getLists: (config: VodConfigData[], genre: Genre[], stripLength: number) =>
        dispatch(fetchFrontPageVodLists(config, genre, stripLength)),
      clearSvodSelection: () => dispatch(clearSvodSelection()),
    }),
  )(FrontPage),
);
