import React from 'react';
import { connect } from 'react-redux';
import { hideLocationSelectModal, handleLoginResponse } from '../actions';
import { AccountWithExtraData, AibHuaweiNonPaceSubscriber, Partner, RootState } from '../../../interfaces';
import Modal from '../../../components/Modal';
import { loginToAccount, logout } from '../../../api/auth';
import i18n from '../../../i18n';
import styles from '../../../components/OptionsModal/styles.module.scss';
import { SvgClose } from '../../../icons';

type Props = {
  hideModal: () => void;
  accounts: AccountWithExtraData[];
  locationSelectModalVisible: boolean;
  loginError: string;
  login: (account: AccountWithExtraData, subscriber: AibHuaweiNonPaceSubscriber) => void;
};

function LocationSelectModal({ accounts, locationSelectModalVisible, hideModal, login }: Props) {
  const showCustomerNumber = accounts.length !== 1;
  const showPartner = !accounts.every((account) => accounts[0].partnerId === account.partnerId);

  const accountsByPartner = accounts.reduce<Record<string, { partner?: Partner; accounts: AccountWithExtraData[] }>>(
    (accumulator, account) => {
      if (!accumulator[account.partnerId]) {
        return { ...accumulator, [account.partnerId]: { accounts: [account], partner: account.partner } };
      } else {
        return {
          ...accumulator,
          [account.partnerId]: {
            ...accumulator[account.partnerId],
            accounts: [...accumulator[account.partnerId].accounts, account],
          },
        };
      }
    },
    {},
  );

  const select = (account: AccountWithExtraData, subscriber: AibHuaweiNonPaceSubscriber) => () => {
    login(account, subscriber);
    hideModal();
  };

  const cancelLogin = () => {
    hideModal();
    logout(true);
  };

  return (
    <Modal
      isOpen={locationSelectModalVisible}
      shouldCloseOnOverlayClick={false}
      portalClassName="options-modal"
      className="aib-modal"
    >
      <main className={styles.mainContainer}>
        <h1 className={styles.modalH1} title={i18n.t<string>('choose_location')}>
          {i18n.t<string>('choose_location')}
        </h1>
        <button className={styles.buttonClose} onClick={cancelLogin} children={<SvgClose />} />
        {Object.keys(accountsByPartner).map((partnerId) => (
          <React.Fragment key={partnerId}>
            {showPartner ? (
              <div className="options-partnerName">{accountsByPartner[partnerId].partner?.name ?? partnerId}</div>
            ) : null}
            {accountsByPartner[partnerId].accounts.map((account) => (
              <Account key={account.id} account={account} select={select} showCustomerNumber={showCustomerNumber} />
            ))}
          </React.Fragment>
        ))}
      </main>
    </Modal>
  );
}

export default connect(
  (state: RootState) => {
    return {
      locationSelectModalVisible: state.authReducer.locationSelectModalVisible,
      loginError: state.authReducer.loginError ?? '',
      accounts: state.authReducer.accounts ?? [],
    };
  },
  (dispatch) => ({
    login: (account: AccountWithExtraData, subscriber: AibHuaweiNonPaceSubscriber) => {
      loginToAccount(account, subscriber)
        .then((res) => handleLoginResponse(dispatch, res))
        .catch((res) => handleLoginResponse(dispatch, res));
    },
    hideModal: () => dispatch(hideLocationSelectModal()),
  }),
)(LocationSelectModal);

interface AccountProps {
  account: AccountWithExtraData;
  select: (accunt: AccountWithExtraData, subscriber: AibHuaweiNonPaceSubscriber) => () => void;
  showCustomerNumber: boolean;
}

function Account({ account, select, showCustomerNumber }: AccountProps) {
  return (
    <>
      {showCustomerNumber ? (
        <div className="options-subtitle options-customerId">Kundenummer: {account.customerId}</div>
      ) : null}
      <div className={'option-group'}>
        {account.subscribers.map((subscriber) => (
          <button key={subscriber.subscriberId} className={styles.optionButton} onClick={select(account, subscriber)}>
            {subscriber.address}
          </button>
        ))}
      </div>
    </>
  );
}
