import React, { Component, MouseEvent } from 'react';
import {
  AltiboxAssetButtonType,
  AltiboxAsset,
  Program,
  AltiboxAssetDetailsType,
  VodAsset,
  VodContentProduct,
  UnionAssetTypes,
  DeleteType,
  BroadcastStatus,
  RootState,
  DispatchResponse,
  Favourite,
  PvrRecording,
} from '../../../../../interfaces';
import i18n from '../../../../../i18n';
import {
  getAltiboxAssetPlayLink,
  getAltiboxAssetChannel,
  getAltiboxAssetBookmark,
  getAltiboxAssetPercentWatched,
  getAltiboxAssetTrailerLink,
  wrapAssetAsAltiboxAsset,
  checkIfUserHasSvodProduct,
  getProgramStartURL,
  isPvr,
  getProgramData,
  getSeriesId,
  getChannelid,
  getPromotedAssetId,
  getProgramBroadcastingStatus,
  getAltiboxAssetEpisodeNumber,
  getAltiboxAssetCover,
} from '../../../../../utils/altiboxassetUtils';
import AltiboxAssetContext from '../../../../../views/details/AltiboxAssetContext';
import channelPermissions from '../../../../../utils/channelPermissions';
import { assetIsOwned, getCurrentLangTitle, hasBoughtProduct } from '../../../../../utils/vodUtils';
import PvrRecordingButton from '../../../../../views/pvr/PvrRecordingButton';
import {
  broadcastEnded,
  broadcastingFuture,
  broadcastingNow,
  isBroadcastWithinFifteenMinutes,
  nowHuaweiFormat,
  toDetailsReadableDateString,
  toMoment,
} from '../../../../../utils/huaweiUtils';
import { isChannelOnlyIPTV, getChannelPermission, catchupAvailable } from '../../../../../utils/tvUtils';
import {
  ButtonPrimary,
  ButtonPrimaryPlay,
  ButtonPrimaryPlayFromStart,
  ButtonPrimaryPlayWithProgress,
  ButtonPrimaryUnavailableAsset,
  ButtonSecondary,
  ButtonSecondaryPlay,
  ButtonSecondaryPlayFromStart,
  ButtonSecondaryUnavailableAsset,
  IconButtonDelete,
  IconButtonFavourite,
  IconButtonSkipBack,
  IconButtonTrailer,
} from '../../../Buttons';
import { assetIsProgram, assetIsPvr } from '../../../../../typeGuards';
import moment from 'moment';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { routes } from '../../../../../config';
import { toggleIsFavouritedOnVodListAsset } from '../../../../../views/vod/actions';
import { ScriptService } from '../../../../../controllers/ScriptService';
import { cast_seek, getCurrentCastMediaStatus } from '../../../../Player/helpers/ChromeCast';
import { playChannel } from '../../../../../views/tv/actions';
import { getThirdPartyCatchupInformation } from '../../../../../utils/thirdPartyCatchupUtils';

type Props = {
  buttonType: AltiboxAssetButtonType;
  toggleFavouriteState: (assetId: string) => DispatchResponse;
  liveFromStart: (channelId: string, liveFromStart: boolean) => unknown;
  currentProgram?: Program;
  limited?: boolean;
  isFutureProgram?: boolean;
  overwriteToLink?: string;
} & RouteComponentProps;

class AltiboxAssetButton extends Component<Props, {}> {
  static contextType = AltiboxAssetContext;
  context!: React.ContextType<typeof AltiboxAssetContext>;

  get promotedAsset() {
    let { promotedAsset } = this.context;
    return promotedAsset;
  }

  get isVod() {
    return this.context.isVod;
  }

  get isSvod() {
    return this.context.isSvod;
  }

  get isCatchupOrPvr() {
    return this.context.isCatchupOrPvr;
  }

  get isGeoBlocked() {
    return this.context.isGeoBlocked;
  }

  get isLockedFromOutsideHome() {
    const { authState, channels } = this.context;
    const asset = this.promotedAsset as Program;
    const channel = getAltiboxAssetChannel(asset.channelid, channels);
    const permissions = channelPermissions(channel, authState);
    return permissions ? permissions.onlyInsideForCatchup : false;
  }

  get thirdPartyCatchupInfo() {
    const asset = this.promotedAsset as Program;
    return getThirdPartyCatchupInformation(this.context.authState.auth, asset);
  }

  get shouldShowRecordingOptions() {
    const { authState, channels, altiboxAsset } = this.context;
    const { meta } = altiboxAsset;
    if (isPvr(this.promotedAsset)) {
      return true;
    }

    if (!meta!.isSeries) {
      let program = this.promotedAsset as Program;
      if (Number(program.endtime) < Number(nowHuaweiFormat())) {
        return false;
      }
    }

    if (authState.isGuest) {
      return false;
    }
    const channel = getAltiboxAssetChannel((this.promotedAsset as Program).channelid, channels);

    if (channel && !channel.channelPermissions.notInSubscription && authState.hasPvr) {
      return true;
    }

    return false;
  }

  get shouldBeLockedFromPlaying() {
    const { promotedAsset, channels } = this.context;
    const channel = getAltiboxAssetChannel((this.promotedAsset as Program).channelid, channels);
    return (
      this.isGeoBlocked ||
      this.isLockedFromOutsideHome ||
      (assetIsProgram(promotedAsset) &&
        (getProgramBroadcastingStatus(promotedAsset) === BroadcastStatus.Future ||
          getChannelPermission(channel).notInSubscription))
    );
  }

  get notInSubscription() {
    const { channels } = this.context;
    const asset = this.promotedAsset as Program;
    const channel = getAltiboxAssetChannel(asset.channelid, channels);
    return channel.channelPermissions.notInSubscription;
  }

  get isIPTVRestricted() {
    const { channels } = this.context;
    const asset = this.promotedAsset as Program;
    const channel = getAltiboxAssetChannel(asset.channelid, channels);
    return channel ? isChannelOnlyIPTV(channel) : false;
  }

  get deepLinkButton() {
    const { data, url, isValid } = this.thirdPartyCatchupInfo;
    const episodeNumber = this.episodeNumber;

    const text = i18n.t('open external catchup', {
      title: getCurrentLangTitle(data?.titles),
      episodeNumber: episodeNumber && !this.props.limited ? episodeNumber : '',
    });

    if (data && isValid && url) {
      // todo: Track this
      return this.props.limited ? (
        <ButtonSecondaryPlay href={url}>{text}</ButtonSecondaryPlay>
      ) : (
        <ButtonPrimaryPlay href={url}>{text}</ButtonPrimaryPlay>
      );
    }

    return this.expiredButton;
  }

  get percent() {
    const { bookmarks } = this.context;
    const asset = this.promotedAsset as UnionAssetTypes;
    const idToUse = getPromotedAssetId(asset);
    const bookmark = getAltiboxAssetBookmark(idToUse, bookmarks);
    return getAltiboxAssetPercentWatched(wrapAssetAsAltiboxAsset(asset), bookmark!);
  }

  get livePercent() {
    const asset = this.promotedAsset;

    if (!assetIsProgram(asset)) {
      return 0;
    }

    const now = moment();
    const start = toMoment(asset.starttime);
    const end = toMoment(asset.endtime);

    const duration = moment.duration(end.diff(start)).asMinutes();
    const fromStart = now.diff(start, 'minutes');

    return Math.round((fromStart / duration) * 100);
  }

  get episodeNumber() {
    let asset = wrapAssetAsAltiboxAsset(this.promotedAsset);
    asset = { ...asset, type: this.context.altiboxAsset.type };
    let episodeNumber = getAltiboxAssetEpisodeNumber(asset);
    return episodeNumber === '0' ? '' : episodeNumber;
  }

  get getAccessButton() {
    const { getAccessToSvod } = this.context;
    if (this.props.limited) {
      return <ButtonSecondary onClick={getAccessToSvod}>{i18n.t<string>('get access')}</ButtonSecondary>;
    }

    const episodeNumber = this.episodeNumber;
    const text = episodeNumber
      ? `${i18n.t<string>('get access')} ${i18n.t<string>('to')} ${i18n.t<string>('episode')} ${episodeNumber}`
      : i18n.t<string>('get access');

    return <ButtonPrimary onClick={getAccessToSvod}>{text}</ButtonPrimary>;
  }

  get comingSoonButton() {
    return this.props.limited ? (
      <ButtonSecondaryUnavailableAsset>
        {i18n.t<string>('watch')} {i18n.t<string>('now')}
      </ButtonSecondaryUnavailableAsset>
    ) : (
      <ButtonPrimaryUnavailableAsset>{i18n.t<string>('coming soon')}</ButtonPrimaryUnavailableAsset>
    );
  }

  get shouldPlay() {
    const { svodKiosk, authState, altiboxAsset, sharedAssets, myContent } = this.context;
    const { meta } = altiboxAsset;
    const asset = this.promotedAsset as UnionAssetTypes;
    if (assetIsProgram(asset)) {
      return !this.notInSubscription && !broadcastingFuture(asset);
    } else if (this.isVod && sharedAssets && sharedAssets.svod && meta) {
      return hasBoughtProduct(meta.purchaseOptions as VodContentProduct[] | undefined);
    }
    return (
      assetIsOwned(asset as VodAsset, myContent) || checkIfUserHasSvodProduct(authState, svodKiosk!) || isPvr(asset)
    );
  }

  get broadcastingFutureButton() {
    const program = this.promotedAsset;
    const { isFutureProgram } = this.props;

    if (!assetIsProgram(program)) {
      return null;
    }

    if (this.props.limited && !isFutureProgram) {
      return (
        <ButtonSecondaryUnavailableAsset>
          {i18n.t<string>('watch')} {i18n.t<string>('now')}
        </ButtonSecondaryUnavailableAsset>
      );
    }

    const episodeNumber = this.episodeNumber;
    const time = toDetailsReadableDateString({
      huaweiDateTime: program.starttime,
      shortFormat: i18n.t<string>('short date format details'),
      longFormat: i18n.t<string>('long date format details-cta'),
      longestFormat: i18n.t<string>('long date format details-cta'),
      farInTheFutureFormat: i18n.t<string>('far date format details'),
    });

    const text =
      episodeNumber && !isFutureProgram
        ? `${i18n.t<string>('episode')} ${episodeNumber} ${i18n.t<string>('airs')} ${time}`
        : `${i18n.t<string>('airs')} ${time}`;

    return (
      <ButtonPrimaryUnavailableAsset style={isFutureProgram ? { width: 296 } : undefined}>
        {text}
      </ButtonPrimaryUnavailableAsset>
    );
  }

  get playOrContinueWatchingButton() {
    const { altiboxAsset } = this.context;
    const { liveFromStart } = this.props;
    const { meta } = altiboxAsset;
    const { purchaseOptions } = meta!;
    const asset = this.promotedAsset as UnionAssetTypes;
    const continueWatching = this.percent > 0 && this.percent < 97;
    const isLivePvrAsset =
      assetIsPvr(this.promotedAsset) &&
      broadcastingNow({
        starttime: (this.promotedAsset as PvrRecording).beginTime,
        endtime: (this.promotedAsset as PvrRecording).endTime,
      });
    const episodeNumber = this.episodeNumber;
    const toLink = getAltiboxAssetPlayLink(altiboxAsset, asset);
    const btnProps = {
      to: {
        pathname: toLink,
        state: { prevPath: this.props.location.pathname },
      },
      onClick: (e: MouseEvent) => {
        if (isLivePvrAsset && this.props.location.pathname.includes(routes.tv.start)) {
          liveFromStart((this.promotedAsset as PvrRecording).channelId, true);
        }

        this.triggerPlay(e, btnProps.to);
      },
    };

    if (assetIsProgram(asset) && broadcastingFuture(asset)) {
      return this.broadcastingFutureButton;
    }

    if (this.shouldPlay) {
      if (continueWatching && !isLivePvrAsset) {
        const text =
          episodeNumber && !this.props.limited
            ? `${i18n.t<string>('continue watching')} ${'episode'} ${episodeNumber}`
            : i18n.t<string>('continue watching');

        return this.props.limited ? (
          <ButtonSecondaryPlay {...btnProps}>{text}</ButtonSecondaryPlay>
        ) : (
          <ButtonPrimaryPlayWithProgress
            className="altibox-asset-cta-with-progress"
            {...btnProps}
            progress={this.percent}
          >
            {text}
          </ButtonPrimaryPlayWithProgress>
        );
      } else {
        const isLive = broadcastingNow(this.promotedAsset as Program);
        const isCurrentProgram = this.props.currentProgram?.id === this.promotedAsset.id;
        const isWatchingFromStart = this.props.location.pathname.includes(routes.tv.start);
        const isWatchingLive = isLive && isCurrentProgram && !isWatchingFromStart;
        const path = this.props.history.location.pathname;

        if (isWatchingLive) {
          const text =
            episodeNumber && !this.props.limited
              ? `${i18n.t<string>('watch')} ${i18n.t<string>('episode')} ${episodeNumber} ${i18n.t<string>(
                  'from start',
                )}`
              : `${i18n.t<string>('watch')} ${i18n.t<string>('from start')}`;

          const fromStartProps = {
            ...btnProps,
            to: { pathname: btnProps.to.pathname + routes.tv.start, state: { prevPath: this.props.location.pathname } },
          };

          if (ScriptService.isCasting()) {
            /* eslint-disable @typescript-eslint/dot-notation */
            const sectionStartTime = getCurrentCastMediaStatus()?.media?.metadata?.['sectionStartTimeInMedia'];
            if (sectionStartTime) {
              return (
                <ButtonPrimaryPlayFromStart
                  {...fromStartProps}
                  onClick={() => {
                    cast_seek(sectionStartTime);
                  }}
                >
                  {i18n.t<string>('watch from beginning')}
                </ButtonPrimaryPlayFromStart>
              );
            }
          }

          return this.props.limited ? (
            <ButtonSecondaryPlayFromStart {...fromStartProps}>{text}</ButtonSecondaryPlayFromStart>
          ) : (
            <ButtonPrimaryPlayFromStart {...fromStartProps}>{text}</ButtonPrimaryPlayFromStart>
          );
        }

        const text = () => {
          if (path.includes(routes.tv.start)) {
            return i18n.t<string>('watch now');
          }

          if (isLivePvrAsset && !path.includes(routes.tvguide.base)) {
            return i18n.t<string>('watch from beginning');
          }

          if (episodeNumber && !this.props.limited) {
            return `${i18n.t<string>('watch')} ${i18n.t<string>('episode')} ${episodeNumber} ${
              isLive ? i18n.t<string>('live') : ''
            }`;
          }

          return `${i18n.t<string>('watch')} ${isLive ? i18n.t<string>('live') : i18n.t<string>('now')}`;
        };

        if (path.includes(routes.tv.start) && this.props.limited) {
          return <ButtonSecondaryPlay {...btnProps}>{text()}</ButtonSecondaryPlay>;
        }

        if (isLivePvrAsset && !this.props.limited && path.includes(routes.tv.base) && path.includes(routes.tv.start)) {
          return <ButtonPrimaryPlay {...btnProps}>{text()}</ButtonPrimaryPlay>;
        }

        if (
          isLivePvrAsset &&
          !this.props.limited &&
          path.includes(routes.tv.base) &&
          !path.includes(routes.tvguide.base)
        ) {
          return <ButtonPrimaryPlayFromStart {...btnProps}>{text()}</ButtonPrimaryPlayFromStart>;
        }

        if (isLivePvrAsset && this.props.limited && !path.includes(routes.tvguide.base)) {
          return <ButtonSecondaryPlayFromStart {...btnProps}>{text()}</ButtonSecondaryPlayFromStart>;
        }

        return this.props.limited ? (
          <ButtonSecondaryPlay {...btnProps}>{text()}</ButtonSecondaryPlay>
        ) : isLive ? (
          <ButtonPrimaryPlayWithProgress
            {...btnProps}
            progress={this.livePercent}
            onClick={() => {
              if (ScriptService.isCasting() && getCurrentCastMediaStatus?.()?.liveSeekableRange?.end) {
                cast_seek(getCurrentCastMediaStatus()!.liveSeekableRange!.end!);
              }
            }}
          >
            {text()}
          </ButtonPrimaryPlayWithProgress>
        ) : (
          <ButtonPrimaryPlay {...btnProps}>{text()}</ButtonPrimaryPlay>
        );
      }
    } else {
      if (purchaseOptions) {
        return this.purchaseButton;
      }
      return null;
    }
  }

  get purchaseButton() {
    const { altiboxAsset } = this.context;
    const { meta } = altiboxAsset;
    const { purchaseOptions } = meta!;
    const lowestPrice = Math.min(
      ...(purchaseOptions as VodContentProduct[])!.map((x: VodContentProduct) => Number(x.price)),
    );
    const episodeNumber = this.episodeNumber;
    if (lowestPrice === Infinity) {
      return this.getAccessButton;
    }
    let buttonText =
      episodeNumber && !this.props.limited
        ? `${i18n.t<string>('watch')} ${i18n.t<string>('episode')} ${episodeNumber} ${i18n.t<string>(
            'from',
          )} ${lowestPrice},-`
        : `${i18n.t<string>('watch')} ${i18n.t<string>('from')} ${lowestPrice},-`;

    if (this.props.limited) {
      return <ButtonSecondaryPlay onClick={() => this.triggerPurchase()}>{buttonText}</ButtonSecondaryPlay>;
    }

    return <ButtonPrimaryPlay onClick={() => this.triggerPurchase()}>{buttonText}</ButtonPrimaryPlay>;
  }

  get play() {
    const { altiboxAsset, authState, svodKiosk } = this.context;
    const { type } = altiboxAsset;
    const promotedAsset = this.promotedAsset as VodAsset;
    switch (type) {
      case AltiboxAssetDetailsType.PVR:
      case AltiboxAssetDetailsType.CATCHUP:
        return this.playOrContinueWatchingButton;
      case AltiboxAssetDetailsType.VOD:
      case AltiboxAssetDetailsType.SVOD:
        if (svodKiosk) {
          const hasProduct = checkIfUserHasSvodProduct(authState, svodKiosk);
          if (!hasProduct || authState.isGuest) {
            return this.getAccessButton;
          }
        }
        if (!authState.isGuest && !promotedAsset.isPlayable && !this.shouldPlay) {
          return this.comingSoonButton;
        }
        return this.playOrContinueWatchingButton;
      default:
        return null;
    }
  }

  get expiredButton() {
    const episodeNumber = this.episodeNumber;
    const text = this.props.limited
      ? `${i18n.t<string>('watch')} ${i18n.t<string>('now')}`
      : episodeNumber
      ? `${i18n.t<string>('episode')} ${episodeNumber} ${i18n.t<string>('is')} ${i18n.t<string>('not available')}`
      : `${i18n.t<string>('not available')}`;

    return this.props.limited ? (
      <ButtonSecondaryPlay disabled={true}>{text}</ButtonSecondaryPlay>
    ) : (
      <ButtonPrimary disabled={true}>{text}</ButtonPrimary>
    );
  }

  get button() {
    const { buttonType } = this.props;
    switch (buttonType) {
      case AltiboxAssetButtonType.PLAY:
        return this.playButton();
      case AltiboxAssetButtonType.FAVOURITE:
        return this.favouriteButton();
      case AltiboxAssetButtonType.TRAILER:
        return this.trailerButton();
      case AltiboxAssetButtonType.PVR:
        return this.pvrButton();
      case AltiboxAssetButtonType.FROMSTART:
        return this.playFromStartButton();
      case AltiboxAssetButtonType.DELETE_ALL:
        return this.getDeleteButton(DeleteType.ALL);
      case AltiboxAssetButtonType.DELETE_SINGLE:
        return this.getDeleteButton(DeleteType.SINGLE);
      case AltiboxAssetButtonType.DELETE_SEASONS:
        return this.getDeleteButton(DeleteType.SEASONS);
      case AltiboxAssetButtonType.DELETE_SERIES:
        return this.getDeleteButton(DeleteType.SERIES);
      default:
        return null;
    }
  }

  pvrButton() {
    const { altiboxAsset } = this.context;

    if (this.shouldShowRecordingOptions) {
      return (
        <PvrRecordingButton
          modalHeroBackground={getAltiboxAssetCover(altiboxAsset, this.promotedAsset)}
          programData={getProgramData(this.promotedAsset)}
          seriesId={getSeriesId(this.promotedAsset)}
          channelId={getChannelid(this.promotedAsset)}
        />
      );
    }
    return null;
  }

  playFromStartButton() {
    const fromStartURL = getProgramStartURL(this.promotedAsset);
    if (this.isIPTVRestricted) {
      return null;
    }
    return (
      <IconButtonSkipBack
        to={{
          pathname: fromStartURL,
          state: { prevPath: this.props.location.pathname },
        }}
        onClick={(e: MouseEvent) => (this.shouldBeLockedFromPlaying ? e.preventDefault() : true)}
      >
        {i18n.t<string>('watch from beginning')}
      </IconButtonSkipBack>
    );
  }

  triggerPurchase() {
    if (this.isGeoBlocked) {
      this.context.cannotPurchaseAbroad();
    } else {
      this.context.purchaseAsset(this.promotedAsset as VodAsset);
    }
  }

  triggerPlay = (
    e: React.MouseEvent,
    to: {
      pathname: string;
      state: {
        prevPath: string;
      };
    },
  ) => {
    const { isSlideIn, hideDetailsPanel } = this.context;
    if (this.isGeoBlocked) {
      e.preventDefault();
      this.context.cannotPlaybackAbroad();
    }

    if (to.pathname === this.props.location.pathname) {
      return;
    }

    if (isSlideIn) {
      e.preventDefault();
      this.props.history.push(to.pathname, to.state);
      hideDetailsPanel();
    }
  };

  isDeeplink() {
    return this.thirdPartyCatchupInfo.isExternal;
  }

  trailerButton() {
    const { altiboxAsset } = this.context;
    return (
      <IconButtonTrailer to={getAltiboxAssetTrailerLink(altiboxAsset)}>
        {i18n.t<string>('play trailer')}
      </IconButtonTrailer>
    );
  }

  handleDeeplinkButton() {
    const promotedAsset = this.promotedAsset as Program;

    const shouldShowPlayButton = broadcastingNow(promotedAsset) || isBroadcastWithinFifteenMinutes(promotedAsset);

    if (shouldShowPlayButton) {
      return this.play;
    }

    if (catchupAvailable(promotedAsset) && this.thirdPartyCatchupInfo.isValid) {
      return this.deepLinkButton;
    }

    return this.expiredButton;
  }

  playButton() {
    const altiboxAsset: AltiboxAsset = this.context.altiboxAsset;
    const { type } = altiboxAsset;
    switch (type) {
      case AltiboxAssetDetailsType.VOD:
      case AltiboxAssetDetailsType.SVOD:
        return this.play;
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PVR:
        if (assetIsPvr(this.promotedAsset)) {
          return this.play;
        }

        if (this.isDeeplink()) {
          return this.handleDeeplinkButton();
        }

        if (broadcastEnded(this.promotedAsset as Program) && !catchupAvailable(this.promotedAsset as Program)) {
          return this.expiredButton;
        }

        if (this.notInSubscription) {
          return this.getAccessButton;
        }

        return this.play;
      default:
        return null;
    }
  }

  favouriteButton() {
    const { altiboxAsset } = this.context;
    const { toggleFavouriteState } = this.props;

    const toggleFavourite = this.context.toggleFavourite;
    let favourite: Favourite;
    let isFavourited: boolean = false;
    let assetId: string;
    if (altiboxAsset.meta && altiboxAsset.meta.favourite && altiboxAsset.meta.favourite.asset) {
      favourite = altiboxAsset.meta.favourite;
      isFavourited = altiboxAsset.meta?.favourite?.asset?.isfavorited === '1';
      assetId = altiboxAsset.meta.favourite.asset.id;
    }

    const handleClick = () => {
      const newState = !isFavourited;
      toggleFavourite(favourite, newState ? '1' : '0');
      toggleFavouriteState(assetId);
    };

    return (
      <IconButtonFavourite onClick={handleClick} active={isFavourited}>
        {i18n.t<string>('favourite')}
      </IconButtonFavourite>
    );
  }

  getDeleteButton(deleteType: DeleteType) {
    const { deleteRecordings } = this.context;
    let buttonText = '';
    switch (deleteType) {
      case DeleteType.SINGLE:
        buttonText = i18n.t<string>('delete recording');
        break;
      case DeleteType.SEASONS:
        buttonText = i18n.t<string>('delete seasons');
        break;
      case DeleteType.SERIES:
        buttonText = i18n.t<string>('delete entire series');
        break;
      case DeleteType.ALL:
        buttonText = i18n.t<string>('delete');
        break;
    }

    return <IconButtonDelete onClick={() => deleteRecordings(deleteType)}>{buttonText}</IconButtonDelete>;
  }

  render() {
    if (!this.promotedAsset) {
      return null;
    }
    return this.button;
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      currentProgram: state.channelsReducer.currentProgram,
    }),
    (dispatch) => ({
      toggleFavouriteState: (assetId: string) => dispatch(toggleIsFavouritedOnVodListAsset(assetId)),
      liveFromStart: (channelId: string, liveFromStart: boolean) => dispatch(playChannel(channelId, liveFromStart)),
    }),
  )(AltiboxAssetButton),
);
