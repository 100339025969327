import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { routes } from '../../config';
import vodBrand from '../../icons/film.svg';
import { MediaFile, RootState } from '../../interfaces';
import { useBookmark } from '../../queries/bookmarks/queries';
import { Vod } from '../../queries/vod/types';
import { getSvodKiosk, getSvodUrl } from '../../utils/svodUtil';
import { HorizontalAsset } from './HorizontalAsset';
import { assetIsBought, assetIsRented, getRentBadgeTextWithInformation } from '../../utils/vodUtils';
import { BadgeTypes } from '../Badge';

interface VodAssetProps {
  orientation?: 'vertical' | 'horizontal';
  constructedAsset: Vod.ConstructedAsset;
  hideIfNotOwned?: boolean;
}

export function VodAsset({
  constructedAsset: { asset, series, seasons, episodes, seasonNum, lastEpisodeWatched },
  hideIfNotOwned = false,
}: VodAssetProps) {
  const svodKiosk = useSelector((state: RootState) => state.app.svodKiosks);
  const myContent = useSelector((state: RootState) => state.vodReducer.myContent);
  const defaultImagePath = useSelector((state: RootState) => state.app.defaultImagePath);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [promotedAsset, setPromotedAsset] = useState(asset);
  const [promotedSeasonNum, setPromotedSeasonNum] = useState(seasonNum);
  const { data: bookmark } = useBookmark(promotedAsset?.id);

  const ownedAsset = useMemo(() => myContent?.find((c) => c?.id === promotedAsset?.id), [myContent, promotedAsset]);

  const kiosk = getSvodKiosk(promotedAsset, svodKiosk);
  const progress = getProgress();
  const isBought = assetIsBought(ownedAsset || promotedAsset, myContent);
  const isRented = assetIsRented(ownedAsset || promotedAsset);
  const isOwned = isBought || isRented;

  function getLink(linkType: 'play' | 'details') {
    if (kiosk) {
      return `${routes.svod.base}/${getSvodUrl(kiosk)}${routes.svod[linkType]}/${promotedAsset.externalContentCode}`;
    }

    return `${routes.vod.base}${routes.vod[linkType]}/${promotedAsset.externalContentCode}`;
  }

  function getTitle() {
    if (series) {
      const seriesName = series.names.find((n) => n.key === language);

      if (seriesName) {
        return promotedSeasonNum
          ? [seriesName.value, `S${promotedSeasonNum.padStart(2, '0')} E${promotedAsset.sitcomnum.padStart(2, '0')}`]
          : [seriesName.value];
      }

      return [series.name];
    }

    const name = promotedAsset.names.find((n) => n.key === language);
    return name ? [name.value] : [promotedAsset.name];
  }

  function getSubtitle() {
    return promotedAsset.genres.split(',')[0];
  }

  function getProgress() {
    if (!promotedAsset) {
      return 0;
    }

    const percentWatched = getPercentWatched(bookmark?.rangeTime, promotedAsset.mediafiles);

    if (percentWatched > 97 && episodes) {
      const nextEpisodeIndex = episodes.findIndex((e) => e.sitcomnum === promotedAsset.sitcomnum) + 1;
      const nextEpisode = episodes[nextEpisodeIndex];
      setPromotedAsset(nextEpisode);

      if (nextEpisode) {
        const season = getNextEpisodeSeason(nextEpisode);
        setPromotedSeasonNum(season?.fathervodlist?.[0].sitcomnum);
      }
    }

    return percentWatched;
  }

  function getNextEpisodeSeason(episode: Vod.Details) {
    if (!episode.fathervodlist) {
      return;
    }

    return seasons?.find((s) => s.id === episode.fathervodlist?.[0].vodid);
  }

  function getBrand() {
    if (kiosk && kiosk.kioskHeader) {
      const { cropped, tablet, stb, phone } = kiosk.kioskHeader;
      return `${defaultImagePath}/${cropped || tablet || stb || phone}`;
    }

    return vodBrand;
  }

  function getBadge() {
    if (kiosk) {
      return;
    }

    const price = Number(promotedAsset.price);

    if (isRented) {
      return {
        text: getRentBadgeTextWithInformation(ownedAsset?.rentperiod || promotedAsset.rentperiod),
        type: BadgeTypes.Rented,
      };
    }

    if (isBought) {
      return {
        text: t('bought'),
        type: BadgeTypes.Bought,
      };
    }

    if (price > 0) {
      return {
        text: `${Math.floor(price)},-`,
        type: BadgeTypes.Price,
      };
    }
  }

  if (lastEpisodeWatched) {
    return null;
  }

  if (!promotedAsset) {
    return null;
  }

  if (hideIfNotOwned && !isOwned) {
    return null;
  }

  if (promotedAsset.id === asset.id && (progress < 1 || progress > 97)) {
    return null;
  }

  return (
    <HorizontalAsset
      titles={getTitle()}
      subtitle={getSubtitle()}
      link={[getLink('play'), getLink('details')]}
      progress={progress > 1 ? progress : undefined}
      image={{
        src: cropImage(series ? series.picture.background : promotedAsset.picture.background),
        alt: series ? series.name : promotedAsset.name,
      }}
      badge={getBadge()}
      brand={{ src: getBrand(), alt: 'Filmer og serier' }}
      playOnHover
    />
  );
}

function cropImage(src: string) {
  return `${src}?x=256&y=144&ar=keep`;
}

export function getPercentWatched(rangeTime: string | undefined, mediafiles: MediaFile[]) {
  if (rangeTime) {
    const bookmarkTimeInSeconds = Number(rangeTime);
    const durationInSeconds = getVodDuration(mediafiles);

    if (durationInSeconds > 0) {
      return (bookmarkTimeInSeconds / durationInSeconds) * 100;
    } else {
      return 0;
    }
  }
  return 0;
}

export function getVodDuration(mediafiles: MediaFile[]) {
  return mediafiles && mediafiles[0] ? Number(mediafiles[0].elapsetime) : 0;
}
