import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { routes } from '../../config';
import vodBrand from '../../icons/film.svg';
import { RootState } from '../../interfaces';
import { Catchup } from '../../queries/catchup/types';
import { useChannel } from '../../queries/channels/queries';
import { getPercentWatched } from '../../queries/recordings/utils';
import channelPermissions from '../../utils/channelPermissions';
import { toMomentReadableDateString } from '../../utils/huaweiUtils';
import { HorizontalAsset } from './HorizontalAsset';

interface CatchupAssetProps {
  orientation?: 'vertical' | 'horizontal';
  constructedAsset: Catchup.ConstructedAsset;
}

export function CatchupAsset({ constructedAsset: { asset, nextEpisode, rangeTime } }: CatchupAssetProps) {
  const authState = useSelector((state: RootState) => state.authReducer);

  const { data: channel } = useChannel(asset.channelid);
  const { locked } = channelPermissions(channel, authState);

  const progress = getProgress(asset, rangeTime);
  const promotedAsset = getPromotedAsset(asset, nextEpisode, progress);

  if (!promotedAsset) {
    return null;
  }

  function getLink(linkType: 'play' | 'details') {
    const { programarchive } = routes;
    if (linkType === 'play') {
      return `${programarchive.base}${programarchive[linkType]}/${promotedAsset.id}`;
    }

    if (!promotedAsset.seriesID) {
      return `${programarchive.base}${programarchive[linkType]}${programarchive.single}/${promotedAsset.id}`;
    }

    return `${programarchive.base}${programarchive[linkType]}${programarchive.series}/${promotedAsset.seriesID}/${promotedAsset.id}`;
  }

  function getTitles() {
    if (promotedAsset.seasonNum) {
      return [
        promotedAsset.name,
        `S${getLeadingZero(promotedAsset.seasonNum)} E${getLeadingZero(promotedAsset.subNum)}`,
      ];
    }

    return [promotedAsset.name];
  }

  function getSubtitle() {
    if (isEmpty(promotedAsset.genres)) {
      return '';
    }

    return `${promotedAsset.genres?.split(',')[0]} - ${toMomentReadableDateString(promotedAsset.starttime)}`;
  }

  function getBrand() {
    if (channel) {
      return channel.pictures[1].href;
    }

    return vodBrand;
  }

  if (progress < 1) {
    return null;
  }

  const titles = getTitles();

  if (!promotedAsset) {
    return null;
  }

  return (
    <HorizontalAsset
      titles={titles}
      subtitle={getSubtitle()}
      link={[getLink(locked ? 'details' : 'play'), getLink('details')]}
      progress={progress > 97 ? undefined : progress}
      image={{
        src: cropImage(promotedAsset.picture.deflate || promotedAsset.picture.poster),
        alt: titles.join(' - '),
      }}
      brand={{ src: getBrand(), alt: channel?.name ?? '' }}
      playOnHover
    />
  );
}

function getPromotedAsset(asset: Catchup.Details, nextEpisode: Catchup.Details | undefined, progress: number) {
  if (progress > 97) {
    return nextEpisode as Catchup.Details;
  }

  return asset;
}

function getProgress(asset: Catchup.Details, rangeTime: string | undefined) {
  return getPercentWatched({
    beginTime: asset.starttime,
    endTime: asset.endtime,
    bookmarkTime: rangeTime,
    beginOffset: asset.beginOffset.toString(),
    endOffset: asset.endOffset.toString(),
  });
}

function cropImage(src: string) {
  return `${src}?x=256&y=144&ar=keep`;
}

function getLeadingZero(num: string | number | undefined) {
  return Number(num) < 10 ? `0${num}` : num;
}
