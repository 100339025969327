import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import Header from '../app/Header';
import StickyHeader from '../app/StickyHeader';
import { getDeviceData, getNonEst, getEst, releaseSlot, releaseESTSlot } from '../../api/auth';
import { Helmet } from 'react-helmet-async';
import { HeaderDisplayType, HeaderInteractionType, RootState, AuthReducerState, Device } from '../../interfaces';
import { isDev, searchConfig } from '../../config';

type DeviceData = {
  deviceList: [];
  replaceTimes: {
    remainTimes: string | undefined;
    totalTimes: string | undefined;
  };
};

type Props = {
  authReducer: AuthReducerState;
};

type State = {
  nonEstCount: number;
  isReloading: boolean;
  deviceData: DeviceData;
  error: boolean;
};

class Settings extends Component<Props, State> {
  state: State = {
    nonEstCount: 0,
    isReloading: false,
    deviceData: {
      deviceList: [],
      replaceTimes: {
        remainTimes: undefined,
        totalTimes: undefined,
      },
    },
    error: false,
  };

  componentDidMount() {
    this.loadDeviceData();
  }

  logDeviceStats() {
    if (isDev || window.location.href.indexOf('atvweb-st3.services.snartibox.net') !== -1) {
      getNonEst().then((data) => {
        console.log('non-Est:');
        if (data.deviceList) {
          this.setState({ nonEstCount: data.deviceList.length });
          data.deviceList.forEach((device, index) => {
            console.log(index + 1 + ': ' + device.physicalDeviceId);
          });
        }
      });

      getEst().then((data) => {
        console.log('EST:');
        if (data.deviceList) {
          data.deviceList.forEach((device, index) => {
            console.log(index + 1 + ': ' + device.ESTID);
          });
        }
      });
    }
  }

  loadDeviceData() {
    if (this.props.authReducer && this.props.authReducer.loggedInWithCredentials) {
      this.logDeviceStats();

      getDeviceData().then((result) => {
        this.setState({
          deviceData: result as DeviceData,
        });
      });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  renderDeviceList(devices: Device[]) {
    return <ul className="devices-list">{this.renderDeviceListItem(devices)}</ul>;
  }

  renderDeviceListItem(devices: Device[]) {
    return devices.map((device) => (
      <li className="devices-item" key={device.value}>
        <div className="light">
          {device.title ? device.title : 'Unknown title(' + device.physicalDeviceId + ')'}
          {this.clearESTdevices(device)}
        </div>
      </li>
    ));
  }

  clearESTdevices(device: Device) {
    if (isDev || window.location.href.indexOf('atvweb-st3.services.snartibox.net') !== -1) {
      return (
        <span
          title="Will release this EST slot and USE ONE SWAP"
          onClick={() => {
            releaseESTSlot(device).then(() => {
              window.location.reload();
            });
          }}
        >
          {`---> ☠️️‍`}
        </span>
      );
    } else {
      return null;
    }
  }

  clearNonESTs() {
    getNonEst().then((data) => {
      console.log('Releasing:');
      data.deviceList.forEach((device) => {
        console.log('device: ' + device.ESTID);
        releaseSlot(device);
      });
    });
  }

  getHeader() {
    return (
      <div>
        <Helmet>
          <title>Settings</title>
        </Helmet>
        <StickyHeader searchConfig={searchConfig.global} />
        <Header
          title={'Settings'}
          displayType={HeaderDisplayType.Solid}
          interactionType={HeaderInteractionType.CloseOnly}
        />
      </div>
    );
  }

  render() {
    if (this.state.deviceData.deviceList.length > 0) {
      return (
        <div className="settings main-frame">
          {this.getHeader()}
          <h2>My devices</h2>
          {this.renderDeviceList(this.state.deviceData.deviceList)}
          <h2>My swap status</h2>
          <ul>
            <li>Remaining: {this.state.deviceData.replaceTimes.remainTimes}</li>
            <li>Total swaps in period:{this.state.deviceData.replaceTimes.totalTimes}</li>
          </ul>
          {this.state.nonEstCount ? (
            <React.Fragment>
              <h2>My IP logins</h2>
              <ul>
                <li>
                  You have <b style={{ color: '#cea872' }}>{this.state.nonEstCount}</b> active IP login registered
                </li>
              </ul>
              {this.state.isReloading ? (
                <div style={{ margin: '20px', color: '#cea872' }}>Reloading in 20 seconds...</div>
              ) : (
                <button
                  onClick={() => {
                    this.setState({
                      isReloading: true,
                    });
                    this.clearNonESTs();
                    setTimeout(() => {
                      window.location.reload();
                    }, 20000);
                  }}
                >
                  {`Clear IP logins`}
                </button>
              )}
            </React.Fragment>
          ) : null}
        </div>
      );
    } else if (this.state.error) {
      return (
        <div className="settings main-frame">
          {this.getHeader()}
          <h2>You need to login with username and password to see settings</h2>
        </div>
      );
    } else {
      return (
        <div className="settings main-frame">
          {this.getHeader()}
          <h2>Loading...</h2>
        </div>
      );
    }
  }
}

export default connect(
  // give the Component access to the store
  (state: RootState) => ({
    authReducer: state.authReducer,
  }),
)(Settings);
