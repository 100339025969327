import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { negate } from 'lodash';
import {
  RootState,
  ApiAuthResponse,
  ProgramArchiveList,
  Channel,
  AltiboxAsset,
  Genre,
  GenreType,
  EpgGenre,
  AltiboxAssetDisplayTypes,
  SearchConfig,
  HeaderDisplayType,
  HeaderInteractionType,
  PopularContentChannelTimespan,
  Program,
  AuthReducerState,
} from '../../../interfaces';
import Header from '../../app/Header';
import i18n from '../../../i18n';
import { getChannels, getGenres } from '../../tv/actions';
import { loadProgramArchiveGenreLists, clearCurrentProgramArchiveGenre, getMostWatchedCatchup } from '../actions';

import Spinner from '../../../components/Spinner/index';
import { loadCurrentProgramArchiveGenre } from '../actions/index';
import AltiboxAssetViewList from '../../../components/UI/AltiboxAssetViewList';
import {
  getProgramArchiveGenreTitle,
  appendFavouriteFlags,
  addLockedSymbolOnInsideOnlyChannelPrograms,
  getCatchupAssetWithLock,
} from '../../../utils/programArchiveUtils';
import './style.scss';
import CategoryHelmet from '../../../components/Helmets/CategoryHelmet';
import { fetchPossibleProgramFavourites } from '../../../api/favorites';
import StickyHeader from '../../../views/app/StickyHeader';
import FullscreenMessage from '../../../components/FullscreenMessage';
import isEmpty from 'lodash/isEmpty';
import { isDisneyProgramAsset } from '../../../utils/altiboxassetUtils';

interface Props {
  channelMode?: boolean;
  isGuest: boolean;
  isHome: boolean;
  isLoading: boolean;
  auth: ApiAuthResponse | undefined;
  authReducer: AuthReducerState;
  loggedInWithCredentials: boolean;
  genreLists: ProgramArchiveList[] | undefined;
  filterGenres: Genre[];
  currentProgramArchiveGenrePrograms: AltiboxAsset[] | undefined;
  archiveGenres: EpgGenre[] | undefined;
  channels: Channel[];
  searchConfig: SearchConfig;
  mostWatchedCatchup: Program[] | undefined;
  getMostWatchedCatchup: () => void;
  getChannels: () => Promise<void>;
  clearCurrentProgramArchiveGenre: () => Function;
  loadCurrentProgramArchiveGenre: (
    filterGenreString: string,
    orderType: string,
    filterGenres: Genre[],
    channels: Channel[],
    offset: number,
  ) => Promise<void>;
  loadAvailableGenres: () => Promise<Genre[]>;
  loadProgramArchiveGenreLists: (areaid: string, bossID: string) => Promise<void>;
}

interface RouteParams {
  genreIds: string;
  orderType: string;
  channelId: string;
  [PopularContentChannelTimespan.TWENTYFOUR_HOURS]: string;
}

interface State {
  title: string | undefined;
  offset: number;
  favouriteIds: string[];
}

class GenreList extends Component<Props & RouteComponentProps<RouteParams> & {}, State> {
  previousLengthOnAssets = 0;
  stopLoading = false;

  state: State = {
    favouriteIds: [],
    title: undefined,
    offset: 0,
  };

  componentWillUnmount() {
    this.props.clearCurrentProgramArchiveGenre();
  }

  componentDidMount() {
    this.loadPrograms();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { state, props } = this;
    if (!state.title && !isEmpty(nextProps.archiveGenres)) {
      let foundTitle = i18n.t<string>('programarchive');

      if (props.channelMode && nextProps.channels) {
        const channel = nextProps.channels.find((x) => x.contentId === this.props.match.params.channelId);
        if (channel) {
          foundTitle = channel.name;
        }
      } else {
        foundTitle = getProgramArchiveGenreTitle(
          nextProps.archiveGenres!,
          this.props.match.params.genreIds,
          this.props.match.params.orderType,
        );
      }

      this.setState({
        title: foundTitle,
      });
    }
  }

  loadChannelsIfNotExist = () => {
    if (!isEmpty(this.props.channels)) {
      return Promise.resolve();
    } else {
      return this.props.getChannels();
    }
  };

  loadFavourites = () => {
    if (!this.props.isGuest && this.props.loggedInWithCredentials && this.state.favouriteIds.length === 0) {
      fetchPossibleProgramFavourites(this.props.channels).then((favorites: string[]) => {
        this.setState({
          favouriteIds: favorites,
        });
      });
    }
  };

  loadPrograms() {
    if (this.props.auth && !this.stopLoading) {
      let genreIds = this.props.match.params.genreIds;
      let orderType = this.props.match.params.orderType;
      let mostWatched = this.props.match.params[PopularContentChannelTimespan.TWENTYFOUR_HOURS];

      if (mostWatched) {
        this.loadChannelsIfNotExist().then(() => {
          this.loadFavourites();
          this.props.getMostWatchedCatchup();
          this.setState({ title: i18n.t<string>('people are watching') });
        });

        return;
      }
      this.loadChannelsIfNotExist().then(() => {
        this.loadFavourites();

        let channels = this.props.channels;
        if (this.props.channelMode && channels) {
          genreIds = '';
          orderType = '3';
          channels = channels.filter((x) => x.contentId === this.props.match.params.channelId);
        }

        if (this.props.auth) {
          this.props
            .loadProgramArchiveGenreLists(this.props.auth.areaid, this.props.auth.bossID)
            .then(this.props.loadAvailableGenres)
            .then(() => {
              this.props
                .loadCurrentProgramArchiveGenre(
                  genreIds,
                  orderType,
                  this.props.filterGenres!,
                  channels!,
                  this.state.offset,
                )
                .then(() => {
                  if (this.props.currentProgramArchiveGenrePrograms) {
                    if (this.previousLengthOnAssets !== this.props.currentProgramArchiveGenrePrograms.length) {
                      this.previousLengthOnAssets = this.props.currentProgramArchiveGenrePrograms.length;
                    } else {
                      this.stopLoading = true;
                    }
                  }
                });
            });
        }
      });
    }
  }

  onScrolledToBottomCallback = () => {
    this.setState(
      {
        offset: this.state.offset + 1,
      },
      this.loadPrograms,
    );
  };

  renderAssets = () => {
    const { props, state } = this;
    const { currentProgramArchiveGenrePrograms, isHome, loggedInWithCredentials, mostWatchedCatchup } = props;
    const emptyCategory = props.match.params.orderType && props.match.params.orderType !== '3';
    const shouldRenderMostWatched =
      props.match.params[PopularContentChannelTimespan.TWENTYFOUR_HOURS] ===
      PopularContentChannelTimespan.TWENTYFOUR_HOURS;
    let assets = currentProgramArchiveGenrePrograms?.filter(negate(isDisneyProgramAsset));

    if (mostWatchedCatchup && shouldRenderMostWatched) {
      assets = mostWatchedCatchup.map((program) =>
        getCatchupAssetWithLock(program, this.props.channels, this.props.authReducer),
      );
      assets = addLockedSymbolOnInsideOnlyChannelPrograms(assets, isHome);
      assets = appendFavouriteFlags(assets, state.favouriteIds);
      return (
        <AltiboxAssetViewList
          assets={assets}
          displayType={AltiboxAssetDisplayTypes.GridView}
          showNumbering={true}
          loggedInWithCredentials={loggedInWithCredentials}
          hideTitleHeader={true}
          onScrolledToBottomCallback={this.onScrolledToBottomCallback}
        />
      );
    } else if (isEmpty(assets) && this.stopLoading) {
      return <h2>{i18n.t<string>('programarchive_empty')}</h2>;
    } else if (emptyCategory) {
      return <FullscreenMessage>{i18n.t('empty category')}</FullscreenMessage>;
    } else if (assets) {
      assets = addLockedSymbolOnInsideOnlyChannelPrograms(assets, isHome);
      assets = appendFavouriteFlags(assets, state.favouriteIds);

      return (
        <AltiboxAssetViewList
          assets={assets}
          displayType={AltiboxAssetDisplayTypes.GridView}
          showNumbering={true}
          loggedInWithCredentials={loggedInWithCredentials}
          hideTitleHeader={true}
          onScrolledToBottomCallback={this.onScrolledToBottomCallback}
        />
      );
    } else {
      return null;
    }
  };
  render() {
    const title = this.state.title ? this.state.title : '';
    let alteredSearchConfig = this.props.searchConfig;
    alteredSearchConfig.returnPath = this.props.location.pathname;
    return (
      <div className="main-frame program-archive">
        <CategoryHelmet categoryTitle={'Program Archive'} title={i18n.t<string>('programarchive') + ' - ' + title} />
        <StickyHeader searchConfig={alteredSearchConfig} />
        <Header title={title} displayType={HeaderDisplayType.Solid} interactionType={HeaderInteractionType.Full} />
        <div className="content-wrapper">{this.renderAssets()}</div>
        {this.props.isLoading ? <Spinner wrapInContainer={true} /> : null}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      isGuest: state.authReducer.isGuest,
      isHome: state.authReducer.isHome,
      auth: state.authReducer.auth,
      authReducer: state.authReducer,
      isLoading: state.programArchiveReducer.currentGenreListIsLoading,
      genreLists: state.programArchiveReducer.epgGenresList,
      filterGenres: state.channelsReducer.genres,
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
      currentProgramArchiveGenrePrograms: state.programArchiveReducer.currentProgramArchiveGenrePrograms,
      archiveGenres: state.programArchiveReducer.epgGenres,
      channels: state.channelsReducer.channels,
      mostWatchedCatchup: state.programArchiveReducer.mostWatchedCatchup,
    }),
    (dispatch) => ({
      getMostWatchedCatchup: () => {
        dispatch(getMostWatchedCatchup());
      },
      getChannels: () => dispatch(getChannels()),
      clearCurrentProgramArchiveGenre: () => dispatch(clearCurrentProgramArchiveGenre()),
      loadAvailableGenres: () => dispatch(getGenres(GenreType.ChannelProgram)),
      loadCurrentProgramArchiveGenre: (
        filterGenreString: string,
        orderType: string,
        filterGenres: Genre[],
        channels: Channel[],
        offset: number,
      ) => dispatch(loadCurrentProgramArchiveGenre(filterGenreString, orderType, filterGenres, channels, offset)),
      loadProgramArchiveGenreLists: (areaid: string, bossID: string) =>
        dispatch(loadProgramArchiveGenreLists(areaid, bossID)),
    }),
  )(GenreList),
);
