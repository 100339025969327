import { Moment } from 'moment';
import './ProgramHeaderStyle.scss';

interface Props {
  showProgramDetailsCallback: () => void;
  startTime: Moment | undefined;
  name: string;
  className?: string;
  episodeNumber: string | undefined;
  markers: JSX.Element;
}

export default function ProgramHeader({
  showProgramDetailsCallback,
  startTime,
  name,
  episodeNumber,
  className,
  markers,
}: Props) {
  const startTimeTitle = startTime ? startTime.calendar() : undefined;

  const programStartTime = startTime ? startTime.format('HH:mm') : '';

  const programName = name;

  const seasonAndEpisode = episodeNumber ? `- ${episodeNumber}` : null;

  const programClassName = 'program-info' + (className ? ` ${className}` : '');

  return (
    <button className={programClassName} onClick={showProgramDetailsCallback}>
      <div className="startTime" title={startTimeTitle}>
        {programStartTime}
      </div>
      <div className="name-episode-season-wrapper">
        <div className="program-name">{programName}</div>
        <div className="season-and-episode">&nbsp;{seasonAndEpisode}</div>
      </div>
      <div className="controls-container">
        <ul className="controls">{markers}</ul>
      </div>
    </button>
  );
}
