/* eslint-disable-next-line */
export default {
  INVALID_USER: '67174404',
  PVR_NOT_FOUND: '85983262',
  NO_FREE_EST_SLOT: '85983787',
  NO_FREE_NON_EST_SLOT: '85983785',
  NON_EST_DEVICE_IS_BLANK: '85983786',
  NO_ACCESS: '117571586',
  MAX_PLAYBACK_SESSIONS: '85983417',
  INCORRECT_PASSWORD_OR_INVALID_USER: '33620481',
  NO_ACTIVE_SUBSCRIBERS: 'NO_ACTIVE_SUBSCRIBERS',
  BLOCKED_FOR_PURCHASE: '85983299',
  COULD_NOT_AIR_PLAY: '515062013',
};

/*
all available errors for reference:

export default {
  "errors": {
    "1": {
      "messageTitleKey": "system.1.title",
      "messageDescriptionKey": "system.1.description",
      "huaweiDescription": "The task is not locked."
    },
    "2": {
      "messageTitleKey": "system.2.title",
      "messageDescriptionKey": "system.2.description",
      "huaweiDescription": "Operation failed."
    },
    "3": {
      "messageTitleKey": "system.3.title",
      "messageDescriptionKey": "system.3.description",
      "huaweiDescription": "The number of profiles exceeded the upper limit."
    },
    "4": {
      "messageTitleKey": "system.4.title",
      "messageDescriptionKey": "system.4.description",
      "huaweiDescription": "The profile does not exist."
    },
    "5": {
      "messageTitleKey": "system.5.title",
      "messageDescriptionKey": "system.5.description",
      "huaweiDescription": "Incorrect password."
    },
    "1099": {
      "messageTitleKey": "system.1099.title",
      "messageDescriptionKey": "system.1099.description",
      "huaweiDescription": "The operation type does not exist."
    },
    "3001": {
      "messageTitleKey": "system.3001.title",
      "messageDescriptionKey": "system.3001.description",
      "huaweiDescription": "Invalid content ID."
    },
    "3002": {
      "messageTitleKey": "system.3002.title",
      "messageDescriptionKey": "system.3002.description",
      "huaweiDescription": "Invalid content type."
    },
    "3003": {
      "messageTitleKey": "system.3003.title",
      "messageDescriptionKey": "system.3003.description",
      "huaweiDescription": "Invalid reminder type."
    },
    "3011": {
      "messageTitleKey": "system.3011.title",
      "messageDescriptionKey": "system.3011.description",
      "huaweiDescription": "The total number of records is invalid."
    },
    "3012": {
      "messageTitleKey": "system.3012.title",
      "messageDescriptionKey": "system.3012.description",
      "huaweiDescription": "The offset parameter is invalid."
    },
    "4001": {
      "messageTitleKey": "system.4001.title",
      "messageDescriptionKey": "system.4001.description",
      "huaweiDescription": "The specified friend does not exist."
    },
    "33619970": {
      "messageTitleKey": "system.33619970.title",
      "messageDescriptionKey": "system.33619970.description",
      "huaweiDescription": "The subscriber account is suspended."
    },
    "33619984": {
      "messageTitleKey": "system.33619984.title",
      "messageDescriptionKey": "system.33619984.description",
      "huaweiDescription": "The number of devices bound to a subscriber reaches the maximum."
    },
    "33620231": {
      "messageTitleKey": "system.33620231.title",
      "messageDescriptionKey": "system.33620231.description",
      "huaweiDescription": "The subscriber account enters the locked state."
    },
    "33620232": {
      "messageTitleKey": "system.33620232.title",
      "messageDescriptionKey": "system.33620232.description",
      "huaweiDescription": "The subscriber account is locked."
    },
    "33620481": {
      "messageTitleKey": "system.33620481.title",
      "messageDescriptionKey": "system.33620481.description",
      "huaweiDescription": "The password is incorrect or the subscriber is invalid."
    },
    "33620483": {
      "messageTitleKey": "system.33620483.title",
      "messageDescriptionKey": "system.33620483.description",
      "huaweiDescription": "The MAC address is used by another subscriber."
    },
    "33620484": {
      "messageTitleKey": "system.33620484.title",
      "messageDescriptionKey": "system.33620484.description",
      "huaweiDescription": "Failed to verify the broadband account."
    },
    "33620737": {
      "messageTitleKey": "system.33620737.title",
      "messageDescriptionKey": "system.33620737.description",
      "huaweiDescription": "cadeviceId signature verification failed."
    },
    "33816577": {
      "messageTitleKey": "system.33816577.title",
      "messageDescriptionKey": "system.33816577.description",
      "huaweiDescription": "Failed to cancel the subscription."
    },
    "33816579": {
      "messageTitleKey": "system.33816579.title",
      "messageDescriptionKey": "system.33816579.description",
      "huaweiDescription": "Only subscription renewal cancellation is supported."
    },
    "33816580": {
      "messageTitleKey": "system.33816580.title",
      "messageDescriptionKey": "system.33816580.description",
      "huaweiDescription": "No subscription record."
    },
    "67174404": {
      "messageTitleKey": "system.67174404.title",
      "messageDescriptionKey": "system.67174404.description",
      "huaweiDescription": "The subscriber does not exist or is invalid."
    },
    "67176601": {
      "messageTitleKey": "system.67176601.title",
      "messageDescriptionKey": "system.67176601.description",
      "huaweiDescription": "The subscriber's token is invalid."
    },
    "67242120": {
      "messageTitleKey": "system.67242120.title",
      "messageDescriptionKey": "system.67242120.description",
      "huaweiDescription": "The content does not exist."
    },
    "83886081": {
      "messageTitleKey": "system.83886081.title",
      "messageDescriptionKey": "system.83886081.description",
      "huaweiDescription": "Self-defined channel numbers or system channel numbers are duplicate."
    },
    "83886082": {
      "messageTitleKey": "system.83886082.title",
      "messageDescriptionKey": "system.83886082.description",
      "huaweiDescription": "Access to the database is abnormal."
    },
    "83886083": {
      "messageTitleKey": "system.83886083.title",
      "messageDescriptionKey": "system.83886083.description",
      "huaweiDescription": "Session errors occur."
    },
    "83886084": {
      "messageTitleKey": "system.83886084.title",
      "messageDescriptionKey": "system.83886084.description",
      "huaweiDescription": "The subscriber is in the bypass state after the device authentication."
    },
    "83886088": {
      "messageTitleKey": "system.83886088.title",
      "messageDescriptionKey": "system.83886088.description",
      "huaweiDescription": "The subscriber is in the bypass state."
    },
    "83890082": {
      "messageTitleKey": "system.83890082.title",
      "messageDescriptionKey": "system.83890082.description",
      "huaweiDescription": "The subscriber who recommends the content and the friend are on different regional nodes."
    },
    "85983233": {
      "messageTitleKey": "system.85983233.title",
      "messageDescriptionKey": "system.85983233.description",
      "huaweiDescription": "The subscriber does not exist."
    },
    "85983234": {
      "messageTitleKey": "system.85983234.title",
      "messageDescriptionKey": "system.85983234.description",
      "huaweiDescription": "The subscriber state is incorrect or the subscriber account is suspended."
    },
    "85983235": {
      "messageTitleKey": "system.85983235.title",
      "messageDescriptionKey": "system.85983235.description",
      "huaweiDescription": "The password is incorrect."
    },
    "85983236": {
      "messageTitleKey": "system.85983236.title",
      "messageDescriptionKey": "system.85983236.description",
      "huaweiDescription": "Only the super profile has the right to change the password."
    },
    "85983237": {
      "messageTitleKey": "system.85983237.title",
      "messageDescriptionKey": "system.85983237.description",
      "huaweiDescription": "The new password and confirm password are different."
    },
    "85983240": {
      "messageTitleKey": "system.85983240.title",
      "messageDescriptionKey": "system.85983240.description",
      "huaweiDescription": "The device verification failed."
    },
    "85983243": {
      "messageTitleKey": "system.85983243.title",
      "messageDescriptionKey": "system.85983243.description",
      "huaweiDescription": "The logical device ID does not exist."
    },
    "85983247": {
      "messageTitleKey": "system.85983247.title",
      "messageDescriptionKey": "system.85983247.description",
      "huaweiDescription": "The product does not exist."
    },
    "85983250": {
      "messageTitleKey": "system.85983250.title",
      "messageDescriptionKey": "system.85983250.description",
      "huaweiDescription": "The product has been subscribed."
    },
    "85983251": {
      "messageTitleKey": "system.85983251.title",
      "messageDescriptionKey": "system.85983251.description",
      "huaweiDescription": "The content type format is invalid. (retcode=-1)"
    },
    "85983252": {
      "messageTitleKey": "system.85983252.title",
      "messageDescriptionKey": "system.85983252.description",
      "huaweiDescription": "The content does not exist."
    },
    "85983254": {
      "messageTitleKey": "system.85983254.title",
      "messageDescriptionKey": "system.85983254.description",
      "huaweiDescription": "The quantity of personalized data reaches the maximum."
    },
    "85983255": {
      "messageTitleKey": "system.85983255.title",
      "messageDescriptionKey": "system.85983255.description",
      "huaweiDescription": "The subscriber cannot subscribe to programs or program
      feature products of the channel because the subscriber has not subscribed to
      any PPV feature product or any bundle containing the PPV feature product of
      the channel."
    },
    "85983258": {
      "messageTitleKey": "system.85983258.title",
      "messageDescriptionKey": "system.85983258.description",
      "huaweiDescription": "The product does not support subscription by carrier bonus points."
    },
    "85983259": {
      "messageTitleKey": "system.85983259.title",
      "messageDescriptionKey": "system.85983259.description",
      "huaweiDescription": "The cPVR task must be delivered again."
    },
    "85983260": {
      "messageTitleKey": "system.85983260.title",
      "messageDescriptionKey": "system.85983260.description",
      "huaweiDescription": "The PVR record is deleted."
    },
    "85983262": {
      "messageTitleKey": "system.85983262.title",
      "messageDescriptionKey": "system.85983262.description",
      "huaweiDescription": "The PVR task does not exist."
    },
    "85983263": {
      "messageTitleKey": "system.85983263.title",
      "messageDescriptionKey": "system.85983263.description",
      "huaweiDescription": "The PVR task status cannot be updated.
       The status only of the PVR tasks whose status value is -2, -1, or 1 can be updated."
    },
    "85983264": {
      "messageTitleKey": "system.85983264.title",
      "messageDescriptionKey": "system.85983264.description",
      "huaweiDescription": "This error code indicates the following possible errors:\n
        The bill cycle–based consumption limit of the profile is insufficient.\n
        The bill cycle–based consumption limit of the subscriber is insufficient.\n
        The periodic consumption amount limit of the subscriber is insufficient.\n
        The periodic consumption times limit of the subscriber is insufficient."
    },
    "85983265": {
      "messageTitleKey": "system.85983265.title",
      "messageDescriptionKey": "system.85983265.description",
      "huaweiDescription": "IB processing errors occur."
    },
    "85983266": {
      "messageTitleKey": "system.85983266.title",
      "messageDescriptionKey": "system.85983266.description",
      "huaweiDescription": "The profile cannot delete itself because the profile has not been deregistered."
    },
    "85983267": {
      "messageTitleKey": "system.85983267.title",
      "messageDescriptionKey": "system.85983267.description",
      "huaweiDescription": "The number of bound devices reaches the maximum."
    },
    "85983269": {
      "messageTitleKey": "system.85983269.title",
      "messageDescriptionKey": "system.85983269.description",
      "huaweiDescription": "The profile is online."
    },
    "85983272": {
      "messageTitleKey": "system.85983272.title",
      "messageDescriptionKey": "system.85983272.description",
      "huaweiDescription": "The upper limit of the devices has been reached. The subscriber cannot log in using the device."
    },
    "85983274": {
      "messageTitleKey": "system.85983274.title",
      "messageDescriptionKey": "system.85983274.description",
      "huaweiDescription": "Failed to add a task because the nPVR network space is insufficient. No record is generated for the task."
    },
    "85983281": {
      "messageTitleKey": "system.85983281.title",
      "messageDescriptionKey": "system.85983281.description",
      "huaweiDescription": "The recording task exists. This error code is displayed only when checkExist is set to 1."
    },
    "85983282": {
      "messageTitleKey": "system.85983282.title",
      "messageDescriptionKey": "system.85983282.description",
      "huaweiDescription": "The nPVR network space is insufficient."
    },
    "85983283": {
      "messageTitleKey": "system.85983283.title",
      "messageDescriptionKey": "system.85983283.description",
      "huaweiDescription": "The program reminder exists."
    },
    "85983284": {
      "messageTitleKey": "system.85983284.title",
      "messageDescriptionKey": "system.85983284.description",
      "huaweiDescription": "The program to which the reminder is added has started."
    },
    "85983285": {
      "messageTitleKey": "system.85983285.title",
      "messageDescriptionKey": "system.85983285.description",
      "huaweiDescription": "The channel media file does not support the nPVR."
    },
    "85983286": {
      "messageTitleKey": "system.85983286.title",
      "messageDescriptionKey": "system.85983286.description",
      "huaweiDescription": "The channel media file does not support the cPVR."
    },
    "85983287": {
      "messageTitleKey": "system.85983287.title",
      "messageDescriptionKey": "system.85983287.description",
      "huaweiDescription": "The subscriber does not subscribe to the nPVR."
    },
    "85983288": {
      "messageTitleKey": "system.85983288.title",
      "messageDescriptionKey": "system.85983288.description",
      "huaweiDescription": "The number of concurrent PVR tasks exceeds the upper limit."
    },
    "85983291": {
      "messageTitleKey": "system.85983291.title",
      "messageDescriptionKey": "system.85983291.description",
      "huaweiDescription": "Failed to notify the MDN."
    },
    "85983299": {
      "messageTitleKey": "system.85983299.title",
      "messageDescriptionKey": "system.85983299.description",
      "huaweiDescription": "Online subscription is not supported."
    },
    "85983300": {
      "messageTitleKey": "system.85983300.title",
      "messageDescriptionKey": "system.85983300.description",
      "huaweiDescription": "If the number of times for unbinding a device reaches the maximum, the device fails to be replaced."
    },
    "85983302": {
      "messageTitleKey": "system.85983302.title",
      "messageDescriptionKey": "system.85983302.description",
      "huaweiDescription": "The device does not exist."
    },
    "85983303": {
      "messageTitleKey": "system.85983303.title",
      "messageDescriptionKey": "system.85983303.description",
      "huaweiDescription": "The system load reaches the maximum."
    },
    "85983304": {
      "messageTitleKey": "system.85983304.title",
      "messageDescriptionKey": "system.85983304.description",
      "huaweiDescription": "The schedule does not support the cPVR."
    },
    "85983305": {
      "messageTitleKey": "system.85983305.title",
      "messageDescriptionKey": "system.85983305.description",
      "huaweiDescription": "The program does not support the nPVR."
    },
    "85983312": {
      "messageTitleKey": "system.85983312.title",
      "messageDescriptionKey": "system.85983312.description",
      "huaweiDescription": "This error code is reserved."
    },
    "85983313": {
      "messageTitleKey": "system.85983313.title",
      "messageDescriptionKey": "system.85983313.description",
      "huaweiDescription": "This error code is reserved."
    },
    "85983314": {
      "messageTitleKey": "system.85983314.title",
      "messageDescriptionKey": "system.85983314.description",
      "huaweiDescription": "Failed to notify the CA."
    },
    "85983315": {
      "messageTitleKey": "system.85983315.title",
      "messageDescriptionKey": "system.85983315.description",
      "huaweiDescription": "The device is invalid during device initialization."
    },
    "85983330": {
      "messageTitleKey": "system.85983330.title",
      "messageDescriptionKey": "system.85983330.description",
      "huaweiDescription": "The CA is abnormal."
    },
    "85983333": {
      "messageTitleKey": "system.85983333.title",
      "messageDescriptionKey": "system.85983333.description",
      "huaweiDescription": "The information about the discount price does not exist."
    },
    "85983355": {
      "messageTitleKey": "system.85983355.title",
      "messageDescriptionKey": "system.85983355.description",
      "huaweiDescription": "Dependency relationships of the product to be subscribed to exist, and the product cannot be subscribed."
    },
    "85983362": {
      "messageTitleKey": "system.85983362.title",
      "messageDescriptionKey": "system.85983362.description",
      "huaweiDescription": "Failed to find the corresponding error code information."
    },
    "85983369": {
      "messageTitleKey": "system.85983369.title",
      "messageDescriptionKey": "system.85983369.description",
      "huaweiDescription": "Subscription using bonus points fails due to insufficient bonus points."
    },
    "85983372": {
      "messageTitleKey": "system.85983372.title",
      "messageDescriptionKey": "system.85983372.description",
      "huaweiDescription": "Geographical location authentication failed."
    },
    "85983373": {
      "messageTitleKey": "system.85983373.title",
      "messageDescriptionKey": "system.85983373.description",
      "huaweiDescription": "Device IP address is in the blacklist."
    },
    "85983374": {
      "messageTitleKey": "system.85983374.title",
      "messageDescriptionKey": "system.85983374.description",
      "huaweiDescription": "The device type reported during authentication is invalid."
    },
    "85983375": {
      "messageTitleKey": "system.85983375.title",
      "messageDescriptionKey": "system.85983375.description",
      "huaweiDescription": "The device does not support the reported CA device type."
    },
    "85983377": {
      "messageTitleKey": "system.85983377.title",
      "messageDescriptionKey": "system.85983377.description",
      "huaweiDescription": "The loginName value reported during authentication is not registered."
    },
    "85983378": {
      "messageTitleKey": "system.85983378.title",
      "messageDescriptionKey": "system.85983378.description",
      "huaweiDescription": "The profile does not belong to any subscribers."
    },
    "85983382": {
      "messageTitleKey": "system.85983382.title",
      "messageDescriptionKey": "system.85983382.description",
      "huaweiDescription": "Authentication failed. The subscriber has subscribed to the content, but the current device cannot play the content."
    },
    "85983383": {
      "messageTitleKey": "system.85983383.title",
      "messageDescriptionKey": "system.85983383.description",
      "huaweiDescription": "Authentication failed. No available device exists for playing the content."
    },
    "85983384": {
      "messageTitleKey": "system.85983384.title",
      "messageDescriptionKey": "system.85983384.description",
      "huaweiDescription": "The device vendor or operating system version is not allowed for login."
    },
    "85983385": {
      "messageTitleKey": "system.85983385.title",
      "messageDescriptionKey": "system.85983385.description",
      "huaweiDescription": "Query failed."
    },
    "85983387": {
      "messageTitleKey": "system.85983387.title",
      "messageDescriptionKey": "system.85983387.description",
      "huaweiDescription": "The mobile number to be changed has been used by another subscriber."
    },
    "85983391": {
      "messageTitleKey": "system.85983391.title",
      "messageDescriptionKey": "system.85983391.description",
      "huaweiDescription": "The profile is not allowed to log in to the device group."
    },
    "85983392": {
      "messageTitleKey": "system.85983392.title",
      "messageDescriptionKey": "system.85983392.description",
      "huaweiDescription": "The number of online sessions of the device group to which the profile
        belongs reaches the upper limit. For example, when a profile that has logged in using an OTT
        device attempts to log in using another OTT device, this error code is returned."
    },
    "85983397": {
      "messageTitleKey": "system.85983397.title",
      "messageDescriptionKey": "system.85983397.description",
      "huaweiDescription": "The coupon cannot be used for the product/pricing object."
    },
    "85983398": {
      "messageTitleKey": "system.85983398.title",
      "messageDescriptionKey": "system.85983398.description",
      "huaweiDescription": "The coupon has been used."
    },
    "85983399": {
      "messageTitleKey": "system.85983399.title",
      "messageDescriptionKey": "system.85983399.description",
      "huaweiDescription": "The number of times the coupon is used reaches the upper limit."
    },
    "85983401": {
      "messageTitleKey": "system.85983401.title",
      "messageDescriptionKey": "system.85983401.description",
      "huaweiDescription": "Failed to update loginName. The mobile phone number is occupied."
    },
    "85983402": {
      "messageTitleKey": "system.85983402.title",
      "messageDescriptionKey": "system.85983402.description",
      "huaweiDescription": "Failed to query all products."
    },
    "85983403": {
      "messageTitleKey": "system.85983403.title",
      "messageDescriptionKey": "system.85983403.description",
      "huaweiDescription": "Failed to query product information based on the service ID."
    },
    "85983405": {
      "messageTitleKey": "system.85983405.title",
      "messageDescriptionKey": "system.85983405.description",
      "huaweiDescription": "Failed to query the number of spare profiles."
    },
    "85983406": {
      "messageTitleKey": "system.85983406.title",
      "messageDescriptionKey": "system.85983406.description",
      "huaweiDescription": "The subscriber does not log in."
    },
    "85983407": {
      "messageTitleKey": "system.85983407.title",
      "messageDescriptionKey": "system.85983407.description",
      "huaweiDescription": "The number of profile slots reaches the upper limit."
    },
    "85983414": {
      "messageTitleKey": "system.85983414.title",
      "messageDescriptionKey": "system.85983414.description",
      "huaweiDescription": "Online device binding is not allowed."
    },
    "85983417": {
      "messageTitleKey": "system.85983417.title",
      "messageDescriptionKey": "system.85983417.description",
      "huaweiDescription": "The number of playback sessions exceeds the maximum."
    },
    "85983418": {
      "messageTitleKey": "system.85983418.title",
      "messageDescriptionKey": "system.85983418.description",
      "huaweiDescription": "The unsubscription is not allowed because the subscriber's
        logical devices that can be deleted do not meet the requirement of the user resource service product to be unsubscribed from."
    },
    "85983419": {
      "messageTitleKey": "system.85983419.title",
      "messageDescriptionKey": "system.85983419.description",
      "huaweiDescription": "The unsubscription through a third-party system fails."
    },
    "85983420": {
      "messageTitleKey": "system.85983420.title",
      "messageDescriptionKey": "system.85983420.description",
      "huaweiDescription": "The subscription through a third-party system fails."
    },
    "85983422": {
      "messageTitleKey": "system.85983422.title",
      "messageDescriptionKey": "system.85983422.description",
      "huaweiDescription": "In the Turkcell RadiusProxy authentication mode, the APN corresponding to the device IP address does not exist."
    },
    "85983423": {
      "messageTitleKey": "system.85983423.title",
      "messageDescriptionKey": "system.85983423.description",
      "huaweiDescription": "When a mobile phone accesses using the Turkcell WAP APN mode, the HTTP header does not carry
        the User-Identity-Forward-msisdn parameter."
    },
    "85983424": {
      "messageTitleKey": "system.85983424.title",
      "messageDescriptionKey": "system.85983424.description",
      "huaweiDescription": "Auto-login is not supported. You must enter the user name and password."
    },
    "85983425": {
      "messageTitleKey": "system.85983425.title",
      "messageDescriptionKey": "system.85983425.description",
      "huaweiDescription": "The request to replace the TINT product has been successfully submitted."
    },
    "85983426": {
      "messageTitleKey": "system.85983426.title",
      "messageDescriptionKey": "system.85983426.description",
      "huaweiDescription": "The request to replace the SOL product has been successfully submitted."
    },
    "85983427": {
      "messageTitleKey": "system.85983427.title",
      "messageDescriptionKey": "system.85983427.description",
      "huaweiDescription": "The communication with a third-party system fails.\nNOTE: The error code is customized for the IPTV domain."
    },
    "85983428": {
      "messageTitleKey": "system.85983428.title",
      "messageDescriptionKey": "system.85983428.description",
      "huaweiDescription": "Failed to replace the product."
    },
    "85983429": {
      "messageTitleKey": "system.85983429.title",
      "messageDescriptionKey": "system.85983429.description",
      "huaweiDescription": "A subscription request of the product is being processed. Repeated subscription is not allowed."
    },
    "85983430": {
      "messageTitleKey": "system.85983430.title",
      "messageDescriptionKey": "system.85983430.description",
      "huaweiDescription": "The subscription request of the main package product is in process. Repeated subscription is not allowed."
    },
    "85983431": {
      "messageTitleKey": "system.85983431.title",
      "messageDescriptionKey": "system.85983431.description",
      "huaweiDescription": "Failed to update loginName. The LDAP verification fails."
    },
    "85983432": {
      "messageTitleKey": "system.85983432.title",
      "messageDescriptionKey": "system.85983432.description",
      "huaweiDescription": "Failed to obtain mobile numbers using Turkcell Radius Proxy."
    },
    "85983433": {
      "messageTitleKey": "system.85983433.title",
      "messageDescriptionKey": "system.85983433.description",
      "huaweiDescription": "Failed to log in using WAP. No MTV devices exist."
    },
    "85983434": {
      "messageTitleKey": "system.85983434.title",
      "messageDescriptionKey": "system.85983434.description",
      "huaweiDescription": "The number of devices on which a subscriber plays the content exceeds the maximum."
    },
    "85983436": {
      "messageTitleKey": "system.85983436.title",
      "messageDescriptionKey": "system.85983436.description",
      "huaweiDescription": "Error code customized for the Etisalat site, indicating that a subscriber's More system account is not activated."
    },
    "85983438": {
      "messageTitleKey": "system.85983438.title",
      "messageDescriptionKey": "system.85983438.description",
      "huaweiDescription": "OTT accounts are replaced with Hybrid Video accounts, and you must use the Hybrid Video accounts to log in. The error
        code is customized for the Saudi Telecom Company site."
    },
    "85983439": {
      "messageTitleKey": "system.85983439.title",
      "messageDescriptionKey": "system.85983439.description",
      "huaweiDescription": "The request to replace the product is in process."
    },
    "85983440": {
      "messageTitleKey": "system.85983440.title",
      "messageDescriptionKey": "system.85983440.description",
      "huaweiDescription": "The coupon does not exist."
    },
    "85983442": {
      "messageTitleKey": "system.85983442.title",
      "messageDescriptionKey": "system.85983442.description",
      "huaweiDescription": "The profile exists under the subscriber, and does not need to be attached."
    },
    "85983443": {
      "messageTitleKey": "system.85983443.title",
      "messageDescriptionKey": "system.85983443.description",
      "huaweiDescription": "Failed to update loginName. The LDAP fails to be connected."
    },
    "85983444": {
      "messageTitleKey": "system.85983444.title",
      "messageDescriptionKey": "system.85983444.description",
      "huaweiDescription": "The TINT login and registration are not supported."
    },
    "85983445": {
      "messageTitleKey": "system.85983445.title",
      "messageDescriptionKey": "system.85983445.description",
      "huaweiDescription": "The channel namespace does not exist."
    },
    "85983446": {
      "messageTitleKey": "system.85983446.title",
      "messageDescriptionKey": "system.85983446.description",
      "huaweiDescription": "The subscriber account does not exist."
    },
    "85983449": {
      "messageTitleKey": "system.85983449.title",
      "messageDescriptionKey": "system.85983449.description",
      "huaweiDescription": "The key ID of the content or product is blank."
    },
    "85983450": {
      "messageTitleKey": "system.85983450.title",
      "messageDescriptionKey": "system.85983450.description",
      "huaweiDescription": "The channel does not support the TSTV service."
    },
    "85983451": {
      "messageTitleKey": "system.85983451.title",
      "messageDescriptionKey": "system.85983451.description",
      "huaweiDescription": "The product does not support online subscription."
    },
    "85983452": {
      "messageTitleKey": "system.85983452.title",
      "messageDescriptionKey": "system.85983452.description",
      "huaweiDescription": "The subscriber is restricted and cannot subscribe to the product."
    },
    "85983454": {
      "messageTitleKey": "system.85983454.title",
      "messageDescriptionKey": "system.85983454.description",
      "huaweiDescription": "The PlayReady server information has not been configured."
    },
    "85983455": {
      "messageTitleKey": "system.85983455.title",
      "messageDescriptionKey": "system.85983455.description",
      "huaweiDescription": "An error occurs during the generation of the trigger."
    },
    "85983456": {
      "messageTitleKey": "system.85983456.title",
      "messageDescriptionKey": "system.85983456.description",
      "huaweiDescription": "The bookmark cannot be added."
    },
    "85983461": {
      "messageTitleKey": "system.85983461.title",
      "messageDescriptionKey": "system.85983461.description",
      "huaweiDescription": "This is not the PlayReady client."
    },
    "85983462": {
      "messageTitleKey": "system.85983462.title",
      "messageDescriptionKey": "system.85983462.description",
      "huaweiDescription": "Repeated rating."
    },
    "85983463": {
      "messageTitleKey": "system.85983463.title",
      "messageDescriptionKey": "system.85983463.description",
      "huaweiDescription": "The subscriber has registered."
    },
    "85983465": {
      "messageTitleKey": "system.85983465.title",
      "messageDescriptionKey": "system.85983465.description",
      "huaweiDescription": "Operation failure due to other reason."
    },
    "85983467": {
      "messageTitleKey": "system.85983467.title",
      "messageDescriptionKey": "system.85983467.description",
      "huaweiDescription": "Presubscription cancellation fails."
    },
    "85983468": {
      "messageTitleKey": "system.85983468.title",
      "messageDescriptionKey": "system.85983468.description",
      "huaweiDescription": "Presubscription fails."
    },
    "85983469": {
      "messageTitleKey": "system.85983469.title",
      "messageDescriptionKey": "system.85983469.description",
      "huaweiDescription": "Order creation fails."
    },
    "85983470": {
      "messageTitleKey": "system.85983470.title",
      "messageDescriptionKey": "system.85983470.description",
      "huaweiDescription": "Order confirmation fails."
    },
    "85983471": {
      "messageTitleKey": "system.85983471.title",
      "messageDescriptionKey": "system.85983471.description",
      "huaweiDescription": "Failed to update loginName. The number of times you are allowed to update loginName reaches the upper limit."
    },
    "85983473": {
      "messageTitleKey": "system.85983473.title",
      "messageDescriptionKey": "system.85983473.description",
      "huaweiDescription": "TVMS messages fail to be sent."
    },
    "85983474": {
      "messageTitleKey": "system.85983474.title",
      "messageDescriptionKey": "system.85983474.description",
      "huaweiDescription": "The subscriber group does not exist."
    },
    "85983475": {
      "messageTitleKey": "system.85983475.title",
      "messageDescriptionKey": "system.85983475.description",
      "huaweiDescription": "The number of devices bound to a subscriber reaches the maximum."
    },
    "85983476": {
      "messageTitleKey": "system.85983476.title",
      "messageDescriptionKey": "system.85983476.description",
      "huaweiDescription": "The number of subscribers associated with the group reaches the maximum."
    },
    "85983477": {
      "messageTitleKey": "system.85983477.title",
      "messageDescriptionKey": "system.85983477.description",
      "huaweiDescription": "The product cannot be subscribed to at the discount price because the discount price has expired."
    },
    "85983478": {
      "messageTitleKey": "system.85983478.title",
      "messageDescriptionKey": "system.85983478.description",
      "huaweiDescription": "Payment result query fails."
    },
    "85983479": {
      "messageTitleKey": "system.85983479.title",
      "messageDescriptionKey": "system.85983479.description",
      "huaweiDescription": "The presubscription relationship exists. Repeated presubscription is not allowed."
    },
    "85983480": {
      "messageTitleKey": "system.85983480.title",
      "messageDescriptionKey": "system.85983480.description",
      "huaweiDescription": "The ruleId value does not exist in the system."
    },
    "85983481": {
      "messageTitleKey": "system.85983481.title",
      "messageDescriptionKey": "system.85983481.description",
      "huaweiDescription": "Operation failed because the remaining number of times is insufficient for this exchange operation."
    },
    "85983482": {
      "messageTitleKey": "system.85983482.title",
      "messageDescriptionKey": "system.85983482.description",
      "huaweiDescription": "The SOL subscriber's request to use a promo code for subscription renewal of the duration-based product is rejected
        because the subscriber has an IPTV STB."
    },
    "85983483": {
      "messageTitleKey": "system.85983483.title",
      "messageDescriptionKey": "system.85983483.description",
      "huaweiDescription": "The input expiration time is earlier than the current time."
    },
    "85983487": {
      "messageTitleKey": "system.85983487.title",
      "messageDescriptionKey": "system.85983487.description",
      "huaweiDescription": "The subscriber has not subscribed to the content."
    },
    "85983489": {
      "messageTitleKey": "system.85983489.title",
      "messageDescriptionKey": "system.85983489.description",
      "huaweiDescription": "Payment record query fails."
    },
    "85983490": {
      "messageTitleKey": "system.85983490.title",
      "messageDescriptionKey": "system.85983490.description",
      "huaweiDescription": "Query failed."
    },
    "85983492": {
      "messageTitleKey": "system.85983492.title",
      "messageDescriptionKey": "system.85983492.description",
      "huaweiDescription": "OCS operation failed."
    },
    "85983494": {
      "messageTitleKey": "system.85983494.title",
      "messageDescriptionKey": "system.85983494.description",
      "huaweiDescription": "System errors occur."
    },
    "85983495": {
      "messageTitleKey": "system.85983495.title",
      "messageDescriptionKey": "system.85983495.description",
      "huaweiDescription": "The available space is insufficient for recording a subtask when the operation type is PREADD."
    },
    "85983496": {
      "messageTitleKey": "system.85983496.title",
      "messageDescriptionKey": "system.85983496.description",
      "huaweiDescription": "The available space supports only some subtasks of a parent task when the operation type is PREADD."
    },
    "85983497": {
      "messageTitleKey": "system.85983497.title",
      "messageDescriptionKey": "system.85983497.description",
      "huaweiDescription": "Failed to request the third-party BOSS to perform operations due to incorrect configuration."
    },
    "85983498": {
      "messageTitleKey": "system.85983498.title",
      "messageDescriptionKey": "system.85983498.description",
      "huaweiDescription": "Third-party BOSS self-registration failed."
    },
    "85983499": {
      "messageTitleKey": "system.85983499.title",
      "messageDescriptionKey": "system.85983499.description",
      "huaweiDescription": "Third-party BOSS self-registration succeeded, but importing the UMS self-registration data to the database failed."
    },
    "85983500": {
      "messageTitleKey": "system.85983500.title",
      "messageDescriptionKey": "system.85983500.description",
      "huaweiDescription": "Third-party BOSS successfully processed product replacement requests, but the UMS failed."
    },
    "85983501": {
      "messageTitleKey": "system.85983501.title",
      "messageDescriptionKey": "system.85983501.description",
      "huaweiDescription": "Subscription by the third-party BOSS succeeds, but that by the UMS fails."
    },
    "85983502": {
      "messageTitleKey": "system.85983502.title",
      "messageDescriptionKey": "system.85983502.description",
      "huaweiDescription": "Failed to bind the third-party BOSS."
    },
    "85983503": {
      "messageTitleKey": "system.85983503.title",
      "messageDescriptionKey": "system.85983503.description",
      "huaweiDescription": "Binding the third-party BOSS succeeded, but binding the UMS failed."
    },
    "85983507": {
      "messageTitleKey": "system.85983507.title",
      "messageDescriptionKey": "system.85983507.description",
      "huaweiDescription": "The product is not an A La Carte product."
    },
    "85983508": {
      "messageTitleKey": "system.85983508.title",
      "messageDescriptionKey": "system.85983508.description",
      "huaweiDescription": "The content selected is not in the A La Carte product."
    },
    "85983509": {
      "messageTitleKey": "system.85983509.title",
      "messageDescriptionKey": "system.85983509.description",
      "huaweiDescription": "Cannot select the content because the subscriber does not subscribe to the A La Carte product."
    },
    "85983510": {
      "messageTitleKey": "system.85983510.title",
      "messageDescriptionKey": "system.85983510.description",
      "huaweiDescription": "Exceeds the remaining number of content items that the subscriber is allowed to select in the A La Carte product."
    },
    "85983511": {
      "messageTitleKey": "system.85983511.title",
      "messageDescriptionKey": "system.85983511.description",
      "huaweiDescription": "Failed to select the content in the A La Carte product due to other errors."
    },
    "85983512": {
      "messageTitleKey": "system.85983512.title",
      "messageDescriptionKey": "system.85983512.description",
      "huaweiDescription": "Fails to query pricing objects in the bundle."
    },
    "85983513": {
      "messageTitleKey": "system.85983513.title",
      "messageDescriptionKey": "system.85983513.description",
      "huaweiDescription": "Failed to query the selected content."
    },
    "85983514": {
      "messageTitleKey": "system.85983514.title",
      "messageDescriptionKey": "system.85983514.description",
      "huaweiDescription": "The profile does not have the access permission."
    },
    "85983515": {
      "messageTitleKey": "system.85983515.title",
      "messageDescriptionKey": "system.85983515.description",
      "huaweiDescription": "Failed to query bills in the BOSS due to other errors."
    },
    "85983516": {
      "messageTitleKey": "system.85983516.title",
      "messageDescriptionKey": "system.85983516.description",
      "huaweiDescription": "Failed to query bills."
    },
    "85983518": {
      "messageTitleKey": "system.85983518.title",
      "messageDescriptionKey": "system.85983518.description",
      "huaweiDescription": "The playback URL fails to be obtained, and a message is displayed indicating that the URL does not exist."
    },
    "85983519": {
      "messageTitleKey": "system.85983519.title",
      "messageDescriptionKey": "system.85983519.description",
      "huaweiDescription": "Invalid mobile number length (less than 10 digits) during RADIUS authentication in Turkey."
    },
    "85983520": {
      "messageTitleKey": "system.85983520.title",
      "messageDescriptionKey": "system.85983520.description",
      "huaweiDescription": "The subscriber account is deleted."
    },
    "85983521": {
      "messageTitleKey": "system.85983521.title",
      "messageDescriptionKey": "system.85983521.description",
      "huaweiDescription": "The subscriber account is suspended."
    },
    "85983522": {
      "messageTitleKey": "system.85983522.title",
      "messageDescriptionKey": "system.85983522.description",
      "huaweiDescription": "The online device is deleted."
    },
    "85983523": {
      "messageTitleKey": "system.85983523.title",
      "messageDescriptionKey": "system.85983523.description",
      "huaweiDescription": "The online device is unbound."
    },
    "85983524": {
      "messageTitleKey": "system.85983524.title",
      "messageDescriptionKey": "system.85983524.description",
      "huaweiDescription": "The number of the subscriber's online sessions exceeded the upper limit."
    },
    "85983525": {
      "messageTitleKey": "system.85983525.title",
      "messageDescriptionKey": "system.85983525.description",
      "huaweiDescription": "The number of the profile's online sessions exceeded the upper limit."
    },
    "85983526": {
      "messageTitleKey": "system.85983526.title",
      "messageDescriptionKey": "system.85983526.description",
      "huaweiDescription": "The profile is deleted."
    },
    "85983527": {
      "messageTitleKey": "system.85983527.title",
      "messageDescriptionKey": "system.85983527.description",
      "huaweiDescription": "The subscriber (including the device and profile) is not authenticated."
    },
    "85983528": {
      "messageTitleKey": "system.85983528.title",
      "messageDescriptionKey": "system.85983528.description",
      "huaweiDescription": "The token of the specified device is not found, and the device cannot receive the message."
    },
    "85983529": {
      "messageTitleKey": "system.85983529.title",
      "messageDescriptionKey": "system.85983529.description",
      "huaweiDescription": "The device has been used for login on another server."
    },
    "85983530": {
      "messageTitleKey": "system.85983530.title",
      "messageDescriptionKey": "system.85983530.description",
      "huaweiDescription": "The number in the BSS is changed on the same phone. (This error code is used only for sites in China.)"
    },
    "85983531": {
      "messageTitleKey": "system.85983531.title",
      "messageDescriptionKey": "system.85983531.description",
      "huaweiDescription": "The subscriber is blacklisted."
    },
    "85983534": {
      "messageTitleKey": "system.85983534.title",
      "messageDescriptionKey": "system.85983534.description",
      "huaweiDescription": "The number of OTT unicast sessions reaches the maximum."
    },
    "85983538": {
      "messageTitleKey": "system.85983538.title",
      "messageDescriptionKey": "system.85983538.description",
      "huaweiDescription": "The service subscribed to by the subscriber is of the SAT-only type, which does not support authentication."
    },
    "85983539": {
      "messageTitleKey": "system.85983539.title",
      "messageDescriptionKey": "system.85983539.description",
      "huaweiDescription": "The session is terminated because the type of the service subscribed to by the subscriber changes."
    },
    "85983540": {
      "messageTitleKey": "system.85983540.title",
      "messageDescriptionKey": "system.85983540.description",
      "huaweiDescription": "Submission to the third-party BOSS succeeds."
    },
    "85983541": {
      "messageTitleKey": "system.85983541.title",
      "messageDescriptionKey": "system.85983541.description",
      "huaweiDescription": "The token-based authentication fails at the Turkcell site."
    },
    "85983542": {
      "messageTitleKey": "system.85983542.title",
      "messageDescriptionKey": "system.85983542.description",
      "huaweiDescription": "Failed to set the password. The password is the same as the account or the reverse account."
    },
    "85983545": {
      "messageTitleKey": "system.85983545.title",
      "messageDescriptionKey": "system.85983545.description",
      "huaweiDescription": "The license is insufficient."
    },
    "85983547": {
      "messageTitleKey": "system.85983547.title",
      "messageDescriptionKey": "system.85983547.description",
      "huaweiDescription": "The device does not belong to the subscriber."
    },
    "85983549": {
      "messageTitleKey": "system.85983549.title",
      "messageDescriptionKey": "system.85983549.description",
      "huaweiDescription": "The IP address has changed and the subscriber is logged out."
    },
    "85983552": {
      "messageTitleKey": "system.85983552.title",
      "messageDescriptionKey": "system.85983552.description",
      "huaweiDescription": "The subscriber is forcibly logged out using the device because the subscriber has logged in using another device."
    },
    "85983553": {
      "messageTitleKey": "system.85983553.title",
      "messageDescriptionKey": "system.85983553.description",
      "huaweiDescription": "The device replacement interval is less than the specified interval."
    },
    "85983562": {
      "messageTitleKey": "system.85983562.title",
      "messageDescriptionKey": "system.85983562.description",
      "huaweiDescription": "Failed to send the email."
    },
    "85983563": {
      "messageTitleKey": "system.85983563.title",
      "messageDescriptionKey": "system.85983563.description",
      "huaweiDescription": "The URL for sending the email has expired."
    },
    "85983564": {
      "messageTitleKey": "system.85983564.title",
      "messageDescriptionKey": "system.85983564.description",
      "huaweiDescription": "The content playback is not allowed."
    },
    "85983565": {
      "messageTitleKey": "system.85983565.title",
      "messageDescriptionKey": "system.85983565.description",
      "huaweiDescription": "The content does not support preview."
    },
    "85983566": {
      "messageTitleKey": "system.85983566.title",
      "messageDescriptionKey": "system.85983566.description",
      "huaweiDescription": "The number of preview times reaches the maximum."
    },
    "85983567": {
      "messageTitleKey": "system.85983567.title",
      "messageDescriptionKey": "system.85983567.description",
      "huaweiDescription": "The subscriber is registered using an email address. The email address cannot be changed."
    },
    "85983568": {
      "messageTitleKey": "system.85983568.title",
      "messageDescriptionKey": "system.85983568.description",
      "huaweiDescription": "The email address to be bound to the subscriber has been used by another subscriber."
    },
    "85983569": {
      "messageTitleKey": "system.85983569.title",
      "messageDescriptionKey": "system.85983569.description",
      "huaweiDescription": "HLS content does not support /XML/GetCaTrigger invoking."
    },
    "85983570": {
      "messageTitleKey": "system.85983570.title",
      "messageDescriptionKey": "system.85983570.description",
      "huaweiDescription": "Login is not allowed because the physical device is suspended."
    },
    "85983572": {
      "messageTitleKey": "system.85983572.title",
      "messageDescriptionKey": "system.85983572.description",
      "huaweiDescription": "The verification code sent to the mobile number is invalid."
    },
    "85983573": {
      "messageTitleKey": "system.85983573.title",
      "messageDescriptionKey": "system.85983573.description",
      "huaweiDescription": "Online registration is not allowed during HESA self-registration by mobile number."
    },
    "85983574": {
      "messageTitleKey": "system.85983574.title",
      "messageDescriptionKey": "system.85983574.description",
      "huaweiDescription": "The SMSC fails to send the SMS message."
    },
    "85983576": {
      "messageTitleKey": "system.85983576.title",
      "messageDescriptionKey": "system.85983576.description",
      "huaweiDescription": "The non-shared product is being used on another device."
    },
    "85983577": {
      "messageTitleKey": "system.85983577.title",
      "messageDescriptionKey": "system.85983577.description",
      "huaweiDescription": "The subscriber is registered using a mobile number. The mobile number cannot be changed."
    },
    "85983578": {
      "messageTitleKey": "system.85983578.title",
      "messageDescriptionKey": "system.85983578.description",
      "huaweiDescription": "The device does not send the destAddr value and the email address matching loginName is left blank."
    },
    "85983580": {
      "messageTitleKey": "system.85983580.title",
      "messageDescriptionKey": "system.85983580.description",
      "huaweiDescription": "The global Multi-Room PVR feature has not been subscribed to."
    },
    "85983582": {
      "messageTitleKey": "system.85983582.title",
      "messageDescriptionKey": "system.85983582.description",
      "huaweiDescription": "The recording task adding is not allowed due to cPVR service authentication failure."
    },
    "85983583": {
      "messageTitleKey": "system.85983583.title",
      "messageDescriptionKey": "system.85983583.description",
      "huaweiDescription": "nPVR content downloads are not supported."
    },
    "85983584": {
      "messageTitleKey": "system.85983584.title",
      "messageDescriptionKey": "system.85983584.description",
      "huaweiDescription": "The number of nPVR content downloads reaches the maximum."
    },
    "85983585": {
      "messageTitleKey": "system.85983585.title",
      "messageDescriptionKey": "system.85983585.description",
      "huaweiDescription": "nPVR content downloads are not allowed."
    },
    "85983586": {
      "messageTitleKey": "system.85983586.title",
      "messageDescriptionKey": "system.85983586.description",
      "huaweiDescription": "The nPVR content download feature has not been subscribed to."
    },
    "85983587": {
      "messageTitleKey": "system.85983587.title",
      "messageDescriptionKey": "system.85983587.description",
      "huaweiDescription": "The subscriber information does not match the media file limitations."
    },
    "85983588": {
      "messageTitleKey": "system.85983588.title",
      "messageDescriptionKey": "system.85983588.description",
      "huaweiDescription": "Login is not allowed because the subscriber has not subscribed to any Login Service products or the product limitations
        are not met."
    },
    "85983589": {
      "messageTitleKey": "system.85983589.title",
      "messageDescriptionKey": "system.85983589.description",
      "huaweiDescription": "Schedule-relevant permissions (such as permissions on IR, nPVR recording, and cPVR recording) are not allowed."
    },
    "85983590": {
      "messageTitleKey": "system.85983590.title",
      "messageDescriptionKey": "system.85983590.description",
      "huaweiDescription": "Failed to send the SMS message because the profile has no mobile number."
    },
    "85983591": {
      "messageTitleKey": "system.85983591.title",
      "messageDescriptionKey": "system.85983591.description",
      "huaweiDescription": "The subscriber account failed to be activated. (This error code applies to subscribers registered by email at the
        Malaysia site.)"
    },
    "85983592": {
      "messageTitleKey": "system.85983592.title",
      "messageDescriptionKey": "system.85983592.description",
      "huaweiDescription": "The subscriber account has not been activated. (This error code applies to subscribers registered by email at the
        Malaysia site.)"
    },
    "85983602": {
      "messageTitleKey": "system.85983602.title",
      "messageDescriptionKey": "system.85983602.description",
      "huaweiDescription": "The Facebook token is invalid."
    },
    "85983605": {
      "messageTitleKey": "system.85983605.title",
      "messageDescriptionKey": "system.85983605.description",
      "huaweiDescription": "The subscriber account has been activated. (This error code applies to subscribers registered by email at the
        Malaysia site.)"
    },
    "85983606": {
      "messageTitleKey": "system.85983606.title",
      "messageDescriptionKey": "system.85983606.description",
      "huaweiDescription": "The PVR task authentication failed in the entire recording period, and the PVR task adding failed."
    },
    "85983635": {
      "messageTitleKey": "system.85983635.title",
      "messageDescriptionKey": "system.85983635.description",
      "huaweiDescription": "The live TV feature on which Catch-up TV depends has not been subscribed to."
    },
    "85983636": {
      "messageTitleKey": "system.85983636.title",
      "messageDescriptionKey": "system.85983636.description",
      "huaweiDescription": "The authentication failed. A subscribable product list exists, but no subscribable product list is available for
      the dependent service."
    },
    "85983637": {
      "messageTitleKey": "system.85983637.title",
      "messageDescriptionKey": "system.85983637.description",
      "huaweiDescription": "Failed to send the SMS message."
    },
    "85983638": {
      "messageTitleKey": "system.85983638.title",
      "messageDescriptionKey": "system.85983638.description",
      "huaweiDescription": "Failed to modify the subscriber information."
    },
    "85983639": {
      "messageTitleKey": "system.85983639.title",
      "messageDescriptionKey": "system.85983639.description",
      "huaweiDescription": "Incorrect verification code."
    },
    "85983640": {
      "messageTitleKey": "system.85983640.title",
      "messageDescriptionKey": "system.85983640.description",
      "huaweiDescription": "Failed to set channel numbers for the subscriber."
    },
    "85983641": {
      "messageTitleKey": "system.85983641.title",
      "messageDescriptionKey": "system.85983641.description",
      "huaweiDescription": "Failed to query channel numbers of the subscriber."
    },
    "85983744": {
      "messageTitleKey": "system.85983744.title",
      "messageDescriptionKey": "system.85983744.description",
      "huaweiDescription": "The system does not support stream management."
    },
    "85983745": {
      "messageTitleKey": "system.85983745.title",
      "messageDescriptionKey": "system.85983745.description",
      "huaweiDescription": "The subscriber is invalid."
    },
    "85983746": {
      "messageTitleKey": "system.85983746.title",
      "messageDescriptionKey": "system.85983746.description",
      "huaweiDescription": "No stream management node is available."
    },
    "85983747": {
      "messageTitleKey": "system.85983747.title",
      "messageDescriptionKey": "system.85983747.description",
      "huaweiDescription": "The stream management node changes. Apply for another node."
    },
    "85983748": {
      "messageTitleKey": "system.85983748.title",
      "messageDescriptionKey": "system.85983748.description",
      "huaweiDescription": "The bandwidth exceeds the maximum bandwidth for a streaming media service."
    },
    "85983749": {
      "messageTitleKey": "system.85983749.title",
      "messageDescriptionKey": "system.85983749.description",
      "huaweiDescription": "Failed to apply for device bandwidth."
    },
    "85983750": {
      "messageTitleKey": "system.85983750.title",
      "messageDescriptionKey": "system.85983750.description",
      "huaweiDescription": "Failed to apply for subscriber bandwidth."
    },
    "85983753": {
      "messageTitleKey": "system.85983753.title",
      "messageDescriptionKey": "system.85983753.description",
      "huaweiDescription": "Failed to apply for the cable resource."
    },
    "85983760": {
      "messageTitleKey": "system.85983760.title",
      "messageDescriptionKey": "system.85983760.description",
      "huaweiDescription": "Bandwidth has been applied for the PVR task. Repeated applications are not allowed."
    },
    "85983761": {
      "messageTitleKey": "system.85983761.title",
      "messageDescriptionKey": "system.85983761.description",
      "huaweiDescription": "Self-registration is not supported."
    },
    "85983765": {
      "messageTitleKey": "system.85983765.title",
      "messageDescriptionKey": "system.85983765.description",
      "huaweiDescription": "No Catch-up TV program exists."
    },
    "85983766": {
      "messageTitleKey": "system.85983766.title",
      "messageDescriptionKey": "system.85983766.description",
      "huaweiDescription": "No satisfactory Catch-up TV program exists."
    },
    "85983768": {
      "messageTitleKey": "system.85983768.title",
      "messageDescriptionKey": "system.85983768.description",
      "huaweiDescription": "The device mapping the STB SN is not unique."
    },
    "85983769": {
      "messageTitleKey": "system.85983769.title",
      "messageDescriptionKey": "system.85983769.description",
      "huaweiDescription": "The device mapping the STB SN does not exist."
    },
    "85983770": {
      "messageTitleKey": "system.85983770.title",
      "messageDescriptionKey": "system.85983770.description",
      "huaweiDescription": "The STB SN reported is inconsistent with that in the database."
    },
    "85983771": {
      "messageTitleKey": "system.85983771.title",
      "messageDescriptionKey": "system.85983771.description",
      "huaweiDescription": "When the conflict of IO concurrent requests, device bandwidths, or network bandwidths occurs, conflicting series
        and periodic tasks are returned."
    },
    "85983772": {
      "messageTitleKey": "system.85983772.title",
      "messageDescriptionKey": "system.85983772.description",
      "huaweiDescription": "When the conflict of IO concurrent requests, device bandwidths, or network bandwidths occurs, conflicting mixed
        tasks are returned."
    },
    "85983774": {
      "messageTitleKey": "system.85983774.title",
      "messageDescriptionKey": "system.85983774.description",
      "huaweiDescription": "Failed to check the cPVR bandwidth (the check does not occupy any bandwidth)."
    },
    "85983775": {
      "messageTitleKey": "system.85983775.title",
      "messageDescriptionKey": "system.85983775.description",
      "huaweiDescription": "The STB does not support the definition of this media resource."
    },
    "85983776": {
      "messageTitleKey": "system.85983776.title",
      "messageDescriptionKey": "system.85983776.description",
      "huaweiDescription": "The product has a subscription relationship that has not timed out."
    },
    "85983780": {
      "messageTitleKey": "system.85983780.title",
      "messageDescriptionKey": "system.85983780.description",
      "huaweiDescription": "No auto login account available."
    },
    "85983782": {
      "messageTitleKey": "system.85983782.title",
      "messageDescriptionKey": "system.85983782.description",
      "huaweiDescription": "The default profile is used, and the subscriber ID is blank."
    },
    "85983783": {
      "messageTitleKey": "system.85983783.title",
      "messageDescriptionKey": "system.85983783.description",
      "huaweiDescription": "The default profile is used, and the Altibox interface returns an account that is inconsistent with the current
        subscriber ID."
    },
    "85983784": {
      "messageTitleKey": "system.85983784.title",
      "messageDescriptionKey": "system.85983784.description",
      "huaweiDescription": "The download service cannot be used because the default profile has logged in."
    },
    "85983785": {
      "messageTitleKey": "system.85983785.title",
      "messageDescriptionKey": "system.85983785.description",
      "huaweiDescription": "No idle non-EST device available."
    },
    "85983786": {
      "messageTitleKey": "system.85983786.title",
      "messageDescriptionKey": "system.85983786.description",
      "huaweiDescription": "The non-EST device is blank."
    },
    "85983787": {
      "messageTitleKey": "system.85983787.title",
      "messageDescriptionKey": "system.85983787.description",
      "huaweiDescription": "No idle EST device available."
    },
    "85983788": {
      "messageTitleKey": "system.85983788.title",
      "messageDescriptionKey": "system.85983788.description",
      "huaweiDescription": "Unsubscription is disallowed because the unsubscription period has expired."
    },
    "85983789": {
      "messageTitleKey": "system.85983789.title",
      "messageDescriptionKey": "system.85983789.description",
      "huaweiDescription": "Unsubscription is disallowed because the subscriber has played or downloaded content."
    },
    "85983790": {
      "messageTitleKey": "system.85983790.title",
      "messageDescriptionKey": "system.85983790.description",
      "huaweiDescription": "Unsubscription is disallowed because the product was paid by bonus points."
    },
    "85983791": {
      "messageTitleKey": "system.85983791.title",
      "messageDescriptionKey": "system.85983791.description",
      "huaweiDescription": "Unsubscription is disallowed because a coupon was used."
    },
    "85984000": {
      "messageTitleKey": "system.85984000.title",
      "messageDescriptionKey": "system.85984000.description",
      "huaweiDescription": "Authentication in the ISMP failed."
    },
    "87031808": {
      "messageTitleKey": "system.87031808.title",
      "messageDescriptionKey": "system.87031808.description",
      "huaweiDescription": "An external component is abnormal."
    },
    "87031809": {
      "messageTitleKey": "system.87031809.title",
      "messageDescriptionKey": "system.87031809.description",
      "huaweiDescription": "Failed to connect to the Push Server."
    },
    "87031811": {
      "messageTitleKey": "system.87031811.title",
      "messageDescriptionKey": "system.87031811.description",
      "huaweiDescription": "Failed to connect to external components."
    },
    "87097345": {
      "messageTitleKey": "system.87097345.title",
      "messageDescriptionKey": "system.87097345.description",
      "huaweiDescription": "Access to the ACS is abnormal."
    },
    "87097346": {
      "messageTitleKey": "system.87097346.title",
      "messageDescriptionKey": "system.87097346.description",
      "huaweiDescription": "Communication times out. Generally, this error is the timeout of access to the ACS."
    },
    "87097347": {
      "messageTitleKey": "system.87097347.title",
      "messageDescriptionKey": "system.87097347.description",
      "huaweiDescription": "Failed to access the IB."
    },
    "87097355": {
      "messageTitleKey": "system.87097355.title",
      "messageDescriptionKey": "system.87097355.description",
      "huaweiDescription": "Other attributes are successfully updated, but the Opt parameter fails to be updated in the soap2tds."
    },
    "102760449": {
      "messageTitleKey": "system.102760449.title",
      "messageDescriptionKey": "system.102760449.description",
      "huaweiDescription": "Failed to query the login name from the rdb."
    },
    "102760481": {
      "messageTitleKey": "system.102760481.title",
      "messageDescriptionKey": "system.102760481.description",
      "huaweiDescription": "Exception on repeated recommendation."
    },
    "117440513": {
      "messageTitleKey": "system.117440513.title",
      "messageDescriptionKey": "system.117440513.description",
      "huaweiDescription": "Database error."
    },
    "117440516": {
      "messageTitleKey": "system.117440516.title",
      "messageDescriptionKey": "system.117440516.description",
      "huaweiDescription": "Operation timed out."
    },
    "117440517": {
      "messageTitleKey": "system.117440517.title",
      "messageDescriptionKey": "system.117440517.description",
      "huaweiDescription": "The input parameter is incorrect."
    },
    "117571585": {
      "messageTitleKey": "system.117571585.title",
      "messageDescriptionKey": "system.117571585.description",
      "huaweiDescription": "No product can be subscribed to."
    },
    "117571586": {
      "messageTitleKey": "system.117571586.title",
      "messageDescriptionKey": "system.117571586.description",
      "huaweiDescription": "Authorization failed."
    },
    "117572096": {
      "messageTitleKey": "system.117572096.title",
      "messageDescriptionKey": "system.117572096.description",
      "huaweiDescription": "Network error."
    },
    "117637121": {
      "messageTitleKey": "system.117637121.title",
      "messageDescriptionKey": "system.117637121.description",
      "huaweiDescription": "Subscription failed."
    },
    "117637376": {
      "messageTitleKey": "system.117637376.title",
      "messageDescriptionKey": "system.117637376.description",
      "huaweiDescription": "Database error."
    },
    "117637632": {
      "messageTitleKey": "system.117637632.title",
      "messageDescriptionKey": "system.117637632.description",
      "huaweiDescription": "Network error."
    },
    "117637888": {
      "messageTitleKey": "system.117637888.title",
      "messageDescriptionKey": "system.117637888.description",
      "huaweiDescription": "Subscription failed because the times-based product has been subscribed to and has not expired."
    },
    "117637892": {
      "messageTitleKey": "system.117637892.title",
      "messageDescriptionKey": "system.117637892.description",
      "huaweiDescription": "The prepaid account balance is insufficient."
    },
    "117702912": {
      "messageTitleKey": "system.117702912.title",
      "messageDescriptionKey": "system.117702912.description",
      "huaweiDescription": "Database error."
    },
    "117703168": {
      "messageTitleKey": "system.117703168.title",
      "messageDescriptionKey": "system.117703168.description",
      "huaweiDescription": "Network error."
    },
    "117768195": {
      "messageTitleKey": "system.117768195.title",
      "messageDescriptionKey": "system.117768195.description",
      "huaweiDescription": "The subscriber who wants to reset the password does not exist, or the subscriber's mailbox does not exist."
    },
    "117768196": {
      "messageTitleKey": "system.117768196.title",
      "messageDescriptionKey": "system.117768196.description",
      "huaweiDescription": "Failed to send the password reset link."
    },
    "117833729": {
      "messageTitleKey": "system.117833729.title",
      "messageDescriptionKey": "system.117833729.description",
      "huaweiDescription": "No product record exists."
    },
    "117833730": {
      "messageTitleKey": "system.117833730.title",
      "messageDescriptionKey": "system.117833730.description",
      "huaweiDescription": "Query failed."
    },
    "117833984": {
      "messageTitleKey": "system.117833984.title",
      "messageDescriptionKey": "system.117833984.description",
      "huaweiDescription": "Database error."
    },
    "117834240": {
      "messageTitleKey": "system.117834240.title",
      "messageDescriptionKey": "system.117834240.description",
      "huaweiDescription": "Network error."
    },
    "117899265": {
      "messageTitleKey": "system.117899265.title",
      "messageDescriptionKey": "system.117899265.description",
      "huaweiDescription": "No bill."
    },
    "118030337": {
      "messageTitleKey": "system.118030337.title",
      "messageDescriptionKey": "system.118030337.description",
      "huaweiDescription": "No template is available."
    },
    "118030338": {
      "messageTitleKey": "system.118030338.title",
      "messageDescriptionKey": "system.118030338.description",
      "huaweiDescription": "Query failed."
    },
    "118030592": {
      "messageTitleKey": "system.118030592.title",
      "messageDescriptionKey": "system.118030592.description",
      "huaweiDescription": "A database exception occurs."
    },
    "118030848": {
      "messageTitleKey": "system.118030848.title",
      "messageDescriptionKey": "system.118030848.description",
      "huaweiDescription": "A network exception occurs."
    },
    "118489089": {
      "messageTitleKey": "system.118489089.title",
      "messageDescriptionKey": "system.118489089.description",
      "huaweiDescription": "No playback record exists."
    },
    "118489090": {
      "messageTitleKey": "system.118489090.title",
      "messageDescriptionKey": "system.118489090.description",
      "huaweiDescription": "Query failed."
    },
    "118489344": {
      "messageTitleKey": "system.118489344.title",
      "messageDescriptionKey": "system.118489344.description",
      "huaweiDescription": "Database error."
    },
    "118554625": {
      "messageTitleKey": "system.118554625.title",
      "messageDescriptionKey": "system.118554625.description",
      "huaweiDescription": "No parental control level is available."
    },
    "118554626": {
      "messageTitleKey": "system.118554626.title",
      "messageDescriptionKey": "system.118554626.description",
      "huaweiDescription": "Operation failed."
    },
    "118554880": {
      "messageTitleKey": "system.118554880.title",
      "messageDescriptionKey": "system.118554880.description",
      "huaweiDescription": "Database error."
    },
    "119537665": {
      "messageTitleKey": "system.119537665.title",
      "messageDescriptionKey": "system.119537665.description",
      "huaweiDescription": "The scheduling fails and no EPG server is available."
    },
    "127506841": {
      "messageTitleKey": "system.127506841.title",
      "messageDescriptionKey": "system.127506841.description",
      "huaweiDescription": "Access is limited because the system is busy."
    },
    "-1": {
      "messageTitleKey": "system.-1.title",
      "messageDescriptionKey": "system.-1.description",
      "huaweiDescription": "Operation failed."
    },
    "-2": {
      "messageTitleKey": "system.-2.title",
      "messageDescriptionKey": "system.-2.description",
      "huaweiDescription": "The subscriber has logged out."
    },
    "-3": {
      "messageTitleKey": "system.-3.title",
      "messageDescriptionKey": "system.-3.description",
      "huaweiDescription": "The subscriber has been deregistered."
    }

    // FAIRPLAY error codes
    "154020084" ---> seen when logging in - authenticate fail
    "125011002" ---> seen while using fairplay drm aquire license, wrong format on payload
    "513099999" ---> "ERR- 513099999: Input byte array has incorrect ending byte at 10076",
    "513062012" ---> "ERR- 515062001: version of spc message is error! version is 1936745277",
    "513062012" ---> "ERR- 515011007: decrpyt failed! java.security.InvalidKeyException: IOException : algid parse error, not a sequence",
    "513062010" ---> "ERR- 515062013: It's not suppot airplay!"
  }
};*/
