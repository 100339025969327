import { useSelector } from 'react-redux';
import './style.scss';

import { RootState } from '../../../interfaces';

import { useTranslation } from 'react-i18next';
import { HorizontalAsset } from '../../../components/Assets/HorizontalAsset';
import { VodAsset } from '../../../components/Assets/VodAsset';
import { Swimlane } from '../../../components/Swimlane';
import { useContinueWatching } from '../../../queries/continue-watching/queries';
import { Vod } from '../../../queries/vod/types';
import { Recording } from '../../../queries/recordings/types';
import { PvrAsset } from '../../../components/Assets/PvrAsset';
import { isEmpty } from 'lodash';
import { Catchup } from '../../../queries/catchup/types';
import { CatchupAsset } from '../../../components/Assets/CatchupAsset';

interface NewContinueWatchingProps {
  numberOfAssetsToShow: number;
}

type CwUnion = Vod.ConstructedAsset | Recording.Group.Union | Catchup.ConstructedAsset | undefined;

export default function ContinueWatching({ numberOfAssetsToShow }: NewContinueWatchingProps) {
  const { data, isLoading } = useContinueWatching();
  const { t } = useTranslation();

  const isGuest = useSelector((state: RootState) => state.authReducer.isGuest);
  const isHome = useSelector((state: RootState) => state.authReducer.isHome);

  function renderAssetComponent(asset: CwUnion) {
    if (isVod(asset)) {
      return <VodAsset key={asset.asset.id} constructedAsset={asset} hideIfNotOwned />;
    }

    if (isPvr(asset)) {
      return <PvrAsset key={asset.pvrId} group={asset} showChannel={false} showProgress playOnHover />;
    }

    if (isCatchup(asset)) {
      return <CatchupAsset key={asset.asset.id} constructedAsset={asset} />;
    }

    return null;
  }

  if (isGuest) {
    return null;
  }

  if (isGuest && isHome) {
    return null;
  }

  if (!isLoading && isEmpty(data)) {
    return null;
  }

  return (
    <Swimlane title={t('continue watching')} oldMargin>
      {data
        ?.flat()
        .sort((a, b) => Number(b?.updateTime) - Number(a?.updateTime))
        .slice(0, numberOfAssetsToShow)
        .map((asset) => renderAssetComponent(asset))}

      {isLoading &&
        Array.from({ length: 8 }).map((_, i) => <HorizontalAsset key={i} isSkeleton={isLoading} showSubtitle />)}
    </Swimlane>
  );
}

function isVod(asset: CwUnion): asset is Vod.ConstructedAsset {
  return Boolean(asset && asset.type === 'vod');
}

function isPvr(asset: CwUnion): asset is Recording.Group.Union {
  return Boolean(asset && (asset.type === 'series' || asset.type === 'movie'));
}

function isCatchup(asset: CwUnion): asset is Catchup.ConstructedAsset {
  return Boolean(asset && asset.type === 'PROGRAM');
}
