import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../components/Modal';
import './style.scss';
import optionButtonStyles from '../../../components/OptionsModal/styles.module.scss';
import i18n from '../../../i18n';
import { withRouter, RouteComponentProps } from 'react-router';
import { routes } from '../../../config';
import {
  RootState,
  VodContentProduct,
  LoginAliasResponse,
  AliasUserData,
  PurchaseProduct,
  PurchaseAsset,
  PurchaseStates,
  PurchaseErrors,
  GAaction,
} from '../../../interfaces';
import { getResolution, getPurchaseType } from '../../../utils/vodUtils';
import { queryPurchaseAsset } from '../../../api/vod';
import { VodAsset } from '../../../interfaces';
import PurchaseOptionsModal from '../../../components/Purchase/PurchaseOptionsModal';
import ConfirmationModal from '../../../components/Purchase/ConfirmationModal';
import Spinner from '../../../components/Spinner';
import { ScriptService } from '../../../controllers/ScriptService';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import { SvgClose } from '../../../icons';
import { ModalHeroBackground } from '../../../components/OptionsModal';
import AltiboxAssetContext from '../../details/AltiboxAssetContext';

interface State {
  step: PurchaseStates;
  error: string | undefined;
  chosenPurchaseAsset: PurchaseProduct | undefined;
}

interface Props {
  purchaseOptions: VodContentProduct[];
  purchaseAsset: VodAsset;
  assetTitle: string;
  modalHeroBackground?: string;
  seasonAndEpisodeShorthand: string;
  onCloseModalCallback: () => void;
  onAssetIsBoughtCallback: () => void;
  getTrackingLabel: () => string;
  currentUser: string;
  isVisible: boolean;
  fromPlayer?: boolean;
}

class PurchaseModal extends Component<Props & RouteComponentProps<{}> & {}, State> {
  static contextType = AltiboxAssetContext;
  context!: React.ContextType<typeof AltiboxAssetContext>;

  state: State = {
    step: PurchaseStates.Options,
    error: undefined,
    chosenPurchaseAsset: undefined,
  };

  tracking = AnalyticsTracking.getInstance();

  get duration() {
    const { mediafiles } = this.props.purchaseAsset;
    return mediafiles && mediafiles[0] ? Math.ceil(parseInt(mediafiles[0].elapsetime, 10) / 60) : '';
  }

  get modalTitle() {
    let { assetTitle, seasonAndEpisodeShorthand } = this.props;
    return assetTitle + `${seasonAndEpisodeShorthand ? ' - ' + seasonAndEpisodeShorthand : ''}`;
  }

  get purchaseDone() {
    return this.state.step === PurchaseStates.Confirmation;
  }

  step = (state: PurchaseStates) => {
    this.setState({
      step: state,
    });
  };

  closeModal = () => {
    this.props.onCloseModalCallback();
    this.setState({
      error: undefined,
    });
    this.step(PurchaseStates.Options);
  };

  chosenAsset = (asset: PurchaseProduct) => {
    this.setState(
      {
        chosenPurchaseAsset: asset,
      },
      () => {
        this.step(PurchaseStates.Purchase);
        this.purchase();
      },
    );
  };

  checkUserInfoAgainstCurrentlyLoggedInUser(user: AliasUserData) {
    return `${user.firstName} ${user.lastName}` === this.props.currentUser; // must be a better way....
  }

  getUserInfo = (result: LoginAliasResponse) => {
    let isValidUser = this.checkUserInfoAgainstCurrentlyLoggedInUser(result.data.user);
    if (isValidUser) {
      this.purchase();
    } else {
      this.setState({
        error: i18n.t<string>('not matching accounts'),
      });
    }
  };

  playTheAsset = (play: boolean) => {
    this.tracking.trackEvent(
      this.tracking.getCurrentCategory(),
      GAaction.play,
      this.props.purchaseAsset.name + ' - ' + this.props.purchaseAsset.externalContentCode,
    );
    if (play && this.state.chosenPurchaseAsset && !this.props.fromPlayer) {
      this.props.history.push(routes.vod.base + routes.vod.play + '/' + this.props.purchaseAsset.externalContentCode);
    } else {
      this.closeModal();
    }
    if (this.props.fromPlayer) {
      this.closeModal();
    }

    if (this.context.isSlideIn && play) {
      this.context.hideDetailsPanel();
    }
  };

  modalInfo() {
    if (this.state.step === PurchaseStates.Confirmation && this.state.chosenPurchaseAsset) {
      let asset = this.state.chosenPurchaseAsset;
      return (
        <p
          className={'confirmation-feedback'}
          dangerouslySetInnerHTML={{
            __html: `${i18n.t<string>('you have purchased', {
              purchaseType: i18n.t<string>(getPurchaseType(asset.isEST)).toLowerCase(),
              name: this.modalTitle,
              quality: getResolution(asset.restrictionList[0].v[0]),
              price: asset.price,
            })}`,
          }}
        ></p>
      );
    }
  }

  trackPurchase = (asset: PurchaseProduct) => {
    let isHD = asset.id.indexOf('HD') !== -1;
    let isRent = asset.isEST === '0';
    this.tracking.trackCurrentService(
      isRent ? (isHD ? GAaction.rentHD : GAaction.rentSD) : isHD ? GAaction.buyHD : GAaction.buySD,
      this.props.purchaseAsset.name + ' - ' + this.props.purchaseAsset.externalContentCode,
      false,
      asset.price,
    );
  };

  purchase() {
    let asset = this.state.chosenPurchaseAsset!;

    let purchaseAsset: PurchaseAsset = {
      businesstype: 1,
      contentid: asset.contentid,
      contenttype: asset.contenttype,
      continuetype: 0,
      productid: asset.id,
      serviceid: asset.priceobjectid,
      servicetype: 2,
    };

    queryPurchaseAsset(purchaseAsset)
      .then(() => this.step(PurchaseStates.Confirmation))
      .then(this.trackPurchase.bind(this, asset))
      .then(() => this.props.onAssetIsBoughtCallback())
      .catch((error: PurchaseErrors) => {
        let assetName = this.props.purchaseAsset.name + ' - ' + this.props.purchaseAsset.externalContentCode;
        switch (error) {
          case PurchaseErrors.InvalidUser:
            this.tracking.trackEvent(
              this.tracking.getCurrentCategory(),
              GAaction.transactionFailed,
              'invalid user tried to purchase - ' + assetName,
            );
            this.setError(i18n.t<string>('invalid user try to login'));
            break;
          case PurchaseErrors.NoFunds:
            this.tracking.trackEvent(
              this.tracking.getCurrentCategory(),
              GAaction.transactionFailed,
              'purchase insufficient funds - ' + assetName,
            );
            this.setError(i18n.t<string>('purchase insufficient funds message'));
            break;
          case PurchaseErrors.Restrictions:
            this.tracking.trackEvent(
              this.tracking.getCurrentCategory(),
              GAaction.transactionFailed,
              'purchase age restricted - ' + assetName,
            );
            this.setError(i18n.t<string>('purchase age restricted message'));
            break;
          case PurchaseErrors.BlockedPurchase:
            this.tracking.trackEvent(
              this.tracking.getCurrentCategory(),
              GAaction.transactionFailed,
              'purchase blocked - ' + assetName,
            );
            this.setError(i18n.t<string>('purchase blocked'));
            break;
          default:
            break;
        }
      });

    this.step(PurchaseStates.Purchasing);
  }

  setError(message: string | undefined) {
    this.setState({
      error: message,
    });
  }

  goBackToOptionsScreen = () => {
    this.step(PurchaseStates.Options);
  };

  displayModals = () => {
    switch (this.state.step) {
      case PurchaseStates.Options:
        return (
          <div className={optionButtonStyles.optionsButtonWrapper}>
            {!ScriptService.onMobile() && !ScriptService.isCurrentBrowserSupported() ? (
              <div className="body-2 purchaseLimitations">{i18n.t<string>('purchase limitations')}</div>
            ) : null}
            <PurchaseOptionsModal
              options={this.props.purchaseOptions}
              onCloseCallback={this.closeModal}
              onPurchaseCallback={this.chosenAsset}
            />
          </div>
        );
      case PurchaseStates.Purchase:
        return <Spinner />;
      case PurchaseStates.Purchasing:
        return this.state.error ? <span className="error">{this.state.error}</span> : <Spinner />;
      case PurchaseStates.Confirmation:
        return (
          <ConfirmationModal
            asset={this.state.chosenPurchaseAsset}
            onPlayCallback={(value: boolean) => this.playTheAsset(value)}
          />
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isVisible}
        onRequestClose={() => {
          this.closeModal();
          this.playTheAsset(false);
        }}
        className="purchaseModal"
      >
        <ModalHeroBackground
          imageUrl={this.props.modalHeroBackground}
          title={this.purchaseDone ? i18n.t<string>('purchase done') : this.modalTitle}
        />
        <button
          className={optionButtonStyles.buttonClose}
          onClick={() => {
            this.closeModal();
            this.playTheAsset(false);
          }}
          children={<SvgClose />}
        />
        <main className={optionButtonStyles.mainContainer}>
          {this.modalInfo()}
          {this.displayModals()}
          {!this.purchaseDone ? (
            <span className="conditionsText">
              <>
                {i18n.t<string>('tos1')}
                <a href={`${i18n.t<string>('tos link')}`} target={void 0}>
                  {i18n.t<string>('tos')}
                </a>
                {'. '}
                {i18n.t<string>('tos2')}
              </>
            </span>
          ) : null}
        </main>
      </Modal>
    );
  }
}
export default withRouter(
  connect((state: RootState) => ({
    currentUser: state.authReducer.userFullName,
  }))(PurchaseModal),
);
