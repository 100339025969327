import * as huaweiApi from '../../../api/recording';
import {
  DispatchType,
  PvrRecording,
  Bookmarks,
  PvrSpace,
  PvrSorting,
  Bookmark,
  ChannelsRecordingsList,
  AlertData,
  RecordingDeleteMode,
} from '../../../interfaces';
import { splitRecordingsByType } from '../../../api/tv';
import {
  setRecordingSingle,
  setRecordingSeries,
  recordingActionInProgress,
  getRecordings,
  recordingActionDone,
} from '../../tv/actions';
import { isEmpty } from 'lodash';
import { getPVRSpaceWarningMessage } from '../../../utils/pvrUtils';
import { showAlert } from '../../app/actions';
import { updateSeriesRecording } from '../../../api/recording';
import { recordingKeys } from '../../../queries/recordings/keys';
import { queryClient } from '../../../queries/client';

export enum TypeKeys {
  ADD_ALL_RECORDINGS = 'ADD_ALL_RECORDINGS',
  ADD_ALL_SCHEDULED_RECORDINGS = 'ADD_ALL_SCHEDULED_RECORDINGS',
  ADD_PVR_BOOKMARKS = 'ADD_PVR_BOOKMARKS',
  SET_CURRENT_PVR_SERIES = 'SET_CURRENT_PVR_SERIES',
  SET_RECORDINGS_LOADING = 'SET_RECORDINGS_LOADING',
  CLEAR_CURRENT_PVR_SERIES = 'CLEAR_CURRENT_PVR_SERIES',
  MARK_RECORDING_FOR_DELETION = 'MARK_RECORDING_FOR_DELETION',
  MARK_RECORDING_AS_DELETED = 'MARK_RECORDING_AS_DELETED',
  MARK_SERIES_FOR_DELETION = 'MARK_SERIES_FOR_DELETION',
  MARK_SEASON_FOR_DELETION = 'MARK_SEASON_FOR_DELETION',
  SHOW_AGGREGATED_DELETE_MODAL = 'SHOW_AGGREGATED_DELETE_MODAL',
  GET_PVR_SPACE = 'GET_PVR_SPACE',
  SET_SORT = 'SET_SORT',
  SET_TEMP_BOOKMARK = 'SET_TEMP_BOOKMARK',
}

export type ActionTypes =
  | AddAllRecordings
  | AddAllScheduledRecordings
  | SetCurrentPvrSeries
  | ClearCurrentPvrSeries
  | AddPVRBookmarks
  | MarkRecordingForDeletion
  | MarkRecordingAsDeleted
  | MarkSeasonForDeletion
  | MarkSeriesForDeletion
  | ShowAggregatedDeleteModal
  | SetRecordingsLoading
  | GetPVRSpace
  | SetSort
  | SetTempBookmark;

export type ShowAggregatedDeleteModal = {
  type: TypeKeys.SHOW_AGGREGATED_DELETE_MODAL;
  showAggregatedDeleteModal: boolean;
};

export function showAggregatedDeleteModal(toggle: boolean): ShowAggregatedDeleteModal {
  return {
    type: TypeKeys.SHOW_AGGREGATED_DELETE_MODAL,
    showAggregatedDeleteModal: toggle,
  };
}

export interface SetSort {
  type: TypeKeys.SET_SORT;
  selectedSort: PvrSorting;
}

export function setSort(newSort: PvrSorting): SetSort {
  return {
    type: TypeKeys.SET_SORT,
    selectedSort: newSort,
  };
}

export interface GetPVRSpace {
  type: TypeKeys.GET_PVR_SPACE;
  space: PvrSpace;
}
export function getPVRSpace(space: PvrSpace): GetPVRSpace {
  return {
    type: TypeKeys.GET_PVR_SPACE,
    space,
  };
}
export interface SetTempBookmark {
  type: TypeKeys.SET_TEMP_BOOKMARK;
  tempBookmark: Bookmark | undefined;
}
export function setTempBookmark(tempBookmark: Bookmark | undefined): SetTempBookmark {
  return {
    type: TypeKeys.SET_TEMP_BOOKMARK,
    tempBookmark,
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadPVRSpace(): any {
  return function (dispatch: DispatchType) {
    return huaweiApi.getPVRSpace().then((space) => {
      dispatch(getPVRSpace(space as PvrSpace));
      return space;
    });
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadPVRFrontpageSpace(): any {
  return function (dispatch: DispatchType) {
    return dispatch(loadPVRSpace()).then((space: PvrSpace) => {
      const pvrSpaceWarningMessage: AlertData | undefined = getPVRSpaceWarningMessage(space);
      if (pvrSpaceWarningMessage) {
        dispatch(showAlert(pvrSpaceWarningMessage));
      }
    });
  };
}

export interface MarkSeriesForDeletion {
  type: TypeKeys.MARK_SERIES_FOR_DELETION;
  showDeleteSeriesModal: boolean;
}
export function markSeriesForDeletion(showDeleteSeriesModal: boolean): MarkSeriesForDeletion {
  return {
    type: TypeKeys.MARK_SERIES_FOR_DELETION,
    showDeleteSeriesModal,
  };
}
// delete series and reload
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deleteSeries(sereiesId: string): any {
  return function (dispatch: DispatchType) {
    return huaweiApi.deleteSeries(sereiesId).then(() => {
      dispatch(markSeriesForDeletion(false));
      dispatch(fetchAllRecordings());
      queryClient.invalidateQueries(recordingKeys.all);
    });
  };
}

export function deleteSeriesAndStopRecording(
  sereiesId: string,
  recording: PvrRecording,
  canceled: boolean,
  deleteMode: RecordingDeleteMode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return function (dispatch: DispatchType) {
    dispatch(recordingActionInProgress());

    updateSeriesRecording(recording, canceled, deleteMode)
      .then(() => dispatch(getRecordings()))
      .then(() => dispatch(recordingActionDone()))
      .then(() =>
        huaweiApi.deleteSeries(sereiesId).then(() => {
          dispatch(markSeriesForDeletion(false));
          dispatch(fetchAllRecordings());
          queryClient.invalidateQueries(recordingKeys.all);
        }),
      );
  };
}

export interface MarkSeasonForDeletion {
  type: TypeKeys.MARK_SEASON_FOR_DELETION;
  showDeleteSeasonModal: boolean;
}
export function markSeasonForDeletion(showDeleteSeasonModal: boolean): MarkSeasonForDeletion {
  return {
    type: TypeKeys.MARK_SEASON_FOR_DELETION,
    showDeleteSeasonModal,
  };
}
// delete season and reload
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deleteSeason(seasonToDelete: PvrRecording[]): any {
  return function (dispatch: DispatchType) {
    let pvrIds: string[] = [];
    seasonToDelete.forEach((pvr: PvrRecording) => {
      pvrIds.push(pvr.pvrId);
    });

    return huaweiApi.deleteMultiplePVRs(pvrIds).then(() => {
      dispatch(markSeasonForDeletion(false));
      dispatch(fetchAllRecordings());
      dispatch(markRecordingAsDeleted(seasonToDelete));
    });
  };
}

export interface MarkRecordingForDeletion {
  type: TypeKeys.MARK_RECORDING_FOR_DELETION;
  recordingToDelete?: PvrRecording;
}
// if recordingToDelete has value a modal will appear
// send in null to unset it and close modal
export function markRecordingForDeletion(recording?: PvrRecording): MarkRecordingForDeletion {
  return {
    type: TypeKeys.MARK_RECORDING_FOR_DELETION,
    recordingToDelete: recording,
  };
}

export interface MarkRecordingAsDeleted {
  type: TypeKeys.MARK_RECORDING_AS_DELETED;
  recordingDeleted?: PvrRecording | PvrRecording[];
}
export function markRecordingAsDeleted(recording?: PvrRecording | PvrRecording[]): MarkRecordingAsDeleted {
  return {
    type: TypeKeys.MARK_RECORDING_AS_DELETED,
    recordingDeleted: recording,
  };
}

// delete recording and reload
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deleteRecording(recording: PvrRecording): any {
  return function (dispatch: DispatchType) {
    return huaweiApi.deleteEpisode(recording).then(() => {
      // unsetting recording to delete
      dispatch(markRecordingForDeletion());

      // mark the recording as deleted
      dispatch(markRecordingAsDeleted(recording));

      // unsetting the deleted recording
      dispatch(markRecordingAsDeleted());

      // loading page and setting recordings
      // dispatch(fetchAllRecordings());
    });
  };
}

export interface SetCurrentPvrSeries {
  type: TypeKeys.SET_CURRENT_PVR_SERIES;
  series: PvrRecording;
}
export function setCurrentPvrSeries(series: PvrRecording): SetCurrentPvrSeries {
  return {
    type: TypeKeys.SET_CURRENT_PVR_SERIES,
    series,
  };
}

export interface ClearCurrentPvrSeries {
  type: TypeKeys.CLEAR_CURRENT_PVR_SERIES;
}
export function clearCurrentSeries(): ClearCurrentPvrSeries {
  return {
    type: TypeKeys.CLEAR_CURRENT_PVR_SERIES,
  };
}

export interface AddAllRecordings {
  type: TypeKeys.ADD_ALL_RECORDINGS;
  recordings: PvrRecording[];
}
export function addAllRecordings(recordings: PvrRecording[]): AddAllRecordings {
  return {
    type: TypeKeys.ADD_ALL_RECORDINGS,
    recordings,
  };
}

export interface AddAllScheduledRecordings {
  type: TypeKeys.ADD_ALL_SCHEDULED_RECORDINGS;
  scheduledRecordings: PvrRecording[];
}
export function addAllScheduledRecordings(scheduledRecordings: PvrRecording[]): AddAllScheduledRecordings {
  return {
    type: TypeKeys.ADD_ALL_SCHEDULED_RECORDINGS,
    scheduledRecordings,
  };
}

export interface AddPVRBookmarks {
  type: TypeKeys.ADD_PVR_BOOKMARKS;
  bookmarks: Bookmarks;
}
export function addPVRBookmarks(bookmarks: Bookmarks): AddPVRBookmarks {
  return {
    type: TypeKeys.ADD_PVR_BOOKMARKS,
    bookmarks,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadBookmarks(): any {
  return function (dispatch: DispatchType) {
    return huaweiApi.getPVRBookmarks().then((bookmarks: Bookmarks) => {
      return dispatch(addPVRBookmarks(bookmarks));
    });
  };
}
export interface SetRecordingsLoading {
  type: TypeKeys.SET_RECORDINGS_LOADING;
  value: boolean;
}
export function setRecordingsLoading(value: boolean): SetRecordingsLoading {
  return {
    type: TypeKeys.SET_RECORDINGS_LOADING,
    value,
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchAllRecordings(skipLoadingSpinner?: boolean, customBookmark?: Bookmark): any {
  return function (dispatch: DispatchType) {
    if (!skipLoadingSpinner) {
      dispatch(setRecordingsLoading(true));
    }

    // first load bookmarks
    return huaweiApi
      .getPVRBookmarks()
      .then((bookmarks: Bookmarks) => {
        dispatch(addPVRBookmarks(bookmarks));
        return bookmarks;
      })
      .then((bookmarks: Bookmarks) => {
        return huaweiApi.getAllFinishedRecordings(bookmarks).then((recordings) => {
          recordings = recordings as PvrRecording[];
          let split = splitRecordingsByType(recordings);
          split = markPvrWithIsWatched(bookmarks, split);
          dispatch(setRecordingSingle(split.singleRecordings));
          dispatch(setRecordingSeries(split.seriesRecordings));
          dispatch(setRecordingsLoading(false));
          dispatch(addAllRecordings(recordings));
          return recordings;
        });
      });
  };
}

export function markPvrWithIsWatched(bookmarklist: Bookmarks, recordings: ChannelsRecordingsList) {
  const bookmarks = bookmarklist.bookmarkList;
  const tempRecordings = { ...recordings };
  if (!isEmpty(recordings.singleRecordings) && bookmarks) {
    tempRecordings.singleRecordings.forEach((recording) => {
      recording.isWatched = Number(Boolean(bookmarks.find((x) => x.contentId === recording.pvrId))).toString();
    });
  }
  if (!isEmpty(recordings.seriesRecordings) && bookmarks) {
    tempRecordings.seriesRecordings.forEach((recording) => {
      if (!isEmpty(recording.subRecordings)) {
        recording.subRecordings.forEach((subRecording) => {
          subRecording.isWatched = Number(
            Boolean(bookmarks.find((x) => x.contentId === subRecording.pvrId)),
          ).toString();
        });
      }
    });
  }
  return tempRecordings;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchAllScheduledRecordings(skipLoadingSpinner?: boolean): any {
  return function (dispatch: DispatchType) {
    if (!skipLoadingSpinner) {
      dispatch(setRecordingsLoading(true));
    }
    return huaweiApi.getAllScheduledRecordings().then((scheduledRecordings) => {
      dispatch(addAllScheduledRecordings(scheduledRecordings as PvrRecording[]));
      return scheduledRecordings;
    });
  };
}
