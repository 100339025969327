import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteLeftActions } from '../../../components/Pvr/DeleteActions';
import { PvrDeleteAsset } from '../../../components/Pvr/DeleteAsset';
import { DeleteMultipleModal } from '../../../components/Pvr/DeleteModal';
import { PvrLayout } from '../../../components/Pvr/Layout';
import { SortDropdown } from '../../../components/SortDropdown';
import Spinner from '../../../components/Spinner';
import { useBlockNavigation } from '../../../hooks/useBlockNavigation';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { useGroupedRecordings } from '../../../queries/recordings/queries';
import { Recording } from '../../../queries/recordings/types';
import { groupIsSeries, sortGroups } from '../../../queries/recordings/utils';
import { isSameInArray } from '../../../utils/commonUtils';
import { FormWrapper } from '../FormWrapper';
import styles from './style.module.scss';

const SORT_OPTIONS = [
  { label: 'latest', value: Recording.Sort.Action.Newest },
  { label: 'oldest', value: Recording.Sort.Action.Oldest },
  { label: 'A to Z', value: Recording.Sort.Action.Alphabetically },
];

export default function PvrDeleteSeries() {
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0].value);
  const { data, isLoading, isFetching } = useGroupedRecordings((groups) => sortGroups(groups, sortBy));
  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<Recording.Group.Union[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation('pvr');

  useScrollToTop();
  useBlockNavigation(!selectionMode, {
    prompt: t('are you sure you want to cancel'),
  });

  function handleChange(group: Recording.Group.Union) {
    if (isGroupSelected(group)) {
      return setSelectedGroups((prev) => prev.filter((selected) => selected.pvrName !== group.pvrName));
    }

    return setSelectedGroups((prev) => [...prev, group]);
  }

  function isGroupSelected(group: Recording.Group.Union) {
    return selectedGroups.some((selected) => {
      return selected.pvrName === group.pvrName;
    });
  }

  function markAll() {
    if (data) {
      setSelectedGroups(data);
    }
  }

  function clearAll() {
    setSelectedGroups([]);
  }

  const leftActions = (
    <DeleteLeftActions
      onSetSelectionMode={setSelectionMode}
      markAllSelections={markAll}
      clearAllSelections={clearAll}
      openModal={() => setIsModalOpen(true)}
      selectionMode={selectionMode}
      selectedRecordings={selectedGroups}
      isAllSelected={selectedGroups.length === data?.length}
    />
  );

  const rightActions = <SortDropdown onChange={setSortBy} options={SORT_OPTIONS} />;

  return (
    <PvrLayout leftActions={leftActions} rightActions={rightActions}>
      <DeleteMultipleModal
        selectedGroups={selectedGroups}
        isOpen={isModalOpen}
        hideModal={() => setIsModalOpen(false)}
        isAllSelected={selectedGroups.length === data?.length}
        callback={() => {
          clearAll();
          setIsModalOpen(false);
          setSelectionMode(false);
        }}
      />

      <div className={styles.container}>
        <FormWrapper
          selectionMode={selectionMode}
          shouldOpenModal={Boolean(selectedGroups.length)}
          openModal={() => setIsModalOpen(true)}
          onCancel={() => {
            setSelectionMode(false);
            clearAll();
          }}
        >
          {isLoading && <Spinner wrapInContainer />}

          {(isEmpty(data) || !data) && !isLoading && (
            <div className={styles.container}>
              <p className={styles.empty}>{t('you have no recordings')}</p>
            </div>
          )}

          <ul className={styles.grid}>
            {data?.map((asset) => (
              <li key={asset.pvrId}>
                {groupIsSeries(asset) ? (
                  <PvrDeleteAsset
                    group={asset}
                    selectionMode={selectionMode}
                    onChange={handleChange}
                    isSelected={isSameInArray(selectedGroups, asset, 'pvrId')}
                    useSkeleton={isFetching}
                    type="series"
                  />
                ) : (
                  <PvrDeleteAsset
                    group={asset}
                    selectionMode={selectionMode}
                    onChange={handleChange}
                    isSelected={isSameInArray(selectedGroups, asset, 'pvrId')}
                    useSkeleton={isFetching}
                    type="movie"
                  />
                )}
              </li>
            ))}
          </ul>

          <button type="submit" className={styles.submit} disabled={!selectionMode}>
            Submit
          </button>
        </FormWrapper>
      </div>
    </PvrLayout>
  );
}
