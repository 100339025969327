import { HTMLAttributes, useState } from 'react';
import { SvgChevronDown, SvgChevronUp } from '../../../icons';

import styles from './expandableText.module.scss';

export function ExpandableText({
  children,
  threshold,
  ...rest
}: { children: string; threshold: number } & HTMLAttributes<HTMLParagraphElement>) {
  const [expanded, setExpanded] = useState(false);
  const aboveThreshold = children.length > threshold;

  return (
    <p
      {...rest}
      className={`${styles.expandableText} ${aboveThreshold ? styles.aboveThreshold : ''} ${rest.className ?? ''}`}
      onClick={() => setExpanded((s) => !s)}
    >
      {expanded || !aboveThreshold ? children : children.slice(0, threshold) + '...'}
      {aboveThreshold ? (
        expanded ? (
          <SvgChevronUp width="12px" height="7px" />
        ) : (
          <SvgChevronDown width="12px" height="7px" />
        )
      ) : null}
    </p>
  );
}
