import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { pvrSettings, routes } from '../../config';
import { RootState, VodAssetDisplayMode } from '../../interfaces';
import { Genre } from '../../queries/genres/types';
import { Recording } from '../../queries/recordings/types';
import AltiboxAssetSlider from '../UI/AltiboxAssetSlider';
import { PvrAsset, PvrAssetSeeAll, PvrAssetSkeleton } from '../Assets/PvrAsset';
import styles from './styles/Swimlane.module.scss';
interface PvrSwimlaneProps {
  title: Recording.Swimlane.Titles | Genre.Epg.Translations | string;
  slug: string;
  groups: Recording.Group.Union[] | undefined;
  isLoading?: boolean;
}

function TitleBar({ title, slug, isLink }: Omit<PvrSwimlaneProps, 'groups'> & { isLink?: boolean }) {
  const { t, i18n } = useTranslation('pvr');

  function getTranslatedTitle() {
    const language = i18n.language;

    if (typeof title === 'string') {
      return t(title, { count: 2 });
    }

    return title[language]?.length ? title[language] : title.nb;
  }

  const translatedTitle = getTranslatedTitle();

  if (isLink) {
    return (
      <Link to={`${routes.pvr.base}/${slug}`} className={styles.titlebar}>
        <h3 title={translatedTitle} className="watch-tv-header">
          {translatedTitle}
        </h3>
        <span className="aib-icon">{`>`}</span>
      </Link>
    );
  }

  return (
    <div className={styles.titlebar}>
      <h3 title={translatedTitle} className="watch-tv-header">
        {translatedTitle}
      </h3>
    </div>
  );
}

const MAX_LENGTH = pvrSettings.swimlaneMaxLength;

export function PvrSwimlane({ title, slug, groups }: PvrSwimlaneProps) {
  const isHome = useSelector((state: RootState) => state.authReducer.isHome);
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.loggedInWithCredentials);

  function getAssetProps() {
    switch (title) {
      case Recording.Swimlane.Titles.ContinueWatching:
        return { showProgress: true, playOnHover: true };
      default:
        return {};
    }
  }

  if (isHome && !isLoggedIn && title === Recording.Swimlane.Titles.ContinueWatching) {
    return null;
  }

  return (
    <div className={styles.list}>
      <AltiboxAssetSlider
        titleBar={<TitleBar title={title} slug={slug} isLink={groups && groups.length > MAX_LENGTH} />}
        displayType={VodAssetDisplayMode.SVOD}
        rememberPositionKey={slug}
      >
        {groups?.slice(0, MAX_LENGTH).map((group) => (
          <PvrAsset key={group.pvrId} group={group} {...getAssetProps()} />
        ))}

        {groups && groups.length > MAX_LENGTH && <PvrAssetSeeAll slug={slug} />}
      </AltiboxAssetSlider>
    </div>
  );
}

export function PvrSwimlaneSkeleton({ title }: { title?: Recording.Swimlane.Titles }) {
  return (
    <div className={styles.skeleton}>
      <AltiboxAssetSlider
        titleBar={
          <TitleBar
            title={title ?? Recording.Swimlane.Titles.AllRecordings}
            slug={title ?? Recording.Swimlane.Titles.AllRecordings}
          />
        }
      >
        <PvrAssetSkeleton />
        <PvrAssetSkeleton />
        <PvrAssetSkeleton />
        <PvrAssetSkeleton />
        <PvrAssetSkeleton />
        <PvrAssetSkeleton />
        <PvrAssetSkeleton />
        <PvrAssetSkeleton />
        <PvrAssetSkeleton />
        <PvrAssetSkeleton />
      </AltiboxAssetSlider>
    </div>
  );
}
