import React, { useRef, useState } from 'react';
import { useOnOutsideClick } from '../../../hooks/useOnOutsideClick';
import gravatar from 'gravatar';
import i18n from '../../../i18n';
import { logout } from '../../../api/auth';

type UserMenuProps = {
  userEmail: string;
  userFullName: string;
  loggedInWithCredentials: boolean;
  isInternetOnlyAuthByIp: boolean;
  isInternetOnlyAuthByCreds: boolean;
  isInternetOnly: boolean;
  loggedInByIp: boolean;
  renderLoginButton: () => JSX.Element;
};

const UserMenu: React.FC<UserMenuProps> = ({
  userEmail,
  userFullName,
  loggedInWithCredentials,
  isInternetOnlyAuthByCreds,
  isInternetOnlyAuthByIp,
  loggedInByIp,
  renderLoginButton,
}) => {
  const gravatarUrl = gravatar.url(userEmail, { d: 'blank', s: '45' });
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnOutsideClick(ref, () => setOpen(false));

  const LoginStatus = (): JSX.Element | null => {
    if (loggedInWithCredentials || isInternetOnlyAuthByCreds) {
      return <div>{i18n.t<string>('logged in with password')}</div>;
    }
    if (loggedInByIp || isInternetOnlyAuthByIp) {
      return <div>{i18n.t<string>('logged in by ip')}</div>;
    }
    return null;
  };

  const AuthButton = () => {
    if (loggedInByIp || isInternetOnlyAuthByIp) {
      return renderLoginButton();
    }
    return <button onClick={async () => await logout()}>{i18n.t<string>('log off')}</button>;
  };

  return (
    <div ref={ref}>
      <div className="overflowHidden">
        <button type="button" className="profileImage" onClick={() => setOpen(!open)}>
          <img alt={i18n.t<string>('profile picture')} src={gravatarUrl} />
          {userFullName ? <div className="initials">{userFullName[0]}</div> : <span className="aib-icon">J</span>}
        </button>
      </div>
      {open ? (
        <article className="popper override-overflow-hidden">
          <h3 className="login-popper-name">{userFullName}</h3>
          {userEmail ? <address className="email">{userEmail}</address> : null}
          <LoginStatus />
          <AuthButton />
        </article>
      ) : null}
    </div>
  );
};
export default UserMenu;
