import { fromMoment } from '../utils/huaweiUtils';
import moment from 'moment';
import { status, json, fetchError, getDataFromResponse } from '../controllers/Fetch';
import { getBaseUrl, getSessionTicket } from '../config';

import {
  Channel,
  RecordingDeleteMode,
  SeriesType,
  PvrRecording,
  Season,
  ProgramToRecord,
  Bookmarks,
  PvrSpace,
  PlayResult,
  PvrStatus,
  ExtensionInfoResponse,
  HuaweiBatchResponse,
  StreamingObject,
  LeadLagTime,
  Pvr,
  Api,
  Bookmark,
} from '../interfaces';
import HuaweiErrors from '../controllers/HuaweiErrors';
import { getItemFromLocalStorage, HUAWEI_SUCCESS_RETCODE } from './auth';
import { isCurrentlyRecording, getnPVRMediaId } from '../utils/pvrUtils';
import { isEmpty } from 'lodash';
import { recordingIsSingle } from '../typeGuards';
import i18n from '../i18n';

const nuke = false;

enum PvrType {
  cPVR = 1,
  nPVR = 2,
}

/* PVR SPACE */
interface PvrSpaceResponseDetails {
  space: number;
  meteringMode: number;
}
interface PvrSpaceResponse {
  name: string;
  msg: PvrSpaceResponseDetails;
}

interface AllPvrSpaceResponse {
  responseList: PvrSpaceResponse[];
}
/* END PVR SPACE */

interface AllRecordingsResponse {
  responseList: ApiRecordingsResponse[];
}

interface ApiRecordingsResponse {
  msg: ApiRecordings;
  name: string;
}

interface ApiRecordings {
  counttotal: number;
  pvrlist: PvrRecording[];
}

enum SeriesRecordingAction {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  PREADD = 'PREADD',
}

interface AddSeriesRecording {
  action: SeriesRecordingAction.ADD;
  programSerialEnable: 0 | 1;
  task: {
    beginOffset?: number;
    endOffset?: number;
    seriesID: string;
    effectivetime: string;
    type: PvrType.nPVR;
    deleteMode: RecordingDeleteMode;
    seriesType: SeriesType.SeriesId;
    mediaId: string;
    timeMode?: number;
    selectedSubNum?: number;
    selectedSeasonNum?: number;
  };
}

interface UpdateSeriesRecording {
  action: SeriesRecordingAction.UPDATE;
  task: {
    type: string;
    periodPVRTaskId: string;
    deleteMode: RecordingDeleteMode;
    beginOffset?: number;
    endOffset?: number;
    effectivetime?: string;
  };
}

const catchNoRecordSupportError = async (response: Object | Response) => {
  const pvrResponse = await json(response as Response);
  if (pvrResponse.retcode === '85983287') {
    throw new Error(i18n.t('channel record not supported'));
  }
  return pvrResponse;
};

function manageSeriesRecording(options: AddSeriesRecording | UpdateSeriesRecording): Promise<void> {
  return fetch(getBaseUrl() + '/EPG/JSON/PeriodPVRMgmt', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify(nuke ? { action: SeriesRecordingAction.DELETE } : options),
  })
    .then(status)
    .then(catchNoRecordSupportError)
    .catch(fetchError);
}

export function getPVRSpace() {
  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: [
        {
          name: 'QueryPVRSpace',
          param: {
            type: 1,
          },
        },
        {
          name: 'QueryPVRSpace',
          param: {
            type: 2,
          },
        },
      ],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (queryPVRSpace: AllPvrSpaceResponse) {
      let usedSpace: PvrSpaceResponseDetails = queryPVRSpace.responseList[0].msg;
      let spaceLeft: PvrSpaceResponseDetails = queryPVRSpace.responseList[1].msg;
      let space: PvrSpace = {
        used: usedSpace.space,
        left: spaceLeft.space,
      };
      return space;
    })
    .catch(fetchError);
}

export function getRecording(id: string) {
  return fetch(getBaseUrl() + '/EPG/JSON/QueryPVRById', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      pvrIds: id,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((recordingData: ApiRecordings) => {
      if (recordingData.pvrlist && recordingData.pvrlist.length > 0) {
        return recordingData.pvrlist[0];
      } else {
        throw new Error('Assets not found');
      }
    })
    .catch(fetchError);
}

export async function getContinueWatching(bookmarkList: Bookmark[] | undefined) {
  if (!bookmarkList || isEmpty(bookmarkList)) {
    return Promise.resolve([]);
  }

  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        requestList: bookmarkList.map((bookmark) => {
          return {
            name: 'QueryPVRById',
            param: {
              pvrIds: bookmark.contentId,
            },
          };
        }),
      }),
    });

    const data = await getDataFromResponse<HuaweiBatchResponse<'QueryPVRById', ApiRecordings>>(res);

    return data.responseList
      .map((r) => r.msg)
      .filter((r) => r.pvrlist && r.pvrlist.length > 0)
      .map((r) => r.pvrlist[0] as PvrRecording);
  } catch (error) {
    return fetchError(error as string);
  }
}

export async function getRecordingsForBookmarks(bookmarks: Bookmarks) {
  if (isEmpty(bookmarks) || isEmpty(bookmarks?.bookmarkList)) {
    return Promise.resolve([]);
  }

  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        requestList: bookmarks.bookmarkList.map((bookmark) => {
          return {
            name: 'QueryPVRById',
            param: {
              pvrIds: bookmark.contentId,
            },
          };
        }),
      }),
    });

    const data = await getDataFromResponse<HuaweiBatchResponse<'QueryPVRById', ApiRecordings>>(res);

    return data.responseList
      .map((r) => r.msg)
      .filter((r) => r.pvrlist && r.pvrlist.length > 0)
      .map((r) => r.pvrlist[0] as PvrRecording);
  } catch (error) {
    return fetchError(error as string);
  }
}

export function getAllScheduledRecordings() {
  return fetch(getBaseUrl() + '/EPG/JSON/QueryPVR', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      status: PvrStatus.NotRecorded,
      count: -1,
      offset: 0,
      pvrType: 2,
      isFilter: 0,
      expandSubTask: 1,
      orderType: 1,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (queryPVRData: ApiRecordings) {
      let scheduleList = queryPVRData.pvrlist.reverse();
      let uniqueScheduledRecordings: PvrRecording[] = [];
      let idList: string[] = [];
      scheduleList.forEach((pvrItem) => {
        if (!pvrItem.seriesId) {
          uniqueScheduledRecordings.push(pvrItem);
        } else if (!(idList.indexOf(pvrItem.seriesId) > -1)) {
          idList.push(pvrItem.seriesId);
          uniqueScheduledRecordings.push(pvrItem);
        }
      });
      return uniqueScheduledRecordings;
    })
    .catch(fetchError);
}

export async function getScheduledAndCurrentlyRecording() {
  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        requestList: [
          {
            name: 'QueryPVR',
            param: {
              status: Pvr.Status.NotRecorded,
              count: -1,
              offset: 0,
              pvrType: Pvr.Type.NPVR,
              isFilter: 0,
              expandSubTask: 2,
              orderType: 1,
            },
          },
          {
            name: 'QueryPVR',
            param: {
              status: Pvr.Status.BeingRecorded,
              count: -1,
              offset: 0,
              pvrType: Pvr.Type.NPVR,
              isFilter: 0,
              expandSubTask: 2,
              orderType: 1,
            },
          },
        ],
      }),
    });

    const data = await getDataFromResponse(res);

    const notRecorded = data.responseList[0].msg;
    const beingRecorded = data.responseList[1].msg;

    return [...beingRecorded.pvrlist, ...notRecorded.pvrlist] as Pvr.UnionRecording[];
  } catch (error) {
    return fetchError(error as string);
  }
}

export async function getRecordings() {
  const pvrStatus = `${Pvr.Status.RecordedSuccessfully},${Pvr.Status.BeingRecorded}`;
  const res = await fetch(getBaseUrl() + '/EPG/JSON/QueryPVR', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      status: pvrStatus,
      count: -1,
      offset: 0,
      pvrType: Pvr.Type.NPVR,
      isFilter: 0,
      expandSubTask: 2,
      orderType: Pvr.APIOrderType.StartTimeAsc,
      isReturnSeriesTask: 0,
    }),
  });

  const data = await getDataFromResponse<Api.PvrResponse>(res);
  return data.pvrlist;
}

export async function getRecordingEpisodes() {
  try {
    const pvrStatus = `${Pvr.Status.RecordedSuccessfully},${Pvr.Status.BeingRecorded}`;
    const res = await fetch(getBaseUrl() + '/EPG/JSON/QueryPVR', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        status: pvrStatus,
        count: -1,
        offset: 0,
        pvrType: Pvr.Type.NPVR,
        isFilter: 0,
        expandSubTask: 1,
        orderType: Pvr.APIOrderType.StartTimeAsc,
        isReturnSeriesTask: 0,
      }),
    });

    const data = await getDataFromResponse<Api.PvrResponse>(res);
    return data.pvrlist;
  } catch (error) {
    return fetchError(error as string);
  }
}

const enrichPvrRecordingData = (pvr: PvrRecording, subList: ApiRecordings, bookmarks: Bookmarks) => {
  pvr.pvrName = pvr.periodPVRTaskName || pvr.pvrName;
  if (Number(pvr.seriesType) === SeriesType.SeriesId && pvr.latestPVRTask) {
    pvr.pictures = pvr.latestPVRTask.pictures;
    // TODO: Perhaps something we need to use for V6
    // let pvrTitleToUse = pvr.latestPVRTask.pvrName;
    // if ((pvrTitleToUse.split('0').length - 1) > 8) {
    //   pvrTitleToUse = pvr.periodPVRTaskName;
    // }
    // if ((pvrTitleToUse.split('0').length - 1) > 8 || pvrTitleToUse === pvr.latestPVRTask.pvrName) {
    //   pvrTitleToUse = pvr.pvrName;
    // }
    // pvr.pvrName = pvrTitleToUse;
    pvr.pvrName = pvr.latestPVRTask.pvrName;
    pvr.subRecordings = [];
    subList.pvrlist.forEach((subPvr: PvrRecording) => {
      if (pvr.seriesId === subPvr.seriesId && pvr.channelId === subPvr.channelId) {
        pvr.subRecordings.push(subPvr);
      }
    });
    pvr.isSingle = false;
    return pvr;
  } else if (pvr.pvrId) {
    pvr.isSingle = true;
    appendBookmark(bookmarks, pvr);
    pvr.status = isCurrentlyRecording(pvr) ? PvrStatus.BeingRecorded : PvrStatus.RecordedSuccessfully;
    return pvr;
  }
};

const groupPvrSeries = (series: PvrRecording, bookmarks: Bookmarks) => {
  let seasons: Season[] = [];
  let isCurrentlyRecordingFlag = false;

  if (series.subRecordings) {
    series.subRecordings.forEach((episode: PvrRecording) => {
      if (episode.status === PvrStatus.BeingRecorded) {
        isCurrentlyRecordingFlag = isCurrentlyRecording(episode);
      }

      appendBookmark(bookmarks, episode);

      let season = seasons.find((x) => x.seasonNum === episode.seasonNum);
      if (season) {
        season.episodes.push(episode);
      } else {
        seasons.push({
          seasonNum: episode.seasonNum,
          episodes: [episode],
        });
      }
    });
  }

  // sort episodes by subNum
  seasons.forEach((season: Season) => {
    season.episodes.sort((a: PvrRecording, b: PvrRecording) => {
      return parseInt(a.subNum, 10) - parseInt(b.subNum, 10);
    });
  });

  // sort seasons by seasonNum
  seasons.sort((a: Season, b: Season) => {
    return parseInt(a.seasonNum, 10) - parseInt(b.seasonNum, 10);
  });

  if (isCurrentlyRecordingFlag) {
    series.status = PvrStatus.BeingRecorded;
  }

  series.sortedSeasons = seasons;
};
export function getAllFinishedRecordings(bookmarks: Bookmarks) {
  // fetch all recordings grouped by parent
  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: [
        {
          name: 'QueryPVR',
          param: {
            status: PvrStatus.ToLoadAndShow,
            count: -1,
            offset: 0,
            pvrType: 2,
            isFilter: 0,
            expandSubTask: 0,
            orderType: 1,
          },
        },
        {
          name: 'QueryPVR',
          param: {
            status: PvrStatus.ToLoadAndShow,
            count: -1,
            offset: 0,
            pvrType: 2,
            isFilter: 0,
            expandSubTask: 1,
            orderType: 1,
          },
        },
      ],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(async function (queryPVRData: AllRecordingsResponse) {
      let topList = queryPVRData.responseList[0].msg;
      let subList = queryPVRData.responseList[1].msg;
      let recordings: PvrRecording[] = [];

      recordings = topList.pvrlist
        .map((pvr) => enrichPvrRecordingData(pvr, subList, bookmarks))
        .filter((pvr) => pvr !== undefined) as PvrRecording[];

      // group pvrs by series/season/episode
      // [{seasonNum:'1', episodes:[PvrRecording,... ]},
      // {seasonNum:'2', episodes:[PvrRecording,... ]}]
      recordings.forEach((series: PvrRecording) => groupPvrSeries(series, bookmarks));

      // reverse
      recordings.forEach((series: PvrRecording) => {
        series.sortedSeasons.reverse();
        series.sortedSeasons.forEach((season: Season) => {
          season.episodes.reverse();
        });
      });

      updateSeriesUnwatchedCount(recordings);

      // re-reverse
      recordings.forEach((series: PvrRecording) => {
        series.sortedSeasons.forEach((season: Season) => {
          season.episodes.reverse();
        });
      });
      return recordings;
    })
    .catch(fetchError);
}

function appendBookmark(bookmarks: Bookmarks, recording: PvrRecording) {
  if (bookmarks.bookmarkList) {
    let foundBookmark = bookmarks.bookmarkList.find((bookmark) => bookmark.contentId === recording.pvrId);
    if (foundBookmark) {
      recording.bookmark = foundBookmark;
    }
  }
}

// loops from top of reversed array to count unwatchedSubRecordings
function updateSeriesUnwatchedCount(recordings: PvrRecording[]) {
  recordings.forEach((series: PvrRecording, index: number) => {
    if (series.subRecordings) {
      let unwatchedSubRecordings: number = 0;
      let lastPlayedEpisodeIndex = -1;
      let lastPlayedSeasonIndex = -1;

      // find the highest played episode and count episodes before we find it
      series.sortedSeasons.forEach((season: Season, seasonIndex: number) => {
        if (lastPlayedSeasonIndex === -1) {
          season.episodes.forEach((episode: PvrRecording, episodeIndex: number) => {
            if (episode.bookmarkTime && lastPlayedEpisodeIndex === -1) {
              lastPlayedEpisodeIndex = episodeIndex;
              lastPlayedSeasonIndex = seasonIndex;
            } else if (lastPlayedSeasonIndex === -1 && lastPlayedEpisodeIndex === -1) {
              unwatchedSubRecordings++;
            }
          });
        }
      });
      series.unwatchedSubRecordings = unwatchedSubRecordings;
    }
  });
  return recordings;
}

export async function getPVRBookmarks() {
  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/QueryBookmark', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        bookmarkType: 2,
      }),
    });

    return await getDataFromResponse<Bookmarks>(res);
  } catch (error) {
    return fetchError(error as string);
  }
}

export function getPvrPlayUrl(asset: PvrRecording) {
  var playUrl = '/EPG/JSON/Play';

  return fetch(getBaseUrl() + playUrl, {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      pvrId: asset.pvrId,
      contentid: asset.channelId,
      playtype: 9,
      mediaid: asset.mediaId,
      profileId: -1,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then(function (data: PlayResult) {
      if (data.retcode === HuaweiErrors.PVR_NOT_FOUND) {
        return Promise.reject({
          customData: '',
          manifestUrl: '',
        });
      } else {
        const trigger = data.triggers && data.triggers[0] ? data.triggers[0].getLicenseTrigger : null;
        return Promise.resolve({
          customData: trigger ? trigger.customData : '',
          licenseUrl: trigger ? trigger.licenseURL : '',
          manifestUrl: data.url,
        } as StreamingObject);
      }
    })
    .catch(fetchError);
}

export function getLeadLagtimes() {
  return fetch(getBaseUrl() + '/EPG/JSON/QuerySubscriberEx', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      profileId: getItemFromLocalStorage('user'),
      userType: 1,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: ExtensionInfoResponse) => {
      let leadLagTimes: LeadLagTime = {
        leadTime: 3,
        lagTime: 2,
      };
      if (response.retcode === HUAWEI_SUCCESS_RETCODE) {
        if (response.extensionInfo) {
          let leadTime = response.extensionInfo.find((x) => x.key === 'PVRDefaultLeadTime');
          leadLagTimes.leadTime = leadTime ? Number(leadTime.value) : 2;

          let lagTime = response.extensionInfo.find((x) => x.key === 'PVRDefaultLagTime');
          leadLagTimes.lagTime = lagTime ? Number(lagTime.value) : 3;
        }
      }
      return leadLagTimes;
    })
    .catch(fetchError);
}

export function addSingleRecording(
  program: ProgramToRecord,
  channel: Channel,
  deleteMode: RecordingDeleteMode,
): Promise<void> {
  return getLeadLagtimes().then((leadLagTimes) => {
    leadLagTimes = leadLagTimes as LeadLagTime;

    return fetch(getBaseUrl() + '/EPG/JSON/AddPVR', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        beginOffset: Number(leadLagTimes.leadTime),
        endOffset: Number(leadLagTimes.lagTime),
        programId: program.id,
        beginTime: fromMoment(program.starttime as moment.Moment),
        endTime: fromMoment(program.endtime as moment.Moment),
        type: PvrType.nPVR,
        mediaId: getnPVRMediaId(channel),
        deleteMode,
      }),
    })
      .then(status)
      .then(catchNoRecordSupportError);
  });
}

export function deleteMultiplePVRs(pvrIds: string[]): Promise<void> {
  return fetch(getBaseUrl() + '/EPG/JSON/DeletePVR', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      pvrId: pvrIds.join(),
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function deleteSeries(periodPVRId: string): Promise<void> {
  return fetch(getBaseUrl() + '/EPG/JSON/DeleteSubPVR', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      periodPVRId: periodPVRId,
      status: '0,1,2,8',
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function removeSingleRecording({ pvrId }: Pick<PvrRecording, 'pvrId'>): Promise<void> {
  return deleteRecording(pvrId);
}

export function deleteEpisode(recording: PvrRecording): Promise<void> {
  return deleteRecording(recording.pvrId);
}

function deleteRecording(pvrId: string): Promise<void> {
  return fetch(getBaseUrl() + '/EPG/JSON/DeletePVR', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      pvrId: pvrId,
      type: PvrType.nPVR,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function updateSingleRecording(recording: PvrRecording, deleteMode: RecordingDeleteMode): Promise<void> {
  return fetch(getBaseUrl() + '/EPG/JSON/UpdatePVR', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      pvrId: recording.pvrId,
      type: PvrType.nPVR,
      deleteMode,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function addSeriesRecording(
  seriesId: string,
  channel: Channel,
  effectivetime: string,
  deleteMode: RecordingDeleteMode,
) {
  return manageSeriesRecording({
    action: SeriesRecordingAction.ADD,
    programSerialEnable: 1,
    task: {
      seriesID: seriesId,
      effectivetime: effectivetime,
      type: PvrType.nPVR,
      deleteMode: deleteMode,
      seriesType: SeriesType.SeriesId,
      mediaId: getnPVRMediaId(channel),
    },
  });
}

export const addChronologicalSeriesRecording = (
  seriesId: string,
  channel: Channel,
  effectivetime: string,
  deleteMode: RecordingDeleteMode,
  selectedSubNum: number,
  selectedSeasonNum: number,
) => {
  return manageSeriesRecording({
    action: SeriesRecordingAction.ADD,
    programSerialEnable: 1,
    task: {
      seriesID: seriesId,
      effectivetime: effectivetime,
      type: PvrType.nPVR,
      deleteMode: deleteMode,
      seriesType: SeriesType.SeriesId,
      mediaId: getnPVRMediaId(channel),
      timeMode: 1,
      selectedSubNum,
      selectedSeasonNum,
    },
  });
};

export function updateSeriesRecording(
  recording: PvrRecording,
  canceled: boolean,
  deleteMode: RecordingDeleteMode = recording.deleteMode,
) {
  return manageSeriesRecording({
    action: SeriesRecordingAction.UPDATE,
    task: {
      periodPVRTaskId: recording.periodPVRTaskId,
      type: '2',
      deleteMode: deleteMode,
    },
  });
}

export function cancelRecording(
  recording: Pvr.Recording | Pvr.SingleRecording | PvrRecording,
  onlyCancelEpisode?: boolean,
) {
  if (recordingIsSingle(recording) || onlyCancelEpisode) {
    return fetch(getBaseUrl() + '/EPG/JSON/DeletePVR', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        pvrId: recording.pvrId,
        type: Pvr.Type.NPVR,
      }),
    });
  }

  const now = moment();

  return fetch(getBaseUrl() + '/EPG/JSON/PeriodPVRMgmt', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      action: SeriesRecordingAction.UPDATE,
      strategyType: 0,
      task: {
        periodPVRTaskId: recording.periodPVRTaskId,
        checkExist: 1,
        effectivetime: fromMoment(moment(now).subtract(1, 's')),
        overtime: fromMoment(now),
      },
    }),
  });
}

export function toggleRecordingDeleteMode(recording: Pvr.Recording, deleteMode: Pvr.DeleteMode) {
  if (recordingIsSingle(recording)) {
    return fetch(getBaseUrl() + '/EPG/JSON/UpdatePVR', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        pvrId: recording.pvrId,
        type: Pvr.Type.NPVR,
        deleteMode,
      }),
    });
  }

  return fetch(getBaseUrl() + '/EPG/JSON/PeriodPVRMgmt', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      action: SeriesRecordingAction.UPDATE,
      task: {
        periodPVRTaskId: recording.periodPVRTaskId,
        type: '2',
        deleteMode,
      },
    }),
  });
}
