import {
  ContentType,
  FavouriteResponse,
  FavoCatalogResponse,
  HuaweiBatchResponse,
  SeriesRelationsResponse,
  ContentDetailResponse,
  SeriesOrSinglePrograms,
  ProgramContentDetailResponse,
  Program,
  Channel,
  ChannelFilter,
  ProgramArchiveListResponse,
  VodAsset,
} from '../interfaces';
import { getBaseUrl, getSessionTicket } from '../config';
import { status, json, fetchError } from '../controllers/Fetch';
import { getProgramSeries, playBillInclude } from './programarchive';
import { nowHuaweiFormat, minusWeeksFromNow } from '../utils/huaweiUtils';
import * as huaweiApi from '.';
import { isEmpty } from 'lodash';

export const favouriteVodInclude =
  'id,name,type,introduce,picture,casts,cast,vodtype,seriesType,' +
  'sitcomnum,episodeTotalCount,price,genres,genreIds,mediafiles,clipfiles,rentperiod,ratingid,visittimes,' +
  'starttime,isfavorited,issubscribed,isRefresh,producedate,foreignsn,fathervodlist,country,categoryIds,' +
  'extensionInfo,externalContentCode,company,companyName,location,subtitles,languages';

export const favouritePlaybillInclude =
  'id,foreignsn,channelid,name,type,introduce,starttime,endtime,ratingid,picture,istvod,' +
  'isnpvr,contentId,contentType,cast,casts,subName,subNum,seasonNum,genres,genreIds,producedate,extensionInfo,seriesID,visittimes,' +
  'externalContentCode,lifetimeId,originalTitle,mainGenre,gapFiller,episodeInformation,isLive,externalDescription,pictures,' +
  'seriesDescriptions,recordedMediaIds,seasonId';

export function addAssetToFavourites(assetID: string, contentType: ContentType, favoId: string = '-1') {
  let addBody = {
    action: 'ADD',
    autoCover: 0,
    contentid: assetID,
    contenttype: contentType,
    favoId: favoId,
  };

  return fetch(getBaseUrl() + '/EPG/JSON/FavoriteManagement', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify(addBody),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function removeAssetFromFavourites(assetID: string, contentType: ContentType, favoId: string = '-1') {
  let removeBody = {
    action: 'DELETE',
    autoCover: 0,
    contentid: assetID,
    contenttype: contentType,
    favoId: favoId,
  };

  return fetch(getBaseUrl() + '/EPG/JSON/FavoriteManagement', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify(removeBody),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function getFavoIds(): Promise<FavoCatalogResponse> {
  return fetch(getBaseUrl() + '/EPG/JSON/QueryFavoCatalog', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function createFavoCatalogs(name: string) {
  return fetch(getBaseUrl() + '/EPG/JSON/AddFavoCatalog', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      favoName: name,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function getAllFavourites(contentType?: ContentType): Promise<FavouriteResponse> {
  return huaweiApi.getFavorites({
    contenttype: contentType ? contentType : ContentType.VIDEO_VOD,
  });
}

export function getAllFavouriteAssets() {
  return new Promise((resolve, reject) => {
    getAllFavourites(ContentType.VIDEO_VOD)
      .then((favourites: FavouriteResponse) => {
        if (Object.keys(favourites).length === 0) {
          return reject();
        } else {
          let assets = favourites.favoritelist;
          // Sort newest first
          assets.sort((a, b) => {
            return Number(b.collectTime) - Number(a.collectTime);
          });
          return assets
            .map((favourite) => {
              return favourite.id;
            })
            .join(',');
        }
      })
      .then((assetString) => {
        return getAllAssetsIncludingProgramArchive(assetString).then(resolve);
      });
  });
}

export function extractFavouriteIds(favourites: FavouriteResponse) {
  if (Object.keys(favourites).length !== 0) {
    let assets = favourites.favoritelist;
    // Sort newest first
    assets.sort((a, b) => {
      return Number(b.collectTime) - Number(a.collectTime);
    });
    return assets
      .map((favourite) => {
        return favourite.id;
      })
      .join(',');
  }
  return '';
}

export function getNonProgramArchiveFavouriteAssets() {
  return new Promise((resolve, reject) => {
    getAllFavourites(ContentType.VIDEO_VOD)
      .then((favourites) => {
        let allFavourites = extractFavouriteIds(favourites);
        return allFavourites === '' ? reject() : allFavourites;
      })
      .then((assetString) => {
        return getAllAssetsExcludingProgramArchive(assetString).then(resolve);
      });
  });
}

export function getAllAssetsExcludingProgramArchive(assetString: string | void): Promise<ContentDetailResponse> {
  if (isEmpty(assetString)) {
    return Promise.resolve({ vodlist: [] });
  }
  return fetch(getBaseUrl() + '/EPG/JSON/ContentDetail', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      vod: assetString,
      category: '-1',
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function getEPGFavouriteId(assetString: string, idType: number = 1, type: string = 'vod') {
  let EPGBody = {
    requestList: [
      {
        name: 'ContentDetail',
        param: {
          idType: idType,
          metaDataVer: 'Channel/1.1',
          properties: [
            {
              name: 'vod',
              include: favouriteVodInclude,
            },
            {
              name: 'playbill',
              include: favouritePlaybillInclude,
            },
          ],
        },
      },
      {
        name: 'ContentDetail',
        param: {
          filterType: 1,
          idType: idType,
          metaDataVer: 'Channel/1.1',
          properties: [
            {
              name: 'vod',
              include: favouriteVodInclude,
            },
            {
              name: 'playbill',
              include: favouritePlaybillInclude,
            },
          ],
        },
      },
    ],
  };

  EPGBody.requestList.forEach((req) => {
    req.param[type] = assetString;
  });

  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify(EPGBody),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: HuaweiBatchResponse<'ContentDetail', ContentDetailResponse>) => {
      let contentDetail: ContentDetailResponse = { vodlist: [] } as ContentDetailResponse;
      response.responseList.forEach((contentDetailResponse) => {
        if (contentDetailResponse.msg.vodlist && contentDetailResponse.msg.vodlist.length > 0) {
          contentDetail.vodlist = contentDetail.vodlist.concat(contentDetailResponse.msg.vodlist);
        }
      });
      return Promise.resolve(contentDetail);
    })
    .catch(fetchError);
}

export function getAllAssetsIncludingProgramArchive(assetString: string | void): Promise<ContentDetailResponse> {
  if (isEmpty(assetString)) {
    return Promise.resolve({ vodlist: [] });
  }
  return fetch(getBaseUrl() + '/EPG/JSON/ContentDetail', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      properties: [],
      category: '',
      channel: '0',
      filterType: '1',
      idType: '0',
      metaDataVer: 'Channel/1.1',
      playbill: '0',
      vas: '0',
      vod: assetString,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function getProgramArchiveFavouriteAssets(externalContentCodes: string[]) {
  return getProgramSeries(externalContentCodes).then(
    (seriesRelations: HuaweiBatchResponse<'QuerySeriesRelation', SeriesRelationsResponse>) => {
      let programArchiveIds = [] as SeriesOrSinglePrograms[];
      seriesRelations.responseList.forEach((seriesRelation, idx) => {
        if (Number(seriesRelation.msg.counttotal) > 0) {
          programArchiveIds.push({
            index: idx,
            seriesRecording: true,
            programID: seriesRelation.msg.seriesRelations[0].programID,
            externalContentCode: externalContentCodes[idx],
          } as SeriesOrSinglePrograms);
        } else {
          programArchiveIds.push({
            index: idx,
            seriesRecording: false,
            programID: '',
            externalContentCode: externalContentCodes[idx],
          } as SeriesOrSinglePrograms);
        }
      });
      return programArchiveIds;
    },
  );
}

export function getAllProgramArchiveFavouritesRelations(seriesID: string[]) {
  return getProgramSeries(seriesID).then(
    (seriesRelations: HuaweiBatchResponse<'QuerySeriesRelation', SeriesRelationsResponse>) => {
      let programArchiveIds: string[] = [];
      seriesRelations.responseList.forEach((seriesRelation) => {
        if (Number(seriesRelation.msg.counttotal) > 0) {
          seriesRelation.msg.seriesRelations.forEach((relation) => {
            programArchiveIds.push(relation.programID);
          });
        }
      });
      return programArchiveIds;
    },
  );
}

export function getPlaybillFavourites(playbillIds: string[]) {
  if (isEmpty(playbillIds)) {
    return Promise.resolve({ channellist: [], vodlist: [], categorylist: [], vaslist: [] });
  }
  return fetch(getBaseUrl() + '/EPG/JSON/ContentDetail', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      metaDataVer: 'Channel/1.1',
      idType: '0',
      filterType: '1',
      playbill: playbillIds.join(','),
      properties: [
        {
          name: 'vod',
          include: favouriteVodInclude,
        },
        {
          name: 'playbill',
          include: favouritePlaybillInclude,
        },
      ],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}
interface ProgramArchiveRelations {
  collectTime: string;
  id: string;
  type: ContentType;
  externalContentCode?: string;
  programID?: string;
}

export function fetchPossibleProgramFavourites(channels: Channel[]) {
  return getProgramArchiveFavourites(channels).then((responsePlaybill: ProgramContentDetailResponse) => {
    const allIds = responsePlaybill.playbilllist.map((x) => x.id);
    const seriesIdsToCheck = responsePlaybill.playbilllist.map((x) => x.seriesID).filter((x) => x);

    return getAllProgramArchiveFavouritesRelations(seriesIdsToCheck).then((possibleFavouritedProgramIds) => {
      return possibleFavouritedProgramIds.concat(allIds);
    });
  });
}

export function getProgramArchiveFavourites(channels: Channel[]): Promise<ProgramContentDetailResponse> {
  return new Promise(async (resolve) => {
    const allVodFavourites = await getAllFavourites(ContentType.VIDEO_VOD);
    const allVodFavouritesIds = extractFavouriteIds(allVodFavourites);
    const allVodFavouritesAssets = await getAllAssetsIncludingProgramArchive(allVodFavouritesIds);
    const allVodFavouriteAssetsExclProgramArchive = await getAllAssetsExcludingProgramArchive(allVodFavouritesIds);

    const allProgramFavourites = await getAllFavourites(ContentType.PROGRAM);

    let allVodFavouritesList = allVodFavourites.favoritelist || [];
    let allProgramArchiveFavouritesList = allProgramFavourites.favoritelist || [];

    let correctSortOrder = allVodFavouritesList
      .concat(allProgramArchiveFavouritesList)
      .sort((a: ProgramArchiveRelations, b: ProgramArchiveRelations) => {
        return Number(b.collectTime) - Number(a.collectTime);
      })
      .map((x: ProgramArchiveRelations) => x);

    const favoritesIncludingProgramArchive = allVodFavouritesAssets.vodlist || [];
    const favouritesExcludingProgramArchive = allVodFavouriteAssetsExclProgramArchive.vodlist || [];

    let onlyProgramArchiveFavourites: VodAsset[] = [];
    if (favoritesIncludingProgramArchive.length === 1) {
      onlyProgramArchiveFavourites[0] = favoritesIncludingProgramArchive[0];
    } else if (favouritesExcludingProgramArchive.length === 0 && favoritesIncludingProgramArchive.length > 0) {
      onlyProgramArchiveFavourites = favoritesIncludingProgramArchive.filter((incl) => {
        return favoritesIncludingProgramArchive.find((excl) => excl.id !== incl.id);
      });
    } else if (favoritesIncludingProgramArchive.length > 0) {
      onlyProgramArchiveFavourites = favoritesIncludingProgramArchive.filter((incl) => {
        return favouritesExcludingProgramArchive.find((excl) => excl.id !== incl.id);
      });
    } else {
      onlyProgramArchiveFavourites = favoritesIncludingProgramArchive;
    }

    const onlyProgramIds = onlyProgramArchiveFavourites.map((x) => {
      let isInCorrectSortOrder = correctSortOrder.find((z: ProgramArchiveRelations) => z.id === x.id);
      if (isInCorrectSortOrder) {
        isInCorrectSortOrder.externalContentCode = x.externalContentCode;
      }
      return x.externalContentCode;
    });

    const allProgramArchiveAssets: SeriesOrSinglePrograms[] = await getProgramArchiveFavouriteAssets(onlyProgramIds);

    const singleRecordingsIds = allProgramArchiveAssets
      .filter((program) => {
        return !program.seriesRecording;
      })
      .map((x) => {
        return x.externalContentCode;
      });

    const singleProgramArchive = await getSingleRelations(singleRecordingsIds, channels);

    let seriesWithNoRealSeriesRelation: string[] = [];
    singleProgramArchive.responseList.forEach((singleProgram: ProgramArchiveListResponse) => {
      if (singleProgram.msg && singleProgram.msg.playbillList && singleProgram.msg.playbillList.length > 0) {
        let parent = singleProgram.msg.playbillList[0];
        let found = correctSortOrder.find((z: ProgramArchiveRelations) => z.externalContentCode! === parent.seriesID);
        if (found) {
          found.programID = parent.id;
          seriesWithNoRealSeriesRelation.push(parent.id);
        }
      }
    });

    const seriesRecordingsIds = allProgramArchiveAssets
      .filter((program) => {
        return program.seriesRecording;
      })
      .map((x) => {
        let found = correctSortOrder.find(
          (z: ProgramArchiveRelations) => z.externalContentCode! === x.externalContentCode,
        );
        if (found) {
          found.programID = x.programID;
        }
        return x.programID;
      })
      .concat(seriesWithNoRealSeriesRelation);

    const standaloneProgramIds = correctSortOrder
      .filter((x: ProgramArchiveRelations) => (x ? x.type === ContentType.PROGRAM && !x.programID : false))
      .map((z: ProgramArchiveRelations) => z.id);

    const seriesProgramArchive = await getPlaybillFavourites(seriesRecordingsIds);
    const standalonePrograms = await getPlaybillFavourites(standaloneProgramIds);

    let combineSeriesSingleAndStandalone: Program[];
    // remove seriesId from standalone episodes which have no real series relation
    if (seriesProgramArchive.playbilllist) {
      seriesProgramArchive.playbilllist.forEach((series: Program) => {
        let found = seriesWithNoRealSeriesRelation.find((x) => x === series.id);
        if (found) {
          series.seasonNum = 'FAKE';
          series.subNum = 'FAKE';
          series.fakeSeriesID = (' ' + series.seriesID).slice(1); // deep copy
          // @ts-ignore deletion of non-optional property
          delete series.seriesID;
        }
      });
      let standalones = standalonePrograms.playbilllist;
      if (standalones) {
        combineSeriesSingleAndStandalone = seriesProgramArchive.playbilllist.concat(standalonePrograms.playbilllist);
      } else {
        combineSeriesSingleAndStandalone = seriesProgramArchive.playbilllist;
      }
    } else if (standalonePrograms.playbilllist) {
      combineSeriesSingleAndStandalone = standalonePrograms.playbilllist;
    }

    let sortedAssets: ProgramContentDetailResponse = {
      playbillList: [],
      playbilllist: [],
    };

    // hide favourites that are no longer in the catchup archive
    correctSortOrder = correctSortOrder.filter((orderElement: ProgramArchiveRelations) => {
      if (orderElement) {
        if (orderElement.id && orderElement.externalContentCode && orderElement.programID) {
          return orderElement;
        } else if (orderElement.id && orderElement.type === ContentType.PROGRAM) {
          return orderElement;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    });

    correctSortOrder = correctSortOrder.filter((x) => x);

    correctSortOrder.forEach((orderElement: ProgramArchiveRelations) => {
      if (orderElement) {
        let id: string;
        if (orderElement.type === ContentType.PROGRAM && !orderElement.programID) {
          id = orderElement.id;
        } else if (orderElement.type === ContentType.VIDEO_VOD && orderElement.programID) {
          id = orderElement.programID;
        } else {
          id = orderElement.id;
        }
        let found = combineSeriesSingleAndStandalone
          ? combineSeriesSingleAndStandalone.find((x) => (x ? x.id === id : false))
          : false;
        if (found) {
          sortedAssets.playbilllist.push(found);
        }
      }
    });
    resolve(sortedAssets);
  });
}

export function getFavouriteAssetDetails(assetString: string | void): Promise<ContentDetailResponse> {
  return fetch(getBaseUrl() + '/EPG/JSON/ContentDetail', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      vod: assetString,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function getSingleRelations(externalContentCodes: string[], channels: Channel[]) {
  if (isEmpty(externalContentCodes)) {
    return Promise.resolve({ responseList: [] });
  }
  let channelsToLoad: ChannelFilter[] = channels.map((channel) => {
    return {
      channelId: channel.contentId,
      type: 'CHANNEL',
    };
  });
  let body = { requestList: [{}] };
  body.requestList = externalContentCodes.map((externalContentCode) => {
    return {
      name: 'QueryPlaybillByFilter',
      param: {
        begintime: minusWeeksFromNow(2),
        endtime: nowHuaweiFormat(),
        channelIDs: channelsToLoad,
        count: 6,
        filterlist: [
          {
            key: 'SeriesId',
            value: externalContentCode,
          },
        ],
        offset: 0,
        properties: [
          {
            include: playBillInclude,
            name: 'playbill',
          },
        ],
        type: 2,
      },
    };
  });

  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify(body),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}
