export const Environments = function () {
  const ENV_VARS = {
    production: 'production',
    at: 'at',
    st: 'st',
  };

  const AltiboxAPIUrls = {
    production: 'https://api.services.altibox.net',
    at: 'https://api-at3.services.altibox.net',
    st: 'https://api-st1.services.snartibox.net',
  };

  const HuaweiAPIUrls = {
    production: 'https://tvotteds.envision.services.altibox.net',
    at: 'https://tvottedsstg.at.envision.services.altibox.net',
    st: 'https://mrotvottepgottupgtest.st.snartibox.no',
  };

  const AmrAPIUrls = {
    production: 'https://amrapi.services.altibox.net',
    at: 'https://amrapi-at3.services.altibox.net',
    st: 'https://amrapi-st3.services.snartibox.net',
  };

  const AndroidAppIds = {
    production: 'no.altibox.nh',
    at: 'no.altibox.nh',
    st: 'no.altibox.nh',
  };

  const IosAppIds = {
    production: '1164396975',
    at: '1164396975',
    st: '1164396975',
  };

  const IosAppUrls = {
    production: 'itms-apps://itunes.apple.com/no/app/altibox/id1164396975?mt=8',
    at: 'itms-apps://itunes.apple.com/no/app/altibox/id1164396975?mt=8',
    st: 'itms-apps://itunes.apple.com/no/app/altibox/id1164396975?mt=8',
  };

  const ChromecastIDs = {
    production: '69680883',
    at: 'FC6EFC61',
    st: '1646497D',
  };

  function getCurrentApiEnvironment() {
    const current = localStorage.getItem('aib_api_url');
    if (!current) {
      return ENV_VARS.production;
    } else {
      const isST = current.indexOf(ENV_VARS.st) !== -1;
      const isAT = current.indexOf(ENV_VARS.at) !== -1;
      if (isST) {
        return ENV_VARS.st;
      } else if (isAT) {
        return ENV_VARS.at;
      } else {
        return ENV_VARS.production;
      }
    }
  }

  function setApiToST(env?: number) {
    let url = AltiboxAPIUrls.st;
    if (env) {
      url = replaceApiString(url, env);
    }
    setAltiboxAPIUrl(url);
    setHuaweiToST();
  }

  function setApiToAT(env?: number) {
    let url = AltiboxAPIUrls.at;
    if (env) {
      url = replaceApiString(url, env);
    }
    setAltiboxAPIUrl(url);
    setHuaweiToAT();
  }

  function setApiToProd() {
    setAltiboxAPIUrl(AltiboxAPIUrls.production);
    setHuaweiAPIUrl(HuaweiAPIUrls.production);
    _reload();
  }

  function setAltiboxAPIToAT(env?: number) {
    let url = AltiboxAPIUrls.at;
    if (env) {
      url = replaceApiString(url, env);
    }
    setAltiboxAPIUrl(url);
  }

  function setAltiboxApiToProd() {
    setAltiboxAPIUrl(AltiboxAPIUrls.production);
    _reload();
  }

  function setAltiboxApiToST(env?: number) {
    let url = AltiboxAPIUrls.st;
    if (env) {
      url = replaceApiString(url, env);
    }
    setAltiboxAPIUrl(url);
  }

  function setHuaweiToST() {
    setHuaweiAPIUrl(HuaweiAPIUrls.st);
  }

  function setHuaweiToAT() {
    setHuaweiAPIUrl(HuaweiAPIUrls.at);
  }

  function setHuaweiToProd() {
    setHuaweiAPIUrl(HuaweiAPIUrls.production);
  }

  function setAmrToST() {
    setAmrAPIUrl(AmrAPIUrls.st);
  }

  function setAmrToAT() {
    setAmrAPIUrl(AmrAPIUrls.at);
  }

  function setAmrToProd() {
    setAmrAPIUrl(AmrAPIUrls.production);
  }

  function setEnvToST(env?: number) {
    setApiToST(env);
    setHuaweiToST();
  }

  function setEnvToAT(env: number) {
    setApiToAT(env);
    setHuaweiToAT();
  }

  function useForgerock() {
    localStorage.setItem('login_method', 'forgerock');
    console.info('Login method set to forgerock');
  }

  function useUbi() {
    localStorage.setItem('login_method', 'ubi');
    console.info('Login method set to ubi');
  }

  function setEnvToProd() {
    localStorage.removeItem('aib_api_url');
    localStorage.removeItem('huawei_url');
    _reload();
  }

  function setAltiboxAPIUrl(url: string) {
    localStorage.setItem('aib_api_url', url);
    _reload();
  }

  function setHuaweiAPIUrl(url: string) {
    localStorage.setItem('huawei_url', url);
    _reload();
  }

  function setAmrAPIUrl(url: string): void {
    localStorage.setItem('amr_url', url);
    _reload();
  }

  function replaceApiString(url: string, replaceNumber: number) {
    const idx = Number(url.search(/\d/));
    if (idx !== -1) {
      let temp = url.split('');
      temp[idx] = replaceNumber.toString();
      return temp.join('');
    } else {
      return url;
    }
  }

  function setCCToProd() {
    setCCId(ChromecastIDs.production);
  }

  function setCCToAT() {
    setCCId(ChromecastIDs.at);
  }

  function setCCToST() {
    setCCId(ChromecastIDs.st);
  }

  function setCCId(id: string) {
    localStorage.setItem('ccid', id);
    _reload();
  }

  function _reload() {
    document.location.reload();
  }

  return {
    ENV_VARS,
    AltiboxAPIUrls,
    HuaweiAPIUrls,
    AmrAPIUrls,
    AndroidAppIds,
    IosAppIds,
    IosAppUrls,
    getCurrentApiEnvironment,
    setAltiboxAPIToAT,
    setAltiboxApiToProd,
    setAltiboxApiToST,
    setEnvToAT,
    setEnvToST,
    setEnvToProd,
    setApiToST,
    setApiToAT,
    setApiToProd,
    setHuaweiToST,
    setHuaweiToAT,
    setHuaweiToProd,
    setAmrToST,
    setAmrToAT,
    setAmrToProd,
    setCCToProd,
    setCCToAT,
    setCCToST,
    setCCId,
    useForgerock,
    useUbi,
  };
};
