import { recordingKeys } from './keys';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { getScheduledAndCurrentlyRecording } from '../../api/recording';
import { toMoment } from '../../utils/huaweiUtils';
import { getPVRSpaceWarningMessage, reduceScheduledRecordings, sortScheduledRecordings } from '../../utils/pvrUtils';
import { removeNthOccurances } from '../../utils/commonUtils';
import { recordingIsSeries } from '../../typeGuards';
import { useBookmarks } from '../bookmarks/queries';
import {
  filterContinueWatching,
  filterDeletingSoon,
  generateGenreSwimlanes,
  generateSwimlane,
  groupIsSeries,
  groupRecordings,
  ingestNextEpisode,
  ingestUpdateTime,
  sortSeason,
  sortSeasons,
  sortSwimlane,
} from './utils';
import { Recording } from './types';
import { getContinueWatchingRecordings, getRecordingEpisodes, getRecordingSpace } from './api';
import { useChannels } from '../channels/queries';
import { useAllGenres, useEpgGenres } from '../genres/queries';
import { isAnyProperty } from '../../utils/queryUtils';
import { isEmpty } from 'lodash';
import { pvrSettings } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../views/app/actions';
import { RootState } from '../../interfaces';

//*  Docs:  https://react-query.tanstack.com/community/tkdodos-blog#4-status-checks-in-react-query

type UseSelect<TReturn, TArg> = ((arg: TArg) => TReturn) | undefined;

/**
 *
 * @returns All recordings
 */
export function useRecordings<TReturn = Recording.Query.Union[]>(
  options?: UseQueryOptions<Recording.Query.Union[], unknown, TReturn, typeof recordingKeys.all>,
) {
  return useQuery(recordingKeys.all, getRecordingEpisodes, { ...options });
}

/**
 *
 * @returns Groups episodes into a series object and movies into a movie object
 */
export function useGroupedRecordings<TReturn = Recording.Group.Union[]>(
  transform?: UseSelect<TReturn, Recording.Group.Union[]>,
  enabled = true,
): UseQueryResult<TReturn, unknown> {
  const { data: channels } = useChannels();
  const { data: genres } = useAllGenres();

  return useQuery<Recording.Query.Union[], unknown, TReturn>(recordingKeys.all, getRecordingEpisodes, {
    enabled: Boolean(channels) && Boolean(genres) && enabled,
    keepPreviousData: true,
    select: (data) => {
      const grouped = groupRecordings(data, { channels, genres });
      return typeof transform === 'function' ? transform(grouped) : (grouped as unknown as TReturn);
    },
  });
}

/**
 *
 * @param seriesId
 * @returns All seasons of a recording
 */
export function useRecordingSeasons<TReturn = Recording.Group.Season[]>(
  seriesId: string,
  sortValue?: Recording.Sort.Action,
  transform?: UseSelect<TReturn, Recording.Group.Season[]>,
) {
  return useGroupedRecordings((group) => {
    const seasons = getSeasons(group, seriesId);
    if (!seasons) {
      return undefined;
    }
    const sorted = sortSeasons(seasons, sortValue);
    return typeof transform === 'function' ? transform(sorted) : (sorted as unknown as TReturn);
  });
}

function getSeasons(groups: Recording.Group.Union[], seriesId: string, sortValue?: string) {
  return groups.find((group): group is Recording.Group.Series => groupIsSeries(group) && group.seriesId === seriesId)
    ?.seasons;
}

/**
 *
 * @param seriesId
 * @param seasonNum
 * @returns All episodes of a recording based on the season number
 */
export function useRecordingEpisodes<TReturn = Recording.Group.Season>(
  seriesId: string,
  seasonNum: string,
  sortValue?: Recording.Sort.Action,
  transform?: UseSelect<TReturn, Recording.Group.Season>,
) {
  return useGroupedRecordings((group) => {
    const season = getEpisodes(group, seriesId, seasonNum);
    if (!season) {
      return undefined;
    }
    const sorted = sortSeason(season, sortValue);
    return typeof transform === 'function' ? transform(sorted) : (sorted as unknown as TReturn);
  });
}

function getEpisodes(groups: Recording.Group.Union[], seriesId: string, seasonNum: string) {
  const seasons = getSeasons(groups, seriesId);

  return seasons && seasons.find((season) => season.seasonNum === seasonNum);
}

/**
 *
 * @returns All recordings that are currently recording or scheduled to record
 */
export function useScheduledRecordings() {
  return useQuery(recordingKeys.scheduled(), filterScheduledRecordings);
}

async function filterScheduledRecordings() {
  const data = await getScheduledAndCurrentlyRecording();

  const filtered = data.filter((recording) => {
    if (recordingIsSeries(recording)) {
      return toMoment(recording.latestPVRTask.endTime).isAfter();
    }

    return toMoment(recording.endTime!).isAfter();
  });

  const reducedRecordings = reduceScheduledRecordings(filtered);
  const sortedRecordings = sortScheduledRecordings(reducedRecordings);

  return removeNthOccurances(sortedRecordings, 'seriesId', 1);
}

/**
 *
 * @returns Recordings to continue watching
 */
export function useContinueWatchingRecordings<TReturn = Recording.Group.Union[]>(
  transform?: UseSelect<TReturn, Recording.Group.Union[]>,
) {
  const auth = useSelector((state: RootState) => state.authReducer.loggedInWithCredentials);
  const { data: bookmarks } = useBookmarks('pvr');
  const { data: channels } = useChannels();
  const { data: genres } = useAllGenres();

  const { data: recordings } = useRecordings({ enabled: Boolean(auth) });

  return useQuery(recordingKeys.continueWatching(bookmarks), () => getContinueWatchingRecordings(bookmarks), {
    enabled: Boolean(bookmarks) && Boolean(channels) && Boolean(genres) && Boolean(recordings),
    keepPreviousData: true,
    select: (data) => {
      const filtered = filterContinueWatching(data);
      const grouped = groupRecordings(filtered, { channels, genres });
      const withNextEpisode = ingestNextEpisode(grouped, recordings);
      const withUpdateTime = ingestUpdateTime(withNextEpisode, bookmarks).sort(
        (a, b) => Number(b.updateTime) - Number(a.updateTime),
      );

      return typeof transform === 'function' ? transform(withUpdateTime) : (withUpdateTime as unknown as TReturn);
    },
  });
}

let shouldShowWarningMessage = true;

/**
 *
 * @returns Used space and free space for recordings
 */
export function useRecordingsSpace() {
  const dispatch = useDispatch();

  return useQuery(recordingKeys.space(), getRecordingSpace, {
    onSuccess: (space) => {
      const warningMessage = getPVRSpaceWarningMessage(space);
      if (warningMessage && shouldShowWarningMessage) {
        dispatch(showAlert(warningMessage));
        shouldShowWarningMessage = false;
      }
    },
  });
}

/**
 *
 * @returns Recordings that will be deleted soon
 */
export function useDeletingSoon<TReturn = Recording.Group.Union[]>(
  transform?: UseSelect<TReturn, Recording.Group.Union[]>,
) {
  const { data: channels } = useChannels();
  const { data: genres } = useAllGenres();

  return useRecordings({
    enabled: Boolean(channels) && Boolean(genres),
    select: (data) => {
      const filtered = filterDeletingSoon(data, channels, pvrSettings.expiration);

      if (!filtered) {
        return undefined;
      }

      const grouped = groupRecordings(filtered, { channels, genres });
      return typeof transform === 'function' ? transform(grouped) : (grouped as unknown as TReturn);
    },
  });
}

/**
 *
 * @returns Seperates grouped recordings by genres
 */
export function useRecordingsGenresSwimlanes() {
  const { data: epgGenres } = useEpgGenres();
  return useGroupedRecordings((data) => generateGenreSwimlanes(data, epgGenres), Boolean(epgGenres));
}

/**
 *
 * @returns All swimlanes for the recording frontpage
 */
export function useRecordingsSwimlanes() {
  const groups = useGroupedRecordings((items) => generateSwimlane(Recording.Swimlane.Titles.AllRecordings, items));
  const continueWatching = useContinueWatchingRecordings((items) =>
    generateSwimlane(Recording.Swimlane.Titles.ContinueWatching, items, Recording.Sort.Action.ContinueWatching),
  );
  const deletingSoon = useDeletingSoon((items) =>
    generateSwimlane(Recording.Swimlane.Titles.DeletingSoon, items, Recording.Sort.Action.ExpirationTime),
  );
  const genres = useRecordingsGenresSwimlanes();

  const queries = [groups, continueWatching, deletingSoon, genres];

  const data = queries.reduce<Recording.Swimlane.Item[]>((acc, query) => {
    if (query.data === undefined) {
      return acc;
    }

    return isSwimlaneArray(query.data) ? [...acc, ...query.data] : [...acc, query.data];
  }, []);

  return {
    data,
    isLoading: isAnyProperty('isLoading', ...queries),
    isFetching: isAnyProperty('isFetching', ...queries),
    isSuccess: isAnyProperty('isSuccess', ...queries),
  };
}
export function useRecordingsSwimlane(slug: string, sortValue?: Recording.Sort.Action) {
  const swimlanes = useRecordingsSwimlanes();

  const selected = swimlanes.data.find((swimlane) => swimlane.slug === slug);
  const sorted = sortSwimlane(selected, sortValue);

  return {
    ...swimlanes,
    data: sorted,
    isEmpty: isEmpty(selected?.groups),
  };
}

function isSwimlaneArray(
  swimlane: Recording.Swimlane.Item | Recording.Swimlane.Item[] | undefined,
): swimlane is Recording.Swimlane.Item[] {
  return Array.isArray(swimlane);
}
