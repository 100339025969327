import moment from 'moment';
import { flatten } from 'lodash';

import * as api from '../../../api/tv';
import * as recordingApi from '../../../api/recording';
import * as favoritesApi from '../../../api/favorites';
import * as huaweiApi from '../../../api';
import * as popularContentApi from '../../../api/popularContent';

import {
  Channel,
  GetStateType,
  Program,
  ProgramToRecord,
  DispatchType,
  PvrStatus,
  PvrRecording,
  RecordingDeleteMode,
  RootState,
  GenreType,
  Genre,
  ChannelsRecordingsList,
  GAaction,
  Bookmarks,
  PvrSpace,
  AlertData,
  PvrSpaceStatus,
  ContentType,
  TVGuideChannelFilter,
  BroadcastStatus,
  MiniEpgPlaybills,
  FavoritePrograms,
  PopularContentChannelTimespan,
  AMR,
  ProgramDetailsType,
  ApiAuthResponse,
} from '../../../interfaces';
import { broadcastingNow, broadcastStatus } from '../../../utils/huaweiUtils';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import { markPvrWithIsWatched, loadPVRSpace } from '../../pvr/actions';
import { getNoRecordSupportMessage, getPVRSpaceWarningMessage, getStoragePercentUsed } from '../../../utils/pvrUtils';
import { showAlert } from '../../app/actions';
import { catchupAvailable } from '../../../utils/tvUtils';
import isEmpty from 'lodash/isEmpty';
import { hideDetailsPanel } from '../../details/actions';
import { recordingKeys } from '../../../queries/recordings/keys';
import { queryClient } from '../../../queries/client';
import { getThirdPartyCatchupInformation } from '../../../utils/thirdPartyCatchupUtils';

// https://spin.atomicobject.com/2017/07/24/redux-action-pattern-typescript/
export enum TypeKeys {
  SET_CHANNELS = 'SET_CHANNELS',
  SET_POPULAR_CHANNELS = 'SET_POPULAR_CHANNELS',
  SET_CURRENT_LIVE_PROGRAM = 'SET_CURRENT_LIVE_PROGRAM',
  SET_CHANNEL_FOCUSED = 'SET_CHANNEL_FOCUSED',
  SET_CHANNEL_PLAYING = 'SET_CHANNEL_PLAYING',
  SET_CATCHUP_PLAYING = 'SET_CATCHUP_PLAYING',
  SHOW_CHANNEL_LIST = 'SHOW_CHANNEL_LIST',
  HIDE_CHANNEL_LIST = 'HIDE_CHANNEL_LIST',
  ADD_PLAYBILL_PROGRAMS = 'ADD_PLAYBILL_PROGRAMS',
  REPLACE_TVGUIDE_PLAYBILL_PROGRAMS = 'REPLACE_TVGUIDE_PLAYBILL_PROGRAMS',
  APPEND_TVGUIDE_PLAYBILL_PROGRAMS = 'APPEND_TVGUIDE_PLAYBILL_PROGRAMS',
  REPLACE_TVGUIDE_SINGLE_CHANNEL_PLAYBILL = 'REPLACE_TVGUIDE_SINGLE_CHANNEL_PLAYBILL',
  SHOW_PROGRAM_DETAILS = 'SHOW_PROGRAM_DETAILS',
  HIDE_PROGRAM_DETAILS = 'HIDE_PROGRAM_DETAILS',
  SET_CATCHUP_DATA_LOADING = 'SET_CATCHUP_DATA_LOADING',
  SET_PLAYBILL_SHOULD_HIDE = 'SET_PLAYBILL_SHOULD_HIDE',
  SET_SERIES_RECORDINGS = 'SET_SERIES_RECORDINGS',
  SET_SINGLE_RECORDINGS = 'SET_SINGLE_RECORDINGS',
  RECORDING_ACTION_IN_PROGRESS = 'RECORDING_ACTION_IN_PROGRESS',
  RECORDING_ACTION_DONE = 'RECORDING_ACTION_DONE',
  SET_GENRES = 'SET_GENRES',
  RESET_CHANNEL_GUARDS = 'RESET_CHANNEL_GUARDS',
  SET_CHANNEL_FAVORITE = 'SET_CHANNEL_FAVORITE',
  REMOVE_CHANNEL_FAVORITE = 'REMOVE_CHANNEL_FAVORITE',
  SET_FAVORITE_PROGRAMS = 'SET_FAVORITE_PROGRAMS',
  SET_TVGUIDE_LOADING = 'SET_TVGUIDE_LOADING',
  SET_CHANNEL_LIST_FILTER = 'SET_CHANNEL_LIST_FILTER',
  SET_MINI_EPG_PROGRAMS = 'SET_MINI_EPG_PROGRAMS',
  SET_TVGUIDE_GENRE_FILTER_LIST = 'SET_TVGUIDE_GENRE_FILTER_LIST',
  SET_TVGUIDE_CURRENT_DATE = 'SET_TVGUIDE_CURRENT_DATE',
  CACHE_TVGUIDE = 'CACHE_TVGUIDE',
}

export type ActionTypes =
  | SetCurrentLiveProgramAction
  | SetChannelsAction
  | SetPopularChannelsAction
  | SetChannelFocusedAction
  | SetChannelPlayingAction
  | SetProgramPlayingAction
  | ShowChannelListAction
  | HideChannelListAction
  | ReplaceTvGuidePlaybillPrograms
  | AppendTvGuidePlaybillPrograms
  | ReplaceChannelsTvGuide
  | AddPlaybillPrograms
  | ShowProgramDetails
  | HideProgramDetails
  | SetPlaybillShouldHide
  | SetSeriesRecordings
  | SetSingleRecordings
  | RecordingActionInProgress
  | RecordingActionDone
  | SetCatchupDataLoadingAction
  | SetGenres
  | ResetChannelGuards
  | SetFavoritePrograms
  | SetChannelListFilter
  | SetMiniEpgPrograms
  | SetTvGuideGenreFilterList
  | SetTvGuideCurrentDate
  | CacheTvGuide;

export interface CacheTvGuide {
  type: TypeKeys.CACHE_TVGUIDE;
  currentDate: Date;
}

export function cacheTvGuide(date: Date) {
  return {
    type: TypeKeys.CACHE_TVGUIDE,
    currentDate: date,
  };
}

export interface SetTvGuideCurrentDate {
  type: TypeKeys.SET_TVGUIDE_CURRENT_DATE;
  date: Date;
}

export function setTvGuideCurrentDate(date: Date) {
  return {
    type: TypeKeys.SET_TVGUIDE_CURRENT_DATE,
    date,
  };
}

export interface SetTvGuideGenreFilterList {
  type: TypeKeys.SET_TVGUIDE_GENRE_FILTER_LIST;
  tvGuideGenreFilterList: string[];
}

export function setTVGuideGenreFilterList(tvGuideGenreFilterList: string[]) {
  return {
    type: TypeKeys.SET_TVGUIDE_GENRE_FILTER_LIST,
    tvGuideGenreFilterList,
  };
}

export interface SetChannelListFilter {
  type: TypeKeys.SET_CHANNEL_LIST_FILTER;
  tvGuideFilter: TVGuideChannelFilter;
}

export function setTVGuideChannelFilter(tvGuideFilter: TVGuideChannelFilter) {
  return {
    type: TypeKeys.SET_CHANNEL_LIST_FILTER,
    tvGuideFilter,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFavoriteChannels(): any {
  return function (dispatch: DispatchType) {
    return favoritesApi.getAllFavourites(ContentType.CHANNEL).then((favoriteChannels) => {
      // Reverse the list to make the most recent favorite channel render last.
      favoriteChannels.favoritelist?.reverse().forEach((channel) => {
        return dispatch({
          type: TypeKeys.SET_CHANNEL_FAVORITE,
          contentId: channel.id,
          sortByFavorites: true,
        });
      });
    });
  };
}

export interface ResetChannelGuards {
  type: TypeKeys.RESET_CHANNEL_GUARDS;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function resetChannelGuards(): any {
  return function (dispatch: DispatchType) {
    return dispatch({ type: TypeKeys.RESET_CHANNEL_GUARDS });
  };
}
export interface SetCurrentLiveProgramAction {
  type: TypeKeys.SET_CURRENT_LIVE_PROGRAM;
  currentProgram: Program;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateCurrentLiveProgram(): any {
  return function (dispatch: DispatchType, getState: GetStateType): void {
    let currentChannel = getState().channelsReducer.currentChannel;

    if (currentChannel) {
      let currentChannelId = currentChannel.contentId;
      const playbill = (getState().channelsReducer.playbills[currentChannelId] || []) as Program[];
      const currentProgram = playbill.find(broadcastingNow);

      if (currentProgram) {
        return dispatch({
          type: TypeKeys.SET_CURRENT_LIVE_PROGRAM,
          currentProgram,
        });
      }
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function unloadCurrentLiveProgram(): any {
  return function (dispatch: DispatchType): void {
    return dispatch({
      type: TypeKeys.SET_CURRENT_LIVE_PROGRAM,
      currentProgram: undefined,
    });
  };
}

export interface SetChannelsAction {
  type: TypeKeys.SET_CHANNELS;
  channels: Channel[];
}
export function setChannels(channels: Channel[]): SetChannelsAction {
  return {
    type: TypeKeys.SET_CHANNELS,
    channels,
  };
}

export interface SetPopularChannelsAction {
  type: TypeKeys.SET_POPULAR_CHANNELS;
  popularChannels: AMR.PopularChannel[];
}

export function setPopularChannels(popularChannels: AMR.PopularChannel[]): SetPopularChannelsAction {
  return {
    type: TypeKeys.SET_POPULAR_CHANNELS,
    popularChannels,
  };
}

export async function waitForPrograms({
  channelIds,
  date = new Date(),
  index,
  increment,
  preNumber = 3,
  nextNumber = 3,
}: {
  channelIds: string[];
  date?: Date;
  index?: number;
  increment?: number;
  preNumber?: number;
  nextNumber?: number;
}) {
  let condition = 0;
  let programs: Program[] = [];
  let channelsToShow = channelIds.length;
  let indexToStart = index ? index : 0;
  if (index! >= 0 && increment) {
    let loadedPrograms = await getPrograms(channelIds.slice(index, index! + increment), date, preNumber, nextNumber);
    programs = programs.concat(loadedPrograms);
    condition += index!;
  } else {
    while (condition <= channelsToShow) {
      condition = indexToStart;
      let loadedPrograms = await getPrograms(channelIds.slice(condition, condition + 10), date, preNumber, nextNumber);
      programs = programs.concat(loadedPrograms);
      indexToStart += 10;
    }
  }
  return programs.filter((x) => x);
}

export function reduceProgramMap(playbills: Record<string, Program[]>) {
  return Object.keys(playbills)
    .map((x) => playbills[x] as Program[])
    .reduce((result, next) => [...result, ...next], []);
}

function getPrograms(channelIds: string[], date: Date, preNumber: Number = 2, nextNumber: Number = 2) {
  return api.getAllPlaybills(channelIds, preNumber, nextNumber, date).then(reduceProgramMap);
}

export interface SetMiniEpgPrograms {
  type: TypeKeys.SET_MINI_EPG_PROGRAMS;
  epg: MiniEpgPlaybills;
}

function setMiniEpgPrograms(epg: MiniEpgPlaybills) {
  return {
    type: TypeKeys.SET_MINI_EPG_PROGRAMS,
    epg,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchMiniEpgPlaybills(auth: ApiAuthResponse | undefined, channelIds: string[]): any {
  return async function (dispatch: DispatchType) {
    const now = new Date();

    const batches = channelIds
      .reduce((accumulator, channelId, idx) => {
        const batch = Math.ceil((idx + 1) / 10) - 1;

        if (!accumulator[batch]) {
          accumulator[batch] = [channelId];
        } else {
          accumulator[batch].push(channelId);
        }

        return accumulator;
      }, [] as string[][])
      .map((batch) => getPrograms(batch, now, 50, 20));

    const epg: MiniEpgPlaybills = {};
    const programs = await Promise.all(batches).then((r) => flatten(r));
    const momentNow = moment();

    programs
      .filter((program) => {
        const { isExternal } = getThirdPartyCatchupInformation(auth, program);
        if (broadcastStatus(program, momentNow) === BroadcastStatus.Finished) {
          return isExternal || catchupAvailable(program);
        } else {
          return true;
        }
      })
      .forEach((program) => {
        if (epg[program.channelid]) {
          epg[program.channelid]!.push(program);
        } else {
          epg[program.channelid] = [program];
        }
      });

    for (const channel in epg) {
      if (epg[channel]) {
        let sorted = sortPlaybills(epg[channel]!);
        const idxOfLiveProgram = sorted.indexOf(
          sorted.find((program) => broadcastStatus(program, momentNow) === BroadcastStatus.Live)!,
        );

        if (idxOfLiveProgram > 20) {
          sorted = sorted.slice(idxOfLiveProgram - 20);
        }

        epg[channel] = sorted;
      }
    }

    dispatch(setMiniEpgPrograms(epg));
  };

  function sortPlaybills(playbills: Program[]) {
    return playbills.sort((a, b) => Number(a.starttime) - Number(b.starttime));
  }
}

export interface SetFavoritePrograms {
  type: TypeKeys.SET_FAVORITE_PROGRAMS;
  favorites: FavoritePrograms;
}

function setFavoritePrograms(favorites: FavoritePrograms) {
  return {
    type: TypeKeys.SET_FAVORITE_PROGRAMS,
    favorites,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchAllFavoriteIds(): any {
  return async function (dispatch: DispatchType, getState: GetStateType) {
    const { loggedInWithCredentials } = getState().authReducer;

    if (!loggedInWithCredentials) {
      return;
    }

    try {
      // Series
      const allVods = await (await favoritesApi.getAllFavourites(ContentType.VIDEO_VOD)).favoritelist;
      const allPrograms = await (await favoritesApi.getAllFavourites(ContentType.PROGRAM)).favoritelist;
      const favoriteIds = allVods.map((x) => x.id).concat(allPrograms.map((x) => x.id));

      const foreignIds = !isEmpty(favoriteIds)
        ? await huaweiApi
            .getContentDetails({
              vod: favoriteIds.join(','),
              idType: 0,
              filterType: 1,
              metaDataVer: 'Channel/1.1',
              properties: [
                {
                  name: 'vod',
                  include: favoritesApi.favouriteVodInclude,
                },
              ],
            })
            .then((res) =>
              res.vodlist
                ? res.vodlist.map((vod) => vod.foreignsn || '').filter((foreignsn) => Boolean(foreignsn))
                : [],
            )
        : [];

      const seriesIds =
        foreignIds.length === 0
          ? []
          : await huaweiApi
              .queryProgramSeries({
                seriesID: foreignIds,
                count: Math.min(foreignIds.length, 100),
                offset: 0,
              })
              .then((res) => (res.programSerieses ? res.programSerieses.map((series) => series.seriesID) : []));

      const favoritePrograms = favoriteIds.concat(foreignIds.concat(seriesIds)).reduce((favorites, id) => {
        favorites[id] = true;
        return favorites;
      }, {} as FavoritePrograms);

      dispatch(setFavoritePrograms(favoritePrograms));
    } catch (e) {
      // do nothing
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchAllPlaybills(): any {
  return function (dispatch: Function, getState: GetStateType) {
    const channelIds = getState().channelsReducer.channels.map((x) => x.contentId);
    return waitForPrograms({ channelIds }).then((programs: Program[]) => {
      dispatch(addPlaybillPrograms(programs));
    });
  };
}

export interface SetChannelFocusedAction {
  type: TypeKeys.SET_CHANNEL_FOCUSED;
  channelId: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setChannelFocused(channelId: string): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    // Focus the channel immediately
    dispatch({ type: TypeKeys.SET_CHANNEL_FOCUSED, channelId });

    // Load extra playbill programs asynchronously
    const playbill = (getState().channelsReducer.playbills[channelId] || []) as Program[];
    const currentProgram = playbill.find(broadcastingNow);

    if (playbill.length < 10 && currentProgram) {
      api.playbillContext(currentProgram, 12, 12).then((programs) => {
        dispatch(addPlaybillPrograms(programs as Program[]));
      });
    }
  };
}

export interface ReplaceTvGuidePlaybillPrograms {
  type: TypeKeys.REPLACE_TVGUIDE_PLAYBILL_PROGRAMS;
  programs: Program[];
}
export function replaceTvGuidePlaybillPrograms(programs: Program[]): ReplaceTvGuidePlaybillPrograms {
  return {
    type: TypeKeys.REPLACE_TVGUIDE_PLAYBILL_PROGRAMS,
    programs,
  };
}

export interface AppendTvGuidePlaybillPrograms {
  type: TypeKeys.APPEND_TVGUIDE_PLAYBILL_PROGRAMS;
  programs: Program[];
}

export function appendTvGuidePlaybillPrograms(programs: Program[]): AppendTvGuidePlaybillPrograms {
  return {
    type: TypeKeys.APPEND_TVGUIDE_PLAYBILL_PROGRAMS,
    programs,
  };
}

export interface ReplaceChannelsTvGuide {
  type: TypeKeys.REPLACE_TVGUIDE_SINGLE_CHANNEL_PLAYBILL;
  programs: Program[];
}
export function replaceSingleChannelsPrograms(programs: Program[]): ReplaceChannelsTvGuide {
  return {
    type: TypeKeys.REPLACE_TVGUIDE_SINGLE_CHANNEL_PLAYBILL,
    programs,
  };
}

export interface AddPlaybillPrograms {
  type: TypeKeys.ADD_PLAYBILL_PROGRAMS;
  programs: Program[];
}
export function addPlaybillPrograms(programs: Program[]): AddPlaybillPrograms {
  return {
    type: TypeKeys.ADD_PLAYBILL_PROGRAMS,
    programs,
  };
}

export interface SetChannelPlayingAction {
  type: TypeKeys.SET_CHANNEL_PLAYING;
  channelId: string;
  userInteraction: boolean; // if the user has interacted with app or not
  liveFromStart: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function playChannel(channelId: string, liveFromStart: boolean): any {
  localStorage.setItem('previous_channel', channelId);
  return function (dispatch: DispatchType, getState: GetStateType) {
    dispatch(hideChannelList());
    dispatch(hideDetailsPanel());

    return dispatch({
      type: TypeKeys.SET_CHANNEL_PLAYING,
      channelId,
      liveFromStart: liveFromStart,
    });
  };
}

function findProgram(channelId: string, programId: string, state: RootState): Promise<string | Program | undefined> {
  const playbill = (state.channelsReducer.playbills[channelId] || []) as Program[];
  const storedProgram = playbill.find((x) => x.id === programId);

  if (storedProgram) {
    return Promise.resolve(storedProgram);
  } else {
    return api.programDetail(programId);
  }
}

export interface SetProgramPlayingAction {
  type: TypeKeys.SET_CATCHUP_PLAYING;
  program: Program | undefined;
  userIsHome: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function playCatchup(channelId: string, programId: string, isLocked?: boolean): any {
  localStorage.setItem('previous_channel', channelId);
  return function (dispatch: DispatchType, getState: GetStateType) {
    dispatch(setCatchupDataLoading(true));

    return findProgram(channelId, programId, getState()).then((program) => {
      dispatch(hideChannelList());
      dispatch(hideDetailsPanel());
      dispatch(setCatchupDataLoading(false));

      const currentChannel = getState().channelsReducer.currentChannel;

      if (!isLocked && program && currentChannel) {
        program = program as Program;
        const tracking = AnalyticsTracking.getInstance();
        tracking.trackEvent(
          tracking.getCurrentCategory(),
          GAaction.play,
          currentChannel.name + ' - ' + program.name + ' - ' + program.id,
          true,
        );
      }

      return dispatch({
        type: TypeKeys.SET_CATCHUP_PLAYING,
        program,
      });
    });
  };
}

export interface SetCatchupDataLoadingAction {
  type: TypeKeys.SET_CATCHUP_DATA_LOADING;
  catchupDataLoading: boolean;
}
export function setCatchupDataLoading(loading: boolean): SetCatchupDataLoadingAction {
  return {
    type: TypeKeys.SET_CATCHUP_DATA_LOADING,
    catchupDataLoading: loading,
  };
}

export interface ShowChannelListAction {
  type: TypeKeys.SHOW_CHANNEL_LIST;
}
export function showChannelList(): ShowChannelListAction {
  return { type: TypeKeys.SHOW_CHANNEL_LIST };
}

export interface HideChannelListAction {
  type: TypeKeys.HIDE_CHANNEL_LIST;
}
export function hideChannelList(): HideChannelListAction {
  return { type: TypeKeys.HIDE_CHANNEL_LIST };
}

export interface RecordingActionInProgress {
  type: TypeKeys.RECORDING_ACTION_IN_PROGRESS;
}
export function recordingActionInProgress(): RecordingActionInProgress {
  return { type: TypeKeys.RECORDING_ACTION_IN_PROGRESS };
}
export interface RecordingActionDone {
  type: TypeKeys.RECORDING_ACTION_DONE;
}
export function recordingActionDone(): RecordingActionDone {
  return { type: TypeKeys.RECORDING_ACTION_DONE };
}

export interface SetSeriesRecordings {
  type: TypeKeys.SET_SERIES_RECORDINGS;
  recordings: PvrRecording[];
}
export function setRecordingSeries(recordings: PvrRecording[]): SetSeriesRecordings {
  return { type: TypeKeys.SET_SERIES_RECORDINGS, recordings };
}

export interface SetSingleRecordings {
  type: TypeKeys.SET_SINGLE_RECORDINGS;
  recordings: PvrRecording[];
}
export function setRecordingSingle(recordings: PvrRecording[]): SetSingleRecordings {
  return { type: TypeKeys.SET_SINGLE_RECORDINGS, recordings };
}

const displayNoRecordSupportModal = (dispatch: DispatchType, error: Error) => {
  console.error(error);
  dispatch(showAlert(getNoRecordSupportMessage()));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRecordings(): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    api.allRecordings([PvrStatus.NotRecorded, PvrStatus.BeingRecorded, PvrStatus.ToLoadAndShow]).then((recordings) => {
      if (recordings) {
        recordings = recordings as ChannelsRecordingsList;
        const bookmarks = getState().app.bookmarks;
        if (bookmarks) {
          const bookmarkList = { bookmarkList: bookmarks } as Bookmarks;
          recordings = markPvrWithIsWatched(bookmarkList, recordings);
        }
        dispatch(setRecordingSingle(recordings.singleRecordings));
        dispatch(setRecordingSeries(recordings.seriesRecordings));
      }
    });
  };
}

// programId, channel.mediaId
// TODO: we know we have a bug here, where error code: 85983281 is returned
// this is "huaweiDescription": "The recording task exists. This error code is displayed only when checkExist is set to 1."
// fix by: if exists, edit(do not add the same again)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addSingleRecording(program: ProgramToRecord, channelId: string, deleteMode: RecordingDeleteMode): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    dispatch(loadPVRSpace()).then((space: PvrSpace) => {
      if (getStoragePercentUsed(space) < PvrSpaceStatus.FULL) {
        dispatch(recordingActionInProgress());
        const channel = getState().channelsReducer.channels.find((x) => x.contentId === channelId)!;
        recordingApi
          .addSingleRecording(program, channel, deleteMode)
          .then(() => dispatch(getRecordings()))
          .then(() => dispatch(recordingActionDone()))
          .catch((error) => displayNoRecordSupportModal(dispatch, error));
      } else {
        const pvrSpaceWarningMessage: AlertData | undefined = getPVRSpaceWarningMessage(space, true);
        if (pvrSpaceWarningMessage) {
          dispatch(showAlert(pvrSpaceWarningMessage));
        }
      }
    });
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeSingleRecording(recording: PvrRecording): any {
  return function (dispatch: DispatchType) {
    dispatch(recordingActionInProgress());
    recordingApi
      .removeSingleRecording(recording)
      .then(() => dispatch(getRecordings()))
      .then(() => dispatch(recordingActionDone()));
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateSingleRecording(recording: PvrRecording, deleteMode: RecordingDeleteMode): any {
  return function (dispatch: DispatchType) {
    dispatch(recordingActionInProgress());
    recordingApi
      .updateSingleRecording(recording, deleteMode)
      .then(() => dispatch(getRecordings()))
      .then(() => dispatch(recordingActionDone()));
  };
}
export function addSeriesRecording(
  seriesId: string,
  channelId: string,
  effectivetime: string,
  deleteMode: RecordingDeleteMode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    dispatch(loadPVRSpace()).then((space: PvrSpace) => {
      if (getStoragePercentUsed(space) < PvrSpaceStatus.FULL) {
        dispatch(recordingActionInProgress());
        const channel = getState().channelsReducer.channels.find((x) => x.contentId === channelId)!;
        recordingApi
          .addSeriesRecording(seriesId, channel, effectivetime, deleteMode)
          .then(() => dispatch(getRecordings()))
          .then(() => dispatch(recordingActionDone()))
          .catch((error) => displayNoRecordSupportModal(dispatch, error));
      } else {
        const pvrSpaceWarningMessage: AlertData | undefined = getPVRSpaceWarningMessage(space, true);
        if (pvrSpaceWarningMessage) {
          dispatch(showAlert(pvrSpaceWarningMessage));
        }
      }
    });
  };
}

export const addChronologicalSeriesRecording =
  (
    seriesId: string,
    channelId: string,
    effectiveTime: string,
    deleteMode: RecordingDeleteMode,
    selectedSubNum: number,
    selectedSeasonNum: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any =>
  (dispatch: DispatchType, getState: GetStateType) => {
    const channel = getState().channelsReducer.channels.find((x) => x.contentId === channelId)!;
    dispatch(loadPVRSpace()).then(async (space: PvrSpace) => {
      dispatch(recordingActionInProgress());
      if (getStoragePercentUsed(space) < PvrSpaceStatus.FULL) {
        recordingApi
          .addChronologicalSeriesRecording(
            seriesId,
            channel,
            effectiveTime,
            deleteMode,
            selectedSubNum,
            selectedSeasonNum,
          )
          .then(() => dispatch(getRecordings()))
          .then(() => dispatch(recordingActionDone()))
          .catch((error) => displayNoRecordSupportModal(dispatch, error));
      }
      const pvrSpaceWarningMessage: AlertData | undefined = getPVRSpaceWarningMessage(space, true);
      if (pvrSpaceWarningMessage) {
        dispatch(showAlert(pvrSpaceWarningMessage));
      }
    });
  };

export function updateSeriesRecording(
  recording: PvrRecording,
  canceled: boolean,
  deleteMode: RecordingDeleteMode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return function (dispatch: DispatchType) {
    dispatch(recordingActionInProgress());
    recordingApi
      .updateSeriesRecording(recording, canceled, deleteMode)
      .then(() => dispatch(getRecordings()))
      .then(() => dispatch(recordingActionDone()))
      .then(() => queryClient.invalidateQueries(recordingKeys.all));
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cancelSeriesRecording(recording: PvrRecording): any {
  return function (dispatch: DispatchType) {
    dispatch(recordingActionInProgress());
    recordingApi
      .cancelRecording(recording)
      .then(() => dispatch(getRecordings()))
      .then(() => dispatch(recordingActionDone()))
      .then(() => queryClient.invalidateQueries(recordingKeys.all));
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getChannels(): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    // channelNamespace = 0 = ott channels
    const _channels = getState().channelsReducer.channels;
    if (isEmpty(_channels)) {
      return api
        .allChannels('0')
        .then((channels) => dispatch(setChannels(lockFreeToAirChannels(channels as Channel[], getState))));
    }
    return Promise.resolve(_channels);
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPopularChannels(): any {
  return function (dispatch: DispatchType) {
    return popularContentApi
      .fetchMostWatchedChannels(PopularContentChannelTimespan.TWO_HOURS)
      .then((popularChannels) => dispatch(setPopularChannels(popularChannels)));
  };
}

function lockFreeToAirChannels(channels: Channel[], getState: GetStateType) {
  if (getState().authReducer.isGuest && !getState().authReducer.isInternetOnly) {
    return channels.map((channel: Channel) => {
      channel.channelPermissions.notInSubscription = true;
      return channel;
    });
  } else {
    return channels;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getChannelsIncludingIPTV(): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    // channelNamespace = 4000 = ott channels and iptv channels
    return api.allChannels('4000').then((IPTVChannels) => {
      return api.allChannels('0').then((OTTChannels) => {
        IPTVChannels = IPTVChannels as Channel[];
        OTTChannels = OTTChannels as Channel[];
        let newMergedList = IPTVChannels.slice(0);
        OTTChannels.forEach((ottChannel: Channel) => {
          const foundIPTVChannelIndex = newMergedList.findIndex((x) => x.contentId === ottChannel.contentId);
          if (foundIPTVChannelIndex !== -1) {
            newMergedList[foundIPTVChannelIndex] = ottChannel;
          } else {
            newMergedList.push(ottChannel);
          }
        });
        dispatch(setChannels(lockFreeToAirChannels(newMergedList, getState)));
      });
    });
  };
}

export interface ShowProgramDetails extends ProgramDetailsType {
  type: TypeKeys.SHOW_PROGRAM_DETAILS;
}
export function showProgramDetails(
  program: ProgramDetailsType['program'],
  displayType: ProgramDetailsType['displayType'],
  svodKiosk?: ProgramDetailsType['svodKiosk'],
): ShowProgramDetails {
  return { type: TypeKeys.SHOW_PROGRAM_DETAILS, program, displayType, svodKiosk };
}

export interface HideProgramDetails {
  type: TypeKeys.HIDE_PROGRAM_DETAILS;
}
export function hideProgramDetails(): HideProgramDetails {
  return { type: TypeKeys.HIDE_PROGRAM_DETAILS };
}

export interface SetPlaybillShouldHide {
  type: TypeKeys.SET_PLAYBILL_SHOULD_HIDE;
  value: boolean;
}
export function setPlaybillShouldHide(value: boolean): SetPlaybillShouldHide {
  return { type: TypeKeys.SET_PLAYBILL_SHOULD_HIDE, value };
}

export interface SetGenres {
  type: TypeKeys.SET_GENRES;
  genres: Genre[];
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getGenres(genreType?: GenreType, genreIds?: string[]): any {
  return function (dispatch: DispatchType) {
    return api.getGenres(genreType, genreIds).then((genres) =>
      dispatch({
        type: TypeKeys.SET_GENRES,
        genres,
      }),
    );
  };
}
