import OptionsModal from '../../components/OptionsModal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteRecording, markRecordingForDeletion, MarkRecordingForDeletion, loadPVRSpace } from './actions';
import { RootState, PvrRecording, GAaction, OptionsModalContent, OptionType, PvrReducerState } from '../../interfaces';
import { getPvrDeletionSingleRecordingContextText, getSeasonAndEpisodeLabel } from '../../utils/pvrUtils';
import i18n from '../../i18n';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';

type Props = {
  deleteRecording: (_: PvrRecording) => void;
  hideModal: () => MarkRecordingForDeletion;
  loadPVRSpace: () => Promise<void>;
  modalHeroBackground?: string;
};

type State = OptionsModalContent;

class DeleteSingleRecordingModal extends Component<Props, State> {
  tracking = AnalyticsTracking.getInstance();

  state: State = {
    modalIsOpen: false,
    title: i18n.t<string>('confirm deletion'),
    closetext: i18n.t<string>('cancel'),
    subtitle: ' ',
    subtitle2: '',
    posttitle: '',
    options: [],
    onAction: this.onAction(),
    onClose: () => {
      this.props.hideModal();
    },
    error: false,
  };

  onAction() {
    return (selectedAction: OptionType) => {
      if (selectedAction && selectedAction.series) {
        this.props.deleteRecording(selectedAction.series);
        this.props.loadPVRSpace();
        this.tracking.trackEvent(
          this.tracking.getCurrentCategory(),
          GAaction.recordDeleteProgram,
          selectedAction.series.channelName +
            ' - ' +
            selectedAction.series.pvrName +
            ' - ' +
            getSeasonAndEpisodeLabel(selectedAction.series),
        );
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props & PvrReducerState) {
    const recordingToDelete = nextProps.recordingToDelete;
    if (recordingToDelete) {
      const deletionContextText = getPvrDeletionSingleRecordingContextText(recordingToDelete);
      const prefix = getSeasonAndEpisodeLabel(recordingToDelete);
      const episodeLabel = `<b>${recordingToDelete.pvrName}${prefix && prefix !== ' ' ? ` - ${prefix}` : ``}</b>`;

      this.setState({
        modalHeroBackground: this.props.modalHeroBackground,
        subtitle: `${deletionContextText} ${episodeLabel}?`,
        modalIsOpen: recordingToDelete ? true : false,
        options: [
          {
            title: i18n.t<string>('confirm'),
            series: recordingToDelete,
            style: '',
            type: 'secondary',
          },
        ] as OptionType[],
      });
    } else {
      this.setState({
        modalIsOpen: false,
      });
    }
  }

  render() {
    return (
      <OptionsModal
        {...this.state}
        modalHeroBackground={this.props.modalHeroBackground}
        customClass={'options-modal'}
      />
    );
  }
}

export default connect(
  (state: RootState) => {
    return {
      recordingToDelete: state.pvrReducer.recordingToDelete,
    };
  },
  (dispatch) => ({
    deleteRecording: (recording: PvrRecording) => dispatch(deleteRecording(recording)),
    hideModal: () => dispatch(markRecordingForDeletion(undefined)),
    loadPVRSpace: () => dispatch(loadPVRSpace()),
  }),
)(DeleteSingleRecordingModal);
