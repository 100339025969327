import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { RootState, AltiboxAssetDetailsType, PopularContentChannelTimespan } from '../../interfaces';
import { routes, searchConfig } from '../../config';
import FrontPage from './FrontPage';
import ProgramPlayer from './ProgramPlayer';
import GenreList from './GenreList';
import Search from '../globalsearch';
import './style.scss';
import DetailsView from '../details';

interface Props extends RouteComponentProps<{}> {
  isGuest: boolean;
}

class ProgramArchive extends React.Component<Props, {}> {
  render() {
    const { base, play, details, series, single, channel, genre, mostWatched } = routes.programarchive;
    return (
      <Switch>
        <Route
          exact={true}
          path={base + routes.search.base + '*'}
          render={() => <Search searchConfig={searchConfig.programarchive} />}
        />
        <Route exact={true} path={base} render={() => <FrontPage searchConfig={searchConfig.programarchive} />} />

        <Route
          path={base + play + '/:programId'}
          render={() => <ProgramPlayer searchConfig={searchConfig.programarchive} />}
        />

        <Route
          exact={true}
          path={base + details + series + '/:parentId/:childId'}
          render={() => <DetailsView displayType={AltiboxAssetDetailsType.CATCHUP} />}
        />

        <Route
          exact={true}
          path={base + details + single + '/:childId'}
          render={() => <DetailsView displayType={AltiboxAssetDetailsType.CATCHUP} />}
        />

        <Route
          exact={true}
          path={base + play + '/:programId'}
          render={() => <ProgramPlayer searchConfig={searchConfig.programarchive} />}
        />

        <Route
          exact={true}
          path={base + channel + '/:channelId'}
          render={(props) => <GenreList searchConfig={searchConfig.programarchive} channelMode={true} {...props} />}
        />
        <Route
          exact={true}
          path={base + genre + '/:genreIds/:orderType'}
          render={() => <GenreList searchConfig={searchConfig.programarchive} />}
        />
        <Route
          exact={true}
          path={base + mostWatched + `/:${PopularContentChannelTimespan.TWENTYFOUR_HOURS}`}
          render={() => <GenreList searchConfig={searchConfig.programarchive}></GenreList>}
        />
        <Route path={base} render={() => <Redirect to={base} />} />
      </Switch>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({}),
    (dispatch) => ({}),
  )(ProgramArchive),
);
