import i18n from '../i18n';
import languages from '@cospired/i18n-iso-languages';
import localeConfigDa from '@cospired/i18n-iso-languages/langs/da.json';
import localeConfigEn from '@cospired/i18n-iso-languages/langs/en.json';
import localeConfigNo from '@cospired/i18n-iso-languages/langs/no.json';
import iso6392 from 'iso-639-2';
import iso6393 from 'iso-639-3';

let localeConfig: languages.LocaleData | undefined = undefined;

function setLocaleConfig(currentLocale: string) {
  switch (currentLocale) {
    case 'da':
      localeConfig = localeConfigDa;
      break;
    case 'en':
      localeConfig = localeConfigEn;
      break;
    case 'nb':
    default:
      localeConfig = localeConfigNo;
  }
}

// sanitize inputs such as en-GB, en-US etc.
function sanitizei18nlanguage(language: string) {
  const lang = language ? language.split('-') : ['nb'];
  return lang[0];
}

function setLocale(language: string = i18n.language.toString()) {
  setLocaleConfig(sanitizei18nlanguage(language));
  if (localeConfig) {
    languages.registerLocale(localeConfig!);
  }
}

setLocale();

export function sanitize(shortname: string) {
  switch (shortname) {
    case 'eng':
      return 'en';
    case 'nor':
    case 'nb':
      return 'no';
    case 'dan':
    case 'dk':
      return 'da';
    case 'swe':
      return 'se';
    case 'fin':
      return 'fi';
    default:
      return shortname;
  }
}

function getLanguage(shortname: string) {
  shortname = sanitize(shortname);
  let returnString = languages.getName(shortname.toString(), localeConfig!.locale);
  switch (shortname) {
    case 'nn':
      let nynorsk = returnString?.split(' ') || [];
      return nynorsk[1] ? nynorsk[1] : returnString;
    case '':
      return 'unknown';
    case 'nosub':
      return i18n.t<string>('nosub');
    default:
      return returnString;
  }
}

/* eslint-disable no-mixed-operators*/
function findShortnameInISO6392(shortname: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return iso6392.find((x: any) => {
    return (
      (x.iso6392B && x.iso6392B === shortname) ||
      (x.iso6391 && x.iso6391 === shortname) ||
      (x.iso6392T && x.iso6392T === shortname)
    );
  });
}
/* eslint-enable no-mixed-operators*/

/* eslint-disable no-mixed-operators*/
function findShortnameInISO6393(shortname: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return iso6393.find((x: any) => {
    return (
      (x.iso6393 && x.iso6393 === shortname) ||
      (x.iso6392B && x.iso6392B === shortname) ||
      (x.iso6392T && x.iso6392T === shortname)
    );
  });
}
/* eslint-enable no-mixed-operators*/

function getBackupLanguage(shortname: string) {
  const locale = 'en';
  // flip to english
  setLocale(locale);
  const allNames = languages.getNames(locale);
  // flip back to whatever was before
  setLocale();

  let foundInSomeISO = findShortnameInISO6392(shortname) || findShortnameInISO6393(shortname);
  let output: string | undefined = shortname;

  if (foundInSomeISO) {
    const name = foundInSomeISO.name.split(' ')[0];
    const getISO6391 = Object.values(allNames).findIndex((x) => x.toLowerCase().indexOf(name.toLowerCase()) !== -1);
    if (getISO6391 !== -1) {
      const getShortname = Object.keys(allNames)[getISO6391];
      if (getShortname) {
        output = getLanguage(getShortname);
      }
    }
  }
  return output;
}

export function getTranslation(shortname: string) {
  let lang = getLanguage(shortname);
  if (!lang) {
    lang = getBackupLanguage(shortname);
  }
  return lang ? lang.toLowerCase() : shortname;
}

// based on Stephan Wetaas script... Apparently forgerock does have support for sending default browser language...
export function getForgerockLanguage(navigatorLang: string) {
  switch (navigatorLang) {
    case 'no':
    case 'nb':
    case 'nn':
    case 'no-NO':
    case 'nb-NO':
    case 'nn-NO':
      return 'no';
    case 'en':
    case 'en-US':
    case 'en-GB':
      return 'en';
    case 'da':
      return 'da';
    default:
      return 'no';
  }
}
