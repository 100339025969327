import { isEmpty } from 'lodash';
import {
  ContentType,
  NielsenReinventsBooleans,
  NielsenChannelMapping,
  PortalMenu,
  Program,
  PvrRecording,
  UnionAssetTypes,
  VodAsset,
} from '../interfaces';
import { isPvr } from './altiboxassetUtils';
import { toMoment } from './huaweiUtils';

export function getNielsenContentId(asset: UnionAssetTypes | undefined) {
  if (!asset) {
    return '';
  }
  if (isPvr(asset)) {
    return (asset as PvrRecording).pvrId;
  }
  return asset.id;
}

export function getNielsenContentProgram(asset: UnionAssetTypes | undefined) {
  if (!asset) {
    return '';
  }
  if (isPvr(asset)) {
    return (asset as PvrRecording).periodPVRTaskName || (asset as PvrRecording).pvrName;
  }
  if (asset.type === ContentType.VIDEO_VOD || asset.type === ContentType.VOD) {
    if ('fathervodlist' in asset) {
      return (asset as VodAsset).fathervodlist[0].name;
    }
    return asset.name;
  }
  if (asset.type === ContentType.PROGRAM) {
    return asset.name;
  }
}

export function getNielsenContentTitle(asset: UnionAssetTypes | undefined) {
  if (!asset) {
    return '';
  }
  if (isPvr(asset)) {
    if ('subName' in (asset as PvrRecording)) {
      return (asset as PvrRecording).subName;
    }
    return (asset as PvrRecording).periodPVRTaskName || (asset as PvrRecording).pvrName;
  }
  if (asset.type === ContentType.VIDEO_VOD || asset.type === ContentType.VOD) {
    return asset.name;
  }
  if (asset.type === ContentType.PROGRAM) {
    if ('subName' in asset) {
      return (asset as Program).subName;
    }
    return asset.name;
  }
}

export function getNielsenContentLength(asset: UnionAssetTypes | undefined) {
  asset = asset as Program;
  return toMoment(asset.endtime).diff(toMoment(asset.starttime), 'seconds').toString();
}

export function getNielsenContentAirdate(asset: UnionAssetTypes | undefined) {
  if (!asset) {
    return '';
  }
  if (isPvr(asset)) {
    asset = asset as PvrRecording;
    return toMoment(asset.beginTime).format('YYYYMMDD HH:mm:ss');
  }
  if (asset.type === ContentType.VIDEO_VOD || asset.type === ContentType.VOD) {
    return '19700101 00:00:00';
  }
  if (asset.type === ContentType.PROGRAM) {
    asset = asset as Program;
    return toMoment(asset.starttime).format('YYYYMMDD HH:mm:ss');
  }
}

export function getNielsenContentIsFullEpisode(
  asset: UnionAssetTypes | undefined,
  isMultipartChapter: boolean = false,
) {
  if (!asset) {
    return NielsenReinventsBooleans.FALSE;
  }
  // We do not yet have support for multipart chapters
  // TODO: Implement multipart chapters, ref. ATVOTT-5877
  if (isMultipartChapter) {
    return NielsenReinventsBooleans.FALSE;
  }
  return NielsenReinventsBooleans.TRUE;
}

export function getNielsenContentAdLoadType(asset: UnionAssetTypes | undefined) {
  return '1'; // no ad insertion
}

export function getNielsenChannelSubbrand(
  asset: UnionAssetTypes | undefined,
  nielsenChannelMapping: NielsenChannelMapping[],
  svodKiosk: PortalMenu,
) {
  return getNielsenChannelMappingValues(asset, nielsenChannelMapping, svodKiosk, 'subBrand');
}

export function getNielsenChannelStationId(
  asset: UnionAssetTypes | undefined,
  nielsenChannelMapping: NielsenChannelMapping[],
  svodKiosk: PortalMenu,
) {
  return getNielsenChannelMappingValues(asset, nielsenChannelMapping, svodKiosk, 'stationId');
}

function getNielsenChannelMappingValues(
  asset: UnionAssetTypes | undefined,
  nielsenChannelMapping: NielsenChannelMapping[],
  svodKiosk: PortalMenu,
  property: string,
) {
  if (!asset || isEmpty(nielsenChannelMapping)) {
    return undefined;
  }
  if (asset.type === ContentType.PROGRAM) {
    const match = nielsenChannelMapping.find((x) => x.id === (asset as Program).channelid);
    if (match) {
      return match[property];
    }
    return property === 'subBrand' ? 'c33' : undefined; // Altibox Other
  }
  if (asset.type === ContentType.VIDEO_VOD || asset.type === ContentType.VOD) {
    if (svodKiosk) {
      const { associatedChannelIds } = svodKiosk;
      if (!isEmpty(associatedChannelIds)) {
        const intersect = nielsenChannelMapping.filter((x) =>
          associatedChannelIds!.some((y) => x.id.toString() === y.toString()),
        );
        if (!isEmpty(intersect)) {
          return intersect[0][property];
        }
      }
    }
  }
  if ((asset as PvrRecording).pvrId) {
    const match = nielsenChannelMapping.find((x) => x.id === (asset as PvrRecording).channelId);
    if (match) {
      return match[property];
    }
    return property === 'subBrand' ? 'c33' : undefined; // Altibox Other;
  }
  return undefined;
}
