/* eslint-disable */
import { json, status } from '../../../controllers/Fetch';
import { ScriptService } from '../../../controllers/ScriptService';
import i18n from '../../../i18n';
import HuaweiErrors from '../../../controllers/HuaweiErrors';
import { getSessionTicket } from '../../../config';

export function setupFairplayDrm(video, streamData, baseUrl, messagePrompter) {
  if (streamData.licenseUrl === '') {
    console.error('MISSING LICENSE URL!');
  }

  let customData = streamData.customData;
  let licenseUrl = streamData.licenseUrl;
  let manifestUrl = streamData.manifestUrl;
  const FairPlayHelper = HLSFairPlayHelper.init(messagePrompter);
  FairPlayHelper.setVideoElement(video);
  FairPlayHelper.setServerCertificatePath(baseUrl);
  FairPlayHelper.loadCertificate().then(() => {
    FairPlayHelper.setServerLicensePath(licenseUrl);
    FairPlayHelper.setCustomData(customData);
    FairPlayHelper.setVideoSrc(manifestUrl);
    FairPlayHelper.startVideo();
  });
}

var HLSFairPlayHelper = {
  init: function (messagePrompter) {
    this.keySystem = null;
    this.videoScr = null;
    this.certificate = null;
    this.vodProd = null;
    this.serverCertificatePath = null;
    this.serverLicensePath = null;
    this.videoElement = null;

    this.setVideoSrc = (src) => {
      this.videoScr = src;
    };

    this.setServerCertificatePath = (path) => {
      this.serverCertificatePath = path;
    };

    this.setServerLicensePath = (path) => {
      this.serverLicensePath = path;
    };

    this.setVideoElement = (videoElement) => {
      this.videoElement = videoElement;
    };

    this.setCustomData = (data) => {
      this.customData = data;
    };

    this.loadCertificate = () => {
      return fetch(this.serverCertificatePath + '/VSP/V3/GetDRMServerCert', {
        method: 'POST',
        headers: { SessionTicket: getSessionTicket() },
        body: JSON.stringify({
          drmType: 'FAIRPLAY',
        }),
      })
        .then(status)
        .then(json)
        .then((response) => {
          this.certificate = this.base64DecodeUint8Array(response.serverCert);
          return Promise.resolve();
        })
        .catch((error) => {
          return Promise.reject(new Error(error));
        });
    };

    this.startVideo = function () {
      var video = this.videoElement;
      video.addEventListener('webkitneedkey', this.onKeyNeeded, false);
      video.addEventListener('error', this.onError, false);
      return Promise.resolve(video.src);
    };

    this.onKeyNeeded = (event) => {
      var video = event.target;
      var initData = event.initData;
      const contentId = this.extractContentId(initData);
      initData = this.concatInitDataIdAndCertificate(initData, contentId, this.certificate);

      if (!video.webkitKeys) {
        video.webkitSetMediaKeys(new window.WebKitMediaKeys(this.getKeySystem()));
      }

      if (!video.webkitKeys) {
        throw new Error('Could not create MediaKeys');
      }

      var keySession = video.webkitKeys.createSession('video/mp4', initData);
      if (!keySession) {
        throw new Error('Could not create key session');
      }

      keySession.contentId = contentId;
      this.waitForEvent('webkitkeymessage', this.licenseRequestReady, keySession);
      this.waitForEvent('webkitkeyerror', this.onKeyError, keySession);
    };

    this.licenseRequestReady = (event) => {
      var session = event.target;
      var message = event.message;

      return fetch(this.serverLicensePath, {
        method: 'POST',
        headers: {
          SessionTicket: getSessionTicket(),
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          SPC: this.base64EncodeUint8Array(message),
          customData: this.customData,
          osType: 'macOS',
          osVersion: ScriptService.getMacOsVersion(),
        }),
      })
        .then(status)
        .then(json)
        .then((response) => {
          var key;
          if (response.CKC) {
            // Huawei ckc. Fairplay docs specifies that this should be lowercase
            key = this.base64DecodeUint8Array(response.CKC);
            session.update(key);
          } else if (response.ckc) {
            // This is correct Fairplay with correct use of ckc
            key = this.base64DecodeUint8Array(response.ckc);
            session.update(key);
          } else {
            if (
              response.result &&
              response.result.retMsg &&
              response.result.retMsg.indexOf(HuaweiErrors.COULD_NOT_AIR_PLAY) >= 0
            ) {
              messagePrompter(i18n.t < string > 'an_error_occured', [i18n.t < string > 'error with airplay']);
            } else {
              const error = response.result && response.result.retCode ? response.result.retCode : 'Unknown';
              messagePrompter(i18n.t < string > 'an_error_occured', [
                i18n.t < string > 'error sorry message',
                'Error Code: ' + error,
              ]);
            }
            if (response.result) {
              console.error('License Error');
              console.error(response.result.retCode);
              console.error(response.result.retMsg);
            } else {
              console.error('Licence Error');
              console.error(response);
            }
          }
          return Promise.resolve();
        })
        .catch((error) => {
          return Promise.reject(new Error(error));
        });
    };

    this.concatInitDataIdAndCertificate = (initData, id, cert) => {
      if (typeof id === 'string') {
        id = this.stringToArray(id);
      }
      // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
      var offset = 0;
      var buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
      var dataView = new DataView(buffer);

      var initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
      initDataArray.set(initData);
      offset += initData.byteLength;

      dataView.setUint32(offset, id.byteLength, true);
      offset += 4;

      var idArray = new Uint16Array(buffer, offset, id.length);
      idArray.set(id);
      offset += idArray.byteLength;

      dataView.setUint32(offset, cert.byteLength, true);
      offset += 4;

      var certArray = new Uint8Array(buffer, offset, cert.byteLength);
      certArray.set(cert);

      return new Uint8Array(buffer, 0, buffer.byteLength);
    };

    this.getKeySystem = () => {
      if (window.WebKitMediaKeys && window.WebKitMediaKeys.isTypeSupported('com.apple.fps.1_0', 'video/mp4')) {
        return 'com.apple.fps.1_0';
      } else {
        throw new Error('Key System not supported');
      }
    };

    this.extractContentId = (initData) => {
      let contentId = this.fromCharCode(initData);

      let parts = contentId.split('//');
      if (parts.length !== 2) {
        parts = this.arrayToString16(initData).split('//');
        if (parts.length !== 2) {
          parts = this.arrayToString32(initData).split('//');
        }
      }

      if (parts.length !== 2) {
        console.error('No valid init DATA');
      }

      // Removing unnecessary parts from string.
      if (parts && parts[1] && parts[1].includes('?')) {
        const partBeforeQuestionMark = parts[1].split('?')[0];
        if (partBeforeQuestionMark) {
          return partBeforeQuestionMark;
        }
      }
      return parts[1];
    };

    this.arrayToString32 = (array) => {
      const uint16array = new Uint32Array(array.buffer);
      return String.fromCharCode.apply(null, uint16array);
    };

    this.arrayToString16 = (array) => {
      const uint16array = new Uint16Array(array.buffer);
      return String.fromCharCode.apply(null, uint16array);
    };

    // tslint:disable-next-line
    let fromCharCodeImpl_ = null;
    this.fromCharCode = (array) => {
      // Check the browser for what chunk sizes it supports.  Cache the result
      // in an impl method to avoid checking several times.
      if (!fromCharCodeImpl_) {
        for (let size = 64 * 1024; size > 0; size /= 2) {
          // tslint:disable-next-line
          fromCharCodeImpl_ = (buffer) => {
            let ret = '';
            for (let i = 0; i < buffer.length; i += size) {
              const subArray = buffer.subarray(i, i + size);
              ret += String.fromCharCode(...subArray);
            }

            return ret;
          };
          break;
        }
      }
      return fromCharCodeImpl_(array);
    };

    this.waitForEvent = (name, action, target) => {
      target.addEventListener(
        name,
        function () {
          action(arguments[0]);
        },
        false,
      );
    };

    this.stringToArray = (string) => {
      var buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
      var array = new Uint16Array(buffer);
      for (var i = 0, strLen = string.length; i < strLen; i++) {
        array[i] = string.charCodeAt(i);
      }
      return array;
    };

    this.base64EncodeUint8Array = (input) => {
      var keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
      var output = '';
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;

      while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
        chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
          enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }
        output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
      }
      return output;
    };

    this.onError = (event) => {
      console.error('A video playback error occurred');
      console.error(event);
      console.error(event.target.error);
    };

    this.onKeyError = (event) => {
      console.error('A decryption key error was encountered', event);
    };

    this.base64DecodeUint8Array = (base64) => {
      let raw = window.atob(base64);
      let rawLength = raw.length;
      let array = new Uint8Array(new ArrayBuffer(rawLength));

      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }
      return array;
    };
    return this;
  },
};
