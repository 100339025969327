import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import channelPermissions from '../../../utils/channelPermissions';

import { routes } from '../../../config';
import i18n from '../../../i18n';
import { AuthReducerState, Channel, RootState } from '../../../interfaces';
import './styles.scss';
import { getChannelLogoUrl } from '../../../utils/tvUtils';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface Props {
  channel: Channel;
  favoriteChannels: Channel[];
  isProgramArchive?: boolean | undefined;
  isFavoriteView?: boolean;
  authReducer: AuthReducerState;
  ongoingProgramName?: string;
}

function ChannelsBannerListItem({
  channel,
  favoriteChannels,
  authReducer,
  isProgramArchive,
  isFavoriteView,
  ongoingProgramName,
}: Props) {
  const [favoriteChannelsList, setFavoriteChannelsList] = useState<string[]>([]);
  const isLocked = () => {
    const permission = channelPermissions(channel, authReducer);
    let locked = false;

    if (isProgramArchive) {
      if (channel.channelPermissions.onlyInsideForCatchup && !authReducer.isHome) {
        locked = true;
      }
    }
    return permission.locked || locked;
  };

  const overlayClass = (isLocked() ? 'channelMessage' : '') + (isProgramArchive ? ' programarchive' : '');

  const location = useLocation<{ prevPath: string | undefined }>();

  const listItemClass = () => {
    if (isLocked()) {
      return 'channel locked';
    } else if (isFavoriteView) {
      return 'channel favorite';
    }

    return 'channel';
  };

  const LockIcon = () => {
    return isLocked() ? (
      <span>
        <div className="lock aib-icon">p</div>
      </span>
    ) : null;
  };

  const FavoriteIcon = () => {
    const favoritedChannel = favoriteChannelsList.includes(channel.chanNo);
    return favoritedChannel ? (
      <span>
        <div className="favorite aib-icon">M</div>
      </span>
    ) : null;
  };

  const TopRightIcons = () => (
    <div className="top-right-icons">
      <LockIcon />
      <FavoriteIcon />
    </div>
  );

  const channelBannerLink = isProgramArchive
    ? routes.programarchive.base + routes.programarchive.channel + '/' + channel.contentId
    : routes.tv.base + '/' + channel.contentId;

  useEffect(() => {
    let favoritesList = [];
    if (!authReducer.isGuest) {
      favoritesList = favoriteChannels.map((ch) => {
        return ch.chanNo;
      });
      setFavoriteChannelsList(favoritesList);
    }
  }, [authReducer, favoriteChannels]);

  return (
    <li key={channel.chanNo + channel.name} className={listItemClass()}>
      <div className={styles.channelContainer} key={channel.chanNo}>
        <Link
          className={styles.channelLink}
          draggable="false"
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          to={{
            pathname: channelBannerLink,
            state: { prevPath: location.pathname },
          }}
        >
          {isLocked() && !authReducer.isGuest ? (
            <div className={overlayClass}>
              <span className={'no-sub'}>
                {channel.channelPermissions.notInSubscription
                  ? i18n.t<string>('permission not in subscription')
                  : i18n.t<string>("can't show outside home")}
              </span>
            </div>
          ) : null}
          <TopRightIcons />
          <div className={'image-container'}>
            <img
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              src={getChannelLogoUrl(channel)}
              alt={channel.name}
              title={channel.name}
            />
          </div>
          {ongoingProgramName && !isProgramArchive && (
            <div className="title" title={ongoingProgramName}>
              {ongoingProgramName}
            </div>
          )}
        </Link>
      </div>
    </li>
  );
}
export default connect((state: RootState) => ({
  authReducer: state.authReducer,
  favoriteChannels: state.channelsReducer.favoriteChannels,
}))(ChannelsBannerListItem);
