import React from 'react';
import './style.scss';
import { Channel, Program, AuthReducerState, DispatchResponse, AMR } from '../../interfaces';
import LiveCollageProgram from './LiveCollageProgram';
import { broadcastingNow } from '../../utils/huaweiUtils';
import Spinner from '../Spinner';
import i18n from '../../i18n';
import { isEmpty } from 'lodash';
import { getChannelsToShow, getPopularChannelsContentIds } from '../../utils/tvUtils';
import { numberOfLiveCollagePrograms } from '../../config';

interface Props {
  channels: Channel[];
  popularChannels: AMR.PopularChannel[];
  playbills: Map<string, Program[]>;
  authReducer: AuthReducerState;
  channelsToUse: string[];
  showLoginModal: () => DispatchResponse;
}

export default class TVProgramCollage extends React.Component<Props> {
  getChannelView = (channel: Channel, index: number) => {
    const programs = this.props.playbills[channel.contentId];
    if (programs && programs.length > 0) {
      return (
        <div className={'collage-box collage-box-' + index} key={'channel-collage-' + channel.contentId}>
          <LiveCollageProgram
            {...programs.find(broadcastingNow)}
            channel={channel}
            authReducer={this.props.authReducer}
            showLoginModal={this.props.showLoginModal}
            programOrder={index}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { channels, popularChannels, channelsToUse, playbills } = this.props;
    let collageTitle = null;
    let output = null;
    let collageClass = '';
    let channelsToShow: Channel[] = [];

    if (popularChannels.length >= numberOfLiveCollagePrograms) {
      let popularChannelsToUse = getPopularChannelsContentIds(popularChannels);
      channelsToShow = getChannelsToShow(popularChannelsToUse, channels);
    }
    // fallback to frontpage web config
    if (channels && channelsToShow.length < numberOfLiveCollagePrograms) {
      channelsToShow = getChannelsToShow(channelsToUse, channels);
    }

    if (playbills && (channels || popularChannels)) {
      if (!isEmpty(channelsToShow)) {
        output = channelsToShow.map(this.getChannelView);
        if (output.every((channel) => channel === null)) {
          output = <Spinner wrapInContainer={true} />;
          collageClass = ' empty-collage';
        }
      }
    } else {
      output = null;
    }

    if (!output) {
      return null;
    } else {
      collageTitle = (
        <div className="tv-program-collage-title">
          <h3 title={i18n.t<string>('live now')}>{i18n.t<string>('live now')}</h3>
        </div>
      );
      return (
        <div className="tv-program-collage-container">
          {collageTitle}
          <div className={'tv-program-collage' + collageClass}>{output}</div>
        </div>
      );
    }
  }
}
