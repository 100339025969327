import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useConfirm } from './useConfirm';

interface Options {
  title?: string;
  prompt: string;
}

export function useBlockNavigation(conditional: boolean, options: Options) {
  const history = useHistory();
  const { isConfirmed } = useConfirm();

  useEffect(() => {
    history.block((location) => {
      if (conditional) {
        return undefined;
      }

      isConfirmed(options).then((confirmed) => {
        if (confirmed) {
          history.block(() => undefined);
          history.push(location.pathname);
        }
      });

      return false;
    });
  }, [history, isConfirmed, conditional, options]);
}
