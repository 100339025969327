import React, { Component } from 'react';
import AltiboxAssetContext from '../../../../views/details/AltiboxAssetContext';
import i18n from '../../../../i18n';
import {
  VodSharedAsset,
  SvodSharedAsset,
  SharedAssets as SharedAssetsType,
  AltiboxAsset,
  VodAsset,
  AuthReducerState,
} from '../../../../interfaces';
import { routes } from '../../../../config';
import './style.scss';
import { checkIfUserHasSvodProduct } from '../../../../utils/altiboxassetUtils';
import { getSvodUrl } from '../../../../utils/svodUtil';
import { getCurrentLangTitle, hasBoughtProduct } from '../../../../utils/vodUtils';
import { Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { SharedAssetButton } from '../../Buttons';

type State = {
  goTo: string | undefined;
};

type SharedAssetContext = {
  sharedAssets: SharedAssetsType;
  altiboxAsset: AltiboxAsset;
  authState: AuthReducerState;
};
export default class SharedAssets extends Component<{}, State> {
  static contextType = AltiboxAssetContext;

  state: State = {
    goTo: undefined,
  };

  get sharedAssets(): SharedAssetsType {
    return (this.context as SharedAssetContext).sharedAssets;
  }

  get seriesId() {
    const { altiboxAsset } = this.context as SharedAssetContext;
    return (altiboxAsset.asset as unknown as VodAsset).externalContentCode;
  }

  displayVodInformation(vod: VodSharedAsset, isBought: boolean): [string, string] {
    const { isRented, lowestPrice, asset } = vod;
    const { altiboxAsset } = this.context as SharedAssetContext;
    const { meta } = altiboxAsset;
    let linkText: string = i18n.t<string>('watch') + ' ' + i18n.t<string>('from') + ' ' + lowestPrice + ',-';
    let linkUrl =
      routes.vod.base +
      routes.vod.details +
      '/' +
      (meta && meta.isSeries ? this.seriesId + '/' : '') +
      asset.externalContentCode;
    if (isBought) {
      linkText = i18n.t<string>('bought');
    }
    if (isRented) {
      linkText = i18n.t<string>('rented');
    }
    return [linkText, linkUrl];
  }

  displaySvodInformation(svod: SvodSharedAsset): [string, string] {
    const { authState, altiboxAsset } = this.context as SharedAssetContext;
    const { meta } = altiboxAsset;
    const { asset, kiosk } = svod;
    const linkText = !checkIfUserHasSvodProduct(authState, kiosk!)
      ? i18n.t<string>('get access')
      : i18n.t<string>('subscribed');
    const kioskUrl = getSvodUrl(kiosk);
    let linkUrl =
      routes.svod.base +
      '/' +
      kioskUrl +
      routes.svod.details +
      '/' +
      (meta && meta.isSeries ? this.seriesId + '/' : '') +
      asset.externalContentCode;

    return [linkText, linkUrl];
  }

  vodThumb() {
    return <div className="aib-icon asset-brand-icon">N</div>;
  }

  svodService(svod: SvodSharedAsset) {
    const { kiosk } = svod;

    if (!kiosk) {
      return '';
    }

    return getCurrentLangTitle(svod.kiosk?.titles) || kiosk.providerName || '';
  }

  svodThumb(svod: SvodSharedAsset) {
    const { defaultImagePath } = this.context as { defaultImagePath: string };
    const { kiosk } = svod;
    return <img src={`${defaultImagePath}/${kiosk!.kioskHeader!.phone}`} alt={`${kiosk!.providerName!}`} />;
  }

  generateSharedAsset(key: string, title: string, information: string, link: string) {
    return (
      <SharedAssetButton key={key} to={link}>
        {title}
        {information}
      </SharedAssetButton>
    );
  }

  sharedVodAssets(vods: VodSharedAsset[]) {
    return vods.map((vod) => {
      const [jsx, link] = this.displayVodInformation(vod, hasBoughtProduct(vod.pricing));
      return this.generateSharedAsset(vod.asset.id, i18n.t<string>('movies and series'), jsx, link);
    });
  }

  sharedSvodAssets(svods: SvodSharedAsset[]) {
    return svods
      .filter((x) => x.kiosk)
      .map((svod) => {
        const [text, link] = this.displaySvodInformation(svod);
        return this.generateSharedAsset(svod.asset.id, this.svodService(svod), text, link);
      });
  }

  buildSharedAssets() {
    const { sharedAssets } = this;
    const vods = sharedAssets.vod ? this.sharedVodAssets(sharedAssets.vod) : [];
    const svods = sharedAssets.svod ? this.sharedSvodAssets(sharedAssets.svod) : [];
    return vods.concat(svods).filter((x: JSX.Element) => x);
  }

  render() {
    if (this.state.goTo) {
      return <Redirect to={this.state.goTo} />;
    } else {
      if (!isEmpty(this.sharedAssets)) {
        return (
          <div className="shared-assets">
            <h2>{i18n.t<string>('also available in')}</h2>
            <div className="shared-assets-button-container">{this.buildSharedAssets()}</div>
          </div>
        );
      }
      return null;
    }
  }
}
