import React, { useCallback, useEffect, useState } from 'react';
import './styles/cc-style.scss';

import {
  cast_playPause,
  cast_seek,
  getCastPlayerController,
  getCastSession,
  listenForCastConnection,
  updateCastStatus,
  getCastDeviceName,
  cast_setVolume,
  cast_setMute,
  cast_getVolume,
  getCastTracks,
  getActiveTracks,
  cast_setActiveTracks,
  currentOnPlayingCallback,
  setNewCurrentOnPlayingCallback,
} from './helpers/ChromeCast';
import i18n from '../../i18n';
import moment from 'moment';
import VolumeControl from './UI/PlayerVolumeControl';
import HoverMenu from './UI/PlayerHoverMenu';
import { PlayerOptions, RootState } from '../../interfaces';
import { connect, useDispatch } from 'react-redux';
import { unloadCurrentLiveProgram } from '../../views/tv/actions';
import PlayerControlRow from './UI/PlayerControls/PlayerControlsRow';

type LanguageMap = {
  nosub: string;
  en: string;
  eng: string;
  no: string;
  nb: string;
  nor: string;
  da: string;
  dan: string;
  sv: string;
  se: string;
  smi: string;
  swe: string;
  fi: string;
  fin: string;
  '': 'unknown';
};

const languageMap: LanguageMap = {
  nosub: i18n.t<string>('nosub'),
  en: i18n.t<string>('english'),
  eng: i18n.t<string>('english'),
  no: i18n.t<string>('norwegian'),
  nb: i18n.t<string>('norwegian'),
  nor: i18n.t<string>('norwegian'),
  da: i18n.t<string>('danish'),
  dan: i18n.t<string>('danish'),
  sv: i18n.t<string>('swedish'),
  se: i18n.t<string>('sami'),
  smi: i18n.t<string>('sami'),
  swe: i18n.t<string>('swedish'),
  fi: i18n.t<string>('finnish'),
  fin: i18n.t<string>('finnish'),
  '': 'unknown',
};

interface ChromeCastPlayerProps {
  liveFromStart: boolean;
}

const ChromeCastPlayer = ({ liveFromStart }: ChromeCastPlayerProps) => {
  const [playerState, setPlayerState] = useState(
    window.cast.framework.CastContext.getInstance().getCurrentSession()?.getMediaSession()?.playerState,
  );
  const [chromecastController] = useState(getCastPlayerController());
  const [showCastPlayer, setShowCastPlayer] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [liveStart, setLiveStart] = useState(-1);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(0);
  const [start, setStart] = useState(0);
  const [title, setTitle] = useState('Altibox');
  const [muted, setMuted] = useState(false);
  const [currentTimeText, setCurrentTimeText] = useState('');
  const [durationTimeText, setDurationTimeText] = useState('');
  const [duration, setDuration] = useState<undefined | number>(undefined);
  const [isSeeking, setIsSeeking] = useState(false);
  const [volumeThrottleTimer, setVolumeThrottleTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const dispatch = useDispatch();

  const cleanupOnDisconnect = useCallback(
    (event: cast.framework.RemotePlayerChangedEvent) => {
      if (event.field === 'isConnected' && !event.value) {
        getCastSession()?.endSession(true);
        dispatch(unloadCurrentLiveProgram());
      }
    },
    [dispatch],
  );

  const generateTimeString = (seconds: number) => {
    return moment('00:00:00', 'HH:mm:ss').add(Math.ceil(seconds), 'seconds').format('HH:mm:ss');
  };

  const mediaStatusHandler = useCallback(() => {
    let castSession = getCastSession();
    if (!castSession) {
      return;
    }

    let mediaStatus = castSession.getMediaSession();
    if (!mediaStatus) {
      setShowCastPlayer(false);
      return;
    }

    let {
      media,
      media: { duration: mediaDuration, metadata },
    } = mediaStatus;
    let mediaStart = 0;
    let mediaLiveStart = -1;
    let durationTimeTextInput = mediaDuration;

    // if live
    if (mediaStatus.media.streamType === 'LIVE' && metadata) {
      mediaStart = metadata.sectionStartTimeInMedia;
      mediaDuration = mediaStart + metadata.sectionDuration;
      durationTimeTextInput = metadata.sectionDuration;
      mediaLiveStart = metadata.sectionStartTimeInMedia;
    }

    const titleString = media && metadata && metadata.title ? metadata.title : '';

    setIsLive(media.streamType === 'LIVE' ? true : false);
    setLiveStart(mediaLiveStart);
    setPlayerState(mediaStatus.playerState);
    setShowCastPlayer(true);
    setVolume(cast_getVolume());
    setTitle(titleString);
    if (!isSeeking) {
      setCurrentTime(mediaStatus.getEstimatedTime() ? mediaStatus.getEstimatedTime() : 0);
      setDurationTimeText(generateTimeString(durationTimeTextInput));
    }
    if (mediaDuration !== -1) {
      setDuration(mediaDuration);
    }
    setStart(mediaStart);
  }, [isSeeking]);

  const getTimeText = useCallback(
    (time: number) => {
      return isLive ? generateTimeString(time - liveStart) : generateTimeString(time);
    },
    [isLive, liveStart],
  );

  const setCurrentTimeWrapper = (currentTimeNumber: number, currentTimeString: string) => {
    setCurrentTime(currentTimeNumber);
    setCurrentTimeText(currentTimeString);
  };

  const handleAnyChangeEvent = useCallback(
    (event: cast.framework.RemotePlayerChangedEvent) => {
      if (event.field === 'currentTime' && !isSeeking) {
        const time = event.value ?? 0;
        setCurrentTimeWrapper(time, getTimeText(time));
      } else if (event.field === 'playerState' && event.value === 'PLAYING' && currentOnPlayingCallback) {
        setNewCurrentOnPlayingCallback(undefined);
      }
    },
    [getTimeText, isSeeking],
  );

  useEffect(() => {
    chromecastController.addEventListener(window.cast.framework.RemotePlayerEventType.ANY_CHANGE, handleAnyChangeEvent);
    return () => {
      chromecastController.removeEventListener(
        window.cast.framework.RemotePlayerEventType.ANY_CHANGE,
        handleAnyChangeEvent,
      );
    };
  }, [handleAnyChangeEvent, chromecastController]);

  useEffect(() => {
    if (!window.cast) {
      return;
    }
    chromecastController.addEventListener(
      window.cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
      mediaStatusHandler,
    );
    chromecastController.addEventListener(window.cast.framework.RemotePlayerEventType.ANY_CHANGE, cleanupOnDisconnect);

    listenForCastConnection(
      (
        castStateEvent?: cast.framework.CastStateEventData,
        sessionStateEvent?: cast.framework.SessionStateEventData,
      ) => {
        if (
          sessionStateEvent?.sessionState &&
          (sessionStateEvent.sessionState === window.cast.framework.SessionState.SESSION_STARTED ||
            sessionStateEvent.sessionState === window.cast.framework.SessionState.SESSION_RESUMED)
        ) {
          setShowCastPlayer(true);
        }
        if (castStateEvent?.castState === window.cast.framework.CastState.NOT_CONNECTED) {
          setShowCastPlayer(false);
        }
      },
    );
    updateCastStatus();
  }, [chromecastController, cleanupOnDisconnect, mediaStatusHandler]);

  useEffect(() => {
    return () => {
      chromecastController.removeEventListener(
        window.cast.framework.RemotePlayerEventType.ANY_CHANGE,
        cleanupOnDisconnect,
      );
    };
  }, [cleanupOnDisconnect, chromecastController]);

  useEffect(() => {
    if (liveFromStart) {
      setCurrentTimeWrapper(0, getTimeText(0));
      let castSession = getCastSession();
      if (!castSession) {
        return;
      }

      let mediaStatus = castSession.getMediaSession();
      if (!mediaStatus) {
        return;
      }
      if (mediaStatus?.media?.metadata?.sectionStartTimeInMedia) {
        const startTime = mediaStatus.media.metadata.sectionStartTimeInMedia;
        cast_seek(startTime);
      }
    }
  }, [liveFromStart, getTimeText]);

  const playerStateStatusText = () => {
    switch (playerState) {
      case chrome.cast.media.PlayerState.IDLE:
      case chrome.cast.media.PlayerState.BUFFERING:
        return i18n.t<string>('starting on');
      case chrome.cast.media.PlayerState.PAUSED:
        return i18n.t<string>('pause on');
      case chrome.cast.media.PlayerState.PLAYING:
        return i18n.t<string>('playing on');
      default:
        return '';
    }
  };

  const secondsFromLiveEdge = () => {
    let castSession = getCastSession();
    if (!castSession) {
      return 0;
    }
    let mediaStatus = castSession.getMediaSession();
    if (!mediaStatus) {
      return 0;
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return Math.abs(Number(currentTime) + Number(mediaStatus.media['startAbsoluteTime'] - moment().unix()));
  };

  const max = () => {
    if (duration) {
      return isNaN(duration) ? duration.toString() : duration;
    }
    return undefined;
  };

  const positionRangeChange = (event: React.ChangeEvent<HTMLInputElement | undefined>) => {
    if (!event) {
      return;
    }
    const { value } = event.target;
    setCurrentTimeWrapper(+value, getTimeText(+value));
  };

  /**
   * Only last seek event should be sent to chromecast receiver
   */
  const handleSeekOnMouseUp = (event: React.MouseEvent<HTMLInputElement | undefined>) => {
    if (!event) {
      return;
    }
    setIsSeeking(false);
    cast_seek(+event.currentTarget.value);
  };

  const handleSeekOnMouseDown = (event: React.MouseEvent<HTMLInputElement | undefined>) => {
    if (!event) {
      return;
    }
    setIsSeeking(true);
  };

  const toggleMute = () => {
    setMuted(!muted);
    cast_setMute(!muted);
  };

  const volumeChange = (event: React.ChangeEvent<HTMLInputElement | undefined>) => {
    if (!event) {
      return;
    }
    const { value } = event.target;
    if (volumeThrottleTimer) {
      setVolumeThrottleTimer(undefined);
    }
    setVolume(+value);
    setVolumeThrottleTimer(
      setTimeout(() => {
        cast_setVolume(+value);
        if (muted) {
          toggleMute();
        }
      }, 700),
    );
  };

  const setSubtitle = (track: chrome.cast.media.Track) => {
    if (track.language === 'nosub') {
      cast_setActiveTracks([]);
      return;
    }
    cast_setActiveTracks([track.trackId]);
  };

  const setAudioTrack = (track: chrome.cast.media.Track) => {
    cast_setActiveTracks([track.trackId]);
  };

  const checked = () => {
    return <span className="selection-selected aib-icon">v</span>;
  };

  const audioView = (
    showAudios: boolean,
    audioTracks: chrome.cast.media.Track[],
    activeAudioTrackId: number | undefined,
  ) => {
    if (!showAudios) {
      return [];
    }
    return audioTracks.map((track) => {
      let isActive = track.trackId === activeAudioTrackId;
      return (
        <li
          key={`${track.trackId} ${track.trackId}`}
          onClick={() => {
            setAudioTrack(track);
          }}
        >
          {isActive ? <strong>{languageMap[track.language]}</strong> : languageMap[track.language]}
          {isActive ? checked() : null}
        </li>
      );
    });
  };

  const subtitleView = (
    showSubs: boolean,
    textTracks: chrome.cast.media.Track[],
    activeTextTrackId: number | undefined,
  ): JSX.Element[] => {
    if (!showSubs) {
      return [];
    }
    return textTracks.map((track) => {
      let isActive = track.trackId === activeTextTrackId;
      let trackIsChosen = activeTextTrackId;
      let language = languageMap[track.language];
      const trackIsSelected =
        (isActive && trackIsChosen) || (trackIsChosen === undefined && track.language === 'nosub');
      /* eslint-disable @typescript-eslint/dot-notation */
      return (
        <li
          key={`${track.type} ${track.trackId}`}
          onClick={() => {
            setSubtitle(track);
          }}
        >
          {trackIsSelected ? <strong>{language}</strong> : language}
          {track.hasOwnProperty('hardOfHearing') && track['hardOfHearing'] ? '(HOH)' : ''}
          {isActive || trackIsSelected ? checked() : null}
        </li>
      );
      /* eslint-enable @typescript-eslint/dot-notation */
    });
  };

  const audioAndSubView = () => {
    const tracks = getCastTracks();
    if (!tracks) {
      return undefined;
    }
    const audioTracks = tracks.filter((x) => x.type === 'AUDIO');
    const textTracks = tracks.filter((x) => x.type === 'TEXT');
    const showAudios = audioTracks.length > 1;
    const showSubs = textTracks.length >= 1;

    textTracks.unshift({
      language: 'nosub',
      type: chrome.cast.media.TrackType.TEXT,
      trackId: 0,
      hardOfHearing: false,
    } as unknown as chrome.cast.media.Track);

    const activeTracks = getActiveTracks();
    const activeTextTrackId = activeTracks && activeTracks[1] ? activeTracks[1] : undefined;
    const activeAudioTrackId = activeTracks && activeTracks[0] ? activeTracks[0] : undefined;

    return (
      <HoverMenu
        className={'audio-and-subtitles cc'}
        icon={'ë'}
        optionsType={PlayerOptions.AudioAndSubtitles}
        audios={audioView(showAudios, audioTracks, activeAudioTrackId)}
        subtitles={subtitleView(showSubs, textTracks, activeTextTrackId)}
      />
    );
  };

  if (!showCastPlayer) {
    return null;
  }

  return (
    <div className="cc-player player-controls">
      <div className="cc-player-row cc-player-row-1">
        <div className="cc-player-item">{currentTimeText}</div>
        <div className="cc-player-item cc-player-seekbar-item">
          <input
            className="cc-player-seekbar"
            type="range"
            step="1"
            onMouseDown={handleSeekOnMouseDown}
            onChange={positionRangeChange}
            onMouseUp={handleSeekOnMouseUp}
            value={currentTime}
            max={max()}
            min={start}
          />
        </div>
        <div className="cc-player-item">{durationTimeText}</div>
      </div>
      <div className="cc-player-title">
        {`${playerStateStatusText()} ${getCastDeviceName()}:`} <span>{title}</span>
      </div>
      <div className="cc-player-row">
        <PlayerControlRow
          isPlaying={playerState === 'PLAYING'}
          isFullscreen={false}
          isWatchingLive={secondsFromLiveEdge() < 10}
          playPause={cast_playPause}
          fastForward={() => {
            cast_seek(currentTime + 30);
          }}
          rewind={() => {
            cast_seek(currentTime - 30);
          }}
          onMouseEnter={() => {
            void 0;
          }}
          onMouseLeave={() => {
            void 0;
          }}
          volumeControl={
            <VolumeControl volume={volume} toggleMute={toggleMute} changeVolume={volumeChange} muted={muted} />
          }
          audioAndSubtitlesMenu={audioAndSubView()}
        />
      </div>
    </div>
  );
};
export default connect((state: RootState) => ({
  liveFromStart: state.channelsReducer.liveFromStart,
}))(ChromeCastPlayer);
