import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import { ButtonPrimary, ButtonSecondary, ButtonClose } from '../../components/UI/Buttons';
import i18n from '../../i18n';
import { Trans } from 'react-i18next';
import { GAaction, GAlabel, StreamingServiceIDType, StreamingServicePortal } from '../../interfaces';
import {
  getSvodAltiboxServiceUrl,
  getSvodDeeplink,
  getSvodModalSetting,
  skipModalByConditionType,
  ThirdPartyPopUp,
} from '../../utils/svodUtil';
import { getCurrentLangTitle } from '../../utils/vodUtils';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';
import './DeeplinkModal.scss';
import { ScriptService } from '../../controllers/ScriptService';

interface Props {
  showModalState: boolean;
  setOpenModal: (open: boolean) => void;
  externalService: StreamingServicePortal;
}

const descriptionText = (portal: StreamingServicePortal, title: string) => {
  let text;

  switch (portal.id) {
    case StreamingServiceIDType.VIAPLAY:
    case StreamingServiceIDType.HBO_MAX:
      text = (
        <p>
          <Trans
            i18nKey={`${
              ScriptService.isDKUser()
                ? 'pop-up message hbo-max and viaplay dk users'
                : 'pop-up message hbo-max and viaplay'
            }`}
            values={{ service: title }}
          />
        </p>
      );
      break;
    case StreamingServiceIDType.AMAZON_PRIME:
      text = (
        <p>
          <Trans i18nKey="pop-up message prime-video" />
        </p>
      );
      break;
    default:
      text = <p>{i18n.t<string>('order a subscription')}</p>;
  }

  return text;
};

const hideButtonClassName = (externalService: StreamingServicePortal) => {
  return externalService.id === StreamingServiceIDType.AMAZON_PRIME ? 'hide' : '';
};

const secondaryButtonText = () => {
  return ScriptService.isDKUser() ? i18n.t<string>('order') : i18n.t<string>('go to mine sider');
};

const DeeplinkModal = ({ showModalState, setOpenModal, externalService }: Props) => {
  const [servicePortal, setServicePortal] = useState<StreamingServicePortal>({} as StreamingServicePortal);
  const [title, setTitle] = useState<string>('');
  const [deepLink, setDeepLink] = useState<string>('');
  const [checkedBox, setCheckedBox] = useState<boolean>(false);
  const tracking = useRef<AnalyticsTracking | null>(null);
  const hideMessage = localStorage.getItem(ThirdPartyPopUp.KEY) === ThirdPartyPopUp.HIDE;

  const handleClose = () => {
    setOpenModal(false);
    getSvodModalSetting(checkedBox);
  };

  const handleCheckmark = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedBox(e.target.checked);
  };

  const handleLink = (portal?: string) => {
    const category = tracking.current?.getCurrentCategory();
    handleClose();
    tracking.current?.trackEvent(
      category!,
      GAaction.openService,
      portal ? `${GAlabel.thirdParty}_${portal}` : GAlabel.mineSider,
    );
  };

  useEffect(() => {
    tracking.current = AnalyticsTracking.getInstance();
  }, []);

  useEffect(() => {
    if (hideMessage || skipModalByConditionType(servicePortal)) {
      setOpenModal(false);
    }
  }, [hideMessage, setOpenModal, servicePortal]);

  useEffect(() => {
    setServicePortal(externalService);
  }, [externalService]);

  useEffect(() => {
    const serviceDeepLink = servicePortal ? getSvodDeeplink(servicePortal) : '';
    if (servicePortal.titles || servicePortal.id) {
      const serviceTitle = servicePortal.titles ? getCurrentLangTitle(servicePortal.titles) : servicePortal.id;
      setTitle(serviceTitle);
    }

    setDeepLink(serviceDeepLink);
  }, [servicePortal]);

  return (
    <Modal className="deeplink-modal-container" isOpen={showModalState}>
      <ButtonClose className="close-button" onClick={() => handleClose()} />
      <div className="deeplink-modal-wrapper">
        <h3>
          {i18n.t<string>('before opening')} {title}
        </h3>
        {descriptionText(servicePortal, title)}

        <label className="checkbox-container">
          <div className="label-text"> {i18n.t<string>('dont show this message again')} </div>
          <input onChange={(e) => handleCheckmark(e)} type="checkbox" />
          <span className="checkmark">
            <div className="aib-icon">v</div>
          </span>
        </label>
        <Link onClick={() => handleLink(servicePortal.id)} to={{ pathname: deepLink }} target="_blank" rel="noreferrer">
          <ButtonPrimary className="continue-button">
            {i18n.t<string>('continue to')} {title}
          </ButtonPrimary>
        </Link>
        <Link
          onClick={() => handleLink()}
          to={{ pathname: getSvodAltiboxServiceUrl() }}
          target="_blank"
          rel="noreferrer"
        >
          <ButtonSecondary className={`my-page-button ${hideButtonClassName(externalService)}`}>
            {secondaryButtonText()}
          </ButtonSecondary>
        </Link>
      </div>
    </Modal>
  );
};

export default DeeplinkModal;
