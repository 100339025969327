import {
  Bookmark,
  Program,
  AltiboxAsset,
  AltiboxAssetDetailsType,
  Channel,
  Favourite,
  CatchupTVContentRight,
} from '../interfaces';
import { broadcastingFuture, subtractMinutes, toMoment } from './huaweiUtils';

function getSinglePercentTimeUnit({ starttime, beginOffset, endtime, endOffset }: Program) {
  let programStart = subtractMinutes(starttime, Number(beginOffset));

  // We won't be using post padding for the moment, but it could be used in the future
  // let programEnd = addMinutes(endtime, Number(endOffset));

  const durationInSeconds = toMoment(endtime).diff(toMoment(programStart), 'seconds');
  return durationInSeconds / 100;
}

export function getPercentWatchedCatchup(program: Program, bookmark: Bookmark) {
  if (bookmark.rangeTime) {
    const timeUnit = getSinglePercentTimeUnit(program);
    return Number(bookmark.rangeTime) / timeUnit;
  }
  return 0;
}

export function groupBySeason(programs: Program[], channels: Channel[]): Record<string, AltiboxAsset[]> {
  return programs.reduce((seasons: Record<string, AltiboxAsset[]>, program: Program) => {
    const seasonNumber = program.seasonNum ?? 0;

    return {
      ...seasons,
      [seasonNumber]: [...(seasons[seasonNumber] ?? []), getProgramAsset(program, channels, true)],
    };
  }, {});
}

export function createSeries(seasons: Record<string, AltiboxAsset[]>, program: Program, channels: Channel[]) {
  const series = getProgramAsset(program, channels);
  series.meta!.isSeries = true;

  (series.asset as Program).seasons = Object.keys(seasons)
    .map((seasonNumber) => ({
      seasonNumber,
      content: keepOnlyTwoFutureEpisodes(removeDuplicates(seasons[seasonNumber], program), program).sort(
        sortEpisodesOf,
      ),
    }))
    .sort((a, b) => Number(a.seasonNumber) - Number(b.seasonNumber));

  return series;
}

function sortEpisodesOf(a: AltiboxAsset, b: AltiboxAsset) {
  const aAsset = a.asset as Program;
  const bAsset = b.asset as Program;
  return Number(bAsset.subNum) - Number(aAsset.subNum);
}

function keepOnlyTwoFutureEpisodes(episodes: AltiboxAsset[], episodeToPromote: Program): AltiboxAsset[] {
  const sortedEpisodes = [...episodes].sort((a, b) => {
    const programA = a.asset as Program;
    const programB = b.asset as Program;

    return Number(programA.starttime) - Number(programB.starttime);
  });

  const firstFutureEpisodeIdx = sortedEpisodes.findIndex((episode) => broadcastingFuture(episode.asset as Program));

  if (firstFutureEpisodeIdx === -1) {
    return sortedEpisodes;
  }

  const episodeToPromoteIdx = sortedEpisodes.findIndex((episode) => episode.asset === episodeToPromote);

  if (episodeToPromoteIdx === -1 || episodeToPromoteIdx <= firstFutureEpisodeIdx + 1) {
    return sortedEpisodes.slice(0, firstFutureEpisodeIdx + 2);
  }

  return [...sortedEpisodes.slice(0, firstFutureEpisodeIdx + 1), sortedEpisodes[episodeToPromoteIdx]];
}

function removeDuplicates(season: AltiboxAsset[], episodeToPromote: Program) {
  return season
    .map((altiboxAsset, _, array) => {
      const asset = altiboxAsset.asset as Program;
      const duplicates = array.filter((_altiboxAsset) => {
        const toFind = _altiboxAsset.asset as Program;

        return (
          asset.lifetimeId === toFind.lifetimeId ||
          (toFind.seriesID === asset.seriesID &&
            toFind.subNum &&
            asset.subNum &&
            toFind.subNum === asset.subNum &&
            toFind.channelid === asset.channelid)
        );
      });

      // Only found itself
      if (duplicates.length === 1) {
        return altiboxAsset;
      }

      const foundEpisodeToPromoteDuplicate = duplicates.find((duplicate) => duplicate.asset.id === episodeToPromote.id);

      // Pick episodeToPromote over most recently aired
      if (foundEpisodeToPromoteDuplicate && foundEpisodeToPromoteDuplicate.asset === asset) {
        return foundEpisodeToPromoteDuplicate;
      }

      duplicates.sort((a, b) => {
        const programA = a.asset as Program;
        const programB = b.asset as Program;

        if (broadcastingFuture(programA) && broadcastingFuture(programB)) {
          return 0;
        }

        if (broadcastingFuture(programA)) {
          return 1;
        }

        if (broadcastingFuture(programB)) {
          return -1;
        }

        return Number(programB.starttime) - Number(programA.starttime);
      });

      return duplicates[0].asset === asset ? duplicates[0] : undefined;
    })
    .filter((altiboxAsset) => altiboxAsset !== undefined) as AltiboxAsset[];
}

export function getProgramAsset(program: Program, channels: Channel[], nometa?: boolean): AltiboxAsset {
  const channel = channels.find((x) => x.contentId === program.channelid) as Channel;
  return {
    progress: 0,
    id: '0',
    picture: '',
    title: '',
    playLink: '',
    detailsLink: '',
    weight: 0,
    asset: program,
    type: AltiboxAssetDetailsType.CATCHUP,
    meta: nometa
      ? undefined
      : {
          favourite: {} as Favourite,
          isSeries: false,
          channel: channel ? channel : undefined,
        },
  };
}

// copied from android code
export function hasCatchupEnabled(contentRight: CatchupTVContentRight) {
  if (contentRight.e === '0') {
    return false;
  }

  if (contentRight.e === '1' && contentRight.va === '1') {
    return true;
  }

  // Special case, in geo blocked the reason the isValid ("va") param is 0.
  // Check if the reason ("r") is location, if the only reason is that then enable catchup
  return (
    contentRight.va === '0' &&
    contentRight.r &&
    contentRight.r.length === 1 &&
    'Location'.indexOf(contentRight.r[0].name) !== -1
  );
}
