import { Bookmark } from '../../interfaces';

export const catchupKeys = {
  all: ['catchup'] as const,
  ids: (ids: string[]) => [...catchupKeys.all, ids.join('-')] as const,
  bookmarks: (bookmarks: Bookmark[]) => {
    const ids = bookmarks.map((b) => `${b.contentId}:${b.updateTime}`);
    return [...catchupKeys.all, ids.join('-')] as const;
  },
};
