import {
  AltiboxAssetDetailsType,
  AltiboxSubscriber,
  NonPaceSubscriber,
  Program,
  Pvr,
  PvrRecording,
  PvrSortingOption,
  SortCategories,
  UnionAssetTypes,
  VodAsset,
  VodSvodSortingOption,
} from './interfaces';
import { Recording } from './queries/recordings/types';
import { isPvr } from './utils/altiboxassetUtils';

export function assetIsProgram(asset: UnionAssetTypes): asset is Program {
  return asset.type === AltiboxAssetDetailsType.PROGRAM;
}

export function assetIsVod(asset: UnionAssetTypes): asset is VodAsset {
  return 'locationCopyrights' in asset;
}

export function assetIsPvr(asset: UnionAssetTypes): asset is PvrRecording {
  return isPvr(asset);
}

export function subscriberIsNotPace(subscriber: AltiboxSubscriber): subscriber is NonPaceSubscriber {
  return subscriber.platform !== 'PACE';
}

export function sortingIsNotPvr(sorting: VodSvodSortingOption | PvrSortingOption): sorting is VodSvodSortingOption {
  return SortCategories.MOST_POPULAR in sorting;
}

export function recordingIsSingle(recording: Pvr.UnionRecording | PvrRecording): recording is Pvr.SingleRecording {
  return !('periodPVRTaskId' in recording && Boolean(recording.periodPVRTaskId)) || false;
}

export function recordingIsSeries(
  recording: Pvr.UnionRecording | Recording.Query.Union,
): recording is Pvr.SeriesRecording | Recording.Query.Series {
  return 'pvrList' in recording;
}
