import {
  AltiboxServiceLink,
  GAaction,
  GAcategory,
  GAlabel,
  StreamingPortalsType,
  StreamingServiceIDType,
  SvodConditionType,
  VodType,
} from './../interfaces';
import {
  PortalMenu,
  AltiboxAsset,
  VodAsset,
  AltiboxAssetType,
  VodContentProduct,
  StreamingServicePortal,
} from '../interfaces';
import { routes } from '../config';
import { hasPicture } from './altiboxassetUtils';
import i18n from '../i18n';
import isEmpty from 'lodash/isEmpty';

import missingCoverUrlLandscape from '../components/VodAsset/missing-svod-cover.jpeg';
import { ScriptService } from '../controllers/ScriptService';
import AnalyticsTracking from '../controllers/AnalyticsTracking';
import { ContinueWatching } from '../queries/continue-watching/types';
import { Vod } from '../queries/vod/types';
export { missingCoverUrlLandscape };

export function getSvodUrl(svod: PortalMenu | undefined) {
  return svod && svod.providerName ? svod.providerName.replace(/ /g, '-').toLowerCase() : '';
}

export function getSvodDeeplink(portal: StreamingServicePortal) {
  if (portal && portal.actionParams && portal.actionParams.url && portal.actionParams.url.web) {
    return portal.actionParams.url.web;
  }
  return '';
}

export function getSvodRouteUrl(portal: StreamingServicePortal) {
  return portal.id ? portal.id.replace(/_/g, '-').toLowerCase() : '';
}

export function getSvodLinkPath(portal: StreamingServicePortal, svodKiosks: PortalMenu[]) {
  let svodPageLink: string = '';
  let deeplink: string = '';

  if (portal.type === StreamingPortalsType.INTERNAL) {
    const ingestedServiceKiosk = svodKiosks.find((kiosk) => kiosk.identifier === portal.id);
    let svodUrl = getSvodRouteUrl(portal) ?? getSvodUrl(ingestedServiceKiosk);
    svodPageLink = `${routes.svod.base}/${svodUrl}`;
  } else if (portal.type === StreamingPortalsType.EXTERNAL) {
    deeplink = getSvodDeeplink(portal);
  }

  return { deeplink, svodPageLink };
}

export function getSvodAltiboxServiceUrl() {
  return ScriptService.isDKUser() ? AltiboxServiceLink.DK : AltiboxServiceLink.NO;
}

export function getSvodKiosk(asset: VodAsset | ContinueWatching.Vod | Vod.Details, svodKiosks: PortalMenu[]) {
  let tempKiosks = [...svodKiosks];

  tempKiosks.forEach((tempKiosk) => {
    if (!tempKiosk.allActiveCategoryIds) {
      tempKiosk.allActiveCategoryIds = [];
    }
  });

  let kiosk =
    asset && asset.categoryIds
      ? tempKiosks.find((svodKiosk) =>
          svodKiosk.allActiveCategoryIds.some((svodCategoryId) => asset.categoryIds.indexOf(svodCategoryId) !== -1),
        )
      : undefined;
  if (!kiosk && asset && asset.companyName) {
    let kiosksWithProviderName = tempKiosks.filter((x) => x.providerName);
    kiosk = kiosksWithProviderName.find(
      (svodKiosk) => svodKiosk.providerName!.toLowerCase() === asset.companyName.toLowerCase(),
    );
  }
  return kiosk;
}

export function getSvodUrlBasedByProvidername(providerName: string) {
  return providerName ? providerName.replace(/ /g, '-').toLowerCase() : '';
}

export function getSvodGaName(svod: PortalMenu) {
  return 'svod_' + (svod.identifier ? svod.identifier : 'not_set');
}

export function gaSvodTracking(
  category: GAcategory,
  action: GAaction,
  portal: StreamingServicePortal,
  skipModal?: boolean,
) {
  let gaSvodLabel = '';

  if (portal.type === StreamingPortalsType.INTERNAL) {
    gaSvodLabel = `svod_${portal.id}`;
  } else if (skipModal) {
    gaSvodLabel = `${GAlabel.thirdParty}_${portal.id}`;
  }

  return AnalyticsTracking.getInstance().trackEvent(category, action, gaSvodLabel);
}

export function getSvodDeeplinkBase(svod: PortalMenu) {
  return svod.providerName ? 'altibox://svod/' + getSvodUrl(svod) + '/movie' : '';
}

export function getSvodPicture(asset: VodAsset) {
  let pictureUrl = '';
  if (asset.picture) {
    if (Object.keys(asset.picture).length > 0) {
      let pictureType = '';

      if (asset.picture.still) {
        pictureType = asset.picture.still;
      } else if (asset.picture.other) {
        pictureType = asset.picture.other;
      }

      pictureUrl = pictureType.split(',').pop() || '';
    }
  }
  return pictureUrl;
}

export function getSvodProducts(assets: VodContentProduct[]) {
  if (!assets || isEmpty(assets)) {
    return [];
  }
  let svodProducts = assets.filter((x: VodContentProduct) => {
    let hasIntroduce = false;
    let hasName = false;
    if (x.introduce) {
      hasIntroduce = x.introduce.indexOf('SVOD') !== -1;
    }
    if (x.name) {
      hasName = x.name.indexOf('SVOD') !== -1;
    }
    return hasIntroduce || hasName;
  });
  if (svodProducts.length === 0) {
    return [];
  }
  return svodProducts;
}

export function getSvodAsset(
  svodAsset: VodAsset,
  svodKiosks: PortalMenu[],
  defaultImagePath: string,
  showGenreSubtitle?: boolean,
): AltiboxAsset {
  let detailsLink = '';
  let playLink = '';
  let brandLogo = '';
  let croppedBrandLogo = '';
  let svodKiosk = getSvodKiosk(svodAsset, svodKiosks);
  const svodUrl = getSvodUrl(svodKiosk!);
  playLink = `${routes.svod.base}/${svodUrl}${routes.vod.play}/${svodAsset.externalContentCode}`;
  detailsLink = svodUrl
    ? `${routes.svod.base}/${svodUrl}${routes.svod.details}/${svodAsset.externalContentCode}`
    : `${routes.vod.base}${routes.svod.details}/${svodAsset.externalContentCode}`;
  if (svodKiosk && svodKiosk.kioskHeader) {
    const { phone, cropped } = svodKiosk.kioskHeader;
    brandLogo = phone ? `${defaultImagePath}/${phone}` : '';
    croppedBrandLogo = cropped ? `${defaultImagePath}/${cropped}` : '';
  }
  const picture =
    hasPicture(svodAsset.picture) && getSvodPicture(svodAsset) !== ''
      ? getSvodPicture(svodAsset)
      : missingCoverUrlLandscape;
  let percentWatched = 0;
  let subtitleText = '';
  if (showGenreSubtitle) {
    subtitleText =
      (svodAsset.vodtype === VodType.NON_TV_SERIES ? i18n.t<string>('movie') : i18n.t<string>('series')) +
      (svodAsset.genres ? ' - ' : '') +
      (svodAsset.genres ? svodAsset.genres.split(',')[0] : '');
  }

  return {
    id: svodAsset.id,
    progress: percentWatched,
    description: svodAsset.introduce,
    type: AltiboxAssetType.SVOD,
    brandLogo: brandLogo,
    croppedBrandLogo: croppedBrandLogo,
    hideBrandLogo: false,
    picture: picture,
    coverImage: picture,
    title: svodAsset.name,
    subtitle: subtitleText,
    genre: svodAsset.genres ? svodAsset.genres.split(',')[0] : '',
    playLink: playLink,
    weight: 1,
    detailsLink: detailsLink,
    asset: svodAsset,
  };
}

export function getSvodBrandLogo(kiosk: PortalMenu, defaultImagePath: string) {
  if (!kiosk.kioskHeader) {
    return '';
  }
  const { cropped, phone } = kiosk.kioskHeader;

  if (cropped) {
    return `${defaultImagePath}/${kiosk.kioskHeader.cropped}`;
  }
  if (phone) {
    return `${defaultImagePath}/${kiosk.kioskHeader.phone}`;
  }
}

export function getSvodImageLogo(portal: StreamingServicePortal, defaultImagePath: string) {
  return portal.icon ? `${defaultImagePath}/${portal.icon}` : '';
}

export function getSvodKiosksForWeb(svodKiosks: PortalMenu[]) {
  return svodKiosks.filter((kiosk) => (kiosk.devices ? kiosk.devices.web : true));
}

export enum ThirdPartyPopUp {
  KEY = 'third-party-pop-up',
  HIDE = 'hide',
}

export function getSvodModalSetting(hide?: boolean) {
  return hide
    ? localStorage.setItem(ThirdPartyPopUp.KEY, ThirdPartyPopUp.HIDE)
    : localStorage.getItem(ThirdPartyPopUp.KEY);
}

export function skipModalByConditionType(portal: StreamingServicePortal) {
  return Boolean(
    portal.conditions?.includes(SvodConditionType.FREE) || portal.id === StreamingServiceIDType.TV2_PLAY_DK,
  );
}
