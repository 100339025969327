import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  RootState,
  VodListResponse,
  VodConfig,
  VodPromotion,
  ApiAuthResponse,
  SearchConfig,
} from '../../../interfaces';
import FullscreenMessage from '../../../components/FullscreenMessage';
import Spinner from '../../../components/Spinner/index';
import VodAssetList from '../../../components/VodAssetList/index';
import { routes, getBaseUrl } from '../../../config';
import { clearCategory, loadPromoCategory } from '../actions';
import './style.scss';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../components/Footer/index';
import i18n from '../../..//i18n';
import StickyHeader from '../../app/StickyHeader';

interface RouteProps {
  categoryId: string;
}

interface Props extends RouteComponentProps<RouteProps, {}, { prevPath?: string }> {
  clearCategory: () => Promise<void>;
  loadPromoCategory: (categoryId: string) => Promise<void>;
  currentVodCategory: VodListResponse;
  vodConfig: VodConfig;
  auth: ApiAuthResponse;
  loggedInWithCredentials: boolean;
  searchConfig: SearchConfig;
}

interface State {
  title: string;
  loadingTitle: string;
  empty: boolean;
  coverUrl?: string;
}

class Promo extends Component<Props, State> {
  state: State = {
    title: '',
    loadingTitle: i18n.t<string>('loading') + '...',
    empty: false,
  };

  componentDidMount() {
    this.props.clearCategory();

    const params = this.props.match.params;

    this.props.vodConfig.promotions.forEach((promotion: VodPromotion) => {
      if (params.categoryId === promotion.platformId) {
        this.setState({
          coverUrl: `${getBaseUrl()}/EPG/CONFIG/${this.props.auth!.bossID}/${this.props.auth!.areaid}/images/${
            promotion.image
          }`,
        });
        if (params.categoryId) {
          this.props.loadPromoCategory(params.categoryId).then(() => {
            this.setState({
              empty: !this.hasAssets(),
              loadingTitle: '',
            });
          });
        }
      } else {
        this.setState({
          empty: true,
          loadingTitle: '',
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.clearCategory();
  }

  hasAssets() {
    return this.props.currentVodCategory &&
      this.props.currentVodCategory.vodlist &&
      this.props.currentVodCategory.vodlist.length > 0
      ? true
      : false;
  }

  onBackButtonClicked = () => {
    if (this.props.location.state) {
      if (this.props.location.state.prevPath) {
        this.props.history.push(this.props.location.state.prevPath);
      } else {
        this.props.history.goBack();
      }
    } else {
      this.props.history.push(routes.vod.base);
    }
  };

  render() {
    let alteredSearchConfig = this.props.searchConfig;
    alteredSearchConfig.returnPath = this.props.location.pathname;
    const title = 'Kampanje';
    return (
      <div className="main-frame">
        <StickyHeader />
        {this.state.coverUrl ? (
          <div className="promo-cover">
            <img alt={this.state.title} src={this.state.coverUrl} />
            <div className="shader">&nbsp;</div>
          </div>
        ) : null}
        <div className="vod-asset-full-view vod-content vod-promo">
          {this.state.empty ? (
            <>
              <Helmet>
                <title>{'Not Found 😻'}</title>
              </Helmet>
              <FullscreenMessage>{i18n.t('empty campaign')}</FullscreenMessage>
            </>
          ) : this.hasAssets() ? (
            <>
              <Helmet>
                <title>{title}</title>
                <meta property="og:site_name" content={'Altibox TV'} />
                <meta property="og:title" content={title} />
                <meta property="og:image" content={this.state.coverUrl} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:description" content={'Altibox TV - ' + title} />
                <meta property="og:type" content="website" />
              </Helmet>
              <VodAssetList
                vods={this.props.currentVodCategory.vodlist}
                loggedInWithCredentials={this.props.loggedInWithCredentials}
              />
            </>
          ) : (
            <Spinner />
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
      currentVodCategory: state.vodReducer.currentVodCategory as VodListResponse,
      vodConfig: state.vodReducer.vodConfig as VodConfig,
      auth: state.authReducer.auth as ApiAuthResponse,
    }),
    (dispatch) => ({
      clearCategory: () => dispatch(clearCategory()),
      loadPromoCategory: (categoryId: string) => dispatch(loadPromoCategory(categoryId)),
    }),
  )(Promo),
);
