import channelPermissions from './channelPermissions';
import { Channel, AuthReducerState } from '../interfaces';

export default function sortChannels(channels: Channel[], authState: AuthReducerState) {
  channels = channels.slice();

  return channels.sort((channel1, channel2) => {
    const channel1Locked = channelPermissions(channel1, authState).locked;
    const channel2Locked = channelPermissions(channel2, authState).locked;
    const channel1Index = parseInt(channel1.chanNo, 10);
    const channel2Index = parseInt(channel2.chanNo, 10);

    const lockedOrder = (channel1Locked ? 1 : 0) - (channel2Locked ? 1 : 0);
    const indexOrder = channel1Index > channel2Index ? 1 : channel1Index === channel2Index ? 0 : -1;

    return lockedOrder * 10 + indexOrder;
  });
}
