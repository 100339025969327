import { useRef } from 'react';
import { Filter, FilterState } from '../..';
import i18n from '../../../../../../i18n';
import { AltiboxAsset, AltiboxAssetDetailsType, Option } from '../../../../../../interfaces';
import { OptionButtonFilter } from '../../../../Buttons';
import { OptionBox } from '../../../../OptionBox';

export interface FilteringProps {
  altiboxAsset: AltiboxAsset;
  filter: FilterState;
  setFilter: React.Dispatch<React.SetStateAction<FilterState>>;
  handleFilterSelect: (option: Option) => void;
  getFilterOptions: () => {
    count: number;
    title: string;
    value: Filter;
    active: boolean;
  }[];
}

export function Filtering({ altiboxAsset, filter, setFilter, handleFilterSelect, getFilterOptions }: FilteringProps) {
  const filterButtonRef = useRef<HTMLButtonElement>(null);

  const toggleFilterOptionBox = () => {
    setFilter((prev) => prev && { ...prev, open: !prev.open });
  };

  const handleFilterBoxOutsideClick = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
    const button = filterButtonRef.current;

    if (!button || !(event.target instanceof Element)) {
      return;
    }

    if (!button.contains(event.target)) {
      toggleFilterOptionBox();
    }
  };

  switch (altiboxAsset.type) {
    case AltiboxAssetDetailsType.CATCHUP:
    case AltiboxAssetDetailsType.PROGRAM:
    case AltiboxAssetDetailsType.PVR:
      if (!filter) {
        return null;
      }
      const filterOptionsValue = getFilterOptions() as unknown as Option[];
      return (
        <div className="altibox-asset-filter-container">
          <OptionButtonFilter ref={filterButtonRef} onClick={() => toggleFilterOptionBox()}>
            {i18n.t<string>('filter')}
          </OptionButtonFilter>
          {filter.open ? (
            <OptionBox
              className="altibox-asset-filter-box"
              title={i18n.t<string>('filter')}
              options={filterOptionsValue}
              onOutsideClick={handleFilterBoxOutsideClick}
              handleSelect={handleFilterSelect}
            />
          ) : null}
        </div>
      );
    case AltiboxAssetDetailsType.SVOD:
    case AltiboxAssetDetailsType.VOD:
    case AltiboxAssetDetailsType.VIDEO_VOD:
    default:
      return null;
  }
}
