import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getAllFavourites } from '../../api/favorites';
import { Channel, ContentType, RootState } from '../../interfaces';
import { getChannels } from './api';
import { channelKeys } from './keys';
import sortChannels from '../../utils/sortChannels';

type UseSelect<TReturn, TArg> = ((arg: TArg) => TReturn) | undefined;

const FIFTEEN_MIN = 1000 * 60 * 15;

/**
 *
 * @returns All channels
 */
export function useChannels<TReturn = Channel[]>(transform?: UseSelect<TReturn, Channel[]>) {
  return useQuery(channelKeys.all, () => getChannels().then(addFavoritesToChannels), {
    staleTime: FIFTEEN_MIN,
    select: transform,
  });
}

/**
 *
 * @param channelId
 * @returns Channel by id
 */
export function useChannel(channelId: string) {
  return useChannels((channels) => {
    return channels.find((channel) => channel.contentId === channelId);
  });
}

/**
 *
 * @param channelId
 * @returns Sorted channels for TV-guide
 */
export function useSortedChannels() {
  const auth = useSelector((state: RootState) => state.authReducer);
  return useChannels((channels) => sortChannels(channels, auth));
}

function addFavoritesToChannels(channels: Channel[]) {
  return getAllFavourites(ContentType.CHANNEL).then((favoriteChannels) => {
    const { favoritelist } = favoriteChannels;
    return channels.map((channel) => {
      const foundFavorite = favoritelist.find((favorite) => favorite.id === channel.contentId);

      if (foundFavorite) {
        return { ...channel, isfavorited: '1' };
      }

      return { ...channel, isfavorited: '0' };
    });
  });
}
