import {
  PvrRecording,
  PvrStatus,
  AltiboxAssetType,
  AltiboxAsset,
  RecordingDeleteMode,
  Channel,
  Genre,
  PvrSpace,
  PvrSpaceStatus,
  CountryType,
  AlertData,
  AlertType,
  Bookmarks,
  ChannelLogoType,
  Pvr,
  SeriesType,
} from '../interfaces';
import { toMoment, toMomentReadableDateString, subtractMinutes, addMinutes } from './huaweiUtils';
import moment, { unitOfTime } from 'moment';
import { routes, footerURLs, imageScaleValues } from '../config';
import i18n from '../i18n';
import { getChannelid, getImageUrlDimensions } from './altiboxassetUtils';
import { isEmpty } from 'lodash';
import { getChannelLogoUrl } from './tvUtils';
import missingCover from '../components/TVProgramCollage/LiveCollageProgram/tv-placeholder-landscape.png';
import { recordingIsSeries, recordingIsSingle } from '../typeGuards';

export function getRecording(
  seriesRecordings: PvrRecording[],
  singleRecordings: PvrRecording[],
  seriesId: string | undefined,
  channelId: string | undefined,
  programId: string | undefined,
  lifetimeId: string | undefined,
) {
  const seriesRecording = seriesRecordings.find(
    (x) => (x.seriesId === seriesId && x.channelId === channelId) || x.lifetimeId === lifetimeId,
  );
  const singleRecording = singleRecordings.find(
    (x) => (x.programId === programId && x.channelId === channelId) || x.lifetimeId === lifetimeId,
  );
  return singleRecording || findSeriesRecording(seriesRecording, programId, channelId);
}

export function findSeriesRecording(
  seriesRecording: PvrRecording | undefined,
  programId: string | undefined,
  channelId: string | undefined,
) {
  if (seriesRecording && seriesRecording.pvrList) {
    return seriesRecording.pvrList.find((r) => r.programId === programId && r.channelId === channelId);
  }

  if (seriesRecording && seriesRecording.subRecordings) {
    return seriesRecording.subRecordings.find((r) => r.programId === programId && r.channelId === channelId);
  }

  return undefined;
}

export function getIsRecordingProgram(singleRecordings: PvrRecording[], programId: string | undefined) {
  const singleRecording = singleRecordings.find((recording) => recording.programId === programId);
  return Boolean(singleRecording);
}

export function getIsProgramInSeriesRecording(
  seriesRecording: PvrRecording | undefined,
  programId: string | undefined,
) {
  if (seriesRecording && seriesRecording.subRecordings) {
    return Boolean(seriesRecording.subRecordings.find((pvr) => pvr.programId === programId));
  }

  if (seriesRecording && seriesRecording.pvrList) {
    return Boolean(seriesRecording.pvrList.find((pvr) => pvr.programId === programId));
  }

  return undefined;
}

export function getIsRecordingSeries(
  seriesRecordings: PvrRecording[],
  seriesId: string,
  channelContentId: string | undefined,
) {
  const seriesRecording = seriesRecordings.find(
    (recording) => recording.seriesId === seriesId && recording.channelId === channelContentId,
  );

  if (seriesRecording === undefined) {
    return false;
  }

  return !seriesRecording.canceled;

  // return Object.values(RecordingsList).some((list) => {
  //   if (!isEmpty(seriesRecording[list])) {
  //     const episodeSeriesRecording = seriesRecording[list].find((x) => x.lifetimeId === lifetimeId);
  //     if (episodeSeriesRecording) {
  //       return !episodeSeriesRecording.canceled;
  //     }
  //   }
  //   return false;
  // });
}

// more than 97% watched returnes false, else true
export function isBookmarkPassedWatchedLimit(recording: PvrRecording) {
  const timeLimit = getSinglePercentTimeUnit(recording) * 97;
  return parseInt(recording.bookmarkTime, 10) >= timeLimit ? true : false;
}

export function getDuration(asset: PvrRecording) {
  if (asset.endTime && asset.beginTime) {
    const endTime = toMoment(asset.endTime).add(asset.endOffset, 'minutes');
    const beginTime = toMoment(asset.beginTime).add(asset.beginOffset, 'minutes');
    return endTime.diff(beginTime, 'minutes');
  } else {
    return 'NA';
  }
}

function getSinglePercentTimeUnit(recording: PvrRecording | Pvr.Recording) {
  if (!recording.beginTime || !recording.endTime) {
    return 0;
  }

  let programStart = subtractMinutes(recording.beginTime, Number(recording.beginOffset));
  let programEnd = addMinutes(recording.endTime, Number(recording.endOffset));
  const durationInSeconds = toMoment(programEnd).diff(toMoment(programStart), 'seconds');
  return durationInSeconds / 100;
}

export function getStoragePercentUsed(space: PvrSpace) {
  const totalSpace: number = Number(space.used) + Number(space.left);
  const percentUsed: number = space.used / totalSpace;
  return percentUsed;
}

export function getSpaceValues(space: PvrSpace | undefined) {
  if (!space) {
    return {
      totalSpace: 0,
      spaceUsed: 0,
      percentageLeft: 0,
      percentageUsed: 0,
      percentageColor: 'green',
    };
  }

  const totalSpace = Math.round(space.left / 60 + space.used / 60);
  const spaceUsed = Math.round(space.used / 60);
  const percentageUsed = Math.round(getStoragePercentUsed(space) * 100);
  const percentageLeft = 100 - percentageUsed;
  let percentageColor = 'green';

  if (percentageUsed >= 50) {
    percentageColor = 'orange';
  }

  if (percentageUsed >= 90) {
    percentageColor = 'red';
  }

  return {
    totalSpace,
    spaceUsed,
    percentageUsed,
    percentageLeft,
    percentageColor,
  };
}

export function getNoRecordSupportMessage(): AlertData {
  return {
    title: i18n.t<string>('Error'),
    text: [i18n.t<string>('channel record not supported')],
    type: AlertType.INFO,
  };
}

export function getPVRSpaceWarningMessage(space: PvrSpace, inModal?: boolean): AlertData {
  if (space) {
    const usedStorage = getStoragePercentUsed(space);
    const fullStorage = usedStorage > PvrSpaceStatus.FULL;
    const lowStorage = usedStorage > PvrSpaceStatus.LOW && !fullStorage;
    if (fullStorage) {
      if (inModal) {
        return {
          title: i18n.t<string>('insufficient disk space title'),
          text: [i18n.t<string>('insufficient disk space description')],
          type: AlertType.INFO,
        };
      } else {
        return {
          title: i18n.t<string>('disk space full title'),
          text: [i18n.t<string>('disk space full description')],
          type: AlertType.INFO,
        };
      }
    } else if (lowStorage) {
      return {
        title: i18n.t<string>('disk space low title'),
        text: [i18n.t<string>('disk space low description')],
        type: AlertType.INFO,
      };
    }
  }
  return {} as AlertData;
}

export function getPercentWatched(recording: PvrRecording) {
  if (recording.bookmarkTime) {
    const timeUnit = getSinglePercentTimeUnit(recording);
    return Number(recording.bookmarkTime) / timeUnit;
  }
  return 0;
}

export function getSeasonAndEpisodeLabel({ seasonNum, subNum }: Pick<PvrRecording, 'seasonNum' | 'subNum'>) {
  let season = seasonNum;
  let episode = subNum;
  let outputString = ``;
  season = typeof season === 'undefined' ? '' : `S${season}`;
  episode = typeof episode === 'undefined' ? '' : `E${episode}`;
  outputString = `${season} ${episode}`;
  return outputString;
}

export function isCurrentlyRecording(recording: PvrRecording) {
  let beginTime = toMoment(recording.beginTime).subtract(Number(recording.beginOffset), 'minutes');
  let endTime = toMoment(recording.endTime).add(Number(recording.endOffset), 'minutes');
  let now = moment();

  return recording.status === PvrStatus.BeingRecorded && now.isAfter(beginTime) && now.isBefore(endTime);
}

export function isRecorded(recording: PvrRecording) {
  let endTime = toMoment(recording.endTime).add(Number(recording.endOffset), 'minutes');
  let now = moment();

  return (
    recording.status === PvrStatus.RecordedSuccessfully ||
    (recording.status === PvrStatus.BeingRecorded && now.isAfter(endTime))
  );
}

export function getLastEpisodeInSeries(seriesRecordings: PvrRecording) {
  const lastEpisodes = seriesRecordings.sortedSeasons[0].episodes;
  return lastEpisodes[lastEpisodes.length - 1];
}

export function getTheNextEpisodeToWatch(recording: PvrRecording, allSeriesRecordings: PvrRecording) {
  let currentSeason = allSeriesRecordings.sortedSeasons
    .map((seasonElement, idx) => {
      if (seasonElement.episodes.find((eps) => eps.pvrId === recording.pvrId)) {
        return { season: seasonElement, index: idx };
      } else {
        return undefined;
      }
    })
    .filter((x) => x)[0];

  let currentSeasonEpisodes = currentSeason ? currentSeason.season.episodes : [];
  let episodeIndex = currentSeasonEpisodes.indexOf(recording);

  if (currentSeasonEpisodes[episodeIndex + 1]) {
    return currentSeasonEpisodes[episodeIndex + 1];
  } else {
    if (currentSeason && allSeriesRecordings.sortedSeasons[currentSeason.index - 1]) {
      return allSeriesRecordings.sortedSeasons[currentSeason!.index - 1].episodes[0];
    }
    const firstEpisode = allSeriesRecordings.sortedSeasons[allSeriesRecordings.sortedSeasons.length - 1];
    if (firstEpisode && firstEpisode.episodes[0]) {
      return allSeriesRecordings.sortedSeasons[allSeriesRecordings.sortedSeasons.length - 1].episodes[0];
    } else {
      return undefined;
    }
  }
}

export function getPVRAssetForSearch(pvrRecording: PvrRecording): AltiboxAsset {
  return getPVRAsset(pvrRecording);
}

export function getPVRAsset(pvrRecording: PvrRecording): AltiboxAsset {
  const link = pvrRecording.seriesId
    ? `${routes.pvr.base}${routes.pvr.series}/${pvrRecording.channelId}/${pvrRecording.seriesId}`
    : `${routes.pvr.base}${routes.pvr.single}/${pvrRecording.pvrId}`;

  const playLink = pvrRecording.seriesId
    ? `${routes.pvr.base}${routes.pvr.series}${routes.pvr.play}/${pvrRecording.channelId}/${pvrRecording.seriesId}`
    : `${routes.pvr.base}${routes.pvr.single}${routes.pvr.play}/${pvrRecording.pvrId}`;

  const amountOfEpisodes = pvrRecording.subRecordings
    ? pvrRecording.subRecordings.length === 1
      ? `${pvrRecording.subRecordings.length} ${i18n.t<string>('episode')}`
      : `${pvrRecording.subRecordings.length} ${i18n.t<string>('episode')}${i18n.t<string>('plural ending')}`
    : `1 ${i18n.t<string>('episode')}`;

  let percentWatched = 0; // TODO: future implementation of continue watching for PVR
  return {
    id: pvrRecording.seriesId ? pvrRecording.seriesId : pvrRecording.pvrId,
    progress: percentWatched,
    description: pvrRecording.introduce,
    type: AltiboxAssetType.PVR,
    picture: pvrRecording.pictures ? pvrRecording.pictures[0].href : '',
    coverImage: pvrRecording.pictures ? pvrRecording.pictures[0].href : '',
    title: pvrRecording.pvrName,
    episodeTitle: pvrRecording.subName,
    subtitle: amountOfEpisodes,
    episodeSeasonTag:
      (pvrRecording.seasonNum ? ' S' + pvrRecording.seasonNum : '') +
      (pvrRecording.subNum ? ' E' + pvrRecording.subNum : ''),
    playLink: playLink,
    detailsLink: link,
    weight: 1,
    asset: pvrRecording,
  };
}

export function getRecordingDate(recording: PvrRecording) {
  let recordingDate = '';
  recordingDate = recording.beginTime;
  if (!isEmpty(recording.subRecordings)) {
    recordingDate = recording.subRecordings[0].beginTime;
  }
  return toMoment(recordingDate).toDate();
}

export function getRecordingTime(recording: PvrRecording) {
  let recordingDate = '';
  recordingDate = recording.beginTime;
  if (!isEmpty(recording.subRecordings)) {
    recordingDate = recording.subRecordings[0].beginTime;
  }
  const momentTime = toMoment(recordingDate);
  let hours: number | string = momentTime.hours();
  let minutes: number | string = momentTime.minutes();
  hours = hours < 10 ? (hours = '0' + hours) : hours;
  minutes = minutes < 10 ? (minutes = '0' + minutes) : minutes;
  return [hours.toString(), minutes.toString()];
}

export function getnPVRMediaId(channel: Channel) {
  return channel.physicalChannel && channel.physicalChannel.npvrRecCR && channel.physicalChannel.npvrRecCR.mi
    ? channel.physicalChannel.npvrRecCR.mi
    : channel.mediaId
    ? channel.mediaId
    : '';
}

export function isToday(recording: PvrRecording) {
  return isSameTimeInterval(recording, 'day');
}

export function isThisWeek(recording: PvrRecording) {
  return isSameTimeInterval(recording, 'week') && !isToday(recording);
}

export function isThisFortnight(recording: PvrRecording) {
  const recordingDate = getRecordingDate(recording);
  const inTwoWeeks = moment().add(2, 'weeks');
  return moment(recordingDate).isBetween(moment(), inTwoWeeks);
}

export function isSameTimeInterval(recording: PvrRecording, interval: unitOfTime.StartOf) {
  const recordingDate = getRecordingDate(recording);
  return moment(recordingDate).isSame(moment(), interval);
}

export function getDateTime(pvr: PvrRecording) {
  return toMomentReadableDateString(pvr.beginTime);
}

export function shouldShowExpired(recording: PvrRecording, channels: Channel[] | undefined): boolean {
  if (channels && recording.subRecordings) {
    return recording.subRecordings.some((subRecording) => isToExpireWithinSevenDays(subRecording, channels));
  }
  return false;
}

export function getPvrChannelRelation(pvr: PvrRecording): string {
  return pvr.channelName ? pvr.channelName : '';
}
export function shouldPVRNotExpire(asset: PvrRecording) {
  return Number(asset.deleteMode) === RecordingDeleteMode.Manual;
}

export function getPVRExpiration(
  asset: PvrRecording,
  channels: Channel[],
  hideBeforeSevenDays: boolean = false,
  getExpirationAsNumber?: boolean,
) {
  if (shouldPVRNotExpire(asset)) {
    return undefined;
  }
  const channel = channels ? channels.find((x) => x.contentId === getChannelid(asset)) : undefined;
  let daysUntilDeletion = 6912000 / 60 / 60 / 24;
  if (channel) {
    daysUntilDeletion =
      channel.physicalChannel && channel.physicalChannel.npvrRecCR && channel.physicalChannel.npvrRecCR.l
        ? Number(channel.physicalChannel.npvrRecCR.l) / 60 / 60 / 24
        : 24 * 70;
  }
  const recordStartTime = toMoment(asset.endTime ?? asset.latestPVRTask?.endTime);
  const expirationTime = recordStartTime.add(daysUntilDeletion, 'days');
  moment.locale(i18n.language);
  const now = moment(new Date());
  let diff = expirationTime.diff(now, 'days');
  if (diff > 7 && hideBeforeSevenDays) {
    return undefined;
  }
  return getExpirationAsNumber ? diff : expirationTime.fromNow();
}

export function isToExpireWithinSevenDays(recording: PvrRecording, channels: Channel[]) {
  let dayDiff = getPVRExpiration(recording, channels, false, true);
  return dayDiff && dayDiff > 0 && dayDiff <= 7;
}

export function isToExpireWitin(days: number, recording: PvrRecording, channels: Channel[]) {
  const dayDiff = getPVRExpiration(recording, channels, false, true);
  return Number(dayDiff) > 0 && Number(dayDiff) <= days;
}

export function getPvrGenres(recording: PvrRecording, pvrGenres: Genre[]) {
  if (isEmpty(pvrGenres)) {
    return undefined;
  }
  const genreIds = recording.genreIds ? recording.genreIds : [];
  const genres = genreIds.map((id) => pvrGenres.find((genre) => '' + genre.genreId === id)).filter((x) => x);

  return genres.map((x) => x!.genreName).join(', ');
}

export function getCustomerServiceLink(country: string): string {
  const isDK = country === CountryType.DK;
  return isDK ? footerURLs.dk.customerService : footerURLs.no.customerService;
}

export function getPvrPicture(recording: PvrRecording): string {
  return getImageUrlDimensions(recording.pictures[0].href, imageScaleValues.pvr);
}

export function getPvrLinkLocation(pvr: PvrRecording): string {
  let linkLocation = '';
  if (pvr.status === PvrStatus.NotRecorded) {
    linkLocation = `${routes.programarchive.base}${routes.programarchive.details}`;
    linkLocation += pvr.seriesId
      ? `${routes.programarchive.series}/${pvr.seriesId}/${pvr.programId}`
      : `${routes.programarchive.single}/${pvr.programId}`;
  } else {
    linkLocation = `${routes.pvr.base}`;
    linkLocation += pvr.isSingle
      ? `${routes.pvr.single}/${pvr.pvrId}`
      : `${routes.pvr.series}/${pvr.channelId}/${pvr.seriesId}`;
  }
  return linkLocation;
}

export function isDeleteModeAuto(deleteMode: RecordingDeleteMode | Pvr.DeleteMode): boolean {
  return Number(deleteMode) === RecordingDeleteMode.Auto;
}

export function isDeleteModeManual(deleteMode: RecordingDeleteMode | Pvr.DeleteMode): boolean {
  return Number(deleteMode) === RecordingDeleteMode.Manual;
}

export function getPvrDeletionSingleRecordingContextText(recordingToDelete: PvrRecording) {
  const isPartOfSeries = Boolean(recordingToDelete.subNum);

  if (isPartOfSeries) {
    return i18n.t<string>('are you sure to delete episode');
  } else if (!recordingToDelete.subNum && !recordingToDelete.seasonNum) {
    return i18n.t<string>('are you sure to delete the episode');
  }

  return i18n.t<string>('are you sure to delete recording');
}

/* ================================ */
/* ============ NEW PVR =========== */
/* ================================ */
export namespace PvrUtils {
  /* eslint-disable @typescript-eslint/no-shadow */
  export function isCurrentlyRecording({
    beginTime,
    endTime,
    beginOffset,
    endOffset,
    status,
  }: Pvr.Recording | Pvr.SingleRecording) {
    if (!beginTime || !endTime) {
      return false;
    }

    const begin = toMoment(beginTime).subtract(Number(beginOffset), 'minutes');
    const end = toMoment(endTime).add(Number(endOffset), 'minutes');
    const now = moment();

    return status === Pvr.Status.BeingRecorded && now.isAfter(begin) && now.isBefore(end);
  }

  export function getImage(recording: Pvr.Recording | Pvr.SingleRecording | Pvr.SeriesRecording) {
    if (recordingIsSeries(recording)) {
      const pictures = recording.latestPVRTask.pictures;
      return pictures && !isEmpty(pictures)
        ? getImageUrlDimensions(pictures[0].href, imageScaleValues.pvr)
        : missingCover;
    }

    return recording.pictures && !isEmpty(recording.pictures)
      ? getImageUrlDimensions(recording.pictures[0].href, imageScaleValues.pvr)
      : missingCover;
  }

  export function getGenres(genreIds: string[], genres: Genre[]) {
    return genreIds
      .map((id) => genres.find((genre) => '' + genre.genreId === id))
      .filter(Boolean)
      .map((genre) => genre!.genreName);
  }

  export function getChannelLogo(channel: Channel | undefined) {
    if (!channel) {
      return '';
    }

    return getChannelLogoUrl(channel, ChannelLogoType.CROPPED) ?? '';
  }

  export function getChannel(channelId: string, channels: Channel[]) {
    return channels.find((channel) => channel.contentId === channelId);
  }

  export function getRecordingDate(recording: Pvr.Recording) {
    return toMomentReadableDateString(recording.beginTime ?? recording.effectivetime ?? '', true);
  }

  export function getId(recording: Pvr.UnionRecording) {
    if (recordingIsSingle(recording)) {
      return recording.pvrId;
    }

    if (!recordingIsSeries(recording)) {
      return recording.pvrId;
    }

    return recording.periodPVRTaskId ?? recording.latestPVRTask.pvrId;
  }

  export function addCustomProperties(list: Pvr.UnionRecording[]) {
    return list.map((item) => {
      if ('seriesType' in item && Number(item.seriesType) === SeriesType.SeriesId && item.latestPVRTask) {
        return { ...item, isSingle: false };
      } else {
        return {
          ...item,
          isSingle: true,
          pvrName: recordingIsSeries(item) ? item.periodPVRTaskName : item.pvrName ?? item.periodPVRTaskName,
        };
      }
    }) as Pvr.UnionRecording[];
  }

  export function groupBySeason(list: Pvr.Recording[]) {
    return list.reduce<{ [seasonNum: string]: Pvr.Recording[] }>((acc, recording) => {
      if (!recording.seasonNum) {
        return acc;
      }

      return {
        ...acc,
        [recording.seasonNum]: [...(acc[recording.seasonNum] || []), recording],
      };
    }, {});
  }

  export function getEpisodeBySeasonNum(list: Pvr.Recording[], seasonNum: string) {
    return list.filter((recording) => {
      return recording.seasonNum === seasonNum;
    });
  }

  export function getPercentWatched(recording: Pvr.Recording) {
    if (recording.bookmarkTime) {
      const timeUnit = getSinglePercentTimeUnit(recording);
      return Number(recording.bookmarkTime) / timeUnit;
    }
    return 0;
  }
  /* eslint-enable @typescript-eslint/no-shadow */
}

export function splitBetweenSeriesAndMovies(recordings: PvrRecording[]) {
  const [series, movies] = recordings.reduce<PvrRecording[][]>(
    (acc, recording) => {
      if (pvrIsSeries(recording)) {
        acc[0].push(recording);
        return acc;
      }

      acc[1].push(recording);
      return acc;
    },
    [[], []],
  );

  return [series, movies] as const;
}

export function splitBetweenSingleAndSeries(recordings: PvrRecording[]) {
  const [single, series] = recordings.reduce<PvrRecording[][]>(
    (acc, recording) => {
      if (recording.isSingle) {
        acc[0].push(recording);
        return acc;
      }

      acc[1].push(recording);
      return acc;
    },
    [[], []],
  );

  return [single, series] as const;
}

export function filterContinueWatching(recordings: PvrRecording[], bookmarks: Bookmarks) {
  return recordings.filter((recording) => {
    return Boolean(
      bookmarks.bookmarkList.find(
        (bookmark) => bookmark.contentId === recording.latestPVRTask?.pvrId || recording.pvrId,
      ),
    );
  });
}

export function pvrIsSeries(recording: PvrRecording) {
  return recording.hasOwnProperty('seasonNum') || recording.latestPVRTask?.hasOwnProperty('seasonNum');
}

export function filterDeletingSoon(days: number, recordings: PvrRecording[], channels: Channel[]) {
  return recordings.filter((recording) => {
    return Boolean(isToExpireWitin(days, recording, channels));
  });
}

export function getRecordingsForGenreId(recordings: PvrRecording[], genreId: string) {
  return recordings.filter((asset) => {
    return (
      asset.genreIds?.some((id) => id === genreId) ||
      asset.latestPVRTask?.genreIds.some((id) => id === genreId) ||
      false
    );
  });
}

export function getChannelForRecording(channelId: string, channels: Channel[] | undefined) {
  return channels?.find((channel) => channel.contentId === channelId);
}

export function getImageUrlForRecording(recording: PvrRecording) {
  if (recording.pictures && !isEmpty(recording.pictures)) {
    return getImageUrlDimensions(recording.pictures[0].href, imageScaleValues.pvr);
  }

  return missingCover;
}

export function reduceScheduledRecordings(recordings: Pvr.UnionRecording[]) {
  return recordings.reduce((acc, recording) => {
    if (recordingIsSeries(recording)) {
      return [...acc, ...recording.pvrList];
    }

    return [...acc, recording];
  }, [] as (Pvr.Recording | Pvr.SingleRecording)[]);
}

export function sortScheduledRecordings(recordings: (Pvr.Recording | Pvr.SingleRecording)[]) {
  return recordings.sort((a, b) => {
    return Number(a.beginTime) - Number(b.beginTime);
  });
}
