import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import { toggleRecordingDeleteMode } from '../../api/recording';
import { SvgClose } from '../../icons';
import { Pvr } from '../../interfaces';
import { useCancelRecording } from '../../queries/recordings/mutations';
import { recordingIsSingle } from '../../typeGuards';
import { isDeleteModeAuto, PvrUtils } from '../../utils/pvrUtils';
import { CircleLoader } from '../CircleLoader';
import Modal from '../Modal';
import { ModalHeroBackground } from '../OptionsModal';
import styles from './styles/RecordingModal.module.scss';

type DeleteToggleButtonProps = {
  isRecording: boolean;
  deleteToggleChecked: boolean;
  changeDeleteMode: () => void;
};

export const DeleteToggleButton: React.FC<DeleteToggleButtonProps> = ({ deleteToggleChecked, changeDeleteMode }) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.deleteToggle}
      onClick={() => {
        changeDeleteMode();
      }}
    >
      {t('delete automatically')}
      <Toggle checked={deleteToggleChecked} icons={false} readOnly={true} />
    </div>
  );
};
interface RecordingButtonProps {
  children: React.ReactNode;
  callback: () => void;
  shouldShow?: boolean;
  isLoading?: boolean;
}

interface RecordingModalProps {
  isOpen: boolean;
  hideModal: () => void;
  recording: Pvr.Recording | Pvr.SingleRecording;
}

export function RecordingModal({ isOpen, hideModal, recording }: RecordingModalProps) {
  const [deleteMode, setDeleteMode] = useState(recording.deleteMode ?? Pvr.DeleteMode.Auto);

  const { t } = useTranslation();

  function changeDeleteMode() {
    const newDeleteMode = isDeleteModeAuto(deleteMode) ? Pvr.DeleteMode.Manual : Pvr.DeleteMode.Auto;
    setDeleteMode(newDeleteMode);

    toggleRecordingDeleteMode(recording, newDeleteMode);
  }

  return (
    <Modal
      portalClassName={'options-modal'}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      onRequestClose={hideModal}
      onAfterClose={hideModal}
      className="aib-modal"
    >
      <ModalHeroBackground imageUrl={PvrUtils.getImage(recording)} title={recording.pvrName} />

      <button className={styles.buttonClose} onClick={hideModal} children={<SvgClose />} />

      <main className={styles.mainContainer}>
        <div className={styles.optionsButtonWrapper}>
          <RecordingButtonList recording={recording} hideModal={hideModal} />
          <DeleteToggleButton
            isRecording={true}
            changeDeleteMode={changeDeleteMode}
            deleteToggleChecked={isDeleteModeAuto(deleteMode)}
          />
        </div>

        <p className={styles.modalFooterNote}>
          {t('delete automatically description before tack')} "<span className="aib-icon">&</span>{' '}
          <span className={styles.footerNoteAfterTack}>" {t('delete automatically description after tack')}</span>
        </p>
      </main>
    </Modal>
  );
}

interface RecordingButtonListProps {
  recording: Pvr.Recording | Pvr.SingleRecording;
  hideModal: () => void;
}

export function RecordingButtonList({ recording, hideModal }: RecordingButtonListProps) {
  const { mutate, isLoading } = useCancelRecording({
    callback: () => hideModal(),
  });

  const { t } = useTranslation('pvr');

  if (isLoading) {
    return <CircleLoader />;
  }

  return (
    <>
      {recordingIsSingle(recording) && (
        <RecordingButton callback={() => mutate({ recording, onlyCancelEpisode: true })} shouldShow>
          {t('stop recording')}
        </RecordingButton>
      )}

      {!recordingIsSingle(recording) && (
        <RecordingButton callback={() => mutate({ recording })} shouldShow>
          {t('stop series recording')}
        </RecordingButton>
      )}
    </>
  );
}

export function RecordingButton({ children, callback, shouldShow = false }: RecordingButtonProps) {
  if (!shouldShow) {
    return null;
  }

  return (
    <button
      className={styles.optionButton}
      onClick={() => {
        callback();
      }}
    >
      {children}
    </button>
  );
}
