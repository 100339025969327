import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PvrAsset } from '../../../components/Assets/PvrAsset';
import { PvrLayout } from '../../../components/Pvr/Layout';
import { SortDropdown } from '../../../components/SortDropdown';
import Spinner from '../../../components/Spinner';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { useRecordingsSwimlane } from '../../../queries/recordings/queries';
import { Recording } from '../../../queries/recordings/types';
import styles from './style.module.scss';

const SORT_OPTIONS = [
  { label: 'latest', value: Recording.Sort.Action.Newest },
  { label: 'oldest', value: Recording.Sort.Action.Oldest },
  { label: 'A - Z', value: Recording.Sort.Action.Alphabetically },
];

export default function PvrCollection() {
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0].value);
  const { genreSlug } = useParams<{ genreSlug: string }>();
  const { data, isLoading, isEmpty } = useRecordingsSwimlane(genreSlug, sortBy);
  const { t, i18n } = useTranslation('pvr');

  useScrollToTop();

  const language = i18n.language;
  const translatedTitle = typeof data?.title === 'string' ? t(data?.title, { count: 2 }) : data?.title[language];

  const rightActions = <SortDropdown onChange={setSortBy} options={SORT_OPTIONS} />;

  return (
    <PvrLayout title={translatedTitle} rightActions={rightActions}>
      <div className={styles.container}>
        {isLoading && <Spinner wrapInContainer />}

        {isEmpty && !isLoading && (
          <div className={styles.container}>
            <p className={styles.empty}>{t('your recordings will be available here')}</p>
          </div>
        )}

        <div className={styles.grid}>
          {data?.groups?.map((group) => (
            <PvrAsset key={group.pvrId} group={group} />
          ))}
        </div>
      </div>
    </PvrLayout>
  );
}
