import { useEffect, useMemo, useState } from 'react';
import 'react-dropdown/style.css';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer';
import Spinner from '../../components/Spinner';
import { searchConfig } from '../../config';
import { Channel, RootState, TVGuideChannelFilter } from '../../interfaces';
import StickyHeader from '../app/StickyHeader';

import { ChannelTvGuideGroup } from '../../components/ChannelTvGuide/ChannelTvGuideGroup';
import { useSortedChannels } from '../../queries/channels/queries';
import { SlideInDetails } from '../details';
import { TvGuideFilters } from './TvGuideFilters';
import { getRecordings } from '../tv/actions';

export default function TvGuide() {
  const detailsPanel = useSelector((state: RootState) => state.detailsReducer.detailsPanel);
  const isSlidingOut = useSelector((state: RootState) => state.detailsReducer.isSlidingOut);
  const isGuest = useSelector((state: RootState) => state.authReducer.isGuest);
  const dispatch = useDispatch();

  const [currentDate, setCurrentDate] = useState(new Date());

  const [channelsFilter, setChannelsFilter] = useState<TVGuideChannelFilter>(TVGuideChannelFilter.ALL);
  const [genreFilter, setGenreFilter] = useState<string[]>([]);
  const [timeFilter, setTimeFilter] = useState(0);

  const { data: sortedChannels, isLoading } = useSortedChannels();

  // Remove once we use React Query for recordings for playbills
  useEffect(() => {
    if (!isGuest) {
      dispatch(getRecordings());
    }
  }, [dispatch, isGuest]);

  const hasFavorites = useMemo(() => {
    return sortedChannels?.some((value) => value.isfavorited === '1');
  }, [sortedChannels]);

  const splitChannels = useMemo(() => splitChannelsInChunks(sortedChannels), [sortedChannels]);

  function splitChannelsInChunks(channels: Channel[] | undefined, chunkSize = 8) {
    if (!channels) {
      return undefined;
    }

    const arr: Channel[][] = [];

    const favorited = channels.filter((channel) => channel.isfavorited === '1');
    const notFavorited = channels.filter((channel) => channel.isfavorited !== '1');
    const sorted = [...favorited, ...notFavorited];

    for (let i = 0; i < sorted.length; i += chunkSize) {
      const chunk = sorted.slice(i, i + chunkSize);
      arr.push(chunk);
    }

    return arr;
  }

  return (
    <>
      {detailsPanel && (
        <SlideInDetails
          key={`${detailsPanel.routeProps.parentId}${detailsPanel.routeProps.childId}${detailsPanel.displayType}`}
          isSlidingOut={isSlidingOut}
          {...detailsPanel}
        />
      )}

      <div className="main-frame">
        <Helmet>
          <title>Tv-guide</title>
        </Helmet>
        <StickyHeader searchConfig={searchConfig.tvguide} />

        <TvGuideFilters
          channelsFilter={channelsFilter}
          setChannelsFilter={setChannelsFilter}
          genreFilter={genreFilter}
          setGenreFilter={setGenreFilter}
          timeFilter={timeFilter}
          setTimeFilter={setTimeFilter}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          hasFavorites={hasFavorites}
        />

        <div className="tv-guide">
          <div className="playbill-container">
            {genreFilter &&
              splitChannels?.map((channelGroup) => (
                <ChannelTvGuideGroup
                  key={channelGroup.map((channel) => channel.contentId).join(',')}
                  channels={channelGroup}
                  genreFilter={genreFilter}
                  timeFilter={timeFilter}
                  channelsFilter={channelsFilter}
                  currentDate={currentDate}
                />
              ))}
          </div>
        </div>

        {isLoading && <Spinner wrapInContainer={true} />}

        <Footer />
      </div>
    </>
  );
}
