import { Bookmark } from './../../interfaces';
import { sortBy } from 'lodash';
import moment from 'moment';
import { pvrSettings } from '../../config';
import i18n from '../../i18n';
import { Channel } from '../../interfaces';
import { convertHuaweiDateToDateObject } from '../../utils/commonUtils';
import { addMinutes, subtractMinutes, toMoment } from '../../utils/huaweiUtils';
import { Genre } from '../genres/types';
import { Recording } from './types';
import { PartialBy } from '../../utils/typescriptUtils';

interface ChannelsAndGenres {
  channels?: Channel[];
  genres?: Genre.All.Item[];
}

/* ================================================== */
/*                   GROUPS                           */
/* ================================================== */
export function groupRecordings(recordings: Recording.Query.Union[], { channels, genres }: ChannelsAndGenres) {
  const groupList = recordings.reduce<Recording.Group.List>((acc, curr) => {
    return {
      ...acc,
      [isSeries(curr) ? curr.seriesId : curr.pvrId]: generateGroup(curr, { channels, genres }),
    };
  }, {});

  const withEpisodes = ingestEpisodes(groupList, recordings);
  const withUnwatchedCount = ingestUnwatchedCount(withEpisodes);
  return ingestLowestExpirationTime(withUnwatchedCount, channels);
}

function generateGroup(
  recording: Recording.Query.Union,
  { channels, genres }: ChannelsAndGenres,
): Recording.Group.Union {
  const genreNames = recording.genreIds?.reduce<string[]>((acc, id) => {
    const genre = genres?.find(({ genreId }) => genreId === id);
    return genre ? [...acc, genre.genreName] : acc;
  }, []);

  const channel = channels?.find(({ contentId }) => contentId === recording.channelId);

  const commonProperties = {
    pvrName: recording.pvrName,
    pvrId: recording.pvrId,
    pictures: recording.pictures,
    genreIds: recording.genreIds,
    channel,
    genreNames: genreNames?.length ? genreNames : undefined,
    unwatchedCount: 0,
    lowestExpirationTime: undefined,
    progress: 0,
  };

  if (isSeries(recording)) {
    return {
      ...commonProperties,
      seriesId: recording.seriesId,
      periodPVRTaskId: recording.periodPVRTaskId,
      episodes: [],
      seasons: [],
      type: 'series',
    };
  }

  return {
    ...commonProperties,
    asset: { ...recording, progress: getPercentWatched(recording) },
    type: 'movie',
  };
}

function ingestProgress(recordings: Recording.Query.Series[]): Recording.Query.Series[] {
  return recordings.map((recording) => {
    return {
      ...recording,
      progress: getPercentWatched(recording),
    };
  });
}

function ingestEpisodes(groupList: Recording.Group.List, recordings: Recording.Query.Union[]): Recording.Group.Union[] {
  return Object.values(groupList).map((group) => {
    if (group.type === 'series') {
      const allEpisodes = recordings.filter((recording): recording is Recording.Query.Series => {
        return isSeries(recording) ? recording.seriesId === group.seriesId : false;
      });

      const withProgress = ingestProgress(allEpisodes);

      return {
        ...group,
        episodes: withProgress,
        seasons: groupBySeason(withProgress),
      };
    }

    return group;
  });
}

function ingestLowestExpirationTime(
  groups: Recording.Group.Union[],
  channels: Channel[] | undefined,
): Recording.Group.Union[] {
  return groups.map((group) => {
    if (groupIsSeries(group)) {
      const daysLeft = [...group.episodes].map((episode) => getExpiration(episode, channels)).filter((day) => day >= 0);
      const lowestExpirationTimeSeries = Math.min(...daysLeft);
      return { ...group, lowestExpirationTime: lowestExpirationTimeSeries };
    }

    const lowestExpirationTimeSingle = getExpiration(group.asset, channels);
    return { ...group, lowestExpirationTime: lowestExpirationTimeSingle };
  });
}

function ingestUnwatchedCount(groups: Recording.Group.Union[]): Recording.Group.Union[] {
  return groups.map((group) => {
    const unwatchedCount = getUnwatchedCount(group);
    return { ...group, unwatchedCount };
  });
}

function getUnwatchedCount(group: Recording.Group.Union) {
  const timeLimit = moment().subtract(pvrSettings.newBadgeTimeLimit, 'day');

  if (groupIsSeries(group)) {
    return group.episodes
      .map((episode) => {
        const episodeRecordedTime = toMoment(episode.beginTime);
        if (timeLimit.isBefore(episodeRecordedTime)) {
          return Number(getPercentWatched(episode) < 1);
        }

        return 0;
      })
      .reduce((acc, curr) => {
        return acc + curr;
      }, 0);
  }

  const movieRecordedTime = toMoment(group.asset.beginTime);
  if (timeLimit.isBefore(movieRecordedTime)) {
    return Number(getPercentWatched(group.asset) < 1);
  }

  return 0;
}

export function ingestUpdateTime(groups: Recording.Group.Union[], bookmarks: Bookmark[] | undefined) {
  if (!bookmarks) {
    return groups;
  }

  return groups.map<Recording.Group.Union>((group) => {
    if (groupIsSeries(group)) {
      const episodeIds = [...group.episodes].reverse().map((episode) => episode.pvrId);
      const foundBookmark = bookmarks.find((bookmark) => episodeIds.includes(bookmark.contentId));

      return {
        ...group,
        updateTime: foundBookmark?.updateTime,
      };
    }

    const foundBookmark = bookmarks.find((bookmark) => bookmark.contentId === group.pvrId);

    return {
      ...group,
      updateTime: foundBookmark?.updateTime,
    };
  });
}

export function groupBySeason(list: Recording.Query.Series[]): Recording.Group.Season[] {
  const groupedSeasons = list.reduce<{ [seasonNum: string]: Recording.Query.Series[] }>((acc, recording) => {
    if (!recording.seasonNum) {
      return acc;
    }

    return {
      ...acc,
      [recording.seasonNum]: [...(acc[recording.seasonNum] || []), recording],
    };
  }, {});

  return Object.entries(groupedSeasons).map(([seasonNum, recordings]) => {
    return {
      pvrName: recordings[0].pvrName,
      pictures: recordings[0].pictures,
      seasonNum,
      episodes: recordings,
    };
  });
}

export function ingestNextEpisode(
  groups: Recording.Group.Union[],
  recordings: Recording.Query.Union[] | undefined,
): Recording.Group.Union[] {
  return groups.map((group) => {
    if (!groupIsSeries(group)) {
      return group;
    }

    const nextEpisode = getNextEpisode(group.episodes[0], recordings);

    if (!nextEpisode) {
      return group;
    }

    return { ...group, episodes: [nextEpisode, ...group.episodes] };
  });
}

export function getAllEpisodes(recordings: Recording.Query.Union[] | undefined, seriesId: string) {
  const filteredEpisodes = recordings?.filter((recording) => isSeries(recording) && recording.seriesId === seriesId);
  return filteredEpisodes?.sort((a, b) => {
    if (
      !isSeries(a) ||
      !isSeries(b) ||
      a.seasonNum === undefined ||
      a.subNum === undefined ||
      b.seasonNum === undefined ||
      b.subNum === undefined
    ) {
      return -1;
    }
    return Number(a.seasonNum) - Number(b.seasonNum) || Number(a.subNum) - Number(b.subNum);
  });
}

export function filterContinueWatching(recordings: Recording.Query.Union[]) {
  return recordings.filter((recording) => {
    const percent = getPercentWatched(recording);
    return percent > 1;
  });
}

export function getNextEpisode(
  currentEpisode: Recording.Query.Series,
  recordings: Recording.Query.Union[] | undefined,
) {
  if (!recordings) {
    return undefined;
  }

  const episodes = getAllEpisodes(recordings, currentEpisode.seriesId);

  if (!episodes) {
    return undefined;
  }

  if (currentEpisode.progress && currentEpisode.progress > 97) {
    const nextEpisodeIndex = episodes.findIndex((ep) => isSeries(ep) && isSameEpisode(currentEpisode, ep)) + 1;
    return episodes[nextEpisodeIndex] as Recording.Query.Series;
  }

  return undefined;
}

function isSameEpisode(a: Recording.Query.Series, b: Recording.Query.Series) {
  return a.seasonNum === b.seasonNum && a.subNum === b.subNum;
}

/* ================================================== */
/*                    SORTING                         */
/* ================================================== */
/**
 *
 * @param groups
 * @param action
 * @returns Sorted array based on the action supplied
 */
export function sortGroups(groups: Recording.Group.Union[], action: Recording.Sort.Action) {
  switch (action) {
    case Recording.Sort.Action.Newest:
      return sortGroupsByNewest(groups);

    case Recording.Sort.Action.Oldest:
      return sortGroupsByNewest(groups).reverse();

    case Recording.Sort.Action.Alphabetically:
      return groups.sort((a: Recording.Group.Union, b: Recording.Group.Union) => {
        return a.pvrName.localeCompare(b.pvrName);
      });
  }
}

function sortGroupsByNewest(groups: Recording.Group.Union[]): Recording.Group.Union[] {
  const sortedEpisodes = groups.map((group) => {
    if (groupIsSeries(group)) {
      return {
        ...group,
        episodes: sortEpisodes(group.episodes),
      };
    }

    return group;
  });

  return sortBy(sortedEpisodes, (group) => {
    const beginTime = groupIsSeries(group) ? group.episodes[0].beginTime : group.asset.beginTime;
    return convertHuaweiDateToDateObject(beginTime);
  }).reverse();
}

function sortEpisodes(episodes: Recording.Query.Series[]) {
  return sortBy(episodes, (episode) => {
    return convertHuaweiDateToDateObject(episode.beginTime);
  }).reverse();
}

function sortEpisodesAlphabetically(episodes: Recording.Query.Series[]) {
  return episodes.sort((a: Recording.Query.Series, b: Recording.Query.Series) => {
    return Number(a.subNum) - Number(b.subNum);
  });
}

/**
 *
 * @param groups
 * @param action
 * @returns Sorted array based on the action supplied
 */
export function sortSeasons(
  seasons: Recording.Group.Season[],
  action: Recording.Sort.Action = Recording.Sort.Action.Newest,
) {
  switch (action) {
    case Recording.Sort.Action.Newest:
      return sortSeasonsByNewest(seasons);

    case Recording.Sort.Action.Oldest:
      return sortSeasonsByNewest(seasons).reverse();

    case Recording.Sort.Action.Alphabetically:
      return seasons.sort((a: Recording.Group.Season, b: Recording.Group.Season) => {
        return a.pvrName.localeCompare(b.pvrName);
      });

    default:
      return sortSeasonsByNewest(seasons);
  }
}

function sortSeasonsByNewest(seasons: Recording.Group.Season[]): Recording.Group.Season[] {
  const sortedEpisodes = seasons.map(sortSeasonByNewest);

  return sortBy(sortedEpisodes, (season) => {
    return convertHuaweiDateToDateObject(season.episodes[0].beginTime);
  }).reverse();
}

/**
 *
 * @param groups
 * @param action
 * @returns Season with sorted episode array based on the action supplied
 */
export function sortSeason(
  season: Recording.Group.Season,
  action: Recording.Sort.Action = Recording.Sort.Action.Newest,
) {
  switch (action) {
    case Recording.Sort.Action.Newest:
      return sortSeasonByNewest(season);

    case Recording.Sort.Action.Oldest:
      return sortSeasonByOldest(season);

    case Recording.Sort.Action.Alphabetically:
      return sortSeasonAlphabetically(season);

    default:
      return sortSeasonByNewest(season);
  }
}

function sortSeasonByNewest(season: Recording.Group.Season): Recording.Group.Season {
  return {
    ...season,
    episodes: sortEpisodes(season.episodes),
  };
}

function sortSeasonByOldest(season: Recording.Group.Season): Recording.Group.Season {
  return {
    ...season,
    episodes: sortEpisodes(season.episodes).reverse(),
  };
}

function sortSeasonAlphabetically(season: Recording.Group.Season): Recording.Group.Season {
  return {
    ...season,
    episodes: sortEpisodesAlphabetically(season.episodes),
  };
}

function sortAlphabetically(groups: Recording.Group.Union[]) {
  return groups.sort((a: Recording.Group.Union, b: Recording.Group.Union) => {
    return a.pvrName.localeCompare(b.pvrName);
  });
}

function sortContinueWatching(groups: Recording.Group.Union[]) {
  return groups.sort((a, b) => Number(b.updateTime) - Number(a.updateTime));
}

function sortExpirationTime(groups: Recording.Group.Union[]) {
  return groups.sort((a, b) => Number(a.lowestExpirationTime) - Number(b.lowestExpirationTime));
}

export function sortSwimlane(
  swimlane: Recording.Swimlane.Item | undefined,
  action: Recording.Sort.Action = Recording.Sort.Action.Newest,
) {
  if (!swimlane?.groups) {
    return swimlane;
  }

  switch (action) {
    case Recording.Sort.Action.Newest:
      return {
        ...swimlane,
        groups: sortGroupsByNewest(swimlane.groups),
      };

    case Recording.Sort.Action.Oldest:
      return {
        ...swimlane,
        groups: sortGroupsByNewest(swimlane.groups).reverse(),
      };

    case Recording.Sort.Action.Alphabetically:
      return {
        ...swimlane,
        groups: sortAlphabetically(swimlane.groups),
      };

    case Recording.Sort.Action.ContinueWatching:
      return {
        ...swimlane,
        groups: sortContinueWatching(swimlane.groups),
      };

    case Recording.Sort.Action.ExpirationTime:
      return {
        ...swimlane,
        groups: sortExpirationTime(swimlane.groups),
      };
  }
}

/* ================================================== */
/*                CONTINUE WATCHING                   */
/* ================================================== */
type GetPercentWatched = PartialBy<
  Pick<Recording.Query.Union, 'beginTime' | 'endTime' | 'beginOffset' | 'endOffset' | 'bookmarkTime'>,
  'beginOffset' | 'endOffset'
>;

export function getPercentWatched({ bookmarkTime, beginTime, endTime, beginOffset, endOffset }: GetPercentWatched) {
  if (bookmarkTime) {
    const timeUnit = getTimeUnit({ beginTime, endTime, beginOffset, endOffset });
    return Number(bookmarkTime) / timeUnit;
  }
  return 0;
}

function getTimeUnit({ beginTime, endTime, beginOffset, endOffset }: Omit<GetPercentWatched, 'bookmarkTime'>) {
  let programStart = beginTime;
  let programEnd = endTime;

  if (beginOffset) {
    programStart = subtractMinutes(beginTime, Number(beginOffset));
  }

  if (endOffset) {
    programEnd = addMinutes(endTime, Number(endOffset));
  }

  const durationInSeconds = toMoment(programEnd).diff(toMoment(programStart), 'seconds');
  return durationInSeconds / 100;
}

/* ================================================== */
/*                 DELETING SOON                      */
/* ================================================== */
export function filterDeletingSoon(recordings: Recording.Query.Union[], channels: Channel[] | undefined, days = 14) {
  if (!channels) {
    return;
  }
  return recordings.filter((recording) => {
    return Boolean(isToExpireWithin(days, recording, channels));
  });
}

function isToExpireWithin(days: number, recording: Recording.Query.Union, channels: Channel[]) {
  const dayDiff = getExpiration(recording, channels);
  return Number(dayDiff) > 0 && Number(dayDiff) <= days;
}

export function getExpiration(recording: Recording.Query.Union, channels: Channel[] | undefined) {
  if (Number(recording.deleteMode) === Recording.DeleteMode.Manual) {
    return 0;
  }

  const channel = channels?.find((cnl) => cnl.contentId === recording.channelId);
  const daysUntilDeletion = getDaysUntilDeletion(channel);

  const recordStartTime = toMoment(recording.endTime);
  const expirationTime = recordStartTime.add(daysUntilDeletion, 'days');

  moment.locale(i18n.language);
  const now = moment(new Date());

  return expirationTime.diff(now, 'hours') / 24;
}

function getDaysUntilDeletion(channel: Channel | undefined) {
  if (channel) {
    return channel.physicalChannel && channel.physicalChannel.npvrRecCR && channel.physicalChannel.npvrRecCR.l
      ? Number(channel.physicalChannel.npvrRecCR.l) / 60 / 60 / 24
      : 24 * 70;
  }

  // 80 days
  return 6912000 / 60 / 60 / 24;
}

/* ================================================== */
/*                 MOVIES & SERIES                    */
/* ================================================== */
export function filterSeries(groups: Recording.Group.Union[]) {
  return groups.filter((group) => groupIsSeries(group));
}

export function filterMovies(groups: Recording.Group.Union[]) {
  return groups.filter((group) => !groupIsSeries(group));
}

/* ================================================== */
/*                   SWIMLANES                        */
/* ================================================== */
const MIN_LENGTH = pvrSettings.swimlaneMinLength;

export function generateSwimlane(
  title: Recording.Swimlane.Titles,
  groups: Recording.Group.Union[] | undefined,
  action: Recording.Sort.Action = Recording.Sort.Action.Newest,
) {
  const slug = Recording.Swimlane.Slugs[title];
  const swimlaneObj: Recording.Swimlane.Item = { title, slug, groups, order: 999 };

  switch (title) {
    case Recording.Swimlane.Titles.AllRecordings:
      swimlaneObj.order = 0;
      break;
    case Recording.Swimlane.Titles.ContinueWatching:
      swimlaneObj.order = 1;
      break;
    case Recording.Swimlane.Titles.DeletingSoon:
      swimlaneObj.order = 2;
      break;
    case Recording.Swimlane.Titles.Series:
      swimlaneObj.order = 3;
      break;
    case Recording.Swimlane.Titles.Movies:
      swimlaneObj.order = 4;
      break;
    default:
      return swimlaneObj;
  }

  return sortSwimlane(swimlaneObj, action);
}

export function generateSeriesSwimlane(groups: Recording.Group.Union[]) {
  const series = groups.filter((group) => groupIsSeries(group));
  if (series.length < MIN_LENGTH) {
    return undefined;
  }
  return generateSwimlane(Recording.Swimlane.Titles.Series, series);
}

export function generateMoviesSwimlane(groups: Recording.Group.Union[]) {
  const movies = groups.filter((group) => !groupIsSeries(group));
  if (movies.length < MIN_LENGTH) {
    return undefined;
  }
  return generateSwimlane(Recording.Swimlane.Titles.Movies, movies);
}

const MOVIES_AND_SERIES_IDS =
  '2095, 2102, 2104, 2106, 2110, 2111, 2112, 2116, 2117, 2121, 2122, 2123, 2124, 2126, 2131';

export function generateGenreSwimlanes(groups: Recording.Group.Union[], genres: Genre.Epg.Item[] | undefined) {
  if (!genres) {
    return;
  }

  const generatedSwimlanes = genres.reduce<Recording.Swimlane.Item[]>((acc, { genreIds, title }) => {
    // Ignore movies and series
    if (genreIds === MOVIES_AND_SERIES_IDS) {
      const seriesSwimlane = generateSeriesSwimlane(groups);
      const moviesSwimlane = generateMoviesSwimlane(groups);
      return [...acc, ...(seriesSwimlane ? [seriesSwimlane] : []), ...(moviesSwimlane ? [moviesSwimlane] : [])];
    }

    const groupsForGenre = getGroupsForGenreId(groups, genreIds);

    if (groupsForGenre.length < MIN_LENGTH) {
      return acc;
    }

    return [
      ...acc,
      {
        title,
        groups: groupsForGenre,
        slug: genreIds.split(', ').join('-'),
        order: getEpgGenreOrder(genreIds),
      },
    ];
  }, []);

  return generatedSwimlanes.sort((a, b) => a.order - b.order);
}

export function getGroupsForGenreId(groups: Recording.Group.Union[], genreIds: string) {
  const epgGenreIds = genreIds.split(', ');
  return groups.filter((group) => {
    return group.genreIds?.some((id) => epgGenreIds.includes(id));
  });
}

enum EpgGenreOrder {
  ChildrenAndYouth = '2097, 2098, 2099, 2100, 2105, 2128',
  Sport = '2125, 2109, 2107, 2108',
  Documentary = '2101',
  Entertainment = '2127, 2118, 2120, 2113',
  Lifestyle = '2114, 2115',
  Science = '2130',
  News = '2119, 2096',
}

export function getEpgGenreOrder(ids: string) {
  return Object.values(EpgGenreOrder).indexOf(ids as EpgGenreOrder) + Object.values(Recording.Swimlane.Titles).length;
}

/* ================================================== */
/*                  TYPEGUARDS                        */
/* ================================================== */
export function isSeries(recording: Recording.Query.Union): recording is Recording.Query.Series {
  return 'seriesId' in recording;
}

export function groupIsSeries(group: Recording.Group.Union): group is Recording.Group.Series {
  return group.type === 'series';
}

export function groupIsSeason(group: Recording.Group.Union | Recording.Group.Season): group is Recording.Group.Season {
  return 'seasonNum' in group && 'episodes' in group;
}
