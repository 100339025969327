import React, { Component } from 'react';
import './style.scss';
import { Cast, GAaction } from '../../../interfaces';
import { Link } from 'react-router-dom';
import { routes } from '../../../config';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';

interface Props {
  cast: Cast;
  backLink?: string;
  last?: boolean;
  textLink?: boolean;
}

class AltiboxCast extends Component<Props, {}> {
  tracking = AnalyticsTracking.getInstance();

  trackCast(name: string, id: string) {
    this.tracking.trackCurrentService(GAaction.openCastAndCrewList, name + ' - ' + id);
  }

  render() {
    const { castName, castId } = this.props.cast;
    if (castId === '348567' || castId === '326202') {
      return null;
    }
    const backLink = this.props.backLink ? this.props.backLink : '';
    const className = this.props.textLink ? 'altibox-cast-text-link' : 'altibox-cast';

    return (
      <Link
        to={{ pathname: routes.cast.base + '/' + castId, state: { prevPath: backLink } }}
        onClick={() => this.trackCast(castName, castId)}
        className={className}
      >
        {this.props.textLink ? (
          <>
            {castName}
            {this.props.last ? '' : ', '}
          </>
        ) : (
          <>
            <div>
              <span>{castName}</span>
            </div>
          </>
        )}
      </Link>
    );
  }
}

export default AltiboxCast;
