import {
  Program as ProgramType,
  Channel,
  BroadcastStatus,
  GAaction,
  RootState,
  AltiboxAssetDetailsType,
} from '../../../interfaces';
import FutureProgram from './FutureProgram';
import LiveProgram from './LiveProgram';
import PastProgram from './PastProgram';
import './TvGuidePlaybillProgram.scss';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { showDetailsPanel as showDetailsPanelAction } from '../../../views/details/actions';
import { useMemo } from 'react';
import { useTracking } from '../../../hooks/useTracking';
import { broadcastStatus } from '../../../utils/huaweiUtils';
import { getRecording } from '../../../utils/pvrUtils';
import { useThirdPartyCatchupProgram } from '../../../queries/third-party-catchup/queries';

export interface RouteProps {
  channelId: string;
  catchupId: string;
}

interface Props {
  channel: Channel;
  channelIsLocked: boolean;
  isGuest: boolean;
  hasPvr: boolean;
  loggedInWithCredentials: boolean;
  filterActive: boolean;
}

export default function ChannelPlaybill(props: Props & ProgramType) {
  const seriesRecordings = useSelector((state: RootState) => state.channelsReducer.seriesRecordings);
  const singleRecordings = useSelector((state: RootState) => state.channelsReducer.singleRecordings);
  const favoritePrograms = useSelector((state: RootState) => state.channelsReducer.favoritePrograms);

  const dispatch = useDispatch();

  const { trackEvent } = useTracking();

  const { data: thirdPartyInfo } = useThirdPartyCatchupProgram(props);

  const recording = getRecording(
    seriesRecordings,
    singleRecordings,
    props.seriesID,
    props.channel.contentId,
    props.id,
    props.lifetimeId,
  );

  const isFavoriteProgram = useMemo(() => {
    if (!isEmpty(favoritePrograms)) {
      return favoritePrograms[props.seriesID];
    }

    return false;
  }, [favoritePrograms, props.seriesID]);

  function trackPlayFromTVGuide(
    channelName: string,
    seasonAndEpisode: string,
    programName: string,
    fromStart?: boolean,
  ) {
    const action = fromStart ? GAaction.playFromStart : GAaction.play;
    trackEvent({
      action,
      label: `${channelName} - ${programName}${seasonAndEpisode}`,
    });
  }

  function trackThirdParty() {
    const seasonAndEpisode = props.subNum && props.seasonNum ? ` S${props.seasonNum} E${props.subNum}` : '';

    trackEvent({
      action: GAaction.openExternal,
      label: `${props.channel.name} - ${props.name}${seasonAndEpisode}`,
    });
  }

  function renderMarkers() {
    return (
      <>
        {recording !== undefined && <li className="pvr-icon">7</li>}
        {isFavoriteProgram && <li className="favorite-icon">M</li>}
      </>
    );
  }

  function showDetailsPanel(program: ProgramType) {
    dispatch(
      showDetailsPanelAction({
        routeProps: { childId: program.id, parentId: program.seriesID, channelId: program.channelid },
        displayType: AltiboxAssetDetailsType.CATCHUP,
        clickedId: program.id,
      }),
    );
  }

  const currentBroadcastStatus = broadcastStatus({ starttime: props.starttime, endtime: props.endtime });

  const externalProgramButton =
    thirdPartyInfo && thirdPartyInfo.isValid ? (
      <a href={thirdPartyInfo.url} onClick={() => trackThirdParty()}>
        z
      </a>
    ) : null;

  switch (currentBroadcastStatus) {
    case BroadcastStatus.Finished:
      return (
        <PastProgram
          {...props}
          markers={renderMarkers()}
          externalProgramButton={externalProgramButton}
          channelIsLocked={props.channelIsLocked}
          programSet={false}
          showDetailsPanel={showDetailsPanel}
          trackPlay={trackPlayFromTVGuide}
        />
      );
    case BroadcastStatus.Live:
      return (
        <LiveProgram
          {...props}
          markers={renderMarkers()}
          channelIsLocked={props.channelIsLocked}
          isRecording={recording !== undefined}
          programSet={false}
          showDetailsPanel={showDetailsPanel}
          trackPlay={trackPlayFromTVGuide}
          filterActive={props.filterActive}
        />
      );
    case BroadcastStatus.Future:
      return (
        <FutureProgram
          {...props}
          markers={renderMarkers()}
          externalProgramButton={externalProgramButton}
          channelIsLocked={props.channelIsLocked}
          programSet={false}
          showDetailsPanel={showDetailsPanel}
          trackPlay={trackPlayFromTVGuide}
        />
      );
  }
}
