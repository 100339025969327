import React, { Component } from 'react';
import VodAsset from '../../components/VodAsset';
import { VodAsset as VodAssetInterface, VodAssetDisplayMode } from '../../interfaces';
import './style.scss';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { ScriptService } from '../../controllers/ScriptService';

interface Props {
  vods: VodAssetInterface[];
  stripLength?: number;
  title?: string;
  link?: string;
  hidePurchasedBadge?: boolean;
  assetIconPath?: string;
  baseAssetURL?: string;
  viewMode?: VodAssetDisplayMode;
  onScrolledToBottomCallback?: Function;
  overrideBasePath?: string;
  loggedInWithCredentials?: boolean;
}

interface State {
  offset: number;
}

class VodAssetList extends Component<Props, State> {
  state = {
    offset: 0,
  };

  // Strict Class Initialization ignored
  reloadOnScrollTimeout!: NodeJS.Timer;

  renderExtraSpacers() {
    let extraSpacers;
    if (this.props && this.props.stripLength) {
      const numberOfExtraSpacers = this.props.stripLength - this.props.vods.length;
      if (numberOfExtraSpacers > 0) {
        extraSpacers = Array.apply(null, Array(numberOfExtraSpacers)).map((_: unknown, i: number) => (
          <li key={`extra-spacer-${i}`} className="vod-asset" />
        ));
      }
    }
    return extraSpacers;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    this.setState({ offset: window.scrollY });
    clearTimeout(this.reloadOnScrollTimeout);
    this.reloadOnScrollTimeout = setTimeout(() => {
      if (this.props.onScrolledToBottomCallback) {
        const wScroll = window.scrollY;
        const wHeight = window.innerHeight;
        const scrollHeight = document.getElementsByTagName('body')[0].scrollHeight;
        const atBottom = scrollHeight - wHeight - wScroll <= 400;
        if (atBottom) {
          this.props.onScrolledToBottomCallback();
        }
      }
    }, 300);
  };

  render() {
    if (!this.props.vods || (this.props.vods && this.props.vods.length === 0)) {
      return null;
    }

    const vodAssets =
      this.props.stripLength && this.props.vods ? this.props.vods.slice(0, this.props.stripLength) : this.props.vods;
    if (vodAssets) {
      const vodAssetElements = vodAssets.map((vod: VodAssetInterface) => (
        <VodAsset
          hidePurchasedBadge={this.props.hidePurchasedBadge}
          baseURL={this.props.baseAssetURL ? this.props.baseAssetURL : vod.customBasePath}
          viewMode={this.props.viewMode ? this.props.viewMode : VodAssetDisplayMode.VOD}
          assetIconPath={this.props.assetIconPath ? this.props.assetIconPath : vod.customIconPath}
          key={'vodasset-' + vod.id}
          overrideBasePath={this.props.overrideBasePath}
          loggedInWithCredentials={this.props.loggedInWithCredentials}
          scrollOffset={this.state.offset}
          {...vod}
        />
      ));
      const stripClass = ScriptService.onMobile() ? 'vod-asset-list mobile' : 'vod-asset-list';
      const viewMode = this.props.viewMode === VodAssetDisplayMode.SVOD ? ' svod' : ' vod';
      const linkProperties = {
        pathname: this.props.link,
        state: { prevPath: window.location.pathname },
      };

      return (
        <div className={stripClass + viewMode}>
          {this.props.link || this.props.title ? (
            <div className="vod-asset-list-header">
              {this.props.link ? (
                <Link className="header-link" to={linkProperties}>
                  <h3 className="vod-asset-list-titled">{this.props.title}</h3>
                </Link>
              ) : (
                <h3 className="vod-asset-list-titled">{this.props.title}</h3>
              )}
              {this.props.link ? (
                <Link to={linkProperties}>
                  <button className="plain">{i18n.t<string>('see all')}</button>
                </Link>
              ) : null}
            </div>
          ) : null}

          <ul className="vod-asset-list-list">
            {vodAssetElements}
            {this.renderExtraSpacers()}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default VodAssetList;
