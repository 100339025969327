import React, { ChangeEventHandler, createRef, useEffect, useState } from 'react';
import PlayerControlButton from '../PlayerControls/PlayerControlButton';
import './index.scss';

interface VolumeControlProps {
  volume: number;
  muted: boolean;
  changeVolume: ChangeEventHandler<HTMLInputElement>;
  toggleMute: () => void;
}

const VolumeControl = (props: VolumeControlProps) => {
  const [showVolume, setShowVolume] = useState(false);
  const volumeSliderRef = createRef<HTMLInputElement>();

  const fillSlider = () => {
    const volumeSliderNode = volumeSliderRef.current;
    if (volumeSliderNode) {
      volumeSliderNode.style.background =
        ' linear-gradient(to right, #D73E35 0%, #D73E35 ' +
        props.volume * 100 +
        '%, #5A5958 ' +
        props.volume * 100 +
        '%, #5A5958 100%';
    }
  };

  useEffect(() => {
    if (showVolume) {
      fillSlider();
    }
  });

  const handleOnMouseEnter = () => {
    setShowVolume(true);
  };

  const handleOnMouseLeave = () => {
    setShowVolume(false);
  };

  const handleOnInputChange = (change: React.ChangeEvent<HTMLInputElement>) => {
    if (!props && !change) {
      return;
    }
    props.changeVolume(change);
    fillSlider();
  };

  return (
    <div className="volume-control" onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <PlayerControlButton
        icon={props.muted ? 'm' : 's'}
        className="volume-button noOutlineOnHover"
        onClickCallback={props.toggleMute}
      />
      {showVolume ? (
        <div className="volume-slider-container">
          <input
            tabIndex={1}
            ref={volumeSliderRef}
            id="volume-slider"
            className="volume-slider"
            type="range"
            step="any"
            onChange={handleOnInputChange}
            value={props.volume}
            max="1.0"
            min="0.0"
          />
        </div>
      ) : null}
    </div>
  );
};
export default VolumeControl;
