import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef, Ref } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import {
  SvgPlayFilled,
  SvgSkipBack,
  SvgSkipBackFilled,
  SvgTimer,
  SvgRecording,
  SvgFavourite,
  SvgFavouriteFilled,
  SvgTrailer,
  SvgDelete,
  SvgRearrange,
  SvgFilter,
  SvgChevronUp,
  SvgChevronDown,
  SvgClose,
} from '../../../icons';

import styles from './buttons.module.scss';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
type DeeplinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;

type Props = (ButtonProps | LinkProps | DeeplinkProps) & { isLoading?: boolean };

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(function (props, ref) {
  if ('to' in props) {
    const { children, ...linkProps } = props;

    return (
      <Link ref={ref as Ref<HTMLAnchorElement>} {...linkProps}>
        {children}
      </Link>
    );
  } else if ('href' in props) {
    const { children, ...deeplinkProps } = props;

    return (
      <a ref={ref as Ref<HTMLAnchorElement>} {...deeplinkProps}>
        {children}
      </a>
    );
  } else {
    const { children, ...buttonProps } = props as ButtonProps;

    return (
      <button ref={ref as Ref<HTMLButtonElement>} {...buttonProps}>
        {children}
      </button>
    );
  }
});

function ButtonPlay(props: Props) {
  const { children, ...rest } = props;

  return (
    <Button {...rest}>
      <SvgPlayFilled width="12px" height="14px" />
      {children}
    </Button>
  );
}

function ButtonPlayFromStart(props: Props) {
  const { children, ...rest } = props;

  return (
    <Button {...rest}>
      <SvgSkipBackFilled width="12px" height="14px" />
      {children}
    </Button>
  );
}

export function ButtonPrimary(props: Props) {
  return <Button {...props} className={`${props.className ?? ''} ${styles.buttonPrimary}`} />;
}

export function ButtonPrimaryPlay(props: Props) {
  return <ButtonPlay {...props} className={`${props.className ?? ''} ${styles.buttonPrimary}`} />;
}

export function ButtonPrimaryPlayFromStart(props: Props) {
  return <ButtonPlayFromStart {...props} className={`${props.className ?? ''} ${styles.buttonPrimary}`} />;
}

export function ButtonPrimaryPlayWithProgress({ className, ...props }: Props & { progress: string | number }) {
  return (
    <div className={`${styles.withProgressContainer} ${className ?? ''}`}>
      <ButtonPrimaryPlay {...props} />
      <progress value={props.progress} max="100" />
    </div>
  );
}

export function ButtonPrimaryUnavailableAsset(props: Exclude<Props, LinkProps>) {
  const { children, ...rest } = props;

  return (
    <ButtonPrimary {...rest} disabled={true}>
      <SvgTimer width="15px" height="15px" />
      {children}
    </ButtonPrimary>
  );
}

export function ButtonSecondary(props: Props) {
  const { children, className, isLoading, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.buttonSecondary} ${isLoading ? styles.isLoading : ''}`}>
      {!isLoading && children}
    </Button>
  );
}

export function ButtonSecondaryPlay(props: Props) {
  return <ButtonPlay {...props} className={`${props.className ?? ''} ${styles.buttonSecondary}`} />;
}

export function ButtonSecondaryPlayFromStart(props: Props) {
  return <ButtonPlayFromStart {...props} className={`${props.className ?? ''} ${styles.buttonSecondary}`} />;
}

export function ButtonSecondaryUnavailableAsset(props: Exclude<Props, LinkProps>) {
  const { children, ...rest } = props;

  return (
    <ButtonSecondaryPlay {...rest} disabled={true}>
      {children}
    </ButtonSecondaryPlay>
  );
}

export function IconButtonSkipBack(props: Props) {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.iconButton}`}>
      <SvgSkipBack width="22px" height="24px" />
      {children}
    </Button>
  );
}

export function IconButtonTrailer(props: Props) {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.iconButton}`}>
      <SvgTrailer width="23px" height="21px" />
      {children}
    </Button>
  );
}

export function IconButtonDelete(props: Props) {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.iconButton}`}>
      <SvgDelete width="22px" height="24px" />
      {children}
    </Button>
  );
}

export function IconButtonRecording(props: Props & { active?: boolean }) {
  const { children, className, active, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.iconButton} ${active ? styles.active : ''}`}>
      <SvgRecording width="24px" height="24px" />
      {children}
    </Button>
  );
}

export function IconButtonFavourite(props: Props & { active?: boolean }) {
  const { children, className, active, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.iconButton} ${active ? styles.active : ''}`}>
      {active ? <SvgFavouriteFilled width="26px" height="24px" /> : <SvgFavourite width="26px" height="24px" />}
      {children}
    </Button>
  );
}

export function ButtonPagination(props: Props & { active?: boolean }) {
  const { children, className, active, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.buttonPagination} ${active ? styles.active : ''}`}>
      {children}
    </Button>
  );
}

export const OptionButtonFilter = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(function (props, ref) {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} ref={ref} className={`${className ?? ''} ${styles.optionButton}`}>
      {children}
      <SvgFilter width="18px" height="20px" />
    </Button>
  );
});

export const OptionButtonSort = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(function (props, ref) {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} ref={ref} className={`${className ?? ''} ${styles.optionButton}`}>
      {children}
      <SvgRearrange width="17px" height="17px" />
    </Button>
  );
});

export function SharedAssetButton(props: Props & { children: (string | JSX.Element)[] }) {
  const { children, className, ...rest } = props;
  const [title, status] = children;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.sharedAssetButton}`}>
      {title}
      <span className={styles.status}>{status}</span>
    </Button>
  );
}

export function TextButtonExpandable(props: Props & { expanded: boolean }) {
  const { children, className, expanded, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.textButtonExpandable}`}>
      {children}
      {expanded ? <SvgChevronUp width="12px" height="7px" /> : <SvgChevronDown width="12px" height="7px" />}
    </Button>
  );
}

export function ButtonExpandable(props: Props & { expanded: boolean }) {
  const { children, className, expanded, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.buttonExpandable}`}>
      {children}
      {expanded ? <SvgChevronUp width="12px" height="7px" /> : <SvgChevronDown width="12px" height="7px" />}
    </Button>
  );
}

export function ActiveFilterButton(props: Props) {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} className={`${className ?? ''} ${styles.activeFilterButton}`}>
      <SvgClose height="12px" width="12px" />
      {children}
    </Button>
  );
}

export const ButtonClose = forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(function (props, ref) {
  const { children, className, ...rest } = props;
  return (
    <Button {...rest} ref={ref} className={`${className ?? ''} ${styles.buttonClose}`}>
      <SvgClose height="19px" width="19px" />
    </Button>
  );
});
