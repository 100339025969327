import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import i18n from '../../i18n';
import { isEmpty } from 'lodash';
import {
  RootState,
  HeaderDisplayType,
  HeaderInteractionType,
  StreamingServicePortal,
  PortalMenu,
  GAaction,
  GAlabel,
} from '../../interfaces';
import { getSvodKiosksForWeb } from '../../utils/svodUtil';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';
import { Helmet } from 'react-helmet-async';
import useFetchStreamingServices from '../../hooks/useFetchStreamingServices';

// UI
import StickyHeader from '../app/StickyHeader';
import Header from '../app/Header';
import Footer from '../../components/Footer';
import Spinner from '../../components/Spinner';
import GetAccessContainer from './GetAccessContainer';
import StreamingPortalsItem from './StreamingPortalsItem';
import DeeplinkModal from './DeeplinkModal';
import './style.scss';

const StreamingServices: React.FC = () => {
  const defaultImagePath = useSelector((state: RootState) => state.app.defaultImagePath);
  const svodKiosks = useSelector((state: RootState) => state.app.svodKiosks);

  const { streamingPortals, loading: streamingPortalsLoading } = useFetchStreamingServices();
  const [externalPortal, setExternalPortal] = useState<StreamingServicePortal>({} as StreamingServicePortal);
  const [webSvodKiosks, setWebSvodKiosks] = useState([] as PortalMenu[]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const tracking = useRef<AnalyticsTracking | null>(null);

  useEffect(() => {
    const webKiosks = svodKiosks ? getSvodKiosksForWeb(svodKiosks) : [];
    setWebSvodKiosks(webKiosks);
  }, [svodKiosks]);

  useEffect(() => {
    tracking.current = AnalyticsTracking.getInstance();
  }, []);

  const ServicesPortal = () => {
    // TODO: Define user subscription status for portals when Altibox API is ready

    const handleExternalPortal = (portal: StreamingServicePortal) => {
      const category = tracking.current?.getCurrentCategory();
      setOpenModal(true);
      setExternalPortal(portal);
      tracking.current?.trackEvent(category!, GAaction.openModal, `${GAlabel.thirdParty}_${portal.id}`);
    };

    if (!isEmpty(streamingPortals) && !isEmpty(webSvodKiosks)) {
      /* Posters title not included due first release */
      /* <h3> {i18n.t('external applications')} </h3> */
      return (
        <div className="svods-container">
          <ul>
            {streamingPortals.map((portal) => (
              <StreamingPortalsItem
                key={portal.id}
                portal={portal}
                defaultImagePath={defaultImagePath}
                svodKiosks={webSvodKiosks}
                handleExternalPortal={handleExternalPortal}
              />
            ))}
          </ul>
          <DeeplinkModal showModalState={openModal} setOpenModal={setOpenModal} externalService={externalPortal} />
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <Helmet>
        <title>{i18n.t<string>('streaming services')}</title>
      </Helmet>
      <StickyHeader />
      <Header
        displayType={HeaderDisplayType.Solid}
        interactionType={HeaderInteractionType.Full}
        title={i18n.t('streaming services')}
      />

      <div className="streaming-services-page">
        <div className="spacing">
          {streamingPortalsLoading ? <Spinner wrapInContainer={true} /> : <ServicesPortal />}
        </div>
      </div>
      <GetAccessContainer />

      <Footer />
    </div>
  );
};

export default StreamingServices;
