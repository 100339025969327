import { ActionTypes, TypeKeys } from '../actions';
import { AltiboxAsset, DetailsReducerState } from '../../../interfaces';

export function detailsReducer(
  state: DetailsReducerState = {
    currentAsset: undefined,
    loading: true,
    detailsPanel: undefined,
    isSlidingOut: false,
  },
  action: ActionTypes,
): DetailsReducerState {
  switch (action.type) {
    case TypeKeys.SET_CURRENT_ASSET:
      return {
        ...state,
        currentAsset: { ...action.currentAsset } as AltiboxAsset | undefined,
      };
    case TypeKeys.SET_LOADING_STATUS:
      return {
        ...state,
        loading: action.loading,
      };
    case TypeKeys.SHOW_DETAILS_PANEL:
      const { type, ...detailsPanel } = action;
      return {
        ...state,
        detailsPanel,
      };
    case TypeKeys.HIDE_DETAILS_PANEL:
      return {
        ...state,
        detailsPanel: undefined,
      };
    case TypeKeys.SET_SLIDE_OUT_STATUS:
      return {
        ...state,
        isSlidingOut: action.slidingOut,
      };
    default:
      return state;
  }
}
