import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  RootState,
  DispatchType,
  VodAsset,
  PortalMenu,
  ApiAuthResponse,
  VodListResponse,
  PvrRecording,
  Channel,
  Program,
  AuthReducerState,
  FrontPageConfig,
  FrontPageComponent,
  FrontPageComponentType,
  VodAssetDisplayMode,
  VodConfig,
  AltiboxAssetDisplayTypes,
  DispatchResponse,
  SearchConfig,
  AltiboxAsset,
  AMR,
} from '../../interfaces';
import { routes, swimlaneSettings } from '../../config';
import StickyHeader from './StickyHeader';
import ChannelsBanner from '../../components/ChannelsBanner';
import Footer from '../../components/Footer/index';
import TVProgramCollage from '../../components/TVProgramCollage';
import VodInlinePromotion from '../../components/VodInlinePromotion';
import {
  prepareMyContent,
  getCurrentLangTitle,
  getInlinePromotions,
  getVodAsset,
  addBadgeOnOwnedSeriesAssets,
} from '../../utils/vodUtils';
import { getSvodUrl, getSvodAsset } from '../../utils/svodUtil';
import { loadVodCategoryId } from '../../api/vod';
import { fetchAllRecordings, fetchAllScheduledRecordings } from '../pvr/actions';
import { fetchMyContent } from '../vod/actions';
import {
  getChannels,
  getGenres,
  fetchAllPlaybills,
  getPopularChannels,
  getFavoriteChannels,
} from '../tv/actions/index';
import './style/frontpage.scss';
import i18n from '../../i18n';
import { loadSvodNews, showLoginModal } from './actions';
import ContinueWatching from './ContinueWatching';
import { ScriptService } from '../../controllers/ScriptService';
import { getPVRAsset } from '../../utils/pvrUtils';
import AltiboxAssetViewList from '../../components/UI/AltiboxAssetViewList';
import { checkIfUserHasSvodProduct } from '../../utils/altiboxassetUtils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import StreamingServicesBanner from '../../components/StreamingServicesBanner';

interface Props extends RouteComponentProps<{}, {}, { toTop?: boolean }> {
  svodApps: PortalMenu[];
  recordings: PvrRecording[];
  auth: ApiAuthResponse | undefined;
  authReducer: AuthReducerState;
  myContent: VodAsset[];
  vods: VodAsset[];
  channels: Channel[];
  popularChannels: AMR.PopularChannel[];
  favoriteChannels: Channel[];
  defaultImagePath: string;
  playbills: Map<string, Program[]>;
  mySeries: VodAsset[];
  frontPageConfig: FrontPageConfig;
  mySeasons: VodAsset[];
  vodConfig: VodConfig;
  isGuest: boolean;
  loggedInWithCredentials: boolean;
  isHome: boolean;
  hasPvr: boolean;
  searchConfig: SearchConfig;
  getChannels: () => Promise<void>;
  getPopularChannels: () => Promise<void>;
  getFavoriteChannels: () => Promise<void>;
  getGenres: () => Promise<void>;
  loadContent: () => Promise<void>;
  loadSvodNews: (kisoks: PortalMenu[]) => Promise<void>;
  fetchAllRecordings: () => Promise<void>;
  fetchAllScheduledRecordings: () => Promise<void>;
  showLoginModal: () => DispatchResponse;
}

interface FrontPageVodMap {
  categoryId: string;
  goToCategory: boolean;
  orderBy: number;
  vods: VodAsset[];
}

interface State {
  vodLists: FrontPageVodMap[];
  vods: VodAsset[];
  allChannelsOpen: boolean;
  hideArrows: boolean;
  shouldRefreshPrograms: boolean;
}

class FrontPage extends React.Component<Props, State> {
  state: State = {
    vods: [],
    vodLists: [],
    allChannelsOpen: false,
    hideArrows: false,
    shouldRefreshPrograms: true,
  };

  // Strict Class Initialization ignored
  programRefreshInterval!: NodeJS.Timer;

  componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state.toTop) {
      window.scrollTo(0, 0);
    }

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.startProgramsRefreshInterval();

    if (!this.props.isGuest) {
      this.props.loadContent();
      this.props.loadSvodNews(this.props.svodApps);
    }

    if (!this.props.recordings && this.props.hasPvr && !this.props.isGuest) {
      this.loadPvr();
    }

    if (
      this.props.frontPageConfig &&
      this.props.frontPageConfig.availableComponents &&
      this.props.frontPageConfig.clients
    ) {
      this.loadFrontPageVods(this.props.frontPageConfig);
      this.props.getPopularChannels();
    }
    if (isEmpty(this.props.channels)) {
      this.props.getChannels();
    }
    if (isEmpty(this.props.favoriteChannels)) {
      this.props.getFavoriteChannels();
    }
  }

  componentWillUnmount() {
    clearInterval(this.programRefreshInterval);
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth < 600 || ScriptService.onMobile() || ScriptService.onPhone()) {
      if (!this.state.hideArrows) {
        this.setState({ hideArrows: true });
      }
    } else {
      if (this.state.hideArrows) {
        this.setState({
          hideArrows: false,
        });
      }
    }
  };

  startProgramsRefreshInterval() {
    this.programRefreshInterval = setInterval(() => {
      /* Check to see if we should refresh. We check every 10 seconds to see if the clock is a "round" number and close to round number + 1.
      Assumption: Programs usually tend to end on 0,15,30,45 and 60, we add at least 50 seconds to make sure the program has moved on to the next
      */
      if (this.state.shouldRefreshPrograms && moment().get('minutes') % 5 === 0 && moment().get('seconds') > 50) {
        this.props.getChannels();
        this.props.getPopularChannels();
        this.setState({ shouldRefreshPrograms: false }, () => {
          setTimeout(() => {
            this.setState({ shouldRefreshPrograms: true });
          }, 120 * 1000);
        });
      }
    }, 10 * 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.svodApps !== nextProps.svodApps) {
      this.forceUpdate();
    }

    if (!this.props.isGuest) {
      if (this.props.hasPvr !== nextProps.hasPvr && nextProps.hasPvr && !this.props.recordings) {
        this.loadPvr();
      }
    }

    if (nextProps.frontPageConfig !== this.props.frontPageConfig) {
      this.loadFrontPageVods(nextProps.frontPageConfig);
    }
  }

  loadPvr() {
    this.props.fetchAllRecordings();
    this.props.fetchAllScheduledRecordings();
    this.props.getGenres();
  }

  loadFrontPageVods(frontPageConfig: FrontPageConfig) {
    const webConfig = frontPageConfig.clients.find((x) => x.client === 'web');
    if (webConfig) {
      webConfig.order.forEach((name: string) => {
        const component: FrontPageComponent = frontPageConfig.availableComponents.find((x) => x.name === name)!;
        if (component.type === FrontPageComponentType.VOD_PROMOTE_CATEGORY && component.config.categoryId) {
          loadVodCategoryId(component.config.categoryId!, '0', 18).then((vodlist) => {
            const newList = {
              categoryId: component.config.categoryId!,
              vods: (vodlist as VodListResponse).vodlist,
              orderBy: component.config.orderBy ? component.config.orderBy : 0,
              goToCategory: component.config.goToCategory ? true : false,
            };
            this.setState({
              vodLists: this.state.vodLists.concat(newList),
            });
          });
        }
      });
    }
  }

  checkIfUserHasSvodProduct = (kiosk: PortalMenu) => {
    return checkIfUserHasSvodProduct(this.props.authReducer, kiosk);
  };

  getSvodKiosksNews = () => {
    return this.props.svodApps
      .filter((kiosk) => (kiosk.devices ? kiosk.devices.web : true))
      .map((kiosk: PortalMenu) => {
        const altiboxAssets = kiosk.newsAssets
          ? kiosk.newsAssets.map((asset) => {
              return getSvodAsset(asset, this.props.svodApps, this.props.defaultImagePath, true);
            })
          : [];

        if (this.checkIfUserHasSvodProduct(kiosk)) {
          return (
            <div className="frontpage-section svod-frontpage" key={kiosk.providerName}>
              <AltiboxAssetViewList
                assets={altiboxAssets}
                vodDisplayMode={VodAssetDisplayMode.SVOD}
                title={getCurrentLangTitle(kiosk.titles!)}
                displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
                titleLink={`${routes.svod.base}/${getSvodUrl(kiosk)}`}
                loggedInWithCredentials={this.props.loggedInWithCredentials}
                myContent={this.props.myContent}
              />
            </div>
          );
        } else {
          return null;
        }
      })
      .filter((x) => x);
  };

  getContentBasedOnConfig = () => {
    const webConfig = this.props.frontPageConfig.clients.find((x) => x.client === 'web');
    if (!webConfig) {
      return null;
    }
    return webConfig.order.map((name: string, index: number) => {
      const key = 'react-key-' + index;
      let elementToReturn;
      let hideArrowsClass = this.state.hideArrows ? ' no-arrows' : '';
      const component: FrontPageComponent = this.props.frontPageConfig.availableComponents.find(
        (x) => x.name === name,
      )!;
      if (!component) {
        return null;
      }

      switch (component.type) {
        case FrontPageComponentType.VOD_INLINE_PROMOTION:
          elementToReturn = (
            <div className="frontpage-section" key={key}>
              <VodInlinePromotion
                promotions={getInlinePromotions(this.props.vodConfig)}
                imagePath={this.props.defaultImagePath}
              />
            </div>
          );
          break;
        case FrontPageComponentType.CONTINUE_WATCHING:
          if (this.props.isHome && !this.props.loggedInWithCredentials) {
            elementToReturn = null;
          } else {
            elementToReturn = (
              <div className="frontpage-section" key={key}>
                <ContinueWatching numberOfAssetsToShow={swimlaneSettings.limit} />
              </div>
            );
          }
          break;
        case FrontPageComponentType.CHANNEL_MOSAIQUE:
          elementToReturn = (
            <TVProgramCollage
              key={key}
              channels={this.props.channels}
              popularChannels={this.props.popularChannels}
              channelsToUse={component.config.channelsToShow!}
              playbills={this.props.playbills}
              authReducer={this.props.authReducer}
              showLoginModal={this.props.showLoginModal}
            />
          );
          break;
        case FrontPageComponentType.CHANNEL_LIVE:
          elementToReturn = (
            <div key={key} className={'frontpage-section channels-container' + hideArrowsClass}>
              <ChannelsBanner
                onlyInSubscription={true}
                channels={this.props.channels}
                favoriteChannels={this.props.favoriteChannels}
                playbills={this.props.playbills}
                authReducer={this.props.authReducer}
                openState={this.state.allChannelsOpen}
                showLoginModal={this.props.showLoginModal}
              />
            </div>
          );
          break;
        case FrontPageComponentType.STREAMING_SERVICES:
          elementToReturn = (
            <div key={key} className={'frontpage-section' + hideArrowsClass}>
              <StreamingServicesBanner
                defaultImagePath={this.props.defaultImagePath}
                svodKiosks={this.props.svodApps}
              />
            </div>
          );
          break;
        case FrontPageComponentType.PVR_MY_RECORDINGS:
          let recordings = this.props.recordings
            ? this.props.recordings.map((recording) => {
                return getPVRAsset(recording);
              })
            : [];

          let hasRecordings = this.props.recordings && recordings.length > 0;

          elementToReturn =
            !this.props.isGuest &&
            (hasRecordings ? (
              <div className="frontpage-section" key={key}>
                <AltiboxAssetViewList
                  title={i18n.t<string>('my recordings')}
                  displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
                  loggedInWithCredentials={this.props.loggedInWithCredentials}
                  titleLink={routes.pvr.base}
                  assets={recordings.splice(0, 10)}
                  myContent={this.props.myContent}
                  vodDisplayMode={VodAssetDisplayMode.SVOD}
                />
              </div>
            ) : null);
          break;
        case FrontPageComponentType.VOD_PROMOTE_CATEGORY:
          const theVodList = this.state.vodLists.find((x) => x.categoryId === component.config.categoryId);
          let promotedAltiboxAssets: AltiboxAsset[] = [];
          let emptyResult = false;
          let linkPath = '';

          if (this.state.vodLists && this.state.vodLists.length > 0 && theVodList && theVodList.vods) {
            linkPath = theVodList.goToCategory
              ? `${routes.vod.base}${routes.vod.category}/${component.config.categoryId}/${
                  component.config.orderBy ? component.config.orderBy : 0
                }`
              : routes.vod.base;
            let tempList = theVodList.vods;
            if (theVodList.vods.length > 0) {
              let prepareContent = addBadgeOnOwnedSeriesAssets(tempList, this.props.myContent);
              let prepareSeries = addBadgeOnOwnedSeriesAssets(prepareContent, this.props.mySeries);
              tempList = prepareSeries;
            }
            promotedAltiboxAssets =
              tempList.length > 0
                ? tempList.map((asset) => {
                    return getVodAsset(asset);
                  })
                : [];

            if (promotedAltiboxAssets.length === 0) {
              emptyResult = true;
            }
          } else if (theVodList && theVodList.vods === undefined) {
            emptyResult = true;
          }

          if (promotedAltiboxAssets.length >= 0 && !emptyResult) {
            elementToReturn = (
              <div className="frontpage-section altibox-asset-frontpage-section vod-inline" key={key}>
                <AltiboxAssetViewList
                  assets={promotedAltiboxAssets}
                  title={component.config.title ? getCurrentLangTitle(component.config.title) : ''}
                  displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
                  titleLink={linkPath}
                  vodDisplayMode={VodAssetDisplayMode.VOD}
                  loggedInWithCredentials={this.props.loggedInWithCredentials}
                  myContent={this.props.myContent}
                />
              </div>
            );
          } else {
            elementToReturn = null;
          }
          break;
        case FrontPageComponentType.SVOD_PROMOTED:
          elementToReturn = !this.props.isGuest ? this.getSvodKiosksNews() : null;
          break;
        case FrontPageComponentType.VOD_MY_CONTENT:
          const vods = prepareMyContent(this.props.myContent, this.props.mySeries, this.props.mySeasons);
          const myAltiboxAssets = vods
            ? vods.map((asset) => {
                return getVodAsset(asset);
              })
            : [];

          elementToReturn =
            !this.props.isGuest &&
            ((this.props.myContent && this.props.myContent.length > 0) || this.props.myContent === undefined) ? (
              <div key={key} className="frontpage-section altibox-asset-frontpage-section vod-inline">
                <AltiboxAssetViewList
                  assets={myAltiboxAssets.splice(0, 10)}
                  title={i18n.t<string>('your bought and rented')}
                  displayType={AltiboxAssetDisplayTypes.HorizontalScroller}
                  titleLink={routes.vod.base + routes.vod.myContent}
                  vodDisplayMode={VodAssetDisplayMode.VOD}
                  loggedInWithCredentials={this.props.loggedInWithCredentials}
                  myContent={this.props.myContent}
                />
              </div>
            ) : null;
          break;
        default:
          return null;
      }
      return elementToReturn;
    });
  };

  render() {
    const title = 'Altibox TV';
    const description =
      'Altibox TV - Få tilgang rett i nettleseren: Faste og innvalgte kanaler, ' +
      'filmer og serier, hjemme og ute. Se nett-tv på PC eller Mac.';

    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta property="og:site_name" content={title} />
          <meta property="og:title" content={title} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:description" content={description} />
          <meta name="description" content={description} />
          <meta property="og:type" content="website" />
        </Helmet>
        <StickyHeader searchConfig={this.props.searchConfig} />
        <div className="frontpage">
          <div className="spacing">
            {this.props.frontPageConfig.availableComponents ? this.getContentBasedOnConfig() : null}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(
  (state: RootState) => ({
    defaultImagePath: state.app.defaultImagePath,
    hasPvr: state.authReducer.hasPvr as boolean,
    authReducer: state.authReducer,
    auth: state.authReducer.auth,
    channels: state.channelsReducer.channels,
    popularChannels: state.channelsReducer.popularChannels,
    favoriteChannels: state.channelsReducer.favoriteChannels,
    playbills: state.channelsReducer.playbills,
    svodApps: state.app.svodKiosks,
    frontPageConfig: state.app.frontPageConfig,
    recordings: state.pvrReducer.recordings as PvrRecording[],
    myContent: state.vodReducer.myContent as VodAsset[],
    mySeries: state.vodReducer.mySeries as VodAsset[],
    vodConfig: state.vodReducer.vodConfig as VodConfig,
    mySeasons: state.vodReducer.mySeasons as VodAsset[],
    isGuest: state.authReducer.isGuest,
    loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
    isHome: state.authReducer.isHome,
  }),
  (dispatch: DispatchType) => ({
    getChannels: () => dispatch(getChannels()).then(() => dispatch(fetchAllPlaybills())),
    getPopularChannels: () => dispatch(getPopularChannels()),
    getFavoriteChannels: () => dispatch(getFavoriteChannels()),
    getGenres: () => dispatch(getGenres()),
    loadSvodNews: (kiosks: PortalMenu[]) => dispatch(loadSvodNews(kiosks)),
    loadContent: () => dispatch(fetchMyContent(-1)),
    fetchAllRecordings: () => dispatch(fetchAllRecordings(false)),
    fetchAllScheduledRecordings: () => dispatch(fetchAllScheduledRecordings(false)),
    showLoginModal: () => dispatch(showLoginModal()),
  }),
)(withRouter(FrontPage));
