import { VodReducerState, VodConfig, VodConfigData, VodConfigDataType, VodSection } from '../../../interfaces';

import { TypeKeys, ActionTypes } from '../actions';

export function vodReducer(
  state: VodReducerState = {
    selectedGenreType: undefined,
    myContent: undefined,
    mySeasons: undefined,
    mySeries: undefined,
    currentVod: undefined,
    currentVodCategory: undefined,
    currentVodCategoryId: undefined,
    vodConfig: undefined,
    frontPageVodLists: undefined,
    categoryIds: undefined,
    autoplay: undefined,
    streamConfig: undefined,
  },
  action: ActionTypes,
): VodReducerState {
  switch (action.type) {
    case TypeKeys.SET_SELECTED_VOD_GENRE:
      return {
        ...state,
        selectedGenreType: action.selectedGenreType,
      };

    case TypeKeys.SET_FRONT_PAGE_LISTS:
      return {
        ...state,
        frontPageVodLists: action.frontPageVodLists,
      };
    case TypeKeys.SET_VOD_CATEGORY_IDS:
      return {
        ...state,
        categoryIds: action.categoryIds,
      };
    case TypeKeys.SET_VOD_CONFIG:
      return {
        ...state,
        vodConfig: appendCustomCategoryIds(action.vodConfig),
      };
    case TypeKeys.CLEAR_CURRENT_CATEGORY:
      return {
        ...state,
        currentVodCategory: { vodlist: [], counttotal: '0' },
        currentVodCategoryId: '-1',
      };
    case TypeKeys.SET_CURRENT_CATEGORY:
      if (
        state.currentVodCategory &&
        state.currentVodCategory.vodlist &&
        state.currentVodCategory.vodlist.length > 0 &&
        action.currentVodCategory.vodlist &&
        action.currentVodCategory.vodlist.length > 0
      ) {
        action.currentVodCategory.vodlist = state.currentVodCategory.vodlist.concat(action.currentVodCategory.vodlist);

        /* eslint-disable no-mixed-operators */
      } else if (
        state.currentVodCategory &&
        (!action.currentVodCategory.vodlist ||
          (action.currentVodCategory.vodlist && action.currentVodCategory.vodlist.length === 0))
      ) {
        action.currentVodCategory.vodlist = state.currentVodCategory.vodlist;
      }
      /* eslint-enable no-mixed-operators */
      return {
        ...state,
        currentVodCategory: action.currentVodCategory,
        currentVodCategoryId: action.categoryId,
      };
    case TypeKeys.SET_CURRENT_VOD:
      // can be film or series
      return {
        ...state,
        currentVod: action.currentVod,
      };
    case TypeKeys.SET_MY_CONTENT:
      return {
        ...state,
        myContent: action.myContent,
      };
    case TypeKeys.SET_MY_SERIES:
      return {
        ...state,
        mySeries: action.mySeries,
      };
    case TypeKeys.SET_MY_SEASONS:
      return {
        ...state,
        mySeasons: action.mySeasons,
      };
    case TypeKeys.SET_VOD_AUTOPLAY:
      return {
        ...state,
        autoplay: action.autoplay,
      };
    case TypeKeys.CLEAR_VOD_AUTOPLAY:
      return {
        ...state,
        autoplay: undefined,
      };
    case TypeKeys.SET_VOD_STREAM_CONFIG:
      return {
        ...state,
        streamConfig: action.streamConfig,
      };
    case TypeKeys.CLEAR_VOD_STREAM_CONFIG:
      return {
        ...state,
        streamConfig: undefined,
        currentVod: undefined,
      };
    case TypeKeys.TOGGLE_ISFAVOURITE_ON_VODLIST_ASSET:
      return {
        ...state,
        currentVodCategory: {
          ...action.currentVodCategory,
          vodlist: action.newVodlist,
        },
      };
    default:
      return state;
  }
}

function appendCustomCategoryIds(vodConfig: VodConfig) {
  let currentCategoryId = 1;
  vodConfig.data.forEach((data: VodConfigData) => {
    if (data.sections) {
      // todo map other categories when needed

      if (data.type === VodConfigDataType.GRID_WITH_BANNERS) {
        data.sections.forEach((section: VodSection) => {
          if (section.categoryId) {
            section.customCategoryId = currentCategoryId.toString();
            currentCategoryId++;
          }
        });
      }
    }
  });
  return vodConfig;
}
