import { useQuery } from 'react-query';
import { StreamTypeNumber } from '../../interfaces';
import { getAllBookmarks } from './api';
import { bookmarkKeys } from './keys';
import { validBookmarksFor } from './utils';

/**
 *
 * @returns All bookmarks for the given bookmark type
 */
export function useBookmarks(bookmarkType: 'all' | 'pvr' | 'vod' | 'catchup' = 'all') {
  return useQuery(bookmarkKeys.all, () => getAllBookmarks().then((b) => b.bookmarkList), {
    select: (bookmarks) => {
      switch (bookmarkType) {
        case 'all':
          return bookmarks;
        case 'pvr':
          return validBookmarksFor(bookmarks, StreamTypeNumber.PVR);
        case 'vod':
          return validBookmarksFor(bookmarks, StreamTypeNumber.VOD);
        case 'catchup':
          return validBookmarksFor(bookmarks, StreamTypeNumber.CATCHUP);
      }
    },
  });
}

/**
 *
 * @param id
 * @returns Bookmark for the given id
 */
export function useBookmark(id: string | undefined) {
  return useQuery(bookmarkKeys.all, () => getAllBookmarks().then((b) => b.bookmarkList), {
    enabled: Boolean(id),
    select: (bookmarks) => {
      return bookmarks.find((b) => b.contentId === id);
    },
  });
}
