import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AltiboxAsset, AltiboxAssetDisplayTypes, VodAssetDisplayMode, GAaction, VodAsset } from '../../../interfaces';
import './style.scss';
import AltiboxAssetView from '../AltiboxAssetView';
import GALink from '../../../components/GALink';
import { ScriptService } from '../../../controllers/ScriptService';
import Spinner from '../../../components/Spinner';
import SeeAllVodSvg from './see-all-vod.svg';
import SeeAllSvodSvg from './see-all-svod.svg';
import { isEmpty } from 'lodash';
import i18n from '../../../i18n';
import AltiboxAssetSlider from '../AltiboxAssetSlider';

interface Props {
  assets: AltiboxAsset[];
  title?: string;
  trackingTitle?: string;
  stripLength?: number;
  titleLink?: string;
  displayType: AltiboxAssetDisplayTypes;
  hideTitleHeader?: boolean;
  showNumbering?: boolean;
  onScrolledToBottomCallback?: Function;
  loggedInWithCredentials?: boolean;
  favouriteView?: boolean;
  genreIds?: string;
  vodDisplayMode?: VodAssetDisplayMode;
  myContent?: VodAsset[];
}

interface State {
  hideArrows: boolean;
  notEnoughChildren: boolean;
}

class AltiboxAssetViewList extends Component<Props, State> {
  state: State = {
    hideArrows: true,
    notEnoughChildren: false,
  };

  // Strict Class Initialization ignored
  reloadOnScrollTimeout!: NodeJS.Timer;
  resizeTimeout!: NodeJS.Timer;

  get link() {
    return {
      pathname: this.props.titleLink,
      state: { prevPath: window.location.pathname },
    };
  }

  get trackingLabel() {
    const trackingTitle = this.props.trackingTitle ? this.props.trackingTitle : 'unknown';
    const trackingGenres = this.props.genreIds ? ' - ' + this.props.genreIds : '';
    return `${trackingTitle}${trackingGenres}`;
  }

  get seeAllAssetsCard() {
    let vodDisplayType = this.props.vodDisplayMode && this.props.vodDisplayMode === VodAssetDisplayMode.VOD;
    return (
      <div
        key={`see-all-${this.props.title}-key`}
        onMouseDown={(e) => e.preventDefault()}
        className="see-all-container"
      >
        <GALink
          key={`see-all-${this.props.title}`}
          action={GAaction.listFilterBy}
          label={this.trackingLabel}
          className="see-all-link"
        >
          <Link to={this.link}>
            <img className="see-all-img" src={vodDisplayType ? SeeAllVodSvg : SeeAllSvodSvg} alt="see-all" />
            <div className="see-all-text">{i18n.t<string>('see all')}</div>
          </Link>
        </GALink>
      </div>
    );
  }

  get titleBar() {
    if (this.props.titleLink) {
      return this.props.hideTitleHeader ? null : (
        <div className="altibox-asset-list-title-container">
          <GALink action={GAaction.listFilterBy} label={this.trackingLabel} className="altibox-asset-title-link">
            <Link className="altibox-asset-title-link" to={this.link}>
              <h3 title={this.props.title}>{this.props.title}</h3>
              <span className="aib-icon">{'>'}</span>
            </Link>
          </GALink>
        </div>
      );
    }
    return <h3>{this.props.title}</h3>;
  }

  componentDidMount() {
    this.checkViewport();

    window.addEventListener('resize', this.onResize);
    if (this.props.onScrolledToBottomCallback) {
      window.addEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.onScrolledToBottomCallback) {
      window.removeEventListener('scroll', this.onScroll);
      window.removeEventListener('resize', this.onResize);
    }
  }

  onScroll = () => {
    clearTimeout(this.reloadOnScrollTimeout);
    this.reloadOnScrollTimeout = setTimeout(() => {
      if (this.props.onScrolledToBottomCallback) {
        const wScroll = window.scrollY;
        const wHeight = window.innerHeight;
        const scrollHeight = document.getElementsByTagName('body')[0].scrollHeight;
        const atBottom = scrollHeight - wHeight - wScroll <= 400;
        if (atBottom) {
          this.props.onScrolledToBottomCallback();
        }
      }
    }, 300);
  };

  onResize = () => {
    clearInterval(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.checkViewport();
    }, 1000);
  };

  checkViewport = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth < 600 || ScriptService.onMobile() || ScriptService.onPhone()) {
      if (!this.state.hideArrows) {
        this.setState({ hideArrows: true });
      }
    } else {
      if (this.state.hideArrows) {
        this.setState({
          hideArrows: false,
        });
      }
    }
  };

  renderAltiboxAssetLists(): JSX.Element {
    const { displayType, loggedInWithCredentials, assets, stripLength } = this.props;

    switch (displayType) {
      case AltiboxAssetDisplayTypes.GridView:
        const gridView = assets.map((asset: AltiboxAsset, i) => {
          if (i >= stripLength!) {
            return null;
          }
          return (
            <AltiboxAssetView
              key={asset.id}
              loggedInWithCredentials={loggedInWithCredentials}
              asset={asset}
              gridView={true}
              {...this.props}
            />
          );
        });

        return (
          <div key="container-grid" className="items-container items-container-grid">
            {isEmpty(assets) ? <Spinner wrapInContainer={true} /> : gridView}
          </div>
        );
      case AltiboxAssetDisplayTypes.HorizontalScroller:
        let horizontalView = assets.map((asset: AltiboxAsset, index: number) => {
          return (
            <AltiboxAssetView
              key={`${asset.id}${index}`}
              loggedInWithCredentials={loggedInWithCredentials}
              asset={asset}
              {...this.props}
            />
          );
        });

        // Push see all card to the list.
        if (horizontalView.length > 5 && this.link && this.link.pathname) {
          horizontalView.push(this.seeAllAssetsCard);
        }

        return isEmpty(assets) ? (
          <Spinner key="spinner" wrapInContainer={true} />
        ) : (
          <AltiboxAssetSlider
            children={horizontalView}
            titleBar={this.titleBar}
            displayType={this.props.vodDisplayMode}
          />
        );
    }
  }

  render() {
    let stripClass = ScriptService.onMobile() ? 'altibox-asset-list mobile' : 'altibox-asset-list';
    stripClass = stripClass + (this.props.assets.length === 0 ? ' empty-strip' : '');
    let assetList = this.renderAltiboxAssetLists();
    return <div className={stripClass}>{assetList}</div>;
  }
}

export default AltiboxAssetViewList;
