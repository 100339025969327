import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { filterEpisodes } from '..';
import i18n from '../../../../../i18n';
import { AltiboxSeason } from '../../../../../interfaces';
import { ButtonPagination } from '../../../Buttons';
import { ActiveFilters } from './ActiveFilters';
import { FilteringProps, Filtering } from './Filtering';
import { SortingProps, Sorting } from './Sorting';

interface SeasonSelectorProps extends FilteringProps, SortingProps {
  seasons: AltiboxSeason[];
  currentSeason: string;
  setCurrentSeason: React.Dispatch<React.SetStateAction<string>>;
  deletedRecordings: string[];
}

export function SeasonSelector({
  altiboxAsset,
  filter,
  setFilter,
  handleFilterSelect,
  getFilterOptions,
  sort,
  setSort,
  hasEpisodeNumbers,
  seasons,
  currentSeason,
  setCurrentSeason,
  deletedRecordings,
}: SeasonSelectorProps) {
  const filteredSeasons = seasons.filter(
    (season) => filterEpisodes(season.content, filter.value, deletedRecordings).length,
  );

  const seasonExists = filteredSeasons.find((season) => season.seasonNumber === currentSeason);

  useEffect(() => {
    if (!seasonExists && filteredSeasons && !isEmpty(filteredSeasons)) {
      setCurrentSeason(filteredSeasons[0].seasonNumber);
    }
  }, [filteredSeasons, seasonExists, setCurrentSeason]);

  if (isEmpty(seasons)) {
    return null;
  }

  return (
    <div className="season-list-selector-filter-sort-container">
      <div className="season-list-filter-sort-header">
        <h2>{i18n.t<string>('seasons')}</h2>
        <Filtering
          altiboxAsset={altiboxAsset}
          filter={filter}
          setFilter={setFilter}
          handleFilterSelect={handleFilterSelect}
          getFilterOptions={getFilterOptions}
        />
        <Sorting altiboxAsset={altiboxAsset} sort={sort} setSort={setSort} hasEpisodeNumbers={hasEpisodeNumbers} />
      </div>
      <div className="season-list-selector-active-filter-container">
        <nav className="season-list-selector">
          <ul>
            {filteredSeasons.map(({ seasonNumber }) => (
              <li key={seasonNumber + '-season'}>
                <ButtonPagination
                  disabled={currentSeason === seasonNumber}
                  active={currentSeason === seasonNumber}
                  onClick={() => setCurrentSeason(seasonNumber)}
                >
                  {seasonNumber !== 'undefined' ? ' ' + seasonNumber : ' 0'}
                </ButtonPagination>
              </li>
            ))}
          </ul>
        </nav>
        <ActiveFilters filter={filter} handleFilterSelect={handleFilterSelect} getFilterOptions={getFilterOptions} />
      </div>
    </div>
  );
}
