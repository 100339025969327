import React from 'react';
import { AltiboxURLs } from '../../interfaces';
import { getFooterUrl } from '../../utils/appUtils';
import altiboxLogo from '../../images/Altibox_logo_hvit.svg';
import style from './footer.module.scss';
import { useTranslation } from 'react-i18next';

const footerMap = new Map([
  [getFooterUrl(AltiboxURLs.HOME), getFooterUrl(AltiboxURLs.TOP_DOMAIN)],
  [getFooterUrl(AltiboxURLs.MY_PAGE), 'mine sider'],
  [getFooterUrl(AltiboxURLs.GDPR), 'privacy policy'],
  [getFooterUrl(AltiboxURLs.CUSTOMER_SERVICE), 'customer service'],
]);

interface FooterItemProps {
  href: string;
  title: string;
  handleClick?: () => void;
}

const FooterItem = ({ href, title, handleClick }: FooterItemProps) => {
  return href ? (
    <a target="_blank" href={href} rel="noreferrer">
      {title}
    </a>
  ) : (
    <button className={style.footerButton} onClick={handleClick}>
      {title}
    </button>
  );
};

const FooterSeperator = () => {
  return <span> | </span>;
};

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <hr />
      <div className={style.altiboxFooterContent}>
        <div className={style.altiboxFooterLinks}>
          {Array.from(footerMap).map(([key, value]) => {
            return (
              <React.Fragment key={key}>
                <FooterItem href={key} title={t(value)} />
                <FooterSeperator />
              </React.Fragment>
            );
          })}
          <FooterItem
            href=""
            title={t('cookies')}
            handleClick={() => {
              if (window.CookieConsent) {
                window.CookieConsent.renew();
              }
            }}
          />
        </div>
        <a className={style.logoLink} href="/">
          <img className={style.logo} alt={t('to the front page')} src={altiboxLogo} />
        </a>
      </div>
    </>
  );
};
export default Footer;
