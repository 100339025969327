import React from 'react';
import i18n from '../i18n';
import { routes } from '../config';
import Footer from '../components/Footer';
import AnalyticsTracking from '../controllers/AnalyticsTracking';

interface State {
  hasError: boolean;
}

type Props = {
  children: React.ReactNode;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  tracking = AnalyticsTracking.getInstance();

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // tslint:disable-next-line
  componentDidCatch(error: Error) {
    this.tracking.trackError(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="error-page">
            <h2>{i18n.t<string>('error sorry message')}</h2>
            <div className="error-sub-message">{i18n.t<string>('error sorry please try again later')}</div>
            <div className="save-links">
              <div>{i18n.t<string>('error does it work here')}</div>
              <a href="/">{i18n.t<string>('home')}</a>&nbsp;|&nbsp;
              <a href={routes.pvr.base}>{i18n.t<string>('recordings')}</a>&nbsp;|&nbsp;
              <a href={routes.tv.base}>{i18n.t<string>('watch live tv')}</a>&nbsp;|&nbsp;
              <a href={routes.vod.base}>{i18n.t<string>('movies and series')}</a>
            </div>
          </div>
          <Footer />
        </>
      );
    }
    return this.props.children;
  }
}
