import { ManifestStream } from '../.';
import parser from 'fast-xml-parser';
import moment from 'moment';
import { isEmpty, pick, uniqBy } from 'lodash';
import { ticklePSSHBox } from './PSSHDecoder';
import shaka from 'shaka-player';

type VariantTrack = {
  active: boolean;
  codecs: string;
  width: number;
};

const WIDEVINE_UUID = 'urn:uuid:edef8ba9-79d6-4ace-a3c8-27dcd51d21ed';

export const DashPlayerUtil = {
  // get a lower-stream-quality-track-width for making skipping load faster
  getSkippingWidth(player: shaka.Player): number {
    const preferredSkipWidth = 960;
    const variantTracks = player.getVariantTracks() as VariantTrack[];
    const activeTrack = player
      .getVariantTracks()
      .filter((x: shaka.extern.Track) => x)
      .filter((t: shaka.extern.Track) => t && t.active);
    const currentTrack = isEmpty(activeTrack) ? undefined : activeTrack[0];

    let skippingWidthTrack;
    // if a lower width than preferred is active, use it
    if (currentTrack && currentTrack.width && currentTrack.width < preferredSkipWidth) {
      skippingWidthTrack = currentTrack;
    } else {
      skippingWidthTrack = variantTracks.find((track: VariantTrack) => track && track.width === preferredSkipWidth);
    }

    if (skippingWidthTrack && skippingWidthTrack.width) {
      return skippingWidthTrack.width;
    }
    if (variantTracks.length >= 2) {
      return variantTracks[1].width;
    } else {
      return variantTracks[0].width;
    }
  },

  getCurrentCodec: (player: shaka.Player): string => {
    const variantTracks = player.getVariantTracks() as VariantTrack[];
    const currentTrack = variantTracks ? variantTracks.find((x) => x.active) : null;
    return currentTrack ? currentTrack.codecs : 'NA';
  },

  getAvailableVideoStreams: (player: shaka.Player): ManifestStream[] => {
    if (!player) {
      return [];
    }
    if ((player.getVariantTracks && player.getVariantTracks()) || !isEmpty(player.getVariantTracks())) {
      let streams: shaka.extern.TrackList = [];
      let availableStreams = DashPlayerUtil.deduplicateVideoQualityTracks(player.getVariantTracks());
      if (!isEmpty(availableStreams)) {
        streams = Array.from(new Set(availableStreams.map((a: shaka.extern.Track) => a.bandwidth)))
          .map((bandwidth) => {
            return availableStreams.find((a: shaka.extern.Track) => a.bandwidth === bandwidth);
          })
          .sort((a: shaka.extern.Track | undefined, b: shaka.extern.Track | undefined) => {
            if (a && b) {
              return a.bandwidth - b.bandwidth;
            }
            return 1;
          }) as shaka.extern.TrackList;

        return streams.map((track: shaka.extern.Track) => {
          const { id, audioCodec, bandwidth, width, height, videoCodec } = track;
          return {
            bandwidth: bandwidth,
            id: id,
            audio: {
              codecs: audioCodec,
            },
            video: {
              bandwidth: bandwidth,
              width: width,
              height: height,
              codecs: videoCodec,
            },
          } as ManifestStream;
        });
      }
      return [];
    }
    return [];
  },

  deduplicateVideoQualityTracks(trackList: shaka.extern.TrackList): shaka.extern.TrackList {
    return uniqBy(trackList, (item) => {
      return JSON.stringify(pick(item, ['videoBandwidth', 'videoCodec']));
    });
  },

  getMaxedAllowedBitrate: (player: shaka.Player) => {
    const availableBandwidths = DashPlayerUtil.getAvailableVideoStreams(player);
    if (availableBandwidths.length > 0) {
      return availableBandwidths[availableBandwidths.length - 1].bandwidth / 1000 / 1000;
    } else {
      return 0;
    }
  },

  applyVODFilter(data: Uint8Array, cappingLevel: number) {
    return DashPlayerUtil.createInt8Array(
      DashPlayerUtil.convertHuaweiDashVodManifest(DashPlayerUtil.createString(data), cappingLevel),
    );
  },

  applyProgramFilter(data: Uint8Array, programStart: string) {
    return DashPlayerUtil.createInt8Array(
      DashPlayerUtil.convertHuaweiDashProgramManifest(DashPlayerUtil.createString(data), programStart),
    );
  },

  applyLiveFilter(data: Uint8Array) {
    return DashPlayerUtil.createInt8Array(DashPlayerUtil.convertHuaweiLiveManifest(DashPlayerUtil.createString(data)));
  },

  convertHuaweiLiveManifest: (manifestString: string) => {
    manifestString = DashPlayerUtil.ttmlParser(manifestString);
    manifestString = DashPlayerUtil.removeUnsupportedAudioCodecs(manifestString);
    manifestString = DashPlayerUtil.addUTCTiming(manifestString);
    manifestString = DashPlayerUtil.addHardOfHearing(manifestString);
    manifestString = DashPlayerUtil.tickleMyPSSH(manifestString);
    return manifestString;
  },

  convertHuaweiDashProgramManifest: (manifestString: string, programStart: string) => {
    manifestString = DashPlayerUtil.ttmlParser(manifestString);
    manifestString = DashPlayerUtil.removeUnsupportedAudioCodecs(manifestString);
    manifestString = DashPlayerUtil.addUTCTiming(manifestString);
    manifestString = DashPlayerUtil.addPresentationTimeOffset(manifestString, programStart);
    manifestString = DashPlayerUtil.addHardOfHearing(manifestString);
    manifestString = DashPlayerUtil.tickleMyPSSH(manifestString);
    return manifestString;
  },

  convertHuaweiDashVodManifest: (manifestString: string, cappingLevel: number) => {
    manifestString = DashPlayerUtil.ttmlParser(manifestString);
    manifestString = DashPlayerUtil.removeUnsupportedAudioCodecs(manifestString);
    manifestString = DashPlayerUtil.tickleMyPSSH(manifestString);
    if (cappingLevel !== -1) {
      manifestString = DashPlayerUtil.removeProfilesAboveCappingLevel(manifestString, cappingLevel);
    }
    return manifestString;
  },

  removeProfilesAboveCappingLevel: (manifestString: string, cappingLevel: number) => {
    if (parser.validate(manifestString)) {
      var jsonObj = parser.parse(manifestString, {
        ignoreAttributes: false,
      });

      if (jsonObj.MPD.Period.AdaptationSet && jsonObj.MPD.Period.AdaptationSet.length > 0) {
        const representations = jsonObj.MPD.Period.AdaptationSet[0].Representation as {}[];
        var i = representations.length;
        while (i--) {
          let profile = representations[i];
          if (profile['@_height'] > cappingLevel) {
            representations.splice(i, 1);
          }
        }
        return DashPlayerUtil.convertToXml(jsonObj);
      }
    }
    return manifestString;
  },

  removeUnsupportedAudioCodecs: (manifestString: string) => {
    const debug = localStorage.getItem('debug') === 'true' ? true : false;
    if (parser.validate(manifestString)) {
      var jsonObj = parser.parse(manifestString, {
        ignoreAttributes: false,
      });

      if (jsonObj.MPD.Period.AdaptationSet && jsonObj.MPD.Period.AdaptationSet.length > 0) {
        const copy = jsonObj.MPD.Period.AdaptationSet.slice(0);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        jsonObj.MPD.Period.AdaptationSet.forEach((adaptationSet: any, index: number) => {
          if (adaptationSet['@_codecs'] === '.mp3') {
            if (debug) {
              console.error('Player error. Unsupported audio codec: mp3.');
            }
            delete copy[index];
          }
          if (adaptationSet['@_codecs'] === 'ac-3') {
            if (debug) {
              console.error('Player error. Unsupported audio codec: ac-3.');
            }
            delete copy[index];
          }
        });
        jsonObj.MPD.Period.AdaptationSet = copy;
        return DashPlayerUtil.convertToXml(jsonObj);
      }
    }
    return manifestString;
  },

  ttmlParser: (manifestString: string) => {
    if (parser.validate(manifestString)) {
      var jsonObj = parser.parse(manifestString, {
        ignoreAttributes: false,
      });

      if (jsonObj.MPD.Period.AdaptationSet && jsonObj.MPD.Period.AdaptationSet.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        jsonObj.MPD.Period.AdaptationSet.forEach((adaptationSet: any, index: number) => {
          if (adaptationSet['@_contentType'] === 'text') {
            if (adaptationSet.Representation['@_codecs'].toLowerCase() === 'stpp.ttml.im1t') {
              adaptationSet.Representation['@_codecs'] = 'stpp.TTML.im1t';
            }
          }
        });

        return DashPlayerUtil.convertToXml(jsonObj);
      }
    }
    return manifestString;
  },

  tickleMyPSSH: (manifestString: string) => {
    if (parser.validate(manifestString)) {
      var jsonObj = parser.parse(manifestString, {
        ignoreAttributes: false,
      });

      if (jsonObj.MPD.Period.AdaptationSet && jsonObj.MPD.Period.AdaptationSet.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        jsonObj.MPD.Period.AdaptationSet.forEach((adaptationSet: any, index: number) => {
          if (adaptationSet['@_contentType'] === 'video' || adaptationSet['@_contentType'] === 'audio') {
            if (adaptationSet.ContentProtection && adaptationSet.ContentProtection.length > 0) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              adaptationSet.ContentProtection.forEach((protection: any, idx: number) => {
                if (protection['@_schemeIdUri'] === WIDEVINE_UUID) {
                  const pssh = protection['cenc:pssh']['#text'];
                  if (atob(pssh).indexOf('verimatrixaltibox') !== -1) {
                    const newPSSHBox = ticklePSSHBox(pssh);
                    if (newPSSHBox !== undefined) {
                      jsonObj.MPD.Period.AdaptationSet[index].ContentProtection[idx]['cenc:pssh']['#text'] = newPSSHBox;
                    }
                  }
                }
              });
            }
          }
        });
        return DashPlayerUtil.convertToXml(jsonObj);
      }
    } else {
      console.error('MPD NOT VALID XML');
    }
    return manifestString;
  },

  convertToXml: (jsonObj: Object): string => {
    let JsonToXMLParser = parser.j2xParser;
    var defaultOptions = {
      ignoreAttributes: false,
      format: true,
      indentBy: '\t',
      supressEmptyNode: true,
    };
    const jsonToXml = new JsonToXMLParser(defaultOptions);
    return jsonToXml.parse(jsonObj);
  },

  addHardOfHearing: (manifestString: string) => {
    return DashPlayerUtil.replace(
      manifestString,
      /<Accessibility schemeIdUri="urn:tva:metadata:cs:AudioPurposeCS:2007" value="2"\/>/g,
      '<Accessibility schemeIdUri="urn:mpeg:dash:role:2011" value="HH"/>',
    );
  },

  createString: (inputBuffer: Uint8Array): string => {
    return new TextDecoder('utf-8').decode(inputBuffer);
  },

  createInt8Array: (manifestString: string): Uint8Array => {
    return new TextEncoder().encode(manifestString);
  },

  addPresentationTimeOffset: (manifestString: string, programStart: string) => {
    if (parser.validate(manifestString)) {
      var jsonObj = parser.parse(manifestString, {
        ignoreAttributes: false,
      });

      if (jsonObj.MPD.Period.AdaptationSet.length > 1) {
        const firstAdaptationSet = jsonObj.MPD.Period.AdaptationSet[0];
        const segmentTemplate = firstAdaptationSet.SegmentTemplate;
        if (segmentTemplate && segmentTemplate['@_presentationTimeOffset'] === undefined) {
          if (jsonObj.MPD.Period['@_id'] === '') {
            const timescale = segmentTemplate['@_timescale'];
            const publishTime = moment(jsonObj.MPD['@_publishTime']);
            const startDate = moment(programStart, 'YYYYMMDDHHmmss');
            const diffSinceStart = startDate.diff(publishTime, 'seconds');
            const offset = diffSinceStart * timescale;

            // add presentationTimeOffset to ALL SegmentTemplates
            return DashPlayerUtil.replace(
              manifestString,
              /SegmentTemplate/g,
              'SegmentTemplate presentationTimeOffset="' + offset + '"',
            );
          } else {
            // has NO presentationTimeOffset
            const duration = segmentTemplate['@_duration'];
            const startNumber = segmentTemplate['@_startNumber'];
            const offset = duration * startNumber;
            // add presentationTimeOffset to ALL SegmentTemplates
            return DashPlayerUtil.replace(
              manifestString,
              /SegmentTemplate/g,
              'SegmentTemplate presentationTimeOffset="' + offset + '"',
            );
          }
        }
      }
    }
    return manifestString;
  },

  addUTCTiming: (manifestString: string) => {
    if (parser.validate(manifestString)) {
      var jsonObj = parser.parse(manifestString, {
        ignoreAttributes: false,
      });

      if (jsonObj.MPD && jsonObj.MPD.UTCTiming === undefined) {
        return DashPlayerUtil.replace(
          manifestString,
          '<Period',
          '<UTCTiming schemeIdUri="urn:mpeg:dash:utc:http-iso:2014" value="https://time.akamai.com/?iso"/>\n<Period',
        );
      }
    }
    return manifestString;
  },

  replace: (manifestString: string, whatString: string | RegExp, withString: string) => {
    return manifestString.replace(whatString, withString);
  },
};

// Make an element draggagle
export function makeElementDraggable(id: string) {
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  let element = document.getElementById(id);
  if (element) {
    /* if present, the header is where you move the DIV from:*/
    element.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e: MouseEvent) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: MouseEvent) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    if (element) {
      element.style.top = element.offsetTop - pos2 + 'px';
      element.style.left = element.offsetLeft - pos1 + 'px';
    }
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
