import { formatSeasonAndEpisode } from '../../../../utils/tvUtils';
import { missingCoverUrlLandscape } from '../../../../utils/vodUtils';
import styles from './PlayerAssetCard.module.scss';
import './style.scss';

interface AssetCardProps {
  image: string;
  badge?: JSX.Element;
  currentTitle?: string;
  currentSeason?: string;
  currentEpisode?: string;
  showDetailsPanel?: () => void;
}
const PlayerAssetCard: React.FC<AssetCardProps> = ({
  image,
  badge,
  currentTitle,
  currentSeason,
  currentEpisode,
  showDetailsPanel,
}: AssetCardProps) => {
  const renderSeasonAndEpisodePrefix = () => {
    return currentSeason && currentEpisode ? (
      <>&nbsp;{`- ${formatSeasonAndEpisode(currentSeason, currentEpisode)}`}</>
    ) : null;
  };

  return (
    <button
      tabIndex={0}
      className={styles.assetCardContainer}
      onClick={() => (showDetailsPanel ? showDetailsPanel() : null)}
    >
      <div className={styles.assetCard}>
        <div className={styles.badgeContainer}>{badge}</div>
        <div className={styles.overlay}></div>
        <img className={styles.assetImage} alt={currentTitle} src={image ? image : missingCoverUrlLandscape} />
      </div>
      <div className={styles.titleContainer}>
        <h1 className={styles.assetTitle}>{currentTitle}</h1>
        <h1 className={styles.assetTitlePrefix}>{renderSeasonAndEpisodePrefix()}</h1>
      </div>
    </button>
  );
};
export default PlayerAssetCard;
