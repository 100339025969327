import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useTracking } from '../../hooks/useTracking';
import { GAaction, Pvr } from '../../interfaces';
import { deleteRecordings } from './api';
import { recordingKeys as keys } from './keys';
import { Recording } from './types';
import { isSeries } from './utils';
import { cancelRecording } from '../../api/recording';
import { recordingIsSingle } from '../../typeGuards';

interface UseDeleteRecordingArguments {
  callback?: () => void;
  mode?: 'series' | 'season' | 'episode';
  shouldGoBackOnDelete?: boolean;
}
/**
 * For Typescript to properly infer the types for all the callbacks -
 * we need to explicitly define the argument type of the callback `onMutate`
 */
interface UseDeleteRecordingMutateArguments {
  ids: string[];
}

/**
 * Deletes a recording or a selection of multiple recordings
 * @param Object containing the options for the mutation
 * @returns Mutation result
 */
export function useDeleteRecording({ callback, mode, shouldGoBackOnDelete }: UseDeleteRecordingArguments = {}) {
  const queryClient = useQueryClient();
  const { trackEvent } = useTracking();
  const history = useHistory();

  return useMutation(
    async ({ ids }) => {
      if (!Array.isArray(ids)) {
        throw new Error('The useDeleteRecording mutation expects an array of ids.');
      }

      return await deleteRecordings(ids);
    },
    {
      onMutate: async ({ ids }: UseDeleteRecordingMutateArguments) => {
        const previousRecordings = queryClient.getQueryData<Recording.Query.Union[]>(keys.all);

        queryClient.setQueryData<Recording.Query.Union[] | undefined>(keys.all, (old) => {
          return old?.filter((recording) => filterByStringArray(recording, ids));
        });

        const selectedRecording = previousRecordings?.find((recording) => ids.includes(recording.pvrId));

        return { previousRecordings, selectedRecording };
      },
      onError: (_, __, ctx) => {
        if (ctx?.previousRecordings) {
          queryClient.setQueryData<Recording.Query.Union[]>(keys.all, ctx.previousRecordings);
        }
      },
      onSuccess: (_, __, ctx) => {
        queryClient.invalidateQueries(keys.all);

        const trackingLabel = getDeleteTrackingLabel(ctx?.selectedRecording);

        if (trackingLabel) {
          trackEvent({
            action: getTrackingAction(mode),
            label: trackingLabel,
          });
        }

        if (callback) {
          callback();
        }

        if (shouldGoBackOnDelete) {
          history.goBack();
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(keys.all);
      },
    },
  );
}

function filterByStringArray(recording: Recording.Query.Union, ids: string[]) {
  if (isSeries(recording)) {
    const newId = recording.periodPVRTaskId ?? recording.pvrId;
    return !ids.includes(newId);
  }

  return !ids.includes(recording.pvrId);
}

function getTrackingAction(mode: 'series' | 'season' | 'episode' | undefined) {
  switch (mode) {
    case 'series':
      return GAaction.recordDeleteSeries;
    case 'season':
      return GAaction.recordDeleteSeason;
    case 'episode':
      return GAaction.recordDeleteProgram;
    default:
      return GAaction.recordDeleteProgram;
  }
}

function getDeleteTrackingLabel(recording: Recording.Query.Union | undefined) {
  if (!recording) {
    return undefined;
  }

  return `${recording.channelName} - ${recording.pvrName}`;
}

interface UseCancelRecordingArguments {
  callback?: () => void;
}
interface UseCancelRecordingMutateArguments {
  recording: Pvr.Recording | Pvr.SingleRecording;
  onlyCancelEpisode?: boolean;
}

/**
 * Cancels a single recording or series recording
 * @param Object containing the options for the mutation
 * @returns Mutation result
 */
export function useCancelRecording({ callback }: UseCancelRecordingArguments = {}) {
  const queryClient = useQueryClient();
  const { trackEvent } = useTracking();

  return useMutation(
    async ({ recording, onlyCancelEpisode }) => {
      if (!recording) {
        throw new Error('The useCancelRecording mutation expects a recording.');
      }

      return await cancelRecording(recording, onlyCancelEpisode);
    },
    {
      onMutate: async ({ recording, onlyCancelEpisode }: UseCancelRecordingMutateArguments) => {
        const previousRecordings = queryClient.getQueryData<Recording.Query.Union[]>(keys.scheduled());

        return { previousRecordings };
      },
      onError: (_, __, ctx) => {
        if (ctx?.previousRecordings) {
          queryClient.setQueryData<Recording.Query.Union[]>(keys.scheduled(), ctx.previousRecordings);
        }
      },
      onSuccess: (_, { recording, onlyCancelEpisode }) => {
        queryClient.invalidateQueries(keys.scheduled());

        if (recordingIsSingle(recording) || onlyCancelEpisode) {
          return trackEvent({
            action: GAaction.recordProgramCancel,
            label: getCancelTrackingLabel(recording),
          });
        }

        trackEvent({
          action: GAaction.recordSeriesCancel,
          label: getCancelTrackingLabel(recording),
        });

        if (callback) {
          callback();
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(keys.scheduled());
      },
    },
  );
}

function getCancelTrackingLabel(recording: Pvr.Recording | Pvr.SingleRecording) {
  const label = `${recording.channelName} - ${recording.pvrName}`;

  if (recordingIsSingle(recording)) {
    return label;
  }

  return `${label} - S${recording.seasonNum} E${recording.subNum}`;
}
