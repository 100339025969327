import React, { useEffect, useState } from 'react';
import { usePrefersReducedMotion } from '../../../hooks/usePrefersReducedMotion';

interface FadeInProps {
  delay?: number | string;
  duration?: number | string;
  tag?: string;
  childTag?: string;
  className?: string;
  childClassName?: string;
  visible?: boolean;
  slideUp?: boolean;
  onComplete?: () => void;
  children: React.ReactNode;
}

export default function FadeIn({
  duration = 400,
  delay = 50,
  tag,
  childTag,
  className,
  childClassName,
  visible,
  slideUp,
  onComplete,
  children,
}: FadeInProps) {
  const [maxIsVisible, setMaxIsVisible] = useState(0);
  const transitionDuration = typeof duration === 'number' ? duration : Number(duration);
  const transitionDelay = typeof delay === 'number' ? delay : Number(delay);
  const WrapperTag = tag || ('div' as unknown as typeof React.Component);
  const ChildTag = childTag || ('div' as unknown as typeof React.Component);
  const isVisible = typeof visible === 'undefined' ? true : visible;

  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    let count = React.Children.count(children);
    if (!isVisible) {
      // Animate all children out
      count = 0;
    }

    if (count === maxIsVisible) {
      // We're done updating maxVisible, notify when animation is done
      const timeout = setTimeout(() => {
        if (onComplete) {
          onComplete();
        }
      }, transitionDuration);
      return () => clearTimeout(timeout);
    }

    // Move maxIsVisible toward count
    const increment = count > maxIsVisible ? 1 : -1;
    const timeout = setTimeout(() => {
      setMaxIsVisible(maxIsVisible + increment);
    }, transitionDelay);

    return () => clearTimeout(timeout);
  }, [transitionDelay, maxIsVisible, isVisible, transitionDuration, children, onComplete]);

  if (prefersReducedMotion) {
    return (
      <WrapperTag className={className}>
        {React.Children.map(children, (child, i) => {
          return <ChildTag className={childClassName}>{child}</ChildTag>;
        })}
      </WrapperTag>
    );
  }

  const slideUpValue = slideUp ? '20px' : '';

  return (
    <WrapperTag className={className}>
      {React.Children.map(children, (child, i) => {
        return (
          <ChildTag
            className={childClassName}
            style={{
              transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
              transform: maxIsVisible > i ? 'none' : `translateY(${slideUpValue})`,
              opacity: maxIsVisible > i ? 1 : 0,
            }}
          >
            {child}
          </ChildTag>
        );
      })}
    </WrapperTag>
  );
}
