import { RefObject, useEffect } from 'react';
import { Key } from 'ts-key-enum';

// https://usehooks.com/useOnClickOutside/
export function useOnOutsideClick(
  ref: RefObject<HTMLDivElement>,
  handler: (event: MouseEvent | TouchEvent | KeyboardEvent) => void,
) {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
        if (event instanceof KeyboardEvent && event.key !== Key.Enter) {
          return;
        }

        if (event.target instanceof Element) {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        }
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
        document.removeEventListener('keydown', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
}
