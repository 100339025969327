import { Environments } from './Environments';

export const UbiSecure = function () {
  const environments = Environments();
  const currentEnvironment = environments.getCurrentApiEnvironment();

  const UbiSecureClientSecrets = {
    production: '4IW5qG9o6YeTsqyT8ABtc5rELPUr3D3I',
    at: 'bGzjp0bab9vHBoM-nA3eW3FlBSpAx8Ca',
    st: 'bGzjp0bab9vHBoM-nA3eW3FlBSpAx8Ca',
    // int: '',
  };

  const UbiSecureApiClientIDs = {
    production: '9d737891-4851-467c-aab3-4e91483a29ef',
    at: 'd5c15f8c-1216-4062-9e8a-5f0598066039',
    st: 'a79a7c01-b4f3-4a62-afcb-74d0f9dc6dd9',
    // int: 'd0d3ee3b-771f-4daf-826b-f4dc633c6001',
  };

  const UbiSecureClientIDs = {
    production: '55acdc88-7014-4da7-8c54-86a9752ebf7f',
    at: '7f32d176-e0bc-4890-b494-1aca373e3088',
    st: '',
    // int: '',
  };

  const UbiSecureLoginURL = {
    production: 'https://idconnect.cloud',
    at: 'https://sso.test.lyse.no',
    st: 'https://sso.test.lyse.no',
    // int: 'https://sso-int.idconnect.cloud:8443',
  };

  const UbiSecureSettings = {
    logout_url: '/uas/logout',
    token_url: '/uas/oauth2/token',
  };

  const UbiCallbackUrls = {
    prod_no: 'https://tv.altibox.no/auth/callback/',
    prod_dk: 'https://tv.altibox.dk/auth/callback/',
    prod_net: 'https://tv.altibox.net/auth/callback/',
    beta_no: 'https://tvbeta.altibox.no/auth/call-me-back/',
    beta_dk: 'https://tvbeta.altibox.dk/auth/call-me-back/',
    beta_net: 'https://tvbeta.altibox.dk/auth/call-me-back/',
    staging: 'https://atvweb-st3.services.snartibox.net/auth/callback/',
  };

  function getLoginURL() {
    return UbiSecureLoginURL[currentEnvironment];
  }

  function setUbiCallbackUrl(url: string) {
    url = url ? url : UbiCallbackUrls.beta_no;
    localStorage.setItem('call-me-back', url);
    document.location.reload();
  }

  function getRedirectURL() {
    return (
      localStorage.getItem('call-me-back') ||
      window.location.protocol + '//' + document.location.host + '/auth/callback/'
    );
  }

  function getCurrentClientId() {
    return UbiSecureClientIDs[currentEnvironment];
  }

  function getCurrentClientSecret() {
    return UbiSecureClientSecrets[currentEnvironment];
  }

  function getCurrentApiClientId() {
    return UbiSecureApiClientIDs[currentEnvironment];
  }

  return {
    UbiCallbackUrls: UbiCallbackUrls,
    setUbiCallbackUrl: setUbiCallbackUrl,
    logout_url: UbiSecureSettings.logout_url,
    token_url: UbiSecureSettings.token_url,
    url: getLoginURL(),
    redirect_url: getRedirectURL(),
    client_id: getCurrentClientId(),
    client_secret: getCurrentClientSecret(),
    api_client_id: getCurrentApiClientId(),
  };
};
