import moment from 'moment';
import { useMemo, useCallback } from 'react';
import { BroadcastStatus, Program } from '../interfaces';
import { useThirdPartyCatchupProgram } from '../queries/third-party-catchup/queries';
import { broadcastingNow, broadcastProgress, toMoment } from '../utils/huaweiUtils';

type UseProgram<P extends object> = Program & P;

export function useProgram<P extends object>(props: UseProgram<P>) {
  const { seasonNum, subNum, starttime, endtime, istvod, recordedMediaIds } = props;

  const { data: info } = useThirdPartyCatchupProgram(props);

  const externalChannel = info?.data;

  const startTime = useMemo(() => {
    return starttime ? toMoment(starttime) : undefined;
  }, [starttime]);

  const endTime = useMemo(() => {
    return endtime ? toMoment(endtime) : undefined;
  }, [endtime]);

  const episodeNumber = useMemo(() => {
    const season = seasonNum ? `S${seasonNum}` : '';
    const episode = subNum ? `E${subNum}` : '';

    if (season && episode) {
      return `${season} ${episode}`;
    }

    return undefined;
  }, [seasonNum, subNum]);

  const broadcastStatus = useMemo(() => {
    if (broadcastingNow({ starttime, endtime })) {
      return BroadcastStatus.Live;
    }

    if (endTime && endTime.isBefore(moment())) {
      return BroadcastStatus.Finished;
    }

    if (startTime && startTime.isAfter(moment())) {
      return BroadcastStatus.Future;
    }

    return BroadcastStatus.Finished;
  }, [endTime, endtime, startTime, starttime]);

  const getProgress = useCallback(() => {
    return broadcastStatus === BroadcastStatus.Live ? broadcastProgress({ starttime, endtime }) : 0;
  }, [broadcastStatus, endtime, starttime]);

  const canCatchup = useMemo(() => {
    const isExternalChannel = externalChannel !== undefined;

    if (isExternalChannel || (istvod && istvod === '0')) {
      return false;
    } else {
      return recordedMediaIds && recordedMediaIds.length > 0 && toMoment(endtime) < moment();
    }
  }, [endtime, externalChannel, istvod, recordedMediaIds]);

  return {
    startTime,
    endTime,
    episodeNumber,
    broadcastStatus,
    canCatchup,
    getProgress,
    ...props,
  };
}
