import {
  NielsenChannelMapping,
  NielsenContentMetadata,
  NielsenEventTypes,
  NielsenFunctions,
  PortalMenu,
  StreamType,
  UnionAssetTypes,
} from '../interfaces';
import {
  getNielsenChannelStationId,
  getNielsenChannelSubbrand,
  getNielsenContentAdLoadType,
  getNielsenContentAirdate,
  getNielsenContentId,
  getNielsenContentIsFullEpisode,
  getNielsenContentLength,
  getNielsenContentProgram,
  getNielsenContentTitle,
} from '../utils/trackingUtils';

interface BufferEvent {
  type: string;
  buffering: boolean;
}

export class NielsenTracking {
  public nielsenFunctions: NielsenFunctions | undefined;
  public player: shaka.Player | undefined;
  public video: HTMLVideoElement | undefined;
  public trackingInterval: NodeJS.Timer | undefined;
  public streamType: StreamType | undefined;
  public metadata: NielsenContentMetadata | undefined;
  public playheadPosition: number | undefined;
  public _ended: boolean | undefined;
  public _tracking: boolean | undefined;
  private buffering: boolean | undefined;

  public get assetTrackingId() {
    return this.metadata?.assetid;
  }

  public get isTracking() {
    return this._tracking;
  }

  private set ended(end: boolean) {
    this._ended = end;
  }

  private set tracking(tracking: boolean) {
    this._tracking = tracking;
  }

  public init(
    player: shaka.Player,
    video: HTMLVideoElement,
    streamType: StreamType,
    asset: UnionAssetTypes | undefined,
    seriesTitle: string | undefined,
    nielsenChannelMapping: NielsenChannelMapping[] | undefined,
    svodKiosk: PortalMenu | undefined,
  ) {
    if (this.trackingInterval) {
      clearInterval(this.trackingInterval);
    }
    this.player = player;
    this.video = video;
    this.streamType = streamType;
    this.metadata = this.createNielsenMetadata(
      this.player,
      this.video,
      this.streamType,
      asset,
      seriesTitle,
      nielsenChannelMapping,
      svodKiosk,
    );
    this.nielsenFunctions = window.nielsen as NielsenFunctions;
    this.playheadPosition = -1;
    this._ended = false;
    this._tracking = false;
    return this;
  }

  public track() {
    if (!this.nielsenFunctions?.ggPM || !this.shouldTrack()) {
      return;
    }

    this.tracking = true;
    this.ended = false;
    this.loadMetadata();
    this.listenToVideoPlaying();
    this.listenToVideoPause();
    this.listenToVideoEnded();
    this.listenToVideoBuffer();
    this.listenToOffline();
    this.listenToWindowClose();
  }

  public stopTracking = () => {
    if (!this.nielsenFunctions?.ggPM && !this._tracking) {
      return;
    }

    this.tracking = false;
    this.endNielsenTrackingSession();
    this.video?.removeEventListener('playing', this.handleVideoPlaying);
    this.video?.removeEventListener('pause', this.handleVideoPause);
    this.video?.removeEventListener('ended', this.handleVideoEnded);
    this.player?.removeEventListener('buffering', (e) => this.handleBufferEvent(e as unknown as BufferEvent));
    window.removeEventListener('offline', this.handleOffline);
    window.removeEventListener('beforeunload', this.handleWindowClose);
    this.playheadPosition = -1;
  };

  public shouldTrack() {
    return Boolean(this.metadata?.subbrand) || this.streamType === StreamType.VOD;
  }

  public loadNewMetadata(
    player: shaka.Player,
    video: HTMLVideoElement,
    streamType: StreamType,
    asset: UnionAssetTypes | undefined,
    seriesTitle: string | undefined,
    nielsenChannelMapping: NielsenChannelMapping[] | undefined,
    svodKiosk: PortalMenu | undefined,
  ) {
    if (
      this.metadata?.assetid !== getNielsenContentId(asset) &&
      this.metadata?.subbrand === getNielsenChannelSubbrand(asset, nielsenChannelMapping!, svodKiosk!)
    ) {
      this.nielsenFunctions?.ggPM(NielsenEventTypes.END, this.playheadPosition?.toString());
      clearInterval(this.trackingInterval);
      this.metadata = this.createNielsenMetadata(
        player,
        video,
        streamType,
        asset,
        seriesTitle,
        nielsenChannelMapping,
        svodKiosk,
      );
      this.loadMetadata();
      this.playheadPosition = -1;
      this.updateInterval();
    }
  }

  private handleWindowClose = () => {
    if (!this.nielsenFunctions?.ggPM) {
      return;
    }
    this.end();
  };

  private handleOffline = () => {
    if (!this.nielsenFunctions?.ggPM) {
      return;
    }
    this.end();
    this.stopPlayheads();
  };

  private handleVideoPause = () => {
    if ((!this._ended && !this.nielsenFunctions?.ggPM) || this.player?.isBuffering()) {
      return;
    }
    this.nielsenFunctions?.ggPM(NielsenEventTypes.STOP, this.playheadPosition?.toString());
    this.stopPlayheads();
  };

  private handleBufferEvent = (bufferEvent: BufferEvent) => {
    if (this.playheadPosition === -1 || this._ended || this.video?.paused) {
      return;
    }
    if ((bufferEvent as unknown as BufferEvent).buffering && !this.buffering) {
      this.buffering = true;
      this.nielsenFunctions?.ggPM(NielsenEventTypes.STOP, this.playheadPosition?.toString());
    }
  };

  private loadMetadata() {
    this.nielsenFunctions?.ggPM(NielsenEventTypes.LOAD_METADATA, this.metadata);
  }

  private listenToVideoBuffer() {
    this.player?.addEventListener('buffering', (e) => this.handleBufferEvent(e as unknown as BufferEvent));
  }

  private listenToWindowClose() {
    window.addEventListener('beforeunload', this.handleWindowClose);
  }

  private listenToOffline() {
    window.addEventListener('offline', this.handleOffline);
  }

  private listenToVideoPause() {
    this.video?.addEventListener('pause', this.handleVideoPause);
  }

  private listenToVideoEnded() {
    this.video?.addEventListener('ended', this.handleVideoEnded);
  }

  private handleVideoEnded = () => {
    this.end();
  };

  /**
   * Starts a timer which sets playheads on one second intervals
   */
  private listenToVideoPlaying() {
    // first playhead
    if (this.playheadPosition === -1) {
      // first playhead needs to be the same as pbstarttm
      if (this.metadata?.pbstarttm && this.nielsenFunctions) {
        this.playheadPosition = Number(this.metadata?.pbstarttm);
        this.nielsenFunctions.ggPM(NielsenEventTypes.SET_PLAYHEAD_POSITION, this.playheadPosition?.toString());
      }
      this.video?.addEventListener('playing', this.handleVideoPlaying);
    }
  }

  private updateInterval = () => {
    // Timer for setting playheads
    this.trackingInterval = setInterval(() => {
      if (!this.player || this.player.isBuffering() || this.video?.paused || !this.nielsenFunctions?.ggPM) {
        return;
      }

      const newPlayheadPosition = this.getNielsenTimeStamp();

      if (newPlayheadPosition === this.playheadPosition) {
        return;
      }
      this.playheadPosition = this.getNielsenTimeStamp();
      this.nielsenFunctions.ggPM(NielsenEventTypes.SET_PLAYHEAD_POSITION, this.playheadPosition?.toString());
      if (this.buffering) {
        this.buffering = false;
      }
    }, 1000);
  };

  private handleVideoPlaying = () => {
    this.updateInterval();
  };

  private end() {
    if (this._ended) {
      return;
    }
    this.ended = true;
    this.nielsenFunctions?.ggPM(NielsenEventTypes.END, this.playheadPosition?.toString());
  }

  private stopPlayheads = () => {
    clearInterval(this.trackingInterval);
    this.trackingInterval = undefined as unknown as NodeJS.Timer;
  };

  private endNielsenTrackingSession() {
    this.stopPlayheads();
    this.end();
  }

  private getNielsenTimeStamp() {
    const videoCurrentTime = Math.ceil(this.video?.currentTime ?? 0);
    switch (this.streamType) {
      case StreamType.LIVE: {
        const timeline = Number(this.player?.getPresentationStartTimeAsDate()?.getTime()) / 1000;
        return timeline + videoCurrentTime;
      }
      case StreamType.VOD: {
        return Math.floor(this.video?.currentTime ?? 0);
      }
      default: {
        return Math.floor(this.video?.currentTime ?? 0);
      }
    }
  }

  private createNielsenMetadata(
    player: shaka.Player,
    video: HTMLVideoElement,
    streamType: StreamType,
    asset: UnionAssetTypes | undefined,
    seriesTitle: string | undefined,
    nielsenChannelMapping: NielsenChannelMapping[] | undefined,
    svodKiosk: PortalMenu | undefined,
  ) {
    return {
      type: 'content',
      assetid: getNielsenContentId(asset) ?? '',
      program: seriesTitle ?? getNielsenContentProgram(asset) ?? '',
      title: getNielsenContentTitle(asset) ?? '',
      length:
        streamType === StreamType.LIVE ? getNielsenContentLength(asset) ?? '' : Math.floor(video.duration).toString(),
      airdate: getNielsenContentAirdate(asset) ?? '',
      isfullepisode: getNielsenContentIsFullEpisode(asset),
      adloadtype: getNielsenContentAdLoadType(asset),
      islivestn: streamType === StreamType.LIVE ? 'y' : 'n',
      subbrand: getNielsenChannelSubbrand(asset, nielsenChannelMapping!, svodKiosk!) ?? '',
      pbstarttm:
        streamType !== StreamType.LIVE
          ? ''
          : (
              Number(player.getPresentationStartTimeAsDate()?.getTime()) / 1000 +
              Math.ceil(video.currentTime)
            ).toString(),
      stationId: getNielsenChannelStationId(asset, nielsenChannelMapping!, svodKiosk!) ?? '',
    };
  }
}
export const NielsenTracker = Object.seal(new NielsenTracking());
