import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  RootState,
  VodAsset as VodAssetInterface,
  ApiAuthResponse,
  HeaderDisplayType,
  HeaderInteractionType,
} from '../../../interfaces';
import VodAssetList from '../../../components/VodAssetList';
import Header from '../../app/Header';
import { fetchMyContent } from '../actions';
import { prepareMyContent } from '../../../utils/vodUtils';

import { searchConfig } from '../../../config';
import Spinner from '../../../components/Spinner';
import FullscreenMessage from '../../../components/FullscreenMessage';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../components/Footer/index';
import StickyHeader from '../../../views/app/StickyHeader';
import i18n from '../../../i18n';

interface Props extends RouteComponentProps<{}, {}, { scrollOffset?: number }> {
  myContent: VodAssetInterface[] | undefined;
  mySeries: VodAssetInterface[] | undefined;
  mySeasons: VodAssetInterface[] | undefined;
  auth: ApiAuthResponse | undefined;
  getMyContent: (count: number) => Promise<void>;
  isGuest: boolean;
  loggedInWithCredentials: boolean;
}

class ViewAllAssets extends Component<Props> {
  get guestMode() {
    return (
      <div>
        <FullscreenMessage showLoginButton={true}>{i18n.t('log in to see your assets')}</FullscreenMessage>
      </div>
    );
  }

  get emptyMode() {
    return (
      <div>
        <FullscreenMessage showLoginButton={false}>
          <span className="no-content-heart aib-icon">M</span>
          {i18n.t('my content is empty')}
        </FullscreenMessage>
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.isGuest && !nextProps.isGuest) {
      this.props.getMyContent(-1);
    }
  }

  componentDidMount() {
    // tslint:disable-next-line
    if (this.props.location && this.props.location.state && this.props.location.state.scrollOffset) {
      setTimeout(() => {
        if (this.props.location.state.scrollOffset) {
          window.scrollTo(0, this.props.location.state.scrollOffset);
        }
      }, 500);
    } else {
      window.scrollTo(0, 0);
    }
    if (!this.props.isGuest) {
      this.props.getMyContent(-1);
    }
  }

  render() {
    return (
      <div className="main-frame">
        <Helmet>
          <title>{i18n.t<string>('my content')}</title>
        </Helmet>
        <StickyHeader searchConfig={searchConfig.vod} />
        <Header
          title={i18n.t<string>('my content')}
          displayType={HeaderDisplayType.Solid}
          interactionType={HeaderInteractionType.Full}
        />
        {this.props.isGuest ? (
          this.guestMode
        ) : (
          <div className="vod-content vod-asset-full-view">
            {this.props.myContent ? (
              this.props.myContent.length === 0 ? (
                this.emptyMode
              ) : (
                <VodAssetList
                  loggedInWithCredentials={this.props.loggedInWithCredentials}
                  hidePurchasedBadge={false}
                  vods={prepareMyContent(this.props.myContent, this.props.mySeries!, this.props.mySeasons!)}
                />
              )
            ) : (
              <Spinner />
            )}
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      isGuest: state.authReducer.isGuest,
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
      auth: state.authReducer.auth,
      myContent: state.vodReducer.myContent,
      mySeries: state.vodReducer.mySeries,
      mySeasons: state.vodReducer.mySeasons,
    }),
    (dispatch) => ({
      getMyContent: (count: number) => dispatch(fetchMyContent(count)),
    }),
  )(ViewAllAssets),
);
