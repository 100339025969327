import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { VodAsset as VodAssetInterface, VodAssetDisplayMode } from '../../interfaces';
import {
  assetShouldShowBadge,
  Owned,
  isMovie,
  getVodPicture,
  getRentBadgeTextWithInformation,
  getLowestRentedEpisodeCountdown,
} from '../../utils/vodUtils';
import Badge from '../Badge';
import i18n from '../../i18n';
import './style.scss';
import FavouriteIcon from '../FavouriteIcon';
import { routes, imageScaleValues } from '../../config';
import { hasPicture, getImageUrlDimensions } from '../../utils/altiboxassetUtils';
import missingCoverUrlLandscape from './missing-svod-cover.jpeg';
import missingCoverUrl from './missing-vod-cover.png';
import { getSvodPicture } from '../../utils/svodUtil';

interface Props extends VodAssetInterface {
  viewMode: VodAssetDisplayMode;
  assetIconPath?: string;
  baseURL?: string;
  hidePurchasedBadge?: boolean;
  overrideBasePath?: string;
  loggedInWithCredentials?: boolean;
  favouriteView?: boolean;
  scrollOffset?: number;
}

interface State {
  posterURL: string;
  failedToLoadDoNotLoadAgain: boolean;
}

const NAT_GEO_NOW = 'natgeo-now';

class VodAsset extends Component<Props, State> {
  state: State = {
    posterURL: '',
    failedToLoadDoNotLoadAgain: false,
  };

  get shouldShowPosters() {
    const asset = this.props;
    return asset.baseURL?.includes(NAT_GEO_NOW);
  }

  get vodPicture() {
    let asset = this.props;
    return getImageUrlDimensions(getVodPicture(asset), imageScaleValues.vod);
  }

  get svodPicture() {
    let asset = this.props;

    if (this.shouldShowPosters) {
      return getImageUrlDimensions(getVodPicture(asset), imageScaleValues.altiboxAssetView);
    }

    return getImageUrlDimensions(getSvodPicture(asset), imageScaleValues.altiboxAssetView);
  }

  getPosterURL(asset: Props) {
    switch (asset.viewMode) {
      case VodAssetDisplayMode.VOD:
        return hasPicture(asset.picture) ? this.vodPicture : missingCoverUrl;
      case VodAssetDisplayMode.SVOD:
        return hasPicture(asset.picture) ? this.svodPicture : missingCoverUrlLandscape;
    }
  }

  // after placeholder is loaded load the poster or ad img based on width of screen
  handlePlaceholderLoaded = () => {
    if (!this.state.failedToLoadDoNotLoadAgain) {
      const asset = this.props;
      this.setState({ posterURL: this.getPosterURL(asset) });
    }
  };

  handleLoadingError = () => {
    if (!this.state.failedToLoadDoNotLoadAgain) {
      let imageUrl = missingCoverUrl;
      if (this.props.viewMode === VodAssetDisplayMode.SVOD) {
        imageUrl = missingCoverUrlLandscape;
      }

      this.setState({
        failedToLoadDoNotLoadAgain: true,
        posterURL: imageUrl,
      });
    }
  };

  UNSAFE_componentWillMount() {
    this.setState({
      posterURL: this.props.viewMode === VodAssetDisplayMode.VOD ? missingCoverUrl : missingCoverUrlLandscape,
    });
  }

  getBadge(vod: VodAssetInterface) {
    if (this.props.hidePurchasedBadge || this.props.viewMode === VodAssetDisplayMode.SVOD) {
      return null;
    }
    let badge = assetShouldShowBadge(vod);
    if (badge === Owned.RENTED) {
      if (vod.listOfRentedEpisodes && vod.listOfRentedEpisodes.length === 1) {
        const lowestRentPeriod = getLowestRentedEpisodeCountdown(vod.listOfRentedEpisodes);
        return <Badge className="rent" text={getRentBadgeTextWithInformation(lowestRentPeriod.rentPeriod)} />;
      } else if (isMovie(vod)) {
        return <Badge className="rent" text={getRentBadgeTextWithInformation(vod.rentperiod)} />;
      }
      return <Badge className="rent" text={i18n.t<string>('rented')} />;
    } else if (badge === Owned.BOUGHT) {
      return <Badge className="bought" text={i18n.t<string>('bought')} />;
    } else if (Number(vod.price) >= 0) {
      return <Badge className="price" text={`${Math.floor(+vod.price)},-`} />;
    } else {
      return null;
    }
  }

  render() {
    const vod = this.props;
    const isSvod = vod.viewMode === VodAssetDisplayMode.SVOD;

    let assetURL = routes.vod.base + routes.vod.details + '/' + vod.externalContentCode;
    if (vod.baseURL && !vod.overrideBasePath) {
      assetURL = vod.baseURL + '/' + vod.externalContentCode;
    } else if (vod.overrideBasePath) {
      assetURL = vod.overrideBasePath + routes.svod.details + '/' + vod.externalContentCode;
    }
    let showFavouriteIcon = null;
    if (vod.loggedInWithCredentials) {
      if (vod.favouriteView) {
        showFavouriteIcon = <FavouriteIcon isFavourite={true} />;
      } else {
        showFavouriteIcon = <FavouriteIcon isFavourite={Boolean(Number(vod.isfavorited))} />;
      }
    }

    return (
      <li title={vod.name} className={(isSvod ? 'svod-asset ' : '') + 'vod-asset'}>
        {showFavouriteIcon}
        {this.getBadge(vod)}
        <Link
          to={{
            pathname: assetURL,
            state: { prevPath: window.location.pathname, scrollOffset: this.props.scrollOffset },
          }}
        >
          <div className="asset-image-wrapper">
            <img
              className="asset-image fade-in"
              alt={vod.name}
              src={this.state.posterURL}
              onError={this.handleLoadingError}
              onLoad={this.handlePlaceholderLoaded}
            />
            {vod.assetIconPath ? <img className="brand-logo" alt={vod.name} src={vod.assetIconPath} /> : null}
          </div>

          <div className="vod-asset-title">
            {isSvod && !this.shouldShowPosters ? (
              <>
                <div className="svod-title-container">
                  <div className="svod-asset-title body">{vod.name}</div>
                  <div className="svod-asset-genre body-2 uppercase">{vod.genres ? vod.genres.split(',')[0] : ''}</div>
                </div>
              </>
            ) : (
              vod.name
            )}
          </div>
        </Link>
      </li>
    );
  }
}

export default VodAsset;
