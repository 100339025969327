import moment from 'moment';
import { cloneDeep } from 'lodash';
import {
  VodAsset,
  MediaDefinition,
  VodConfig,
  VodSection,
  VodGenre,
  VodConfigDataType,
  Title,
  VodConfigData,
  VodAssetSeason,
  Bookmarks,
  LanguageImage,
  VodConfigSectionType,
  CappedStreams,
  AltiboxAsset,
  AltiboxAssetType,
  StreamTypeObject,
  VodContentProduct,
  VodRentEpisode,
  Filter,
  FilterKeys,
} from '../interfaces';
import i18n from 'i18next';
import { getTranslation } from '../i18n/languageMap';
import { firstBy } from 'thenby';
import { routes } from '../config';
import { getSvodPicture } from './svodUtil';
import isEmpty from 'lodash/isEmpty';
import { hasPicture } from './altiboxassetUtils';
import { ScriptService } from '../controllers/ScriptService';

import missingCoverUrl from '../components/VodAsset/missing-vod-cover.png';
import missingCoverUrlLandscape from '../components/VodAsset/missing-svod-cover.jpeg';
import { Vod } from '../queries/vod/types';
export { missingCoverUrl, missingCoverUrlLandscape };

export enum Owned {
  BOUGHT = 'bought',
  RENTED = 'rented',
}

export function getRentDurationLeftInHours(rentPeriod: string) {
  return Math.floor(parseInt(rentPeriod, 10) / 60 / 60);
}

export function getRentBadgeText(rentPeriod: string) {
  const periodInHours = parseInt(rentPeriod, 10) / 60 / 60;

  if (periodInHours < 1) {
    let rentMinutes = Math.round(periodInHours * 60);
    return `${rentMinutes} ${i18n.t<string>('rent minutes suffix')}`;
  }

  return `${getRentDurationLeftInHours(rentPeriod)} ${i18n.t<string>('rent hours suffix')}`;
}

export function getRentBadgeTextWithInformation(rentPeriod: string) {
  return getRentBadgeText(rentPeriod) + ' ' + i18n.t<string>('time remaining');
}

export function getRentDurationLeftAsString(rentPeriod: string) {
  const rentPeriodLeftInseconds = parseInt(rentPeriod, 10);
  const hours = Math.floor(moment.duration(rentPeriodLeftInseconds, 'seconds').asHours());
  const minutes = moment.duration(rentPeriodLeftInseconds, 'seconds').minutes();
  const seconds = moment.duration(rentPeriodLeftInseconds, 'seconds').seconds();
  const format = `${hours}${i18n.t<string>('hours short')} ${minutes}${i18n.t<string>(
    'minutes short',
  )} ${seconds}${i18n.t<string>('seconds short')}`;
  return format;
}

export function doubleDigits(input: number | string) {
  let output = input.toString();
  if (Number(input) < 10) {
    output = '0' + input.toString();
  }
  return output;
}

export function seriesHasOnlyRentedEpisodes(vod: VodAsset) {
  return vod.hasRentedEpisode && !vod.hasBoughtEpisode;
}
export function seriesHasABoughtEpisode(vod: VodAsset) {
  return vod.hasBoughtEpisode;
}

/**
 * If an asset is rented for longer than a year we treat it as having been bought
 */
export function assetIsRented(vod: VodAsset | Vod.Details | undefined) {
  if (!vod) {
    return false;
  }

  const rentTimeLeftInSeconds = parseInt(vod.rentperiod, 10);
  // 31536000 = (24 * 365 * 60 * 60) = seconds
  return rentTimeLeftInSeconds > 0 && rentTimeLeftInSeconds < 31536000;
}

export function assetIsBought(vod: VodAsset | Vod.Details, myContent?: VodAsset[]) {
  const isBought = Boolean(vod && vod.issubscribed === '1') && !assetIsRented(vod);

  if (myContent && !isEmpty(myContent)) {
    const existsInMyContent = Boolean(vod && myContent.find((x) => x.id === vod.id)) && !assetIsRented(vod);
    return existsInMyContent || isBought;
  }

  return isBought;
}

export function assetShouldPrioritizeBadge(vods: VodAsset[] | undefined) {
  if (!isEmpty(vods)) {
    const myOwnedAssetContains = vods?.map((vod) => {
      return assetIsRented(vod) ? Owned.RENTED : Owned.BOUGHT;
    });

    if (myOwnedAssetContains?.includes(Owned.RENTED)) {
      return Owned.RENTED;
    } else {
      return Owned.BOUGHT;
    }
  }

  return false;
}

export function assetIsOwned(vod: VodAsset, myContent?: VodAsset[]) {
  return assetIsRented(vod) || assetIsBought(vod, myContent);
}

export function assetShouldShowBoughtBadge(vod: VodAsset) {
  return vod.showBoughtBadge && !vod.hasRentedEpisode ? true : false;
}

export function assetShouldShowBadge(vod: VodAsset): Owned | boolean {
  if (vod.showRentedBadge || vod.hasRentedEpisode || !isEmpty(vod?.listOfRentedEpisodes)) {
    return Owned.RENTED;
  }
  if (vod.showBoughtBadge) {
    return Owned.BOUGHT;
  }
  return true;
}

export function addBadgeOnOwnedSeriesAssets(assets: VodAsset[], myContent: VodAsset[]) {
  if (!isEmpty(assets) && !isEmpty(myContent)) {
    return cloneDeep(assets).map((asset: VodAsset) => {
      let ownedAsset = myContent.find((x) => x.id === asset.id);

      if (!ownedAsset) {
        return asset;
      }

      let isBought = assetIsBought(ownedAsset, myContent);
      let isRentedSeriesOrMovie = assetIsRented(ownedAsset);
      let isRentedEpisode = ownedAsset.hasOwnProperty('rentedId');
      let isRented = isRentedSeriesOrMovie || isRentedEpisode;

      if (isRented) {
        ownedAsset.showRentedBadge = true;
        ownedAsset.showBoughtBadge = false;
      } else if (isBought) {
        ownedAsset.showBoughtBadge = true;
        ownedAsset.showRentedBadge = false;
      }

      ownedAsset.isfavorited = asset.isfavorited;

      return ownedAsset;
    });
  } else {
    return assets;
  }
}

export function isEpisode(vod: VodAsset) {
  return !!(vod.fathervodlist && vod.fathervodlist[0] && vod.fathervodlist[0].vodid);
}

export function getEpisodeFromSeries(episodeId: string, vod: VodAsset) {
  let theEpisode;
  if (vod && vod.seasons) {
    vod.seasons.forEach((season: VodAssetSeason) => {
      season.episodes.forEach((episode: VodAsset) => {
        if (episode.id === episodeId) {
          theEpisode = episode;
        }
      });
    });
  }
  return theEpisode;
}

export function getEpisodeIndexFromSeries(episodeId: string, vod: VodAsset) {
  let theEpisode;
  if (vod && vod.seasons) {
    vod.seasons.forEach((season: VodAssetSeason) => {
      season.episodes.forEach((episode: VodAsset, index: number) => {
        if (episode.id === episodeId) {
          theEpisode = index;
        }
      });
    });
  }
  return theEpisode;
}

export function getSeasonIndexFromSeries(episodeId: string, vod: VodAsset) {
  let theSeason;
  if (vod && vod.seasons) {
    vod.seasons.forEach((season: VodAssetSeason, index: number) => {
      season.episodes.forEach((episode: VodAsset) => {
        if (episode.id === episodeId) {
          theSeason = index;
        }
      });
    });
  }
  return theSeason;
}

export function seasonIndexForEpisode(seasons: VodAssetSeason[]) {
  let theIndex = 0;
  let currentEpisode = getEpisodeToPromoteBasedOnSeasons(seasons);

  if (seasons) {
    seasons.forEach((season: VodAssetSeason, seasonIndex: number) => {
      season.episodes.forEach((episode: VodAsset) => {
        if (episode && currentEpisode && episode.id === currentEpisode!.id) {
          theIndex = seasonIndex;
        }
      });
    });
  }
  return theIndex;
}

export function isMovie(vod: VodAsset) {
  return !isSeries(vod);
}

export function isSeries(vod: VodAsset) {
  return vod && vod.seriesType && vod.seriesType === '0';
}

export function hasParent(vod?: VodAsset) {
  return !!(vod && vod.fathervodlist && vod.fathervodlist[0]);
}

const langMap = {
  nb: 'NO',
  no: 'NO',
  en: 'EN',
  da: 'DA',
};

export function getVodDuration(vodAsset: VodAsset) {
  return vodAsset.mediafiles && vodAsset.mediafiles[0] ? Number(vodAsset.mediafiles[0].elapsetime) : 0;
}

interface PossibleEpisodes {
  asset: VodAsset;
  seasonIndex: number;
  episodeIndex: number;
  bookmarkTime: number;
  watchedPercent: number;
}

export function getEpisodeToPromoteBasedOnSeasons(seasons: VodAssetSeason[], continueWatching?: boolean) {
  let promotedAsset: VodAsset | undefined;
  let promotedSeasonIndex: number = 0;
  let promotedEpisodeIndex: number = 0;
  let possibleEpisodes: PossibleEpisodes[] = [];
  if (seasons) {
    seasons.forEach((season: VodAssetSeason, seasonIndex: number) => {
      if (season.episodes) {
        season.episodes.forEach((episode: VodAsset, episodeIndex: number) => {
          let currentBookmarkTime =
            episode.bookmark && episode.bookmark.updateTime ? parseInt(episode.bookmark!.updateTime, 10) : 0;
          possibleEpisodes.push({
            asset: episode,
            seasonIndex: seasonIndex,
            episodeIndex: episodeIndex,
            bookmarkTime: currentBookmarkTime,
            watchedPercent: getPercentWatched(episode),
          });
        });
      }
    });

    let newestUpdateTime = possibleEpisodes.slice(0).sort(
      firstBy<PossibleEpisodes>((a, b) => b!.bookmarkTime - a!.bookmarkTime)
        .thenBy<PossibleEpisodes>((a, b) => b!.seasonIndex - a.seasonIndex)
        .thenBy((a, b) => a.episodeIndex - b!.episodeIndex),
    );

    if (newestUpdateTime[0]) {
      promotedAsset = newestUpdateTime[0].asset;
      promotedSeasonIndex = newestUpdateTime[0].seasonIndex;
      promotedEpisodeIndex = newestUpdateTime[0].episodeIndex;

      let promotedIndex = possibleEpisodes.findIndex((x) => x.asset === promotedAsset);
      let nextEpisode = possibleEpisodes[promotedIndex + 1];
      if (getPercentWatched(promotedAsset) > 97) {
        if (!nextEpisode) {
          if (!continueWatching) {
            return seasons[0].episodes[0];
          } else {
            return undefined;
          }
        } else {
          promotedAsset = getNextEpisode(promotedSeasonIndex, promotedEpisodeIndex, seasons);
        }
      }
    }
  }
  return promotedAsset;
}

export function getNextEpisode(
  seasonIndex: number,
  episodeIndex: number,
  seasons: VodAssetSeason[],
  retry?: boolean,
): VodAsset | undefined {
  let nextEpisode: VodAsset | undefined = undefined;
  let increment = retry ? 0 : 1;
  if (seasons) {
    if (seasons[seasonIndex] && seasons[seasonIndex].episodes[episodeIndex]) {
      nextEpisode = seasons[seasonIndex].episodes[episodeIndex + increment];
      if (!nextEpisode) {
        nextEpisode = getNextEpisode(seasonIndex + increment, 0, seasons, true);
      } else {
        nextEpisode.seasonNumber = seasons[seasonIndex].fathervodlist[0].sitcomnum;
      }
    }
  }
  return nextEpisode;
}

export function getSeasonNumber(seasonIndex: number, seasons: VodAssetSeason[]) {
  return seasons[seasonIndex].fathervodlist[0].sitcomnum;
}

export function getSeriesNameFromBaseVodAsset(baseAsset: VodAsset) {
  if (baseAsset.fatherVod && baseAsset.fatherVod.fatherVod) {
    return baseAsset.fatherVod.fatherVod.name;
  }
  return '';
}

export function appendBookmarksOn(vodAsset: VodAsset, bookmarks: Bookmarks) {
  if (isEpisode(vodAsset)) {
    return addBookmarkToVodAsset(vodAsset, bookmarks);
  } else if (isSeries(vodAsset)) {
    if (vodAsset.seasons) {
      vodAsset.seasons.forEach((season: VodAssetSeason) => {
        season.episodes.forEach((episode: VodAsset) => {
          addBookmarkToVodAsset(episode, bookmarks);
        });
      });
    }
    return vodAsset;
  } else {
    return addBookmarkToVodAsset(vodAsset, bookmarks);
  }
}

function addBookmarkToVodAsset(vodAsset: VodAsset, bookmarks: Bookmarks) {
  if (bookmarks.bookmarkList && vodAsset) {
    vodAsset.bookmark = bookmarks.bookmarkList.find((x) => x.contentId === vodAsset.id);
  }
  return vodAsset;
}

export function getPercentWatched(vodAsset: VodAsset) {
  if (vodAsset.bookmark) {
    const bookmarkTimeInSeconds = parseInt(vodAsset.bookmark.rangeTime, 10);
    const durationInSeconds = getVodDuration(vodAsset);

    if (durationInSeconds > 0) {
      return (bookmarkTimeInSeconds / durationInSeconds) * 100;
    } else {
      return 0;
    }
  }
  return 0;
}

export function getInfo(key: string, vod: VodAsset, promotedVodId?: string) {
  let value = '';
  if (vod.extensionInfo && vod.extensionInfo.length > 0) {
    const info = vod.extensionInfo.filter((x) => x.key.indexOf(key) !== -1);
    if (info.length > 0) {
      value = info[0].value;
    }
  }
  if (vod.seasons && promotedVodId) {
    vod.seasons.forEach((season) => {
      if (season.extensionInfo) {
        const info = season.extensionInfo.filter((x) => x.key.indexOf(key) !== -1);
        if (info.length > 0 && promotedVodId && season.episodes.find((x) => x.id === promotedVodId)) {
          value = info[0].value;
        }
      }
    });
  }
  return value;
}

export function getDescription(vod: VodAsset) {
  let language = langMap[i18n.language.split('-')[0]];
  language = language ? language : 'no';
  const key = 'SYNOPSIS_SHORT_' + language.toUpperCase();
  const info = getInfo(key, vod);

  if (language === 'no' || language === 'NO') {
    return vod.introduce ? vod.introduce : '';
  } else {
    return info ? info : vod.introduce ? vod.introduce : '';
  }
}

export function getShortDescription(vod: VodAsset) {
  let language = langMap[i18n.language.split('-')[0]];
  language = language ? language : 'no';
  const key = 'SYNOPSIS_SHORT_' + language.toUpperCase();
  const info = getInfo(key, vod);
  return info ? info : '';
}

const scoreKeepers = [
  {
    name: 'VG',
    key: 'VG_SCORE',
  },
  {
    name: 'Dagbladet',
    key: 'DAGBLADET_SCORE',
  },
];

interface Score {
  name: string;
  value: string;
}

export function getScores(vod: VodAsset) {
  let scores: Score[] = [];
  scoreKeepers.forEach((item) => {
    const scorer = getInfo(item.key, vod);
    if (scorer) {
      scores.push({
        name: item.name,
        value: scorer.substring(0, 1),
      });
    }
  });
  return scores;
}

export function getCurrentLangTitle(title: Title | undefined) {
  if (title && i18n && i18n.language && title[i18n.language.split('-')[0]]) {
    return title[i18n.language.split('-')[0]];
  }
  return title ? title.nb || '' : '';
}

export function getCurrentLangImage(image: LanguageImage) {
  if (image && image[i18n.language.split('-')[0]]) {
    return image[i18n.language.split('-')[0]];
  }
  return image ? image.nb || '' : '';
}

export function getInlinePromotions(vodConfig: VodConfig) {
  if (vodConfig && vodConfig.data) {
    const vodFrontPage = vodConfig.data.find((x) => x.type === VodConfigDataType.GRID_WITH_BANNERS);
    if (vodFrontPage && vodFrontPage.sections) {
      const promotionSection = vodFrontPage.sections.find((x) => x.type === VodConfigSectionType.PROMOTION);
      if (promotionSection) {
        return promotionSection.data;
      }
    }
  }
  return [];
}

/**
 * Loops thorough pased config to retrieve the Title of the filtered category
 */
export function getCategoryTitle(categoryId: string, orderType: string, genreIds: string, vodConfig: VodConfig) {
  let foundTitle = '';
  let gridSectionsFound: VodSection[] = [];

  vodConfig.data.forEach((data: VodConfigData) => {
    if (data.type === VodConfigDataType.GRID_WITH_BANNERS && data.sections) {
      gridSectionsFound = data.sections;

      gridSectionsFound.forEach((section: VodSection) => {
        if (section.categoryId === categoryId && section.sorting === orderType) {
          foundTitle = getCurrentLangTitle(section.title);
        }
      });
    }
  });

  // still not found - double check the inline promotions for a title
  if (foundTitle === '' && gridSectionsFound) {
    const promotionSections = gridSectionsFound.filter((x) => x.type === VodConfigSectionType.PROMOTION);

    if (promotionSections) {
      promotionSections.forEach((promotionSection) => {
        promotionSection.data.forEach((section) => {
          if (section.actionParams && section.actionParams.categoryId === categoryId) {
            foundTitle = getCurrentLangTitle(section.actionParams.title);
          }
        });
      });
    }
  }

  return foundTitle;
}

/**
 * Loops thorough pased config to retrieve the Title of the filtered genre
 */
export function getGenreTitle(categoryId: string, orderType: string, genreIds: string, vodConfig: VodConfig) {
  let foundTitle = '';
  vodConfig.data.forEach((data: VodConfigData) => {
    if (data.type === VodConfigDataType.MOVIE_GENRE || data.type === VodConfigDataType.SERIES_GENRE) {
      if (data.genres) {
        data.genres.forEach((genre: VodGenre) => {
          if (data.categoryId === categoryId && genre.genreIds.replace(/\s/g, '') === genreIds) {
            foundTitle = getCurrentLangTitle(genre.title);
          }
        });
      }
    }
  });
  return foundTitle;
}

/**
 * Will merge episodes into series, buy checking if episode is in the season in the series
 */
export function prepareMyContent(vods: VodAsset[], series: VodAsset[], seasons: VodAsset[]) {
  let newVodList: VodAsset[] = [];
  let seriesIdsAdded: string[] = [];

  if (vods && seasons) {
    vods.forEach(function (vod: VodAsset) {
      if (isEpisode(vod)) {
        seasons.forEach((season) => {
          if (season.id === vod.fathervodlist[0].vodid) {
            if (series) {
              series.forEach((singleSeries: VodAsset) => {
                // todo episodes are showing
                if (!singleSeries.listOfRentedEpisodes) {
                  singleSeries.listOfRentedEpisodes = [];
                }
                if (season.fathervodlist && singleSeries.id === season.fathervodlist[0].vodid) {
                  if (vod.rentperiod && assetIsRented(vod)) {
                    singleSeries.listOfRentedEpisodes = singleSeries.listOfRentedEpisodes
                      .concat([{ rentId: vod.id, rentPeriod: vod.rentperiod }])
                      .filter((obj, idx, ast) => ast.map((mapObj) => mapObj.rentId).indexOf(obj.rentId) === idx);
                  } else {
                    singleSeries.hasBoughtEpisode = true;
                  }
                  singleSeries.showBoughtBadge = true;
                  seriesIdsAdded.push(singleSeries.id);
                  let existsInList = newVodList.findIndex((x) => x.id === singleSeries.id);
                  if (existsInList !== -1) {
                    newVodList[existsInList] = { ...newVodList[existsInList], ...singleSeries };
                  } else {
                    newVodList.push(singleSeries);
                  }
                }
              });
            }
          }
        });
      } else {
        if (vod.rentperiod && assetIsRented(vod)) {
          vod.showRentedBadge = true;
        } else {
          vod.showBoughtBadge = true;
        }
        newVodList.push(vod);
      }
    });
  }
  return newVodList;
}

export function getLowestRentedEpisodeCountdown(asset: VodRentEpisode[]) {
  return asset
    ? asset.reduce((acc, curr) => (Number(curr.rentPeriod) < Number(acc.rentPeriod) ? curr : acc), asset[0])
    : ({} as VodRentEpisode);
}

export function getLowestRentedSeriesCountdown(asset: VodAsset[]) {
  return asset
    ? asset.reduce((acc, curr) => (Number(curr.rentperiod) < Number(acc.rentperiod) ? curr : acc), asset[0])
    : ({} as VodAsset);
}

export function getResolution(v: string) {
  return v === '1' ? 'SD' : 'HD';
}

export function getPurchaseType(isEST: string) {
  return isEST === '0' ? 'rented' : 'bought';
}

export function shouldCapBandwidthForThisStream(name: string) {
  return (
    Object.keys(CappedStreams)
      .map((key) => CappedStreams[key])
      .indexOf(name) > -1
  );
}

export function getAvailableFormats(vod: VodAsset) {
  let formats: string[] = [];
  if (vod.mediafiles) {
    vod.mediafiles.forEach((mediafile) => {
      if (mediafile.definition === MediaDefinition.SD) {
        formats.push('SD');
      } else if (mediafile.definition === MediaDefinition.HD) {
        formats.push('HD');
      }
    });
  }
  return formats;
}

export function getYearProduced(vod: VodAsset) {
  return vod.producedate ? vod.producedate.substr(0, 4) : '';
}

export function getAvailableSubtitles(vod: VodAsset) {
  if (vod.subtitles) {
    let translatedSubs: string[] = translate(vod.subtitles);
    return translatedSubs.join(', ');
  } else {
    let subtitleArrays: string[] = [];
    // try to get subtitles based on episodes
    if (vod.seasons) {
      vod.seasons.forEach((season) => {
        if (season.episodes) {
          season.episodes.forEach((episode) => {
            if (episode.subtitles) {
              episode.subtitles.split(',').forEach((lang) => {
                subtitleArrays.push(lang);
              });
            }
          });
        }
      });
    }
    if (subtitleArrays.length > 0) {
      let translatedSubs: string[] = translate(Array.from(new Set(subtitleArrays)).join(','));
      return translatedSubs.join(', ');
    }
  }
  return '';
}

function translate(languagesString: string) {
  const languageArray: string[] = languagesString ? languagesString.split(',') : [];
  let translated = languageArray.map((item) => getTranslation(item));
  return translated;
}

export function getAvailableAudio(vod: VodAsset) {
  if (vod.languages) {
    let translatedSubs: string[] = translate(vod.languages);
    return translatedSubs.join(', ');
  } else {
    let audioArrays: string[] = [];
    // try to get audios based on episodes
    if (vod.seasons) {
      vod.seasons.forEach((season) => {
        if (season.episodes) {
          season.episodes.forEach((episode) => {
            if (episode.languages) {
              episode.languages.split(',').forEach((lang) => {
                audioArrays.push(lang);
              });
            }
          });
        }
      });
    }
    if (audioArrays.length > 0) {
      let translatedSubs: string[] = translate(Array.from(new Set(audioArrays)).join(','));
      return translatedSubs.join(', ');
    }
  }
  return '';
}

export function isVodActive(categoryIds: string[], lookup: string[]) {
  return !isEmpty(categoryIds.filter((n) => lookup.indexOf(n) !== -1));
}

export function getVodAsset(vodAsset: VodAsset, landscape?: boolean): AltiboxAsset {
  let picture = null;
  if (landscape) {
    picture = hasPicture(vodAsset.picture) ? getSvodPicture(vodAsset) : missingCoverUrlLandscape;
  } else {
    picture = hasPicture(vodAsset.picture) ? getVodPicture(vodAsset) : missingCoverUrl;
  }
  let assetURL = routes.vod.base + routes.vod.details + '/' + vodAsset.externalContentCode;
  let percentWatched = 0;
  return {
    id: vodAsset.id,
    progress: percentWatched,
    description: vodAsset.introduce,
    type: AltiboxAssetType.VOD,
    hideBrandLogo: landscape ? false : true,
    picture: picture,
    coverImage:
      hasPicture(vodAsset.picture) && vodAsset.picture.background ? vodAsset.picture.background.split(',').pop() : '',
    title: vodAsset.name,
    genre: vodAsset.genres ? vodAsset.genres.split(',')[0] : '',
    playLink: assetURL,
    weight: 1,
    detailsLink: assetURL,
    asset: vodAsset,
  };
}

export function getBackgroundPicture(asset: VodAsset) {
  if (hasPicture(asset.picture) && asset.picture.background) {
    return asset.picture.background.split(',').pop();
  } else {
    return '';
  }
}

export function getVodPicture(asset: VodAsset) {
  let pictureUrl = '';
  if (asset.picture) {
    if (Object.keys(asset.picture).length > 0) {
      if (window.document.body.offsetWidth > 450) {
        if (asset.picture.ad) {
          pictureUrl = asset.picture.ad.split(',').pop() || '';
        }
      } else {
        if (asset.picture.other) {
          pictureUrl = asset.picture.poster.split(',').pop() || '';
        }
      }
    }
  }
  return pictureUrl;
}

export function getGenre(genreString: string) {
  if (genreString && genreString.length > 0) {
    return genreString.split(',')[0];
  }
  return '';
}

export function generateVodStreamObject(vod: VodAsset, streamObject: StreamTypeObject) {
  let streamData = { ...streamObject };
  if (vod.bookmark && Number(vod.bookmark.rangeTime) > 0) {
    let percentWatched = getPercentWatched(vod);
    if (percentWatched < 97 && percentWatched > 0) {
      streamData.startBookmark = Number(vod.bookmark.rangeTime);
    }
    if (ScriptService.isCurrentBrowserSupported() && !ScriptService.onMobile()) {
      streamData.title = vod.name;
      streamData.picture = vod.picture;
    }
  }
  return streamData;
}

export function getGrandfatherVodName(vod: VodAsset) {
  if (vod && vod.fatherVod && vod.fatherVod.fatherVod) {
    return vod.fatherVod.fatherVod.name;
  }
  return undefined;
}

export function hasBoughtProduct(pricings?: VodContentProduct[]) {
  if (pricings && !isEmpty(pricings)) {
    return pricings.some((v) => v.issubscribed === '1');
  }
  return false;
}

export function addFiltersToLink(filters: Filter[], link: string) {
  if (filters) {
    filters.forEach((filter: Filter) => {
      if (filter.key === FilterKeys.GENRE_IDS) {
        link += '/' + filter.value.replace(/\s/g, '');
      }
    });
  }

  return link;
}
