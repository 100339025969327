import { getBaseUrl, getSessionTicket } from '../../config';
import { getDataFromResponse, fetchError } from '../../controllers/Fetch';
import { Bookmarks } from '../../interfaces';

/**
 * @param count - the amount of bookmarks to return (default is `-1` which obtains all bookmarks)
 */
export async function getAllBookmarks(count = -1) {
  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/QueryBookmark', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        count,
      }),
    });

    return await getDataFromResponse<Bookmarks>(res);
  } catch (error) {
    return fetchError(error as string);
  }
}
