import React from 'react';
import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AlertType } from '../../interfaces';
import './style.scss';
import styles from '../OptionsModal/styles.module.scss';
import { hideAlert } from '../../views/app/actions';
import isEmpty from 'lodash/isEmpty';
import { SvgClose } from '../../icons';
import { ButtonPrimary, ButtonSecondary } from '../UI/Buttons';
import i18n from '../../i18n';

interface Props {
  ariaShowApp?: boolean;
  children: JSX.Element | JSX.Element[];
}

const Alert = ({ ariaShowApp, children }: Props) => {
  const dispatch = useDispatch();
  const visible = useSelector((state: RootState) => state.app.alertVisible);
  const data = useSelector((state: RootState) => state.app.alert);

  const getButtonText = () => {
    const { type } = data;
    switch (type) {
      case 'get access':
        return i18n.t<string>('go to mine sider');
      case 'customer service':
        return i18n.t<string>('go to customer service');
      case 'deeplink':
        return i18n.t<string>('watch external catchup');
      case 'info':
        return i18n.t<string>('close');
      default:
        return i18n.t<string>('close');
    }
  };

  const closeButtonClick = () => {
    if (data.type === AlertType.RETURN_TO_BASE) {
      document.location.href = '/';
    }
    dispatch(hideAlert());
  };

  const showAlert = () => {
    const { text, title, type, link, buttonText } = data;
    if (isEmpty(text) && !title) {
      return null;
    }
    return (
      <Modal
        contentLabel="aib-alert-modal"
        isOpen={visible}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={true}
        onRequestClose={() => closeButtonClick()}
        className="aib-alert-modal"
        ariaHideApp={ariaShowApp}
      >
        <button className={styles.buttonClose} onClick={closeButtonClick} children={<SvgClose />} />
        <main className={styles.mainContainer}>
          <h1 className="alert-title">{title}</h1>
          {text.map((singleText, index) => (
            <p key={index} className="alert-text" dangerouslySetInnerHTML={{ __html: singleText }} />
          ))}
          {type === AlertType.GET_ACCESS || type === AlertType.DEEPLINK || type === AlertType.GET_ACCESS_DK ? (
            <ButtonPrimary className={styles.optionButtonNoPadding} href={link} target="_blank">
              {buttonText || getButtonText()}
            </ButtonPrimary>
          ) : (
            <ButtonSecondary onClick={closeButtonClick}>{buttonText || getButtonText()}</ButtonSecondary>
          )}
        </main>
      </Modal>
    );
  };

  return (
    <div className="global-alert-wrapper">
      {showAlert()}
      {children}
    </div>
  );
};

export default Alert;
