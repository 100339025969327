import { getAmrUrl } from '../config';
import { json, status } from '../controllers/Fetch';
import { PopularContentChannelTimespan, AMR, Program } from '../interfaces';
import { getProgramDetail } from './programarchive';

export function fetchMostWatchedChannels(timespan: PopularContentChannelTimespan) {
  return fetch(`${getAmrUrl()}/v0/mostWatched/channel/${timespan}`)
    .then(status)
    .then((r) => json(r as Response))
    .then((popularChannelsResponse: AMR.PopularChannelResponse) => {
      return popularChannelsResponse.channels;
    })
    .catch(() => {
      // return empty list, will fallback to frontpage web config
      return [];
    });
}

export function fetchMostWatchedCatchup(timespan: PopularContentChannelTimespan) {
  return fetch(`${getAmrUrl()}/v0/mostWatched/catchup/${timespan}`)
    .then(status)
    .then((r) => json(r as Response))
    .then(async (mostWatchedCatchupResponse: AMR.MostWatchedCatchupResponse) => {
      let programIds = mostWatchedCatchupResponse.programs.map((program) => program.id);
      let huaweiPrograms = (await getProgramDetail(programIds.toString(), false)) as Program[];
      return huaweiPrograms;
    })
    .catch(() => {
      return [];
    });
}
