import React, { Component } from 'react';
import AltiboxAssetButton from './AltiboxAssetButton';
import {
  AltiboxAssetButtonType,
  BroadcastStatus,
  AltiboxAssetDetailsType,
  RootState,
  Program,
} from '../../../../interfaces';
import { getProgramBroadcastingStatus, altiboxAssetHasTrailer } from '../../../../utils/altiboxassetUtils';
import AltiboxAssetContext from '../../../../views/details/AltiboxAssetContext';

import './style.scss';
import { assetIsPvr } from '../../../../typeGuards';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';

type Props = { currentProgram?: Program } & RouteComponentProps;

class AltiboxAssetButtonRow extends Component<Props> {
  static contextType = AltiboxAssetContext;
  context!: React.ContextType<typeof AltiboxAssetContext>;

  get promotedAsset() {
    let { promotedAsset } = this.context;
    return promotedAsset;
  }

  get programArchiveButtons() {
    const { isCatchupOrPvr } = this.context;
    let buttonsToReturn: JSX.Element[] = [];
    const broadcastingStatus = getProgramBroadcastingStatus(this.promotedAsset);
    const isLive = broadcastingStatus === BroadcastStatus.Live;
    const isCurrentProgram = this.props.currentProgram?.id === this.promotedAsset.id;
    if (isLive && !isCurrentProgram) {
      const fromStart = <AltiboxAssetButton buttonType={AltiboxAssetButtonType.FROMSTART} key="fromstartbutton" />;
      buttonsToReturn.push(fromStart);
    }
    if (isCatchupOrPvr) {
      const recordButton = <AltiboxAssetButton buttonType={AltiboxAssetButtonType.PVR} key="pvrbutton" />;
      buttonsToReturn.push(recordButton);
    }
    return buttonsToReturn;
  }

  get buttons(): (JSX.Element | null)[] {
    const { altiboxAsset } = this.context;
    const { type } = altiboxAsset;
    const favouriteButton = <AltiboxAssetButton buttonType={AltiboxAssetButtonType.FAVOURITE} key="favouritebutton" />;
    switch (type) {
      case AltiboxAssetDetailsType.SVOD:
      case AltiboxAssetDetailsType.VOD:
        let trailerButton: JSX.Element | null = null;
        if (altiboxAssetHasTrailer(altiboxAsset)) {
          trailerButton = <AltiboxAssetButton buttonType={AltiboxAssetButtonType.TRAILER} key="trailerbutton" />;
        }
        return [favouriteButton, trailerButton];
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PVR:
        if (assetIsPvr(this.promotedAsset)) {
          const recordButton = <AltiboxAssetButton buttonType={AltiboxAssetButtonType.PVR} key="pvrbutton" />;
          const deleteModal = (
            <AltiboxAssetButton buttonType={AltiboxAssetButtonType.DELETE_ALL} key="deleterecordings" />
          );

          return this.promotedAsset.isSingle
            ? [recordButton, deleteModal]
            : [recordButton, favouriteButton, deleteModal];
        }

        let programArkivPlayButtons: JSX.Element[] = this.programArchiveButtons;
        programArkivPlayButtons.push(favouriteButton);
        return programArkivPlayButtons;
      default:
        return [null];
    }
  }

  render() {
    const buttons = this.buttons.map((button) => button).filter((x) => x);
    return <div className="altibox-asset-button-row">{buttons}</div>;
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      currentProgram: state.channelsReducer.currentProgram,
    }),
    () => ({}),
  )(AltiboxAssetButtonRow),
);
