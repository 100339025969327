import { HuaweiBatchResponse } from './../../interfaces';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getBaseUrl, getSessionTicket, huaweiDateTimeFormat } from '../../config';
import { getDataFromResponse } from '../../controllers/Fetch';
import { ProgramContentDetailResponse } from '../../interfaces';

function endTime(date: moment.Moment) {
  return date.add(1, 'days');
}

function beginTime(date: Date) {
  return moment(date).startOf('day').add(5, 'hours');
}

export async function getPlaybillsForDay(channelId: string, date: Date) {
  const beginTimeDate = moment(date).startOf('day').add(5, 'hours');

  const res = await fetch(getBaseUrl() + '/EPG/JSON/PlayBillList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      channelid: channelId,
      begintime: beginTimeDate.format(huaweiDateTimeFormat),
      endtime: beginTimeDate.add(1, 'days').format(huaweiDateTimeFormat),
      type: 2,
      offset: 0,
      count: -1,
    }),
  });

  const data = await getDataFromResponse<ProgramContentDetailResponse>(res);

  return data.playbilllist;
}

export async function getAllPlaybills(channelIds: string[], date: Date = new Date()) {
  if (isEmpty(channelIds)) {
    return Promise.resolve([]);
  }

  const res = await fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: channelIds.map((id) => {
        return {
          name: 'PlayBillList',
          param: {
            channelid: `${id}`,
            begintime: beginTime(date).format(huaweiDateTimeFormat),
            endtime: endTime(beginTime(date)).format(huaweiDateTimeFormat),
            type: 2,
            offset: 0,
            count: -1,
          },
        };
      }),
    }),
  });

  const data = await getDataFromResponse<HuaweiBatchResponse<'PlayBillList', ProgramContentDetailResponse>>(res);

  const messages = data.responseList.map((response) => response.msg);
  const lists = messages.map((message) => (message.playbilllist ? message.playbilllist : message.playbillList));

  return lists.flat();
}
