import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import nb from './nb.json';
import da from './da.json';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: {
      no: ['nb'],
      nn: ['nb'],
      dk: ['da'],
      default: ['nb'],
    },

    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // },

    detection: {
      order: ['navigator', 'querystring'],
    },

    // we init with resources
    resources: {
      en: en,
      nb: nb,
      da: da,
    },

    debug: false,

    // // have a common namespace used around the full app
    ns: ['translations', 'pvr', 'sort'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    // react: {
    //   wait: true,
    // },
  });

export default i18n;
