import {
  DispatchType,
  GetStateType,
  VodConfig,
  PortalMenu,
  VodBatchListResponse,
  VodConfigData,
  Genre,
  VodSection,
  VodConfigDataType,
  CategoryListResponse,
  VodConfigSectionType,
  AssetsBySvodKioskIdentifier,
  VodAsset,
} from '../../../interfaces';
import { getBatchOfVodLists, getVodCategory } from '../../../api/vod';
import { getSvodConfig, loadSvodCategoryIds, loadSvodKiosksBaseCategoryId } from '../../../api/svod';
import { fakeFavourite } from '../../../utils/favourites';
import { updateVodWithFavourite } from '../../../views/vod/actions';
import { flatten, isEmpty } from 'lodash';

export enum TypeKeys {
  SET_SVOD_CONFIG = 'SET_SVOD_CONFIG',
  SET_SVOD_KIOSK = 'SET_SVOD_KIOSK',
  SET_SVOD_FRONT_PAGE_LISTS = 'SET_SVOD_FRONT_PAGE_LISTS',
  SET_SVOD_PROMOTED_ASSETS = 'SET_SVOD_PROMOTED_ASSETS',
  SET_SVOD_ALL_ACTIVE_CONTENT = 'SET_SVOD_ALL_ACTIVE_CONTENT',
}

export type ActionTypes =
  | SetSvodKiosk
  | SetSvodConfig
  | SetFrontPageLists
  | SetSvodPromotedAssets
  | SetSvodAllActiveContent;

export interface SetFrontPageLists {
  type: TypeKeys.SET_SVOD_FRONT_PAGE_LISTS;
  frontPageVodLists?: VodBatchListResponse[];
}
export function setFrontPageLists(frontPageVodLists?: VodBatchListResponse[]): SetFrontPageLists {
  return {
    type: TypeKeys.SET_SVOD_FRONT_PAGE_LISTS,
    frontPageVodLists,
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchFrontPageVodLists(vodConfigData: VodConfigData[], genre: Genre[], count: number): any {
  return function (dispatch: DispatchType) {
    let categoriesToFetch: VodSection[] = [];
    vodConfigData.forEach((data: VodConfigData) => {
      if (data.sections) {
        if (data.type === VodConfigDataType.GRID_WITH_BANNERS) {
          data.sections.forEach((section: VodSection) => {
            if (section.categoryId) {
              categoriesToFetch.push(section);
            }
          });
        }
      }
    });
    getBatchOfVodLists(categoriesToFetch, genre, count).then((result) => {
      dispatch(setFrontPageLists(result as VodBatchListResponse[]));
    });
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateSvodKioskWithFavourite(favoId: string): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    let svodKiosk = getState().svodReducer.svodKiosk;
    if (svodKiosk) {
      svodKiosk.favoId = favoId;
    }
    dispatch(setSvodKiosk(svodKiosk));
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateSvodAssetWithFavourite(svodId: string, favouriteValue: string): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    const state = getState().svodReducer;
    const frontPageVodLists = state.frontPageVodLists;
    if (frontPageVodLists) {
      frontPageVodLists.forEach((list) => {
        return fakeFavourite(list.msg, svodId, favouriteValue);
      });
      dispatch(setFrontPageLists(frontPageVodLists));
    } else {
      dispatch(updateVodWithFavourite(svodId, favouriteValue));
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clearSvodSelection(): any {
  return function (dispatch: DispatchType) {
    dispatch(setFrontPageLists());
    dispatch(setSvodKiosk());
    dispatch(setVodConfig());
  };
}

export interface SetSvodKiosk {
  type: TypeKeys.SET_SVOD_KIOSK;
  svodKiosk: PortalMenu;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setSvodKiosk(svodKiosk?: PortalMenu): any {
  return function (dispatch: DispatchType) {
    dispatch({
      type: TypeKeys.SET_SVOD_KIOSK,
      svodKiosk,
    });
  };
}

export interface SetSvodConfig {
  type: TypeKeys.SET_SVOD_CONFIG;
  svodConfig: VodConfig;
}
export function setVodConfig(svodConfig?: VodConfig) {
  return {
    type: TypeKeys.SET_SVOD_CONFIG,
    svodConfig,
  };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function loadSvodConfig(areaId: string, bossId: string, svod: PortalMenu, callback: Function): any {
  return function (dispatch: DispatchType, getState: GetStateType) {
    return getSvodConfig(areaId, bossId, svod).then((config: VodConfig) => {
      loadSvodKiosksBaseCategoryId().then((categoryBaseList: CategoryListResponse) => {
        svod.svodCategoryId = categoryBaseList.categorylist!.find((x) => x.name === svod.providerName!)!.id;
        dispatch(setSvodKiosk(svod));
        callback();

        loadSvodCategoryIds(categoryBaseList, svod).then((categoryList: CategoryListResponse) => {
          config.data.forEach((data: VodConfigData) => {
            if (data.type === VodConfigDataType.GRID_WITH_BANNERS) {
              data.sections.forEach((section: VodSection) => {
                if (section.type === VodConfigSectionType.CATEGORY) {
                  let category = categoryList.categorylist.find((x) => x.name === section.categoryName);
                  if (category) {
                    section.categoryId = category.id;
                  }
                }
              });
            }
          });
          dispatch(setVodConfig(config));
        });
      });
    });
  };
}

interface SetSvodPromotedAssets {
  type: TypeKeys.SET_SVOD_PROMOTED_ASSETS;
  promotedAssets: AssetsBySvodKioskIdentifier;
}

function setSvodPromotedAssets(promotedAssets: AssetsBySvodKioskIdentifier): SetSvodPromotedAssets {
  return {
    type: TypeKeys.SET_SVOD_PROMOTED_ASSETS,
    promotedAssets,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchSvodPromotedAssets(): any {
  return async function (dispatch: DispatchType, getState: GetStateType) {
    const kiosks = getState().app.svodKiosks;
    const batches = kiosks
      // only kiosks with svod channels has use of promoted assets for now
      .filter((kiosk) => !isEmpty(kiosk.associatedChannelIds))
      .reduce((accumulator, kiosk, idx) => {
        const batch = Math.ceil((idx + 1) / 10) - 1;

        if (!accumulator[batch]) {
          accumulator[batch] = [{ categoryId: kiosk.svodAllActiveContentId, sorting: '3' }];
        } else {
          accumulator[batch].push({ categoryId: kiosk.svodAllActiveContentId, sorting: '3' });
        }

        return accumulator;
      }, [] as Partial<VodSection>[][])
      .map((batch) => {
        return getBatchOfVodLists(batch, [], 3);
      });

    const vodLists = await Promise.all(batches).then((r) => flatten(r));

    const promotedAssets = kiosks.reduce((accumulator, kiosk) => {
      const vodList = vodLists.find((list) => list.section.categoryId === kiosk.svodAllActiveContentId);

      if (vodList && kiosk.identifier) {
        return {
          ...accumulator,
          [kiosk.identifier]: vodList.msg.vodlist,
        };
      } else {
        return accumulator;
      }
    }, {} as AssetsBySvodKioskIdentifier);

    dispatch(setSvodPromotedAssets(promotedAssets));
  };
}

interface SetSvodAllActiveContent {
  type: TypeKeys.SET_SVOD_ALL_ACTIVE_CONTENT;
  content: AssetsBySvodKioskIdentifier;
}

function setSvodAllActiveContent(identifier: string, assets: VodAsset[]): SetSvodAllActiveContent {
  return {
    type: TypeKeys.SET_SVOD_ALL_ACTIVE_CONTENT,
    content: { [identifier]: assets },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchAllActiveContent(kiosk: PortalMenu): any {
  return async function (dispatch: DispatchType) {
    if (!kiosk.identifier) {
      return;
    }

    const assets = await getVodCategory(kiosk.svodAllActiveContentId, '2', '', [], -1, 0)
      .then((res) => res.vodlist)
      .catch(() => []);

    dispatch(setSvodAllActiveContent(kiosk.identifier, assets));
  };
}
