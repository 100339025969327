import React, { Component, ReactElement } from 'react';
import AnalyticsTracking from '../controllers/AnalyticsTracking';
import { GAcategory, GAaction } from '../interfaces';

interface Props {
  category?: string;
  label: string;
  action: string;
  className?: string;
  noDiv?: boolean;
  children: React.ReactNode;
}

export default class GALink extends Component<Props> {
  tracking = AnalyticsTracking.getInstance();

  handleClick = () => {
    const category = this.props.category ? this.props.category : this.tracking.getCurrentCategory();
    if (this.props.category !== this.props.label) {
      this.tracking.trackEvent(category as GAcategory, this.props.action as GAaction, this.props.label);
    }
  };

  render() {
    if (!this.props.noDiv) {
      return (
        <div className={(this.props.className ? this.props.className + ' ' : '') + 'GALINK'} onClick={this.handleClick}>
          {this.props.children}
        </div>
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const clonedChild = React.cloneElement(this.props.children as ReactElement<any>, {
        trackButtonEvent: this.handleClick,
      });
      return clonedChild;
    }
  }
}
