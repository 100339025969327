import React, { Component } from 'react';
import './style.scss';

interface Props {
  isFavourite: boolean;
}

class FavouriteIcon extends Component<Props, {}> {
  render() {
    return this.props.isFavourite ? (
      <div className={'favouriteIcon'}>
        <div className="aib-icon">M</div>
      </div>
    ) : null;
  }
}

export default FavouriteIcon;
