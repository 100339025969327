import { DetailsRouteProps } from './views/details';
import moment from 'moment';

export interface ThirdPartyCatchupData {
  id: string;
  channelIds: string[];
  titles: {
    da: string;
    en: string;
    nb: string;
  };
  logo: string;
  actionParams: {
    packageName: string;
    url: {
      ios: string;
      android: string;
      web: string;
    };
    queryParams?: string[];
    fallbackUrl: {
      ios: string;
      android: string;
    };
  };
}

export enum RecordingsList {
  PvrList = 'pvrList',
  SubRecordings = 'subRecordings',
}

export enum IsSomething {
  No,
  Yes,
}

export interface NamedParameter {
  key: string;
  value: string;
}

export namespace Api {
  export interface RetResponse {
    retcode: string;
    retmsg: string;
  }

  export interface PvrResponse<T = unknown> extends RetResponse {
    counttotal: string;
    hasNext: '0' | '1';
    pvrDataVersion: string;
    pvrlist: T;
  }

  export interface PvrSpaceResponseDetails {
    space: number;
    meteringMode: number;
  }
  export interface PvrSpaceResponse {
    name: string;
    msg: PvrSpaceResponseDetails;
  }

  export interface AllPvrSpaceResponse {
    responseList: PvrSpaceResponse[];
  }

  export interface ChannelsResponse {
    channellist: ChannelList[];
  }

  export interface ChannelList {
    contentId: string;
    name: string;
    chanNo: string;
    physicalChannels: ApiPhysicalChannel[];
    pictures: {
      href: string;
    }[];
    locationCopyrights: string[];
  }

  export interface Recordings {
    counttotal: number;
    pvrlist: Pvr.Recording[];
  }
}
export namespace Pvr {
  export namespace Nav {
    export enum Titles {
      Recordings = 'recordings',
      Scheduled = 'scheduled',
      Delete = 'delete',
    }
  }

  export interface Picture {
    href: string;
    imageType: ImageType;
    resolution: string[];
  }

  export enum ImageType {
    Thumbnail,
    Poster,
    Still,
    Icon,
    Title,
    Ad,
    Draft,
    Background,
    Channel,
    ChannelBW,
    ChannelName,
    Secondary,
    LogoBright,
    LogoDark,
    MainWide,
    SecondaryWide,
    InstantRestartLogo,
    ChannelFallbackWide,
    Others = 99,
  }

  export interface Recording {
    alreadyUpdated?: IsSomething;
    beginOffset: string;
    beginTime?: string;
    bookmarkTime?: string;
    casts?: Cast[];
    channelCode?: string;
    channelId: string;
    channelName: string;
    channelno: string;
    country?: string;
    createTime?: string;
    definition: Definition;
    deleteMode: DeleteMode;
    deviceId?: string;
    dimension: Dimension;
    effectivetime?: string;
    endOffset: string;
    endTime?: string;
    extensionInfo?: NamedParameter;
    fromToToday?: string;
    fromTodayTo?: string;
    foreignsn?: string;
    genreIds?: string[];
    introduce?: string;
    isConflict?: IsSomething;
    isShare: IsSomething;
    isWatched?: IsSomething;
    /**
     * `isSingle` is added after the fact when fetching from API.
     */
    isSingle?: boolean;
    latestSeriesNum?: string;
    lifetimeId?: string;
    maxSubTaskNum?: string;
    mediaId: string;
    newPeriodPVRTaskId?: string;
    overtime?: string;
    periodPVRTaskId?: string;
    periodPVRTaskName?: string;
    playbillID?: string;
    pictures?: Picture[];
    playEnable?: IsSomething;
    profileId: string;
    programCode?: string;
    programId?: string;
    pvrId?: string;
    /**
     * `pvrName` is originally optional, but added when fetching from API.
     */
    pvrName: string;
    ratingId?: string;
    realRecordLength?: string;
    seasonNum?: string;
    selectedSeasonNum?: string;
    selectedSubNum?: string;
    seriesID: string;
    seriesId: string;
    status: Status;
    subPvrNum?: string;
    subName?: string;
    subNum?: string;
    timeMode?: TimeMode;
    type: Type;
    unWatchedNum?: string;
  }

  export interface SingleRecording extends Recording {
    /**
     * `isSingle` is added after the fact when fetching from API.
     */
    isSingle: true;
  }

  export interface SeriesRecording {
    alreadyUpdated: IsSomething;
    beginOffset: string;
    channelId: string;
    channelName: string;
    channelno: string;
    createTime: string;
    definition: Definition;
    deleteMode: DeleteMode;
    effectivetime: string;
    endOffset: string;
    fromToToday: string;
    fromTodayTo: string;
    isShare: IsSomething;
    /**
     * `isSingle` is added after the fact when fetching from API.
     */
    isSingle?: false;
    latestPVRTask: Required<Recording>;
    latestSeriesNum: string;
    maxSubTaskNum: string;
    mediaId: string;
    newPeriodPVRTaskId: string;
    periodPVRTaskName: string;
    periodPVRTaskId: string;
    playbillID: string;
    profileId: string;
    pvrList: Recording[];
    recordNum: string;
    selectedSeasonNum: string;
    selectedSubNum: string;
    seriesID: string;
    seriesId: string;
    seriesType: SeriesType;
    status: Status;
    subPvrNum: string;
    timeMode: TimeMode;
    type: Type;
    unWatchedNum: string;
  }

  export type UnionRecording = Recording | SingleRecording | SeriesRecording;

  export enum Definition {
    SD,
    HD,
    FourK,
  }

  export enum DeleteMode {
    Auto = 0,
    Manual = 1,
  }

  export enum Dimension {
    '2D' = 2,
    '3D' = 3,
  }

  export enum Status {
    NotRecorded = '-1',
    ToBeDeleted = '-3',
    ToBeDelivered = '-2',
    RecordedSuccessfully = '0',
    BeingRecorded = '1',
    RecordingFailed = '2',
    PartiallyRecorded = '8',
    ToLoadAndShow = '0,1',
  }

  export enum Type {
    CPVR = 1,
    NPVR = 2,
    MixedPVR = 3,
  }

  export enum SeriesType {
    RecordingByTimeSegment,
    RecordingByKeyword,
    RecordingBySeriesID,
  }

  export enum TimeMode {
    RecordWholeSeries,
    AllEpisodesFromThisSeasonAndNewer,
    OnlyEpisodeAtThisTime,
  }

  export enum APIOrderType {
    StartTimeAsc,
    StartTimeDesc,
    TaskNameAsc,
    TaskNameDesc,
    GenreNameAsc,
    GenreNameDesc,
    RecordingDurationAsc,
    RecordingDurationDesc,
    ChannelNumberAsc,
    ChannelNumberDesc,
    EndTimeAsc,
    EndTimeDesc,
  }
}

export interface RootState {
  app: AppReducerState;
  channelsReducer: ChannelsReducerState;
  authReducer: AuthReducerState;
  pvrReducer: PvrReducerState;
  vodReducer: VodReducerState;
  searchReducer: SearchReducerState;
  svodReducer: SvodReducerState;
  programArchiveReducer: ProgramArchiveReducerState;
  detailsReducer: DetailsReducerState;
}

export interface SearchReducerState {
  searchQuery: string;
  searchResults: [AltiboxAssetSearchResponse[], AltiboxAssetSearchResponse[], AltiboxAssetSearchResponse[]] | undefined;
  initialSearchQuery: string;
  searchCategories: SearchCategories | undefined;
}

export interface VodReducerState {
  selectedGenreType: GenreTypes | undefined;
  myContent: VodAsset[] | undefined;
  mySeries: VodAsset[] | undefined;
  mySeasons: VodAsset[] | undefined;
  currentVod: VodAsset | undefined;
  currentVodCategory: VodListResponse | undefined;
  currentVodCategoryId: string | undefined;
  vodConfig: VodConfig | undefined;
  categoryIds: CategoryId[] | undefined;
  frontPageVodLists: VodBatchListResponse[] | undefined;
  autoplay: VodAutoplay | undefined;
  streamConfig: StreamTypeObject | TrailerStreamTypeObject | undefined;
}

export interface SvodReducerState {
  svodConfig: VodConfig | undefined;
  svodKiosk: PortalMenu | undefined;
  frontPageVodLists: VodBatchListResponse[] | undefined;
  promotedAssets?: AssetsBySvodKioskIdentifier;
  allActiveContent: AssetsBySvodKioskIdentifier;
}

export interface ProgramArchiveReducerState {
  epgGenres: EpgGenre[] | undefined;
  epgGenresList: ProgramArchiveList[] | undefined;
  currentProgramArchiveGenrePrograms: AltiboxAsset[] | undefined;
  currentGenreListIsLoading: boolean;
  currentProgramArchiveAsset: AltiboxAsset | undefined;
  mostWatchedCatchup: Program[] | undefined;
}

export type ApiPhysicalChannel = {
  extensionInfo: ApiExtensionInfo[];
  videoCodec: string;
  npvrRecCR: {
    mi: string;
    l: string;
  };
  mediaId: string;
  btvBR: {
    is: '1' | '0';
    va: '1' | '0';
  };
  cutvCR: CatchupTVContentRight;
};

export interface CatchupTVContentRight {
  e?: '1' | '0';
  va?: '1' | '0';
  l?: string;
  r?: {
    name: string;
  }[];
}

interface ApiExtensionInfo {
  key: string;
  value: string;
}

export interface EpgGenre {
  /**
   * `genreIds` is a comma delimited string of the ids.
   * @example '2114, 2115, 2116'
   */
  genreIds: string;
  orderType: string;
  title: Title;
}

export enum CountryType {
  NO = 'no',
  DK = 'dk',
}

export enum BossType {
  NO = 'ALTIBOX_NORWAY',
  DK = 'ALTIBOX_DANMARK',
}

export enum SortingTypes {
  ASC = 'ASCENDING',
  DESC = 'DESCENDING',
}
export enum SortingValues {
  EPISODE = 'EPISODE',
  DATE = 'DATE',
}

export enum PortalMenuType {
  SVOD = 'svod',
}

export enum FrontPageComponentType {
  CHANNEL_MOSAIQUE = 'liveGrid',
  CHANNEL_LIVE = 'liveChannels',
  STREAMING_SERVICES = 'streamingServices',
  PVR_MY_RECORDINGS = 'myRecordings',
  CONTINUE_WATCHING = 'continueWatching',
  SVOD_AVAILABLE = 'svodIcons',
  SVOD_PROMOTED = 'svodPromotedNews',
  VOD_MY_CONTENT = 'myVodContent',
  VOD_PROMOTE_CATEGORY = 'vodPromotedCategory',
  VOD_INLINE_PROMOTION = 'vodInlinePromotions',
}

export interface FrontPageComponent {
  type: FrontPageComponentType;
  name: string;
  config: {
    channelsToShow?: string[];
    goToCategory?: boolean;
    doNotLinkToCategory?: boolean;
    orderBy?: number;
    categoryId?: string;
    title?: Title;
  };
}

export interface FrontPageClient {
  client: string;
  order: string[];
}

export interface FrontPageConfig {
  clients: FrontPageClient[];
  availableComponents: FrontPageComponent[];
}

// TODO: Change PortalMenu to have a SVOD-ish name
export interface PortalMenu {
  titles?: Title;
  newsAssets: VodAsset[];
  svodCategoryId: string; // svodAllActiveContentId parent id
  svodAllActiveContentId: string; // all active movies and series(*not episodes and seasons)
  allActiveCategoryIds: string[];
  actionParams?: {
    platformChannelId: string;
  };
  associatedChannelIds?: string[];
  type?: PortalMenuType;
  providerName?: string;
  identifier?: string;
  productIdentifier?: string;
  allowSubscription?: boolean;
  favoId?: string;
  kioskHeader?: KioskHeader;
  promotions: {
    kiosks: PortalMenu[];
  };
  allContentIds?: Set<string>;
  devices?: {
    stb: boolean;
    android: boolean;
    ios: boolean;
    web: boolean;
  };
}

export interface StreamingServicePortal {
  action?: string;
  managedByAltibox?: boolean;
  actionParams?: {
    className: string;
    fallbackUrl: { android: string; ios: string };
    packageName: string;
    url: { android: string; ios: string; web: string; q22?: string };
  };
  conditions?: string[];
  icon: string;
  id: string;
  portalMenuId: string;
  titles?: Title;
  type: string;
}

export enum StreamingPortalsType {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export enum StreamingServiceIDType {
  VIAPLAY = 'viaplay',
  HBO_MAX = 'HBOMAX',
  AMAZON_PRIME = 'amazonprime',
  TV2_PLAY_DK = 'tv2playdk',
}

export enum SvodConditionType {
  MODEL_A = 'model-a',
  FREE = 'free',
}

export interface KioskHeader {
  cropped: string;
  stb: string;
  phone: string;
  tablet: string;
}

export enum MediaDefinition {
  HD = '1',
  SD = '2',
  FOURK = '3', // 4K
}

export enum CODEC {
  HEVC = 'H.265',
  AVC = 'H.264',
}

export interface CategoryId {
  key: string;
  value: string;
}

export interface MediaFile {
  id: string;
  elapsetime: string;
  definition: MediaDefinition;
  videoCodec: CODEC;
}

export interface VodPlaybackResponse {
  retcode: string;
}

export interface Authorization {
  errorCode: string;
  desc: string;
  isValid?: string;
  subscriptionKey: string;
}

export interface VodConfigData {
  sections: VodSection[];
  categoryId: string;
  type: VodConfigDataType;
  genres: VodGenre[];
  title: Title;
}

export enum HeaderDisplayType {
  Solid = 'solid',
  Gradient = 'gradient',
  NoStickyGradient = 'no-sticky-gradient',
  Player = 'player',
  NoGradient = 'no-gradient',
}

export enum HeaderInteractionType {
  Full = 'Full',
  CloseOnly = 'CloseOnly',
  Back = 'Back',
}

export enum VodConfigDataType {
  GRID_WITH_BANNERS = 'gridWithBanners',
  GRID = 'grid',
  MOVIE_GENRE = 'movieGenre',
  SERIES_GENRE = 'seriesGenre',
}

export enum PromotionActionType {
  MOVIE_DETAILS = 'moviedetails',
  CATEGORY = 'category',
}

export enum AltiboxAssetType {
  VOD = 'vod',
  SVOD = 'svod',
  PVR = 'pvr',
  LIVE = 'live',
  CATCHUP = 'catchup',
  TRAILER = 'trailer',
  PROGRAM = 'PROGRAM',
  TVGUIDE = 'tvguide',
}

export enum StreamType {
  VOD = 'vod',
  PVR = 'pvr',
  LIVE = 'live',
  CATCHUP = 'catchup',
  TRAILER = 'trailer',
}

export enum StreamCatchupSupport {
  NO = '0',
  YES = '1',
}

export enum ChromecastStreamTypeNumber {
  VOD = 1,
  PVR = 9,
  LIVE = 2,
  CATCHUP = 4,
  TRAILER = 5,
}

export enum StreamTypeNumber {
  CATCHUP = '3',
  VOD = '0',
  PVR = '2',
}

export enum VodConfigSectionType {
  CATEGORY = 'category',
  PROMOTION = 'promotion',
  GENRES = 'genres',
}

export enum CappedStreams {
  WaltDisney = 'Walt Disney',
  WarnerBros = 'Warner Bros.',
  DisneyChannelsXTRA = 'Disney Channels XTRA',
  DisneyMoviesOnDemand = 'Disney Movies on Demand',
}

export interface Filter {
  key: string;
  value: string;
}

export enum FilterKeys {
  GENRE_IDS = 'genreIds',
}

export interface Title {
  nb: string;
  en: string;
  da: string;
}

export interface LanguageImage extends Title {}

export interface VodGenre {
  title: Title;
  genreIds: string;
}

export enum SortCategories {
  LATEST_ARRIVALS = '0',
  MOST_POPULAR = '2',
  RECOMMENDED = '3',
  A_TO_Z = '4',
}

export interface VodSvodSortingOption {
  i18nKey: string;
  value: SortCategories;
}

export enum PvrSorting {
  A_TO_Z,
  NEW,
  OLD,
}

export interface PvrSortingOption {
  i18nKey: string;
  value: PvrSorting;
}

export interface VodSection {
  customCategoryId: string;
  categoryId: string;
  categoryName: string;
  sorting: string;
  data: InlineVodPromotion[];
  type: VodConfigSectionType;
  title: Title;
  filter: Filter[];
}

export interface InlineVodPromotion {
  actionParams: {
    title: Title;
    categoryId: string;
    sorting: string;
    filter: Filter[];
  };
  actionType: string;
  image: LanguageImage;
  size: string;
}

export interface VodPromotion {
  actionType: string;
  image: string;
  title: Title;
  platformId: string;
  trailerMediaId: string;
  subTitle: Title;
  price: string;
  link?: string;
}

export interface VodListResponse {
  vodlist: VodAsset[];
  counttotal: string;
}

export interface CategoryListResponse {
  categorylist: CategoryList[];
  counttotal: string;
}
export interface CategoryList {
  id: string;
  name: string;
}

export enum CategoryIds {
  VOD = 'All active content',
}

export interface ProgramArchiveBatchListResponse {
  responseList: ProgramArchiveListResponse[];
}

export interface ProgramArchiveListResponse {
  msg: PlayBillList;
  name: string;
}

export interface ProgramContentDetailResponse {
  playbilllist: Program[];
  playbillList: Program[];
}

interface PlayBillList extends ProgramContentDetailResponse {}

export interface ProgramArchiveList {
  genre: EpgGenre;
  programs: AltiboxAsset[];
}

export interface VodBatchListResponse {
  msg: {
    counttotal: string;
    vodlist: VodAsset[];
  };
  section: Partial<VodSection>;
}

export interface BatchVodListResponse {
  responseList: VodBatchListResponse[];
}

export interface ProgramBatchListResponse {
  msg: {
    counttotal: string;
    playbilllist: Program[];
  };
}

export interface BatchProgramListResponse {
  responseList: ProgramBatchListResponse[];
}

export interface NonCatchupSeriesResponse extends ProgramContentDetailResponse {}

export interface AuthPromise {
  asset: VodAsset;
  mediafileId: string;
  authData: Authorization;
}

export interface ChannelFilter {
  channelId: string;
  type: string;
}

export interface QueryPlaybillByFilterBatch {
  name: string;
  param: {
    orderType: string;
    type: number;
    count: number;
    offset: number;
    endtime: string;
    begintime: string;
    channelIDs: ChannelFilter[];
    properties: [
      {
        name: string;
        include: string;
      },
    ];
    filterlist?: Filter[];
  };
}

export interface VodBatch {
  name: string;
  param: {
    categoryid?: string;
    vodid?: string;
    count: number;
    offset: number;
    orderType?: string;
    properties: [
      {
        include: string;
      },
    ];
    filterlist?: Filter[];
  };
}

export interface ForeignCodeResponse {
  content: {
    id: string;
  };
}

export interface ContentDetailResponse {
  vodlist: VodAsset[];
}

export interface VodConfig {
  data: VodConfigData[];
  promotions: VodPromotion[];
}

interface ClipFile {
  id: string;
  elapsetime: number;
}

export enum VodAssetDisplayMode {
  SVOD = 'svod',
  VOD = 'vod',
}

export enum VodType {
  NON_TV_SERIES = '0',
  SERIES = '1',
  SEASONS = '2',
}

export type VodAsset = {
  id: string;
  clipfiles: [ClipFile];
  locationCopyrights: string[];
  categoryIds: string[];
  bookmark?: Bookmark;
  issubscribed: string;
  company: string;
  companyName: string;
  genres: string;
  seasonNumber: string;
  languages: string;
  subtitles: string;
  country: string;
  producedate: string;
  ratingid: string;
  seriesType: string;
  name: string;
  showBoughtBadge: boolean;
  showRentedBadge: boolean;
  isfavorited: string;
  foreignsn?: string;
  introduce: string;
  externalContentCode: string;
  picture: Picture;
  mediafiles: MediaFile[];
  rentperiod: string;
  casts: Cast[];
  cast: CastList;
  extensionInfo: ExtensionInfo[];
  fathervodlist: VodFatherSeason[];
  vodtype?: VodType;
  type?: ContentType;
  price: string;
  customBasePath?: string;
  customIconPath?: string;
  visittimes?: string;
  subscriptionType: string;
  isPlayable: string;
  isSvod?: boolean;
  // series
  sitcomnum?: string;
  seasons?: VodAssetSeason[];
  seasonEpisodes: VodAssetSeason[];
  fatherVod?: VodAsset;
  previousAsset?: VodAsset;
  hideBoughtForCW?: boolean;
  hasRentedEpisode?: boolean;
  rentedId?: string;
  hasBoughtEpisode?: boolean;
  seriesAsset?: VodAsset;
  listOfRentedEpisodes?: VodRentEpisode[];
};

export interface VodAssetSeason extends VodAsset {
  episodes: VodAsset[];
}
export interface VodRentEpisode {
  rentId: string;
  rentPeriod: string;
}

export interface Cast {
  castId: string;
  roleType: string;
  castName: string;
  castCode: string;
  weight?: number;
}

export interface CastList {
  actor: string;
  director: string;
  producer: string;
}

interface VodFatherSeason {
  name: string;
  sitcomnum: string;
  vodid: string;
  vodtype: string;
}

export enum PvrStatus {
  NotRecorded = '-1',
  ToBeDeleted = '-3',
  ToBeDelivered = '-2',
  RecordedSuccessfully = '0',
  BeingRecorded = '1',
  RecordingFailed = '2',
  PartiallyRecorded = '8',
  ToLoadAndShow = '0,1',
}

export enum SeriesType {
  Periodic = 0,
  Keyword = 1,
  SeriesId = 2,
  KeywordAndSeasonId = 3,
  TimeAndProgramName = 4,
  SeriesIdAndSeasonId = 5,
}

export enum RecordingsToShow {
  ALL = 0,
  SCHEDULED = 1,
}

export enum RecordingTime {
  TODAY = 'today',
  THIS_WEEK = 'this week',
  EARLIER = 'earlier',
  SCHEDULED = 'scheduled recordings',
  EXPIRES_SOON = 'expires soon',
}

export enum RecordingDeleteMode {
  Auto = 0,
  Manual = 1,
}

export type PvrRecording = {
  beginOffset: number;
  beginTime: string;
  bookmark: Bookmark;
  bookmarkTime: string;
  canceled: boolean;
  casts: Cast[];
  channelId: string;
  channelName: string;
  deleteMode: RecordingDeleteMode;
  effectivetime: string;
  endOffset: number;
  endTime: string;
  extensionInfo?: ApiExtensionInfo[];
  foreignsn?: string;
  fromTodayTo: string;
  genreIds: string[];
  id?: string;
  introduce: string;
  isfavorited?: string;
  isSingle: boolean;
  isWatched: string;
  lastPlayedEpisode: PvrRecording;
  latestPVRTask: PvrRecording;
  lifetimeId: string;
  mediaId: string;
  name: string;
  overtime: string | null;
  periodPVRTaskId: string;
  periodPVRTaskName: string;
  pictures: Picture;
  producedate?: string;
  programId: string;
  pvrId: string;
  pvrList: PvrRecording[];
  pvrName: string;
  seasonNum: string;
  seasons?: AltiboxSeason[];
  series?: AltiboxSeason[];
  seriesId: string;
  seriesType: SeriesType;
  sortedSeasons: Season[];
  status: PvrStatus;
  subName: string;
  subNum: string;
  subPvrNum: string;
  subRecordings: PvrRecording[];
  type?: ContentType;
  unWatchedNum: number;
  unwatchedSubRecordings: number;
  visittimes?: string;
};

export interface Recordings {
  all: PvrRecording[] | undefined;
  single: PvrRecording[];
  series: PvrRecording[];
}

export interface AddPVRResponse {
  pvrId: string;
  type: string;
  retmsg: string;
  retcode: string;
}

export interface Product {
  contentid: string;
  introduce: string;
  name: string;
  id: string;
  package: {
    priceobjects: [
      {
        contentid: string;
        name: string;
      },
    ];
  };
  restrictionList: {
    n: string;
    t: string;
    v: string;
  }[];
}

export interface QueryOrderResponse {
  counttotal: number;
  retcode: number;
  retmsg: string;
  productlist: Product[];
}

export interface QuerySubscriberResp {
  retcode: number;
  retmsg: string;
  subscriber: {
    customer: {
      customerName: string;
    };
  };
}
export interface PlayResult {
  triggers: Triggers[];
  url: string;
  retcode: string;
  retmsg: string;
}

export interface Triggers {
  getLicenseTrigger: Trigger;
}

export interface Trigger {
  customData: string;
  licenseURL: string;
}

export interface StreamData {
  manifestUrl: string;
  licenseUrl: string;
  customData: string;
}

export interface Bookmark {
  contentId: string;
  updateTime: string;
  subContentInfo: {};
  contentInfo?: ContentInfo;
  rangeTime: string;
  bookmarkType: string;
}

export interface ContentInfo {
  contentName: string;
  defaultRatingCode: string;
  episodeNum: string;
  introduce: string;
  picture: Picture;
  ratingCode: string;
  ratingId: string;
  seasonNum: string;
  subNum: string;
}

export interface Bookmarks {
  bookmarkList: Bookmark[];
}

export interface Season {
  seasonNum: string;
  episodes: PvrRecording[];
}

export interface StreamTypeObject {
  isGuest: boolean;
  chanNo?: string;
  title?: string;
  pvrId?: string;
  channelId?: string;
  picture?: Picture;
  startBookmark?: number;
  streamType: StreamType;
  mediaId: string | undefined;
  contentId: string;
  programStart?: string | undefined;
  programEnd?: string | undefined;
  playData: StreamingObject;
  secondsFromLive?: number;
}

export interface StreamingObject {
  manifestUrl: string;
  customData: string;
  licenseUrl?: string;
  capBandwidthAt?: number;
}

export interface TrailerStreamTypeObject {
  streamType: StreamType;
  contentId: string;
  mediaId: string;
  isGuest: boolean;
  externalContentCode: string;
  playData: {
    manifestUrl: string;
  };
}

export enum AspectRatioType {
  KEEP = 'keep',
  IGNORE = 'ignore',
}

export type ImageParams = {
  width: number;
  height: number;
  aspectRatioType: AspectRatioType;
};

export type ImageScaleValue = {
  [name: string]: ImageParams;
};

export type AltiboxAsset = {
  progress: number;
  picture: string;
  brandLogo?: string;
  croppedBrandLogo?: string;
  hideBrandLogo?: boolean;
  coverImage?: string;
  id: string;
  title: string;
  playLink: string;
  detailsLink: string;
  episodeTitle?: string;
  subtitle?: string;
  description?: string;
  episodeSeasonTag?: string;
  doNotMarkAsSvod?: boolean;
  genre?: string;
  isEpisode?: boolean;
  promotedEpisodeId?: string;
  isLockedForOutsideHomePlayback?: boolean;
  isGeoLockedAtUserLocation?: boolean;
  seasons?: AltiboxSeason[];
  weight: number;
  type: string | AltiboxAssetDetailsType;
  asset: UnionAssetTypes;
  meta?: DetailsMeta;
  price?: string;
};

export type AltiboxAssetContextType = {
  altiboxAsset: AltiboxAsset;
  promotedAsset: UnionAssetTypes;
  routeProps: DetailsRouteProps;
  authState: AuthReducerState;
  channels: Channel[];
  bookmarks: Bookmark[];
  myContent?: VodAsset[];
  recordings: Recordings;
  svodKiosk?: PortalMenu;
  svodKiosks?: PortalMenu[];
  programGenres: Genre[];
  sharedAssets: SharedAssets;
  clickedId?: string;
  recordingDeleted: PvrRecording | PvrRecording[] | undefined;

  defaultImagePath: string;
  isGeoBlocked: boolean;
  isVod: boolean;
  isSvod: boolean;
  isVodOrSvod: boolean;
  isPvr: boolean;
  isCatchup: boolean;
  isCatchupOrPvr: boolean;
  isSlideIn: boolean;

  toggleFavourite: (_1: Favourite, _2: string) => void;
  purchaseAsset: (_1: VodAsset) => void;
  getAccessToSvod: () => void;
  deleteRecordings: (_1: DeleteType) => void;
  getTrackingLabel: () => string;
  cannotPurchaseAbroad: () => void;
  cannotPlaybackAbroad: () => void;
  setRouteProps: (routeProps: DetailsRouteProps) => void;
  hideDetailsPanel: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // [x: string]: any;
};

export enum AltiboxAssetButtonType {
  PLAY = 'PLAY',
  FAVOURITE = 'FAVOURITE',
  TRAILER = 'TRAILER',
  PVR = 'PVR',
  FROMSTART = 'FROMSTART',
  DELETE_SINGLE = 'DELETE_SINGLE',
  DELETE_SEASONS = 'DELETE_SEASONS',
  DELETE_SERIES = 'DELETE_SERIES',
  DELETE_ALL = 'DELETE_ALL',
}

export type UnionAssetTypes = Program | VodAsset | PvrRecording;

export enum AssetType {
  VOD = 'VOD',
  SVOD = 'SVOD',
  PROGRAM = 'PROGRAM',
  RECORDING = 'RECORDING',
}

export enum AssetVariant {
  MOVIE = 'MOVIE',
  SERIES = 'SERIES',
  SEASON = 'SEASON',
  EPISODE = 'EPISODE',
}

export interface AltiboxSeason {
  seasonNumber: string;
  content: AltiboxAsset[];
  description?: string;
}
// interface PvrPicture {
//   href: string;
// }

export enum AlertType {
  GET_ACCESS = 'get access',
  GET_ACCESS_DK = 'customer service',
  INFO = 'info',
  DEEPLINK = 'deeplink',
  RETURN_TO_BASE = 'return to base',
}
export interface AlertData {
  title: string;
  text: string[];
  type: AlertType;
  buttonText?: string;
  link?: string;
}

export interface AppReducerState {
  shouldFadeOut: boolean;
  originLocation: string;
  alertVisible: boolean;
  bookmarks: Bookmark[];
  continueWatchingAssetsIsLoading: boolean;
  continueWatchingAssets: AltiboxAsset[];
  alert: AlertData;
  portalMenu: PortalMenu[];
  svodKiosks: PortalMenu[];
  frontPageConfig: FrontPageConfig;
  defaultImagePath: string;
  nielsenChannelMapping?: NielsenChannelMapping[];
}

export interface PvrSpace {
  used: number;
  left: number;
}

export enum PvrSpaceStatus {
  FULL = 0.995,
  LOW = 0.95,
}

export interface PvrReducerState {
  scheduledRecordings: PvrRecording[] | undefined;
  recordings: PvrRecording[] | undefined;
  bookmarks: Bookmarks | undefined;
  currentPvrSeries?: PvrRecording;
  recordingsLoading: boolean;
  recordingToDelete?: PvrRecording;
  recordingDeleted?: PvrRecording | PvrRecording[];
  showDeleteSeasonModal: boolean;
  showDeleteSeriesModal: boolean;
  showAggregatedDeleteModal: boolean;
  space: PvrSpace | undefined;
  selectedSort: PvrSorting;
  tempBookmark: Bookmark | undefined;
  isRecordingModalOpen: boolean;
}

export interface ChannelsRecordingsList {
  seriesRecordings: PvrRecording[];
  singleRecordings: PvrRecording[];
}

export interface MiniEpgPlaybills {
  [k: string]: Program[] | undefined;
}

export interface ProgramDetailsType {
  program: DetailsRouteProps;
  displayType: AltiboxAssetDetailsType;
  svodKiosk?: PortalMenu;
}

export interface ChannelsReducerState {
  channels: Channel[];
  sortedChannels: Channel[];
  popularChannels: AMR.PopularChannel[];
  favoriteChannels: Channel[];
  favoritePrograms: FavoritePrograms;
  channelListVisible: boolean;
  currentChannel?: Channel;
  focusedChannel?: Channel;
  currentProgram?: Program;
  tvGuidePlaybills: Map<string, Program[]>;
  playbills: Map<string, Program[]>;
  cachedPlaybills: Map<string, Map<string, Program[]>>;
  focusedChannelTopOffset: number;
  startingCatchup: boolean;
  programDetails?: ProgramDetailsType;
  playbillShouldHide: boolean;
  seriesRecordings: PvrRecording[];
  singleRecordings: PvrRecording[];
  recordingActionInProgress: boolean;
  channelNotFound: boolean;
  catchupNotFound: boolean;
  catchupDataLoading: boolean;
  genres: Genre[];
  liveFromStart: boolean;
  tvGuideLoading: boolean;
  tvGuideFilter: TVGuideChannelFilter;
  miniEpgPlaybills: MiniEpgPlaybills;
  tvGuideGenreFilterList: string[];
  currentDate: Date;
}

export interface AltiboxNetworkResponse {
  isInside: string;
}

export enum AltiboxAssetDisplayTypes {
  HorizontalScroller = 'horizonalscroller',
  GridView = 'gridview',
}

export enum AltiboxAssetDetailsType {
  VOD = 'VOD',
  VIDEO_VOD = 'VIDEO_VOD',
  SVOD = 'SVOD',
  PVR = 'PVR',
  CATCHUP = 'CATCHUP',
  PROGRAM = 'PROGRAM',
}

export interface Device {
  title: string;
  value: string;
  isonline: string;
  lastOnlineTime: string;
  deviceName: string;
  deviceId: string;
  physicalDeviceId: string;
  ESTID: string;
}
export interface AuthReducerState {
  deviceReplaceTimes: number;
  isHome: boolean;
  country: string;
  isGuest: boolean;
  isInternetOnlyAuthByIp: boolean;
  isInternetOnlyAuthByCreds: boolean;
  isInternetOnly: boolean;
  devicesToSwitch: [Device];
  locationSelectModalVisible: boolean;
  deviceSwitchModalVisible: boolean;
  loginError?: string;
  accounts?: Array<AccountWithExtraData>;
  productList?: Product[];
  hasPvr?: boolean;
  loggedInWithCredentials: boolean;
  loggedInWithIp?: boolean;
  auth?: ApiAuthResponse;
  loginModalVisible: boolean;
  userFullName: string;
}

export interface DetailsPanelType {
  routeProps: DetailsRouteProps;
  displayType: AltiboxAssetDetailsType;
  svodKiosk?: PortalMenu;
  clickedId?: string;
}

export type DetailsReducerState = {
  currentAsset: AltiboxAsset | undefined;
  loading: boolean;
  detailsPanel: DetailsPanelType | undefined;
  isSlidingOut: boolean | undefined;
};

export type DetailsMeta = {
  favourite: Favourite;
  isSeries: boolean;
  purchaseOptions?: VodContentProduct[] | string;
  progress?: number;
  kiosk?: PortalMenu;
  defaultImagePath?: string;
  channel?: Channel;
  forceRedirectToSingle?: string;
  hide?: boolean;
  producedate?: string;
  country?: string;
  introduce?: string;
};

export type AuthStatus = {
  isGuest: boolean;
  isHome: boolean;
  loggedInWithCredentials: boolean;
};

export type Favourite = {
  asset: VodAsset | Program;
  catalog: string;
};

export type SharedAssets = {
  programarchive?: ProgramSharedAsset[];
  pvr?: PVRSharedAsset[];
  vod?: VodSharedAsset[];
  svod?: SvodSharedAsset[];
};

export type SharedAsset = {
  favoCatalog: string;
};

export type VodSharedAsset = SharedAsset & {
  lowestPrice: number;
  isBought: boolean;
  isRented: boolean;
  asset: VodAsset;
  pricing?: VodContentProduct[];
};

export type SvodSharedAsset = SharedAsset & {
  asset: VodAsset;
  kiosk: PortalMenu | undefined;
  pricing?: VodContentProduct[];
};

export type ProgramSharedAsset = SharedAsset & {
  asset: Program;
};

export type PVRSharedAsset = SharedAsset & {
  asset: PvrRecording;
};

export interface PurchaseProduct extends Product {
  contentname: string;
  price: string;
  isEST: string;
  contenttype: string;
  priceobjectid: string;
}

export enum PurchaseStates {
  Options,
  Purchase,
  Purchasing,
  Confirmation,
}

export enum PurchaseErrors {
  NoFunds,
  InvalidUser,
  Restrictions,
  BlockedPurchase,
}

export enum DRM {
  WIDEVINE = 8,
  PLAYREADY = 6,
  FAIRPLAY = 9,
}

export interface KeyEvent {
  keyCode: number;
}

export interface PurchaseAsset {
  businesstype: number;
  contentid: string;
  contenttype: string;
  continuetype: number;
  productid: string;
  serviceid: string;
  servicetype: number;
}

export interface PurchaseContentLocked {
  contentid: string;
  contenttype: string;
  iscontrolled: number;
  islocked: number;
}

export interface AltiboxApiSiteResponse {
  status: string;
  message: string;
  value: AibSite[];
}

export interface AltiboxApiLinkResponse {
  status: string;
  message: string;
  messageCode: string;
}

export interface AibSite {
  siteId: string;
  services: string[];
}

export interface HuaweiAuthenticateResponse {
  errorCode: string;
  desc: string;
  jSessionID?: string;
  cSessionID?: string;
}

export interface AltiboxApiAuthenticateResponse {
  status: string;
  message: string;
  accessToken: string;
  data: {
    sessionTicket: {
      identifier: string;
    };
    user: {
      firstName: string;
      lastName: string;
    };
  };
}

export interface HeartBitResponse {
  retcode: string;
  nextcallinterval: string;
}

export interface LoginResponse {
  epghttpsurl: string;
  enctytoken: string;
  version: string;
}

export interface ApiAuthResponse {
  isInternetOnly?: boolean;
  isInternetOnlyAuthByIp?: boolean;
  isInternetOnlyAuthByCreds?: boolean;
  loginIP: string;
  location: string;
  country: string;
  isInside: string;
  retmsg: string;
  retcode: string;
  areaid: string;
  usergroup: string;
  epgurl: string;
  ca: {
    widevine: {
      LA_Url: string;
    };
  };
  sessionid: string;
  currenttime: string;
  parameters: object;
  users: object;
  userID: string;
  currentuser: string;
  errorCode: string;
  desc: string;
  deviceId: string;
  deviceName: string;
  success: boolean;
  subscriberId?: string;
  accounts?: AccountWithExtraData[];
  message?: string;
  bossID: string;
  subnetId?: string;
  jSessionID?: string;
  cSessionID?: string;
}

export interface Channel {
  contentId: string;
  name: string;
  chanNo: string;
  pictures: ChannelLogoPicture[];
  mediaId: string | undefined;
  channelPermissions: ChannelPermissions;
  physicalChannel?: ApiPhysicalChannel;
  locationCopyrights: string[];
  isfavorited?: string;
}

export interface SvodChannel extends Channel {
  svodKiosk?: PortalMenu;
}

export interface ChannelPermissions {
  onlyInsideforLiveTV: boolean;
  onlyInsideForCatchup: boolean;
  onlyIptvChannel: boolean;
  notInSubscription: boolean;
  hasEnabledCatchup: boolean;
  catchupTimeToLive?: string;
}

export enum TVGuideChannelFilter {
  ALL = 'all channels list',
  MINE = 'my channels list',
  FAVORITE = 'favorite channels list',
}

export interface ExtensionInfo {
  key: string;
  value: string;
}

export interface ProgramToRecord {
  id?: string;
  name?: string;
  episodeNum?: string;
  seasonNum?: string;
  channelName?: string;
  lifetimeId?: string;
  starttime?: moment.Moment;
  endtime?: moment.Moment;
}

export enum ProgramType {
  Movie = 'movie',
  Episode = 'episode',
  Program = 'program',
}

export type Program = {
  id: string;
  type: string;
  subName?: string;
  name: string;
  channelid: string;
  channel?: Channel;
  channelName?: string;
  starttime: string;
  isfavorited?: string;
  endtime: string;
  country?: string;
  producedate: string;
  recordedMediaIds?: string[];
  extensionInfo: ExtensionInfo[];
  picture: Picture;
  seasonNum: string;
  subNum: string;
  genres: string;
  genreIds?: string[];
  lifetimeId?: string;
  introduce: string;
  seriesID: string;
  fakeSeriesID?: string;
  visittimes?: string;
  programType?: ProgramType;
  brandLogo?: string;
  croppedBrandLogo?: string;
  foreignsn?: string;
  externalContentCode?: string;
  istvod?: string;
  casts?: Cast[];
  cast?: {
    actor: string;
    director: string;
    producer: string;
  };
  seasons?: AltiboxSeason[];
  chapterNO?: string;
  /**
   * Added from the getContinueWatchingPadding function
   */
  beginOffset?: number;
  endOffset?: number;
};

export interface Picture {
  deflate: string;
  href: string;
  poster: string;
  still: string;
  other: string;
  ad: string;
  background: string;
}

export interface SeriesRelation {
  channelId: string;
  programId: string;
  seriesId: string;
}

export enum GenreType {
  Music = 1,
  MovieAndTV = 2,
  Application = 3,
  Image = 4,
  Theme = 5,
  Information = 6,
  ChannelProgram = 7,
}

export enum GenreTypes {
  SERIES = 'series',
  MOVIES = 'movies',
}

export enum BroadcastStatus {
  Finished,
  Live,
  Future,
}

export enum DeleteType {
  SINGLE = 'SINGLE',
  SEASONS = 'SEASONS',
  SERIES = 'SERIES',
  ALL = 'ALL',
  SWITCH = 'switch',
  EPISODES = 'EPISODES',
  EPISODES_AND_STOP_RECORDING = 'EPISODES_AND_STOP_RECORDING',
  CANCEL = 'CANCEL',
}

export interface Genre {
  genreId: string;
  genreType: GenreType;
  genreName: string;
}

export type GetStateType = () => RootState;
export type BaseAction = {
  type: symbol;
};
// export type DispatchType = (_:T) => Promise<any>;
// export type DispatchType = (action: BaseAction) => Promise<any> | boolean;
// export type DispatchType = ((_: & BaseAction) => void)
//   | ((_: & BaseAction) => Promise<any>)
//   | ((_:Function) => any);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DispatchType = (_: any) => any;
export type DispatchResponse = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  type: symbol;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

export interface HuaweiBatchResponse<name extends string, SingleResponseType> {
  responseList: {
    name: name;
    msg: SingleResponseType;
  }[];
}

export enum ContentType {
  VOD = 'VOD',
  TELEPLAY_VOD = 'TELEPLAY_VOD',
  CHANNEL = 'CHANNEL',
  PROGRAM = 'PROGRAM',
  TVOD = 'TVOD',
  VAS = 'VAS',
  AUDIO_CHANNEL = 'AUDIO_CHANNEL',
  VIDEO_CHANNEL = 'VIDEO_CHANNEL',
  WEB_CHANNEL = 'WEB_CHANNEL',
  FILECAST_CHANNEL = 'FILECAST_CHANNEL',
  AUDIO_VOD = 'AUDIO_VOD',
  VIDEO_VOD = 'VIDEO_VOD',
}

export enum SearchType {
  CONTENT_NAME = '1',
  GENRE_NAME = '2',
  VOD_TAG = '4',
  STAR_NAME = '8',
  DIRECTOR_NAME = '16',
  AUTHOR_NAME = '32',
  SINGER_NAME = '64',
  PRODUCER_NAME = '128',
  SCREENWRITER_NAME = '256',
  OTHER_CAST_AND_CREW_NAME = '512',
  DIRECTORS_AND_OTHER_CAST = '528',
  ALL_FIELDS = '1023',
}

export interface ApiBatchSearchResponse extends HuaweiBatchResponse<'Search' | 'QueryPVR', GeneralSearchResponse> {}

export interface SearchResponse {
  msg: GeneralSearchResponse | PVRSearchResponse;
}
export interface GeneralSearchResponse {
  contentlist: VodAsset[];
  counttotal: string;
}

export interface AltiboxAssetSearchResponse {
  contentlist: AltiboxAsset[];
  counttotal: string;
}

export interface PVRSearchResponse {
  pvrlist: PvrRecording[];
  counttotal: string;
}

export interface SearchResult extends VodAsset {
  name: string;
  picture: Picture;
  type: ContentType;
  searchType: SearchType;
}

export interface SearchCategories {
  VOD: string;
  SVOD: string;
  ActiveContent: string[];
}

export interface SearchPictures {
  ad: string;
  background: string;
  other: string;
  poster: string;
}

export interface GlobalSearchConfig {
  [name: string]: SearchConfig;
}
export interface SearchConfig {
  priority?: SearchPriority[];
  service?: string;
  type?: AltiboxAssetType;
  noFocus?: boolean;
  baseService: string;
  returnPath: string;
}

export enum SearchPriority {
  VOD = 0,
  SVOD = 1,
  CATCHUP = 2,
  TVGUIDE = 3,
  RECORDINGS = 4,
}

export enum SearchFilter {
  REGULAR = 'REGULAR',
  MOVIES = 'MOVIES',
  SERIES = 'SERIES',
  PROGRAMARCHIVE = 'PROGRAMARCHIVE',
  TVGUIDE = 'TVGUIDE',
  RECORDING = 'RECORDING',
}

export interface SearchResultPriority {
  prioritized: AltiboxAsset[] | undefined;
  rest: AltiboxAsset[] | undefined;
  unfiltered: AltiboxAsset[] | undefined;
}
export interface SearchResultCast {
  actors: Cast[] | undefined;
  producers: Cast[] | undefined;
}

export interface SeriesRelationsResponse {
  counttotal: string;
  seriesRelations: SeriesRelations[];
}

export interface SeriesRelations {
  channelID: string;
  lifetimeID: string;
  name: string;
  programID: string;
  seasonNum: string;
  seriesID: string;
  starttime: string;
  subNum: string;
}

export interface SearchFunctions {
  fetchSearchResults: (searchQuery: string) => Promise<void>;
  emptySearch: () => DispatchResponse;
}

export interface VodContentProduct extends Product {
  isEST: string;
  issubscribed: string;
  contenttype: string;
  price: string;
  introduce: string;
  rentperiod: string;
}

export interface VodContentPriceResponse {
  retcode: string;
  retmsg: string;
  list: VodContentProduct[];
}

export interface LoginAliasResponse {
  status: string;
  message: string;
  messageCode: string;
  data: LoginAliasResponseData;
}

export interface LoginAliasResponseData {
  sessionTicket: SessionTicket;
  user: AliasUserData;
  permissionLevel: string;
}
export interface SessionTicket {
  identifier: string;
}

// In rare cases where infonova is down, many of these fields will be null.
export interface AliasUserData {
  firstName: string | null;
  lastName: string | null;
  partnerNumber: number;
  customerNumber: string | null;
  customerCrmId: string | null;
  crmId: string | null;
  crmSystem: string;
}

export interface ExtensionInfoResponse {
  retcode: string;
  extensionInfo: [
    {
      key: string;
      value: string;
    },
  ];
}

export interface SeriesOrSinglePrograms {
  index: number;
  seriesRecording: boolean;
  programID: string;
  externalContentCode: string;
}

export interface FavouriteResponse {
  countTotal: string;
  favoritelist: [
    {
      collectTime: string;
      id: string;
      type: ContentType;
      favoId: string;
    },
  ];
}

export interface FavoCatalogResponse {
  retcode: string;
  retmsg: string;
  favolist?: [
    {
      id: string;
      name: string;
    },
  ];
}

export interface BatchBookmarksResponse extends HuaweiBatchResponse<'QueryBookmark', Bookmarks> {}

export interface ContinueWatchingAsset {
  asset: AltiboxAsset;
  bookmark: Bookmark;
}

interface ProgramSeries {
  name: string;
  seriesID: string;
  maxSeasonNumber?: string;
  latestBroadcastTime?: string;
  picture?: Picture;
  newestEpisodeNumber?: string;
}

export interface QueryProgramSeriesResponse {
  counttotal?: string;
  programSerieses?: ProgramSeries[];
}

export type OptionsModalContent = {
  modalIsOpen: boolean;
  title: string;
  subtitle: string;
  subtitle2: string;
  options: OptionType[];
  posttitle: string;
  closetext: string;
  error: boolean;
  overrideButtonIcon?: string;
  closeButton?: HTMLButtonElement | null;
  onClose: () => void;
  onAction: (option: OptionType) => void;
  modalHeroBackground?: string;
  deletedRecordings?: string[];
};

export type OptionType = {
  title: string;
  titleInfo?: string;
  value?: DeleteType;
  style: string;
  type: 'primary' | 'secondary';
  series?: PvrRecording;
  season?: Season;
  nextAction?: 'confirm' | 'delete';
};

export interface LeadLagTime {
  leadTime: number;
  lagTime: number;
}

export enum GAcategory {
  uninitialized = 'uninitialized',
  moviesandseries = 'moviesandseries',
  programarchive = 'catchup',
  frontpage = 'frontpage',
  watchtv = 'watchtv',
  pvr = 'pvr',
  svod = 'svod',
  mycontent = 'mycontent',
  tvguide = 'tvguide',
  playerError = 'PlayerError',
  webError = 'WebError',
  error = 'Error',
  login = 'Login',
  streamingServices = 'streamingServices',
}

export enum GAaction {
  openApp = 'openApp',

  login = 'login',
  recordSeries = 'recordSeries',
  recordProgram = 'recordProgram',
  recordDeleteSeason = 'recordDeleteSeason',
  recordDeleteSeries = 'recordDeleteSeries',
  recordSeriesCancel = 'recordSeriesCancel',
  recordProgramCancel = 'recordProgramCancel',
  recordDeleteProgram = 'recordDeleteProgram',

  openService = 'openService',
  openExternal = 'openExternal',
  errorMessage = 'errorMessage',
  notification = 'notification',
  openDetailsPanel = 'openDetailsPanel',
  openModal = 'openModal',

  favoriteRemove = 'favoriteRemove',
  favoriteAdd = 'favoriteAdd',

  play = 'play',
  playTrailer = 'playTrailer',
  playChannel = 'playChannel',
  playback_play = 'playback_play',
  playFromStart = 'playFromStart',
  playback_pause = 'playback_pause',
  playNextChannel = 'playNextChannel',
  playPreviousChannel = 'playPreviousChannel',
  playback_jumpToLive = 'playback_jumpToLive',
  playback_jumpToStart = 'playback_jumpToStart',
  playback_scrollToLive = 'playback_scrollToLive',

  bannerInteraction = 'bannerInteraction',
  rentOrBuyNotPossibleAbroad = 'rentOrBuyNotPossibleAbroad',
  assetGeoblocked = 'assetGeoblocked',
  aboutToSpend = 'aboutToSpend',
  rentHD = 'rentHD',
  buyHD = 'buyHD',
  rentSD = 'rentSD',
  buySD = 'buySD',
  transactionFailed = 'transactionFailed',

  openCastAndCrewList = 'openCastAndCrewList',

  listFilterBy = 'listFilterBy',

  sessionExpired = 'Session Expired',
  sessionRedirected = 'Redirecting',
  loginError = 'LOGIN ERROR',

  noFreeNonESTSlot = 'NO_FREE_NON_EST_SLOT',
}

export enum GAlabel {
  noPVRAccess = 'noPVRAccess',
  maximumNoOfStreamReached = 'maximumNoOfStreamReached',
  channelGeoblocked = 'channelGeoblocked',
  noChannelRightsOutsideHome = 'noChannelRightsOutsideHome',
  mineSider = 'mineSider',
  thirdParty = 'thirdparty',
  search = 'search',
}

export enum Domains {
  DK = 'tv.altibox.dk',
  NO = 'tv.altibox.no',
  NET = 'tv.altibox.net',
  DKBETA = 'tvbeta.altibox.dk',
  NOBETA = 'tvbeta.altibox.no',
  NETBETA = 'tvbeta.altibox.net',
  PRODUCTION = 'tv.altibox',
  BETA = 'tvbeta.altibox',
}

export enum AltiboxServiceLink {
  NO = 'https://www.altibox.no/velg',
  DK = 'https://www.altibox.dk/bestil-streaming',
}

export enum SubnetIds {
  DK = 4501,
  NO = 4701,
}

export enum AltiboxURLs {
  HOME,
  GDPR,
  MY_PAGE,
  TOP_DOMAIN,
  CUSTOMER_SERVICE,
}
interface Autoplay {
  seasonIndex: number;
  episodeIndex: number;
  iteration: number;
}
export interface VodAutoplay extends Autoplay {
  seriesAsset: VodAsset;
  nextAsset: VodAsset | undefined;
}
export interface CatchupAutoplay extends Autoplay {
  seriesAsset: Program;
  nextAsset: Program | undefined;
}
export interface PVRAutoplay extends Autoplay {
  seriesAsset: PvrRecording;
  nextAsset: PvrRecording | undefined;
}

export enum Day {
  YESTERDAY = -1,
  TODAY = 0,
  TOMRROW = 1,
}

export enum PlayerOptions {
  BitRates,
  AudioAndSubtitles,
}

export interface FavoritePrograms {
  [k: string]: boolean | undefined;
}

export interface GetFavoriteRequestBody {
  favoid?: string;
  contenttype?: ContentType;
  orderType?: 0 | 1;
  filterlist?: {
    key: 'IsHide';
    value: '-1' | '0' | '1';
  }[];
  channelNamespace?: string;
  count?: number;
  offset?: number;
}

export interface ContentDetailsRequestBody {
  userContentFilter?: string;
  vod?: string;
  metaDataVer?: 'Channel/1.0' | 'Channel/1.1';
  channel?: string;
  category?: string;
  vas?: string;
  playbill?: string;
  idType?: 0 | 1 | 2 | 3 | 4;
  filterType?: 0 | 1;
  properties?: {
    name: string;
    include: string;
  }[];
}

export interface QueryProgramSeriesRequestBody {
  seriesID?: string[];
  begintime?: string;
  endtime?: string;
  orderType?: 0 | 1;
  count?: number;
  offset?: number;
  filterlist?: {
    key: 'Genre';
    value: string;
  }[];
}

export enum ChannelLogoType {
  DEFAULT = '12',
  CROPPED = '3',
}

export interface ChannelLogoPicture {
  href: string;
  imageType: ChannelLogoType;
  resolution: string[];
}

export interface Account {
  accountName: string | null; // Can be null if infonova is down
  customerId: string;
  id: string;
  name: string;
  partnerId: number;
}

interface BaseAltiboxSubscriber {
  username: string;
  credential: string;
  address: string;
}

interface PaceSubscriber extends BaseAltiboxSubscriber {
  platform: 'PACE';
}

export interface NonPaceSubscriber extends BaseAltiboxSubscriber {
  platform: 'HUAWEI' | 'MIXED';
}

export type AltiboxSubscriber = PaceSubscriber | NonPaceSubscriber;

export interface HuaweiSubscriber {
  subscriberId: string;
  profilePin: string;
  authToken: string;
  address?: string;
}

export type AibHuaweiNonPaceSubscriber = NonPaceSubscriber & HuaweiSubscriber;

export type AccountType = 'CUSTOMER_PRIVATE' | 'CUSTOMER_ENTERPRISE' | 'NOT_AUTHORIZED';

export interface Partner {
  countryCode: string;
  name: string;
  partnerId: number;
  platform: 'legacy' | 'migrated';
}

export interface ExtraAccountData {
  user: LoginAliasResponseData['user'];
  subscribers: AibHuaweiNonPaceSubscriber[];
  type: AccountType;
  sessionTicket: string;
  partner?: Partner;
}

export type AccountWithExtraData = Account & ExtraAccountData;

export enum PopularContentChannelTimespan {
  THIRTY_MINUTES = '30m',
  TWO_HOURS = '2h',
  TWENTYFOUR_HOURS = '24h',
}

export declare module AMR {
  interface BtvBR {
    is: string;
    va: string;
  }

  interface CutvCR {
    dt: string;
    e: string;
    l: string;
    mi: string;
    prl: string;
    va: string;
  }

  interface PhysicalChannel {
    btvBR: BtvBR;
    cutvCR: CutvCR;
    mediaId: string;
  }

  interface PopularChannelPicture {
    v: { f: [{ v: string }] };
  }

  interface PopularChannel {
    contentId: number;
    locationCopyrights: [{ v: string }];
    name: string;
    numPlays: number;
    physicalChannels: PhysicalChannel[];
    pictures: PopularChannelPicture[];
    position: number;
  }

  interface PopularChannelResponse {
    channels: PopularChannel[];
    listType: 'top_last_30m' | 'top_last_2_hours' | 'top_last_24_hours';
  }

  interface MostWatchedCatchupResponse {
    limit: number;
    listType: string;
    offset: number;
    programs: Program[];
    total: number;
    updatedAt: string;
  }
}

export type AssetsBySvodKioskIdentifier = Record<Required<PortalMenu>['identifier'], VodAsset[] | undefined>;

export type SortingOption = {
  title: string;
  active: boolean;
  value: SortingValues;
  type: SortingTypes;
};

export interface ChromecastCustomData {
  mediaId: string;
  contentId: string;
  altiboxUrl: string;
  baseUrl: string;
  playType: string;
  pvrId?: string;
  currentSubtitle?: string;
  currentAudio?: string;
  systemLanguage?: string;
  country?: string;
  recordedMediaId?: string;
  loginToken?: string;
  isHome: boolean;
}

export interface NielsenSDK {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nlsQ: (o: string, r: string, c: any) => NielsenFunctions;
}

export interface NielsenFunctions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ggPM: (o?: any, c?: any, s?: any, a?: any, i?: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackEvent: (o: any) => void;
}

export interface NielsenContentMetadata {
  type: string;
  assetid: string;
  program: string;
  title: string;
  length: string | number;
  airdate: string;
  isfullepisode: NielsenReinventsBooleans;
  adloadtype: string;
  stationId?: string;
  islivestn?: string;
  pbstarttm?: string | number;
  subbrand?: string;
}

export interface NielsenAdMetadata {
  type: string;
  assetid: string;
  subbrand: string;
}

export enum NielsenReinventsBooleans {
  TRUE = 'y',
  FALSE = 'n',
}

export interface NielsenChannelMapping {
  name: string;
  id: string;
  subBrand: string;
}

export enum NielsenEventTypes {
  LOAD_METADATA = 'loadMetadata',
  SET_PLAYHEAD_POSITION = 'setPlayheadPosition',
  SEND_ID3 = 'sendID3',
  STOP = 'stop',
  END = 'end',
}

export interface PlaybillDetailResponse {
  playbillDetail: PlaybillDetails;
  result: {
    retCode: string;
    retMsg: string;
  };
}
export interface PlaybillDetails {
  playbillSeries?: {
    chapterNO: string;
    lifetimeID: string;
    seasonNO: string;
    seriesID: string;
    sitcomNO: string;
    sitcomName: string;
  };
  chapterInfo?: {
    chapterNO: string;
    chapterTotalCount: string;
  };
}
export enum AgeLimitColorsGroup {
  WHITE = 'white',
  GREEN = 'green',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  RED = 'red',
}

export enum AgeRatingCountry {
  DK = 'RATING_DK',
  NO = 'RATING_NO',
}

export interface Option {
  title?: string;
  count?: string | number;
  active?: boolean;
  value?: Filter | SortingValues | number;
  type?: SortingTypes;
}
