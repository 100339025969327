import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { ScriptService } from '../../../controllers/ScriptService';

interface Props {
  title: string;
  path: string;
}

const AltiboxGenreButton = ({ title, path }: Props) => {
  const genreButtonClass: string = 'altibox-genre-button' + (ScriptService._isIE() ? ' IE' : '');
  return (
    <div className={genreButtonClass}>
      <Link className="altibox-genre-link" key={title} to={path}>
        {title}
      </Link>
    </div>
  );
};
export default AltiboxGenreButton;
