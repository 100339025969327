import { getBaseUrl, getSessionTicket } from '../../config';
import { fetchError, getDataFromResponse } from '../../controllers/Fetch';
import { GenreType } from '../../interfaces';
import { Genre } from './types';

export async function getAllGenres(genreType: GenreType = 7, genreIds?: string[]) {
  try {
    const res = await fetch(getBaseUrl() + '/EPG/JSON/GetGenreList', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        genreType,
        genreIds,
      }),
    });

    return (await getDataFromResponse<Genre.All.ApiResponse>(res)).genres;
  } catch (error) {
    return fetchError(error as string);
  }
}

export async function getEpgGenres(areaId: string, bossId: string) {
  const configUrl = `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/epg-genres.json`;

  try {
    const res = await fetch(configUrl, {
      method: 'GET',
      headers: { SessionTicket: getSessionTicket() },
    });

    return await getDataFromResponse<Genre.Epg.Item[]>(res);
  } catch (error) {
    return fetchError(error as string);
  }
}
