import { isArray } from 'lodash';
import { Program, ThirdPartyCatchupData, ApiAuthResponse } from '../interfaces';
import { queryClient } from '../queries/client';
import { thirdPartyCatchupKeys } from '../queries/third-party-catchup/keys';
import { isDeepLinkValid } from './programArchiveUtils';

export function constructThirdPartyInformation(
  program: Pick<Program, 'channelid' | 'extensionInfo' | 'istvod' | 'lifetimeId'>,
  data: ThirdPartyCatchupData | undefined,
) {
  /** The full url built from the origin, with data from `extensionInfo` and query params */
  const url = getThirdPartyCatchupUrl(data, program);

  /** Base URL and query params */
  const baseUrlInfo = getThirdPartyCatchupBaseUrl(data, program);

  /** The domain origin url */
  const fallbackUrl = baseUrlInfo?.url ? new URL(baseUrlInfo.url).origin : '';

  /** Is a catchup program that comes from a third party */
  const isExternal = program.istvod !== undefined && program.istvod === '1' && data !== undefined;

  /** Checks if the catchup is valid */
  const isValid = isExternal && isDeepLinkValid(program);

  return { data, url, fallbackUrl, isExternal, isValid };
}

export function getThirdPartyCatchupInformation(
  auth: ApiAuthResponse | undefined,
  program: Pick<Program, 'channelid' | 'extensionInfo' | 'istvod' | 'lifetimeId'>,
) {
  /** The specific third party catchup data for the passed program */
  const data = getThirdPartyCatchupData(auth, program.channelid);
  return constructThirdPartyInformation(program, data);
}

/**
 * Will get the third party catchup data from the cache. If `channelId` is provided, it will return the data for that channel.
 * If `channelId` is not provided, it will return all the data. If no data is found, it will return undefined.
 *
 * @param auth
 * @param channelId
 */
export function getThirdPartyCatchupData(auth: ApiAuthResponse | undefined): ThirdPartyCatchupData[];
// eslint-disable-next-line no-redeclare
export function getThirdPartyCatchupData(
  auth: ApiAuthResponse | undefined,
  channelId: string,
): ThirdPartyCatchupData | undefined;
// eslint-disable-next-line no-redeclare
export function getThirdPartyCatchupData(auth: ApiAuthResponse | undefined, channelId?: string) {
  if (!auth) {
    return undefined;
  }

  const data = queryClient.getQueryData<ThirdPartyCatchupData[]>(thirdPartyCatchupKeys.auth(auth.bossID, auth.areaid));

  if (channelId) {
    return data?.find((x) => x.channelIds.includes(channelId));
  }

  return data;
}

/**
 * Extracts the base url and query parameters from the third party catchup data.
 * @param data
 * @param program
 */
export function getThirdPartyCatchupBaseUrl(
  data: ThirdPartyCatchupData | ThirdPartyCatchupData[] | undefined,
  program: Pick<Program, 'channel' | 'extensionInfo'>,
) {
  const channelId = program.channel?.contentId;
  const externalData = isArray(data) ? data.find((x) => x.id === channelId) : data;

  if (!externalData) {
    return undefined;
  }

  return { url: externalData.actionParams.url.web, queryParams: externalData.actionParams.queryParams };
}

/**
 * Gets the third party catchup url from the third party catchup data and builds the url from the extensionInfo.
 * @param data
 * @param program
 */
export function getThirdPartyCatchupUrl(
  data: ThirdPartyCatchupData | ThirdPartyCatchupData[] | undefined,
  program: Pick<Program, 'channel' | 'extensionInfo'>,
) {
  const baseUrlInfo = getThirdPartyCatchupBaseUrl(data, program);

  if (!baseUrlInfo) {
    return undefined;
  }

  const { url, queryParams } = baseUrlInfo;
  const extensionInfoPath = program.extensionInfo.find((x) => x.key === 'CATCHUP_LINK')?.value;

  if (!extensionInfoPath) {
    return undefined;
  }

  const suffixPath = getSuffixPath(extensionInfoPath, queryParams);

  return url + suffixPath;
}

/**
 * Get the suffix path from the extensionInfo, making sure to add the query parameters in the correct way.
 * @param extensionInfoPath
 * @param queryParams
 */
function getSuffixPath(extensionInfoPath: string, queryParams: string[] | undefined) {
  let path = extensionInfoPath;

  // If the path doesn't start with a slash, add one
  if (!path.startsWith('/')) {
    path = '/' + path;
  }

  if (!queryParams) {
    return path;
  }

  // If the path includes a `?` then there are query parameters included
  if (path.includes('?')) {
    if (path.endsWith('&')) {
      path = path + queryParams.join('&');
    } else {
      path = path + '&' + queryParams.join('&');
    }
  } else {
    path = path + '?' + queryParams.join('&');
  }

  return path;
}
