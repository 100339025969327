import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useConfirm } from '../../hooks/useConfirm';
import { SvgDelete, SvgMultipleSelection, SvgRearrange } from '../../icons';
import { Recording } from '../../queries/recordings/types';
import FadeIn from '../UI/FadeIn';
import styles from './styles/DeleteActions.module.scss';

interface DeleteLeftActionsProps {
  onSetSelectionMode: React.Dispatch<React.SetStateAction<boolean>>;
  markAllSelections: () => void;
  clearAllSelections: () => void;
  openModal: () => void;
  selectionMode: boolean;
  selectedRecordings: Recording.Group.Union[] | Recording.Group.Season[] | Recording.Query.Series[];
  isAllSelected: boolean;
}

export function DeleteLeftActions({
  onSetSelectionMode,
  markAllSelections,
  clearAllSelections,
  openModal,
  selectionMode,
  selectedRecordings,
  isAllSelected,
}: DeleteLeftActionsProps) {
  const { t } = useTranslation('pvr');
  const { isConfirmed } = useConfirm();

  async function handleCancel() {
    const confirmed = await isConfirmed({
      prompt: t('are you sure you want to cancel'),
    });

    if (confirmed) {
      onSetSelectionMode(false);
      clearAllSelections();
    }
  }

  if (selectionMode) {
    return (
      <div className={styles.actions}>
        <button className={styles.action} onClick={handleCancel}>
          {t('cancel')}
        </button>

        <button className={styles.action} onClick={isAllSelected ? clearAllSelections : markAllSelections}>
          {isAllSelected ? t('deselect all') : t('select all')}
        </button>

        {!isEmpty(selectedRecordings) && (
          <FadeIn slideUp>
            <button className={styles.action} onClick={openModal}>
              {t('select recordings')}
              <SvgDelete />
            </button>
          </FadeIn>
        )}
      </div>
    );
  }

  return (
    <button className={styles.action} onClick={() => onSetSelectionMode(true)}>
      {t('select recordings')}
      <SvgMultipleSelection />
    </button>
  );
}

export function DeleteRightActions() {
  const { t } = useTranslation('pvr');

  return (
    <button className={styles.action}>
      {t('sort', { ns: 'translations' }).toUpperCase()}
      <SvgRearrange />
    </button>
  );
}
