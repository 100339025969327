import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../app/Header';
import {
  RootState,
  PvrRecording,
  StreamTypeObject,
  AuthReducerState,
  Channel,
  DispatchType,
  AlertData,
  HeaderDisplayType,
  HeaderInteractionType,
  SearchConfig,
  Picture,
  StreamType,
  StreamingObject,
  GAaction,
  GAlabel,
  GAcategory,
  Bookmark,
  AltiboxAssetDetailsType,
  DetailsPanelType,
  BroadcastStatus,
  NielsenChannelMapping,
} from '../../../interfaces';
import { fetchAllRecordings, setTempBookmark } from '../actions';
import { getPvrPlayUrl } from '../../../api/recording';
import Player from '../../../components/Player';
import { ScriptService } from '../../../controllers/ScriptService';
import { isBookmarkPassedWatchedLimit, getPvrPicture } from '../../../utils/pvrUtils';
import { showAlert } from '../../app/actions';
import channelPermissions from '../../../utils/channelPermissions';

import './style.scss';
import NoSupport from '../../../components/NoSupport';
import { Helmet } from 'react-helmet-async';
import { subtractMinutes, addMinutes, nowHuaweiFormat, broadcastStatus } from '../../../utils/huaweiUtils';
import StickyHeader from '../../app/StickyHeader';
import i18n from '../../../i18n';
import { routes, searchConfig } from '../../../config';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import MobileLock from '../../app/MobileLock';
import { hasPicture, wrapAssetAsAltiboxAsset } from '../../../utils/altiboxassetUtils';
import PlayerAssetCard from '../../../components/Player/UI/PlayerAssetCard';
import { ShowDetailsPanel, showDetailsPanel as showDetailsPanelAction } from '../../../views/details/actions';
import { SlideInDetails } from '../../details';
import moment from 'moment';
import PlayerInfoPopupManager from '../../../components/UI/Player/PlayerInfoPopupManager';
import { isEmpty } from 'lodash';
import { recordingKeys } from '../../../queries/recordings/keys';
import { queryClient } from '../../../queries/client';

interface Props
  extends RouteComponentProps<
    RouteProps,
    {},
    { prevPath?: string; overwriteEpisodeToPromoteId?: string; showDetailsPanel?: Omit<ShowDetailsPanel, 'type'> }
  > {
  authReducer: AuthReducerState;
  recordings: PvrRecording[];
  channels: Channel[];
  shouldHideControls: boolean;
  searchConfig: SearchConfig;
  detailsPanel: DetailsPanelType | undefined;
  nielsenChannelMapping?: NielsenChannelMapping[];
  fetchAllRecordings: () => Promise<PvrRecording[]>;
  showAlert: DispatchType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTempBookmark: (_1: Bookmark | undefined) => any;
  showDetailsPanel: (pvr: PvrRecording) => void;
}

interface RouteProps {
  pvrId: string;
  seriesId: string;
  channelId: string;
}

interface State {
  channelLogo: string;
  recordingNotFound: boolean;
  isGeoLocked: boolean;
  stream: StreamTypeObject | undefined;
  numberOfPlaybacksAllowed: number;
  numberOfPlaybacksDone: number;
  goBack: boolean;
  startWithBookmark: boolean;
  nextPvrId: string;
  currentPvrId: string;
  redirectToObject: {
    path: string;
    overwriteEpisodeToPromoteId?: string;
  };
  // Autoplay
  timeLeft: number;
  goToDetailsAfterEnd: boolean;
  currentSeries: PvrRecording | undefined;
  nextEpisode: PvrRecording | undefined;
}

class PvrPlayer extends Component<Props, State> {
  state: State = {
    channelLogo: '',
    recordingNotFound: false,
    isGeoLocked: false,
    startWithBookmark: true,
    goBack: false,
    numberOfPlaybacksAllowed: 4,
    numberOfPlaybacksDone: 0,
    stream: undefined,
    redirectToObject: {
      path: '',
      overwriteEpisodeToPromoteId: undefined,
    },
    nextPvrId: '',
    currentPvrId: '',
    // Autoplay
    timeLeft: -1,
    goToDetailsAfterEnd: false,
    currentSeries: undefined,
    nextEpisode: undefined,
  };

  // Strict Class Initialization ignored
  redirectInterval!: NodeJS.Timer;
  _recordings: PvrRecording[] | undefined = undefined;

  tracking = AnalyticsTracking.getInstance();

  get allRecordings(): PvrRecording[] {
    let recordings = this.props.recordings || this._recordings;
    recordings = recordings || [];
    return recordings.reduce((result, next) => {
      return [...result, next, ...(next.subRecordings || [])];
    }, [] as PvrRecording[]);
  }

  get recording(): PvrRecording | undefined {
    const recording = this.allRecordings.find((x) => x.pvrId === this.props.match.params.pvrId);
    return recording;
  }

  get imageUrl() {
    const { recording } = this;
    return recording && hasPicture(recording.pictures) ? getPvrPicture(recording) : '';
  }

  get titleName() {
    const { recording } = this;
    return recording?.pvrName || '';
  }

  get seasonNum() {
    const { recording } = this;
    return recording?.seasonNum;
  }

  get badgeText() {
    const { recording } = this;
    if (recording) {
      const isLive =
        broadcastStatus({ starttime: recording.beginTime, endtime: recording.endTime }, moment()) ===
        BroadcastStatus.Live;
      return i18n.t<string>(`${isLive ? 'is recording' : 'recorded'}`);
    }
    return '';
  }

  get prefix() {
    const { recording } = this;

    const isPartOfSeries = recording && !recording.subNum ? false : true;
    return recording && isPartOfSeries ? ` S${recording.seasonNum} E${recording.subNum}` : '';
  }

  get detailsPanelUrl() {
    const { currentSeries } = this.state;
    const currentRecording = this.recording;

    if (!currentRecording || !currentSeries) {
      return '/opptak/';
    }

    return `/opptak/serie/${currentRecording.channelId}/${currentSeries.seriesId}`;
  }

  get nextPlayUrl() {
    const { currentSeries, nextEpisode } = this.state;
    const currentRecording = this.recording;

    if (!currentRecording || !currentSeries || !nextEpisode) {
      return '/opptak/';
    }

    return `/opptak/serie/play/${currentRecording.channelId}/${currentSeries.seriesId}/${nextEpisode.pvrId}`;
  }

  get popupOverlay() {
    const currentRecording = this.recording;
    const { nextEpisode, currentSeries } = this.state;

    if (!currentRecording || !nextEpisode || !currentSeries) {
      return null;
    }

    const autoplay = {
      seasonIndex: 0, // required in type, but not used
      episodeIndex: 0, // required in type, but not used
      iteration: 0, // required in type, but not used
      seriesAsset: currentSeries,
      nextAsset: nextEpisode,
    };

    return (
      <PlayerInfoPopupManager
        timeLeft={this.state.timeLeft}
        autoplay={{ ...autoplay }}
        seriesName={this.titleName}
        assetType={AltiboxAssetDetailsType.PVR}
        callback={this.popupCallback}
        forceExitPlayer={this.handleForceExitPlayer}
        authStatus={this.props.authReducer}
      />
    );
  }

  componentDidMount() {
    const recording = this.recording;
    if (ScriptService.isCurrentBrowserSupported() && recording) {
      this.setState({
        currentPvrId: recording.pvrId,
      });
      this.loadStream(recording);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.componentDidMount();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const [currentSeries, nextEpisode] = this.getCurrentSeriesAndNextEpisode(nextProps);

    if (currentSeries || nextEpisode) {
      this.setState({
        currentSeries,
        nextEpisode,
      });
    }

    if (this.state.redirectToObject.path && this.state.currentPvrId !== this.state.nextPvrId) {
      this.setState({
        redirectToObject: {
          path: '',
        },
        startWithBookmark: false,
      });

      const recording = this.allRecordings.find((x) => x.pvrId === this.state.nextPvrId);
      if (recording && ScriptService.isCurrentBrowserSupported()) {
        this.loadStream(recording);
        return true;
      }
    }

    if (this.state.currentPvrId && this.state.currentPvrId === this.state.nextPvrId) {
      this.setState({
        redirectToObject: {
          path: '',
        },
        goBack: true,
      });
      return true;
    }

    // trick to set props before connector does
    if (!this.props.recordings && nextProps.recordings) {
      this._recordings = nextProps.recordings;
    }

    if (!this.state.currentPvrId) {
      const recording = this.recording;

      if (recording && ScriptService.isCurrentBrowserSupported()) {
        this.setState({
          currentPvrId: recording.pvrId,
        });
        this.loadStream(recording);
      }
    }

    // recordings are loaded but recording is not found
    if (isEmpty(this.allRecordings)) {
      this.setState({
        redirectToObject: {
          path: '/opptak/',
        },
      });
    }
    return true;
  }

  getCurrentSeriesAndNextEpisode({ recordings }: Props) {
    const currentRecording = this.recording;

    if (!currentRecording || currentRecording.isSingle) {
      return [undefined, undefined];
    }

    const currentSeries = recordings.find((recording) => {
      return recording.seriesId === currentRecording?.seriesId && recording.channelId === currentRecording.channelId;
    });

    if (!currentSeries || isEmpty(currentSeries.sortedSeasons)) {
      return [undefined, undefined];
    }

    const seasonIndex = currentSeries.sortedSeasons.findIndex(
      (sortedSeason) => sortedSeason.seasonNum === currentRecording.seasonNum,
    );

    const season = currentSeries.sortedSeasons[seasonIndex];

    const episodeIndex = season.episodes.findIndex((episode) => episode.subNum === currentRecording.subNum);
    let nextEpisode: PvrRecording | undefined = season.episodes[episodeIndex + 1];

    // If next episode doesn't exist, we go to the next season
    // sortedSeasons start with the newest seasons, therefor decrement the index
    const nextSeason = currentSeries.sortedSeasons[seasonIndex - 1];
    if (nextSeason) {
      nextEpisode ||= nextSeason.episodes[0];
    }

    return [currentSeries, nextEpisode];
  }

  unMountCallbackToPlayer = (contentId?: string, rangeTime?: string) => {
    queryClient.invalidateQueries(recordingKeys.continueWatching());

    if (this.props.authReducer.loggedInWithCredentials) {
      this.props.setTempBookmark({
        bookmarkType: '2',
        contentId: contentId!,
        rangeTime: rangeTime!,
        updateTime: nowHuaweiFormat(),
      } as Bookmark);
    }
    this.props.fetchAllRecordings();
  };

  loadStream(recording: PvrRecording) {
    let startBookmark = 0;
    if (isBookmarkPassedWatchedLimit(recording)) {
      startBookmark = 0;
    } else if (this.state.startWithBookmark) {
      startBookmark = parseInt(recording.bookmarkTime, 10);
    }

    const channel = this.props.channels.find((x) => x.contentId === recording.channelId);
    if (channel && this.props.authReducer) {
      let channelPermission = channelPermissions(channel, this.props.authReducer);
      this.setState({
        channelLogo: channel.pictures.length > 0 ? channel.pictures[0].href : '',
        isGeoLocked: channelPermission.geoLocked,
      });
    }

    return getPvrPlayUrl(recording)
      .then((playData) => {
        playData = playData as StreamingObject;
        let licenseUrl = '';
        if (ScriptService._isSafari()) {
          licenseUrl = playData.licenseUrl ? playData.licenseUrl : '';
        }

        let programStart = subtractMinutes(recording.beginTime, Number(recording.beginOffset));
        let programEnd = addMinutes(recording.endTime, Number(recording.endOffset));
        this.setState({
          timeLeft: -1,
          stream: {
            title: recording.pvrName,
            picture: recording.pictures ? (recording.pictures[0] as Picture) : undefined,
            isGuest: false,
            streamType: StreamType.PVR,
            programStart: programStart,
            programEnd: programEnd,
            mediaId: recording.mediaId,
            contentId: recording.channelId,
            pvrId: recording.pvrId,
            startBookmark: startBookmark,
            playData: {
              manifestUrl: playData.manifestUrl,
              licenseUrl: licenseUrl,
              customData: playData.customData,
            },
          } as StreamTypeObject,
        });

        // React doesn't notice the changes in the nested objects in stream, so we have to tell it to rerender.
        this.forceUpdate();
      })
      .catch(() => {
        this.setState({
          recordingNotFound: true,
        });
      });
  }

  goBackToDetails = () => {
    this.props.history.push(this.detailsPanelUrl);
  };

  onProgramEnded = () => {
    if (this.state.goToDetailsAfterEnd) {
      this.goBackToDetails();
    } else {
      this.popupCallback();
    }
  };

  timeLeftCallback = (timeLeft: number) => {
    if (timeLeft) {
      this.setState({ timeLeft });
    }
  };

  popupCallback = () => {
    const { nextEpisode } = this.state;

    this.setState({ timeLeft: -1 }, () => {
      if (nextEpisode) {
        this.setState({
          redirectToObject: {
            path: this.nextPlayUrl,
            overwriteEpisodeToPromoteId: nextEpisode.pvrId,
          },
        });

        this.loadStream(nextEpisode);
      }
    });
  };

  handleForceExitPlayer = () => {
    this.setState({ goToDetailsAfterEnd: true });
  };

  getPvrName(recording: PvrRecording) {
    return `${recording.pvrName} S${recording.seasonNum} E${recording.subNum}`;
  }

  // called by player on error
  onPlayerError = (error: object) => {
    this.tracking.trackEvent(GAcategory.playerError, 'pvr' as GAaction, JSON.stringify(error));
  };

  onPause = () => {
    this.tracking.trackCurrentService(
      GAaction.playback_pause,
      this.recording!.pvrName + ' - ' + this.recording!.programId,
    );
  };

  onPlay = () => {
    this.tracking.trackCurrentService(
      GAaction.playback_play,
      this.recording!.pvrName + ' - ' + this.recording!.programId,
    );
  };

  Player = () => {
    if (!ScriptService.isCurrentBrowserSupported()) {
      return <div className="unsupported-player"> </div>;
    }

    if (this.state.stream && !this.state.recordingNotFound && this.recording) {
      return (
        <Player
          timeLeftCallback={this.timeLeftCallback}
          popupOverlay={this.popupOverlay}
          stream={this.state.stream}
          unMountCallback={this.unMountCallbackToPlayer}
          onConcurrencyError={this.onConcurrencyError}
          onPlay={this.onPlay}
          onPause={this.onPause}
          onAlert={this.alert}
          onProgramEnded={this.onProgramEnded}
          onPlayerError={this.onPlayerError}
          hideControls={this.props.shouldHideControls}
          authStatus={this.props.authReducer}
          currentAsset={wrapAssetAsAltiboxAsset(this.recording)}
          includeInFullscreen={() => this.fullscreenDetails()}
          nielsenChannelMapping={this.props.nielsenChannelMapping}
        />
      );
    } else if (this.state.recordingNotFound) {
      return <div className="recording-missing">{i18n.t<string>('recording not found message')}</div>;
    } else {
      return null;
    }
  };

  onConcurrencyError = () => {
    this.tracking.trackCurrentService(
      GAaction.notification,
      (this.props.authReducer.auth!.isInside === '1' ? 'inside ' : 'outside ') + GAlabel.maximumNoOfStreamReached,
    );
  };

  handleOpenDetails = () => {
    if (this.recording) {
      this.props.showDetailsPanel(this.recording);

      this.props.history.replace({
        state: { overwriteEpisodeToPromoteId: this.recording.pvrId },
      });
    }
  };

  fullscreenDetails = (): JSX.Element => {
    const { recording } = this;
    return (
      <>
        {this.props.detailsPanel ? (
          <SlideInDetails
            key={
              this.props.detailsPanel.routeProps.parentId ??
              '' + this.props.detailsPanel.routeProps.childId ??
              '' + this.props.detailsPanel.displayType
            }
            {...this.props.detailsPanel}
          />
        ) : null}
        {this.props.shouldHideControls ? null : (
          <PlayerAssetCard
            image={this.imageUrl}
            currentTitle={this.titleName}
            currentSeason={recording?.seasonNum}
            currentEpisode={recording?.subNum}
            showDetailsPanel={() => this.handleOpenDetails()}
          />
        )}
      </>
    );
  };

  onClose = () => {
    const { base, series, single } = routes.pvr;
    const { channelId, seriesId, pvrId } = this.recording!;

    if (this.props.location && this.props.location.state && this.props.location.state.prevPath) {
      return this.props.history.push(this.props.location.state.prevPath, {
        showDetailsPanel: {
          routeProps: { childId: pvrId, parentId: seriesId },
          displayType: AltiboxAssetDetailsType.PVR,
        },
      });
    }

    if (seriesId) {
      return this.props.history.push(`${base}${series}/${channelId}/${seriesId}/${pvrId}`);
    }

    return this.props.history.push(`${base}${single}/${pvrId}`);
  };

  render() {
    if (this.state.redirectToObject.path || this.state.redirectToObject.overwriteEpisodeToPromoteId) {
      return <Redirect to={this.state.redirectToObject.path} />;
    }

    const recording = this.recording;

    let playerSearchConfig = this.props.searchConfig ? this.props.searchConfig : searchConfig.global;
    playerSearchConfig.noFocus = true;

    if (this.state.goBack) {
      if (this.props.location.state && this.props.location.state.prevPath) {
        return <Redirect to={this.props.location.state.prevPath} />;
      } else {
        return <Redirect to={'/'} />;
      }
    }

    const title = recording ? `${recording.pvrName} ${this.prefix}` : i18n.t<string>('loading') + '...';

    if (ScriptService.onMobile()) {
      var deepLink = 'altibox://recordings';
      return (
        <div className="main-frame">
          <Header displayType={HeaderDisplayType.NoStickyGradient} interactionType={HeaderInteractionType.CloseOnly} />
          <MobileLock deepLink={deepLink} /> :
        </div>
      );
    } else {
      return (
        <div className="tv-container pvr-view">
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {this.props.shouldHideControls ? null : (
            <>
              <StickyHeader searchConfig={playerSearchConfig} overPlayer={true} />
              <Header
                title={title}
                hideTitle={false}
                displayType={HeaderDisplayType.Player}
                interactionType={HeaderInteractionType.CloseOnly}
                closeCallback={this.onClose}
              />
            </>
          )}
          {ScriptService.isCurrentBrowserSupported() ? (
            this.state.isGeoLocked ? (
              <div className="geoBlockPvr">
                <img alt="channel-logo" src={this.state.channelLogo} />
                <span>{i18n.t<string>('no rights for abroad')}</span>
              </div>
            ) : (
              this.Player()
            )
          ) : (
            <NoSupport />
          )}
        </div>
      );
    }
  }
  alert = (title: string, message: string[]) => {
    this.props.showAlert({
      title: title,
      text: message,
      type: 'info',
    });
  };
}

export default withRouter(
  connect(
    (state: RootState) => ({
      authReducer: state.authReducer,
      recordings: state.pvrReducer.recordings as PvrRecording[],
      channels: state.channelsReducer.channels,
      shouldHideControls: state.app.shouldFadeOut,
      detailsPanel: state.detailsReducer.detailsPanel,
      nielsenChannelMapping: state.app.nielsenChannelMapping,
    }),
    (dispatch) => ({
      showDetailsPanel: (pvr: PvrRecording) =>
        dispatch(
          showDetailsPanelAction({
            routeProps: { childId: pvr.pvrId, parentId: pvr.seriesId },
            displayType: AltiboxAssetDetailsType.PVR,
          }),
        ),
      fetchAllRecordings: () => dispatch(fetchAllRecordings(true)),
      showAlert: (alert: AlertData) => dispatch(showAlert(alert)),
      setTempBookmark: (_1: Bookmark | undefined) => dispatch(setTempBookmark(_1)),
    }),
  )(PvrPlayer),
);
