import '../style.scss';
import styles from '../TvGuide.module.scss';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { goToLogin } from '../../../api/auth';
import { SvgChevronLeft, SvgChevronRight } from '../../../icons';
import { Day, EpgGenre, RootState, TVGuideChannelFilter } from '../../../interfaces';
import { useEpgGenres } from '../../../queries/genres/queries';
import { getTimeIntervalHour, getSelectedTvGuideFilterList, createDateLabel } from '../../../utils/tvUtils';
import { getCurrentLangTitle } from '../../../utils/vodUtils';
import Dropdown, { Option } from 'react-dropdown';
import i18n from '../../../i18n';

interface TvGuideFiltersProps {
  channelsFilter: TVGuideChannelFilter;
  setChannelsFilter: React.Dispatch<React.SetStateAction<TVGuideChannelFilter>>;
  genreFilter: string[];
  setGenreFilter: React.Dispatch<React.SetStateAction<string[]>>;
  timeFilter: number;
  setTimeFilter: React.Dispatch<React.SetStateAction<number>>;
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  hasFavorites: boolean | undefined;
}

export function TvGuideFilters({
  channelsFilter,
  setChannelsFilter,
  genreFilter,
  setGenreFilter,
  timeFilter,
  setTimeFilter,
  currentDate,
  setCurrentDate,
  hasFavorites,
}: TvGuideFiltersProps) {
  const { t } = useTranslation();
  const auth = useSelector((state: RootState) => state.authReducer);
  const { data: epgGenres } = useEpgGenres();

  const [dayPickerCounter, setDayPickerCounter] = useState(0);
  const [datepickerPlaceholder, setDatepickerPlaceholder] = useState('');

  const minLimitClass = dayPickerCounter > -7 ? ' date-change' : ' limit-date';
  const maxLimitClass = dayPickerCounter < 14 ? ' date-change' : ' limit-date';
  const className = 'filter-row' + (channelsFilter !== TVGuideChannelFilter.ALL ? ' active' : '');

  const placeholder = datepickerPlaceholder || createDatePlaceholderString(0, currentDate);

  function handleDatePickerDropDownChange(selectedOption: Option) {
    const selectedDay = Number(selectedOption.value);
    const selectedDate = getDate(selectedDay);
    setDayPickerCounter(selectedDay);
    setDatepickerPlaceholder(createDatePlaceholderString(selectedDay, selectedDate));
    setCurrentDate(selectedDate);
  }

  function handleDateChangeButtonClick(day: Day) {
    let newDate = getDate(dayPickerCounter + day);

    if (newDate !== currentDate) {
      setDayPickerCounter(dayPickerCounter + day);
      setDatepickerPlaceholder(createDatePlaceholderString(dayPickerCounter + day, newDate));
      setCurrentDate(newDate);
    }
  }

  function handleChannelsFilter(selectedOption: Option) {
    if (auth.isGuest && !(selectedOption.value === TVGuideChannelFilter.ALL)) {
      return goToLogin();
    }

    switch (selectedOption.value) {
      case TVGuideChannelFilter.ALL:
        return setChannelsFilter(TVGuideChannelFilter.ALL);
      case TVGuideChannelFilter.MINE:
        return setChannelsFilter(TVGuideChannelFilter.MINE);
      case TVGuideChannelFilter.FAVORITE:
        return setChannelsFilter(TVGuideChannelFilter.FAVORITE);
    }
  }

  function handleTimeFilter(selectedTimeInterval: Option) {
    setTimeFilter(Number(selectedTimeInterval.value));
  }

  function createTimepickerFilterBox() {
    return (
      <div className={styles.tvGuideInfoBadge}>
        <CloseIcon callback={() => setTimeFilter(0)} />
        &nbsp;&nbsp;
        {getTimeIntervalHour(timeFilter, 4)}
      </div>
    );
  }

  function createChannelFilterBox() {
    return (
      <div className={styles.tvGuideInfoBadge}>
        <CloseIcon callback={() => setChannelsFilter(TVGuideChannelFilter.ALL)} />
        &nbsp;&nbsp;
        {t(channelsFilter)}
      </div>
    );
  }

  function handleCategoryChange(selectedFilter: Option) {
    const filter = getSelectedTvGuideFilterList(selectedFilter.value);
    setGenreFilter(filter);
  }

  function createProgramFilterBox() {
    const epgGenre = epgGenres
      ?.filter((genre) => genreFilter.some((filter) => genre.genreIds.split(',').indexOf(filter) !== -1))
      .pop();

    return (
      <div className={styles.tvGuideInfoBadge}>
        <CloseIcon callback={() => setGenreFilter([])} />
        <div className={styles.badgeText}>{t(epgGenre ? getCurrentLangTitle(epgGenre.title) : '')}</div>
      </div>
    );
  }

  function renderFilterBoxes() {
    let classNames = 'filter-box-wrapper' + (channelsFilter !== TVGuideChannelFilter.ALL ? ' active' : '');
    return (
      <div className={classNames}>
        {channelsFilter !== TVGuideChannelFilter.ALL ? createChannelFilterBox() : null}
        {!isEmpty(genreFilter) ? createProgramFilterBox() : null}
        {timeFilter !== 0 ? createTimepickerFilterBox() : null}
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="filter-container">
        <div className="dropdown-container">
          <button className={minLimitClass} onClick={() => handleDateChangeButtonClick(Day.YESTERDAY)}>
            <SvgChevronLeft />
          </button>

          <Dropdown
            value={datepickerPlaceholder}
            placeholder={placeholder}
            options={getDropDatePickerDownOptions(currentDate)}
            onChange={handleDatePickerDropDownChange}
            arrowOpen={<></>}
            arrowClosed={<></>}
          />

          <button
            className={maxLimitClass}
            onClick={() => {
              handleDateChangeButtonClick(Day.TOMRROW);
            }}
          >
            <SvgChevronRight />
          </button>
        </div>
        <div className="dropdown-container-channellist">
          <DropdownFilter
            placeholder="channellist"
            onChange={handleChannelsFilter}
            options={getChannelListDropDownOptions(channelsFilter, hasFavorites)}
            icon=":"
          />

          {epgGenres && (
            <DropdownFilter
              placeholder="category"
              onChange={handleCategoryChange}
              options={getGenreFilterDropDownOptions(epgGenres, genreFilter)}
              icon="Ď"
            />
          )}

          <DropdownFilter
            placeholder="timepicker"
            onChange={handleTimeFilter}
            options={getTimepickerDropDownOptions(timeFilter)}
            icon="r"
          />
        </div>
        {renderFilterBoxes()}
      </div>
    </div>
  );
}

interface CloseIconProps {
  callback: () => void;
}

function CloseIcon({ callback }: CloseIconProps) {
  const pointerStyle = { cursor: 'pointer' };
  return (
    <span style={pointerStyle} className={styles.icon} onClick={callback}>
      B
    </span>
  );
}

interface DropdownFilterProps {
  placeholder: string;
  onChange: (selectedOption: Option) => void;
  options: Option[];
  icon: string;
}

function DropdownFilter({ placeholder, onChange, options, icon }: DropdownFilterProps) {
  const { t } = useTranslation();

  const placeholderWidth = window.innerWidth > 480 ? t(placeholder) : '';
  const dropdownIcon = <span className="aib-icon">{icon}</span>;

  return (
    <Dropdown
      placeholder={placeholderWidth}
      options={options}
      onChange={(e) => {
        e.label = t(placeholder); // force label to be same value in case of change
        onChange(e);
      }}
      arrowOpen={dropdownIcon}
      arrowClosed={dropdownIcon}
      className={placeholder}
    />
  );
}

function getTimepickerDropDownOptions(timeFilter: number) {
  let hourStartOffset = 6;
  let options: Option[] = [];
  for (let hour = hourStartOffset; hour <= 20 + hourStartOffset; hour += 4) {
    let isSelected = timeFilter === hour;
    options.push({
      value: hour.toString(),
      className: isSelected ? 'selected' : '',
      label: (
        <>
          {getTimeIntervalHour(hour, 4)}
          {isSelected ? <span className="aib-icon">v</span> : null}
        </>
      ),
    });
  }
  return options;
}

function getDropDatePickerDownOptions(currentDate: Date) {
  let options: Option[] = [];
  for (let startDay = -7; startDay <= 14; startDay++) {
    let date = getDate(startDay);
    let dateLabel = createDateLabel(startDay, date);
    let isSelectedDate = currentDate.getDate() === date.getDate();
    options.push({
      value: `${startDay}`,
      label: isSelectedDate ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: dateLabel }} />
          <span className="aib-icon">v</span>
        </>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: dateLabel }} />
      ),
      className: isSelectedDate ? 'selected' : '',
    });
  }
  return options;
}

function getChannelListDropDownOptions(channelsToShow: TVGuideChannelFilter, hasFavorites: boolean | undefined) {
  let options: Option[] = [];
  Object.values(TVGuideChannelFilter).forEach((channelList) => {
    let iconClassName = channelsToShow === channelList ? '' : ' hide';
    options.push({
      value: channelList,
      label: (
        <div className="channel-list-option">
          {i18n.t<string>(channelList)}
          <span className={'aib-icon' + iconClassName}>v</span>
        </div>
      ),
      className:
        channelsToShow === channelList
          ? 'selected'
          : !hasFavorites && channelList === TVGuideChannelFilter.FAVORITE
          ? ' disabled'
          : '',
    });
  });
  return options;
}

function getDate(days: number) {
  var date = new Date();
  if (days === 0) {
    return date;
  } else {
    date.setDate(date.getDate() + days);
    date.setHours(18);
    return date;
  }
}

function getGenreFilterDropDownOptions(epgGenres: EpgGenre[], tvGuideGenreFilterList: string[]) {
  let options: Option[] = [];
  epgGenres.forEach((epgGenre) => {
    let isSelected = tvGuideGenreFilterList.some((value) => epgGenre.genreIds.split(',').indexOf(value) !== -1);
    let iconClassName = isSelected ? '' : ' hide';
    options.push({
      value: epgGenre.genreIds,
      label: (
        <div className="filter-list-option">
          {getCurrentLangTitle(epgGenre.title)}
          <span className={'aib-icon' + iconClassName}>v</span>
        </div>
      ),
      className: isSelected ? 'selected' : '',
    });
  });
  return options;
}

function createDatePlaceholderString(day: number, date: Date) {
  return createDateLabel(day, date);
}
