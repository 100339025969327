import React, { PureComponent, ChangeEvent } from 'react';
import Player from '.';
import { StreamType, AuthReducerState, AltiboxAsset } from '../../interfaces';
import './styles/teststyle.scss';

class TestPlayer extends PureComponent<{}, {}> {
  state = {
    url:
      'https://mdn-d5-a7-64-11.hvp.services.altibox.net/wh7f454c46tw3033189920_958613706/PLTV/88888888/224/3221226649/3221226649.mpd',
    type: 'live',
  };

  render() {
    return (
      <div className="testplayer">
        <span>Paste clear MPD url in here</span>
        <input
          value={this.state.url}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            this.setState({
              url: event.target.value.trim(),
            });
          }}
        />
        <br />
        <span>Stream type: vod, pvr, live, catchup, trailer</span>
        <input
          value={this.state.type}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            this.setState({
              type: event.target.value.trim(),
            });
          }}
        />
        <Player
          onConcurrencyError={() => {
            // OK
          }}
          hideControls={false}
          stream={{
            isGuest: true,
            streamType: this.state.type as StreamType,
            mediaId: undefined,
            contentId: 'string',
            playData: {
              manifestUrl: this.state.url,
              licenseUrl: '',
              customData: '',
            },
          }}
          onAlert={() => {
            // OK
          }}
          onPlayerError={() => {
            // OK
          }}
          onProgramEnded={() => {
            // OK
          }}
          onPlay={() => {
            // OK
          }}
          onPause={() => {
            // OK
          }}
          authStatus={{} as AuthReducerState}
          currentAsset={{} as AltiboxAsset}
        />
      </div>
    );
  }
}
export default TestPlayer;
