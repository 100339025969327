import { QueryObserverBaseResult, UseQueryResult } from 'react-query';
import { isEmpty } from 'lodash';

export function isAnyProperty(
  property: keyof QueryObserverBaseResult<unknown, unknown>,
  ...queries: UseQueryResult<unknown, unknown>[]
) {
  return queries.some((query) => query[property]);
}

export function isAllEmpty(...queries: UseQueryResult<unknown, unknown>[]) {
  return queries.every((query) => isEmpty(query.data));
}

/**
 * You will need to pass in the types for the return values of data from the queries in a tuple.
 *
 * @param queries
 * @returns Object with the data property merged from all queries - and is properties are true if any are true
 */
export function combineQueryResults<
  TData extends unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TQueries extends UseQueryResult<TData>[] = UseQueryResult<any>[],
>(...queries: TQueries) {
  const data = queries.map((query) => query.data);

  return {
    data: data as TData | undefined,
    isLoading: isAnyProperty('isLoading', ...queries),
    isFetching: isAnyProperty('isFetching', ...queries),
    isSuccess: isAnyProperty('isSuccess', ...queries),
  };
}
