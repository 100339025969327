import React, { Component } from 'react';
import {
  VodAsset,
  VodContentProduct,
  AltiboxAsset,
  AltiboxAssetDetailsType,
  AuthReducerState,
  VodAutoplay,
  PVRAutoplay,
  CatchupAutoplay,
  PvrRecording,
} from '../../../../interfaces';
import i18n from '../../../../i18n';
import PlayerInfoPopup from '../PlayerInfoPopup';
import PurchaseModal from '../../../../views/vod/PurchaseModal';
import { assetIsOwned } from '../../../../utils/vodUtils';
import { getContentPricing } from '../../../../api/vod';
import { getProgramCover, getImageUrlDimensions } from '../../../../utils/altiboxassetUtils';
import { imageScaleValues } from '../../../../config';
import missingCoverUrlLandscape from '../../../VodAsset/missing-svod-cover.jpeg';

type Props = {
  timeLeft: number;
  autoplay: VodAutoplay | PVRAutoplay | CatchupAutoplay;
  assetType: AltiboxAssetDetailsType;
  seriesName: string;
  authStatus: AuthReducerState;
  callback?: Function;
  forceExitPlayer?: Function;
};

type State = {
  showPopup: boolean;
  hasCrossedOut: boolean;
  popupCounter: number;
  canBePurchased: boolean;
  triggerPurchase: boolean;
  purchaseObject: VodContentProduct[] | undefined;
  hasRetrievedPurchaseObject: boolean;
  purchased: boolean;
};

class PlayerInfoPopupManager extends Component<Props, State> {
  state: State = {
    showPopup: false,
    hasCrossedOut: false,
    popupCounter: 0,
    canBePurchased: false,
    triggerPurchase: false,
    purchaseObject: undefined,
    hasRetrievedPurchaseObject: false,
    purchased: false,
  };

  get title() {
    const { popupCounter, canBePurchased } = this.state;
    const seconds = popupCounter === 1 ? i18n.t('second') : i18n.t('seconds');
    if (canBePurchased) {
      return i18n.t('see next episode');
    } else {
      return `${i18n.t('play next episode in')} <strong>${popupCounter}</strong> ${seconds}`;
    }
  }

  get seasonAndEpisode() {
    let asset = this.props.autoplay.nextAsset!;
    let season = 0;
    let episode = 0;
    switch (this.props.assetType) {
      case AltiboxAssetDetailsType.VOD:
        asset = asset as VodAsset;
        episode = Number(asset.sitcomnum!);
        season = Number(asset.seasonNumber);
        break;
      case AltiboxAssetDetailsType.PVR:
        asset = asset as PvrRecording;
        episode = Number(asset.subNum);
        season = Number(asset.seasonNum);
        break;
    }
    return [season, episode];
  }

  get seasonAndEpisodeShorthand() {
    const [season, episode] = this.seasonAndEpisode;
    return `S${season} E${episode}`;
  }

  get subtitle() {
    const { canBePurchased } = this.state;
    const [season, episode] = this.seasonAndEpisode;
    let seriesName = this.props.seriesName !== '' ? this.props.seriesName : '';
    seriesName = canBePurchased ? seriesName : seriesName + ' - ';
    if (this.state.canBePurchased) {
      return `${seriesName} - ${i18n.t('season')} ${season} ${i18n.t('episode')} ${episode}`;
    }
    return `${seriesName}${i18n.t('season')} ${season}`;
  }

  get buttonText() {
    if (this.state.canBePurchased && this.state.purchaseObject) {
      const lowestPrice = Math.min(...this.state.purchaseObject.map((x) => Number(x.price)));
      return `${i18n.t('watch')} ${i18n.t('from')} ${lowestPrice},-`;
    } else {
      const [, episode] = this.seasonAndEpisode;
      return `${i18n.t('watch')} ${i18n.t('episode')} ${episode}`;
    }
  }

  get cover() {
    const { autoplay, assetType } = this.props;
    const cover = getProgramCover({ asset: autoplay.nextAsset!, type: assetType } as AltiboxAsset);
    if (cover) {
      return getImageUrlDimensions(cover!, imageScaleValues.altiboxAssetView);
    } else {
      return missingCoverUrlLandscape;
    }
  }

  get shouldBeAbleToPurchase() {
    return this.state.purchaseObject && this.props.authStatus.loggedInWithCredentials;
  }

  get shouldShowPopup() {
    const { autoplay, authStatus } = this.props;
    let asset = autoplay.nextAsset;

    switch (this.props.assetType) {
      case AltiboxAssetDetailsType.VOD:
        asset = asset as VodAsset;

        if (this.state.showPopup) {
          if (this.state.hasCrossedOut) {
            return false;
          }
          if (authStatus.isHome && !authStatus.loggedInWithCredentials) {
            return assetIsOwned(asset);
          } else {
            return true;
          }
        }

        break;
      case AltiboxAssetDetailsType.PVR:
        if (this.state.showPopup) {
          if (this.state.hasCrossedOut) {
            return false;
          }

          return true;
        }

        break;
    }

    return false;
  }

  componentDidMount() {
    if (this.props.assetType === AltiboxAssetDetailsType.VOD) {
      this.isAssetPurchaseable();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { timeLeft } = this.props;
    const { purchased, canBePurchased, hasCrossedOut } = this.state;

    if (timeLeft - 3 === -1) {
      this.setState({ showPopup: false });
      if (!purchased && canBePurchased && !hasCrossedOut) {
        this.crossOutWindow();
      }
    }

    if (timeLeft >= 0 && timeLeft <= 18) {
      this.setState({
        showPopup: true,
        popupCounter: timeLeft - 2 <= 0 ? 0 : timeLeft - 2,
      });
    } else {
      this.setState({
        showPopup: false,
      });
    }
  }

  isAssetPurchaseable() {
    const { autoplay, authStatus } = this.props;
    const asset = autoplay.nextAsset as VodAsset;
    if (!assetIsOwned(asset) && !asset.isSvod! && authStatus.loggedInWithCredentials) {
      this.setState(
        {
          canBePurchased: true,
        },
        () => {
          if (!this.state.hasRetrievedPurchaseObject) {
            getContentPricing(asset.id!).then((priceResponse) => {
              this.setState({
                purchaseObject: priceResponse,
                hasRetrievedPurchaseObject: true,
              });
            });
          }
        },
      );
    }
  }

  buttonCallback = () => {
    if (this.state.canBePurchased) {
      const video = document.querySelector('video')!;
      video.pause();
      /* eslint-disable @typescript-eslint/dot-notation */
      if (
        document.fullscreenElement ||
        document['mozFullScreenElement'] ||
        document['webkitFullscreenElement'] ||
        document['msFullscreenElement']
      ) {
        if (document['mozCancelFullScreen']) {
          document['mozCancelFullScreen']();
        } else if (document && document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document['webkitCancelFullScreen']) {
          document['webkitCancelFullScreen']();
        }
      }
      /* eslint-enable @typescript-eslint/dot-notation */
      this.setState({ triggerPurchase: true });
    } else {
      this.setState(
        {
          showPopup: false,
        },
        () => {
          this.goToNextEpisode();
        },
      );
    }
  };

  purchaseCallback = () => {
    this.setState(
      {
        showPopup: false,
        purchased: true,
      },
      () => {
        this.goToNextEpisode();
      },
    );
  };

  goToNextEpisode = () => {
    const { callback } = this.props;
    if (callback) {
      callback();
    }
  };

  crossOutWindow() {
    this.setState({ hasCrossedOut: true });
    if (this.props.forceExitPlayer) {
      this.props.forceExitPlayer();
    }
  }

  render() {
    return (
      <>
        <PlayerInfoPopup
          cover={this.cover}
          title={this.title}
          subtitle={this.subtitle}
          buttonText={this.buttonText}
          buttonCallback={() => this.buttonCallback()}
          show={this.shouldShowPopup}
          crossout={() => this.crossOutWindow()}
          purchase={false}
          popupCounter={this.state.popupCounter}
        />
        {!this.shouldBeAbleToPurchase ? null : (
          <PurchaseModal
            assetTitle={this.props.seriesName}
            seasonAndEpisodeShorthand={this.seasonAndEpisodeShorthand}
            purchaseOptions={this.state.purchaseObject!}
            purchaseAsset={this.props.autoplay.nextAsset! as VodAsset}
            onCloseModalCallback={() => this.setState({ triggerPurchase: false })}
            onAssetIsBoughtCallback={this.purchaseCallback}
            isVisible={this.state.triggerPurchase}
            getTrackingLabel={() => ''}
            fromPlayer={true}
          />
        )}
      </>
    );
  }
}

export default PlayerInfoPopupManager;
