import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../config';
import { SvgCheck, SvgChevronRight, SvgDelete } from '../../icons';
import { Recording } from '../../queries/recordings/types';
import { toMomentReadableDateString } from '../../utils/huaweiUtils';
import { DeleteModal } from './DeleteModal';
import styles from './styles/DeleteAsset.module.scss';
import missingImageUrl from '../../images/tv-placeholder-landscape.png';
import { Image } from '../Image';

interface DeleteSeriesProps {
  group: Recording.Group.Series;
  selectionMode?: boolean;
  onChange?: (group: Recording.Group.Series) => void | undefined;
  isSelected?: boolean;
  useSkeleton?: boolean;
  type: 'series';
}

interface DeleteSeasonProps {
  season: Recording.Group.Season;
  selectionMode?: boolean;
  onChange?: (group: Recording.Group.Season) => void | undefined;
  isSelected?: boolean;
  useSkeleton?: boolean;
  type: 'season';
}

interface DeleteEpisodeProps {
  episode: Recording.Query.Series;
  selectionMode?: boolean;
  onChange?: (episode: Recording.Query.Series) => void | undefined;
  isSelected?: boolean;
  shouldGoBackOnDelete?: boolean;
  percentWatched?: number;
  useSkeleton?: boolean;
  type: 'episode';
}

interface DeleteMovieProps {
  group: Recording.Group.Movie;
  selectionMode?: boolean;
  onChange?: (group: Recording.Group.Movie) => void | undefined;
  isSelected?: boolean;
  useSkeleton?: boolean;
  type: 'movie';
}

type DeleteUnionProps = DeleteSeriesProps | DeleteSeasonProps | DeleteEpisodeProps | DeleteMovieProps;

const BASE_LINK = routes.pvr.base + routes.pvr.delete;

export function PvrDeleteAsset(props: DeleteUnionProps) {
  switch (props.type) {
    case 'series':
      return props.useSkeleton ? <PvrDeleteAssetSkeleton /> : <DeleteSeries {...props} />;
    case 'season':
      return props.useSkeleton ? <PvrDeleteAssetSkeleton /> : <DeleteSeason {...props} />;
    case 'episode':
      return props.useSkeleton ? <PvrDeleteAssetSkeleton /> : <DeleteEpisode {...props} />;
    case 'movie':
      return props.useSkeleton ? <PvrDeleteAssetSkeleton /> : <DeleteMovie {...props} />;
  }
}

export function DeleteSeries({ group, selectionMode, onChange, isSelected }: DeleteSeriesProps) {
  const { t } = useTranslation('pvr');
  const [isModalOpen, setIsModalOpen] = useState(false);

  function getSubtitle() {
    return t('episodes', { count: group.episodes.length });
  }

  function getLink() {
    if (group.seasons.length === 1) {
      return {
        pathname: `${BASE_LINK}/${group.seriesId}/${group.seasons[0].seasonNum}`,
        state: { goBackTwice: true },
      };
    }

    return `${BASE_LINK}/${group.seriesId}`;
  }

  const title = group.pvrName;

  if (selectionMode) {
    return (
      <div className={styles.selectionMode}>
        <div className={styles.selection}>
          <input
            type="checkbox"
            name={title}
            id={title}
            value={group.periodPVRTaskId ?? `[${group.episodes.map((episode) => episode.pvrId).join(',')}]`}
            onChange={() => onChange && onChange(group)}
            checked={isSelected}
            tabIndex={0}
          />
          <label htmlFor={title}>
            <SvgCheck />
          </label>
        </div>

        <label htmlFor={title} className={styles.container} title={title}>
          <div className={styles.left}>
            <Image
              src={group.pictures?.[0].href}
              fallback={missingImageUrl}
              crop={[256, 144]}
              alt={title}
              loading="lazy"
            />
          </div>
          <div className={styles.text}>
            <h4>
              <span className={styles.title}>{title}</span>
            </h4>
            <p>{getSubtitle()}</p>
          </div>
        </label>
      </div>
    );
  }

  if (hasSubrecordings(group)) {
    return (
      <Link to={getLink} className={styles.container} title={title}>
        <div className={styles.left}>
          <Image
            src={group.pictures?.[0].href}
            fallback={missingImageUrl}
            crop={[256, 144]}
            alt={title}
            loading="lazy"
          />
        </div>
        <div className={styles.text}>
          <h4>
            <span className={styles.title}>{title}</span>
          </h4>
          <p>{getSubtitle()}</p>
        </div>
        <div className={styles.right}>
          <SvgChevronRight />
        </div>
      </Link>
    );
  }

  return (
    <>
      <DeleteModal
        idToDelete={group.periodPVRTaskId ?? group.episodes.map((episode) => episode.pvrId)}
        isOpen={isModalOpen}
        hideModal={() => setIsModalOpen(false)}
        name={group.pvrName}
        picture={group.pictures?.[0].href}
      />

      <button className={styles.container} title={title} onClick={() => setIsModalOpen(true)}>
        <div className={styles.left}>
          <Image
            src={group.pictures?.[0].href}
            fallback={missingImageUrl}
            crop={[256, 144]}
            alt={title}
            loading="lazy"
          />
        </div>
        <div className={styles.text}>
          <h4>
            <span className={styles.title}>{title}</span>
          </h4>
          <p>{getSubtitle()}</p>
        </div>
        <div className={styles.right}>
          <SvgDelete />
        </div>
      </button>
    </>
  );
}

export function DeleteSeason({ season, selectionMode, onChange, isSelected }: DeleteSeasonProps) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  if (isEmpty(season.episodes)) {
    return null;
  }

  const title = `${season.pvrName}`;
  const seasonNum = `- ${t('season')} ${season.seasonNum}`;
  const titleAndSasonNum = `${title} ${seasonNum}`;

  if (selectionMode) {
    return (
      <div className={styles.selectionMode}>
        <div className={styles.selection}>
          <input
            type="checkbox"
            name={titleAndSasonNum}
            id={titleAndSasonNum}
            value={`[${season.episodes.map((episode) => episode.pvrId).join(',')}]`}
            onChange={() => onChange && onChange(season)}
            checked={isSelected}
          />
          <label htmlFor={titleAndSasonNum}>
            <SvgCheck />
          </label>
        </div>

        <label htmlFor={titleAndSasonNum} className={styles.container} title={titleAndSasonNum}>
          <div className={styles.left}>
            <Image
              src={season.pictures?.[0].href}
              fallback={missingImageUrl}
              crop={[256, 144]}
              alt={titleAndSasonNum}
              loading="lazy"
            />
          </div>
          <div className={styles.text}>
            <h4>
              <span className={styles.title}>{title}</span>
              <span className={styles.seasonNum}>{seasonNum}</span>
            </h4>
            <p>{t('episodes', { count: season.episodes.length })}</p>
          </div>
        </label>
      </div>
    );
  }

  return (
    <Link to={`${pathname}/${season.seasonNum}`} className={styles.container} title={titleAndSasonNum}>
      <div className={styles.left}>
        <Image
          src={season.pictures?.[0].href}
          fallback={missingImageUrl}
          crop={[256, 144]}
          alt={titleAndSasonNum}
          loading="lazy"
        />
      </div>
      <div className={styles.text}>
        <h4>
          <span className={styles.title}>{title}</span>
          <span className={styles.seasonNum}>{seasonNum}</span>
        </h4>
        <p>{t('episodes', { count: season.episodes.length })}</p>
      </div>
      <div className={styles.right}>
        <SvgChevronRight />
      </div>
    </Link>
  );
}

export function DeleteEpisode({
  episode,
  selectionMode,
  onChange,
  isSelected,
  percentWatched,
  shouldGoBackOnDelete,
}: DeleteEpisodeProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = episode.pvrName;
  const seasonNumAndEpisodeNum = episode.seasonNum ? `- S${episode.seasonNum} E${episode.subNum}` : '';

  const titleAndNumber = `${title} ${seasonNumAndEpisodeNum}`;

  if (selectionMode) {
    return (
      <div className={styles.selectionMode}>
        <div className={styles.selection}>
          <input
            type="checkbox"
            name={episode.pvrId}
            id={episode.pvrId}
            value={episode.pvrId}
            onChange={() => onChange && onChange(episode)}
            checked={isSelected}
            tabIndex={0}
          />
          <label htmlFor={episode.pvrId}>
            <SvgCheck />
          </label>
        </div>

        <label htmlFor={episode.pvrId} className={styles.container} title={titleAndNumber}>
          <div className={styles.left}>
            {!!percentWatched && percentWatched >= 97 && (
              <div className={styles.overlay}>
                <SvgCheck />
              </div>
            )}
            <Image
              src={episode.pictures?.[0].href}
              fallback={missingImageUrl}
              crop={[256, 144]}
              alt={titleAndNumber}
              loading="lazy"
            />
          </div>
          <div className={styles.text}>
            <h4>
              <span className={styles.title}>{title}</span>
              <span className={styles.seasonNum}>{seasonNumAndEpisodeNum}</span>
            </h4>
            <p>{toMomentReadableDateString(episode.beginTime, true)}</p>
          </div>
          {!!percentWatched && percentWatched > 1 && percentWatched < 97 && (
            <progress className={styles.progress} aria-label="Watched" value={percentWatched} max={100} />
          )}
        </label>
      </div>
    );
  }

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        hideModal={() => setIsModalOpen(false)}
        idToDelete={episode.pvrId}
        name={titleAndNumber}
        picture={episode.pictures?.[0].href}
        shouldGoBackOnDelete={shouldGoBackOnDelete}
      />

      <button className={styles.container} title={titleAndNumber} onClick={() => setIsModalOpen(true)}>
        <div className={styles.left}>
          {!!percentWatched && percentWatched >= 97 && (
            <div className={styles.overlay}>
              <SvgCheck />
            </div>
          )}
          <Image
            src={episode.pictures?.[0].href}
            fallback={missingImageUrl}
            crop={[256, 144]}
            alt={titleAndNumber}
            loading="lazy"
          />
        </div>
        <div className={styles.text}>
          <h4>
            <span className={styles.title}>{title}</span>
            <span className={styles.seasonNum}>{seasonNumAndEpisodeNum}</span>
          </h4>
          <p>{toMomentReadableDateString(episode.beginTime, true)}</p>
        </div>
        <div className={styles.right}>
          <SvgDelete />
        </div>
        {!!percentWatched && percentWatched > 1 && percentWatched < 97 && (
          <progress className={styles.progress} aria-label="Watched" value={percentWatched} max={100} />
        )}
      </button>
    </>
  );
}

export function DeleteMovie({ group, selectionMode, onChange, isSelected }: DeleteMovieProps) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = group.pvrName;
  const subtitle = t('movie');

  if (selectionMode) {
    return (
      <div className={styles.selectionMode}>
        <div className={styles.selection}>
          <input
            type="checkbox"
            name={group.pvrName}
            id={group.pvrName}
            value={group.pvrId}
            onChange={() => onChange && onChange(group)}
            checked={isSelected}
          />
          <label htmlFor={group.pvrName}>
            <SvgCheck />
          </label>
        </div>

        <label htmlFor={group.pvrName} className={styles.container} title={title}>
          <div className={styles.left}>
            <Image
              src={group.pictures?.[0].href}
              fallback={missingImageUrl}
              crop={[256, 144]}
              alt={title}
              loading="lazy"
            />
          </div>
          <div className={styles.text}>
            <h4>
              <span className={styles.title}>{title}</span>
            </h4>
            <p>{subtitle}</p>
          </div>
        </label>
      </div>
    );
  }

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        hideModal={() => setIsModalOpen(false)}
        idToDelete={group.pvrId}
        name={group.pvrName}
        picture={group.pictures?.[0].href}
      />

      <button className={styles.container} title={title} onClick={() => setIsModalOpen(true)}>
        <div className={styles.left}>
          <Image
            src={group.pictures?.[0].href}
            fallback={missingImageUrl}
            crop={[256, 144]}
            alt={title}
            loading="lazy"
          />
        </div>
        <div className={styles.text}>
          <h4>
            <span className={styles.title}>{title}</span>
          </h4>
          <p>{subtitle}</p>
        </div>
        <div className={styles.right}>
          <SvgDelete />
        </div>
      </button>
    </>
  );
}

export function PvrDeleteAssetSkeleton() {
  return (
    <div className={styles.skeleton}>
      <div className={styles.left} />
      <div className={styles.text}>
        <div></div>
        <div></div>
      </div>
      <div className={styles.right}>
        <SvgChevronRight />
      </div>
    </div>
  );
}

function hasSubrecordings(group: Recording.Group.Series) {
  return group.episodes.length > 1;
}
