import { getBaseUrl, getSessionTicket } from '../config';
import { getDataFromResponse, fetchError } from '../controllers/Fetch';
import { ThirdPartyCatchupData } from '../interfaces';

export async function getThirdPartyCatchupData(bossId: string | undefined, areaId: string | undefined) {
  const configUrl = `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/streaming-third-party-catchup.json`;

  try {
    const res = await fetch(configUrl, {
      method: 'GET',
      headers: { SessionTicket: getSessionTicket() },
    });

    return await getDataFromResponse<ThirdPartyCatchupData[]>(res);
  } catch (error) {
    return fetchError(error as string);
  }
}
