import { flatten, uniqBy } from 'lodash';
import { getBaseUrl, getSessionTicket } from '../../config';
import { getDataFromResponse } from '../../controllers/Fetch';
import { Bookmark, MediaFile } from '../../interfaces';
import { Vod } from './types';

export async function getVodDetails(ids: string[]) {
  const res = await fetch(getBaseUrl() + '/EPG/JSON/ContentDetail', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      metaDataVer: 'Channel/1.1',
      vod: ids.join(','),
    }),
  });

  const { vodlist } = await getDataFromResponse<{ vodlist: Vod.Details[] }>(res);

  return vodlist;
}

export async function getVodSitcomList(ids: string[]) {
  const requests = ids.map((id) => ({
    name: 'SitcomList',
    param: {
      vodid: id,
      count: -1,
      offset: 0,
    },
  }));

  const res = await fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: requests,
    }),
  });

  const data = await getDataFromResponse<Vod.BatchResponseList>(res);

  return data.responseList.map((list) => list.msg.vodlist);
}

export async function getSingleVodDetails(id: string) {
  const res = await fetch(getBaseUrl() + '/EPG/JSON/ContentDetail', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      metaDataVer: 'Channel/1.1',
      vod: id,
    }),
  });

  const { vodlist } = await getDataFromResponse<{ vodlist: Vod.Details[] }>(res);

  return vodlist[0];
}

export async function getVodParentDetails(ids: string[]): Promise<Vod.Details[]> {
  const assets = await getVodDetails(ids);

  if (!assets) {
    return Promise.resolve([]);
  }

  const hasParents = assets.some((asset) => asset.fathervodlist);

  if (hasParents) {
    const seasons = await getVodDetails(
      assets.map((asset) => (asset.fathervodlist ? asset.fathervodlist[0].vodid : asset.id)),
    );
    return getVodParentDetails(seasons.map((asset) => asset.id));
  }

  return assets;
}

export async function getSingleVodParentDetails(id: string): Promise<Vod.Details | undefined> {
  const asset = await getSingleVodDetails(id);

  if (!asset) {
    return Promise.resolve(undefined);
  }

  if (asset.fathervodlist) {
    const nextParent = await getSingleVodDetails(asset.fathervodlist[0].vodid);
    return getSingleVodParentDetails(nextParent.id);
  }

  return asset;
}

export async function constructContinueWatchingVodList(
  data: Vod.Details[],
  bookmarks: Bookmark[],
): Promise<Vod.ConstructedAsset[]> {
  if (!data) {
    return Promise.resolve([]);
  }

  const bookmarkMap = new Map(bookmarks.map((bookmark) => [bookmark.contentId, bookmark]));

  const episodes = data.filter((asset) => Boolean(asset.fathervodlist));
  const movies = data.filter((asset) => !Boolean(asset.fathervodlist));

  const uniqueEpisodes = uniqBy(episodes, (episode) => {
    return episode.fathervodlist?.[0].vodid;
  });

  const series = await getVodParentDetails(uniqueEpisodes.map((vod) => vod.id));

  const seasonsArr = await getVodSitcomList(series.map((parent) => parent.id));

  const episodesArr = await Promise.all(
    seasonsArr.map(async (seasons) => {
      const seasonIds = seasons.map((season) => season.id);
      return flatten(await getVodSitcomList(seasonIds));
    }),
  );

  const currentSeasonsArr = await getVodDetails(
    data.map((vod) => (vod.fathervodlist ? vod.fathervodlist[0].vodid : vod.id)),
  );

  const groupedData = [...uniqueEpisodes, ...movies];

  return groupedData.map((vod, i) => {
    let lastEpisodeWatched = false;

    if (episodesArr[i]) {
      const lastEpisode = episodesArr[i][episodesArr[i].length - 1];
      const percentWatched = getPercentWatched(bookmarkMap.get(lastEpisode.id)?.rangeTime, lastEpisode.mediafiles);
      lastEpisodeWatched = percentWatched > 97;
    }

    return {
      asset: vod,
      series: series[i],
      seasons: seasonsArr[i],
      episodes: episodesArr[i],
      seasonNum: currentSeasonsArr[i].fathervodlist?.[0].sitcomnum,
      updateTime: bookmarkMap.get(vod.id)?.updateTime,
      lastEpisodeWatched,
      type: 'vod',
    };
  });
}

function getPercentWatched(rangeTime: string | undefined, mediafiles: MediaFile[]) {
  if (rangeTime) {
    const bookmarkTimeInSeconds = Number(rangeTime);
    const durationInSeconds = getVodDuration(mediafiles);

    if (durationInSeconds > 0) {
      return (bookmarkTimeInSeconds / durationInSeconds) * 100;
    } else {
      return 0;
    }
  }
  return 0;
}

function getVodDuration(mediafiles: MediaFile[]) {
  return mediafiles && mediafiles[0] ? Number(mediafiles[0].elapsetime) : 0;
}
