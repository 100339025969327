import { Bookmark } from './../../interfaces';
import { useQuery } from 'react-query';
import { getSingleVodParentDetails, getVodDetails, constructContinueWatchingVodList } from './api';
import { vodKeys } from './keys';

export function useContinueWatchingVods(bookmarks: Bookmark[]) {
  const ids = removeDuplicateBookmarks(bookmarks).map((b) => b.contentId);

  return useQuery(
    vodKeys.bookmarks(bookmarks),
    () => getVodDetails(ids).then((data) => constructContinueWatchingVodList(data, bookmarks)),
    {
      enabled: Boolean(bookmarks),
      keepPreviousData: true,
    },
  );
}

export function useVodParentDetails(id: string) {
  return useQuery(vodKeys.parents(id), () => getSingleVodParentDetails(id));
}

function removeDuplicateBookmarks(bookmarks: Bookmark[]) {
  const deduped = bookmarks
    .reduce((map, obj) => {
      const curr = map.get(obj.contentId);
      return map.set(obj.contentId, curr ? compareUpdateTime(curr, obj) : obj);
    }, new Map<string, Bookmark>())
    .values();
  return [...deduped];
}

function compareUpdateTime(a: Bookmark, b: Bookmark) {
  return parseInt(a.updateTime, 10) > parseInt(b.updateTime, 10) ? a : b;
}
