import { googleAnalytics, isDev } from '../config';
import { isEmpty } from 'lodash';
import { getGAcategoryBasedOnRoute } from './AnalyticsMapping';
import { PortalMenu, GAcategory, GAaction } from '../interfaces';
import { getSvodGaName } from '../utils/svodUtil';

interface AnalyticsTrackingInterface {
  setCurrentService: (path: string, kiosk?: PortalMenu) => void;
  createCustomDimensionsObject: (a: string, b: string, c: string) => CustomDimension;
  createEcomObject: (a: string, b: string, c: string) => Ecom;
  createEventObject: (
    hitType: string,
    action: GAaction,
    label?: string,
    nonInteraction?: boolean,
    value?: string,
  ) => EventObject;
  addToQueue: (item: AnalyticsObject) => void;
  undoFromQueue: () => boolean;
  trackPage: () => void;
  trackEvent: (service: GAcategory, action: GAaction, label?: string, nonInteraction?: boolean, value?: string) => void;
  trackError: (error: Error) => void;
}

type CustomDimension = {
  a: string;
  b: string;
  c: string;
};

type EventObject = {
  hitType: string;
  eventCategory: GAcategory;
  eventAction: GAaction;
  nonInteraction: boolean;
  eventLabel?: string;
  eventValue?: string;
};

type Ecom = CustomDimension;

type AnalyticsObject = CustomDimension | Ecom;

class AnalyticsTracking implements AnalyticsTrackingInterface {
  public static instance: AnalyticsTracking;

  // Strict Class Initialization ignored
  private customActionQueue!: AnalyticsObject[];
  private currentCategory: GAcategory = GAcategory.uninitialized;
  private previousCategory!: GAcategory;

  public static getInstance(): AnalyticsTracking {
    if (!AnalyticsTracking.instance) {
      AnalyticsTracking.instance = new AnalyticsTracking();
    }
    return this.instance;
  }

  public isDisabled() {
    return googleAnalytics.isDisabled();
  }

  public disableTracking() {
    localStorage.setItem(googleAnalytics.localStorageKey, 'false');
    window[googleAnalytics.disableString] = true;
  }

  public enableTracking() {
    localStorage.setItem(googleAnalytics.localStorageKey, 'true');
    window[googleAnalytics.disableString] = false;
  }

  public toggleTracking() {
    if (this.isDisabled()) {
      this.enableTracking();
    } else {
      this.disableTracking();
    }
  }

  public getCurrentCategory(): GAcategory {
    return this.currentCategory;
  }

  public getPreviousCategory(): GAcategory {
    return this.previousCategory;
  }

  public setCurrentService(path: string, kiosk?: PortalMenu): void {
    const category = this.getCategoryName(path, kiosk);
    if (category !== this.getCurrentCategory()) {
      let action = this.getCurrentCategory() === GAcategory.uninitialized ? GAaction.openApp : GAaction.openService;
      this.setPreviousCategory(this.getCurrentCategory());
      this.setCurrentCategory(category);
      this.trackEvent(this.getPreviousCategory(), action, this.getCurrentCategory());
    }
  }

  public addToQueue(item: AnalyticsObject): void {
    this.getQueue().unshift(item);
  }

  public undoFromQueue(): boolean {
    const shifted = this.getQueue().shift();
    return shifted ? true : false;
  }

  public createCustomDimensionsObject(a: string, b: string, c: string): CustomDimension {
    return {
      a: a,
      b: b,
      c: c,
    };
  }

  public createEcomObject(a: string, b: string, c: string): Ecom {
    return {
      a: a,
      b: b,
      c: c,
    };
  }

  public createEventObject(
    hitType: string,
    action: GAaction,
    label?: string,
    nonInteraction?: boolean,
    value?: string,
  ): EventObject {
    let tempObject: EventObject = {
      hitType: hitType,
      eventCategory: this.getCurrentCategory(),
      eventAction: action,
      nonInteraction: nonInteraction ? nonInteraction : false,
    };

    if (label) {
      tempObject.eventLabel = label;
    }
    if (value) {
      tempObject.eventValue = value;
    }
    return tempObject;
  }

  public trackPage(): void {
    if (this.isDisabled()) {
      return console.debug('📄 🛑 Google Analytics is disabled');
    }

    this.sendQueue();
    if (isDev) {
      console.debug('📄' + decodeURIComponent(encodeURIComponent(window.location.pathname + window.location.search)));
    }

    this.send({
      hitType: 'pageview',
      page: decodeURIComponent(encodeURIComponent(window.location.pathname + window.location.search)),
    });
  }

  public trackEvent(
    service: GAcategory,
    action: GAaction,
    label?: string,
    nonInteraction?: boolean,
    value?: string,
  ): void {
    if (this.isDisabled()) {
      return console.debug('trackEvent: 🛑 Google Analytics is disabled');
    }

    this.sendQueue();
    if (isDev) {
      console.debug(
        'trackEvent: 📌 ' + service + ' 📣 ' + action + (label ? ' 🏷 ' + label + ' ' : '') + (value ? value : ''),
      );
    }
    if (service !== label) {
      const eventObject: EventObject = this.createEventObject('event', action, label, nonInteraction, value);
      this.send(eventObject);
    }
  }

  public trackCurrentService(action: GAaction, label?: string, nonInteraction?: boolean, value?: string): void {
    this.trackEvent(this.getCurrentCategory(), action, label, nonInteraction, value);
  }

  public trackError(error: Error): void {
    this.sendQueue();

    const message = error.message
      ? '| <MESSAGE> |' + error.message + '| <NAME> |' + error.name + '| <STACK> |' + error.stack
      : error.toString();
    const action = this.getCurrentCategory() ? this.getCurrentCategory() : 'Unknown Service';

    const eventObject = this.createEventObject('event', action as GAaction, message, false);
    eventObject.eventCategory = GAcategory.webError;

    this.send(eventObject);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private ga(command: string, input: any) {
    if (typeof ga === 'function') {
      ga(command, input);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private send(input: any) {
    this.ga('send', input);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private set(input: any) {
    this.ga('set', input);
  }

  private setPreviousCategory(category: GAcategory): void {
    this.previousCategory = category;
  }

  private sendQueue(): void {
    if (!isEmpty(this.getQueue())) {
      this.getQueue().forEach((objects) => {
        this.set(objects);
      });
      this.clearQueue();
    }
  }

  private getPathArray(path: string): string[] {
    return path.split('/');
  }

  private getCategoryName(path: string, kiosk?: PortalMenu): GAcategory {
    const pathArray = this.getPathArray(path);
    if (pathArray.length < 1) {
      return GAcategory.frontpage;
    }
    let category = getGAcategoryBasedOnRoute(pathArray[1]);
    if (kiosk && category === GAcategory.svod) {
      category = getSvodGaName(kiosk) as GAcategory;
    }
    return category;
  }

  private getQueue(): AnalyticsObject[] {
    return this.customActionQueue;
  }

  private clearQueue(): void {
    this.customActionQueue = [];
  }

  private setCurrentCategory(category: GAcategory): void {
    this.currentCategory = category;
  }
}

export default AnalyticsTracking;
