import React from 'react';
import { GAaction, GAcategory, GAlabel, SearchConfig, SearchFunctions } from '../../../interfaces';
import { Key } from 'ts-key-enum';
import './style.scss';
import {
  prettifySearchQueryURL,
  prettySearchQueryToHuawei,
  isOnSearch,
  prettySearchQuery,
  isStringEncoded,
  decodeURIComponentSafe,
} from '../../../utils/searchUtils';
import { withRouter, RouteComponentProps } from 'react-router';
import i18n from '../../../i18n';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import { DebounceInput } from 'react-debounce-input';

interface Props extends RouteComponentProps {
  hideContainer: boolean;
  searchQuery?: string;
  closeSearch?: Function;
  searchFunctions: SearchFunctions;
  cancelPotentialSearch: Function;
}

interface State {
  isSearching: boolean;
  shouldRedirect: boolean;
  isMounted: boolean;
  overrideSearch: string;
  shouldFocus: boolean;
}

class SearchBar extends React.Component<Props & SearchConfig, State> {
  maxCharLength = 30;

  state: State = {
    isSearching: false,
    shouldRedirect: false,
    isMounted: false,
    shouldFocus: true,
    overrideSearch: '',
  };

  // Strict Class Initialization ignored
  searchFieldRef!: HTMLInputElement | null;

  tracking = AnalyticsTracking.getInstance();

  UNSAFE_componentWillMount() {
    if (this.searchFieldRef && !this.props.noFocus) {
      this.searchFieldRef.focus();
    }
  }

  componentDidMount() {
    if (window.location.search.indexOf('?s=') !== -1) {
      if (window.location.search.length >= 4) {
        let query = String(window.location.search.substring(3));
        query = isStringEncoded(query) ? decodeURIComponentSafe(query) : query;
        this.setState(
          {
            overrideSearch: prettySearchQuery(query),
          },
          () => {
            this.setSearchState(query);
          },
        );
      }
    }
    if (this.searchFieldRef && !this.props.noFocus) {
      this.searchFieldRef.focus();
    }

    window.addEventListener('mousedown', this.clickOutside, false);
    window.addEventListener('mousedown', this.clickInside, false);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.clickOutside, false);
    window.removeEventListener('mousedown', this.clickInside, false);
  }

  componentDidUpdate() {
    if (this.searchFieldRef && !this.props.noFocus && this.state.shouldFocus) {
      this.searchFieldRef.focus();
    }
  }

  clickInside = (event: Event) => {
    if (this.searchFieldRef && this.searchFieldRef.contains(event.target as Node)) {
      if (!this.state.shouldFocus) {
        this.toggleFocus();
      }
    }
  };

  clickOutside = (event: Event) => {
    const { searchQuery } = this.props;
    if (this.searchFieldRef && !this.searchFieldRef.contains(event.target as Node)) {
      if (this.state.shouldFocus) {
        this.toggleFocus();
      }
      if (searchQuery === '') {
        this.handleClearSearch(event as {} as React.MouseEvent<HTMLDivElement, MouseEvent>);
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.searchQuery && nextProps.searchQuery !== this.props.searchQuery) {
      if (nextProps.searchQuery.length >= 1) {
        if (this.state.isSearching) {
          this.search(nextProps.searchQuery!);
        }
        this.showSearchLoader(false);
        if (!this.state.isMounted) {
          this.setState({
            isMounted: true,
          });
        }
      } else {
        this.props.searchFunctions.emptySearch();
      }
    } else {
      this.showSearchLoader(false);
    }
  }

  setSearchState(query: string) {
    if (query.length > this.maxCharLength) {
      query = query.substring(0, this.maxCharLength);
    }
    query = isStringEncoded(query) ? decodeURIComponentSafe(query) : query;
    query = prettySearchQueryToHuawei(query);
    this.search(query);
    window.history.replaceState({}, 'search', `./search?s=${prettifySearchQueryURL(query)}`);
  }

  showSearchLoader(value: boolean) {
    this.setState({
      isSearching: value,
    });
  }

  toggleFocus() {
    this.setState({
      shouldFocus: !this.state.shouldFocus,
    });
  }

  toggleSpinner = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== Key.Enter && event.key !== Key.Tab && event.key !== Key.Shift && event.key !== Key.Control) {
      if ((event.target as HTMLInputElement).value.length >= 1) {
        this.showSearchLoader(false);
      } else {
        this.showSearchLoader(false);
      }
    }
  };

  preventAction = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Key.Enter) {
      this.showSearchLoader(false);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handleChange = (evt: React.FormEvent<EventTarget>) => {
    let queryString: string = String((evt.target as HTMLInputElement).value);
    if (queryString.length > this.maxCharLength) {
      queryString = queryString.substring(0, this.maxCharLength);
    }
    let prettyString = prettifySearchQueryURL(queryString);
    queryString = isStringEncoded(queryString) ? decodeURIComponentSafe(queryString) : queryString;
    this.setState(
      {
        overrideSearch: queryString,
      },
      () => {
        if (!isOnSearch(window.location.pathname)) {
          this.props.history.push(`${this.props.baseService}/search?s=${prettyString}`);
        } else {
          if (queryString.length >= 1) {
            this.setState({ isSearching: false }, () => this.search(queryString));
          } else {
            this.props.searchFunctions.emptySearch();
            window.history.replaceState({}, 'search', `./search`);
          }
        }
      },
    );
  };

  trackSearch() {
    let criteria = window.location.search.charAt(window.location.search.length - 1) !== '+';
    const fromStreamingServicesPage = this.tracking.getCurrentCategory() === GAcategory.streamingServices;

    if (fromStreamingServicesPage) {
      this.tracking.trackEvent(GAcategory.streamingServices, GAaction.openService, GAlabel.search);
    }

    if (criteria && this.state.isMounted) {
      this.tracking.trackPage();
    }
  }

  search = (value: string) => {
    if (value !== '') {
      window.history.replaceState({}, 'search', `./search?s=${prettifySearchQueryURL(this.state.overrideSearch)}`);

      value = prettySearchQueryToHuawei(value);
      if (!this.state.isSearching) {
        this.props.searchFunctions.fetchSearchResults(value);
        this.trackSearch();
        this.setState({
          isSearching: true,
        });
      }
    }
  };

  setCursorToEOL = (e: React.FocusEvent<EventTarget>) => {
    let val = (e.target as HTMLInputElement).value;
    (e.target as HTMLInputElement).value = '';
    (e.target as HTMLInputElement).value = val;
  };

  handleClearSearch = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.props.searchFunctions.emptySearch();
    this.setState({ overrideSearch: '' });
    if (
      e.target &&
      typeof (e.target as HTMLElement).className === 'string' &&
      (e.target as HTMLElement).className.split(' ').indexOf('search-close-button') !== -1
    ) {
      this.props.cancelPotentialSearch();
      this.setSearchState('');
    }
    if (this.searchFieldRef && this.state.shouldFocus) {
      this.searchFieldRef.focus();
    }
  };

  handleCloseSearch = () => {
    if (this.searchFieldRef) {
      this.searchFieldRef.focus();
    }
    if (this.props.closeSearch) {
      this.props.closeSearch();
    }
  };

  render() {
    let value = this.state.overrideSearch === '' ? this.props.searchQuery : this.state.overrideSearch;
    return (
      <div className="searchbar-container">
        <form>
          <div className="searchField">
            <span className="searchIcon">E</span>
            <DebounceInput
              debounceTimeout={1200}
              className="searchInput"
              onFocus={this.setCursorToEOL}
              placeholder={i18n.t<string>('placeholder.vod')}
              onChange={this.handleChange}
              value={decodeURIComponentSafe(value!)}
              type="search"
              onKeyDown={(evt) => this.preventAction(evt)}
              onKeyUp={this.toggleSpinner}
              maxLength={this.maxCharLength}
            />
            {value === '' ? null : (
              <div onClick={(e) => this.handleClearSearch(e)} className="aib-icon search-close-button">
                B
              </div>
            )}
            {this.state.isSearching
              ? null // <Spinner />
              : null}
          </div>
        </form>
      </div>
    );
  }
}
export default withRouter(SearchBar);
