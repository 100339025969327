import React, { Component, RefCallback, useCallback, useEffect, useState } from 'react';
import {
  getAltiboxAssetCast,
  getAltiboxAssetId,
  getAltiboxAssetYearAndCountry,
  getAltiboxAssetOriginalTitle,
  getAltiboxAssetDistributor,
  getAltiboxAssetMedietilsynId,
  getAltiboxAssetAudioLanguages,
  getAltiboxAssetSubtitleLanguages,
  getAltiboxAssetVideoFormats,
  wrapAssetAsAltiboxAsset,
  getAltiboxAssetExpiration,
} from '../../../../utils/altiboxassetUtils';
import AltiboxAssetContext from '../../../../views/details/AltiboxAssetContext';
import './style.scss';
import i18n from '../../../../i18n';
import AltiboxCast from '../../AltiboxCast';
import { AltiboxAsset, AltiboxAssetDetailsType, Cast, Channel, UnionAssetTypes } from '../../../../interfaces';
import { shouldPVRNotExpire } from '../../../../utils/pvrUtils';
import { isEmpty } from 'lodash';
import { TextButtonExpandable } from '../../Buttons';
import { assetIsPvr } from '../../../../typeGuards';

function CastList({ cast }: { cast: Cast[] }) {
  const [expanded, setExpanded] = useState(false);
  const [expandable, setExpandable] = useState(false);
  const [expandLimit, setExpandLimit] = useState(0);
  const [castToShow, setCastToShow] = useState(cast);
  const [listEl, setListEl] = useState<HTMLUListElement | null>(null);
  const listRef = useCallback<RefCallback<HTMLUListElement>>((element) => setListEl(element), []);

  useEffect(() => {
    if (!listEl || isEmpty(listEl.children)) {
      return;
    }

    const listItemHeight = listEl.children[0].clientHeight;
    const itemCount = listEl.children.length;
    const firstLineItemCount = Array.from(listEl.children).filter(
      (element) => (element as HTMLLIElement).offsetTop <= listEl.offsetTop + listItemHeight / 2,
    ).length;

    if (itemCount > firstLineItemCount) {
      setExpandable(true);
      setExpandLimit(firstLineItemCount - 1);
    }
  }, [listEl]);

  useEffect(() => {
    if (expandable && expandLimit && !expanded) {
      setCastToShow(cast.slice(0, expandLimit));
    } else {
      setCastToShow(cast);
    }
  }, [expandable, expandLimit, expanded, cast]);

  return (
    <div className="altibox-asset-cast-list-container">
      <ul ref={listRef} className="altibox-asset-cast-list">
        {castToShow.map((member, idx, array) => (
          <li key={member.castId}>
            <AltiboxCast textLink={true} cast={member} last={idx === array.length - 1}></AltiboxCast>
          </li>
        ))}
        {expandable ? (
          <li>
            <TextButtonExpandable
              className="altibox-asset-cast-list-expand-button"
              expanded={expanded}
              onClick={() => setExpanded((state) => !state)}
            >
              {expanded ? i18n.t<string>('show less') : i18n.t<string>('show more')}
            </TextButtonExpandable>
          </li>
        ) : null}
      </ul>
    </div>
  );
}

type AltiboxAssetMetaContext = {
  promotedAsset: UnionAssetTypes;
  altiboxAsset: AltiboxAsset;
  channels: Channel[];
};
export default class AltiboxAssetMeta extends Component {
  static contextType = AltiboxAssetContext;

  get promotedAsset() {
    let { promotedAsset } = this.context as AltiboxAssetMetaContext;
    return promotedAsset;
  }

  get cast() {
    const { promotedAsset } = this.context as AltiboxAssetMetaContext;
    const cast = getAltiboxAssetCast(wrapAssetAsAltiboxAsset(promotedAsset));
    const [actors, producers] = cast;

    const noCast = !actors && !producers;

    let actorsJsx = actors ? (
      <div className="altibox-asset-cast-container">
        <h2>{i18n.t<string>('in roles')}</h2>
        <CastList cast={actors} />
      </div>
    ) : null;

    let producersJsx = producers ? (
      <div className="altibox-asset-cast-container">
        <h2>{i18n.t<string>('directors and producers')}</h2>
        <CastList cast={producers} />
      </div>
    ) : null;

    if (noCast) {
      return null;
    }

    return (
      <>
        {actorsJsx}
        {producersJsx}
      </>
    );
  }

  get details() {
    const { altiboxAsset, channels } = this.context as AltiboxAssetMetaContext;
    const { type } = altiboxAsset;
    let asset = this.promotedAsset;

    // Use foreignsn for meta data details
    const altiboxId = getAltiboxAssetId(wrapAssetAsAltiboxAsset(asset).asset, true);
    const yearandcountry = getAltiboxAssetYearAndCountry(altiboxAsset);
    const originaltitle = getAltiboxAssetOriginalTitle(altiboxAsset);
    const distributor = getAltiboxAssetDistributor(altiboxAsset);
    const audiolanguages = getAltiboxAssetAudioLanguages(altiboxAsset, this.promotedAsset);
    const subtitlelanguages = getAltiboxAssetSubtitleLanguages(altiboxAsset, this.promotedAsset);
    const videoformats = getAltiboxAssetVideoFormats(altiboxAsset, this.promotedAsset);
    const medietilsyn = getAltiboxAssetMedietilsynId(altiboxAsset, this.promotedAsset);

    switch (type) {
      case AltiboxAssetDetailsType.PVR:
      case AltiboxAssetDetailsType.CATCHUP:
        if (assetIsPvr(asset)) {
          return (
            <div className="altibox-asset-meta-group">
              {!isEmpty(yearandcountry) ? (
                <>
                  <span className="key">{i18n.t<string>('year and country')}: </span>
                  <span className="value">{yearandcountry}</span>
                </>
              ) : null}
              <div className="divider" />
              <span className="key">Altibox ID: </span>
              <span className="value">{altiboxId}</span>
              {shouldPVRNotExpire(asset) ? null : (
                <>
                  <div className="divider" />
                  <span className="key">{i18n.t<string>('deletes in')}: </span>
                  <span className="value">{getAltiboxAssetExpiration(asset, channels)}</span>
                </>
              )}
            </div>
          );
        }

        return (
          <div className="altibox-asset-meta-group">
            <span className="key">{i18n.t<string>('year and country')}: </span>
            <span className="value">{yearandcountry}</span>
            <div className="divider" />
            <span className="key">Altibox ID: </span>
            <span className="value">{altiboxId}</span>
          </div>
        );
      case AltiboxAssetDetailsType.SVOD:
      case AltiboxAssetDetailsType.VIDEO_VOD:
      case AltiboxAssetDetailsType.VOD:
        return (
          <>
            <div className="altibox-asset-meta-group">
              <span className="key">{i18n.t<string>('original title')}: </span>
              <span className="value">{originaltitle}</span>
              <div className="divider" />
              <span className="key">{i18n.t<string>('distributor')}: </span>
              <span className="value">{distributor}</span>
              <div className="divider" />
              <span className="key">Altibox ID: </span>
              <span className="value">{altiboxId}</span>
              <div className="divider" />
              <span className="key">{i18n.t<string>('year and country')}: </span>
              <span className="value">{yearandcountry}</span>
            </div>
            <div className="altibox-asset-meta-group">
              <span className="key">{i18n.t<string>('audio languages')}: </span>
              <span className="value language">{audiolanguages}</span>
              <div className="divider" />
              <span className="key">{i18n.t<string>('subtitle languages')}: </span>
              <span className="value language">{subtitlelanguages}</span>
              <div className="divider" />
              <span className="key">{i18n.t<string>('video quality')}: </span>
              <span className="value">{videoformats}</span>
              <div className="divider" />
              <span className="key">Medietilsynet ID: </span>
              <span className="value">{medietilsyn}</span>
            </div>
          </>
        );
      default:
        return undefined;
    }
  }

  render() {
    if (!this.promotedAsset) {
      return null;
    }
    return (
      <div className="altibox-asset-content-container">
        {this.cast}
        {this.details ? (
          <div className="altibox-asset-details">
            <h2>{i18n.t<string>('details')}</h2>
            <div className="details">{this.details}</div>
          </div>
        ) : null}
      </div>
    );
  }
}
