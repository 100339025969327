import { useEffect, useRef, useState, useCallback } from 'react';

type UseInViewOptions = (IntersectionObserverInit | undefined) & { triggerOnce?: boolean };

export function useInView<T extends HTMLElement>({ triggerOnce, ...options }: UseInViewOptions) {
  const ref = useRef<T>(null);
  const [inView, setInView] = useState(false);

  const callback = useCallback(
    ([entry]: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      if (entry.isIntersecting && triggerOnce && ref.current) {
        setInView(true);
        return observer.unobserve(ref.current);
      }

      setInView(entry.isIntersecting);
    },
    [triggerOnce],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);

    let current: T;

    if (ref.current) {
      observer.observe(ref.current);
      current = ref.current;
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [callback, options]);

  return { ref, inView };
}
