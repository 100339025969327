import { isNumber } from 'util';
import { routes, getT1ProductName, imageScaleValues, disneyChannels } from '../config';
import i18n from '../i18n';
import {
  AltiboxAsset,
  AltiboxAssetDetailsType,
  AltiboxSeason,
  Bookmark,
  Cast,
  Channel,
  Picture,
  PortalMenu,
  Program,
  VodAsset,
  UnionAssetTypes,
  AuthReducerState,
  BroadcastStatus,
  PvrRecording,
  ProgramToRecord,
  Genre,
  ImageParams,
  ChannelLogoType,
  AgeLimitColorsGroup,
  ExtensionInfo,
  RecordingDeleteMode,
  Recordings,
  AgeRatingCountry,
} from '../interfaces';
import { DetailsRouteProps } from '../views/details';
import { toMoment, broadcastingNow, toDetailsReadableDateString, broadcastStatus } from './huaweiUtils';
import { getDuration as getProgramDuration, programExpiresIn } from './programArchiveUtils';
import {
  getVodDuration,
  getYearProduced,
  getInfo as getVodInfo,
  getAvailableAudio,
  getAvailableSubtitles,
  getAvailableFormats,
} from './vodUtils';
import { getSvodKiosk, getSvodUrl } from './svodUtil';
import moment from 'moment';
import {
  getPercentWatched as getPVRPercentWatched,
  getDuration as getPvrDuration,
  getPVRExpiration,
  getPvrGenres,
  getIsRecordingSeries,
  getIsRecordingProgram,
} from './pvrUtils';
import isEmpty from 'lodash/isEmpty';
import { getChannelLogoUrl } from './tvUtils';
import { assetIsProgram, assetIsPvr, assetIsVod } from '../typeGuards';
import { flatten } from 'lodash';
import { ScriptService } from '../controllers/ScriptService';

export function getAltiboxAssetDetailsType({ type }: AltiboxAsset) {
  return AltiboxAssetDetailsType[type];
}

export function getAltiboxAssetId(asset: UnionAssetTypes, useForeignsn?: boolean) {
  if (assetIsProgram(asset)) {
    return asset.id || asset.foreignsn || '';
  }

  if (assetIsPvr(asset)) {
    return asset.pvrId || asset.foreignsn || asset.programId;
  }

  // Content providers requires that we display foreignsn in meta data details
  return useForeignsn && asset.foreignsn ? asset.foreignsn : asset.id || asset.foreignsn || '';
}

export function addEpisodeSeasonTag(asset: AltiboxAsset, seasonNum: string): AltiboxAsset {
  return {
    ...asset,
    episodeSeasonTag: `S${seasonNum}E${getAltiboxAssetEpisodeNumber(asset)}`,
  };
}

export function sortByEpisodeAndSeasonTag(assets: AltiboxAsset[], ascending = false) {
  return [...assets].sort((a, b) => {
    if (!a.episodeSeasonTag || !b.episodeSeasonTag) {
      return 0;
    }

    const REGEX_SEASON_EPISODE_SELECTOR = /S([0-9]+)E([0-9]+)/;
    const matches = {
      a: a.episodeSeasonTag.match(REGEX_SEASON_EPISODE_SELECTOR) || [],
      b: b.episodeSeasonTag.match(REGEX_SEASON_EPISODE_SELECTOR) || [],
    };
    const [aSeason, aEpisode] = [...matches.a].slice(1);
    const [bSeason, bEpisode] = [...matches.b].slice(1);

    if (!aSeason || !bSeason || !aEpisode || !bEpisode) {
      return 0;
    }

    if (ascending) {
      return Number(aSeason) - Number(bSeason) || Number(aEpisode) - Number(bEpisode);
    }

    return Number(bSeason) - Number(aSeason) || Number(bEpisode) - Number(aEpisode);
  });
}

export function getAltiboxAssetEpisodeTitle(altiboxAsset: AltiboxAsset): string {
  return getAltiboxAssetTitle(altiboxAsset, true);
}

export function getAltiboxAssetTitle(altiboxAsset: AltiboxAsset, episode: boolean = false): string {
  let { asset } = altiboxAsset;
  if (asset && isPvr(asset)) {
    asset = asset as PvrRecording;
    if (asset.subName && !isPvrPartOfSeries(asset)) {
      return asset.subName;
    }
    return asset.periodPVRTaskName ?? asset.pvrName;
  }
  if ('subName' in asset && asset.subName) {
    if (episode && altiboxAsset.type === AltiboxAssetDetailsType.CATCHUP) {
      return asset.subName;
    }
    if (asset.seasons) {
      return asset.name;
    }
    // check for part of series, but is single
    if (asset.subNum && asset.seasonNum) {
      return asset.name;
    }
    return asset.subName;
  }

  return asset ? asset.name : '';
}

export function getAltiboxAssetYearAndCountry(altiboxAsset: AltiboxAsset) {
  const asset = altiboxAsset.asset as Program | VodAsset;
  const producedate = asset.producedate ?? altiboxAsset.meta?.producedate ?? '';
  const country = asset.country ?? altiboxAsset.meta?.country ?? '';

  const year = producedate ? producedate.substr(0, 4) : '';
  return [year, country].filter((x) => x).join(', ');
}

export function getAltiboxAssetTitleSuffix(
  altiboxAsset: AltiboxAsset,
  routeProps: DetailsRouteProps,
  promotedAsset?: UnionAssetTypes,
): string {
  let { type, asset } = altiboxAsset;
  if (altiboxAsset.meta && altiboxAsset.meta.isSeries) {
    switch (type) {
      case AltiboxAssetDetailsType.VIDEO_VOD:
      case AltiboxAssetDetailsType.SVOD:
      case AltiboxAssetDetailsType.VOD:
        asset = asset as VodAsset;
        let vodSeasons: AltiboxSeason[] = asset.seasons! as {} as AltiboxSeason[];
        let idToPromote = routeProps.childId ? routeProps.childId : (promotedAsset as VodAsset)?.id;
        let foundEpisode: AltiboxAsset | undefined = undefined;
        let vodSeasonNumber = '0';
        vodSeasons.forEach((season) => {
          if (!foundEpisode) {
            vodSeasonNumber = season.seasonNumber;
            foundEpisode = season.content.find((x) => (x.asset as VodAsset).id === idToPromote);
            if (!foundEpisode) {
              foundEpisode = season.content.find((x) => (x.asset as VodAsset).foreignsn! === idToPromote);
            }
          }
        });
        if (foundEpisode) {
          return `S${vodSeasonNumber} E${((foundEpisode as AltiboxAsset).asset as VodAsset).sitcomnum}`; // Shhh Typescript 🤫
        }
        return foundEpisode ? foundEpisode : '';
      case AltiboxAssetDetailsType.PVR: {
        asset = asset as PvrRecording;
        const { childId } = routeProps;
        let pvrIdToFind = promotedAsset ? (promotedAsset as PvrRecording).pvrId : childId;
        const [foundPVREpisode, PVRSeasonNumber] = findPromotedSeasonBasedOnEpisode(asset.seasons!, pvrIdToFind!);
        if (foundPVREpisode && !isNaN(Number(PVRSeasonNumber))) {
          const episode = foundPVREpisode.asset as Program;
          if (episode.subNum) {
            return `S${PVRSeasonNumber} E${episode.subNum}`;
          } else {
            return '';
          }
        }
        return '';
      }
      case AltiboxAssetDetailsType.PROGRAM:
      case AltiboxAssetDetailsType.CATCHUP:
        const { childId } = routeProps;
        asset = asset as Program;
        let programIdToFind = promotedAsset ? promotedAsset.id : childId;
        const [foundProgramEpisode, programSeasonNumber] = findPromotedSeasonBasedOnEpisode(
          asset.seasons!,
          programIdToFind!,
        );
        if (foundProgramEpisode && !isNaN(Number(programSeasonNumber))) {
          const episode = foundProgramEpisode.asset as Program;
          if (episode.subNum) {
            return `S${programSeasonNumber} E${episode.subNum}`;
          } else {
            return '';
          }
        }
        return '';
      default:
        return '';
    }
  } else {
    switch (type) {
      case AltiboxAssetDetailsType.PVR:
        asset = asset as PvrRecording;
        if (asset.subNum) {
          let seasonNum = asset.seasonNum ? asset.seasonNum : 0;
          return `S${seasonNum} E${asset.subNum}`;
        } else {
          return '';
        }
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PROGRAM:
        asset = asset as Program;
        if (asset.subNum && asset.seasonNum) {
          let seasonNum = asset.seasonNum ? asset.seasonNum : 0;
          return `S${seasonNum} E${asset.subNum}`;
        }
        return '';
      default:
        return '';
    }
  }
}

export function getAltiboxAssetPromotedAsset(altiboxAsset: AltiboxAsset, promotedId: string) {
  const asset = altiboxAsset.asset;
  const assetHuaweiId = assetIsPvr(asset) ? asset.pvrId : asset.id;
  const assetForeignId = asset.foreignsn;
  const seasons = asset.seasons as AltiboxSeason[];

  if (promotedId === assetHuaweiId || promotedId === assetForeignId || !seasons) {
    return asset;
  } else {
    let [foundAsset] = findPromotedSeasonBasedOnEpisode(seasons, promotedId);
    return foundAsset ? foundAsset.asset : undefined;
  }
}

function findPromotedSeasonBasedOnEpisode(
  seasons: AltiboxSeason[],
  promotedId: string,
): [AltiboxAsset | undefined, string | undefined] {
  const allEpisodes = flatten(seasons.map((season) => season.content));
  const episode = allEpisodes.find(({ asset }) =>
    assetIsPvr(asset) ? asset.pvrId === promotedId : asset.id === promotedId ? true : asset.foreignsn === promotedId,
  );
  const season = episode
    ? seasons.find((_season) => _season.content.find((_episode) => _episode === episode))
    : undefined;

  if (!episode || !season) {
    const firstSeason = seasons.find((_season) => !isEmpty(_season.content));

    if (!firstSeason) {
      return [undefined, undefined];
    }

    return [firstSeason.content[0], firstSeason.seasonNumber];
  }

  return [episode, season.seasonNumber];
}

export function getAltiboxAssetHeroDescription(altiboxAsset: AltiboxAsset): string {
  return altiboxAsset.meta?.introduce ?? altiboxAsset.asset.introduce;
}

export function getAltiboxAssetDescription(altiboxAsset: AltiboxAsset, routeProps: DetailsRouteProps): string {
  let description = altiboxAsset.asset.introduce;
  if (altiboxAsset.meta && altiboxAsset.meta.isSeries) {
    const { type } = altiboxAsset;
    switch (type) {
      case AltiboxAssetDetailsType.SVOD:
      case AltiboxAssetDetailsType.VOD: {
        const { childId } = routeProps;
        let vodAsset = altiboxAsset.asset as VodAsset;
        let vodSeasons: AltiboxSeason[] = vodAsset.seasons as [] as AltiboxSeason[];
        if (childId) {
          let [foundVodEpisode] = findPromotedSeasonBasedOnEpisode(vodSeasons, childId);
          if (foundVodEpisode) {
            description = foundVodEpisode.asset.introduce;
          }
        }
        break;
      }
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PVR:
        const { childId } = routeProps;
        let programAsset = altiboxAsset.asset as Program;
        const [foundProgramEpisode] = findPromotedSeasonBasedOnEpisode(programAsset.seasons!, childId!);
        if (foundProgramEpisode) {
          description = foundProgramEpisode.asset.introduce;
        }
        break;
    }
  }
  return description ? description : '';
}

export function getAltiboxAssetExpiration(promotedAsset: UnionAssetTypes, channels?: Channel[]) {
  if (isPvr(promotedAsset)) {
    return getPVRExpiration(promotedAsset as PvrRecording, channels!);
  }
  return programExpiresIn(promotedAsset as Program);
}

export function getAltiboxAssetCaption(
  altiboxAsset: AltiboxAsset,
  promotedAsset: UnionAssetTypes,
  genres: Genre[],
): string {
  const { type } = altiboxAsset;
  switch (type) {
    case AltiboxAssetDetailsType.PROGRAM:
    case AltiboxAssetDetailsType.CATCHUP:
    case AltiboxAssetDetailsType.PVR:
      const asset = (promotedAsset ?? altiboxAsset.asset) as Program | PvrRecording;
      const [assetDuration, assetGenres] = assetIsPvr(asset)
        ? [getPvrDuration(asset), getPvrGenres(asset, genres)]
        : [getProgramDuration(asset, true), asset.genres];
      const assetYearProduced = asset.producedate
        ? getAssetYearProduced(asset)
        : getAssetYearProduced(altiboxAsset.asset as Program, altiboxAsset);

      return [assetDuration + ' min', assetYearProduced, assetGenres].filter((x) => x).join(' • ');
    case AltiboxAssetDetailsType.VIDEO_VOD:
    case AltiboxAssetDetailsType.VOD:
    case AltiboxAssetDetailsType.SVOD:
      let vodAsset = promotedAsset ? (promotedAsset as VodAsset) : (altiboxAsset.asset as VodAsset);
      let vodGenres = vodAsset.genres ? separateGenres(vodAsset.genres) : undefined;
      let vodDuration: string = Math.ceil(getVodDuration(vodAsset) / 60) + ' min';
      let vodYear = getYearProduced(vodAsset) ? getYearProduced(vodAsset) : getYearProduced(vodAsset);
      return [vodDuration, vodYear, vodGenres].filter((x) => x).join(' • ');
    default:
      return '';
  }
}

function getAssetYearProduced(asset: Program | PvrRecording, altiboxAsset?: AltiboxAsset) {
  if (asset.producedate) {
    return asset.producedate.substring(0, 4);
  } else if (altiboxAsset && altiboxAsset.meta && altiboxAsset.meta.producedate) {
    return altiboxAsset.meta.producedate.substring(0, 4);
  }

  return '';
}

function separateGenres(genres: string) {
  return genres.split(',').join(', ');
}

export function getAltiboxAssetChannel(channelId: string, channelList: Channel[]) {
  return channelList.find((x) => x.contentId === channelId) as Channel;
}

export function getAltiboxAssetEpisodeNumber(altiboxAsset: AltiboxAsset) {
  const { type } = altiboxAsset;

  switch (type) {
    case AltiboxAssetDetailsType.CATCHUP:
    case AltiboxAssetDetailsType.PVR:
      const programAsset = altiboxAsset.asset as Program;
      return programAsset.subNum ? programAsset.subNum : '';
    case AltiboxAssetDetailsType.VOD:
      const vodAsset = altiboxAsset.asset as VodAsset;
      return vodAsset.sitcomnum ? vodAsset.sitcomnum : '';
    case AltiboxAssetDetailsType.SVOD:
    default:
      return '';
  }
}

export function getAltiboxAssetCover(altiboxAsset: AltiboxAsset, promotedAsset?: UnionAssetTypes): string | undefined {
  const { type } = altiboxAsset;
  switch (type) {
    case AltiboxAssetDetailsType.CATCHUP:
    case AltiboxAssetDetailsType.PVR:
    case AltiboxAssetDetailsType.SVOD:
    case AltiboxAssetDetailsType.VOD:
    case AltiboxAssetDetailsType.PROGRAM:
      let temp = { ...altiboxAsset };
      if (promotedAsset) {
        temp.asset = promotedAsset;
      }
      return getProgramCover(temp) ? getProgramCover(temp) : getFallbackPicture(altiboxAsset);
  }
  return undefined;
}

export function getAltiboxAssetAdditionalThumbnail(
  altiboxAsset: AltiboxAsset,
  channels?: Channel[],
  kiosk?: PortalMenu,
): string | undefined {
  const { type, meta } = altiboxAsset;
  let channel = undefined;
  let channelImage = '';
  let picture = '';
  switch (type) {
    case AltiboxAssetDetailsType.CATCHUP:
      if (channels) {
        channel = getAltiboxAssetChannel((altiboxAsset.asset as Program).channelid, channels);
        picture = channel ? getChannelLogoUrl(channel, ChannelLogoType.CROPPED) : '';
        return picture;
      }
      channelImage = altiboxAsset.meta!.channel!.pictures[0].href;
      return channelImage ? channelImage : '';
    case AltiboxAssetDetailsType.PVR:
      if (channels) {
        channel = getAltiboxAssetChannel((altiboxAsset.asset as PvrRecording).channelId, channels);
        if (channel) {
          picture = getChannelLogoUrl(channel, ChannelLogoType.CROPPED);
        }
        return picture;
      }
      channelImage = altiboxAsset.meta!.channel!.pictures[0].href;
      return channelImage ? channelImage : '';
    case AltiboxAssetDetailsType.VOD:
    case AltiboxAssetDetailsType.SVOD:
      if (kiosk && kiosk.kioskHeader && kiosk.kioskHeader.phone) {
        return meta!.defaultImagePath! + '/' + kiosk.kioskHeader.phone;
      }
      return undefined;
  }
  return undefined;
}
export function getAltiboxAssetThumbnail(altiboxAsset: AltiboxAsset, assetSeries: AltiboxAsset): string | undefined {
  const { type } = altiboxAsset;
  const programCoverDimensionParams = getImageDimensionParams(imageScaleValues.assetThumbnail);
  switch (type) {
    case AltiboxAssetDetailsType.CATCHUP:
    case AltiboxAssetDetailsType.PROGRAM:
    case AltiboxAssetDetailsType.PVR:
    case AltiboxAssetDetailsType.SVOD:
    case AltiboxAssetDetailsType.VOD:
      return (
        (getProgramCover(altiboxAsset) ? getProgramCover(altiboxAsset) : getFallbackPicture(assetSeries)) +
        programCoverDimensionParams
      );
  }
  return undefined;
}

function getImageDimensionParams(imageParams: ImageParams): string {
  return `?x=${imageParams.width}&y=${imageParams.height}&ar=${imageParams.aspectRatioType}`;
}

export function getImageUrlDimensions(imageUrl: string, params: ImageParams): string {
  return imageUrl + getImageDimensionParams(params);
}

export function getAltiboxAssetCast(altiboxAsset: AltiboxAsset): [Cast[] | undefined, Cast[] | undefined] {
  const cast = altiboxAsset.asset.casts;
  if (cast && cast.length > 0) {
    let actors: Cast[] | undefined = cast
      .filter((x) => x.roleType === '0')
      .filter((y) => y)
      .map((z) => z as Cast);
    actors = onlyUniqueCasts(actors);
    if (actors.length <= 0) {
      actors = undefined;
    }
    let directors: Cast[] | undefined = cast
      .filter((x) => x.roleType === '4' || x.roleType === '1')
      .filter((y) => y)
      .map((z) => z as Cast);
    directors = onlyUniqueCasts(directors);
    if (directors.length <= 0) {
      directors = undefined;
    }
    return [actors, directors];
  } else {
    return [undefined, undefined];
  }
}

function onlyUniqueCasts(casts: Cast[]) {
  return casts.reduce((unique: Cast[], o: Cast) => {
    if (!unique.some((obj) => obj.castName === o.castName)) {
      unique.push(o);
    }
    return unique;
  }, []);
}

export function getAltiboxAssetPromotedSeasonIndex(
  altiboxAsset: AltiboxAsset,
  routeProps: DetailsRouteProps,
  promotedAsset?: UnionAssetTypes,
) {
  const { type } = altiboxAsset;
  let asset = altiboxAsset.asset as UnionAssetTypes;
  switch (type) {
    case AltiboxAssetDetailsType.SVOD:
    case AltiboxAssetDetailsType.VOD: {
      const { childId } = routeProps;
      asset = altiboxAsset.asset as VodAsset;
      let vodIdToPromote = childId;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let [foundVodEpisode, vodSeasonNumber] = findPromotedSeasonBasedOnEpisode(asset.seasons! as any, vodIdToPromote!);
      if (foundVodEpisode && isNumber(Number(vodSeasonNumber))) {
        return asset.seasons!.findIndex((season) => season.seasonNumber === vodSeasonNumber);
      } else {
        return 0;
      }
    }
    case AltiboxAssetDetailsType.PVR:
    case AltiboxAssetDetailsType.CATCHUP:
      if ((promotedAsset as Program | PvrRecording).seasonNum) {
        let seasonNumToFind = (promotedAsset as Program | PvrRecording).seasonNum;
        let foundSeasonNum = (asset.seasons! as AltiboxSeason[]).findIndex((s) => s.seasonNumber === seasonNumToFind);
        if (foundSeasonNum > -1) {
          return foundSeasonNum;
        }
      }
      const { childId } = routeProps;
      asset = altiboxAsset.asset as Program;
      let [foundProgramEpisode, programSeasonNumber] = findPromotedSeasonBasedOnEpisode(asset.seasons!, childId!);
      if (foundProgramEpisode && isNumber(Number(programSeasonNumber))) {
        return asset.seasons!.findIndex((season) => season.seasonNumber === programSeasonNumber);
      } else {
        return 0;
      }
    default:
      return 0;
  }
}

function getBasePath(type: AltiboxAssetDetailsType, svodKiosk?: PortalMenu) {
  switch (type) {
    case AltiboxAssetDetailsType.CATCHUP:
    case AltiboxAssetDetailsType.PROGRAM:
    case AltiboxAssetDetailsType.PVR:
    case AltiboxAssetDetailsType.VOD:
    case AltiboxAssetDetailsType.VIDEO_VOD:
    case AltiboxAssetDetailsType.SVOD:
      return routes.vod.base + routes.vod.playTrailer;
  }
}

export function getAltiboxAssetTrailerLink(altiboxAsset: AltiboxAsset) {
  const { type } = altiboxAsset;
  const base = getBasePath(type as AltiboxAssetDetailsType);
  if (altiboxAssetHasTrailer(altiboxAsset)) {
    return base + '/' + altiboxAsset.asset.foreignsn!;
  } else {
    return '/';
  }
}

export function altiboxAssetHasTrailer(altiboxAsset: AltiboxAsset) {
  const asset = altiboxAsset.asset as VodAsset;
  return asset.clipfiles && asset.clipfiles[0];
}

export function getAltiboxAssetSeasonEpisodeLink(
  altiboxAsset: AltiboxAsset,
  id: string,
  routeProps: DetailsRouteProps,
) {
  const { type, asset, meta } = altiboxAsset;
  switch (type) {
    case AltiboxAssetDetailsType.CATCHUP:
      const programAsset = asset as Program;
      const programBase = routes.programarchive.base + routes.programarchive.details + routes.programarchive.series;
      return programBase + '/' + programAsset.seriesID + '/' + id;
    case AltiboxAssetDetailsType.VOD:
    case AltiboxAssetDetailsType.VIDEO_VOD:
    case AltiboxAssetDetailsType.SVOD:
      let svodKioskAsList = [];
      let kiosk = undefined;
      let vodOrSvodBase = routes.vod.base + routes.vod.details;
      if (meta!.kiosk) {
        svodKioskAsList = [meta!.kiosk] as PortalMenu[];
        if (svodKioskAsList.length > 0) {
          kiosk = getSvodKiosk(asset as VodAsset, svodKioskAsList as PortalMenu[]);
        }
        if (kiosk) {
          vodOrSvodBase = routes.svod.base + '/' + getSvodUrl(kiosk) + routes.svod.details;
        }
      }
      return vodOrSvodBase + '/' + routeProps.parentId + '/' + id;
    case AltiboxAssetDetailsType.PVR:
      const pvrAsset = asset as PvrRecording;
      return routes.pvr.base + routes.pvr.series + '/' + pvrAsset.channelId + '/' + pvrAsset.seriesId + '/' + id;
    default:
      return '';
  }
}
export function getAltiboxAssetPlayLink(altiboxAsset: AltiboxAsset, promotedAsset: UnionAssetTypes) {
  const { type, meta } = altiboxAsset;
  const pathname = window.location.pathname;
  switch (type) {
    case AltiboxAssetDetailsType.CATCHUP:
    case AltiboxAssetDetailsType.PVR:
      const asset = promotedAsset as PvrRecording | Program;

      if (assetIsPvr(asset)) {
        let pvrBroadcastingNow = broadcastingNow({ starttime: asset.beginTime, endtime: asset.endTime });

        if (
          pvrBroadcastingNow &&
          pathname.includes(routes.tv.base) &&
          !pathname.includes(routes.tvguide.base) &&
          !pathname.includes(routes.tv.start)
        ) {
          return `${routes.tv.base}/${asset.channelId}${routes.tv.start}`;
        }

        if (pvrBroadcastingNow && pathname.includes(routes.tv.base) && pathname.includes(routes.tv.start)) {
          return `${routes.tv.base}/${asset.channelId}`;
        }

        if (asset.isSingle) {
          return routes.pvr.base + routes.pvr.single + routes.pvr.play + '/' + asset.channelId + '/' + asset.pvrId;
        }

        return (
          routes.pvr.base +
          routes.pvr.series +
          routes.pvr.play +
          '/' +
          asset.channelId +
          '/' +
          asset.seriesId +
          '/' +
          asset.pvrId
        );
      }

      if (broadcastingNow(asset)) {
        return getProgramURL(asset);
      }

      const programBase = routes.programarchive.base + routes.programarchive.play;
      return programBase + '/' + asset.id;
    case AltiboxAssetDetailsType.VOD:
    case AltiboxAssetDetailsType.VIDEO_VOD:
    case AltiboxAssetDetailsType.SVOD:
      const vodAsset = promotedAsset as VodAsset;
      let svodKioskAsList = [];
      let kiosk = meta!.kiosk;
      let vodOrSvodBase = routes.vod.base + routes.vod.play;
      if (kiosk) {
        svodKioskAsList = [meta!.kiosk] as PortalMenu[];
        if (svodKioskAsList.length > 0) {
          kiosk = getSvodKiosk(vodAsset, svodKioskAsList as PortalMenu[]);
        }
        if (kiosk) {
          vodOrSvodBase = routes.svod.base + '/' + getSvodUrl(kiosk) + routes.svod.play;
        }
      }
      return vodOrSvodBase + '/' + vodAsset.externalContentCode;
    default:
      return '';
  }
}

export function getAltiboxAssetPoster(altiboxAsset: AltiboxAsset) {
  if (altiboxAsset.type === AltiboxAssetDetailsType.VOD) {
    const asset = altiboxAsset.asset as VodAsset;
    if (hasPicture(asset.picture)) {
      return asset.picture.poster ? getPicture(asset.picture.poster) : undefined;
    } else {
      return undefined;
    }
  }
  return undefined;
}

export function getProgramCover(altiboxAsset: AltiboxAsset) {
  let asset = altiboxAsset.asset;
  if (!asset) {
    return undefined;
  }
  if (assetIsVod(asset) && asset.picture?.background) {
    return getPicture(asset.picture.background);
  }

  if (assetIsPvr(asset) && asset.pictures && asset.pictures[0]) {
    return getPicture(asset.pictures[0].href);
  }

  if (assetIsProgram(asset) && asset.picture.poster) {
    return getPicture(asset.picture.poster);
  }
}

function getFallbackPicture(seriesAsset: AltiboxAsset): string | undefined {
  switch (seriesAsset.type) {
    case AltiboxAssetDetailsType.PVR:
    case AltiboxAssetDetailsType.CATCHUP:
      return undefined;
  }
  const series = seriesAsset.asset as Program | VodAsset;
  return getPicture(series.picture.background) ? getPicture(series.picture.background) : undefined;
}

export function hasPicture(picture: Picture): boolean {
  return picture ? Object.keys(picture).length > 0 : false;
}

export function getPicture(csvPath: string): string | undefined {
  const imgUrlArray = csvPath ? csvPath.split(',') : [];
  if (imgUrlArray.length > 0) {
    return imgUrlArray.pop();
  } else {
    return undefined;
  }
}

export function getAltiboxAssetBookmark(promotedId: string, bookmarks: Bookmark[]) {
  return bookmarks.find((bookmark) => bookmark.contentId === promotedId);
}

function getSinglePercentTimeUnit(starttime: string, endtime: string) {
  const durationInSeconds = toMoment(endtime).diff(toMoment(starttime), 'seconds');
  return durationInSeconds / 100;
}

export function getAltiboxAssetPercentWatched(altiboxAsset: AltiboxAsset, bookmark: Bookmark | undefined) {
  let asset = { ...altiboxAsset.asset };
  let starttime = '';
  let endtime = '';
  let percent = 0;
  if (isPvr(asset)) {
    asset.type = AltiboxAssetDetailsType.PVR;
  }
  if (bookmark) {
    switch (asset.type) {
      case AltiboxAssetDetailsType.PROGRAM:
      case AltiboxAssetDetailsType.CATCHUP:
        asset = asset as unknown as Program;
        starttime = asset.starttime;
        endtime = asset.endtime;
        if (bookmark.rangeTime) {
          const timeUnit = getSinglePercentTimeUnit(starttime, endtime);
          percent = Number(bookmark.rangeTime) / timeUnit;
        }
        break;
      case AltiboxAssetDetailsType.VIDEO_VOD:
      case AltiboxAssetDetailsType.VOD:
      case AltiboxAssetDetailsType.SVOD:
        asset = asset as unknown as VodAsset;
        if (bookmark.rangeTime) {
          percent = getVodPercentWatched(altiboxAsset, bookmark.rangeTime);
        }
        break;
      case AltiboxAssetDetailsType.PVR:
        let pvrAsset = asset as unknown as PvrRecording;
        if (bookmark) {
          pvrAsset.bookmarkTime = bookmark.rangeTime;
        }
        percent = getPVRPercentWatched(pvrAsset);
        break;
      default:
    }
  }
  return percent;
}

const langMap = {
  nb: 'NO',
  no: 'NO',
  en: 'EN',
  da: 'DA',
};

export function getAltiboxAssetShortDescription(altiboxAsset: AltiboxAsset) {
  let language = langMap[i18n.language.split('-')[0]];
  language = language ? language : 'no';
  let vod = altiboxAsset.asset as VodAsset;
  const key = 'SYNOPSIS_SHORT_' + language.toUpperCase();
  const info = getVodInfo(key, vod);
  return info ? info : '';
}

export function getAltiboxAssetOriginalTitle(altiboxAsset: AltiboxAsset) {
  let title = getVodInfo('ORIGINAL_TITLE', altiboxAsset.asset as VodAsset);
  title = title ? title : altiboxAsset.asset.name;
  return title ? title : 'N/A';
}
export function getAltiboxAssetDistributor(altiboxAsset: AltiboxAsset) {
  const distributor = (altiboxAsset.asset as VodAsset).companyName;
  return distributor ? distributor : 'N/A';
}
function getAgeRating(altiboxAsset: AltiboxAsset) {
  const definedCountry = ScriptService.isDKUser() ? AgeRatingCountry.DK : AgeRatingCountry.NO;
  const { asset, type } = altiboxAsset;
  const pvrAsset = altiboxAsset.asset as PvrRecording;

  if (!asset) {
    return '0';
  }

  if (
    type.includes(AltiboxAssetDetailsType.PROGRAM) ||
    type.includes(AltiboxAssetDetailsType.PVR) ||
    type.includes(AltiboxAssetDetailsType.CATCHUP)
  ) {
    if (asset.extensionInfo) {
      return asset.extensionInfo.find((el) => el.key === definedCountry)?.value;
    } else if (pvrAsset.latestPVRTask && pvrAsset.latestPVRTask.extensionInfo) {
      return pvrAsset.latestPVRTask.extensionInfo?.find((el) => el.key === definedCountry)?.value || '0';
    }
  }

  return (altiboxAsset.asset as VodAsset).ratingid || '0';
}
export function getAltiboxAssetAgeRating(altiboxAsset: AltiboxAsset) {
  const ratingId = getAgeRating(altiboxAsset);
  if (ratingId && Number(ratingId) !== 0) {
    return ratingId;
  }
  return 'A';
}
export function getAltiboxAssetColorCategory(altiboxAsset: AltiboxAsset): string {
  let ageColorCategory: string = AgeLimitColorsGroup.WHITE;
  const ratingId = Number(getAgeRating(altiboxAsset));

  if ((ratingId >= 0 && ratingId <= 8) || getAgeRating(altiboxAsset) === 'A') {
    ageColorCategory = AgeLimitColorsGroup.GREEN;
  } else if (ratingId >= 9 && ratingId <= 14) {
    ageColorCategory = AgeLimitColorsGroup.YELLOW;
  } else if (ratingId >= 15 && ratingId <= 17) {
    ageColorCategory = AgeLimitColorsGroup.ORANGE;
  } else if (ratingId >= 18) {
    ageColorCategory = AgeLimitColorsGroup.RED;
  }

  return ageColorCategory;
}
export function getAltiboxAssetImdbScore(altiboxAsset: AltiboxAsset) {
  if (!altiboxAsset?.asset?.extensionInfo) {
    return undefined;
  }

  const score = altiboxAsset.asset.extensionInfo.find((obj: ExtensionInfo) => obj.key === 'IMDB_SCORE');
  const link = altiboxAsset.asset.extensionInfo.find((obj: ExtensionInfo) => obj.key === 'IMDB_LINK');

  if (!score || !link) {
    return undefined;
  }

  return {
    score: score.value.slice(0, 3),
    link: createExternalLink(link.value, {
      ref_: 'ref_ext_altibox',
    }),
  };
}
function createExternalLink(link: string, queryParams?: Record<string, string>) {
  if (link.includes('http://') || link.includes('https://')) {
    return link;
  }

  const params = decodeURIComponent(new URLSearchParams(queryParams).toString());

  return `https://${link}${params ? '?' + params : ''}`;
}

export function getAltiboxAssetRecordingAutoDeletion(promotedAsset: PvrRecording) {
  return Boolean(
    promotedAsset && promotedAsset.deleteMode && Number(promotedAsset.deleteMode) === RecordingDeleteMode.Manual,
  );
}
export function getAltiboxAssetAudioLanguages(altiboxAsset: AltiboxAsset, promotedAsset?: UnionAssetTypes) {
  const vodToUse = promotedAsset ? (promotedAsset as VodAsset) : (altiboxAsset.asset as VodAsset);
  const languages = getAvailableAudio(vodToUse);
  return languages ? languages : 'N/A';
}
export function getAltiboxAssetSubtitleLanguages(altiboxAsset: AltiboxAsset, promotedAsset?: UnionAssetTypes) {
  const vodToUse = promotedAsset ? (promotedAsset as VodAsset) : (altiboxAsset.asset as VodAsset);
  const languages = getAvailableSubtitles(vodToUse);
  return languages ? languages : 'N/A';
}

export function getAltiboxAssetSeriesTitle(altiboxAsset: AltiboxAsset, promotedAsset?: UnionAssetTypes) {
  const { asset } = altiboxAsset;

  if (altiboxAsset.type === AltiboxAssetDetailsType.PVR) {
    promotedAsset = promotedAsset as PvrRecording;
    return promotedAsset.latestPVRTask?.pvrName ?? promotedAsset.pvrName ?? '';
  }
  return asset.name ?? '';
}

export function getAltiboxAssetVideoFormats(altiboxAsset: AltiboxAsset, promotedAsset?: UnionAssetTypes) {
  const vodToUse = promotedAsset ? (promotedAsset as VodAsset) : (altiboxAsset.asset as VodAsset);
  const formats = getAvailableFormats(vodToUse);
  return formats.length > 0 ? formats.join(', ') : 'N/A';
}

export function getAltiboxAssetMedietilsynId(altiboxAsset: AltiboxAsset, promotedAsset?: UnionAssetTypes) {
  const vodToUse = promotedAsset ? (promotedAsset as VodAsset) : (altiboxAsset.asset as VodAsset);
  let id = getVodInfo('MEDIETILSYN_ID', vodToUse);
  return id && Number(id) !== 0 ? id : 'N/A';
}

function getVodPercentWatched(altiboxAsset: AltiboxAsset, rangeTime: string | number) {
  const durationInSeconds = getVodDuration(altiboxAsset.asset as VodAsset);
  if (durationInSeconds > 0) {
    return (Number(rangeTime) / durationInSeconds) * 100;
  } else {
    return 0;
  }
}

export function wrapAssetAsAltiboxAsset(asset: UnionAssetTypes) {
  return {
    asset: asset,
  } as AltiboxAsset;
}

export function checkIfUserHasSvodProduct(authState: AuthReducerState, kiosk: PortalMenu) {
  if (authState.productList && kiosk) {
    const identifier = kiosk.identifier ? kiosk.identifier.toUpperCase() : '';
    const productIdentifier = kiosk.productIdentifier ? kiosk.productIdentifier.toUpperCase() : '';
    const foundProduct = authState.productList.find((x) => {
      const introduce = x.introduce && x.introduce.toUpperCase();
      const comparisons = [identifier, productIdentifier, 'SVOD_' + identifier, 'SVOD_' + productIdentifier];
      return comparisons.indexOf(introduce) !== -1;
    });
    if (foundProduct) {
      return true;
    }

    // double check in T1
    let hasT1Support = false;
    const T1Product = getT1ProductName().toUpperCase();
    let T1 = authState.productList.find((x) => {
      const introduce = x.introduce && x.introduce.toUpperCase();
      return introduce === T1Product;
    });
    if (T1) {
      T1.package.priceobjects.forEach((priceObject) => {
        if (priceObject.contentid === kiosk.svodCategoryId) {
          hasT1Support = true;
        }
      });
    }
    return hasT1Support;
  }
  return false;
}

export function getProgramBroadcastingStatus(promotedAsset: UnionAssetTypes) {
  promotedAsset = promotedAsset as Program;
  let startTime = toMoment(promotedAsset.starttime);
  let endTime = toMoment(promotedAsset.endtime);
  if (broadcastingNow(promotedAsset)) {
    return BroadcastStatus.Live;
  }

  if (endTime && endTime.isBefore(moment())) {
    return BroadcastStatus.Finished;
  }

  if (startTime && startTime.isAfter(moment())) {
    return BroadcastStatus.Future;
  }

  return BroadcastStatus.Finished;
}

function getProgramURL(promotedAsset: UnionAssetTypes) {
  return `${routes.tv.base}/${(promotedAsset as Program).channelid}`;
}

export function getProgramStartURL(promotedAsset: UnionAssetTypes) {
  return getProgramURL(promotedAsset) + routes.tv.start;
}

export function getAltiboxAssetPromotedId(promotedAsset: UnionAssetTypes) {
  if (assetIsPvr(promotedAsset)) {
    return promotedAsset.lifetimeId || promotedAsset.pvrId;
  }

  if (assetIsProgram(promotedAsset)) {
    return promotedAsset.lifetimeId || promotedAsset.id;
  }

  return promotedAsset.id;
}

export function isEpisodeActive({ asset }: AltiboxAsset, promotedId: string) {
  return promotedId === getAltiboxAssetPromotedId(asset);
}

export function getEpisodeId(episode: AltiboxAsset, type?: AltiboxAssetDetailsType) {
  if (
    type === AltiboxAssetDetailsType.VOD ||
    type === AltiboxAssetDetailsType.VIDEO_VOD ||
    type === AltiboxAssetDetailsType.SVOD
  ) {
    return episode.asset.foreignsn!;
  }
  if (episode.asset.id) {
    return episode.asset.id;
  } else {
    return (episode.asset as PvrRecording).pvrId;
  }
}

export function isPvr(promotedAsset: UnionAssetTypes) {
  return promotedAsset.hasOwnProperty('pvrId') || promotedAsset.hasOwnProperty('periodPVRTaskId');
}

function isPvrPartOfSeries(recording: PvrRecording) {
  if (isPvr(recording)) {
    return recording.isSingle && recording.seriesId;
  }
  return false;
}

export function getProgramData(promotedAsset: UnionAssetTypes) {
  const isPartOfSeries = promotedAsset && !(promotedAsset as Program | PvrRecording).subNum ? false : true;
  if (isPvr(promotedAsset)) {
    let pvrAsset = promotedAsset as PvrRecording;
    return isPartOfSeries
      ? ({
          name: pvrAsset.pvrName,
          seasonNum: pvrAsset.seasonNum,
          episodeNum: pvrAsset.subNum,
          id: pvrAsset.programId,
          channelName: pvrAsset.channelName,
          lifetimeId: pvrAsset.lifetimeId,
          starttime: toMoment(pvrAsset.beginTime),
          endtime: toMoment(pvrAsset.endTime),
        } as ProgramToRecord)
      : {
          id: pvrAsset.programId,
          name: pvrAsset.pvrName,
          channelName: pvrAsset.channelName,
          lifetimeId: pvrAsset.lifetimeId,
          starttime: toMoment(pvrAsset.beginTime),
          endtime: toMoment(pvrAsset.endTime),
        };
  } else {
    let programAsset = promotedAsset as Program;
    return isPartOfSeries
      ? ({
          name: programAsset.name,
          seasonNum: programAsset.seasonNum,
          episodeNum: programAsset.subNum,
          id: programAsset.id,
          lifetimeId: programAsset.lifetimeId,
          starttime: toMoment(programAsset.starttime),
          endtime: toMoment(programAsset.endtime),
        } as ProgramToRecord)
      : {
          id: programAsset.id,
          name: programAsset.name,
          lifetimeId: programAsset.lifetimeId,
          starttime: toMoment(programAsset.starttime),
          endtime: toMoment(programAsset.endtime),
        };
  }
}

export function getSeriesId(promotedAsset: UnionAssetTypes) {
  if (isPvr(promotedAsset)) {
    return (promotedAsset as PvrRecording).seriesId;
  } else {
    return (promotedAsset as Program).seriesID;
  }
}

export function getChannelid(promotedAsset: UnionAssetTypes) {
  if (isPvr(promotedAsset)) {
    return (promotedAsset as PvrRecording).channelId;
  } else {
    return (promotedAsset as Program).channelid;
  }
}

export function getPromotedAssetId(promotedAsset: UnionAssetTypes) {
  if (isPvr(promotedAsset)) {
    return (promotedAsset as PvrRecording).pvrId;
  } else {
    return (promotedAsset as Program | VodAsset).id;
  }
}

export function findEpisodeBasedOnVodId(altiboxAsset: AltiboxAsset, id: string) {
  const asset = altiboxAsset.asset as VodAsset;
  let foundAsset = undefined;
  if (asset.seasons) {
    (asset.seasons as unknown as AltiboxSeason[]).forEach((season) => {
      season.content.forEach((episode) => {
        let epsAsset = episode.asset as VodAsset;
        if (epsAsset.id === id || epsAsset.foreignsn! === id) {
          foundAsset = epsAsset;
        }
      });
    });
  }
  return foundAsset;
}

export function isDisneyProgramAsset(altiboxAsset: AltiboxAsset): boolean {
  const { asset } = altiboxAsset;

  if (assetIsProgram(asset)) {
    return disneyChannels[ScriptService.getLocale()].some(
      (disneyChannel) => disneyChannel === asset.channel?.contentId,
    );
  }

  return false;
}

export function getEpisodeProgressValue(episode: AltiboxAsset, bookmarks: Bookmark[]) {
  if (!episode) {
    return 0;
  }

  let { asset } = episode;
  const idToUse = getPromotedAssetId(asset);
  const bookmark = getAltiboxAssetBookmark(idToUse, bookmarks);
  return getAltiboxAssetPercentWatched(wrapAssetAsAltiboxAsset(asset), bookmark!);
}

export function getShouldShowEpisodeProgress(
  authState: AuthReducerState,
  episode: AltiboxAsset,
  bookmarks: Bookmark[],
) {
  let shouldShow = false;
  if (authState.loggedInWithCredentials) {
    let value = getEpisodeProgressValue(episode, bookmarks);
    shouldShow = value >= 0.001;
  }
  return shouldShow;
}

export function canAssetBePurchased(altiboxAsset: AltiboxAsset) {
  const { meta } = altiboxAsset;
  return Boolean(meta?.purchaseOptions);
}

export function isSeriesRecording(recordings: Recordings, episode: Program) {
  return getIsRecordingSeries(recordings.series, episode.seriesID, episode.channel?.contentId);
}

export function isSingleRecording(recordings: Recordings, episode: Program) {
  return getIsRecordingProgram(recordings.single, episode.id);
}

export function getAdditionalInformation(
  episode: AltiboxAsset,
  isCatchupOrPvr: boolean,
): [info: string, className: string] | [null] {
  if (!isCatchupOrPvr) {
    return [null];
  }

  const asset = episode.asset as PvrRecording | Program;

  const [starttime, endtime] = assetIsPvr(asset) ? [asset.beginTime, asset.endTime] : [asset.starttime, asset.endtime];

  const status = broadcastStatus({ starttime, endtime });

  if (status === BroadcastStatus.Live) {
    return [i18n.t<string>('airing now')!, 'additional-information additional-information-live'];
  }

  let timePrefix = assetIsPvr(asset) ? i18n.t<string>('recorded') : status === BroadcastStatus.Finished;

  if (typeof timePrefix === 'boolean') {
    if (timePrefix) {
      timePrefix = i18n.t<string>('aired').toString();
    } else {
      timePrefix = i18n.t<string>('airs').toString();
    }
  }

  const time = toDetailsReadableDateString({
    huaweiDateTime: starttime,
    longFormat: i18n.t<string>('long date format details-plain-text'),
    longestFormat: i18n.t<string>('longest date format details'),
    shortFormat: i18n.t<string>('short date format details'),
    farInTheFutureFormat: i18n.t<string>('long date format details-plain-text'),
  });

  return [`${timePrefix} ${time}`, 'additional-information'];
}

export function getMostRecentBookmark(bookmarks: Bookmark[]) {
  return bookmarks.sort((a: Bookmark, b: Bookmark) => {
    const [aSeason, aEpisode] = [a.contentInfo?.seasonNum, a.contentInfo?.subNum];
    const [bSeason, bEpisode] = [b.contentInfo?.seasonNum, b.contentInfo?.subNum];
    return Number(bSeason) - Number(aSeason) && Number(bEpisode) - Number(aEpisode);
  })[0];
}
