import React from 'react';
import i18n from '../../i18n/index';
import FullscreenMessage from '../FullscreenMessage';

interface Props {
  clickCallback?: () => void;
}

class NoSupport extends React.Component<Props, {}> {
  clicked = () => {
    if (this.props.clickCallback) {
      this.props.clickCallback();
    }
  };

  render() {
    return (
      <FullscreenMessage>
        <h2>{i18n.t<string>('browser not supported')}</h2>
        <>
          {(
            i18n.t('browser not supported description', {
              returnObjects: true,
            }) as string[]
          ).map((singleText: string, index: number) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: singleText }} />
          ))}
        </>
      </FullscreenMessage>
    );
  }
}
export default NoSupport;
