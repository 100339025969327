import { Program, RootState, ThirdPartyCatchupData } from './../../interfaces';
import { useSelector } from 'react-redux';
import { useQuery, UseQueryOptions } from 'react-query';
import { getThirdPartyCatchupData } from '../../api/thirdPartyCatchup';
import { thirdPartyCatchupKeys } from './keys';
import { constructThirdPartyInformation } from '../../utils/thirdPartyCatchupUtils';

export function useThirdPartyCatchup<TData>(
  selector?: UseQueryOptions<ThirdPartyCatchupData[], Error, TData>['select'],
) {
  const auth = useSelector((state: RootState) => state.authReducer.auth);

  return useQuery<ThirdPartyCatchupData[], Error, TData>(
    thirdPartyCatchupKeys.auth(auth?.bossID, auth?.areaid),
    () => getThirdPartyCatchupData(auth?.bossID, auth?.areaid),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: auth !== undefined,
      select: selector,
    },
  );
}

export function useThirdPartyCatchupProgram(
  program: Pick<Program, 'channelid' | 'extensionInfo' | 'istvod' | 'lifetimeId'>,
) {
  return useThirdPartyCatchup((data) => {
    /** The specific third party catchup data for the passed program */
    const foundData = data.find((x) => x.channelIds.includes(program.channelid));

    return constructThirdPartyInformation(program, foundData);
  });
}
