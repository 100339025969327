import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import SearchBar from '../../../components/Search/SearchBar';
import { getStoredGuestLocation, routes, searchConfig, setStoredGuestLocation } from '../../../config';
import { ScriptService } from '../../../controllers/ScriptService';
import i18n from '../../../i18n';
import {
  ApiAuthResponse,
  DispatchResponse,
  PortalMenu,
  RootState,
  SearchConfig,
  SearchFunctions,
  SubnetIds,
} from '../../../interfaces';
import { showLoginModal } from '../actions';
import './style.scss';
import UserMenu from './UserMenu';
import { fetchSearchResults, emptySearch } from '../../globalsearch/actions';
import { goToLogin } from '../../../api/auth';
import { isBossIdSameAsLocation } from '../../../utils/appUtils';
import { debounce } from 'debounce';

import hamburgerIcon from '../../../images/hamburger.png';
import closeIcon from '../../../images/close.png';
import altiboxLogo from '../../../images/Altibox_logo_hvit.svg';
import noFlag from '../../../images/no.png';
import dkFlag from '../../../images/dk.png';
import { SvgMyContent } from '../../../icons';

interface State {
  showOptimizedMessage: boolean;
  openSearch: boolean;
  openSideMenu: boolean;
  viewPortWidth: number;
  hideSearchBar: boolean;
}

interface Props {
  focusSearch?: boolean;
  userEmail: string;
  auth: ApiAuthResponse;
  userFullName: string;
  loggedInWithCredentials: boolean;
  loggedInByIp: boolean;
  isGuest: boolean;
  isInternetOnlyAuthByIp: boolean;
  isInternetOnlyAuthByCreds: boolean;
  isInternetOnly: boolean;
  hasPvr: boolean;
  svodKiosks: PortalMenu[];
  defaultImagePath: string;
  cancelPotentialSearch?: Function;

  isSearching?: boolean;
  overPlayer?: boolean;
  searchQuery: string;
  searchConfig?: SearchConfig;

  showLoginModal: () => void;
  emptySearch: () => DispatchResponse;
  fetchSearchResults: (_: string) => Promise<void>;
}

class StickyHeader extends Component<Props & RouteComponentProps<{}>, State> {
  state: State = {
    showOptimizedMessage: true,
    openSearch: false,
    openSideMenu: false,
    viewPortWidth: window.innerWidth,
    hideSearchBar: true,
  };

  onResize = debounce(() => {
    if (this.state.viewPortWidth !== window.innerWidth) {
      this.updateViewPortWidth();
      if (!(this.state.hideSearchBar && this.shouldShowSearchDropdown)) {
        this.toggleSearchBar();
      }
    }
  }, 1000);

  get shouldShowSearchDropdown() {
    return this.state.viewPortWidth < 560 && !ScriptService._isIE();
  }

  get searchFunctions(): SearchFunctions {
    return {
      fetchSearchResults: this.props.fetchSearchResults,
      emptySearch: this.props.emptySearch,
    };
  }

  get config() {
    let config = this.props.searchConfig ? this.props.searchConfig : searchConfig.global;
    if (ScriptService.onMobile()) {
      config.noFocus = this.state.hideSearchBar;
    }
    return config;
  }

  componentDidMount() {
    this.setState({
      openSearch: true,
      hideSearchBar: !this.props.focusSearch,
    });
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  updateViewPortWidth() {
    this.setState({
      viewPortWidth: window.innerWidth,
    });
  }

  closeSearchBar = (event: MouseEvent) => {
    if ((event.target as HTMLElement).className.split(' ').indexOf('searchInput') === -1) {
      if (this.props.searchQuery === '') {
        this.setState(
          {
            openSearch: false,
          },
          () => {
            this.renderRightContent();
          },
        );
      }
    }
  };

  renderLoginButton = () => {
    return (
      <button
        className="loginButton"
        onClick={() => {
          goToLogin();
        }}
        style={this.props.loggedInByIp ? { width: '100%' } : {}}
      >
        {i18n.t<string>('log on')} {this.props.loggedInByIp ? i18n.t<string>('with password') : ''}
      </button>
    );
  };

  renderLogo() {
    return (
      <Link
        className="logo-link"
        to={{
          pathname: '/',
          state: { prevPath: window.location.pathname, toTop: true },
        }}
      >
        <img alt={i18n.t<string>('to the front page')} className="logo" src={altiboxLogo} />
      </Link>
    );
  }

  renderLinks() {
    return (
      <nav className="top">
        {this.renderLogo()}
        {this.renderTopLink('watch tv', routes.watchTv.base + routes.watchTv.live, 'sticky-high')}
        {this.renderTopLink('tv guide', routes.tvguide.base, 'sticky-medium priority')}
        {this.renderTopLink('programarchive', routes.programarchive.base, 'sticky-high')}
        {this.renderTopLink('movies and series', routes.vod.base, 'sticky-low priority')}
        {this.renderTopLink('streaming services', routes.svod.base, 'sticky-low priority')}
      </nav>
    );
  }

  renderTopLink(i18nKey: string, path: string, className: string) {
    return (
      <Link
        className={className + this.getSelectedClass(path)}
        to={{
          pathname: path,
          state: { prevPath: window.location.pathname, toTop: true },
        }}
      >
        <span>{i18n.t<string>(i18nKey)}</span>
      </Link>
    );
  }

  renderSideLink(i18nKey: string, icon: string | ReactNode, path: string) {
    return (
      <Link
        onClick={() => {
          this.toggleSideMenu();
        }}
        to={{
          pathname: path,
          state: { prevPath: window.location.pathname, toTop: true },
        }}
      >
        <span className="aib-icon">{icon}</span>
        <span>{i18n.t<string>(i18nKey)}</span>
      </Link>
    );
  }

  renderSideMenu() {
    const stickySideContainerClass = 'sticky-side-container sticky' + (ScriptService.onPhone() ? ' phone' : '');
    return (
      <div className={stickySideContainerClass}>
        <nav>
          {this.renderSideLink('home', 'J', '/')}
          {this.renderSideLink('watch tv', 'c', routes.watchTv.base + routes.watchTv.live)}
          {this.renderSideLink('tv guide', 'b', routes.tvguide.base)}
          {this.renderSideLink('programarchive', 'V', routes.programarchive.base)}
          {this.renderSideLink('movies and series', 'N', routes.vod.base)}
          {this.renderSideLink('streaming services', '¬', routes.svod.base)}
          <p>{i18n.t<string>('my content')}</p>
          {this.renderSideLink('recordings', '7', routes.pvr.base)}
          {this.renderSideLink('my content', <SvgMyContent className="svg-icon" />, routes.mycontent.base)}
        </nav>
      </div>
    );
  }

  renderCenterContent() {
    return <div className="header-center">{this.renderLinks()}</div>;
  }

  closeSearch = () => {
    this.setState({ openSearch: false });
  };

  toggleSideMenu() {
    this.setState({ openSideMenu: !this.state.openSideMenu });
  }

  returnToScrollPosition() {
    if (this.state.openSideMenu) {
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      if (scrollY) {
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
    }
  }

  renderLeftContent() {
    const toggleClass = 'sticky-side-toggle noselect ' + (this.state.openSideMenu ? 'open' : 'closed');
    if (ScriptService.onPhone()) {
      this.returnToScrollPosition();
    }
    return (
      <div className="header-left">
        <div
          className={toggleClass}
          onClick={() => {
            this.toggleSideMenu();
          }}
        >
          {this.state.openSideMenu ? (
            <img alt={i18n.t<string>('close side menu')} src={closeIcon} />
          ) : (
            <img alt={i18n.t<string>('open side menu')} src={hamburgerIcon} />
          )}
        </div>
        {this.renderLogo()}
        {this.state.openSideMenu ? this.renderSideMenu() : null}
      </div>
    );
  }

  renderSearchBar() {
    if (this.config) {
      return (
        <SearchBar
          hideContainer={this.state.hideSearchBar && this.shouldShowSearchDropdown}
          cancelPotentialSearch={this.props.cancelPotentialSearch!}
          searchQuery={this.props.searchQuery}
          closeSearch={this.closeSearch}
          searchFunctions={this.searchFunctions}
          {...this.config}
        />
      );
    } else {
      return null;
    }
  }

  renderSearchButton() {
    return (
      <div
        className="search-button-container"
        onClick={() => {
          this.toggleSearchBar();
        }}
      >
        <span className="searchIcon">E</span>
      </div>
    );
  }

  toggleSearchBar() {
    this.setState({
      hideSearchBar: !this.state.hideSearchBar,
      openSideMenu: false,
    });
  }

  getSelectedClass(path: string) {
    return (window.location.pathname + '/').indexOf(path + '/') !== -1 ? ' current' : '';
  }

  renderSearchElement() {
    if (!this.shouldShowSearchDropdown) {
      return this.renderSearchBar();
    } else {
      return this.renderSearchButton();
    }
  }

  renderRightContent() {
    return (
      <div className={'header-right'}>
        {this.renderSearchElement()}
        <nav className="top">
          {this.serviceLink(routes.pvr.base, '7', 'recordings')}
          {this.serviceLink(
            routes.mycontent.base,
            <SvgMyContent className="service-container__svg-icon" />,
            'my content',
          )}
          {this.props.loggedInWithCredentials || this.props.loggedInByIp || this.props.isInternetOnly ? (
            <div className="user-menu-container">
              <UserMenu {...this.props} renderLoginButton={this.renderLoginButton} />
            </div>
          ) : (
            this.renderLoginButton()
          )}
        </nav>
      </div>
    );
  }

  serviceLink = (routeBase: string, icon: string | ReactNode, label: string) => {
    return (
      <Link
        className={'icon-link sticky-high' + this.getSelectedClass(routeBase)}
        to={{
          pathname: routeBase,
          state: {
            prevPath: window.location.pathname,
            toTop: true,
          },
        }}
      >
        <div className="service-container">
          <span className="aib-icon">{icon}</span>
          <span>{i18n.t<string>(label)}</span>
        </div>
      </Link>
    );
  };

  toggleWarning = () => {
    this.setState({
      showOptimizedMessage: !this.state.showOptimizedMessage,
    });
  };

  setGuestCountry = (subnetId: number) => {
    setStoredGuestLocation(`${subnetId}`);
    window.location.reload();
  };

  shouldShowCountrySelector() {
    const showCountrySelector =
      ScriptService.isNETDomain() && this.props.isGuest && isBossIdSameAsLocation(this.props.auth);
    const guestLocation = getStoredGuestLocation();

    if (showCountrySelector && guestLocation && guestLocation === SubnetIds.NO) {
      return false;
    }

    return showCountrySelector;
  }

  render() {
    const { state } = this;
    const showNotOptimised = !ScriptService.isOptimizedForWeb() && state.showOptimizedMessage;
    const stickyHeaderClass = 'stickyheader sticky' + (ScriptService._isIE() ? ' IE' : '');
    const searchBarWrapperClass = 'searchbar-wrapper' + (this.state.hideSearchBar ? '' : ' show');
    return (
      <>
        {this.props.overPlayer ? null : <div className="sticky-buffer" />}
        <div className={stickyHeaderClass}>
          {this.renderLeftContent()}
          {this.renderCenterContent()}
          {this.renderRightContent()}

          {this.shouldShowSearchDropdown ? <div className={searchBarWrapperClass}>{this.renderSearchBar()}</div> : null}

          {this.shouldShowCountrySelector() ? (
            <div className={'country-selector' + (showNotOptimised ? ' move-down' : '')}>
              <p className="body">
                {i18n.t<string>('see content for')}
                <span
                  className="flag"
                  onClick={() => {
                    this.setGuestCountry(SubnetIds.DK);
                  }}
                >
                  <img src={dkFlag} alt="DK" />
                </span>
                <span
                  className="flag"
                  onClick={() => {
                    this.setGuestCountry(SubnetIds.NO);
                  }}
                >
                  <img src={noFlag} alt="NO" />
                </span>
              </p>
            </div>
          ) : null}

          {showNotOptimised ? (
            <div className="not-optimized-container">
              <p className="body">{i18n.t<string>('webpage not optimized')}</p>
              <div onClick={this.toggleWarning} className="aib-icon close-optimize">
                B
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => {
      return {
        auth: state.authReducer.auth as ApiAuthResponse,
        isInternetOnlyAuthByIp: state.authReducer.isInternetOnlyAuthByIp,
        isInternetOnlyAuthByCreds: state.authReducer.isInternetOnlyAuthByCreds,
        isInternetOnly: state.authReducer.isInternetOnly,
        loggedInByIp: state.authReducer.isHome && !state.authReducer.loggedInWithCredentials,
        loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
        isGuest: state.authReducer.isGuest,
        svodKiosks: state.app.svodKiosks.filter((kiosk) => (kiosk.devices ? kiosk.devices.web : true)),
        userEmail: state.authReducer.auth!.currentuser,
        userFullName: state.authReducer.userFullName,
        hasPvr: state.authReducer.hasPvr as boolean,
        defaultImagePath: state.app.defaultImagePath,
        searchQuery: state.searchReducer.searchQuery,
      };
    },
    (dispatch) => ({
      showLoginModal: () => dispatch(showLoginModal()),
      emptySearch: () => dispatch(emptySearch()),
      fetchSearchResults: (_: string) => dispatch(fetchSearchResults(_)),
    }),
  )(StickyHeader),
);
