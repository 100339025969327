import { status, json, fetchError, getDataFromResponse } from './Fetch';
import { getBaseUrl, getSessionTicket } from '../config';
import { Api, Bookmark, StreamType } from '../interfaces';
import { ScriptService } from './ScriptService';
import { getAllBookmarks } from '../queries/bookmarks/api';
import { bookmarkKeys } from './../queries/bookmarks/keys';
import { queryClient } from '../queries/client';

// save bookmark to backend
export async function addBookmark(contentId: string, playType: StreamType, currentPos: number) {
  let addBookmarkUrl = '/EPG/JSON/AddBookmark';
  let bookmarkType;

  switch (playType) {
    case StreamType.PVR:
      bookmarkType = 2;
      break;
    case StreamType.CATCHUP:
      bookmarkType = 3;
      break;
    default:
      bookmarkType = 0; // vod
  }

  try {
    // calling AddBookmark
    const res = await fetch(getBaseUrl() + addBookmarkUrl, {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        bookmarkList: [
          {
            bookmarkType: bookmarkType,
            contentId: contentId,
            rangeTime: Math.round(currentPos),
          },
        ],
      }),
    });

    const data = await getDataFromResponse<Api.RetResponse>(res);

    if (data.retcode && data.retcode !== '0') {
      throw data.retcode;
    }

    const allBookmarks = await getAllBookmarks(1);
    queryClient.setQueryData<Bookmark[]>(bookmarkKeys.all, (bookmarks) =>
      bookmarks ? [...bookmarks, ...allBookmarks.bookmarkList] : allBookmarks.bookmarkList,
    );

    return res;
  } catch (error) {
    fetchError(error as string);
  }
}

// PlayHeartbit for concurrency
export function doPlayHeartBit(contentId: string, playType: StreamType, mediaId: string) {
  let url = '/EPG/JSON/PlayHeartbit';
  let contentType;

  switch (playType) {
    case StreamType.PVR:
    case StreamType.LIVE:
      contentType = 'VIDEO_CHANNEL';
      break;
    case StreamType.CATCHUP:
      contentType = 'PROGRAM';
      break;
    default:
      // 'trailer' && 'vod'
      contentType = 'VIDEO_VOD';
  }

  // calling PlayHeartbit
  return fetch(getBaseUrl() + url, {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      contentId: contentId,
      contentType: contentType,
      mediaId: mediaId,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response) => {
      if ('false' === response.isValid) {
        return Promise.reject({
          response: response,
          success: false,
        });
      }
      return Promise.resolve(response);
    })
    .catch(fetchError);
}

export function doPlayRecord(assetIdentifier: string, playType: StreamType, start: boolean, mediaId?: string) {
  let url = '/EPG/JSON/PlayRecord';
  let recordtype = 0;
  let huaweiPlayType = 1;

  switch (playType) {
    case StreamType.CATCHUP:
      recordtype = start ? 4 : 5;
      huaweiPlayType = 4;
      break;
    case StreamType.PVR:
      recordtype = start ? 4 : 5;
      huaweiPlayType = 6;
      break;
    case StreamType.LIVE:
      recordtype = start ? 0 : 1;
      huaweiPlayType = 1;
      break;
    case StreamType.TRAILER:
      recordtype = start ? 4 : 5;
      huaweiPlayType = 3;
      break;
    default:
      // vod
      recordtype = start ? 4 : 5;
      huaweiPlayType = 3;
  }

  // calling PlayRecord
  return fetch(getBaseUrl() + url, {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      channel: assetIdentifier,
      playtype: huaweiPlayType,
      recordtype: recordtype,
      mediaId: mediaId,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response) => {
      if (response.retcode && response.retcode !== 0) {
        throw response.retcode;
      }
      return Promise.resolve(response);
    })
    .catch(fetchError);
}

const HLSNor = 'nor';
const HLSDan = 'dan';
const HLSEng = 'eng';
const unknownlang = 'unknown_lang';

const HLSLangMap = {
  no: HLSNor,
  nb: HLSNor,
  da: HLSDan,
  dk: HLSDan,
  en: HLSEng,
};

export function getShortLanguage(language: string) {
  if (ScriptService._isSafari()) {
    const langMap = Object.keys(HLSLangMap);
    const inHLSLangMap = langMap.indexOf(language) !== -1;
    if (inHLSLangMap) {
      // tslint:disable-next-line
      return HLSLangMap[language];
    }
    return language;
  }
  return language;
}

export function setDefaultAudioLanguage(language: string) {
  return localStorage.setItem('audio_default_language', language.toString());
}

export function getDefaultAudioLanguage() {
  const lang = localStorage.getItem('audio_default_language') || unknownlang;
  return getShortLanguage(lang.toString());
}

export function setDefaultSubtitleLanguage(language: string) {
  return localStorage.setItem('subtitle_default_language', language.toString());
}

export function getDefaultSubtitleLanguage() {
  const lang = localStorage.getItem('subtitle_default_language') || unknownlang;
  return lang.toString();
}

function getFallbackLanguage() {
  if (ScriptService.isNODomain()) {
    return 'no';
  } else if (ScriptService.isDKLanguage() || ScriptService.isDKUser()) {
    return 'da';
  } else if (ScriptService.isNOLanguage()) {
    return 'no';
  } else if (ScriptService.isENLanguage()) {
    return 'en';
  } else {
    return 'no';
  }
}

export function getFallbackSubtitleLanguage() {
  return getFallbackLanguage();
}

export function getFallbackAudioLanguage() {
  return getShortLanguage(getFallbackLanguage());
}
