import { status, json, fetchError } from '../controllers/Fetch';
import { getBaseUrl, getSessionTicket } from '../config';
import HuaweiError from '../controllers/HuaweiErrors';
import { getMediaId } from '../utils/tvUtils';
import {
  Channel,
  Program,
  SeriesRelation,
  HuaweiBatchResponse,
  PvrRecording,
  PvrStatus,
  Genre,
  PlayResult,
  GenreType,
  ChannelsRecordingsList,
  ChannelPermissions,
  Bookmarks,
  StreamData,
  ApiPhysicalChannel,
  ProgramArchiveBatchListResponse,
  ChannelLogoPicture,
} from '../interfaces';
import moment from 'moment';
import { huaweiDateTimeFormat } from '../config';
import toPathArray from 'object-to-string-path-array';
import { hasCatchupEnabled } from '../utils/catchupUtils';
import { isEmpty } from 'lodash';

interface AllChannelsResponse {
  channellist: ApiChannel[];
}

interface ApiChannel {
  contentId: string;
  name: string;
  chanNo: string;
  physicalChannels: ApiPhysicalChannel[];
  pictures: ApiPicture[];
  locationCopyrights: string[];
}

interface ApiPicture {
  href: string;
}

function allChannelsNew(channelNamespace: string) {
  return fetch(getBaseUrl() + '/EPG/JSON/AllChannel', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      domain: 0,
      channelNamespace: channelNamespace,
      metaDataVer: 'Channel/1.1',
      filterlist: [{ key: 'IsHide', value: '-1' }],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

// eslint-disable-next-line @typescript-eslint/dot-notation
window['allChannelsNew'] = allChannelsNew;

// channelNamespace = 0 = ott channels
// channelNamespace = 4000 = ott channels and iptv channels
export function allChannels(channelNamespace: string): Promise<string | Channel[]> {
  return (
    fetch(getBaseUrl() + '/EPG/JSON/AllChannel', {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify({
        domain: 0,
        channelNamespace: channelNamespace,
        metaDataVer: 'Channel/1.1',
        filterlist: [{ key: 'IsHide', value: '-1' }],
        properties: [
          {
            include: [
              '/channellist/logicalChannel/contentId',
              '/channellist/logicalChannel/name',
              '/channellist/logicalChannel/chanNo',
              '/channellist/logicalChannel/mediaid',
              '/channellist/logicalChannel/locationCopyrights',
              '/channellist/logicalChannel/pictures/picture/href',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/extensionInfo',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/mediaId',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/btvBR/is',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/btvBR/va',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/videoCodec',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/cutvCR',
              '/channellist/logicalChannel/physicalChannels/physicalChannel/npvrRecCR',
            ].join(),
          },
        ],
      }),
    })
      .then(status)
      .then((r) => json(r as Response))
      // todo this function is perfect for a util test in tvUtils
      .then((response: AllChannelsResponse) =>
        response.channellist.map((channel) => {
          const physicalChannel = channel.physicalChannels.length > 0 ? channel.physicalChannels[0] : undefined;
          const extensionInfo = physicalChannel ? physicalChannel.extensionInfo : undefined;
          const onlyInsideforLiveTV = extensionInfo
            ? extensionInfo.find((x) => x.key === 'onlyInsideforLiveTV')
            : undefined;
          const onlyInsideForCatchup = extensionInfo
            ? extensionInfo.find((x) => x.key === 'onlyInsideforCATCHUPTV')
            : undefined;
          const inSubscription = physicalChannel
            ? physicalChannel.btvBR.is === '1' && physicalChannel.btvBR.va === '1'
            : false;
          const hasEnabledCatchup =
            physicalChannel && physicalChannel.cutvCR ? hasCatchupEnabled(physicalChannel.cutvCR) : false;
          return {
            contentId: channel.contentId,
            name: channel.name,
            chanNo: channel.chanNo,
            pictures: channel.pictures as ChannelLogoPicture[],
            physicalChannel: physicalChannel,
            mediaId: getMediaId(channel.physicalChannels),
            channelPermissions: {
              onlyIptvChannel: extensionInfo ? false : true,
              onlyInsideforLiveTV: onlyInsideforLiveTV ? onlyInsideforLiveTV.value === '1' : true,
              onlyInsideForCatchup: onlyInsideForCatchup ? onlyInsideForCatchup.value === '1' : true,
              notInSubscription: !inSubscription,
              hasEnabledCatchup: hasEnabledCatchup,
              catchupTimeToLive: physicalChannel ? physicalChannel.cutvCR.l : undefined,
            } as ChannelPermissions,
            locationCopyrights: channel.locationCopyrights,
          };
        }),
      )
      .catch(fetchError)
  );
}

export enum ChannelType {
  Live = 0,
  Recorded = 1,
  LiveIncludingExpired = 2,
  Filecast = 3,
}

function _playbillContext(
  channelId: string,
  date: moment.Moment,
  before: number,
  after: number,
  channelType: ChannelType = ChannelType.LiveIncludingExpired,
): Promise<string | Program[]> {
  const includePlaybill = {
    id: [],
    extensionInfo: [],
    name: [],
    channelid: [],
    starttime: [],
    endtime: [],
    recordedMediaIds: [],
    picture: ['deflate', 'poster'],
    seasonNum: [],
    subNum: [],
    genres: [],
    introduce: [],
    seriesID: [],
  };
  const include = toPathArray({
    current: includePlaybill,
    nextList: { playbill: includePlaybill },
    preList: { playbill: includePlaybill },
  }).join();

  return fetch(getBaseUrl() + '/EPG/JSON/PlayBillContextEx', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      channelid: channelId,
      date: date.format(huaweiDateTimeFormat),
      type: channelType,
      preNumber: before,
      nextNumber: after,
      properties: [{ include }],
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response) => {
      const past = (response.preList || []) as Program[];
      const current = response.current as Program;
      const future = (response.nextList || []) as Program[];
      return [...past, current, ...future];
    })
    .catch(fetchError);
}

export function playbillContext(program: Program, before: number = 0, after: number = 0): Promise<string | Program[]> {
  return _playbillContext(program.channelid, moment(program.starttime, huaweiDateTimeFormat), before, after);
}

function endTime(date: moment.Moment) {
  return date.add(1, 'days');
}

function beginTime(date: Date) {
  return moment(date).startOf('day').add(5, 'hours');
}

export function playbillPerDay(program: Program, date: Date): Promise<string | Program[]> {
  const beginTimeDate = beginTime(date);
  return fetch(getBaseUrl() + '/EPG/JSON/PlayBillList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      channelid: program.channelid,
      begintime: beginTimeDate.format(huaweiDateTimeFormat),
      endtime: endTime(beginTimeDate).format(huaweiDateTimeFormat),
      type: 2,
      offset: 0,
      count: -1,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: ProgramContentDetailResponse) => {
      return response.playbilllist;
    })
    .catch(fetchError);
}

export function tvGuidePerDay(channeIds: string[], date: Date): Promise<string | Array<Program[]>> {
  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: channeIds.map((id) => {
        return {
          name: 'PlayBillList',
          param: {
            channelid: `${id}`,
            begintime: beginTime(date).format(huaweiDateTimeFormat),
            endtime: endTime(beginTime(date)).format(huaweiDateTimeFormat),
            type: 2,
            offset: 0,
            count: -1,
          },
        };
      }),
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((responses: ProgramArchiveBatchListResponse) => {
      return responses.responseList.map((r) => r.msg).map((r) => (r.playbilllist ? r.playbilllist : r.playbillList));
    })
    .catch(fetchError);
}

interface PlayBillContextEx {
  msg: {
    current: {
      channelid: string;
    };
    preList: Program[];
    nextList: Program[];
  };
}

export function getAllPlaybills(
  channelIds: string[],
  preNumber: Number = 2,
  nextNumber: Number = 2,
  date: Date = new Date(),
): Promise<Record<string, Program[]>> {
  if (isEmpty(channelIds)) {
    return Promise.resolve({ responseList: [] });
  }
  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: channelIds.map((id) => {
        return {
          name: 'PlayBillContextEx',
          param: {
            channelid: `${id}`,
            date: moment(date).format(huaweiDateTimeFormat),
            type: 2,
            preNumber: preNumber,
            nextNumber: nextNumber,
          },
        };
      }),
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((responses) => {
      return responses.responseList.reduce((result: Program[], response: PlayBillContextEx) => {
        const data = response.msg;
        const channelId = data.current ? data.current.channelid : 'null';
        const playbill = (data.current ? [data.current] : []).concat(data.preList || []).concat(data.nextList || []);

        result[channelId] = playbill;
        return result;
      }, {});
    })
    .catch(fetchError);
}

interface QueryPvrResponse {
  pvrlist: PvrRecording[];
}
enum PvrExpandSubTask {
  OnlyParentTasks = 0,
  OnlySubTasks = 1,
  SubTasksAndParentTasks = 2,
}

export function allRecordings(statuses?: PvrStatus[]): Promise<string | ChannelsRecordingsList | void> {
  return fetch(getBaseUrl() + '/EPG/JSON/QueryPVR', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      count: -1,
      expandSubTask: PvrExpandSubTask.SubTasksAndParentTasks,
      isFilter: 0,
      offset: 0,
      status: statuses ? statuses.join(',') : undefined,
      type: '1',
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: QueryPvrResponse) => {
      return splitRecordingsByType(response.pvrlist);
    })
    .catch(fetchError);
}

export function splitRecordingsByType(recordings: PvrRecording[]) {
  return {
    singleRecordings: recordings.filter((x) => x.pvrId !== undefined).map((x) => x as PvrRecording),
    seriesRecordings: recordings
      .filter((x) => x.periodPVRTaskId !== undefined)
      .map((x) => {
        return {
          ...x,
          subPvrNum: x.subPvrNum,
          canceled: x.overtime !== undefined,
        };
      })
      .map((x) => x as PvrRecording),
  } as ChannelsRecordingsList;
}

interface ApiSeriesRelation {
  channelID: string;
  lifetimeID: string;
  name: string;
  programID: string;
  seasonNum: string;
  seriesID: string;
  starttime: string;
  subNum: string;
}
interface QuerySeriesRelationResponse {
  seriesRelations: ApiSeriesRelation[];
}
interface BatchSeriesRelationsResponse
  extends HuaweiBatchResponse<'QuerySeriesRelation', QuerySeriesRelationResponse> {}
export function seriesRelations(seriesIds: string[]): Promise<SeriesRelation[]> {
  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: seriesIds.map((seriesID) => {
        return {
          name: 'QuerySeriesRelation',
          param: {
            seriesID,
          },
        };
      }),
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: BatchSeriesRelationsResponse) =>
      response.responseList
        .map((x) => x.msg.seriesRelations || [])
        .reduce((result, next) => [...result, ...next], [])
        .map((msg) => ({
          channelId: msg.channelID,
          programId: msg.programID,
          seriesId: msg.seriesID,
        })),
    )
    .catch(fetchError);
}

interface ProgramContentDetailResponse {
  playbilllist: Program[];
}
export function programDetail(programId: string): Promise<string | Program | undefined> {
  return fetch(getBaseUrl() + '/EPG/JSON/ContentDetail', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      playbill: programId,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: ProgramContentDetailResponse) => (response.playbilllist || []).find((_) => true))
    .catch(fetchError);
}

export function getCatchupDetailsFromBookmarks(bookmarks: Bookmarks) {
  if (isEmpty(bookmarks) || isEmpty(bookmarks.bookmarkList)) {
    return Promise.resolve([]);
  }
  return fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: bookmarks.bookmarkList.map((bookmark) => {
        return {
          name: 'ContentDetail',
          param: {
            playbill: bookmark.contentId,
          },
        };
      }),
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: HuaweiBatchResponse<'ContentDetail', ProgramContentDetailResponse>) => {
      return response.responseList
        .map((r) => r.msg)
        .filter((r) => r.playbilllist)
        .map((details) => details.playbilllist[0]);
    });
}

interface GetGenreResponse {
  retcode: number;
  retmsg: string;
  genres: Genre[];
}
export function getGenres(genreType?: GenreType, genreIds?: string[]): Promise<string | Genre[]> {
  return fetch(getBaseUrl() + '/EPG/JSON/GetGenreList', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      genreType,
      genreIds,
    }),
  })
    .then(status)
    .then((r) => json(r as Response))
    .then((response: GetGenreResponse) => response.genres)
    .catch(fetchError);
}

interface AuthParams {
  businesstype: number;
  profileId: number;
  mediaId?: string | undefined;
  contentid: string;
  contenttype: string;
}

interface PlayParams {
  contentid: string;
  mediaid?: string | undefined;
  playbillid?: string;
  playtype: number;
  profileId: number;
  subscriptionKey?: string;
}

interface AuthorizationResponse {
  subscriptionKey: string;
  retcode: string;
}

function getAuthorizeAndPlay(auth: AuthParams, play: PlayParams) {
  const authorizationUrl = '/EPG/JSON/Authorization';
  const playUrl = '/EPG/JSON/Play';

  // Authorization CALL
  return (
    fetch(getBaseUrl() + authorizationUrl, {
      method: 'POST',
      headers: { SessionTicket: getSessionTicket() },
      body: JSON.stringify(auth),
    })
      .then(status)
      .then((r) => json(r as Response))
      .then(function (data: AuthorizationResponse) {
        if (data.retcode === HuaweiError.NO_ACCESS) {
          throw data;
        }
        return data.subscriptionKey;
      })
      // Play CALL
      .then(function (subscriptionKey: string) {
        play.subscriptionKey = subscriptionKey;

        // devops hack - until we implement mediaId selection of channels
        // one channel can have two mediaIds but only one contentId
        var theSpecialMediaIdSetInLocalStorage = localStorage.getItem('media_overload_id');
        if (theSpecialMediaIdSetInLocalStorage) {
          play.mediaid = theSpecialMediaIdSetInLocalStorage;
        }

        return fetch(getBaseUrl() + playUrl, {
          method: 'POST',
          headers: { SessionTicket: getSessionTicket() },
          body: JSON.stringify(play),
        })
          .then(status)
          .then((r) => json(r as Response))
          .then(function (data: PlayResult) {
            if (data.retcode === HuaweiError.MAX_PLAYBACK_SESSIONS) {
              return Promise.reject({
                retcode: data.retcode,
                message: data.retmsg,
              });
            }
            const triggerData = data.triggers ? data.triggers[0].getLicenseTrigger : { customData: '', licenseURL: '' };

            return Promise.resolve({
              customData: triggerData.customData,
              licenseUrl: triggerData.licenseURL,
              manifestUrl: data.url,
            } as StreamData);
          });
      })
  );
}

export function getChannelLivePlayData(channelId: string, mediaId?: string) {
  return getAuthorizeAndPlay(
    {
      businesstype: 2, // 2 = live tv
      profileId: -1,
      contentid: channelId,
      contenttype: 'VIDEO_CHANNEL',
    },
    {
      contentid: channelId,
      mediaid: mediaId,
      playtype: 2,
      profileId: -1,
    },
  );
}

export function getProgramCatchupPlayData(program: Program, channelMediaId?: string) {
  let mediaId = channelMediaId ? channelMediaId : program.recordedMediaIds ? program.recordedMediaIds[0] : undefined;
  let playbillId = program.id;

  return getAuthorizeAndPlay(
    {
      businesstype: 5, // 5 = Catch-up TV
      profileId: -1,
      mediaId: mediaId,
      contentid: playbillId,
      contenttype: 'PROGRAM',
    },
    {
      contentid: program.channelid,
      mediaid: mediaId,
      playbillid: playbillId,
      playtype: 4, // 4 = Catch-up TV
      profileId: -1,
    },
  );
}
