import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Footer from '../../components/Footer';
import { routes, searchConfig as globalSearchConfig } from '../../config';
import { PvrProvider } from '../../context/PvrContext';
import {
  AltiboxAssetDetailsType,
  GAaction,
  GAlabel,
  HeaderDisplayType,
  HeaderInteractionType,
  RootState,
  SearchConfig,
} from '../../interfaces';
import Header from '../app/Header';
import StickyHeader from '../app/StickyHeader';
import Details from '../details';
import Search from '../globalsearch';
import PvrPlayer from '../pvr/PvrPlayer';
import { getChannelsIncludingIPTV, getGenres } from '../tv/actions';
import PvrDeleteSeries from './PvrDeleteSeries';
import PvrDeleteSeasons from './PvrDeleteSeasons';
import PvrFrontPage from './PvrFrontPage';
import PvrCollection from './PvrCollection';
import PvrScheduled from './PvrScheduled';
import PvrDeleteEpisodes from './PvrDeleteEpisodes';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../hooks/useTracking';
import { fetchAllScheduledRecordings, fetchAllRecordings } from '../pvr/actions';

interface PvrProps {
  searchConfig: SearchConfig;
}

export default function PvrViews({ searchConfig }: PvrProps) {
  const dispatch = useDispatch();

  const isGuest = useSelector((state: RootState) => state.authReducer.isGuest);
  const hasPvr = useSelector((state: RootState) => state.authReducer.hasPvr);

  const match = useRouteMatch();

  const [defaultSearchConfig] = useState(searchConfig ?? globalSearchConfig.pvr);

  const { trackEvent } = useTracking();
  const { t } = useTranslation();

  /**
   * Remove this once details panel is updated to use React Query
   */
  const loadContent = useCallback(() => {
    dispatch(fetchAllScheduledRecordings());
    dispatch(getGenres());
    dispatch(fetchAllRecordings());
    dispatch(getChannelsIncludingIPTV());
  }, [dispatch]);

  useEffect(() => {
    if (!isGuest && hasPvr) {
      loadContent();
    }

    if (!hasPvr) {
      trackEvent({
        action: GAaction.notification,
        label: GAlabel.noPVRAccess,
      });
    }
  }, [dispatch, hasPvr, isGuest, loadContent, trackEvent]);

  if (isGuest) {
    return (
      <div>
        <StickyHeader searchConfig={globalSearchConfig.pvr} />
        <Header
          displayType={HeaderDisplayType.Solid}
          title={t('recordings')}
          interactionType={HeaderInteractionType.Full}
        />
        <h2 className="h2-login-needed">{t('log in to see recordings')}</h2>
        <Footer />
      </div>
    );
  }

  return (
    <Switch>
      <Route
        path={match.path + routes.search.base + '*'}
        render={() => <Search searchConfig={defaultSearchConfig} />}
      />

      <Route exact={true} path={match.path + '/serie'} render={() => <Redirect to={match.path} />} />
      <Route
        exact={true}
        path={match.path + '/serie/play/:channelId/:seriesId/:pvrId'}
        render={() => <PvrPlayer searchConfig={defaultSearchConfig} />}
      />
      <Route exact={true} path={match.path + '/enkelt'} render={() => <Redirect to={match.path} />} />
      <Route
        exact={true}
        path={match.path + '/serie/:channelId/:parentId/:childId'}
        render={() => <Details displayType={AltiboxAssetDetailsType.PVR} />}
      />
      <Route
        exact={true}
        path={match.path + '/serie/:channelId/:parentId'}
        render={() => <Details displayType={AltiboxAssetDetailsType.PVR} />}
      />
      <Route
        exact={true}
        path={match.path + '/enkelt/:childId'}
        render={() => <Details displayType={AltiboxAssetDetailsType.PVR} />}
      />
      <Route
        exact={true}
        path={match.path + '/enkelt/play/:channelId/:pvrId'}
        render={() => <PvrPlayer searchConfig={defaultSearchConfig} />}
      />

      <PvrProvider searchConfig={defaultSearchConfig}>
        <Switch>
          <Route exact={true} path={match.path} render={() => <PvrFrontPage />} />
          <Route exact={true} path={match.path + routes.pvr.scheduled} render={() => <PvrScheduled />} />
          <Route exact={true} path={match.path + routes.pvr.delete} render={() => <PvrDeleteSeries />} />
          <Route exact={true} path={match.path + routes.pvr.delete + '/:id'} render={() => <PvrDeleteSeasons />} />
          <Route
            exact={true}
            path={match.path + routes.pvr.delete + '/:id/:seasonNum'}
            render={() => <PvrDeleteEpisodes />}
          />
          <Route exact={true} path={match.path + '/:genreSlug'} render={() => <PvrCollection />} />
        </Switch>
      </PvrProvider>
    </Switch>
  );
}
