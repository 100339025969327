import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApiAuthResponse, RootState, AltiboxApiLinkResponse } from '../../interfaces';

import './style.scss';

import { showLoginModal } from '../app/actions';
import { getAltiboxApiUrl } from '../../config';
import { json, aib400Status } from '../../controllers/Fetch';
import i18n from 'i18next';
import { logout } from '../../api/auth';
import altiboxLogo from '../../images/Altibox_logo_hvit.svg';

interface Props {
  auth: ApiAuthResponse | undefined;
  loggedInWithCredentials: boolean;
  userEmail: string;
  showLoginModal: () => void;
}

interface State {
  hasClickedLink: boolean;
  hasLinkedSuccess: boolean;
  messageKey: string;
  code: string;
}

class LinkTvBox extends Component<Props & RouteComponentProps<{}>, State> {
  state: State = {
    hasClickedLink: false,
    hasLinkedSuccess: false,
    messageKey: '',
    code: '',
  };

  // Strict Class Initialization ignored
  private inputCode!: HTMLInputElement | null;

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    if (code) {
      let sixFirstInCode = code.substring(0, 6);
      if (sixFirstInCode.length === 6 && Number(sixFirstInCode)) {
        this.setState({ code: sixFirstInCode });
      }
    }
  }

  linkAccount() {
    this.setState({
      hasClickedLink: true,
    });

    const sessionTicket = localStorage.getItem('sessionticket') || '';
    const userId = this.props.auth ? this.props.auth.userID : 0;
    const userCode = this.inputCode!.value;

    fetch(getAltiboxApiUrl() + 'authentication/oauth/device/activate', {
      method: 'POST',
      headers: {
        SessionTicket: sessionTicket,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userCode: userCode,
        userId: userId,
        userType: 'IPTVSUB',
      }),
    })
      .then(aib400Status)
      .then((r) => json(r as Response))
      .then((response: AltiboxApiLinkResponse) => {
        if (response.status === 'notAuthenticated') {
          this.setState({
            hasClickedLink: false,
            messageKey: 'notAuthenticated',
          });
          this.props.showLoginModal();
        } else if (response.status === 'failure') {
          if (response.message === 'invalid_user_code') {
            this.setState({
              hasClickedLink: false,
              messageKey: response.message,
            });
          } else {
            this.setState({
              hasClickedLink: false,
              messageKey: 'an_error_occured',
            });
          }
        } else {
          setTimeout(() => {
            this.setState({
              hasLinkedSuccess: true,
            });
          }, 2000);
        }
      });
  }

  render() {
    if (this.props.loggedInWithCredentials && !this.state.hasClickedLink) {
      return (
        <div className="main-frame linktvbox-container">
          <a className="logo-link" href="/">
            <img className="logo" alt={i18n.t<string>('to the front page')} src={altiboxLogo} />
          </a>
          <div className="linktvbox-frame">
            <h3>{i18n.t<string>('link_account')}</h3>
            <p>{i18n.t<string>('enter_linking_code')}</p>
            <input
              autoFocus={true}
              tabIndex={-1}
              maxLength={6}
              ref={(element) => (this.inputCode = element)}
              defaultValue={this.state.code}
              type="number"
            />
            <br />
            {this.state.messageKey ? <p className="status-message">{i18n.t<string>(this.state.messageKey)}</p> : null}
            <button
              className="link-button"
              onClick={() => {
                this.linkAccount();
              }}
            >
              LINK
            </button>
          </div>
          <p>
            {i18n.t<string>('logged_in_as')} {this.props.userEmail}
          </p>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="logoutlink"
            onClick={() => {
              logout();
            }}
          >
            {i18n.t<string>('sign_in_as_different_user')}
          </a>
        </div>
      );
    } else if (this.state.hasClickedLink && !this.state.hasLinkedSuccess) {
      return (
        <div className="main-frame linktvbox-container">
          <a className="logo-link" href="/">
            <img className="logo" alt={i18n.t<string>('to the front page')} src={altiboxLogo} />
          </a>
          <div className="linktvbox-frame">
            <h2>Working</h2>
            <br />
            <div className={'circle-loader' + (this.state.hasLinkedSuccess ? ' load-complete' : '')}>
              <div className={'checkmark draw' + (this.state.hasLinkedSuccess ? ' checkmark-show' : '')} />
            </div>
          </div>
        </div>
      );
    } else if (this.state.hasClickedLink) {
      return (
        <div className="main-frame linktvbox-container">
          <a className="logo-link" href="/">
            <img className="logo" alt={i18n.t<string>('to the front page')} src={altiboxLogo} />
          </a>
          <div className="linktvbox-frame">
            <h2>{i18n.t<string>('account_has_been_linked')}</h2>
            <p>
              {this.props.userEmail} {i18n.t<string>('account_has_been_linked_text')}
            </p>
            <br />
            <br />
            <div className={'circle-loader' + (this.state.hasLinkedSuccess ? ' load-complete' : '')}>
              <div className={'checkmark draw' + (this.state.hasLinkedSuccess ? ' checkmark-show' : '')} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="main-frame linktvbox-container">
          <a className="logo-link" href="/">
            <img className="logo" alt={i18n.t<string>('to the front page')} src={altiboxLogo} />
          </a>
          <div className="linktvbox-frame">
            <h2>{i18n.t<string>('link_account')}</h2>
            <p>{i18n.t<string>('login_to_link')}</p>
            <button
              className="link-button"
              onClick={() => {
                this.props.showLoginModal();
              }}
            >
              {i18n.t<string>('click_to_login')}
            </button>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      auth: state.authReducer.auth,
      userEmail: state.authReducer.auth!.currentuser,
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
    }),
    (dispatch) => ({
      showLoginModal: () => dispatch(showLoginModal()),
    }),
  )(LinkTvBox),
);
