import { SearchReducerState } from '../../../interfaces';

import { TypeKeys, ActionTypes } from '../actions';

export function searchReducer(
  state: SearchReducerState = {
    searchQuery: '',
    initialSearchQuery: '',
    searchResults: undefined,
    searchCategories: undefined,
  },
  action: ActionTypes,
): SearchReducerState {
  switch (action.type) {
    case TypeKeys.SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.searchResults,
        initialSearchQuery: action.initialSearchQuery,
      };
    case TypeKeys.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery,
      };
    case TypeKeys.CLEAR_SEARCH:
      return {
        ...state,
        searchResults: undefined,
        searchQuery: '',
      };

    case TypeKeys.SET_SEARCH_CATEGORIES:
      return {
        ...state,
        searchCategories: {
          ...action.searchCategories,
        },
      };
    default:
      return state;
  }
}
