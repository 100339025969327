import React, { Component } from 'react';
import {
  AltiboxAsset,
  AltiboxSeason,
  AltiboxAssetButtonType,
  AltiboxAssetDetailsType,
  BroadcastStatus,
  GAaction,
  Program,
  PvrRecording,
  VodAsset,
  UnionAssetTypes,
} from '../../../../interfaces';
import {
  getAltiboxAssetTitle,
  getAltiboxAssetCover,
  getAltiboxAssetTitleSuffix,
  getAltiboxAssetCaption,
  getAltiboxAssetPoster,
  getAltiboxAssetShortDescription,
  getAltiboxAssetHeroDescription,
  getAltiboxAssetExpiration,
  getAltiboxAssetAdditionalThumbnail,
  getAltiboxAssetColorCategory,
  getAltiboxAssetImdbScore,
  getAltiboxAssetAgeRating,
  getAltiboxAssetRecordingAutoDeletion,
} from '../../../../utils/altiboxassetUtils';
import AltiboxAssetContext from '../../../../views/details/AltiboxAssetContext';
import './style.scss';
import AltiboxAssetButtonRow from '../AltiboxAssetButtonRow';
import i18n from '../../../../i18n';
import { getPVRAsset } from '../../../../utils/pvrUtils';
import SharedAssets from '../SharedAssets';

import missingTvPlaceholder from './tv-placeholder.png';
import missingVODPlaceholder from './vod-placeholder.jpeg';
import missingVODPoster from '../../../VodAsset/missing-vod-cover.png';
import Badge from '../../../Badge';
import {
  assetIsRented,
  getRentBadgeTextWithInformation,
  assetShouldPrioritizeBadge,
  Owned,
  getLowestRentedSeriesCountdown,
} from '../../../../utils/vodUtils';
import ExpandableText from '../../ExpandableText';
import AltiboxAssetButton from '../AltiboxAssetButtonRow/AltiboxAssetButton';
import { SvgFilm, SvgImdb, SvgPin } from '../../../../icons';
import { getCatchupAsset, nonCULiveProgramExpiresIn } from '../../../../utils/programArchiveUtils';
import { broadcastEnded, broadcastStatus, toDetailsReadableDateString } from '../../../../utils/huaweiUtils';
import { assetIsProgram, assetIsPvr } from '../../../../typeGuards';
import { catchupAvailable, isSupportingCatchup } from '../../../../utils/tvUtils';
import { ButtonClose } from '../../Buttons';
import AnalyticsTracking from '../../../../controllers/AnalyticsTracking';

type State = {
  placeholderCover: string;
  placeholderPoster: string;
  forcePlaceholder: boolean;
  cover: string | undefined;
  showMore: boolean;
};

export default class AltiboxAssetHero extends Component<{}, State> {
  static contextType = AltiboxAssetContext;
  context!: React.ContextType<typeof AltiboxAssetContext>;
  tracking = AnalyticsTracking.getInstance();

  closeButton: HTMLButtonElement | HTMLAnchorElement | null = null;

  state: State = {
    placeholderCover: '',
    forcePlaceholder: false,
    placeholderPoster: missingVODPoster,
    cover: '',
    showMore: false,
  };

  get promotedAsset() {
    return this.context.promotedAsset;
  }

  get cover() {
    const { placeholderCover, forcePlaceholder } = this.state;
    const { altiboxAsset } = this.context;
    if (forcePlaceholder) {
      return placeholderCover;
    }
    const assetCover = getAltiboxAssetCover(altiboxAsset, this.promotedAsset);
    return assetCover ? assetCover : placeholderCover;
  }

  get assetHasExpired() {
    const { promotedAsset } = this.context;

    if (!assetIsProgram(promotedAsset)) {
      return false;
    }

    return broadcastEnded(promotedAsset) && !catchupAvailable(promotedAsset);
  }

  get infoTile() {
    const { altiboxAsset, promotedAsset } = this.context;
    const { type } = altiboxAsset;

    switch (type) {
      case AltiboxAssetDetailsType.CATCHUP:
      case AltiboxAssetDetailsType.PROGRAM:
      case AltiboxAssetDetailsType.PVR:
        if (!assetIsProgram(promotedAsset)) {
          return undefined;
        }

        if (this.assetHasExpired) {
          return i18n.t<string>('expired');
        }

        switch (broadcastStatus(promotedAsset)) {
          case BroadcastStatus.Finished:
            return i18n.t<string>('expires in no colon') + ' ' + getAltiboxAssetExpiration(promotedAsset);
          case BroadcastStatus.Live:
            if (!catchupAvailable(promotedAsset)) {
              return (
                i18n.t<string>('live expires in') +
                ' ' +
                nonCULiveProgramExpiresIn(promotedAsset) +
                ' ' +
                i18n.t<string>('minutes no abrv')
              );
            }
            return i18n.t<string>('expires in no colon') + ' ' + getAltiboxAssetExpiration(promotedAsset);

          case BroadcastStatus.Future:
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  }
  get title() {
    const { chapterNO } = this.context.promotedAsset as Program;
    const shouldShowParts = assetIsProgram(this.context.promotedAsset) && chapterNO && chapterNO !== '1';
    return `${getAltiboxAssetTitle(this.context.altiboxAsset)}${
      shouldShowParts ? i18n.t<string>('n parts suffix', { n: chapterNO }) : ''
    }`;
  }
  get suffix() {
    const { altiboxAsset, routeProps } = this.context;
    const suffix = getAltiboxAssetTitleSuffix(altiboxAsset, routeProps, this.promotedAsset);
    return suffix ? <span className="title-suffix"> {suffix}</span> : null;
  }
  get ageRating(): JSX.Element | null {
    return (
      <span className={`age-limit ${getAltiboxAssetColorCategory(this.context.altiboxAsset)}`}>
        {getAltiboxAssetAgeRating(this.context.altiboxAsset)}
      </span>
    );
  }
  get caption() {
    const { altiboxAsset, programGenres } = this.context;
    return (
      <div className="altibox-asset-caption">
        {this.ageRating}
        {getAltiboxAssetCaption(altiboxAsset, this.promotedAsset, programGenres)}
        {this.imdbRating}
        {this.automaticDeletionPinIcon}
      </div>
    );
  }
  get imdbRating(): JSX.Element | null {
    const { altiboxAsset } = this.context;
    const imdb = getAltiboxAssetImdbScore(altiboxAsset);

    if (imdb) {
      return (
        <a href={imdb.link} target="_blank" rel="noopener noreferrer" className="imdb-rating">
          • <SvgImdb className="imdb-rating__icon" /> <span className="imdb-rating__score">{imdb.score}</span>{' '}
          <span className="imdb-rating__limit">/10</span>
        </a>
      );
    }
    return null;
  }
  get automaticDeletionPinIcon(): JSX.Element | null {
    const { promotedAsset } = this.context;
    const autoRecordingDeletion = getAltiboxAssetRecordingAutoDeletion(promotedAsset as PvrRecording);
    if (autoRecordingDeletion) {
      return (
        <span className="pin-icon">
          • <SvgPin className="pin-icon__icon" />
        </span>
      );
    }
    return null;
  }
  get description() {
    return this.longDescription;
  }

  get longDescription() {
    return getAltiboxAssetHeroDescription(this.context.altiboxAsset);
  }

  get shortDescription() {
    return getAltiboxAssetShortDescription(this.context.altiboxAsset);
  }

  get isCatchupOrPvr() {
    return this.context.isCatchupOrPvr;
  }

  get isVod() {
    return this.context.isVod;
  }

  get isSvod() {
    return this.context.isSvod;
  }

  get isVodOrSvod() {
    return this.context.isVodOrSvod;
  }

  get isSeries() {
    return this.context.altiboxAsset?.meta?.isSeries;
  }

  get displayInfoTile() {
    const { promotedAsset, altiboxAsset } = this.context;

    if (this.isActiveRecording) {
      return <Badge className="altibox-asset-info-tile recording" text={i18n.t<string>('is recording')} />;
    }

    if (assetIsPvr(promotedAsset)) {
      return <Badge className={'altibox-asset-info-tile recorded'} text={i18n.t<string>('recorded')} />;
    }

    if (assetIsProgram(promotedAsset)) {
      if (this.infoTile) {
        return <Badge className={'altibox-asset-info-tile expires'} text={this.infoTile} />;
      }
    }

    if (altiboxAsset.asset && assetIsProgram(altiboxAsset.asset)) {
      if (!isSupportingCatchup(altiboxAsset.asset)) {
        return <Badge className={'altibox-asset-info-tile live-only'} text={i18n.t<string>('live only')} />;
      }
    }

    if (this.isVod && this.isSeries) {
      let ownedAssets: UnionAssetTypes[] = [];

      if (altiboxAsset && altiboxAsset.asset && altiboxAsset.asset.seasons) {
        const allEpisodesOfSeriesCombined: UnionAssetTypes[] = (
          altiboxAsset.asset.seasons as unknown as AltiboxSeason[]
        )
          .map((season: AltiboxSeason) => {
            return (season as AltiboxSeason).content;
          })
          .flat()
          .map((season: AltiboxAsset) => {
            return season.asset;
          });

        ownedAssets = allEpisodesOfSeriesCombined.filter(
          (asset) => (asset as VodAsset).issubscribed === '1' && (asset as VodAsset).rentperiod !== '0',
        );
      }

      const badge = assetShouldPrioritizeBadge(ownedAssets as VodAsset[]);

      if (badge) {
        if (badge === Owned.RENTED) {
          const rentedAssets = ownedAssets.filter((asset) => assetIsRented(asset as VodAsset));
          const lowestRentPeriod = getLowestRentedSeriesCountdown(ownedAssets as VodAsset[]).rentperiod;

          if (rentedAssets.length === 1) {
            return (
              <Badge
                className="altibox-asset-info-tile rent"
                text={getRentBadgeTextWithInformation(lowestRentPeriod)}
              />
            );
          }

          return <Badge className="altibox-asset-info-tile rent" text={i18n.t<string>('rented')} />;
        } else {
          return <Badge className="altibox-asset-info-tile bought" text={i18n.t<string>('bought')} />;
        }
      }
    }

    return null;
  }

  get serviceLogo(): JSX.Element | null {
    const { svodKiosk, defaultImagePath, promotedAsset, channels } = this.context;
    let logo: JSX.Element | null = null;

    if (this.isVod) {
      logo = <SvgFilm width="43px" height="35px" />;
    } else if (this.isSvod) {
      logo = <img alt={svodKiosk!.providerName ?? ''} src={`${defaultImagePath}/${svodKiosk!.kioskHeader?.cropped}`} />;
    } else if (assetIsProgram(promotedAsset)) {
      const asset = { ...getCatchupAsset(promotedAsset, channels), type: AltiboxAssetDetailsType.CATCHUP };
      const channelLogo = getAltiboxAssetAdditionalThumbnail(asset, channels);
      logo = channelLogo ? <img src={channelLogo} alt={(asset.asset as Program).channelName} /> : null;
    } else if (assetIsPvr(promotedAsset)) {
      const asset = { ...getPVRAsset(promotedAsset), type: AltiboxAssetDetailsType.PVR };
      const channelLogo = getAltiboxAssetAdditionalThumbnail(asset, channels);
      logo = channelLogo ? <img src={channelLogo} alt={(asset.asset as PvrRecording).channelName} /> : null;
    }

    if (logo) {
      return <span className="altibox-asset-logo">{logo}</span>;
    }

    return null;
  }

  get background(): JSX.Element | null {
    return (
      <div className="altibox-asset-hero-background">
        <img src={this.cover} alt={this.title} />
        <div className="altibox-asset-hero-overlay" />
      </div>
    );
  }

  get additionalInfo(): JSX.Element | null {
    if (!this.isCatchupOrPvr) {
      return null;
    }

    const episode = this.promotedAsset as PvrRecording | Program;

    const [starttime, endtime] = assetIsPvr(episode)
      ? [episode.beginTime, episode.endTime]
      : [episode.starttime, episode.endtime];

    const status = broadcastStatus({ starttime, endtime });

    if (status === BroadcastStatus.Live) {
      return <div className="additional-information additional-information-live">{i18n.t<string>('airing now')}</div>;
    }

    const timePrefix = assetIsPvr(episode)
      ? i18n.t<string>('recorded')
      : status === BroadcastStatus.Finished
      ? i18n.t<string>('aired')
      : i18n.t<string>('airs');
    const time = toDetailsReadableDateString({
      huaweiDateTime: starttime,
      longFormat: i18n.t<string>('long date format details-plain-text'),
      longestFormat: i18n.t<string>('longest date format details'),
      shortFormat: i18n.t<string>('short date format details'),
      farInTheFutureFormat: i18n.t<string>('long date format details-plain-text'),
    });

    return (
      <div className="additional-information">
        {timePrefix} {time}
      </div>
    );
  }

  get isActiveRecording() {
    if (!this.isCatchupOrPvr) {
      return false;
    }

    const asset = this.promotedAsset as Program | PvrRecording;
    const [starttime, endtime] = assetIsPvr(asset)
      ? [asset.beginTime, asset.endTime]
      : [asset.starttime, asset.endtime];

    if (broadcastStatus({ starttime, endtime }) !== BroadcastStatus.Live) {
      return false;
    }

    if (assetIsPvr(asset)) {
      return true;
    }

    if (this.isSeriesRecording || this.isSingleRecording) {
      return true;
    }

    return false;
  }

  get isSeriesRecording() {
    const { recordings, promotedAsset } = this.context;

    if (!assetIsProgram(promotedAsset)) {
      return false;
    }

    return (
      recordings.series.find(
        (recording: PvrRecording) => !recording.canceled && recording.seriesId === promotedAsset.seriesID,
      ) !== undefined
    );
  }

  get isSingleRecording() {
    const { recordings, promotedAsset } = this.context;
    return recordings.single.find((recording: PvrRecording) => recording.programId === promotedAsset.id) !== undefined;
  }

  get descriptionCharacterThreshold() {
    return this.context.isSlideIn ? 200 : 275;
  }

  componentDidMount() {
    const coverURL = this.isCatchupOrPvr ? missingTvPlaceholder : missingVODPlaceholder;

    this.setState({
      placeholderCover: coverURL,
      cover: this.cover,
    });

    if (this.closeButton) {
      this.closeButton.focus();
    }
  }

  toggleShowMore = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  posterAndAssetInformation() {
    const { altiboxAsset, myContent } = this.context;
    const { name, id } = altiboxAsset.asset;
    const vodAsset = altiboxAsset.asset;
    const poster = getAltiboxAssetPoster(altiboxAsset) ? getAltiboxAssetPoster(altiboxAsset) : missingVODPlaceholder;
    let badge = null;
    if (this.isVod && myContent) {
      const myAsset = myContent.find((asset: VodAsset) => asset.id === vodAsset.id);

      if (myAsset && assetIsRented(myAsset)) {
        badge = (
          <Badge
            className="altibox-asset-poster-badge rent"
            text={getRentBadgeTextWithInformation(myAsset.rentperiod)}
          />
        );
      } else if (myAsset) {
        badge = <Badge className="altibox-asset-poster-badge bought" text={i18n.t<string>('bought')} />;
      }
    }

    return (
      <div className="altibox-asset-head-details-container">
        <div className="altibox-asset-poster-container">
          <img src={poster} onError={(e) => (e.currentTarget.src = this.state.placeholderPoster)} alt={name} />
          {badge}
        </div>
        <div className="altibox-asset-title-container">
          <h1>{this.title}</h1>
          {this.caption}
          <div
            className="altibox-asset-cta-button"
            onClick={() => {
              if (name && id) {
                this.tracking.trackCurrentService(GAaction.play, name + ' - ' + id);
              }
            }}
          >
            <AltiboxAssetButton buttonType={AltiboxAssetButtonType.PLAY} />
          </div>
        </div>
      </div>
    );
  }

  renderView() {
    if (!this.isSeries && this.isVodOrSvod) {
      return this.renderVodMovie();
    }
    return this.renderDefaultView();
  }

  renderVodMovie() {
    return (
      <div className="altibox-asset-single">
        {this.posterAndAssetInformation()}
        <div className="altibox-asset-description">
          {this.description ? (
            <ExpandableText threshold={this.descriptionCharacterThreshold}>{this.description}</ExpandableText>
          ) : null}
          <AltiboxAssetButtonRow />
        </div>
        <hr className="altibox-asset-hr" />
        <SharedAssets />
      </div>
    );
  }

  renderDefaultView() {
    const { name, id } = this.context.altiboxAsset.asset;
    return (
      <>
        <div className="altibox-asset-head-details-container">
          <div className="altibox-asset-title-container">
            {this.displayInfoTile}
            <h1>{this.title}</h1>
            {this.caption}
            <div
              className="altibox-asset-cta-button"
              onClick={() => {
                if (name && id) {
                  this.tracking.trackCurrentService(GAaction.play, name + ' - ' + id);
                }
              }}
            >
              {<AltiboxAssetButton buttonType={AltiboxAssetButtonType.PLAY} />}
            </div>
          </div>
        </div>
        <div className="altibox-asset-description">
          {this.description ? (
            <ExpandableText threshold={this.descriptionCharacterThreshold}>{this.description}</ExpandableText>
          ) : null}
        </div>
        <AltiboxAssetButtonRow />
        {this.additionalInfo}
        <hr className="altibox-asset-hr" />
      </>
    );
  }

  render() {
    return (
      <>
        {this.background}
        <div className="altibox-asset-hero-buttons-container">
          {this.serviceLogo}
          {this.context.isSlideIn ? (
            <ButtonClose
              ref={(e) => {
                this.closeButton = e;
              }}
              onClick={() => this.context.hideDetailsPanel()}
            >
              X
            </ButtonClose>
          ) : null}
        </div>
        <div className="altibox-asset-content-container">
          <div className="altibox-asset-head-container">{this.renderView()}</div>
        </div>
      </>
    );
  }
}
