import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { routes } from '../../../config';
import i18n from '../../../i18n';
import { AltiboxAssetDetailsType, PortalMenu, VodAsset } from '../../../interfaces';
import { getSvodPicture, getSvodUrl } from '../../../utils/svodUtil';
import { showDetailsPanel as showDetailsPanelAction } from '../../../views/details/actions';

import styles from './svodService.module.scss';

interface Props {
  kiosk: PortalMenu;
  imageBase: string;
  promotions?: VodAsset[];
  showDetailsPanel: (asset: VodAsset, kiosk: PortalMenu) => void;
}

function SvodService({ kiosk, imageBase, promotions, showDetailsPanel }: Props) {
  const imageSrc = kiosk.kioskHeader?.cropped ? `${imageBase}/${kiosk.kioskHeader.cropped}` : '';
  const linkTo = `${routes.svod.base}/${getSvodUrl(kiosk)}`;

  const handlePromoClick = (vod: VodAsset) => () => {
    showDetailsPanel(vod, kiosk);
  };

  return (
    <div className={styles.svodService}>
      {promotions && (
        <div className={styles.promotions}>
          {promotions.map((promo) => (
            <button onClick={handlePromoClick(promo)} className={styles.promo} key={promo.name}>
              <img alt={promo.name} src={getSvodPicture(promo)} />
              <div className={styles.title}>{promo.name}</div>
            </button>
          ))}
        </div>
      )}
      <div className={styles.svodLinkContainer}>
        <Link className={styles.svodLink} to={linkTo}>
          <span className={styles.svodLinkText}>
            {i18n.t<string>('watch all from')}
            <span className={styles.iconWrapper}>
              <span className={styles.icon}>{'>'}</span>
            </span>
          </span>
          {imageSrc && <img alt={routes.programarchive.channel} src={imageSrc} />}
        </Link>
      </div>
    </div>
  );
}

export default connect(
  () => ({}),
  (dispatch) => ({
    showDetailsPanel: (asset: VodAsset, kiosk: PortalMenu) => {
      return dispatch(
        showDetailsPanelAction({
          routeProps: { parentId: asset.foreignsn },
          displayType: AltiboxAssetDetailsType.SVOD,
          svodKiosk: kiosk,
        }),
      );
    },
  }),
)(SvodService);
