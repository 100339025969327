import MobileDetect from 'mobile-detect';
import SmartBanner from 'smart-app-banner';
import 'smart-app-banner/index.css';
import { androidAppId, iosAppId } from '../config';

const mobileDetect = new MobileDetect(window.navigator.userAgent);

function addMetaTag(name, appId) {
  const smartbanner = document.createElement('meta');
  smartbanner.setAttribute('name', name);
  smartbanner.setAttribute('content', `app-id=${appId}`);
  document.head.appendChild(smartbanner);
}

// Add smartbanner meta tags
if (mobileDetect.mobile()) {
  addMetaTag('google-play-app', androidAppId);
  addMetaTag('apple-itunes-app', iosAppId);
}

// When on android or a different browser than Safari on iOS we show a custom banner
const onAndroid = mobileDetect.os() === 'AndroidOS';
const on3rdPartyIOSBrowser = mobileDetect.os() === 'iOS' && mobileDetect.userAgent() !== 'Safari';
if (mobileDetect.mobile() && (onAndroid || on3rdPartyIOSBrowser)) {
  // Only works for chrome apparently?
  // Possibly because the player or something else throws a JS error
  new SmartBanner({
    daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    appStoreLanguage: 'no', // language code for the App Store (defaults to user's browser language)
    title: 'Altibox',
    author: 'Altibox AS',
    button: 'VIEW',
    store: {
      ios: 'On the App Store',
      android: 'In Google Play',
      windows: 'In Windows store',
    },
    price: {
      ios: 'FREE',
      android: 'FREE',
      windows: 'FREE',
    },
    // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
    icon: '/images/altibox-app.png', // full path to icon image if not using website icon image
    // , force: 'android' // Uncomment for platform emulation
  });
}
