import React, { Component, KeyboardEventHandler } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Player from '../../../components/Player';
import {
  RootState,
  Channel,
  Program,
  StreamType,
  AlertData,
  ApiAuthResponse,
  Bookmark,
  StreamTypeNumber,
  StreamTypeObject,
  GAaction,
  GAlabel,
  GAcategory,
  AuthReducerState,
  NielsenChannelMapping,
  AlertType,
} from '../../../interfaces';
import { hideChannelList, showChannelList, ShowChannelListAction, updateCurrentLiveProgram } from '../actions';
import { loadAllUsersBookmarks } from '../../app/actions/index';

import channelPermissions from '../../../utils/channelPermissions';
import * as api from '../../../api/tv';
import { showAlert } from '../../app/actions';
import { ScriptService } from '../../../controllers/ScriptService';
import HuaweiError from '../../../controllers/HuaweiErrors';

import './style.scss';
import NoSupport from '../../../components/NoSupport';
import { Helmet } from 'react-helmet-async';
import i18n from '../../../i18n';
import { getPercentWatchedCatchup } from '../../../utils/catchupUtils';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import { hideDetailsPanel } from '../../details/actions';
import { wrapAssetAsAltiboxAsset } from '../../../utils/altiboxassetUtils';

interface Props extends RouteComponentProps {
  isHome: boolean;
  currentChannel: Channel | undefined;
  currentProgram: Program | undefined;
  startingCatchup: boolean;
  bookmarks: Bookmark[];
  shouldHideControls: boolean;
  shouldHideCursor: boolean;
  currentChannelIsLocked: boolean;
  isGuest: boolean;
  auth: ApiAuthResponse | undefined;
  authStatus: AuthReducerState;
  liveFromStart: boolean;
  includeInFullscreen?: () => JSX.Element;
  overlay?: JSX.Element | null;
  loadBookmarks: () => Promise<Bookmark[]>;
  hideChannelList: () => void;
  showChannelList: () => ShowChannelListAction;
  updateCurrentLiveProgram: () => void;
  showAlert: (alert: AlertData) => {
    type: symbol;
    alertVisible: boolean;
    alert: { title: string; text: string[] };
  };
  handleKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  nielsenChannelMapping?: NielsenChannelMapping[];
  unmountPlayer: () => void;
}

interface State {
  stream: StreamTypeObject | undefined;
  catchupDonePlaying: boolean;
  redirectIn: number | undefined;
}

class PlayerStreamManager extends Component<Props, State> {
  state: State = {
    stream: undefined,
    catchupDonePlaying: false,
    redirectIn: undefined,
  };

  // Strict Class Initialization ignored
  redirectInterval!: NodeJS.Timer;

  tracking = AnalyticsTracking.getInstance();

  constructor(props: Props) {
    super(props);
    this.setStream(props);
  }

  setStream({ startingCatchup, currentProgram, currentChannel }: Props) {
    let channelMediaId = '';
    if (currentChannel && currentChannel.mediaId) {
      channelMediaId = currentChannel.mediaId;
    } else if (currentProgram && currentProgram.recordedMediaIds && currentProgram.recordedMediaIds.length > 0) {
      channelMediaId = currentProgram.recordedMediaIds[0];
    }

    const startProgram = startingCatchup && currentProgram ? this.startProgram(currentProgram, channelMediaId) : null;

    const startChannel =
      !startProgram && currentChannel
        ? this.startTvChannel(currentChannel.contentId, currentChannel.chanNo, channelMediaId, currentProgram)
        : null;

    (startProgram || startChannel || Promise.resolve()).catch((error) => {
      if (error.retcode === HuaweiError.MAX_PLAYBACK_SESSIONS) {
        this.notifyToManyStreams();
        this.props.history.goBack();
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const catchupChanged =
      nextProps.currentProgram !== this.props.currentProgram && nextProps.currentProgram && nextProps.startingCatchup;
    const channelChanged = nextProps.currentChannel && nextProps.currentChannel !== this.props.currentChannel;
    const catchupEnded = nextProps.currentChannel === this.props.currentChannel && this.state.catchupDonePlaying;
    const stoppedCatchup =
      nextProps.currentChannel === this.props.currentChannel &&
      !nextProps.startingCatchup &&
      this.props.startingCatchup;
    const liveFromStartToggle = nextProps.liveFromStart !== this.props.liveFromStart;

    if (
      ScriptService.isCurrentBrowserSupported() &&
      (catchupChanged || channelChanged || catchupEnded || stoppedCatchup || liveFromStartToggle)
    ) {
      this.setStream(nextProps);
    }
  }

  startProgram(program: Program, channelMediaId: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return api.getProgramCatchupPlayData(program, channelMediaId).then((playData: any) => {
      this.props.loadBookmarks().then(() => {
        this.startNewStream(StreamType.CATCHUP, playData, program.id, undefined, program, channelMediaId);
      });
    });
  }

  startTvChannel(channelId: string, chanNo: string, mediaId?: string, program?: Program) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return api.getChannelLivePlayData(channelId, mediaId).then((playData: any) => {
      if (!this.props.currentChannelIsLocked) {
        this.startNewStream(StreamType.LIVE, playData, channelId, chanNo, program, mediaId);
      }
    });
  }

  notifyToManyStreams() {
    this.alert(i18n.t<string>('concurrency title'), [
      i18n.t<string>('concurrency message1'),
      i18n.t<string>('concurrency message2'),
    ]);
    this.props.showChannelList();
  }

  getCatchupBookmark(contentId: string) {
    return this.props.bookmarks.find(
      (bookmark) => bookmark.bookmarkType === StreamTypeNumber.CATCHUP && bookmark.contentId === contentId,
    );
  }

  startNewStream(
    streamType: StreamType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    playData: any,
    channelId: string,
    chanNo?: string,
    program?: Program,
    mediaId?: string,
  ) {
    clearInterval(this.redirectInterval);
    let startBookmark = 0;
    if (program) {
      let bookmark = this.getCatchupBookmark(program.id);
      if (bookmark) {
        if (getPercentWatchedCatchup(program, bookmark) < 97) {
          startBookmark = Number(bookmark.rangeTime);
        }
      }
    }
    let theNewState = {
      redirectIn: undefined,
      catchupDonePlaying: false,
      stream: {
        isGuest: this.props.isGuest,
        streamType: streamType,
        picture: program ? program.picture : undefined,
        channelId: program ? program.channelid : undefined,
        programStart: program ? program.starttime : undefined,
        programEnd: program ? program.endtime : undefined,
        mediaId: mediaId,
        contentId: channelId,
        chanNo: chanNo,
        startBookmark: startBookmark,
        playData: {
          manifestUrl: playData.manifestUrl,
          licenseUrl: playData.licenseUrl,
          customData: playData.customData,
        },
      } as StreamTypeObject,
    };

    this.setState(theNewState);
    // React doesn't notice the changes in the nested objects in stream, so we have to tell it to rerender.
    this.forceUpdate();
  }

  // called by player on error
  onPlayerError = (error: object) => {
    if (this.state.stream && this.props.currentChannel && this.props.currentProgram) {
      this.tracking.trackEvent(
        GAcategory.playerError,
        `${this.state.stream ? this.state.stream!.streamType : null}:
        CHANNEL [ID: ${this.props.currentChannel.contentId}, NAME: ${this.props.currentChannel.name}]
        PROGRAM [ID: ${this.props.currentProgram.id}, NAME: ${this.props.currentProgram.name}]` as GAaction,
        JSON.stringify(error),
      );
    }
  };

  alert = (title: string, message: string[]) => {
    this.props.showAlert({
      title: title,
      text: message,
      type: AlertType.INFO,
    });
  };

  updateRedirect = () => {
    if (this.state.redirectIn !== undefined && this.state.redirectIn <= 0) {
      this.goToLive();
    } else if (this.state.redirectIn && this.state.redirectIn > 0) {
      this.setState({ redirectIn: this.state.redirectIn - 1 });
    }
  };

  programEnded = (type: string) => {
    clearInterval(this.redirectInterval);
    if (type === 'live') {
      this.props.updateCurrentLiveProgram();
    } else {
      // catchup
      this.setState({ redirectIn: 15 });
      this.redirectInterval = setInterval(this.updateRedirect, 1000);
    }
  };

  goToLive = () => {
    clearInterval(this.redirectInterval);
    this.setState({
      redirectIn: undefined,
      catchupDonePlaying: true,
      stream: undefined,
    });
  };

  cancelRedirect = () => {
    clearInterval(this.redirectInterval);
    this.setState({
      redirectIn: undefined,
    });
  };

  renderRedirectSoon() {
    return (
      <div className="redirect-soon aib-modal" onClick={this.props.hideChannelList}>
        <p>
          {i18n.t<string>('program done')}.<br />
          {i18n.t<string>('going to live tv in')}&nbsp;
          <span className="now">{this.state.redirectIn}</span>&nbsp;
          {i18n.t<string>('seconds')}.
        </p>
        <div className="button-col">
          <button className="button-next" onClick={this.goToLive}>
            <span className="aib-icon">z</span>
            {i18n.t<string>('go to live now')}
          </button>
          <button className="button-cancel" onClick={this.cancelRedirect}>
            <span className="aib-icon">B</span>
            {i18n.t<string>('cancel')}
          </button>
        </div>
      </div>
    );
  }

  trackGoScrollToLive = () => {
    this.tracking.trackCurrentService(
      GAaction.playback_scrollToLive,
      this.props.currentChannel!.name + ' - ' + this.props.currentChannel!.contentId,
    );
  };

  onPause = () => {
    this.tracking.trackCurrentService(
      GAaction.playback_pause,
      this.props.currentChannel!.name + ' - ' + this.props.currentChannel!.contentId,
    );
  };

  onControlsClick = () => {
    this.cancelRedirect();
  };

  onPlay = () => {
    this.tracking.trackCurrentService(
      GAaction.playback_play,
      this.props.currentChannel!.name + ' - ' + this.props.currentChannel!.contentId,
    );
  };

  onConcurrencyError = () => {
    this.tracking.trackCurrentService(
      GAaction.notification,
      (this.props.auth!.isInside ? 'inside ' : 'outside ') + GAlabel.maximumNoOfStreamReached,
    );
  };

  dismiss() {
    this.props.unmountPlayer();
  }

  render() {
    if (!ScriptService.isCurrentBrowserSupported()) {
      return <NoSupport />;
    }

    if (this.props.startingCatchup && this.state.catchupDonePlaying) {
      return <Redirect to={'/tv/' + this.props.currentChannel!.contentId} />;
    }

    /*
    if (!this.state.stream) {
          return null;
        } */

    const pageTitle = this.props.currentChannel ? this.props.currentChannel.name : 'Altibox TV';
    return (
      <React.Fragment>
        {this.state.redirectIn ? this.renderRedirectSoon() : null}
        <Helmet>
          <title>{pageTitle}</title>
          <meta property="og:site_name" content={'Altibox TV'} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content={
              this.props.currentChannel && this.props.currentChannel.pictures
                ? this.props.currentChannel.pictures[0].href
                : ''
            }
          />
          <meta property="og:description" content={'Altibox TV - ' + pageTitle} />
        </Helmet>
        <Player
          stream={
            this.state.stream
              ? {
                  ...this.state.stream,
                  programStart: this.props.currentProgram ? this.props.currentProgram.starttime : undefined,
                  programEnd: this.props.currentProgram ? this.props.currentProgram.endtime : undefined,
                }
              : undefined
          }
          currentAsset={this.props.currentProgram ? wrapAssetAsAltiboxAsset(this.props.currentProgram) : undefined}
          currentProgram={this.props.currentProgram}
          onScrollToLive={this.trackGoScrollToLive}
          onPlayerClick={this.props.hideChannelList}
          onPlay={this.onPlay}
          onControlsClick={this.onControlsClick}
          onPause={this.onPause}
          onConcurrencyError={this.onConcurrencyError}
          onProgramEnded={this.programEnded}
          onPlayerError={this.onPlayerError}
          hideControls={this.props.shouldHideControls || this.props.overlay !== null}
          hideCursor={this.props.shouldHideCursor}
          onAlert={this.alert}
          liveFromStart={this.props.liveFromStart}
          authStatus={this.props.authStatus}
          includeInFullscreen={this.props.includeInFullscreen}
          popupOverlay={this.props.overlay}
          handleKeyDown={this.props.handleKeyDown}
          nielsenChannelMapping={this.props.nielsenChannelMapping}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  (state: RootState) => ({
    isHome: state.authReducer.isHome,
    currentChannel: state.channelsReducer.currentChannel,
    currentProgram: state.channelsReducer.currentProgram,
    startingCatchup: state.channelsReducer.startingCatchup,
    shouldHideControls:
      state.app.shouldFadeOut ||
      state.channelsReducer.channelListVisible ||
      state.detailsReducer.detailsPanel !== undefined,
    shouldHideCursor:
      !state.channelsReducer.channelListVisible &&
      state.detailsReducer.detailsPanel === undefined &&
      state.app.shouldFadeOut,
    currentChannelIsLocked: channelPermissions(state.channelsReducer.currentChannel, state.authReducer).locked,
    isGuest: state.authReducer.isGuest,
    bookmarks: state.app.bookmarks,
    auth: state.authReducer.auth,
    authStatus: state.authReducer,
    liveFromStart: state.channelsReducer.liveFromStart,
    nielsenChannelMapping: state.app.nielsenChannelMapping,
  }),
  (dispatch) => ({
    loadBookmarks: () => dispatch(loadAllUsersBookmarks()),
    hideChannelList: () => {
      dispatch(hideChannelList());
      dispatch(hideDetailsPanel());
    },
    showChannelList: () => dispatch(showChannelList()),
    updateCurrentLiveProgram: () => dispatch(updateCurrentLiveProgram()),
    showAlert: (alert: AlertData) => dispatch(showAlert(alert)),
  }),
)(withRouter(PlayerStreamManager));
