import { useDispatch } from 'react-redux';
import { showLoginModal } from '../../views/app/actions';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';

interface Props {
  showLoginButton?: boolean;
  backgroundImage?: string;
  children: JSX.Element | JSX.Element[];
}

export default function FullscreenMessage({ showLoginButton, backgroundImage, children }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const style = backgroundImage ? { backgroundImage: `url('${backgroundImage}')` } : {};

  return (
    <div className={styles.fullscreenMessage} style={style}>
      <div className={styles.backdrop}>
        {children}

        {showLoginButton && (
          <button onClick={() => dispatch(showLoginModal())} className={styles.login}>
            <span className="aib-icon">q</span>
            {t('log on')}
          </button>
        )}
      </div>
    </div>
  );
}
