import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeleteLeftActions } from '../../../components/Pvr/DeleteActions';
import { PvrDeleteAsset } from '../../../components/Pvr/DeleteAsset';
import { DeleteMultipleSeasonsModal } from '../../../components/Pvr/DeleteModal';
import { PvrLayout } from '../../../components/Pvr/Layout';
import { SortDropdown } from '../../../components/SortDropdown';
import Spinner from '../../../components/Spinner';
import { useBlockNavigation } from '../../../hooks/useBlockNavigation';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { useRecordingSeasons } from '../../../queries/recordings/queries';
import { Recording } from '../../../queries/recordings/types';
import { isSameInArray } from '../../../utils/commonUtils';
import { FormWrapper } from '../FormWrapper';
import styles from '../PvrDeleteSeries/style.module.scss';

const SORT_OPTIONS = [
  { label: 'newest', value: Recording.Sort.Action.Newest },
  { label: 'oldest', value: Recording.Sort.Action.Oldest },
  { label: 'A - Å', value: Recording.Sort.Action.Alphabetically },
];

export default function PvrDeleteSeasons() {
  const { id } = useParams<{ id: string }>();
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0].value);
  const { data: seasons, isLoading } = useRecordingSeasons(id, sortBy);

  const [selectedSeasons, setSelectedSeasons] = useState<Recording.Group.Season[]>([]);
  const [selectionMode, setSelectionMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation('pvr');

  useScrollToTop();
  useBlockNavigation(!selectionMode, {
    prompt: t('are you sure you want to cancel'),
  });

  function handleChange(season: Recording.Group.Season) {
    if (selectedSeasons.includes(season)) {
      return setSelectedSeasons((prev) => prev.filter((selected) => selected?.seasonNum !== season.seasonNum));
    }

    return setSelectedSeasons((prev) => [...prev, season]);
  }

  function markAll() {
    if (seasons) {
      setSelectedSeasons(seasons);
    }
  }

  function clearAll() {
    setSelectedSeasons([]);
  }

  const leftActions = (
    <DeleteLeftActions
      onSetSelectionMode={setSelectionMode}
      markAllSelections={markAll}
      clearAllSelections={clearAll}
      openModal={() => setIsModalOpen(true)}
      selectionMode={selectionMode}
      selectedRecordings={selectedSeasons}
      isAllSelected={selectedSeasons.length === seasons?.length}
    />
  );

  const rightActions = <SortDropdown onChange={setSortBy} options={SORT_OPTIONS} />;

  const title = seasons && seasons[0].pvrName;

  return (
    <PvrLayout title={title} leftActions={leftActions} rightActions={rightActions}>
      <DeleteMultipleSeasonsModal
        selectedSeasons={selectedSeasons}
        isOpen={isModalOpen}
        name={title}
        hideModal={() => setIsModalOpen(false)}
        isAllSelected={selectedSeasons.length === seasons?.length}
        callback={() => {
          clearAll();
          setIsModalOpen(false);
          setSelectionMode(false);
        }}
      />

      <div className={styles.container}>
        <FormWrapper
          selectionMode={selectionMode}
          shouldOpenModal={Boolean(selectedSeasons.length)}
          openModal={() => setIsModalOpen(true)}
          onCancel={() => {
            setSelectionMode(false);
            clearAll();
          }}
        >
          {isLoading && <Spinner wrapInContainer />}

          {(isEmpty(seasons) || !seasons) && !isLoading && (
            <div className={styles.container}>
              <p className={styles.empty}>{t('you have no recordings')}</p>
            </div>
          )}

          <ul className={styles.grid}>
            {seasons?.map((season) => (
              <li key={season.seasonNum}>
                <PvrDeleteAsset
                  season={season}
                  selectionMode={selectionMode}
                  onChange={handleChange}
                  isSelected={isSameInArray(selectedSeasons, season, 'seasonNum')}
                  type="season"
                />
              </li>
            ))}
          </ul>

          <button type="submit" className={styles.submit}>
            Submit
          </button>
        </FormWrapper>
      </div>
    </PvrLayout>
  );
}
