import { status, json, fetchError } from '../controllers/Fetch';
import { getBaseUrl } from '../config';
import { PortalMenu, CategoryListResponse } from '../interfaces';
import { getContentCategory } from './app';

export function getSvodConfig(areaId: string, bossId: string, svod: PortalMenu) {
  const path = 'svod-config-' + svod.identifier + '.json';
  const configUrl = `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/${path}`;
  return fetch(configUrl)
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function getStreamingServices(bossId: string, areaId: string) {
  const path = 'streaming-services.json';
  const streamingServicesURL = `${getBaseUrl()}/EPG/CONFIG/${bossId}/${areaId}/${path}`;

  return fetch(streamingServicesURL)
    .then(status)
    .then((r) => json(r as Response))
    .catch(fetchError);
}

export function loadSvodCategoryIds(secondLevel: CategoryListResponse, svod: PortalMenu) {
  const third = getThirdLevel(secondLevel, svod);
  if (third) {
    return third
      .then((thirdLevel: CategoryListResponse) => {
        return third;
      })
      .catch(fetchError);
  }
  return Promise.reject();
}

export function loadSvodKiosksBaseCategoryId() {
  return getFirstLevel()
    .then((firstLevel: CategoryListResponse) => {
      return getSecondLevel(firstLevel);
    })
    .catch(fetchError);
}

// get the passed SVODs categories
function getThirdLevel(secondLevel: CategoryListResponse, svod: PortalMenu) {
  if (svod.providerName) {
    const category = secondLevel.categorylist.find((x) => x.name === svod.providerName);
    if (category && category.id) {
      return getContentCategory(category.id);
    }
  }
  return Promise.reject('Not found');
}

// get the SVOD category
function getSecondLevel(firstLevel: CategoryListResponse) {
  const category = firstLevel.categorylist.find((x) => x.name === 'SVOD');
  if (category && category.id) {
    return getContentCategory(category!.id);
  }
  return Promise.reject('Not found');
}

function getFirstLevel() {
  return getContentCategory('-1');
}
