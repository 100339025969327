import { Bookmark } from '../../interfaces';

export const vodKeys = {
  all: ['vod'] as const,
  parents: (id?: string) => (id ? ([...vodKeys.all, 'parents', id] as const) : ([...vodKeys.all, 'parents'] as const)),
  ids: (ids: string[]) => [...vodKeys.all, ids.join('-')] as const,
  bookmarks: (bookmarks: Bookmark[]) => {
    const ids = bookmarks.map((b) => `${b.contentId}:${b.updateTime}`);
    return [...vodKeys.all, ids.join('-')] as const;
  },
};
