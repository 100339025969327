export function isUrl(str: string) {
  return str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
}

/**
 * Take an array of objects and return a new array with the nth occurance of the same object removed.
 * @param iteratee
 * @param property
 * @param nthOccurance - optional, default is 2
 */
export function removeNthOccurances<T>(iteratee: T[], property: string, nthOccurance: number = 2) {
  const tally = {};
  const results: T[] = [];

  iteratee.forEach((item) => {
    if (!tally[item[property]]) {
      tally[item[property]] = 0;
    }

    if (tally[item[property]] < nthOccurance) {
      tally[item[property]] += 1;
      results.push(item);
    }
  });

  return results;
}

export function convertHuaweiDateToDateObject(date: string) {
  const ISOString = convertHuaweiDateToISOString(date);

  return new Date(ISOString);
}

export function convertHuaweiDateToISOString(date: string) {
  const [YYYY, MM, DD, hh, mm] = [
    date.slice(0, 4),
    date.slice(4, 6),
    date.slice(6, 8),
    date.slice(8, 10),
    date.slice(10, 12),
  ];

  return `${YYYY}-${MM}-${DD}T${hh}:${mm}:00.000Z`;
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function arrayToMap<T>(array: T[], selector: (item: T) => string) {
  return new Map(array.map((el) => [selector(el), el]));
}

export function isSameInArray<T extends unknown>(array: T[], item: T, property: keyof T) {
  return Boolean(array.find((arrayItem) => arrayItem[property] === item[property]));
}
