import { sortedUniqBy, uniqBy } from 'lodash';
import { getBaseUrl, getSessionTicket } from '../../config';
import { getDataFromResponse } from '../../controllers/Fetch';
import { Bookmark, HuaweiBatchResponse } from '../../interfaces';
import { getPercentWatched } from '../recordings/utils';
import { Catchup } from './types';

export async function getCatchupDetails(bookmarks: Bookmark[]) {
  const ids = bookmarks.map((b) => b.contentId);
  const [prePadding, postPadding] = await getCatchupBookmarkPadding();

  const res = await fetch(getBaseUrl() + '/EPG/JSON/ExecuteBatch', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      requestList: ids.map((id) => {
        return {
          name: 'ContentDetail',
          param: {
            playbill: id,
          },
        };
      }),
    }),
  });

  const { responseList } = await getDataFromResponse<HuaweiBatchResponse<'ContentDetail', Catchup.BatchResponseList>>(
    res,
  );

  return responseList
    .map((list) => list.msg)
    .filter((msg) => msg.playbilllist)
    .map((playbill) => playbill.playbilllist[0])
    .map((catchup) => ({ ...catchup, beginOffset: prePadding, endOffset: postPadding }));
}

async function getCatchupSeriesDetails(id: string) {
  const res = await fetch(getBaseUrl() + '/EPG/JSON/QuerySeriesRelation', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      seriesID: id,
      orderType: 2,
    }),
  });

  const { seriesRelations } = await getDataFromResponse<{
    counttotal: string;
    seriesRelations: Catchup.SeriesRelation[];
  }>(res);

  return seriesRelations;
}

export async function constructContinueWatchingCatchupList(
  data: Catchup.Details[],
  bookmarks: Bookmark[],
): Promise<Catchup.ConstructedAsset[]> {
  const episodes = data.filter((episode) => episode.seriesID);
  const movies = data.filter((movie) => !movie.seriesID);

  const uniqueEpisodes = uniqBy(episodes, 'seriesID');

  const episodesList = await Promise.all(
    uniqueEpisodes.map(async (episode) => {
      return getCatchupSeriesDetails(episode.seriesID);
    }),
  );

  const constructedEpisodes = uniqueEpisodes.map((episode, i) => {
    const bookmark = bookmarks.find((b) => b.contentId === episode.id);
    const allEpisodes = sortedUniqBy(episodesList[i], (ep) => ep.seasonNum && ep.subNum);
    const nextEpisodeIndex =
      allEpisodes.findIndex((e) => e.seasonNum === episode.seasonNum && e.subNum === episode.subNum) - 1;
    const nextEpisode = allEpisodes[nextEpisodeIndex];

    const percentWatched = getPercentWatched({
      beginTime: episode.starttime,
      endTime: episode.endtime,
      bookmarkTime: bookmark?.rangeTime,
      beginOffset: episode.beginOffset.toString(),
      endOffset: episode.endOffset.toString(),
    });

    return {
      asset: episode,
      nextEpisode: nextEpisode ? { ...episode, ...nextEpisode, id: nextEpisode.programID } : undefined,
      updateTime: bookmark?.updateTime,
      rangeTime: bookmark?.rangeTime,
      episodes: allEpisodes,
      progress: percentWatched,
      type: 'PROGRAM',
    } as const;
  });

  const constructedMovies = movies.map((movie) => {
    const bookmark = bookmarks.find((b) => b.contentId === movie.id);

    const percentWatched = getPercentWatched({
      beginTime: movie.starttime,
      endTime: movie.endtime,
      bookmarkTime: bookmark?.rangeTime,
      beginOffset: movie.beginOffset.toString(),
      endOffset: movie.endOffset.toString(),
    });

    return {
      asset: movie,
      updateTime: bookmark?.updateTime,
      rangeTime: bookmark?.rangeTime,
      progress: percentWatched,
      type: 'PROGRAM',
    } as const;
  });

  const uniqueConstructedEpisodes = uniqBy(constructedEpisodes, (e) => e.asset.seriesID);

  return [...uniqueConstructedEpisodes, ...constructedMovies];
}

export async function getCatchupBookmarkPadding(): Promise<[prePadding: number, postPadding: number]> {
  const res = await fetch(getBaseUrl() + '/VSP/V3/QueryCustomizeConfig', {
    method: 'POST',
    headers: { SessionTicket: getSessionTicket() },
    body: JSON.stringify({
      key: 'CatchupPostPadding,CatchupPrePadding',
      queryType: '2',
    }),
  });

  const data = await getDataFromResponse(res);
  const [prePadding, postPadding] = data.configurations[0].values;

  return [prePadding.values[0], postPadding.values[0]];
}
