import { Filter, FilterState } from '../..';
import { Option } from '../../../../../../interfaces';
import { ActiveFilterButton } from '../../../../Buttons';

export interface ActiveFiltersProps {
  filter: FilterState | null;
  handleFilterSelect: (option: Option) => void;
  getFilterOptions(): {
    count: number;
    title: string;
    value: Filter;
    active: boolean;
  }[];
}

export function ActiveFilters({ filter, handleFilterSelect, getFilterOptions }: ActiveFiltersProps) {
  if (!filter) {
    return null;
  }

  const options = getFilterOptions();
  const activeFilter = options.find((option) => option.value === filter?.value);

  if (!activeFilter || (activeFilter && activeFilter.value === Filter.All)) {
    return null;
  }

  return (
    <div className="altibox-asset-active-filter-container">
      <ActiveFilterButton onClick={() => handleFilterSelect({ value: Filter.All })}>
        {activeFilter?.title} ({activeFilter?.count})
      </ActiveFilterButton>
    </div>
  );
}
