import React from 'react';
import Modal from '../Modal';
import './style.scss';
import styles from './styles.module.scss';
import { OptionsModalContent, OptionType } from '../../interfaces';
import { isEmpty } from 'lodash';
import { SvgClose } from '../../icons';
import { ButtonSecondary } from '../UI/Buttons';
import i18n from '../../i18n';
import Toggle from 'react-toggle';
import { Key } from 'ts-key-enum';
import { Image } from '../Image';

type DeleteToggleButtonProps = {
  isRecording: boolean;
  isDeleteModeAuto: boolean;
  changeDeleteMode: () => void;
};

export const DeleteToggleButton: React.FC<DeleteToggleButtonProps> = ({ isDeleteModeAuto, changeDeleteMode }) => {
  return (
    <div
      className={styles.deleteToggle}
      onKeyDown={(e) => {
        if (e.key === Key.Enter) {
          changeDeleteMode();
        }
      }}
      onClick={() => {
        changeDeleteMode();
      }}
    >
      {i18n.t<string>('delete automatically')}
      <Toggle checked={isDeleteModeAuto} icons={false} onChange={() => void 0} />
    </div>
  );
};

type ModalHeroBackroundProps = {
  title: string;
  imageUrl: string | undefined;
};

export const ModalHeroBackground: React.FC<ModalHeroBackroundProps> = ({ title, imageUrl }) => {
  return (
    <div className={styles.modalHeroContainer}>
      <div className={styles.modalHeroBackgroundOverlay} />
      {imageUrl && <Image className={styles.modalHeroBackground} src={imageUrl} alt={title} />}
      <header className={styles.modalHeader}>
        <h1 className={styles.modalH1} title={title}>
          {title}
        </h1>
      </header>
    </div>
  );
};

type Props = {
  customClass?: string;
  hideModal?: () => void;
};

const OptionsModal: React.FC<OptionsModalContent & Props> = ({
  onClose,
  onAction,
  modalIsOpen,
  options,
  error,
  customClass,
  title,
  subtitle,
  posttitle,
  modalHeroBackground,
}) => {
  const renderOptions = () => {
    if (isEmpty(options)) {
      return null;
    }
    return [
      ...options.map((option: OptionType) => {
        return option.type === 'primary' ? (
          <button
            key={option.title}
            className={option.style ? styles['optionButton' + option.style] : styles.optionButton}
            onClick={() => onAction(option)}
          >
            {option.style === 'checkmark' ? <span className="aib-icon">v</span> : ''}
            {option.title} <span className={styles.titleInfo}>{option.titleInfo}</span>
          </button>
        ) : (
          <ButtonSecondary key={option.title} className={styles.optionsButton} onClick={() => onAction(option)}>
            {option.title}
          </ButtonSecondary>
        );
      }),
    ];
  };

  const renderSubtitle = () => {
    if (subtitle) {
      return <div className="options-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />;
    }

    return null;
  };

  return !modalIsOpen ? null : (
    <Modal
      contentLabel="options-modal-label"
      portalClassName={customClass}
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldFocusAfterRender={true}
      onRequestClose={() => onClose()}
      className="aib-modal"
    >
      {(title || modalHeroBackground) && <ModalHeroBackground imageUrl={modalHeroBackground} title={title} />}
      <button className={styles.buttonClose} onClick={onClose} children={<SvgClose />} />
      <main className={styles.mainContainer}>
        {subtitle && <div className={styles.titleSubtitleContainer}>{renderSubtitle()}</div>}
        <div className={styles.optionsButtonWrapper}>{renderOptions()}</div>
        <div className={`options-posttitle ${error ? ' error' : ''}`}>{posttitle}</div>
      </main>
    </Modal>
  );
};
export default OptionsModal;
