import { Component } from 'react';
import { PurchaseProduct } from '../../../interfaces';
import i18n from '../../../i18n';
import styles from '../../OptionsModal/styles.module.scss';
import { ButtonPrimary } from '../../UI/Buttons';

interface Props {
  onPlayCallback: Function;
  asset: PurchaseProduct | undefined;
}

class ConfirmationModal extends Component<Props> {
  render() {
    return (
      <div className={styles.optionsButtonWrapper}>
        <ButtonPrimary
          className={styles.optionButtonNoPadding}
          onClick={() => {
            this.props.onPlayCallback(true);
          }}
        >
          <span className={'aib-icon'}>z</span>
          {i18n.t<string>('play')}
        </ButtonPrimary>
      </div>
    );
  }
}
export default ConfirmationModal;
