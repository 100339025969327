import OptionsModal from '../../components/OptionsModal';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showAggregatedDeleteModal, ShowAggregatedDeleteModal } from './actions';
import { RootState, OptionsModalContent, OptionType, DeleteType, PvrReducerState } from '../../interfaces';
import i18n from '../../i18n';
import AnalyticsTracking from '../../controllers/AnalyticsTracking';

type Props = {
  hideModal: () => ShowAggregatedDeleteModal;
  deleteTypeCallback: (_1: DeleteType) => void;
  modalHeroBackground?: string;
  title: string;
};

type State = OptionsModalContent;

class DeleteModal extends Component<Props, State> {
  tracking = AnalyticsTracking.getInstance();

  state: State = {
    modalIsOpen: false,
    title: this.props.title,
    closetext: i18n.t<string>('cancel'),
    subtitle: '',
    subtitle2: '',
    posttitle: '',
    options: [],
    onAction: this.onAction(),
    onClose: () => {
      this.props.hideModal();
    },
    error: false,
  };

  onAction() {
    return (selectedAction: OptionType) => {
      this.props.hideModal();
      if (selectedAction.value) {
        this.props.deleteTypeCallback(selectedAction.value);
      }
    };
  }

  componentDidUpdate() {
    if (this.state.title !== this.props.title) {
      this.setState({
        title: this.props.title,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props & PvrReducerState) {
    this.setState({
      options: [
        {
          title: i18n.t<string>('delete recording'),
          value: DeleteType.SINGLE,
          type: 'primary',
          style: '',
        },
        {
          title: i18n.t<string>('delete seasons'),
          value: DeleteType.SEASONS,
          type: 'primary',
          style: '',
        },
        {
          title: i18n.t<string>('delete entire series'),
          value: DeleteType.SERIES,
          type: 'primary',
          style: 'NoPadding',
        },
      ],
      modalIsOpen: nextProps.showAggregatedDeleteModal,
    });
  }

  render() {
    return (
      <OptionsModal
        {...this.state}
        modalHeroBackground={this.props.modalHeroBackground}
        customClass={'options-modal'}
      />
    );
  }
}

export default connect(
  (state: RootState) => {
    return {
      showAggregatedDeleteModal: state.pvrReducer.showAggregatedDeleteModal,
    };
  },
  (dispatch) => ({
    hideModal: () => dispatch(showAggregatedDeleteModal(false)),
  }),
)(DeleteModal);
