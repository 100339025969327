import React from 'react';

import { Helmet } from 'react-helmet-async';

interface Props {
  title: string;
  categoryTitle: string;
}

export default class CategoryHelmet extends React.Component<Props> {
  render() {
    const title = this.props.title ? this.props.title : 'Altibox TV';
    return (
      <Helmet>
        <title>{title}</title>
        <meta property="og:site_name" content={'Altibox TV'} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={'Altibox TV - ' + this.props.categoryTitle + ' - ' + title} />
        <meta name="description" content={'Altibox TV - ' + this.props.categoryTitle + ' - ' + title} />
        <meta property="og:type" content="website" />
      </Helmet>
    );
  }
}
