import React, { Component } from 'react';
import i18n from '../../../i18n';
import { connect } from 'react-redux';
import { RootState, ApiAuthResponse } from '../../../interfaces';
import {
  handleAibLoginCallback,
  cleanUpLocalStorage,
  logout,
  loginToAccount,
  createAuthResponse,
  loginInternetCustomer,
} from '../../../api/auth';
import { handleLoginResponse } from '../actions';
import { goToPrevPath } from '../../../utils/appUtils';
import './style.scss';
import LocationSelectModal from './LocationSelectModal';
import DeviceSwitchModal from './DeviceSwitchModal';
import Spinner from '../../../components/Spinner';
import Footer from '../../../components/Footer';
import { isEmpty } from 'lodash';

interface Props {
  location: string;
  loginError: string;
  handleLoginResponse: (res: ApiAuthResponse) => void;
}

interface State {
  message: string;
  loginError: string;
  loading: boolean;
}

class Login extends Component<Props, State> {
  state: State = {
    message: '',
    loginError: '',
    loading: true,
  };

  static getDerivedStateFromProps = (props: Props, state: State) => {
    if (props.loginError && props.loginError !== state.loginError) {
      setTimeout(() => logout(true), 10000);
      return {
        loginError: props.loginError,
        loading: false,
      };
    }
    return null;
  };

  showFailedMessageAndGoBack(message?: string) {
    setTimeout(() => goToPrevPath(), 10000);
    this.setState({
      message: message ? message : i18n.t<string>('authentication failed'),
      loading: false,
    });
  }

  async handleLogin() {
    if (this.props.location) {
      try {
        const accounts = await handleAibLoginCallback(this.props.location);

        if (isEmpty(accounts)) {
          cleanUpLocalStorage();
          this.showFailedMessageAndGoBack();

          return;
        }

        const accountsWithTvSubscribers = accounts.filter((account) => account.subscribers.length);

        if (isEmpty(accountsWithTvSubscribers)) {
          // Just pick the first account, as none of them have TV subscribers so it won't matter
          const loginResponse = await loginInternetCustomer(accounts[0]);
          this.props.handleLoginResponse(loginResponse);

          return;
        }

        const singleAccountWithSingleSubscriber =
          accountsWithTvSubscribers.length === 1 && accountsWithTvSubscribers[0].subscribers.length === 1;

        if (singleAccountWithSingleSubscriber) {
          const loginResponse = await loginToAccount(
            accountsWithTvSubscribers[0],
            accountsWithTvSubscribers[0].subscribers[0],
          );
          this.props.handleLoginResponse(loginResponse);
        } else {
          this.props.handleLoginResponse(createAuthResponse({ success: true, accounts: accountsWithTvSubscribers }));
        }
      } catch (errorFromHandleLogin) {
        if (typeof errorFromHandleLogin === 'string') {
          this.props.handleLoginResponse({ retcode: errorFromHandleLogin.replace('Error: ', '') } as ApiAuthResponse);
          return;
        }
        this.props.handleLoginResponse(errorFromHandleLogin as ApiAuthResponse);
      }
    }
  }

  componentDidMount() {
    this.handleLogin();
  }

  render() {
    return (
      <div className="loginLanding">
        <DeviceSwitchModal />
        <LocationSelectModal />
        <h3>{i18n.t<string>('checking authentication')}</h3>
        <div dangerouslySetInnerHTML={{ __html: this.state.message }} />
        <div dangerouslySetInnerHTML={{ __html: this.state.loginError }} />
        {this.state.loading && <Spinner wrapInContainer={true} />}
        {this.state.loginError ? (
          <>
            <Spinner wrapInContainer={true} />
            <p>{i18n.t<string>('redirecting')}</p>
          </>
        ) : null}
        <Footer />
      </div>
    );
  }
}

export default connect(
  (state: RootState) => ({
    loginError: state.authReducer.loginError as string,
  }),
  (dispatch) => ({
    handleLoginResponse: (loginResponse: ApiAuthResponse) => handleLoginResponse(dispatch, loginResponse),
  }),
)(Login);
