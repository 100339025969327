import React, { Component } from 'react';
import './style.scss';

class PvrPinIcon extends Component {
  render() {
    return (
      <div className="pvrPinIcon">
        <div className="aib-icon">&</div>
      </div>
    );
  }
}

export default PvrPinIcon;
