import { AMR, ChannelLogoPicture, ChannelLogoType, StreamCatchupSupport } from './../interfaces';
import { ScriptService } from '../controllers/ScriptService';
import { ApiPhysicalChannel, CODEC, Channel, Program, Day, PvrRecording } from '../interfaces';
import i18n from '../i18n';
import { sanitize } from '../i18n/languageMap';
import { hasCatchupEnabled } from './catchupUtils';
import moment from 'moment';
import { isEmpty, upperFirst } from 'lodash';

import { huaweiDateTimeFormat, numberOfLiveCollagePrograms } from '../config';
import { broadcastEnded, broadcastingNow, toMoment } from './huaweiUtils';

export function getMediaId(physicalChannels: ApiPhysicalChannel[]) {
  let mediaId = '';

  physicalChannels.forEach((physicalChannel: ApiPhysicalChannel) => {
    if (ScriptService.supportsHEVC()) {
      if (physicalChannel.videoCodec === CODEC.HEVC) {
        mediaId = physicalChannel.mediaId;
      }
    } else {
      if (physicalChannel.videoCodec === CODEC.AVC) {
        mediaId = physicalChannel.mediaId;
      }
    }
  });

  if (mediaId === '') {
    const physicalChannel = physicalChannels.length > 0 ? physicalChannels[0] : undefined;
    mediaId = physicalChannel && physicalChannel.npvrRecCR ? physicalChannel.npvrRecCR.mi : '';
  }

  return mediaId;
}

export function getChannelPermission(channel: Channel) {
  return channel.channelPermissions;
}

export function getLockedMessage(channel: Channel) {
  const permission = getChannelPermission(channel);
  if (permission.notInSubscription) {
    return i18n.t<string>('permission not in subscription');
  } else if (permission.onlyIptvChannel) {
    return i18n.t<string>('permission only decoder');
  } else if (permission.onlyInsideforLiveTV) {
    return i18n.t<string>('permission only on homenet');
  }
  return '';
}

export function isChannelOnlyIPTV(channel: Channel) {
  const permission = getChannelPermission(channel);
  return permission.onlyIptvChannel;
}

export function createDateLabel(day: number, date: Date) {
  let dateString = '';
  switch (day) {
    case Day.YESTERDAY:
      dateString = i18n.t<string>('yesterday');
      break;
    case Day.TODAY:
      dateString = i18n.t<string>('today');
      break;
    case Day.TOMRROW:
      dateString = i18n.t<string>('tomorrow');
  }
  if (dateString) {
    return upperFirst(dateString + ' ' + createDateString(date, 'D. MMMM'));
  }
  dateString = createDateString(date, 'dddd D. MMMM');
  return upperFirst(dateString);
}

function createDateString(date: Date, format: string) {
  return moment(date).locale(sanitize(i18n.language)).format(format);
}

export function channelHasCatchupEnabled(channel: Channel) {
  if (channel) {
    const cutv = channel.physicalChannel;
    if (cutv) {
      return hasCatchupEnabled(cutv.cutvCR);
    }
  }
  return false;
}

export function formatTimeSpan(starttime: string, endtime: string, format: string = 'HH:mm'): string {
  return [starttime, endtime]
    .map(toMoment)
    .map((time) => time.format(format))
    .join(' - ');
}

export function formatSeasonAndEpisode(seasonNum: string, subNum: string, abbreviation = true): string {
  if (!(seasonNum && subNum)) {
    return '';
  }
  return abbreviation ? `S${seasonNum} E${subNum}` : `${i18n.t('season')} ${seasonNum}, ${i18n.t('episode')} ${subNum}`;
}

export function isCatchup(program: Program): boolean {
  return catchupAvailable(program) && broadcastEnded(program);
}

export function isSupportingCatchup(program: Program): boolean {
  return Boolean(program && program.istvod && program.istvod === StreamCatchupSupport.YES);
}

export function catchupAvailable(program: Program): boolean {
  return program.istvod === '1' && !isEmpty(program.recordedMediaIds);
}

export function isRecording(
  program: Program,
  singleRecordings: PvrRecording[],
  seriesRecordings: PvrRecording[],
): boolean {
  const singleRecording = singleRecordings.find((recording) => recording.programId === program.id);
  if (singleRecording && !broadcastEnded(program)) {
    return true;
  }

  const seriesRecording = seriesRecordings.find((recording) => recording.seriesId === program.seriesID);
  if (seriesRecording && !seriesRecording.canceled) {
    return true;
  }

  return false;
}

export function getChannelLogoUrl({ pictures }: Pick<Channel, 'pictures'>, logoType?: ChannelLogoType): string {
  if (!pictures || isEmpty(pictures)) {
    return '';
  }

  let logo: ChannelLogoPicture | undefined;

  switch (logoType) {
    case ChannelLogoType.CROPPED:
      logo = pictures.find((pic) => pic.imageType === ChannelLogoType.CROPPED);
      break;
    case ChannelLogoType.DEFAULT:
      logo = pictures.find((pic) => pic.imageType === ChannelLogoType.DEFAULT);
      break;
    default:
      logo = pictures.find((pic) => pic.href);
  }

  return logo ? logo.href : '';
}
export function getTimeIntervalHour(hour: number, interval: number) {
  let fromHour = moment().hour(hour).format('HH');
  let toHour = moment()
    .hour(hour + interval)
    .format('HH');
  return `${fromHour}:00-${toHour}:00`;
}

export function programIsBetweenTimeInterval(program: Program, date: Date, selectedHour: number, interval: number) {
  let currentDate = moment(date).date();
  let filterBeginMoment = moment(date, huaweiDateTimeFormat).date(currentDate).hour(selectedHour).minute(0);
  let filterEndMoment = moment(date, huaweiDateTimeFormat)
    .date(currentDate)
    .hour(selectedHour + interval)
    .minute(0);
  return moment(program.starttime, huaweiDateTimeFormat).isBetween(filterBeginMoment, filterEndMoment);
}

export function getSelectedTvGuideFilterList(selectedFilter: string) {
  return selectedFilter.split(',').map((genreString) => genreString.replace(' ', ''));
}

function programContainsGenreId(program: Program, epgGenreList: string[]): Boolean {
  if (isEmpty(program.genreIds)) {
    return false;
  }
  return epgGenreList.some((value) => program.genreIds!.indexOf(value) !== -1);
}

function shouldShowChannel(program: Program, epgGenreList: string[], timePicker: number, date: Date) {
  if (isEmpty(epgGenreList) && timePicker === 0) {
    return true;
  }
  if (!isEmpty(epgGenreList) && timePicker !== 0) {
    return programContainsGenreId(program, epgGenreList) && programIsBetweenTimeInterval(program, date, timePicker, 4);
  } else if (isEmpty(epgGenreList) && timePicker !== 0) {
    return programIsBetweenTimeInterval(program, date, timePicker, 4);
  } else if (!isEmpty(epgGenreList) && timePicker === 0) {
    return programContainsGenreId(program, epgGenreList);
  } else {
    return false;
  }
}

export function filterPlaybills(
  playbills: Program[] | undefined,
  epgGenreList: string[],
  timePicker: number,
  date: Date,
) {
  if (playbills) {
    return playbills.filter((program) => shouldShowChannel(program, epgGenreList, timePicker, date));
  }
  return [];
}

export function getPopularChannelsContentIds(popularChannels: AMR.PopularChannel[]): string[] {
  return popularChannels
    .map((popularChannel) => popularChannel.contentId.toString())
    .slice(0, numberOfLiveCollagePrograms);
}

export function getChannelsToShow(channelsToUse: string[], channels: Channel[]): Channel[] {
  let result = channelsToUse.map((contentId) => {
    return channels.find((a) => a.contentId === contentId);
  });

  if (result.length === numberOfLiveCollagePrograms && !result.includes(undefined)) {
    return result as Channel[];
  }
  return [];
}

export function getOngoingProgramName(channel: Channel, playbills: Map<string, Program[]>) {
  const programs = playbills[channel.contentId];
  if (!isEmpty(programs)) {
    const program = programs.find(broadcastingNow);
    return program ? program.name : i18n.t<string>('program unknown');
  } else {
    return '';
  }
}

export function getSortedChannelsBySubscription(channels: Channel[]) {
  if (isEmpty(channels)) {
    return [];
  }

  return channels.sort((a, b) => {
    if (a.channelPermissions.notInSubscription > b.channelPermissions.notInSubscription) {
      return 1;
    }
    if (a.channelPermissions.notInSubscription < b.channelPermissions.notInSubscription) {
      return -1;
    }
    return 0;
  });
}
