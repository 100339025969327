import React, { createContext, useContext, useState } from 'react';

interface ConfirmValues {
  title?: string;
  prompt: string;
  isOpen: boolean;
  proceed: (value: unknown) => void;
  cancel: (reason?: unknown) => void;
}

interface ConfirmContextProviderProps {
  children: React.ReactNode;
}

interface ConfirmContextValues {
  confirm: ConfirmValues;
  setConfirm: React.Dispatch<React.SetStateAction<ConfirmValues>>;
}

export const ConfirmContext = createContext({} as ConfirmContextValues);

export function useConfirmContext() {
  return useContext(ConfirmContext);
}

export function ConfirmContextProvider({ children }: ConfirmContextProviderProps) {
  const [confirm, setConfirm] = useState<ConfirmValues>({
    title: '',
    prompt: '',
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    proceed: (value: unknown) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    cancel: (reason?: unknown) => {},
  });

  return <ConfirmContext.Provider value={{ confirm, setConfirm }}>{children}</ConfirmContext.Provider>;
}
