import { isEmpty } from 'lodash';
import { Api, Channel, ChannelLogoPicture, ChannelPermissions } from '../../interfaces';
import { hasCatchupEnabled } from '../../utils/catchupUtils';
import { getMediaId } from '../../utils/tvUtils';

enum ExtInfoKeys {
  LiveTV = 'onlyInsideforLiveTV',
  CatchupTV = 'onlyInsideforCATCHUPTV',
}

export function constructChannel(channel: Api.ChannelList): Channel {
  const physicalChannel = !isEmpty(channel.physicalChannels) ? channel.physicalChannels[0] : undefined;
  const extensionInfo = physicalChannel?.extensionInfo;

  const onlyInsideforLiveTV = extensionInfo?.find((extInfo) => extInfo.key === ExtInfoKeys.LiveTV);
  const onlyInsideForCatchup = extensionInfo?.find((extInfo) => extInfo.key === ExtInfoKeys.CatchupTV);
  const inSubscription = physicalChannel?.btvBR.is === '1' && physicalChannel?.btvBR.va === '1';
  const hasEnabledCatchup = physicalChannel && hasCatchupEnabled(physicalChannel.cutvCR);

  return {
    contentId: channel.contentId,
    name: channel.name,
    chanNo: channel.chanNo,
    pictures: channel.pictures as ChannelLogoPicture[],
    physicalChannel: physicalChannel,
    mediaId: getMediaId(channel.physicalChannels),
    channelPermissions: {
      onlyIptvChannel: !extensionInfo,
      onlyInsideforLiveTV: onlyInsideforLiveTV ? onlyInsideforLiveTV.value === '1' : true,
      onlyInsideForCatchup: onlyInsideForCatchup ? onlyInsideForCatchup.value === '1' : true,
      notInSubscription: !inSubscription,
      hasEnabledCatchup: hasEnabledCatchup,
      catchupTimeToLive: physicalChannel ? physicalChannel.cutvCR.l : undefined,
    } as ChannelPermissions,
    locationCopyrights: channel.locationCopyrights,
  };
}
