import { SvodReducerState } from '../../../interfaces';

import { TypeKeys, ActionTypes } from '../actions';

export function svodReducer(
  state: SvodReducerState = {
    svodConfig: undefined,
    frontPageVodLists: undefined,
    svodKiosk: undefined,
    promotedAssets: undefined,
    allActiveContent: {},
  },
  action: ActionTypes,
): SvodReducerState {
  switch (action.type) {
    case TypeKeys.SET_SVOD_FRONT_PAGE_LISTS:
      return {
        ...state,
        frontPageVodLists: action.frontPageVodLists,
      };
    case TypeKeys.SET_SVOD_CONFIG:
      return {
        ...state,
        svodConfig: action.svodConfig,
      };
    case TypeKeys.SET_SVOD_KIOSK:
      return {
        ...state,
        svodKiosk: action.svodKiosk,
      };
    case TypeKeys.SET_SVOD_PROMOTED_ASSETS:
      return {
        ...state,
        promotedAssets: action.promotedAssets,
      };
    case TypeKeys.SET_SVOD_ALL_ACTIVE_CONTENT:
      return {
        ...state,
        allActiveContent: {
          ...state.allActiveContent,
          ...action.content,
        },
      };
    default:
      return state;
  }
}
