import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Spinner from '../../../../components/Spinner';
import AltiboxAssetViewList from '../../../../components/UI/AltiboxAssetViewList';
import { getSvodAsset } from '../../../../utils/svodUtil';
import { getCurrentLangTitle } from '../../../../utils/vodUtils';
import { fetchAllActiveContent as fetchAllActiveContentAction } from '../../actions';

import {
  AltiboxAssetDisplayTypes,
  DispatchType,
  PortalMenu,
  RootState,
  VodAsset,
  VodAssetDisplayMode,
} from '../../../../interfaces';

import styles from './miniSvodFrontPage.module.scss';

interface OwnProps {
  svodKiosk: PortalMenu;
  defaultImagePath: string;
  loggedInWithCredentials: boolean;
}

interface StoreProps {
  content: VodAsset[] | undefined;
  fetchAllActiveContent: (kiosk: PortalMenu) => (dispatch: DispatchType) => Promise<void>;
}

type Props = OwnProps & StoreProps;

enum Status {
  Initial,
  Loading,
  Initialized,
}

function MiniSvodFrontPage({
  svodKiosk,
  defaultImagePath,
  loggedInWithCredentials,
  content,
  fetchAllActiveContent,
}: Props) {
  const [status, setStatus] = useState(content ? Status.Initialized : Status.Initial);

  useEffect(() => {
    if (status === Status.Initial) {
      setStatus(Status.Loading);
      fetchAllActiveContent(svodKiosk);
    }

    if (status === Status.Loading && content) {
      setStatus(Status.Initialized);
    }
  }, [status, content, svodKiosk, setStatus, fetchAllActiveContent]);

  const imageUrl = svodKiosk.kioskHeader?.cropped ? `${defaultImagePath}/${svodKiosk.kioskHeader?.cropped}` : '';
  const assets = content ? content.map((asset) => getSvodAsset(asset, [svodKiosk], defaultImagePath, true)) : [];
  const showSpinner = status !== Status.Initialized || isEmpty(assets);

  return (
    <main className={styles.container}>
      <div className={styles.logoWrapper}>
        {imageUrl ? (
          <img
            alt={getCurrentLangTitle(svodKiosk.titles)}
            src={`${defaultImagePath}/${svodKiosk.kioskHeader?.cropped}`}
          />
        ) : (
          <h1>{getCurrentLangTitle(svodKiosk.titles)}</h1>
        )}
      </div>

      {showSpinner ? (
        <Spinner wrapInContainer={true} />
      ) : (
        <AltiboxAssetViewList
          hideTitleHeader={true}
          assets={assets}
          vodDisplayMode={VodAssetDisplayMode.SVOD}
          displayType={AltiboxAssetDisplayTypes.GridView}
          loggedInWithCredentials={loggedInWithCredentials}
        />
      )}
    </main>
  );
}

export default connect(
  (state: RootState, ownProps: OwnProps) => {
    return {
      content:
        ownProps.svodKiosk.identifier && state.svodReducer.allActiveContent
          ? state.svodReducer.allActiveContent[ownProps.svodKiosk.identifier]
          : undefined,
    };
  },
  (dispatch: DispatchType) => ({
    fetchAllActiveContent: (kiosk: PortalMenu) => dispatch(fetchAllActiveContentAction(kiosk)),
  }),
)(MiniSvodFrontPage);
