import React from 'react';
import { connect } from 'react-redux';

import { showDetailsPanel as showDetailsPanelAction } from '../../views/details/actions';
import { showChannelList as showChannelListAction, ShowChannelListAction } from '../../views/tv/actions';
import i18n from '../../i18n';

import { formatSeasonAndEpisode, formatTimeSpan, getChannelLogoUrl } from '../../utils/tvUtils';

import {
  Program as ProgramType,
  Channel as ChannelType,
  RootState,
  ChannelLogoType,
  AltiboxAssetDetailsType,
  DetailsPanelType,
} from '../../interfaces';

import './style.scss';

interface Props {
  currentChannel: ChannelType;
  currentProgram: ProgramType;
  detailsPanel?: DetailsPanelType;
  shouldFadeOut: boolean;
  channelListVisible: boolean;
  showChannelList: () => ShowChannelListAction;
  showDetailsPanel: (program: ProgramType) => void;
}

const ActiveChannel = ({
  currentChannel,
  currentProgram,
  detailsPanel,
  shouldFadeOut,
  channelListVisible,
  showChannelList,
  showDetailsPanel,
}: Props) => {
  if (!currentProgram || channelListVisible) {
    return null;
  }

  const formattedTime = formatTimeSpan(currentProgram.starttime, currentProgram.endtime);
  const seasonAndEpisodeShorthand = formatSeasonAndEpisode(currentProgram.seasonNum, currentProgram.subNum);
  const logoUrl = getChannelLogoUrl(currentChannel, ChannelLogoType.CROPPED);

  const renderLogo = () => {
    return logoUrl ? <img src={logoUrl} alt={i18n.t<string>('choose channel')} /> : currentChannel.name;
  };

  return (
    <div className={`activeChannel ${detailsPanel ? 'fade-to-transparent-4500ms' : shouldFadeOut ? 'hidden' : ''}`}>
      <button className="showChannelListButton" onClick={showChannelList}>
        <span className="arrowUp">u</span>&nbsp;
        {i18n.t<string>('choose channel')}
      </button>
      <div>
        <button className="showChannelListLogoButton" onClick={showChannelList}>
          {renderLogo()}
        </button>
        <button className="activeProgram" onClick={() => showDetailsPanel(currentProgram)}>
          <div>{formattedTime}</div>
          <div className="nameSeasonAndEpisode">
            <div className={'programName'}>{currentProgram.name}</div>
            {seasonAndEpisodeShorthand && <div className={'seasonAndEpisode'}>&nbsp;- {seasonAndEpisodeShorthand}</div>}
          </div>
        </button>
      </div>
    </div>
  );
};

export default connect(
  (state: RootState) => {
    return {
      shouldFadeOut: state.app.shouldFadeOut,
      channelListVisible: state.channelsReducer.channelListVisible,
      currentChannel: state.channelsReducer.currentChannel as ChannelType,
      currentProgram: state.channelsReducer.currentProgram as ProgramType,
      detailsPanel: state.detailsReducer.detailsPanel,
    };
  },
  (dispatch) => {
    return {
      showChannelList: () => dispatch(showChannelListAction()),
      showDetailsPanel: (program: ProgramType) => {
        return dispatch(
          showDetailsPanelAction({
            routeProps: { childId: program.id, parentId: program.seriesID },
            displayType: AltiboxAssetDetailsType.CATCHUP,
          }),
        );
      },
    };
  },
)(ActiveChannel);
