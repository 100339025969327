import React, { Suspense, useEffect } from 'react';
import AltiboxAssetHero from './AltiboxAssetHero';
import AltiboxAssetMeta from './AltiboxAssetMeta';
import { useAltiboxAsset } from '../../../views/details/AltiboxAssetContext';
import './style.scss';
import AltiboxAssetSeries from './AltiboxAssetSeries';
import Spinner from '../../Spinner';
import { flatten } from 'lodash';
import { AltiboxSeason, PvrRecording, RootState } from '../../../interfaces';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from '../../../config';

const Details = ({ recordingDeleted }: { recordingDeleted?: PvrRecording | PvrRecording[] }) => {
  const { altiboxAsset, isSlideIn } = useAltiboxAsset();
  const history = useHistory();
  const location = useLocation();

  function isSeries() {
    return altiboxAsset.meta?.isSeries;
  }

  function shouldShowEpisodeList() {
    if (!isSeries()) {
      return false;
    }

    const seasons = altiboxAsset.asset.seasons as AltiboxSeason[];
    const allEpisodes = flatten(seasons.map((season) => season.content));

    return allEpisodes.length >= 2;
  }

  useEffect(() => {
    if (recordingDeleted && location.pathname.includes('enkelt')) {
      history.push(routes.pvr.base, { reloadRecordings: true });
    }
  }, [history, location, recordingDeleted]);

  return (
    <div className={`altibox-detail-wrapper ${isSlideIn ? 'altibox-details-slide-in' : ''}`}>
      <Suspense fallback={<Spinner wrapInContainer={true} />}>
        <AltiboxAssetHero />
      </Suspense>
      {shouldShowEpisodeList() ? <AltiboxAssetSeries recordingDeleted={recordingDeleted} /> : null}

      <AltiboxAssetMeta />
    </div>
  );
};

export default connect((state: RootState) => {
  return {
    recordingDeleted: state.pvrReducer.recordingDeleted,
  };
})(Details);
