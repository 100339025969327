import React from 'react';
import { connect } from 'react-redux';
import {
  SearchConfig,
  RootState,
  VodAutoplay,
  VodAsset,
  StreamTypeObject,
  AuthReducerState,
  HeaderDisplayType,
  HeaderInteractionType,
  GAaction,
  GAlabel,
  GAcategory,
  AltiboxAssetDetailsType,
  PortalMenu,
  DetailsPanelType,
  NielsenChannelMapping,
} from '../../../interfaces';
import { withRouter, RouteComponentProps } from 'react-router';
import { loadPlayAsset, autoplayNextEpisode, clearVodAutoplay, clearVodStreamConfig, fetchMyContent } from '../actions';
import { Helmet } from 'react-helmet-async';
import Player from '../../../components/Player';
import { searchConfig, routes, imageScaleValues } from '../../../config';
import StickyHeader from '../../app/StickyHeader';
import Header from '../../app/Header';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import PlayerInfoPopupManager from '../../../components/UI/Player/PlayerInfoPopupManager';
import {
  assetIsBought,
  assetIsOwned,
  assetIsRented,
  getGrandfatherVodName,
  getRentBadgeTextWithInformation,
} from '../../../utils/vodUtils';
import { getSvodPicture, getSvodUrl } from '../../../utils/svodUtil';
import { ScriptService } from '../../../controllers/ScriptService';
import MobileLock from '../../app/MobileLock';
import { getImageUrlDimensions, wrapAssetAsAltiboxAsset } from '../../../utils/altiboxassetUtils';
import PlayerAssetCard from '../../../components/Player/UI/PlayerAssetCard';
import { showDetailsPanel as showDetailsPanelAction } from '../../../views/details/actions';
import { SlideInDetails } from '../../details';
import Badge from '../../../components/Badge';
import i18n from '../../../i18n';
import { isEmpty } from 'lodash';
import { getSeriesByEpisode } from '../../../api/vod';

interface State {
  timeLeft: number;
  goToDetailsAfterEnd: boolean;
  seriesTitle?: string;
  seriesSeasonEpisodeShorthand?: string;
  delayDKLoad?: boolean;
  myContentAsset: VodAsset | undefined;
}

interface RouteParams {
  vodId: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  searchConfig: SearchConfig;
  currentVod: VodAsset;
  myContent: VodAsset[] | undefined;
  autoplay: VodAutoplay;
  streamConfig: StreamTypeObject;
  authStatus: AuthReducerState;
  shouldHideControls: boolean;
  svodKiosk: PortalMenu | undefined;
  detailsPanel: DetailsPanelType | undefined;
  nielsenChannelMapping?: NielsenChannelMapping[];

  loadPlayAsset: (_1: string, _2: boolean) => void;
  autoplayNextEpisode: () => void;
  clearVodAutoplay: () => void;
  clearVodStreamConfig: () => void;
  showDetailsPanel: (asset: VodAsset, displayType: AltiboxAssetDetailsType) => void;
  loadMyContent: () => void;
}

class VodPlayer extends React.Component<Props, State> {
  state: State = {
    timeLeft: -2,
    goToDetailsAfterEnd: false,
    seriesTitle: undefined,
    seriesSeasonEpisodeShorthand: undefined,
    delayDKLoad: Boolean(ScriptService.isDKUser()),
    myContentAsset: undefined,
  };

  tracking = AnalyticsTracking.getInstance();

  get title() {
    const { currentVod } = this.props;
    return currentVod?.name || '';
  }

  get imageUrl() {
    const { currentVod } = this.props;

    return currentVod
      ? getImageUrlDimensions(getSvodPicture(this.props.currentVod), imageScaleValues.altiboxAssetView)
      : '';
  }

  get detailsURL() {
    const { currentVod, svodKiosk, autoplay } = this.props;
    let service = routes.vod.base;

    if (svodKiosk) {
      service = routes.svod.base + '/' + getSvodUrl(svodKiosk);
    }

    let returnId = autoplay?.seriesAsset.fatherVod?.fatherVod?.foreignsn || currentVod.externalContentCode;
    return service + routes.vod.details + '/' + returnId;
  }

  get seriesName() {
    const { autoplay } = this.props;

    if (autoplay) {
      return getGrandfatherVodName(autoplay.seriesAsset) || '';
    }
    return '';
  }

  get svodChannelAsset() {
    const { svodKiosk, currentVod } = this.props;
    if (!isEmpty(svodKiosk?.associatedChannelIds)) {
      return wrapAssetAsAltiboxAsset(currentVod);
    }
    return undefined;
  }

  get currentVodAsset() {
    const { currentVod } = this.props;
    if (currentVod && !isEmpty(currentVod)) {
      return wrapAssetAsAltiboxAsset(currentVod);
    }
    return undefined;
  }

  get badge() {
    const { currentVod, svodKiosk } = this.props;
    const { myContentAsset } = this.state;
    if (svodKiosk || !currentVod || !myContentAsset) {
      return undefined;
    }
    let className = 'player-asset-card-badge';
    if (assetIsRented(myContentAsset || currentVod)) {
      return (
        <Badge
          className={`${className} rent`}
          text={getRentBadgeTextWithInformation(myContentAsset.rentperiod || currentVod.rentperiod)}
        />
      );
    }
    if (assetIsBought(currentVod)) {
      return <Badge className={`${className} bought`} text={i18n.t<string>('bought')} />;
    }
    return undefined;
  }

  get popupOverlay() {
    const { autoplay, streamConfig } = this.props;
    if (autoplay && autoplay.nextAsset && streamConfig) {
      return (
        <PlayerInfoPopupManager
          timeLeft={this.state.timeLeft}
          autoplay={{ ...autoplay }}
          seriesName={this.state.seriesTitle ? this.state.seriesTitle : this.seriesName}
          assetType={AltiboxAssetDetailsType.VOD}
          callback={this.popupCallback}
          forceExitPlayer={this.forceExit}
          authStatus={this.props.authStatus}
        />
      );
    }
    return undefined;
  }

  get fullscreenDetails() {
    const { detailsPanel, svodKiosk, currentVod, shouldHideControls } = this.props;
    return (
      <>
        {detailsPanel ? (
          <SlideInDetails
            key={
              detailsPanel.routeProps.parentId ?? '' + detailsPanel.routeProps.childId ?? '' + detailsPanel.displayType
            }
            {...detailsPanel}
          />
        ) : null}
        {shouldHideControls ? null : (
          <PlayerAssetCard
            image={this.imageUrl}
            currentTitle={this.seriesName || this.title}
            currentSeason={currentVod?.seasonNumber}
            currentEpisode={currentVod?.sitcomnum}
            badge={svodKiosk ? undefined : this.badge}
            showDetailsPanel={() => this.handleOpenDetails()}
          />
        )}
      </>
    );
  }

  get playNextEpisode() {
    const asset = this.props.autoplay?.nextAsset;
    if (asset && assetIsOwned(asset)) {
      return () => this.props.autoplayNextEpisode();
    }
    return undefined;
  }

  componentDidMount() {
    this.prepareAsset(this.props.match.params.vodId);

    if (!this.props.myContent) {
      this.props.loadMyContent();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.onExit();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (ScriptService.isDKUser()) {
      let { svodKiosk } = this.props;
      if (
        !isEmpty(nextProps.currentVod) &&
        !isEmpty(svodKiosk?.associatedChannelIds) &&
        !isEmpty(nextProps.currentVod.fathervodlist)
      ) {
        getSeriesByEpisode(nextProps.currentVod).then((series) => {
          this.setState({ seriesTitle: series.name, delayDKLoad: false });
        });
      } else {
        this.setState({ delayDKLoad: false });
      }
    }

    if (this.props.myContent && this.props.currentVod && !this.state.myContentAsset) {
      const myContentAsset = this.props.myContent.find((c) => c.id === this.props.currentVod.id);
      this.setState({ myContentAsset });
    }
  }

  onExit = () => {
    this.props.clearVodStreamConfig();
    this.props.clearVodAutoplay();
  };

  prepareAsset(externalContentCode: string) {
    this.props.loadPlayAsset(externalContentCode, true);
  }

  onConcurrencyError = () => {
    this.tracking.trackCurrentService(
      GAaction.notification,
      (this.props.authStatus.isHome ? 'inside ' : 'outside ') + GAlabel.maximumNoOfStreamReached,
    );
  };

  onPause = () => {
    const id = this.props.match.params.vodId;
    if (this.props.currentVod) {
      this.tracking.trackCurrentService(GAaction.playback_pause, this.props.currentVod.name + ' - ' + id);
    }
  };

  onPlay = () => {
    const id = this.props.match.params.vodId;
    if (this.props.currentVod) {
      this.tracking.trackCurrentService(GAaction.playback_play, this.props.currentVod.name + ' - ' + id);
    }
  };

  onPlayerError = (error: object) => {
    this.tracking.trackEvent(GAcategory.playerError, 'vod or svod' as GAaction, JSON.stringify(error));
  };

  goBackToDetails = () => {
    if (!this.props.currentVod) {
      this.props.history.goBack();
    } else {
      this.props.history.replace(this.detailsURL);
    }
  };

  forceExit = () => {
    this.setState({ goToDetailsAfterEnd: true });
  };

  onProgramEnded = () => {
    if (!this.props.autoplay || this.state.goToDetailsAfterEnd) {
      this.goBackToDetails();
    } else {
      this.popupCallback();
    }
  };

  timeLeftCallback = (timeLeft: number) => {
    // Only set timeLeft if new episode is being played.
    // Stops timeLeft being set before new episode has loaded and setting the wrong time.
    if (this.state.timeLeft === -1 && timeLeft < 20) {
      return;
    }

    if (timeLeft) {
      this.setState({ timeLeft });
    }
  };

  alert = () => {
    return null;
  };

  popupCallback = () => {
    this.setState({ timeLeft: -1 }, () => this.props.autoplayNextEpisode());
  };

  handleOpenDetails = () => {
    const { currentVod, svodKiosk } = this.props;
    if (currentVod) {
      let displayType = AltiboxAssetDetailsType.VOD;
      if (svodKiosk) {
        displayType = AltiboxAssetDetailsType.SVOD;
      }

      this.props.showDetailsPanel(currentVod, displayType);
    }
  };

  renderPlayer() {
    return (
      <Player
        stream={this.props.streamConfig}
        seriesTitle={this.state.seriesTitle ?? this.seriesName}
        onPlay={this.onPlay}
        onAlert={this.alert}
        onPause={this.onPause}
        hideControls={this.props.shouldHideControls}
        onPlayerError={this.onPlayerError}
        onConcurrencyError={this.onConcurrencyError}
        onProgramEnded={this.onProgramEnded}
        authStatus={this.props.authStatus}
        timeLeftCallback={this.timeLeftCallback}
        popupOverlay={this.popupOverlay}
        currentAsset={this.svodChannelAsset || this.currentVodAsset}
        playNextEpisode={this.playNextEpisode}
        includeInFullscreen={() => this.fullscreenDetails}
        svodKiosk={this.props.svodKiosk}
        nielsenChannelMapping={this.props.nielsenChannelMapping}
      />
    );
  }

  render() {
    if (ScriptService.onMobile()) {
      return <MobileLock deepLink={'altibox://m'} />;
    }
    let playerSearchConfig = this.props.searchConfig ? this.props.searchConfig : searchConfig.global;
    playerSearchConfig.noFocus = true;
    return (
      <div className="main-frame">
        <div className="tv-container vod-player">
          <Helmet async={true}>
            <title>{this.title}</title>
          </Helmet>
          {this.props.shouldHideControls ? null : (
            <>
              <StickyHeader overPlayer={true} searchConfig={playerSearchConfig} />
              <Header
                displayType={HeaderDisplayType.Player}
                interactionType={HeaderInteractionType.Back}
                closeCallback={this.goBackToDetails}
              />
            </>
          )}
          {this.state.delayDKLoad ? null : this.renderPlayer()}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      currentVod: state.vodReducer.currentVod as VodAsset,
      myContent: state.vodReducer.myContent,
      autoplay: state.vodReducer.autoplay as VodAutoplay,
      streamConfig: state.vodReducer.streamConfig as StreamTypeObject,
      authStatus: state.authReducer,
      shouldHideControls: state.app.shouldFadeOut,
      svodKiosk: state.svodReducer.svodKiosk,
      detailsPanel: state.detailsReducer.detailsPanel,
      nielsenChannelMapping: state.app.nielsenChannelMapping,
    }),
    (dispatch) => ({
      showDetailsPanel: (vodAsset: VodAsset, displayType: AltiboxAssetDetailsType) =>
        dispatch(
          showDetailsPanelAction({
            routeProps: { childId: vodAsset.externalContentCode, parentId: vodAsset.foreignsn },
            displayType: displayType,
          }),
        ),
      loadPlayAsset: (_1: string, _2: boolean) => dispatch(loadPlayAsset(_1, _2)),
      autoplayNextEpisode: () => dispatch(autoplayNextEpisode()),
      clearVodAutoplay: () => dispatch(clearVodAutoplay()),
      clearVodStreamConfig: () => dispatch(clearVodStreamConfig()),
      loadMyContent: () => dispatch(fetchMyContent(-1)),
    }),
  )(VodPlayer),
);
