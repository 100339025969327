import Modal from '../../../components/Modal';

import React from 'react';
import { connect } from 'react-redux';
import { RootState, ProgramToRecord, RecordingDeleteMode, PvrRecording, Channel, GAaction } from '../../../interfaces';
import 'react-toggle/style.css';
import './style.scss';
import styles from '../../../components/OptionsModal/styles.module.scss';
import i18n from '../../../i18n';
import {
  addSingleRecording,
  removeSingleRecording,
  updateSingleRecording,
  addSeriesRecording,
  updateSeriesRecording,
  addChronologicalSeriesRecording,
  cancelSeriesRecording,
} from '../../../views/tv/actions';
import {
  getIsProgramInSeriesRecording,
  getIsRecordingProgram,
  getIsRecordingSeries,
  getSeasonAndEpisodeLabel,
  isDeleteModeAuto,
} from '../../../utils/pvrUtils';
import moment from 'moment';
import { fromMoment } from '../../../utils/huaweiUtils';
import AnalyticsTracking from '../../../controllers/AnalyticsTracking';
import { SvgClose } from '../../../icons';
import { ModalHeroBackground } from '../../../components/OptionsModal';
import Toggle from 'react-toggle';

type DeleteToggleButtonProps = {
  isRecording: boolean;
  deleteToggleChecked: boolean;
  changeDeleteMode: () => void;
};

export const DeleteToggleButton: React.FC<DeleteToggleButtonProps> = ({ deleteToggleChecked, changeDeleteMode }) => {
  return (
    <div
      className={styles.deleteToggle}
      onClick={() => {
        changeDeleteMode();
      }}
    >
      {i18n.t<string>('delete automatically')}
      <Toggle checked={deleteToggleChecked} icons={false} readOnly={true} />
    </div>
  );
};

interface Props {
  modalVisible: boolean;
  hideModal: () => void;
  programData: ProgramToRecord;
  seriesId: string;
  channelId: string;
  modalHeroBackground?: string;
  channels: Channel[];
  singleRecordings: PvrRecording[];
  seriesRecordings: PvrRecording[];
  recordingActionInProgress: boolean;
  addSingleRecording: (_1: ProgramToRecord, _2: string, _3: RecordingDeleteMode) => void;
  removeSingleRecording: (_: PvrRecording) => void;
  updateSingleRecording: (_: PvrRecording, _2: RecordingDeleteMode) => void;
  addSeriesRecording: (_1: string, _2: string, _3: string, _4: RecordingDeleteMode) => void;
  addChronologicalSeriesRecording: (
    _1: string,
    _2: string,
    _3: string,
    _4: RecordingDeleteMode,
    _5: number,
    _6: number,
  ) => void;
  updateSeriesRecording: (_: PvrRecording, _2: boolean, _3: RecordingDeleteMode) => void;
  cancelSeriesRecording: (_: PvrRecording) => void;
}

interface State {
  modalIsOpen: boolean;
  title?: string;
  closetext?: string;
  recordSeries: boolean;
  recordProgram: boolean;
  deleteMode: RecordingDeleteMode;
  currentRecording: PvrRecording;
}

class PvrEditRecordingModal extends React.Component<Props, State> {
  state: State = {
    modalIsOpen: false,
    title: i18n.t<string>('warning'),
    closetext: i18n.t<string>('cancel'),
    recordSeries: this.seriesRecording() !== undefined && !this.seriesRecording()!.canceled,
    recordProgram: this.singleRecording() !== undefined,
    deleteMode:
      this.recording() && this.recording()?.deleteMode ? this.recording()?.deleteMode! : RecordingDeleteMode.Auto,
    currentRecording: this.singleRecording()!,
  };

  tracking = AnalyticsTracking.getInstance();

  get channel() {
    const { channels, channelId } = this.props;
    return channels.find((x) => x.contentId === channelId)!;
  }

  get pvrOptionsModalTitle() {
    const { name, seasonNum, episodeNum } = this.props.programData;
    return (
      <div className="options-title">
        {name}
        {seasonNum && episodeNum ? ` S${seasonNum} E${episodeNum}` : ''}
      </div>
    );
  }

  get programHasEnded() {
    const { endtime } = this.props.programData;
    return endtime && endtime.isBefore(moment());
  }

  get programOrRecordingIsSingle() {
    const { seriesId } = this.props;
    return !seriesId || (seriesId && !this.state.recordSeries);
  }

  get showCancelSingleRecordingButton() {
    return this.recording();
  }

  get programIsCurrent() {
    const { endtime, starttime } = this.props.programData;
    if (endtime && starttime) {
      const now = moment();
      return endtime.isAfter(now) && starttime.isBefore(now);
    }
    return false;
  }

  get recordingTrackingLabel() {
    const { name, seasonNum, episodeNum } = this.props.programData;
    return `${this.channel.name} - ${name} - ${seasonNum ? 'S' + seasonNum : ''} ${episodeNum ? 'E' + episodeNum : ''}`;
  }

  get isRecordingSeries() {
    // Channels may be "retired" and removed from the channel list, but still have recordings.
    if (!this.channel) {
      return false;
    }
    return getIsRecordingSeries(this.props.seriesRecordings, this.props.seriesId, this.channel.contentId);
  }

  get isRecordingProgram() {
    return getIsRecordingProgram(this.props.singleRecordings, this.props.programData.id);
  }

  get isRecording() {
    return this.isRecordingSeries || this.isRecordingProgram;
  }

  get isProgramInSeriesRecording() {
    return getIsProgramInSeriesRecording(this.recording(), this.props.programData.id);
  }

  get shouldShowSeriesRecordButtons() {
    const seriesRecording = this.seriesRecording();

    if (!seriesRecording) {
      return true;
    }

    const foundRecording = seriesRecording.pvrList?.find((pvr) => pvr.programId === this.props.programData.id);
    return Boolean(!foundRecording?.canceled);
  }

  recording(props: Props = this.props): PvrRecording | undefined {
    return this.singleRecording(props) || this.seriesRecording(props);
  }

  seriesRecording(props: Props = this.props): PvrRecording | undefined {
    return props.seriesRecordings.find((x) => x.seriesId === props.seriesId && x.channelId === props.channelId);
  }

  singleRecording(props: Props = this.props): PvrRecording | undefined {
    return props.singleRecordings.find((x) => props.programData !== undefined && x.programId === props.programData.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      modalIsOpen: nextProps.modalVisible,
      recordSeries: this.seriesRecording(nextProps) !== undefined && !this.seriesRecording(nextProps)!.canceled,
      recordProgram: this.singleRecording(nextProps) !== undefined,
    });
  }

  getTrackingLabel = (seriesRecording: PvrRecording) => {
    const { latestPVRTask, channelName } = seriesRecording;
    const {
      programData,
      programData: { seasonNum, episodeNum },
    } = this.props;
    const label = latestPVRTask
      ? `${latestPVRTask.channelName} - ${latestPVRTask.pvrName}`
      : `${channelName} - ${programData!.name}`;

    if (programData && seasonNum && episodeNum) {
      return `${label} - S${seasonNum} E${episodeNum}`;
    }
    return label;
  };

  trackSeries = (label: string, canceled: boolean) => {
    if (canceled) {
      this.tracking.trackEvent(this.tracking.getCurrentCategory(), GAaction.recordSeriesCancel, label);
      return;
    }
    this.tracking.trackEvent(this.tracking.getCurrentCategory(), GAaction.recordSeries, label);
  };

  renderSingleRecordButton() {
    const { programData, channelId } = this.props;
    const { deleteMode, recordProgram } = this.state;

    return this.renderButton(
      this.programIsCurrent ? i18n.t<string>('record program from start') : i18n.t<string>('record program'),
      () => {
        if (!recordProgram && programData) {
          this.props.addSingleRecording(programData, channelId, deleteMode);
          this.tracking.trackEvent(
            this.tracking.getCurrentCategory(),
            GAaction.recordProgram,
            this.recordingTrackingLabel,
          );
        }
      },
    );
  }

  renderCancelSingleRecordButton() {
    const {
      programData: { id },
    } = this.props;

    return this.renderButton(i18n.t<string>('cancel program recording'), () => {
      const singleRecording = this.singleRecording();

      if (singleRecording) {
        return this.cancelAndTrackSingleRecording(singleRecording);
      }

      let seriesRecording = this.seriesRecording();

      if (seriesRecording?.pvrList) {
        const foundInPvrList = seriesRecording?.pvrList.find((pvr) => pvr.programId === id);
        return foundInPvrList && this.cancelAndTrackSingleRecording(foundInPvrList);
      }

      if (seriesRecording?.subRecordings) {
        const foundInSubRecordings = seriesRecording?.subRecordings.find((pvr) => pvr.programId === id);
        return foundInSubRecordings && this.cancelAndTrackSingleRecording(foundInSubRecordings);
      }
    });
  }

  cancelAndTrackSingleRecording(singleRecording: PvrRecording) {
    this.props.removeSingleRecording(singleRecording);

    this.tracking.trackEvent(
      this.tracking.getCurrentCategory(),
      GAaction.recordProgramCancel,
      singleRecording.channelName + ' - ' + singleRecording.pvrName + ' - ' + getSeasonAndEpisodeLabel(singleRecording),
    );
  }

  renderSeriesRecordButton(recordInChronologicalOrder: boolean) {
    const {
      programData,
      programData: { starttime, name, seasonNum, episodeNum },
      seriesId,
      channelId,
    } = this.props;
    const effectivetime = starttime ? fromMoment(starttime) : '';
    return this.renderButton(
      i18n.t<string>(recordInChronologicalOrder ? 'record series chronological' : 'record series'),
      () => {
        let seriesRecording = this.seriesRecording();
        if (seriesRecording) {
          this.props.updateSeriesRecording(seriesRecording, false, RecordingDeleteMode.Auto);
          this.trackSeries(this.getTrackingLabel(seriesRecording), false);
          return;
        }
        let label = '';
        if (programData && name && seasonNum && episodeNum) {
          label = this.channel.name + ' - ' + name + ' - S' + seasonNum + ' E' + episodeNum;
        }
        this.trackSeries(label, false);
        if (recordInChronologicalOrder) {
          this.props.addChronologicalSeriesRecording(
            seriesId,
            channelId,
            effectivetime,
            this.state.deleteMode,
            Number(episodeNum),
            Number(seasonNum),
          );
          return;
        }
        this.props.addSeriesRecording(seriesId, channelId, effectivetime, this.state.deleteMode);
      },
    );
  }

  renderCancelSeriesRecordButton() {
    return this.renderButton(i18n.t<string>('cancel series recording'), () => {
      let seriesRecording = this.seriesRecording();
      if (seriesRecording) {
        this.props.cancelSeriesRecording(seriesRecording);
        this.trackSeries(this.getTrackingLabel(seriesRecording), true);
      }
    });
  }

  renderButton(label: string, callback: Function) {
    return (
      <button
        className={styles.optionButton}
        onClick={() => {
          callback();
          this.props.hideModal();
        }}
      >
        {label}
      </button>
    );
  }

  changeDeleteMode = () => {
    let deleteMode = isDeleteModeAuto(this.state.deleteMode) ? RecordingDeleteMode.Manual : RecordingDeleteMode.Auto;
    this.setState(
      {
        deleteMode: deleteMode,
      },
      () => {
        if (this.singleRecording()) {
          this.props.updateSingleRecording(this.singleRecording()!, this.state.deleteMode);
        }
        if (this.seriesRecording()) {
          this.props.updateSeriesRecording(
            this.seriesRecording()!,
            this.seriesRecording()!.canceled,
            this.state.deleteMode,
          );
        }
      },
    );
  };

  render() {
    const {
      modalHeroBackground,
      seriesId,
      programData: { name, seasonNum, episodeNum },
      hideModal,
    } = this.props;

    const { modalIsOpen, recordProgram, recordSeries, deleteMode } = this.state;
    const title = `${name!} ${seasonNum && episodeNum ? '- S' + seasonNum + ' E' + episodeNum : ''}`;
    return (
      <Modal
        portalClassName={'options-modal'}
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={true}
        onRequestClose={hideModal}
        onAfterClose={hideModal}
        className="aib-modal"
      >
        <ModalHeroBackground imageUrl={modalHeroBackground} title={title} />
        <button className={styles.buttonClose} onClick={hideModal} children={<SvgClose />} />
        <main className={styles.mainContainer}>
          <div className={styles.optionsButtonWrapper}>
            {!this.isRecordingProgram && !this.isProgramInSeriesRecording && this.renderSingleRecordButton()}

            {(this.isRecordingProgram || this.isProgramInSeriesRecording) && this.renderCancelSingleRecordButton()}

            {seriesId && this.isRecordingSeries && this.renderCancelSeriesRecordButton()}

            {seriesId && !this.isRecordingSeries && this.shouldShowSeriesRecordButtons && (
              <>
                {this.renderSeriesRecordButton(true)}
                {this.renderSeriesRecordButton(false)}
              </>
            )}
            <DeleteToggleButton
              isRecording={recordSeries || recordProgram}
              changeDeleteMode={this.changeDeleteMode}
              deleteToggleChecked={isDeleteModeAuto(deleteMode)}
            />
          </div>
          <p className={styles.modalFooterNote}>
            {i18n.t<string>('delete automatically description before tack')} "<span className="aib-icon">&</span>{' '}
            <span className={styles.footerNoteAfterTack}>
              " {i18n.t<string>('delete automatically description after tack')}
            </span>
          </p>
        </main>
      </Modal>
    );
  }
}

export default connect(
  (state: RootState) => {
    const { channels, singleRecordings, seriesRecordings, recordingActionInProgress } = state.channelsReducer;
    return {
      channels,
      singleRecordings,
      seriesRecordings,
      recordingActionInProgress,
    };
  },
  (dispatch) => {
    return {
      addSingleRecording: (programData: ProgramToRecord, channelId: string, deleteMode: RecordingDeleteMode) =>
        dispatch(addSingleRecording(programData, channelId, deleteMode)),
      removeSingleRecording: (recording: PvrRecording) => dispatch(removeSingleRecording(recording)),
      updateSingleRecording: (recording: PvrRecording, deleteMode: RecordingDeleteMode) =>
        dispatch(updateSingleRecording(recording, deleteMode)),
      addSeriesRecording: (
        seriesId: string,
        channelId: string,
        effectivetime: string,
        deleteMode: RecordingDeleteMode,
      ) => dispatch(addSeriesRecording(seriesId, channelId, effectivetime, deleteMode)),
      addChronologicalSeriesRecording: (
        seriesId: string,
        channelId: string,
        effectivetime: string,
        deleteMode: RecordingDeleteMode,
        selectedSubNum: number,
        selectedSeasonNum: number,
      ) =>
        dispatch(
          addChronologicalSeriesRecording(
            seriesId,
            channelId,
            effectivetime,
            deleteMode,
            selectedSubNum,
            selectedSeasonNum,
          ),
        ),
      updateSeriesRecording: (recording: PvrRecording, canceled: boolean, deleteMode: RecordingDeleteMode) =>
        dispatch(updateSeriesRecording(recording, canceled, deleteMode)),
      cancelSeriesRecording: (recording: PvrRecording) => dispatch(cancelSeriesRecording(recording)),
    };
  },
)(PvrEditRecordingModal);
