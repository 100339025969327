import React, { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import styles from './Swimlane.module.scss';
import { Key } from 'ts-key-enum';
import { useGesture } from 'react-use-gesture';
import { SvgChevronLeft, SvgChevronRight } from '../../icons';

enum SCROLL_POSITION {
  LEFT = -1,
  MIDDLE = 0,
  RIGHT = 1,
}

const dragThreshold = 5;
const simulatedDragDistance = 500; // Distance in pixels

interface SwimlaneDefaultProps {
  title: string;
  children: React.ReactNode;
  shouldNotResize?: boolean;
  rememberPositionKey?: string;
  oldMargin?: boolean;
  className?: string;
  isLoading?: boolean;
}

interface SwimlaneWithLinkProps extends SwimlaneDefaultProps {
  link?: string;
  onClick?: never;
}

interface SwimlaneWithButtonProps extends SwimlaneDefaultProps {
  link?: never;
  onClick?: () => void;
}

type SwimlaneProps = (SwimlaneWithLinkProps | SwimlaneWithButtonProps) & HTMLAttributes<HTMLElement>;

interface ArrowButtonProps {
  scrollPosition: SCROLL_POSITION;
  simulateDrag: (direction: boolean) => void;
  direction: 'right' | 'left';
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ direction, scrollPosition, simulateDrag }) => {
  const isDisabled =
    direction === 'right' ? scrollPosition === SCROLL_POSITION.RIGHT : scrollPosition === SCROLL_POSITION.LEFT;

  return (
    <button
      className={styles.arrow}
      onClick={() => {
        simulateDrag(direction === 'right' ?? true);
      }}
      disabled={isDisabled}
      onKeyDown={(e) => {
        if (e.key === Key.Enter) {
          simulateDrag(direction === 'right' ?? true);
        }
      }}
    >
      {direction === 'right' ? <SvgChevronRight /> : <SvgChevronLeft />}
    </button>
  );
};

const CACHE: { [rememberPositionKey: string]: number } = {};

export function Swimlane({
  title,
  link,
  onClick,
  children,
  shouldNotResize,
  rememberPositionKey,
  oldMargin,
  className = '',
  isLoading = false,
  ...rest
}: SwimlaneProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isClicking, setIsClicking] = useState(false);
  const [xOffset, setXOffset] = useState(rememberPositionKey ? CACHE[rememberPositionKey] ?? 0 : 0);
  const [scrollPosition, setScrollPosition] = useState(SCROLL_POSITION.LEFT);
  const [width, setWidth] = useState(shouldNotResize ? 0 : window.innerWidth);

  const shouldRenderArrows = useCallback(() => {
    return ref.current && ref.current.scrollWidth > ref.current.offsetWidth;
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth), {
      passive: false,
    });

    if (!shouldNotResize) {
      return () => window.removeEventListener('resize', () => setWidth(window.innerWidth));
    }
  }, [width, shouldNotResize, shouldRenderArrows]);

  useEffect(() => {
    return () => {
      if (rememberPositionKey) {
        CACHE[rememberPositionKey] = xOffset;
      }
    };
  }, [rememberPositionKey, xOffset]);

  useEffect(() => {
    if (rememberPositionKey && ref.current) {
      ref.current.scrollLeft = CACHE[rememberPositionKey] ?? 0;
    }
  }, [rememberPositionKey]);

  const onDragCallback = (x: number, last: boolean, dragging: boolean) => {
    const newPos = xOffset + -x;
    const maxScrollWidth = scrollWhileDragging(newPos, dragging);

    handleLastDragEvent(last, newPos, maxScrollWidth);
  };

  function scrollWhileDragging(newPos: number, dragging: boolean) {
    // Keep track of dragging state to make sure that ´pointer-events: 'none'´ while dragging
    if (dragging) {
      setIsClicking(false);
      setIsDragging(true);
    }

    if (ref && ref.current) {
      const clientWidth = ref.current.clientWidth;
      const scrollWidth = ref.current.scrollWidth;
      const maxScrollWidth = scrollWidth - clientWidth;

      // Scroll freely between the limits
      if (newPos > 0 && newPos < scrollWidth) {
        ref.current.scrollLeft = newPos;
      }

      // Set initial position if user is dragging all the way to the left
      if (newPos <= 0) {
        ref.current.scrollLeft = 0;
      }

      return maxScrollWidth;
    }
  }

  function handleLastDragEvent(isLastEvent: boolean, newPos: number, maxScrollWidth: number | undefined) {
    if (isLastEvent) {
      // If the offset is negative scrollLeft should be set to 0 to avoid "negative" scrolling.
      if (newPos < 0) {
        setXOffset(0);
        setScrollPosition(SCROLL_POSITION.LEFT);
        if (ref && ref.current && isClicking) {
          ref.current.scrollLeft = 0;
        }
        // If the offset is has reached max scroll width "over-scrolling" should not happen
      } else if (maxScrollWidth && newPos > maxScrollWidth) {
        if (ref && ref.current) {
          setXOffset(maxScrollWidth);
          setScrollPosition(SCROLL_POSITION.RIGHT);
          if (isClicking) {
            ref.current.scrollLeft = maxScrollWidth;
          }
        }
      } else {
        setScrollPosition(SCROLL_POSITION.MIDDLE);
        setXOffset(newPos);
      }
      setIsDragging(false);
    }
  }

  const handleSimulateDrag = (direction: boolean) => {
    setIsClicking(true);
    if (ref && ref.current) {
      // Smooth scrolling for button scrolling.
      ref.current.style.scrollBehavior = 'smooth';
    }
    onDragCallback(simulatedDragDistance * (direction ? -1 : 1), true, false);
  };

  const bindGestures = useGesture(
    {
      onDrag: ({ movement: [x], last, dragging, event }) => {
        if (event) {
          switch (event.type) {
            case 'touchmove':
            case 'touchend':
              if (ref && ref.current) {
                setXOffset(ref.current.scrollLeft);
              }
              break;
            default:
              onDragCallback(x, last, dragging);
          }
        }
      },
      onWheel: ({ last }) => {
        if (ref && ref.current) {
          setXOffset(ref.current.scrollLeft);

          if (last) {
            if (ref.current.scrollLeft <= 0) {
              setScrollPosition(SCROLL_POSITION.LEFT);
            } else if (ref.current.scrollLeft === ref.current.scrollWidth - ref.current.clientWidth) {
              setScrollPosition(SCROLL_POSITION.RIGHT);
            } else {
              setScrollPosition(SCROLL_POSITION.MIDDLE);
            }
          }
        }
      },
      onKeyUp: ({ key }) => {
        if (key === Key.ArrowRight || key === Key.ArrowLeft) {
          if (ref && ref.current) {
            if (ref.current.scrollLeft <= 0) {
              setScrollPosition(SCROLL_POSITION.LEFT);
            } else if (ref.current.scrollLeft === ref.current.scrollWidth - ref.current.clientWidth) {
              setScrollPosition(SCROLL_POSITION.RIGHT);
            } else {
              setScrollPosition(SCROLL_POSITION.MIDDLE);
            }
          }
        }
      },

      // Mouse enter and leave to prevent back and forward navigation for trackpads.
      onMouseEnter: () => {
        document.body.style.overscrollBehaviorX = 'contain';
      },
      onMouseLeave: () => {
        document.body.style.overscrollBehaviorX = '';
      },
    },
    {
      // pixel threshold in order to destinguish between click and drag.
      drag: { threshold: dragThreshold },
      domTarget: ref,
      eventOptions: { passive: false },
    },
  );

  const getStyles = (): React.CSSProperties => {
    return {
      scrollBehavior: isClicking ? 'smooth' : 'auto',
    };
  };

  function renderTitle() {
    const classNames = `${styles.title} ${isLoading ? styles.isLoading : ''}`;

    if (link) {
      return (
        <a className={classNames} href={link}>
          {title}
        </a>
      );
    }

    if (onClick) {
      return (
        <button className={classNames} onClick={onClick}>
          {title}
        </button>
      );
    }

    return <h3 className={classNames}>{title}</h3>;
  }

  return (
    <div className={`${styles.swimlane} ${oldMargin ? styles.old : ''} ${className}`} {...rest}>
      <div className={styles.header}>
        {renderTitle()}
        <div className={styles.buttons}>
          <ArrowButton scrollPosition={scrollPosition} simulateDrag={handleSimulateDrag} direction="left" />
          <ArrowButton scrollPosition={scrollPosition} simulateDrag={handleSimulateDrag} direction="right" />
        </div>
      </div>
      <div
        {...bindGestures()}
        className={`${styles.slider} ${isDragging ? styles.noEvent : ''}`}
        ref={ref}
        style={getStyles()}
      >
        {children}
      </div>
    </div>
  );
}
