import React, { Component } from 'react';
import './style.scss';

import imagePlaceholder from '../../../../images/tv-placeholder-landscape.png';

type Props = {
  cover: string | undefined;
  title: string;
  subtitle: string;
  buttonText: string;
  buttonCallback: Function;
  crossout: Function;
  show: boolean;
  purchase: boolean;
  popupCounter: number;
};

type State = {
  hidden: boolean;
  hasCountedDown: boolean;
};

class PlayerInfoPopup extends Component<Props, State> {
  state: State = {
    hidden: true,
    hasCountedDown: false,
  };

  crossOutWindow() {
    this.setState({ hidden: !this.state.hidden, hasCountedDown: true });
    this.props.crossout();
  }

  render() {
    const { cover, title, subtitle, buttonText, buttonCallback, show } = this.props;
    const classname = `player-info-popup${show ? '' : ' hidden'}`;
    return (
      <div className={classname}>
        <button className="aib-icon crossout" aria-label="Close">
          <span role="button" onClick={() => this.crossOutWindow()}>
            B
          </span>
        </button>
        <div className="poster-image">
          <div className="overlay" onClick={() => buttonCallback()} role="button">
            <div className="icon">
              <span className="aib-icon">Ă</span>
            </div>
          </div>
          <img src={cover} onError={(e) => (e.currentTarget.src = imagePlaceholder)} alt={subtitle} />
        </div>
        <div className="content">
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <div className="description" dangerouslySetInnerHTML={{ __html: subtitle }} />
          <button className="autoplay-action" onClick={() => buttonCallback()} aria-label="Go to next episode">
            <span className="autoplay-container">
              <span className="icon">
                <span className="aib-icon">z</span>
              </span>
              <span className="text">{buttonText}</span>
            </span>
          </button>
        </div>
      </div>
    );
  }
}

export default PlayerInfoPopup;
