import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  RootState,
  ApiAuthResponse,
  Genre,
  GenreType,
  VodListResponse,
  VodConfig,
  VodAssetDisplayMode,
  PortalMenu,
  VodAsset,
  HeaderInteractionType,
  HeaderDisplayType,
  SearchConfig,
  SortCategories,
} from '../../../interfaces';
import Header from '../../app/Header';
import { loadVodCategory, clearCategory, fetchMyContent } from '../actions';

import Spinner from '../../../components/Spinner';
import { routes, vodSvodSortingOptions } from '../../../config';
import { getGenres } from '../../tv/actions';
import VodAssetList from '../../../components/VodAssetList';
import { getCategoryTitle, getGenreTitle, addBadgeOnOwnedSeriesAssets } from '../../../utils/vodUtils';
import FullscreenMessage from '../../../components/FullscreenMessage';
import { getSvodUrl } from '../../../utils/svodUtil';
import CategoryHelmet from '../../../components/Helmets/CategoryHelmet';
import SortDropdown from '../../../components/SortDropdown';
import StickyHeader from '../../../views/app/StickyHeader';
import i18n from '../../../i18n';

interface RouteProps {
  categoryId: string;
  orderType?: SortCategories;
  genreIds?: string;
}

interface Props extends RouteComponentProps<RouteProps, {}, { scrollOffset?: number }> {
  viewType: VodAssetDisplayMode | undefined;
  auth: ApiAuthResponse | undefined;
  genres: Genre[];
  mySeries: VodAsset[];
  myContent: VodAsset[];
  vodConfig: VodConfig;
  svodConfig: VodConfig | undefined;
  searchConfig: SearchConfig;
  defaultImagePath: string;
  svodKiosk: PortalMenu | undefined;
  currentVodCategory: VodListResponse;
  currentVodCategoryId: string;
  getMyContent: (count: number) => Promise<void>;
  clearCategory: () => Promise<void>;
  getVodCategory: (
    categoryId: string,
    orderType: string,
    genres: string,
    availableGenres: Genre[],
    count: number,
    offset: number,
  ) => Promise<void>;
  getGenres: () => Promise<Genre[]>;
  isGuest: boolean;
  loggedInWithCredentials: boolean;
}

interface State {
  title: string;
  loadingTitle: string;
  empty: boolean;
  offset: number;
  preventScrollToTop: boolean;
}

class CategoryView extends Component<Props, State> {
  state: State = {
    title: '',
    loadingTitle: i18n.t<string>('loading') + '...',
    empty: false,
    offset: 0,
    preventScrollToTop: true,
  };

  // Strict Class Initialization ignored
  numberOfAssetsToLoad = 50;
  orderType!: string;
  overriddenOrderType!: SortCategories;
  genreIds!: string;

  get iconPath() {
    const { svodKiosk, defaultImagePath } = this.props;
    if (svodKiosk && svodKiosk.kioskHeader) {
      let kioskHeader = svodKiosk.kioskHeader;
      return `${defaultImagePath}/${kioskHeader.cropped ? kioskHeader.cropped : kioskHeader.phone}`;
    }
    return undefined;
  }

  getCategoryId(params: RouteProps) {
    return (
      params.categoryId +
      '/' +
      (params.orderType ? params.orderType : SortCategories.MOST_POPULAR) +
      '/' +
      (params.genreIds ? params.genreIds : '')
    );
  }

  isGenreView(path: string) {
    // todo what about svod genre?
    return path.indexOf(routes.vod.genre) !== -1;
  }

  getPageTitle(categoryId: string, orderType: string, genreIds: string, vodConfig: VodConfig) {
    if (this.isGenreView(this.props.match.path)) {
      return getGenreTitle(categoryId, orderType, genreIds, vodConfig);
    } else {
      return getCategoryTitle(categoryId, orderType, genreIds, vodConfig);
    }
  }

  componentDidUpdate() {
    if (this.state.preventScrollToTop) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state.scrollOffset) {
      setTimeout(() => {
        if (this.props.location.state.scrollOffset) {
          window.scrollTo(0, this.props.location.state.scrollOffset);
        }
      }, 500);
    }
    const params = this.props.match.params;
    if (params.categoryId) {
      this.orderType = params.orderType ? params.orderType : this.orderType;
      this.genreIds = params.genreIds ? params.genreIds : '';
      if (this.props.currentVodCategoryId !== this.getCategoryId(params)) {
        this.props.clearCategory();
      } else {
        this.setState({
          title: this.getPageTitle(
            params.categoryId,
            this.orderType,
            this.genreIds,
            this.props.svodConfig ? this.props.svodConfig : this.props.vodConfig,
          ),
        });

        if (this.props.currentVodCategory && this.props.currentVodCategory.vodlist.length > 0) {
          this.setState({
            offset: this.props.currentVodCategory.vodlist.length,
          });
        } else {
          this.loadVodAssets(params.categoryId!).then(() => {
            if (this.props.currentVodCategory.counttotal === '0') {
              this.setState({
                empty: true,
                loadingTitle: '',
              });
            }
          });
        }
      }
    } else {
      this.setState({
        empty: true,
        loadingTitle: '',
      });
    }
  }

  getMyContentIfNotSet() {
    if (!this.props.isGuest) {
      if (this.props.mySeries) {
        return Promise.resolve();
      } else {
        return this.props.getMyContent(-1);
      }
    }
    return Promise.resolve();
  }

  loadVodAssets(categoryId: string) {
    return this.props.getGenres().then(() => {
      return this.getMyContentIfNotSet().then(() => {
        return this.props.getVodCategory(
          categoryId,
          this.overriddenOrderType ? this.overriddenOrderType : this.orderType,
          this.genreIds,
          this.props.genres,
          this.numberOfAssetsToLoad,
          this.state.offset,
        );
      });
    });
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const params = newProps.match.params;
    if (
      newProps.currentVodCategory &&
      newProps.currentVodCategory !== this.props.currentVodCategory &&
      newProps.currentVodCategoryId === '-1'
    ) {
      if (params.categoryId) {
        this.loadVodAssets(params.categoryId!).then(() => {
          const title = this.getPageTitle(
            params.categoryId,
            this.orderType,
            this.genreIds,
            this.props.svodConfig ? this.props.svodConfig : this.props.vodConfig,
          );

          if (title !== '') {
            this.setState({
              title: title,
            });
          }

          if (this.props.currentVodCategory.counttotal === '0') {
            this.setState({
              empty: true,
              loadingTitle: '',
            });
          }
        });
      }
    }
    if (newProps.svodConfig) {
      this.setState({
        title: this.getPageTitle(params.categoryId, this.orderType, this.genreIds, newProps.svodConfig),
      });
    }
  }

  onScrolledToBottomCallback = () => {
    if (this.props.match.params.categoryId) {
      this.setState(
        {
          preventScrollToTop: false,
          offset: this.state.offset + this.numberOfAssetsToLoad,
        },
        () => {
          const params = this.props.match.params;
          this.props.getVodCategory(
            params.categoryId!,
            this.overriddenOrderType ? this.overriddenOrderType : this.orderType,
            this.genreIds,
            this.props.genres,
            this.numberOfAssetsToLoad,
            this.state.offset,
          );
        },
      );
    }
  };

  sortChanged = (sortValue: SortCategories) => {
    this.props.clearCategory();
    this.setState(
      {
        offset: 0,
      },
      () => {
        this.overriddenOrderType = sortValue;
      },
    );
  };

  renderVodAssetList = () => {
    const { svodKiosk, myContent, currentVodCategory, mySeries } = this.props;
    let prepareContent = addBadgeOnOwnedSeriesAssets(currentVodCategory.vodlist, myContent);
    let prepareSeries = addBadgeOnOwnedSeriesAssets(prepareContent, mySeries);
    const vods = prepareSeries;

    return (
      <VodAssetList
        assetIconPath={this.iconPath}
        baseAssetURL={svodKiosk ? `${routes.svod.base}/${getSvodUrl(svodKiosk!)}${routes.svod.details}` : undefined}
        viewMode={this.props.viewType}
        vods={vods}
        onScrolledToBottomCallback={this.onScrolledToBottomCallback}
        loggedInWithCredentials={this.props.loggedInWithCredentials}
      />
    );
  };

  render() {
    let alteredSearchConfig = this.props.searchConfig;
    alteredSearchConfig.returnPath = this.props.location.pathname;
    return (
      <div className="main-frame">
        <CategoryHelmet
          title={this.state.title}
          categoryTitle={this.props.svodKiosk ? this.props.svodKiosk.titles!.nb : 'Film og Serier'}
        />
        <StickyHeader searchConfig={alteredSearchConfig} />
        <Header
          title={
            this.props.currentVodCategory && parseInt(this.props.currentVodCategory.counttotal, 10) > 0
              ? this.state.title
              : this.state.loadingTitle
          }
          displayType={HeaderDisplayType.Solid}
          interactionType={HeaderInteractionType.Full}
        />
        <div className="vod-asset-full-view vod-content">
          <SortDropdown
            initialSelectedSort={
              this.props.match.params.orderType ? this.props.match.params.orderType : SortCategories.MOST_POPULAR
            }
            changeSortCallback={this.sortChanged}
            sortingOptions={vodSvodSortingOptions}
          />
          {this.state.empty ? (
            <FullscreenMessage>{i18n.t('empty category')}</FullscreenMessage>
          ) : this.props.currentVodCategory &&
            this.props.currentVodCategory.vodlist &&
            this.props.currentVodCategory.vodlist.length > 0 ? (
            this.renderVodAssetList()
          ) : (
            <Spinner wrapInContainer={true} />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: RootState) => ({
      mySeries: state.vodReducer.mySeries as VodAsset[],
      myContent: state.vodReducer.myContent as VodAsset[],
      defaultImagePath: state.app.defaultImagePath,
      isGuest: state.authReducer.isGuest,
      loggedInWithCredentials: state.authReducer.loggedInWithCredentials,
      auth: state.authReducer.auth,
      vodConfig: state.vodReducer.vodConfig as VodConfig,
      svodConfig: state.svodReducer.svodConfig,
      svodKiosk: state.svodReducer.svodKiosk,
      currentVodCategory: state.vodReducer.currentVodCategory as VodListResponse,
      currentVodCategoryId: state.vodReducer.currentVodCategoryId as string,
      genres: state.channelsReducer.genres,
    }),
    (dispatch) => ({
      clearCategory: () => dispatch(clearCategory()),
      getMyContent: (count: number) => dispatch(fetchMyContent(count)),
      getVodCategory: (
        categoryId: string,
        orderType: string,
        genres: string,
        availableGenres: Genre[],
        count: number,
        offset: number,
      ) => dispatch(loadVodCategory(categoryId, orderType, genres, availableGenres, count, offset)),
      getGenres: () => dispatch(getGenres(GenreType.MovieAndTV)),
    }),
  )(CategoryView),
);
