import { VodAsset, PortalMenu, VodListResponse, FavoCatalogResponse } from '../interfaces';
import { getSvodKiosk } from './svodUtil';

export function getVodFromFavourites(providers: PortalMenu[], favouriteList: VodAsset[]) {
  return favouriteList.filter((favourite) => {
    let isVod = getSvodKiosk(favourite, providers);
    return isVod ? false : true;
  });
}
export function getSvodFromFavourites(providers: PortalMenu[], favouriteList: VodAsset[]) {
  return favouriteList.filter((favourite) => {
    let isSvod = getSvodKiosk(favourite, providers);
    return isSvod ? true : false;
  });
}

export function getSvodKioskIcon(provider: PortalMenu[], target: string) {
  let found = provider.find((x) => {
    return x.providerName === target;
  });

  if (found && found.kioskHeader) {
    return found.kioskHeader.phone;
  } else {
    return '';
  }
}

export function fakeFavourite(list: VodListResponse, vodId: string, favouriteValue: string) {
  if (list.vodlist) {
    const listToMutate = list.vodlist.map((vod) => {
      if (vod.id === vodId) {
        vod.isfavorited = favouriteValue;
      }
      return vod;
    });
    const temp: VodListResponse = {
      counttotal: list.counttotal,
      vodlist: listToMutate,
    };
    return temp;
  } else {
    return list;
  }
}

export function getUniqueMissingFavoIds(list: FavoCatalogResponse, svodKiosks: PortalMenu[]): string[] {
  let favolistStripped: string[] = [];
  let svodKiosksStripped = svodKiosks.map((el) => `svod-${el.identifier!}`);

  if (list.favolist) {
    favolistStripped = list.favolist.map((el) => el.name);
  } else {
    return svodKiosksStripped;
  }
  return svodKiosksStripped.filter((o) => favolistStripped.indexOf(o) === -1);
}

export function identifySvodIdentifierBasedOnFavoIdName(favoIdName: string) {
  return favoIdName.split('svod-')[1];
}

export function favoCatalogifyProviderName(name: string) {
  if (name === 'HBO Nordic') {
    name = 'hbo';
  }
  name = name.replace(/ /g, '_');
  return `svod-${name}`;
}
