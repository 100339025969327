import React, { Component } from 'react';

interface Props {
  alt: string;
  image?: string;
  placeholder: string;
  failedToLoadCallback?: Function;
}

interface State {
  imageUrl: string;
  failedToLoadDoNotLoadAgain: boolean;
}

class AltiboxImage extends Component<Props, State> {
  state: State = {
    imageUrl: '',
    failedToLoadDoNotLoadAgain: false,
  };

  // after placeholder is loaded load the image
  handlePlaceholderLoaded = () => {
    if (!this.state.failedToLoadDoNotLoadAgain) {
      this.setState({
        imageUrl: this.props.image ? this.props.image : this.props.placeholder,
      });
    }
  };

  // if failed show placeholder
  handleLoadingError = () => {
    if (!this.state.failedToLoadDoNotLoadAgain) {
      let imageUrl = this.props.placeholder;

      this.setState({
        failedToLoadDoNotLoadAgain: true,
        imageUrl: imageUrl,
      });

      if (this.props.failedToLoadCallback) {
        this.props.failedToLoadCallback();
      }
    }
  };

  // start with placeholder
  UNSAFE_componentWillMount() {
    this.setState({
      imageUrl: this.props.placeholder,
    });
  }

  render() {
    return (
      <img
        alt={this.props.alt}
        src={this.state.imageUrl}
        onError={this.handleLoadingError}
        onLoad={this.handlePlaceholderLoaded}
      />
    );
  }
}

export default AltiboxImage;
