import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { routes } from '../../config';
import { usePvr } from '../../context/PvrContext';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { SvgChevronLeft } from '../../icons';
import { Pvr, PvrSpace } from '../../interfaces';
import { useRecordingsSpace } from '../../queries/recordings/queries';
import { getSpaceValues } from '../../utils/pvrUtils';
import StickyHeader from '../../views/app/StickyHeader';
import Footer from '../Footer';
import styles from './styles/Layout.module.scss';

const NAV_ITEMS = [
  { label: Pvr.Nav.Titles.Recordings, to: routes.pvr.base },
  { label: Pvr.Nav.Titles.Scheduled, to: routes.pvr.base + routes.pvr.scheduled },
  { label: Pvr.Nav.Titles.Delete, to: routes.pvr.base + routes.pvr.delete },
];

const SHOW_NAV = [routes.pvr.base, routes.pvr.base + routes.pvr.scheduled, routes.pvr.base + routes.pvr.delete];

interface PvrLayoutProps {
  children: React.ReactNode;
  title?: string;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}

export function PvrLayout({ children, title, leftActions, rightActions }: PvrLayoutProps) {
  const { searchConfig } = usePvr();
  const { t } = useTranslation('pvr');
  const { data: space } = useRecordingsSpace();

  const history = useHistory();
  const { pathname, state: locationState } = useLocation<{ goBackTwice: boolean }>();

  const scrollPos = useScrollPosition();

  const showNav = shouldShowNavigation(history.location.pathname);

  function getBackLink() {
    const pathArr = pathname.split('/');

    if (locationState && locationState.goBackTwice) {
      return pathArr.slice(0, pathArr.length - 2).join('/');
    }

    return pathArr.slice(0, pathArr.length - 1).join('/');
  }

  return (
    <>
      <Helmet>
        <title>{t(title ?? Pvr.Nav.Titles.Recordings.toString())}</title>
      </Helmet>
      <StickyHeader searchConfig={searchConfig} />

      <h1 className={styles.title}>{t(title ?? Pvr.Nav.Titles.Recordings.toString())}</h1>
      <div className={`${styles.wrapper} ${!leftActions && !rightActions && scrollPos >= 170 ? styles.scrolled : ''}`}>
        <div className={styles.container}>
          {showNav ? (
            <nav className={styles.nav}>
              {NAV_ITEMS.map(({ label, to }) => (
                <NavLink key={to} to={to} className={(active) => `${styles.link} ${active ? styles.active : ''}`} exact>
                  {t(label)}
                </NavLink>
              ))}
            </nav>
          ) : (
            <nav className={styles.nav}>
              <Link to={getBackLink} className={styles.backLink}>
                <SvgChevronLeft />
                {t('back', { ns: 'translations' })}
              </Link>
            </nav>
          )}

          {space && <PvrSpacePercentage space={space} />}
        </div>

        {(leftActions || rightActions) && (
          <div className={`${styles.actionsWrapper} ${scrollPos >= 170 ? styles.scrolled : ''}`}>
            <div className={styles.actions}>
              {leftActions}
              {rightActions}
            </div>
          </div>
        )}
      </div>

      <div className={styles.content}>{children}</div>

      <Footer />
    </>
  );
}

interface PvrSpacePercentageProps {
  space: PvrSpace;
}
export function PvrSpacePercentage({ space }: PvrSpacePercentageProps) {
  const { totalSpace, spaceUsed, percentageUsed, percentageColor } = getSpaceValues(space);
  const [percent, setPercent] = useState(0);
  const { t } = useTranslation('pvr');

  useEffect(() => {
    setPercent(percentageUsed);
  }, [percentageUsed]);

  return (
    <div className={styles.space}>
      <span>
        {t('hours used', {
          spaceUsed,
          totalSpace,
        })}
      </span>
      <div className={styles.percentage}>
        <div className={`${styles.bar} ${styles[percentageColor]}`} style={{ width: `${percent}%` }} />
      </div>
    </div>
  );
}

function shouldShowNavigation(path: string) {
  return SHOW_NAV.includes(path);
}
