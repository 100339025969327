import { PvrReducerState, PvrSorting } from '../../../interfaces';

import { TypeKeys, ActionTypes } from '../actions';

export function pvrReducer(
  state: PvrReducerState = {
    scheduledRecordings: undefined,
    recordings: undefined,
    currentPvrSeries: undefined,
    bookmarks: undefined,
    recordingToDelete: undefined,
    recordingDeleted: undefined,
    showDeleteSeasonModal: false,
    showDeleteSeriesModal: false,
    showAggregatedDeleteModal: false,
    recordingsLoading: false,
    space: undefined,
    selectedSort: PvrSorting.NEW,
    tempBookmark: undefined,
    isRecordingModalOpen: false,
  },
  action: ActionTypes,
): PvrReducerState {
  switch (action.type) {
    case TypeKeys.SHOW_AGGREGATED_DELETE_MODAL:
      return {
        ...state,
        showAggregatedDeleteModal: action.showAggregatedDeleteModal,
      };
    case TypeKeys.MARK_SERIES_FOR_DELETION:
      return {
        ...state,
        showDeleteSeriesModal: action.showDeleteSeriesModal,
        showAggregatedDeleteModal: false,
      };

    case TypeKeys.MARK_SEASON_FOR_DELETION:
      return {
        ...state,
        showDeleteSeasonModal: action.showDeleteSeasonModal,
        showAggregatedDeleteModal: false,
      };
    case TypeKeys.MARK_RECORDING_FOR_DELETION:
      return {
        ...state,
        recordingToDelete: action.recordingToDelete,
        showAggregatedDeleteModal: false,
      };
    case TypeKeys.MARK_RECORDING_AS_DELETED:
      return {
        ...state,
        recordingDeleted: action.recordingDeleted,
      };
    case TypeKeys.ADD_ALL_RECORDINGS:
      return {
        ...state,
        recordings: action.recordings,
      };
    case TypeKeys.ADD_ALL_SCHEDULED_RECORDINGS:
      return {
        ...state,
        scheduledRecordings: action.scheduledRecordings.slice(0),
      };
    case TypeKeys.ADD_PVR_BOOKMARKS:
      return {
        ...state,
        bookmarks: action.bookmarks,
      };
    case TypeKeys.SET_CURRENT_PVR_SERIES:
      return {
        ...state,
        currentPvrSeries: action.series,
      };
    case TypeKeys.CLEAR_CURRENT_PVR_SERIES:
      return {
        ...state,
        currentPvrSeries: undefined,
      };
    case TypeKeys.SET_RECORDINGS_LOADING:
      return {
        ...state,
        recordingsLoading: action.value,
      };
    case TypeKeys.GET_PVR_SPACE:
      return {
        ...state,
        space: action.space,
      };
    case TypeKeys.SET_SORT:
      return {
        ...state,
        selectedSort: action.selectedSort,
      };
    case TypeKeys.SET_TEMP_BOOKMARK:
      return {
        ...state,
        tempBookmark: action.tempBookmark,
      };
    default:
      return state;
  }
}
